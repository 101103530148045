import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { useGetStatesQuery } from 'services/states/states';
import { useUpdateLocationMutation } from 'services/truck-board/truck-board/truckBoard';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { scrollToTarget } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { verificationAPI } from '../../../../../../services/truck-board/verification-board/verification';
import { validation } from '../../constants/validation';

import { defaultValuesLocation, SUBJECT } from './constants/constants';
import { ILocationData } from './constants/types';

const useLocation = ({ truckInfoById }: any) => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParams } = useDetectedParams();
  const { truckId } = searchParams;
  const locationRef = useRef(null);

  const { data: statesData } = useGetStatesQuery({});
  const [updateLocation] = useUpdateLocationMutation();
  const { id: userId } = useSelector(selectUserInfo);

  const verificationPermissions = usePermittedActions('truckBoard.verification');

  const canChangeLocation = useMemo(() => {
    return (
      (userId == truckInfoById?.coordinatorId && verificationPermissions?.updateSelfLocation) ||
      (truckInfoById?.coordinatorId &&
        userId != truckInfoById?.coordinatorId &&
        verificationPermissions?.updateOtherCoordinatorLocation) ||
      (!truckInfoById?.coordinatorId && verificationPermissions?.updateUnassignedLocation)
    );
  }, [truckInfoById, userId, verificationPermissions]);

  const {
    control,
    setValue,
    watch,
    reset,
    formState: { errors, dirtyFields },
    trigger,
    clearErrors,
    handleSubmit,
  } = useForm<ILocationData>({
    defaultValues: defaultValuesLocation,
    mode: 'onChange',
    resolver: yupResolver(validation),
  });

  const [isConvertingNeeded, setIsConvertingNeeded] = useState(false);

  const onSubmit: SubmitHandler<any> = (data: any) => {
    if (Object.keys(errors)?.length) {
      scrollToTarget(locationRef);
      return;
    } else {
      dispatch(
        openModal({
          title: SUBJECT.POST_TRUCK_TEXT,
          okText: SUBJECT.YES,
          cancelText: SUBJECT.NO,
          cancelButtonVariant: 'gray',
          onOk: () => {
            dispatch(changeLoading(true));
            updateLocation({ id: truckId, data: { ...data, isConvertingNeeded } }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully added',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                setSearchParams({
                  ...searchParams,
                  open: 'false',
                });
                dispatch(verificationAPI?.util?.invalidateTags(['VerificationList']));
              }
            });
            clearErrors();
            dispatch(changeLoading(false));
            dispatch(closeModal());
          },
          onCancel: () => {
            dispatch(closeModal());
          },
        })
      );
    }
  };
  const handleCancel = () => {
    reset();
    clearErrors();
  };

  useEffect(() => {
    reset(
      {
        city: truckInfoById?.city ? truckInfoById?.city : '',
        from: truckInfoById?.fromStateId ? truckInfoById?.fromStateId : '',
        to: truckInfoById?.toStateIds?.length ? truckInfoById?.toStateIds : [],
        availableDate: truckInfoById?.availableAt ? truckInfoById?.availableAt : '',
        equipmentTypeLocation: truckInfoById?.equipmentType ? truckInfoById?.equipmentType : '',
        trailerDetailsLocation: truckInfoById?.trailerDetails ? truckInfoById?.trailerDetails : '',
        length: truckInfoById?.availableLength ? truckInfoById?.availableLength : '',
        locationComment: truckInfoById?.comment ? truckInfoById?.comment : '',
        desiredHomeSwitch: truckInfoById?.city ? truckInfoById.desired : true,
      },
      { keepDirty: false }
    );
  }, [truckInfoById]);

  useEffect(() => {
    dispatch(setIsValid({ isValid: !!Object.keys(dirtyFields)?.length }));
  }, [Object.keys(dirtyFields)?.length]);

  return {
    statesData,
    control,
    setValue,
    watch,
    errors,
    trigger,
    onSubmit,
    handleCancel,
    locationRef,
    handleSubmit,
    canChangeLocation,
    setIsConvertingNeeded,
  };
};

export default useLocation;
