export interface IDateSelect {
  [key: string]: string;
}
export interface ISelectedDatePickerProps {
  fieldName: string;
  label?: string;
  isAbleToSelectFutureValue?: boolean;
  defaultSelected?: string;
  withoutAll?: boolean;
}

export enum DateOptions {
  'TODAY' = 1,
  'YESTERDAY' = 2,
  'CURRENT_MONTH' = 3,
  'All' = 4,
  'CUSTOM' = 5,
}
