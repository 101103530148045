import React from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row } from 'antd';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from '../../constants/constants';

import { useDocumentation } from './useDocumentation';

import styles from '../../CreateLoad.module.scss';

export const Documentation = ({ setValue, control, watch }: any) => {
  const { beforeUploadForCreate, file, handleDeleteFile, handleDownloadFile, handleViewFile, allFiles } =
    useDocumentation({ setValue, watch });

  return (
    <ViewItemWrapper id="documentation" title={SUBJECT.DOCUMENTATION}>
      <Typography variant="heading" children={SUBJECT.UPLOAD_RED_FILE} className={styles.uploadHeading} />
      <Row className={styles.uploadSection} gutter={[23, 40]}>
        <Col span={9}>
          <Upload
            uploadText={SUBJECT.UPLOAD_TEXT}
            className={styles.upload}
            action={file}
            beforeUpload={(e: any, setLoading: any) => beforeUploadForCreate(e, 'allFiles', setLoading)}
            accept="application/pdf"
            uploadType="2"
            multiple={false}
          />
        </Col>
        <Col>
          <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT_PDF} className={styles.allowedFormat} />
        </Col>
        {allFiles.length ? (
          <Col span={24}>
            <Controller
              control={control}
              render={({ field: { value: _value } }) => (
                <FileItems
                  onDeleteClick={(e: any) => handleDeleteFile(e)}
                  handleDownloadFile={handleDownloadFile}
                  handleViewFileCreate={handleViewFile}
                  files={allFiles}
                  withCrossing
                />
              )}
              name="file"
            />
          </Col>
        ) : null}
      </Row>
    </ViewItemWrapper>
  );
};
