import React from 'react';

import Button from '../../../ui/button/Button';

import { SUBJECT } from './constants/constants';
import useCarriersSignUp from './useCarriersSignUp';

const CarriersSignUp = () => {
  const {
    currentChildren: { component, onSubmit },
  } = useCarriersSignUp();

  return (
    <div className="page-content">
      {component}
      <Button variant="primary" size="large" onClick={onSubmit} style={{ width: '100%' }}>
        {SUBJECT.SEND_FOR_REVIEW}
      </Button>
    </div>
  );
};

export default CarriersSignUp;
