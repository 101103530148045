import { createApi } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';
import { baseQuery } from 'services/service';

import { changeTimeZone } from '../../utils/helpers';

export const exportsAPI = createApi({
  reducerPath: 'exportsAPI',
  baseQuery,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    exportAgents: builder.mutation({
      query: ({ search, field, order, orderBy, filter }) => ({
        url: '/exports/agents',
        method: 'POST',
        params: { search, order, field, orderBy, ...filter },
      }),
    }),
    exportOffices: builder.mutation({
      query: ({ search, field, order, orderBy, filter }) => ({
        url: '/exports/offices',
        method: 'POST',
        params: { search, field, order, orderBy, ...filter },
      }),
    }),
    exportBranches: builder.mutation({
      query: ({ search, order, field, orderBy, filter }) => ({
        url: '/exports/branches',
        method: 'POST',
        params: { search, order, field, orderBy, ...filter },
      }),
    }),
    exportAuthorities: builder.mutation({
      query: ({ search, order, field, orderBy, filter }) => ({
        url: '/exports/authorities',
        method: 'POST',
        params: { search, order, field, orderBy, ...filter },
      }),
    }),
    exportEmployees: builder.mutation({
      query: ({ search, field, order, orderBy, level }) => ({
        url: '/exports/employees',
        method: 'POST',
        params: { search, field, order, orderBy, level },
      }),
    }),
    exportFactoringCompanies: builder.mutation({
      query: ({ search, field, order, orderBy, level, filter }) => ({
        url: '/exports/factoring-companies',
        method: 'POST',
        params: { search, field, order, orderBy, level, ...filter },
      }),
    }),
    exportCarriers: builder.mutation({
      query: ({ search, order, field, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });

        if (filter?.filter?.ratings?.length) {
          filter?.filter?.ratings?.forEach((el: string | number) => queryParams.append('ratings[]', String(el)));
        }
        if (filter?.insuranceDays) {
          queryParams.append('insuranceDays', filter?.insuranceDays);
        }
        if (filter?.filter?.type) {
          queryParams.append('type', filter?.filter?.type);
        }

        return {
          url: '/exports/carriers',
          method: 'POST',
          params: queryParams,
        };
      },
    }),
    exportCarriersRequest: builder.mutation({
      query: ({ search, field, order, orderBy }) => {
        return {
          url: '/exports/carriers/requests',
          method: 'POST',
          params: { search, field, order, orderBy },
        };
      },
    }),
    exportCarriersRequestSelf: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, orderBy });

        if (filter?.filter?.ratings?.length) {
          filter?.ratings?.forEach((el: string | number) => queryParams.append('ratings[]', String(el)));
        }
        if (filter?.insuranceDays) {
          queryParams.append('insuranceDays', filter?.insuranceDays);
        }
        if (filter?.filter?.type) {
          queryParams.append('type', filter?.filter?.type);
        }
        if (field) {
          queryParams.append('field', field);
        }
        return {
          url: '/exports/carriers/requests/self',
          method: 'POST',
          params: queryParams,
        };
      },
    }),
    exportCarrierOnBoard: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, orderBy });

        if (filter?.filter?.ratings?.length) {
          filter?.ratings?.forEach((el: string | number) => queryParams.append('ratings[]', String(el)));
        }
        if (filter?.insuranceDays) {
          queryParams.append('insuranceDays', filter?.insuranceDays);
        }
        if (filter?.filter?.type) {
          queryParams.append('type', filter?.filter?.type);
        }
        if (field) {
          queryParams.append('field', field);
        }
        // todo
        return {
          url: '/exports/carriers/on-board/requests',
          method: 'POST',
          params: queryParams,
        };
      },
    }),
    exportCarriersBlacklist: builder.mutation({
      query: ({ search, field, order, orderBy }) => ({
        url: '/exports/carriers/blacklists',
        method: 'POST',
        params: { search, field, order, orderBy },
      }),
    }),
    exportCustomers: builder.mutation({
      query: ({ search, field, order, orderBy, filter }) => ({
        url: '/exports/customers',
        method: 'POST',
        params: { search, field, order, orderBy, ...filter },
      }),
    }),
    exportCustomersBlacklist: builder.mutation({
      query: ({ search, field, order, orderBy, filter }) => ({
        url: '/exports/customers/blacklists',
        method: 'POST',
        params: { search, field, order, orderBy, ...filter },
      }),
    }),
    exportLoads: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });

        if (filter?.agentIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
        }
        if (filter?.branchIds?.length) {
          filter?.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
        }
        if (filter?.document?.length) {
          filter?.document?.forEach((el: string | number) => queryParams.append('documentTypes[]', String(el)));
        }
        if (filter?.loadTypes?.length) {
          filter?.loadTypes?.forEach((el: string | number) => queryParams.append('loadTypes[]', String(el)));
        }
        if (filter?.officeIds?.length) {
          filter?.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
        }
        if (filter?.statuses?.carrierStatuses?.length) {
          filter?.statuses?.carrierStatuses?.forEach((el: string | number) =>
            queryParams.append('carrierStatuses[]', String(el))
          );
        }
        if (filter?.statuses?.customerStatuses?.length) {
          filter?.statuses?.customerStatuses?.forEach((el: string | number) =>
            queryParams.append('customerStatuses[]', String(el))
          );
        }
        if (filter?.statuses?.statuses?.length) {
          filter?.statuses?.statuses?.forEach((el: string | number) => queryParams.append('statuses[]', String(el)));
        }
        if (filter?.statuses?.processing) {
          queryParams.append('processing', filter?.statuses?.processing);
        }
        if (filter?.dateEnd) {
          queryParams.append(
            'dateEnd',
            filter?.isConvertingTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.dateEnd)), 'America/Los_Angeles'))
              : String(filter?.dateEnd)
          );
        }
        if (filter?.dateStart) {
          queryParams.append(
            'dateStart',
            filter?.isConvertingTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.dateStart)), 'America/Los_Angeles'))
              : String(filter?.dateStart)
          );
        }
        return { url: '/exports/loads', method: 'POST', params: queryParams };
      },
    }),
    exportNewLoadsForAgent: builder.mutation({
      query: ({ search, field, order, orderBy, filter }) => ({
        url: '/exports/credits/agents',
        method: 'POST',
        params: { search, field, order, orderBy, ...filter },
      }),
    }),
    exportNewCredit: builder.mutation({
      query: ({ search, field, order, orderBy, filter }) => ({
        url: '/exports/credits/employees',
        method: 'POST',
        params: { search, field, order, orderBy, ...filter },
      }),
    }),
    exportMcAndCustomer: builder.mutation({
      query: ({ search, field, order, orderBy, filter }) => ({
        url: '/exports/payment-terms',
        method: 'POST',
        params: { search, field, order, orderBy, ...filter },
      }),
    }),
    exportContact: builder.mutation({
      query: ({ search, field, ...filter }) => {
        const queryParams = new URLSearchParams({ search, field });

        if (filter?.level) {
          queryParams.append('level', filter?.level);
        }
        return { url: '/exports/contacts', method: 'POST', params: queryParams };
      },
    }),

    exportBilling: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });

        if (filter?.agentIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
        }
        if (filter?.branchIds?.length) {
          filter?.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
        }
        if (filter?.officeIds?.length) {
          filter?.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
        }
        if (filter?.carrierPaymentTypes?.length) {
          filter?.carrierPaymentTypes?.forEach((el: string | number) =>
            queryParams.append('carrierPaymentTypes[]', String(el))
          );
        }
        if (filter?.customerPaymentTypes?.length) {
          filter?.customerPaymentTypes?.forEach((el: string | number) =>
            queryParams.append('customerPaymentTypes[]', String(el))
          );
        }
        if (filter?.createdFrom) {
          queryParams.append(
            'createdFrom',
            filter?.isConvertingTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.createdFrom)), 'America/Los_Angeles'))
              : String(filter?.createdFrom)
          );
        }
        if (filter?.createdTo) {
          queryParams.append(
            'createdTo',
            filter?.isConvertingTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.createdTo)), 'America/Los_Angeles'))
              : String(filter?.createdTo)
          );
        }
        return { url: '/exports/billings', method: 'POST', params: queryParams };
      },
    }),
    exportPayables: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });

        if (filter?.agentId) {
          queryParams.append('agentId', filter?.agentId);
        }
        if (filter?.authorityId) {
          queryParams.append('authorityId', filter?.authorityId);
        }
        if (filter?.carrierPaidAtFrom) {
          queryParams.append(
            'carrierPaidAtFrom',
            filter?.isConvertingPaidAtTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.carrierPaidAtFrom)), 'America/Los_Angeles'))
              : String(filter?.carrierPaidAtFrom)
          );
        }
        if (filter?.carrierPaidAtTo) {
          queryParams.append(
            'carrierPaidAtTo',
            filter?.isConvertingPaidAtTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.carrierPaidAtTo)), 'America/Los_Angeles'))
              : String(filter?.carrierPaidAtTo)
          );
        }
        if (filter?.createdAtFrom) {
          queryParams.append(
            'createdAtFrom',
            filter?.isConvertingCreatedAtTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.createdAtFrom)), 'America/Los_Angeles'))
              : String(filter?.createdAtFrom)
          );
        }
        if (filter?.createdAtTo) {
          queryParams.append(
            'createdAtTo',
            filter?.isConvertingCreatedAtTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.createdAtTo)), 'America/Los_Angeles'))
              : String(filter?.createdAtTo)
          );
        }
        if (filter?.carrierId) {
          queryParams.append('carrierId', filter?.carrierId);
        }
        if (filter?.carrierType) {
          queryParams.append('carrierType', filter?.carrierType);
        }
        if (filter?.officeIds?.length) {
          filter?.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
        }
        if (filter?.branchIds?.length) {
          filter?.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
        }
        return { url: '/exports/payables', method: 'POST', params: queryParams };
      },
    }),
    exportReceivables: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });
        if (filter?.agentIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
        }
        if (filter?.authorityIds?.length) {
          filter?.authorityIds?.forEach((el: string | number) => queryParams.append('authorityIds[]', String(el)));
        }
        if (filter?.branchIds?.length) {
          filter?.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
        }
        if (filter?.createdFrom) {
          queryParams.append(
            'createdFrom',
            filter?.isConvertingCreatedAtTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.createdFrom)), 'America/Los_Angeles'))
              : String(filter?.createdFrom)
          );
        }
        if (filter?.createdTo) {
          queryParams.append(
            'createdTo',
            filter?.isConvertingCreatedAtTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.createdTo)), 'America/Los_Angeles'))
              : String(filter?.createdTo)
          );
        }
        if (filter?.customerIds?.length) {
          filter?.customerIds?.forEach((el: string | number) => queryParams.append('customerIds[]', String(el)));
        }
        if (filter?.customerPaymentTypes?.length) {
          filter?.customerPaymentTypes?.forEach((el: string | number) =>
            queryParams.append('customerPaymentTypes[]', String(el))
          );
        }
        if (filter?.officeIds?.length) {
          filter?.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
        }
        if (filter?.paidFrom) {
          queryParams.append(
            'paidFrom',
            filter?.isConvertingPaidAtTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.paidFrom)), 'America/Los_Angeles'))
              : String(filter?.paidFrom)
          );
        }
        if (filter?.paidTo) {
          queryParams.append(
            'paidTo',
            filter?.isConvertingPaidAtTimeNeeded
              ? String(changeTimeZone(new Date(Number(filter?.paidTo)), 'America/Los_Angeles'))
              : String(filter?.paidTo)
          );
        }
        return { url: '/exports/receivables', method: 'POST', params: queryParams };
      },
    }),
    exportTruckBoard: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });
        if (filter?.filter?.equipmentTypes?.length) {
          filter?.filter?.equipmentTypes?.forEach((el: string | number) => {
            if (el != 0) queryParams.append('equipmentTypes[]', String(el));
          });
        }
        if (filter?.filter?.trailerDetails?.length) {
          filter?.filter?.trailerDetails?.forEach((el: string | number) => {
            if (el != 0) queryParams.append('trailerDetails[]', String(el));
          });
        }
        queryParams.append('truckBoardStatus', filter?.filter?.truckBoardStatus || filter?.truckBoardStatus || '1');
        return { url: '/exports/truck-board', method: 'POST', params: queryParams };
      },
    }),
    exportTruckBoardSearchResult: builder.mutation({
      query: ({ search, field, order, orderBy, id, status, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy, id, truckBoardStatus: status });
        if (filter?.filter?.equipmentTypes?.length) {
          filter?.filter?.equipmentTypes?.forEach((el: string | number) => {
            if (el != 0) queryParams.append('equipmentTypes[]', String(el));
          });
        }
        if (filter?.filter?.trailerDetails?.length) {
          filter?.filter?.trailerDetails?.forEach((el: string | number) => {
            if (el != 0) queryParams.append('trailerDetails[]', String(el));
          });
        }
        return { url: '/exports/truck-board-result', method: 'POST', params: queryParams };
      },
    }),
    exportVerification: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });
        if (filter?.filter?.coordinatorIds?.length && !filter?.filter?.coordinatorIds?.includes('0')) {
          filter?.filter?.coordinatorIds?.forEach((el: string | number) =>
            queryParams.append('coordinatorIds[]', String(el))
          );
        }
        if (filter?.filter?.status && filter?.filter?.status != '0') {
          queryParams.append('status', filter?.filter?.status);
        }
        return { url: '/exports/verification', method: 'POST', params: queryParams };
      },
    }),
    exportInformation: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });

        if (filter?.agentIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
        }
        if (filter?.branchIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
        }
        if (filter?.officeIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
        }
        if (filter?.dateStart) {
          queryParams.append(
            'dateStart',
            String(changeTimeZone(new Date(Number(filter?.dateStart)), 'America/Los_Angeles'))
          );
        }
        if (filter?.dateEnd) {
          queryParams.append(
            'dateEnd',
            String(changeTimeZone(new Date(Number(filter?.dateEnd)), 'America/Los_Angeles'))
          );
        } else if (filter?.dateStart) {
          const currentMonthEnd = dayjs(new Date(Number(filter?.dateStart)))
            .endOf('month')
            .valueOf();
          queryParams.append(
            'dateEnd',
            String(changeTimeZone(new Date(Number(currentMonthEnd)), 'America/Los_Angeles'))
          );
        }
        return { url: '/exports/information', method: 'POST', params: queryParams };
      },
    }),
    exportPaymentAgency: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });
        //todo query when payment will be ready
        if (filter?.agentIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
        }
        if (filter?.createdTo) {
          queryParams.append('createdTo', filter?.createdTo);
          //use this code
          // queryParams.append(
          //     'createdTo',
          //     String(changeTimeZone(new Date(Number(filter?.createdTo)), 'America/Los_Angeles'))
          // );
        }
        return { url: '/exports/payment-agency', method: 'POST', params: queryParams };
      },
    }),
    exportPaymentBranch: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });
        //todo query when payment will be ready
        if (filter?.agentIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
        }
        if (filter?.createdTo) {
          queryParams.append('createdTo', filter?.createdTo);
          //use this code
          // queryParams.append(
          //     'createdTo',
          //     String(changeTimeZone(new Date(Number(filter?.createdTo)), 'America/Los_Angeles'))
          // );
        }
        return { url: '/exports/payment-branch', method: 'POST', params: queryParams };
      },
    }),
    exportPaymentAgent: builder.mutation({
      query: ({ search, field, order, orderBy, ...filter }) => {
        const queryParams = new URLSearchParams({ search, order, field, orderBy });
        //todo query when payment will be ready

        if (filter?.agentIds?.length) {
          filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
        }
        if (filter?.createdTo) {
          queryParams.append('createdTo', filter?.createdTo);
          //use this code
          // queryParams.append(
          //     'createdTo',
          //     String(changeTimeZone(new Date(Number(filter?.createdTo)), 'America/Los_Angeles'))
          // );
        }
        return { url: '/exports/payment-agent', method: 'POST', params: queryParams };
      },
    }),
  }),
});

export const {
  useExportEmployeesMutation,
  useExportFactoringCompaniesMutation,
  useExportAgentsMutation,
  useExportOfficesMutation,
  useExportBranchesMutation,
  useExportAuthoritiesMutation,
  useExportCarriersMutation,
  useExportCarriersRequestMutation,
  useExportCarriersRequestSelfMutation,
  useExportCarrierOnBoardMutation,
  useExportCarriersBlacklistMutation,
  useExportCustomersMutation,
  useExportCustomersBlacklistMutation,
  useExportLoadsMutation,
  useExportNewLoadsForAgentMutation,
  useExportNewCreditMutation,
  useExportMcAndCustomerMutation,
  useExportContactMutation,
  useExportBillingMutation,
  useExportPayablesMutation,
  useExportReceivablesMutation,
  useExportTruckBoardMutation,
  useExportTruckBoardSearchResultMutation,
  useExportVerificationMutation,
  useExportInformationMutation,
  useExportPaymentAgencyMutation,
  useExportPaymentBranchMutation,
  useExportPaymentAgentMutation,
} = exportsAPI;
