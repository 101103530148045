import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';
import { handleShowFloatingPercent } from 'utils/helpers';

import { createCustomers, updateCustomerSerializer } from './deserializers';
import { BillingTypes, IGetCustomersParams } from './interfaces';
import { getCustomersBlackListSerializer, getCustomersListSerializer, getTemplatesSerializer } from './serializers';

export const customersApi = createApi({
  reducerPath: 'customersAPI',
  baseQuery,
  tagTypes: ['Customers', 'Comments', 'Loads', 'Payment-terms'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    createCustomer: builder.mutation({
      query: body => ({
        url: 'customers',
        method: 'POST',
        body: createCustomers(body),
      }),
      invalidatesTags: ['Customers'],
    }),
    updateCustomer: builder.mutation({
      query: ({ id, values }) => ({
        url: `customers/${id}`,
        method: 'PUT',
        body: updateCustomerSerializer(values),
      }),
      invalidatesTags: ['Customers'],
    }),
    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `customers/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['Customers'],
    }),
    getCustomers: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<IGetCustomersParams>) => ({
        url: 'customers',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getCustomersListSerializer(body),
      providesTags: ['Customers'],
    }),
    getCustomersAutocomplete: builder.query({
      query: ({ search, skip, limit, filter }: Partial<IGetCustomersParams>) => ({
        url: 'customers/autocomplete',
        params: { search, skip, limit, ...filter },
      }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            title: el.name,
            value: String(el.id),
            name: el.name,
            mc: el.mc,
            phone: el.phone,
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      providesTags: ['Customers'],
    }),
    getCustomer: builder.query({
      query: id => `customers/${id}`,
      providesTags: ['Customers'],
    }),
    getDetailedCustomer: builder.query({
      query: id => `customers/${id}/detailed`,
      providesTags: ['Customers'],
    }),
    getCustomersTemplates: builder.query({
      query: () => ({
        url: 'customers/templates',
      }),
      transformResponse: (body: any) => getTemplatesSerializer(body),
      providesTags: ['Customers'],
    }),
    addPermentTerms: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `customers/${id}/payment-terms`,
        method: 'POST',
        body: { ...rest, ...(rest?.templateId ? { templateId: Number(rest?.templateId) } : {}) },
      }),
      invalidatesTags: ['Payment-terms'],
    }),
    updatePermentTerms: builder.mutation({
      query: ({ id, paymentId, ...rest }) => ({
        url: `customers/${id}/payment-terms/${paymentId}`,
        method: 'PUT',
        body: { ...rest, ...(rest?.templateId ? { templateId: Number(rest?.templateId) } : {}) },
      }),
      invalidatesTags: ['Payment-terms'],
    }),
    deletePermentTerms: builder.mutation({
      query: ({ id, paymentId }) => ({
        url: `customers/${id}/payment-terms/${paymentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Payment-terms'],
    }),
    getPermentTerms: builder.query({
      query: ({ skip, limit, id }) => ({
        url: `customers/${id}/payment-terms`,
        params: { skip, limit, order: 2, orderBy: 'createdAt' },
      }),
      transformResponse: ({ result, count }: any) => {
        return {
          result: result?.map((item: any) => ({
            paymentId: item?.id,
            day: item?.days,
            email: item?.emails,
            id: item?.authorityId,
            mc: item?.authority?.mc,
            paymentType: BillingTypes[item?.type],
            name: item.authority?.name,
            paymentLimit: item?.paymentLimit ? item?.paymentLimit : '',
            templateId: item?.templateId ? String(item?.templateId) : '',
            percent: `${handleShowFloatingPercent(item?.percent)}%`,
          })),
          count,
        };
      },
      providesTags: ['Payment-terms'],
    }),
    createComment: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `customers/${id}/comments`,
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: ['Comments', 'Loads'],
    }),
    deleteComment: builder.mutation({
      query: ({ id, commentId }) => ({
        url: `customers/${id}/comments/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Comments', 'Loads'],
    }),
    getComments: builder.query({
      query: ({ skip, limit, id }) => {
        return {
          url: `customers/${id}/comments`,
          params: { skip, limit },
        };
      },
      providesTags: ['Comments'],
      transformResponse: (data: any) => {
        return {
          count: data?.count,
          commentsList: data?.result?.map((item: any) => {
            return {
              id: item?.id,
              name: `${item?.creator.username}`,
              createdAt: item?.createdAt,
              commentText: item.message,
            };
          }),
        };
      },
    }),
    // BLACK LIST
    getBlackList: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<IGetCustomersParams>) => ({
        url: 'customers/black-list',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      providesTags: ['Customers'],
      transformResponse: (body: any) => getCustomersBlackListSerializer(body),
    }),
    getBlackListById: builder.query({
      query: ({ id }) => ({
        url: `customers/black-list/${id}`,
      }),
    }),
    deleteAuhtorityFromBlackList: builder.mutation({
      query: ({ id, authorityId }) => ({
        url: `customers/${id}/black-list/${authorityId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Customers'],
    }),
    addAuhtorityToBlackList: builder.mutation({
      query: ({ customerId, authorityId }) => ({
        url: `customers/${customerId}/black-list`,
        method: 'POST',
        body: { authorityId: Number(authorityId) },
      }),
      invalidatesTags: ['Customers'],
    }),
  }),
});

export const {
  useCreateCustomerMutation,
  useChangeStatusMutation,
  useUpdateCustomerMutation,
  useGetCustomersQuery,
  useGetCustomerQuery,
  useGetDetailedCustomerQuery,
  useCreateCommentMutation,
  useGetCommentsQuery,
  useDeleteCommentMutation,
  useAddPermentTermsMutation,
  useUpdatePermentTermsMutation,
  useDeletePermentTermsMutation,
  useGetPermentTermsQuery,
  useGetBlackListQuery,
  useDeleteAuhtorityFromBlackListMutation,
  useAddAuhtorityToBlackListMutation,
  useGetBlackListByIdQuery,
  useGetCustomersTemplatesQuery,
  useGetCustomersAutocompleteQuery,
} = customersApi;
