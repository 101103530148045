import React from 'react';
import clsx from 'clsx';

import styles from './Loader.module.scss';

const Loader = ({ isLarge = false, intoElement = false }: { isLarge?: boolean; intoElement?: boolean }) => {
  return (
    <div
      className={clsx('local-loader', {
        'large-loader': isLarge,
        'element-loader': intoElement,
      })}
    >
      <div className={styles.loaderWrapper}>
        <div className={styles['TruckLoader']}>
          <div className={styles['TruckLoader-cab']} />
          <div className={styles['TruckLoader-smoke']} />
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Loader;
