import { emailPattern, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { SUBJECT, VALIDATION_MESSAGES } from './constants';

const {
  FIRST_NAME_REQUIRED,
  PERMISSION_GROUP_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  STATE_REQUIRED,
  ZIP_REQUIRED,
  NAME_REQUIRED,
  RELATION_REQUIRED,
  LAST_NAME_REQUIRED,
  USERNAME_REQUIRED,
  EMAIL_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  PHONE,
  INVALID_EMAIL,
  MAX_LENGTH,
  FILE_REQUIRED,
  ADDRESS_MAX_LENGTH,
  MAX_MIN_ZIP_LENGTH,
  PHONE_NUMBER_EMERGENCY_REQUIRED,
} = VALIDATION_MESSAGES;

export const validation = (isUserNameExists: any) => {
  return yup.object().shape({
    firstName: yup.string().required(FIRST_NAME_REQUIRED),
    username: yup
      .string()
      .required(USERNAME_REQUIRED)
      .test('isUserNameExists', SUBJECT.USERNAME_EXISTS, function checkTotal(value = '') {
        if (value) {
          return !isUserNameExists;
        }
        return false;
      }),
    lastName: yup.string().required(LAST_NAME_REQUIRED),
    dob: yup.string(),
    email: yup.string().required(EMAIL_REQUIRED).matches(emailPattern, INVALID_EMAIL),
    phoneNumber: yup.string().required(PHONE_NUMBER_REQUIRED).max(12, MAX_LENGTH).matches(phoneRegExp, PHONE),
    title: yup.string(),
    permissionGroup: yup.string().required(PERMISSION_GROUP_REQUIRED),
    address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
    city: yup.string().required(CITY_REQUIRED),
    state: yup.string().required(STATE_REQUIRED),
    zipcode: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
    name: yup.string().required(NAME_REQUIRED),
    relation: yup.string().required(RELATION_REQUIRED),
    phoneNumberEmergency: yup
      .string()
      .required(PHONE_NUMBER_EMERGENCY_REQUIRED)
      .max(12, MAX_LENGTH)
      .matches(phoneRegExp, PHONE),
    file: yup
      .array()
      .required(FILE_REQUIRED)
      .test('length', FILE_REQUIRED, function checkLength(arr = []) {
        return !!arr?.length;
      }),
  });
};
