import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { SUBJECT } from 'pages/profile/tabs/employee/constants/constants';
import { useInviteAgentAgainMutation } from 'services/profile/agents/agents';
import { useChangeStatusMutation } from 'services/profile/employees/employees';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

export const useEmployeeView = (viewData: any) => {
  const dispatch = useDispatch();

  const [isTooltipOpen, setIsTooltipOpen] = useState('');

  const [changeStatus] = useChangeStatusMutation();
  const [inviteEmployeeAgain] = useInviteAgentAgainMutation();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { id, activePage } = searchParams;

  const handleChecked = (checked: boolean) => {
    dispatch(
      openModal({
        title: checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({ id, status: checked ? 1 : 2 }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleOpenFiles = () => {
    viewData?.files?.forEach((file: any) => {
      window.open(file.url, '_blank');
    });
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: 'edit',
    });
  };
  const handleInviteAgain = (id: number) => {
    inviteEmployeeAgain(id).then((res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.data?.details?.[0] || ServerErrorCodes[0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Employee was successfully invited',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  return {
    handleChecked,
    handleChangeMode,
    handleOpenFiles,
    handleInviteAgain,
    isTooltipOpen,
    setIsTooltipOpen,
    activePage,
  };
};
