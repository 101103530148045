export enum SUBJECT {
  CONFIRMATION_TYPE = 'Credit Type:',
  TONU_LOAD_ID = 'tonu-load',
  GENERAL_INFO = 'General info',
  AGENT_ID = 'Agent ID:',
  FIRST_AGENT_NAME = 'First Agent Name',
  FIRST_AGENT_USERNAME = 'First Agent Username',
  CONNECTING_LOAD_ID = 'connecting-load',
  CONNECTING_LOAD = 'Connecting Load',
  BRACK_NAME = 'Branch Name:',
  SECOND_AGENT_NAME = 'Second Agent Name',
  SECOND_AGENT_USERNAME = 'Second Agent Username',
  FIRST_AGENT_PHONE = 'First Agent Phone',
  FIRST_AGENT_PHONE_NUMBER = 'First Agent Phone Number',
  SECOND_AGENT_PHONE = 'Second Agent Phone',
  SECOND_AGENT_PHONE_NUMBER = 'Second Agent Phone Number',
  INTERNAL_LOAD = 'Internal load',
  TAKEN_AS = 'Taken as',
  TAKEN_AS_MC = 'Taken As MC',
  REG_DATE = 'Reg. Date And Time:',
  REF_NUMBER = 'Reference Number:',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  NO = 'No',
  YES = 'Yes',
  CHECKED_ACTIVATE = 'If Activated The User Can Login  Again',
  CHECKED_DEACTIVATE = 'If Deactivated, The User Cannot Login',
  LOAD_INFO = 'Load Info',
  LOAD_TYPE = 'Load Type:',
  LOAD_NUMBER = 'Load ID:',
  REFERANCE_NUMBER = 'Reference Number:',
  OFFICE_NAME = 'Agency Name:',
  BRANCH_NAME = 'Branch Name:',
  REG_DATE_TIME = 'Reg. Date And Time:',
  CUSTOMER_INFO = 'Customer Info',
  CUSTOMER_NAME = 'Customer Name:',
  CARRIER_INFO = 'Carrier Info',
  CARRIER_COMPANY_NAME = 'Carrier Company Name:',
  CARRIER_PHONE_NUMBER = 'Carrier Phone Number:',
  AGENT_INFO = 'Agent Info',
  AGENTS_INFO = 'Agents Info',
  AGENT_USERNAME = 'Agent Username:',
  AGENT_NAME = 'Agent Name:',
  AGENT_PHONE = 'Agent Phone Number:',
  MC = 'MC:',
  SOLD_US_MC = 'Sold As MC:',
  MARK_AS_CLAIM = 'Mark As Claim',
  CANCEL_LOAD = 'Cancel Load',
  TONU_LOAD = 'Tonu Load',
  ARE_YOU_READY = 'Cancel Load?',
  REQUEST_CHECK = 'REQUEST_CHECK',
  CREDIT_REQUEST = 'Credit Request',
  REGULAR_LOAD = 'Regular Load',
  REGULAR_LOAD_ID = 'regular-load',
  INTERNAL_LOAD_ID = 'internal-load',
  RATING = 'Rating:',
  ARE_YOU_SURE_DELETE = 'Are You Sure Want To Delete Rate?',
  EDIT = 'Edit',
  RATING_COLON = 'Rating:',
  COMMENTS = 'Comments:',
  HISTORY = 'History',
}

export const defaultValues = {
  loadsComment: '',
};

export enum ConfirmationTypes {
  'Pending' = 1,
  'Approved' = 2,
  'Denied' = 3,
  'COD' = 4,
  'Consignment' = 5,
}
