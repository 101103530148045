export enum IAgentType {
  Admin = 0,
  Owner = 1,
  Manager = 2,
  Agent = 3,
}

export enum IRoleLevel {
  corpLevel = 1,
  officeLevel = 2,
  branchLevel = 3,
  agentLevel = 4,
}
