const FactoringIcon = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.49808 19.2373V9.61678H6.15876V19.2373H4.49808ZM11.2267 19.2373V9.61678H12.916V19.2373H11.2267ZM0.775879 22.759V21.0984H23.2236V22.759H0.775879ZM17.8408 19.2373V9.61678H19.5014V19.2373H17.8408ZM0.775879 7.75568V6.2668L11.9998 0.11084L23.2236 6.2668V7.75568H0.775879ZM4.61261 6.095H19.3869L11.9998 2.02921L4.61261 6.095Z"
        fill="white"
      />
    </svg>
  );
};

export default FactoringIcon;
