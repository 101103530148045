import { withController } from 'ui/inputs/hoc/Controller';
import SearchableInput from 'ui/inputs/searchable-select/simple-searchable-select/SimpleSearchableSelect';

import { ICustomPicker } from '../../../ui/inputs/custom-date-picker/constants/types';
import CustomDatePicker from '../../../ui/inputs/custom-date-picker/CustomDatePicker';
import CustomSwitch from '../../../ui/inputs/switch/Switch';
import { ITextAreaProps } from '../../../ui/inputs/textarea/constants/types';
import CustomTextArea from '../../../ui/inputs/textarea/Textarea';
import { ILocationData } from '../components/view-edit-carrier-truck/components/location/constants/types';

export const CoordinatorSelect = withController<any, any>(SearchableInput);
export const Switch = withController<any, ILocationData>(CustomSwitch);
export const DatePicker = withController<ICustomPicker, any>(CustomDatePicker);
export const TextArea = withController<ITextAreaProps, any>(CustomTextArea);
