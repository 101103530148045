import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { selectWidth } from 'store/sidebarSlice/selector';
import { ls } from 'utils/storage';

import { useDetectedParams } from '../../hooks/useDetectedParams';

import Header from './components/header/Header';
import Sidebar from './components/sidebar/Sidebar';

import styles from './MainLayout.module.scss';

const MainLayout = ({ children }: { children: ReactNode }) => {
  const sidebarState = useSelector(selectWidth);
  const navigate = useNavigate();

  const { searchParams } = useDetectedParams();

  const { hash } = searchParams;

  useEffect(() => {
    if (hash) {
      ls.remove('token');
      ls.remove('refresh');
      navigate('/sign-in');
    }
  }, [hash]);

  return (
    <div className={styles.mainLayoutWrapper}>
      <Header />
      <div
        className={clsx(styles.contentWrapper, {
          [styles.growContent]: sidebarState,
        })}
      >
        <Sidebar />
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
