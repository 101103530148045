import { formatDate } from 'utils/dates';

export const getVerificationListSerializer = (body: any) => {
  const result = body?.result?.map(({ carrier, truck }: any) => {
    return {
      carrierId: carrier?.id,
      id: truck?.id,
      createdAt: formatDate(truck?.createdAt),
      verificationAt: formatDate(truck?.verificationAt),
      timer: truck?.verificationAt,
      companyName: carrier?.companyName,
      comments_count: carrier?.commentCount,
      carrierMc: carrier?.mc,
      truckNumber: truck?.truckNumber,
      phoneNumber: carrier?.phone,
      changedBy: truck?.statusChangedBy ? truck?.statusChanger.username : '',
      rate: {
        rating: carrier?.rating,
        ratingCount: carrier?.ratingCount,
      },
      coordinator: truck?.coordinatorId ? `${truck?.coordinator?.username}` : '',
      carrierStatus: truck?.status,
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getCoordinatorsAutocompleteSerializer = (body: any) => {
  const result = body?.result?.map((el: any) => {
    return {
      id: el.id,
      title: `${el?.username}`,
      value: String(el.id),
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  if (params.filter.status && params.filter.status !== '0') {
    queryParams.append('status', String(params.filter.status));
  }
  if (params.filter.coordinatorIds?.length) {
    if (!params.filter?.coordinatorIds.includes('0')) {
      params.filter.coordinatorIds?.forEach((el: string | number) =>
        queryParams.append('coordinatorIds[]', String(el))
      );
    }
  }
  return queryParams;
};
