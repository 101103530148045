import React from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useChangeRequestStatusForCarrierMutation,
  useChangeRequestStatusMutation,
} from 'services/profile/carriers/carriers';
import { closeModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

const useCarrierRequestInfo = () => {
  const { searchParams, setSearchParams } = useDetectedParams();

  const [changeRequestStatuesForAgent] = useChangeRequestStatusMutation();
  const [changeRequestStatuesForCarrier] = useChangeRequestStatusForCarrierMutation();
  const { id, activePage } = searchParams;

  const isCarrierOnBoard = activePage === 'carrierOnBoard';
  const changeRequestStatues = isCarrierOnBoard ? changeRequestStatuesForCarrier : changeRequestStatuesForAgent;

  const dispatch = useDispatch();
  const handleApprove = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: 'edit',
      id,
      isAproveMode: 'true',
    });
  };
  const handleDeny = () => {
    changeRequestStatues({ id: id, status: isCarrierOnBoard ? 2 : 3 }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully denied',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
      setSearchParams({
        ...searchParams,
        open: 'false',
      });
      dispatch(closeModal());
    });
  };

  return {
    handleApprove,
    handleDeny,
  };
};

export default useCarrierRequestInfo;
