export interface IAuthParams {
  email: string;
  password: string;
}

export interface IAuthResult {
  [key: string]: {
    access: string;
    refresh: string;
  };
}

export interface IChangePasswordParams {
  resetToken: string;
  newPassword: string;
}

export interface ICreatePasswordParams {
  hash: string;
  newPassword: string;
}

export interface ICreator {
  modeType: number;
  email: string;
  firstName: string;
  id: number | string;
  lastName: string;
  phone: string;
  username: string;
  branchIds?: number[] | [];
  defaultBranchId?: number;
  authorityIds?: number[] | [];
  officeIds?: number[] | [];
  permissionGroupId?: number;
  type?: number;
  agentType?: number;
  employeeLevel?: number;
  unreadCount?: number;
}

export enum ModeTypes {
  dark = 1,
  light = 2,
}
