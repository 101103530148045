const CreditIcon = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.72644 18.0237V16.7597H4.69287V15.1306H10.7881V12.1251H5.59171C5.34827 12.1251 5.13761 12.0408 4.95971 11.8723C4.78182 11.7038 4.69287 11.4791 4.69287 11.1982V6.70399C4.69287 6.47928 4.77714 6.27797 4.94567 6.10008C5.1142 5.92219 5.32018 5.83324 5.56362 5.83324H7.72644V4.62543H9.38367V5.83324H12.4172V7.49047H6.32201V10.496H11.5184C11.7618 10.496 11.9725 10.5802 12.1504 10.7487C12.3283 10.9173 12.4172 11.1326 12.4172 11.3948V15.889C12.4172 16.1324 12.333 16.3384 12.1644 16.5069C11.9959 16.6755 11.7899 16.7597 11.5465 16.7597H9.38367V18.0237H7.72644ZM15.8721 24.765L11.2094 20.1023L12.3611 18.9506L15.8721 22.4617L22.5853 15.7485L23.737 16.9002L15.8721 24.765Z"
        fill="#252733"
      />
    </svg>
  );
};

export default CreditIcon;
