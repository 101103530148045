import React from 'react';

const InfoWarningIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85875 14.25H10.2837V8.55H8.85875V14.25ZM9.5 6.7925C9.72167 6.7925 9.90771 6.72125 10.0581 6.57875C10.2085 6.43625 10.2837 6.25417 10.2837 6.0325C10.2837 5.81083 10.2085 5.62083 10.0581 5.4625C9.90771 5.30417 9.72167 5.225 9.5 5.225C9.27833 5.225 9.09229 5.30417 8.94188 5.4625C8.79146 5.62083 8.71625 5.81083 8.71625 6.0325C8.71625 6.25417 8.79146 6.43625 8.94188 6.57875C9.09229 6.72125 9.27833 6.7925 9.5 6.7925ZM9.5 19C8.20167 19 6.97458 18.7506 5.81875 18.2519C4.66292 17.7531 3.65354 17.0723 2.79062 16.2094C1.92771 15.3465 1.24687 14.3371 0.748125 13.1812C0.249375 12.0254 0 10.7904 0 9.47625C0 8.17792 0.249375 6.95083 0.748125 5.795C1.24687 4.63917 1.92771 3.63375 2.79062 2.77875C3.65354 1.92375 4.66292 1.24687 5.81875 0.748125C6.97458 0.249375 8.20958 0 9.52375 0C10.8221 0 12.0492 0.249375 13.205 0.748125C14.3608 1.24687 15.3663 1.92375 16.2213 2.77875C17.0763 3.63375 17.7531 4.63917 18.2519 5.795C18.7506 6.95083 19 8.18583 19 9.5C19 10.7983 18.7506 12.0254 18.2519 13.1812C17.7531 14.3371 17.0763 15.3465 16.2213 16.2094C15.3663 17.0723 14.3608 17.7531 13.205 18.2519C12.0492 18.7506 10.8142 19 9.5 19Z"
        fill="#FF8718"
      />
    </svg>
  );
};

export default InfoWarningIcon;
