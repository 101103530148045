import { loginPasswordPattern } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const { PASSWORD_NOT_VALID, PASS_IS_REQUIRED } = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  newPassword: yup.string().required(PASS_IS_REQUIRED).matches(loginPasswordPattern, PASSWORD_NOT_VALID),
  repeatPassword: yup
    .string()
    .required(PASS_IS_REQUIRED)
    .matches(loginPasswordPattern, PASSWORD_NOT_VALID)
    .oneOf([yup.ref('newPassword')], 'Passwords don’t match'),
});
