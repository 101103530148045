import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { SUBJECT } from 'pages/profile/tabs/customers/constants/constants';
import { SearchableDropdown } from 'pages/profile/tabs/customers/hoc-instance/instance';
import Button from 'ui/button/Button';

import ResetIcon from '../../../../../../../../components/svgs/ResetIcon';

import { AUTHORITY_KEYS, CUSTOMER_KEYS, CUSTOMER_TYPE_COLUMNS, MY_AUTHORITY_COLUMNS } from './constants/constants';
import { IBlacklistProps } from './constants/types';

import styles from './Blacklist.module.scss';

const BlackList = ({
  onSubmit,
  handleSubmit,
  setValue,
  handleResetBlacklist,
  control,
  authorities,
  setAuthoritiesFilter,
  customersData,
  setCustomersFilter,
  watch,
}: IBlacklistProps) => {
  const isReset = watch('blacklistCustomer')?.length || watch('blacklistMC')?.length;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.blacklistOverlay}>
        <div className={styles.blacklist}>
          <Row align="bottom" gutter={[20, 0]}>
            <Col xl={7} xxl={6}>
              <SearchableDropdown
                label={SUBJECT.MC}
                options={authorities?.result}
                columns={MY_AUTHORITY_COLUMNS}
                keys={AUTHORITY_KEYS}
                placeholder={SUBJECT.NOT_SELECTED}
                name="blacklistMC"
                control={control}
                setValue={setValue}
                values={watch('blacklistMC')}
                offset={authorities?.count}
                setOffset={setAuthoritiesFilter}
                showKey="name"
                width={500}
              />
            </Col>
            <Col xl={7} xxl={6}>
              <SearchableDropdown
                options={customersData?.result?.map((item: any) => {
                  return {
                    id: item.id,
                    mcID: item?.id,
                    name: item.name,
                  };
                })}
                columns={CUSTOMER_TYPE_COLUMNS}
                keys={CUSTOMER_KEYS}
                label={SUBJECT.CUSTOMER}
                placeholder={SUBJECT.CUSTOMER}
                name="blacklistCustomer"
                control={control}
                setValue={setValue}
                values={watch('blacklistCustomer')}
                offset={customersData?.count}
                setOffset={setCustomersFilter}
                showKey="name"
                width={500}
              />
            </Col>
            <Col xl={6} xxl={6}>
              <div className={styles.blackListButtons}>
                <Button
                  icon={isReset ? <ResetIcon /> : ''}
                  variant="ghost"
                  onClick={handleResetBlacklist}
                  children={isReset ? `${SUBJECT.RESET}` : SUBJECT.CANCEL}
                />
                {!(watch('blacklistCustomer')?.length && watch('blacklistMC')?.length) ? (
                  <Tooltip title={SUBJECT.TOOLTIP_TEXT} color="white">
                    <Button
                      style={{ opacity: 0.5, cursor: 'not-allowed' }}
                      children={SUBJECT.ADD_BLACKLIST}
                      htmlType="button"
                      variant="danger"
                    />
                  </Tooltip>
                ) : (
                  <Button children={SUBJECT.ADD_BLACKLIST} htmlType="submit" variant="danger" />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};
export default BlackList;
