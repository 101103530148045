import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { IRadioProps } from 'ui/inputs/radio/constants/types';
import CustomRadio from 'ui/inputs/radio/Radio';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { IContactCreateEditData } from '../constants/types';

export const SearchableDropdown = withController<ISearchableDropdownProps, IContactCreateEditData>(
  CustomSearchableDropdown
);
export const Input = withController<IInputProps, IContactCreateEditData>(CustomInput);
export const Radio = withController<IRadioProps, IContactCreateEditData>(CustomRadio);
export const SearchableSelect = withController<ISearchableSelectProps, IContactCreateEditData>(CustomSearchableSelect);
