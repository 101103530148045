import React, { ForwardedRef, forwardRef } from 'react';
import { Input, InputRef } from 'antd';
import clsx from 'clsx';

import { ISearchProps } from './constants/types';

import styles from './Search.module.scss';

const { Search: AntSearch } = Input;

const Search = forwardRef(
  (
    { className = '', placeholder, loading = false, onSearch, size = 'large', label, ...props }: Partial<ISearchProps>,
    ref: ForwardedRef<InputRef>
  ) => {
    return (
      <>
        {label && <label>{label}</label>}
        <div className={clsx(className, styles.searchInput, { [styles.searchInputWithLabel]: label })}>
          <AntSearch ref={ref} placeholder={placeholder} loading={loading} onSearch={onSearch} size={size} {...props} />
        </div>
      </>
    );
  }
);

export default Search;
