import clsx from 'clsx';

import { SUBJECT } from 'components/main-layout/components/header/components/header-search/constants/constants';

import { MainSearchDropdown } from '../../hoc-instantce/instance';

import styles from './HeaderSearch.module.scss';

const HeaderSearch = ({
  filterControl,
  handleOverlayState,
  isOverlayOpen,
  getValues,
  setGetCustomersFilter,
  setGetCarrierFilter,
  setGetLoadsFilter,
  customersData,
  carriersData,
  loadsData,
  handleRowClick,
  setValue,
  isFetching,
}: any) => {
  const headerSelect = getValues('header_select');

  const combinedData = {
    customers: { result: customersData?.result, filter: setGetCustomersFilter, count: customersData?.count },
    carriers: { result: carriersData?.result, filter: setGetCarrierFilter, count: carriersData?.count },
    loads: { result: loadsData?.result, filter: setGetLoadsFilter, count: loadsData?.count },
  };
  type combineType = keyof typeof combinedData;

  return (
    <>
      <div
        className={clsx(styles.headerSearch, {
          [styles.headerSearchLong]: isOverlayOpen,
        })}
        onClick={() => handleOverlayState(true)}
      >
        <MainSearchDropdown
          setValue={setValue}
          name="header_input"
          isFetching={isFetching}
          control={filterControl}
          rules={{ required: true }}
          placeholder={SUBJECT.PLACEHOLDER}
          withButton
          options={combinedData[headerSelect as combineType]?.result}
          setOffset={combinedData[headerSelect as combineType]?.filter}
          showKey="title"
          headerFilter={headerSelect}
          offset={combinedData[headerSelect as combineType]?.count}
          handleRowClick={({ value, id, type }: any) => handleRowClick({ value, id, type })}
          value={getValues('header_input')}
        />
      </div>
    </>
  );
};

export default HeaderSearch;
