import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { editPermissionsNameSerializer, editPermissionsSerializer } from './deserializers';
import { ChangeStatusRequest, IEditRequest } from './interface';
import { getPermissionGroupByIdSerializer } from './serializers';

export const permissionAPI = createApi({
  reducerPath: 'permissionAPI',
  baseQuery,
  tagTypes: ['Permission'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    createPermissionGroup: builder.mutation({
      query: ({ name }: { name: string }) => ({
        url: `permissions`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['Permission'],
    }),
    editPermissionGroup: builder.mutation({
      query: ({ id, data, defaultPermissions, mode }: IEditRequest) => {
        return {
          url: `permissions/${id}`,
          method: 'PUT',
          body:
            mode === 'editName'
              ? editPermissionsNameSerializer(data, defaultPermissions)
              : editPermissionsSerializer(data, defaultPermissions),
        };
      },

      invalidatesTags: ['Permission'],
    }),
    changeStatus: builder.mutation({
      query: (body: ChangeStatusRequest) => ({
        url: `permissions/${body.id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['Permission'],
    }),
    getPermissionGroups: builder.query({
      query: () => ({
        url: 'permissions',
      }),
      providesTags: ['Permission'],
    }),
    getPermissionGroupById: builder.query({
      query: (id: number | string) => {
        return {
          url: `permissions/${id}`,
        };
      },
      transformResponse: body => getPermissionGroupByIdSerializer(body),
      providesTags: ['Permission'],
    }),
    getPermissionActiveGroups: builder.query({
      query: () => ({
        url: 'permissions/actives',
      }),
      providesTags: ['Permission'],
    }),
  }),
});

export const {
  useChangeStatusMutation,
  useCreatePermissionGroupMutation,
  useEditPermissionGroupMutation,
  useGetPermissionGroupByIdQuery,
  useLazyGetPermissionGroupByIdQuery,
  useGetPermissionGroupsQuery,
  useGetPermissionActiveGroupsQuery,
} = permissionAPI;
