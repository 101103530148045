import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import { Col, Divider, PaginationProps, Popover, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { LoadTypes } from 'pages/loads/constants/constants';
import { ILoadFileTypes } from 'pages/loads/tabs/loads-tab/constants/types';
import { IRequestStatusText, RequestStatus, RequestStatusText } from 'services/loads/interface';
import { PaymentStatusTypes } from 'services/payroll/payment/types';
import { ICarrierStatusForCarrier } from 'services/profile/carriers/interfaces';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import Badge from 'ui/bagde/Badge';
import Button from 'ui/button/Button';
import Input from 'ui/inputs/input/Input';
import Switch from 'ui/inputs/switch/Switch';
import { CustomerStatus } from 'ui/load-status/constants/types';
import LoadStatus from 'ui/load-status/LoadStatus';
import TimerUp from 'ui/Time/TimeUp';
import Typography from 'ui/typography/Typography';
import { AgentTypesText, EmployeeLevels, UserTypes } from 'utils/constants';
import { formatDate, formatDateBirth } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import { ArrowSvg } from 'components/svgs/ArrowSvg';
import CopyIcon from 'components/svgs/CopyIcon';
import HeartIcon from 'components/svgs/HeartIcon';
import HeartIconBordered from 'components/svgs/HeartIconBordered';
import PoligonDownIcon from 'components/svgs/PoligonDownIcon';
import PoligonUpIcon from 'components/svgs/PoligonUpIcon';
import { VectorLeft } from 'components/svgs/VectorLeft';

import ActivityIcon from '../svgs/ActivityIcon';
import DownloadIcon from '../svgs/DownloadIcon';

import Menu from './components/MenuItem/Menu';
import RateCellComponent from './components/RateTable/RateTable';
import { StatusTable } from './components/status-table/StatusTable';
import TableAging from './components/table-aging/TableAging';
import {
  BADGE_STATUS_TYPE,
  buttonTypes,
  CARRIER_STATUS,
  LoadRequestStatus,
  SORTING,
  SUBJECT,
  TAG_NAME,
} from './constants/constants';

import styles from './Table.module.scss';

const useTable = ({
  onSort,
  onResetPassword,
  columns,
  onRateChange,
  onBadgeClick,
  onResizeStop,
  // onGetRatings,
  // rateList,
  onSwitchClick,
  onColumnSearch,
  onDownloadClick,
  withColumnSearch = true,
  withMenu = true,
  handleRowEdit,
  handleRowDelete,
  onFavoriteClick,
  onCommentClick,
  onActionButton,
  onActionUnderline,
  onStatusClick,
  onRowPayClick,
  onDistributeClick,
  onRowClick,
  onSendInvoice,
  onReceivedInvoice,
  onReceivePayableInvoice,
  hasTerminateOnPermission,
  hasTerminateOffPermission,
  hasEditPermission,
  hasDeletePermission,
  hasUnlockPermission,
  hasApprovePermission,
  hasDenyPermission,
  hasCreditActionsPermissions,
  onPaymentPlanClick,
  hasPermissionToPay,
  hasPermissionToApprove,
  hasPermissionToAction,
  hasPermissionToReceiveInvoice,
}: any) => {
  const [inputCellValue, setInputCellValue] = useState<{
    id: number | string;
    value: string;
  }>({ id: '', value: '' });
  const [isInputCell, setIsInputCell] = useState<string[]>([]);
  const [sortingColumns, setSortingColumn] = useState<{
    dataIndex: string;
    sorting: number;
  }>();

  const userInfo = useSelector(selectUserInfo);

  const { employeeLevel, userType, agentType } = useSelector(selectUserPermissionsInfo);

  const payrollPermissions = usePermittedActions('payroll');

  const { searchParams, setSearchParams } = useDetectedParams();
  const { activePage, open, card } = searchParams;

  const [resizableColumns, setResizableColumns] = useState<any>([]);
  const [isResizeTagTouched, setIsResizeTagTouched] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState('');

  const ref = useOutsideDetect<HTMLDivElement>(() => setIsInputCell([]));
  const { copiedText, handleCopy } = useHandleCopyActive();

  useEffect(() => {
    setResizableColumns(columns);
  }, [columns]);

  const onHeaderCellClick = useCallback(
    (id: any) => {
      setIsInputCell((prev: string[]) => {
        if (prev && prev.includes(id)) {
          return prev.filter(cell => cell !== id);
        } else {
          return [id];
        }
      });
      if (isInputCell.includes(id) && !inputCellValue.value) {
        onColumnSearch('');
      }
    },
    [onColumnSearch]
  );
  const handleChangeInputValue = useCallback(
    (id: number | string, { target: { value } }: any) => {
      setInputCellValue({ id, value });
    },
    [onColumnSearch]
  );

  useEffect(() => {
    const debounce = setTimeout(() => {
      onColumnSearch && onColumnSearch(inputCellValue?.value, inputCellValue?.id);
    }, 300);
    return () => clearTimeout(debounce);
  }, [inputCellValue?.value?.length]);

  useEffect(() => {
    // reset column search after change active tab
    if (activePage || card) {
      setInputCellValue({ id: '', value: '' });
      setSortingColumn({ dataIndex: '', sorting: SORTING.DEFAULT as number });
    }
  }, [activePage, card]);

  const handleSortColumn = useCallback(
    (e: any, dataIndex: string) => {
      e.stopPropagation();
      if (onSort(SORTING.DESC, dataIndex) === 'unSort') {
        return;
      }
      if (sortingColumns) {
        if (sortingColumns.sorting === SORTING.ASC) {
          onSort(SORTING.DESC, dataIndex);
          setSortingColumn({ dataIndex, sorting: SORTING.DESC as number });
        } else if (sortingColumns.sorting === SORTING.DESC) {
          onSort(SORTING.DEFAULT, dataIndex);
          setSortingColumn({ dataIndex, sorting: SORTING.DEFAULT as number });
        } else if (sortingColumns.sorting === SORTING.DEFAULT) {
          onSort(SORTING.ASC, dataIndex);
          setSortingColumn({ dataIndex, sorting: SORTING.ASC as number });
        }
      } else {
        onSort(SORTING.ASC, dataIndex);
        setSortingColumn({ dataIndex, sorting: SORTING.ASC as number });
      }
    },
    [onSort, sortingColumns]
  );

  ////////////////

  const TextComponent = memo(({ col, rowData }: any) => {
    if (Array.isArray(col)) return null;
    return (
      <span
        id={`child_${col}`}
        className={clsx(styles.RowTextType, {
          [styles.negativeText]: String(col)?.[0] === '-',
          [styles.claimed]: rowData.claimed,
        })}
      >
        {col}
      </span>
    );
  });

  const TextComponentWithCopyIcon = memo(({ col, rowData }: any) => {
    if (col) {
      return (
        <Col span={13}>
          <div
            className={clsx(styles.copyIconText, {
              [styles.copyActive]: copiedText === col,
            })}
          >
            <span
              id={`child_${col}${rowData?.id}`}
              className={clsx(styles.RowTextType, {
                [styles.negativeText]: String(col)?.[0] === '-',
                [styles.claimed]: rowData.claimed,
              })}
            >
              {col}
            </span>
            <div
              className={styles.copyIcon}
              role="button"
              onClick={(e: any) => {
                e.stopPropagation();
                handleCopy(String(col || ''), col);
              }}
            >
              <CopyIcon active={copiedText === col} />
            </div>
          </div>
        </Col>
      );
    } else {
      return null;
    }
  });

  const AmountComponent = memo(({ col, rowData }: any) => {
    return (
      <span
        id={`child_${col}`}
        className={clsx(styles.RowTextType, {
          [styles.negativeText]: String(col)?.[0] === '-',
          [styles.claimed]: rowData.claimed,
        })}
      >
        {formatNumberWithThousandsSeparatorAndDecimal(col, true)}
      </span>
    );
  });

  const AmountWithPercentComponent = memo(({ col, rowData }: any) => {
    if (col) {
      return (
        <span
          id={`child_${col}`}
          className={clsx(styles.RowTextType, {
            [styles.negativeText]: String(col.amount)?.[0] === '-',
            [styles.claimed]: rowData.claimed,
          })}
        >
          {`${formatNumberWithThousandsSeparatorAndDecimal(isNaN(col) ? col?.amount : col, isNaN(col))} ${
            isNaN(col) ? `(${col?.percent}%)` : '%'
          }`}
        </span>
      );
    }
    return null;
  });

  const DateComponent = memo(({ col, rowData }: any) => {
    if (!col) return null;
    return (
      <Typography
        variant="paragraph"
        className={clsx(styles.RowTextType, {
          [styles.negativeText]: String(col)?.[0] === '-',
          [styles.claimed]: rowData.claimed,
        })}
      >
        {formatDate(col)}
      </Typography>
    );
  });

  const DOBComponent = memo(({ col }: any) => {
    return col ? (
      <Typography variant="paragraph" className={clsx(styles.RowTextType)}>
        {formatDateBirth(col)}
      </Typography>
    ) : null;
  });

  const ArrayOfStringsComponent = memo(({ col, rowData }: any) => {
    return (
      <div className={styles.tooltipItemWrapper}>
        <Tooltip
          overlay={
            <div onClick={e => e.stopPropagation()}>
              {Array.isArray(col) &&
                col?.map(el => {
                  return (
                    <React.Fragment key={el?.id}>
                      <div
                        className={styles.tooltipMainItem}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                          gap: '20px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            gap: '20px',
                          }}
                        >
                          <Typography variant="paragraph" className={styles.phoneNameInArray} children="First Name:" />
                          <Typography
                            variant="paragraph"
                            className={styles.phoneNameInArray}
                            children={el?.firstName}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '20px',
                          }}
                        >
                          <Typography variant="paragraph" className={styles.phoneNameInArray} children="Last Name:" />
                          <Typography variant="paragraph" className={styles.phoneNameInArray} children={el?.lastName} />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '20px',
                          }}
                        >
                          <Typography variant="paragraph" className={styles.phoneNameInArray} children="Phone:" />
                          <a className={styles.phoneLinkInArray} href={`tel:${el?.phone}`}>
                            {formatPhone(el?.phone)}
                          </a>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          }
          placement="top"
          color="#ffffff"
        >
          <Row
            className="test"
            style={{
              flexWrap: 'nowrap',
              width: 'fit-content',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
            onClick={e => {
              e.stopPropagation();
              onRowClick(rowData.id, rowData);
            }}
          >
            {Array.isArray(col) && col.length >= 2 ? (
              <Col>
                <a>{`+${col.length}`}</a>
              </Col>
            ) : (
              Array.isArray(col) &&
              col.length === 1 && (
                <Row
                  style={{
                    flexWrap: 'nowrap',
                    width: 'fit-content',
                    maxWidth: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <>
                    <a className={styles.phoneLinkInArray} href={`tel:${col[0]?.phone}`}>
                      {formatPhone(col[0]?.phone)}
                    </a>
                    <Typography variant="paragraph" className={styles.truncString}>
                      {`${col[0].firstName} ${col[0].lastName}`}
                    </Typography>
                  </>
                </Row>
              )
            )}
          </Row>
        </Tooltip>
      </div>
    );
  });

  const ArrayOfNamesComponent = memo(({ col, rowData }: any) => {
    return (
      <Row
        onClick={e => {
          e.stopPropagation();
          onRowClick(rowData.id, rowData);
        }}
      >
        {Array.isArray(col) &&
          col.map(
            (el, index) =>
              index < 2 && (
                <Col key={index}>
                  <Row>
                    <Typography variant="paragraph" className={styles.phoneNameInArray}>
                      {`${el.name}`}
                    </Typography>
                    {index !== col?.length - 1 && <span style={{ marginRight: '2px' }}>,</span>}
                  </Row>
                </Col>
              )
          )}
        {Array.isArray(col) && col.length > 2 && (
          <Popover
            placement="top"
            content={
              rowData?.isFromToLarge ? (
                <div className={styles.stateWrapperList}>
                  <Row gutter={8}>
                    {col.slice(2)?.map((el, index) => (
                      <Col key={index}>
                        <Typography variant="paragraph">
                          <span>{el?.name}</span>
                        </Typography>
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <>
                  {col.slice(2).map((el, index) => (
                    <Col key={index}>
                      <Row>
                        <Typography
                          variant="paragraph"
                          className={clsx(styles.phoneNameInArray, {
                            [styles.isFromToLarge]: rowData?.isFromToLarge,
                          })}
                        >
                          <span>{`${el.name}`}</span>
                        </Typography>
                      </Row>
                    </Col>
                  ))}
                </>
              )
            }
          >
            <a>{`+${col.length - 2}`}</a>
          </Popover>
        )}
      </Row>
    );
  });

  const SwitcherComponent = memo(({ col, rowData }: any) => {
    const checkedValue = !col;
    return rowData.terminate_denied ? (
      <span />
    ) : (
      <div className={styles.tableSwitcher}>
        <Switch
          checked={checkedValue || false}
          onChange={checked => onSwitchClick(checked, rowData.id, rowData)}
          onClick={(_, event: any) => event.stopPropagation()}
          disabled={(checkedValue && !hasTerminateOffPermission) || (!checkedValue && !hasTerminateOnPermission)}
        />
      </div>
    );
  });

  const WithFavoriteComponent = memo(({ rowData }: any) => {
    return (
      <Row align="middle">
        <Col
          onClick={e => {
            e.stopPropagation();
            onFavoriteClick(rowData.carrierId);
          }}
          className={styles.favoriteIconWrapper}
        >
          {rowData.isFavorite ? <HeartIcon /> : <HeartIconBordered />}
        </Col>
      </Row>
    );
  });

  const DownloadComponent = memo(({ rowData }: any) => {
    return (
      <div
        onClick={(e: any) => {
          e.stopPropagation();
          if (!rowData.claimed) {
            onDownloadClick(rowData.id);
          }
        }}
      >
        {!!rowData?.file?.length && <DownloadIcon />}
      </div>
    );
  });

  const StatusComponent = memo(({ rowData }: any) => {
    return rowData.verifiedAt ? (
      <Typography variant="paragraph" className={styles.statusText}>
        Accepted
      </Typography>
    ) : (
      <div className={styles.agentInvite}>
        <Button
          size="small"
          variant="text"
          onClick={e => {
            e.stopPropagation();
            onStatusClick(rowData.id, rowData);
          }}
          children="Invite again"
        />
      </div>
    );
  });

  const ResetPasswordComponent = memo(({ rowData }: any) => {
    return (
      <div className={styles.tableHover}>
        <div
          className={styles.agentInvite}
          onClick={e => {
            e.stopPropagation();
            if (rowData.verifiedAt) {
              onResetPassword(rowData.email);
            }
          }}
        >
          <Button size="small" variant="text" children={rowData?.reset_password} />
        </div>
      </div>
    );
  });

  const CommentsComponent = memo(({ rowData }: any) => {
    return (
      <div
        className={clsx(styles.commentBlock, {
          [styles.commentsZero]: rowData.comments_count === 0,
        })}
        onClick={e => {
          e.stopPropagation();
          onCommentClick(rowData.id, rowData);
        }}
      >
        <Typography
          variant="paragraph"
          className={styles.commentsLink}
        >{`Comments ${rowData.comments_count}`}</Typography>
      </div>
    );
  });

  const ActionButtonComponent = memo(({ col, rowData }: any) => {
    if (rowData?.status === LoadRequestStatus.REQUEST_STATUS_TYPES_DENY) {
      return (
        <Typography variant="paragraph" className={styles.deny}>
          Denied
        </Typography>
      );
    } else if (rowData?.status === LoadRequestStatus.REQUEST_STATUS_TYPES_PENDING) {
      return null;
    } else if (
      typeof col === 'number' &&
      rowData?.status ===
        LoadRequestStatus.REQUEST_STATUS_TYPES_APPROVE /*which in this case (carrier request) is pending*/ &&
      !hasApprovePermission &&
      !hasDenyPermission
    ) {
      return (
        <Typography variant="paragraph" className={styles.pending}>
          Pending
        </Typography>
      );
    } else if (rowData.loadType === Number(LoadTypes.CONNECTING_LOAD)) {
      return (
        <Typography variant="paragraph" className={styles.pending}>
          Connecting
        </Typography>
      );
    } else if (rowData?.creatorId && userInfo?.id !== rowData?.creatorId) {
      return null;
    } else {
      return (
        <div className={styles.addLoadBtn}>
          <Button
            onClick={e => {
              e.stopPropagation();
              onActionButton(rowData);
            }}
            variant="comment"
            size="small"
            children={typeof col === 'string' ? col : 'All Actions'}
          />
        </div>
      );
    }
  });

  const ActionRequestCarrierComponent = memo(({ col, rowData }: any) => {
    if (rowData?.status === ICarrierStatusForCarrier.Denied) {
      return (
        <Typography variant="paragraph" className={styles.deny}>
          Denied
        </Typography>
      );
    } else {
      return (
        <div className={styles.addLoadBtn}>
          <Button
            onClick={e => {
              e.stopPropagation();
              onActionButton(rowData);
            }}
            variant="comment"
            size="small"
            children={'All Actions'}
          />
        </div>
      );
    }
  });

  const ActionUnderlineComponent = memo(({ col, rowData }: any) => {
    return (
      <div
        className={styles.commentBlock}
        onClick={e => {
          e.stopPropagation();
          if (hasUnlockPermission) {
            onActionUnderline(rowData.id, rowData);
          }
        }}
      >
        <Typography variant="paragraph" className={styles.unlock}>
          {col}
        </Typography>
      </div>
    );
  });

  const TelephoneComponent = memo(({ col }: any) => {
    return (
      <Button type="link" variant="link" href={`tel:${col}`} onClick={e => e.stopPropagation()}>
        {col && col?.length > 19 ? `${col.slice(0, 18)}...` : formatPhone(col)}
      </Button>
    );
  });

  const SocketComponent = memo(({ col }: any) => {
    return (
      <div onClick={e => e.stopPropagation()}>
        <ActivityIcon active={col} />
      </div>
    );
  });

  const MenuComponent = memo(({ rowData }: any) => {
    // agent case
    if (searchParams.tab === 'agents') {
      return userType === UserTypes.ADMIN ||
        (employeeLevel === EmployeeLevels.CORPORATE && hasEditPermission) ||
        agentType < AgentTypesText[rowData?.agent_type as keyof typeof AgentTypesText] ? (
        <Menu
          handleEditClick={handleRowEdit}
          handleDeleteClick={handleRowDelete}
          rowId={rowData.id}
          rowData={rowData}
          hasEditPermission={hasEditPermission}
          hasDeletePermission={hasDeletePermission}
        />
      ) : (
        <div></div>
      );
    } else {
      return (
        <Menu
          handleEditClick={handleRowEdit}
          handleDeleteClick={handleRowDelete}
          rowId={rowData.id}
          rowData={rowData}
          hasEditPermission={hasEditPermission}
          hasDeletePermission={hasDeletePermission}
        />
      );
    }
  });

  const PaymentPlan = memo(({ rowData }: any) => {
    return (
      <div onClick={e => e.stopPropagation()} className={styles.paymentPlanButtons}>
        <Button
          variant="default"
          children={SUBJECT.PAYMENT_PLAN}
          onClick={() => {
            onPaymentPlanClick(rowData.id, rowData);
          }}
          disabled={
            !(
              payrollPermissions?.payrollCollection?.givePaymentPlan ||
              payrollPermissions?.payrollCollection?.editPaymentPlan
            )
          }
        />
      </div>
    );
  });

  const ChargeBackComponent = memo(({ col, rowData }: any) => {
    return (
      <div className={styles.chargeBack}>
        <Typography
          variant="paragraph"
          className={clsx(styles.RowTextType, {
            [styles.negativeText]: String(col?.summary)?.[0] === '-',
            [styles.claimed]: rowData.claimed,
          })}
        >
          {col?.summary}
        </Typography>

        <Typography
          variant="paragraph"
          className={clsx(styles.RowTextType, {
            [styles.claimed]: rowData.claimed,
          })}
        >
          {col?.percent}
        </Typography>
      </div>
    );
  });

  const PayButtonComponent = memo(({ col, rowData }: any) => {
    if (col === PaymentStatusTypes.PAYMENT_STATUS_PENDING) {
      const isPayButton = Number(rowData.final) > 0;
      if (hasPermissionToAction) {
        return (
          <Tooltip
            color="white"
            title={
              isPayButton && !hasPermissionToPay
                ? SUBJECT.NO_PERMISSION_PAY
                : !isPayButton && !hasPermissionToApprove
                ? SUBJECT.NO_PERMISSION_APPROVE
                : ''
            }
            placement="top"
          >
            <div className={styles.tableButtonsWrapper}>
              <Button
                size="small"
                className={styles.payButtonComponent}
                variant={isPayButton ? SUBJECT.DEFAULT : SUBJECT.TABLE_DARK}
                children={isPayButton ? SUBJECT.PAY : SUBJECT.APPROVE}
                disabled={(isPayButton && !hasPermissionToPay) || (!isPayButton && !hasPermissionToApprove)}
                onClick={e => {
                  e.stopPropagation();
                  onRowPayClick(rowData);
                }}
              />
            </div>
          </Tooltip>
        );
      } else {
        return (
          <Typography
            variant="paragraph"
            className={clsx(styles.RowTextType, {
              [styles.claimed]: rowData.claimed,
            })}
          >
            {SUBJECT.PENDING}
          </Typography>
        );
      }
    } else {
      return (
        <Typography
          variant="paragraph"
          className={clsx(styles.RowTextType, {
            [styles.claimed]: rowData.claimed,
          })}
        >
          {col === PaymentStatusTypes.PAYMENT_STATUS_APPROVE ? SUBJECT.APPROVED : SUBJECT.PAID}
        </Typography>
      );
    }
  });
  const PayablesInvoiceButton = memo(({ col, rowData }: any) => {
    if (!col) {
      return (
        <Tooltip
          color="white"
          title={
            !hasPermissionToReceiveInvoice
              ? SUBJECT.NO_PERMISSION_RECEIVE
              : rowData?.loadStatus?.carrierStatus == 2
              ? SUBJECT.ALREADY_PAID
              : ''
          }
          placement="top"
        >
          <div className={styles.tableButtonsWrapper}>
            <Button
              size="small"
              className={styles.payButtonComponent}
              variant={SUBJECT.DEFAULT}
              children={SUBJECT.RECEIVE_INVOICE}
              disabled={rowData?.loadStatus?.carrierStatus == 2 || !hasPermissionToReceiveInvoice}
              onClick={e => {
                e.stopPropagation();
                onReceivePayableInvoice(rowData);
              }}
            />
          </div>
        </Tooltip>
      );
    }
    return <DateComponent col={col} rowData={rowData} />;
  });

  const DistributeComponent = memo(({ col, rowData }: any) => {
    return col === 'Distributed' ? (
      <Typography
        variant="paragraph"
        className={clsx(styles.RowTextType, {
          [styles.claimed]: rowData.claimed,
        })}
      >
        {col}
      </Typography>
    ) : (
      <Row>
        <Col span={6}>
          <Button
            variant="default"
            children={col}
            onClick={e => {
              e.stopPropagation();
              onDistributeClick(rowData);
            }}
          />
        </Col>
      </Row>
    );
  });

  const TextWithDownloadComponent = memo(({ col, rowData, colId }: any) => {
    return (
      <div
        className={clsx(styles.documentBlock, {
          [styles.claimed]: rowData?.claimed,
        })}
      >
        {!!rowData[ILoadFileTypes[colId as keyof typeof ILoadFileTypes]]?.length &&
          (col === SUBJECT.RATE_CONFIRMATION_COL || col === SUBJECT.POD || rowData?.dataType === 'payables') && (
            <>
              <Typography
                variant="paragraph"
                className={clsx(styles.RowTextType, {
                  [styles.claimed]: rowData.claimed,
                })}
              >
                {rowData?.dataType !== 'payables' ? col : 'Rate Confirmation'}
              </Typography>

              <div
                onClick={e => {
                  e.stopPropagation();
                  onDownloadClick(rowData[ILoadFileTypes[colId as keyof typeof ILoadFileTypes]], rowData?.id);
                }}
              >
                <DownloadIcon />
              </div>
            </>
          )}
        {!!rowData[ILoadFileTypes[colId as keyof typeof ILoadFileTypes]]?.length &&
          rowData?.invoice &&
          col != SUBJECT.RATE_CONFIRMATION_COL &&
          col != SUBJECT.POD &&
          rowData?.dataType !== 'payables' && (
            <>
              <Typography
                variant="paragraph"
                className={clsx(styles.RowTextType, {
                  [styles.claimed]: rowData.claimed,
                })}
              >
                {colId === 'carrierPayment' ? rowData?.carrierBackDownDate : rowData?.invoice}
              </Typography>

              <div
                onClick={e => {
                  e.stopPropagation();
                  onDownloadClick(rowData[ILoadFileTypes[colId as keyof typeof ILoadFileTypes]], rowData?.id);
                }}
              >
                <DownloadIcon />
              </div>
            </>
          )}
      </div>
    );
  });

  const LoadStatusComponent = memo(({ rowData }: any) => {
    return (
      <div
        className={clsx({
          [styles.claimed]: rowData.claimed,
        })}
      >
        <LoadStatus
          loadStatus={rowData?.loadStatus?.status}
          carrierStatus={rowData?.loadStatus?.carrierStatus}
          customerStatus={rowData?.loadStatus?.customerStatus}
          isProcessing={rowData?.loadStatus?.processing}
          withoutBackground
          withoutLoadsText
        />
      </div>
    );
  });

  const CommentTooltipComponent = memo(({ col, rowData, column }: any) => {
    const [width, setWidth] = useState(0);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const lastCommentWidth = ref.current?.getBoundingClientRect().width || 0;
      if (column?.width - 17 < lastCommentWidth) {
        setWidth(lastCommentWidth);
      }
    }, [ref, column]);

    return col?.length ? (
      <div className={styles.tooltipItemWrapper}>
        <Tooltip
          overlay={width ? <div onClick={e => e.stopPropagation()}>{col}</div> : null}
          placement="top"
          color="white"
          key={rowData.id}
        >
          <div className={styles.commentText} ref={ref}>
            {col}
          </div>
        </Tooltip>
      </div>
    ) : null;
  });

  const StatusActionComponent = memo(({ col, rowData }: any) => {
    return Number(col) === 1 && hasCreditActionsPermissions ? (
      <div className={styles.allActionsButton}>
        <Button
          onClick={e => {
            e.stopPropagation();
            onActionButton(rowData);
          }}
          variant="comment"
          children="All actions"
        />
      </div>
    ) : (
      <div className={styles.statusAction}>
        <div
          className={clsx(styles.pending, {
            [styles.approved]: RequestStatus[col] === 'Approve',
            [styles.consignment]: RequestStatus[col] === 'Consignment',
            [styles.cod]: RequestStatus[col] === 'COD',
            [styles.denied]: RequestStatus[col] === 'Deny',
          })}
        />
        <Typography variant="paragraph">{RequestStatusText[RequestStatus[col] as keyof IRequestStatusText]}</Typography>
      </div>
    );
  });

  const NewLoadStatusComponent = memo(({ col }: any) => {
    return (
      <div className={styles.statusAction}>
        <div
          className={clsx(styles.pending, {
            [styles.approved]: RequestStatus[col] === 'Approve',
            [styles.consignment]: RequestStatus[col] === 'Consignment',
            [styles.cod]: RequestStatus[col] === 'COD',
            [styles.denied]: RequestStatus[col] === 'Deny',
          })}
        />
        <Typography variant="paragraph">{RequestStatusText[RequestStatus[col] as keyof IRequestStatusText]}</Typography>
      </div>
    );
  });

  const PaymentMethodComponent = memo(({ col }: any) => {
    return (
      <Row align="middle" gutter={[10, 0]}>
        <Col>
          <Typography variant="paragraph">{col?.type}</Typography>
        </Col>
        <Col>
          <Divider type="vertical" />
        </Col>
        <Col>
          <Typography variant="paragraph">{`${col?.percent}%`}</Typography>
        </Col>
        <Col>
          <Divider type="vertical" />
        </Col>
        <Col>
          <Typography variant="paragraph">{col?.days + ' '} day</Typography>
        </Col>
      </Row>
    );
  });

  const AgingComponent = memo(({ col, rowData }: any) => {
    return (
      <div
        className={clsx({
          [styles.claimed]: rowData.claimed,
        })}
      >
        <TableAging data={rowData} day={col} />
      </div>
    );
  });

  const MarkAsClaimComponent = memo(({ col }: any) => {
    return (
      <Row align="middle">
        <Typography variant="paragraph"> {col}</Typography>
        <StatusTable />
      </Row>
    );
  });

  const TruckBoardStatusComponent = memo(({ rowData }: any) => {
    return rowData?.new ? (
      <Row align="middle" justify="start">
        <Col span={24}>
          <Badge
            type={BADGE_STATUS_TYPE[3]}
            text="New"
            helperText={`${rowData.truckCount}`}
            direction="right"
            {...(onBadgeClick
              ? {
                  onBadgeClick: (e: any) => {
                    e.stopPropagation();
                    onBadgeClick(rowData?.id);
                  },
                }
              : {})}
          />
        </Col>
      </Row>
    ) : (
      <TextComponent col={rowData?.truckCount} rowData={rowData} />
    );
  });

  const TruckBoardNewTruckComponent = memo(({ rowData }: any) => {
    return (
      <Row align="middle" justify="start">
        {!!rowData?.new && (
          <Col span={24}>
            <Badge
              type={BADGE_STATUS_TYPE[3]}
              text="New"
              direction="right"
              {...(onBadgeClick
                ? {
                    onBadgeClick: (e: any) => {
                      e.stopPropagation();
                      onBadgeClick(rowData?.id);
                    },
                  }
                : {})}
            />
          </Col>
        )}
      </Row>
    );
  });

  const CarrierStatusComponent = memo(({ rowData }: any) => {
    return (
      <Row align="middle" justify="start">
        <Col span={24}>
          <Badge
            type={BADGE_STATUS_TYPE[rowData.carrierStatus]}
            text={CARRIER_STATUS[rowData.carrierStatus]}
            helperText={`By ${rowData.changedBy}`}
          />
        </Col>
      </Row>
    );
  });

  const RetrievedComponent = memo(({ col, rowData }: any) => {
    return col ? (
      <Row
        align="middle"
        className={clsx(styles.retrieved, {
          [styles.claimed]: rowData?.claimed,
        })}
      >
        <VectorLeft />
        <Typography
          variant="paragraph"
          className={styles.RowTextType}
          children={
            rowData?.claimed
              ? SUBJECT.CLAIMED
              : rowData.byPaymentTerm
              ? SUBJECT.BY_PAYMENT_TERM
              : rowData?.byClaim
              ? SUBJECT.RESOLVED
              : SUBJECT.RETRIEVED
          }
        />
      </Row>
    ) : null;
  });

  const Timer = memo(({ col, rowData }: any) => {
    const timeForCount = !isNaN(col) ? col : rowData?.timer;
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timeForCount < 0 ? 0 : currentTime - timeForCount;

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDifference / 1000) % 60);
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return <TimerUp initialTime={formattedTime} danger={hours >= 24} warning={hours > 2 && hours < 24} />;
  });

  const SendInvoiceComponent = memo(({ col, rowData }: any) => {
    return col === CustomerStatus.CUSTOMER_STATUS_PAID ? (
      <Typography children="Invoice sent" variant="paragraph" />
    ) : (
      <Button
        size="small"
        variant="default"
        disabled={rowData?.claimed}
        onClick={e => {
          e.stopPropagation();
          onSendInvoice(rowData.id);
        }}
        children="Send invoice"
      />
    );
  });

  const ReceivedInvoiceComponent = memo(({ rowData }: any) => {
    return (
      <Button
        size="small"
        variant="default"
        onClick={e => {
          e.stopPropagation();
          onReceivedInvoice(rowData.id);
        }}
        children="Pending Verification"
        disabled={!!rowData.invoiceReceivedAt || rowData?.claimed}
      />
    );
  });

  const ButtonComponent = memo(({ dataIndex, rowData, ...props }: any) => {
    const currentButton = buttonTypes[dataIndex];

    const permission: any = {
      Edit: {
        Edit: hasEditPermission,
        text: SUBJECT.NO_PERMISSION_EDIT,
      },
      Delete: {
        Delete: hasDeletePermission,
        text: SUBJECT.NO_PERMISSION_DELETE,
      },
    };

    const handleClick = () => {
      const onClickHandlerName = currentButton?.onClick;
      if (typeof onClickHandlerName === 'string') {
        const onClickHandler = props[onClickHandlerName];
        if (typeof onClickHandler === 'function') {
          onClickHandler(rowData.id);
        }
      }
    };

    return (
      <Tooltip
        color="white"
        title={
          !permission[currentButton?.children][currentButton?.children] && permission[currentButton?.children].text
        }
        placement="top"
      >
        <span>
          <Button
            size={currentButton?.size}
            variant={currentButton?.variant}
            onClick={e => {
              e.stopPropagation();
              handleClick();
            }}
            children={currentButton?.children}
            disabled={!permission[currentButton?.children][currentButton?.children]}
          />
        </span>
      </Tooltip>
    );
  });

  const renderRows = useMemo(() => {
    return (type: string, col: string | object | number, rowData: any, column: any) => {
      const rows: any = {
        text: <TextComponent col={col} rowData={rowData} column={column.dataIndex} />,
        textWithCopyIcon: <TextComponentWithCopyIcon col={col} rowData={rowData} column={column.dataIndex} />,
        amount: <AmountComponent col={col} rowData={rowData} column={column.dataIndex} />,
        amountWithPercent: <AmountWithPercentComponent col={col} rowData={rowData} column={column.dataIndex} />,
        date: <DateComponent col={col} rowData={rowData} />,
        dateOfBirth: <DOBComponent col={col} />,
        arrayOfStrings: <ArrayOfStringsComponent col={col} rowData={rowData} />,
        arrayOfNames: <ArrayOfNamesComponent col={col} rowData={rowData} />,
        rate: <RateCellComponent col={col} rowData={rowData} />,
        switcher: <SwitcherComponent col={col} rowData={rowData} />,
        favorite: <WithFavoriteComponent col={col} rowData={rowData} />,
        download: <DownloadComponent rowData={rowData} />,
        status: <StatusComponent rowData={rowData} />,
        resetPassword: <ResetPasswordComponent rowData={rowData} />,
        comments: <CommentsComponent rowData={rowData} />,
        actionButton: <ActionButtonComponent col={col} rowData={rowData} />,
        actionUnderline: <ActionUnderlineComponent col={col} rowData={rowData} />,
        tel: <TelephoneComponent col={col} />,
        socket: <SocketComponent col={col} />,
        menu: <MenuComponent rowData={rowData} />,
        chargeBack: <ChargeBackComponent col={col} rowData={rowData} />,
        payButton: <PayButtonComponent col={col} rowData={rowData} />,
        payableInvoiceButton: <PayablesInvoiceButton col={col} rowData={rowData} />,
        distribute: <DistributeComponent col={col} rowData={rowData} />,
        textWithDownload: <TextWithDownloadComponent col={col} rowData={rowData} colId={column.id} />,
        loadStatus: <LoadStatusComponent col={col} rowData={rowData} />,
        commentTooltip: <CommentTooltipComponent col={col} rowData={rowData} column={column} />,
        statusActions: <StatusActionComponent col={col} rowData={rowData} />,
        paymentMethod: <PaymentMethodComponent col={col} />,
        aging: <AgingComponent col={col} rowData={rowData} />,
        markAsClaim: <MarkAsClaimComponent col={col} />,
        truckBoardTrucks: <TruckBoardStatusComponent rowData={rowData} />,
        newTruck: <TruckBoardNewTruckComponent rowData={rowData} />,
        truckBoardCarrierStatus: <CarrierStatusComponent rowData={rowData} />,
        newLoadStatus: <NewLoadStatusComponent col={col} />,
        retrieved: <RetrievedComponent col={col} rowData={rowData} />,
        timer: <Timer col={col} rowData={rowData} />,
        sendInvoice: <SendInvoiceComponent col={col} rowData={rowData} />,
        invoiceReceived: <ReceivedInvoiceComponent col={col} rowData={rowData} />,
        paymentPlan: <PaymentPlan rowData={rowData} />,
        button: (
          <ButtonComponent
            dataIndex={column?.dataIndex}
            rowData={rowData}
            handleRowDelete={handleRowDelete}
            handleRowEdit={handleRowEdit}
          />
        ),
        actionRequestCarrier: <ActionRequestCarrierComponent col={col} rowData={rowData} />,
      };

      return rows[type];
    };
  }, [
    onDownloadClick,
    onRateChange,
    onSwitchClick,
    onDistributeClick,
    activePage,
    open,
    handleRowEdit,
    isTooltipOpen,
    copiedText,
    onBadgeClick,
    hasPermissionToPay,
    hasPermissionToApprove,
    hasPermissionToAction,
  ]);

  const onMouseUp = useCallback(() => {
    if (isResizeTagTouched) {
      const timeout = setTimeout(() => {
        onResizeStop(resizableColumns);
      }, 200);
      setIsResizeTagTouched(false);
      return () => clearTimeout(timeout);
    }
  }, [isResizeTagTouched, onResizeStop, resizableColumns]);

  const renderHeaderCellTitle = useMemo(() => {
    return (column: any) =>
      isInputCell?.includes(column.id) ? (
        <div ref={ref}>
          <Input
            isInTable
            height={32}
            autoFocus
            tableSearchValue={inputCellValue.id === column.id ? inputCellValue.value : ''}
            name={column.title}
            type="text"
            placeholder={column.title}
            allowClear
            onClick={e => e.stopPropagation()}
            onChange={e => handleChangeInputValue(column.id, e)}
          />
        </div>
      ) : (
        <div className={styles.HeaderTitleWithSort}>
          <Typography className={styles.headingCellText} variant="paragraph">
            {column.title}
            {inputCellValue.id === column.id && inputCellValue.value && <span />}
          </Typography>
          {column.sortable && (
            <div className={styles.poligonWrapper} onClick={e => handleSortColumn(e, column.dataIndex)}>
              <PoligonUpIcon
                filled={
                  sortingColumns &&
                  sortingColumns.dataIndex === column.dataIndex &&
                  sortingColumns.sorting === SORTING.ASC
                }
              />
              <PoligonDownIcon
                filled={
                  sortingColumns &&
                  sortingColumns.dataIndex === column.dataIndex &&
                  sortingColumns.sorting === SORTING.DESC
                }
              />
            </div>
          )}
        </div>
      );
  }, [handleChangeInputValue, handleSortColumn, isInputCell, inputCellValue]);

  const ResizableTitle = useMemo(() => {
    return (
      props: React.HTMLAttributes<any> & {
        onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
        width: number;
      }
    ) => {
      const { onResize, width, ...restProps } = props;

      if (!width) {
        return <th {...restProps} />;
      }
      return (
        <Resizable
          width={width}
          height={0}
          handle={
            <span
              className={styles.reactResizableHandle}
              onClick={e => {
                e.stopPropagation();
              }}
            />
          }
          onResize={onResize}
          draggableOpts={{ enableUserSelectHack: false }}
        >
          <th {...restProps} />
        </Resizable>
      );
    };
  }, []);

  const handleResizeColumn = useCallback(
    (index: number) =>
      (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
        const newColumns = [...resizableColumns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width < 200 ? 200 : size.width,
        };
        setResizableColumns(newColumns);
      },
    [columns, resizableColumns]
  );
  const [hoveredColumnIndex, setHoveredColumnIndex] = useState<any>(null);

  const handleColumnMouseEnter = (index: any) => {
    if (hoveredColumnIndex !== index) setHoveredColumnIndex(index);
  };

  const handleColumnMouseLeave = () => {
    setHoveredColumnIndex(null);
  };

  const getColumnClassName = (index: any) => {
    if (index === hoveredColumnIndex && open === 'false') {
      return 'highlighted-column';
    }
    return '';
  };

  useEffect(() => {
    if (open === 'true') {
      setHoveredColumnIndex(null);
    }
  }, [open]);

  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const tableBody = document.querySelector('.ant-table-body');
    const handleScroll = () => {
      // Clear the previous timeout
      clearTimeout(timeoutId);

      // Update the scrolling state
      setIsScrolling(true);

      // Set a new timeout after scrolling stops
      timeoutId = setTimeout(() => {
        setIsScrolling(false);
      }, 200); // Adjust the timeout duration as needed
    };

    // Attach the scroll event listener when the component mounts
    tableBody!.addEventListener('scroll', handleScroll);
    tableBody!.addEventListener('mouseleave', handleColumnMouseLeave);
    // Clean up the scroll event listener and timeout when the component unmounts
    return () => {
      tableBody!.removeEventListener('scroll', handleScroll);
      tableBody!.removeEventListener('mouseleave', handleColumnMouseLeave);

      clearTimeout(timeoutId);
    };
  }, []);

  const renderColumns = useMemo(() => {
    const columnsWithMenu = withMenu
      ? Array.isArray(resizableColumns)
        ? [
            ...resizableColumns,
            {
              title: '',
              id: 'menu',
              dataIndex: 'menu',
              displayType: 'menu',
              isVisible: true,
              sortable: false,
              width: 32,
            },
          ]
        : []
      : resizableColumns;

    const customizedColumns = columnsWithMenu?.map((column: any, index: any) => ({
      ...column,
      title: renderHeaderCellTitle(column),
      className: getColumnClassName(column?.id),

      onCell: () => ({
        onMouseMove: () => {
          if (!isScrolling) handleColumnMouseEnter(column.id);
        },
        onClick: () => {
          return setIsTooltipOpen('');
        },
      }),
      onHeaderCell: (column: any) => ({
        width: column?.width,
        onResize: handleResizeColumn(index) as React.ReactEventHandler<any>,
        onClick: () => withColumnSearch && column?.isSearchable && onHeaderCellClick(column?.id),
        onMouseDown: ({ target: { tagName } }: any) => tagName === TAG_NAME.SPAN && setIsResizeTagTouched(true),
        onMouseUp: () => onMouseUp(),
      }),

      render: (col: any, rowData: any) => {
        const parentElement = document.getElementById(`${`parent_${col}`}`)?.getBoundingClientRect().width || 0;
        const childElement = document.getElementById(`child_${col}`)?.getBoundingClientRect().width || 0;

        const mainElement = document
          .getElementById(`mainElement_${col}_${rowData?.id}_${column?.id}`)
          ?.getBoundingClientRect();

        const handleTooltipOpen = () => {
          if (parentElement < childElement) {
            setIsTooltipOpen(`${col}_${rowData?.id}_${column?.id}`);
          } else {
            setIsTooltipOpen('');
          }
        };
        if (rowData?.claimed || rowData?.byPaymentTerm || rowData?.byClaim || rowData?.retrieved) {
          return (
            <div className={styles.tooltipItemWrapper}>
              <Tooltip
                placement="top"
                overlay={
                  rowData?.claimed && column?.displayType !== 'menu' ? (
                    <div onClick={e => e.stopPropagation()}>{'Claimed'}</div>
                  ) : null
                }
                color="white"
              >
                <div className={styles.customTableCell}>{renderRows(column?.displayType, col, rowData, column)}</div>
              </Tooltip>
            </div>
          );
        }
        return (
          <div
            id={`mainElement_${col}_${rowData?.id}_${column?.id}`}
            className={styles.customTableCell}
            onMouseEnter={handleTooltipOpen}
            onMouseLeave={() => setIsTooltipOpen('')}
          >
            <div
              className={clsx(styles.renderedItems, {
                [styles.withoutTooltip]:
                  column?.displayType === 'switcher' ||
                  column?.displayType === 'favorite' ||
                  parentElement > childElement,
              })}
              id={`${`parent_${col}`}`}
            >
              {renderRows(column?.displayType, col, rowData, column)}
            </div>
            {createPortal(
              <>
                {isTooltipOpen === `${col}_${rowData?.id}_${column?.id}` ? (
                  <div
                    style={{
                      position: 'fixed',
                      left: mainElement?.left,
                      top: mainElement?.top,
                    }}
                    className={styles.singleTooltip}
                    onClick={e => e.stopPropagation()}
                  >
                    <div className={styles.customArrow} />
                    <div className={styles.customContent}>
                      <div className={styles.customInner}>
                        <p>{col}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>,
              document.body
            )}
          </div>
        );
      },
    }));
    return customizedColumns;
  }, [
    handleResizeColumn,
    onHeaderCellClick,
    onMouseUp,
    renderHeaderCellTitle,
    renderRows,
    resizableColumns,
    withMenu,
    getColumnClassName,
  ]);

  const paginationArrows: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowLeft)}>
          <ArrowSvg />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowRight)}>
          <ArrowSvg />
        </a>
      );
    }
    return originalElement;
  };

  const pageSizeOptions = (total: number) => {
    const maxPageSize = 40;
    const pageSizeOptions = Array.from({ length: Math.ceil(total / 10) }, (_, i) => (i + 1) * 10).filter(
      option => option <= maxPageSize
    );

    return pageSizeOptions;
  };

  return {
    renderColumns,
    ResizableTitle,
    onMouseUp,
    paginationArrows,
    pageSizeOptions,
    searchParams,
    setSearchParams,
  };
};

export default useTable;
