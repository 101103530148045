export const BookedTypes: { [key: string]: string } = {
  0: 'BOOKED_TYPES_UNSPECIFIED',
  1: 'Full',
  2: 'Partial',
};

export enum BookedTypesEnum {
  BOOKED_TYPES_UNSPECIFIED = 0,
  Full = 1,
  Partial = 2,
}
export const RequestStatus: { [key: string]: string } = {
  0: 'REQUEST_STATUS_TYPES_UNSPECIFIED',
  1: 'Pending',
  2: 'Approve',
  3: 'Deny',
  4: 'COD',
  5: 'Consigment',
};
