import { IFilterValues } from './types';

export enum SUBJECT {
  ARE_YOU_SURE = 'Are You Sure You Want To Delete Transaction?',
  DELETE = 'Delete',
  CANCEL = 'Cancel',
  LOADS_IS_EMPTY = 'Loads Is Empty',
  SAVE = 'Save',

  RESET_ALL = 'Reset All',
  NOT_SELECTED = 'Not Selected',
  DATE = 'Date',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  AGENT = 'Agent',
  LOAD_TYPE = 'Load Type',
  DOCUMENT = 'Document',
  LOAD_STATUS = 'Load Status',
  NUMBER_OF_LOAD = 'Loads',
  LOAD_AVERAGE = 'Load Profit Average',
  GROSS_PROFIT = 'Gross Profit',
  POTENTIAL_GROSS_PROFIT = 'Potential Profits',
  LOAD_RED_CONFIRMATION = 'Loads Without Rate Confirmation',
  SEARCH = 'Search',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  DOWNLOADING_IN_PROCCESS = 'Downloading In Proccess',
}

export const FILTER_DEFAULT_VALUES: IFilterValues = {
  date: null,
  loadsOffice: [],
  loadsBranch: [],
  loadsAgent: [],
  loadsByType: [],
  document: [],
  loadStatus: [],
};

export const DATE_FILTER = [
  { title: 'Today', value: 'Today' },
  { title: 'Current Week', value: 'Current week' },
  { title: 'Current Month', value: 'Current month' },
  { title: 'Custom', value: 'Custom' },
];

export const LOAD_TYPES = [
  { title: 'Regular', value: '1' },
  { title: 'Tonu', value: '2' },
  { title: 'Connecting', value: '3' },
  { title: 'Internal Board', value: '4' },
];

export const DOCUMENT_FILTER = [
  { title: 'With Rate Confirmation', value: '1' },
  { title: 'Without Rate Confirmation', value: '2' },
  { title: 'With POD', value: '3' },
  { title: 'Without POD', value: '4' },
];

export const LOAD_STATUSES = [
  { title: 'Not Processed', value: '1' },
  { title: 'Processing', value: '2' },
  { title: 'Carrier Payment Pending', value: '3' },
  { title: 'Customer Payment Pending', value: '4' },
  { title: 'Customer Received', value: '5' },
  { title: 'Carrier Paid', value: '6' },
  { title: 'Canceled', value: '7' },
  { title: 'Claimed', value: '8' },
];
