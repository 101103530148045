interface IBillingBlockFile {
  id: number;
  url: string;
}
export interface IBillingBlockProps {
  loadType?: number;
  mailableFiles?: any[];
  loadData: any;
  paymentTermType: number;
  onRejectClick?: () => void;
  onAcceptClick?: () => void;
  onCreateInvoice?: () => void;
  role?: string;
  noa: IBillingBlockFile[] | null;
  redConfirmation: IBillingBlockFile[] | null;
  pod: IBillingBlockFile[] | null;
  invoice: IBillingBlockFile[];
  onDeleteInvoice: () => void;
  loadStatus: number;
  processing: boolean;
  hasValidFiles: boolean;
  isFetching: boolean;
  hasAcceptToBillingPermission: boolean;
  hasClaimLoadAcceptToBillingPermission: boolean;
  hasRejectBillingPermission: boolean;
  hasClaimLoadRejectPermission: boolean;
  isLoadClaimed: boolean;
  isLoadHasCustomer?: boolean;
  isInvoiceLoading?: boolean;
  isSendedToBilling?: boolean;
  isCanceledClaimed?: boolean;
}

export enum PaymentTermType {
  BILLING_TYPES_UNSPECIFIED = 0,
  BILLING_TYPES_FACTORING = 1,
  BILLING_TYPES_QUICK_PAY = 2,
  BILLING_TYPES_CUSTOM = 3,
}

export const PaymentTermTypeText = {
  2: 'Quick Pay',
  3: 'Custom (Quick Pay)',
};

export enum paymentTermTypeTextNew {
  'Quick Pay' = 1,
  '30 Day' = 2,
}

export const PaymentTermTypeForHistoryMessage = {
  1: 'Factoring',
  2: 'Quick Pay',
  3: 'Custom',
};
