import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import ResetIcon from '../../../../components/svgs/ResetIcon';
import { selectUserPermissionsInfo } from '../../../../store/user-slice/selector';
import Button from '../../../../ui/button/Button';
import { EmployeeLevels, UserTypes } from '../../../../utils/constants';

import { SUBJECT } from './constants/constants';
import { ITruckBoardCardProps } from './constants/types';
import useTruckBoardCard from './useTruckBoardCard';

import styles from './TruckBoardCard.module.scss';

const TruckBoardCard = ({
  setValue,
  state,
  onBoardCount,
  offBoardCount,
  transitCount,
  formData = [],
  formDataNames = {},
  watch,
  isDisabledButtonDependOnPerm,
}: ITruckBoardCardProps) => {
  const { handleClick, handlePopupClick, openOptions, handleMouseMove, resetCurrentSearch, isFilledCurrentBlock } =
    useTruckBoardCard({
      setValue,
      watch,
      formData,
    });

  const { userType, employeeLevel } = useSelector(selectUserPermissionsInfo);

  return (
    <div className={styles.cardController}>
      <div className={styles.cardHeader}>
        <h3 className={styles.headerTitle}>{state}</h3>
        <div className={styles.headerNumbers}>
          {!!transitCount && (employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN) && (
            <Typography variant="paragraph">
              {SUBJECT.TRANSIT_COUNT}
              <span>{transitCount}</span>
            </Typography>
          )}
          {!!onBoardCount && (
            <Typography variant="paragraph">
              {SUBJECT.ON_BOARD_COUNT}
              <span>{onBoardCount}</span>
            </Typography>
          )}
          {!!offBoardCount && (
            <Typography variant="paragraph">
              {SUBJECT.OFF_BOARD_COUNT}
              <span>{offBoardCount}</span>
            </Typography>
          )}
        </div>
      </div>
      <div className={styles.checkBoxesContainer}>
        {formData?.map((state: any) => {
          return (
            <div
              key={state}
              className={styles.checkboxWithLabelContainer}
              onMouseLeave={() => handleMouseMove('leave')}
            >
              <Tooltip placement="top" color="#fff" title={isDisabledButtonDependOnPerm ? SUBJECT.NO_PERMISSION : ''}>
                <span>
                  <input
                    className={clsx(styles.input, styles.checkbox, {
                      [styles.rightClick]: watch(state) === SUBJECT.TO,
                      [styles.bothRightClick]: watch(state) === SUBJECT.BOTH,
                      [styles.disabled]: isDisabledButtonDependOnPerm,
                    })}
                    type="checkbox"
                    onClick={handleClick}
                    onContextMenu={handleClick}
                    onDoubleClick={handleClick}
                    onMouseEnter={() => handleMouseMove('enter', state)}
                    id={state}
                    checked={!!watch(state)}
                    readOnly
                    disabled={isDisabledButtonDependOnPerm}
                  />
                </span>
              </Tooltip>
              {openOptions === state ? (
                <div className={styles.tooltipCheck}>
                  <div onClick={() => handlePopupClick('click', state)} className={styles.item} role="banner">
                    <span className={clsx(styles.cube, styles.blueCube)} />
                    {SUBJECT.TEXT_FROM}
                  </div>
                  <div onClick={() => handlePopupClick('contextmenu', state)} className={styles.item} role="banner">
                    <span className={clsx(styles.cube, styles.greenCube)} />
                    {SUBJECT.TEXT_TO}
                  </div>
                  <div onClick={() => handlePopupClick('dblclick', state)} className={styles.item} role="banner">
                    <span className={clsx(styles.cube, styles.grayCube)} />
                    {SUBJECT.TEXT_BOTH}
                  </div>
                </div>
              ) : null}
              <div className={styles.checkboxName}>
                <span>{formDataNames?.[state]}</span>
              </div>
            </div>
          );
        })}
        {isFilledCurrentBlock && !isDisabledButtonDependOnPerm && (
          <div className={styles.resetCardBtn}>
            <Button variant="ghost" children={SUBJECT.RESET} icon={<ResetIcon />} onClick={resetCurrentSearch} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TruckBoardCard;
