import React, { useEffect, useState } from 'react';
import { Modal, Rate as AntRate } from 'antd';
import clsx from 'clsx';
import Button from 'ui/button/Button';
import { SUBJECT } from 'ui/rate/constants/constants';
import { IRateProps } from 'ui/rate/constants/types';

import CloseIcon from 'components/svgs/CloseIcon';
import StarIcon from 'components/svgs/StarIcon';

import DisplayingRate from './components/displaying-rate/DisplayingRate';

import styles from './Rate.module.scss';

const Rate = ({
  canRate,
  isFirstTime = true,
  averageValue,
  handleRate,
  getRatings,
  rateCount = 0,
  rateList,
  direction = 'left',
  setOffset,
  offset,
  handleDeleteRate,
}: IRateProps | any) => {
  const [valueRate, setValueRate] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const handleRateValue = (value: number) => {
    setValueRate(value);
  };

  useEffect(() => {
    if (!isModalOpen) setValueRate(null);
  }, [isModalOpen]);

  return (
    <>
      <div
        className={clsx(styles.tableRateItem, {
          [styles.alreadyRated]: false,
          [styles.rateZero]: Number(rateCount) === 0,
        })}
        onClick={canRate ? showModal : undefined}
      >
        <DisplayingRate
          rateCount={rateCount}
          averageValue={averageValue}
          rateList={rateList}
          direction={direction}
          getRatings={getRatings}
          setOffset={setOffset}
          offset={offset}
          handleDeleteRate={handleDeleteRate}
          canRate={canRate}
        />
      </div>
      <Modal
        zIndex={999999}
        className={styles.rateModal}
        closeIcon={<CloseIcon />}
        title={isFirstTime ? SUBJECT.RATE_THE_CARRIER : SUBJECT.CHANGE_YOUR_RATE}
        footer={false}
        open={isModalOpen}
        onOk={() => handleRate(valueRate)}
        onCancel={showModal}
      >
        <div className={styles.showRateCount}>
          <DisplayingRate canRate={canRate} rateCount={rateCount} averageValue={averageValue} rateList={rateList} />
          <div
            className={clsx(styles.rateCurrent, {
              [styles.rateFirst]: isFirstTime,
            })}
          >
            <AntRate
              ref={null}
              className={styles.rate}
              character={<StarIcon />}
              value={valueRate || averageValue}
              allowClear={false}
              onChange={value => handleRateValue(value)}
            />
          </div>
        </div>

        <div className={styles.rateModalButton}>
          <Button htmlType="button" variant="outlined" size="large" onClick={showModal}>
            {SUBJECT.CANCEL}
          </Button>
          <Button
            disabled={false}
            htmlType="button"
            size="large"
            onClick={() => {
              if (valueRate) {
                handleRate(valueRate);
              }
              setIsModalOpen(false);
            }}
          >
            {SUBJECT.SAVE}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Rate;
