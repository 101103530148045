import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useLazyGetDefaultColumnsQuery } from 'services/user/user';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

const useResetColumns = ({ setDragColumns, deleteColumns, editColumns, handleCloseModal, dragColumns, type }: any) => {
  const dispatch = useDispatch();

  const [getDefaultColumns, { data: defaultColumnsData }] = useLazyGetDefaultColumnsQuery();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isDragged, setIsDragged] = useState(false);

  useEffect(() => {
    if (defaultColumnsData?.columns.length && isSuccess) {
      setDragColumns(defaultColumnsData?.columns);
    }
  }, [defaultColumnsData, isSuccess]);

  const handleResetToDefault = () => {
    getDefaultColumns({ type }).then(() => {
      setIsSuccess(true);
      dispatch(setIsValid({ isValid: true }));
    });
  };

  const onCustomizeSubmit = () => {
    if (isSuccess && !isDragged) {
      deleteColumns({ type }).then((data: any) => {
        if (data?.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          setIsSuccess(false);
          notification.success({
            message: 'Successfully saved',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          handleCloseModal();
        }
      });
      return;
    }
    if (!isSuccess && !isDragged) {
      handleCloseModal();
    } else {
      editColumns({ type, columns: dragColumns }).then((data: any) => {
        setIsSuccess(false);
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully saved',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        }
        handleCloseModal();
      });
    }

    setIsDragged(false);
    setIsSuccess(false);
  };

  return {
    isSuccess,
    setIsSuccess,
    defaultColumnsData,
    handleResetToDefault,
    onCustomizeSubmit,
    isDragged,
    setIsDragged,
  };
};

export default useResetColumns;
