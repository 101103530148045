import { createSlice } from '@reduxjs/toolkit';

export const initialState: any = {
  onlineAgents: [],
};

export const onlineAgentsSlice = createSlice({
  name: 'onlineAgents',
  initialState,
  reducers: {
    setOnlineAgent: (state, { payload }) => {
      state.onlineAgents = Array.from(new Set([...state.onlineAgents, payload]));
    },
    setOfflineAgent: (state, { payload }) => {
      state.onlineAgents = state.onlineAgents?.filter((el: number) => el !== payload);
    },
  },
});

export const { setOnlineAgent, setOfflineAgent } = onlineAgentsSlice.actions;

export default onlineAgentsSlice.reducer;
