import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useFileUpload from 'hooks/useFileUpload';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import {
  useAddFileToFactoringCompanyMutation,
  useChangeStatusMutation,
  useCreateFactoringCompanyMutation,
  useDeleteFileFromFactoringCompanyMutation,
  useGetFactoringCompaniesQuery,
  useGetFactoringCompanyQuery,
  useUpdateFactoringCompanyMutation,
} from 'services/profile/factoringCompanies/factoringCompanies';
import { useGetStatesQuery } from 'services/states/states';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatPhone } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import CreateFactoringCompaniesForm from './components/create-form/CreateFactoringCompaniesForm';
import EditProfileFactoringForm from './components/edit-form/EditProfileFactoringForm';
import FactoringCompaniesView from './components/profile-factoring-view/FactoringCompaniesView';
import { defaultValues, filterDefaultValues, SUBJECT } from './constants/constants';
import {
  BillBy,
  FactoringStatusTypes,
  IFactoringCompaniesTableColumns,
  IFactoringParams,
  IGetFactoringParams,
  IProfileFactoringFilter,
  IProfileFactoringFormData,
} from './constants/types';
import { validation } from './constants/validation';

export const useFactoringCompanies = ({ setExcelParams }: IFactoringParams) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { factoringCompaniesFilter, mode, id } = searchParams;

  const [getProfileFactoringFilter, setGetProfileFactoringFilter] = useState<Partial<IGetFactoringParams>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const { data: columnsData } = useGetColumnsQuery({ type: 'factoringCompanies' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [changeStatus] = useChangeStatusMutation();

  // create factoring
  const [createFactoring, { isLoading: isCreateLoading }] = useCreateFactoringCompanyMutation();

  // edit factoring
  const [editFactoring, { isLoading: isEditLoading }] = useUpdateFactoringCompanyMutation();

  // get by id
  const { currentData: factoringCompanyById, isFetching: isFetchingById } = useGetFactoringCompanyQuery(id, {
    skip: !id,
  });

  // add - delete file
  const [deleteFile] = useDeleteFileFromFactoringCompanyMutation();
  const [addNewFile] = useAddFileToFactoringCompanyMutation();

  const { data: statesData } = useGetStatesQuery({}, { skip: mode === 'view' || mode === 'customize' });
  const {
    data = [],
    isFetching,
    refetch: refetchFactoringCompaniesList,
  } = useGetFactoringCompaniesQuery(getProfileFactoringFilter);

  // form data for factoring
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, dirtyFields },
    getValues,
    reset,
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm<IProfileFactoringFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const {
    handleViewFileCreate,
    handleViewFileEdit,
    handleDeleteFileCreate,
    handleDeleteFileEdit,
    handleDownloadFileCreate,
    handleDownloadFileEdit,
    beforeUploadForCreate,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
  } = useFileUpload({ data: factoringCompanyById, setValue, getValues, id, trigger, deleteFile, addNewFile });

  const {
    control: filterControl,
    reset: filterReset,
    watch: filterWatch,
    setValue: setFilterValue,
    formState: { isDirty: isFilterDirty },
  } = useForm<IProfileFactoringFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const filterValue = filterWatch('factoringCompaniesFilter');
  const filterQueryCheck = typeof filterValue === 'string';

  useEffect(() => {
    if (factoringCompaniesFilter) {
      setFilterValue('factoringCompaniesFilter', factoringCompaniesFilter, { shouldDirty: true });
      setGetProfileFactoringFilter(prev => ({
        ...prev,
        skip: 0,
        limit: prev.limit,
        filter: { type: factoringCompaniesFilter },
      }));
    }
  }, [factoringCompaniesFilter]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { factoringCompaniesFilter: filterValue } : {}),
    });
  }, [filterValue]);
  const factoringCompaniesPermissions = usePermittedActions('profiles.factoringCompanies');

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        name: factoringCompanyById?.result?.companyName,
        email: factoringCompanyById?.result?.email || '',
        phoneNumber: formatPhone(factoringCompanyById?.result?.phone),
        fax: factoringCompanyById?.result?.fax,
        address: factoringCompanyById?.result?.address,
        secondAddress: factoringCompanyById?.result?.secondAddress || '',
        city: factoringCompanyById?.result?.city,
        stateId: factoringCompanyById?.result?.state?.id,
        zipcode: factoringCompanyById?.result?.zip,
        file: savedFiles || [],
        billBy: factoringCompanyById?.result?.type === BillBy.billByFreightMax,
        paymentBankName: factoringCompanyById?.result?.paymentBankName,
        paymentRoutingNumber: factoringCompanyById?.result?.paymentRoutingNumber,
        paymentAccountNumber: factoringCompanyById?.result?.paymentAccountNumber,
        paymentCompanyName: factoringCompanyById?.result?.paymentCompanyName,
        paymentAddress: factoringCompanyById?.result?.paymentAddress,
        paymentSecondAddress: factoringCompanyById?.result?.paymentSecondAddress,
        paymentCity: factoringCompanyById?.result?.paymentCity,
        paymentStateId: factoringCompanyById?.result?.paymentState?.id,
        paymentZip: factoringCompanyById?.result?.paymentZip,
      });
      setAllFiles(savedFiles);
    } else {
      reset(defaultValues);
      setAllFiles([]);
    }
  }, [mode, factoringCompanyById?.result]);
  // todo need add dependency
  // }, [mode, factoringCompanyById?.result,id, savedFiles]);

  const resetFilter = () => {
    filterReset();
    setSearchParams({
      ...searchParams,
      factoringCompaniesFilter: '',
    });
    setGetProfileFactoringFilter((prev: any) => ({
      ...prev,
      filter: {},
    }));
  };

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    reset(defaultValues, { keepDirty: false });
    setGetProfileFactoringFilter(prev => ({
      search: prev.search,
      skip: prev.skip,
      limit: prev.limit,
      order: prev.order,
      orderBy: prev.orderBy,
      field: prev.field,
      filter: {},
    }));
    clearErrors();
  };

  const onSubmit: SubmitHandler<IProfileFactoringFormData> = data => {
    if (mode === 'edit' && !isEditLoading) {
      editFactoring({ id, ...data }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully edited',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
          refetchFactoringCompaniesList();
        }
      });
    } else if (mode === 'add' && !isCreateLoading) {
      createFactoring(data).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    }
  };

  const handleStopResize = (columns: IFactoringCompaniesTableColumns[]): void => {
    editColumns({ type: 'factoringCompanies', columns });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (columnsData?.columns.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { factoringCompaniesFilter: filterValue } : {}),
      open: 'false',
      id: '',
    });
    dispatch(closeModal());
    resetForm();
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: 'factoringCompanies',
    });

  const handleCancel = () => {
    if (Object.keys(dirtyFields)?.length) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: isValid
            ? () => {
                onSubmit(watch());
                dispatch(closeModal());
                clearErrors();
              }
            : () => {
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(filterValue && filterQueryCheck ? { factoringCompaniesFilter: filterValue } : {}),
        open: 'false',
      });
      if (mode === 'edit') {
        resetForm();
      }
      setGetProfileFactoringFilter(prev => ({
        search: prev.search,
        skip: prev.skip,
        limit: prev.limit,
        order: prev.order,
        orderBy: prev.orderBy,
        field: prev.field,
        filter: {},
      }));
    }
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { factoringCompaniesFilter: filterValue } : {}),
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleRowEdit = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { factoringCompaniesFilter: filterValue } : {}),
      mode: 'edit',
      open: 'true',
      id: rowId,
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setGetProfileFactoringFilter(prev => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setGetProfileFactoringFilter(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setGetProfileFactoringFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleSwitchClick = (checked: boolean, rowId: number | string) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({
            id: rowId,
            status: checked
              ? FactoringStatusTypes.FACTORING_STATUS_TYPES_ACTIVE
              : FactoringStatusTypes.FACTORING_STATUS_TYPES_INACTIVE,
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...getProfileFactoringFilter,
        };
      });
    }
  }, [getProfileFactoringFilter]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: factoringCompaniesPermissions.create
      ? {
          component: (
            <CreateFactoringCompaniesForm
              handleViewFileCreate={handleViewFileCreate}
              handleDeleteFileCreate={handleDeleteFileCreate}
              handleDownloadFileCreate={handleDownloadFileCreate}
              beforeUploadForCreate={beforeUploadForCreate}
              allFiles={allFiles}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              getValues={getValues}
              control={control}
              errors={errors}
              setValue={setValue}
              statesData={statesData}
              watch={watch}
              trigger={trigger}
              isCreateLoading={isCreateLoading}
              isEditLoading={isEditLoading}
              clearErrors={clearErrors}
            />
          ),
          buttonText: SUBJECT.ADD_FACTORING,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit: factoringCompaniesPermissions.edit
      ? {
          component: (
            <EditProfileFactoringForm
              handleViewFileEdit={handleViewFileEdit}
              handleDeleteFileEdit={handleDeleteFileEdit}
              handleDownloadFileEdit={handleDownloadFileEdit}
              beforeUploadForEdit={beforeUploadForEdit}
              allFiles={allFiles}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              getValues={getValues}
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              data={factoringCompanyById}
              id={id}
              statesData={statesData}
              isFetchingById={isFetchingById}
              trigger={trigger}
              isEditLoading={isEditLoading}
              isCreateLoading={isCreateLoading}
            />
          ),
          buttonText: SUBJECT.EDIT_BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    view: {
      component: (
        <FactoringCompaniesView
          data={factoringCompanyById}
          isFetchingById={isFetchingById}
          pagePermissions={factoringCompaniesPermissions}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    isDirty,
    watch(),
    factoringCompanyById,
    statesData,
    columnsData?.isDefault,
    isSuccess,
    isEditLoading,
    isCreateLoading,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  useEffect(() => {
    if (data?.result?.length) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result?.length]);

  return {
    currentChildren,
    isFetching,
    columns: columnsData?.columns,
    data,
    filterControl,
    handleRowClick,
    resetFilter,
    handleRowEdit,
    handleSortClick,
    handleColumnSearch,
    handleSwitchClick,
    handleStopResize,
    handlePaginationClick,
    skip: getProfileFactoringFilter.skip,
    limit: getProfileFactoringFilter.limit,
    isFilterDirty,
    factoringCompaniesPermissions,
  };
};
