import React from 'react';

const LoadFeeIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5667 16.8771L29.1768 15.7027C28.9744 15.67 28.7699 15.6174 28.5633 15.5446C28.3567 15.4719 28.1697 15.38 28.0021 15.269L26.9883 15.6981L26.2735 14.8319L27.0268 13.8903C26.9807 13.7602 26.9432 13.6031 26.9144 13.4189C26.8855 13.2346 26.8731 13.0736 26.8773 12.9356L25.8721 12.2695L26.2877 11.2263L27.3842 11.3247C27.5098 11.1678 27.6598 11.0231 27.8342 10.8907C28.0087 10.7583 28.1873 10.6456 28.37 10.5526L28.3821 9.31521L29.5629 9.13024L29.9527 10.3046C30.1552 10.3373 30.3599 10.391 30.5668 10.4658C30.7737 10.5407 30.9612 10.6357 31.1294 10.7509L32.1413 10.3093L32.8581 11.1879L32.1047 12.1296C32.1495 12.2513 32.1867 12.4063 32.2162 12.5947C32.2457 12.7831 32.2577 12.9421 32.2523 13.0717L33.2575 13.7378L32.8418 14.781L31.7454 14.6826C31.6198 14.8395 31.4698 14.9842 31.2954 15.1166C31.1209 15.249 30.9423 15.3618 30.7595 15.4547L30.7475 16.6921L29.5667 16.8771ZM29.8009 14.511C30.2406 14.4421 30.5796 14.2442 30.8179 13.9173C31.0563 13.5904 31.141 13.2071 31.0721 12.7675C31.0033 12.3279 30.8054 11.9889 30.4785 11.7505C30.1516 11.5122 29.7683 11.4274 29.3287 11.4963C28.889 11.5652 28.55 11.7631 28.3117 12.09C28.0733 12.4169 27.9886 12.8001 28.0575 13.2398C28.1263 13.6794 28.3242 14.0184 28.6511 14.2568C28.978 14.4951 29.3613 14.5799 29.8009 14.511Z"
        fill="var(--black)"
      />
      <path
        d="M32.0396 22.4328L33.4335 20.7911C33.299 20.4605 33.1924 20.1087 33.1138 19.7357C33.0351 19.3627 33.0026 19.0014 33.0162 18.6519L31.4614 17.5322L32.103 15.686L34.1846 15.9548C34.3363 15.7687 34.5361 15.5708 34.784 15.3613C35.0319 15.1518 35.2602 14.9878 35.4691 14.8692L35.5508 12.7719L37.4781 12.4468L38.3231 14.1664C38.67 14.2112 39.0208 14.3035 39.3755 14.4431C39.7302 14.5828 40.0593 14.7465 40.3628 14.9342L42.2139 13.8333L43.5567 15.422L42.1627 17.0637C42.2973 17.3943 42.4025 17.7473 42.4783 18.1226C42.5541 18.498 42.5823 18.8629 42.5631 19.2172L44.1349 20.3226L43.4763 22.1831L41.3947 21.9142C41.2543 22.0909 41.0573 22.2863 40.8038 22.5006C40.5503 22.7149 40.3247 22.8766 40.1271 22.9856L40.0455 25.0829L38.1182 25.408L37.2731 23.6884C36.9262 23.6435 36.5754 23.5513 36.2207 23.4117C35.866 23.272 35.5369 23.1083 35.2334 22.9206L33.3824 24.0215L32.0396 22.4328ZM35.77 20.6416C36.27 21.2331 36.8708 21.5583 37.5725 21.6172C38.2742 21.676 38.9208 21.4555 39.5123 20.9555C40.1039 20.4555 40.4291 19.8547 40.4879 19.153C40.5468 18.4513 40.3262 17.8047 39.8262 17.2132C39.3263 16.6217 38.7254 16.2965 38.0238 16.2376C37.3221 16.1788 36.6755 16.3993 36.0839 16.8993C35.4924 17.3993 35.1672 18.0001 35.1084 18.7018C35.0495 19.4035 35.2701 20.0501 35.77 20.6416Z"
        fill="var(--black)"
      />
      <path
        d="M8.16406 22.3942C8.16406 21.7109 8.71799 21.157 9.4013 21.157H27.2214C27.9047 21.157 28.4586 21.7109 28.4586 22.3942V26.9481H8.16406V22.3942Z"
        fill="var(--white)"
        stroke="var(--black)"
      />
      <path
        d="M8.16406 14.3932C8.16406 13.7099 8.71799 13.156 9.4013 13.156H27.2214C27.9047 13.156 28.4586 13.7099 28.4586 14.3932V18.9471H8.16406V14.3932Z"
        fill="var(--white)"
        stroke="var(--black)"
      />
      <rect
        x="8.16406"
        y="29.543"
        width="20.2946"
        height="6.97186"
        rx="1.23724"
        fill="var(--white)"
        stroke="var(--black)"
      />
      <mask
        id="path-6-outside-1_502_9751"
        maskUnits="userSpaceOnUse"
        x="19.4062"
        y="23.7136"
        width="19"
        height="22"
        fill="black"
      >
        <rect fill="white" x="19.4062" y="23.7136" width="19" height="22" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.1499 26.9293C33.8044 26.156 32.2438 25.7136 30.5797 25.7136C25.5133 25.7136 21.4062 29.8137 21.4062 34.8714C21.4062 38.3997 23.405 41.4619 26.3338 42.9913L26.9736 41.8256C24.4567 40.5223 22.7372 37.8973 22.7372 34.8714C22.7372 30.5475 26.2484 27.0423 30.5797 27.0423C32.0119 27.0423 33.3545 27.4256 34.5101 28.095L35.1499 26.9293Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.1499 26.9293C33.8044 26.156 32.2438 25.7136 30.5797 25.7136C25.5133 25.7136 21.4062 29.8137 21.4062 34.8714C21.4062 38.3997 23.405 41.4619 26.3338 42.9913L26.9736 41.8256C24.4567 40.5223 22.7372 37.8973 22.7372 34.8714C22.7372 30.5475 26.2484 27.0423 30.5797 27.0423C32.0119 27.0423 33.3545 27.4256 34.5101 28.095L35.1499 26.9293Z"
        fill="var(--black)"
      />
      <path
        d="M35.1499 26.9293L36.6728 27.7652L37.4925 26.2719L36.0156 25.4231L35.1499 26.9293ZM26.3338 42.9913L25.5296 44.5312L27.0379 45.3189L27.8567 43.8272L26.3338 42.9913ZM26.9736 41.8256L28.4965 42.6615L29.3529 41.1014L27.7724 40.283L26.9736 41.8256ZM34.5101 28.095L33.6393 29.5982L35.1776 30.4893L36.033 28.9309L34.5101 28.095ZM36.0156 25.4231C34.4135 24.5024 32.5554 23.9764 30.5797 23.9764V27.4509C31.9321 27.4509 33.1952 27.8096 34.2843 28.4355L36.0156 25.4231ZM30.5797 23.9764C24.5567 23.9764 19.669 28.8515 19.669 34.8714H23.1435C23.1435 30.7759 26.47 27.4509 30.5797 27.4509V23.9764ZM19.669 34.8714C19.669 39.073 22.051 42.7147 25.5296 44.5312L27.1379 41.4514C24.759 40.2091 23.1435 37.7264 23.1435 34.8714H19.669ZM27.8567 43.8272L28.4965 42.6615L25.4507 40.9897L24.8109 42.1554L27.8567 43.8272ZM20.9999 34.8714C20.9999 38.5728 23.1052 41.7788 26.1748 43.3683L27.7724 40.283C25.8083 39.2659 24.4744 37.2219 24.4744 34.8714H20.9999ZM30.5797 25.305C25.2917 25.305 20.9999 29.5852 20.9999 34.8714H24.4744C24.4744 31.5097 27.205 28.7795 30.5797 28.7795V25.305ZM35.3809 26.5918C33.9677 25.7731 32.3257 25.305 30.5797 25.305V28.7795C31.6981 28.7795 32.7413 29.078 33.6393 29.5982L35.3809 26.5918ZM36.033 28.9309L36.6728 27.7652L33.627 26.0934L32.9872 27.2591L36.033 28.9309Z"
        fill="var(--white)"
        mask="url(#path-6-outside-1_502_9751)"
      />
      <path
        d="M36.4858 28.0162C36.666 28.2988 36.471 28.6704 36.1357 28.6834L34.2413 28.7566C33.9075 28.7695 33.6849 28.4172 33.84 28.1216L34.716 26.4521C34.8711 26.1565 35.288 26.1386 35.4674 26.4199L36.4858 28.0162Z"
        fill="var(--black)"
      />
      <path
        d="M28.6147 42.8136C28.7949 43.0962 28.5999 43.4678 28.2646 43.4808L26.3702 43.554C26.0365 43.5668 25.8138 43.2145 25.9689 42.919L26.8449 41.2494C27 40.9539 27.4169 40.936 27.5963 41.2172L28.6147 42.8136Z"
        fill="var(--black)"
      />
    </svg>
  );
};

export default LoadFeeIcon;
