import { BillBy, BillByFreightMax } from 'pages/profile/tabs/factoringCompanies/constants/types';
import { formatPhone } from 'utils/helpers';

export const getFactoringCompaniesSerializer = (body: any): any => {
  const result = body?.result?.map((factoringCompany: any) => {
    return {
      ...factoringCompany,
      name: factoringCompany?.companyName,
      state: factoringCompany?.state?.name,
      paymentState: factoringCompany?.paymentState?.name,
      phone: formatPhone(factoringCompany?.phone),
      billByFreightmax: BillByFreightMax[factoringCompany?.type],
      loads: factoringCompany?.loads || 0,
    };
  });
  return {
    result,
    count: body?.count,
  };
};

export const getFactoringCompanyByIdSerializer = (body: any): any => ({
  result: {
    ...body.result,
    paymentState: body?.result?.paymentState,
  },
});
export const factoringDataSerializer = (body: any) => {
  return {
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body.secondAddress } : {}),
    city: body.city,
    email: body?.email,
    companyName: body.name,
    phone: body.phoneNumber,
    stateId: Number(body.stateId),
    zip: body.zipcode,
    fax: body?.fax ? String(body?.fax) : '',
    type: body?.billBy ? BillBy.billByFreightMax : BillBy.billByOther,
    fileIds: body?.file?.map((el: any) => Number(el)),
    paymentBankName: body?.paymentBankName,
    paymentRoutingNumber: body?.paymentRoutingNumber,
    paymentAccountNumber: body?.paymentAccountNumber,
    paymentCompanyName: body?.paymentCompanyName,
    paymentAddress: body?.paymentAddress,
    paymentSecondAddress: body?.paymentSecondAddress,
    paymentCity: body?.paymentCity,
    paymentStateId: Number(body?.paymentStateId),
    paymentZip: body?.paymentZip,
  };
};
