import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SUBJECT } from 'pages/profile/tabs/office/constants/constants';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { closeModal, drawerClose, openModal } from 'store/modal-slice/modals';
import { selectModals } from 'store/modal-slice/selector';

import { useDetectedParams } from '../../hooks/useDetectedParams';

import { IColumn, ICustomColumnProps } from './constants/types';

export const useCustomColumn = ({
  dragColumns,
  setDragColumns,
  columns,
  handleResetToDefault,
  isSuccess,
  setIsSuccess,
  setIsDragged,
  onCustomizeSubmit,
  isLoading,
}: ICustomColumnProps | any) => {
  const dispatch = useDispatch();

  const { isDrawerClosed } = useSelector(selectModals);

  const [touched, setTouched] = useState(false);
  const [position, setPosition] = useState(-1);
  const [draggedIndex, setDraggedIndex] = useState(-1);
  const [isAnimating, setIsAnimating] = useState(isSuccess);
  const { searchParams, setSearchParams } = useDetectedParams();

  useEffect(() => {
    dispatch(setIsValid({ isValid: isLoading }));
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      setTouched(true);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
  }, [isSuccess]);

  const dragItem = useRef<number | null>();
  const dragOverItem = useRef<number | null>();

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    dragItem.current = position;
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    dragOverItem.current = position;
    if (position !== draggedIndex) {
      setPosition(position);
    }
  };
  const [lastUndoIndex, setLastUndoIndex] = useState<number | null>(null);
  const [lastUndoPrevIndex, setLastUndoPrevIndex] = useState<number | null>(null);
  const changeRef = useRef<any>([]);
  const handleUndo = () => {
    const changesLength = changeRef.current.length;

    if (changeRef.current[changesLength - 1]?.to || changeRef.current[changesLength - 1]?.from) {
      const copyListItems = [...dragColumns];
      const dragItemContent = copyListItems[changeRef.current[changesLength - 1]?.to];

      copyListItems.splice(changeRef.current[changesLength - 1]?.to, 1);
      copyListItems.splice(changeRef.current[changesLength - 1]?.from, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setLastUndoIndex(changeRef.current[changesLength - 1]?.from);
      setLastUndoPrevIndex(changeRef.current[changesLength - 1]?.to);
      setTimeout(() => {
        setLastUndoIndex(null);
        setLastUndoPrevIndex(null);
      }, 500);
      setDragColumns(copyListItems);
    } else {
      setDragColumns((prev: IColumn[]) =>
        prev.map((el: IColumn) => {
          if (el?.id === changeRef?.current[changesLength - 1]?.id) {
            return { ...el, isVisible: !changeRef?.current[changesLength - 1]?.isVisible };
          } else {
            return el;
          }
        })
      );
    }
    setTimeout(() => {
      changeRef?.current?.pop();
      if (changeRef?.current?.length) {
        setTouched(true);
      } else {
        setTouched(false);
        setIsDragged(false);
      }
    });
  };

  useEffect(() => {
    if (searchParams?.open === 'false') {
      changeRef.current = [];
    }
  }, [searchParams.open]);

  const handleResetClick = () => {
    handleResetToDefault();
    setIsDragged(false);
    changeRef.current = [];
  };

  const onDrop = () => {
    const copyListItems = [...dragColumns];
    const dragItemContent = copyListItems[dragItem.current!];

    copyListItems.splice(dragItem.current!, 1);
    copyListItems.splice(dragOverItem.current!, 0, dragItemContent);
    changeRef.current.push({ from: dragItem.current, to: dragOverItem.current });

    dragItem.current = null;
    dragOverItem.current = null;

    setDragColumns(copyListItems);
    setTouched(true);
    setIsSuccess(false);
    setIsDragged(true);
  };

  const handleVisibility = (columnId: string | number) => {
    setDragColumns(
      dragColumns?.map((column: IColumn) => {
        if (column?.id === columnId) {
          changeRef.current.push({ id: column?.id, isVisible: !column.isVisible });
          return { ...column, isVisible: !column?.isVisible };
        }
        return column;
      })
    );
    setTouched(true);
    setIsDragged(true);
  };

  const handleReset = () => {
    setDragColumns(columns);
  };

  useEffect(() => {
    if (isDrawerClosed) {
      handleCancel();
    }
  }, [isDrawerClosed]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
    });
    dispatch(closeModal());
    dispatch(drawerClose({ isDrawerClosed: false }));
    setDragColumns(columns);
    setIsDragged(false);
  };

  const handleCancel = () => {
    if (touched) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: SUBJECT.SAVE,
          cancelText: SUBJECT.CANCEL,
          onOk: onCustomizeSubmit,
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        open: 'false',
      });
    }
    setIsDragged(false);
    setIsSuccess(false);
  };

  return {
    dragColumns,
    touched,
    onDragStart,
    onDragEnter,
    onDrop,
    handleVisibility,
    handleReset,
    position,
    setPosition,
    draggedIndex,
    setDraggedIndex,
    handleUndo,
    changeRef,
    handleResetClick,
    lastUndoIndex,
    lastUndoPrevIndex,
    isAnimating,
  };
};
