import { Control, UseFormReset } from 'react-hook-form';

import { IEmployeeFilter, IOptions } from './types';

export const EMPLOYEE_LOCATION_OPTIONS: IOptions[] = [
  {
    title: 'Coordinator',
    value: '1',
  },
  {
    title: 'Lawyer',
    value: '2',
  },
  {
    title: 'Support team',
    value: '3',
  },
  {
    title: 'Accountant',
    value: '4',
  },
];

export enum PERMISSION_GROUPS {
  Coordinator = 1,
  Lawyer = 2,
  SupportTeam = 3,
  Accountant = 4,
}

export enum SUBJECT {
  DATE = 'Date',
  ADDED_BY = 'Added By',
  INVITATION = 'Invitation',
  PERMISSION_GROUP = 'Permission Group',
  NOT_SELECTED = 'Not Selected',
  RESET_ALL = 'Reset All',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  CANCEL = 'Close',
  SAVE = 'Save',
  EMPLOYEES_LIST_IS_EMPTY = 'Employees  List Is Empty',
  ADD_NEW_EMPLOYEE = 'Add New Employee',
  ADD_EMPLOYEE = 'Add Employee',
  EDIT_EMPLOYEE = 'Edit Employee',
  EDIT_BTN_TEXT = 'Save Changes',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  USER_NAME = 'Username',
  DOB = 'DOB',
  EMAIL = 'Email',
  INVITE_AGAIN = 'Invite Again',
  ACCEPTED = 'Accepted',
  PHONE_NUMBER = 'Phone Number',
  EXT = 'EXT',
  TITLE = 'Title',
  ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIPCODE = 'Zip Code',
  EMERGENCY_CONTACT = 'Emergency Contact',
  NAME = 'Name',
  RELATION = 'Relation',
  EMPLOYEE_INFO = 'Employee Info',
  EDIT = 'Edit',
  EMPLOYEES_ID = 'Employees ID',
  STATUS = 'Status',
  PERSONAL_INFORMATION = 'Personal Information',
  NO = 'No',
  YES = 'Yes',
  CHECKED_ACTIVATE = 'If Activated, The User Can Login Again',
  CHECKED_DEACTIVATE = "If Terminated, User Can't Login",
  ARE_YOU_SURE = 'Are You Sure Want Do Delete?',
  DELETE = 'Delete',
  CORPORATE = 'Corporate',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  STAY_HERE = 'Stay Here',
  ALLOWED_FORMAT = 'Allowed Format (jpeg, pdf, tiff, png)',
  UPLOAD = 'Drag And Drop Or',
  UPLOAD_FILE = 'Upload File',
  USERNAME_EXISTS = 'This Username Already Exists',
  FILE = 'File',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',
  FULL_INFO = 'Full Info',
  TERMINATE = 'Terminate',
}

export const filterDefaultValues = {
  permissionGroupFilter: null,
};

export interface IEmployeeTableProps {
  data: any;
  isLoading?: boolean;
  filterControl?: Control<IEmployeeFilter>;
  filterReset: UseFormReset<IEmployeeFilter>;
}

export const defaultValues = {
  firstName: '',
  lastName: '',
  username: '',
  dob: '',
  email: '',
  phoneNumber: null,
  ext: '',
  title: '',
  permissionGroup: '',
  address: '',
  secondAddress: '',
  city: '',
  relation: '',
  state: '',
  zipcode: null,
  name: '',
  phoneNumberEmergency: null,
  file: [],
};

export enum VALIDATION_MESSAGES {
  PHONE = 'Incorrect phone format',
  EMAIL = 'Sorry, we don`t  recognise this email address.',
  FIRST_NAME = 'First name can only contain alphabetical characters',
  USER_NAME = 'User name can only contain alphabetical characters',
  LAST_NAME = 'Last name can only contain alphabetical characters',
  NAME = 'Name can only contain alphabetical characters',
  RELATION = 'Relation can only contain alphabetical characters',
  MAX_LENGTH = 'Maximum of 10 digits allowed',
  INVALID_EMAIL = 'Invalid email format',
  FIRST_NAME_REQUIRED = 'First name is a required field',
  PERMISSION_GROUP_REQUIRED = 'Permission group is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  CITY_REQUIRED = 'City is a required field',
  STATE_REQUIRED = 'State is a required field',
  ZIP_REQUIRED = 'Zip Code is a required field',
  NAME_REQUIRED = 'Name is a required field',
  RELATION_REQUIRED = 'Relation is a required field',
  PHONE_NUMBER_EMERGENCY_REQUIRED = 'Phone number emergency is a required field',
  LAST_NAME_REQUIRED = 'Last name is a required field',
  USERNAME_REQUIRED = 'Username is a required field',
  EMAIL_REQUIRED = 'Email is a required field',
  PHONE_NUMBER_REQUIRED = 'Phone number is a required field',
  FILE_REQUIRED = 'File is a required field',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
}

export const LEVEL: any = {
  corporate: 1,
  office: 2,
  branch: 3,
};
