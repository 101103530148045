import React, { ForwardedRef, forwardRef, useMemo, useRef, useState } from 'react';
import { RefSelectProps, TreeSelect } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import CloseIcon from 'components/svgs/CloseIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';

import { getHighlightedOptions } from '../../../components/main-layout/components/header/components/header-search/constants/helper';

import { IRegionOptions, IState } from './constants/types';

import styles from './LocalSearchMultiSelect.module.scss';

const LocalSearchMultiSelect = forwardRef(
  (
    {
      options,
      placeholder,
      label,
      allowClear,
      name,
      isDisabled,
      onChange,
      value,
      required,
      onBlur,
      errors,
      ...props
    }: any,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const selectRef = useRef<RefSelectProps>(null);
    const dropdownRef = useRef<HTMLInputElement>(null);

    const { TreeNode } = TreeSelect;

    const handleSelect = () => {
      setIsSearching(false);
      setSearchValue('');
    };

    const handleCheck = (checkedKeys: string[]) => {
      onChange(checkedKeys);
    };

    const handleSearch = (value: string) => {
      setSearchValue(value);
      setIsSearching(true);
    };

    const expandedKeys = useMemo(() => {
      return options?.map((el: any) => el?.children && el.key);
    }, [options]);

    const tagPlaceholder = useMemo(() => {
      return (
        !!value?.length && (
          <div className={styles.selectedItems}>
            <Typography variant="paragraph">+{value?.length}</Typography>
            <div role="button" onClick={() => onChange && onChange([])}>
              <CloseIcon />
            </div>
          </div>
        )
      );
    }, [value?.length]);

    return (
      <div className={styles.selectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          id={`select_${name}`}
          className={clsx(styles.selectWrapper, {
            [styles.selected]: value && value.length > 0,
            [styles.required]: required,
            [styles.error]: errors?.[name],
            [styles.withSubChild]: true,
          })}
          ref={dropdownRef}
        >
          <TreeSelect
            id={`current_state_searchable${label}${placeholder}`}
            showSearch
            showArrow
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            ref={selectRef || ref}
            style={{ width: '100%' }}
            getPopupContainer={() => document.getElementById(`select_${name}`)!}
            placeholder={label ? placeholder : null}
            allowClear={(value && value.length > 0) || searchValue}
            value={value}
            suffixIcon={(allowClear && value && value.length > 0) || searchValue ? <CloseIcon /> : <SelectArrowIcon />}
            disabled={isDisabled || false}
            clearIcon={<CloseIcon />}
            className={clsx(styles.select)}
            treeDefaultExpandAll
            treeExpandedKeys={expandedKeys}
            multiple
            maxTagCount={0}
            maxTagPlaceholder={() => tagPlaceholder}
            autoClearSearchValue={false}
            onChange={handleCheck}
            onSelect={handleSelect}
            onBlur={onBlur}
            onSearch={handleSearch}
            filterTreeNode={(search, item: any) => {
              const matchesParent =
                item.innerTitle
                  ?.toLowerCase()
                  ?.replaceAll(' ', '')
                  .indexOf(search.toLowerCase()?.replaceAll(' ', '')) >= 0;
              const matchesChild =
                item.innerValue?.toLowerCase().indexOf(search.toLowerCase()?.replaceAll(' ', '')) >= 0;
              return matchesParent || matchesChild;
            }}
            treeCheckable
            {...props}
          >
            {options?.map((region: IRegionOptions) => {
              const highlightedValues = getHighlightedOptions(
                searchValue.replaceAll(' ', ''),
                region.title.replaceAll(' ', '')
              );

              return (
                <TreeNode
                  value={region?.value}
                  title={
                    <div className={styles.optionsContainer}>
                      {highlightedValues?.length && isSearching ? (
                        <>
                          {highlightedValues[0]}
                          <span className={styles.searchResultKeyword}>{highlightedValues[1]}</span>
                          {highlightedValues[2]}
                        </>
                      ) : (
                        <span>{region?.title}</span>
                      )}
                    </div>
                  }
                  key={region?.key}
                  selectable={true}
                >
                  {region.children?.map((state: IState) => {
                    const highlightedValues = getHighlightedOptions(String(searchValue || ''), String(state.title));
                    return (
                      <TreeNode
                        value={state?.value}
                        title={
                          <div className={styles.optionsContainer}>
                            {highlightedValues?.length && isSearching ? (
                              <>
                                {highlightedValues[0]}
                                <span className={styles.searchResultKeyword}>{highlightedValues[1]}</span>
                                {highlightedValues[2]}
                              </>
                            ) : (
                              <span>{state?.title}</span>
                            )}
                          </div>
                        }
                        key={state?.key}
                        innerValue={state?.title}
                        innerTitle={region?.title}
                        selectable={true}
                      />
                    );
                  })}
                </TreeNode>
              );
            })}
          </TreeSelect>
          {!label && (
            <Typography variant="paragraph" className={`placeholder-select ${styles.placeholder}`}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);
export default LocalSearchMultiSelect;
