import { notification } from 'antd';

import { IBracket } from '../services/profile/agents/interfaces';

import { dateFormatOptions, IFormatTextInput, MAX_ALLOWED_FILE_SIZE, timeFormatOptions } from './constants';
import { formatDate } from './dates';
import { onlyCharacters } from './regexp';
import { TSeparator } from './types';

export const handleKeyPress = ({ key }: KeyboardEvent, pressedKey: string) => {
  return key === pressedKey;
};

export const formatTextOnlyInput = ({ value, setter, key }: IFormatTextInput) => {
  if (onlyCharacters.test(value) || value === '') {
    setter(key, value, { shouldDirty: true, shouldValidate: true });
  }
};

export const convertTimeStamp = (timeStamp: number, separator: TSeparator = '-', withTime?: boolean) => {
  return new Intl.DateTimeFormat('en-US', { ...dateFormatOptions, ...(withTime ? timeFormatOptions : {}) })
    .format(timeStamp)
    .replaceAll('/', separator);
};

export const getHighlightedOptions = (phaseWords: string, fullWords = '') => {
  const res: string | number[] = [];
  const result = [];
  const words = String(fullWords);
  const phase = phaseWords?.at(-1) === ' ' ? phaseWords?.trim() : phaseWords;
  for (let i = 0, j = phase?.length; i < words?.length; i++) {
    const firstIndex = i;
    while (j > 0) {
      if (words[i]?.toLocaleLowerCase() !== String(phase[phase.length - j])?.toLocaleLowerCase()) break;
      j--;
      i++;
    }
    if (j === 0) {
      res.push(i - phase.length, i - 1);
      result.push(words.slice(0, res[0]));
      if (res[1] === words.length - 1) {
        result.push(words.slice(res[0]));
      } else {
        result.push(words.slice(res[0], res[1] + 1));
        result.push(words.slice(res[1] + 1, words.length));
      }
      return result;
    }
    i = firstIndex;
    j = phase?.length;
  }

  return res;
};

export const handleShowFloatingPercent = (value: string | number) => {
  let newNum = Number(value).toFixed(2).replace(',', '.');
  if (newNum[newNum.length - 1] === '0') {
    newNum = newNum.slice(0, -1);
  }
  if (newNum[newNum.length - 1] === '0') {
    newNum = newNum.slice(0, -2);
  }
  return newNum;
};

export const handleDeFloatedBrackeds = (brackets: IBracket[]) => {
  return brackets?.map(el => ({
    ...el,
    to: el.to ? Number(el.to) : 0,
    from: el.from ? Number(el.from) : 0,
    percent: el.percent ? Number(handleShowFloatingPercent(el.percent)) : 0,
  }));
};

export const handleFloatedBrackeds = (brackets: IBracket[] | undefined) => {
  return brackets?.map(el => ({
    ...el,
    to: el.to ? Number(el.to) : 0,
    from: el.from ? Number(el.from) : 0,
    percent: el.percent ? Number(el.percent) : 0,
  }));
};

export const ArrayValuesToNumber = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value.map(el => Number(el));
  }
  return value;
};

export const multiplyDownloadWithDelay = (files: any[], delay = 1000) => {
  if (!(files.length <= 1))
    notification.info({
      message:
        'If all URLs were not opened, they would probably be blocked by your browser. Allow popups in this site for this tool to work properly.',
      duration: 3,
      placement: 'topRight',
    });
  files.forEach(({ download }: any, index: number) => {
    setTimeout(() => {
      const link = document?.createElement('a');
      link.href = download;
      link.download = download?.substring(download?.lastIndexOf('/') + 1);
      link.click();
    }, index * delay);
  });
};
export const multiplyDownloadWithDelayLocal = (localFiles: any[], delay = 1000) => {
  if (localFiles.length > 1) {
    notification.info({
      message:
        'If all URLs were not opened, they would probably be blocked by your browser. Allow popups in this site for this tool to work properly.',
      duration: 3,
      placement: 'topRight',
    });

    setTimeout(() => {
      localFiles.forEach(file => {
        const downloadLink = document.createElement('a');

        const blob = new Blob([file]);
        const url = window.URL.createObjectURL(blob);
        downloadLink!.href = url;
        downloadLink.download = file!.name;
        downloadLink.click();
        window.URL.revokeObjectURL(url);
      });
    }, delay);
  } else {
    const downloadLink = document.createElement('a');
    const blob = new Blob([localFiles[0]]);
    const url = window.URL.createObjectURL(blob);
    downloadLink!.href = url;
    downloadLink.download = localFiles[0]!.name;
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  }
};

export const multiplyViewWithDelay = (files: any[], delay = 3000) => {
  if (files.length > 1) {
    notification.info({
      message:
        'If all URLs were not opened, they would probably be blocked by your browser. Allow popups in this site for this tool to work properly.',
      duration: 3,
      placement: 'topRight',
    });
    setTimeout(() => {
      files?.forEach((file: any) => {
        window.open(file.preview, '_blank');
      });
    }, delay);
  } else {
    window.open(files[0]?.preview, '_blank');
  }
};

export const multiplyViewWithDelayLocal = (localFiles: any[], delay = 3000) => {
  if (localFiles) {
    const blobs = localFiles.map((el: any) => new Blob([el], { type: el.type }));

    const urls = blobs.map(el => window.URL.createObjectURL(el));
    if (localFiles.length > 1) {
      notification.info({
        message:
          'If all URLs were not opened, they would probably be blocked by your browser. Allow popups in this site for this tool to work properly.',
        duration: 3,
        placement: 'topRight',
      });

      setTimeout(() => {
        urls.forEach(url => window.open(url, '_blank'));
      }, delay);

      setTimeout(() => {
        urls.forEach(url => URL.revokeObjectURL(url));
      }, delay);
    } else {
      window.open(urls[0], '_blank');
    }
  }
};
export const handleViewFileClick = (url: string) => {
  window.open(url, '_blank');
};
export const handleDownloadClick = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = url.substring(url.lastIndexOf('/') + 1);
  link.target = '_blank';
  link.click();
};
export const getFormattedDateForTypography = (el: any) => {
  return formatDate(el.createdAt);
};

export const handleViewMultipleFiles = (files: any) => {
  files?.forEach((file: any) => {
    window.open(file.url, '_blank');
  });
};

export const getMillisecondsSeparatedDateAndTime = (inputDate: any, inputTime: string, period: string) => {
  const originalDate = new Date(inputDate);
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');

  const inputHours = +inputTime?.split(':')[0];
  const inputMinutes = inputTime?.split(':')[1];

  const newInputTime = period === 'PM' ? inputHours + 12 + ':' + inputMinutes : inputTime;

  const combinedDateTime = `${year}-${month}-${day}T${newInputTime}:00`;
  const parsedDate = new Date(combinedDateTime);

  return parsedDate.getTime();
};

export const capitalizeFirstLetter = (str: string) => {
  return `${str?.charAt(0)?.toUpperCase()}${str?.slice(1)}`.replaceAll('_', ' ');
};

export const phoneFormatter = (value: string) => Number(value?.replace(/[-\s]/g, '').replace(/\D/g, ''));
export const onlyNumbersFormatter = (value: any) => value?.replace(/[-\s]/g, '').replace(/\D/g, '');

export const nonAlphanumericFormatter = (value: string) => value?.replace(/[^a-zA-Z0-9]/g, '');
export const nonAlphanumericSpaceFormatter = (value: string) => value?.replace(/[^\ta-zA-Z0-9\s]/g, '')?.trimStart();

export const nonAlphanumericAndSpacesFormatter = (value: string) => value?.replace(/[^a-zA-Z0-9]/g, '');

export const unicodeFormatter = (value: string) => value?.replace(/[^ \p{Letter}]/gu, '')?.trimStart();

export const unicodeSpaceNumbersFormatter = (value: string) =>
  value?.replace(/[^ \p{Letter}\p{Number}]/gu, '')?.trimStart();

export const emailFormatter = (value: string) => value?.replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=[\]{};':"|,.<>/?]+/g, '');

export const whiteSpaceFormatter = (value: string) => value?.replace(/\s/g, '');

export const formatNumberWithThousandsSeparatorAndDecimal = (value: any, withDollar = false): string => {
  let inputValue = String(value);

  inputValue = inputValue?.replace(/[^\d.-]/g, '');

  const decimalCount = (inputValue?.match(/\./g) || []).length;

  if (decimalCount > 1) {
    inputValue = inputValue?.replace(/\./, '');
  }
  const parts = inputValue?.split('.');
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  inputValue = parts?.join('.');

  return withDollar ? inputValue && serializeAmountWithDollar(inputValue) : inputValue;
};

export const floatNumberFormatter = (value: string, isDecimal = true) => {
  if (value) {
    value = value.replace(/[^0-9.]/g, '');

    if (value.startsWith('.')) {
      value = value.substring(1);
    }

    const parts = value.split('.');

    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1];
    }

    if (parts.length === 2) {
      value = parts[0] + '.' + parts[1].substring(0, 2);
    }

    value = value.replace(/^0+(?=\d)/, '');
    // if (Number(value) > 99999.99) {
    //   value = '99999.99';
    // }

    if (!isDecimal) {
      value = value.split('.')[0];
    }

    return value;
  } else {
    return '';
  }
};
export const floatNumberFormatterWithMinus = (value: string, isDecimal = true) => {
  if (value) {
    value = value.replace(/[^0-9.-]|(?<=.)-/g, '');
    if (value.startsWith('-') && isNaN(Number(value[1]))) {
      value = '-' + value.substring(1).replace(/-|\./g, '');
    }

    if (value.startsWith('.')) {
      value = value.substring(1);
    }

    const parts = value.split('.');

    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1];
    }

    if (parts.length === 2) {
      value = parts[0] + '.' + parts[1].substring(0, 2);
    }

    if (value.startsWith('-')) {
      value = value.replace(/^-0+(?=\d)/, '-');
    } else {
      value = value.replace(/^0+(?=\d)/, '');
    }

    // if (Number(value) < -maxNumber) {
    //   value = `-${maxNumber}`;
    // }
    // if (Number(value) > maxNumber) {
    //   value = `${maxNumber}`;
    // }
    if (!isDecimal) {
      value = value.split('.')[0];
    }
    return value;
  } else {
    return '';
  }
};

export const scrollToTarget = (targetRef: any) => {
  targetRef.current?.scrollIntoView({ behavior: 'smooth' });
};

export const formatPhone = (phone: string) => {
  if (!phone) return phone;
  const value = onlyNumbersFormatter(phone);
  const formatedPhone = value.replace(/[^\d]/g, '');
  const phoneLenght = formatedPhone.length;
  if (phoneLenght < 4) return formatedPhone;
  if (phoneLenght < 7) {
    return `${formatedPhone.slice(0, 3)}-${formatedPhone.slice(3)}`;
  }
  return `${formatedPhone.slice(0, 3)}-${formatedPhone.slice(3, 6)}-${formatedPhone.slice(6)}`;
};

export const checkElementLengthForTooltip = (parent: string, child: string, open: boolean, setIsTooltipOpen: any) => {
  const parentElement = document.getElementById(parent)?.getBoundingClientRect().width || 0;
  const childElement = document.getElementById(child)?.getBoundingClientRect().width || 0;

  if (open) {
    if (parentElement < childElement) {
      setIsTooltipOpen(child);
    } else {
      setIsTooltipOpen('');
    }
  } else {
    setIsTooltipOpen('');
  }
};

export const checkIsNumberDecimal = (number: number) => {
  if (String(number)?.includes('.')) {
    return Number(number.toFixed(2));
  } else {
    return number;
  }
};
export const serializeAmountWithDollar = (value: string) => {
  if (value.includes('-')) {
    return `-$ ${value.replace('-', '')}`;
  } else {
    return `$ ${value}`;
  }
};

export const changeTimeZone = (date: any, timeZone: any, reverseMode?: boolean) => {
  const sameDateInLosAngeles = new Date(
    date.toLocaleString('en-US', {
      timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      fractionalSecondDigits: 3,
    })
  );

  const difference = date.getTime() - sameDateInLosAngeles.getTime();
  return reverseMode ? date.getTime() - difference : date.getTime() + difference;
};

export const capitalizeWords = (inputString: string) => {
  return inputString
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const isFileAllowedToBeUpload = (fileSize: number) => fileSize < MAX_ALLOWED_FILE_SIZE;
