import clsx from 'clsx';

import { ExcelIcon } from '../../components/svgs/ExcelIcon';

import { IExcelButton } from './constants/types';

import styles from './ExcelButton.module.scss';

const ExcelButton = ({ onClick, isButtonVisible, ...props }: IExcelButton) => {
  // onClick function must be include a download logic
  return (
    <button
      className={clsx(styles.excelBtnWrapper, { [styles.disabled]: !isButtonVisible })}
      type="button"
      onClick={() => isButtonVisible && onClick()}
      {...props}
    >
      <ExcelIcon />
    </button>
  );
};

export default ExcelButton;
