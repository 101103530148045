import React, { useDeferredValue } from 'react';
import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import {
  AGENT_COLUMNS_LOAD,
  AGENT_KEYS_LOAD,
} from 'pages/profile/tabs/carriers/components/blacklist/constants/constants';
import Button from 'ui/button/Button';
import SelectDatePicker from 'ui/inputs/select-date-picker/SelectDatePicker';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import ResetIcon from 'components/svgs/ResetIcon';
import Table from 'components/table/Table';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';

import { DOCUMENT_FILTER, LOAD_STATUSES, LOAD_TYPES, SUBJECT } from './constants/constants';
import { FilterSelect, SearchableDropdown } from './hoc-instance/instance';
import { useLoadsTab } from './useLoadsTab';

import styles from './LoadsTab.module.scss';

const LoadsTab = ({ loadData: _loadData, hasEditPermission, setExcelParams }: any) => {
  const {
    data,
    columns,
    filterControl,
    isLoading,
    withRedConf,
    handleRowEdit,
    handleRowClick,
    handleSortClick,
    handleColumnSearch,
    handlePaginationClick,
    handleStopResize,
    handleDownloadClick,
    handleFilterWithRedConfirmation,
    handleResetFilters,
    handleCommentClick,
    setFilterValue,
    filterWatch,
    setAgentsFilter,
    officesOptions,
    finalAgentOptions,
    selectedAgentOption,
    setOfficesFilter,
    setBranchFilter,
    handleLoadFilterStatus,
    selectedOffices,
    selectedBranches,
    skip,
    limit,
    isDirty,
    loadsAgent,
    branchesData,
    roleLevel,
  } = useLoadsTab(setExcelParams);

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onColumnSearch: handleColumnSearch,
    onDownloadClick: handleDownloadClick,
    onCommentClick: handleCommentClick,
    handleRowEdit,
    onPaginationClick: handlePaginationClick,
    hasEditPermission,
    withMenu: hasEditPermission,
  });

  return (
    <>
      <div className={styles.loadsTabContainer}>
        <div
          className={clsx({
            ['filtersWrapperBig']: roleLevel <= IRoleLevel.branchLevel,
            ['filtersWrapper']: roleLevel > IRoleLevel.branchLevel,
          })}
        >
          <div className={styles.loadsTopBlock}>
            <Row align="bottom" gutter={[18, 16]}>
              <Col span={5}>
                <SelectDatePicker fieldName="load" label={SUBJECT.DATE} defaultSelected="currentMonth" />
              </Col>
              {roleLevel === IRoleLevel.corpLevel && (
                <Col span={5}>
                  <FilterSelect
                    isSearchable
                    isMultiSelect
                    withCheckbox
                    options={officesOptions?.result}
                    label={SUBJECT.OFFICE}
                    placeholder={SUBJECT.NOT_SELECTED}
                    name="loadsOffice"
                    control={filterControl}
                    offset={officesOptions.count}
                    setOffset={setOfficesFilter}
                    setValue={setFilterValue}
                    withMarking
                    isAutocomplete
                  />
                </Col>
              )}
              {roleLevel <= IRoleLevel.officeLevel && (
                <Col span={5}>
                  <FilterSelect
                    isSearchable
                    isMultiSelect
                    withCheckbox
                    options={branchesData?.result}
                    label={SUBJECT.BRANCH}
                    placeholder={SUBJECT.NOT_SELECTED}
                    name="loadsBranch"
                    control={filterControl}
                    offset={branchesData.count}
                    setOffset={setBranchFilter}
                    setValue={setFilterValue}
                    withMarking
                    isDisabled={filterWatch('loadsOffice')!.length !== 1 && roleLevel === IRoleLevel.corpLevel}
                    isAutocomplete
                  />
                </Col>
              )}
              {roleLevel <= IRoleLevel.branchLevel && (
                <Col span={5}>
                  <SearchableDropdown
                    options={finalAgentOptions?.result}
                    columns={AGENT_COLUMNS_LOAD}
                    keys={AGENT_KEYS_LOAD}
                    label={SUBJECT.AGENT}
                    placeholder={SUBJECT.NOT_SELECTED}
                    name="loadsAgent"
                    showId
                    control={filterControl}
                    disabled={
                      (roleLevel === IRoleLevel.corpLevel
                        ? selectedOffices.length !== 1 ||
                          selectedBranches.length !== 1 ||
                          selectedBranches?.[0]?.default
                        : selectedBranches.length !== 1 || selectedBranches?.[0]?.default) &&
                      !loadsAgent &&
                      roleLevel <= IRoleLevel.officeLevel
                    }
                    setValue={setFilterValue}
                    values={selectedAgentOption}
                    offset={finalAgentOptions.count}
                    setOffset={setAgentsFilter}
                    showKey="username"
                    width={400}
                  />
                </Col>
              )}
              <Col span={5}>
                <FilterSelect
                  label={SUBJECT.LOAD_TYPE}
                  placeholder={SUBJECT.NOT_SELECTED}
                  control={filterControl}
                  options={LOAD_TYPES}
                  name="loadsByType"
                  isMultiSelect
                  withCheckbox
                />
              </Col>
              <Col span={5}>
                <FilterSelect
                  label={SUBJECT.DOCUMENT}
                  placeholder={SUBJECT.NOT_SELECTED}
                  control={filterControl}
                  options={DOCUMENT_FILTER}
                  name="document"
                  withCheckbox
                  isMultiSelect
                />
              </Col>
              <Col span={5}>
                <FilterSelect
                  label={SUBJECT.LOAD_STATUS}
                  placeholder={SUBJECT.NOT_SELECTED}
                  control={filterControl}
                  options={LOAD_STATUSES}
                  name="loadStatus"
                  withCheckbox
                  onChange={handleLoadFilterStatus}
                  isMultiSelect
                />
              </Col>
              {isDirty && (
                <Col>
                  <div className={styles.reset}>
                    <Button
                      icon={<ResetIcon />}
                      variant="ghost"
                      children={SUBJECT.RESET_ALL}
                      onClick={handleResetFilters}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <Divider />
        <div className={styles.allUsersBlockWrapper}>
          {!isLoading && (
            <Row justify="space-between" className={styles.allUsersBlock}>
              <Row>
                <Col>
                  <span className={styles.filtersInfo}>{SUBJECT.NUMBER_OF_LOAD}: </span>
                  <span className={styles.filtersCount}>{data?.count}</span>
                </Col>
                <Col>
                  <Divider type="vertical" />
                </Col>
                <Col>
                  <span className={styles.filtersInfo}>{SUBJECT.LOAD_AVERAGE}: </span>
                  <span className={styles.filtersCount}>{`${formatNumberWithThousandsSeparatorAndDecimal(
                    data?.average,
                    true
                  )}`}</span>
                </Col>
                <Col>
                  <Divider type="vertical" />
                </Col>
                <Col>
                  <span className={styles.filtersInfo}>{SUBJECT.GROSS_PROFIT}: </span>
                  <span className={styles.filtersCount}>{`${formatNumberWithThousandsSeparatorAndDecimal(
                    data?.grossProfit,
                    true
                  )} `}</span>
                </Col>
                <Col>
                  <Divider type="vertical" />
                </Col>
                <Col>
                  <span className={styles.filtersInfo}>{SUBJECT.POTENTIAL_GROSS_PROFIT}: </span>
                  <span className={styles.filtersCount}>{`${formatNumberWithThousandsSeparatorAndDecimal(
                    data?.potentialGrossProfit,
                    true
                  )} `}</span>
                </Col>
              </Row>
              <Row onClick={handleFilterWithRedConfirmation}>
                <Col className={styles.redConfirmationBlock}>
                  <span className={styles.filtersInfo}>{SUBJECT.LOAD_RED_CONFIRMATION}:</span>
                  <span
                    className={clsx(styles.filtersCountRed, {
                      [styles.filterActive]: withRedConf,
                    })}
                  >
                    {data?.withoutRedConfirmation}
                  </span>

                  {withRedConf && <ResetIcon />}
                </Col>
              </Row>
            </Row>
          )}
        </div>
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default LoadsTab;
