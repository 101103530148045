import React from 'react';

const ResetIcon = () => {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.97605 19.0568C5.92605 18.8901 4.20105 18.0484 2.80105 16.5318C1.40105 15.0151 0.70105 13.2151 0.70105 11.1318C0.70105 9.84843 0.996883 8.6401 1.58855 7.50676C2.18022 6.37343 3.00938 5.44843 4.07605 4.73176L5.15105 5.80676C4.21772 6.35676 3.49272 7.11093 2.97605 8.06926C2.45938 9.0276 2.20105 10.0484 2.20105 11.1318C2.20105 12.7984 2.75105 14.2401 3.85105 15.4568C4.95105 16.6734 6.32605 17.3734 7.97605 17.5568V19.0568ZM9.47605 19.0568V17.5568C11.1427 17.3568 12.5177 16.6526 13.601 15.4443C14.6844 14.2359 15.226 12.7984 15.226 11.1318C15.226 9.3151 14.5969 7.7776 13.3386 6.51926C12.0802 5.26093 10.5427 4.63176 8.72605 4.63176H8.22605L9.72605 6.13176L8.65105 7.20676L5.32605 3.88176L8.65105 0.556763L9.72605 1.63176L8.22605 3.13176H8.72605C10.9594 3.13176 12.8511 3.91093 14.4011 5.46926C15.9511 7.0276 16.726 8.9151 16.726 11.1318C16.726 13.2151 16.0302 15.0151 14.6385 16.5318C13.2469 18.0484 11.526 18.8901 9.47605 19.0568Z"
        fill="#98A2B2"
      />
    </svg>
  );
};

export default ResetIcon;
