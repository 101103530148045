import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import type { RefSelectProps } from 'antd';
import { Col, Row, Select } from 'antd';
import clsx from 'clsx';
import useDebounce from 'hooks/useDebounce';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import CloseIcon from 'components/svgs/CloseIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';

import styles from './CustomSearchSelect.module.scss';

const CustomSearchSelect = forwardRef(
  (
    {
      suffixIcon,
      options = [],
      placeholder = '',
      label,
      required,
      errors,
      name,
      onBlur,
      value,
      onChange,
      setValue,
      resetFunc,
      setOffset,
      offset,
      ...props
    }: any,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const [inputValue, setInputValue] = useState('');

    const debounceValue = useDebounce(inputValue, 500);

    const selectRef = useRef<RefSelectProps>(null);
    const dropdownRef = useRef<HTMLInputElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      if (debounceValue?.length >= 2 || !debounceValue?.length) {
        setOffset((prev: any) => ({ ...prev, search: debounceValue, limit: 10, field: debounceValue ? name : '' }));
      }
    }, [debounceValue]);

    const handleSearch = (value: any) => {
      setInputValue(value);
    };

    const handleBlur = () => {
      if (onBlur) {
        onBlur();
        setTimeout(() => {
          setIsOpen(false);
        }, 300);
      }
    };

    const addItem = () => {
      if (inputValue) {
        setValue(name, inputValue, { shouldDirty: true, shouldValidate: true });
        resetFunc();

        handleBlur();
      }
    };

    const handleScroll = (event: any) => {
      const { target } = event;
      if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
        if (offset && options?.length < offset) {
          setOffset((prev: any) => {
            return { ...prev, limit: prev.limit + 10 };
          });
        }
      }
    };

    return (
      <div className={styles.selectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          id={`custom-search-select-dropdown_${name}`}
          className={clsx(styles.selectWrapper, {
            [styles.selected]: value?.length,
            [styles.required]: required,
            [styles.error]: errors && errors[name || ''],
          })}
          ref={dropdownRef}
        >
          <Select
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            getPopupContainer={() => document.getElementById(`custom-search-select-dropdown_${name}`)!}
            optionLabelProp="label"
            optionFilterProp="label"
            suffixIcon={suffixIcon || <SelectArrowIcon />}
            className={styles.select}
            ref={selectRef || ref}
            showSearch
            onSearch={handleSearch}
            placeholder={label ? placeholder : null}
            options={options}
            onClick={() => setIsOpen(true)}
            onBlur={handleBlur}
            open={isOpen}
            allowClear={!!inputValue.length}
            onPopupScroll={handleScroll}
            clearIcon={
              inputValue?.length || value?.length ? (
                <div className={styles.clearIcon}>
                  <CloseIcon />
                </div>
              ) : null
            }
            onClear={() => {
              setValue(name, '', { shouldValidate: true });
              resetFunc();
            }}
            onChange={(value: string, option: any) => {
              onChange(option);
              setTimeout(() => {
                setIsOpen(false);
              }, 122);
            }}
            value={value || null}
            dropdownRender={menu => (
              <>
                {menu}
                <div>
                  <div className={styles.enterItem}>
                    <Row gutter={[8, 4]}>
                      <Col span={24}>
                        <Button variant="text" icon={<PlusOutlined />} onClick={addItem}>
                          {'Add item'}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
            {...props}
          />
          {!label && (
            <Typography variant="paragraph" className={`placeholder-select ${styles.placeholder}`}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);
export default CustomSearchSelect;
