import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { ISearchResultFilter } from 'pages/truck-board/constants/types';
import {
  useDeleteTruckBoardResultListItemMutation,
  useLazyGetTruckBoardResultListByIdQuery,
  useLazyGetTruckBoardResultListByIdTrucksQuery,
} from 'services/truck-board/truck-board/truckBoard';
import { useEditColumnsMutation, useLazyGetColumnsQuery } from 'services/user/user';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { selectUserPermissionsInfo } from '../../../../store/user-slice/selector';
import { searchResultFilterDefaultValues, truckBoardTabColumnsMap } from '../../constants/constants';
import { ISingleFilterState } from '../truck-board-single/constants/types';
import { SUBJECT } from '../view-edit-carrier-truck/components/add-coordinator/constants/constants';

const useTruckBoardResult = ({ setSearchResultFilter }: any) => {
  const dispatch = useDispatch();
  const searchResultsTableRef = useRef<null | HTMLDivElement>(null);

  const {
    control: searchResultFilterControl,
    watch: searchResultFilterWatch,
    setValue: searchResultFilterSetValue,
    reset: resetResultFilter,
  } = useForm<any>({
    defaultValues: searchResultFilterDefaultValues,
    mode: 'onChange',
  });

  const { searchParams, setSearchParams } = useDetectedParams();
  const { equipmentType, trailerDetails, status, singleStatus, isSingle, id } = searchParams;

  const [getSearchResultsColumns, { data: searchResultsColumns }] = useLazyGetColumnsQuery();
  const [editColumns] = useEditColumnsMutation();

  const [editTruckBoardResultColumns] = useEditColumnsMutation();
  const [deleteTruckBoardResultListItem] = useDeleteTruckBoardResultListItemMutation();

  const equipmentTypeFilter = searchResultFilterWatch('equipmentType')?.toString();
  const trailerDetailsFilter = searchResultFilterWatch('trailerDetails')?.toString();
  const statusFilter = searchResultFilterWatch('status');

  const equipmentTypeQueryCheck = !!(typeof equipmentTypeFilter === 'string' && equipmentTypeFilter);
  const trailerDetailsQueryCheck = !!(typeof trailerDetailsFilter === 'string' && trailerDetailsFilter);

  const { userType, employeeLevel } = useSelector(selectUserPermissionsInfo);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(equipmentTypeQueryCheck ? { equipmentType: equipmentTypeFilter } : { equipmentType: '' }),
      ...(trailerDetailsQueryCheck ? { trailerDetails: trailerDetailsFilter } : { trailerDetails: '' }),
      status: statusFilter,
    });
  }, [equipmentTypeFilter, trailerDetailsFilter, statusFilter]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      status: searchParams?.status || '1',
    });
  }, []);

  useEffect(() => {
    const filterOptions = [
      { key: 'equipmentType', value: equipmentType ? equipmentType?.split(',') : equipmentType },
      { key: 'trailerDetails', value: trailerDetails ? trailerDetails?.split(',') : trailerDetails },
      { key: 'status', value: status },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        searchResultFilterSetValue(key as keyof ISearchResultFilter, value);
      }
      setSearchResultFilter((prev: any) => ({
        ...prev,
        skip: 0,
        filter: {
          ...prev.filter,
          truckBoardStatus: status,
          equipmentTypes: equipmentType?.split(','),
          trailerDetails: trailerDetails?.split(','),
        },
      }));
    });
  }, [equipmentType, trailerDetails, status]);

  const [searchResultsFilter, setSearchResultsFilter] = useState<Partial<ISingleFilterState>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'availableAt',
    filter: {},
  });

  const [getSearchResultData, { data: searchResultsData, isFetching: isSearchResultsLoading }] =
    useLazyGetTruckBoardResultListByIdTrucksQuery();

  const [getTruckBoardResultByIdQuery, { data: searchInfoData, isFetching: isSearchInfoFetching }] =
    useLazyGetTruckBoardResultListByIdQuery();
  const handleRowClick = useCallback(
    (rowId: number | string) => {
      setSearchParams({
        ...searchParams,
        status,
        open: 'false',
        singleStatus: status,
        ...(Number(rowId) === Number(id) && status === singleStatus
          ? { id: '', isSingle: '' }
          : { id: String(rowId), isSingle: 'true' }),
      });
      Promise.allSettled([
        getTruckBoardResultByIdQuery({ id: rowId, status }),
        getSearchResultsColumns({ type: truckBoardTabColumnsMap[status] }),
        getSearchResultData({ ...searchResultsFilter, id: rowId, truckBoardStatus: status }),
      ]);
    },
    [status, id, singleStatus]
  );

  useEffect(() => {
    if (searchResultsTableRef && searchResultsTableRef?.current) {
      searchResultsTableRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [searchResultsTableRef, searchResultsTableRef?.current, id]);

  useEffect(() => {
    if (isSingle) {
      Promise.allSettled([
        getTruckBoardResultByIdQuery({ id, status: singleStatus }),
        getSearchResultsColumns({ type: truckBoardTabColumnsMap[singleStatus] }),
        getSearchResultData({ ...searchResultsFilter, id, truckBoardStatus: singleStatus }),
      ]);
    }
  }, [singleStatus, isSingle, searchResultsFilter]);

  const handleRowEdit = (rowId: number | string) => {
    setSearchParams({
      ...searchParams,
      currentPage: 'create',
      isEdit: 'true',
      status: status || statusFilter || '1',
      editId: String(rowId),
    });
  };

  const handleRowDelete = (rowId: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteTruckBoardResultListItem({ id: rowId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
            setSearchParams({
              ...searchParams,
              isSingle: '',
              id: '',
            });
            dispatch(closeModal());
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setSearchResultFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'availableAtFrom',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setSearchResultFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setSearchResultFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleStopResize = (columns: any) => {
    editTruckBoardResultColumns({ type: 'truckBoard', columns });
  };

  const handleResetSearchResultFilter = () => {
    resetResultFilter();
    setSearchParams({
      ...searchParams,
      id: id || '',
      isSingle: isSingle || '',
      singleStatus: singleStatus || '',
    });
    setSearchResultFilter({
      search: '',
      field: '',
      skip: 0,
      limit: 10,
      order: 2,
      orderBy: 'availableAtFrom',
      filter: { truckBoardStatus: '1' },
    });
  };

  const handleStopResizeSearchResult = (columns: any): void => {
    editColumns({ type: truckBoardTabColumnsMap[status], columns });
  };

  return {
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handleColumnSearch,
    handlePaginationClick,
    handleRowEdit,
    handleRowDelete,
    handleResetSearchResultFilter,
    searchResultFilterWatch,
    searchResultFilterControl,
    userType,
    employeeLevel,
    isSingle,
    singleStatus,
    status,
    id,
    searchInfoData,
    isSearchInfoFetching,
    searchResultsData,
    searchResultsColumns,
    isSearchResultsLoading,
    searchResultsFilter,
    handleStopResizeSearchResult,
    setSearchResultsFilter,
    searchResultsTableRef,
  };
};
export default useTruckBoardResult;
