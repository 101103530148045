import clsx from 'clsx';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import { ROUTES } from 'components/routes/routes';
import BoxIcon from 'components/svgs/BoxIcon';
import DropAreaIcon from 'components/svgs/DropAreaIcon';
import Map from 'components/svgs/Map';
import NotFoundIcon from 'components/svgs/NotFoundIcon';
import NotFoundLine from 'components/svgs/NotFoundLine';

import { SUBJECT } from './constants/constants';
import { useNotFound } from './useNotFound';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const { xBox, yBox, xArea, yArea, dragState, boxElement, areaElement, lineElement, handleDragEnd, handleDragStart } =
    useNotFound();

  return (
    <div className={styles.notFoundWrapper}>
      <div
        className={clsx(styles.linWrapper, {
          [styles.hideLine]: dragState,
        })}
        ref={lineElement}
      >
        <NotFoundLine />
      </div>
      <div className={styles.mapImage}>
        <Map />
      </div>
      <div
        className={styles.boxIcom}
        ref={boxElement}
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        style={{
          position: 'absolute',
          left: xBox,
          top: yBox,
        }}
      >
        <Typography variant="paragraph" className={styles.loseText}>
          {SUBJECT.I_LOST_MY_ADDRESS}
        </Typography>
        <BoxIcon />
      </div>
      <div
        className={styles.dropArea}
        ref={areaElement}
        style={{
          position: 'absolute',
          left: xArea,
          top: yArea,
        }}
      >
        <DropAreaIcon />
      </div>
      <div className={styles.info}>
        <NotFoundIcon />
        <Button variant="dark" href={ROUTES.HOME}>
          {SUBJECT.GO_HOME}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
