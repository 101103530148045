import CustomDatePicker from 'ui/inputs/custom-date-picker/CustomDatePicker';
import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { IRadioProps } from 'ui/inputs/radio/constants/types';
import CustomRadio from 'ui/inputs/radio/Radio';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import CustomSelect from 'ui/inputs/select/Select';
import { ISwitchProps } from 'ui/inputs/switch/constants/types';
import CustomSwitch from 'ui/inputs/switch/Switch';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { IBlacklistFilter } from '../components/blacklist/constants/types';
import { ICarriersFilter, ICarriersFormData } from '../constants/types';

export const Input = withController<IInputProps, ICarriersFormData>(CustomInput);

// export const Select = withController<ISelectProps, ICarriersFormData>(CustomSelect);

export const Radio = withController<IRadioProps, ICarriersFormData>(CustomRadio);

export const FiltersSelect = withController<ISelectProps, ICarriersFilter>(CustomSelect);

export const BlacklistSelect = withController<ISelectProps, IBlacklistFilter>(CustomSelect);

export const SearchableDropdown = withController<ISearchableDropdownProps, IBlacklistFilter>(CustomSearchableDropdown);
export const DatePicker = withController<any, any>(CustomDatePicker);

export const SearchableSelect = withController<ISearchableSelectProps, IBlacklistFilter>(CustomSearchableSelect);
export const Switch = withController<ISwitchProps, any>(CustomSwitch);
