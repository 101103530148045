import { IButtonTypes } from './interfaces';

export enum SORTING {
  NAME = 'sort',
  ASC = 1,
  DESC = 2,
  DEFAULT = 0,
}

export enum TAG_NAME {
  SPAN = 'SPAN',
}

export enum SUBJECT {
  ALREADY_PAID = 'Already Paid',
  NO_PERMISSION_DELETE = "You don't Have Permission For Delete",
  NO_PERMISSION_EDIT = "You don't Have Permission For Edit",
  NO_PERMISSION_PAY = "You don't Have Permission For Pay",
  NO_PERMISSION_RECEIVE = "You don't Have Permission For Receive Invoice",
  NO_PERMISSION_APPROVE = "You don't Have Permission For Approve",
  TOOLTIP_LENGTH = 'Tooltip Text Length',
  RATE_CONFIRMATION = 'Rate Confirmation',
  RATE_CONFIRMATION_COL = 'Rate confirmation',
  POD = 'POD',
  DIFFERENCE = '-100.00',
  PAID = 'Paid',
  PAY = 'Pay',
  APPROVE = 'Approve',
  APPROVED = 'Approved',
  MARK_AS_CLAIM = 'Mark As Claim',
  NEW = 'new',
  RETRIEVED = 'Retrieved',
  INVOICE_SENT = 4,
  RECEIVABLES = 'Receivables',
  BY_PAYMENT_TERM = 'Payment Term Changed',
  RESOLVED = 'Resolved',
  CLAIMED = 'Claimed',
  EDIT = 'Edit',
  PAYMENT_PLAN = 'Payment Plan',
  DEFAULT = 'default',
  RECEIVE_INVOICE = 'Receive Invoice',
  TABLE_DARK = 'tableDark',
  PENDING = 'Pending',
}

export enum CARRIER_STATUS {
  'Standard' = 1,
  'Already has a load' = 2,
  'Refused to reload' = 3,
}

export enum BADGE_STATUS_TYPE {
  'standard' = 1,
  'widthLoad' = 2,
  'new' = 3,
}

export enum CARRIER_STATUS_COLOR {
  '#A1B0CC' = 1,
  '#FBBC05' = 2,
  '#FF5454' = 3,
}

export enum LoadRequestStatus {
  REQUEST_STATUS_TYPES_UNSPECIFIED = 0,
  REQUEST_STATUS_TYPES_PENDING = 1,
  REQUEST_STATUS_TYPES_APPROVE = 2,
  REQUEST_STATUS_TYPES_DENY = 3,
  REQUEST_STATUS_TYPES_COD = 4,
  REQUEST_STATUS_TYPES_CONSIGNMENT = 5,
}

export const buttonTypes: IButtonTypes = {
  edit: {
    size: 'small',
    variant: 'comment',
    children: 'Edit',
    onClick: 'handleRowEdit',
  },
  delete: {
    size: 'small',
    variant: 'outlinedDanger',
    children: 'Delete',
    onClick: 'handleRowDelete',
  },
};
