import { ICreator } from 'services/auth/interface';

export enum ICarrierType {
  'Owner Operator' = 1,
  'Outside' = 2,
}

export enum CarrierFiles {
  // FILE_TYPES_CARRIER_PACKET = 1,
  // FILE_TYPES_SIGN_AGREEMENT = 2,
  // FILE_TYPES_PAID = 3,
  // FILE_TYPES_INSURANCE = 4,
  // FILE_TYPES_VOIDED_CHECK_BANK_LATTER = 5,
  // FILE_TYPES_CARRIER_BREAKDOWN_EMPLOYEE = 6,
  // FILE_TYPES_CARRIER_BREAKDOWN_AGENT = 7,
  // FILE_TYPES_COMPANY_OWNER_ID = 8,
  // FILE_TYPES_W9 = 9,
  'Carrier Packet' = 1,
  'Signed Agreement' = 2,
  'W9' = 9,
  'Insurance' = 4,
  'Voided Check/Bank Latter' = 5,
  'Company Owner Ids' = 8,
}

export enum RatingAccessTypes {
  NOT_ACCESS = 0,
  ADD_RATING = 1,
  EDIT_RATING = 2,
}

export enum IFileTypes {
  carrierPacketFile = 1,
  signAgreementFile = 2,
  // paidAsW9File = 3,
  insuranceFile = 4,
  voidedCheckBankLatterFile = 5,
  companyOwnerIdFile = 8,
  paidAsW9File = 9,
}

export enum FileTypesConst {
  'carrierPacketFile' = 1,
  'signAgreementFile' = 2,
  'paidAsW9File' = 3,
  'insuranceFile' = 4,
  'voidedCheckBankLatterFile' = 5,
  'companyOwnerIdFile' = 8,
}

// TODO CARIER FILE TYPES WHEN GET BY ID

export enum ICarrierFillFileTypes {
  carrierPacketFile = 1,
  signAgreementFile = 0,
  insuranceFile = 6,
  voidedCheckBankLatterFile = 3,
  companyOwnerIdFile = 4,
  paidAsW9File = 2,
}

export enum ICarrierStatus {
  'Standart' = 1,
  'Pending' = 2,
  'Denied' = 3,
}

export enum ICarrierStatusForCarrier {
  'Standart' = 1,
  'Denied' = 2,
}

export enum CarrierPaymentTermType {
  BROKERAGE = 1,
  FREIGHTMAX = 2,
}
export enum CarrierPaymentTermTypeText {
  Brokerage = 1,
  FreightMax = 2,
}

export enum CarrierFactoringType {
  NOT_SPECIFIED = 0,
  FACTORED = 1,
  NOT_FACTORED = 2,
}

export enum CarrierPaymentType {
  NOT_SPECIFIED = 0,
  DIRECT_DEPOSIT = 1,
  PAPER_CHECK = 2,
  FUEL_CARD = 3,
}

export enum BrokerPaymentTermsType {
  NOT_SPECIFIED = 0,
  DAY = 2,
  QUICK = 1,
}

export enum BrokerPaymentTermsTypeTable {
  '30 Day' = 2,
  'Quick pay' = 1,
}

export enum CarrierPaymentTermFileTypes {
  CARRIER_PACKAGE = 1,
  SIGN_AGREEMENT = 2,
  VOIDED_CHECK = 3,
  NOA = 4,
  LETTER_OF_RELEASE = 5,
}
export enum CarrierPaymentTermFileTypesTexts {
  'Unknown' = 0,
  'Carrier Package' = 1,
  'Sign Agreement' = 2,
  'Voided Check' = 3,
  'NOA' = 4,
  'Letter Of Release' = 5,
}
export enum IPaymentType {
  'Factored' = 1,
  'Not Factored' = 2,
}

export const CarrierPaymentTermFiles = ['1', '2', '3', '4', '5', '6'];

export interface CarrierPaymentTermFile {
  fileId: number;
  type: CarrierPaymentTermFileTypes;
}

export interface IAuthorityId {
  createdAt: number;
  id: number;
  mc: string;
  name: string;
  type: number;
  typeName: string;
}

export interface ICustomerId {
  id: number;
  title: string;
  value: string;
  name: string;
  mc: string;
  phone: string;
}

export interface CarrierPaymentTerms {
  id: number | string | null;
  paymentPaidAs: string;
  showFiles: any;
  showAgreement: any;
  iAgree: boolean;
  type: CarrierPaymentTermType;
  authorityId?: number | IAuthorityId[];
  customerId?: number | ICustomerId[];
  factoringType: CarrierFactoringType;
  factoredCompanyName?: any[];
  factoredEmail?: [] | string;
  factoredPhone?: string;
  factoredAddress?: string;
  factoredSecondAddress?: string;
  factoredFax?: string;
  factoredCity?: string;
  factoredStateId?: number;
  factoredZip?: string;
  paymentType: CarrierPaymentType;
  paymentBankName: string;
  paymentRoutingNumber: string;
  paymentAccountNumber?: string;
  paymentCompanyName?: string;
  paymentAddress?: string;
  paymentSecondAddress?: string;
  paymentCity?: string;
  paymentStateId?: string;
  paymentZip?: string;
  paymentFuelCard?: string;
  brokerPaymentTermType: BrokerPaymentTermsType;
  day?: number | string;
  percent?: number | string;
  brokerEmails: Array<string>;
  files: Array<CarrierPaymentTermFile>;
  carrierFiles: Array<CarrierPaymentTermFile>;
  carrierFilesFreightmax?: Array<CarrierPaymentTermFile>;
  agreement: Array<CarrierPaymentTermFile>;
  createdAt: Date | string;
  createdBy: number;
  fuelAdvance: boolean;
  quickPay: boolean;
  liveTruckBoard: boolean;
  loadBoard: boolean;
  factoring: boolean;
}

export interface ICarrierByIdData {
  address: string;
  cargo: number;
  city: string;
  commentCount: number;
  companyName: string;
  contactName: string;
  createdAt: string;
  createdBy: number;
  creator: ICreator;
  dot: string;
  email: string;
  fax: string;
  filesCounts: {
    type: number;
    count: number;
    order: number;
  }[];
  id: number;
  liability: number;
  mc: string;
  phone: string;
  rating: number;
  secondPhone: string;
  stateId: number;
  status: number;
  truckCount: number;
  type: number;
  updatedAt: number | Date;
  zip: string;
  ratingCount: number;
  accessRating: number;
  paymentTerms: CarrierPaymentTerms[];
  equipments: any;
}

export interface ICarrierRatingParams {
  rating: number | string;
  loadId: number;
  carrierId: number | string;
  ratingId: string | number;
  skip: number | string;
  limit: number | string;
}

export enum TruckTypes {
  TRUCK_TYPES_UNSPECIFIED = 0,
  TRUCK_TYPES_VAN = 1,
  TRUCK_TYPES_REEFER = 2,
  TRUCK_TYPES_FLAT_BED = 3,
  TRUCK_TYPES_POWER_ONLY = 4,
  TRUCK_TYPES_BOX_TRUCK = 5,
}

export interface StateDomain {
  id: number;
  name: string;
}

export enum LoadPointTypes {
  LOAD_POINT_TYPES_UNSPECIFIED = 0,
  LOAD_POINT_TYPES_PICK_UP = 1,
  LOAD_POINT_TYPES_DROP_OFF = 2,
}

export interface LoadPointShort {
  loadId: number;
  city: string;
  stateId: number;
  createdAt: number;
  type: LoadPointTypes;
}

export interface LoadPointShortFull extends LoadPointShort {
  state: StateDomain;
}

export interface LoadByCarrierIdFull {
  id: number;
  carrierId: number;
  truckId: number;
  amount: number;
  createdAt: number;
  truckType: TruckTypes;
  pickUps: Array<LoadPointShortFull>;
  dropOffs: Array<LoadPointShortFull>;
}

export interface GetLastLoadsByCarrierIdResult {
  result: Array<LoadByCarrierIdFull>;
  count: number;
}
