import React from 'react';
import CarrierInfo from 'pages/truck-board/components/carrier-info/CarrierInfo';
import { MainLoader } from 'ui/main-loader/MainLoader';

import CustomComment from 'components/custom-comment/CustomComment';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import LastLoadsTable from './components/last-loads-table/LastLoadsTable';
import TruckInfo from './components/truck-info/TruckInfo';
import { SUBJECT } from './constants/constants';
import useViewEditCarrierTruck from './useViewEditCarrierTruck';

const ViewCarrierTruck = ({
  createComment,
  deleteComment,
  carrierInfo,
  getMoreComments,
  isShowMoreVisible,
  isLoadsCommentsLoading,
  carrierCommentsInfo,
  hasDeleteCommentPermission,
  handleCarrierLasLoadsPaginationClick,
  isCarrierLastLoadsLoading,
  carrierLastLoads,
  isFetchingCarrierInfo,
}: any) => {
  const { commentsWatch, commentsControl, commentsSetValue, handleScrollToHistory, drawerRef, truckInfoById } =
    useViewEditCarrierTruck();

  return (
    <>
      <MainLoader isFetching={isCarrierLastLoadsLoading || isFetchingCarrierInfo} />
      <CarrierInfo
        carrierInfo={{ ...carrierInfo, commentCount: carrierCommentsInfo?.count }}
        handleScrollToHistory={handleScrollToHistory}
      />
      <TruckInfo truckInfo={truckInfoById} carrierInfo={carrierInfo} />
      {!!carrierLastLoads?.result?.length && (
        <ViewItemWrapper title={SUBJECT.LAST_LOADS}>
          <LastLoadsTable
            isLoading={isCarrierLastLoadsLoading}
            carrierLastLoads={carrierLastLoads}
            onPaginationClick={handleCarrierLasLoadsPaginationClick}
          />
        </ViewItemWrapper>
      )}
      {!!carrierCommentsInfo?.count && (
        <div ref={drawerRef}>
          <CustomComment
            id="comment"
            watch={commentsWatch}
            name="comment"
            setValue={commentsSetValue}
            control={commentsControl}
            comments={carrierCommentsInfo}
            createComment={createComment}
            deleteComment={deleteComment}
            getMoreComments={getMoreComments}
            isShowMoreVisible={isShowMoreVisible}
            canCreate={false}
            canDelete={false}
            hasDeleteCommentPermission={hasDeleteCommentPermission}
            isLoadsCommentsLoading={isLoadsCommentsLoading}
          />
        </div>
      )}
    </>
  );
};

export default ViewCarrierTruck;
