export enum SUBJECT {
  INTERNAL_LOAD = 'Internal Load',
  FACILITY = 'Facility',
  ADDITIONAL = 'Additional',
  UPLOADED_RED_CONFIRMATION = 'Uploaded Rate Confirmation',
  UPLOADED_POD = 'Uploaded POD',
  TAKEN_AS_MC = 'Taken As MC',
  LOAD_ID = 'Load ID',
  LOAD_STATUS = 'Load Status',
  OFFICE = 'Agency',
  OFFICE_ID = 'Agency ID',
  BRANCH = 'Branch',
  BRANCH_ID = 'Branch ID',
  AGENT = 'Agent',
  AGENT_ID = 'Agent ID',
  CUSTOMER_NAME = 'Customer Name',
  CUSTOMER_ID = 'Customer ID',
  PICKUPS = 'Pick Ups',
  DROP_OFFS = 'Drop Offs',
  CARRIER_NAME = 'Carrier Name',
  CARRIER_ID = 'Carrier ID',
  LOAD_TYPE = 'Load Type',
  REFERENCE_NUMBER = 'Reference Number',
  CUSTOMER_AMOUNT = 'Customer Amount',
  CUSTOMER_CONTACT = 'Phone Number',
  CARRIER_AMOUNT = 'Carrier Rate',
  TRUCK = 'Truck',
  ALL_AUTHORITIES = 'All Authorities',
  CARRIER = 'Carrier Detail',
  CARRIER_RATE = 'Carrier Rate',
  PROFIT = 'Profit',
  SOLID_AS_MC = 'Solid as MC',
  FACTORED = 'Factored',
  CUSTOMER_DEBT = 'Customer Debt',
  CUSTOMER_INVOICE_PAYMENT_AMOUNT = 'Customer Invoice Payment Amount',
  CUSTOMER_INVOICE = 'Customer invoice',
  CARRIER_PAYMENT_BREAKDOWN = 'Carrier Payment Breakdown',
  COMMENTS = 'Comments',
  EDIT = 'Edit',
  DOCUMENTATION = 'Documentation',
  LOAD_FINANCE = 'Load Finance',
  CUSTOMER_BALANCE = 'Customer Balance',
  INITIAL_RATE_CUSTOMER = 'Initial Rate / Customer',
  FINAL_RECEIVABLE_CUSTOMER = 'Final Receivable / Customer',
  AMOUNT_PAID_CUSTOMER = 'Amount Paid / Customer',
  CARRIER_BALANCE = 'Carrier Balance',
  INITIAL_RATE_CARRIER = 'Initial Rate / Carrier',
  FINAL_PAYABLE_CARRIER = 'Final Payable / Carrier',
  AMOUNT_PAID_CARRIER = 'Amount Paid / Carrier',
  AGENT_BALANCE = 'Agent Balance',
  FINAL_PROFIT = 'Final Profit',
  TRANSACTION = 'Transaction',
  TAKEN_AS = 'Taken As Authority',
  TAKEN_AS_TYPE = 'Taken As',
  LOAD_INFORMATION = 'Load Detail',
  GIVEN_AS = 'Given As',
  COMMODITY = 'Commodity',
  BOOKED_AS = 'Booked As',
  SOLD_AS = 'Sold As',
  WEIGHT = 'Weight',
  FEET_OF_PARTIAL = 'Feet Of Partial',

  PICKUPS_AND_DROP_OFFS = 'Pick Ups & Drop Offs',
  AGENT_AMOUNT = 'Agent Amount',
  CONNECTING_LOADS = 'Connecting Loads',
  ADDED_BY = 'Added By',
  DATE = 'Date',
  DATE_AND_TIME = 'Date And Time',
  USERNAME = 'Username',
  CUST_AMOUNT = 'Customer Amount',
  BALANCE_AMOUNT = 'Balance Amount',
  COMMENT = 'Comment',
  ADDRESS = 'Address',
  CITY = 'City',
  ZIP_CODE = 'ZIP Code',
  STATE = 'State',
  ARE_YOU_SURE_CLAIM = 'Are You Sure You Want To Mark As Claim?',
  ARE_YOU_SURE_LOAD = 'Are You Sure You Want To Cancel Load?',
  YES = 'Yes',
  NO = 'No',
  CANCEL = 'Cancel',
  GENERAL_INFO = 'General Info',
  HISTORY = 'History',
  CUSTOMER = 'Customer Detail',
  RED_CONFIRMATION = 'Customer Rate Confirmation',
  LOAD_TRANSACTION = 'Transactions',
  REFRIGERATOR_CELSIUS = 'Refrigerator Fahrenheit',
  VIEW_CUSTOMER_ID = 'customer-id',
  RATE_CONFIRMATION_ID = 'rate-confirmation-id',
  LOAD_INFORMATION_ID = 'load-information-id',
  VIEW_PICKUPS_DROPOFFS_ID = 'pickup-dropoffs-id',
  LOAD_TRANSACTION_ID = 'load-transaction-id',
}
