import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useResetColumns from 'hooks/useResetColumns';
import { TSkipLimit } from 'pages/loads/tabs/new-load/constants/types';
import { useGetPaymentTermsQuery, useLazyGetPaymentTermsByCustomerIdAndAuthorityIdQuery } from 'services/loads/loads';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { closeModal } from 'store/modal-slice/modals';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';

import { usePermittedActions } from '../../../../hooks/usePermittedActions';

import McAndCustomersView from './components/mc-and-customers-view/McAndCustomersView';
import { SUBJECT } from './constants/constants';

const useMcAndCustomers = (setExcelParams: any) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode } = searchParams;

  const { data: columnsData } = useGetColumnsQuery({ type: 'creditCheckMcAndCustomers' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [mcAndCustomersState, setMcAndCustomersState] = useState<TSkipLimit>({
    skip: 0,
    limit: 20,
    level: 0,
    search: '',
    field: '',
  });

  const { data = [], isFetching: isLoading } = useGetPaymentTermsQuery(mcAndCustomersState);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...mcAndCustomersState,
        };
      });
    }
  }, [mcAndCustomersState]);

  const [getPaymentTermsByCustomerIdAndAuthorityId, { currentData: paymentTermById, isFetching }] =
    useLazyGetPaymentTermsByCustomerIdAndAuthorityIdQuery();

  const { download } = usePermittedActions('creditCheck.mcAndCustomers');

  const handleRowClick = (rowId: string, { customerId, authorityId }: any) => {
    setSearchParams({
      ...searchParams,
      mode: 'view',
      open: 'true',
      id: rowId,
    });
    getPaymentTermsByCustomerIdAndAuthorityId({ customerId, authorityId });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setMcAndCustomersState((prev: TSkipLimit) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleColumnSearch = (value: string, column: string) => {
    setMcAndCustomersState((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleCancel = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
    });
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
    });
    dispatch(closeModal());
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: 'creditCheckMcAndCustomers',
    });

  const handleStopResize = (columns: any) => {
    editColumns({ type: 'creditCheckMcAndCustomers', columns });
  };

  useEffect(() => {
    if (columnsData?.columns?.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    view: {
      component: <McAndCustomersView isFetching={isFetching} data={paymentTermById?.result} />,
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [mode, setDragColumns, dragColumns, paymentTermById, isFetching, isLoadingColumnsEdit, isLoadingColumnsDelete]);

  return {
    isLoading,
    data,
    columns: columnsData?.columns,
    handleRowClick,
    handlePaginationClick,
    handleStopResize,
    currentChildren,
    skip: mcAndCustomersState.skip,
    limit: mcAndCustomersState.limit,
    download,
    handleColumnSearch,
  };
};

export default useMcAndCustomers;
