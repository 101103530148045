import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { getPaymentListSerializer, getPaymentsParamsSerializer } from './serializers';

export const paymentAPI = createApi({
  reducerPath: 'paymentAPI',
  baseQuery,
  tagTypes: ['Payment'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getPayment: builder.query({
      query: (params: Partial<any>) => {
        const queryParams = getPaymentsParamsSerializer(params);

        return {
          url: '/payrolls/payment',
          params: queryParams,
        };
      },
      transformResponse: body => getPaymentListSerializer(body),
      providesTags: ['Payment'],
    }),
    getPaymentById: builder.query({
      query: ({ id }: { id: string }) => {
        return {
          url: `/payrolls/payment/${id}`,
        };
      },
    }),
    changePaymentStatus: builder.mutation({
      query: ids => ({
        url: `/payrolls/payment/status`,
        method: 'PUT',
        body: ids,
      }),
      invalidatesTags: ['Payment'],
    }),
  }),
});

export const { useGetPaymentQuery, useGetPaymentByIdQuery, useChangePaymentStatusMutation } = paymentAPI;
