const TruckBoardIcon = () => {
  return (
    <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.4857 22.5521L23.0781 21.1065L25.1559 18.9381H13.5268C13.3034 19.81 12.8398 20.5156 12.136 21.0549C11.4322 21.5941 10.6558 21.8637 9.80685 21.8637C8.9355 21.8637 8.14794 21.5941 7.44416 21.0549C6.74038 20.5156 6.27678 19.81 6.05336 18.9381H4.61228C3.47283 18.9381 2.52887 18.5595 1.78041 17.8023C1.03195 17.045 0.657715 16.0813 0.657715 14.9111V6.92584H8.66739V2.31369H0.657715V0.317383H17.6154C18.241 0.317383 18.7884 0.558316 19.2576 1.04018C19.7268 1.52205 19.9614 2.0957 19.9614 2.76114V16.9418H25.1559L23.0781 14.8078L24.4857 13.3622L28.9429 17.9399L24.4857 22.5521ZM10.6782 6.92584H18.0176V2.76114C18.0176 2.64641 17.9729 2.54315 17.8836 2.45136C17.7942 2.35958 17.7048 2.31369 17.6154 2.31369H10.6782V6.92584ZM9.77333 19.8674C10.3319 19.8674 10.7955 19.6781 11.1641 19.2995C11.5328 18.9209 11.7171 18.4448 11.7171 17.8711C11.7171 17.3204 11.5328 16.8557 11.1641 16.4771C10.7955 16.0985 10.3431 15.9092 9.80685 15.9092C9.27063 15.9092 8.81262 16.0928 8.4328 16.4599C8.05298 16.8271 7.86307 17.2975 7.86307 17.8711C7.86307 18.4218 8.0474 18.8922 8.41604 19.2823C8.78469 19.6724 9.23712 19.8674 9.77333 19.8674ZM6.05336 16.9418C6.27678 16.0698 6.74038 15.347 7.44416 14.7734C8.14794 14.1997 8.9355 13.9129 9.80685 13.9129C10.6558 13.9129 11.4322 14.1997 12.136 14.7734C12.8398 15.347 13.3034 16.0698 13.5268 16.9418H18.0176V8.92215H2.60149V14.9111C2.60149 15.5077 2.79139 15.9953 3.17121 16.3739C3.55103 16.7525 4.03139 16.9418 4.61228 16.9418H6.05336ZM6.05336 8.92215H2.60149H18.0176H6.05336Z"
        fill="white"
      />
    </svg>
  );
};

export default TruckBoardIcon;
