import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import { changeTimezoneToUs } from 'hooks/useTimeZoneDetector';
import Typography from 'ui/typography/Typography';

import ChevronIcon from 'components/svgs/ChevronIcon';

import DatepickerInput from './components/datepicker-input/DatepickerInput';
import { MONTHS, SUBJECT } from './constants/constants';
import { ICustomPicker, TChangeYearMonth, TPickerDate } from './constants/types';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './CustomDatePicker.module.scss';

const CustomDatePicker = ({
  selectsRange,
  handleDateChange,
  start,
  end,
  isCalendarOpen,
  setIsCalendarOpen,
  onClear,
  required,
  value: enteredValue,
  placeholder,
  isMonthPicker = false,
  isAbleToSelectFutureValue,
  minDate = new Date(63072000000),
  maxDate = new Date(48484106443344),
  shouldRangeValidate = false,
  disabled = false,
  errors,
  onBlur,
  name,
  mode = 'default',
  hasInsurance = false,
  carrierInsurance,
  withDisableText = false,
  handleChangeIndicator,
  ...props
}: ICustomPicker) => {
  const maxDateWithoutTime =
    typeof maxDate === 'number'
      ? new Date(new Date(maxDate).getFullYear(), new Date(maxDate).getMonth(), new Date(maxDate).getDate())
      : new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());
  const minDateWithoutTime =
    typeof minDate === 'number'
      ? new Date(new Date(minDate).getFullYear(), new Date(minDate).getMonth(), new Date(minDate).getDate())
      : new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());

  const carrierInsuranceWithoutTime = new Date(
    new Date(carrierInsurance).getFullYear(),
    new Date(carrierInsurance).getMonth(),
    new Date(carrierInsurance).getDate()
  );

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [value, setValue] = useState<null | Date>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDropDown, setOpenDropDown] = useState('');
  const range = (start: number, end: number | string) => {
    const length = Number(end) - start + 1;
    const years = new Array(length);
    for (let i = 0; i < length; i++) years[i] = start + i;
    return years.reverse();
  };

  const handleOpenDropDown = (picker: string) => {
    if (disabled) {
      return;
    }
    if (openDropDown) {
      setOpenDropDown('');
    } else {
      setOpenDropDown(picker);
    }
  };

  const monthRef = useOutsideDetect<HTMLLIElement>(() => setOpenDropDown(''));
  const yearRef = useOutsideDetect<HTMLLIElement>(() => setOpenDropDown(''));

  const onChange = (dates: TPickerDate | null) => {
    if (selectsRange) {
      if (Array.isArray(dates)) {
        const [start, end] = dates;
        setStartDate(start!);

        if (end && handleDateChange) {
          setEndDate(end);
          handleDateChange(dates);
        } else if (!end && handleDateChange) {
          setEndDate(null);
        }
      }
    } else {
      if (dates instanceof Date && handleDateChange) {
        setStartDate(dates);
        handleDateChange([dates]);
      }
    }
  };

  const handleClear = (event: any) => {
    event.stopPropagation();
    if (onClear) {
      onClear();
    }
    setValue(null);
    setStartDate(null);
    setEndDate(null);
  };

  const handleChangeYear = (date: Date, year: number, changeYear: TChangeYearMonth) => {
    changeYear(year);
  };

  const handleChangeMonth = (date: Date, month: number, changeMonth: TChangeYearMonth) => {
    changeMonth(month);
  };
  useEffect(() => {
    if (end && selectsRange) {
      setStartDate(dayjs(Number(start)).toDate());
      setEndDate(dayjs(Number(end)).toDate());
    }
  }, [end]);

  useEffect(() => {
    if (enteredValue) {
      setOpen(false);
      setValue(enteredValue);
    } else {
      setValue(null);
    }
  }, [enteredValue]);
  const renderDayContents = (day: any, date: any) => {
    const tooltipText =
      date.getTime() > maxDateWithoutTime?.getTime() &&
      (!hasInsurance || (hasInsurance && carrierInsuranceWithoutTime.getTime() > maxDateWithoutTime?.getTime()))
        ? `${SUBJECT.DISABLE_TOOLTIP_NEXT}: ${day}`
        : date.getTime() < minDateWithoutTime.getTime()
        ? `${SUBJECT.DISABLE_TOOLTIP_PREV}: ${day}`
        : date.getTime() > maxDateWithoutTime?.getTime() && hasInsurance
        ? `${SUBJECT.DISABLE_INSURANCE}: ${day}`
        : '';
    return (
      <Tooltip title={tooltipText} color="white" placement="top">
        <div>{day}</div>
      </Tooltip>
    );
  };

  const determineYearRange = () => {
    if (name === 'dob') {
      return new Date().getFullYear();
    }

    const currentYear = String(new Date().getFullYear());
    const lastNumberAsString = Number(currentYear.charAt(currentYear.length - 1));

    return new Date().getFullYear() + 10 + (10 - lastNumberAsString);
  };

  return (
    <Tooltip placement="top" color="#fff" title={disabled && withDisableText ? SUBJECT.DROP_TYPE : null}>
      <div
        className={clsx(styles.customDatePickerWrapper, {
          [styles.disabled]: disabled,
          [styles.disabledFull]: disabled && !withDisableText,
          [styles.error]: errors?.[name || ''],
        })}
      >
        <DatePicker
          disabled={disabled}
          open={isMonthPicker ? isCalendarOpen : open}
          {...(mode === 'pickUp' && { renderDayContents })}
          {...(shouldRangeValidate && {
            excludeDateIntervals: [
              {
                start: new Date(0),
                end: new Date((typeof minDate === 'number' ? minDate : minDate?.getTime()) - 86400000),
              },
              { start: new Date(maxDateWithoutTime?.getTime() + 86400000), end: new Date(48484506443344) },
            ],
          })}
          {...(!shouldRangeValidate && {
            maxDate: isAbleToSelectFutureValue ? null : changeTimezoneToUs(new Date()),
            minDate: dayjs().set('year', 1900).toDate(),
          })}
          minDate={dayjs().set('year', 1900).toDate()}
          onClickOutside={
            setIsCalendarOpen
              ? () => {
                  onBlur && onBlur();
                  setIsCalendarOpen(false);
                  setOpenDropDown('');
                }
              : () => {
                  onBlur && onBlur();
                  setOpen(false);
                  setOpenDropDown('');
                }
          }
          calendarStartDay={0}
          customInput={
            !isMonthPicker ? (
              <DatepickerInput
                inputText={placeholder}
                mandatory={required}
                isOpened={open}
                setIsOpen={setOpen}
                handleClear={handleClear}
                disabled={disabled}
              />
            ) : undefined
          }
          renderCustomHeader={({
            date,
            changeYear,
            decreaseMonth,
            increaseMonth,
            changeMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            const currentYear = dayjs(date).year();
            const currentMonth = MONTHS[dayjs(date).month()];

            return (
              <div className={styles.datepickerHeader}>
                <div className={styles.headerContent}>
                  <div className={styles.montsWrapper}>
                    <button
                      type="button"
                      disabled={prevMonthButtonDisabled}
                      onClick={() => {
                        handleChangeMonth(date, dayjs(date).get('month') - 1, decreaseMonth);
                      }}
                    >
                      <ChevronIcon />
                    </button>

                    <ul className={styles.selectWrapper}>
                      <li ref={monthRef}>
                        <Typography variant="paragraph" onClick={() => handleOpenDropDown('month')}>
                          {currentMonth}
                        </Typography>
                        <ul
                          className={clsx({
                            [styles.openedDropDown]: openDropDown === 'month',
                          })}
                        >
                          {MONTHS?.map((month, index) => {
                            return (
                              <li key={month} onClick={() => handleChangeMonth(date, index, changeMonth)}>
                                {month}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                    <button
                      type="button"
                      disabled={nextMonthButtonDisabled}
                      onClick={() => handleChangeMonth(date, dayjs(date).get('month') + 1, increaseMonth)}
                    >
                      <ChevronIcon />
                    </button>
                  </div>
                  <div className={styles.yearsWrapper}>
                    <button
                      type="button"
                      onClick={() => handleChangeYear(date, dayjs(date).year() - 1, changeYear)}
                      disabled={currentYear <= 1972}
                    >
                      <ChevronIcon />
                    </button>
                    <ul className={styles.selectWrapper}>
                      <li ref={yearRef}>
                        <Typography variant="paragraph" onClick={() => handleOpenDropDown('year')}>
                          {currentYear}
                        </Typography>
                        <ul
                          className={clsx({
                            [styles.openedDropDown]: openDropDown === 'year',
                          })}
                        >
                          {range(1972, determineYearRange())?.map(year => {
                            return (
                              <li key={year} onClick={() => handleChangeYear(date, year, changeYear)}>
                                {year}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                    <button
                      disabled={!isAbleToSelectFutureValue && currentYear >= new Date().getFullYear()}
                      type="button"
                      onClick={() => handleChangeYear(date, dayjs(date).year() + 1, changeYear)}
                    >
                      <ChevronIcon />
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
          formatWeekDay={nameOfDay => nameOfDay.toString().substring(0, 3)}
          selected={isMonthPicker ? dayjs(startDate).toDate() : value}
          startDate={startDate}
          endDate={endDate}
          onChange={isMonthPicker ? onChange : date => setValue(date as Date)}
          selectsRange={selectsRange}
          onSelect={() => {
            if (handleChangeIndicator) handleChangeIndicator();
          }}
          fixedHeight
          {...props}
        />
      </div>
    </Tooltip>
  );
};

export default CustomDatePicker;
