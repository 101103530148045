import { CarrierPaymentType, ICarrierType } from 'services/profile/carriers/interfaces';
import { emailPattern, onlyCharacters, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  COMPANY_REQUIRED,
  PRIMARY_PHONE_REQUIRED,
  CONTACT_REQUIRED,
  TYPE_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  PAID_AS_REQUIRED,
  STATE_REQUIRED,
  ZIP_REQUIRED,
  MIN_OPTION,
  PHONE,
  CONTACT,
  INVALID_EMAIL,
  EMAIL_IS_REQUIRED,
  DOT_REQUIRED,
  TRUCK_NUMBER_REQUIRED,
  LENGTH_REQUIRED,
  FUEL_CARD_MIN,
  FUEL_CARD_MAX,
  MAX_LENGTH,
  CARRIER_PACKET_REQUIRED,
  SIGN_AGREEMENT_REQUIRED,
  PAID_W9_REQUIRED,
  INSURANCE_REQUIRED,
  VOIDED_CHECK_REQUIRED,
  LIABILITY_REQUIRED,
  CARGO_REQUIRED,
  ADDRESS_MAX_LENGTH,
  MC_NUMBER_REQUIRED,
  MAX_MIN_ZIP_LENGTH,
  TRUCKS_REQUIRED,
  NOT_ZERO,
  INCORRECT_FAX,
  LESS_THAN_BILLION,
  PAYMENT_TYPE_REQUIRED_FOR_ONE,
} = VALIDATION_MESSAGES;

export const validation = (mode: string, activePage: string) => {
  return yup.object().shape({
    // GENERAL INFORMATION
    company: yup.string().required(COMPANY_REQUIRED),
    primaryPhone: yup.string().max(12, MAX_LENGTH).required(PRIMARY_PHONE_REQUIRED).matches(phoneRegExp, PHONE),
    secondaryPhone: yup
      .string()
      .nullable()
      .test('isCorrectPhone', 'isCorrectPhone', function checkIsPhoneCorrect(value) {
        if ((value && value.length === 12) || !value) {
          return true;
        } else if (!value?.match(phoneRegExp)) {
          return this.createError({
            message: PHONE,
            path: 'secondaryPhone',
          });
        } else {
          return this.createError({
            message: MAX_LENGTH,
            path: 'secondaryPhone',
          });
        }
      }),
    fax: yup
      .string()
      .nullable()
      .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
        if ((value && value.length === 12) || !value) {
          return true;
        } else if (!value?.match(phoneRegExp)) {
          return this.createError({
            message: INCORRECT_FAX,
            path: 'fax',
          });
        } else {
          return this.createError({
            message: MAX_LENGTH,
            path: 'fax',
          });
        }
      }),
    contact: yup.string().required(CONTACT_REQUIRED).matches(onlyCharacters, CONTACT),
    email: yup.string().email(INVALID_EMAIL).required(EMAIL_IS_REQUIRED).matches(emailPattern, INVALID_EMAIL),
    type: yup.string().min(1, MIN_OPTION).required(TYPE_REQUIRED),
    MC: yup.string().required(MC_NUMBER_REQUIRED),
    dot: yup.string().required(DOT_REQUIRED),
    address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
    city: yup.string().required(CITY_REQUIRED),

    state: yup.string().required(STATE_REQUIRED),

    zip: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),

    physicalAddress: yup.string().when('physical', {
      is: (value: string) => !!value,
      then: () => yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
      otherwise: schema => schema.notRequired(),
    }),
    physicalCity: yup.string().when('physical', {
      is: (value: string) => !!value,
      then: () => yup.string().required(CITY_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
    paymentPaidAs: yup.string().required(PAID_AS_REQUIRED),
    physicalState: yup.string().when('physical', {
      is: (value: string) => !!value,
      then: () => yup.string().required(STATE_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),

    physicalZip: yup.string().when('physical', {
      is: (value: string) => !!value,
      then: () => yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
      otherwise: schema => schema.notRequired(),
    }),

    // payment term
    paymentTerms: yup.array().of(
      yup.object().shape({
        files: yup.array().min(1, 'Minimum one file is a required'),
        paymentType: yup.number().test('isRequired', PAYMENT_TYPE_REQUIRED_FOR_ONE, value => {
          return [
            CarrierPaymentType.DIRECT_DEPOSIT,
            CarrierPaymentType.PAPER_CHECK,
            CarrierPaymentType.FUEL_CARD,
          ].includes(value!);
        }),
      })
    ),
    // ADD TRUCK
    trucks: yup.array().test('isRequired', TRUCKS_REQUIRED, function checkLength(value) {
      if (this.parent.type === String(ICarrierType['Owner Operator'])) {
        return !!value?.length;
      } else {
        return true;
      }
    }),
    truckNumber: yup.string().test('isRequired', TRUCK_NUMBER_REQUIRED, function checkLength(value) {
      if (this.parent.type === String(ICarrierType['Owner Operator']) && !this.parent.trucks?.length) {
        return !!value?.length;
      } else {
        return true;
      }
    }),
    numberOfTrucks: yup.string().nullable(),
    numberOfTrailers: yup.string().nullable(),
    yearAndMake: yup.string().notRequired(),
    boxTruck: yup.number().notRequired(),
    length: yup.string().test('isRequired', LENGTH_REQUIRED, function checkLength(value) {
      if (this.parent.boxTruck === 1) {
        if (value) {
          if (String(value).length > 7) {
            return this.createError({
              message: `Length couldn't be more than 7 digits`,
              path: 'length',
            });
          } else if (String(parseFloat(value)).replace('.', '')! == '0') {
            return this.createError({
              message: NOT_ZERO,
              path: 'length',
            });
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),

    fuelCard: yup.string().test('fuelCardLength', function (value: any) {
      if (value?.length) {
        if (value.length < 16 && value.length < 24) {
          return this.createError({
            message: FUEL_CARD_MIN,
            path: 'fuelCard',
          });
        } else if (value.length > 24) {
          return this.createError({
            message: FUEL_CARD_MAX,
            path: 'fuelCard',
          });
        }
      }
      return true;
    }),

    // DOCUMENTATION
    carrierPacketFile:
      mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
        ? yup.array().notRequired()
        : yup.array().test('length', CARRIER_PACKET_REQUIRED, value => {
            return value && value.length > 0;
          }),
    signAgreementFile:
      mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
        ? yup.array().notRequired()
        : yup.array().test('length', SIGN_AGREEMENT_REQUIRED, value => {
            return value && value.length > 0;
          }),
    paidAsW9File:
      mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
        ? yup.array().notRequired()
        : yup.array().test('length', PAID_W9_REQUIRED, value => {
            return value && value.length > 0;
          }),
    insuranceFile:
      mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
        ? yup.array().notRequired()
        : yup.array().test('length', INSURANCE_REQUIRED, value => {
            return value && value.length > 0;
          }),
    voidedCheckBankLatterFile:
      mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
        ? yup.array().notRequired()
        : yup.array().test('length', VOIDED_CHECK_REQUIRED, value => {
            return value && value.length > 0;
          }),
    Liability: yup.mixed().test('is-date-or-timestamp', LIABILITY_REQUIRED, function (value) {
      const { path, createError } = this;
      if (value instanceof Date) {
        return true;
      }
      if (typeof value === 'number' && !isNaN(value)) {
        return true;
      }

      return createError({
        path,
        message: LIABILITY_REQUIRED,
      });
    }),
    Cargo: yup.mixed().test('is-date-or-timestamp-cargo', CARGO_REQUIRED, function (value) {
      const { path, createError } = this;

      if (value instanceof Date) {
        return true;
      }
      if (typeof value === 'number' && !isNaN(value)) {
        return true;
      }

      return createError({
        path,
        message: CARGO_REQUIRED,
      });
    }),
    cargoCoverageValue: yup
      .string()
      .notRequired()
      .nullable()
      .test('not-zero', NOT_ZERO, function (value) {
        if (Number(value) >= 1000000000) {
          return this.createError({
            message: LESS_THAN_BILLION,
            path: 'cargoCoverageValue',
          });
        }
        return String(value).replace('.', '')! !== '0';
      }),
    nonOwnedCoverage: yup
      .string()
      .notRequired()
      .nullable()
      .test('not-zero', NOT_ZERO, function (value) {
        if (Number(value) >= 1000000000) {
          return this.createError({
            message: LESS_THAN_BILLION,
            path: 'nonOwnedCoverage',
          });
        }
        return String(value).replace('.', '')! !== '0';
      }),
    trailerInterchange: yup
      .string()
      .notRequired()
      .nullable()
      .test('not-zero', NOT_ZERO, function (value) {
        if (Number(value) >= 1000000000) {
          return this.createError({
            message: LESS_THAN_BILLION,
            path: 'trailerInterchange',
          });
        }
        return String(value).replace('.', '')! !== '0';
      }),
  });
};
