import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { IUseDoubleRangePickerProps, TPickerDate } from '../../constants/types';

export const useDoubleRangePicker = ({
  handleDateChange,
  start,
  end,
  selectsRange,
  setIsCalendarOpen,
}: IUseDoubleRangePickerProps) => {
  const [openDropDown, setOpenDropDown] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(start ? dayjs(Number(start)).toDate() : new Date());
  const [endDate, setEndDate] = useState<Date | null>(end ? dayjs(Number(end)).toDate() : null);

  const onChange = (dates: TPickerDate | null) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start!);

      if (end && handleDateChange) {
        setEndDate(end);
        handleDateChange(dates);
      } else if (!end && handleDateChange) {
        setEndDate(null);
      }
    }
  };
  const onClickOutside = setIsCalendarOpen
    ? () => {
        setIsCalendarOpen(false);
        setOpenDropDown('');
      }
    : () => {
        setOpenDropDown('');
      };

  useEffect(() => {
    if (end && selectsRange) {
      setStartDate(dayjs(Number(start)).toDate());
      setEndDate(dayjs(Number(end)).toDate());
    }
  }, [end]);

  return { openDropDown, setOpenDropDown, startDate, endDate, onChange, onClickOutside };
};
