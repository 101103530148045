import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { selectIsValid } from 'store/check-is-dirty-slice/selector';
import { drawerClose, drawerSave } from 'store/modal-slice/modals';

import { selectUserPermissionsInfo } from '../../store/user-slice/selector';

import { drawerModesWithScrollBar, drawerTabsWithScrollBar, SUBJECT } from './constants/constants';
import { IMainDrawerHookProps } from './constants/types';

export const useMainDrawer = ({ onSubmit, onCancel }: IMainDrawerHookProps) => {
  const dispatch = useDispatch();

  const { userType } = useSelector(selectUserPermissionsInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { open, mode, tab, headerFilter } = searchParams;

  const isValid = useSelector(selectIsValid);

  const [fullWidth, setFullWidth] = useState(false);

  const allDrawerFullWidth = Boolean(
    mode !== 'add' && mode !== 'tonuCreate' && mode !== 'carrierCreate' && mode !== 'create' && fullWidth
  );

  const shouldOpenDrawer = open === 'true';

  const shouldCloseAutomatically = useMemo(() => mode!.includes('view'), [mode]);

  const showLargeDrawer = () => {
    setFullWidth(prev => !prev);
    setSearchParams({ ...searchParams, ...(!fullWidth ? { width: 'full' } : { width: 'half' }) });
  };

  const onClose = () => {
    if (onCancel) onCancel();
    dispatch(drawerClose({ isDrawerClosed: true }));
    if (shouldCloseAutomatically) setSearchParams({ ...searchParams, open: 'false', id: '', authorityId: '' });
  };

  const handleAfterClose = (open: boolean) => {
    if (!open) {
      setFullWidth(false);
      setSearchParams({ ...searchParams, width: 'half' });
      dispatch(drawerClose({ isDrawerClosed: false }));
    }
  };

  const onSave = () => {
    if (onSubmit) onSubmit();
    dispatch(drawerSave({ isDrawerSaved: true }));
  };

  useEffect(() => {
    if (searchParams.width === 'full') {
      setFullWidth(true);
    }
  }, [searchParams.width]);

  const width = !fullWidth ? '1260px' : SUBJECT.HUNDRED_PERCENT;
  const { pathname } = useLocation();

  const checkDrawerType = useMemo(() => {
    if (headerFilter === 'customers' && pathname === '/search-result') {
      return false;
    } else if (
      (drawerTabsWithScrollBar.includes(tab) || pathname === '/search-result') &&
      drawerModesWithScrollBar.includes(mode)
    ) {
      return true;
    }
  }, [headerFilter, tab, pathname, mode]);

  return {
    showLargeDrawer,
    onClose,
    width,
    shouldOpenDrawer,
    fullWidth,
    onSave,
    isValid,
    handleAfterClose,
    mode,
    tab,
    userType,
    allDrawerFullWidth,
    pathname,
    headerFilter,
    checkDrawerType,
  };
};
