import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import { floatNumberFormatterWithMinus, formatNumberWithThousandsSeparatorAndDecimal } from '../../utils/helpers';

import { SUBJECT } from './constants/constants';
import { IPayOutFooterProps } from './constants/types';

import styles from './PayOutFooter.module.scss';

const PayOutFooter = ({ isPayButton, handleCancel, selectedCount, payTotal, handlePay }: IPayOutFooterProps) => {
  return (
    <>
      <div className={styles.payrollFooter}>
        <div className={styles.footerRow}>
          <div className={styles.leftItem}>
            <Button variant="text" onClick={handleCancel}>
              {SUBJECT.CANCEL}
            </Button>
          </div>
          <div className={styles.rightSide}>
            <Typography variant="paragraph">{`${SUBJECT.SELECTED}: ${selectedCount}`}</Typography>
            <Typography variant="paragraph">
              {`${SUBJECT.TOTAL}:`}{' '}
              <span>{`${formatNumberWithThousandsSeparatorAndDecimal(
                floatNumberFormatterWithMinus(String(payTotal))
              )} $`}</span>
            </Typography>
            <Button size="large" onClick={handlePay} variant={isPayButton ? 'default' : 'tableDark'}>
              {isPayButton ? SUBJECT.PAY : SUBJECT.APPROVE}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayOutFooter;
