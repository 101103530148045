import React, { ComponentType } from 'react';
import { Controller, FieldValues, useController, UseControllerProps } from 'react-hook-form';

import ErrorMessage from '../../error-message/ErrorMessage';

export const withController = <T, U extends FieldValues>(WrappedComponent: ComponentType<T>) => {
  const WithController = ({ name, rules, control, ...rest }: T & UseControllerProps<U>) => {
    const {
      formState: { errors },
    } = useController({ name, control, rules });

    return (
      <div>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => <WrappedComponent {...field} {...(rest as T)} />}
        />
        <ErrorMessage fieldError={errors[name]} />
      </div>
    );
  };

  const { name, displayName } = WrappedComponent;
  const componentDisplayName = displayName || name || 'Component';

  WithController.displayName = `withController(${componentDisplayName})`;
  return WithController;
};
