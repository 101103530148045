import { UploadProps } from 'antd';
import { ICustomPicker } from 'ui/inputs/custom-date-picker/constants/types';
import CustomDatePicker from 'ui/inputs/custom-date-picker/CustomDatePicker';
import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ICustomDropdownProps } from 'ui/inputs/select/components/dropdown-with-custom-input/constants/types';
import CustomSelectDropDown from 'ui/inputs/select/components/dropdown-with-custom-input/CustomDropdown';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import CustomSelect from 'ui/inputs/select/Select';
import { ISwitchProps } from 'ui/inputs/switch/constants/types';
import CustomSwitch from 'ui/inputs/switch/Switch';
import { ITimePickerProps } from 'ui/inputs/time-picker/constants/types';
import CustomTimePicker from 'ui/inputs/time-picker/TimePicker';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';
import { IUploadProps } from 'ui/upload/constants/types';
import CustomUpload from 'ui/upload/Upload';

import { ICreateLoadFormData, ITransactionFormData } from '../constants/types';

export const Input = withController<IInputProps, ICreateLoadFormData>(CustomInput);
export const TransactionInput = withController<IInputProps, ITransactionFormData>(CustomInput);

export const Select = withController<ISelectProps, ICreateLoadFormData>(CustomSelect);
export const TransactionSelect = withController<ISelectProps, ITransactionFormData>(CustomSelect);

export const TimePicker = withController<ITimePickerProps, ICreateLoadFormData>(CustomTimePicker);
export const DatePicker = withController<ICustomPicker, ICreateLoadFormData>(CustomDatePicker);
export const Upload = withController<IUploadProps & UploadProps, ICreateLoadFormData>(CustomUpload);
export const SearchableSelect = withController<ISearchableSelectProps, ICreateLoadFormData>(CustomSearchableSelect);
export const Switch = withController<ISwitchProps, ICreateLoadFormData>(CustomSwitch);

export const CustomDropDown = withController<Partial<ICustomDropdownProps>, ICreateLoadFormData>(CustomSelectDropDown);

export const SearchableDropdown = withController<ISearchableDropdownProps, ICreateLoadFormData | any>(
  CustomSearchableDropdown
);
