import { INewPasswordFormData } from './types';

export const defaultValues: INewPasswordFormData = {
  newPassword: '',
  repeatPassword: '',
};

export enum SUBJECT {
  NEW_PASSWORD = 'New Password',
  REPEAT_PASSWORD = 'Repeat Password',
  SAVE = 'Save',
  PASSWORD_REQUIREMENTS = 'Password Requirements',
  PASSWORD_REQUIREMENTS_CONTENT = 'Must Contain At Least 6 Characters, 1 Symbol, Number And Both Upper And Lower Letters',
}

export enum VALIDATION_MESSAGES {
  PASSWORD_NOT_VALID = `Password  don't match with password requirements`,
  PASS_IS_REQUIRED = 'Password is required',
}
