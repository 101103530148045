import { formatDate } from 'utils/dates';

import { formatNumberWithThousandsSeparatorAndDecimal } from '../../../../../utils/helpers';

import { ITransactionLabelTypes, ITransactionTypes } from './constants';

export const transactionsForDataGrind = (loadTransactions: any) => {
  const formattedTransactions = loadTransactions?.result.map((el: any) => {
    return {
      transactionType: ITransactionTypes[el?.type],
      label: ITransactionLabelTypes[el?.label],
      dateAndTime: formatDate(el?.createdAt),
      addedBy: el?.username,
      customerAmount: formatNumberWithThousandsSeparatorAndDecimal(el?.customerAmout),
      carrierAmount: formatNumberWithThousandsSeparatorAndDecimal(el?.carrierAmout),
      secondAgent: formatNumberWithThousandsSeparatorAndDecimal(el?.secondAgent),
      balanceAmount: formatNumberWithThousandsSeparatorAndDecimal(el?.balance),
      comment: el?.comment,
      deletedAt: el?.deletedAt,
      deletor: el?.deletor,
      id: el?.id,
    };
  });
  return formattedTransactions;
};
