import React from 'react';
import Typography from 'ui/typography/Typography';

import LogoBlack from 'components/svgs/LogoBlack';

import styles from './styles.module.scss';

const ThankYouPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <LogoBlack />
        <Typography className={styles.heading} variant="heading">
          Success! Thank you for signing up with <br />
          the Freightmax App. <br />
        </Typography>
        <Typography
          children="We'll review your application within 24 hours."
          variant="paragraph"
          className={styles.helperText}
        />
        <div className={styles.contact}>
          <Typography variant="paragraph">
            Problems or questions? Call us at
            <a className={styles.phone} href="tel:718-825-8556">
              718-825-8556
            </a>
            <br />
            or email
            <a className={styles.email} href="mailto:info@freigthmax.com">
              info@freigthmax.com
            </a>
          </Typography>
        </div>
        <div className={styles.copyright}>
          <Typography variant="paragraph">
            Copyright &copy; 2024 <span>FreightMax.</span> All Rights Reserved.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
