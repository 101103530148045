import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import SearchableSelectForMultipleAuthorities from 'ui/inputs/searchable-select/searchable-select-for-multiple-authorities/SearchableSelectForMultipleAuthorities';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';
import { IProgressBarProps } from 'ui/progress-bar/constants/types';
import CustomStatusBar from 'ui/progress-bar/ProgressBar';

import { IOfficeFilter, IOfficeFormData } from './../constants/types';

// export const Select = withController<ISelectProps, IOfficeFormData>(ReusableSelect);
export const Input = withController<IInputProps, IOfficeFormData>(CustomInput);
export const SearchableSelect = withController<ISearchableSelectProps, IOfficeFormData>(
  SearchableSelectForMultipleAuthorities
);
export const StatusBar = withController<IProgressBarProps, IOfficeFormData>(CustomStatusBar);
export const FilterSelect = withController<ISelectProps, IOfficeFilter>(ReusableSelect);
