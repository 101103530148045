import { BillingTypes } from './interfaces';

export const createCustomers = (customer: any) => {
  return {
    name: customer.name,
    phone: customer.phoneNumber,
    type: Number(customer.type),
    ...(customer?.mcNumber ? { mc: customer?.mcNumber } : {}),
    ...(customer?.ffNumber ? { ff: customer?.ffNumber } : {}),
    ...(customer?.dot ? { dot: customer?.dot } : {}),
    ...(customer?.stateNumber ? { stateNumber: customer?.stateNumber } : {}),
    fax: customer.fax ? customer.fax : '',
    address: customer.address,
    ...(customer?.secondAddress ? { secondAddress: customer?.secondAddress } : {}),
    city: customer.city,
    stateId: Number(customer.state),
    zip: customer.zipcode,
    ...(customer?.billingPhone ? { billingPhone: customer?.billingPhone } : {}),
    ...(customer?.billingEmail ? { billingEmail: customer?.billingEmail } : {}),
    ...(customer?.billingContactName ? { billingContactName: customer?.billingContactName } : {}),
    ...(customer?.receivablesPhone ? { receivablesPhone: customer?.receivablesPhone } : {}),
    ...(customer?.receivablesEmail ? { receivablesEmail: customer?.receivablesEmail } : {}),
    ...(customer?.receivablesContactName ? { receivablesContactName: customer?.receivablesContactName } : {}),
    paymentTerms: customer.addedMc?.map((mc: any) => {
      return {
        authorityId: Number(mc?.id),
        days: mc?.day,
        percent: Number(mc.percent.slice(0, mc.percent.length - 1)),
        emails: mc.email,
        type: mc.paymentType === 'Quick pay' ? BillingTypes['QuickPay'] : BillingTypes[mc.paymentType],
        ...(mc?.templateId ? { templateId: Number(mc?.templateId) } : {}),
        ...(mc?.paymentLimit ? { paymentLimit: Number(mc?.paymentLimit) } : {}),
      };
    }),
  };
};

export const updateCustomerSerializer = (customer: any) => {
  return {
    name: customer.name,
    phone: customer.phoneNumber,
    type: Number(customer.type),
    ...(customer?.mcNumber ? { mc: customer?.mcNumber } : {}),
    ...(customer?.ffNumber ? { ff: customer?.ffNumber } : {}),
    ...(customer?.dot ? { dot: customer?.dot } : {}),
    ...(customer?.stateNumber ? { stateNumber: customer?.stateNumber } : {}),
    fax: customer.fax ? customer.fax : '',
    address: customer.address,
    ...(customer?.secondAddress ? { secondAddress: customer?.secondAddress } : {}),
    city: customer.city,
    stateId: Number(customer.state),
    zip: customer.zipcode,
    ...(customer?.billingPhone ? { billingPhone: customer?.billingPhone } : {}),
    ...(customer?.billingEmail ? { billingEmail: customer?.billingEmail } : {}),
    ...(customer?.billingContactName ? { billingContactName: customer?.billingContactName } : {}),
    ...(customer?.receivablesPhone ? { receivablesPhone: customer?.receivablesPhone } : {}),
    ...(customer?.receivablesEmail ? { receivablesEmail: customer?.receivablesEmail } : {}),
    ...(customer?.receivablesContactName ? { receivablesContactName: customer?.receivablesContactName } : {}),
  };
};
