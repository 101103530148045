import { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import { FilterSelect, SearchableSelectForFilter } from 'pages/profile/tabs/branch/hoc-instance/instance';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { LOCATION_DROPDOWN_DATA } from 'utils/constants';

import MainDrawer from 'components/main-drawer/MainDrawer';
import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import Table from 'components/table/Table';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';
import { IOfficeParams } from '../office/constants/types';

import { SUBJECT } from './constants/constants';
import useBranch from './useBranch';

import styles from './Branch.module.scss';

const Branch = ({ setExcelParams }: IOfficeParams) => {
  const {
    currentChildren: { component, buttonText, onCancel, onSubmit, withFooter },
    isFetching,
    data,
    skip,
    limit,
    columns,
    filterControl,
    resetFilter,
    handleRowEdit,
    handleRowClick,
    handleColumnSearch,
    handleSortClick,
    handleSwitchClick,
    handlePaginationClick,
    handleStopResize,
    isFilterDirty,
    filterOfficeData,
    setOfficesFilter,
    handleOfficeFilterSelect,
    setFilterValue,
    filterOfficeValue,
    branchPagePermission: { edit, terminateOff, terminateOn },
    roleLevel,
  } = useBranch({ setExcelParams });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading: isFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onSwitchClick: handleSwitchClick,
    onColumnSearch: handleColumnSearch,
    handleRowEdit,
    onPaginationClick: handlePaginationClick,
    hasTerminateOnPermission: terminateOn,
    hasTerminateOffPermission: terminateOff,
    hasEditPermission: edit,
    withMenu: edit,
  });

  return (
    <>
      <div className="page-content">
        <div className={styles.branchTopBlock}>
          <div className={styles.branchMainSelect}>
            <Row align="middle" gutter={[18, 0]}>
              <Col span={14}>
                <Row align="middle" gutter={[15, 0]}>
                  <Col span={8}>
                    <FilterSelect
                      suffixIcon={<SelectArrowIcon />}
                      withAll
                      options={LOCATION_DROPDOWN_DATA}
                      control={filterControl}
                      label={SUBJECT.LOCATION}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name={SUBJECT.LOCATION_FILTER}
                      isFilter
                    />
                  </Col>
                  {roleLevel <= IRoleLevel.corpLevel && (
                    <Col span={8}>
                      <SearchableSelectForFilter
                        options={filterOfficeData?.result}
                        control={filterControl}
                        label={SUBJECT.OFFICE}
                        placeholder={SUBJECT.NOT_SELECTED}
                        name="office_filter"
                        offset={filterOfficeData?.count}
                        setOffset={setOfficesFilter}
                        showKey="title"
                        handleClick={handleOfficeFilterSelect}
                        setValue={setFilterValue}
                        value={filterOfficeValue}
                        isFilter
                        resetFunc={resetFilter}
                      />
                    </Col>
                  )}
                  <Col>
                    {isFilterDirty && (
                      <Col>
                        <div className={styles.branchSearchClear}>
                          <Button
                            icon={<ResetIcon />}
                            variant="ghost"
                            onClick={resetFilter}
                            children={SUBJECT.RESET_ALL}
                          />
                        </div>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        {!isFetching && !data && (
          <div className="page-content">
            <div className={styles.branchEmpty}>
              <Typography variant="paragraph">{SUBJECT.BRANCH_LIST_IS_EMPTY}</Typography>
            </div>
          </div>
        )}
        <Table {...tableProps} />
      </div>
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
      />
    </>
  );
};

export default Branch;
