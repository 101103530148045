import LogoOpenIcon from 'components/svgs/LogoOpenIcon';

import CarriersSignUp from './CarriersSignUp';

import styles from './Carriers.module.scss';

export const CarrierSignUpPage = () => {
  return (
    <div className={styles.carrierSignUpPageWrapper}>
      <div className={styles.centeredContainer}>
        <div className={styles.pageHeader}>
          <div className={styles.logo}>
            <LogoOpenIcon />
          </div>
          <div className={styles.info}>
            <a className={styles.email} href={`mailto:info@freightmax.com`}>
              info@freightmax.com
            </a>
            <a className={styles.phone} href={`tel:8068068686`}>
              (806)-806-8686
            </a>
          </div>
        </div>
        <h1 className={styles.pageHeaderText}>Register with FreightMax App Today!</h1>

        <div className={styles.formWrapper}>
          <CarriersSignUp />
        </div>
      </div>
    </div>
  );
};

export default CarrierSignUpPage;
