import React from 'react';
import { MainLoader } from 'ui/main-loader/MainLoader';

import CustomComment from 'components/custom-comment/CustomComment';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import CarrierInfo from '../carrier-info/CarrierInfo';

import AddCoordinator from './components/add-coordinator/AddCoordinator';
import LastLoadsTable from './components/last-loads-table/LastLoadsTable';
import Location from './components/location/Location';
import TruckInfo from './components/truck-info/TruckInfo';
import { SUBJECT } from './constants/constants';
import useViewEditCarrierTruck from './useViewEditCarrierTruck';

const EditCarrierTruck = ({
  createComment,
  deleteComment,
  carrierInfo,
  getMoreComments,
  isShowMoreVisible,
  isLoadsCommentsLoading,
  carrierCommentsInfo,
  userInfo,
  hasDeleteCommentPermission,
  handleCarrierLasLoadsPaginationClick,
  isCarrierLastLoadsLoading,
  isFetchingCarrierInfo,
  carrierLastLoads,
}: any) => {
  const {
    commentsControl,
    commentsWatch,
    commentsSetValue,
    handleScrollToHistory,
    drawerRef,
    truckInfoById,
    isFetchingTruckInfoById,
    verificationPermissions,
  } = useViewEditCarrierTruck();

  return (
    <>
      <MainLoader isFetching={isCarrierLastLoadsLoading || isFetchingCarrierInfo} />
      <CarrierInfo
        carrierInfo={{ ...carrierInfo, commentCount: carrierCommentsInfo?.count }}
        handleScrollToHistory={handleScrollToHistory}
      />
      <TruckInfo truckInfo={truckInfoById} carrierInfo={carrierInfo} />
      {!!carrierLastLoads?.result?.length && (
        <ViewItemWrapper title={SUBJECT.LAST_LOADS}>
          <LastLoadsTable
            isLoading={isCarrierLastLoadsLoading}
            carrierLastLoads={carrierLastLoads}
            onPaginationClick={handleCarrierLasLoadsPaginationClick}
          />
        </ViewItemWrapper>
      )}
      {verificationPermissions?.view && (
        <AddCoordinator truckInfoById={truckInfoById} isFetchingTruckInfoById={isFetchingTruckInfoById} />
      )}
      <Location truckInfoById={truckInfoById} />
      <div ref={drawerRef}>
        <CustomComment
          id="comment"
          watch={commentsWatch}
          name="comment"
          setValue={commentsSetValue}
          control={commentsControl}
          comments={carrierCommentsInfo}
          createComment={createComment}
          deleteComment={deleteComment}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          canCreate={!userInfo?.agentType}
          canDelete={!userInfo?.agentType}
          hasDeleteCommentPermission={hasDeleteCommentPermission}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
        />
      </div>
    </>
  );
};
export default EditCarrierTruck;
