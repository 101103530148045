import {
  BrokerPaymentTermsType,
  CarrierFactoringType,
  CarrierPaymentTermFiles,
  CarrierPaymentTermType,
  CarrierPaymentType,
} from 'services/profile/carriers/interfaces';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  PAYMENT_TERM_TYPE_REQUIRED,
  AUTHORITY_REQUIRED,
  FACTORING_TYPE_REQUIRED,
  FACTORED_COMPANY_NAME_REQUIRED,
  PAYMENT_TYPE_REQUIRED,
  PAYMENT_BANK_NAME_REQUIRED,
  PAYMENT_ROUTING_NUMBER_REQUIRED,
  PAYMENT_ACCOUNT_REQUIRED,
  PAYMENT_COMPANY_NAME_REQUIRED,
  PAYMENT_ADDRESS_REQUIRED,
  PAYMENT_CITY_REQUIRED,
  PAYMENT_ZIP_REQUIRED,
  PAYMENT_STATE_REQUIRED,
  DAY_REQUIRED,
  PAYMENT_FUEL_CARD_REQUIRED,
  PERCENT_REQUIRED,
  EMAILS_REQUIRED,
  FILES_REQUIRED,
  ZIP_MIN_LENGTH,
  FUEL_CARD_MIN,
  FUEL_CARD_MAX,
  PERCENT_MAX_LENGTH,
  MAX_DECIMAL_NUMBER,
} = VALIDATION_MESSAGES;

export const validation = (allFiles: any) => {
  return yup.object().shape({
    id: yup.mixed(),
    type: yup.number().required(PAYMENT_TERM_TYPE_REQUIRED),
    authorityId: yup.array().test('isRequired', AUTHORITY_REQUIRED, function checkisRequired(value) {
      if (this.parent.type === CarrierPaymentTermType.BROKERAGE) {
        return !!value?.length;
      } else {
        return true;
      }
    }),
    factoringType: yup.number().required(FACTORING_TYPE_REQUIRED),
    factoredCompanyName: yup
      .array()
      .test('isRequired', FACTORED_COMPANY_NAME_REQUIRED, function checkisRequired(value) {
        if (this.parent.factoringType === CarrierFactoringType.FACTORED) {
          return !!value?.length;
        } else {
          return true;
        }
      }),
    paymentType: yup
      .number()
      .test('isRequired', '', value => {
        return [
          CarrierPaymentType.DIRECT_DEPOSIT,
          CarrierPaymentType.PAPER_CHECK,
          CarrierPaymentType.FUEL_CARD,
        ].includes(value!);
      })
      .required(PAYMENT_TYPE_REQUIRED),
    paymentBankName: yup.string().test('isRequired', PAYMENT_BANK_NAME_REQUIRED, function checkisRequired(value) {
      if (this.parent.factoringType === CarrierFactoringType.FACTORED) return true;
      if (this.parent.paymentType === CarrierPaymentType.DIRECT_DEPOSIT) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentRoutingNumber: yup
      .string()
      .test('isRequired', PAYMENT_ROUTING_NUMBER_REQUIRED, function checkisRequired(value) {
        if (this.parent.factoringType === CarrierFactoringType.FACTORED) return true;
        if (this.parent.paymentType === CarrierPaymentType.DIRECT_DEPOSIT) {
          return !!value;
        } else {
          return true;
        }
      }),
    paymentAccountNumber: yup.string().test('isRequired', PAYMENT_ACCOUNT_REQUIRED, function checkisRequired(value) {
      if (this.parent.factoringType === CarrierFactoringType.FACTORED) return true;
      if (this.parent.paymentType === CarrierPaymentType.DIRECT_DEPOSIT) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentCompanyName: yup.string().test('isRequired', PAYMENT_COMPANY_NAME_REQUIRED, function checkisRequired(value) {
      if (this.parent.factoringType === CarrierFactoringType.FACTORED) return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentAddress: yup.string().test('isRequired', PAYMENT_ADDRESS_REQUIRED, function checkisRequired(value) {
      if (this.parent.factoringType === CarrierFactoringType.FACTORED) return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentSecondAddress: yup.string(),
    paymentCity: yup.string().test('isRequired', PAYMENT_CITY_REQUIRED, function checkisRequired(value) {
      if (this.parent.factoringType === CarrierFactoringType.FACTORED) return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentStateId: yup.string().test('isRequired', PAYMENT_STATE_REQUIRED, function checkisRequired(value) {
      if (this.parent.factoringType === CarrierFactoringType.FACTORED) return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentZip: yup.string().test('isRequired', PAYMENT_ZIP_REQUIRED, function checkisRequired(value) {
      if (this.parent.factoringType === CarrierFactoringType.FACTORED) return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        if (value && value?.length !== 5) {
          return this?.createError({
            message: ZIP_MIN_LENGTH,
            path: 'paymentZip',
          });
        } else return !!value?.length;
      } else {
        return true;
      }
    }),
    paymentFuelCard: yup
      .string()
      .test('isRequired', PAYMENT_FUEL_CARD_REQUIRED, function checkisRequired(value) {
        if (this.parent.paymentType === CarrierPaymentType.FUEL_CARD) {
          return !!value;
        } else {
          return true;
        }
      })
      .test('fuelCardValidation', function fuelCardValidation(value) {
        if (this.parent.paymentType === CarrierPaymentType.FUEL_CARD && value) {
          if (value.length < 16 && value.length < 24) {
            return this.createError({
              message: FUEL_CARD_MIN,
              path: 'paymentFuelCard',
            });
          } else if (value.length > 24) {
            return this.createError({
              message: FUEL_CARD_MAX,
              path: 'paymentFuelCard',
            });
          }
        }
        return true;
      }),
    brokerPaymentTermType: yup.number().required(),
    day: yup.string().test('isRequired', DAY_REQUIRED, function checkisRequired(value) {
      if (this.parent.brokerPaymentTermType === BrokerPaymentTermsType.QUICK) {
        return !!value;
      } else {
        return true;
      }
    }),
    percent: yup
      .string()
      .test('isRequired', PERCENT_REQUIRED, function checkisRequired(value) {
        if (this.parent.brokerPaymentTermType === BrokerPaymentTermsType.QUICK) {
          return !!value;
        } else {
          return true;
        }
      })
      .test('percentValidation', PERCENT_MAX_LENGTH, function emailPatternValidation(value) {
        if (this.parent.brokerPaymentTermType === BrokerPaymentTermsType.QUICK && value) {
          return yup.number().max(100).isValidSync(value);
        }
        return true;
      })
      .test('is-decimal', MAX_DECIMAL_NUMBER, function test(value) {
        if (this.parent.brokerPaymentTermType !== BrokerPaymentTermsType.QUICK) return true;
        const decimalPart = value?.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      }),
    brokerEmails: yup.array().test('isRequired', EMAILS_REQUIRED, function checkisRequired(value) {
      return !!value?.length;
    }),
    files: yup.array().test('isRequired', FILES_REQUIRED, function checkisRequired(value) {
      const allFilesWithoutFactored = allFiles?.files.filter((item: any) => {
        return Number(item.fileType) !== 6;
      });
      const hasOneOfRequiredFile = allFilesWithoutFactored?.some((file: any) =>
        CarrierPaymentTermFiles.includes(file.fileType)
      );

      return hasOneOfRequiredFile;
    }),
    agreement: yup.array().test('isRequired', FILES_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.brokerPaymentTermType === BrokerPaymentTermsType.QUICK &&
        this.parent.factoringType === CarrierFactoringType.FACTORED
      ) {
        return !!allFiles?.agreement?.length;
      } else return true;
    }),
  });
};
