import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { MainLoader } from 'ui/main-loader/MainLoader';
import { floatNumberFormatterWithMinus, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { IRoleLevel } from '../../../../../../services/truck-board/carrier-request/interfaces';
import { selectUserPermissionsInfo } from '../../../../../../store/user-slice/selector';
import { AGENT_COLUMNS, AGENT_KEYS } from '../../constants/constants';
import { OFFICE_KEYS, SEARCH_OFFICE_COLUMNS, SUBJECT } from '../constants/constants';
import { IChargeAdvanceProps } from '../constants/types';
import { DropDown, FormInput, SearchableDropdown } from '../hoc-instance/instance';

export const Charge = ({
  watch,
  errors,
  control,
  mode,
  trigger,
  setValue,
  isFetching,
  branchesData,
  agentsOptions,
  officesOptions,
  setBranchFilter,
  setAgentsFilter,
  setOfficesFilter,
  isOtherServiceFeeFetching,
}: IChargeAdvanceProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isFetching }));
  }, [isFetching]);

  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  return (
    <div>
      {mode === 'editCharge' && <MainLoader isFetching={isOtherServiceFeeFetching} />}
      <form>
        <ViewItemWrapper title={SUBJECT.ADD_NEW_CHARGE}>
          <Row align="top" gutter={[20, 40]}>
            {roleLevel === IRoleLevel.corpLevel && (
              <Col span={8}>
                <SearchableDropdown
                  options={officesOptions?.result}
                  placeholder={SUBJECT.OFFICE}
                  offset={officesOptions?.count}
                  setOffset={setOfficesFilter}
                  values={watch('agency')}
                  columns={SEARCH_OFFICE_COLUMNS}
                  keys={OFFICE_KEYS}
                  control={control}
                  name="agency"
                  showKey="title"
                  setValue={setValue}
                  rules={{ required: true }}
                  required
                  withoutBorder
                  errors={errors}
                  disabled={mode === 'editCharge'}
                />
              </Col>
            )}
            {roleLevel === IRoleLevel.officeLevel && (
              <Col span={8}>
                <SearchableDropdown
                  options={branchesData?.result}
                  placeholder={SUBJECT.BRANCH}
                  offset={branchesData?.count}
                  setOffset={setBranchFilter}
                  values={watch('branch')}
                  columns={SEARCH_OFFICE_COLUMNS}
                  keys={OFFICE_KEYS}
                  control={control}
                  errors={errors}
                  name="branch"
                  showKey="title"
                  setValue={setValue}
                  withoutBorder
                  required={roleLevel === IRoleLevel.officeLevel}
                  rules={{ required: roleLevel === IRoleLevel.officeLevel }}
                  disabled={mode === 'editCharge'}
                />
              </Col>
            )}
            {roleLevel === IRoleLevel.branchLevel && (
              <Col span={8}>
                <SearchableDropdown
                  options={agentsOptions?.result}
                  placeholder={SUBJECT.AGENT}
                  offset={agentsOptions?.count}
                  setOffset={setAgentsFilter}
                  values={watch('agent')}
                  columns={AGENT_COLUMNS}
                  keys={AGENT_KEYS}
                  errors={errors}
                  name="agent"
                  control={control}
                  setValue={setValue}
                  showKey="firstName"
                  disabled={mode === 'editCharge'}
                  required={roleLevel === IRoleLevel.branchLevel}
                  rules={{ required: roleLevel === IRoleLevel.branchLevel }}
                />
              </Col>
            )}
            <Col span={8}>
              <DropDown
                name="type"
                control={control}
                rules={{ required: true }}
                value={watch('type')}
                onChange={(value: string) => setValue('type', value, { shouldValidate: true, shouldDirty: true })}
                onBlur={() => {
                  setTimeout(() => {
                    trigger('type');
                  }, 300);
                }}
                errors={errors}
                required
              />
            </Col>
            <Col span={8}>
              <FormInput
                name="amount"
                rules={{ required: true }}
                suffix={<DollarIcon />}
                placeholder={SUBJECT.AMOUNT}
                getValues={watch}
                errors={errors}
                onChange={(event: React.ChangeEvent<any>) =>
                  setValue(
                    'amount',
                    floatNumberFormatterWithMinus(
                      event?.target?.value[0] !== '-' ? `-${event?.target?.value}` : event?.target?.value
                    ),
                    { shouldValidate: true, shouldDirty: true }
                  )
                }
                value={watch('amount') ? formatNumberWithThousandsSeparatorAndDecimal(watch('amount')) : ''}
                control={control}
                required
              />
            </Col>
          </Row>
        </ViewItemWrapper>
      </form>
    </div>
  );
};
