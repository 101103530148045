import { ReactNode } from 'react';
import { formatDate } from 'utils/dates';

import { CreatorTypes, links } from '../constants';
import { EMessageTypes, Handler, ISocketMessage } from '../interfaces';
import Notification from '../notification/Notification';

export class EmployeeHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      createCorporate: this.handleCreateCorporate,
      editCorporate: this.handleEditCorporate,
      terminateCorporate: this.handleTerminateCorporate,
      activateCorporate: this.handleActivateCorporate,
      fileDeleteCorporate: this.handleFileDeleteCorporate,
      editCorporateUsername: this.handleEditCorporateUsername,
      corporateEditPermission: this.handleCorporateEditPermission,

      createOffice: this.handleCreateOffice,
      editOffice: this.handleEditOffice,
      terminateOffice: this.handleTerminateOffice,
      activateOffice: this.handleActivateOffice,
      fileDeleteOffice: this.handleFileDeleteOffice,
      editOfficeUsername: this.handleEditOfficeUsername,
      officeEditPermission: this.handleOfficeEditPermission,

      createBranch: this.handleCreateBranch,
      editBranch: this.handleEditBranch,
      terminateBranch: this.handleTerminateBranch,
      activateBranch: this.handleActivateBranch,
      fileDeleteBranch: this.handleFileDeleteBranch,
      editBranchUsername: this.handleEditBranchUsername,
      branchEditPermission: this.handleBranchEditPermission,
    };
  }
  handleCreateCorporate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Corporate Employee created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleEditCorporate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Corporate Employee edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleTerminateCorporate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Corporate Employee terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleActivateCorporate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Corporate Employee activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleFileDeleteCorporate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted file from employee' },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Corporate Employee file deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditCorporateUsername = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed username from ${additionalData[0]} to ${additionalData[1]} for employee` },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Corporate Employee username changed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCorporateEditPermission = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed permission group from ${additionalData[0]} to ${additionalData[1]} for employee` },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Corporate Employee permission changed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };

  handleCreateOffice = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Office Employee created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleEditOffice = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Office Employee edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleTerminateOffice = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Office Employee terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleActivateOffice = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Office Employee activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleFileDeleteOffice = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted file from employee' },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Office Employee file deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditOfficeUsername = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed username from ${additionalData[0]} to ${additionalData[1]} for employee` },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Office Employee username changed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleOfficeEditPermission = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed permission group from ${additionalData[0]} to ${additionalData[1]} for employee` },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Office Employee permission changed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };

  handleCreateBranch = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Branch Employee created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleEditBranch = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Branch Employee edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleTerminateBranch = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Branch Employee terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleActivateBranch = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.employees}${id}` },
          { title: 'employee was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Branch Employee activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleFileDeleteBranch = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted file from employee' },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Branch Employee file deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditBranchUsername = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed username from ${additionalData[0]} to ${additionalData[1]} for employee` },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Branch Employee username changed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleBranchEditPermission = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed permission group from ${additionalData[0]} to ${additionalData[1]} for employee` },
          { id, title, link: `${links.employees}${id}` },
        ]}
        title="Branch Employee permission changed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
}
