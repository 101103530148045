import React from 'react';
import clsx from 'clsx';

import { CalendarIcon } from '../../../components/svgs/CalendarIcon';
import CloseIcon from '../../../components/svgs/CloseIcon';
import CustomDatePicker from '../custom-date-picker/CustomDatePicker';

import { dateSelect, SUBJECT } from './constants/constants';
import { ISelectedDatePickerProps } from './constants/types';
import { useSelectDatePicker } from './useSelectDatePicker';

import styles from './SelectDatePicker.module.scss';

const SelectDatePicker = ({
  fieldName,
  label = 'Date',
  isAbleToSelectFutureValue = false,
  defaultSelected = '',
  withoutAll = false,
}: ISelectedDatePickerProps) => {
  const {
    isCalendarOpen,
    isOpenedMonth,
    handleOpenList,
    setIsCalendarOpen,
    onRangeChange,
    startSelectDate,
    handleClear,
    endSelectDate,
    selectDate,
    handleOpenCalendar,
    inputValue,
    ref,
  } = useSelectDatePicker({ fieldName, defaultSelected });
  return (
    <div
      className={clsx(styles.selectDatePickerWrapper, {
        [styles.openedWrapper]: isOpenedMonth || isCalendarOpen,
      })}
    >
      <label>{label}</label>
      <ul className={styles.datepickerMainList} ref={ref}>
        <li className={styles.datepickerAction}>
          <div
            className={clsx(styles.actionItem, {
              [styles.openedPicker]: isOpenedMonth,
            })}
            role="button"
            onClick={defaultSelected === 'custom' ? () => handleOpenCalendar(false) : handleOpenList}
          >
            {inputValue}
            {startSelectDate && endSelectDate && defaultSelected === 'custom' ? (
              <div role="button" className={styles.clearBtn} onClick={handleClear}>
                <CloseIcon />
              </div>
            ) : (
              <span>
                <CalendarIcon />
              </span>
            )}
          </div>
          <ul
            className={clsx(styles.sublist, {
              [styles.openedList]: isOpenedMonth,
            })}
          >
            {defaultSelected !== 'custom' &&
              dateSelect
                ?.filter(el => (withoutAll ? el.value !== 'all' : el))
                .map(({ name, value }) => {
                  return (
                    <li key={value} role="button" className={styles.listItem} onClick={() => selectDate(value)}>
                      {name}
                    </li>
                  );
                })}
            {defaultSelected !== 'custom' && (
              <li role="button" className={styles.listItem} onClick={() => handleOpenCalendar(true)}>
                {SUBJECT.CUSTOM}
              </li>
            )}
          </ul>
        </li>
      </ul>
      <div className={styles.rangepicker}>
        {isCalendarOpen && (
          <CustomDatePicker
            isAbleToSelectFutureValue={isAbleToSelectFutureValue}
            selectsRange
            isCalendarOpen={isCalendarOpen}
            setIsCalendarOpen={setIsCalendarOpen}
            handleDateChange={onRangeChange}
            start={startSelectDate}
            end={endSelectDate}
            isMonthPicker
            defaultSelected={defaultSelected}
          />
        )}
      </div>
    </div>
  );
};

export default SelectDatePicker;
