import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, notification, Row } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { IGetLoadsParams } from 'services/loads/interface';
import { useDeleteCarrierRatingMutation } from 'services/loads/loads';
import { useGetAuthoritiesShortQuery, useGetAuthorityQuery } from 'services/profile/authorities/authorities';
import { useLazyGetCarrierRatingQuery } from 'services/profile/carriers/carriers';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectModals } from 'store/modal-slice/selector';
import { IDataSource } from 'ui/data-grid/constants/types';
import { CarrierStatus } from 'ui/load-status/constants/types';
import Typography from 'ui/typography/Typography';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from './constants/constants';
import { IPayablesProps, LoadsViewTypes } from './constants/types';

import styles from './PayablesView.module.scss';

const usePayablesView = ({ getCarrierPay, pagePermissions, setValue }: Partial<IPayablesProps>) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectModals);

  const {
    searchParams: { tab, authorityId, id, mode },
  } = useDetectedParams();

  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ...(!isNaN(Number(authorityId)) ? { ignoreAuthorityIds: [Number(authorityId)] } : {}),
  });
  const { data: authorities = [] } = useGetAuthoritiesShortQuery(authoritiesState, {
    skip: tab !== 'payables',
  });

  const [getCarrierRating, { data: rateList }] = useLazyGetCarrierRatingQuery();
  const [deleteCarrierRating] = useDeleteCarrierRatingMutation();

  const { currentData: authorityIdData } = useGetAuthorityQuery(authorityId, { skip: !authorityId });

  const selectedAuthorityOption = useMemo(
    () => ({
      id: authorityIdData?.result?.id,
      name: authorityIdData?.result?.name,
      value: String(authorityIdData?.result?.id),
    }),
    [authorityIdData?.result]
  );
  const getCarrierRatings = () => {
    getCarrierRating({ skip: 0, limit: 50, carrierId: Number(id) });
  };

  const handleDeleteRate = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_DELETE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        onOk: () => {
          deleteCarrierRating({ ratingId: Number(id) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const [selectedRows, setSelectedRows] = useState<IDataSource[]>([]);

  const summary = useMemo(() => {
    let sum = 0;
    selectedRows.forEach(row => {
      sum += +row.carrierBalance;
    });
    return sum;
  }, [selectedRows.length]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleCheckboxChange = (data: number | string[], items: any[]) => {
    setSelectedRows(
      items?.filter(
        (row: any) =>
          row.status !== CarrierStatus.CARRIER_STATUS_PAID && row.status !== CarrierStatus.CARRIER_STATUS_DEBT
      )
    );
  };

  const handlePayAllSelectedRows = () => {
    dispatch(
      openModal({
        title: SUBJECT.PAYMENT,
        content: (
          <Row align="middle" gutter={[12, 20]}>
            <Col>
              <Typography
                className={styles.payableModalItem}
                children={`${SUBJECT.LOAD}: ${selectedRows?.length}`}
                variant="paragraph"
              />
            </Col>
            <Col>
              <Typography
                className={styles.payableModalItem}
                children={`${SUBJECT.TOTAL}: ${formatNumberWithThousandsSeparatorAndDecimal(summary, true)}`}
                variant="paragraph"
              />
            </Col>
          </Row>
        ),
        cancelButtonVariant: 'text',
        okText: SUBJECT.PAY,
        cancelText: SUBJECT.CANCEL,
        currentClass: 'payableModal',
        onOk: () => {
          if (getCarrierPay && !isLoading) {
            dispatch(changeLoading(true));
            getCarrierPay({ ids: selectedRows.map(({ id }) => id) }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(changeLoading(false));
              } else {
                notification.success({
                  message: 'Successfully paid',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(changeLoading(false));
                dispatch(closeModal());
                setSelectedRows([]);
              }
            });
          }
        },
        onCancel: handleCloseModal,
      })
    );
  };

  const handlePayBtnClick = (data: IDataSource | undefined) => {
    dispatch(
      openModal({
        title: SUBJECT.PAYMENT,
        content: (
          <Row align="middle" gutter={[12, 20]}>
            <Col>
              <Typography className={styles.payableModalItem} children={`${SUBJECT.LOAD}: 1`} variant="paragraph" />
            </Col>
            <Col>
              <Typography
                className={styles.payableModalItem}
                children={`${SUBJECT.TOTAL}: ${formatNumberWithThousandsSeparatorAndDecimal(
                  data!.carrierBalance,
                  true
                )} `}
                variant="paragraph"
              />
            </Col>
          </Row>
        ),
        cancelButtonVariant: 'text',
        okText: SUBJECT.PAY,
        cancelText: SUBJECT.CANCEL,
        currentClass: 'payableModal',
        onOk: () => {
          if (getCarrierPay && !isLoading) {
            dispatch(changeLoading(true));
            getCarrierPay({ ids: [data?.id] }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(changeLoading(false));
              } else {
                notification.success({
                  message: 'Successfully paid',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(changeLoading(false));
                dispatch(closeModal());
              }
            });
          }
        },
        onCancel: handleCloseModal,
      })
    );
  };

  const checkIsDisabledRowSelection = (item: any, node: ReactNode) => {
    if (!pagePermissions.pay && !pagePermissions.claimLoadPay) {
      return;
    } else if (item.claimed && pagePermissions.claimLoadPay) {
      return node;
    } else if (!item.claimed && pagePermissions.pay) {
      return node;
    }
    return true;
  };

  const isDisabledPayButton = (claimed: boolean) =>
    !!selectedRows.length || (claimed && !pagePermissions.claimLoadPay) || (!claimed && !pagePermissions.pay);

  const handleOpenLoadView = (rowData: IDataSource) => {
    window.open(
      `http://${window.location.host}/loads?tab=loads&open=true&mode=${LoadsViewTypes[rowData.loadType]}&id=${
        rowData.id
      }`
    );
  };

  useEffect(() => {
    setValue && setValue('authorityIds', [{ id: authorityId, value: authorityId }]);
  }, []);

  return {
    summary,
    count: authorityId ? authorities?.count + 1 : authorities?.count,
    authorities: [selectedAuthorityOption, ...(authorities?.result || [])],
    selectedRows,
    rateList,
    setSelectedRows,
    handleDeleteRate,
    handlePayBtnClick,
    getCarrierRatings,
    handleOpenLoadView,
    setAuthoritiesState,
    handleCheckboxChange,
    handlePayAllSelectedRows,
    checkIsDisabledRowSelection,
    isDisabledPayButton,
    mode,
  };
};

export default usePayablesView;
