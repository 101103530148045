import { AuthorityTypes } from 'pages/profile/tabs/authorities/constants/constants';
import { formatPhone, handleDeFloatedBrackeds } from 'utils/helpers';

import { AgentType, IAgentById, IAgentTable, IAuthorityByAgentId, Location } from './interfaces';

export const getAgentListSerializer = (body: IAgentTable) => {
  const result = body?.result?.map((agent: IAgentById) => {
    return {
      id: agent?.id,
      createdAt: agent?.createdAt,
      firstname: agent?.firstName,
      lastname: agent?.lastName,
      agent_id: agent?.id,
      phone: formatPhone(agent?.phone),
      phone2: formatPhone(agent?.phoneSecond),
      address: agent?.address,
      city: agent?.city,
      state: agent?.state?.name,
      zip: agent?.zip,
      agent_type: AgentType[agent?.agentType],
      username: agent?.username,
      email: agent?.email,
      office: agent?.office?.name,
      branch: agent?.branch?.name || '',
      location: Location[agent?.location],
      verifiedAt: !!agent?.verifiedAt,
      reset_password: agent?.verifiedAt ? 'Reset Password' : ' ',
      activity: agent?.isOnline,
      terminatedAt: agent?.terminatedAt,
      terminate_denied: !agent?.verifiedAt,
      emergencyName: agent?.emergencyName,
      emergencyPhone: agent?.emergencyPhone,
      emergencyRelation: agent?.emergencyRelation,
    };
  });
  return {
    result,
    totalCount: body?.total,
    online: body?.online,
    count: body?.count,
    passive: Number(body?.total || 0) - Number(body?.online || 0),
  };
};

export const getAgentById = (body: { result: IAgentById }) => {
  const newResult = {
    ...body.result,
    agentPayType: body.result.agentPayType,
    brackets: handleDeFloatedBrackeds(body.result.brackets),
  };
  return {
    result: newResult,
  };
};

export const getAuthoritiesByAgentId = (body: { result: IAuthorityByAgentId[]; count: number }) => {
  const result = body?.result?.map((authority: IAuthorityByAgentId) => {
    return {
      ...authority,
      type: AuthorityTypes[authority.type],
    };
  });
  return {
    result,
    count: body.count,
  };
};
