import { ReactNode } from 'react';
import { formatDate } from 'utils/dates';

import { CreatorTypes, links } from '../constants';
import { EMessageTypes, Handler, ISocketMessage } from '../interfaces';
import Notification from '../notification/Notification';

export class UserHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      verifyAgent: this.handleVerifyAgent,
      verifyManager: this.handleVerifyManager,
      verifyOwner: this.handleVerifyOwner,
    };
  }
  handleVerifyAgent = (event: ISocketMessage) => {
    const {
      creator: { id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[{ title: 'Agent' }, { id, title, link: `${links.agents}${id}` }, { title: 'successfully signed in' }]}
        title="Agent signed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleVerifyManager = (event: ISocketMessage) => {
    const {
      creator: { id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Manager' },
          { id, title, link: `${links.agents}${id}` },
          { title: 'successfully signed in' },
        ]}
        title="Manager signed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleVerifyOwner = (event: ISocketMessage) => {
    const {
      creator: { id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[{ title: 'Owner' }, { id, title, link: `${links.agents}${id}` }, { title: 'successfully signed in' }]}
        title="Owner signed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
}
