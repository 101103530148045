import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useCarrierSignUpMutation } from 'services/auth/auth';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import LogoBlack from 'components/svgs/LogoBlack';

import { SUBJECT } from './constants/constants';
import { Input } from './constants/hoc-instance';
import { IForm } from './constants/interfaces';
import { validation } from './constants/validation';

import styles from './CarrierOTP.module.scss';

const CarrierOTP = () => {
  const [isEmailSended, setIsEmailSended] = useState<boolean | string>(false);
  const [searchParams] = useSearchParams();

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<IForm>({
    defaultValues: { email: '' },
    mode: 'onChange',
    resolver: yupResolver(validation),
  });

  const [carrierSignUp] = useCarrierSignUpMutation();

  const onSubmit = ({ email }: { email: string }) => {
    carrierSignUp(email).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        setIsEmailSended(email);
      }
    });
  };

  if (searchParams.get('done')) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.modal}>
          <LogoBlack />
          <Typography className={styles.heading} variant="heading">
            {SUBJECT.SUCCESS} <br />
            {SUBJECT.THE_FREIGHTMAX_APP} <br />
          </Typography>
          <Typography
            children="We'll review your application within 24 hours."
            variant="paragraph"
            className={styles.helperText}
          />
          <div className={styles.contact}>
            <Typography variant="paragraph">
              {SUBJECT.PROBLEMS_AND_QUESTIONS}
              <a className={styles.phone} href="tel:8068068686">
                806-806-8686
              </a>
              <br />
              {SUBJECT.OR_MAIL}
              <a className={styles.email} href="mailto:info@freigthmax.com">
                info@freigthmax.com
              </a>
            </Typography>
          </div>
          <div className={styles.copyright}>
            <Typography variant="paragraph">
              Copyright &copy; 2024 <span>FreightMax.</span> All Rights Reserved.
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <LogoBlack />

        {isEmailSended ? (
          <>
            <Typography className={styles.heading} variant="heading">
              {SUBJECT.PLEASE_CHECK_EMAIL}
            </Typography>
            <Typography
              children={`Please check ${isEmailSended} mail`}
              variant="paragraph"
              className={styles.helperText}
            />
          </>
        ) : (
          <Typography className={styles.heading} variant="heading">
            {SUBJECT.PLEASE_ENTER_EMAIL} <br />
            {SUBJECT.YOU_WILL_RECEIVE_A_LINK} <br />
            {SUBJECT.MAKE_SURE}
          </Typography>
        )}
        {!isEmailSended && (
          <div className={styles.form}>
            <Input
              name="email"
              control={control}
              rules={{ required: true }}
              placeholder="Email"
              getValues={getValues}
              errors={errors}
              required
            />
            <Button type="primary" onClick={handleSubmit(onSubmit)} children="Send" />
          </div>
        )}
        <div className={styles.contact}>
          <Typography variant="paragraph">
            {SUBJECT.PROBLEMS_AND_QUESTIONS}
            <a className={styles.phone} href="tel:8068068686">
              806-806-8686
            </a>
            <br />
            {SUBJECT.OR_MAIL}
            <a className={styles.email} href="mailto:info@freigthmax.com">
              info@freigthmax.com
            </a>
          </Typography>
        </div>
        <div className={styles.copyright}>
          <Typography variant="paragraph">
            Copyright &copy; 2024 <span>FreightMax.</span> All Rights Reserved.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CarrierOTP;
