import { useEffect, useState } from 'react';

import { useDetectedParams } from '../../hooks/useDetectedParams';
import Loader from '../loader/Loader';

export const MainLoader = ({
  isFetching,
  isLarge = false,
  additionalTimer = 0,
  shouldWorkAnyway = false,
  intoElement = false,
}: {
  isFetching?: any;
  isLarge?: any;
  additionalTimer?: number;
  shouldWorkAnyway?: boolean;
  intoElement?: boolean;
}) => {
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const { searchParams } = useDetectedParams();
  const { mode, tab } = searchParams;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isFetching) {
        setIsLocalLoading(false);
      }
    }, additionalTimer * 1000 || 2000);

    return () => clearTimeout(timer);
  }, [mode, isFetching, tab]);

  useEffect(() => {
    const currentBody = document.querySelector('.ant-drawer-body');
    if (isLocalLoading) {
      currentBody?.classList.add('ant-drawer-body_loader');
    } else {
      currentBody?.classList.remove('ant-drawer-body_loader');
    }
  }, [isLocalLoading]);

  const handleCheckMode = (checkingMode: string) => {
    return mode.toLowerCase().includes(checkingMode);
  };

  return (
    <>
      {isLocalLoading &&
        (handleCheckMode('customize') ||
          handleCheckMode('edit') ||
          handleCheckMode('view') ||
          handleCheckMode('load') ||
          handleCheckMode('blacklist') ||
          handleCheckMode('request') ||
          handleCheckMode('comment') ||
          handleCheckMode('distribute') ||
          handleCheckMode('advance') ||
          handleCheckMode('charge') ||
          handleCheckMode('collection') ||
          shouldWorkAnyway ||
          (tab === 'new_load' && mode === 'create')) && <Loader isLarge={isLarge} intoElement={intoElement} />}
    </>
  );
};
