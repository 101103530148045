import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Skeleton } from 'antd';
import clsx from 'clsx';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import ThemeSwitcher from 'ui/inputs/theme-switcher/ThemeSwitcher';
import Typography from 'ui/typography/Typography';

import DoubleArrowsIcon from 'components/svgs/DoubleArrowsIcon';
import LogoCollapsedIcon from 'components/svgs/LogoCollapsedIcon';
import LogoOpenIcon from 'components/svgs/LogoOpenIcon';

import { allRoutes } from '../../../routes/routes';

import { useSidebar } from './useSidebar';

import styles from './Sidebar.module.scss';

function Sidebar() {
  const { sidebarState, handleSidebarOpen, pathName, routesCase } = useSidebar();

  const { permissionsInfo, isFetching } = useSelector(selectUserPermissionsInfo);

  const allowedRoutes = useMemo(() => {
    if (permissionsInfo?.routes) {
      return allRoutes.filter((route: string) => permissionsInfo.routes.includes(route));
    }
  }, [permissionsInfo?.routes]);

  return (
    <aside
      className={clsx(styles.aside, {
        [styles.collapseAside]: sidebarState,
      })}
    >
      <div className={styles.asideHeader}>
        <div className={styles.headerLogo}>{!sidebarState ? <LogoOpenIcon /> : <LogoCollapsedIcon />} </div>
        <button onClick={handleSidebarOpen} type="button">
          <DoubleArrowsIcon />
        </button>
      </div>
      <div className={styles.asideMenuList}>
        <ul className={styles.menuList}>
          {isFetching ? (
            <div className={styles.skeletonWrapper}>
              <Row gutter={12}>
                <Col span={3}>
                  <div className={styles.leftSkeleton}>
                    <Skeleton
                      active
                      paragraph={{
                        style: { borderRadius: '100%' },
                        rows: 7,
                        width: ['100%', '100%', '100%', '100%', '100%', '100%', '100%'],
                      }}
                    />
                  </div>
                </Col>
                <Col span={20}>
                  <div className={styles.rightSkeleton}>
                    <Skeleton
                      active
                      paragraph={{
                        rows: 7,
                        width: ['85%', '57%', '75%', '68%', '48%', '75%', '65%'],
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            allowedRoutes?.map((route: any) => {
              return (
                <li key={route} className={clsx({ [styles.permissionDivider]: route === 'permission' })}>
                  <a
                    className={clsx({ [styles.activeLink]: pathName.includes(route) })}
                    onClick={() => routesCase[route as keyof typeof routesCase]?.navigate()}
                  >
                    <span>{routesCase[route as keyof typeof routesCase]?.icon()}</span>
                    <Typography variant="paragraph">{routesCase[route as keyof typeof routesCase]?.title}</Typography>
                  </a>
                </li>
              );
            })
          )}
        </ul>
        <div>
          <ThemeSwitcher />
        </div>
        {/*<div*/}
        {/*  className={clsx(styles.footerSidebar, {*/}
        {/*    [styles.collapsedText]: sidebarState,*/}
        {/*  })}*/}
        {/*>*/}
        {/*  <Typography variant="paragraph">*/}
        {/*    {SUBJECT.CREATED}{' '}*/}
        {/*    <Link to="https://aimit.company/" target="_blank">*/}
        {/*      Aimit*/}
        {/*    </Link>*/}
        {/*  </Typography>*/}
        {/*</div>*/}
      </div>
    </aside>
  );
}

export default Sidebar;
