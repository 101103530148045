import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { notification } from 'antd';
import clsx from 'clsx';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import { IComponent, LoadsTabs } from 'pages/profile/constants/constants';
import { IGetCommentRequest } from 'services/loads/interface';
import {
  useCreateCommentMutation,
  useCreateConnectinglLoadMutation,
  useCreateEditCarrierRatingMutation,
  useCreateInternalLoadMutation,
  useCreateRateConfirmationMutation,
  useCreateRegularLoadMutation,
  useCreateTonuLoadMutation,
  useDeleteCarrierRatingMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useGetLoadByIdQuery,
  useGetLoadFilesQuery,
  useGetLoadPointByIdAutocompleteQuery,
  useGetLoadPointByIdQuery,
  useGetRateConfirmationsQuery,
  useGetTransactionstByIdQuery,
  useLazyGetCreditRequestForAgentsByIdQuery,
  useLazyGetCreditRequestForEmployeesByIdQuery,
  useSendRateConfirmationMutation,
} from 'services/loads/loads';
import { useLazyGetCarrierRatingQuery } from 'services/profile/carriers/carriers';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { changeLoading as changeLoadingModal, closeModal, openModal } from 'store/modal-slice/modals';
import { changeLoading } from 'store/sidebarSlice/sidebar';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import ScrollBar from 'ui/scroll-bar/ScrollBar';
import { notificationKey, UserTypes } from 'utils/constants';
import { formatPickUpDropOff } from 'utils/dates';
import { ServerErrorCodes } from 'utils/errors';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { useExportLoadsMutation, useExportNewLoadsForAgentMutation } from '../../services/exports/exports';
import { selectIsLoading } from '../../store/sidebarSlice/selector';

import { ICreateLoad } from './components/create-load/constants/types';
import { CreateLoad } from './components/create-load/CreateLoad';
import { useCreateLoad } from './components/create-load/useCreateLoad';
import EditLoad from './components/edit-load/EditLoad';
import RequestCredit from './components/request-credit/RequestCredit';
import useRequestCredit from './components/request-credit/useRequestCredit';
import { LoadTypes, LoadTypesFromBack, SUBJECT } from './constants/constants';
import ConnectingLoadView from './tabs/loads-tab/components/connecting-load-view/ConnectingLoadView';
import InternalBoardLoadView from './tabs/loads-tab/components/internal-board-view/InternalBoardLoadView';
import LoadsComment from './tabs/loads-tab/components/loads-comment/LoadsComment';
import NewLoadsComment from './tabs/loads-tab/components/loads-comment/NewLoadComment';
import NewLoadsView from './tabs/loads-tab/components/new-loads-view/NewLoadsView';
import RegularLoadView from './tabs/loads-tab/components/regular-load-view/RegularLoadView';
import LoadsTab from './tabs/loads-tab/LoadsTab';
import NewLoad from './tabs/new-load/NewLoad';
import { TONU_SECTIONS } from './tonu/constants/constants';
import TonuCreate from './tonu/create/TonuCreate';
import useTonuCreate from './tonu/create/useCreateTonuLoad';
import TonuLoadEdit from './tonu/edit/TonuEdit';
import TonuLoadView from './tonu/view/TonuView';

const useLoads = () => {
  const [exportLoads] = useExportLoadsMutation();
  const [exportNewLoads] = useExportNewLoadsForAgentMutation();
  const { permissionsInfo, employeeLevel, userType } = useSelector(selectUserPermissionsInfo);
  const userInfo = useSelector(selectUserInfo);
  const { roleLevel } = permissionsInfo;
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab, mode, isEdit, width, id, headerFilter, renderMode } = searchParams;
  const dispatch = useDispatch();
  const isUserEmployee = employeeLevel;

  const inSearchPage = window.location.pathname === '/search-result';
  const isLoading = useSelector(selectIsLoading);

  const {
    currentData: loadData,
    isFetching: isLoadingById,
    refetch: refetchLoadById,
  } = useGetLoadByIdQuery(
    { id },
    {
      skip:
        !id ||
        mode === 'viewNewLoad' ||
        mode === 'create' ||
        mode === 'newLoadComment' ||
        (tab === 'information' && renderMode !== 'loads' && !inSearchPage) ||
        (inSearchPage && headerFilter !== 'loads'),
    }
  );

  const {
    data: loadFiles,
    refetch: refetchFilesByLoadId,
    isFetching: isFilesFetching,
  } = useGetLoadFilesQuery(
    { id },
    {
      skip:
        !id ||
        mode === 'viewNewLoad' ||
        mode === 'create' ||
        mode === 'newLoadComment' ||
        (tab === 'information' && renderMode !== 'loads') ||
        (inSearchPage && headerFilter !== 'loads'),
    }
  );

  const { data: rateConfirmations, refetch: refetchRateConfirmations } = useGetRateConfirmationsQuery(
    { id: String(id) },
    {
      skip:
        (inSearchPage && headerFilter !== 'loads') ||
        !id ||
        mode === 'newLoadComment' ||
        mode === 'create' ||
        mode === 'viewNewLoad' ||
        (tab === 'information' && renderMode !== 'loads'),
    }
  );
  const [createRateConfirmation] = useCreateRateConfirmationMutation();
  const [sendRateConfirmation] = useSendRateConfirmationMutation();

  const [pointsState, setPointsState] = useState<Partial<any>>({
    limit: 10,
    skip: 0,
    search: '',
    field: '',
  });

  const { data: loadPointsAutocomplete } = useGetLoadPointByIdAutocompleteQuery(
    { id, pointsState },

    {
      skip:
        !id ||
        mode === 'newLoadComment' ||
        (inSearchPage && headerFilter !== 'loads') ||
        mode === 'viewNewLoad' ||
        tab === 'information',
    }
  );

  const { data: loadPoints, refetch: refetchLoadPoints } = useGetLoadPointByIdQuery(
    { id },

    {
      skip:
        !id ||
        mode === 'newLoadComment' ||
        mode === 'viewNewLoad' ||
        (inSearchPage && headerFilter !== 'loads') ||
        (tab === 'information' && renderMode !== 'loads'),
    }
  );

  const { data: loadTransactions, refetch: refetchTransaction } = useGetTransactionstByIdQuery(
    { id },
    {
      skip:
        mode === 'create' ||
        mode === 'viewNewLoad' ||
        mode === 'newLoadComment' ||
        (tab === 'information' && renderMode !== 'loads') ||
        !id ||
        (inSearchPage && headerFilter !== 'loads'),
    }
  );

  const activeSection = useSelector(selectActiveSection);

  const isButtonVisible = true;

  const createLoadForm = useCreateLoad({ editLoadData: loadData });

  const [
    getCreditRequestForAgentsById,
    { currentData: creditRequestById, isFetching: isAgentByIdFetching, isError, error },
  ] = useLazyGetCreditRequestForAgentsByIdQuery();
  const [
    getCreditRequestForEmployeesById,
    { currentData: creditRequestForEmployeeById, isFetching: isEmployeeByIdFetching },
  ] = useLazyGetCreditRequestForEmployeesByIdQuery();

  useEffect(() => {
    if (userType) {
      if (id && (mode === 'viewNewLoad' || mode === 'newLoadComment') && UserTypes.AGENT === userType) {
        getCreditRequestForAgentsById({ id });
      }
      if (id && (mode === 'viewNewLoad' || mode === 'newLoadComment') && UserTypes.AGENT !== userType) {
        getCreditRequestForEmployeesById({ id });
      }
    }
  }, [id, mode, userType]);

  const requestCreditForm = useRequestCredit();
  const tonuCreateForm = useTonuCreate();

  const { data: columnsData } = useGetColumnsQuery(
    { type: tab === 'loads' ? 'loads' : 'newLoad' },
    { skip: inSearchPage || tab === 'information' }
  );

  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [createRegularLoad, { isLoading: isCreateRegularLoading, error: regularError }] =
    useCreateRegularLoadMutation();
  const [createConnectingLoad, { isLoading: isCreateConnectingLoading, error: connectingError }] =
    useCreateConnectinglLoadMutation();
  const [createInternalLoad, { isLoading: isCreateInternalLoading }] = useCreateInternalLoadMutation();
  const [createTonuLoad, { isLoading: isCreateTonuLoading }] = useCreateTonuLoadMutation();

  const loadsPermissions = usePermittedActions('loads.loads');
  const { view: _, download: __, ...billingPermissions } = usePermittedActions('factoring.billing');
  const loadsPagePermissions = {
    ...loadsPermissions,
    ...billingPermissions,
  };

  const hasEditPermission = Object.entries(loadsPagePermissions).some(([key, value]) => key !== 'view' && value);

  const [ratesParams] = useState({
    skip: 0,
    limit: 50,
  });

  const [createEditCarrierRating] = useCreateEditCarrierRatingMutation();
  const [getCarrierRating, { data: rateList }] = useLazyGetCarrierRatingQuery();
  const [deleteCarrierRating] = useDeleteCarrierRatingMutation();

  const handleCarrierRate = (rating: number | string) => {
    if (loadData?.load?.carrier?.accessRating) {
      createEditCarrierRating({ carrierId: loadData?.load?.carrier?.id, rating }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully rated',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        }
      });
    }
  };

  const getCarrierRatings = () => {
    getCarrierRating({ skip: ratesParams.skip, limit: ratesParams.limit, carrierId: loadData?.load?.carrier?.id });
  };

  const handleDeleteRate = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_DELETE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        onOk: () => {
          deleteCarrierRating({ ratingId: Number(id) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleCreateRateConfirmation = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_RATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'modalPrimary',
        onOk: () => {
          dispatch(changeLoadingModal(true));
          createRateConfirmation({ id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoadingModal(false));
            } else {
              notification.success({
                message: 'Successfully created',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              dispatch(changeLoadingModal(false));
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleSendRateConfirmation = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_RATE_SEND,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'modalPrimary',
        onOk: () => {
          dispatch(changeLoadingModal(true));
          sendRateConfirmation({ id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoadingModal(false));
            } else {
              notification.success({
                message: 'Successfully sent',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              dispatch(changeLoadingModal(false));
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const isFormWithFooter = useMemo(() => {
    return (
      loadData?.load?.loadType &&
      createLoadForm.watch('load_type') &&
      Number(loadData?.load?.loadType) !== Number(createLoadForm.watch('load_type'))
    );
  }, [loadData?.load?.loadType, createLoadForm.watch('load_type')]);

  const isFilledPickUpDropOffs =
    createLoadForm.watch('load_type') === LoadTypesFromBack['Internal']
      ? !!createLoadForm.pickupDropOffsWatch(['dropType', 'appointmentDate', 'appointmentTime', 'city', 'state'])
      : !!createLoadForm
          .pickupDropOffsWatch([
            'dropType',
            'appointmentDate',
            'appointmentTime',
            'city',
            'state',
            'zip',
            'facility',
            'address',
          ])
          .filter(el => !!el)?.length;

  const filledPickUpDropOffs =
    createLoadForm.watch('load_type') === LoadTypesFromBack['Internal']
      ? createLoadForm.pickupDropOffsWatch(['dropType', 'appointmentDate', 'appointmentTime', 'city', 'state'])
      : createLoadForm
          .pickupDropOffsWatch([
            'dropType',
            'appointmentDate',
            'appointmentTime',
            'city',
            'state',
            'zip',
            'facility',
            'address',
          ])
          .filter(el => !!el);

  useEffect(() => {
    if (createLoadForm?.isSubmitting && isFilledPickUpDropOffs) {
      createLoadForm?.pickupsDropoffsTrigger();
    }
  }, [createLoadForm?.isSubmitting]);

  const [invalidLoadPointIds, setInvalidLoadPointIds] = useState<any>([]);

  const letsGetInvalidLodPointsId = () => {
    const allLoadPoints =
      Array.isArray(createLoadForm?.watch('pickups')) && Array.isArray(createLoadForm?.watch('dropOffs'))
        ? [...createLoadForm.watch('pickups'), ...createLoadForm.watch('dropOffs')]
        : [];
    const onlyInvalidLoadPointsIds = allLoadPoints
      ?.filter((el: any) => {
        if (!el?.facility) {
          return el;
        } else if (!el?.address) {
          return el;
        } else if (!el?.zip) {
          return el;
        }
      })
      .map((el: any) => el?.id);
    setInvalidLoadPointIds(onlyInvalidLoadPointsIds);
  };

  useEffect(() => {
    if (
      (String(createLoadForm.watch('load_type')) !== LoadTypes.INTERNAL_BOARD_LOAD &&
        connectingError?.data?.code === 1320) ||
      regularError?.data?.code === 1320
    ) {
      letsGetInvalidLodPointsId();
    } else {
      setInvalidLoadPointIds([]);
    }
  }, [connectingError?.data?.code, regularError?.data?.code]);

  useEffect(() => {
    if (
      String(createLoadForm.watch('load_type')) !== LoadTypes.INTERNAL_BOARD_LOAD &&
      createLoadForm?.watch('pickups')?.length &&
      createLoadForm?.watch('dropOffs')?.length
    ) {
      letsGetInvalidLodPointsId();
    } else {
      setInvalidLoadPointIds([]);
    }
  }, [createLoadForm?.watch('pickups'), createLoadForm?.watch('dropOffs'), createLoadForm.watch('load_type')]);

  const onSubmit = (data: ICreateLoad) => {
    if (
      createLoadForm?.watch('pickups').length &&
      createLoadForm?.watch('dropOffs').length &&
      !isFilledPickUpDropOffs
    ) {
      const carrierId = data?.carrier?.[0]?.value;
      const truckId = data?.truck?.[0]?.value;
      if (data?.load_type === LoadTypes.REGULAR_LOAD) {
        dispatch(changeLoading(true));
        createRegularLoad({
          ...data,
          id,
          ...(carrierId?.length ? { carrier: carrierId } : {}),
          ...(truckId?.length ? { truck: truckId } : {}),
        }).then((data: any) => {
          if (data.error) {
            notification.error({
              message:
                Number(data?.error?.data?.code) === 2000
                  ? `${ServerErrorCodes[Number(data?.error?.data?.code) || 0]} ${formatPickUpDropOff(
                      new Date(createLoadForm?.carrierInsurance)
                    )}`
                  : ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            dispatch(changeLoading(false));
          } else {
            notification.success({
              message: 'Successfully created',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            setSearchParams({
              ...searchParams,
              open: 'false',
              id: '',
            });
            createLoadForm.reset();
            createLoadForm.pickupsDropoffsReset({});
            dispatch(changeLoading(false));
          }
        });
      }
      if (data?.load_type === LoadTypes.CONNECTING_LOAD) {
        dispatch(changeLoading(true));
        createConnectingLoad({
          ...data,
          id,
        }).then((data: any) => {
          if (data.error) {
            notification.error({
              message:
                Number(data?.error?.data?.code) === 2000
                  ? `${ServerErrorCodes[Number(data?.error?.data?.code) || 0]} ${formatPickUpDropOff(
                      new Date(createLoadForm?.carrierInsurance)
                    )}`
                  : ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            dispatch(changeLoading(false));
          } else {
            notification.success({
              message: 'Successfully created',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            setSearchParams({
              ...searchParams,
              open: 'false',
              id: '',
            });
            createLoadForm.reset();
            createLoadForm.pickupsDropoffsReset({});
            dispatch(changeLoading(false));
          }
        });
      }
      if (data?.load_type === LoadTypes.INTERNAL_BOARD_LOAD) {
        dispatch(changeLoading(true));
        createInternalLoad({
          ...data,
          id,
        }).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            dispatch(changeLoading(false));
          } else {
            notification.success({
              message: 'Successfully created',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            setSearchParams({
              ...searchParams,
              open: 'false',
              id: '',
            });
            createLoadForm.reset();
            createLoadForm.pickupsDropoffsReset({});
            dispatch(changeLoading(false));
          }
        });
      }
    }
  };

  const onTonuSubmit = (data: any) => {
    dispatch(changeLoading(true));
    createTonuLoad(data).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        setSearchParams({
          ...searchParams,
          open: 'false',
          id: '',
        });
        tonuCreateForm.resetTonu();
        tonuCreateForm.setAllFiles([]);
        dispatch(changeLoading(false));
      }
    });
  };

  const [getCommentsFilter, setCommentsFilter] = useState<IGetCommentRequest>({
    skip: 0,
    limit: 10,
  });

  const { data: loadsComments, isFetching: isLoadsCommentsLoading } = useGetCommentsQuery(
    { id, ...getCommentsFilter },
    { skip: !id || mode === 'create' }
  );
  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const isShowMoreVisible = useMemo(
    () => loadsComments?.count && loadsComments!.count > getCommentsFilter.limit,
    [loadsComments, getCommentsFilter]
  );

  const getMoreComments = () => {
    setCommentsFilter((prev: IGetCommentRequest) => ({ ...prev, limit: prev?.limit + 10 }));
  };

  const handleCreateComment = (text: string) => {
    dispatch(changeLoading(true));
    createComment({ id: Number(id), message: text }).then((messageData: any) => {
      if (messageData.error) {
        notification.error({
          message: ServerErrorCodes[Number(messageData?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      }
    });
  };
  const handleDeleteCommentModal = (commentId: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoadingModal(true));
          deleteComment({ id: Number(id), commentId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoadingModal(false));
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoadingModal(false));
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const openMainDrawer = (mode: string) => {
    setSearchParams({
      ...searchParams,
      mode,
      open: 'true',
    });
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      mode: 'view',
      isEdit: '',
      customerName: '',
      id: '',
      renderMode: '',
    });
    dispatch(closeModal());
    if (mode === 'create' || mode === 'edit') {
      createLoadForm.reset();
      createLoadForm.clearErrors();
      createLoadForm.pickupsDropoffsReset({});
    }
    if (mode === 'add') {
      requestCreditForm.reset();
      requestCreditForm.clearErrors();
      createLoadForm.pickupsDropoffsReset({});
    }
    if (mode === 'tonuCreate') {
      tonuCreateForm.resetTonu();
      tonuCreateForm.clearErrors();
      tonuCreateForm.setAllFiles([]);
    }
  };

  const handleCancelTonuCreate = () => {
    if (Object.keys(tonuCreateForm.dirtyFields)?.length) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: tonuCreateForm.isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: tonuCreateForm.isValid
            ? () => {
                onTonuSubmit(tonuCreateForm.tonuWatch());
                dispatch(closeModal());
              }
            : () => {
                tonuCreateForm.clearErrors();
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        open: 'false',
        isEdit: '',
        id: '',
      });
    }
  };

  const handleRequestCreditCancel = () => {
    if (requestCreditForm.isDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: requestCreditForm.isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: requestCreditForm.isValid
            ? () => {
                requestCreditForm.onSubmit(requestCreditForm.watch());
                dispatch(closeModal());
              }
            : () => {
                requestCreditForm.clearErrors();
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      handleCloseModal();
    }
  };

  const handleCreateLoadCancel = () => {
    if (Object.keys(createLoadForm.dirtyFields).length) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: createLoadForm.isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: createLoadForm.isValid
            ? () => {
                createLoadForm?.pickupsDropoffsTrigger();
                onSubmit(createLoadForm.watch());
                dispatch(closeModal());
              }
            : () => {
                createLoadForm.clearErrors();
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      handleCloseModal();
    }
  };

  const [excelParams, setExcelParams] = useState<any>();
  const downloadExcel = () => {
    const exportFunctionMap: Record<string, any> = {
      [LoadsTabs.loads]: exportLoads,
      [LoadsTabs.newLoad]: exportNewLoads,
    };

    const exportFunction = exportFunctionMap[tab];

    dispatch(changeLoading(true));
    exportFunction(excelParams).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: SUBJECT.CHECK_EMAIL,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      }
    });
  };

  const handleCommentClick = () => {
    setSearchParams({
      ...searchParams,
      mode: 'comments',
      open: 'true',
      id,
    });
  };

  const Component: IComponent = {
    loads: <LoadsTab setExcelParams={setExcelParams} loadData={loadData} hasEditPermission={hasEditPermission} />,
    new_load: <NewLoad setExcelParams={setExcelParams} />,
  };
  const [dragColumns, setDragColumns] = useState<any>([]);

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: tab === 'loads' ? 'loads' : 'newLoad',
    });

  useEffect(() => {
    if (columnsData?.columns?.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: {
      component: <RequestCredit {...requestCreditForm} />,
      buttonText: SUBJECT.SEND_REQUEST,
      onCancel: handleRequestCreditCancel,
      onSubmit: requestCreditForm?.handleSubmit(requestCreditForm?.onSubmit),
    },
    regularLoad: {
      component: (
        <RegularLoadView
          isFetching={isLoadingById}
          data={loadData}
          files={loadFiles}
          points={loadPoints}
          transactions={loadTransactions}
          handleCommentClick={handleCommentClick}
          refetchLoadById={refetchLoadById}
          comments={loadsComments}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          loadsPagePermissions={loadsPagePermissions}
          hasEditPermission={hasEditPermission}
          serializedStates={createLoadForm.serializedStates}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
          handleCreateRateConfirmation={handleCreateRateConfirmation}
          handleSendRateConfirmation={handleSendRateConfirmation}
          rateConfirmations={rateConfirmations}
          createRateConfCheck={false}
        />
      ),
      onCancel: handleCloseModal,
    },
    connectingLoad: {
      component: (
        <ConnectingLoadView
          isFetching={isLoadingById}
          data={loadData}
          files={loadFiles}
          points={loadPoints}
          transactions={loadTransactions}
          handleCommentClick={handleCommentClick}
          refetchLoadById={refetchLoadById}
          comments={loadsComments}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          loadsPagePermissions={loadsPagePermissions}
          hasEditPermission={hasEditPermission}
          serializedStates={createLoadForm.serializedStates}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
          handleCreateRateConfirmation={handleCreateRateConfirmation}
          rateConfirmations={rateConfirmations}
          createRateConfCheck={false}
          handleSendRateConfirmation={handleSendRateConfirmation}
        />
      ),
      onCancel: handleCloseModal,
    },
    internalBoardLoad: {
      component: (
        <InternalBoardLoadView
          isFetching={isLoadingById}
          data={loadData}
          files={loadFiles}
          transactions={loadTransactions}
          points={loadPoints}
          handleCommentClick={handleCommentClick}
          refetchLoadById={refetchLoadById}
          comments={loadsComments}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          loadsPagePermissions={loadsPagePermissions}
          hasEditPermission={hasEditPermission}
          serializedStates={createLoadForm.serializedStates}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
        />
      ),
      onCancel: handleCloseModal,
    },
    viewNewLoad: {
      component: (
        <NewLoadsView
          data={UserTypes.AGENT === userType ? creditRequestById : creditRequestForEmployeeById}
          isError={isError}
          error={error}
          isLoadingById={UserTypes.AGENT === userType ? isAgentByIdFetching : isEmployeeByIdFetching}
        />
      ),
    },
    comments: {
      component: (
        <LoadsComment
          loadData={loadData!}
          loadsPagePermissions={loadsPagePermissions}
          createComment={handleCreateComment}
          loadsComments={loadsComments}
          deleteComment={handleDeleteCommentModal}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          isLoading={isLoadsCommentsLoading || isLoadingById}
          hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
        />
      ),
      onCancel: handleCloseModal,
    },
    newLoadComment: {
      component: (
        <NewLoadsComment
          creditRequestById={UserTypes.AGENT === userType ? creditRequestById : creditRequestForEmployeeById}
          createComment={handleCreateComment}
          loadsComments={loadsComments}
          deleteComment={handleDeleteCommentModal}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          isLoading={isLoadsCommentsLoading}
          hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
        />
      ),
      onCancel: handleCloseModal,
    },
    tonuLoad: {
      component: (
        <TonuLoadView
          data={loadData}
          files={loadFiles}
          transactions={loadTransactions}
          handleCommentClick={handleCommentClick}
          loadsPagePermissions={loadsPagePermissions}
          hasEditPermission={hasEditPermission}
          refetchLoadById={refetchLoadById}
          comments={loadsComments}
          getMoreComments={getMoreComments}
          rateList={rateList}
          handleCarrierRate={handleCarrierRate}
          getCarrierRatings={getCarrierRatings}
          handleDeleteRate={handleDeleteRate}
          isShowMoreVisible={isShowMoreVisible}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
          handleCreateRateConfirmation={handleCreateRateConfirmation}
          rateConfirmations={rateConfirmations}
          createRateConfCheck={false}
          handleSendRateConfirmation={handleSendRateConfirmation}
          {...tonuCreateForm}
        />
      ),
      onCancel: handleCloseModal,
    },
    tonuCreate: {
      component: (
        <TonuCreate
          {...tonuCreateForm}
          onSubmit={onTonuSubmit}
          activeSection={activeSection}
          isCreateTonuLoading={isCreateTonuLoading}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onCancel: handleCancelTonuCreate,
      onSubmit: tonuCreateForm.handleTonuSubmit(onTonuSubmit),
    },
    tonuEdit: {
      component: (
        <div
          className={clsx({
            ['scrollBarWrapper']: width === 'full',
          })}
        >
          {width === 'full' && <ScrollBar sections={TONU_SECTIONS} />}
          <div
            className={clsx('scrollBarContent', {
              ['openedDrawer']: width === 'full',
            })}
          >
            <TonuLoadEdit
              data={loadData}
              tonuCreateForm={tonuCreateForm}
              loadTransactions={loadTransactions}
              loadFiles={loadFiles}
              mode={mode}
              comments={loadsComments}
              createComment={handleCreateComment}
              deleteComment={handleDeleteCommentModal}
              getMoreComments={getMoreComments}
              isShowMoreVisible={isShowMoreVisible}
              refetchTransaction={refetchTransaction}
              refetchLoadById={refetchLoadById}
              refetchFilesByLoadId={refetchFilesByLoadId}
              isUserEmployee={isUserEmployee}
              activeSection={activeSection}
              loadsPagePermissions={loadsPagePermissions}
              roleLevel={roleLevel}
              isFetching={isLoadingById}
              rateList={rateList}
              handleCarrierRate={handleCarrierRate}
              getCarrierRatings={getCarrierRatings}
              handleDeleteRate={handleDeleteRate}
              isLoadsCommentsLoading={isLoadsCommentsLoading}
              handleCreateRateConfirmation={handleCreateRateConfirmation}
              rateConfirmations={rateConfirmations}
              isFilesFetching={isFilesFetching}
              createRateConfCheck={rateConfirmations?.canCreateRateConfirmation}
              handleSendRateConfirmation={handleSendRateConfirmation}
              refetchRateConfirmations={refetchRateConfirmations}
            />
          </div>
        </div>
      ),
      buttonText: SUBJECT.SAVE,
      onCancel: handleCancelTonuCreate,
    },
    create: {
      component: (
        <CreateLoad
          createLoadForm={createLoadForm}
          refetchLoadPoints={refetchLoadPoints}
          isUserEmployee={isUserEmployee}
          onSubmit={onSubmit}
          isCreateRegularLoading={isCreateRegularLoading}
          isCreateConnectingLoading={isCreateConnectingLoading}
          isCreateInternalLoading={isCreateInternalLoading}
          filledPickUpDropOffs={filledPickUpDropOffs}
          submitted={createLoadForm?.isSubmitted}
          pointsState={pointsState}
          setPointsState={setPointsState}
          loadPoints={loadPoints}
          loadPointsAutocomplete={loadPointsAutocomplete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onCancel: handleCreateLoadCancel,
      onSubmit: createLoadForm.handleSubmit(onSubmit),
    },
    view: {
      component:
        loadData?.load?.loadType === 1 ? (
          <RegularLoadView
            isFetching={isLoadingById}
            data={loadData}
            files={loadFiles}
            points={loadPoints}
            transactions={loadTransactions}
            handleCommentClick={handleCommentClick}
            refetchLoadById={refetchLoadById}
            comments={loadsComments}
            getMoreComments={getMoreComments}
            isShowMoreVisible={isShowMoreVisible}
            loadsPagePermissions={loadsPagePermissions}
            hasEditPermission={hasEditPermission}
            serializedStates={createLoadForm.serializedStates}
            isLoadsCommentsLoading={isLoadsCommentsLoading}
            handleCreateRateConfirmation={handleCreateRateConfirmation}
            rateConfirmations={rateConfirmations}
            createRateConfCheck={false}
            handleSendRateConfirmation={handleSendRateConfirmation}
          />
        ) : loadData?.load?.loadType === 2 ? (
          <TonuLoadView
            data={loadData}
            files={loadFiles}
            transactions={loadTransactions}
            handleCommentClick={handleCommentClick}
            loadsPagePermissions={loadsPagePermissions}
            hasEditPermission={hasEditPermission}
            refetchLoadById={refetchLoadById}
            comments={loadsComments}
            getMoreComments={getMoreComments}
            rateList={rateList}
            handleCarrierRate={handleCarrierRate}
            getCarrierRatings={getCarrierRatings}
            handleDeleteRate={handleDeleteRate}
            isShowMoreVisible={isShowMoreVisible}
            isLoadsCommentsLoading={isLoadsCommentsLoading}
            handleCreateRateConfirmation={handleCreateRateConfirmation}
            rateConfirmations={rateConfirmations}
            createRateConfCheck={false}
            handleSendRateConfirmation={handleSendRateConfirmation}
            {...tonuCreateForm}
          />
        ) : loadData?.load?.loadType === 3 ? (
          <ConnectingLoadView
            isFetching={isLoadingById}
            data={loadData}
            files={loadFiles}
            points={loadPoints}
            transactions={loadTransactions}
            handleCommentClick={handleCommentClick}
            refetchLoadById={refetchLoadById}
            comments={loadsComments}
            getMoreComments={getMoreComments}
            isShowMoreVisible={isShowMoreVisible}
            loadsPagePermissions={loadsPagePermissions}
            hasEditPermission={hasEditPermission}
            serializedStates={createLoadForm.serializedStates}
            isLoadsCommentsLoading={isLoadsCommentsLoading}
            handleCreateRateConfirmation={handleCreateRateConfirmation}
            rateConfirmations={rateConfirmations}
            createRateConfCheck={false}
            handleSendRateConfirmation={handleSendRateConfirmation}
          />
        ) : loadData?.load?.loadType === 4 ? (
          <InternalBoardLoadView
            isFetching={isLoadingById}
            data={loadData}
            files={loadFiles}
            transactions={loadTransactions}
            points={loadPoints}
            handleCommentClick={handleCommentClick}
            refetchLoadById={refetchLoadById}
            comments={loadsComments}
            getMoreComments={getMoreComments}
            isShowMoreVisible={isShowMoreVisible}
            loadsPagePermissions={loadsPagePermissions}
            hasEditPermission={hasEditPermission}
            serializedStates={createLoadForm.serializedStates}
            isLoadsCommentsLoading={isLoadsCommentsLoading}
          />
        ) : null,
      onCancel: handleCloseModal,
    },
    edit: hasEditPermission
      ? {
          component: (
            <EditLoad
              createLoadForm={createLoadForm}
              loadFiles={loadFiles}
              loadTransactions={loadTransactions}
              loadPoints={loadPoints}
              loadPointsAutocomplete={loadPointsAutocomplete}
              comments={loadsComments}
              createComment={handleCreateComment}
              deleteComment={handleDeleteCommentModal}
              getMoreComments={getMoreComments}
              isShowMoreVisible={isShowMoreVisible}
              refetchTransaction={refetchTransaction}
              width={width}
              isFetching={isLoadingById}
              refetchLoadById={refetchLoadById}
              refetchLoadPoints={refetchLoadPoints}
              refetchFilesByLoadId={refetchFilesByLoadId}
              isFormWithFooter={isFormWithFooter}
              data={loadData}
              isUserEmployee={isUserEmployee}
              loadsPagePermissions={loadsPagePermissions}
              roleLevel={roleLevel}
              onSubmit={onSubmit}
              isFilesFetching={isFilesFetching}
              isLoadsCommentsLoading={isLoadsCommentsLoading}
              invalidLoadPointIds={invalidLoadPointIds}
              connectingError={connectingError}
              regularError={regularError}
              userId={userInfo.id}
              handleCreateRateConfirmation={handleCreateRateConfirmation}
              handleSendRateConfirmation={handleSendRateConfirmation}
              rateConfirmations={rateConfirmations}
              createRateConfCheck={rateConfirmations?.canCreateRateConfirmation}
              pointsState={pointsState}
              setPointsState={setPointsState}
              refetchRateConfirmations={refetchRateConfirmations}
              filledPickUpDropOffs={filledPickUpDropOffs}
              submitted={createLoadForm?.isSubmitted}
            />
          ),
          buttonText: SUBJECT.SAVE,
          onCancel: handleCreateLoadCancel,
          onSubmit: createLoadForm.handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" />,
        },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter:
        mode === 'add' ||
        mode === 'create' ||
        mode === 'customize' ||
        (mode === 'tonuCreate' && !isEdit) ||
        !!isFormWithFooter,
    };
  }, [
    tab,
    mode,
    tonuCreateForm.tonuWatch(),
    requestCreditForm.watch(),
    createLoadForm.watch(),
    createLoadForm.errors,
    setDragColumns,
    dragColumns,
    createLoadForm.isDirty,
    tonuCreateForm.isDirty,
    tonuCreateForm.isValid,
    isCreateTonuLoading,
    isCreateRegularLoading,
    isCreateConnectingLoading,
    isCreateInternalLoading,
    filledPickUpDropOffs,
    createLoadForm?.isSubmitted,
    createLoadForm?.pickupsDropoffsReset,
    createLoadForm?.serializedStates,
    isAgentByIdFetching,
    invalidLoadPointIds,
    connectingError,
    regularError,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  return {
    tab,
    openMainDrawer,
    downloadExcel,
    Component,
    isButtonVisible,
    currentChildren,
    drawerChildren,
    permissionsInfo,
    userType,
    isLoading,
    loadsPagePermissions,
  };
};
export default useLoads;
