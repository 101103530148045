import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import { SUBJECT } from './constants/constants';
import { renderCarrierStatus, renderCustomerStatus, renderLoadStatus } from './constants/helpers';
import { ILoadStatusProps } from './constants/types';

import styles from './LoadStatus.module.scss';

const LoadStatus = ({
  loadStatus,
  customerStatus,
  carrierStatus,
  isProcessing,
  withoutBackground,
  withoutLoadsText,
}: ILoadStatusProps) => {
  return (
    <>
      <div className={clsx(styles.loadStatusWrapper)}>
        {!withoutLoadsText && (
          <Typography variant="paragraph" className={styles.loadsText}>
            {SUBJECT.LOAD_STATUS}
          </Typography>
        )}
        <div
          className={clsx(styles.loadStatusItems, {
            [styles.withoutBackground]: withoutBackground,
          })}
        >
          {renderLoadStatus({ isProcessing, status: loadStatus })}
          {renderCustomerStatus({ isProcessing, customerStatus })}
          {renderCarrierStatus({ carrierStatus })}
        </div>
      </div>
    </>
  );
};

export default LoadStatus;
