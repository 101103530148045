import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { ICoordinatorsParams } from './interfaces';
import {
  getCoordinatorsAutocompleteSerializer,
  getParamsSerializer,
  getVerificationListSerializer,
} from './serializers';

export const verificationAPI = createApi({
  reducerPath: 'verificationAPI',
  baseQuery,
  tagTypes: ['VerificationList', 'Verification'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getVerificationList: builder.query({
      query: (filter: Partial<any>) => {
        const params = getParamsSerializer(filter);
        return {
          url: 'truck-board/verifications',
          params,
        };
      },
      transformResponse: (body: any) => getVerificationListSerializer(body),
      providesTags: ['VerificationList', 'Verification'],
    }),
    getCoordinatorsAutocomplete: builder.query({
      query: ({ search, skip, limit, filter }: Partial<ICoordinatorsParams>) => ({
        url: 'employees/autocomplete',
        params: { search, skip, limit, onlyCoordinator: true, ...filter },
      }),
      transformResponse: (body: any) => getCoordinatorsAutocompleteSerializer(body),
      providesTags: ['Verification'],
    }),
  }),
});

export const { useGetVerificationListQuery, useGetCoordinatorsAutocompleteQuery } = verificationAPI;
