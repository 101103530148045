import { ICustomPicker } from 'ui/inputs/custom-date-picker/constants/types';
import CustomDatePicker from 'ui/inputs/custom-date-picker/CustomDatePicker';
import CustomSearchSelect from 'ui/inputs/custom-search-select/CustomSearchSelect';
import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';
import { ISwitchProps } from 'ui/inputs/switch/constants/types';
import CustomSwitch from 'ui/inputs/switch/Switch';
import { ITimePickerProps } from 'ui/inputs/time-picker/constants/types';
import CustomTimePicker from 'ui/inputs/time-picker/TimePicker';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { ICreateLoad, IPickupsDropoffs } from '../constants/types';

export const Input = withController<IInputProps, ICreateLoad | IPickupsDropoffs>(CustomInput);

export const SearchableDropdown = withController<ISearchableDropdownProps, ICreateLoad | IPickupsDropoffs>(
  CustomSearchableDropdown
);

export const Switch = withController<ISwitchProps, ICreateLoad | IPickupsDropoffs>(CustomSwitch);

export const TimePicker = withController<ITimePickerProps, ICreateLoad | IPickupsDropoffs>(CustomTimePicker);

export const DatePicker = withController<ICustomPicker, ICreateLoad | IPickupsDropoffs>(CustomDatePicker);

export const FilterSelect = withController<ISelectProps, IPickupsDropoffs>(ReusableSelect);

export const SearchSelect = withController<any, any>(CustomSearchSelect);
