import { ReactNode } from 'react';
import { formatDate } from 'utils/dates';

import { CreatorTypes, links } from '../constants';
import { EMessageTypes, Handler, ISocketMessage } from '../interfaces';
import Notification from '../notification/Notification';

export class AgentHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      create: this.handleCreate,
      editAgent: this.handleEditAgent,
      terminateAgent: this.handleTerminateAgent,
      activateAgent: this.handleActivateAgent,
      fileDeleteAgent: this.handleFileDeleteAgent,
      fileDeleteManager: this.handleFileDeleteManager,
      editUsernameAgent: this.handleEditUsernameAgent,
      manager: this.handleManager,
      editManager: this.handleEditManager,
      terminateManager: this.handleTerminateManager,
      activateManager: this.handleActivateManager,
      editUsernameManager: this.handleEditUsernameManager,
      owner: this.handleOwner,
      editOwner: this.handleEditOwner,
      terminateOwner: this.handleTerminateOwner,
      activateOwner: this.handleActivateOwner,
      fileDeleteOwner: this.handleFileDeleteOwner,
      editUsernameOwner: this.handleEditUsernameOwner,
    };
  }
  handleCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.agents}${id}` },
          { title: 'agent was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Agent created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleEditAgent = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.agents}${id}` },
          { title: 'agent was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Agent edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleTerminateAgent = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.agents}${id}` },
          { title: 'agent was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Agent terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleActivateAgent = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.agents}${id}` },
          { title: 'agent was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Agent activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleFileDeleteAgent = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'The file of agent' },
          { id, title, link: `${links.agents}${id}` },
          { title: 'was deleted by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Agent file deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditUsernameAgent = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed username from ${additionalData[0]} into ${additionalData[1]} for agent` },
          { id, title, link: `${links.agents}${id}` },
        ]}
        title="Username edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleManager = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Manager' },
          { id, title, link: `${links.agents}${id}` },
          { title: 'was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Manager created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleEditManager = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Manager' },
          { id, title, link: `${links.agents}${id}` },
          { title: 'was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Manager edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleTerminateManager = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.agents}${id}` },
          { title: 'manager was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Manager terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleActivateManager = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.agents}${id}` },
          { title: 'manager was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Manager activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditUsernameManager = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed username from ${additionalData[0]} into ${additionalData[1]} for manager` },
          { id, title, link: `${links.agents}${id}` },
        ]}
        title="Username edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleFileDeleteManager = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'The file of manager' },
          { id, title, link: `${links.agents}${id}` },
          { title: 'was deleted by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Manager file deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleOwner = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Owner' },
          { id, title, link: `${links.agents}${id}` },
          { title: 'was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Owner created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleEditOwner = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Owner' },
          { id, title, link: `${links.agents}${id}` },
          { title: 'was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Owner edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleTerminateOwner = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.agents}${id}` },
          { title: 'owner was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Owner terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleActivateOwner = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.agents}${id}` },
          { title: 'owner was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Owner activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleFileDeleteOwner = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'The file of owner' },
          { id, title, link: `${links.agents}${id}` },
          { title: 'was deleted by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Owner file deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditUsernameOwner = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed username from ${additionalData[0]} into ${additionalData[1]} for owner` },
          { id, title, link: `${links.agents}${id}` },
        ]}
        title="Username edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
}
