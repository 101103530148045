import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import { TDate } from 'utils/types';

import { DateTypes, SUBJECT } from './constants/constants';
import { DateOptions, ISelectedDatePickerProps } from './constants/types';

export const useSelectDatePicker = ({ fieldName, defaultSelected }: ISelectedDatePickerProps) => {
  const [inputValue, setInputValue] = useState<any>(DateTypes[defaultSelected as keyof typeof DateTypes] || 'All');
  const [isOpenedMonth, setIsOpenedMonth] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { searchParams, setSearchParams } = useDetectedParams();
  const {
    [`${fieldName}StartDate`]: startSelectDate,
    [`${fieldName}EndDate`]: endSelectDate,
    [`${fieldName}AllDate`]: allSelectDate,
    [`${fieldName}Option`]: selectedOption,
    isEdit,
    tab,
    currentPage,
  } = searchParams;

  const ref = useOutsideDetect<HTMLUListElement>(setIsOpenedMonth);

  const handleOpenList = () => {
    setIsOpenedMonth(prev => !prev);
  };

  const handleOpenCalendar = (withQuery: boolean) => {
    setIsCalendarOpen(prev => !prev);

    setIsOpenedMonth(false);
    setSearchParams({
      ...searchParams,
      [`${fieldName}StartDate`]: '',
      [`${fieldName}EndDate`]: '',
      ...(withQuery && { [`${fieldName}Option`]: String(DateOptions.CUSTOM) }),
    });
  };

  dayjs.extend(customParseFormat);

  const currentDate = useMemo(() => dayjs().tz('America/Los_Angeles'), [startSelectDate]);

  const startOfMonth = currentDate.startOf('month');
  const endOfMonth = currentDate.endOf('month');

  const startOfToday = currentDate.startOf('day');
  const endOfToday = currentDate.endOf('day');

  const startOfYesterday = currentDate.subtract(1, 'day').startOf('day');
  const endOfYesterday = currentDate.subtract(1, 'day').endOf('day');

  const today = dayjs().tz('America/Los_Angeles');
  const tomorrow = today.endOf('day').valueOf() + 86400000;

  useEffect(() => {
    const key: any = DateOptions[selectedOption as keyof typeof DateOptions];
    if (selectedOption === '5' && !endSelectDate && !startSelectDate) {
      setInputValue(SUBJECT[key as keyof typeof SUBJECT] as string);
    } else if (selectedOption && defaultSelected !== 'custom') {
      if (Number(selectedOption) == DateOptions.CUSTOM) {
        setInputValue(
          `${dayjs(Number(startSelectDate)).format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(Number(endSelectDate)).format(
            SUBJECT.FULL_DATE_FORMAT
          )}`
        );
      } else {
        setInputValue(SUBJECT[key as keyof typeof SUBJECT] as string);
      }
    } else if (
      defaultSelected === 'custom' &&
      startSelectDate &&
      endSelectDate &&
      !selectedOption &&
      isEdit === 'true'
    ) {
      setInputValue(
        `${dayjs(Number(startSelectDate)).format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(
          Number(endSelectDate) - 86400000
        ).format(SUBJECT.FULL_DATE_FORMAT)}`
      );
    }
    // else if (
    //   defaultSelected === 'custom' &&
    //   startSelectDate &&
    //   endSelectDate &&
    //   !selectedOption &&
    //   isEdit !== 'true' &&
    //   tab === 'truck_board' &&
    //   currentPage === 'create'
    // ) {
    //   setInputValue(
    //     `${dayjs(Number(startSelectDate)).format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(
    //       Number(endSelectDate) - 86400000
    //     ).format(SUBJECT.FULL_DATE_FORMAT)}`
    //   );
    else if (defaultSelected === 'custom' && startSelectDate && endSelectDate && selectedOption) {
      setInputValue(
        `${dayjs(Number(startSelectDate)).format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(Number(endSelectDate)).format(
          SUBJECT.FULL_DATE_FORMAT
        )}`
      );
    }
  }, [selectedOption, startSelectDate, endSelectDate]);

  const selectDate = (value: string) => {
    if (value === 'today') {
      setInputValue(SUBJECT.TODAY);
      const { ...rest } = searchParams;
      setSearchParams({
        ...rest,
        [`${fieldName}StartDate`]: String(startOfToday.valueOf()),
        [`${fieldName}EndDate`]: String(endOfToday.valueOf()),
        [`${fieldName}Option`]: String(DateOptions.TODAY),
        [`${fieldName}AllDate`]: '',
      });
    }
    if (value === 'yesterday') {
      setInputValue(SUBJECT.YESTERDAY);
      const { ...rest } = searchParams;
      setSearchParams({
        ...rest,
        [`${fieldName}StartDate`]: String(startOfYesterday.valueOf()),
        [`${fieldName}EndDate`]: String(endOfYesterday.valueOf()),
        [`${fieldName}Option`]: String(DateOptions.YESTERDAY),
        [`${fieldName}AllDate`]: '',
      });
    }
    if (value === 'currentMonth') {
      setInputValue(SUBJECT.CURRENT_MONTH);
      const { ...rest } = searchParams;
      setSearchParams({
        ...rest,
        [`${fieldName}StartDate`]: String(startOfMonth.valueOf()),
        [`${fieldName}EndDate`]: String(endOfMonth.valueOf()),
        [`${fieldName}Option`]: String(DateOptions.CURRENT_MONTH),
        [`${fieldName}AllDate`]: '',
      });
    }
    if (value === 'all') {
      setInputValue(SUBJECT.All);
      const { ...rest } = searchParams;
      setSearchParams({
        ...rest,
        [`${fieldName}AllDate`]: 'true',
        [`${fieldName}Option`]: String(DateOptions.All),
        [`${fieldName}StartDate`]: String(0),
        [`${fieldName}EndDate`]: String(tomorrow),
      });
    }
    setIsOpenedMonth(false);
  };

  const handleClear = (e: any) => {
    e.stopPropagation();
    if (defaultSelected !== 'custom') {
      const key: any = DateOptions[(defaultSelected as keyof typeof DateOptions) || 'All'];
      setInputValue(SUBJECT[key as keyof typeof SUBJECT] as string);
      const { ...rest } = searchParams;
      setSearchParams({
        ...rest,
        [`${fieldName}Option`]: String(DateOptions[defaultSelected as keyof typeof DateOptions] || 'All'),
        [`${fieldName}StartDate`]: '',
        [`${fieldName}EndDate`]: '',
      });
    } else {
      setInputValue('All');
      const { ...rest } = searchParams;
      setSearchParams({
        ...rest,
        [`${fieldName}StartDate`]: '',
        [`${fieldName}EndDate`]: '',
      });
    }
  };

  const onRangeChange = (dates: TDate) => {
    const startOfSelectedFromDate = dayjs(dates?.[0])?.startOf('day');
    const endOfSelectedToDate = dayjs(dates?.[1])?.endOf('day');
    if (dates?.length) {
      setInputValue(
        `${dayjs(dates?.[0]?.valueOf()).format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(dates?.[1]?.valueOf()).format(
          SUBJECT.FULL_DATE_FORMAT
        )}`
      );
      const { ...rest } = searchParams;
      setSearchParams({
        ...rest,
        [`${fieldName}StartDate`]: String(startOfSelectedFromDate?.valueOf()),
        [`${fieldName}EndDate`]: String(endOfSelectedToDate?.valueOf()),
        [`${fieldName}AllDate`]: '',
        ...(defaultSelected === 'custom' && { [`${fieldName}Option`]: String(DateOptions.CUSTOM) }),
      });
    }
    setIsCalendarOpen(false);
  };

  useEffect(() => {
    if (!startSelectDate && !allSelectDate && selectedOption === String(DateOptions.CUSTOM)) {
      setSearchParams({
        ...searchParams,
        [`${fieldName}AllDate`]: '',
        [`${fieldName}Option`]: String(DateOptions.CUSTOM),
        [`${fieldName}StartDate`]: '',
        [`${fieldName}EndDate`]: '',
      });
    } else if (!startSelectDate && !allSelectDate && !defaultSelected) {
      setSearchParams({
        ...searchParams,
        [`${fieldName}StartDate`]: String(0),
        [`${fieldName}EndDate`]: String(tomorrow),
        [`${fieldName}Option`]: String(DateOptions.All),
        [`${fieldName}AllDate`]: 'true',
      });
    } else if (defaultSelected && !startSelectDate && !allSelectDate) {
      if (defaultSelected === 'today') {
        setSearchParams({
          ...searchParams,
          [`${fieldName}StartDate`]: String(startOfToday.valueOf()),
          [`${fieldName}EndDate`]: String(endOfToday.valueOf()),
          [`${fieldName}Option`]: String(DateOptions.TODAY),
          [`${fieldName}AllDate`]: '',
        });
      } else if (defaultSelected === 'yesterday') {
        setSearchParams({
          ...searchParams,
          [`${fieldName}StartDate`]: String(startOfYesterday.valueOf()),
          [`${fieldName}EndDate`]: String(endOfYesterday.valueOf()),
          [`${fieldName}Option`]: String(DateOptions.YESTERDAY),
          [`${fieldName}AllDate`]: '',
        });
      } else if (defaultSelected === 'currentMonth') {
        setSearchParams({
          ...searchParams,
          [`${fieldName}StartDate`]: String(startOfMonth.valueOf()),
          [`${fieldName}EndDate`]: String(endOfMonth.valueOf()),
          [`${fieldName}Option`]: String(DateOptions.CURRENT_MONTH),
          [`${fieldName}AllDate`]: '',
        });
      }
      // else if (
      //   defaultSelected === 'custom' &&
      //   isEdit !== 'true' &&
      //   tab === 'truck_board' &&
      //   currentPage === 'create' &&
      //   !startSelectDate &&
      //   !endSelectDate &&
      //   !selectedOption
      // ) {
      //   setSearchParams({
      //     ...searchParams,
      //     [`${fieldName}StartDate`]: String(startOfToday.valueOf()),
      //     [`${fieldName}EndDate`]: String(endOfToday.valueOf()),
      //   });
      // }
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isCalendarOpen && selectedOption === String(DateOptions.CUSTOM) && !startSelectDate && !endSelectDate) {
      setSearchParams({
        ...searchParams,
        [`${fieldName}StartDate`]: String(startOfMonth.valueOf()),
        [`${fieldName}EndDate`]: String(endOfMonth.valueOf()),
        [`${fieldName}Option`]: String(DateOptions.CURRENT_MONTH),
        [`${fieldName}AllDate`]: '',
      });
    }
  }, [isCalendarOpen]);
  return {
    isOpenedMonth,
    handleOpenList,
    isCalendarOpen,
    setIsCalendarOpen,
    onRangeChange,
    startSelectDate,
    endSelectDate:
      (defaultSelected === 'custom' &&
        startSelectDate &&
        endSelectDate &&
        !selectedOption &&
        isEdit !== 'true' &&
        tab === 'truck_board' &&
        currentPage === 'create') ||
      (defaultSelected === 'custom' && startSelectDate && endSelectDate && !selectedOption && isEdit === 'true')
        ? String(Number(endSelectDate) - 86400000)
        : endSelectDate,
    handleOpenCalendar,
    selectDate,
    ref,
    inputValue,
    handleClear,
  };
};
