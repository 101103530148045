import { phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  CUSTOMER_NAME_REQUIRED,
  REFERENCE_NUMBER_REQUIRED,
  CUSTOMER_AMOUNT_REQUIRED,
  AUTHORITY_REQUIRED,
  CARRIER_REQUIRED,
  CARRIER_AMOUNT_REQUIRED,
  TRUCK_REQUIRED,
  CUSTOMER_CONTACT_NAME_REQUIRED,
  MAX_DIGIT,
  CUSTOMER_CONTACT_REQUIRED,
  PHONE,
  NOT_ZERO,
  LESS_THAN_MILLION,
} = VALIDATION_MESSAGES;

export const tonuValidation = yup.object().shape({
  loadType: yup.string().required(),
  customerSwitch: yup.boolean().notRequired().nullable(),
  carrierSwitch: yup.boolean().notRequired().nullable(),
  soldUsSwitch: yup.boolean().test('soldUsRequired', '', function (value) {
    if (!this.parent.customerSwitch) {
      return value;
    }
    return true;
  }),
  customerName: yup.array().test('length', CUSTOMER_NAME_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.customerSwitch) {
      return value && !!value.length;
    }
    return true;
  }),
  customerContactName: yup.string().test('isRequired', CUSTOMER_CONTACT_NAME_REQUIRED, function (value) {
    if (this.parent.customerSwitch) {
      return !!value;
    }
    return true;
  }),
  referenceNumber: yup.string().test('length', REFERENCE_NUMBER_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.customerSwitch) {
      return !!value;
    }
    return true;
  }),
  customerAmount: yup.string().test('length', CUSTOMER_AMOUNT_REQUIRED, function checkCarrierValue(value) {
    if (Number(value) >= 1000000) {
      return this.createError({
        message: LESS_THAN_MILLION,
        path: 'customerAmount',
      });
    }
    if (this.parent.customerSwitch) {
      if (this.parent.carrierSwitch) {
        if (value) {
          if (Number(value) < Number(this.parent.carrierAmount)) {
            return this?.createError({
              message: `Customer amount must be more or equal than Carrier amount`,
              path: 'customerAmount',
            });
          } else if (String(value).replace('.', '')! == '0') {
            return this.createError({
              message: NOT_ZERO,
              path: 'customerAmount',
            });
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return !!value;
      }
    }
    return true;
  }),
  customerContact: yup
    .string()
    .max(12, MAX_DIGIT)
    .test('length', CUSTOMER_CONTACT_REQUIRED, function checkCarrierValue(value) {
      if (this.parent.customerSwitch) {
        if (value && value.length === 12) {
          return true;
        } else if (value && !value?.match(phoneRegExp)) {
          return this.createError({
            message: PHONE,
            path: 'customerContact',
          });
        } else if (!value) {
          return false;
        }
      }
      return true;
    }),
  takenUsAuthority: yup.array().test('length', AUTHORITY_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.customerSwitch) {
      return value && !!value.length;
    }
    return true;
  }),
  carrier: yup.array().test('length', CARRIER_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.carrierSwitch) {
      return value && !!value.length;
    }
    return true;
  }),
  carrierAmount: yup.string().test('length', CARRIER_AMOUNT_REQUIRED, function checkCarrierValue(value) {
    if (Number(value) >= 1000000) {
      return this.createError({
        message: LESS_THAN_MILLION,
        path: 'carrierAmount',
      });
    }
    if (this.parent.carrierSwitch) {
      if (this.parent.customerSwitch) {
        if (value) {
          if (Number(value) > Number(this.parent.customerAmount)) {
            return this?.createError({
              message: `Carrier amount must be less or equal than Customer amount`,
              path: 'carrierAmount',
            });
          } else if (String(value).replace('.', '')! == '0') {
            return this.createError({
              message: NOT_ZERO,
              path: 'carrierAmount',
            });
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return !!value;
      }
    }
    return true;
  }),
  truck: yup.array().test('length', TRUCK_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.carrier[0]?.type === 'Owner Operator') {
      if (this.parent.carrierSwitch) {
        return value && !!value.length;
      }
      return true;
    } else return true;
  }),
  allAuthorities: yup.array().test('length', AUTHORITY_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.soldUsSwitch) {
      return value && !!value.length;
    }
    if (!this.parent.customerSwitch) {
      return value && !!value.length;
    }
    return true;
  }),
  file: yup.array().notRequired(),
});
