import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Rate as AntRate, Row, Spin } from 'antd';
import clsx from 'clsx';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Typography from 'ui/typography/Typography';
import { EmployeeLevels, UserTypes } from 'utils/constants';

import StarIcon from 'components/svgs/StarIcon';

import styles from './DropdownRate.module.scss';

const DropdownRate = ({ isOpen, rateList, countPositionTop, countPositionLeft, isFetching, onScroll }: any) => {
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  return (
    <div
      style={{ position: 'fixed', top: `${countPositionTop}px`, left: `${countPositionLeft}px` }}
      className={clsx(styles.dropdown, 'rate-dropdown', {
        [styles.opened]: isOpen,
      })}
      onScroll={onScroll}
    >
      <>
        {isFetching ? (
          <div className={styles.spinWrapper}>
            <Spin />
          </div>
        ) : (
          rateList?.map(({ key, name, date, rate, creatorId }: any) => {
            return (
              <div key={key} className={styles.rateItem}>
                <Row gutter={15} align="middle" justify="space-between">
                  <Col span={8}>
                    {UserTypes.ADMIN === userType || EmployeeLevels.CORPORATE === employeeLevel ? (
                      <a
                        href={`http://${window.location.host}/profile?tab=agents&open=true&mode=view&activePage=table&headerFilter=carriers&id=${creatorId}`}
                        target="_blank"
                      >
                        {name}
                      </a>
                    ) : (
                      <h2>{name}</h2>
                    )}
                  </Col>
                  <Col span={7}>
                    <Typography variant="paragraph">{date}</Typography>
                  </Col>

                  {UserTypes.ADMIN === userType || EmployeeLevels.CORPORATE === employeeLevel ? (
                    <Col span={6}>
                      <AntRate className={styles.rate} character={<StarIcon />} disabled defaultValue={rate} />
                    </Col>
                  ) : (
                    <Col>
                      <AntRate className={styles.rate} character={<StarIcon />} disabled defaultValue={rate} />
                    </Col>
                  )}
                </Row>
              </div>
            );
          })
        )}
      </>
    </div>
  );
};

export default memo(DropdownRate);
