import { IAddedMc } from 'pages/profile/tabs/customers/constants/types';
import { emailPattern, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  NAME_REQUIRED,
  PAYMENT_TYPE_REQUIRED,
  DAY_REQUIRED,
  PERCENT_REQUIRED,
  EMAIL_REQUIRED,
  ADDED_MC_REQUIRED,
  TYPE_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  INVALID_PHONE,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  STATE_REQUIRED,
  ZIP_REQUIRED,
  PHONE,
  INCORRECT_FAX,
  TEMPLATE_REQUIRED,
  MAX_LENGTH,
  ADDRESS_MAX_LENGTH,
  INVALID_EMAIL,
  MC_NUMBER_REQUIRED,
  MAX_MIN_ZIP_LENGTH,
  CONTACT_NAME_REQUIRED,
  FF_NUMBER_REQUIRED,
  DOT_REQUIRED,
  STATE_NUMBER_REQUIRED,
  NOT_ZERO,
} = VALIDATION_MESSAGES;
export const validation = yup.object().shape({
  name: yup.string().required(NAME_REQUIRED),
  type: yup.string().required(TYPE_REQUIRED),
  mcNumber: yup.string().test('isRequiredField', MC_NUMBER_REQUIRED, function check(value) {
    return !(!value && !this.parent.ffNumber && !this.parent.dot && !this.parent.stateNumber);
  }),
  ffNumber: yup.string().test('isRequiredField', FF_NUMBER_REQUIRED, function check(value) {
    return !(!value && !this.parent.mcNumber && !this.parent.dot && !this.parent.stateNumber);
  }),
  dot: yup.string().test('isRequiredField', DOT_REQUIRED, function check(value) {
    return !(!value && !this.parent.mcNumber && !this.parent.ffNumber && !this.parent.stateNumber);
  }),
  stateNumber: yup.string().test('isRequiredField', STATE_NUMBER_REQUIRED, function check(value) {
    return !(!value && !this.parent.mcNumber && !this.parent.ffNumber && !this.parent.dot);
  }),
  phoneNumber: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBER_REQUIRED).matches(phoneRegExp, PHONE),
  fax: yup
    .string()
    .nullable()
    .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
      if ((value && value.length === 12) || !value) {
        return true;
      } else if (!value?.match(phoneRegExp)) {
        return this.createError({
          message: INCORRECT_FAX,
          path: 'fax',
        });
      } else {
        return this.createError({
          message: MAX_LENGTH,
          path: 'fax',
        });
      }
    }),
  address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
  city: yup.string().required(CITY_REQUIRED),
  state: yup.string().required(STATE_REQUIRED),
  zipcode: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
  mc: yup.array(yup.object().shape({ id: yup.string(), name: yup.string(), mc: yup.string() })).notRequired(),
  paymentType: yup.string().when('mc', {
    is: (value: Array<any>) => value.length,
    then: schema => schema.required(PAYMENT_TYPE_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  day: yup.string().when('paymentType', {
    is: (value: string) => value,
    then: schema => schema.required(DAY_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  percent: yup
    .number()
    .when('paymentType', {
      is: (value: string) => value,
      then: schema => schema.required(PERCENT_REQUIRED),
      otherwise: schema => schema.notRequired(),
    })
    .nullable()
    .typeError('Please enter a valid number')
    .transform(value => (isNaN(value) ? undefined : value))
    .max(100, 'Percent must be less than 100')
    .test('is-decimal', 'Up to 2 decimal places allowed', function test(value) {
      if (!this.parent.paymentType) return true;
      const decimalPart = value?.toString().split('.')[1];
      return !decimalPart || decimalPart.length <= 2;
    }),
  email: yup
    .array()
    .of(yup.string())
    .when('paymentType', {
      is: (value: string) => value,
      then: schema => schema.min(1, EMAIL_REQUIRED).required(EMAIL_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
  paymentLimit: yup
    .string()
    .notRequired()
    .nullable()
    .test('not-zero', NOT_ZERO, function (value) {
      return String(value)! != '0';
    }),
  addedMc: yup.array().when('mc', {
    is: (value: IAddedMc[]) => value?.length,
    then: schema => schema.min(1, ADDED_MC_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  templateId: yup.string().when('paymentType', {
    is: (value: string) => value === 'QuickPay' || value === 'Custom',
    then: schema => schema.min(1, TEMPLATE_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  billingPhone: yup.string().test('isRequiredField', PHONE_NUMBER_REQUIRED, function check(value: any) {
    if ((this.parent.billingEmail || this.parent.billingContactName) && !value?.length) {
      return false;
    } else if (value && value?.length < 12) {
      return this.createError({
        message: PHONE,
        path: 'billingPhone',
      });
    } else if (value && value?.length > 12) {
      return this.createError({
        message: MAX_LENGTH,
        path: 'billingPhone',
      });
    } else if (value && !value?.match(phoneRegExp)) {
      return this.createError({
        message: INVALID_PHONE,
        path: 'billingPhone',
      });
    } else {
      return true;
    }
  }),
  billingEmail: yup.string().test('isRequiredField', EMAIL_REQUIRED, function check(value) {
    if ((this.parent.billingPhone || this.parent.billingContactName) && !value?.length) {
      return false;
    } else if (value && !value.match(emailPattern)) {
      return this.createError({
        message: INVALID_EMAIL,
        path: 'billingEmail',
      });
    } else {
      return true;
    }
  }),
  billingContactName: yup.string().test('isRequiredField', CONTACT_NAME_REQUIRED, function check(value) {
    if (this.parent.billingEmail || this.parent.billingPhone || value) {
      return !!value;
    }
    return true;
  }),
  receivablesPhone: yup.string().test('isRequiredField', PHONE_NUMBER_REQUIRED, function check(value: any) {
    if ((this.parent.receivablesEmail || this.parent.receivablesContactName) && !value?.length) {
      return false;
    } else if (value && value?.length < 12) {
      return this.createError({
        message: PHONE,
        path: 'receivablesPhone',
      });
    } else if (value && value?.length > 12) {
      return this.createError({
        message: MAX_LENGTH,
        path: 'receivablesPhone',
      });
    } else if (value && !value?.match(phoneRegExp)) {
      return this.createError({
        message: INVALID_PHONE,
        path: 'receivablesPhone',
      });
    } else {
      return true;
    }
  }),
  receivablesEmail: yup.string().test('isRequiredField', EMAIL_REQUIRED, function check(value) {
    if ((this.parent.receivablesPhone || this.parent.receivablesContactName) && !value?.length) {
      return false;
    } else if (value && !value.match(emailPattern)) {
      return this.createError({
        message: INVALID_EMAIL,
        path: 'receivablesEmail',
      });
    } else {
      return true;
    }
  }),
  receivablesContactName: yup.string().test('isRequiredField', CONTACT_NAME_REQUIRED, function check(value) {
    if (this.parent.receivablesEmail || this.parent.receivablesPhone || value) {
      return !!value;
    }
    return true;
  }),
});
