import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Tooltip } from 'antd';
import CreateEditEmployeeForm from 'pages/profile/tabs/employee/components/create-edit-form/CreateEditEmployeeForm';
import { SUBJECT } from 'pages/profile/tabs/employee/constants/constants';
import { IEmployeeFormProps } from 'pages/profile/tabs/employee/constants/types';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { checkElementLengthForTooltip, formatPhone } from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import styles from './EditEmployeeForm.module.scss';

const EditEmployeeForm = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  watch,
  errors,
  setValue,
  statesData,
  employeeById,
  setError,
  isDirty,
  handleViewFileEdit,
  handleDeleteFileEdit,
  handleDownloadFileEdit,
  beforeUploadForEdit,
  allFiles,
  employeeId,
  isFetchingById,
  handleNewFileDownload,
  handleNewFileView,
  setUsername,
  handleClearDate,
  mode,
  isUserNameExists,
  employeeLevel,
  permissionOptions,
  isEditLoading,
  isCreateLoading,
}: IEmployeeFormProps) => {
  const dispatch = useDispatch();

  const [isTooltipOpen, setIsTooltipOpen] = useState('');

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isEditLoading && !isCreateLoading }));
  }, [isEditLoading, isCreateLoading]);

  return (
    <div className={styles.employee}>
      <MainLoader isFetching={isFetchingById} />

      <ViewItemWrapper title={SUBJECT.EMPLOYEE_INFO}>
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.FIRST_NAME}:{' '}
              </Typography>
              <Tooltip
                color="white"
                placement="top"
                title={employeeById?.result?.firstName}
                onOpenChange={open =>
                  checkElementLengthForTooltip(
                    `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`,
                    `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`,
                    open,
                    setIsTooltipOpen
                  )
                }
                open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`}
              >
                <Typography
                  id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`}
                  variant="paragraph"
                  className="tooltipCroped top-banner-value"
                  onClick={() => setIsTooltipOpen('')}
                >
                  <span id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`}>
                    {employeeById?.result?.firstName}
                  </span>
                </Typography>
              </Tooltip>
            </div>
          </Col>
          {employeeById?.result?.ext ? (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.EXT}:{' '}
                </Typography>
                <Tooltip
                  color="white"
                  placement="top"
                  title={employeeById?.result?.ext}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`,
                      `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`}
                >
                  <Typography
                    id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`}
                    variant="paragraph"
                    className="tooltipCroped top-banner-value"
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`}>{employeeById?.result?.ext}</span>
                  </Typography>
                </Tooltip>
              </div>
            </Col>
          ) : (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.PERMISSION_GROUP}:{' '}
                </Typography>
                <Typography variant="paragraph" className="top-banner-value">
                  {employeeById?.result?.permissionGroupName}
                </Typography>
              </div>
            </Col>
          )}
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.EMAIL}:{' '}
              </Typography>
              <Tooltip
                color="white"
                placement="top"
                title={employeeById?.result?.email}
                onOpenChange={open =>
                  checkElementLengthForTooltip(
                    `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`,
                    `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`,
                    open,
                    setIsTooltipOpen
                  )
                }
                open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`}
              >
                <Typography
                  id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`}
                  variant="paragraph"
                  className="tooltipCroped top-banner-value"
                  onClick={() => setIsTooltipOpen('')}
                >
                  <a
                    id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`}
                    href={`mailto:${employeeById?.result?.email}`}
                  >
                    {employeeById?.result?.email}
                  </a>
                </Typography>
              </Tooltip>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.LAST_NAME}:{' '}
              </Typography>
              <Tooltip
                color="white"
                placement="top"
                title={employeeById?.result?.lastName}
                onOpenChange={open =>
                  checkElementLengthForTooltip(
                    `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`,
                    `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`,
                    open,
                    setIsTooltipOpen
                  )
                }
                open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`}
              >
                <Typography
                  id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`}
                  variant="paragraph"
                  className="tooltipCroped top-banner-value"
                  onClick={() => setIsTooltipOpen('')}
                >
                  <span id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`}>
                    {employeeById?.result?.lastName}
                  </span>
                </Typography>
              </Tooltip>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.PHONE_NUMBER}:{' '}
              </Typography>
              <div className={styles.textWithLinks}>
                <a className="top-banner-value" href={`tel:${employeeById?.result?.phone}`}>
                  {formatPhone(employeeById?.result?.phone)}
                </a>
              </div>
            </div>
          </Col>
          {employeeById?.result?.ext ? (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.PERMISSION_GROUP}:{' '}
                </Typography>
                <Typography variant="paragraph" className="top-banner-value">
                  {employeeById?.result?.permissionGroupName}
                </Typography>
              </div>
            </Col>
          ) : null}
        </Row>
      </ViewItemWrapper>
      <CreateEditEmployeeForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        control={control}
        trigger={trigger}
        watch={watch}
        errors={errors}
        setValue={setValue}
        statesData={statesData}
        employeeById={employeeById}
        setError={setError}
        isDirty={isDirty}
        handleViewFileEdit={handleViewFileEdit}
        handleDeleteFileEdit={handleDeleteFileEdit}
        handleDownloadFileEdit={handleDownloadFileEdit}
        beforeUploadForEdit={beforeUploadForEdit}
        allFiles={allFiles}
        employeeId={employeeId}
        isFetchingById={isFetchingById}
        handleDownloadFileCreate={handleNewFileDownload}
        handleViewFileCreate={handleNewFileView}
        setUsername={setUsername}
        handleClearDate={handleClearDate}
        mode={mode}
        isUserNameExists={isUserNameExists}
        employeeLevel={employeeLevel}
        permissionOptions={permissionOptions}
        isEditLoading={isEditLoading}
        isCreateLoading={isCreateLoading}
        hasEditPermission
      />
    </div>
  );
};

export default EditEmployeeForm;
