import { useEffect, useMemo } from 'react';
import { Col, Divider, Row, Tooltip } from 'antd';
import {
  AUTHORITIES_CUSTOM_COLUMNS,
  AUTHORITIES_CUSTOM_KEYS,
  CARRIER_CUSTOM_KEYS,
  CARRIER_KEYS,
  SEARCH_CARRIER_COLUMNS,
  SEARCH_CARRIER_CUSTOM_COLUMNS,
  SEARCH_TRUCK_COLUMNS,
  TRUCK_KEYS,
} from 'pages/loads/components/create-load/constants/constants';
import { SUBJECT } from 'pages/loads/components/edit-load/constants/constants';
import { Input, SearchableDropdown, Switch } from 'pages/loads/components/edit-load/hoc-instance/instance';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { floatNumberFormatter, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import { SmallSearchIcon } from 'components/svgs/SmallSearchIcon';

import { LoadTypes } from '../../../constants/types';

import styles from '../ConnectingEdit.module.scss';

const GeneralInformation = ({
  data,
  connectingControl,
  connectingWatch,
  connectingSetValue,
  handleSwitchSoldUs,
  connectingDirtyFields,
  handleCancel,
  handleSave,
  carriers,
  setCarriersState,
  connectingErrors,
  isSaveButtonVisible,
  authorities,
  setAuthoritiesState,
  trucks,
  setTrucksState,
  canEditSoldAs,
  isDisableSoldUs,
  isDisableReference,
  generalInformationRef,
}: any) => {
  useEffect(() => {
    if (!data?.load?.carrier?.id && connectingWatch('carrier')[0]?.type === 'Outside') {
      connectingSetValue('soldUsSwitch', true, {
        shouldTouch: false,
        shouldDirty: false,
        shouldValidate: false,
      });
    } else if (connectingWatch('carrier')[0]?.type === 'Outside' && !data?.load?.authorities?.length) {
      connectingSetValue('soldUsSwitch', false, {
        shouldTouch: false,
        shouldDirty: false,
        shouldValidate: false,
      });
    }
  }, [connectingWatch('carrier'), data?.load?.carrier?.id]);

  const saveButtonCheck = () => {
    if (connectingWatch('soldUsSwitch')) {
      return !!connectingWatch('authorities').length;
    }
    if (
      Object?.keys(connectingDirtyFields)?.includes('soldUsSwitch') &&
      Object?.keys(connectingDirtyFields)?.length === 1
    ) {
      return true;
    }
    if (Object?.keys(connectingDirtyFields)?.includes('referenceNumber')) {
      if (connectingWatch('carrier').length && !!connectingWatch('carrierAmount') && connectingWatch('truck').length) {
        return true;
      } else if (
        connectingWatch('carrier').length ||
        !!connectingWatch('carrierAmount') ||
        connectingWatch('truck').length
      ) {
        return false;
      }
      return true;
    }
    if (
      (Object?.keys(connectingDirtyFields)?.includes('referenceNumber') ||
        (Object?.keys(connectingDirtyFields)?.includes('carrier') &&
          Object?.keys(connectingDirtyFields)?.includes('carrierAmount') &&
          Object?.keys(connectingDirtyFields)?.includes('truck'))) &&
      !connectingWatch('soldUsSwitch') &&
      connectingWatch('carrier').length &&
      !!connectingWatch('carrierAmount') &&
      connectingWatch('truck').length
    ) {
      return true;
    } else {
      return !!connectingWatch('authorities').length;
    }
  };

  const triggerVariant = useMemo(() => {
    if (data?.load?.customerAccess && data?.load?.carrierAccess) {
      return 'customerCarrierAccess';
    } else if (data?.load?.customerAccess) {
      return 'customerAccess';
    } else if (data?.load?.carrierAccess) {
      return 'carrierAccess';
    } else {
      return '';
    }
  }, [data?.load?.customerAccess, data?.load?.carrierAccess]);

  if (data?.load?.customerAccess && data?.load?.carrierAccess) {
    return (
      <>
        <Row gutter={[18, 40]} ref={generalInformationRef}>
          <Col span={8}>
            <Input
              name="load_type"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.LOAD_TYPE}
              getValues={connectingWatch}
              errors={connectingErrors}
              value={LoadTypes[data?.load?.loadType]}
              defaultValue={LoadTypes[data?.load?.loadType]}
              required
              disabled
            />
          </Col>
          <Col span={8} />
          <Col span={8} />

          <Col span={8}>
            <Input
              name="customerName"
              type="text"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_NAME}
              value={data?.load?.customer?.name}
              defaultValue={data?.load?.customer?.name}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              suffix={<DollarIcon />}
              name="customerAmount"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_AMOUNT}
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount)}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="referenceNumber"
              type="text"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.REFERENCE_NUMBER}
              getValues={connectingWatch}
              onChange={e =>
                connectingSetValue('referenceNumber', e.target.value.trimStart(), {
                  shouldTouch: true,
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              errors={connectingErrors}
              required
              disabled={isDisableReference()}
            />
          </Col>

          <Col span={8}>
            <Input
              name="customerContactName"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CONTACT_NAME}
              defaultValue={data?.load?.customerContactName}
              value={data?.load?.customerContactName}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="customerContact"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_CONTACT}
              value={data?.load?.customerContact}
              defaultValue={data?.load?.customerContact}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={8} />

          <Col span={8}>
            {data?.load?.carrier?.id ? (
              <Input
                suffix={<SmallSearchIcon />}
                name="carrier"
                control={connectingControl}
                rules={{ required: true }}
                placeholder={SUBJECT.CARRIER}
                defaultValue={data?.load?.carrier?.name}
                value={data?.load?.carrier?.name}
                errors={connectingErrors}
                required
                disabled
              />
            ) : (
              <SearchableDropdown
                options={carriers?.result?.map((carrier: any) => ({
                  id: carrier?.id,
                  name: carrier?.companyName,
                  mc: carrier?.mc,
                  value: carrier?.id,
                  type: carrier?.type,
                }))}
                placeholder={SUBJECT.CARRIER}
                offset={carriers?.count}
                setOffset={setCarriersState}
                values={connectingWatch('carrier')}
                columns={SEARCH_CARRIER_CUSTOM_COLUMNS}
                keys={CARRIER_CUSTOM_KEYS}
                control={connectingControl}
                shouldNotValidate={true}
                name="carrier"
                showKey="title"
                setValue={connectingSetValue}
                errors={connectingErrors}
                withoutBorder={true}
                required={
                  Object?.keys(connectingDirtyFields)?.includes('referenceNumber')
                    ? connectingWatch('carrier')?.length ||
                      connectingWatch('carrierAmount') ||
                      connectingWatch('truck')?.length
                    : true
                }
                rules={{
                  required: Object?.keys(connectingDirtyFields)?.includes('referenceNumber')
                    ? connectingWatch('carrier')?.length ||
                      connectingWatch('carrierAmount') ||
                      connectingWatch('truck')?.length
                    : true,
                }}
                width={620}
              />
            )}
          </Col>
          {(connectingWatch('carrier')[0]?.type === 'Owner Operator' || data?.load?.truck?.truckNumber) && (
            <Col span={8}>
              {data?.load?.truck?.truckNumber ? (
                <Input
                  name="truck"
                  control={connectingControl}
                  placeholder={SUBJECT.TRUCK}
                  rules={{ required: true }}
                  errors={connectingErrors}
                  getValues={connectingWatch}
                  defaultValue={data?.load?.truck?.truckNumber}
                  value={data?.load?.truck?.truckNumber}
                  required
                  disabled
                />
              ) : (
                connectingWatch('carrier')[0]?.type === 'Owner Operator' && (
                  <SearchableDropdown
                    options={trucks?.result?.map((truck: any) => ({
                      title: truck?.truckNumber,
                      value: String(truck?.id),
                      id: String(truck?.id),
                    }))}
                    placeholder={SUBJECT.TRUCK}
                    offset={trucks?.count}
                    setOffset={setTrucksState}
                    errors={connectingErrors}
                    values={connectingWatch('truck')}
                    columns={SEARCH_TRUCK_COLUMNS}
                    keys={TRUCK_KEYS}
                    control={connectingControl}
                    shouldNotValidate={true}
                    name="truck"
                    showKey="title"
                    setValue={connectingSetValue}
                    disabled={!connectingWatch('carrier').length}
                    withoutBorder={true}
                    required={
                      Object?.keys(connectingDirtyFields)?.includes('referenceNumber')
                        ? connectingWatch('carrier')?.length ||
                          connectingWatch('carrierAmount') ||
                          (connectingWatch('carrier')[0]?.type === 'Owner Operator' && connectingWatch('truck')?.length)
                        : true
                    }
                    rules={{
                      required: Object?.keys(connectingDirtyFields)?.includes('referenceNumber')
                        ? connectingWatch('carrier')?.length ||
                          connectingWatch('carrierAmount') ||
                          (connectingWatch('carrier')[0]?.type === 'Owner Operator' && connectingWatch('truck')?.length)
                        : true,
                    }}
                  />
                )
              )}
            </Col>
          )}
          <Col span={8}>
            {data?.load?.carrier?.id ? (
              <Input
                name="carrierAmount"
                control={connectingControl}
                placeholder={SUBJECT.CARRIER_AMOUNT}
                rules={{ required: true }}
                errors={connectingErrors}
                value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.carrierAmount)}
                suffix={<DollarIcon />}
                required
                disabled={data?.load?.carrier?.name}
              />
            ) : (
              <Input
                name="carrierAmount"
                control={connectingControl}
                placeholder={SUBJECT.CARRIER_AMOUNT}
                errors={connectingErrors}
                value={
                  connectingWatch('carrierAmount')
                    ? formatNumberWithThousandsSeparatorAndDecimal(connectingWatch('carrierAmount'))
                    : ''
                }
                suffix={<DollarIcon />}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  connectingSetValue('carrierAmount', floatNumberFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                required={
                  Object?.keys(connectingDirtyFields)?.includes('referenceNumber')
                    ? connectingWatch('carrier')?.length ||
                      connectingWatch('carrierAmount') ||
                      connectingWatch('truck')?.length
                    : true
                }
                rules={{
                  required: Object?.keys(connectingDirtyFields)?.includes('referenceNumber')
                    ? connectingWatch('carrier')?.length ||
                      connectingWatch('carrierAmount') ||
                      connectingWatch('truck')?.length
                    : true,
                }}
              />
            )}
          </Col>
          <Col span={24}>
            <Row gutter={[18, 40]}>
              <Col>
                <Typography variant="heading" children={SUBJECT.SOLD_US_SWITCH} className={styles.switchHeading} />
              </Col>
              <Col>
                <Tooltip
                  placement="top"
                  title={!connectingWatch('carrier') || isDisableSoldUs() ? "You can't change sold us" : ''}
                  color={'white'}
                >
                  <span>
                    <Switch
                      name="soldUsSwitch"
                      checked={connectingWatch('soldUsSwitch')}
                      control={connectingControl}
                      onChange={handleSwitchSoldUs}
                      disabled={
                        !connectingWatch('carrier') ||
                        isDisableSoldUs() ||
                        connectingWatch('carrier')[0]?.type === 'Outside'
                      }
                    />
                  </span>
                </Tooltip>
              </Col>
            </Row>
          </Col>

          {connectingWatch('soldUsSwitch') && (
            <Col span={8}>
              <SearchableDropdown
                options={authorities?.result?.map((item: any) => ({
                  title: item?.name,
                  value: String(item?.id),
                  id: String(item?.id),
                }))}
                placeholder={SUBJECT.ALL_AUTHORITIES}
                offset={authorities?.count}
                setOffset={setAuthoritiesState}
                values={connectingWatch('authorities')}
                columns={AUTHORITIES_CUSTOM_COLUMNS}
                keys={AUTHORITIES_CUSTOM_KEYS}
                control={connectingControl}
                errors={connectingErrors}
                name="authorities"
                showKey="title"
                setValue={connectingSetValue}
                withoutBorder={true}
                required
                rules={{ required: true }}
                disabled={!canEditSoldAs || isDisableSoldUs()}
                width={500}
              />
            </Col>
          )}
          {isSaveButtonVisible && (
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Button children="Cancel" variant="text" onClick={handleCancel} />
                </Col>
                <Col>
                  <Button children="Save" onClick={() => handleSave(triggerVariant)} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <Divider />

        <Row gutter={[18, 40]}>
          <Col span={12}>
            <Typography variant="paragraph" className={styles.textItem}>
              {SUBJECT.OFFICE_NAME} <span>{data?.agents[0]?.office?.name}</span>
            </Typography>
          </Col>
          <Col span={12}>
            <Typography variant="paragraph" className={styles.textItem}>
              {SUBJECT.BRACH_NAME} <span>{data?.agents[0]?.branch?.name}</span>
            </Typography>
          </Col>
          <Col span={12}>
            <Input
              name="firstAgentName"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.FIRST_AGENT_NAME}
              getValues={connectingWatch}
              value={`${data?.agents[0]?.user?.username}`}
              defaultValue={`${data?.agents[0]?.user?.username}`}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={12}>
            <Input
              suffix={<DollarIcon />}
              name="agentAmount"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.FIRST_AGENT_AMOUNT}
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount)}
              getValues={connectingWatch}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={[18, 40]}>
          <Col span={12}>
            <Typography variant="paragraph" className={styles.textItem}>
              {SUBJECT.OFFICE_NAME} <span>{data?.agents[1]?.office?.name}</span>
            </Typography>
          </Col>
          <Col span={12}>
            <Typography variant="paragraph" className={styles.textItem}>
              {SUBJECT.BRACH_NAME} <span>{data?.agents[1]?.branch?.name}</span>
            </Typography>
          </Col>
          <Col span={12}>
            <Input
              name="firstAgentName"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.SECOND_AGENT_NAME}
              getValues={connectingWatch}
              value={`${data?.agents[1]?.user?.username}`}
              defaultValue={`${data?.agents[1]?.user?.username}`}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={12}>
            <Input
              suffix={<DollarIcon />}
              name="agentAmount"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.SECOND_AGENT_AMOUNT}
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.agents[1]?.amount)}
              getValues={connectingWatch}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
        </Row>
      </>
    );
  }
  if (data?.load?.carrierAccess) {
    return (
      <Row gutter={[18, 40]}>
        <Col span={8}>
          <Input
            name="load_type"
            control={connectingControl}
            rules={{ required: true }}
            placeholder={SUBJECT.LOAD_TYPE}
            getValues={connectingWatch}
            errors={connectingErrors}
            value={LoadTypes[data?.load?.loadType]}
            defaultValue={LoadTypes[data?.load?.loadType]}
            required
            disabled
          />
        </Col>
        <Col span={8} />
        <Col span={8} />

        <Col span={8}>
          <Input
            name="firstAgentName"
            control={connectingControl}
            rules={{ required: true }}
            placeholder={SUBJECT.FIRST_AGENT_NAME}
            getValues={connectingWatch}
            value={`${data?.agents[0]?.user?.username}`}
            defaultValue={`${data?.agents[0]?.user?.username}`}
            errors={connectingErrors}
            required
            disabled
          />
        </Col>
        <Col span={8}>
          <Input
            suffix={<DollarIcon />}
            name="agentAmount"
            control={connectingControl}
            rules={{ required: true }}
            placeholder={SUBJECT.SECOND_AGENT_AMOUNT}
            value={formatNumberWithThousandsSeparatorAndDecimal(data?.agents[1]?.amount)}
            defaultValue={data?.agents[1]?.amount}
            getValues={connectingWatch}
            errors={connectingErrors}
            required
            disabled
          />
        </Col>
        <Col span={8} />
        <Col span={8}>
          {data?.load?.carrier ? (
            <Input
              suffix={<SmallSearchIcon />}
              name="carrier"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CARRIER}
              defaultValue={data?.load?.carrier?.name}
              value={data?.load?.carrier?.name}
              errors={connectingErrors}
              required
              disabled
            />
          ) : (
            <SearchableDropdown
              options={carriers?.result?.map((carrier: any) => ({
                id: carrier?.id,
                name: carrier?.companyName,
                mc: carrier?.mc,
                value: carrier?.id,
                type: carrier?.type,
              }))}
              placeholder={SUBJECT.CARRIER}
              offset={carriers?.count}
              setOffset={setCarriersState}
              values={connectingWatch('carrier')}
              columns={SEARCH_CARRIER_COLUMNS}
              shouldNotValidate={true}
              keys={CARRIER_KEYS}
              control={connectingControl}
              name="carrier"
              showKey="title"
              setValue={connectingSetValue}
              errors={connectingErrors}
              withoutBorder={true}
              required
              rules={{ required: true }}
              width={620}
            />
          )}
        </Col>
        {(connectingWatch('carrier')[0]?.type === 'Owner Operator' || data?.load?.truck?.truckNumber) && (
          <Col span={8}>
            {data?.load?.truck?.truckNumber ? (
              <Input
                name="truck"
                control={connectingControl}
                placeholder={SUBJECT.TRUCK}
                rules={{ required: true }}
                errors={connectingErrors}
                getValues={connectingWatch}
                defaultValue={data?.load?.truck?.truckNumber}
                value={data?.load?.truck?.truckNumber}
                required
                disabled
              />
            ) : (
              connectingWatch('carrier')[0]?.type === 'Owner Operator' && (
                <SearchableDropdown
                  options={trucks?.result?.map((truck: any) => ({
                    title: truck?.truckNumber,
                    value: String(truck?.id),
                    id: String(truck?.id),
                  }))}
                  placeholder={SUBJECT.TRUCK}
                  offset={trucks?.count}
                  setOffset={setTrucksState}
                  errors={connectingErrors}
                  values={connectingWatch('truck')}
                  columns={SEARCH_TRUCK_COLUMNS}
                  keys={TRUCK_KEYS}
                  shouldNotValidate={true}
                  control={connectingControl}
                  name="truck"
                  showKey="title"
                  setValue={connectingSetValue}
                  disabled={!connectingWatch('carrier').length}
                  withoutBorder={true}
                  required
                  rules={{ required: true }}
                />
              )
            )}
          </Col>
        )}
        <Col span={8}>
          {data?.load?.carrier?.id ? (
            <Input
              name="carrierAmount"
              control={connectingControl}
              placeholder={SUBJECT.CARRIER_AMOUNT}
              rules={{ required: true }}
              errors={connectingErrors}
              getValues={connectingWatch}
              defaultValue={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.carrierAmount)}
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.carrierAmount)}
              suffix={<DollarIcon />}
              required
              disabled={data?.load?.carrier?.name}
            />
          ) : (
            <Input
              name="carrierAmount"
              control={connectingControl}
              placeholder={SUBJECT.CARRIER_AMOUNT}
              rules={{ required: true }}
              errors={connectingErrors}
              getValues={connectingWatch}
              value={formatNumberWithThousandsSeparatorAndDecimal(connectingWatch('carrierAmount'))}
              suffix={<DollarIcon />}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                connectingSetValue('carrierAmount', floatNumberFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
            />
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[18, 40]}>
            <Col>
              <Typography variant="heading" children={SUBJECT.SOLD_US_SWITCH} className={styles.switchHeading} />
            </Col>
            <Col>
              <Tooltip
                placement="top"
                title={!connectingWatch('carrier') || isDisableSoldUs() ? "You can't change sold us" : ''}
                color={'white'}
              >
                <span>
                  <Switch
                    name="soldUsSwitch"
                    checked={connectingWatch('soldUsSwitch')}
                    control={connectingControl}
                    onChange={handleSwitchSoldUs}
                    disabled={
                      !connectingWatch('carrier') ||
                      isDisableSoldUs() ||
                      connectingWatch('carrier')[0]?.type === 'Outside'
                    }
                  />
                </span>
              </Tooltip>
            </Col>
          </Row>
        </Col>

        {connectingWatch('soldUsSwitch') && (
          <Col span={8}>
            <SearchableDropdown
              options={authorities?.result?.map((item: any) => ({
                title: item?.name,
                value: String(item?.id),
                id: String(item?.id),
              }))}
              placeholder={SUBJECT.ALL_AUTHORITIES}
              offset={authorities?.count}
              setOffset={setAuthoritiesState}
              values={connectingWatch('authorities')}
              columns={SEARCH_TRUCK_COLUMNS}
              keys={TRUCK_KEYS}
              control={connectingControl}
              errors={connectingErrors}
              name="authorities"
              showKey="title"
              setValue={connectingSetValue}
              withoutBorder={true}
              required
              rules={{ required: true }}
              disabled={isDisableSoldUs()}
            />
          </Col>
        )}
        {isSaveButtonVisible && (
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Button children="Cancel" variant="text" onClick={handleCancel} />
              </Col>
              <Col>
                <Button children="Save" onClick={() => handleSave(triggerVariant)} />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    );
  }
  if (data?.load?.customerAccess) {
    return (
      <>
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="load_type"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.LOAD_TYPE}
              getValues={connectingWatch}
              errors={connectingErrors}
              value={LoadTypes[data?.load?.loadType]}
              defaultValue={LoadTypes[data?.load?.loadType]}
              required
              disabled
            />
          </Col>
          <Col span={8} />
          <Col span={8} />

          <Col span={8}>
            <Input
              name="customerName"
              value={data?.load?.customer?.name}
              defaultValue={data?.load?.customer?.name}
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_NAME}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              suffix={<DollarIcon />}
              name="customerAmount"
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount)}
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_AMOUNT}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="referenceNumber"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.REFERENCE_NUMBER}
              getValues={connectingWatch}
              onChange={(e: any) =>
                connectingSetValue('referenceNumber', e.target.value, {
                  shouldTouch: true,
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              errors={connectingErrors}
              required
            />
          </Col>

          <Col span={8}>
            <Input
              name="customerContactName"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CONTACT_NAME}
              defaultValue={data?.load?.customerContactName}
              value={data?.load?.customerContactName}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="customerContact"
              value={data?.load?.customerContact}
              defaultValue={data?.load?.customerContact}
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_CONTACT}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>

          {connectingDirtyFields?.referenceNumber && (
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Button children="Cancel" variant="text" onClick={handleCancel} />
                </Col>
                <Col>
                  <Button children="Save" onClick={() => handleSave(triggerVariant)} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <Divider />

        <Row gutter={[18, 40]}>
          <Col span={12}>
            <Typography variant="paragraph" className={styles.textItem}>
              {SUBJECT.OFFICE_NAME} <span>{data?.agents[0]?.office?.name}</span>
            </Typography>
          </Col>
          <Col span={12}>
            <Typography variant="paragraph" className={styles.textItem}>
              {SUBJECT.BRACH_NAME} <span>{data?.agents[0]?.branch?.name}</span>
            </Typography>
          </Col>
          <Col span={12}>
            <Input
              name="firstAgentName"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.FIRST_AGENT_NAME}
              getValues={connectingWatch}
              value={`${data?.agents[0]?.user?.username}`}
              defaultValue={`${data?.agents[0]?.user?.username}`}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
          <Col span={12}>
            <Input
              suffix={<DollarIcon />}
              name="agentAmount"
              control={connectingControl}
              placeholder={SUBJECT.FIRST_AGENT_AMOUNT}
              rules={{ required: true }}
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount)}
              defaultValue={data?.load?.customerAmount}
              getValues={connectingWatch}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={[18, 40]}>
          <Col span={12}>
            <Typography variant="paragraph" className={styles.textItem}>
              {SUBJECT.OFFICE_NAME} <span>{data?.agents[1]?.office?.name}</span>
            </Typography>
          </Col>
          <Col span={12}>
            <Typography variant="paragraph" className={styles.textItem}>
              {SUBJECT.BRACH_NAME} <span>{data?.agents[1]?.branch?.name}</span>
            </Typography>
          </Col>
          <Col span={12}>
            <Input
              name="firstAgentName"
              control={connectingControl}
              rules={{ required: true }}
              placeholder={SUBJECT.SECOND_AGENT_NAME}
              getValues={connectingWatch}
              value={`${data?.agents[1]?.user?.username}`}
              defaultValue={`${data?.agents[1]?.user?.username}`}
              errors={connectingErrors}
              required
              disabled
            />
          </Col>
        </Row>
      </>
    );
  }
  return null;
};

export default GeneralInformation;
