import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { IGetOfficesParams } from 'services/profile/offices/interfaces';
import {
  useAddAuthorityToOfficeMutation,
  useRemoveAuthorityFromOfficeMutation,
} from 'services/profile/offices/offices';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { IAuthorityElement } from 'utils/types';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from '../../constants/constants';

import { IOfficeFormProps } from './constants/types';

const useCreateEditOffice = ({
  setValue,
  authoritiesData,
  refetch,
  authorityByOfficeId,
  isAutocompleteFetching,
  setGetAuthoritiesFilter,
  setGetAuthoritiesByOfficeFilter,
}: Partial<IOfficeFormProps>) => {
  const dispatch = useDispatch();

  const {
    searchParams: { mode, open, id },
  } = useDetectedParams();

  const [removeAuthorityFromOffice] = useRemoveAuthorityFromOfficeMutation();
  const [addAuthorityToOffice, { isLoading }] = useAddAuthorityToOfficeMutation();

  const [authorities, setAuthorities] = useState<IAuthorityElement[]>([]);
  const [addedAuthorities, setAddedAuthorities] = useState<IAuthorityElement[]>([]);

  const addedMcIds = useMemo(() => addedAuthorities?.map(el => el.id), [addedAuthorities?.length]);

  const handleAddAuthority = (authorityElement: IAuthorityElement) => {
    if (mode === 'edit' && !isLoading) {
      addAuthorityToOffice({ id, authorityId: authorityElement.id }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully added',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          refetch();
        }
      });
    } else {
      if (!addedAuthorities.includes(authorityElement?.id as any) && !isAutocompleteFetching) {
        const renderItems = authorities.filter(authority => authority.id === authorityElement.id);

        setAddedAuthorities(prev => [...prev.filter(item => item.id !== renderItems[0]?.id), ...renderItems]);
        setAuthorities(authorities);
        notification.success({
          message: 'Successfully added',
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    }
  };

  const handleDeleteAuthority = (id: number | string) => {
    setAddedAuthorities(prev => prev.filter(item => item.id !== id));

    const optionsSetter = authoritiesData?.result?.filter((item: any) => item.id === id);
    setAuthorities(prev => [...prev, ...optionsSetter]);
    dispatch(closeModal());
  };

  const handleRowDelete = (rowId: string | number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          if (mode === 'edit') {
            removeAuthorityFromOffice({ id, authorityId: rowId }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully deleted',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                refetch();
                handleDeleteAuthority(rowId);
              }
            });
          } else {
            handleDeleteAuthority(rowId);
          }
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    if (mode === 'edit') {
      setGetAuthoritiesByOfficeFilter((prev: IGetOfficesParams) => ({
        ...prev,
        limit,
        skip,
      }));
    }
    return;
  };

  useEffect(() => {
    if (setValue) {
      setValue('authorities', addedMcIds);
    }
  }, [addedAuthorities?.length, authorities?.length]);

  useEffect(() => {
    if (mode === 'add') {
      setAuthorities(authoritiesData?.result);
    }
  }, [mode, open, authoritiesData]);

  useEffect(() => {
    if (mode === 'edit') {
      setAuthorities(authoritiesData?.result);
      setAddedAuthorities(authorityByOfficeId?.result);
    }
  }, [mode, open, authorityByOfficeId, authoritiesData]);

  useEffect(() => {
    setGetAuthoritiesFilter((prev: any) => {
      return {
        ...prev,
        ignoreAuthorityIds: addedMcIds,
      };
    });
  }, [addedMcIds?.length]);

  return { authorities, addedAuthorities, mode, handleAddAuthority, handleRowDelete, handlePaginationClick };
};

export default useCreateEditOffice;
