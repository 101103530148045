export enum SUBJECT {
  CARRIER_REQUEST_INFO = 'Carrier Request Info',
  TIMER = 'Timer',
  DATE_AND_TIME = 'Date And Time',
  COMPANY_NAME = 'Company Name',
  CONTACT_NAME = 'Contact Name',
  ADDED_BY = 'Added By',
  AGENT_TYPE = 'Agent Type',
  CARRIER_TYPE = 'Carrier Type',
  MC = 'MC Number',
  PHONE_NUMBER = 'Phone Number',
  ADDRESS = 'Address',
  ACTION = 'Action',
  EDIT = 'Edit',
  DOT = 'DOT',
  APPROVE = 'Approve',
  REVIEW = 'Review',
  DENY = 'Deny',
}
