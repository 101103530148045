import React from 'react';
import { Tabs } from 'antd';

import MainDrawer from 'components/main-drawer/MainDrawer';

import { ICustomersParams } from './constants/types';
import useCustomers from './useCustomers';

import styles from './Customers.module.scss';

const Customers = ({ setExcelParams }: ICustomersParams) => {
  const {
    activePage,
    handleTabChange,
    customerTabs,
    currentChildren: { component, buttonText, onCancel, onSubmit, withFooter },
  } = useCustomers({ setExcelParams });

  return (
    <div className="page-content">
      <Tabs
        defaultActiveKey={activePage}
        onChange={activeTab => handleTabChange(activeTab)}
        items={customerTabs}
        className={styles.customerHeaderTabs}
      />
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
      />
    </div>
  );
};

export default Customers;
