export const createRequestCarrier = (body: any) => {
  return {
    companyName: body.companyName,
    contactName: body.contactName,
    mc: body.mc,
    phone: body.phoneNumber,
    dot: body.dot,
  };
};

export const editRequestCarrierSelfDeserializer = (body: any) => {
  return {
    companyName: body.companyName,
    contactName: body.contactName,
    mc: body.mc,
    phone: body.phoneNumber,
    dot: body.dot,
  };
};
