import { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import FilterButtons from 'ui/filter-buttons/FilterButtons';
import Typography from 'ui/typography/Typography';

import MainDrawer from 'components/main-drawer/MainDrawer';
import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import Table from 'components/table/Table';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';

import { AUTHORITIES_TYPE_OPTIONS, AuthorityStatusTypes, SUBJECT } from './constants/constants';
import { IAuthoritiesParams } from './constants/types';
import { FiltersSelect, SearchableSelect } from './hoc-instance/instance';
import useAuthorities from './useAuthorities';

import styles from './Authorities.module.scss';

const Authorities = ({ setExcelParams }: IAuthoritiesParams) => {
  const {
    filterControl,
    data,
    columns,
    isFetching,
    type,
    handleRowClick,
    handleRowEdit,
    handleSortClick,
    handleColumnSearch,
    handleSwitchClick,
    handlePaginationClick,
    handleStopResize,
    setOfficesFilter,
    handleDownloadClick,
    handleResetFilters,
    skip,
    filterOfficeData,
    limit,
    handleUsedFilter,
    currentChildren: { component, buttonText, onCancel, onSubmit, withFooter },
    handleOfficeFilterSelect,
    setFilterValue,
    authorityUsed,
    resetOfficeParams,
    officeFilterValue,
    visibleFilter,
    authoritiesPagePermissions: { edit, terminateOn, terminateOff },
    roleLevel,
  } = useAuthorities({ setExcelParams });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading: isFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onSwitchClick: handleSwitchClick,
    onColumnSearch: handleColumnSearch,
    handleRowEdit,
    onPaginationClick: handlePaginationClick,
    onDownloadClick: handleDownloadClick,
    hasTerminateOnPermission: terminateOn,
    hasTerminateOffPermission: terminateOff,
    hasEditPermission: edit,
    withMenu: edit,
  });

  return (
    <>
      <div className="page-content">
        <div className="filtersWrapper">
          {!isFetching && (
            <Row align="bottom" justify="space-between" className={styles.filters}>
              <Col span={13}>
                <Row gutter={13}>
                  {roleLevel === IRoleLevel.corpLevel && (
                    <Col span={8}>
                      <SearchableSelect
                        options={filterOfficeData?.result}
                        control={filterControl}
                        label={SUBJECT.OFFICES}
                        placeholder={SUBJECT.SEARCH_SELECT}
                        name="officeId"
                        offset={filterOfficeData?.count}
                        setOffset={setOfficesFilter}
                        showKey="title"
                        value={officeFilterValue}
                        handleClick={handleOfficeFilterSelect}
                        setValue={setFilterValue}
                        isFilter
                        resetFunc={resetOfficeParams}
                        allowClear
                      />
                    </Col>
                  )}
                  <Col span={8}>
                    <FiltersSelect
                      suffixIcon={<SelectArrowIcon />}
                      withAll
                      options={AUTHORITIES_TYPE_OPTIONS}
                      control={filterControl}
                      label={SUBJECT.AUTHORITIES_TYPE}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name="type"
                      isFilter
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={11}>
                <Row justify="end" align="middle" gutter={13}>
                  <Col>
                    <FilterButtons
                      text={SUBJECT.ALL}
                      value={String(data?.used + data?.notUsed)}
                      color="#388DFF"
                      isActive={authorityUsed === '0'}
                      onClick={() => handleUsedFilter('0')}
                    />
                  </Col>
                  <Col>
                    <FilterButtons
                      text={SUBJECT.USED}
                      value={String(data?.used)}
                      color="#34A853"
                      isActive={authorityUsed === '1'}
                      onClick={() => handleUsedFilter(AuthorityStatusTypes.AUTHORITY_STATUS_TYPES_ACTIVE)}
                    />
                  </Col>
                  {!visibleFilter && (
                    <Col>
                      <FilterButtons
                        text={SUBJECT.NOT_USED}
                        value={String(data?.notUsed)}
                        color="#FF5454"
                        isActive={authorityUsed === '2'}
                        onClick={() => handleUsedFilter(AuthorityStatusTypes.AUTHORITY_STATUS_TYPES_INACTIVE)}
                      />
                    </Col>
                  )}
                  {!!(authorityUsed || officeFilterValue || type) && (
                    <Col>
                      <div className={styles.reset}>
                        <Button
                          icon={<ResetIcon />}
                          variant="ghost"
                          onClick={handleResetFilters}
                          children={SUBJECT.RESET_ALL}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}
        </div>
        {!isFetching && !data && (
          <div className="page-content">
            <Typography
              children={SUBJECT.AUTHORITIES_LIST_IS_EMPTY}
              variant="paragraph"
              className={styles.authoritiesEmpty}
            />
          </div>
        )}
        <Table {...tableProps} />
      </div>
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
      />
    </>
  );
};

export default Authorities;
