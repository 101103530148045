import dayjs from 'dayjs';
import { TransportTypes } from 'pages/loads/components/create-load/constants/types';
import { ActiveTypes } from 'services/carriersOnBoard/interface';
import { formatDate } from 'utils/dates';
import { formatPhone } from 'utils/helpers';

import { AgentType } from '../agents/interfaces';

import { GetLastLoadsByCarrierIdResult, ICarrierByIdData, ICarrierStatusForCarrier, ICarrierType } from './interfaces';

export const equipmentTypeSerializer = (body: any): any => {
  if (!body) {
    return [];
  }

  return body
    .map((item: any) => (item.status === ActiveTypes.ACTIVE ? item.type : null))
    .filter((item: any) => item !== null);
};

export const paymentTermSerializer = (body: any): any => {
  const paymentTermResult = body?.brokerage?.result?.map((term: any) => {
    return {
      type: term?.type,
      authorityId: term?.authority?.id ? [term?.authority] : [],
      id: term?.id,
      factoringType: term?.billingType,
      factoringCompanyById: { result: term?.factoringCompany },
      factoredCompanyName: term?.factoringCompany?.id
        ? [
            {
              id: term?.factoringCompany?.id,
              companyName: term?.factoringCompany?.companyName,
              type: 1,
            },
          ]
        : [],
      paymentType: term?.dealType,
      paymentBankName: term?.paymentBankName || '',
      paymentRoutingNumber: term?.paymentRoutingNumber || '',
      paymentAccountNumber: term?.paymentAccountNumber || '',
      paymentCompanyName: term?.paymentCompanyName || '',
      paymentAddress: term?.paymentAddress || '',
      paymentSecondAddress: term?.paymentSecondAddress || '',
      paymentCity: term?.paymentCity || '',
      paymentStateId: term?.paymentStateId,
      paymentZip: term?.paymentZip ? String(term?.paymentZip) : '',
      paymentFuelCard: term?.paymentFuelCard || '',
      brokerPaymentTermType: term?.paymentTermType,
      day: term?.day || '30',
      percent: Number(term?.percent) || 0,
      brokerEmails: term?.emails,
      files: term?.files?.filter((el: any) => el.type !== 5)?.map((el: any) => el.id),
      agreement: term?.files?.filter((el: any) => el.type === 5)?.map((el: any) => el.id),
      showFiles: term?.files
        ?.filter((el: any) => el.type !== 5)
        ?.map((el: any) => ({
          createdAt: el?.createdAt,
          fileType: String(el?.type),
          ...el?.file,
          createdBy: `${el?.creator?.firstName} ${el?.creator?.lastName}`,
        })),
      showAgreement: term?.files
        ?.filter((el: any) => el.type === 5)
        ?.map((el: any) => ({
          createdAt: el?.createdAt,
          fileType: String(el?.type),
          ...el?.file,
          createdBy: `${el?.creator?.firstName} ${el?.creator?.lastName}`,
        })),
      createdAt: term?.createdAt,
      createdBy: term?.createdBy,
    };
  });
  const paymentTermFM = {
    type: body?.freightmax?.type,
    additionalInfo: body?.freightmax?.additionalInfo,
    agreementsFiles: body?.freightmax?.agreementsFiles,
    id: body?.freightmax?.id,
    authorityId: body?.freightmax?.authorityId ? [body?.freightmax?.authorityId] : [],
    factoringType: body?.freightmax?.billingType,
    factoringCompanyById: { result: body?.freightmax?.factoringCompany },
    factoredCompanyName: [
      {
        id: body?.freightmax?.factoringCompany?.id,
        companyName: body?.freightmax?.factoringCompany?.companyName,
        type: 1,
      },
    ],
    paymentType: body?.freightmax?.dealType,
    paymentBankName: body?.freightmax?.paymentBankName || '',
    paymentRoutingNumber: body?.freightmax?.paymentRoutingNumber || '',
    paymentAccountNumber: body?.freightmax?.paymentAccountNumber || '',

    paymentCompanyName: body?.freightmax?.paymentCompanyName,
    paymentAddress: body?.freightmax?.paymentAddress,
    paymentSecondAddress: body?.freightmax?.paymentSecondAddress || '',
    paymentCity: body?.freightmax?.paymentCity,
    paymentStateId: body?.freightmax?.paymentStateId,
    paymentZip: body?.freightmax?.paymentZip ? String(body?.freightmax?.paymentZip) : '',
    paymentFuelCard: body?.freightmax?.paymentFuelCard || '',
    brokerPaymentTermType: body?.freightmax?.paymentTermType,
    day: body?.freightmax?.day || '30',
    percent: Number(body?.freightmax?.percent) || 0,
    brokerEmails: body?.freightmax?.emails,
    files: body?.freightmax?.files?.filter((el: any) => el.type !== 5)?.map((el: any) => el.id),
    agreement: body?.freightmax?.files?.filter((el: any) => el.type === 5)?.map((el: any) => el.id),
    showFiles: body?.freightmax?.files
      ?.filter((el: any) => el.type !== 5)
      ?.map((el: any) => ({
        createdAt: el?.createdAt,
        fileType: String(el?.type),
        ...el?.file,
        createdBy: `${el?.creator?.firstName} ${el?.creator?.lastName}`,
      })),
    showAgreement: body?.freightmax?.files
      ?.filter((el: any) => el.type === 5)
      ?.map((el: any) => ({
        createdAt: el?.createdAt,
        fileType: String(el?.type),
        ...el?.file,
        createdBy: `${el?.creator?.firstName} ${el?.creator?.lastName}`,
      })),
    createdAt: body?.freightmax?.createdAt,
    createdBy: body?.freightmax?.createdBy,
  };
  return {
    paymentTerm: { count: body?.brokerage?.count, result: paymentTermResult },
    paymentTermFM: body?.freightmax?.id ? [paymentTermFM] : [],
  };
};
export const getCarriersListSerializer = (body: any): any => {
  const result = body?.result?.map((carrier: any) => {
    return {
      id: carrier?.id,
      date: formatDate(carrier?.createdAt),
      company: carrier?.companyName,
      carrier_id: carrier?.id,
      phone: formatPhone(carrier?.phone),
      phone2: formatPhone(carrier?.secondPhone),
      fax: carrier?.fax,
      contact: carrier?.contactName,
      type: ICarrierType[carrier?.type],
      mc: carrier?.mc,
      physical: carrier?.physical === 1,
      address: carrier?.address,
      city: carrier?.city,
      state: carrier?.state?.name,
      zip: carrier?.zip,
      rate: {
        rating: carrier?.rating,
        accessRating: carrier?.accessRating || 0,
        ratingCount: carrier?.ratingCount,
      },
      comments_count: carrier?.commentCount,
      terminate: carrier?.terminatedAt,
      dot: carrier?.dot,
      truckCount: carrier?.truckCount,
      paymentPaidAs: carrier?.paymentPaidAs,
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getCarrierSerializer = (body: ICarrierByIdData): any => {
  return {
    ...body,
    equipmentType: equipmentTypeSerializer(body.equipments),
    createdAt: formatDate(body.createdAt),
    type: body.type,
  };
};

export const getRequestCarrierSerializer = (body: any) => {
  return {
    ...body,
    createdAt: formatDate(body.createdAt),
    type: ICarrierType[body.type],
    status: body.requestStatus,
  };
};

export const getRequestCarrierSerializerForCarrier = (body: any) => {
  return {
    ...body?.result,
    createdAt: formatDate(body?.result?.createdAt),
    status: ICarrierStatusForCarrier[body?.result?.status],
  };
};

export const getCarrierComments = (body: any) => {
  const comments = body?.result?.map((comment: any) => {
    return {
      id: comment.id,
      comment: comment.message,
      createdAt: formatDate(comment.createdAt),
      name: 'izmenit',
    };
  });
  return {
    comments,
    count: body?.count,
  };
};

export const getRequestCarrierListSerializer = (body: any) => {
  const result = body?.result?.map((carrier: any) => {
    return {
      id: carrier?.id,
      createdAt: formatDate(carrier?.createdAt),
      name: carrier?.companyName,
      contact_name: carrier?.contactName,
      addedBy: `${carrier?.user?.username}`,
      agent_type: AgentType[carrier?.user?.agentType],
      dot: carrier?.dot,
      mc: carrier?.mc,
      phone: carrier?.phone,
      action: carrier?.requestStatus,
      status: carrier?.requestStatus,
    };
  });
  return {
    result,
    count: body?.count,
  };
};
export const getRequestCarrierListForCarrierSerializer = (body: any) => {
  const result = body?.result?.map((carrier: any) => {
    return {
      id: carrier?.id,
      date: formatDate(carrier?.createdAt),
      name: carrier?.companyName,
      contact: carrier?.contactName,
      company: carrier?.companyName,
      addedBy: `${carrier?.user?.username}`,
      agent_type: AgentType[carrier?.user?.agentType],
      dot: carrier?.dot,
      mc: carrier?.mc,
      phone: carrier?.phone,
      action: carrier?.status,
      status: carrier?.status,
    };
  });
  return {
    result,
    count: body?.count,
  };
};

export const getCarrierBlackListSerializer = (body: any) => {
  const result = body?.result?.map((carrier: any) => {
    return {
      id: carrier?.groupId,
      date: formatDate(carrier?.createdAt),
      added_by: `${carrier?.creator?.username}`,
      carrier: carrier?.carrier?.companyName,
      offices: carrier?.offices,
      branch: carrier?.allBranch ? [{ name: 'All' }] : carrier?.branches,
      unlock: 'Unlock',
      agent: carrier?.allAgent
        ? [{ name: 'All' }]
        : carrier?.agents?.map((agent: any) => ({
            id: agent?.id,
            name: `${agent?.username}`,
          })),
    };
  });
  return {
    result,
    count: body?.count,
  };
};

export const getCarrierRatingsSerializer = (body: any) => {
  const result = body?.result?.map((item: any) => {
    return {
      id: item?.id,
      key: item?.id,
      name: `${item?.creator?.firstName} ${item?.creator?.lastName}`,
      date: formatDate(item?.updatedAt || item?.createdAt),
      rate: item?.rating,
      creatorId: item?.creator?.id,
    };
  });

  return result;
};

export const getLastLoadsSerializer = (body: GetLastLoadsByCarrierIdResult) => {
  const result = body?.result?.map((load: any) => {
    return {
      registrationDate: dayjs(load.createdAt).tz('America/Los_Angeles')!.format('MM-YYYY'),
      from: `${load?.firstPickUp?.city}, ${load?.firstPickUp?.state?.name}`,
      to: `${load?.lastDropOff?.city}, ${load.lastDropOff?.state?.name}`,
      pickupsCount: load.pickUps.length,
      dropoffsCount: load.dropOffs.length,
      pickupsOptions: load.pickUps,
      dropoffsOptions: load.dropOffs,
      equipmentType: TransportTypes[load.truckType],
      weight: load.weight,
      carrierAmount: load.amount,
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getCountsParamsSerializer = (queries: any) => {
  const { ratings, type } = queries;
  const searchParams = new URLSearchParams();

  const rates = ratings?.map((item: string) => {
    if (item === '6') {
      return '0';
    }
    return item;
  });
  rates?.length && rates?.forEach((el: any) => searchParams.append('ratings[]', el));
  type ? searchParams.append('type', type) : {};

  return searchParams;
};
