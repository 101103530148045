import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useFileUpload from 'hooks/useFileUpload';
import { transactionDefalultValue } from 'pages/loads/components/edit-load/constants/constants';
import { ITransactionFormData } from 'pages/loads/components/edit-load/constants/types';
import { transactionValidation } from 'pages/loads/components/edit-load/constants/validation';
import { handleBalanceAmount } from 'pages/loads/constants/helpers';
import { billingAPI } from 'services/factoring/billing/billing';
import { IGetLoadsParams } from 'services/loads/interface';
import {
  useAcceptBillingMutation,
  useAddRedConfirmationFileToLoadMutation,
  useCancelLoadMutation,
  useClaimLoadMutation,
  useCreateLoadInvoiceMutation,
  useDeleteFileFromLoadMutation,
  useEditLoadMutation,
  useGetCarrierBreakdownsInLoadQuery,
  useGetLoadHistoryQuery,
  useRejectLoadBillingMutation,
  useRejectLoadRetrievedMutation,
  useSendToBillingMutation,
  useUnclaimLoadMutation,
} from 'services/loads/loads';
import { useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { useCreateTransactionMutation, useDeleteTransactionMutation } from 'services/transactions/transactions';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { CarrierStatus, CustomerStatus, LoadStatus } from 'ui/load-status/constants/types';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { formatNumberWithThousandsSeparatorAndDecimal, scrollToTarget } from '../../../../utils/helpers';
import { SUBJECT } from '../constants/constants';

import { defaulValuesForEdit } from './constants/constants';
import { tonuValidationForEdit } from './constants/validation';

const useTonuEdit = ({
  data,
  loadFiles,
  refetchLoadById,
  isFilesFetching,
  isFetching,
  refetchTransaction,
  refetchFilesByLoadId,
  loadsPagePermissions,
  refetchRateConfirmations,
}: any) => {
  const dispatch = useDispatch();
  const { searchParams } = useDetectedParams();
  const { id: userId } = useSelector(selectUserInfo);

  const { open, id } = searchParams;

  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const drawerRef: { current: any } = useRef(null);

  const [isDefaultHistory, setIsDefaultHistory] = useState(false);
  const handleScrollToHistory = (mode: string) => {
    scrollToTarget(drawerRef);
    setIsDefaultHistory(mode === 'history');
  };

  const [breakdownParams, setBreakdownParams] = useState({
    skip: 0,
    limit: 10,
    order: 2,
    orderBy: 'createdAt',
  });

  const { data: breakdowns, isFetching: isBreakdownLoading } = useGetCarrierBreakdownsInLoadQuery(
    { id: id, ...breakdownParams },
    { skip: !id }
  );

  const handleCarrierBreakdownPaginationClick = (skip: number, limit: number) => {
    setBreakdownParams((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleBreakdownSortClick = (sortOrder: number, dataIndex: string) => {
    setBreakdownParams((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          skip: 0,
          limit: prev.limit,
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const isDisableSoldUs = () => {
    if (
      (userType == UserTypes.AGENT ||
        (userType == UserTypes.EMPLOYEE &&
          (employeeLevel == EmployeeLevels.OFFICE || employeeLevel == EmployeeLevels.BRANCH) &&
          loadsPagePermissions.editSoldAsMc)) &&
      data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING &&
      data?.load?.carrier?.id &&
      data?.load?.customer?.id &&
      !data?.load?.rateConfirmationSentAt
    ) {
      return false;
    } else
      return !(
        ((employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.editSoldAsMc) ||
          userType === UserTypes.ADMIN) &&
        data?.load?.carrierStatus !== CarrierStatus.CARRIER_STATUS_PAID &&
        data?.load?.carrier?.id &&
        data?.load?.customer?.id &&
        !data?.load?.rateConfirmationSentAt
      );
  };
  const isDisableAuthority = () => {
    if (
      (userType == UserTypes.AGENT ||
        (userType == UserTypes.EMPLOYEE &&
          (employeeLevel == EmployeeLevels.OFFICE || employeeLevel == EmployeeLevels.BRANCH) &&
          loadsPagePermissions.editSoldAsMc)) &&
      data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING
    ) {
      return false;
    } else
      return !(
        ((employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.editSoldAsMc) ||
          userType === UserTypes.ADMIN) &&
        data?.load?.carrierStatus !== CarrierStatus.CARRIER_STATUS_PAID
      );
  };
  const isDisableReference = () => {
    if (
      (userType == 3 ||
        (userType == 2 && (employeeLevel == 2 || employeeLevel == 3) && loadsPagePermissions.editSoldAsMc)) &&
      data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING
    ) {
      return false;
    } else
      return !(
        ((employeeLevel === 1 && loadsPagePermissions.editReferenceNumber) || userType === 1) &&
        data?.load?.customerStatus !== CustomerStatus.CUSTOMER_STATUS_PAID &&
        data?.load?.customerStatus !== CustomerStatus.CUSTOMER_STATUS_DEBT
      );
  };

  const {
    control,
    formState: { isValid, errors, dirtyFields },
    getValues,
    watch,
    reset,
    resetField,
    setValue,
    trigger,
  } = useForm<any>({
    defaultValues: defaulValuesForEdit,
    mode: 'onBlur',
    resolver: yupResolver(tonuValidationForEdit),
  });

  const [notes, setNotes] = useState<any>({
    result: [],
    count: 0,
  });

  const [notesParams, setNotesParams] = useState<any>({
    skip: 0,
    limit: 10,
  });

  const { currentData: loadHistory, isFetching: isFetchingHistory } = useGetLoadHistoryQuery(
    { id: data?.load?.id, ...notesParams },
    { skip: !data?.load?.id }
  );

  const getMoreNotes = () => {
    setNotesParams((prev: any) => ({ ...prev, skip: prev.skip + 10 }));
  };

  const isShowMoreVisibleNotes = useMemo(
    () => loadHistory?.count && loadHistory?.count > notes?.result?.length,
    [loadHistory, notesParams, notes?.result?.length]
  );

  useEffect(() => {
    if (loadHistory?.result && isShowMoreVisibleNotes) {
      setNotes((prev: any) => ({
        result: [...prev.result, ...loadHistory.result],
        count: loadHistory?.count,
      }));
    }
  }, [loadHistory?.result?.length, notesParams.skip]);

  useEffect(() => {
    return () => {
      setNotesParams({
        skip: 0,
        limit: 10,
      });
    };
  }, [open]);

  const [editLoad] = useEditLoadMutation();
  const [addRedConfirmationFileToLoad] = useAddRedConfirmationFileToLoadMutation();
  const [deleteFileFromLoad] = useDeleteFileFromLoadMutation();
  const [createTransaction, { isLoading: isCreateTransactionLoading }] = useCreateTransactionMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [sendToBilling] = useSendToBillingMutation();
  const [createInvoice, { isLoading: isInvoiceLoading }] = useCreateLoadInvoiceMutation();
  const [acceptBilling] = useAcceptBillingMutation();
  const [rejectBilling] = useRejectLoadBillingMutation();
  const [rejectLoadRetrieved] = useRejectLoadRetrievedMutation();
  const [cancelLoad] = useCancelLoadMutation();
  const [claimLoad] = useClaimLoadMutation();
  const [unclaimLoad] = useUnclaimLoadMutation();
  const [isSendedToBilling, setIsSendedToBilling] = useState(false);

  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: [],
  });

  const authoritiesRequestType = () => {
    return !(employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN);
  };

  const { data: authorities = [] } = useGetAuthoritiesShortQuery(
    { ...authoritiesState, onlyAssigned: authoritiesRequestType() },
    {
      skip: open === 'false',
    }
  );

  useEffect(() => {
    if (data?.load?.status === LoadStatus.LOAD_STATUS_FACTORED) {
      setIsSendedToBilling(true);
    }
    if (data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING) {
      setIsSendedToBilling(false);
    }
  }, [data?.load?.status]);

  const {
    formState: { errors: transactioErrors, dirtyFields: transactionDirtyFields },
    control: transactioControl,
    reset: resetTransaction,
    watch: transactionWatch,
    setValue: transactionSetValue,
    trigger: transactionTrigger,
  } = useForm<ITransactionFormData>({
    defaultValues: transactionDefalultValue,
    mode: 'all',
    resolver: yupResolver(transactionValidation()),
  });

  const customer = transactionWatch('customer');
  const carrier = transactionWatch('carrierTransaction');

  const amount = handleBalanceAmount(Number(customer), Number(carrier));

  const isTransactionValidBtn =
    !!(customer || carrier) && !!transactionWatch('transactionType') && !Object.keys(transactioErrors)?.length;

  useEffect(() => {
    if (amount) {
      transactionSetValue('balanceAmount', formatNumberWithThousandsSeparatorAndDecimal(amount));
    } else {
      transactionSetValue('balanceAmount', 0);
    }
  }, [customer, carrier]);

  const handleResetTransactionForm = () => {
    resetTransaction();
  };

  const podFiles = useMemo(() => {
    const resultArray = [];
    for (const key in loadFiles?.result) {
      if (key !== 'RED_CONFIRMATION' && key !== 'INVOICE') {
        resultArray.push(...loadFiles.result[key]);
      }
    }
    return resultArray.sort((a, b) => a.createdAt - b.createdAt);
  }, [loadFiles]);

  const mailableFiles = podFiles.filter(item => item.mailable);

  const {
    handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
  } = useFileUpload({
    data: {
      result: { files: loadFiles?.result?.RED_CONFIRMATION },
    },
    setValue,
    getValues,
    id: data?.load?.id,
    addNewFile: addRedConfirmationFileToLoad,
    deleteFile: deleteFileFromLoad,
    trigger,
    autoUpdate: true,
  });

  useEffect(() => {
    setAllFiles(savedFiles);
  }, [savedFiles]);

  useEffect(() => {
    reset({
      referenceNumber: data?.load?.referenceNumber || '',
      soldUsSwitch: !!data?.load?.soldAsAuthority?.id,
      allAuthorities: data?.load?.soldAsAuthority?.id ? [data?.load?.soldAsAuthority] : [],
      markAsClaimSwitch: Boolean(data?.load?.claimed),
      carrierAmount: String(data?.load?.carrierAmount),
      carrierSwitch: !!data?.load?.carrier?.id,
      carrier: data?.load?.carrier?.name,
      customerAmount: String(data?.load?.customerAmount),
      customerContact: String(data?.load?.customerContact),
      customerName: data?.load?.customer?.name,
      customerSwitch: !!data?.load?.customer?.id,
      customerContactName: data?.load?.customerContactName,
      load_type: 'Tonu',
      takenUsAuthority: data?.load?.takenAsAuthority?.name,
      truck: data?.load?.truck?.truckNumber,
      file: allFiles,
    });
  }, [data]);

  const handleSwitchMarkAsClaim = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_CLAIM,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: data?.load?.claimed ? 'danger' : 'modalPrimary',
        onOk: data?.load?.claimed
          ? () => {
              dispatch(changeLoading(true));
              unclaimLoad(Number(data?.load?.id)).then((args: any) => {
                if (args.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(args?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(changeLoading(false));
                } else {
                  notification.success({
                    message: 'Successfully marked as claim',
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(changeLoading(false));
                  dispatch(closeModal());
                  refetchLoadById({ id: data?.load?.id });
                }
              });
            }
          : () => {
              dispatch(changeLoading(true));
              claimLoad(Number(data?.load?.id)).then((args: any) => {
                if (args.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(args?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(changeLoading(false));
                } else {
                  notification.success({
                    message: 'Successfully marked as claim',
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(changeLoading(false));
                  dispatch(closeModal());
                  refetchLoadById({ id: data?.load?.id });
                }
              });
            },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleCancelLoad = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_LOAD,
        okText: SUBJECT.CANCEL,
        cancelText: SUBJECT.NO,
        okButtonVariant: 'danger',
        cancelButtonVariant: 'gray',
        onOk: () => {
          cancelLoad(String(data?.load?.id)).then((data: any) => {
            if (data.error) {
              dispatch(closeModal());
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully canceled',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              refetchLoadById({ id: data?.load?.id });
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleCancel = () => {
    reset({
      referenceNumber: data?.load?.referenceNumber || '',
      soldUsSwitch: !!data?.load?.soldAsAuthority?.id,
      allAuthorities: data?.load?.soldAsAuthority?.id ? [data?.load?.soldAsAuthority] : [],
      markAsClaimSwitch: false,
      carrierAmount: data?.load?.carrierAmount,
      carrierSwitch: !!data?.load?.carrier?.id,
      carrier: data?.load?.carrier?.name,
      customerAmount: data?.load?.customerAmount,
      customerContact: data?.load?.customerContact,
      customerName: data?.load?.customer?.name,
      customerContactName: data?.load?.customerContactName,
      customerSwitch: !!data?.load?.customer?.id,
      load_type: 'Tonu',
      takenUsAuthority: data?.load?.takenAsAuthority?.name,
      truck: data?.load?.truck?.truckNumber,
      file: allFiles,
    });
  };

  const handleSave = () => {
    dispatch(
      openModal({
        title: SUBJECT.JUST_ARE_YOU_SURE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        onOk: () => {
          dispatch(changeLoading(true));
          editLoad({
            id: data?.load?.id,
            referenceNumber: watch('referenceNumber'),
            soldAuthority: watch('soldUsSwitch') ? watch('allAuthorities') : null,
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              handleCancel();
            } else {
              notification.success({
                message: 'Successfully edited',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchLoadById();
            }
            dispatch(changeLoading(false));
            dispatch(closeModal());
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };
  const handleCreateTransaction = () => {
    dispatch(changeLoading(true));
    createTransaction({
      loadId: data?.load?.id,
      body: transactionWatch(),
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        resetTransaction();
        refetchLoadById();
        refetchTransaction();
        dispatch(changeLoading(false));
        refetchRateConfirmations();
        refetchFilesByLoadId({ id: data?.load?.id });
        dispatch(billingAPI.util.invalidateTags(['Billing']));
      }
    });
  };

  const handleDeleteTransaction = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          deleteTransaction({ id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchTransaction();
              refetchLoadById();
              refetchRateConfirmations();
              dispatch(changeLoading(false));
              refetchFilesByLoadId({ id: data?.load?.id });
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const isSaveButtonVisible = useMemo(() => {
    return !!Object.keys(dirtyFields).length;
  }, [Object.keys(dirtyFields).length]);

  const handleAcceptToBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_ACCEPT,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));

          acceptBilling({ id: data?.load?.id, sendEmail: true }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: SUBJECT.ACCEPTED_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchFilesByLoadId();
              refetchLoadById();
              if (!isFetching && !isFilesFetching) {
                dispatch(changeLoading(false));
                dispatch(closeModal());
              }
            }
          });
        },
        onCancel: () => {
          dispatch(changeLoading(true));

          acceptBilling({ id: data?.load?.id, sendEmail: false }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: SUBJECT.ACCEPTED_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchFilesByLoadId();
              refetchLoadById();
              if (!isFetching && !isFilesFetching) {
                dispatch(changeLoading(false));
                dispatch(closeModal());
              }
            }
          });
        },
      })
    );
  };

  const handleRejectBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.REJECT,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          data?.load?.retrieved
            ? rejectLoadRetrieved({ id: data?.load?.id }).then((data: any) => {
                if (data.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                } else {
                  notification.success({
                    message: SUBJECT.REJECTED_SUCCESSFULLY,
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  refetchLoadById();
                  refetchTransaction();
                }
              })
            : rejectBilling({ id: data?.load?.id }).then((data: any) => {
                if (data.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                } else {
                  notification.success({
                    message: SUBJECT.REJECTED_SUCCESSFULLY,
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  refetchLoadById();
                  refetchTransaction();
                }
              });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleCreateInvoice = () => {
    notification.info({
      message: SUBJECT.CREATE_INVOICE_IN_PROCCESS,
      duration: 3,
      placement: 'topRight',
      closeIcon: <CloseIcon />,
    });
    createInvoice({ id: data?.load?.id }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.INVOICE_SUCCESSFULLY,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        refetchFilesByLoadId();
      }
    });
  };

  const handleDeleteInvoice = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteFileFromLoad({ fileId: loadFiles?.result?.INVOICE[0].id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };
  const handleSendToBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_BILLING,
        okText: SUBJECT.SEND,
        cancelText: SUBJECT.CLOSE,
        onOk: () => {
          dispatch(changeLoading(true));
          sendToBilling({ id: data?.load?.id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: SUBJECT.REQUEST_SENT_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              setIsSendedToBilling(true);
              dispatch(closeModal());
            }
            dispatch(changeLoading(false));
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const isSendToBillingDisabled = useMemo(() => {
    const hasRedConfirmationFile =
      loadFiles?.result?.RED_CONFIRMATION && loadFiles?.result?.RED_CONFIRMATION.length > 0;
    const hasMailableRedConfirmation =
      hasRedConfirmationFile && loadFiles?.result?.RED_CONFIRMATION.some((file: any) => !!file.mailable);
    if (!data?.load?.customer?.id) {
      return false;
    }
    if (data?.load?.customer?.id && hasMailableRedConfirmation) {
      return false;
    }
    return true;
  }, [loadFiles, data]);

  const isLoadHasCustomer = useMemo(() => {
    return !!data?.load?.customer?.id;
  }, [data]);

  const addTransactionCheck = () => {
    if (
      userType === UserTypes.ADMIN ||
      (employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.transaction)
    ) {
      return false;
    } else {
      if (
        (data?.load?.customerStatus === 2 && !data?.load?.processing) ||
        (data?.load?.carrierStatus === 2 && !data?.load?.processing)
      ) {
        return true;
      }
    }
  };

  useEffect(() => {
    setAuthoritiesState((prev: any) => {
      return {
        ...prev,
        ...(data?.load?.takenAsAuthority?.id
          ? { ignoreAuthorityIds: [data?.load?.takenAsAuthority?.id] }
          : { ignoreAuthorityIds: [] }),
      };
    });
  }, []);

  return {
    loadHistory: notes,
    getMoreNotes,
    isShowMoreVisibleNotes,
    authorities,
    setAuthoritiesState,
    allFiles,
    beforeUploadForEdit,
    handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    transactioControl,
    transactionSetValue,
    transactionTrigger,
    transactionWatch,
    transactioErrors,
    handleSwitchMarkAsClaim,
    handleCancelLoad,
    control,
    watch,
    errors,
    setValue,
    isSaveButtonVisible,
    isValid,
    handleCancel,
    dirtyFields,
    handleSave,
    handleCreateTransaction,
    handleDeleteTransaction,
    isSendedToBilling,
    handleAcceptToBilling,
    handleRejectBilling,
    handleCreateInvoice,
    handleDeleteInvoice,
    isSendToBillingDisabled,
    isInvoiceLoading,
    handleSendToBilling,
    resetField,
    isLoadHasCustomer,
    isTransactionValidBtn,
    handleResetTransactionForm,
    employeeLevel,
    addTransactionCheck,
    userType,
    transactionDirtyFields,
    isCreateTransactionLoading,
    userId,
    isFetchingHistory,
    isDisableSoldUs,
    isDisableReference,
    mailableFiles,
    isDisableAuthority,
    breakdowns,
    isBreakdownLoading,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    breakdownParams,
    setIsDefaultHistory,
    isDefaultHistory,
    handleScrollToHistory,
    drawerRef,
  };
};

export default useTonuEdit;
