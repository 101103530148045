import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row } from 'antd';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import SimpleFlatSelect from 'ui/inputs/simpleFlatSelect/simpleFlatSelect';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import { floatNumberFormatter } from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { BOOKED_TYPES, RESOURCE_TYPES, SUBJECT, TRANSPORT_TYPES } from '../../constants/constants';
import { BookedTypes, LoadTypes, TransportTypes } from '../../constants/types';
import { Input } from '../../hoc/instance';

export const LoadInformation = ({ control, errors, watch, setValue, trigger }: any) => {
  const fahrenheitOptions = useMemo(() => {
    const options = [];
    for (let i = -100; i <= 100; i++) {
      const obj = {
        value: i.toString(),
        label: i.toString(),
        title: i.toString(),
      };
      options.push(obj);
    }
    return options;
  }, []);
  return (
    <ViewItemWrapper id="load-information" title={SUBJECT.LOAD_INFORMATION}>
      <Row gutter={[18, 40]}>
        <Col span={8}>
          <Controller
            name="transportType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <SimpleSelect
                  options={TRANSPORT_TYPES}
                  placeholder={SUBJECT.LOAD_TYPE}
                  name={field.name}
                  onBlur={() => trigger('transportType')}
                  onChange={(value: string | string[]) => field.onChange(value)}
                  errors={errors}
                  value={field.value}
                  required
                />
              );
            }}
          />
          <ErrorMessage fieldError={errors?.transportType} />
        </Col>
        {TransportTypes[watch('transportType')] === 'Refrigerated' && (
          <Col span={8}>
            <Controller
              name="celsius"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <SimpleFlatSelect
                    options={fahrenheitOptions}
                    placeholder={SUBJECT.REFRIGERATOR_CELCIUS}
                    name={field.name}
                    onBlur={() => trigger('celsius')}
                    onChange={(value: string | string[]) => {
                      field.onChange(value);
                    }}
                    errors={errors}
                    value={field.value}
                    required
                  />
                );
              }}
            />
          </Col>
        )}
        <Col span={8}>
          <Controller
            name="takenAs"
            control={control}
            rules={{ required: LoadTypes[watch('load_type')] !== 'Internal' }}
            render={({ field }) => {
              return (
                <SimpleSelect
                  options={RESOURCE_TYPES}
                  placeholder={SUBJECT.TAKEN_AS}
                  name={field.name}
                  onBlur={() => trigger('takenAs')}
                  onChange={(value: string | string[]) => field.onChange(value)}
                  errors={errors}
                  value={field.value}
                  required={LoadTypes[watch('load_type')] !== 'Internal'}
                />
              );
            }}
          />
          <ErrorMessage fieldError={errors?.takenAs} />
        </Col>
        <Col span={8}>
          <Controller
            name="givenAs"
            control={control}
            rules={{ required: LoadTypes[watch('load_type')] !== 'Internal' }}
            render={({ field }) => {
              return (
                <SimpleSelect
                  options={RESOURCE_TYPES}
                  placeholder={SUBJECT.GIVEN_AS}
                  name={field.name}
                  onBlur={() => trigger('givenAs')}
                  onChange={(value: string | string[]) => field.onChange(value)}
                  errors={errors}
                  value={field.value}
                  required={LoadTypes[watch('load_type')] !== 'Internal'}
                />
              );
            }}
          />
          <ErrorMessage fieldError={errors?.givenAs} />
        </Col>
        <Col span={16}>
          <Input
            name="commodity"
            control={control}
            placeholder={SUBJECT.COMMODITY}
            rules={{ required: true }}
            errors={errors}
            getValues={watch}
            required
            onChange={(e: any) => setValue('commodity', e.target.value.trimStart(), { shouldValidate: true })}
          />
        </Col>
      </Row>
      <Row gutter={[18, 40]} style={{ marginTop: '40px' }}>
        <Col span={8}>
          <Controller
            name="bookedAs"
            control={control}
            rules={{ required: LoadTypes[watch('load_type')] !== 'Internal' }}
            render={({ field }) => {
              return (
                <SimpleSelect
                  options={BOOKED_TYPES}
                  placeholder={SUBJECT.BOOKED_AS}
                  name={field.name}
                  onBlur={() => trigger('bookedAs')}
                  onChange={(value: string | string[]) => field.onChange(value)}
                  errors={errors}
                  value={field.value}
                  required={LoadTypes[watch('load_type')] !== 'Internal'}
                />
              );
            }}
          />
          <ErrorMessage fieldError={errors?.bookedAs} />
        </Col>
        <Col span={8}>
          <Controller
            name="soldAs"
            control={control}
            rules={{ required: LoadTypes[watch('load_type')] !== 'Internal' }}
            render={({ field }) => {
              return (
                <SimpleSelect
                  options={BOOKED_TYPES}
                  placeholder={SUBJECT.SOLD_AS}
                  name={field.name}
                  onBlur={() => trigger('soldAs')}
                  onChange={(value: string | string[]) => field.onChange(value)}
                  errors={errors}
                  value={field.value}
                  required={LoadTypes[watch('load_type')] !== 'Internal'}
                />
              );
            }}
          />
          <ErrorMessage fieldError={errors?.soldAs} />
        </Col>
        {BookedTypes[watch('soldAs')] === 'Partial' && (
          <Col span={8}>
            <Input
              name="feetPartial"
              control={control}
              placeholder={SUBJECT.FEET_PARTIAL}
              rules={{ required: true }}
              errors={errors}
              getValues={watch}
              required
              onChange={(e: any) => {
                setValue('feetPartial', floatNumberFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
          </Col>
        )}
        <Col span={8}>
          <Input
            name="weight"
            control={control}
            placeholder={SUBJECT.WEIGHT}
            rules={{ required: true }}
            errors={errors}
            getValues={watch}
            required
            value={watch('weight')}
            onChange={(e: any) => {
              setValue('weight', floatNumberFormatter(e.target.value), {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
          />
        </Col>
      </Row>
    </ViewItemWrapper>
  );
};
