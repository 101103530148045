import { changeTimeZone } from 'utils/helpers';

export const inviteEmployeeDeserializer = (body: any): any => {
  return {
    firstName: body.firstName,
    lastName: body.lastName,
    phone: body.phoneNumber,
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body?.secondAddress } : {}),
    ...(body.dob
      ? { dateOfBirth: changeTimeZone(new Date(Number(body.dob)), 'America/Los_Angeles') }
      : { dateOfBirth: 0 }),
    city: body.city,
    stateId: Number(body.state),
    zip: body.zipcode,
    type: 1,
    username: body.username,
    email: body.email,
    ext: body.ext,
    title: body.title,
    permissionGroupId: Number(body?.permissionGroup),
    emergencyName: body.name,
    emergencyRelation: body.relation,
    emergencyPhone: body.phoneNumberEmergency,
    fileIds: body.file?.map((el: any) => Number(el)),
  };
};

export const editEmployeeDeserializer = (body: any): any => {
  const dob = isNaN(Number(body?.dob))
    ? new Date(new Date(body?.dob).getFullYear(), new Date(body?.dob).getMonth(), new Date(body?.dob).getDate())
    : body.dob;
  const permissionGroup = isNaN(Number(body?.permissionGroup)) ? body?.groupId : body?.permissionGroup;
  return {
    firstName: body.firstName,
    lastName: body.lastName,
    phone: body.phoneNumber,
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body?.secondAddress } : {}),
    ...(body?.dob
      ? { dateOfBirth: isNaN(Number(body?.dob)) ? changeTimeZone(dob, 'America/Los_Angeles') : body?.dob }
      : { dateOfBirth: 0 }),
    city: body.city,
    stateId: Number(body.state),
    zip: body.zipcode,
    username: body.username,
    ext: body.ext,
    title: body.title,
    permissionGroupId: Number(permissionGroup),
    emergencyName: body.name,
    emergencyRelation: body.relation,
    emergencyPhone: body.phoneNumberEmergency,
  };
};
