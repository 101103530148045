export const editPermissionsNameSerializer = (data: any, defaultPermissions: any) => {
  const { updatedAt: _updatedAt, updatedBy: _updatedBy, terminatedAt: _terminatedAt, ...rest } = defaultPermissions;
  return {
    ...rest,
    name: data.permissionGroupName,
  };
};
export const editPermissionsSerializer = (data: any, defaultPermissions: any) => {
  const updatedPermissions: any = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      updatedPermissions[key] = { actions: {} };
      for (const action in defaultPermissions.permissions[key].actions) {
        updatedPermissions[key].actions[action] = false;
      }

      if (!defaultPermissions.permissions[key].hasOwnProperty('actions')) {
        delete updatedPermissions[key].actions;
      }

      if (key !== 'newLoad' && key !== 'mcCustomer') {
        data[key].actions.forEach((el: any) => {
          updatedPermissions[key].actions = {
            ...updatedPermissions[key].actions,
            ...(el !== 'download' ? { [el]: true } : {}),
          };
        });
      }

      updatedPermissions[key].view = data[key].permissions.includes('view');
      updatedPermissions[key].download = data[key].actions.includes('download');
    }
  }

  return {
    permissions: {
      ...defaultPermissions.permissions,
      ...updatedPermissions,
    },
    name: defaultPermissions.name,
  };
};
