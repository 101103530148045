import ExcelButton from 'ui/excel-button/ExcelButton';
import { getPayrollTabs } from 'utils/constants';

import CustomizeColumnButton from 'components/customize-column-button/CustomizeColumnButton';
import TabPanel from 'components/tab-panel/TabPanel';

import usePayroll from './usePayroll';

const Payroll = () => {
  const {
    tab,
    card,
    Component,
    canDownload,
    panelButton,
    isButtonVisible,
    permissionsInfo,
    isCreateButtonVisible,
    downloadExcel,
    openMainDrawer,
  } = usePayroll();
  const isButtonsShown = (card && tab === 'information') || tab === 'payout';
  return (
    <>
      <TabPanel tabs={getPayrollTabs(permissionsInfo?.permissions?.payroll?.visibleTabs)}>
        <div className="tabPanelButtons">
          {isButtonsShown && (
            <>
              <CustomizeColumnButton onClick={() => openMainDrawer('customize')} isButtonVisible={isButtonVisible} />
              {isCreateButtonVisible && panelButton[+card as keyof typeof panelButton]}

              {/*reopen when need*/}
              {canDownload && false && <ExcelButton onClick={downloadExcel} isButtonVisible={isButtonVisible} />}
            </>
          )}
        </div>
      </TabPanel>
      <div>{Component[tab]}</div>
    </>
  );
};
export default Payroll;
