const useContactCreateEdit = (usersList: any) => {
  const searchContactOptions = usersList?.result?.map((item: any) => ({
    id: String(item?.id),
    userId: String(item?.id),
    username: item?.username,
  }));
  return {
    searchContactOptions,
  };
};
export default useContactCreateEdit;
