import { phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  COMPANY_NAME_REQUIRED,
  PHONE_NUMBRE_REQUIRED,
  CONTANCT_NAME_REQUIRE,
  DOT_REQUIRED,
  MAX_LENGTH,
  PHONE,
  MC_REQUIRED,
} = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  companyName: yup.string().required(COMPANY_NAME_REQUIRED),
  phoneNumber: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBRE_REQUIRED).matches(phoneRegExp, PHONE),
  contactName: yup.string().required(CONTANCT_NAME_REQUIRE),
  dot: yup.string().required(DOT_REQUIRED),
  mc: yup.string().required(MC_REQUIRED),
});
