import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import styles from './TimerUp.module.scss';

const TimerUp = ({ initialTime, danger, warning }: { initialTime: string; danger: boolean; warning: boolean }) => {
  const [currentTime, setCurrentTime] = useState(parseTimeToSeconds(initialTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime((prevTime: any) => prevTime + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formattedTime = formatSecondsToTime(currentTime);

  return (
    <Row>
      <Typography
        children={formattedTime}
        className={clsx(styles.timerText, {
          [styles.danger]: danger,
          [styles.warning]: warning,
        })}
        variant="paragraph"
      />
    </Row>
  );
};

function parseTimeToSeconds(time: string) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

function formatSecondsToTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const time = `${String(hours).padStart(2, '0')}:${String(remainingMinutes)?.padStart(2, '0')}:${String(
    remainingSeconds
  ).padStart(2, '0')}`;

  if (time.includes('NaN')) {
    return '';
  }
  return time;
}

export default TimerUp;
