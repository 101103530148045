import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (date: any, format = 'MM-DD-YYYY, h:mm A') =>
  dayjs(date)!.tz('America/Los_Angeles').format(format)!.toString();
export const formatPickUpDropOff = (date: Date | string) =>
  dayjs(date)!.tz('America/Los_Angeles').format('MM-DD-YYYY')!.toString();
export const formatPickUpDropOffForSubmitting = (date: Date | string) => dayjs(date)!.format('MM-DD-YYYY')!.toString();

export const formatPickUpDropOffWithTime = (date: Date | string) =>
  dayjs(date)!.tz('America/Los_Angeles').format('MM-DD-YYYY, h:mm A')!.toString();

export const formatPickUpDropOffWithTimeWithoutTimeZoneChange = (date: Date | string) =>
  dayjs(date)!.format('MM-DD-YYYY, h:mm A')!.toString();

export const formatDateTimePeriod = (date: any, time: string, period: string) => {
  return `${formatPickUpDropOff(date)}, ${time} ${period}`;
};

export const formatDateBirth = (date: Date | string) =>
  dayjs(date)!.tz('America/Los_Angeles').format('MM-DD-YYYY').toString();

export const formatDateTimePeriodForBrowsers = (date: any, time: string, period: string) => {
  const [hour, minute] = time.split(':');
  const timeForEveryBrowser =
    period === 'PM' && Number(hour) + 12 === 24
      ? `00:${minute}`
      : period === 'PM'
      ? `${Number(hour) + 12}:${minute}`
      : time;
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate()}T${timeForEveryBrowser}:00`;
};

export const getMonth = (date: Date) => dayjs(date).month();
export const getYear = (date: Date) => dayjs(date).year();

export const subtractOneMonth = (timestamp: number): number => {
  const date = new Date(timestamp);

  let month = date.getMonth();
  let year = date.getFullYear();

  month--;

  if (month < 0) {
    month = 11;
    year--;
  }
  date.setFullYear(year, month, 1);

  return date.getTime();
};

export const addOneMonth = (timestamp: number): number => {
  const date = new Date(timestamp);

  let month = date.getMonth();
  let year = date.getFullYear();

  month++;

  if (month > 11) {
    month = 0;
    year++;
  }
  date.setFullYear(year, month, 1);

  return date.getTime();
};

export const addOneYear = (timestamp: number): number => {
  const date = new Date(timestamp);

  const month = date.getMonth();
  let year = date.getFullYear();

  year++;
  date.setFullYear(year, month, 1);

  return date.getTime();
};

export const subtractOneYear = (timestamp: number): number => {
  const date = new Date(timestamp);

  const month = date.getMonth();
  let year = date.getFullYear();

  year--;
  date.setFullYear(year, month, 1);

  return date.getTime();
};
