import ExcelButton from 'ui/excel-button/ExcelButton';
import { getFactoringTabs } from 'utils/constants';

import CustomizeColumnButton from 'components/customize-column-button/CustomizeColumnButton';
import TabPanel from 'components/tab-panel/TabPanel';

import useFactoring from './useFactoring';

const Factoring = () => {
  const { tab, openMainDrawer, downloadExcel, Component, isButtonVisible, permissionsInfo } = useFactoring();

  return (
    <div>
      <TabPanel tabs={getFactoringTabs(permissionsInfo?.permissions?.factoring?.visibleTabs)}>
        <div className="tabPanelButtons">
          <CustomizeColumnButton onClick={() => openMainDrawer('customize')} isButtonVisible={isButtonVisible} />
          <ExcelButton onClick={downloadExcel} isButtonVisible={isButtonVisible} />
        </div>
      </TabPanel>
      <div>{Component[tab]}</div>
    </div>
  );
};
export default Factoring;
