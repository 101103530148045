import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { SUBJECT } from './constants/constants';
import { ITruckBoardCardHookProps } from './constants/types';

export const useTruckBoardCard = ({ setValue, watch, formData }: ITruckBoardCardHookProps) => {
  const [openOptions, setOpenOptions] = useState('');
  useEffect(() => {
    let selectedValue = Number(watch(formData[1]));
    for (let i = 2; i < formData.length; i++) {
      if (Number(watch(formData[i])) !== selectedValue) {
        selectedValue = -1;
        break;
      }
    }
    if (selectedValue === -1 && Number(watch(formData[0])) !== 0) {
      setValue(formData[0], 0);
    }
    if (selectedValue !== -1 && Number(watch(formData[0])) !== selectedValue) {
      setValue(formData[0], selectedValue);
    }
  }, [watch(formData)]);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLInputElement;
    if (e.type === 'dblclick') {
      if (target.id.includes(SUBJECT.ALL)) {
        setValue(target.id, Number(watch(target.id)) !== SUBJECT.BOTH ? SUBJECT.BOTH : SUBJECT.EMPTY);

        formData.forEach((el: string) => {
          setValue(el, Number(watch(target.id)));
        });
      } else {
        setValue(target.id, Number(watch(target.id)) !== SUBJECT.BOTH ? SUBJECT.BOTH : SUBJECT.EMPTY);
      }
    } else if (e.type === 'click') {
      if (target.id.includes(SUBJECT.ALL)) {
        setValue(
          target.id,
          watch(target.id) && Number(watch(target.id)) === SUBJECT.FROM ? SUBJECT.EMPTY : SUBJECT.FROM
        );

        formData.forEach((el: string) => {
          setValue(el, Number(watch(target.id)));
        });
      } else {
        setValue(
          target.id,
          watch(target.id) && Number(watch(target.id)) === SUBJECT.FROM ? SUBJECT.EMPTY : SUBJECT.FROM
        );
      }
    } else if (e.type === 'contextmenu') {
      e.preventDefault();
      if (target.id.includes(SUBJECT.ALL)) {
        setValue(target.id, watch(target.id) && Number(watch(target.id)) === SUBJECT.TO ? SUBJECT.EMPTY : SUBJECT.TO);

        formData.forEach((el: string) => {
          setValue(el, Number(watch(target.id)));
        });
      } else {
        setValue(target.id, watch(target.id) && Number(watch(target.id)) === SUBJECT.TO ? SUBJECT.EMPTY : SUBJECT.TO);
      }
    }
  };

  const handlePopupClick = (type: string, id: string) => {
    if (type === 'dblclick') {
      if (id.includes(SUBJECT.ALL)) {
        setValue(id, Number(watch(id)) !== SUBJECT.BOTH ? SUBJECT.BOTH : SUBJECT.EMPTY);

        formData.forEach((el: string) => {
          setValue(el, Number(watch(id)));
        });
      } else {
        setValue(id, Number(watch(id)) !== SUBJECT.BOTH ? SUBJECT.BOTH : SUBJECT.EMPTY);
      }
    } else if (type === 'click') {
      if (id.includes(SUBJECT.ALL)) {
        setValue(id, watch(id) && Number(watch(id)) === SUBJECT.FROM ? SUBJECT.EMPTY : SUBJECT.FROM);

        formData.forEach((el: string) => {
          setValue(el, Number(watch(id)));
        });
      } else {
        setValue(id, watch(id) && Number(watch(id)) === SUBJECT.FROM ? SUBJECT.EMPTY : SUBJECT.FROM);
      }
    } else if (type === 'contextmenu') {
      if (id.includes(SUBJECT.ALL)) {
        setValue(id, watch(id) && Number(watch(id)) === SUBJECT.TO ? SUBJECT.EMPTY : SUBJECT.TO);

        formData.forEach((el: string) => {
          setValue(el, Number(watch(id)));
        });
      } else {
        setValue(id, watch(id) && Number(watch(id)) === SUBJECT.TO ? SUBJECT.EMPTY : SUBJECT.TO);
      }
    }
  };

  const handleMouseMove = (type: string, key = '') => {
    if (type === 'leave') {
      setOpenOptions('');
    } else {
      setOpenOptions(key);
    }
  };

  const resetCurrentSearch = () => {
    formData?.forEach((el: string) => {
      setValue(el, SUBJECT.EMPTY);
    });
  };

  const isFilledCurrentBlock = useMemo(() => {
    return !!formData?.filter(
      (el: any) => watch(el) == SUBJECT.FROM || watch(el) == SUBJECT.TO || watch(el) == SUBJECT.BOTH
    )?.length;
  }, [formData?.length, watch()]);

  return {
    handleClick,
    handlePopupClick,
    handleMouseMove,
    openOptions,
    resetCurrentSearch,
    isFilledCurrentBlock,
  };
};
export default useTruckBoardCard;
