import {
  contactsTabs,
  creditCheckTabs,
  factoringTabs,
  loadsTabs,
  payrollTabs,
  profileTabs,
  truckBoardTabs,
} from 'utils/constants';

import { useSidebar } from 'components/main-layout/components/sidebar/useSidebar';
import ContactIcon from 'components/svgs/home-page-icons/ContactIcon';
import CreditCheckIcon from 'components/svgs/home-page-icons/CreditCheckIcon';
import FactoringIcon from 'components/svgs/home-page-icons/FactoringIcon';
import LoadsIcon from 'components/svgs/home-page-icons/LoadsIcon';
import PayrollIcon from 'components/svgs/home-page-icons/PayrollIcon';
import PoermissionIcon from 'components/svgs/home-page-icons/PoermissionIcon';
import ProfiliesIcon from 'components/svgs/home-page-icons/ProfiliesIcon';
import TruckBoardIcon from 'components/svgs/home-page-icons/TruckBoardIcon';

export enum SUBJECT {
  PAGE_TITLE = 'Welcome to FreightMax',
}
export const getHomeCase = () => {
  const { activeTab } = useSidebar();

  return {
    profile: {
      id: 1,
      path: `/profile?tab=${profileTabs[activeTab.firstAvailableProfileTab as keyof typeof profileTabs]}`,
      icon: ProfiliesIcon,
      name: 'Profiles',
      description:
        'In the Profile section there are seven tabs: Agents,  Offices,  Branches,  Authorities, Carriers, Customers, Employees. On this page you have all possibilities to add, delete and change the whole information of the given sections.',
    },
    loads: {
      id: 2,
      path: `/loads?tab=${loadsTabs[activeTab.firstAvailableLoadTab as keyof typeof loadsTabs]}`,
      icon: LoadsIcon,
      name: 'Loads',
      description:
        'In the Loads section there are two tabs (Loads and New Loads). On this page you can see all loads and newly created loads. In addition, we can make a credit request in this section.',
    },
    payroll: {
      id: 3,
      path: `/payroll?tab=${payrollTabs[activeTab.firstAvailablePayrollTab as keyof typeof payrollTabs]}&activeTab=${
        activeTab.firstAvailablePayrollTab === 'payout' ? activeTab.firstAvailablePaymentSubTab : ''
      }`,
      icon: PayrollIcon,
      name: 'Agency Finance',
      description:
        'The Agency Finance section enables you to properly and accurately calculate the salaries and interest rates of the corporation’s employees.',
    },
    'credit-check': {
      id: 4,
      path: `/credit-check?tab=${
        creditCheckTabs[activeTab.firstAvailableCreditCheckTab as keyof typeof creditCheckTabs]
      }`,
      icon: CreditCheckIcon,
      name: 'Credit check',
      description:
        'In the employee-exclusive Credit Check section, featuring New Credit MC and Customers tabs, added credits are listed upon request and data is sourced from Payment Terms and Billing block in the Customers section.',
    },
    contact: {
      id: 5,
      path: `/contact?tab=${contactsTabs[activeTab.firstAvailableContactTab as keyof typeof contactsTabs]}`,
      icon: ContactIcon,
      name: 'Contact',
      description:
        'In the Contacts section you can view the contacts of the corporation’s employees. In this section you can also find the e-mail, address, phone number and position of this contact. There is also a possibility to search for a contact.',
    },
    factoring: {
      id: 6,
      path: `/factoring?tab=${factoringTabs[activeTab.firstAvailableFactoringTab as keyof typeof factoringTabs]}`,
      icon: FactoringIcon,
      name: 'Accounting',
      description:
        'In the Accounting section, comprising Billing, Payables, and Receivables tabs, shipments move from Billing (when under processing) to both Payables and Receivables upon billing confirmation.',
    },
    'truck-board': {
      id: 7,
      path: `/truck-board?tab=${truckBoardTabs[activeTab.firstAvailableTruckBoardTab as keyof typeof truckBoardTabs]}`,
      icon: TruckBoardIcon,
      name: 'Truck board',
      description: 'In the Truck board section, you can discover trucks tailored to your load destination.',
    },
    permission: {
      id: 8,
      path: '/permission?tab=permission',
      icon: PoermissionIcon,
      name: 'Permission',
      description:
        'The Permissions page has three tabs: \n' +
        'Corporate, Office, and Branch.\n' +
        'In this section you can manage user permissions.',
    },
  };
};
