import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { IBillingsData } from './interface';
import { getBillingsParamsSerializer, getBillingsSerializer } from './serializers';

export const billingAPI = createApi({
  reducerPath: 'billingAPI',
  baseQuery,
  tagTypes: ['Billing', 'Loads'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getBillings: builder.query({
      query: (filters: any) => {
        const queryParams = getBillingsParamsSerializer(filters);
        return {
          url: 'factoring/billings',
          params: queryParams,
        };
      },
      transformResponse: (body: IBillingsData) => getBillingsSerializer(body),
      providesTags: ['Billing', 'Loads'],
    }),
  }),
});

export const { useGetBillingsQuery } = billingAPI;
