import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  CITY_REQUIRED,
  FROM_REQUIRED,
  TO_REQUIRED,
  AVAILABLE_DATE_REQUIRED,
  EQUIPMENT_TYPE_REQUIRED,
  NOT_ZERO,
  LENGTH_REQUIRED,
  LENGTH_DIGITS,
} = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  city: yup.string().required(CITY_REQUIRED),
  from: yup.string().required(FROM_REQUIRED),
  to: yup.array().min(1, TO_REQUIRED),
  availableDate: yup.string().required(AVAILABLE_DATE_REQUIRED),
  equipmentTypeLocation: yup.string().required(EQUIPMENT_TYPE_REQUIRED),
  length: yup.string().test('isRequired', LENGTH_REQUIRED, function checkLength(value) {
    if (!value) {
      return false;
    } else if (value && String(value).length > 7) {
      return this.createError({
        message: LENGTH_DIGITS,
        path: 'length',
      });
    } else if (String(value).replace('.', '')! == '0') {
      return this.createError({
        message: NOT_ZERO,
        path: 'length',
      });
    } else {
      return true;
    }
  }),
});
