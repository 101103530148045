import { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import Table from 'components/table/Table';

import Blacklist from '../../components/blacklist/Blacklist';
import { SUBJECT } from '../../constants/constants';
import { ICarriersParams } from '../../constants/types';

import useBlacklistTable from './useBlacklistTable';

import styles from '../../Carriers.module.scss';

const BlacklistTable = ({ setExcelParams, pagePermissions: { lock, unlock } }: ICarriersParams) => {
  const {
    data,
    columns,
    isFetching,
    showBlacklist,
    carriersOptions,
    control,
    agentsOptions,
    handleResetBlacklist,
    setValue,
    handleSubmit,
    onSubmit,
    watch,
    handleBlacklistClick,
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handleColumnSearch,
    handleCarrierUnlockClick,
    handlePaginationClick,
    carriersFilter,
    officesFilter,
    agentsFilter,
    setCarriersFilter,
    setOfficesFilter,
    setAgentsFilter,
    setBranchFilter,
    skip,
    limit,
    finalOfficeOptions,
    finalBranchesOptions,
    selectedOffices,
    selectedBranches,
  } = useBlacklistTable({ setExcelParams });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading: isFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onColumnSearch: handleColumnSearch,
    onPaginationClick: handlePaginationClick,
    onActionUnderline: handleCarrierUnlockClick,
    hasUnlockPermission: unlock,
    withMenu: false,
  });
  return (
    <>
      <>
        <Row className={styles.filters} align="middle">
          {showBlacklist ? (
            <Col span={24}>
              <Blacklist
                carriersOptions={carriersOptions}
                officesOptions={finalOfficeOptions}
                control={control}
                agentsOptions={agentsOptions}
                handleResetBlacklist={handleResetBlacklist}
                setValue={setValue}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                watch={watch}
                branchesData={finalBranchesOptions}
                carriersFilter={carriersFilter}
                officesFilter={officesFilter}
                agentsFilter={agentsFilter}
                setCarriersFilter={setCarriersFilter}
                setOfficesFilter={setOfficesFilter}
                setAgentsFilter={setAgentsFilter}
                setBranchFilter={setBranchFilter}
                selectedOffices={selectedOffices}
                selectedBranches={selectedBranches}
              />
            </Col>
          ) : (
            <Col className={styles.addBlacklist}>
              {lock && (
                <Button
                  children={SUBJECT.ADD_BLACK_LIST}
                  className="dangerBtn"
                  variant="danger"
                  htmlType="submit"
                  onClick={handleBlacklistClick}
                />
              )}
            </Col>
          )}
        </Row>
        {!isFetching && !data && (
          <Typography children={SUBJECT.BLACK_LIST_IS_EMPTY} variant="paragraph" className={styles.carriersEmpty} />
        )}
        <Table {...tableProps} />
      </>
    </>
  );
};

export default BlacklistTable;
