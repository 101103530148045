export enum SUBJECT {
  CREDIT_REQUEST = 'Credit Request',
  LOAD_INFO = 'Load Info',
  LOAD_NUMBER = 'Load ID',
  OFFICE_NAME = 'Agency Name',
  BRANCH_NAME = 'Branch Name',
  REG_DATE_TIME = 'Reg. Date And Time',
  CUSTOMER_INFO = 'Customer Info',
  CUSTOMER_NAME = 'Customer Name',
  AGENT_INFO = 'Agent Info',
  AGENT_NAME = 'Agent Name',
  AGENT_PHONE = 'Agent Phone Number',
  MARK_AS_CLAIM = 'Mark As Claim',
  CANCEL_LOAD = 'Cancel Load',
  REQUEST_CHECK = 'REQUEST_CHECK',
}
