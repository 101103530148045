export const getColumnsSerializer = (body: any): any => {
  return {
    columns: JSON.parse(body.result.columns) || [],
    isDefault: body.result.isDefault,
  };
};

export const getDefaultColumnsSerializer = (body: any): any => {
  return {
    columns: JSON.parse(body.result.columns) || [],
  };
};
