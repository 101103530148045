export enum SUBJECT {
  EMAIL = 'E-Mail',
  RESET_PASSWORD = 'Reset Password',
  EMAIL_TITLE = 'Email Confirmation',
  TITLE = 'The Letter Has Been Sent',
  ASTERISKS = '******',
  CHANGE = 'Change',
  RECEIVE_EMAIL = 'Didn’t Receive An Email ',
  RESEND = 'Resend',
}

export enum VALIDATION_MESSAGES {
  EMAIL_IS_REQUIRED = 'Email is required',
  FORMAT = 'Sorry, we don`t  recognise this email address.',
  INVALID_EMAIL = 'Invalid email format',
}

export enum ERRORS {
  EMAIL = 'Wrong email or password. Try again.',
}
