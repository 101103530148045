import Button from 'ui/button/Button';
import { PERMISSION_MAIN_TABS } from 'utils/constants';

import TabPanel from 'components/tab-panel/TabPanel';

import { SUBJECT } from './constants/constants';
import usePermission from './usePermission';

const Permission = () => {
  const { tab, openMainDrawer, Component } = usePermission();

  return (
    <div>
      <TabPanel tabs={PERMISSION_MAIN_TABS}>
        <div className="tabPanelButtons">
          <Button
            onClick={() => openMainDrawer('add')}
            children={SUBJECT.ADD_PERMISSION_GROUP}
            className="panelAddButton"
          />
        </div>
      </TabPanel>

      <div>{Component[tab]}</div>
    </div>
  );
};
export default Permission;
