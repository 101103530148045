const ContactIcon = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.88574 22.158V20.059H6.89148V8.63098C6.89148 8.24227 7.02752 7.91187 7.29962 7.63977C7.57172 7.36768 7.90212 7.23163 8.29083 7.23163H22.7508V8.63098H8.29083V20.059H13.7483V22.158H4.88574ZM16.0572 22.158C15.7929 22.158 15.5752 22.0531 15.4042 21.8432C15.2332 21.6333 15.1476 21.3962 15.1476 21.1318V10.9399C15.1476 10.6756 15.2332 10.4579 15.4042 10.2869C15.5752 10.1158 15.7929 10.0303 16.0572 10.0303H22.4009C22.6963 10.0303 22.9607 10.112 23.1939 10.2752C23.4271 10.4385 23.5437 10.66 23.5437 10.9399V21.1318C23.5437 21.4272 23.431 21.6721 23.2056 21.8665C22.9801 22.0608 22.7119 22.158 22.4009 22.158H16.0572ZM16.547 20.059H22.1444V11.4297H16.547V20.059Z"
        fill="#252733"
      />
    </svg>
  );
};

export default ContactIcon;
