import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import CustomDropdown from 'ui/inputs/select/components/dropdown-with-custom-input/CustomDropdown';
import Select from 'ui/inputs/select/Select';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';

import { BlueInfoIcon } from 'components/svgs/BlueInfoIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import { TrashIcon } from 'components/svgs/TrashIcon';

import { EMAIL_TYPE_OPTIONS, LEGIBLE_SELECT_OPTIONS, LOAD_DOCUMENT_OPTIONS, SUBJECT } from './constants/constants';
import { IUploadRowProps } from './constants/types';

import styles from './UploadRow.module.scss';

const UploadRow = ({
  files,
  onDeleteClick,
  onEditFile,
  onWatchClick,
  onDownloadClick,
  isDisabled = false,
}: IUploadRowProps) => {
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const { view: _viewLoad, download: _downloadLoad, ...loadsPermissions } = usePermittedActions('loads.loads');
  const {
    view: _viewFactoring,
    download: _downloadFactoring,
    ...billingPermissions
  } = usePermittedActions('factoring.billing');

  const loadsPagePermissions = {
    ...loadsPermissions,
    ...billingPermissions,
  };

  const { reject } = loadsPagePermissions;

  const factoringPermissions = usePermittedActions('factoring.billing');
  const { reject: rejectInBilling } = factoringPermissions;

  const [rowsData, setRowsData] = useState<any>({});

  const legibleCheck =
    (EmployeeLevels.CORPORATE === employeeLevel && (reject || rejectInBilling)) || UserTypes.ADMIN === userType;

  useEffect(() => {
    files.map(file => {
      setRowsData((prev: any) => ({
        ...prev,
        [file.id]: {
          type: file?.type === 'Pod' ? 'POD' : file?.type || '',
          legible: file?.legible || '',
          mailable: file?.mailable || '0',
          fileName: file?.fileName,
          addedBy: file?.fullName,
          createdAt: file?.createdAt,
          preview: file?.preview,
          download: file?.download,
        },
      }));
    });
  }, [files]);

  if (!Object.keys(rowsData).length) {
    return null;
  }

  return (
    <>
      {files.map((file, index) => {
        return (
          <React.Fragment key={file.id}>
            <Row
              className={clsx(styles.uploadedList, {
                [styles.blurred]: file?.blurred,
              })}
              gutter={15}
            >
              <Col span={6}>
                <CustomDropdown
                  options={LOAD_DOCUMENT_OPTIONS}
                  name={`${file.id}type`}
                  value={rowsData[file.id]?.type}
                  disabled={!loadsPagePermissions.uploadsFiles}
                  onChange={(value: string) =>
                    onEditFile({
                      id: file.id,
                      type: value,
                      readable: rowsData[file.id]?.legible,
                      mailable: rowsData[file.id]?.mailable,
                    })
                  }
                />
              </Col>
              <Col span={6}>
                <Select
                  options={LEGIBLE_SELECT_OPTIONS}
                  placeholder={SUBJECT.LEGIBLE}
                  name={`${file.id}legible`}
                  onChange={(value: string | string[]) =>
                    onEditFile({
                      id: file.id,
                      readable: value,
                      type: rowsData[file.id]?.type,
                      mailable: rowsData[file.id]?.mailable,
                    })
                  }
                  value={rowsData[file.id]?.legible}
                  isDisabled={!legibleCheck || !loadsPagePermissions.uploadsFiles}
                />
              </Col>
              <Col span={5}>
                {rowsData[file.id]?.type !== LOAD_DOCUMENT_OPTIONS[4].value && (
                  <Select
                    options={EMAIL_TYPE_OPTIONS}
                    placeholder={SUBJECT.EMAIL}
                    name={`${file.id}mailable`}
                    isDisabled={!loadsPagePermissions.uploadsFiles}
                    onChange={(value: string | string[]) =>
                      onEditFile({
                        id: file.id,
                        type: rowsData[file.id]?.type,
                        readable: rowsData[file.id]?.legible,
                        mailable: value,
                      })
                    }
                    value={rowsData[file.id]?.mailable || '0'}
                  />
                )}
              </Col>
              <Col span={2}>
                <Tooltip
                  placement="top"
                  color="white"
                  overlayInnerStyle={{
                    width: 400,
                  }}
                  key={file.id}
                  title={
                    <Row gutter={[10, 10]} className={styles.infoBlock}>
                      <Col span={24}>
                        <Row>
                          <Col span={12} className={styles.leftColumn}>
                            {SUBJECT.FILE_NAME}
                          </Col>
                          <Col span={12} className={styles.rightColumn}>
                            {rowsData[file.id]?.fileName}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={12} className={styles.leftColumn}>
                            {SUBJECT.ADDED_BY}
                          </Col>
                          <Col span={12} className={styles.rightColumn}>
                            {rowsData[file.id]?.addedBy}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={12} className={styles.leftColumn}>
                            {SUBJECT.DATE_AND_TIME}
                          </Col>
                          <Col span={12} className={styles.rightColumn}>
                            {formatDate(rowsData[file.id]?.createdAt)}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                >
                  <span>
                    <BlueInfoIcon />
                  </span>
                </Tooltip>
              </Col>
              <Col span={5}>
                <div className={styles.uploadedButtons}>
                  <button
                    type="button"
                    className={clsx(styles.uploadControl, styles.eyeIcon)}
                    onClick={() => onWatchClick(file?.preview)}
                  >
                    <EyeIconSvg />
                  </button>
                  <button
                    type="button"
                    className={clsx(styles.uploadControl, styles.downloadBtn)}
                    onClick={() => onDownloadClick(file?.download)}
                  >
                    <DownloadIcon />
                  </button>

                  <Tooltip
                    zIndex={isDisabled ? 9999 : 0}
                    title={SUBJECT.PROCESSING_RULE}
                    placement="top"
                    color="#fff"
                    key={file.id}
                  >
                    <button
                      type="button"
                      className={styles.uploadControl}
                      disabled={isDisabled}
                      onClick={() => onDeleteClick(file.id)}
                    >
                      <TrashIcon />
                    </button>
                  </Tooltip>
                </div>
              </Col>
            </Row>
            {files?.length - 1 !== index && <Divider />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default UploadRow;
