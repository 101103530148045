import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import { MainLoader } from 'ui/main-loader/MainLoader';
import { floatNumberFormatter, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import { PercentIcon } from 'components/svgs/PercentIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { IDistributeValues } from '../../constants/types';
import { SUBJECT } from '../constants/constants';
import { IDistributeProps } from '../constants/types';
import { Input } from '../hoc-instance/instance';

import { Chargeback } from './chargeback/Chargeback';
import { handleLimitError } from './constants/helper';

import styles from './Distribute.module.scss';

export const Distribute = ({
  data,
  card,
  errors,
  isOpen,
  control,
  activeRow,
  isTouched,
  mainAmount,
  isFetching,
  isSubmitted,
  remainingSum,
  validationCheck,
  distributeBtnCheck,
  hasDistributePermission,
  watch,
  handleOpen,
  handleReset,
  handleDistributeChange,
}: IDistributeProps) => {
  const columns = Object.keys(watch());

  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetching} />
      <ViewItemWrapper title={SUBJECT.DISTRIBUTE}>
        <form>
          <Chargeback card={card} data={data!} columns={columns} />
          {!isOpen && (
            <div className={styles.buttonsWrapper}>
              <Row justify="end">
                <Tooltip
                  placement="top"
                  color="#fff"
                  title={
                    hasDistributePermission
                      ? SUBJECT.DONT_HAVE_PERMISSION
                      : distributeBtnCheck
                      ? SUBJECT.EXPIRATION_ERROR
                      : ''
                  }
                >
                  <span>
                    <Button
                      size="large"
                      children={SUBJECT.DISTRIBUTE}
                      disabled={hasDistributePermission || distributeBtnCheck}
                      onClick={() => handleOpen(true)}
                    />
                  </span>
                </Tooltip>
              </Row>
            </div>
          )}
          {isOpen && (
            <div className={styles.infoBlock}>
              <>
                {columns?.includes('corporate') && (
                  <Row gutter={[30, 0]} align="top">
                    <Col span={6}>
                      <Input
                        control={control}
                        name="corporate.sum"
                        getValues={watch}
                        placeholder={SUBJECT.CORPORATE}
                        suffix={<DollarIcon />}
                        onChange={({ target: { value } }) => handleDistributeChange('corporate.sum', value)}
                        errors={{
                          'corporate.sum':
                            errors?.corporate?.sum ||
                            handleLimitError(activeRow, 'corporate', 'sum', remainingSum, mainAmount!),
                        }}
                        disabled={!watch('corporate.sum') && remainingSum === mainAmount}
                        value={
                          watch('corporate.sum')
                            ? formatNumberWithThousandsSeparatorAndDecimal(watch('corporate.sum'))
                            : ''
                        }
                      />
                      <ErrorMessage fieldError={errors?.corporate?.sum} />
                    </Col>
                    <Col span={6}>
                      <Input
                        control={control}
                        name="corporate.percent"
                        getValues={watch}
                        placeholder={SUBJECT.CORPORATE}
                        suffix={<PercentIcon />}
                        onChange={({ target: { value } }) => handleDistributeChange('corporate.percent', value)}
                        value={
                          watch('corporate.percent') ? floatNumberFormatter(String(watch('corporate.percent'))) : ''
                        }
                        errors={{
                          'corporate.percent':
                            errors?.corporate?.percent ||
                            handleLimitError(activeRow, 'corporate', 'percent', remainingSum, mainAmount!),
                        }}
                        disabled={!watch('corporate.percent') && remainingSum === mainAmount}
                      />
                      <ErrorMessage fieldError={errors?.corporate?.percent} />
                    </Col>
                    {activeRow === SUBJECT.CORPORATE.toLowerCase() &&
                      !errors[activeRow! as keyof IDistributeValues] &&
                      !(remainingSum > mainAmount!) && (
                        <Col>
                          <span className={styles.sumNote}>
                            Left{' '}
                            <span
                              className={clsx({
                                [styles.remainingSum]: remainingSum > 0,
                                [styles.negative]: Number(card) === 5,
                              })}
                            >
                              {formatNumberWithThousandsSeparatorAndDecimal(
                                floatNumberFormatter(String(mainAmount! - remainingSum)),
                                true
                              )}
                            </span>{' '}
                            out of{' '}
                            {formatNumberWithThousandsSeparatorAndDecimal(
                              floatNumberFormatter(String(mainAmount!)),
                              true
                            )}
                          </span>
                        </Col>
                      )}
                    {activeRow === SUBJECT.CORPORATE.toLowerCase() && remainingSum > mainAmount! && (
                      <Col>
                        <span className={styles.sumNoteError}>{`${
                          SUBJECT.ERROR_MESSAGE
                        } ${formatNumberWithThousandsSeparatorAndDecimal(
                          floatNumberFormatter(String(mainAmount)),
                          true
                        )}`}</span>
                      </Col>
                    )}
                  </Row>
                )}
                {columns?.includes('agency') && (
                  <Row gutter={[30, 0]} align="top">
                    <Col span={6}>
                      <Input
                        control={control}
                        name="agency.sum"
                        getValues={watch}
                        placeholder={SUBJECT.OFFICE}
                        suffix={<DollarIcon />}
                        onChange={({ target: { value } }) => handleDistributeChange('agency.sum', value)}
                        errors={{
                          'agency.sum':
                            errors?.agency?.sum ||
                            handleLimitError(activeRow, 'agency', 'sum', remainingSum, mainAmount!),
                        }}
                        disabled={!watch('agency.sum') && remainingSum === mainAmount}
                        value={
                          watch('agency.sum') ? formatNumberWithThousandsSeparatorAndDecimal(watch('agency.sum')) : ''
                        }
                      />
                      <ErrorMessage fieldError={errors?.agency?.sum} />
                    </Col>
                    <Col span={6}>
                      <Input
                        control={control}
                        name="agency.percent"
                        getValues={watch}
                        placeholder={SUBJECT.OFFICE}
                        suffix={<PercentIcon />}
                        onChange={({ target: { value } }) => handleDistributeChange('agency.percent', value)}
                        errors={{
                          'agency.percent':
                            errors?.agency?.percent ||
                            handleLimitError(activeRow, 'agency', 'percent', remainingSum, mainAmount!),
                        }}
                        disabled={!watch('agency.percent') && remainingSum === mainAmount}
                        value={
                          watch('agency.percent')
                            ? formatNumberWithThousandsSeparatorAndDecimal(watch('agency.percent'))
                            : ''
                        }
                      />
                      <ErrorMessage fieldError={errors?.agency?.percent} />
                    </Col>
                    {activeRow === SUBJECT.OFFICE.toLowerCase() &&
                      !errors[activeRow! as keyof IDistributeValues] &&
                      !(remainingSum > mainAmount!) && (
                        <Col>
                          <span className={styles.sumNote}>
                            Left{' '}
                            <span
                              className={clsx({
                                [styles.remainingSum]: remainingSum > 0,
                                [styles.negative]: Number(card) === 5,
                              })}
                            >
                              {formatNumberWithThousandsSeparatorAndDecimal(
                                floatNumberFormatter(String(mainAmount! - remainingSum)),
                                true
                              )}
                            </span>{' '}
                            out of{' '}
                            {formatNumberWithThousandsSeparatorAndDecimal(
                              floatNumberFormatter(String(mainAmount!)),
                              true
                            )}
                          </span>
                        </Col>
                      )}
                    {activeRow === SUBJECT.OFFICE.toLowerCase() && remainingSum > mainAmount! && (
                      <Col>
                        <span className={styles.sumNoteError}>{`${
                          SUBJECT.ERROR_MESSAGE
                        } ${formatNumberWithThousandsSeparatorAndDecimal(
                          floatNumberFormatter(String(mainAmount)),
                          true
                        )}`}</span>
                      </Col>
                    )}
                  </Row>
                )}
                {columns?.includes('branch') && (
                  <Row gutter={[30, 0]} align="top">
                    <Col span={6}>
                      <Input
                        control={control}
                        name="branch.sum"
                        getValues={watch}
                        placeholder={SUBJECT.BRANCH}
                        suffix={<DollarIcon />}
                        onChange={({ target: { value } }) => handleDistributeChange('branch.sum', value)}
                        errors={{
                          'branch.sum':
                            errors?.branch?.sum ||
                            handleLimitError(activeRow, 'branch', 'sum', remainingSum, mainAmount!),
                        }}
                        disabled={!watch('branch.sum') && remainingSum === mainAmount}
                        value={
                          watch('branch.sum') ? formatNumberWithThousandsSeparatorAndDecimal(watch('branch.sum')) : ''
                        }
                      />
                      <ErrorMessage fieldError={errors?.branch?.sum} />
                    </Col>
                    <Col span={6}>
                      <Input
                        control={control}
                        name="branch.percent"
                        getValues={watch}
                        placeholder={SUBJECT.BRANCH}
                        suffix={<PercentIcon />}
                        onChange={({ target: { value } }) => handleDistributeChange('branch.percent', value)}
                        errors={{
                          'branch.percent':
                            errors?.branch?.percent ||
                            handleLimitError(activeRow, 'branch', 'percent', remainingSum, mainAmount!),
                        }}
                        disabled={!watch('branch.percent') && remainingSum === mainAmount}
                        value={
                          watch('branch.percent')
                            ? formatNumberWithThousandsSeparatorAndDecimal(watch('branch.percent'))
                            : ''
                        }
                      />
                      <ErrorMessage fieldError={errors?.branch?.percent} />
                    </Col>
                    {activeRow === SUBJECT.BRANCH.toLowerCase() &&
                      !errors[activeRow! as keyof IDistributeValues] &&
                      !(remainingSum > mainAmount!) && (
                        <Col>
                          <span className={styles.sumNote}>
                            Left{' '}
                            <span
                              className={clsx({
                                [styles.remainingSum]: remainingSum > 0,
                                [styles.negative]: Number(card) === 5,
                              })}
                            >
                              {formatNumberWithThousandsSeparatorAndDecimal(
                                floatNumberFormatter(String(mainAmount! - remainingSum)),
                                true
                              )}
                            </span>{' '}
                            out of{' '}
                            {formatNumberWithThousandsSeparatorAndDecimal(
                              floatNumberFormatter(String(mainAmount!)),
                              true
                            )}
                          </span>
                        </Col>
                      )}
                    {activeRow === SUBJECT.BRANCH.toLowerCase() && remainingSum > mainAmount! && (
                      <Col>
                        <span className={styles.sumNoteError}>{`${
                          SUBJECT.ERROR_MESSAGE
                        } ${formatNumberWithThousandsSeparatorAndDecimal(
                          floatNumberFormatter(String(mainAmount)),
                          true
                        )}`}</span>
                      </Col>
                    )}
                  </Row>
                )}
                <Row gutter={[30, 0]} align="top">
                  <Col span={6}>
                    <Input
                      control={control}
                      name="agent.sum"
                      getValues={watch}
                      placeholder={SUBJECT.AGENT}
                      suffix={<DollarIcon />}
                      onChange={({ target: { value } }) => handleDistributeChange('agent.sum', value)}
                      errors={{
                        'agent.sum':
                          errors?.agent?.sum || handleLimitError(activeRow, 'agent', 'sum', remainingSum, mainAmount!),
                      }}
                      disabled={!watch('agent.sum') && remainingSum === mainAmount}
                      value={watch('agent.sum') ? formatNumberWithThousandsSeparatorAndDecimal(watch('agent.sum')) : ''}
                    />
                    <ErrorMessage fieldError={errors?.agent?.sum} />
                  </Col>
                  <Col span={6}>
                    <Input
                      control={control}
                      name="agent.percent"
                      getValues={watch}
                      placeholder={SUBJECT.AGENT}
                      suffix={<PercentIcon />}
                      onChange={({ target: { value } }) => handleDistributeChange('agent.percent', value)}
                      errors={{
                        'agent.percent':
                          errors?.agent?.percent ||
                          handleLimitError(activeRow, 'agent', 'percent', remainingSum, mainAmount!),
                      }}
                      disabled={!watch('agent.percent') && remainingSum === mainAmount}
                      value={
                        watch('agent.percent')
                          ? formatNumberWithThousandsSeparatorAndDecimal(watch('agent.percent'))
                          : ''
                      }
                    />
                    <ErrorMessage fieldError={errors?.agent?.percent} />
                  </Col>
                  {activeRow === SUBJECT.AGENT.toLowerCase() &&
                    !errors[activeRow! as keyof IDistributeValues] &&
                    !(remainingSum > mainAmount!) && (
                      <Col>
                        <span className={styles.sumNote}>
                          Left{' '}
                          <span
                            className={clsx({
                              [styles.remainingSum]: remainingSum > 0,
                              [styles.negative]: Number(card) === 5,
                            })}
                          >
                            {formatNumberWithThousandsSeparatorAndDecimal(
                              floatNumberFormatter(String(mainAmount! - remainingSum)),
                              true
                            )}
                          </span>{' '}
                          out of{' '}
                          {formatNumberWithThousandsSeparatorAndDecimal(
                            floatNumberFormatter(String(mainAmount!)),
                            true
                          )}
                        </span>
                      </Col>
                    )}
                  {remainingSum > mainAmount! && activeRow === SUBJECT.AGENT.toLowerCase() && (
                    <Col>
                      <span className={styles.sumNoteError}>{`${
                        SUBJECT.ERROR_MESSAGE
                      } ${formatNumberWithThousandsSeparatorAndDecimal(
                        floatNumberFormatter(String(mainAmount)),
                        true
                      )}`}</span>
                    </Col>
                  )}
                </Row>
              </>

              <div className={styles.buttonsWrapper}>
                <Row justify="space-between" align="middle">
                  <ErrorMessage
                    fieldError={{
                      message: !validationCheck && isSubmitted ? SUBJECT.DISTRIBUTE_ERROR : '',
                    }}
                  />
                  <Row justify="end" align="middle" gutter={28}>
                    <Col>
                      <Button children={SUBJECT.CANCEL} variant="outlined" onClick={() => handleOpen(false)} />
                    </Col>
                    {isTouched && (
                      <Col>
                        <Button
                          icon={<ResetIcon />}
                          variant="ghost"
                          children={SUBJECT.RESET_ALL}
                          onClick={handleReset}
                        />
                      </Col>
                    )}
                  </Row>
                </Row>
              </div>
            </div>
          )}
        </form>
      </ViewItemWrapper>
    </div>
  );
};
