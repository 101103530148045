import { IContactByID, IContactTable } from './interfaces';

const usersLevels: { [key: string]: string } = {
  1: 'Corporate',
  2: 'Agency',
  3: 'Branch',
};

export const userSinglelevel = (data: IContactByID) => {
  return {
    ...data,
    level: usersLevels[data?.level],
  };
};

export const usersTableLevels = (data: IContactTable) => {
  return {
    count: data.count,
    result: data.result.map((item: IContactByID) => ({
      ...item,
      firstname: item.firstName,
      lastname: item.lastName,
      level: usersLevels[item.level],
      id: Number(item.userId),
    })),
  };
};
