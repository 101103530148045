import React from 'react';
import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { SUBJECT } from 'pages/profile/tabs/customers/constants/constants';
import { BillingTypes, CustomerType } from 'services/profile/customers/interfaces';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';
import { formatPhone } from 'utils/helpers';

import CustomComment from 'components/custom-comment/CustomComment';
import CopyIcon from 'components/svgs/CopyIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { ICustomersViews } from './constants/types';
import { useCustomersView } from './useCustomersView';

import styles from './CustomersView.module.scss';

const CustomersViews = ({
  customerById,
  handleCommentClick,
  isFetchingById,
  customerComments,
  getMoreComments,
  isShowMoreVisible,
  pagePermissions: { edit, terminateOn, terminateOff, deleteComment },
  paymentTerms,
  setGetPaymentTerms,
  isCustomerCommentsLoading,
}: ICustomersViews) => {
  const { handleChecked, handleChangeMode, userType, employeeLevel, control, watch, setValue, handlePaginationClick } =
    useCustomersView({
      setGetPaymentTerms,
    });
  const { copiedText, handleCopy } = useHandleCopyActive();
  const checkedValue = !customerById?.terminatedAt;

  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetchingById} />

      <ViewItemWrapper
        title={SUBJECT.CUSTOMER_INFO}
        Component={edit && <Button children={SUBJECT.EDIT} onClick={handleChangeMode} className="viewDrawerEditBtn" />}
      >
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.NAME}:{' '}
              </Typography>
              <TooltipText
                prefixClass={'top-banner-value'}
                data={customerById?.name}
                subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.NAME}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.PHONE_NUMBER}:{' '}
              </Typography>
              <div className={styles.textWithLinks}>
                <a className="top-banner-value" href={`tel:${customerById?.phone}`}>
                  {formatPhone(customerById?.phone)}
                </a>
              </div>
            </div>
          </Col>
          {customerById?.fax && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.FAX}:{' '}
                </Typography>
                <TooltipText
                  prefixClass={'top-banner-value'}
                  data={customerById?.fax}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.FAX}`}
                />
              </div>
            </Col>
          )}
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.TYPE}:{' '}
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {CustomerType[customerById?.type]}
              </Typography>
            </div>
          </Col>
          {customerById?.ff && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.FF_NUMBER}:{' '}
                </Typography>
                <TooltipText
                  prefixClass={'top-banner-value'}
                  data={customerById?.ff}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.FF_NUMBER}`}
                />
              </div>
            </Col>
          )}
          {customerById?.id && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.CUSTOMER_ID}:{' '}
                </Typography>
                <Col span={13}>
                  <div
                    className={clsx(styles.copyIconText, {
                      [styles.copyActive]: copiedText === 'topBannerCustomerById',
                    })}
                  >
                    <Typography variant="paragraph" children={customerById?.id} className={styles.infoParagraph} />
                    <div
                      className={styles.copyIcon}
                      role="button"
                      onClick={() => handleCopy(customerById?.id, 'topBannerCustomerById')}
                    >
                      <CopyIcon active={copiedText === 'topBannerCustomerById'} />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
          )}
          {customerById?.dot && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.DOT}:{' '}
                </Typography>
                <TooltipText
                  prefixClass={'top-banner-value'}
                  data={customerById?.dot}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.DOT}`}
                />
              </div>
            </Col>
          )}
          {customerById?.mc && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.MC_NUMBER}:{' '}
                </Typography>
                <TooltipText
                  prefixClass={'top-banner-value'}
                  data={customerById?.mc}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.MC_NUMBER}`}
                />
              </div>
            </Col>
          )}

          {customerById?.stateNumber && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.STATE_NUMBER}:{' '}
                </Typography>
                <TooltipText
                  prefixClass={'top-banner-value'}
                  data={customerById?.stateNumber}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.STATE_NUMBER}`}
                />
              </div>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>

      <ViewItemWrapper title={SUBJECT.FULL_INFO}>
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          {(UserTypes.ADMIN === userType || EmployeeLevels.CORPORATE === employeeLevel) && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={formatDate(customerById?.createdAt)}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CUSTOMER_ID} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === 'customerById',
                  })}
                >
                  <Typography variant="paragraph" children={customerById?.id} className={styles.infoParagraph} />
                  <div
                    className={styles.copyIcon}
                    role="button"
                    onClick={() => handleCopy(customerById?.id, 'customerById')}
                  >
                    <CopyIcon active={copiedText === 'customerById'} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {customerById?.mc && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.MC_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={customerById?.mc}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.MC_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {customerById?.ff && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FF_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={customerById?.ff}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.FF_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {customerById?.dot && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.DOT} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={customerById?.dot}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.DOT}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {customerById?.stateNumber && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.STATE_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={customerById?.stateNumber}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.STATE_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={customerById?.name}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.TYPE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={CustomerType[customerById?.type]}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <a className="top-banner-value" href={`tel:${customerById?.phone}`}>
                  {formatPhone(customerById?.phone)}
                </a>
              </Col>
            </Row>
          </Col>
          {customerById?.fax && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FAX} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={customerById?.fax}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.FAX}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={customerById?.address}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.ADDRESS}`}
                />
              </Col>
            </Row>
          </Col>
          {customerById?.secondAddress && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.SECOND_ADDRESS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={customerById?.secondAddress}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={customerById?.city}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.CITY}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={customerById?.state?.name} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ZIPCODE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={customerById?.zip} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.COMMENT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  onClick={() => handleCommentClick(customerById?.id)}
                  variant="paragraph"
                  children={`${SUBJECT.COMMENT} ${customerById?.commentsCount}`}
                  className={styles.commentText}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Switch
                  checked={checkedValue || false}
                  onChange={handleChecked}
                  disabled={(checkedValue && !terminateOff) || (!checkedValue && !terminateOn)}
                />
              </Col>
            </Row>
          </Col>
          {customerById?.creator.username && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={`${customerById?.creator.username} `}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>

      {(UserTypes.ADMIN === userType || EmployeeLevels.CORPORATE === employeeLevel) &&
        !!(
          customerById?.receivablesContactName ||
          customerById?.receivablesPhone ||
          customerById?.receivablesEmail
        ) && (
          <ViewItemWrapper title={SUBJECT.RECEIVABLES_CONTACT}>
            <Row justify="space-between" align="top" gutter={[10, 30]}>
              {!!customerById?.receivablesContactName && (
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CONTACT_NAME} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={customerById?.receivablesContactName}
                        subject={`${SUBJECT.RECEIVABLES_CONTACT}${SUBJECT.CONTACT_NAME}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {!!customerById?.receivablesPhone && (
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <a className="top-banner-value" href={`tel:${customerById?.receivablesPhone}`}>
                        {formatPhone(customerById?.receivablesPhone)}
                      </a>
                    </Col>
                  </Row>
                </Col>
              )}
              {!!customerById?.receivablesEmail && (
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={customerById?.receivablesEmail}
                        subject={`${SUBJECT.RECEIVABLES_CONTACT}${SUBJECT.EMAIL}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </ViewItemWrapper>
        )}

      {(UserTypes.ADMIN === userType || EmployeeLevels.CORPORATE === employeeLevel) &&
        !!(customerById?.billingContactName || customerById?.billingPhone || customerById?.billingEmail) && (
          <ViewItemWrapper title={SUBJECT.BILLING_CONTACT}>
            <Row justify="space-between" align="top" gutter={[10, 30]}>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.CONTACT_NAME} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={customerById?.billingContactName}
                      subject={`${SUBJECT.BILLING_CONTACT}${SUBJECT.CONTACT_NAME}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <a className="top-banner-value" href={`tel:${customerById?.billingPhone}`}>
                      {formatPhone(customerById?.billingPhone)}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={customerById?.billingEmail}
                      subject={`${SUBJECT.BILLING_CONTACT}${SUBJECT.EMAIL}`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ViewItemWrapper>
        )}

      {(UserTypes.ADMIN === userType || EmployeeLevels.CORPORATE === employeeLevel) &&
        !!paymentTerms?.result?.length && (
          <ViewItemWrapper title={SUBJECT.PAYMENT_TERMS}>
            <Row justify="space-between" align="top" gutter={[10, 30]}>
              {customerById?.paymentTermsInfo?.map((item: any) => {
                return (
                  <Col span={8}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography
                          variant="heading"
                          children={BillingTypes[item?.type]}
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col span={13}>
                        <Typography variant="paragraph" children={item?.count} className={styles.infoParagraph} />
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
            <Divider />
            <DataGrid
              data={paymentTerms?.result}
              count={paymentTerms?.count}
              type="mcView"
              handlePaginationClick={handlePaginationClick}
            />
          </ViewItemWrapper>
        )}

      {!!customerComments?.count && (
        <CustomComment
          id="comment"
          watch={watch}
          name="comment"
          setValue={setValue}
          control={control}
          comments={customerComments}
          canCreate={false}
          canDelete={false}
          inView
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          hasDeleteCommentPermission={deleteComment}
          isLoadsCommentsLoading={isCustomerCommentsLoading}
        />
      )}
    </div>
  );
};

export default CustomersViews;
