import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import SearchableSelectForMultipleAuthorities from 'ui/inputs/searchable-select/searchable-select-for-multiple-authorities/SearchableSelectForMultipleAuthorities';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';

import { ISearchableDropdownProps } from '../../../../../ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from '../../../../../ui/searchable-dropdown/SearchableDropdown';
import { IAddBrackedFormData, IBranchFilter, IBranchFormData } from '../constants/types';

export const Input = withController<IInputProps, IBranchFormData>(CustomInput);
export const SearchableSelect = withController<ISearchableSelectProps, IBranchFormData>(
  SearchableSelectForMultipleAuthorities
);
export const SearchableDropdown = withController<ISearchableDropdownProps, IBranchFormData>(CustomSearchableDropdown);
export const BrackedInput = withController<IInputProps, IAddBrackedFormData>(CustomInput);
export const FilterSelect = withController<ISelectProps, IBranchFilter>(ReusableSelect);
export const SearchableSelectForFilter = withController<ISearchableSelectProps, IBranchFilter>(CustomSearchableSelect);
