import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import {
  useCreateContactMutation,
  useDeleteContactMutation,
  useGetContactQuery,
  useGetContactsQuery,
  useGetUsersQuery,
  useUpdateContactMutation,
} from 'services/contact/contact';
import { IGetContactsParams } from 'services/contact/interfaces';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatPhone } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { selectUserPermissionsInfo } from '../../../../store/user-slice/selector';

import ContactView from './components/contact-view/ContactView';
import { defaultValues } from './components/create-edit/constants/constants';
import { IContactCreateEditData } from './components/create-edit/constants/types';
import ContactCreateEdit from './components/create-edit/ContactCreateEdit';
import { filterDefaultValues, SUBJECT } from './constants/constants';
import { IContactListTypeFilter } from './constants/types';
import { validation } from './constants/validation';

const useContactList = (setExcelParams: any) => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, contactListFilter } = searchParams;

  const {
    permissionsInfo: { roleLevel },
  } = useSelector(selectUserPermissionsInfo);

  const id = searchParams.id;
  const agentsSkip = mode === 'view' || mode === 'customize';

  const { data: columnsData } = useGetColumnsQuery({ type: 'contact' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [contactFilters, setContactFilters] = useState<IGetContactsParams>({
    skip: 0,
    limit: 20,
    level: 0,
    search: '',
    field: '',
  });
  const [usersListFilters, setUsersListFilters] = useState<Partial<IGetContactsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });
  const { data, isFetching: isLoading } = useGetContactsQuery(contactFilters);

  useEffect(() => {
    if (!data?.result.length && contactFilters?.skip > 0) {
      setContactFilters(prev => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [data?.result.length]);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...contactFilters,
        };
      });
    }
  }, [contactFilters]);

  const { data: usersList } = useGetUsersQuery(usersListFilters, { skip: agentsSkip });
  const [createContact, { isLoading: isCreateLoading }] = useCreateContactMutation();
  const { currentData: userData, isFetching: isFethingById } = useGetContactQuery(id, { skip: !id });
  const [deletedUser] = useDeleteContactMutation();
  const [updateUser, { isLoading: isEditLoading }] = useUpdateContactMutation();
  const {
    reset: resetFilterForm,
    control: filterControl,
    watch: watchFilter,
    setValue: setFilterValue,
  } = useForm<IContactListTypeFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onBlur',
  });

  const contactList = watchFilter('contactListType');

  const contactListQueryCheck = !!(typeof contactList === 'string' && contactList);
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    getValues,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm<IContactCreateEditData>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const contactPagePermissions = usePermittedActions('contact.contact');

  const resetFilter = () => {
    resetFilterForm();
    setSearchParams({
      ...searchParams,
      contactListFilter: '',
    });
  };
  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    reset(defaultValues, { keepDirty: false });
    clearErrors();
  };

  const handleDeleteOk = (id: number) => {
    deletedUser(id).then((response: any) => {
      if (response?.error) {
        notification.error({
          message: ServerErrorCodes[Number(response?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_DELETED,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
      handleCloseModal();
      resetForm();
    });
  };

  const onSubmit: SubmitHandler<IContactCreateEditData> = data => {
    if (mode === 'edit') {
      updateUser({ ...data, id: Number(userData?.id) }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: SUBJECT.SUCCESSFULLY_UPDATED,
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    } else {
      createContact(data).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: SUBJECT.SUCCESSFULLY_CREATED,
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    }
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(contactListQueryCheck ? { contactListFilter: contactList } : {}),
      open: 'false',
      id: '',
    });
    dispatch(closeModal());
    reset(defaultValues);
    clearErrors();
  };

  const handleCancel = () => {
    if (isDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: isValid
            ? () => {
                onSubmit(watch());
                dispatch(closeModal());
                clearErrors();
              }
            : () => {
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(contactListQueryCheck ? { contactListFilter: contactList } : {}),
        open: 'false',
      });
      resetForm();
    }
  };

  const handleDeleteModal = (id: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => handleDeleteOk(id),
        onCancel: handleCloseModal,
      })
    );
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(contactListQueryCheck ? { contactListFilter: contactList } : {}),
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleRowEdit = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(contactListQueryCheck ? { contactListFilter: contactList } : {}),
      mode: 'edit',
      open: 'true',
      id: rowId,
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setContactFilters((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setContactFilters(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({ setDragColumns, deleteColumns, editColumns, handleCloseModal, dragColumns, type: 'contact' });

  const handleStopResize = (columns: any): void => {
    editColumns({ type: 'contact', columns });
  };

  useEffect(() => {
    if (columnsData?.columns?.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: contactPagePermissions.create
      ? {
          component: (
            <ContactCreateEdit
              control={control}
              errors={errors}
              getValues={getValues}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              setValue={setValue}
              watch={watch}
              usersList={usersList}
              mode={mode!}
              setOffset={setUsersListFilters}
              isCreateLoading={isCreateLoading}
              isEditLoading={isEditLoading}
            />
          ),
          buttonText: SUBJECT.SAVE,
          onSubmit: handleSubmit(onSubmit),
          onCancel: handleCancel,
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit: contactPagePermissions.edit
      ? {
          component: (
            <ContactCreateEdit
              control={control}
              errors={errors}
              getValues={getValues}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              setValue={setValue}
              watch={watch}
              usersList={usersList}
              mode={mode!}
              setOffset={setUsersListFilters}
              isFetchingById={isFethingById}
              isCreateLoading={isCreateLoading}
              isEditLoading={isEditLoading}
            />
          ),
          buttonText: SUBJECT.EDIT_BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    view: {
      component: (
        <ContactView
          pagePermissions={contactPagePermissions}
          data={userData}
          handleDeleteModal={handleDeleteModal}
          isFethingById={isFethingById}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode!]?.component,
      buttonText: drawerChildren[mode!]?.buttonText,
      onCancel: drawerChildren[mode!]?.onCancel,
      onSubmit: drawerChildren[mode!]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    isDirty,
    watch(),
    updateUser,
    setDragColumns,
    dragColumns,
    isCreateLoading,
    isEditLoading,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(contactListQueryCheck ? { contactListFilter: contactList } : {}),
    });
  }, [contactList]);

  useEffect(() => {
    const filterOptions = [{ key: 'contactListType', value: contactListFilter }];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setFilterValue(key as keyof IContactListTypeFilter, value);
      }
    });

    setContactFilters(prev => ({
      ...prev,
      skip: 0,
      limit: prev.limit,
      level: contactListFilter ? +contactListFilter : 0,
    }));
  }, [contactListFilter]);

  useEffect(() => {
    if (data?.result?.length) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result?.length, data]);

  useEffect(() => {
    if (mode === 'edit') {
      if (userData) {
        reset({
          searchContact: [{ name: userData?.firstName, id: userData?.userId, username: userData?.username }],
          email: userData?.email,
          phone: formatPhone(userData?.phone),
          position: userData?.position,
        });
      }
    } else {
      reset(defaultValues);
    }
  }, [mode, userData, id]);

  return {
    columns: columnsData?.columns,
    isLoading,
    data,
    filterControl,
    currentChildren,
    resetFilter,
    contactListFilter,
    handlePaginationClick,
    handleRowClick,
    handleRowEdit,
    handleStopResize,
    handleColumnSearch,
    skip: contactFilters.skip,
    limit: contactFilters.limit,
    handleDeleteModal,
    contactPagePermissions,
    roleLevel,
  };
};

export default useContactList;
