import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import { SUBJECT } from 'pages/profile/tabs/employee/constants/constants';
import { IEmployeeFormProps } from 'pages/profile/tabs/employee/constants/types';
import { DatePicker, Input } from 'pages/profile/tabs/employee/hoc-instance/instance';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import LocalSearchSelect from 'ui/inputs/local-search-select/LocalSearchSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import {
  emailFormatter,
  formatPhone,
  nonAlphanumericSpaceFormatter,
  onlyNumbersFormatter,
  unicodeFormatter,
} from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import styles from './CreateEditEmployee.module.scss';

const CreateEditEmployeeForm = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  watch,
  errors,
  setValue,
  statesData,
  setError,
  isDirty,
  handleViewFileEdit,
  handleDeleteFileEdit,
  handleDownloadFileEdit,
  beforeUploadForEdit,
  handleViewFileCreate,
  handleDeleteFileCreate,
  handleDownloadFileCreate,
  beforeUploadForCreate,
  employeeId,
  allFiles,
  permissionOptions,
  setUsername,
  handleClearDate,
  mode,
  isUserNameExists,
  isEditLoading,
  isCreateLoading,
  hasEditPermission,
}: IEmployeeFormProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isEditLoading && !isCreateLoading }));
  }, [isEditLoading, isCreateLoading]);

  return (
    <div className={styles.employeeWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ViewItemWrapper title={mode === 'add' ? SUBJECT.ADD_NEW_EMPLOYEE : SUBJECT.EDIT_EMPLOYEE}>
          <div className={styles.employeesSubheader}>
            <Typography variant="paragraph">{SUBJECT.PERSONAL_INFORMATION}</Typography>
          </div>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Input
                name="firstName"
                type="text"
                control={control}
                onChange={(e: any) =>
                  setValue('firstName', unicodeFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                rules={{ required: true }}
                placeholder={SUBJECT.FIRST_NAME}
                getValues={watch}
                required
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="lastName"
                type="text"
                control={control}
                onChange={(e: any) =>
                  setValue('lastName', unicodeFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                rules={{ required: true }}
                placeholder={SUBJECT.LAST_NAME}
                getValues={watch}
                required
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="username"
                type="text"
                success={isDirty && !!(watch('username') && !isUserNameExists)}
                control={control}
                onChange={(e: any) => {
                  setValue('username', nonAlphanumericSpaceFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setUsername(e.target.value);
                }}
                rules={{ required: true }}
                placeholder={SUBJECT.USER_NAME}
                getValues={watch}
                required
                disabled={mode === 'edit' && !hasEditPermission}
                errors={errors}
                handleBlur={() => {
                  if (!watch('username')) {
                    setError('username', { message: 'Username is a required field' });
                  }
                }}
              />
            </Col>
            <Col span={8}>
              <DatePicker
                name="dob"
                control={control}
                placeholder={SUBJECT.DOB}
                errors={errors}
                onClear={handleClearDate}
                isAbleToSelectFutureValue={false}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <Input
                name="email"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.EMAIL}
                getValues={watch}
                required
                errors={errors}
                disabled={mode === 'edit'}
                onChange={(e: any) =>
                  setValue('email', emailFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </Col>
            <Col span={8}>
              <Input
                name="phoneNumber"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.PHONE_NUMBER}
                getValues={watch}
                onChange={(e: any) =>
                  setValue('phoneNumber', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                required
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="ext"
                type="text"
                control={control}
                rules={{ required: false }}
                placeholder={SUBJECT.EXT}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => setValue('ext', e.target.value.trimStart())}
              />
            </Col>
            <Col span={8}>
              <Input
                name="title"
                type="text"
                control={control}
                rules={{ required: false }}
                placeholder={SUBJECT.TITLE}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => setValue('title', e.target.value.trimStart())}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="permissionGroup"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <LocalSearchSelect
                      options={permissionOptions?.result?.map((el: any) => ({
                        value: `${el.id}`,
                        title: el.name,
                      }))}
                      placeholder={SUBJECT.PERMISSION_GROUP}
                      name={field.name}
                      value={field.value}
                      allowClear
                      onChange={(value: string) => {
                        setValue(field.name, value, { shouldValidate: true, shouldDirty: true });
                      }}
                      errors={errors}
                      required
                      onBlur={() => trigger(field.name)}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.permissionGroup} />
            </Col>

            <Col span={12}>
              <Input
                name="address"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.ADDRESS}
                getValues={watch}
                required
                errors={errors}
                onChange={(e: any) => setValue('address', e.target.value.trimStart(), { shouldValidate: true })}
              />
            </Col>
            <Col span={12}>
              <Input
                name="secondAddress"
                type="text"
                control={control}
                placeholder={SUBJECT.SECOND_ADDRESS}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => setValue('secondAddress', e.target.value.trimStart())}
              />
            </Col>

            <Col span={8}>
              <Input
                name="city"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.CITY}
                getValues={watch}
                required
                errors={errors}
                onChange={(e: any) => setValue('city', e.target.value.trimStart(), { shouldValidate: true })}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <StateSelect
                      allowClear
                      options={statesData}
                      placeholder={SUBJECT.STATE}
                      name={field.name}
                      errors={errors}
                      onBlur={() => trigger('state')}
                      onChange={(value: string) =>
                        setValue('state', value, {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      value={field.value}
                      required
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.state} />
            </Col>
            <Col span={8}>
              <Input
                name="zipcode"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.ZIPCODE}
                onChange={(e: any) =>
                  setValue('zipcode', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                getValues={watch}
                required
                errors={errors}
              />
            </Col>
          </Row>
          <div className={styles.employeesSubheaderBottom}>
            <Typography variant="paragraph">{SUBJECT.EMERGENCY_CONTACT}</Typography>
          </div>
          <div>
            <Row gutter={[18, 40]}>
              <Col span={8}>
                <Input
                  name="name"
                  type="text"
                  control={control}
                  onChange={(e: any) =>
                    setValue('name', unicodeFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  rules={{ required: true }}
                  placeholder={SUBJECT.NAME}
                  getValues={watch}
                  required
                  errors={errors}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="relation"
                  type="text"
                  control={control}
                  onChange={(e: any) =>
                    setValue('relation', unicodeFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  rules={{ required: true }}
                  placeholder={SUBJECT.RELATION}
                  getValues={watch}
                  required
                  errors={errors}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="phoneNumberEmergency"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.PHONE_NUMBER}
                  getValues={watch}
                  onChange={(e: any) =>
                    setValue('phoneNumberEmergency', formatPhone(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  required
                  errors={errors}
                />
              </Col>
            </Row>
          </div>
        </ViewItemWrapper>
        <ViewItemWrapper title={SUBJECT.UPLOAD_FILE} required>
          <Row className={styles.uploadSection} align="middle" gutter={[20, 40]}>
            <Col span={12}>
              <Upload
                uploadText={SUBJECT.UPLOAD}
                className={styles.upload}
                beforeUpload={beforeUploadForCreate || beforeUploadForEdit}
              />
            </Col>
            <Col span={12}>
              <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT} className={styles.allowedFormat} />
            </Col>
            <Col span={24}>
              <Controller
                control={control}
                render={({ field: { value: _value } }) => (
                  <FileItems
                    handleDownloadFileEdit={handleDownloadFileEdit}
                    handleDownloadFile={handleDownloadFileCreate}
                    handleViewFileEdit={handleViewFileEdit}
                    handleViewFileCreate={handleViewFileCreate}
                    itemId={employeeId}
                    onDeleteClick={handleDeleteFileCreate || handleDeleteFileEdit}
                    files={allFiles}
                  />
                )}
                name="file"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ErrorMessage fieldError={errors?.file} />
            </Col>
          </Row>
        </ViewItemWrapper>
      </form>
    </div>
  );
};

export default CreateEditEmployeeForm;
