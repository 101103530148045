import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { defaultValues } from 'pages/authorization/sign-in/constants/constants';
import { IFormData } from 'pages/authorization/sign-in/constants/types';
import { validation } from 'pages/authorization/sign-in/constants/validation';
import { useLoginMutation } from 'services/auth/auth';
import { updateAuth } from 'store/user-slice/user';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';

const useSignIn = () => {
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  const [isForgot, setIsForgot] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    getValues,
  } = useForm<IFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const { searchParams, setSearchParams } = useDetectedParams();
  const { reset, newPass, createPass } = searchParams;

  const redirectWaitingPage = () => {
    setIsForgot(prev => !prev);
    if (!isForgot) {
      setSearchParams({ reset: 'true' });
    } else {
      setSearchParams({});
    }
  };

  const onSubmit = async (values: IFormData) => {
    if (isLoading) {
      return;
    }
    const { email, password } = values;
    const userData: any = await login({ email, password });

    if (userData?.data?.access) {
      dispatch(updateAuth({ token: userData?.data?.access }));
    }
    if (userData?.error) {
      notification.error({
        message: ServerErrorCodes[Number(userData?.error?.data?.code) || 0],
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  useEffect(() => {
    setIsForgot(!!reset);
  }, [searchParams]);

  return {
    handleSubmit,
    control,
    isValid,
    onSubmit,
    isForgot,
    redirectWaitingPage,
    errors,
    getValues,
    newPass,
    createPass,
    isLoading,
  };
};

export default useSignIn;
