import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

import { ISidebar } from './interfaces';

export const initialState: ISidebar = {
  open: false,
  isLoading: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    sidebarStatus: (state, { payload }) => {
      state.open = payload;
    },
    changeLoading: (state, { payload: isLoading }) => {
      state.isLoading = isLoading;
    },
  },
});

export const { sidebarStatus, changeLoading } = sidebarSlice.actions;

export const sidebarSliceActions = (state: RootState) => state.sidebar.open;

export default sidebarSlice.reducer;
