import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetAgentQuery, useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import { useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { IRoleLevel } from 'services/truck-board/carrier-request/interfaces';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';

import { FILTER_DEFAULT_VALUES, SUBJECT } from './constants/constants';
import { IPayoutFilter } from './constants/types';

export const usePayoutFilter = () => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { activeTab, agentPayout, officePayout, branchPayout, startDate, endDate } = searchParams;

  const { permissionsInfo, branchIds, officeIds } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const {
    formState: { isDirty: isFilterDirty, dirtyFields },
    control: filterControl,
    reset: filterReset,
    watch: filterWatch,
    setValue: setFilterValue,
  } = useForm<IPayoutFilter>({ defaultValues: FILTER_DEFAULT_VALUES, mode: 'onChange' });

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [selectedOffices, setSelectedOffices] = useState<any>([]);
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [finalBranchesOptions, setFinalBranchesOptions] = useState<any>({ result: [], count: 0 });

  const [selectedAgentOption, setSelectedAgentOption] = useState<any>({});
  const [finalAgentOptions, setFinalAgentOptions] = useState<any>({ result: [], count: 0 });

  const { data: officesOptions = { result: [], count: 0 }, isFetching: isOfficeFetching } =
    useGetOfficesAutocompleteQuery(officesFilter);

  const { data: branchesOptions = { result: [], count: 0 }, isFetching: isBranchFetching } =
    useGetBranchesAutocompleteQuery(
      { officeId: selectedOffices?.[0]?.id || officeIds?.[0], withDefaults: true, ...branchFilter },
      {
        skip:
          activeTab === SUBJECT.OFFICE ||
          roleLevel > IRoleLevel.officeLevel ||
          (!filterWatch('officePayout')?.length && roleLevel < IRoleLevel.officeLevel) ||
          filterWatch('officePayout')!.length > 1,
      }
    );

  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    {
      ...agentsFilter,
      branchId: filterWatch('branchPayout')?.[0] || branchIds?.[0],
      excludeOwners: true,
    },
    {
      skip:
        activeTab !== SUBJECT.AGENT ||
        (roleLevel <= IRoleLevel.officeLevel &&
          (typeof filterWatch('branchPayout') === 'string' || filterWatch('branchPayout')?.length !== 1)),
    }
  );
  const { data: selectedAgent } = useGetAgentQuery(agentPayout, { skip: !agentPayout });

  useEffect(() => {
    if (selectedAgent) {
      setSelectedAgentOption([
        {
          firstName: selectedAgent?.result?.firstName,
          lastName: selectedAgent?.result?.lastName,
          username: selectedAgent?.result?.username,
          id: selectedAgent?.result?.id,
          value: String(selectedAgent?.result?.id),
        },
      ]);
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (agentsOptions) {
      setFinalAgentOptions(agentsOptions);
    }
  }, [selectedAgentOption, agentsOptions?.result?.length]);

  useEffect(() => {
    if (Array.isArray(filterWatch('branchPayout'))) {
      setSelectedBranches((prev: any) =>
        [
          ...prev.filter((el: any) => !branchesOptions?.result?.some((item: any) => item.value == el.value)),
          ...branchesOptions.result,
        ].filter((el: any) => filterWatch('branchPayout')?.some((item: any) => item == el.value))
      );
    }
  }, [filterWatch('branchPayout')?.length, branchesOptions?.result?.length]);

  useEffect(() => {
    if (Array.isArray(filterWatch('officePayout'))) {
      setSelectedOffices((prev: any) =>
        [
          ...prev.filter((el: any) => !officesOptions?.result?.some((item: any) => item.value == el.value)),
          ...officesOptions.result,
        ].filter((el: any) => filterWatch('officePayout')?.some((item: any) => item == el.value))
      );
    }
  }, [filterWatch('officePayout')?.length, isOfficeFetching]);

  useEffect(() => {
    if (officesOptions?.result) {
      setFinalOfficeOptions({
        result: [
          ...selectedOffices,
          ...officesOptions.result.filter((el: any) => !selectedOffices.some((item: any) => item.value == el.value)),
        ],
        count: officesOptions.count,
      });
    } else {
      setFinalOfficeOptions(selectedOffices);
    }
  }, [selectedOffices?.length, isOfficeFetching]);

  useEffect(() => {
    if (branchesOptions?.result?.length) {
      setFinalBranchesOptions({
        result: [
          ...selectedBranches,
          ...branchesOptions.result.filter((el: any) => !selectedBranches.some((item: any) => item.value == el.value)),
        ],
        count: branchesOptions.count,
      });
    } else {
      setFinalBranchesOptions({ result: [], count: 0 });
    }
  }, [selectedBranches?.length, isBranchFetching]);

  const filterOfficeValue = filterWatch('officePayout');
  const filterBranchValue = filterWatch('branchPayout');
  const filterAgentValue = filterWatch('agentPayout')?.[0]?.id
    ? filterWatch('agentPayout')?.[0]?.id
    : filterWatch('agentPayout');

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(filterOfficeValue
        ? { officePayout: Array.isArray(filterOfficeValue) ? filterOfficeValue?.join(',') : '' }
        : { officePayout: '' }),
      ...(filterBranchValue
        ? { branchPayout: Array.isArray(filterBranchValue) ? filterBranchValue?.join(',') : '' }
        : { branchPayout: '' }),
      ...(filterAgentValue ? { agentPayout: filterAgentValue } : { agentPayout: '' }),
    });
  }, [filterOfficeValue, filterBranchValue, filterAgentValue]);

  useEffect(() => {
    if (filterWatch('officePayout')!.length !== 1 && roleLevel === IRoleLevel.corpLevel) {
      setSearchParams({
        ...searchParams,
        branchPayout: '',
        agentPayout: '',
      });
      setFilterValue('branchPayout', []);
      setFilterValue('agentPayout', []);
      setSelectedAgentOption({});
    }
  }, [filterWatch('officePayout'), roleLevel]);

  useEffect(() => {
    if (filterWatch('branchPayout')!.length !== 1 && roleLevel <= IRoleLevel.officeLevel) {
      setSearchParams({
        ...searchParams,
        agentPayout: '',
      });
      setFilterValue('agentPayout', []);
      setSelectedAgentOption({});
    }
  }, [filterWatch('branchPayout'), roleLevel]);

  const filterOptions = useMemo(() => {
    return [
      { key: 'officePayout', value: officePayout ? officePayout.split(',') : officePayout },
      { key: 'branchPayout', value: branchPayout ? branchPayout.split(',') : branchPayout },
      { key: 'agentPayout', value: agentPayout ? agentPayout.split(',') : agentPayout },
      { key: 'monthPickerPayout.startDate', value: startDate },
      { key: 'monthPickerPayout.endDate', value: endDate },
    ];
  }, [officePayout, branchPayout, agentPayout]);

  useEffect(() => {
    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setFilterValue(key as keyof IPayoutFilter, value, {
          shouldDirty:
            key === 'monthPickerPayout.startDate' || key === 'monthPickerPayout.endDate' ? false : !!value?.length,
        });
      }
    });
  }, [officePayout, branchPayout, agentPayout, startDate, endDate, agentsOptions?.result?.length, filterOptions]);

  const isChangedDate = useMemo(() => {
    if (startDate && endDate) {
      return true;
    } else return !!(startDate && !endDate && startDate !== String(FILTER_DEFAULT_VALUES.monthPickerPayout.startDate));
  }, [
    filterWatch('monthPickerPayout').startDate,
    startDate,
    endDate,
    activeTab,
    FILTER_DEFAULT_VALUES.monthPickerPayout.startDate,
  ]);

  return {
    isFilterDirty,
    filterControl,
    filterOptions,
    finalOfficeOptions,
    finalBranchesOptions,
    finalAgentOptions,
    selectedAgentOption,
    setAgentsFilter,
    setBranchFilter,
    filterReset,
    filterWatch,
    setFilterValue,
    setOfficesFilter,
    dirtyFields,
    isChangedDate,
    setSelectedAgentOption,
    selectedBranches,
    FILTER_DEFAULT_VALUES,
  };
};
