import React from 'react';
import { Col, Row } from 'antd';
import { BillingTypesText } from 'services/profile/customers/interfaces';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import { IMcAndCustomersProps } from './constants/types';

import styles from './McAndCustomersView.module.scss';

const McAndCustomersView = ({ data, isFetching }: IMcAndCustomersProps) => {
  return (
    <>
      <MainLoader isFetching={isFetching} />
      <ViewItemWrapper title={SUBJECT.MC_AND_CUSTOMER_INFO}>
        <Row justify="space-between" align="top" gutter={[10, 25]}>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.MC_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.authority?.name}
                  subject={SUBJECT.MC_NAME}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.MC_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.authority?.mc}
                  subject={SUBJECT.MC_NUMBER}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CUSTOMER_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.customer?.name}
                  subject={SUBJECT.CUSTOMER_NAME}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CUSTOMER_MC} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.customer?.mc}
                  subject={SUBJECT.CUSTOMER_MC}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PAYMENT_TERMS_TYPES} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={`${BillingTypesText[data?.type as BillingTypesText]} / ${data?.days} day / ${
                    data?.percent
                  }%`}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </>
  );
};

export default McAndCustomersView;
