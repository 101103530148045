import React from 'react';

const LogOutIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 18.3373C3.41667 18.3373 3.125 18.2123 2.875 17.9623C2.625 17.7123 2.5 17.4207 2.5 17.0873V4.58734C2.5 4.25401 2.625 3.96234 2.875 3.71234C3.125 3.46234 3.41667 3.33734 3.75 3.33734H9.8125V4.58734H3.75V17.0873H9.8125V18.3373H3.75ZM13.875 14.4832L12.9792 13.5873L15.1042 11.4623H7.8125V10.2123H15.0625L12.9375 8.08734L13.8333 7.19151L17.5 10.8582L13.875 14.4832Z"
        fill="#98A2B2"
      />
    </svg>
  );
};

export default LogOutIcon;
