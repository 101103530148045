import React, { useDeferredValue } from 'react';
import Typography from 'ui/typography/Typography';

import MainDrawer from 'components/main-drawer/MainDrawer';
import Table from 'components/table/Table';

import { SUBJECT } from './constants/constants';
import useMcAndCustomers from './useMcAndCustomers';

import styles from './McAndCustomers.module.scss';

const McAndCustomers = ({ setExcelParams }: any) => {
  const {
    isLoading,
    data,
    columns,
    handleRowClick,
    handlePaginationClick,
    handleStopResize,
    currentChildren: { component, withFooter, buttonText, onCancel, onSubmit },
    skip,
    limit,
    handleColumnSearch,
  } = useMcAndCustomers(setExcelParams);

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading,
    onColumnSearch: handleColumnSearch,
    withMenu: false,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onPaginationClick: handlePaginationClick,
  });

  return (
    <>
      {data ? (
        <div className="page-content">
          <Table {...tableProps} />
          <MainDrawer
            Children={component}
            onSubmit={onSubmit}
            buttonText={buttonText}
            withFooter={withFooter}
            onCancel={onCancel}
          />
        </div>
      ) : (
        <div>
          <Typography className={styles.mcAndCustomersEmpty} variant="paragraph">
            {SUBJECT.MC_AND_CUSTOMERS_IS_EMPTY}
          </Typography>
        </div>
      )}
    </>
  );
};

export default McAndCustomers;
