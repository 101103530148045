import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { IRequestCreditFormData } from '../constants/types';

export const Input = withController<IInputProps, IRequestCreditFormData>(CustomInput);
export const Select = withController<ISelectProps, IRequestCreditFormData>(ReusableSelect);

export const SearchableSelect = withController<ISearchableSelectProps, IRequestCreditFormData>(CustomSearchableSelect);

export const SearchableDropdown = withController<ISearchableDropdownProps, IRequestCreditFormData>(
  CustomSearchableDropdown
);
