import { ISliderImages } from './types';

export const SLIDER_IMAGES: ISliderImages[] = [
  {
    IMG_FIRST: '/assets/images/slider-image-1.png',
    IMG_SECOND: '/assets/images/slider-image-2.png',
    IMG_DESCR_FIRST: 'First Image',
    IMG_DESCR_SECOND: 'Second Image',
    ID: 1,
  },
  {
    IMG_FIRST: '/assets/images/slider-image-3.png',
    IMG_SECOND: '/assets/images/slider-image-4.png',
    IMG_DESCR_FIRST: 'First Image',
    IMG_DESCR_SECOND: 'Second Image',
    ID: 2,
  },
  {
    IMG_FIRST: '/assets/images/slider-image-5.png',
    IMG_SECOND: '/assets/images/slider-image-6.png',
    IMG_DESCR_FIRST: 'First Image',
    IMG_DESCR_SECOND: 'Second Image',
    ID: 3,
  },
  {
    IMG_FIRST: '/assets/images/slider-image-1.png',
    IMG_SECOND: '/assets/images/slider-image-2.png',
    IMG_DESCR_FIRST: 'First Image',
    IMG_DESCR_SECOND: 'Second Image',
    ID: 4,
  },
  {
    IMG_FIRST: '/assets/images/slider-image-3.png',
    IMG_SECOND: '/assets/images/slider-image-4.png',
    IMG_DESCR_FIRST: 'First Image',
    IMG_DESCR_SECOND: 'Second Image',
    ID: 5,
  },
  {
    IMG_FIRST: '/assets/images/slider-image-5.png',
    IMG_SECOND: '/assets/images/slider-image-6.png',
    IMG_DESCR_FIRST: 'First Image',
    IMG_DESCR_SECOND: 'Second Image',
    ID: 6,
  },
  {
    IMG_FIRST: '/assets/images/slider-image-1.png',
    IMG_SECOND: '/assets/images/slider-image-2.png',
    IMG_DESCR_FIRST: 'First Image',
    IMG_DESCR_SECOND: 'Second Image',
    ID: 7,
  },
  {
    IMG_FIRST: '/assets/images/slider-image-3.png',
    IMG_SECOND: '/assets/images/slider-image-4.png',
    IMG_DESCR_FIRST: 'First Image',
    IMG_DESCR_SECOND: 'Second Image',
    ID: 8,
  },
];
