import React from 'react';
import { useForm } from 'react-hook-form';
import { LoadTypes } from 'pages/loads/components/create-load/constants/types';
import { IGetLoadByIdModified } from 'services/loads/interface';

import ConnectingBanner from './components/connecting-banner/ConnectingBanner';
import InternalBoardBanner from './components/internal-board-banner/InternalBanner';
import RegularBanner from './components/regular-banner/RegularBanner';
import TonuBanner from './components/tonu-banner/TonuBanner';
import { defaultValues } from './constants/constants';
import { ILoadsCommentFormData } from './constants/types';

const useLoadsComment = (loadData: IGetLoadByIdModified) => {
  const { control, watch, setValue } = useForm<ILoadsCommentFormData>({
    defaultValues,
  });

  const loadType = loadData?.load?.loadType;

  const banners = {
    [LoadTypes.Connecting]: <ConnectingBanner data={loadData} />,
    [LoadTypes.Tonu]: <TonuBanner data={loadData} />,
    [LoadTypes.Regular]: <RegularBanner data={loadData} />,
    [LoadTypes.Internal]: <InternalBoardBanner data={loadData} />,
  };

  return {
    control,
    watch,
    setValue,
    banners,
    loadType,
  };
};

export default useLoadsComment;
