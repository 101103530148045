import { IDistributeResponse } from 'services/payroll/information/types';

import { IRoleLevel } from '../../../../../../../services/truck-board/carrier-request/interfaces';

export const getDistributeDefaultValues = (roleLevel: number) => {
  return {
    ...(roleLevel === IRoleLevel.corpLevel ? { corporate: { sum: '', percent: '' } } : {}),
    ...(roleLevel <= IRoleLevel.officeLevel ? { agency: { sum: '', percent: '' } } : {}),
    ...(roleLevel <= IRoleLevel.branchLevel ? { branch: { sum: '', percent: '' } } : {}),
    agent: { sum: '', percent: '' },
  };
};

export const getDistributeMainAmount = (roleLevel: number, data: IDistributeResponse) => {
  if (data) {
    return Object.values({
      ...(roleLevel === IRoleLevel.corpLevel
        ? { corporate: { sum: (data?.amount / 100) * data?.corporatePercent, percent: data?.corporatePercent } }
        : {}),
      ...(roleLevel <= IRoleLevel.officeLevel
        ? { agency: { sum: (data?.amount / 100) * data?.agencyPercent, percent: data?.agencyPercent } }
        : {}),
      ...(roleLevel <= IRoleLevel.branchLevel
        ? { branch: { sum: (data?.amount / 100) * data?.branchPercent, percent: data?.branchPercent } }
        : {}),
      agent: { sum: (data?.amount / 100) * data?.agentPercent, percent: data?.agentPercent },
    })?.reduce((acc, curr) => acc + curr?.sum, 0);
  }
};

export const getDistributeMainPercent = (roleLevel: number, data: IDistributeResponse) => {
  if (data) {
    return Object.values({
      ...(roleLevel === IRoleLevel.corpLevel
        ? { corporate: { sum: (data?.amount / 100) * data?.corporatePercent, percent: data?.corporatePercent } }
        : {}),
      ...(roleLevel <= IRoleLevel.officeLevel
        ? { agency: { sum: (data?.amount / 100) * data?.agencyPercent, percent: data?.agencyPercent } }
        : {}),
      ...(roleLevel <= IRoleLevel.branchLevel
        ? { branch: { sum: (data?.amount / 100) * data?.branchPercent, percent: data?.branchPercent } }
        : {}),
      agent: { sum: (data?.amount / 100) * data?.agentPercent, percent: data?.agentPercent },
    })?.reduce((acc, curr) => acc + curr?.percent, 0);
  }
};

export const handleLimitError = (
  activeRow: string | null,
  inputName: string,
  key: string,
  remainingSum: number,
  mainAmount: number
) => {
  if (activeRow === inputName && remainingSum > mainAmount)
    return {
      [inputName]: {
        [key]: {},
      },
    };
};
