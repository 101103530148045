import DatePicker from 'react-datepicker';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { IDoubleRangePickerProps } from '../../constants/types';
import CustomHeaderRangePicker from '../custom-header-range-picker/CustomHeaderRangePicker';

import { useDoubleRangePicker } from './useDoubleRangePicker';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './DoubleRangePicker.module.scss';

const DoubleRangePicker = ({
  selectsRange,
  handleDateChange,
  start,
  end,
  isCalendarOpen,
  setIsCalendarOpen,
}: IDoubleRangePickerProps) => {
  const { openDropDown, setOpenDropDown, startDate, endDate, onChange, onClickOutside } = useDoubleRangePicker({
    handleDateChange,
    start,
    end,
    selectsRange,
    setIsCalendarOpen,
  });

  return (
    <div className={clsx(styles.customDatePickerWrapper)}>
      <DatePicker
        open={isCalendarOpen}
        minDate={dayjs().set('year', 1900).toDate()}
        onClickOutside={onClickOutside}
        calendarStartDay={0}
        renderCustomHeader={props => (
          <CustomHeaderRangePicker
            {...props}
            openDropDown={openDropDown}
            setOpenDropDown={setOpenDropDown}
            start={start}
          />
        )}
        formatWeekDay={nameOfDay => nameOfDay.toString().substring(0, 3)}
        selected={dayjs(startDate).toDate()}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        selectsRange={selectsRange}
        fixedHeight
        monthsShown={2}
        focusSelectedMonth={false}
      />
    </div>
  );
};

export default DoubleRangePicker;
