export interface IFilter {
  [key: string]: string;
}

export interface IGetAgentsParams {
  search: string;
  field: string;
  skip: number;
  limit: number;
  order: number;
  orderBy: string;
  filter: any;
  officeId?: string | number;
  branchId?: string | number;
  excludeOwners?: boolean;
  withDefaults?: boolean;
  ignoreAuthorityIds: any[];
}

export interface IAuthorityByAgentId {
  id: number;
  mc: string;
  type: number;
  name: string;
  createdAt?: number;
}

export interface IBracket {
  from: number | string | null;
  to: number | string | null;
  percent: number | string | null;
  id?: number;
}

export interface IAgentById {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  phone: string;
  stateId: number;
  address: string;
  secondAddress: string;
  city: string;
  zip: string;
  type: number;
  createdAt: number;
  updatedAt: number;
  createdBy: number;
  updatedBy: number;
  terminatedAt: number;
  terminatedBy: number;
  verifiedAt: number;
  phoneSecond: string;
  location: number;
  brackets: IBracket[];
  payType: number;
  paidAs: number;
  agentPayType?: string;
  agentPayInput: string;
  officeId: number;
  authorityIds: number[];
  authorities: IAuthorityByAgentId[];
  branchIds: number[];
  agentType: number;
  fileIds: number[];
  authorityCount: string;
  state: {
    id: number;
    name: string;
    regionId: number;
  };
  files: any[];
  office: {
    id: number;
    name: string;
  };
  branch: {
    id: number;
    name: string;
  };
  emergencyName: string;
  emergencyPhone: null | string;
  emergencyRelation: string;
  isOnline: boolean;
}

export interface IAgentTable {
  count: number;
  online: number;
  result: IAgentById[];
  total: number;
}

export enum AgentType {
  Admin = 0,
  Owner = 1,
  Manager = 2,
  Agent = 3,
}

export enum Location {
  Domestic = 1,
  International = 2,
}

export enum PayType {
  Unspecified = 0,
  Ach = 1,
  Check = 2,
}

export enum PaidAs {
  Unspecified = 0,
  Individual = 1,
  Corporation = 2,
}

export enum AgentPayType {
  Unspecified = 0,
  SSN = 1,
  EIN = 2,
}
