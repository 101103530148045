import { IBracket } from 'services/profile/agents/interfaces';
import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import CustomSelect from 'ui/inputs/select/Select';

import SearchableSelectForMultipleAuthorities from '../../../../../ui/inputs/searchable-select/searchable-select-for-multiple-authorities/SearchableSelectForMultipleAuthorities';
import { ISearchableDropdownProps } from '../../../../../ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from '../../../../../ui/searchable-dropdown/SearchableDropdown';
import { IAgentFormData, IAgentsFilter } from '../constants/types';

export const Input = withController<IInputProps, IAgentFormData>(CustomInput);
export const BrackedInput = withController<IInputProps, IBracket>(CustomInput);

export const FilterSelect = withController<ISelectProps, IAgentsFilter>(CustomSelect);
export const FilterInput = withController<IInputProps, IAgentsFilter>(CustomInput);
export const SearchableSelect = withController<ISearchableSelectProps, IAgentFormData>(
  SearchableSelectForMultipleAuthorities
);
export const SearchableSelectForFilter = withController<ISearchableSelectProps, IAgentsFilter>(CustomSearchableSelect);

export const SearchableDropdown = withController<ISearchableDropdownProps, IAgentFormData>(CustomSearchableDropdown);
