import React from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { SUBJECT } from 'pages/profile/tabs/office/constants/constants';
import { useChangeStatusMutation } from 'services/profile/offices/offices';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { OfficeStatusTypes } from '../../constants/types';

export const useOfficeView = ({ id, setGetAuthoritiesByOfficeFilter }: any) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const [changeStatus] = useChangeStatusMutation();

  const handleChecked = (checked: boolean) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({
            id,
            status: checked
              ? OfficeStatusTypes.OFFICE_STATUS_TYPES_ACTIVE
              : OfficeStatusTypes.OFFICE_STATUS_TYPES_INACTIVE,
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setGetAuthoritiesByOfficeFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      mode: 'edit',
      open: 'true',
    });
  };

  return {
    handleChecked,
    handleChangeMode,
    handlePaginationClick,
    mode: searchParams?.mode,
  };
};
