import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ICustomDropdownProps } from 'ui/inputs/select/components/dropdown-with-custom-input/constants/types';
import CustomDropdown from 'ui/inputs/select/components/dropdown-with-custom-input/CustomDropdown';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import CustomSelect from 'ui/inputs/select/Select';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { ICollectionValues, IDistributeValues } from './../../constants/types';

export const Input = withController<IInputProps, IDistributeValues>(CustomInput);

export const FormInput = withController<IInputProps, ICollectionValues>(CustomInput);
export const Select = withController<ISelectProps, ICollectionValues>(CustomSelect);
export const SearchableDropdown = withController<ISearchableDropdownProps, ICollectionValues>(CustomSearchableDropdown);
export const DropDown = withController<ICustomDropdownProps, ICollectionValues>(CustomDropdown);
