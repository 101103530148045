import { formatDate } from '../../../utils/dates';

import { CustomerType } from './interfaces';

export const getCustomersListSerializer = (body: any): any => {
  const result = body?.result?.map((customer: any) => {
    return {
      id: customer?.id,
      createdAt: customer?.createdAt,
      name: customer?.name,
      customerId: customer?.id,
      phone: customer?.phone,
      fax: customer?.fax,
      type: CustomerType[customer?.type],
      address: customer?.address,
      city: customer?.city,
      state: customer?.state?.name,
      zip: customer?.zip,
      comments_count: customer?.commentsCount || 0,
      terminatedAt: customer?.terminatedAt,
      mc: customer?.mc,
      ff: customer?.ff,
      dot: customer?.dot,
      stateNumber: customer?.stateNumber,
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getCustomersBlackListSerializer = (body: any): any => {
  const result = body.result?.map((item: any) => {
    return {
      id: item.id,
      date: formatDate(item?.createdAt),
      name: item?.customer?.name,
      customerId: item?.customer?.id,
      authorityName: item?.authority?.name,
      addedBy: `${item?.creator?.username || ''}`,
      authorityId: item?.authority?.id,
      unlock: 'Unlock',
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getTemplatesSerializer = (body: any) => {
  const result = body.result.map((item: any) => {
    return {
      value: item?.id,
      title: item?.name,
      url: item?.url,
    };
  });
  return {
    result,
    count: result.length,
  };
};
