export enum SUBJECT {
  CREATE = 'Create',
  SAVE = 'Save',
  CANCEL = 'Close',
  CHANGE_PASSWORD = 'Change Password',
  STAY_HERE = 'Stay Here',
  SETTINGS = 'Settings',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  CURRENT_REQUIRED = 'Current Password Is a Required Field',
  NEW_REQUIRED = 'New Password Is a Required Field',
  REPEAT_REQUIRED = 'Repeat Password Is a Required Field',
  PASSWORD_NOT_MATCH = 'Passwords Do Not Match',
  PASSWORD_NOT_VALID = `Password  don't match with password requirements`,
  THE_SAME_PASSWORD = `New Password And Current Password Can't Be The Same`,
}

export const defaultValues = {
  currentPassword: '',
  newPassword: '',
  repeatPassword: '',
};
