export const createTransaction = (body: any) => {
  return {
    loadId: Number(body.loadId),
    type: Number(body.body.transactionType),
    ...(body.body.customer ? { customerAmount: Number(body.body.customer) } : {}),
    ...(body.body.carrierTransaction ? { carrierAmount: Number(body.body.carrierTransaction) } : {}),
    ...(body?.body?.secondAgent ? { secondAgentAmount: Number(body?.body?.secondAgent) } : {}),
    comment: body.body.transactionComment,
  };
};
