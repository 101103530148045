import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useChangeTruckStatusMutation } from 'services/truck-board/truck-board/truckBoard';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Button from 'ui/button/Button';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { useHandleCopyActive } from '../../../../../../hooks/useCopy';
import { usePermittedActions } from '../../../../../../hooks/usePermittedActions';
import { SUBJECT } from '../../constants/constants';

import { TruckStatuses } from './constants/constants';
import { IuseTruckInfoProps } from './constants/types';

const useTruckInfo = ({ truckInfo = {} }: IuseTruckInfoProps) => {
  const { status } = truckInfo;
  const { userType, employeeLevel } = useSelector(selectUserPermissionsInfo);
  const dispatch = useDispatch();
  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, truckId, singleStatus: statusQuery, tab } = searchParams;
  const [changeStatus] = useChangeTruckStatusMutation();
  const { copiedText, handleCopy } = useHandleCopyActive();
  const { truckBoard: truckBoardPermission, verification: truckBoardVerificationPermission } =
    usePermittedActions('truckBoard');

  const canEdit = useMemo(() => {
    return Object.values({
      createSearch: truckBoardPermission.createSearch,
      addCoordinator: truckBoardVerificationPermission.addCoordinator,
      deleteCoordinator: truckBoardVerificationPermission.deleteCoordinator,
      updateOtherCoordinatorLocation: truckBoardVerificationPermission.updateOtherCoordinatorLocation,
      updateSelfLocation: truckBoardVerificationPermission.updateSelfLocation,
      updateUnassignedLocation: truckBoardVerificationPermission.updateUnassignedLocation,
    })?.includes(true);
  }, [truckBoardPermission, truckBoardVerificationPermission]);

  const handleEditClick = () => {
    setSearchParams({ ...searchParams, mode: 'carrierTruckEdit', open: 'true' });
  };

  const handleRefusedClick = () => {
    setSearchParams({ ...searchParams, open: 'false', carrierId: '' });
  };

  const handleClick = (type: string) => {
    dispatch(
      openModal({
        title: type === 'HAS_A_LOAD' ? SUBJECT.ARE_YOU_SURE_HAS_LOAD : SUBJECT.ARE_YOU_SURE_WRONG_LOCATION,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        onOk: () => {
          dispatch(changeLoading(true));
          changeStatus({ id: truckId, status: TruckStatuses[type as keyof typeof TruckStatuses] }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              handleRefusedClick();
              notification.success({
                message: 'Successfully changed',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(changeLoading(false));
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const isHasALoadShowed = useMemo(() => status === TruckStatuses.TRUCK_BOARD, [status]);

  const TruckInfoButtonsRenderer = () => {
    if (mode !== 'carrierTruckView' && mode !== 'carrierTruckViewFromResult') return null;
    if (
      mode === 'carrierTruckView' &&
      (userType === UserTypes.ADMIN ||
        (userType === UserTypes.EMPLOYEE && employeeLevel === EmployeeLevels.CORPORATE && canEdit))
    ) {
      return (
        <>
          <Button variant="primary" onClick={handleEditClick}>
            {SUBJECT.EDIT}
          </Button>
        </>
      );
    } else if (
      mode === 'carrierTruckViewFromResult' &&
      (userType === UserTypes.ADMIN ||
        (userType === UserTypes.EMPLOYEE && employeeLevel === EmployeeLevels.CORPORATE && canEdit))
    ) {
      return (
        <>
          {isHasALoadShowed && (
            <>
              <Button variant="warning" onClick={() => handleClick('HAS_A_LOAD')}>
                {SUBJECT.ALREADY_HAS_LOAD}
              </Button>
              <Button variant="outlinedDanger" onClick={() => handleClick('WRONG_LOCATION')}>
                {SUBJECT.WRONG_LOCATION}
              </Button>
            </>
          )}
          <Button variant="primary" onClick={handleEditClick}>
            {SUBJECT.EDIT}
          </Button>
        </>
      );
    } else if (
      userType !== UserTypes.ADMIN &&
      userType !== UserTypes.EMPLOYEE &&
      employeeLevel !== EmployeeLevels.CORPORATE
    ) {
      return (
        <>
          <Button variant="danger" onClick={handleRefusedClick}>
            {SUBJECT.REFUSED_THE_LOAD}
          </Button>
          {isHasALoadShowed && (
            <>
              <Button variant="warning" onClick={() => handleClick('HAS_A_LOAD')}>
                {SUBJECT.ALREADY_HAS_LOAD}
              </Button>
              <Button variant="outlinedDanger" onClick={() => handleClick('WRONG_LOCATION')}>
                {SUBJECT.WRONG_LOCATION}
              </Button>
            </>
          )}
        </>
      );
    } else return null;
  };

  return {
    TruckInfoButtonsRenderer,
    copiedText,
    handleCopy,
    statusQuery,
    tab,
  };
};
export default useTruckInfo;
