import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useGetStatesForCarrierOnBoardQuery,
  useRequestCarrierSignUpMutation,
} from 'services/carriersOnBoard/carriersOnBoard';
import { useUploadFileNOAMutation } from 'services/profile/authorities/authorities-file';
import { useLazyGetFilesByCarrierIdAndTypeQuery } from 'services/profile/carriers/carriers';
import { IFileTypes } from 'services/profile/carriers/interfaces';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { formatDate } from 'utils/dates';
import { FILE_MAX_SIZE_ERROR, ServerErrorCodes } from 'utils/errors';
import { isFileAllowedToBeUpload } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { ICarriersFormData } from '../../profile/tabs/carriers/constants/types';

import { defaultValues, SUBJECT } from './constants/constants';
import { validation } from './constants/validation';
import CreateCarrierSignUp from './create-form-sign-up/CreateCarrierSignUp';

const useCarriersSignUp = () => {
  const dispatch = useDispatch();
  const [paymentTermDirty, setPaymentTermDirty] = useState(false);
  const [panelShowed, setPanelShowed] = useState<boolean>(false);

  const { pathname } = useLocation();

  const activeSection = useSelector(selectActiveSection);

  const { searchParams, setSearchParams } = useDetectedParams();
  const navigate = useNavigate();
  const { mode, activePage, width, id } = searchParams;

  const { data: statesData = [] } = useGetStatesForCarrierOnBoardQuery({});

  const [truckState, setTruckState] = useState<any>({
    skip: 0,
    limit: 10,
  });

  useEffect(() => {
    setTruckState({ skip: 0, limit: 10 });
  }, [pathname]);

  const handleTruckPaginationClick = (skip: number, limit: number) => {
    setTruckState((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const [requestCarrierSignUp, { isLoading: isCreateLoading }] = useRequestCarrierSignUpMutation();

  const [getFilesByType, { data: filesDataForEdit }] = useLazyGetFilesByCarrierIdAndTypeQuery();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, dirtyFields, isSubmitted },
    clearErrors,
    getValues,
    setValue,
    watch,
    reset,
    trigger,
    resetField,
  } = useForm<ICarriersFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation('add', activePage, panelShowed)),
  });

  useEffect(() => {
    reset(defaultValues);
    setAllFiles({
      carrierPacketFile: [],
      signAgreementFile: [],
      paidAsW9File: [],
      insuranceFile: [],
      voidedCheckBankLatterFile: [],
    });
  }, [pathname]);

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      isAproveMode: 'false',
      mode: 'view',
      id: '',
    });
    reset(defaultValues, { keepDirty: false });
    setAllFiles({
      carrierPacketFile: [],
      signAgreementFile: [],
      paidAsW9File: [],
      insuranceFile: [],
      voidedCheckBankLatterFile: [],
    });
  };
  const onSubmit: SubmitHandler<ICarriersFormData> = data => {
    if (paymentTermDirty) {
      return;
    }
    const dataWithPaymentTerms = panelShowed
      ? data
      : { ...data, paymentTerms: data.paymentTerms.filter((el: any) => el.type === 2) };

    if (!isCreateLoading) {
      requestCarrierSignUp(dataWithPaymentTerms).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully requested',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          navigate('/carrierotp/?done=true');
        }
      });
    }
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      isAproveMode: 'false',
      id: '',
    });
    dispatch(closeModal());
    reset(defaultValues);
    setAllFiles({
      carrierPacketFile: [],
      signAgreementFile: [],
      paidAsW9File: [],
      insuranceFile: [],
      voidedCheckBankLatterFile: [],
    });
    setTruckState({
      skip: 0,
      limit: 10,
    });
    clearErrors();
  };

  const [allFiles, setAllFiles] = useState<any>({
    carrierPacketFile: [],
    signAgreementFile: [],
    paidAsW9File: [],
    insuranceFile: [],
    voidedCheckBankLatterFile: [],
  });
  const handleCancel = () => {
    if (Object.keys(dirtyFields)?.length || paymentTermDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CLOSE,
          onOk: isValid
            ? () => {
                onSubmit(watch());
                dispatch(closeModal());
                setSearchParams({
                  ...searchParams,
                  mode: 'view',
                  id: '',
                });

                setTruckState({
                  skip: 0,
                  limit: 10,
                });
                clearErrors();
              }
            : () => {
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      clearErrors();
      resetForm();
      setSearchParams({
        ...searchParams,
        open: 'false',
        isAproveMode: 'false',
        id: '',
      });
      setAllFiles({
        carrierPacketFile: [],
        signAgreementFile: [],
        paidAsW9File: [],
        insuranceFile: [],
        voidedCheckBankLatterFile: [],
      });
      setTruckState({
        skip: 0,
        limit: 10,
      });
    }
  };

  const [localFiles, setLocalFiles] = useState<any>([]);

  const [uploadFileNOA] = useUploadFileNOAMutation();
  const userInfo = useSelector(selectUserInfo);

  const beforeUploadForCreate = (file: any, ctx: any, setLoading: any) => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', '1');
      uploadFileNOA(formData)
        .then(({ data: { result } }: any) => {
          setLocalFiles((prev: any) => [file, ...prev]);
          setValue(ctx, getValues(ctx) ? [...getValues(ctx), ...result!.ids] : result);
          setAllFiles((prev: any) => {
            prev[ctx].unshift({
              fullName: `${userInfo?.username}`,
              fileName: file.name,
              id: result?.ids?.[0],
              createdAt: new Date(),
              uid: file.uid,
              preview: file.preview,
              download: file.download,
            });
            return {
              ...prev,
            };
          });
          trigger(ctx);
        })
        .catch(() => null)
        .finally(() => {
          setLoading(false);
        });

      return false;
    } else {
      notification.error({
        message: FILE_MAX_SIZE_ERROR,
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  useEffect(() => {
    if (filesDataForEdit) {
      setAllFiles((prev: any) => {
        for (let i = 0; i < filesDataForEdit.result.length; i++) {
          if (
            !prev[IFileTypes[filesDataForEdit.result[i]?.carrierFileType]]?.find(
              (el: any) => el.id === filesDataForEdit.result[i]?.id
            )
          ) {
            prev[IFileTypes[filesDataForEdit.result[i]?.carrierFileType]].push({
              fullName: `${filesDataForEdit?.result[i]?.creator?.username}`,
              fileName: filesDataForEdit.result[i]?.name,
              id: filesDataForEdit?.result[i]?.id,
              createdAt: formatDate(filesDataForEdit?.result[i]?.createdAt),
              uid: filesDataForEdit?.result[i]?.id,
              preview: filesDataForEdit?.result[i]?.preview,
              download: filesDataForEdit?.result[i]?.download,
            });
          }
        }
        return {
          ...prev,
        };
      });
    }
  }, [filesDataForEdit]);

  const handleDeleteCarrierFileCreate = (fileId: number, ctx: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          setValue(
            ctx,
            getValues(ctx).filter((el: any) => el !== fileId)
          );
          setAllFiles((prev: any) => {
            const updated = prev[ctx].filter((el: any) => el.id !== fileId);
            return {
              ...prev,
              [ctx]: updated,
            };
          });
          trigger(ctx);
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDownloadCarrierFileCreate = (file: any) => {
    const fileToDownload = localFiles.find((el: any) => el.uid === file.uid);
    const blob = new Blob([fileToDownload]);

    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');

    downloadLink!.href = url;
    downloadLink.download = fileToDownload!.name;

    // Programmatically click the link to trigger the download
    downloadLink.click();

    // Clean up the temporary URL
    window.URL.revokeObjectURL(url);
  };

  const handleViewCarrierFileCreate = (file: any) => {
    const fileToView = localFiles.find((el: any) => el.uid === file.uid);
    if (fileToView) {
      const blob = new Blob([fileToView], { type: fileToView.type });

      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      window.URL.revokeObjectURL(url);
    }
  };

  const truckSectionRef = useRef(null);

  const drawerChildren: IChildren = {
    add: {
      component: (
        <CreateCarrierSignUp
          clearCarrierFormErrors={clearErrors}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          getValues={getValues}
          setValue={setValue}
          control={control}
          errors={errors}
          mode={'add'}
          statesData={statesData}
          activeSection={activeSection}
          beforeUpload={beforeUploadForCreate}
          allFiles={allFiles}
          handleDeleteCarrierFile={handleDeleteCarrierFileCreate}
          handleDownloadCarrieryFile={handleDownloadCarrierFileCreate}
          handleViewCarrieryFileEdit={handleViewCarrierFileCreate}
          // carrierInfo={carrierInfo}
          getFilesByType={getFilesByType}
          trigger={trigger}
          watch={watch}
          truckSectionRef={truckSectionRef}
          resetField={resetField}
          isCreateLoading={isCreateLoading}
          // isEditLoading={isEditLoading}
          handleTruckPaginationClick={handleTruckPaginationClick}
          // trucksByCarrierId={trucksByCarrierId}
          truckState={truckState}
          setTruckState={setTruckState}
          // refetchTrucksById={refetchTrucksById}
          setPaymentTermDirty={setPaymentTermDirty}
          isSubmitted={isSubmitted}
          dirtyFields={dirtyFields}
          panelShowed={panelShowed}
          setPanelShowed={setPanelShowed}
        />
      ),
      buttonText: SUBJECT.ADD,
      onCancel: handleCancel,
      onSubmit: handleSubmit(onSubmit),
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren['add']?.component,
      onSubmit: drawerChildren['add']?.onSubmit,
    };
  }, [pathname, errors, isDirty, allFiles, watch(), isCreateLoading]);

  return {
    currentChildren,
  };
};

export default useCarriersSignUp;
