import clsx from 'clsx';
import {
  CONNECTING_SECTIONS,
  INTERNAL_SECTIONS,
  REGULAR_SECTIONS,
} from 'pages/loads/components/edit-load/constants/constants';
import { MainLoader } from 'ui/main-loader/MainLoader';
import ScrollBar from 'ui/scroll-bar/ScrollBar';

import ConnectingEdit from './components/connecting-edit/ConnectingEdit';
import InternalEdit from './components/internal-edit/InternalEdit';
import RegularEdit from './components/regular-edit/RegularEdit';
import { LoadTypes } from './constants/types';
import { useEditLoad } from './useEditLoad';

const EditLoad = ({
  createLoadForm,
  loadFiles,
  loadTransactions,
  statesData,
  loadPointsAutocomplete,
  data,
  loadPoints,
  comments,
  createComment,
  deleteComment,
  getMoreComments,
  isShowMoreVisible,
  refetchTransaction,
  width,
  isFetching,
  refetchLoadById,
  refetchLoadPoints,
  refetchFilesByLoadId,
  isFilesFetching,
  isFormWithFooter,
  isUserEmployee,
  loadsPagePermissions,
  roleLevel,
  onSubmit,
  invalidLoadPointIds,
  connectingError,
  regularError,
  isLoadsCommentsLoading,
  handleCreateRateConfirmation,
  rateConfirmations,
  createRateConfCheck,
  pointsState,
  setPointsState,
  handleSendRateConfirmation,
  refetchRateConfirmations,
  filledPickUpDropOffs,
  submitted,
}: any) => {
  const { loadHistory, getMoreNotes, isShowMoreVisibleNotes, isFetchingHistory, carrierInsurance } = useEditLoad({
    id: data?.load?.id,
    carrierId: data?.load?.carrier?.id,
  });
  if (data?.load.loadType === LoadTypes.Regular) {
    return (
      <div
        className={clsx({
          ['scrollBarWrapper']: width === 'full',
        })}
      >
        {width === 'full' && <ScrollBar sections={REGULAR_SECTIONS} />}
        <div
          className={clsx('scrollBarContent', {
            ['openedDrawer']: width === 'full',
          })}
        >
          <MainLoader isFetching={isFetching} />

          <RegularEdit
            data={data}
            createLoadForm={createLoadForm}
            loadFiles={loadFiles}
            statesData={statesData}
            loadTransactions={loadTransactions}
            loadPoints={loadPoints}
            loadPointsAutocomplete={loadPointsAutocomplete}
            comments={comments}
            createComment={createComment}
            deleteComment={deleteComment}
            getMoreComments={getMoreComments}
            isShowMoreVisible={isShowMoreVisible}
            refetchTransaction={refetchTransaction}
            refetchLoadById={refetchLoadById}
            refetchLoadPoints={refetchLoadPoints}
            refetchFilesByLoadId={refetchFilesByLoadId}
            isUserEmployee={isUserEmployee}
            loadHistory={loadHistory}
            getMoreNotes={getMoreNotes}
            isShowMoreVisibleNotes={isShowMoreVisibleNotes}
            loadsPagePermissions={loadsPagePermissions}
            roleLevel={roleLevel}
            isFetching={isFetching}
            isLoadsCommentsLoading={isLoadsCommentsLoading}
            isFetchingHistory={isFetchingHistory}
            invalidLoadPointIds={invalidLoadPointIds}
            connectingError={connectingError}
            regularError={regularError}
            handleCreateRateConfirmation={handleCreateRateConfirmation}
            rateConfirmations={rateConfirmations}
            createRateConfCheck={createRateConfCheck}
            pointsState={pointsState}
            setPointsState={setPointsState}
            isFilesFetching={isFilesFetching}
            handleSendRateConfirmation={handleSendRateConfirmation}
            refetchRateConfirmations={refetchRateConfirmations}
            insurance={carrierInsurance}
            filledPickUpDropOffs={filledPickUpDropOffs}
            submitted={submitted}
          />
        </div>
      </div>
    );
  }
  if (data?.load.loadType === LoadTypes.Connecting) {
    return (
      <div
        className={clsx({
          ['scrollBarWrapper']: width === 'full',
        })}
      >
        {width === 'full' && <ScrollBar sections={CONNECTING_SECTIONS()} />}
        <div
          className={clsx('scrollBarContent', {
            ['openedDrawer']: width === 'full',
          })}
        >
          <MainLoader isFetching={isFetching} />

          <ConnectingEdit
            data={data}
            createLoadForm={createLoadForm}
            loadFiles={loadFiles}
            statesData={statesData}
            loadTransactions={loadTransactions}
            loadPoints={loadPoints}
            loadPointsAutocomplete={loadPointsAutocomplete}
            comments={comments}
            createComment={createComment}
            deleteComment={deleteComment}
            getMoreComments={getMoreComments}
            isShowMoreVisible={isShowMoreVisible}
            refetchTransaction={refetchTransaction}
            refetchLoadById={refetchLoadById}
            refetchLoadPoints={refetchLoadPoints}
            refetchFilesByLoadId={refetchFilesByLoadId}
            isUserEmployee={isUserEmployee}
            loadHistory={loadHistory}
            getMoreNotes={getMoreNotes}
            isShowMoreVisibleNotes={isShowMoreVisibleNotes}
            loadsPagePermissions={loadsPagePermissions}
            roleLevel={roleLevel}
            isFetching={isFetching}
            isLoadsCommentsLoading={isLoadsCommentsLoading}
            isFetchingHistory={isFetchingHistory}
            invalidLoadPointIds={invalidLoadPointIds}
            connectingError={connectingError}
            regularError={regularError}
            handleCreateRateConfirmation={handleCreateRateConfirmation}
            rateConfirmations={rateConfirmations}
            createRateConfCheck={createRateConfCheck}
            pointsState={pointsState}
            setPointsState={setPointsState}
            isFilesFetching={isFilesFetching}
            handleSendRateConfirmation={handleSendRateConfirmation}
            refetchRateConfirmations={refetchRateConfirmations}
            insurance={carrierInsurance}
            filledPickUpDropOffs={filledPickUpDropOffs}
            submitted={submitted}
          />
        </div>
      </div>
    );
  }
  if (data?.load.loadType === LoadTypes['Internal']) {
    return (
      <div
        className={clsx({
          ['scrollBarWrapper']: width === 'full',
        })}
      >
        {width === 'full' && <ScrollBar sections={INTERNAL_SECTIONS} />}
        <div
          className={clsx('scrollBarContent', {
            ['openedDrawer']: width === 'full',
          })}
        >
          <MainLoader isFetching={isFetching} />

          <InternalEdit
            data={data}
            createLoadForm={createLoadForm}
            loadFiles={loadFiles}
            statesData={statesData}
            loadTransactions={loadTransactions}
            loadPoints={loadPoints}
            loadPointsAutocomplete={loadPointsAutocomplete}
            comments={comments}
            createComment={createComment}
            deleteComment={deleteComment}
            getMoreComments={getMoreComments}
            isShowMoreVisible={isShowMoreVisible}
            refetchTransaction={refetchTransaction}
            refetchLoadById={refetchLoadById}
            refetchLoadPoints={refetchLoadPoints}
            refetchFilesByLoadId={refetchFilesByLoadId}
            isFormWithFooter={isFormWithFooter}
            isUserEmployee={isUserEmployee}
            loadHistory={loadHistory}
            getMoreNotes={getMoreNotes}
            isShowMoreVisibleNotes={isShowMoreVisibleNotes}
            loadsPagePermissions={loadsPagePermissions}
            onSubmit={onSubmit}
            isFetching={isFetching}
            isLoadsCommentsLoading={isLoadsCommentsLoading}
            isFetchingHistory={isFetchingHistory}
            invalidLoadPointIds={invalidLoadPointIds}
            connectingError={connectingError}
            regularError={regularError}
            pointsState={pointsState}
            setPointsState={setPointsState}
            filledPickUpDropOffs={filledPickUpDropOffs}
            submitted={submitted}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default EditLoad;
