import { useDetectedParams } from 'hooks/useDetectedParams';

const useTruckCarrierRequestBoard = () => {
  const { searchParams, setSearchParams } = useDetectedParams();

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      mode: 'edit',
      open: 'true',
    });
  };

  return { handleChangeMode };
};

export default useTruckCarrierRequestBoard;
