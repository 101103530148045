import React, { useDeferredValue } from 'react';
import Typography from 'ui/typography/Typography';

import Table from 'components/table/Table';

import { SUBJECT } from '../../constants/constants';
import { ICarriersParams } from '../../constants/types';

import useRequestTable from './useRequestTable';

import styles from '../../Carriers.module.scss';

const RequestTable = ({ setExcelParams, pagePermissions: { approve, deny } }: ICarriersParams) => {
  const {
    data,
    columns,
    isFetching,
    skip,
    limit,
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handleColumnSearch,
    handleCarrierActionClick,
    handlePaginationClick,
  } = useRequestTable({ setExcelParams, pagePermissions: { approve, deny } });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading: isFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onColumnSearch: handleColumnSearch,
    onPaginationClick: handlePaginationClick,
    onActionButton: handleCarrierActionClick,
    hasApprovePermission: approve,
    hasDenyPermission: deny,
    withMenu: false,
  });

  return (
    <>
      {!isFetching && !data && (
        <Typography
          children={SUBJECT.CARRIERS_REQUEST_LIST_IS_EMPTY}
          variant="paragraph"
          className={styles.carriersEmpty}
        />
      )}
      <Table {...tableProps} />
    </>
  );
};

export default RequestTable;
