import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import Button from 'ui/button/Button';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatPhone, multiplyDownloadWithDelay, multiplyViewWithDelay } from 'utils/helpers';

import CopyIcon from 'components/svgs/CopyIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { AuthorityTypes, SUBJECT } from '../../constants/constants';

import useAuthoritiesInfo from './useAuthoritiesInfo';

import styles from './AuthoritiesInfo.module.scss';

const AuthoritiesInfo = ({ data, isFetchingById, pagePermissions: { edit, terminateOn, terminateOff } }: any) => {
  const { handleChecked, handleChangeMode, copiedText, handleCopy, showMore, handleShowMore } = useAuthoritiesInfo();
  const checkedValue = !data?.result?.terminatedAt;
  const privacyTextLength = data?.result?.rateConfirmationDetails?.length > 500;
  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetchingById} />
      <ViewItemWrapper
        title={SUBJECT.AUTHORITIES_INFO}
        Component={
          edit && (
            <Button onClick={handleChangeMode} children={SUBJECT.EDIT} size="large" className="viewDrawerEditBtn" />
          )
        }
      >
        <Row gutter={[20, 14]}>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.NAME_COLON}</span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.name}
                subject={`${SUBJECT.AUTHORITIES_INFO}${SUBJECT.NAME_COLON}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.MC_NUMBER_COLON}</span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.mc}
                subject={`${SUBJECT.AUTHORITIES_INFO}${SUBJECT.MC_NUMBER_COLON}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.AUTHORITIES_ID_COLON}</span>{' '}
              <Col span={13}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === 'topBannerId',
                  })}
                >
                  <Typography variant="paragraph" children={data?.result?.id} className={styles.infoParagraph} />
                  <div
                    className={styles.copyIcon}
                    role="button"
                    onClick={() => handleCopy(data?.result?.id, 'topBannerId')}
                  >
                    <CopyIcon active={copiedText === 'topBannerId'} />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.TYPES_COLON}</span>{' '}
              <span className="top-banner-value">{AuthorityTypes[data?.result?.type]}</span>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.PHONE_NUMBER_COLON} </span>
              <Link className="top-banner-value" to={`tel:${data?.result?.phone}`}>
                {formatPhone(data?.result?.phone)}
              </Link>
            </div>
          </Col>
          {data?.result?.fax && (
            <Col span={8}>
              <div className={styles.infoColumns}>
                <span className="top-banner-title">{SUBJECT.FAX_COLON}</span>{' '}
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.fax}
                  subject={`${SUBJECT.AUTHORITIES_INFO}${SUBJECT.FAX_COLON}`}
                />
              </div>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.FULL_INFO}>
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.result?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AUTHORITY_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.result?.name}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.AUTHORITY_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.MC} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.result?.mc}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.MC}`}
                />
              </Col>
            </Row>
          </Col>

          {data?.result?.ff && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FF_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.ff}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.FF_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}

          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DOT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.result?.dot}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.DOT}`}
                />
              </Col>
            </Row>
          </Col>
          {data?.result?.stateNumber && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.STATE_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.stateNumber}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.STATE_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AUTHORITIES_ID} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === 'result',
                  })}
                >
                  <Typography variant="paragraph" children={data?.result?.id} className={styles.infoParagraph} />
                  <div className={styles.copyIcon} role="button" onClick={() => handleCopy(data?.result?.id, 'result')}>
                    <CopyIcon active={copiedText === 'result'} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AUTHORITIES_TYPE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={AuthorityTypes[data?.result?.type]}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          {!!data?.result?.templateId && AuthorityTypes[data?.result?.type] !== 'Carrier' && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.TEMPLATE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.template?.name}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!data?.result?.email && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.email}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.EMAIL}`}
                  />
                </Col>
              </Row>
            </Col>
          )}

          {data?.result?.bankName && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.BANK_NAME} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.bankName}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.BANK_NAME}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {data?.result?.accountingNumber && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ACCOUNTING_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.accountingNumber}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.ACCOUNTING_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {data?.result?.achRoutingNumber && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ACH_ROUTING_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.achRoutingNumber}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.ACH_ROUTING_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.MAIL_ADDRESS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.result?.address}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.MAIL_ADDRESS}`}
                />
              </Col>
            </Row>
          </Col>
          {data?.result?.secondAddress && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.SECOND_ADDRESS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.secondAddress}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.MAIL_CITY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.result?.city}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.MAIL_CITY}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.MAIL_STATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.result?.state.name} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.MAIL_ZIP} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.result?.zip} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Link className="top-banner-value" to={`tel:${data?.result?.phone}`}>
                  {formatPhone(data?.result?.phone)}
                </Link>
              </Col>
            </Row>
          </Col>
          {data?.result?.fax && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FAX} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.fax}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.FAX}`}
                  />
                </Col>
              </Row>
            </Col>
          )}

          {!!data?.result?.physicalCity && (
            <>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography
                      variant="heading"
                      children={SUBJECT.PHYSICAL_ADDRESS_VIEW}
                      className={styles.infoHeading}
                    />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={data?.result?.physicalAddress}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.PHYSICAL_ADDRESS_VIEW}`}
                    />
                  </Col>
                </Row>
              </Col>
              {data?.result?.physicalSecondAddress && (
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.PHYSICAL_SECOND_ADDRESS}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.result?.physicalSecondAddress}
                        subject={`${SUBJECT.FULL_INFO}${SUBJECT.PHYSICAL_SECOND_ADDRESS}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.PHYSICAL_CITY} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={data?.result?.physicalCity}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.PHYSICAL_CITY}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.PHYSICAL_STATE} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={data?.result?.physicalState?.name}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.PHYSICAL_ZIP} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={data?.result?.physicalZip}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}

          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AUTHORITY_PACKAGE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Row gutter={5}>
                  <Col>
                    <Button variant="ghost" onClick={() => multiplyViewWithDelay(data?.result?.packetFiles)}>
                      <EyeIconSvg outlined={true} />
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="ghost" onClick={() => multiplyDownloadWithDelay(data?.result?.packetFiles)}>
                      <NoaDownloadIcon />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Switch
                  checked={!data?.result?.terminatedAt || false}
                  onChange={handleChecked}
                  disabled={(checkedValue && !terminateOff) || (!checkedValue && !terminateOn)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.NOA} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Row gutter={5}>
                  <Col>
                    <Button variant="ghost" onClick={() => multiplyViewWithDelay(data?.result?.files)}>
                      <EyeIconSvg outlined={true} />
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="ghost" onClick={() => multiplyDownloadWithDelay(data?.result?.files)}>
                      <NoaDownloadIcon />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {data?.result?.creator.username && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={`${data?.result?.creator.username}`}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {data?.result?.brokerAgreementFiles.length && (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.BROKER_AGREEMENT_FILES}
                    className={styles.infoHeading}
                  />
                </Col>
                <Col span={13}>
                  <Row gutter={5}>
                    <Col>
                      <Button variant="ghost" onClick={() => multiplyViewWithDelay(data?.result?.files)}>
                        <EyeIconSvg outlined={true} />
                      </Button>
                    </Col>
                    <Col>
                      <Button variant="ghost" onClick={() => multiplyDownloadWithDelay(data?.result?.files)}>
                        <NoaDownloadIcon />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>

      {data?.result?.rateConfirmationDetails && (
        <ViewItemWrapper title={SUBJECT.RATE_CONFIRMATION_PRIVACY_POLICY}>
          <pre>
            {privacyTextLength ? (
              <>
                {showMore
                  ? data?.result?.rateConfirmationDetails
                  : `${data?.result?.rateConfirmationDetails.slice(0, 500)}...`}
              </>
            ) : (
              data?.result?.rateConfirmationDetails
            )}
            {privacyTextLength ? (
              <span
                className={clsx(styles.showMore, {
                  [styles.showMoreBlock]: showMore,
                })}
                onClick={handleShowMore}
              >
                {showMore ? 'Show less' : 'Show more'}
              </span>
            ) : null}
          </pre>
        </ViewItemWrapper>
      )}
    </div>
  );
};

export default AuthoritiesInfo;
