import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import Typography from 'ui/typography/Typography';
import { getHighlightedOptions } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import SearchIcon from 'components/svgs/SearchIcon';

import useDebounce from '../../hooks/useDebounce';

import { SUBJECT } from './constants/constants';
import { ISearchableDropdownOption, ISearchableDropdownProps } from './constants/types';

import styles from './SearchableDropdown.module.scss';

const SearchableDropdown = ({
  options,
  columns,
  isMulti = false,
  keys,
  required,
  label,
  values,
  setOffset,
  offset,
  setValue,
  onChangeFunc = () => {
    return;
  },
  errors,
  withLongInput,
  placeholder,
  disabled,
  isFilter,
  name,
  showId = false,
  showKey,
  shouldNotValidate,
  withoutBorder,
  allowClear,
  resetFunc,
  onBlur,
  width,
  withAll = true,
}: ISearchableDropdownProps) => {
  const {
    searchParams: { open: openQuery },
  } = useDetectedParams();

  const [selectedValues, setSelectedValues] = useState<ISearchableDropdownOption[]>(values || []);

  const [error, setError] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const debounceValue = useDebounce(searchValue, 500);

  const [isFocused, setIsFocused] = useState(false);

  const [open, setOpen] = useState(false);

  const columnWidth = 100 / columns.length - 2 + '%';

  useEffect(() => {
    if (typeof searchValue === 'string' && setOffset) {
      setOffset((prev: any) => ({ ...prev, search: debounceValue, limit: 10 }));
    }
  }, [debounceValue]);

  const handleChange = (data: string) => {
    setSearchValue(data);
    setOpen(true);
  };

  const handleClear = () => {
    if (!disabled) {
      setSearchValue('');
      if (setValue) {
        if (!isFilter) {
          setValue(name, [], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        } else {
          setValue(name, [], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }
      setSelectedValues([]);
      setOpen(false);
      setError(false);
      if (resetFunc) {
        resetFunc();
      }
    }
  };

  const inputRef = useOutsideDetect<HTMLDivElement>(setOpen);

  const handleFocus = () => {
    inputRef.current?.focus();
    setIsFocused(true);
    setOpen(true);
  };

  const handleSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: ISearchableDropdownOption) => {
    event.preventDefault();
    if (isMulti) {
      if (!selectedValues.some(item => item.id === value.id)) {
        setSelectedValues(prev => [...prev, value]);
        setSearchValue('');
      } else {
        setSelectedValues(prev => prev.filter(item => item.id !== value.id));
      }
    } else {
      setSelectedValues([value]);
      setSearchValue('');
      if (setValue) {
        if (!isFilter) {
          setValue(name, [value], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        } else {
          setValue(name, value?.id, {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }
    }
    if (onChangeFunc) onChangeFunc();
  };

  useEffect(() => {
    if (isMulti && setValue) {
      setValue(name, selectedValues, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedValues.length]);

  const handleCheckboxItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: ISearchableDropdownOption
  ) => {
    event.stopPropagation();

    if (!selectedValues.some(item => item.id === value.id)) {
      setSelectedValues(prev => [...prev, value]);
    } else {
      setSelectedValues(prev => prev.filter(item => item.id !== value.id));
    }
  };

  const handleSelectAll = () => {
    if (!selectedValues.length) {
      setSelectedValues(options);
    } else {
      setSelectedValues([]);
    }
  };
  const [blurCount, setBlurCount] = useState(0);

  const handleErrors = () => {
    if (!shouldNotValidate) {
      // todo added errors?.name
      if (!selectedValues?.length && !searchValue?.length && required && errors?.name) {
        setError(true);
      } else {
        setError(false);
      }
    }
    setIsFocused(false);
  };

  useEffect(() => {
    if (blurCount) handleErrors();
  }, [blurCount, selectedValues]);

  const dropdownRef = useRef(null);

  const handleScroll = (event: any) => {
    const { target } = event;
    if (target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight) {
      if (offset && options?.length < offset) {
        setOffset((prev: any) => {
          return { ...prev, limit: prev.limit + 10 };
        });
      }
    }
  };

  useEffect(() => {
    if (!isMulti && !!selectedValues?.length) {
      setTimeout(() => {
        inputRef.current?.blur();
      }, 0);
      setIsFocused(false);
      setOpen(false);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (!values?.length) {
      setSearchValue('');
      setSelectedValues([]);
    } else {
      setSelectedValues(values);
    }
    if (typeof values === 'string') {
      setSelectedValues(options?.filter(option => option.id === values));
    }
    if (isFilter && !values?.length) {
      setSearchValue('');
      setSelectedValues([]);
    }
  }, [values, isFilter]);

  useEffect(() => {
    if (isFilter && typeof values === 'string') {
      setSelectedValues(options.filter(item => item.id === values));
    }
  }, [isFilter, values]);

  useEffect(() => {
    if (!open && !!searchValue?.length && !shouldNotValidate) {
      setError(true);
    }
  }, [searchValue, open]);

  useEffect(() => {
    if (openQuery === 'true') {
      setError(false);
    }
  }, [openQuery]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Backspace' && selectedValues.length) {
      handleClear();
      setOpen(true);
    }
  };

  const inputContainerRef = useRef<HTMLDivElement>(null);
  const borderWidth = inputContainerRef?.current?.getBoundingClientRect().width;

  const element = document.getElementById(`drodownWrapper${label}${placeholder}`);

  const outViewPort =
    Number(element?.parentElement?.getBoundingClientRect().left) + Number(element?.getBoundingClientRect().width) >
    window.innerWidth;

  return (
    <div className={styles.searchableDropdownMain}>
      {!!label && <label>{label}</label>}

      <div
        className={clsx(styles.searchableDropdownWrapper, {
          [styles.disabled]: disabled || disabled === 'true',
          [styles.wrapperLong]: withLongInput,
          [styles.required]: required,
          [styles.error]: (error && !isFilter) || errors?.[name || ''],
          [styles.onFocus]: isFocused || open,
          [styles.filled]: open || !!selectedValues?.length || !!searchValue?.length,
        })}
        onBlur={() => {
          if (onBlur) {
            onBlur();
            setSearchValue('');
          }
          setBlurCount(prev => prev + 1);
        }}
        ref={inputRef}
        onFocus={handleFocus}
      >
        <div className={styles.inputContainer} ref={inputContainerRef}>
          {!!selectedValues?.length && isMulti && !allowClear && (
            <div className={styles.selectedItems}>
              <Typography variant="paragraph">+{selectedValues?.length}</Typography>
              <div role="button" onClick={handleClear}>
                <CloseIcon />
              </div>
            </div>
          )}
          {!isMulti && !!selectedValues?.length && !searchValue?.length && !isFocused && (
            <div className={styles.selectedItemsSingle}>
              <Typography variant="paragraph">{selectedValues[0]?.name || selectedValues[0]?.[showKey!]}</Typography>
            </div>
          )}

          <input
            disabled={disabled as boolean}
            type="text"
            value={searchValue}
            placeholder={label && !selectedValues?.length ? placeholder : undefined}
            className={styles.inputBlock}
            onKeyDown={handleKeyDown}
            onChange={({ target: { value } }) => {
              handleChange(value);
            }}
          />
          {!label && !!placeholder && (
            <Typography variant="paragraph" className={clsx(styles.placeholder)}>
              {placeholder}
            </Typography>
          )}
          <div className={styles.suffixIconBlock} onClick={handleClear}>
            {(searchValue || !!selectedValues?.length) && !allowClear ? (
              <div>
                <CloseIcon />
              </div>
            ) : (
              <SearchIcon />
            )}
          </div>
        </div>
        <div
          id={`drodownWrapper${label}${placeholder}`}
          className={clsx(styles.dropdownContainerWrapper, {
            [styles.dropdownRight]: outViewPort,
            [styles.dropdownOpen]: open,
          })}
          style={{ minWidth: `${width}px` }}
        >
          {width ? (
            <span className={styles.mistakeBorder} style={{ width: `${width - (borderWidth || 0)}px` }} />
          ) : null}
          <div className={styles.dropdownWrapper}>
            <div className={styles.innerScroller} onScroll={handleScroll} ref={dropdownRef}>
              <div className={styles.dropdownColumnsSection}>
                <Row align="middle" justify="space-between">
                  {isMulti && withAll && (
                    <Col>
                      <label>
                        <input
                          className={clsx(styles.checkbox, {
                            [styles.selected]: !!selectedValues?.length && selectedValues?.length !== options?.length,
                            [styles.selectedAll]: selectedValues?.length === options?.length,
                          })}
                          type="checkbox"
                          onClick={handleSelectAll}
                          checked={!!selectedValues?.length}
                        />
                        <span className={styles.checkboxMark}></span>
                      </label>
                    </Col>
                  )}
                  {isMulti && !withAll && (
                    <Col>
                      <div style={{ width: 20 }}></div>
                    </Col>
                  )}
                  {columns?.map((column, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Col
                          span={
                            column.key.toLowerCase() === 'name' || column.key.toLowerCase() === 'title'
                              ? 12
                              : column.key.toLowerCase() === 'mc'
                              ? 6
                              : column.key.toLowerCase() === 'surname' || column.key.toLowerCase() === 'username'
                              ? 6
                              : column.key.toLowerCase() === 'value' ||
                                column.key.toLowerCase() === 'id' ||
                                column.key.toLowerCase() === 'mcid'
                              ? 6
                              : undefined
                          }
                          style={{
                            width: columnWidth,
                            padding: '0 5px',
                            pointerEvents: 'none',
                            whiteSpace: 'nowrap',
                          }}
                          key={index}
                        >
                          <span>{column.name}</span>
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </div>
              <div className={styles.dropdownOptionsSection}>
                <Row align="middle">
                  {options?.length ? (
                    options?.map(option => {
                      return (
                        <Row
                          className={clsx(styles.optionItem, {
                            [styles.withoutBorder]: withoutBorder,
                          })}
                          key={option.id}
                          onClick={event => handleSelect(event, option)}
                          justify="space-between"
                        >
                          {isMulti && (
                            <Col>
                              <label>
                                <input
                                  className={styles.checkbox}
                                  type="checkbox"
                                  onClick={event => handleCheckboxItem(event, option)}
                                  checked={selectedValues.some(item => item.id === option.id)}
                                />
                                <span className={styles.checkboxMark}></span>
                              </label>
                            </Col>
                          )}

                          {keys?.map(key => {
                            const matched = getHighlightedOptions(searchValue!, String(option[key]));
                            if (key !== 'id' || showId) {
                              return (
                                <>
                                  {matched?.length ? (
                                    <Col
                                      style={{
                                        width: columnWidth,
                                        padding: '0 5px',
                                      }}
                                      className={clsx(styles.resultText, {
                                        [styles.firstTextItem]:
                                          key.toLowerCase() === 'name' ||
                                          key.toLowerCase() === 'title' ||
                                          key.toLowerCase() === 'lastname' ||
                                          key.toLowerCase() === 'username',
                                      })}
                                      span={
                                        key.toLowerCase() === 'name' || key.toLowerCase() === 'title'
                                          ? 12
                                          : key.toLowerCase() === 'mc'
                                          ? 6
                                          : key.toLowerCase() === 'surname' || key.toLowerCase() === 'username'
                                          ? 6
                                          : key.toLowerCase() === 'value' ||
                                            key.toLowerCase() === 'id' ||
                                            key.toLowerCase() === 'mcid'
                                          ? 6
                                          : undefined
                                      }
                                    >
                                      {matched[0]}
                                      <span className={styles.searchResultKeyword}>{matched[1]}</span>
                                      {matched[2]}
                                    </Col>
                                  ) : (
                                    <Col
                                      style={{
                                        width: columnWidth,
                                        padding: '0 5px',
                                      }}
                                      className={clsx(styles.resultText, {
                                        [styles.firstTextItem]:
                                          key.toLowerCase() === 'name' ||
                                          key.toLowerCase() === 'title' ||
                                          key.toLowerCase() === 'lastname' ||
                                          key.toLowerCase() === 'username',
                                      })}
                                      span={
                                        key.toLowerCase() === 'name' || key.toLowerCase() === 'title'
                                          ? 12
                                          : key.toLowerCase() === 'mc'
                                          ? 6
                                          : key.toLowerCase() === 'surname' || key.toLowerCase() === 'username'
                                          ? 6
                                          : key.toLowerCase() === 'value' ||
                                            key.toLowerCase() === 'id' ||
                                            key.toLowerCase() === 'mcid'
                                          ? 6
                                          : undefined
                                      }
                                    >
                                      {option[key]}
                                    </Col>
                                  )}
                                </>
                              );
                            }
                          })}
                        </Row>
                      );
                    })
                  ) : (
                    <Typography variant="paragraph" className={styles.notDataItem} children={SUBJECT.NO_DATA} />
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchableDropdown;
