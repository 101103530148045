import { LoadHistoryMessageTypes } from './types';

export const linkGeneratorForLoadHistory = ({ type, id, loadId }: any) => {
  switch (type) {
    case LoadHistoryMessageTypes.AGENT:
      return `/profile?tab=agents&open=true&mode=view&headerFilter=carriers&id=${id}`;
    case LoadHistoryMessageTypes.EMPLOYEE:
      return `/profile?tab=employees&open=true&mode=view&activePage=corporate&headerFilter=carriers&id=${id}`;
    case LoadHistoryMessageTypes.BRANCH:
      return `/profile?tab=branches&open=true&mode=view&headerFilter=carriers&id=${id}`;
    case LoadHistoryMessageTypes.OFFICE:
      return `/profile?tab=offices&open=true&mode=view&headerFilter=carriers&id=${id}`;
    case LoadHistoryMessageTypes.CUSTOMER:
      return `/profile?tab=customers&open=true&mode=view&activePage=customer-table&headerFilter=carriers&id=${id}`;
    case LoadHistoryMessageTypes.CARRIER:
      return `/profile?tab=carriers&open=true&mode=view&activePage=table&headerFilter=carriers&id=${id}`;
    case LoadHistoryMessageTypes.COMMENT:
      return `/loads?tab=loads&open=true&mode=comments&headerFilter=carriers&id=${loadId}`;
    case LoadHistoryMessageTypes.TRANSACTION:
      return `/loads?tab=loads&open=true&mode=regularLoad&headerFilter=carriers&id=${id}`;
  }
};
