export const loadTableUpdateCases = [
  'load.create',
  'load.createTonu',
  'load.addSecondAgent',
  'load.deleteFile',
  'load.editReferenceNumber',
  'load.claim',
  'load.unclaim',
  'load.createTransaction',
  'load.deleteTransaction',
  'load.createComment',
  'load.deleteComment',
  'load.cancel',
  'load.sendToBilling',
  'load.reject',
  'load.acceptToBilling',
  'load.carrierPaySingle',
  'load.carrierPayMultiple',
  'load.customerReceiveSingleRemaining',
  'load.customerReceiveMultiple',
  'load.addPickup',
  'load.editPickup',
  'load.deletePickup',
  'load.addDropOff',
  'load.editDropOff',
  'load.deleteDropOff',
  'load.createCredit',
];

export const factoringBillingUpdateCases = [
  'load.toRetrieved',
  'load.sendToBilling',
  'load.reject',
  'load.acceptToBilling',
  'load.claim',
  'load.unclaim',
];
export const factoringPayablesUpdateCases = [
  'load.acceptToBilling',
  'load.carrierPaySingle',
  'load.carrierPayMultiple',
];
export const factoringReceivablesUpdateCases = [
  'load.toRetrieved',
  'load.acceptToBilling',
  'load.customerReceiveSingleFull',
  'load.customerReceiveMultiple',
  'load.customerReceiveSingleRemaining',
];
export const newCreditUpdateCases = [
  'load.creditApproved',
  'load.creditCOD',
  'load.creditConsignment',
  'load.creditDeny',
];
export const creditCheckUpdateCases = ['load.createCredit', 'refreshPage.createCredit'];

export const refreshPageCases = [
  'refreshPage.createCredit',
  'refreshPage.addLoad',
  'refreshPage.acceptToBilling',
  'refreshPage.sendToBilling',
];

export enum RefreshPages {
  BILLING = 'refreshPage.sendToBilling',
  PAYABLES = 'refreshPage.acceptToBilling',
  RECEIVABLES = 'refreshPage.acceptToBilling',
  CREDIT_CHECK = 'refreshPage.createCredit',
  LOAD = 'refreshPage.addLoad',
}

export enum NotificationEventTypes {
  USER_CREATE_AGENT = 'agent.create',
  USER_CREATE_MANAGER = 'agent.manager',
  USER_CREATE_OWNER = 'agent.owner',
  USER_EDIT_AGENT = 'agent.editAgent',
  USER_EDIT_MANAGER = 'agent.editManager',
  USER_EDIT_OWNER = 'agent.editOwner',
  USER_ACTIVATE_AGENT = 'agent.activateAgent',
  USER_ACTIVATE_MANAGER = 'agent.activateManager',
  USER_ACTIVATE_OWNER = 'agent.activateOwner',
  USER_TERMINATE_AGENT = 'agent.terminateAgent',
  USER_TERMINATE_MANAGER = 'agent.terminateManager',
  USER_TERMINATE_OWNER = 'agent.terminateOwner',
  USER_DELETE_FILE_AGENT_AGENT = 'agent.fileDeleteAgent',
  USER_DELETE_FILE_AGENT_MANAGER = 'agent.fileDeleteManager',
  USER_DELETE_FILE_AGENT_OWNER = 'agent.fileDeleteOwner',
  USER_AGENT_EDIT_USERNAME_AGENT = 'agent.editUsernameAgent',
  USER_AGENT_EDIT_USERNAME_OWNER = 'agent.editUsernameOwner',
  USER_AGENT_EDIT_USERNAME_MANAGER = 'agent.editUsernameManager',

  USER_CREATE_EMPLOYEE_CORPORATE = 'employee.createCorporate',
  USER_CREATE_EMPLOYEE_OFFICE = 'employee.createOffice',
  USER_CREATE_EMPLOYEE_BRANCH = 'employee.createBranch',
  USER_ACTIVATE_EMPLOYEE_CORPORATE = 'employee.activateCorporate',
  USER_ACTIVATE_EMPLOYEE_OFFICE = 'employee.activateOffice',
  USER_ACTIVATE_EMPLOYEE_BRANCH = 'employee.activateBranch',
  USER_TERMINATE_EMPLOYEE_CORPORATE = 'employee.terminateCorporate',
  USER_TERMINATE_EMPLOYEE_OFFICE = 'employee.terminateOffice',
  USER_TERMINATE_EMPLOYEE_BRANCH = 'employee.terminateBranch',
  USER_EDIT_EMPLOYEE_CORPORATE = 'employee.editCorporate',
  USER_EDIT_EMPLOYEE_OFFICE = 'employee.editOffice',
  USER_EDIT_EMPLOYEE_BRANCH = 'employee.editBranch',
  USER_EDIT_EMPLOYEE_CORPORATE_USERNAME = 'employee.editCorporateUsername',
  USER_EDIT_EMPLOYEE_OFFICE_USERNAME = 'employee.editOfficeUsername',
  USER_EDIT_EMPLOYEE_BRANCH_USERNAME = 'employee.editBranchUsername',
  USER_EDIT_EMPLOYEE_CORPORATE_PERMISSION = 'employee.corporateEditPermission',
  USER_EDIT_EMPLOYEE_OFFICE_PERMISSION = 'employee.officeEditPermission',
  USER_EDIT_EMPLOYEE_BRANCH_PERMISSION = 'employee.branchEditPermission',
  USER_DELETE_FILE_EMPLOYEE_CORPORATE = 'employee.fileDeleteCorporate',
  USER_DELETE_FILE_EMPLOYEE_OFFICE = 'employee.fileDeleteOffice',
  USER_DELETE_FILE_EMPLOYEE_BRANCH = 'employee.fileDeleteBranch',
}

export enum truckUpdateCases {
  hasALoad = 'truck.hasALoad',
  addedSearch = 'truck.addedSearch',
  truckCreate = 'truck.create',
}
