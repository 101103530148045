import { useDeferredValue } from 'react';
import { Col, Divider, Row } from 'antd';
import Button from 'ui/button/Button';

import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import Table from 'components/table/Table';

import { EmployeeLevels, UserTypes } from '../../../../utils/constants';
import TruckBoardSingle from '../truck-board-single/TruckBoardSingle';

import { equipmentTypeOptions, statusOptions, SUBJECT, trailerDetailsOptions } from './constants/constsants';
import { ITruckBoardStatus } from './constants/types';
import { FilterSelect } from './hoc-instance/instance';
import useTruckBoardResult from './useTruckBoardResult';

import styles from './TruckBoardResult.module.scss';

const TruckBoardResult = ({
  columns,
  truckBoardResultList,
  isTruckBoardResultListFetching,
  searchResultFilter,
  setSearchResultFilter,
  hasDownloadPermission,
  hasCreateSearchPermission,
}: any) => {
  const {
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handleColumnSearch,
    handlePaginationClick,
    handleRowEdit,
    handleRowDelete,
    handleResetSearchResultFilter,
    searchResultFilterControl,
    searchResultFilterWatch,
    userType,
    employeeLevel,
    isSingle,
    singleStatus,
    status,
    searchInfoData,
    isSearchInfoFetching,
    searchResultsData,
    searchResultsColumns,
    isSearchResultsLoading,
    searchResultsFilter,
    handleStopResizeSearchResult,
    setSearchResultsFilter,
    searchResultsTableRef,
    id,
  } = useTruckBoardResult({
    searchResultFilter,
    setSearchResultFilter,
  });

  const tableProps = useDeferredValue({
    data: truckBoardResultList,
    skip: searchResultFilter?.skip,
    limit: searchResultFilter?.limit,
    columns: columns,
    isLoading: isTruckBoardResultListFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onPaginationClick: handlePaginationClick,
    onColumnSearch: handleColumnSearch,
    handleRowDelete: handleRowDelete,
    handleRowEdit: handleRowEdit,
    hasEditPermission: hasCreateSearchPermission,
    hasDeletePermission: true,
    withMenu: false,
    highlightedRowId: singleStatus === status && id,
  });

  return (
    <>
      <div className={styles.truckBoardResultWrapper}>
        <div className={styles.customersTopBlock}>
          <div className="filtersWrapper">
            <Row align="middle" gutter={[15, 0]}>
              <Col lg={6} xxl={4}>
                <FilterSelect
                  withAll
                  withCheckbox
                  isMultiSelect
                  options={equipmentTypeOptions}
                  control={searchResultFilterControl}
                  label={SUBJECT.EQUIPMENT_TYPE}
                  placeholder={SUBJECT.NOT_SELECTED}
                  name="equipmentType"
                  isFilter
                />
              </Col>
              <Col lg={6} xxl={4}>
                <FilterSelect
                  withAll
                  withCheckbox
                  isMultiSelect
                  options={trailerDetailsOptions}
                  control={searchResultFilterControl}
                  label={SUBJECT.TRAILER_DETAILS}
                  placeholder={SUBJECT.NOT_SELECTED}
                  name="trailerDetails"
                  isFilter
                />
              </Col>
              <Col lg={6} xxl={4}>
                <FilterSelect
                  suffixIcon={<SelectArrowIcon />}
                  options={
                    userType == UserTypes.ADMIN ||
                    (userType == UserTypes.EMPLOYEE && employeeLevel == EmployeeLevels.CORPORATE)
                      ? statusOptions
                      : statusOptions.filter(el => el.value !== ITruckBoardStatus.transit)
                  }
                  control={searchResultFilterControl}
                  label={SUBJECT.STATUS}
                  placeholder={SUBJECT.NOT_SELECTED}
                  name="status"
                  isFilter
                />
              </Col>

              {(!!searchResultFilterWatch('equipmentType')?.length ||
                !!searchResultFilterWatch('trailerDetails')?.length ||
                searchResultFilterWatch('status') !== ITruckBoardStatus.onBoard) && (
                <Col>
                  <div className={styles.reset}>
                    <Button
                      icon={<ResetIcon />}
                      variant="ghost"
                      onClick={handleResetSearchResultFilter}
                      children={SUBJECT.RESET_ALL}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <Table {...tableProps} />
        {!!isSingle && !!id && <Divider className={styles.divider} />}
      </div>
      {!!isSingle && !!id && (
        <TruckBoardSingle
          hasDownloadPermission={hasDownloadPermission}
          searchInfoData={searchInfoData}
          isSearchInfoFetching={isSearchInfoFetching}
          searchResultsData={searchResultsData}
          searchResultsColumns={searchResultsColumns?.columns}
          isSearchResultsLoading={isSearchResultsLoading}
          searchResultsFilter={searchResultsFilter}
          handleStopResizeSearchResult={handleStopResizeSearchResult}
          searchResultFilter={searchResultFilter}
          setSearchResultsFilter={setSearchResultsFilter}
          searchResultsTableRef={searchResultsTableRef}
        />
      )}
    </>
  );
};
export default TruckBoardResult;
