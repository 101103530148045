import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { editEmployeeDeserializer, inviteEmployeeDeserializer } from './deserializers';
import { IEmployessParams } from './interface';
import { getEmployeesSerializer } from './serializers';

export const employeesAPI = createApi({
  reducerPath: 'employeesAPI',
  baseQuery,
  tagTypes: ['Employees'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getEmployees: builder.query({
      query: ({ level, search, field, skip, limit, order, orderBy, filter }: Partial<IEmployessParams>) => ({
        url: 'employees',
        params: { level, search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getEmployeesSerializer(body),
      providesTags: ['Employees'],
    }),

    getEmployeesById: builder.query({
      query: id => `employees/${id}`,
      providesTags: ['Employees'],
    }),
    inviteEmployee: builder.mutation({
      query: body => ({
        url: 'employees/invite',
        method: 'POST',
        body: inviteEmployeeDeserializer(body),
      }),
      invalidatesTags: ['Employees'],
    }),
    updateEmployee: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `employees/${id}`,
        method: 'PUT',
        body: editEmployeeDeserializer(rest),
      }),
    }),
    addNewFileEmployee: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `users/file`,
        method: 'POST',
        body: { userId: Number(id), fileId: Number(fileId) },
      }),
    }),
    deleteFileFromEmployee: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `users/file`,
        method: 'DELETE',
        body: { userId: Number(id), fileId: Number(fileId) },
      }),
    }),
    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `employees/${id}/status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: ['Employees'],
    }),
  }),
});

export const {
  useInviteEmployeeMutation,
  useGetEmployeesByIdQuery,
  useGetEmployeesQuery,
  useChangeStatusMutation,
  useUpdateEmployeeMutation,
  useDeleteFileFromEmployeeMutation,
  useAddNewFileEmployeeMutation,
} = employeesAPI;
