export const TrashIcon = () => {
  return (
    <svg cursor="pointer" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33102 17.9148C2.93508 17.9148 2.59854 17.7762 2.32138 17.4991C2.04422 17.2219 1.90565 16.8854 1.90565 16.4894V2.94836H0.931641V1.52299H5.39781V0.810303H11.6695V1.52299H16.1356V2.94836H15.1616V16.4894C15.1616 16.8695 15.0191 17.2021 14.734 17.4872C14.4489 17.7723 14.1164 17.9148 13.7363 17.9148H3.33102ZM13.7363 2.94836H3.33102V16.4894H13.7363V2.94836ZM5.84918 14.4464H7.27456V4.96764H5.84918V14.4464ZM9.79272 14.4464H11.2181V4.96764H9.79272V14.4464Z"
        fill="#667695"
      />
    </svg>
  );
};
