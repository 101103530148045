import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { LoadTypes } from 'pages/loads/components/create-load/constants/types';
import { useLazyGetCreditRequestForAgentsQuery, useLazyGetCreditRequestForEmployeesQuery } from 'services/loads/loads';
import { useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { selectLoadUpdate } from 'store/loads-slice/selector';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { UserTypes } from 'utils/constants';

import { INewLoadParams, INewLoadTableAction } from './constants/types';

export const useNewLoad = (setExcelParams: any) => {
  const dispatch = useDispatch();

  const { userType } = useSelector(selectUserPermissionsInfo);
  const updatedLoad = useSelector(selectLoadUpdate);

  const { searchParams, setSearchParams } = useDetectedParams();

  const { data: columnsData } = useGetColumnsQuery({
    type: UserTypes.AGENT === userType ? 'newLoad' : 'newCredit',
  });
  const [editColumns] = useEditColumnsMutation();

  const [getNewLoadFilterForAgents, setNewLoadFilterForAgents] = useState<INewLoadParams>({
    skip: 0,
    limit: 20,
    order: 1,
    orderBy: 'status',
    search: '',
    field: '',
  });

  const [getNewLoadFilterForEmployee, setNewLoadFilterForEmployee] = useState<INewLoadParams>({
    skip: 0,
    limit: 20,
    order: 2,
    search: '',
    field: '',
  });

  useEffect(() => {
    if (userType && setExcelParams) {
      if (UserTypes.AGENT === userType) {
        setExcelParams((prev: any) => {
          return {
            ...prev,
            ...getNewLoadFilterForAgents,
          };
        });
      } else {
        setExcelParams((prev: any) => {
          return {
            ...prev,
            ...getNewLoadFilterForEmployee,
          };
        });
      }
    }
  }, [getNewLoadFilterForEmployee, getNewLoadFilterForAgents]);

  const [getCreditRequestForAgents, { data, isFetching: isLoading }] = useLazyGetCreditRequestForAgentsQuery();
  const [getCreditRequestForEmployees, { data: dataForEmployees, isFetching: isLoadingForEmployees }] =
    useLazyGetCreditRequestForEmployeesQuery();

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  useEffect(() => {
    if (userType) {
      if (UserTypes.AGENT === userType) {
        getCreditRequestForAgents(getNewLoadFilterForAgents);
      } else {
        getCreditRequestForEmployees(getNewLoadFilterForEmployee);
      }
    }
  }, [getNewLoadFilterForAgents, getNewLoadFilterForEmployee, userType, updatedLoad]);

  const handleColumnSearch = (value: string, column: string) => {
    if (UserTypes.AGENT === userType) {
      setNewLoadFilterForAgents((prev: any) => {
        return {
          ...prev,
          search: String(value),
          field: column,
          skip: 0,
          limit: prev.limit,
        };
      });
    } else if (UserTypes.EMPLOYEE === userType) {
      setNewLoadFilterForEmployee((prev: any) => {
        return {
          ...prev,
          search: String(value),
          field: column,
          skip: 0,
          limit: prev.limit,
        };
      });
    }
  };

  const handleRowClick = (rowId: string, rowData: any) => {
    if (rowData?.loadType === LoadTypes.Connecting) {
      setSearchParams({
        ...searchParams,
        mode: 'view',
        open: 'true',
        id: rowId,
      });
    } else {
      setSearchParams({
        ...searchParams,
        mode: 'viewNewLoad',
        open: 'true',
        id: rowId,
      });
    }
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    if (userType) {
      if (UserTypes.AGENT === userType) {
        setNewLoadFilterForAgents((prev: any) => {
          if (sortOrder === 0)
            return {
              ...prev,
              order: 1,
              orderBy: 'status',
            };
          return {
            ...prev,
            order: sortOrder,
            orderBy: dataIndex,
          };
        });
      } else {
        setNewLoadFilterForEmployee((prev: any) => {
          if (sortOrder === 0)
            return {
              skip: 0,
              limit: prev.limit,
              order: 1,
              orderBy: 'status',
            };
          return {
            ...prev,
            order: sortOrder,
            orderBy: dataIndex,
          };
        });
      }
    }
  };

  const handleStopResize = (columns: []) => {
    editColumns({ type: 'newLoad', columns });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    if (userType) {
      if (UserTypes.AGENT === userType) {
        setNewLoadFilterForAgents(prev => ({
          ...prev,
          limit,
          skip,
        }));
      } else {
        setNewLoadFilterForEmployee(prev => ({
          ...prev,
          limit,
          skip,
        }));
      }
    }
  };

  const handleRowType = (data: INewLoadTableAction) => {
    setSearchParams({
      ...searchParams,
      customerName: String(data?.customerName),
      id: String(data.id),
      mode: 'create',
      open: 'true',
    });
  };

  const handleCommentClick = (id: string, rowData: any) => {
    if (rowData.loadType === LoadTypes.Connecting) {
      setSearchParams({
        ...searchParams,
        mode: 'comments',
        open: 'true',
        id,
      });
    } else {
      setSearchParams({
        ...searchParams,
        mode: 'newLoadComment',
        open: 'true',
        id: id,
      });
    }
  };

  useEffect(() => {
    if (data?.result) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result]);

  useEffect(() => {
    if (UserTypes.AGENT === userType && !data?.result.length && getNewLoadFilterForAgents?.skip > 0) {
      setNewLoadFilterForAgents((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [data?.result.length]);

  useEffect(() => {
    if (!dataForEmployees?.result.length && getNewLoadFilterForEmployee?.skip > 0) {
      setNewLoadFilterForEmployee((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [dataForEmployees?.result.length]);

  return {
    data: UserTypes.AGENT === userType ? data : dataForEmployees,
    columns: columnsData?.columns,
    isLoading: UserTypes.AGENT === userType ? isLoading : isLoadingForEmployees,
    skip: UserTypes.AGENT === userType ? getNewLoadFilterForAgents.skip : getNewLoadFilterForEmployee.skip,
    limit: UserTypes.AGENT === userType ? getNewLoadFilterForAgents.limit : getNewLoadFilterForEmployee.limit,
    handleRowClick,
    handleSortClick,
    handleColumnSearch,
    handlePaginationClick,
    handleStopResize,
    handleRowType,
    handleCommentClick,
  };
};
