export enum SUBJECT {
  BILLING_IS_EMPTY = 'Billing Is Empty',
  TOTAL = 'Total:',
  RESET_ALL = 'Reset All',
  PAYMENT_METHOD = 'Payment Method',
  AGENT = 'Agent',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  NOT_SELECTED = 'Not Selected',
  SEARCH = 'Search',
  SAVE = 'Save',
  CARRIER_PAYMENT_METHOD = 'Carrier Payment Method',
  LOAD_REG_DATE = 'Load Reg.Date',
  CUSTOMER_PAYMENT_METHOD = 'Customer Payment Method',
  EXPECTED = 'Pending',
  LOAD = 'Load:',
  QUICK_PAY = 'QuickPay %:',
  CUSTOM = 'Custom %:',
  MUST_PAY = 'Pending',
  PAID = 'Paid',
}

export const filterDefaultValues = {
  loadRegDateFilter: null,
  customerPaymentMethod: null,
  carrierPaymentMethod: null,
  officeBilling: [],
  branchBilling: [],
  agentBilling: [],
};

export const CUSTOMER_PAYMENT_METHOD = [
  {
    title: 'All',
    value: '0',
  },
  {
    title: 'Quick Pay',
    value: '2',
  },
  {
    title: 'Custom',
    value: '3',
  },
  {
    title: 'Factoring',
    value: '1',
  },
];

export const CARRIER_PAYMENT_METHOD = [
  {
    title: 'All',
    value: '0',
  },
  {
    title: 'Quick pay',
    value: '1',
  },
  {
    title: 'Factoring',
    value: '2',
  },
];

export const BILLING_TYPE_COLUMNS = [
  { key: 'name', name: 'Carrier Name' },
  { key: 'mc', name: 'MC Number' },
];

export const BILLING_TYPE_OPTIONS = [
  { id: '1', name: 'John', mc: '24456789' },
  { id: '2', name: 'Alex', mc: '98876897' },
  { id: '3', name: 'Johnathan', mc: '65656548' },
];

export const BILLING_TYPE_KEYS = Object.keys(BILLING_TYPE_OPTIONS[0]);

export const AGENT_COLUMNS = [
  { key: 'firstName', name: 'Name' },
  { key: 'lastName', name: 'Last Name' },
  { key: 'username', name: 'Username' },
];

export const AGENT_KEYS = ['id', 'firstName', 'lastName', 'username'];
