export enum SUBJECT {
  RESET = 'Reset',
  NO_PERMISSION = 'You Have No Permission For Creating',
  ON_BOARD_COUNT = 'On Board: ',
  OFF_BOARD_COUNT = 'Off Board: ',
  TRANSIT_COUNT = 'Transit: ',
  EMPTY = 0,

  FROM = 1,
  TO = 2,
  BOTH = 3,
  ALL = 'ALL',
  TEXT_FROM = 'From',
  TEXT_TO = 'To',
  TEXT_BOTH = 'Both',
}
