import { IAuthoritiesFormData } from 'pages/profile/tabs/authorities/constants/types';

import { AuthorityTypes } from './interfaces';

export const createAutorityDeserializer = (body: IAuthoritiesFormData) => {
  return {
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body?.secondAddress } : {}),
    city: body.city,
    fax: body.fax ?? '',
    mc: String(body.mc),
    ...(body?.dot ? { dot: body?.dot } : {}),
    ...(body?.ffNumber ? { ff: body?.ffNumber } : {}),
    ...(body?.stateNumber ? { stateNumber: body?.stateNumber } : {}),
    name: body.name,
    phone: body.phone,
    stateId: Number(body.stateId),
    type: Number(body.authority_type),
    email: body?.email,
    ...(Number(body.authority_type) === AuthorityTypes.Broker ? { rateConfirmationDetails: body?.privacyPolicy } : {}),
    ...(Number(body.authority_type) === AuthorityTypes.Broker ? { templateId: Number(body?.templateId) } : {}),
    zip: body.zip,
    fileIds: body.files?.map(el => Number(el)),
    packetFileIds: body?.packet?.map(el => Number(el)),
    ...(Number(body.authority_type) === AuthorityTypes.Broker
      ? { brokerSignAgreementFiles: body?.broker?.map(el => Number(el)) }
      : {}),
    bankName: body.bankName,
    accountingNumber: body.accounting_number,
    achRoutingNumber: body.ach_routing_number,
    physical: body?.physical ? 1 : 2,
    ...(body?.physical ? { physicalAddress: body?.physicalAddress } : {}),
    ...(body?.physical ? { physicalZip: body?.physicalZip } : {}),
    ...(body?.physical ? { physicalStateId: Number(body?.physicalStateId) } : {}),
    ...(body?.physical ? { physicalCity: body?.physicalCity } : {}),
    ...(body?.physical && body?.physicalSecondAddress ? { physicalSecondAddress: body?.physicalSecondAddress } : {}),
  };
};

export const updateAutorityDeserializer = (body: IAuthoritiesFormData) => {
  return {
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body?.secondAddress } : {}),
    city: body.city,
    fax: body?.fax,
    mc: String(body.mc),
    ...(body?.dot ? { dot: body?.dot } : {}),
    ...(body?.ffNumber ? { ff: body?.ffNumber } : {}),
    ...(body?.stateNumber ? { stateNumber: body?.stateNumber } : {}),
    name: body.name,
    phone: body.phone,
    stateId: Number(body.stateId),
    type: Number(body.authority_type),
    zip: body.zip,
    bankName: body.bankName,
    accountingNumber: body.accounting_number,
    achRoutingNumber: body.ach_routing_number,
    email: body?.email,
    ...(Number(body.authority_type) === AuthorityTypes.Broker ? { rateConfirmationDetails: body?.privacyPolicy } : {}),
    ...(Number(body.authority_type) === AuthorityTypes.Broker ? { templateId: Number(body?.templateId) } : {}),

    physical: body?.physical ? 1 : 2,
    ...(body?.physical ? { physicalAddress: body?.physicalAddress } : {}),
    ...(body?.physical ? { physicalZip: body?.physicalZip } : {}),
    ...(body?.physical ? { physicalStateId: Number(body?.physicalStateId) } : {}),
    ...(body?.physical ? { physicalCity: body?.physicalCity } : {}),
    ...(body?.physical && body?.physicalSecondAddress ? { physicalSecondAddress: body?.physicalSecondAddress } : {}),
  };
};
