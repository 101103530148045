import React from 'react';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { IRequestStatusText, RequestStatus, RequestStatusText } from 'services/loads/interface';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';

import CopyIcon from 'components/svgs/CopyIcon';
import { LoadRequestStatus } from 'components/table/constants/constants';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import { INewLoadsProps } from './constants/types';
import useNewLoadsView from './useNewLoadsView';

import styles from './NewLoadsView.module.scss';

const NewLoadsView = ({ data, isLoadingById, isError, error }: INewLoadsProps) => {
  const { handleChangeMode, handleCommentClick, copiedText, handleCopy, userType, userId, handleRedirectToLoad } =
    useNewLoadsView();

  if (isError && Number(error?.data?.code) === 1350)
    return (
      <ViewItemWrapper
        title={SUBJECT.CREDIT_CHECK}
        Component={
          <Button
            children={SUBJECT.OPEN_LOAD}
            size="small"
            variant="primary"
            onClick={handleRedirectToLoad}
            className="viewDrawerEditBtn"
          />
        }
      >
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Typography variant="paragraph" children={SUBJECT.CREDIT_CHECK_ERROR} className={styles.infoParagraph} />
        </Row>
      </ViewItemWrapper>
    );

  return (
    <>
      <MainLoader isFetching={isLoadingById} />
      <ViewItemWrapper
        title={SUBJECT.NEW_LOAD}
        Component={
          data?.requestStatus !== LoadRequestStatus.REQUEST_STATUS_TYPES_DENY &&
          data?.requestStatus !== LoadRequestStatus.REQUEST_STATUS_TYPES_PENDING &&
          UserTypes.AGENT === userType &&
          data?.creatorId === userId && (
            <Button
              children={SUBJECT.ADD_LOAD}
              size="small"
              variant="comment"
              onClick={handleChangeMode}
              className="viewDrawerEditBtn"
            />
          )
        }
      >
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          {data?.createdAt && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={formatDate(new Date(data?.createdAt || 0))}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.LOAD_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.code} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CUSTOMER_ID} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <div role="button">
                  <div
                    className={clsx(styles.copyIconText, {
                      [styles.copyActive]: copiedText === 'customerId',
                    })}
                  >
                    <Typography variant="paragraph" children={data?.customerId} className={styles.infoParagraph} />
                    <div
                      className={styles.copyIcon}
                      role="button"
                      onClick={() => handleCopy(String(data?.customerId), 'customerId')}
                    >
                      <CopyIcon active={copiedText === 'customerId'} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CUSTOMER_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.customerName} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.OFFICE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" className={styles.infoParagraph} children={data?.officeName} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.BRANCH} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.branchName} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AGENT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" className={styles.infoParagraph} children={data?.agentName} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AMOUNT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" className={styles.infoParagraph} children={data?.amount} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.REAL_AMOUNT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.realAmount} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DEPT_NOT_PAID} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" className={styles.infoParagraph} children={data?.debtNotPaid} />
              </Col>
            </Row>
          </Col>
          {!!data?.debtLimit && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.DEPT_LIMIT} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" children={data?.debtLimit} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DEPT_LOAD_QTY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" className={styles.infoParagraph} children={data?.debtQty} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={RequestStatusText[RequestStatus[data?.requestStatus as number] as keyof IRequestStatusText]}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PAYMENT_METHOD} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" className={styles.infoParagraph} children={data?.paymentMethod} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.COMMENTS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <div className={styles.infoCommentParagraph} onClick={handleCommentClick}>
                  <Typography
                    variant="paragraph"
                    children={`Comment ${data?.comments}`}
                    className={styles.commentText}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </>
  );
};

export default NewLoadsView;
