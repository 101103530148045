import { ForwardedRef, forwardRef } from 'react';
import { Switch as AntSwitch } from 'antd';

import { ISwitchProps } from './constants/types';

import styles from './Switch.module.scss';

const Switch = forwardRef(({ checked, size = 'small', ...props }: ISwitchProps, ref: ForwardedRef<HTMLElement>) => {
  return (
    <div className={styles.switchWrapper}>
      <AntSwitch checked={checked} className={styles.switch} size={size} ref={ref} {...props} />
    </div>
  );
});

export default Switch;
