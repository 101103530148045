import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import {
  CUSTOMERS_COLUMNS,
  CUSTOMERS_KEYS,
  customerTypeOptions,
  PAYMENT_TERMS_OPTIONS,
  SUBJECT,
} from 'pages/profile/tabs/customers/constants/constants';
import { ICustomersFormProps } from 'pages/profile/tabs/customers/constants/types';
import { Input, InputTags, Radio, SearchableDropdown } from 'pages/profile/tabs/customers/hoc-instance/instance';
import { useCreateEditCustomersForm } from 'pages/profile/tabs/customers/useCreateEditCustomersForm';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import Select from 'ui/inputs/select/Select';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Typography from 'ui/typography/Typography';
import {
  emailFormatter,
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  onlyNumbersFormatter,
} from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import { PercentIcon } from 'components/svgs/PercentIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import { SpinIcon } from 'components/svgs/SpinIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import styles from './CreateCustomersForm.module.scss';

const CreateCustomerForm = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  errors,
  setValue,
  resetField,
  reset,
  paymentCheck,
  watch,
  mc,
  authorities,
  clearErrors,
  setError,
  addedMc,
  statesData,
  setAuthoritiesFilter,
  handlePaymentPaginationClick,
  mode,
  paymentTermsCount,
  isEditLoading,
  isCreateLoading,
  isSubmitted,
  defaultValues,
  refetchAuthoritiesShort,
}: ICustomersFormProps) => {
  const dispatch = useDispatch();

  const {
    handleAddMcRow,
    setIsEmailValid,
    handleEditMcRow,
    handleRowDelete,
    setPaymentTermState,
    addBtnDisabledCheck,
    selectedRow,
    templateData,
    image,
    editingRow,
    setEditingRow,
    isEditedRow,
    handleResetForm,
    isPaymentTermsLoading,
    paymentMcRef,
  } = useCreateEditCustomersForm({
    resetField,
    reset,
    setValue,
    watch,
    authorities,
    defaultValues,
    refetchAuthoritiesShort,
    trigger,
    setAuthoritiesFilter,
  });

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isEditLoading && !isCreateLoading && !isPaymentTermsLoading }));
  }, [isEditLoading, isCreateLoading, isPaymentTermsLoading]);

  return (
    <div className={styles.customerformWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ViewItemWrapper title={mode === 'add' ? SUBJECT.ADD_NEW_CUSTOMER : SUBJECT.EDIT_CUSTOMER}>
          <div className={styles.customersSubheader}>
            <Typography variant="paragraph">{SUBJECT.GENERAL_INFO}</Typography>
          </div>
          <Row gutter={[18, 40]}>
            <Col span={16}>
              <Input
                name="name"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.NAME}
                onChange={({ target: { value } }) => {
                  setValue('name', value.trimStart(), { shouldDirty: true, shouldValidate: true });
                }}
                required
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      suffixIcon={<SelectArrowIcon />}
                      options={customerTypeOptions}
                      placeholder={SUBJECT.TYPE}
                      withCheckbox={false}
                      required
                      onBlur={() => trigger(field.name)}
                      errors={errors}
                      onChange={(value: string | string[]) => {
                        setValue('type', String(value), { shouldDirty: true, shouldValidate: true });
                      }}
                      value={field.value}
                      name={field.name}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.type} />
            </Col>
          </Row>
          <div className={styles.rowsGap}>
            <Row gutter={[18, 40]}>
              <Col span={8}>
                <Input
                  name="mcNumber"
                  type="text"
                  control={control}
                  rules={{ required: !(watch('stateNumber') || watch('ffNumber') || watch('dot')) }}
                  placeholder={SUBJECT.MC_NUMBER}
                  getValues={watch}
                  required={!(watch('stateNumber') || watch('ffNumber') || watch('dot'))}
                  errors={errors}
                  onChange={(e: any) => {
                    setValue('mcNumber', onlyNumbersFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    trigger(['stateNumber', 'ffNumber', 'dot', 'mcNumber']);
                  }}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="ffNumber"
                  type="text"
                  control={control}
                  rules={{ required: !(watch('mcNumber') || watch('dot') || watch('stateNumber')) }}
                  placeholder={SUBJECT.FF_NUMBER}
                  getValues={watch}
                  required={!(watch('mcNumber') || watch('dot') || watch('stateNumber'))}
                  errors={errors}
                  onChange={(e: any) => {
                    setValue('ffNumber', onlyNumbersFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    trigger(['stateNumber', 'mcNumber', 'dot', 'ffNumber']);
                  }}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="dot"
                  type="text"
                  control={control}
                  rules={{ required: !(watch('mcNumber') || watch('ffNumber') || watch('stateNumber')) }}
                  placeholder={SUBJECT.DOT}
                  getValues={watch}
                  required={!(watch('mcNumber') || watch('ffNumber') || watch('stateNumber'))}
                  errors={errors}
                  onChange={(e: any) => {
                    setValue('dot', onlyNumbersFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    trigger(['stateNumber', 'mcNumber', 'ffNumber', 'dot']);
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className={styles.rowsGap}>
            <Row gutter={[18, 40]}>
              <Col span={8}>
                <Input
                  name="phoneNumber"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.PHONE_NUMBER}
                  getValues={watch}
                  onChange={(e: any) =>
                    setValue('phoneNumber', formatPhone(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  required
                  errors={errors}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="fax"
                  type="text"
                  control={control}
                  rules={{ required: false }}
                  placeholder={SUBJECT.FAX}
                  onChange={(e: any) =>
                    setValue('fax', formatPhone(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  getValues={watch}
                  errors={errors}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="stateNumber"
                  type="number"
                  control={control}
                  rules={{ required: !(watch('mcNumber') || watch('ffNumber') || watch('dot')) }}
                  placeholder={SUBJECT.STATE_NUMBER}
                  getValues={watch}
                  required={!(watch('mcNumber') || watch('ffNumber') || watch('dot'))}
                  errors={errors}
                  onChange={(e: any) => {
                    setValue('stateNumber', onlyNumbersFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    trigger(['dot', 'mcNumber', 'ffNumber', 'stateNumber']);
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className={styles.rowsGap}>
            <Row gutter={[18, 40]}>
              <Col span={12}>
                <Input
                  name="address"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.ADDRESS}
                  getValues={watch}
                  required
                  errors={errors}
                  onChange={(e: any) => setValue('address', e.target.value.trimStart(), { shouldValidate: true })}
                />
              </Col>
              <Col span={12}>
                <Input
                  name="secondAddress"
                  type="text"
                  control={control}
                  placeholder={SUBJECT.SECOND_ADDRESS}
                  getValues={watch}
                  errors={errors}
                  onChange={(e: any) => setValue('secondAddress', e.target.value.trimStart())}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="city"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.CITY}
                  getValues={watch}
                  required
                  errors={errors}
                  onChange={(e: any) => setValue('city', e.target.value.trimStart(), { shouldValidate: true })}
                />
              </Col>
              <Col span={8}>
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <StateSelect
                        allowClear
                        options={statesData}
                        placeholder={SUBJECT.STATE}
                        name={field.name}
                        errors={errors}
                        onBlur={() => trigger('state')}
                        onChange={(value: string) =>
                          setValue('state', value, {
                            shouldDirty: true,
                            shouldValidate: true,
                          })
                        }
                        value={field.value}
                        required
                      />
                    );
                  }}
                />
                <ErrorMessage fieldError={errors?.state} />
              </Col>
              <Col span={8}>
                <Input
                  name="zipcode"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.ZIPCODE}
                  getValues={watch}
                  onChange={(e: any) =>
                    setValue('zipcode', onlyNumbersFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  required
                  errors={errors}
                />
              </Col>
            </Row>
          </div>
        </ViewItemWrapper>
        <ViewItemWrapper title={SUBJECT.BILLING}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Input
                name="billingPhone"
                type="text"
                control={control}
                rules={{ required: !!watch('billingContactName') || !!watch('billingEmail') }}
                placeholder={SUBJECT.PHONE_NUMBER}
                getValues={watch}
                onChange={(e: any) =>
                  setValue('billingPhone', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                required={
                  !!watch('billingContactName')?.length ||
                  !!watch('billingEmail')?.length ||
                  !!watch('billingPhone')?.length
                }
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="billingEmail"
                type="string"
                control={control}
                rules={{ required: !!watch('billingContactName') || !!watch('billingPhone') }}
                onChange={(e: any) =>
                  setValue('billingEmail', emailFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.EMAIL}
                getValues={watch}
                required={
                  !!watch('billingContactName')?.length ||
                  !!watch('billingEmail')?.length ||
                  !!watch('billingPhone')?.length
                }
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="billingContactName"
                type="text"
                control={control}
                rules={{ required: !!watch('billingEmail') || !!watch('billingPhone') }}
                placeholder={SUBJECT.CONTACT_NAME}
                getValues={watch}
                onChange={({ target: { value } }) => {
                  if (/^[\p{L}\s]+$/u.test(value) || value === '') {
                    setValue('billingContactName', value.trimStart(), { shouldDirty: true, shouldValidate: true });
                  }
                }}
                required={
                  !!watch('billingContactName')?.length ||
                  !!watch('billingEmail')?.length ||
                  !!watch('billingPhone')?.length
                }
                errors={errors}
              />
            </Col>
          </Row>
        </ViewItemWrapper>
        <ViewItemWrapper title={SUBJECT.RECEIVABLES}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Input
                name="receivablesPhone"
                type="text"
                control={control}
                rules={{
                  required: !!watch('receivablesContactName') || !!watch('receivablesEmail'),
                }}
                placeholder={SUBJECT.PHONE_NUMBER}
                getValues={watch}
                onChange={(e: any) =>
                  setValue('receivablesPhone', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                required={
                  !!watch('receivablesPhone')?.length ||
                  !!watch('receivablesContactName')?.length ||
                  !!watch('receivablesEmail')?.length
                }
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="receivablesEmail"
                type="string"
                control={control}
                rules={{
                  required: !!watch('receivablesContactName') || !!watch('receivablesPhone'),
                }}
                onChange={(e: any) =>
                  setValue('receivablesEmail', emailFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.EMAIL}
                getValues={watch}
                required={
                  !!watch('receivablesPhone')?.length ||
                  !!watch('receivablesContactName')?.length ||
                  !!watch('receivablesEmail')?.length
                }
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="receivablesContactName"
                type="text"
                control={control}
                rules={{
                  required: !!watch('receivablesEmail') || !!watch('receivablesPhone'),
                }}
                placeholder={SUBJECT.CONTACT_NAME}
                getValues={watch}
                onChange={({ target: { value } }) => {
                  if (/^[\p{L}\s]+$/u.test(value) || value === '') {
                    setValue('receivablesContactName', value.trimStart(), { shouldDirty: true, shouldValidate: true });
                  }
                }}
                required={
                  !!watch('receivablesPhone')?.length ||
                  !!watch('receivablesContactName')?.length ||
                  !!watch('receivablesEmail')?.length
                }
                errors={errors}
              />
            </Col>
          </Row>
        </ViewItemWrapper>
        <ViewItemWrapper
          title={SUBJECT.PAYMENT_TERMS}
          Component={
            !!watch('mc').length && (
              <div className={styles.resetPayment}>
                <Button icon={<ResetIcon />} variant="ghost" children={SUBJECT.RESET} onClick={handleResetForm} />
              </div>
            )
          }
        >
          <>
            <Row>
              <Col span={24} ref={paymentMcRef}>
                <SearchableDropdown
                  control={control}
                  name="mc"
                  placeholder={SUBJECT.MC}
                  options={authorities?.result?.map((item: any) => ({
                    id: String(item.id),
                    name: item.name,
                    mc: item.mc,
                  }))}
                  offset={authorities?.count}
                  setOffset={setAuthoritiesFilter}
                  setValue={setValue}
                  values={watch('mc')}
                  columns={CUSTOMERS_COLUMNS}
                  keys={CUSTOMERS_KEYS}
                  showId
                  shouldNotValidate
                  disabled={!!selectedRow}
                />
              </Col>
              {!!mc.length && (
                <Col span={24} style={{ marginTop: 24 }}>
                  <Radio
                    name="paymentType"
                    control={control}
                    rules={{ required: true }}
                    value={watch('paymentType')}
                    options={PAYMENT_TERMS_OPTIONS}
                    onChange={e => {
                      setValue('paymentType', e.target.value);
                      setTimeout(() => {
                        trigger('paymentLimit');
                      }, 10);
                    }}
                    className={styles.radio}
                  />
                </Col>
              )}
            </Row>
            {!!paymentCheck?.length && paymentCheck !== 'Factoring' && (
              <Row gutter={[15, 40]} style={{ marginTop: 35 }}>
                <Col span={3}>
                  <Input
                    control={control}
                    placeholder={SUBJECT.DAY}
                    getValues={watch}
                    rules={{ required: true }}
                    required
                    type="number"
                    name="day"
                    errors={errors}
                    onChange={({ target: { value } }) =>
                      setPaymentTermState((prev: any) => ({
                        ...prev,
                        [watch('paymentType')]: {
                          ...prev[watch('paymentType')],
                          day: value,
                        },
                      }))
                    }
                  />
                </Col>
                <Col span={8}>
                  <Input
                    control={control}
                    placeholder={SUBJECT.PERCENT}
                    getValues={watch}
                    rules={{ required: true }}
                    required
                    name="percent"
                    suffix={<PercentIcon />}
                    errors={errors}
                    onChange={({ target: { value } }) => {
                      setPaymentTermState((prev: any) => ({
                        ...prev,
                        [watch('paymentType')]: {
                          ...prev[watch('paymentType')],
                          percent: floatNumberFormatter(value),
                        },
                      }));
                      setTimeout(() => {
                        trigger('percent');
                      }, 10);
                    }}
                  />
                </Col>
                <Col span={13}>
                  <InputTags
                    control={control}
                    placeholder={SUBJECT.EMAIL}
                    getValues={watch}
                    setValue={setValue}
                    required
                    setIsEmailValid={setIsEmailValid}
                    editData={watch('email')}
                    rules={{ required: true }}
                    name="email"
                    errors={errors}
                    trigger={trigger}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </Col>
              </Row>
            )}
            {paymentCheck === 'Factoring' && (
              <Row gutter={[15, 40]} style={{ marginTop: 35 }}>
                <Col span={3}>
                  <Input
                    control={control}
                    placeholder={SUBJECT.DAY}
                    getValues={watch}
                    rules={{ required: true }}
                    type="text"
                    name="day"
                    errors={errors}
                    disabled
                  />
                </Col>
                <Col span={8}>
                  <Input
                    control={control}
                    placeholder={SUBJECT.PERCENT}
                    getValues={watch}
                    rules={{ required: true }}
                    type="text"
                    name="percent"
                    suffix={<PercentIcon />}
                    errors={errors}
                    disabled
                  />
                </Col>
                <Col span={13}>
                  <InputTags
                    control={control}
                    placeholder={SUBJECT.EMAIL}
                    getValues={watch}
                    setValue={setValue}
                    setIsEmailValid={setIsEmailValid}
                    rules={{ required: true }}
                    editData={watch('email')}
                    name="email"
                    errors={errors}
                    required
                    trigger={trigger}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </Col>
              </Row>
            )}
            {paymentCheck?.length && paymentCheck !== 'Factoring' ? (
              <div className={styles.templateImageArea}>
                <Row gutter={15}>
                  <Col span={11}>{image ? <img src={image?.url} alt="" /> : null}</Col>
                  <Col span={13}>
                    <Row justify="end" gutter={[30, 30]}>
                      <Col span={24}>
                        <Controller
                          name="templateId"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                            return (
                              <Select
                                suffixIcon={
                                  templateData?.result ? (
                                    <SelectArrowIcon />
                                  ) : (
                                    <span className={styles.spinIcon}>
                                      <SpinIcon />
                                    </span>
                                  )
                                }
                                treeDefaultExpandAll
                                options={templateData?.result?.map((item: any) => {
                                  return { title: item?.title, value: String(item?.value) };
                                })}
                                onBlur={() => trigger(field.name)}
                                placeholder={SUBJECT.TEAMPLATE}
                                required
                                onChange={(value: string | string[]) => field.onChange(String(value))}
                                value={templateData?.result ? field.value : 'Loading...'}
                                name={field.name}
                              />
                            );
                          }}
                        />
                        <ErrorMessage fieldError={errors?.templateId} />
                      </Col>
                      <Col span={24}>
                        <Row gutter={[15, 40]}>
                          <Col span={24}>
                            <Input
                              name="paymentLimit"
                              suffix={<DollarIcon />}
                              control={control}
                              placeholder={SUBJECT.CREDIT_LIMIT}
                              value={
                                watch('paymentLimit')
                                  ? formatNumberWithThousandsSeparatorAndDecimal(watch('paymentLimit'))
                                  : ''
                              }
                              errors={errors}
                              onChange={({ target: { value } }) => {
                                setPaymentTermState((prev: any) => ({
                                  ...prev,
                                  [watch('paymentType')]: {
                                    ...prev[watch('paymentType')],
                                    paymentLimit: floatNumberFormatter(value, false),
                                  },
                                }));
                                setTimeout(() => {
                                  trigger('paymentLimit');
                                }, 10);
                              }}
                            />
                          </Col>
                          <Col span={7} offset={17}>
                            <Button
                              variant="comment"
                              children={isEditedRow ? SUBJECT.EDIT_BTN_TEXT : SUBJECT.ADD}
                              onClick={() =>
                                handleAddMcRow({
                                  mc: watch('mc')[0]!.mc,
                                  name: watch('mc')[0]!.name,
                                  paymentType: watch('paymentType'),
                                  day: watch('day'),
                                  percent: `${watch('percent')}%`,
                                  email: watch('email'),
                                  ...(watch('templateId') ? { templateId: watch('templateId') } : {}),
                                  ...(watch('paymentId') ? { paymentId: watch('paymentId') } : {}),
                                  ...(watch('paymentLimit') ? { paymentLimit: String(watch('paymentLimit')) } : {}),
                                })
                              }
                              disabled={!addBtnDisabledCheck}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {!!paymentCheck?.length && addBtnDisabledCheck && isSubmitted && (
                    <ErrorMessage
                      fieldError={{
                        message: 'You need to save  or reset your changes , before submitting the form',
                      }}
                    />
                  )}
                </Row>
              </div>
            ) : null}
            {!!paymentCheck?.length && paymentCheck == 'Factoring' && (
              <Row style={{ marginTop: 35, marginBottom: 30 }} gutter={[15, 40]}>
                <Col span={13} offset={11}>
                  <Input
                    name="paymentLimit"
                    suffix={<DollarIcon />}
                    control={control}
                    placeholder={SUBJECT.CREDIT_LIMIT}
                    value={
                      watch('paymentLimit') ? formatNumberWithThousandsSeparatorAndDecimal(watch('paymentLimit')) : ''
                    }
                    errors={errors}
                    onChange={({ target: { value } }) => {
                      setPaymentTermState((prev: any) => ({
                        ...prev,
                        [watch('paymentType')]: {
                          ...prev[watch('paymentType')],
                          paymentLimit: floatNumberFormatter(value, false),
                        },
                      }));
                      setTimeout(() => {
                        trigger('paymentLimit');
                      }, 10);
                    }}
                  />
                </Col>
                <Col span={4} offset={20}>
                  <Button
                    variant="comment"
                    children={isEditedRow ? SUBJECT.EDIT_BTN_TEXT : SUBJECT.ADD}
                    onClick={() => {
                      handleAddMcRow({
                        mc: watch('mc')[0]!.mc,
                        name: watch('mc')[0]!.name,
                        paymentType: watch('paymentType'),
                        day: watch('day'),
                        percent: `${watch('percent')}%`,
                        email: watch('email'),
                        ...(watch('templateId') ? { templateId: watch('templateId') } : {}),
                        ...(watch('paymentId') ? { paymentId: watch('paymentId') } : {}),
                        ...(watch('paymentLimit') ? { paymentLimit: String(watch('paymentLimit')) } : {}),
                      });
                    }}
                    disabled={!addBtnDisabledCheck}
                  />
                </Col>
                {!!paymentCheck?.length && addBtnDisabledCheck && isSubmitted && (
                  <ErrorMessage
                    fieldError={{ message: 'You need to save  or reset your changes , before submitting the form' }}
                  />
                )}
              </Row>
            )}
            {!!addedMc?.length && (
              <Row>
                <Col span={24}>
                  <div className={styles.addedBlock}>
                    <Typography variant="paragraph">{SUBJECT.ADDED}</Typography>
                  </div>
                  <DataGrid
                    type="mc"
                    handleRowEdit={handleEditMcRow}
                    handleRowDelete={handleRowDelete}
                    data={addedMc}
                    count={mode === 'edit' ? paymentTermsCount : addedMc.length}
                    handlePaginationClick={handlePaymentPaginationClick}
                    selectedRow={selectedRow}
                    editingRow={editingRow}
                    setEditingRow={setEditingRow}
                  />
                </Col>
              </Row>
            )}
          </>
        </ViewItemWrapper>
      </form>
    </div>
  );
};

export default CreateCustomerForm;
