import React from 'react';
import { Rate } from 'antd';

import StarIcon from 'components/svgs/StarIcon';

import { ISelectOptions } from '../../../constants/types';

export const RATING_OPTIONS: ISelectOptions[] = [
  { title: <Rate character={<StarIcon />} disabled defaultValue={5} />, value: '5' },
  { title: <Rate character={<StarIcon />} disabled defaultValue={4} />, value: '4' },
  { title: <Rate character={<StarIcon />} disabled defaultValue={3} />, value: '3' },
  { title: <Rate character={<StarIcon />} disabled defaultValue={2} />, value: '2' },
  { title: <Rate character={<StarIcon />} disabled defaultValue={1} />, value: '1' },
  { title: <Rate character={<StarIcon />} disabled defaultValue={0} />, value: '6' },
];

export const SECTIONS = (hasLastLoads: boolean, hasBreakdowns: boolean) => {
  const sections = [
    {
      id: 'carrier-info',
      title: 'Carrier Info',
    },
    {
      id: 'general-information',
      title: 'General Information',
    },
    {
      id: 'truck',
      title: 'Truck',
    },
    {
      id: 'fuel-card',
      title: 'Fuel Cards',
    },
    {
      id: 'factoring',
      title: 'Factoring',
    },
    {
      id: 'payment_method',
      title: 'Payment Method',
    },
    {
      id: 'payment-terms-and-billing',
      title: 'Payment Terms',
    },
    {
      id: 'documentation',
      title: 'Documentation',
    },
  ];

  if (hasLastLoads) {
    sections.splice(8, 0, {
      title: 'Last loads',
      id: 'last-loads',
    });
  }
  if (hasBreakdowns) {
    sections.splice(hasLastLoads ? 9 : 8, 0, {
      id: 'carrier-payment-breakdown',
      title: 'Payment breakdown',
    });
  }

  return sections;
};

export const CARRIER_SECTIONS_VIEW = [
  {
    id: 'carrier-info-id',
    title: 'Carrier Info',
  },
  {
    id: 'full-info-id',
    title: 'Carrier Detail',
  },
  {
    id: 'added-truck-info-id',
    title: 'Added Truck',
  },
  {
    id: 'added-fuel-cards-id',
    title: 'Fuel Card',
  },
  {
    id: 'factoring-id',
    title: 'Factoring',
  },
  {
    id: 'payment-terms-id',
    title: 'Payment Terms',
  },
  {
    id: 'payment-method-id',
    title: 'Payment Method',
  },
  {
    id: 'documentation-id',
    title: 'Documentation',
  },
  {
    id: 'carrier-payment-breakdown-id',
    title: 'Carrier Payment Breakdown',
  },
  {
    id: 'last-loads-id',
    title: 'Last Loads',
  },
  {
    id: 'comment',
    title: 'Comment',
  },
];

export const CarriersFilterValues: any = {
  ratings: 'ratings',
  carrierType: 'type',
};

export enum InsuranceDays {
  FIRST = 1,
  SECOND = 2,
  EXPIRED = 3,
}
