import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { SUBJECT } from 'pages/profile/tabs/agent/constants/constants';
import { AgentPayType, AgentType, Location, PaidAs, PayType } from 'services/profile/agents/interfaces';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import {
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  multiplyDownloadWithDelay,
  multiplyViewWithDelay,
} from 'utils/helpers';
import { v4 as uuid } from 'uuid';

import ActivityIcon from 'components/svgs/ActivityIcon';
import CopyIcon from 'components/svgs/CopyIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import useView from './useView';

import styles from './View.module.scss';

const View = ({
  data,
  isFetchingById,
  pagePermissions: { edit, terminateOn, terminateOff },
  setAuthoritiesByAgent,
  canEdit,
  authoritiesByAgentId,
}: any) => {
  const {
    handleChangeMode,
    handleChecked,
    handleResetPassword,
    handleInviteAgain,
    handleCopy,
    copiedText,
    mode,
    checkedValue,
    handlePaginationClick,
  } = useView({
    data,
    setAuthoritiesByAgent,
  });

  return (
    <>
      <MainLoader isFetching={isFetchingById} />
      <div className={styles.viewMain}>
        <ViewItemWrapper
          title={SUBJECT.AGENT_INFO}
          Component={
            edit &&
            canEdit && <Button onClick={handleChangeMode} children={SUBJECT.EDIT} className="viewDrawerEditBtn" />
          }
        >
          <Row className={styles.agentInfo} gutter={[10, 16]}>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.FIRST_NAME}:
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.firstName}
                  subject={`${SUBJECT.AGENT_INFO}${SUBJECT.FIRST_NAME}`}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.OFFICE}:
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.office?.name}
                  subject={`${SUBJECT.AGENT_INFO}${SUBJECT.OFFICE}`}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.AGENT_ID}:
                </Typography>
                <Col span={13}>
                  <div
                    className={clsx(styles.copyIconText, {
                      [styles.copyActive]: copiedText === 'topBannerAgentId',
                    })}
                  >
                    <Typography variant="paragraph" children={data?.result?.id} className={styles.infoParagraph} />
                    <div
                      className={styles.copyIcon}
                      role="button"
                      onClick={() => handleCopy(data?.result?.id, 'topBannerAgentId')}
                    >
                      <CopyIcon active={copiedText === 'topBannerAgentId'} />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>

            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.LAST_NAME}:
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.lastName}
                  subject={`${SUBJECT.AGENT_INFO}${SUBJECT.LAST_NAME}`}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.BRANCH}:
                </Typography>

                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.branch?.name || SUBJECT.ALL}
                  subject={`${SUBJECT.AGENT_INFO}${SUBJECT.BRANCH}`}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.USERNAME}:
                </Typography>

                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.username}
                  subject={`${SUBJECT.AGENT_INFO}${SUBJECT.USERNAME}`}
                />
              </div>
            </Col>

            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.PHONE_NUMBER}:
                </Typography>
                <Link className="top-banner-value" to={`tel:${data?.result?.phone}`}>
                  {formatPhone(data?.result?.phone)}
                </Link>
              </div>
            </Col>

            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.LOCATION}:
                </Typography>
                <Typography variant="paragraph" className="top-banner-value">
                  {Location[data?.result?.location]}
                </Typography>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.EMAIL}:
                </Typography>

                <TooltipText
                  dataLink
                  prefixClass="top-banner-value"
                  data={data?.result?.email}
                  subject={`${SUBJECT.AGENT_INFO}${SUBJECT.USERNAME}`}
                />
              </div>
            </Col>

            {data?.result?.phoneSecond && (
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.SECOND_NUMBER}:
                  </Typography>
                  <Link className="top-banner-value" to={`tel:${data?.result?.phoneSecond}`}>
                    {formatPhone(data?.result?.phoneSecond)}
                  </Link>
                </div>
              </Col>
            )}
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.AGENT_TYPE}:
                </Typography>
                <Typography variant="paragraph" className="top-banner-value">
                  {AgentType[data?.result?.agentType]}
                </Typography>
              </div>
            </Col>
          </Row>
        </ViewItemWrapper>
        <ViewItemWrapper title={SUBJECT.FULL_INFO}>
          <Row justify="space-between" align="top" gutter={[10, 30]}>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={formatDate(data?.result?.createdAt)}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.AGENT_ID} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <div
                    className={clsx(styles.copyIconText, {
                      [styles.copyActive]: copiedText === 'result',
                    })}
                  >
                    <Typography variant="paragraph" children={data?.result?.id} className={styles.infoParagraph} />
                    <div
                      className={styles.copyIcon}
                      role="button"
                      onClick={() => handleCopy(data?.result?.id, 'result')}
                    >
                      <CopyIcon active={copiedText === 'result'} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FIRST_NAME} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.firstName}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.FIRST_NAME}`}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.LAST_NAME} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.lastName}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.LAST_NAME}`}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Link className="top-banner-value" to={`tel:${data?.result?.phone}`}>
                    {formatPhone(data?.result?.phone)}
                  </Link>
                </Col>
              </Row>
            </Col>
            {data?.result?.phoneSecond && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.SECOND_NUMBER} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Link className="top-banner-value" to={`tel:${data?.result?.phoneSecond}`}>
                      {formatPhone(data?.result?.phoneSecond)}
                    </Link>
                  </Col>
                </Row>
              </Col>
            )}
            {data?.result?.address && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
                  </Col>

                  <Col span={13}>
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={data?.result?.address}
                      subject={`${SUBJECT.AGENT_INFO}${SUBJECT.ADDRESS}`}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {data?.result?.secondAddress && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.SECOND_ADDRESS} className={styles.infoHeading} />
                  </Col>

                  <Col span={13}>
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={data?.result?.secondAddress}
                      subject={`${SUBJECT.AGENT_INFO}${SUBJECT.SECOND_ADDRESS}`}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
                </Col>

                <Col span={13}>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.city}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.CITY}`}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
                </Col>

                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.state?.result?.name}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ZIP_CODE} className={styles.infoHeading} />
                </Col>

                <Col span={13}>
                  <Typography variant="paragraph" children={data?.result?.zip} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ACTIVITY} className={styles.infoHeading} />
                </Col>

                <Col span={13}>
                  <ActivityIcon active={data?.result?.isOnline} />
                </Col>
              </Row>
            </Col>
            {data?.result?.verifiedAt && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.RESET_PASSWORD} className={styles.infoHeading} />
                  </Col>

                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children="Reset Password"
                      className={styles.infoParagraphReset}
                      onClick={() => handleResetPassword(data?.result?.email)}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {!!data?.result?.authorityCount && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.AUTHORITIES} className={styles.infoHeading} />
                  </Col>

                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={data?.result?.authorityCount}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
            )}

            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.BRACKED} className={styles.infoHeading} />
                </Col>

                <Col span={13}>
                  {data?.result?.brackets?.map((item: any) => {
                    return (
                      <Typography
                        variant="paragraph"
                        children={`${item?.percent}% (${formatNumberWithThousandsSeparatorAndDecimal(
                          item?.from,
                          true
                        )} - ${formatNumberWithThousandsSeparatorAndDecimal(item?.to, true)}) `}
                        className={styles.infoParagraphBracket}
                        key={uuid()}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Col>
            {!!data?.result?.verifiedAt && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
                  </Col>

                  <Col span={13}>
                    <Switch
                      checked={checkedValue || false}
                      onChange={handleChecked}
                      disabled={(checkedValue && !terminateOff) || (!checkedValue && !terminateOn)}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.INVITATION} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  {data?.result?.verifiedAt ? (
                    <Typography variant="paragraph" children={SUBJECT.ACCEPTED} className={styles.infoParagraph} />
                  ) : (
                    <Typography
                      onClick={() => handleInviteAgain(data?.result?.id)}
                      variant="paragraph"
                      children={SUBJECT.INVITE_AGAIN}
                      className={styles.infoParagraphReset}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            {data?.result?.creator.username && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                  </Col>

                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={`${data?.result?.creator.username}`}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
        <ViewItemWrapper title={SUBJECT.AGENT_MANAGMENT}>
          <Row justify="space-between" align="top" gutter={[10, 30]}>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.AGENT_TYPE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={AgentType[data?.result?.agentType]}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.OFFICE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.office?.name}
                    subject={`${SUBJECT.AGENT_MANAGMENT}${SUBJECT.OFFICE}`}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.email}
                    subject={`${SUBJECT.AGENT_MANAGMENT}${SUBJECT.EMAIL}`}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.BRANCH} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={AgentType[data?.result?.agentType] === 'Owner' ? 'All' : data?.result?.branch?.name}
                    subject={`${SUBJECT.AGENT_MANAGMENT}${SUBJECT.BRANCH}`}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.USERNAME} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.username}
                    subject={`${SUBJECT.AGENT_MANAGMENT}${SUBJECT.USERNAME}`}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.LOCATION} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={Location[data?.result?.location]}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
            {!!data?.result?.files?.length && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.FILE} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Row gutter={5}>
                      <Col>
                        <Button
                          variant="ghost"
                          onClick={() => {
                            multiplyViewWithDelay(data?.result?.files);
                          }}
                        >
                          <EyeIconSvg outlined={true} />
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="ghost" onClick={() => multiplyDownloadWithDelay(data?.result?.files)}>
                          <NoaDownloadIcon />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
        {!!authoritiesByAgentId?.result?.length && (
          <ViewItemWrapper title={`${SUBJECT.ADDED} Authority (${authoritiesByAgentId?.count})`}>
            <Row>
              <Col span={24}>
                <DataGrid
                  data={authoritiesByAgentId?.result}
                  count={authoritiesByAgentId?.count}
                  type="authoritiesView"
                  handlePaginationClick={handlePaginationClick}
                  copiedText={copiedText}
                  handleCopy={handleCopy}
                  mode={mode}
                />
              </Col>
            </Row>
          </ViewItemWrapper>
        )}
        {data?.result?.payType && (
          <ViewItemWrapper title={SUBJECT.PAYROLL}>
            <Row justify="space-between" align="top" gutter={[10, 30]}>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.PAY_TYPE} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={PayType[data?.result?.payType]}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              {data?.result?.paidAs && (
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.PAID_AS} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        children={PaidAs[data?.result?.paidAs]}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {data?.result?.agentPayType && (
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.AGENT_PAY_TYPE} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        children={AgentPayType[data?.result?.agentPayType]}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography
                      variant="heading"
                      children={SUBJECT[`${AgentPayType[data?.result?.agentPayType]}_NUMBER` as keyof typeof SUBJECT]}
                      className={styles.infoHeading}
                    />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={data?.result?.agentPayInput}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ViewItemWrapper>
        )}
        {data?.result?.emergencyName && (
          <ViewItemWrapper title={SUBJECT.EMERGENCY_CONTACT}>
            <Row justify="space-between" align="top" gutter={[10, 30]}>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.NAME} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={data?.result?.emergencyName}
                      subject={`${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.NAME}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.RELATION} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={data?.result?.emergencyRelation}
                      subject={`${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.RELATION}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="paragraph" className={styles.infoHeading}>
                      {SUBJECT.PHONE_NUMBER}
                    </Typography>
                  </Col>
                  <Col span={13}>
                    <Link className="top-banner-value" to={`tel:${data?.result?.emergencyPhone}`}>
                      {formatPhone(data?.result?.emergencyPhone)}
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ViewItemWrapper>
        )}
      </div>
    </>
  );
};

export default View;
