const HeartIconBordered = () => {
  return (
    <svg width="18" cursor="pointer" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.11214 16.1029L8.25798 15.332C6.78575 13.9848 5.57048 12.8216 4.61214 11.8424C3.65381 10.8633 2.88992 9.98828 2.32048 9.21745C1.75103 8.44661 1.35173 7.7487 1.12256 7.1237C0.893392 6.4987 0.778809 5.86675 0.778809 5.22786C0.778809 3.97786 1.19895 2.93273 2.03923 2.09245C2.8795 1.25217 3.9177 0.832031 5.15381 0.832031C5.94548 0.832031 6.67811 1.01953 7.35173 1.39453C8.02534 1.76953 8.61214 2.3112 9.11214 3.01953C9.69547 2.26953 10.3135 1.71745 10.9663 1.36328C11.6191 1.00911 12.3205 0.832031 13.0705 0.832031C14.3066 0.832031 15.3448 1.25217 16.1851 2.09245C17.0253 2.93273 17.4455 3.97786 17.4455 5.22786C17.4455 5.86675 17.3309 6.4987 17.1017 7.1237C16.8726 7.7487 16.4733 8.44661 15.9038 9.21745C15.3344 9.98828 14.5705 10.8633 13.6121 11.8424C12.6538 12.8216 11.4385 13.9848 9.96631 15.332L9.11214 16.1029ZM9.11214 14.457C10.5149 13.1654 11.6712 12.0577 12.5809 11.1341C13.4906 10.2105 14.2128 9.40148 14.7476 8.70703C15.2823 8.01259 15.6573 7.39453 15.8726 6.85286C16.0878 6.3112 16.1955 5.76953 16.1955 5.22786C16.1955 4.3112 15.9038 3.55773 15.3205 2.96745C14.7371 2.37717 13.9871 2.08203 13.0705 2.08203C12.3621 2.08203 11.7024 2.30078 11.0913 2.73828C10.4802 3.17578 9.98714 3.79036 9.61214 4.58203H8.59131C8.2302 3.80425 7.74409 3.19314 7.13298 2.7487C6.52186 2.30425 5.86214 2.08203 5.15381 2.08203C4.23714 2.08203 3.48714 2.37717 2.90381 2.96745C2.32048 3.55773 2.02881 4.3112 2.02881 5.22786C2.02881 5.76953 2.13645 6.31467 2.35173 6.86328C2.567 7.41189 2.942 8.03689 3.47673 8.73828C4.01145 9.43967 4.73714 10.2487 5.65381 11.1654C6.57048 12.082 7.72325 13.1793 9.11214 14.457Z"
        fill="#667695"
      />
    </svg>
  );
};

export default HeartIconBordered;
