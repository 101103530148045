import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { Input as AntInput, InputRef } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import CloseIcon from 'components/svgs/CloseIcon';
import EyeInvisibleIcon from 'components/svgs/EyeInvisibleIcon';
import PasswordEyeIcon from 'components/svgs/PasswordEyeIcon';
import SearchIcon from 'components/svgs/SearchIcon';

import { whiteSpaceFormatter } from '../../../utils/helpers';

import { IInputProps } from './constants/types';

import styles from './Input.module.scss';

const { Password } = AntInput;

const Input = forwardRef(
  (
    {
      placeholder,
      time = false,
      picker = false,
      required,
      disabled,
      type,
      height,
      suffix,
      prefix,
      allowClear,
      getValues,
      name,
      withoutSpaces = false,
      label,
      defaultValue,
      tableSearchValue,
      errors,
      warningMessage,
      warning,
      success = false,
      isInTable = false,
      handleBlur,
      withRedMinus,
      otherRef,
      ...props
    }: IInputProps,
    ref: ForwardedRef<InputRef>
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const isInputFilled = !!getValues && getValues(`${name}`)?.toString()?.length;
    const visibleIcon = useCallback((visible: boolean) => {
      return visible ? (
        <span>
          <PasswordEyeIcon />
        </span>
      ) : (
        <span>
          <EyeInvisibleIcon />
        </span>
      );
    }, []);

    const handleKeyPress = (event: any) => {
      const { target } = event;

      if (type === 'number') {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
          event.preventDefault();
        }
      }
      if (withoutSpaces && event.key === ' ') {
        target.value = whiteSpaceFormatter(target.value);
        event.preventDefault();
      }
    };

    const handleWheel = (e: any) => {
      e.target.blur();
    };

    return type === 'password' ? (
      <div
        style={{ height: height ? `${height}px` : '' }}
        className={clsx(styles.password, {
          [styles.filled]: isInputFilled,
          [styles.required]: required,
          [styles.disabled]: disabled,
          [styles.errors]: errors && name && errors[name],
          [styles.success]: false,
        })}
      >
        <Password
          ref={ref}
          type="password"
          visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
          iconRender={visibleIcon}
          required={required}
          disabled={disabled}
          {...props}
        />
        {!!placeholder && (
          <Typography variant="paragraph" className={styles.placeholder}>
            {placeholder}
          </Typography>
        )}
      </div>
    ) : (
      <div className={styles.inputMainWrapper}>
        {label && <label>{label}</label>}
        <div
          style={{ height: height ? `${height}px` : '' }}
          className={clsx(styles.input, {
            [styles.filled]:
              props?.value === '-' && props?.value?.toString()?.length === 1
                ? false
                : isInputFilled || defaultValue || time || props?.value,
            [styles.required]: required,
            [styles.disabled]: disabled,
            [styles.warning]: warning,
            [styles.success]: success,
            [styles.suffix]: suffix || allowClear,
            [styles.prefix]: (prefix || allowClear) && !isInTable,
            [styles.errors]: errors && name && errors[name],
            [styles.inTableInput]: isInTable,
            // [styles.errorsNested]: errors && name && errors[name],
            [styles.withMinus]: props?.value?.toString()?.[0] === '-' && withRedMinus,
          })}
        >
          <AntInput
            defaultValue={isInTable ? tableSearchValue : defaultValue}
            allowClear={
              allowClear
                ? {
                    clearIcon: !isInTable ? (
                      <CloseIcon />
                    ) : isInTable && tableSearchValue ? (
                      <CloseIcon />
                    ) : (
                      <span className={styles.noValue}>
                        <SearchIcon />
                      </span>
                    ),
                  }
                : false
            }
            ref={otherRef || ref}
            required={required}
            disabled={disabled}
            suffix={suffix || null}
            prefix={prefix || null}
            placeholder={isInTable || label || picker ? placeholder : ''}
            type={type}
            onKeyPress={handleKeyPress}
            onWheel={handleWheel}
            onBlur={handleBlur}
            {...props}
          />
          {isInTable ||
            (!label && !picker && !!placeholder && (
              <Typography
                variant="paragraph"
                className={clsx(styles.placeholder, {
                  // [styles.errorLabel]: errors && name && errors[name],
                })}
              >
                {placeholder}
              </Typography>
            ))}
        </div>
        {warning && (
          <Typography variant="paragraph" className={styles.warningText}>
            {warningMessage}
          </Typography>
        )}
      </div>
    );
  }
);

export default Input;
