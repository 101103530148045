const DollarIcon = () => {
  return (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.29528 13.5086V0.781294H5.11062V13.5086H4.29528ZM6.8457 4.72874C6.79267 4.25809 6.57392 3.89351 6.18945 3.63499C5.80498 3.37315 5.32108 3.24223 4.73775 3.24223C4.32014 3.24223 3.95887 3.30852 3.65394 3.44109C3.34902 3.57036 3.11204 3.74933 2.943 3.97803C2.77728 4.20341 2.69442 4.46027 2.69442 4.74862C2.69442 4.99057 2.75077 5.19938 2.86346 5.37504C2.97946 5.55071 3.13027 5.6982 3.31587 5.81752C3.50479 5.93352 3.70697 6.03129 3.92241 6.11084C4.13784 6.18707 4.34499 6.25004 4.54386 6.29976L5.53817 6.55828C5.86299 6.63783 6.19608 6.74555 6.53746 6.88144C6.87885 7.01733 7.19537 7.1963 7.48704 7.41837C7.77871 7.64043 8.01403 7.91553 8.193 8.24365C8.3753 8.57178 8.46644 8.96453 8.46644 9.42192C8.46644 9.99862 8.31729 10.5107 8.019 10.9581C7.72402 11.4056 7.2948 11.7586 6.73136 12.0171C6.17122 12.2756 5.49343 12.4049 4.69798 12.4049C3.93567 12.4049 3.2761 12.2839 2.71928 12.0419C2.16246 11.8 1.72662 11.457 1.41175 11.0128C1.09689 10.5654 0.922881 10.0351 0.889737 9.42192H2.43093C2.46076 9.78982 2.58008 10.0964 2.78889 10.3417C3.00101 10.5836 3.27113 10.7642 3.59925 10.8836C3.93069 10.9996 4.29362 11.0576 4.68803 11.0576C5.12222 11.0576 5.50835 10.9896 5.84641 10.8537C6.1878 10.7145 6.45626 10.5223 6.65181 10.277C6.84736 10.0285 6.94514 9.73844 6.94514 9.407C6.94514 9.10539 6.85896 8.85847 6.68661 8.66624C6.51758 8.474 6.28723 8.31491 5.99556 8.18896C5.70721 8.06302 5.38074 7.95199 5.01616 7.85587L3.81303 7.52774C2.99769 7.30568 2.35139 6.97921 1.87411 6.54834C1.40015 6.11747 1.16317 5.54739 1.16317 4.83811C1.16317 4.25146 1.32227 3.73939 1.64045 3.30189C1.95863 2.86439 2.3895 2.52467 2.93306 2.28271C3.47662 2.03745 4.08978 1.91482 4.77255 1.91482C5.46194 1.91482 6.07014 2.03579 6.59712 2.27774C7.12743 2.51969 7.54504 2.85279 7.84997 3.27703C8.15489 3.69796 8.31398 4.18186 8.32724 4.72874H6.8457Z"
        fill="#98A2B2"
      />
    </svg>
  );
};

export default DollarIcon;
