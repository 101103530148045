import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useResetColumns from 'hooks/useResetColumns';
import { useGetSummariesQuery } from 'services/payroll/information/information';
import {
  useChangePaymentStatusMutation,
  useGetPaymentByIdQuery,
  useGetPaymentQuery,
} from 'services/payroll/payment/payment';
import { PaymentStatusTypes } from 'services/payroll/payment/types';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { addOneMonth } from 'utils/dates';
import { ServerErrorCodes } from 'utils/errors';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { usePermittedActions } from '../../../../hooks/usePermittedActions';
import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';
import { CardNames } from '../information/constants/constants';
import { payrollCards } from '../information/constants/helper';

import { PaymentView } from './components/view/PaymentView';
import { ACTIVE_TAB_LEVEL, paymentRoutes, SUBJECT } from './constants/constants';
import { IPayoutFilter, IPayoutTableColumns, ISelectedRow } from './constants/types';
import { usePayoutFilter } from './usePayoutFilter';

import styles from './Payout.module.scss';

// eslint-disable-next-line unused-imports/no-unused-vars
export const usePayout = (setExcelParams: any) => {
  // todo implement export
  const dispatch = useDispatch();

  const { employeeLevel, permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;
  const allAllowedCards = permissionsInfo?.permissions?.payroll?.informationCards || [];
  const { searchParams, setSearchParams } = useDetectedParams();
  const paymentPermission = usePermittedActions('payroll.payment');

  const { tab, mode, open, activeTab, officePayout, branchPayout, agentPayout, startDate, endDate, id, dateMode } =
    searchParams;
  const hasPermissionToAction = useMemo(() => {
    if (roleLevel === IRoleLevel.corpLevel) {
      return activeTab === SUBJECT.OFFICE;
    }
    if (roleLevel === IRoleLevel.officeLevel) {
      return activeTab === SUBJECT.BRANCH;
    }
    if (roleLevel === IRoleLevel.branchLevel) {
      return activeTab === SUBJECT.AGENT;
    }
    return false;
  }, [activeTab, roleLevel]);

  const { data: summariesData } = useGetSummariesQuery(
    {
      ...(endDate ? { dateEnd: Number(endDate) } : {}),
      dateStart: Number(startDate),
      branchIds: branchPayout?.length ? branchPayout?.split(',')?.map(el => Number(el)) : [],
      officeIds: officePayout?.length ? officePayout?.split(',')?.map(el => Number(el)) : [],
      agentIds: agentPayout ? [Number(agentPayout)] : [],
      dateMode,
    },
    { skip: !startDate }
  );
  const [changePaymentStatus] = useChangePaymentStatusMutation();

  const cards = payrollCards(summariesData!);
  const allowedCards = cards?.filter(card => allAllowedCards.includes(CardNames[card?.type as keyof typeof CardNames]));

  const [selectedRows, setSelectedRows] = useState<ISelectedRow[]>([]);
  const [dragColumns, setDragColumns] = useState<any>([]);

  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const summary = useMemo(() => {
    let sum = 0;
    selectedRows.forEach(row => {
      sum += +row.final;
    });
    return sum;
  }, [selectedRows.length]);

  const {
    isFilterDirty,
    filterControl,
    filterOptions,
    finalAgentOptions,
    finalOfficeOptions,
    selectedAgentOption,
    finalBranchesOptions,
    filterReset,
    filterWatch,
    setFilterValue,
    setAgentsFilter,
    setBranchFilter,
    setOfficesFilter,
    dirtyFields,
    isChangedDate,
    FILTER_DEFAULT_VALUES,
    setSelectedAgentOption,
    selectedBranches,
  } = usePayoutFilter();

  const [paymentFilters, setPaymentFilters] = useState<Partial<IGetAuthoritiesParams>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: '',
    level: 0,
  });
  useEffect(() => {
    setPaymentFilters(prev => {
      return {
        ...prev,
        level: ACTIVE_TAB_LEVEL[activeTab as keyof typeof ACTIVE_TAB_LEVEL],
      };
    });
  }, [activeTab]);

  const { data, isFetching } = useGetPaymentQuery(
    {
      ...paymentFilters,
      ...(endDate ? { dateEnd: addOneMonth(Number(endDate)) } : {}),
      dateStart: addOneMonth(Number(startDate)),
      branchIds: branchPayout?.length ? branchPayout?.split(',')?.map(el => Number(el)) : [],
      officeIds: officePayout?.length ? officePayout?.split(',')?.map(el => Number(el)) : [],
      agentIds: agentPayout ? [Number(agentPayout)] : [],
    },
    { skip: !paymentFilters?.level }
  );

  const { currentData: paymentById, isFetching: isFetchingById } = useGetPaymentByIdQuery({ id }, { skip: !id });

  const payoutTabs = useMemo(() => {
    const allTabs = [
      { label: SUBJECT.OFFICE, key: 'Agency' },
      { label: SUBJECT.BRANCH, key: 'Branch' },
      { label: SUBJECT.AGENT, key: 'Agent' },
    ];
    if (roleLevel === IRoleLevel.corpLevel || roleLevel === IRoleLevel.officeLevel) {
      return allTabs;
    } else {
      return allTabs.slice(roleLevel - 2);
    }
  }, [data, employeeLevel]);

  const activeTabCheck = !activeTab || activeTab === 'undefined' ? payoutTabs![0].label : activeTab;
  const handleCloseModal = () => {
    setSearchParams({ ...searchParams, open: 'false' });
    dispatch(closeModal());
  };

  const { data: columnsData } = useGetColumnsQuery({ type: paymentRoutes[activeTabCheck] }, { skip: !activeTabCheck });

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: paymentRoutes[activeTabCheck],
    });

  const handleStopResize = (columns: IPayoutTableColumns[]): void => {
    editColumns({ type: paymentRoutes[activeTabCheck], columns });
  };

  const handleRowClick = (rowId: string | number) => {
    setSearchParams({
      ...searchParams,
      mode: 'view',
      open: 'true',
      id: rowId as string,
    });
  };

  const handlePaginationClick = useCallback((skip: number, limit: number) => {
    setPaymentFilters(prev => ({ ...prev, limit, skip }));
  }, []);

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setPaymentFilters(prev => {
      if (sortOrder === 0) {
        return {
          ...prev,
          order: 2,
          orderBy: '',
        };
      }
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setPaymentFilters(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleTabChange = (tabName: string) => {
    handleResetFilters();
    setPaymentFilters(() => ({
      search: '',
      field: '',
      skip: 0,
      limit: 20,
      order: 2,
      orderBy: '',
      level: ACTIVE_TAB_LEVEL[activeTab as keyof typeof ACTIVE_TAB_LEVEL],
    }));
    setSearchParams({ ...searchParams, tab, open, mode, activeTab: tabName });
    filterOptions.forEach(({ key }) => {
      setFilterValue(key as keyof IPayoutFilter, []);
    });
  };

  const handleResetFilters = () => {
    filterReset(FILTER_DEFAULT_VALUES, { keepDirty: false });
    setSelectedAgentOption({});
    setSearchParams({
      tab,
      open,
      mode,
      activeTab: activeTabCheck,
      startDate: String(FILTER_DEFAULT_VALUES.monthPickerPayout.startDate),
    });
  };

  const handleRowPayClick = (row: ISelectedRow) => {
    const roleName = row?.agent || row?.branch || row?.agency;
    let role = '';
    switch (roleName) {
      case row?.agent:
        role = SUBJECT.AGENT;
        break;
      case row?.branch:
        role = SUBJECT.BRANCH;
        break;
      case row?.agency:
        role = SUBJECT.OFFICE;
        break;
    }
    dispatch(
      openModal({
        title: (
          <span>
            {row.final <= 0 ? SUBJECT.YOU_WILL_APPROVE : SUBJECT.YOU_WILL_PAY}{' '}
            <span className={styles.rowInfo}>{roleName}</span>
            {role}
            <span className={styles.rowInfo}>{row?.final + '$'}</span>
          </span>
        ),
        okText: row?.final <= 0 ? SUBJECT.APPROVE : SUBJECT.PAY,
        cancelText: SUBJECT.CANCEL,
        cancelButtonVariant: 'gray',
        okButtonVariant: row?.final <= 0 ? 'tableDark' : 'default',
        closeIcon: false,
        onOk: () => {
          changePaymentStatus({ ids: [row?.id] }).then((data: any) => {
            if (data?.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: row.final <= 0 ? SUBJECT.SUCCESFULLY_APPROVED : SUBJECT.SUCCESFULLY_PAID,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
          setSelectedRows([]);
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleAllPay = () => {
    let role = '';
    if (activeTab === SUBJECT.OFFICE) {
      role = selectedRows?.length < 2 ? SUBJECT.OFFICE : SUBJECT.OFFICES;
    } else if (activeTab === SUBJECT.BRANCH) {
      role = selectedRows?.length < 2 ? SUBJECT.BRANCH : SUBJECT.BRANCHES;
    } else {
      role = selectedRows?.length < 2 ? SUBJECT.AGENT : SUBJECT.AGENTS;
    }
    dispatch(
      openModal({
        title: (
          <span>
            {SUBJECT.YOU_WILL_PAY} <span className={styles.rowInfo}>{selectedRows?.length}</span>
            {role}
            <span className={styles.rowInfo}>{summary + '$'}</span>
          </span>
        ),
        okText: selectedRows[0]?.final <= 0 ? SUBJECT.APPROVE : SUBJECT.PAY,
        cancelText: SUBJECT.CANCEL,
        cancelButtonVariant: 'gray',
        okButtonVariant: selectedRows[0]?.final <= 0 ? 'tableDark' : 'default',
        closeIcon: false,
        onOk: () => {
          const ids = selectedRows.map(el => el.id);
          changePaymentStatus({ ids }).then((data: any) => {
            if (data?.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: selectedRows[0].final <= 0 ? SUBJECT.SUCCESFULLY_APPROVED : SUBJECT.SUCCESFULLY_PAID,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              setSelectedRows([]);
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleCancelPayments = () => {
    setSelectedRows([]);
  };

  const handleCheckboxChange = (data: number | string[], items: ISelectedRow[]) => {
    setSelectedRows(items);
  };

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),

      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    view: {
      component: <PaymentView data={paymentById} isFetching={isFetchingById} handlePay={() => null} />,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode === 'customize',
    };
  }, [mode, activeTabCheck, dragColumns, isLoadingColumnsEdit, isLoadingColumnsDelete, isFetchingById]);

  useEffect(() => {
    if (columnsData?.columns) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  useEffect(() => {
    setSearchParams({ ...searchParams, activeTab: activeTabCheck });
  }, []);

  useEffect(() => {
    if (selectedRows?.length) {
      document.querySelector('.page-content')?.classList.add('sticky-requirements');
    }
    return () => {
      document.querySelector('.page-content')?.classList.remove('sticky-requirements');
    };
  }, [selectedRows?.length]);

  const isPayButton = useMemo((): any => {
    return (
      selectedRows?.length &&
      selectedRows[0].status === PaymentStatusTypes.PAYMENT_STATUS_PENDING &&
      selectedRows[0]?.final > 0
    );
  }, [selectedRows?.length]);

  return {
    data,
    columns: columnsData?.columns,
    offices: finalOfficeOptions,
    branches: finalBranchesOptions,
    agents: finalAgentOptions,
    summary,
    paymentFilters,
    activeTab,
    isFilterDirty,
    cardsData: allowedCards!,
    isLoading: isFetching,
    payoutTabs,
    selectedRows,
    filterControl,
    activeTabCheck,
    selectedAgentOption,
    currentChildren,
    filterWatch,
    handleAllPay,
    handleRowClick,
    setFilterValue,
    handleSortClick,
    setAgentsFilter,
    setBranchFilter,
    handleTabChange,
    handleStopResize,
    setOfficesFilter,
    handleRowPayClick,
    handleResetFilters,
    handleColumnSearch,
    handleCancelPayments,
    handleCheckboxChange,
    handlePaginationClick,
    roleLevel,
    dirtyFields,
    isChangedDate,
    isPayButton,
    hasPermissionToPay: paymentPermission?.payPositiveBalance,
    hasPermissionToApprove: paymentPermission?.payNegativeBalance,
    hasPermissionToAction,
    selectedBranches,
  };
};
