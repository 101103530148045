import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { defaultValues } from 'pages/authorization/forgot-password/constants/constants';
import { IForgotFormData } from 'pages/authorization/forgot-password/constants/types';
import { validation } from 'pages/authorization/forgot-password/constants/validation';
import { useResetPasswordMutation } from 'services/auth/auth';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { ls } from 'utils/storage';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';

const useForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    getValues,
  } = useForm<IForgotFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const { searchParams, setSearchParams } = useDetectedParams();
  const { confirm } = searchParams;

  const [resetPassword] = useResetPasswordMutation();

  const handleEmailConfirmation = () => {
    setSearchParams({ reset: 'true' });
    ls.remove('emailTime');
  };

  const onSubmit = (data: IForgotFormData) => {
    const { email } = data;
    if (email) {
      ls.set('emailValue', email);
      resetPassword(email).then((data: any) => {
        if (data?.error) {
          notification.error({
            message:
              data?.error?.data?.code === '1021'
                ? data?.error?.data?.details?.[0]
                : ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          setSearchParams({ reset: 'true', confirm: 'true' });
        }
      });
    }
  };

  return {
    handleSubmit,
    control,
    isValid,
    onSubmit,
    handleEmailConfirmation,
    confirm,
    getValues,
    errors,
  };
};

export default useForgotPassword;
