import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from 'antd';
import clsx from 'clsx';
import { selectIsLoading } from 'store/sidebarSlice/selector';
import Button from 'ui/button/Button';
import Loader from 'ui/loader/Loader';
import { UserTypes } from 'utils/constants';

import DrawerCloseIcon from 'components/svgs/DrawerCloseIcon';
import DrawerDoubleArrowIcon from 'components/svgs/DrawerDoubleArrowIcon';

import { SUBJECT } from './constants/constants';
import { IMainDrawerProps } from './constants/types';
import { useMainDrawer } from './useMainDrawer';

import styles from './MainDrawer.module.scss';

const MainDrawer = ({
  Children,
  buttonText = '',
  onSubmit,
  onCancel,
  withFooter = false,
  withScrollBar = false,
}: IMainDrawerProps) => {
  const {
    onClose,
    onSave,
    tab,
    showLargeDrawer,
    width,
    shouldOpenDrawer,
    fullWidth,
    isValid,
    handleAfterClose,
    mode,
    userType,
    allDrawerFullWidth,
    checkDrawerType,
  } = useMainDrawer({
    onSubmit,
    onCancel,
  });
  const isLoading = useSelector(selectIsLoading);
  const [isLocalDisable, setIsLocalDisable] = useState(isValid);

  useEffect(() => {
    setIsLocalDisable(!isValid);
    const timer = setTimeout(() => {
      if (!isValid) {
        setIsLocalDisable(false);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [mode, isValid, tab]);

  const DrawerFooter = useMemo(() => {
    return withFooter ? (
      <div
        className={clsx(styles.drawerFooter, {
          [styles.withScrollBar]: withScrollBar && mode === 'edit' && fullWidth,
          [styles.withScrollBarOpened]: withScrollBar && mode === 'edit' && fullWidth,
        })}
      >
        <Button onClick={onClose} shape="default" size="large" variant="ghost" children={SUBJECT.CANCEL} />
        <Button
          onClick={() => {
            if (!isLoading) {
              onSave();
            }
          }}
          shape="default"
          size="large"
          children={buttonText}
          disabled={mode === 'customize' || mode === 'customizeResult' ? false : isLocalDisable}
        />
      </div>
    ) : null;
  }, [withFooter, buttonText, isValid, onCancel, withScrollBar, fullWidth, onSubmit]);

  return (
    <div>
      <Drawer
        rootClassName={clsx(styles.drawerWrapper, {
          [styles.fullWidthAllDrawers]: allDrawerFullWidth,
          [styles.carrierDrawerCustomize]: Boolean(checkDrawerType && fullWidth),
        })}
        placement={SUBJECT.RIGHT}
        size={SUBJECT.DEFAULT}
        open={shouldOpenDrawer}
        closable={false}
        width={width}
        footer={DrawerFooter}
        afterOpenChange={open => handleAfterClose(open)}
        destroyOnClose
      >
        <div className={styles.mainDrawer}>
          <div className={styles.drawerControlPanel}>
            <div className={styles.controlButtons}>
              {!(mode === 'carrierTruckViewFromResult' && userType === UserTypes.AGENT) && (
                <Button onClick={onClose} variant="text" shape="default" children={<DrawerCloseIcon />} />
              )}
            </div>
            <div
              className={clsx(styles.drawerOpener, {
                [styles.rotatedArrow]: fullWidth,
              })}
            >
              <Button onClick={showLargeDrawer} variant="text" shape="default" children={<DrawerDoubleArrowIcon />} />
            </div>
          </div>
          <div className={styles.drawerContent}>
            <>{Children && shouldOpenDrawer ? Children : <Loader />}</>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MainDrawer;
