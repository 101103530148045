import React from 'react';

const CollectionIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_502_9682" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.384 9.59204H22.0095V13.7053C14.9627 15.3699 9.71875 21.7004 9.71875 29.2558C9.71875 38.0802 16.8724 45.2338 25.6968 45.2338C34.5212 45.2338 41.6748 38.0802 41.6748 29.2558C41.6748 21.7004 36.4308 15.3699 29.384 13.7053V9.59204Z"
        />
      </mask>
      <path
        d="M22.0095 9.59204V7.59204H20.0095V9.59204H22.0095ZM29.384 9.59204H31.384V7.59204H29.384V9.59204ZM22.0095 13.7053L22.4693 15.6517L24.0095 15.2879V13.7053H22.0095ZM29.384 13.7053H27.384V15.2879L28.9242 15.6518L29.384 13.7053ZM22.0095 11.592H29.384V7.59204H22.0095V11.592ZM24.0095 13.7053V9.59204H20.0095V13.7053H24.0095ZM11.7188 29.2558C11.7188 22.6487 16.3047 17.1079 22.4693 15.6517L21.5498 11.7589C13.6208 13.6318 7.71875 20.7521 7.71875 29.2558H11.7188ZM25.6968 43.2338C17.9769 43.2338 11.7188 36.9756 11.7188 29.2558H7.71875C7.71875 39.1848 15.7678 47.2338 25.6968 47.2338V43.2338ZM39.6748 29.2558C39.6748 36.9756 33.4166 43.2338 25.6968 43.2338V47.2338C35.6257 47.2338 43.6748 39.1848 43.6748 29.2558H39.6748ZM28.9242 15.6518C35.0889 17.1079 39.6748 22.6488 39.6748 29.2558H43.6748C43.6748 20.7521 37.7727 13.6318 29.8438 11.7589L28.9242 15.6518ZM27.384 9.59204V13.7053H31.384V9.59204H27.384Z"
        fill="var(--black)"
        mask="url(#path-1-inside-1_502_9682)"
      />
      <path
        d="M25.7482 10.0396C25.9309 6.62586 19.4563 3.50728 17.2616 10.1273C15.5483 15.2953 8.55716 13.8919 9.02602 8.37576"
        stroke="var(--black)"
        strokeWidth="1.11765"
      />
      <path
        d="M24.6764 36.6285H27.2948V34.7491H29.3896C29.826 34.7491 30.1824 34.582 30.4588 34.2479C30.7352 33.9138 30.8734 33.5379 30.8734 33.1202V28.3279C30.8734 27.8753 30.7352 27.489 30.4588 27.1688C30.1824 26.8486 29.826 26.6885 29.3896 26.6885H23.4544V23.8067H30.8734V21.3008H27.2948V19.4214H24.6764V21.3008H22.5379C22.1015 21.3008 21.7088 21.4679 21.3596 21.802C21.0105 22.1361 20.8359 22.5275 20.8359 22.9763V27.7277C20.8359 28.1764 21.0105 28.5331 21.3596 28.7976C21.7088 29.0621 22.1015 29.1943 22.5379 29.1943H28.2549V32.2432H20.8359V34.7491H24.6764V36.6285Z"
        fill="var(--black)"
      />
      <rect x="8.54297" y="5.89844" width="1.22908" height="1.22908" fill="var(--black)" />
      <rect x="8.54297" y="3.44141" width="1.22908" height="1.22908" fill="var(--black)" />
      <rect
        x="6.08594"
        y="8.35571"
        width="1.22908"
        height="1.22908"
        transform="rotate(-90 6.08594 8.35571)"
        fill="var(--black)"
      />
      <rect
        x="5.47266"
        y="5.01367"
        width="1.22908"
        height="1.22908"
        transform="rotate(-35 5.47266 5.01367)"
        fill="var(--black)"
      />
      <rect
        x="11.707"
        y="4.31348"
        width="1.22908"
        height="1.22908"
        transform="rotate(35 11.707 4.31348)"
        fill="var(--black)"
      />
      <rect
        x="12.2305"
        y="7.1272"
        width="1.22908"
        height="1.22908"
        transform="rotate(90 12.2305 7.1272)"
        fill="var(--black)"
      />
    </svg>
  );
};

export default CollectionIcon;
