import { Col, Row } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { formatPhone } from 'utils/helpers';

import MainDrawer from 'components/main-drawer/MainDrawer';

import { LoadTypes } from '../loads/components/edit-load/constants/types';

import { SUBJECT } from './constants/constants';
import useSearchResult from './useSearchResult';

import styles from './SearchResult.module.scss';

const SearchResult = () => {
  const {
    searchParams: { id: _id, headerFilter },
  } = useDetectedParams();

  const { component, buttonText, onCancel, onSubmit, withFooter, handleOpen, carrierInfo, customerInfo, loadsInfo } =
    useSearchResult();

  return (
    <div className={styles.mainResult}>
      {carrierInfo || customerInfo || loadsInfo ? (
        <>
          <div className={styles.ResultWrapper}>
            <Row align="middle" gutter={[8, 14]} className={styles.topWrapper}>
              <Col span={3}>
                <Typography variant="paragraph" children={SUBJECT.RESULT} />
              </Col>
              <Col span={21}>
                <Button onClick={handleOpen} children={SUBJECT.OPEN} />
              </Col>
            </Row>
            <Row align="middle" gutter={[8, 14]}>
              <Col span={3}>
                <Typography variant="paragraph" children={SUBJECT.SEARCH_TYPE} className={styles.text} />
              </Col>
              <Col span={21}>
                <Typography
                  variant="paragraph"
                  children={headerFilter[0].toUpperCase() + headerFilter.slice(1)}
                  className={styles.strongText}
                />
              </Col>
              {headerFilter === 'loads' && (
                <>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.LOAD_ID} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography variant="paragraph" children={loadsInfo.code} className={styles.strongText} />
                  </Col>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.REF_NUMBER} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography
                      variant="paragraph"
                      children={loadsInfo.referenceNumber}
                      className={styles.strongText}
                    />
                  </Col>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.LOAD_TYPE} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography
                      variant="paragraph"
                      children={LoadTypes[loadsInfo.type]}
                      className={styles.strongText}
                    />
                  </Col>
                </>
              )}
              {headerFilter === 'carriers' && (
                <>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.CONTACT_NAME} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography variant="paragraph" children={carrierInfo.contactName} className={styles.strongText} />
                  </Col>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.COMPANY_NAME} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography variant="paragraph" children={carrierInfo.companyName} className={styles.strongText} />
                  </Col>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.CARRIER_ID} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography variant="paragraph" children={carrierInfo.id} className={styles.strongText} />
                  </Col>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.PHONE_NUMBER} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography
                      variant="paragraph"
                      children={formatPhone(carrierInfo.phone)}
                      className={styles.strongText}
                    />
                  </Col>
                </>
              )}
              {headerFilter === 'customers' && (
                <>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.CUSTOMER_NAME} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography
                      variant="paragraph"
                      children={customerInfo.customerName}
                      className={styles.strongText}
                    />
                  </Col>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.CUSTOMER_ID} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography variant="paragraph" children={customerInfo.id} className={styles.strongText} />
                  </Col>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.CUSTOMER_PHONE} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography variant="paragraph" children={customerInfo.phone} className={styles.strongText} />
                  </Col>
                  <Col span={3}>
                    <Typography variant="paragraph" children={SUBJECT.CUSTOMER_MC} className={styles.text} />
                  </Col>
                  <Col span={21}>
                    <Typography variant="paragraph" children={customerInfo.mc} className={styles.strongText} />
                  </Col>
                </>
              )}
            </Row>
          </div>
          <MainDrawer
            Children={component}
            buttonText={buttonText}
            onCancel={onCancel}
            withFooter={withFooter}
            onSubmit={onSubmit}
          />
        </>
      ) : (
        <Typography className={styles.noResult} variant="paragraph" children={SUBJECT.NO_RESULT} />
      )}
    </div>
  );
};

export default SearchResult;
