import { ITransactionFormData } from 'pages/loads/components/edit-load/constants/types';
import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ICustomDropdownProps } from 'ui/inputs/select/components/dropdown-with-custom-input/constants/types';
import CustomSelectDropDown from 'ui/inputs/select/components/dropdown-with-custom-input/CustomDropdown';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import CustomSelect from 'ui/inputs/select/Select';
import { ISwitchProps } from 'ui/inputs/switch/constants/types';
import CustomSwitch from 'ui/inputs/switch/Switch';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

export const Input = withController<IInputProps, any>(CustomInput);

export const SearchableSelect = withController<ISearchableSelectProps, any>(CustomSearchableSelect);

export const CustomerSwitch = withController<ISwitchProps, any>(CustomSwitch);
export const MarkedAsClaim = withController<ISwitchProps, any>(CustomSwitch);
export const CustomDropDown = withController<Partial<ICustomDropdownProps>, any>(CustomSelectDropDown);
export const Select = withController<ISelectProps, any>(CustomSelect);
export const Switch = withController<ISwitchProps, any>(CustomSwitch);
export const SearchableDropdown = withController<ISearchableDropdownProps, any>(CustomSearchableDropdown);
export const TransactionInput = withController<IInputProps, ITransactionFormData>(CustomInput);
export const TransactionSelect = withController<ISelectProps, ITransactionFormData>(CustomSelect);
