import { useDetectedParams } from 'hooks/useDetectedParams';

export const useNewCreditView = () => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { id } = searchParams;

  const handleCommentClick = () => {
    setSearchParams({
      ...searchParams,
      mode: 'comments',
      open: 'true',
      id,
    });
  };

  return {
    handleCommentClick,
    id,
  };
};
