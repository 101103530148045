import { Control, UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ICarrierSerializedInfo } from 'services/factoring/payables/interface';
import { ICarrierByIdData } from 'services/profile/carriers/interfaces';

import { IPayablesFilter } from '../../../constants/types';

export interface IOptions {
  title: string;
  value: string;
  key?: string;
}

export interface IPayablesProps {
  carrierById: ICarrierByIdData;
  handleViewFileClick: any;
  handleDownloadClick: any;
  handlePaginationClick: any;
  isLoading: boolean;
  isInfoLoading: boolean;
  carrierInfo: {
    count: number;
    expected: number;
    paid: number;
    result: ICarrierSerializedInfo[];
  };
  getCarrierPay: MutationTrigger<
    MutationDefinition<
      {
        ids: number[];
      },
      (args: any, api: any, extraOptions: any) => Promise<any>,
      'Payables',
      any,
      'payablesAPI'
    >
  >;
  resetField: UseFormResetField<IPayablesFilter>;
  watch: UseFormWatch<IPayablesFilter>;
  setValue: UseFormSetValue<IPayablesFilter>;
  control: Control<IPayablesFilter>;
  pagePermissions: any;
}

export enum LoadsViewTypes {
  'regularLoad' = 1,
  'tonuLoad' = 2,
  'connectingLoad' = 3,
  'internalBoardLoad' = 4,
}
