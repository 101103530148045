import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import { ArrowBack } from 'components/svgs/ArrowBack';
import DraggableIcon from 'components/svgs/DraggableIcon';
import EyeIcon from 'components/svgs/EyeIcon';

import { MainLoader } from '../../ui/main-loader/MainLoader';

import { SUBJECT } from './constants/constants';
import { ICustomColumnProps } from './constants/types';
import { useCustomColumn } from './useCustomColumn';

import styles from './CustomColumn.module.scss';

const CustomColumn = ({
  columns = [],
  dragColumns: dragColumnsState,
  setDragColumns,
  handleResetToDefault,
  isDefault,
  isSuccess,
  setIsSuccess,
  setIsDragged,
  onCustomizeSubmit,
  isLoading,
}: ICustomColumnProps | any) => {
  const {
    dragColumns,
    onDragStart,
    onDragEnter,
    onDrop,
    handleVisibility,
    handleUndo,
    position,
    setPosition,
    draggedIndex,
    setDraggedIndex,
    changeRef,
    handleResetClick,
    lastUndoIndex,
    lastUndoPrevIndex,
    isAnimating,
  } = useCustomColumn({
    dragColumns: dragColumnsState,
    setDragColumns,
    columns,
    handleResetToDefault,
    isSuccess,
    setIsSuccess,
    setIsDragged,
    onCustomizeSubmit,
    isLoading,
  });

  const columnsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const columnsCount = columns.length < 10 ? 1 : columns.length <= 22 ? 2 : 3;
    const maxCount = Math.round(columns.length / columnsCount);

    if (columnsRef.current) {
      columnsRef.current.style.setProperty('--columnsCount', `${maxCount}`);
    }
  }, [columns.length, columnsRef]);

  return (
    <div className={clsx(styles.customizeColumnsContainer)}>
      <MainLoader />
      <div className={clsx(styles.customizeTopBlock)}>
        <div className={clsx(styles.customizeHeader)}>
          <h1>{SUBJECT.CUSTOMIZE_COLUMNS}</h1>
          <Typography variant="paragraph">{SUBJECT.SELECT_COLUMNS}</Typography>
        </div>
        <div className={styles.customizeTopBlockActions}>
          {!!changeRef.current.length && (
            <Button icon={<ArrowBack />} className="backToBtn" children={SUBJECT.UNDO} onClick={handleUndo} />
          )}
          {!isDefault && !isSuccess && (
            <Button icon={<ArrowBack />} className="backToBtn" children={SUBJECT.RESET} onClick={handleResetClick} />
          )}
        </div>
      </div>
      <div className={clsx(styles.columnsContainer)} ref={columnsRef}>
        {dragColumns?.map(({ id, title, isVisible, color }: any, index: number) => {
          const isDragging = index === draggedIndex;

          return (
            <div
              key={index}
              onDragStart={e => {
                onDragStart(e, index);
                setDraggedIndex(index);
                e.dataTransfer.setData('text/plain', '');
              }}
              onDragEnter={e => {
                onDragEnter(e, index);
                e.preventDefault();
              }}
              onDragOver={e => {
                e.preventDefault();
              }}
              onDragEnd={e => {
                e.preventDefault();
                setPosition(-1);
                onDrop();
                setDraggedIndex(-1);
              }}
              draggable
              className={clsx(
                styles.columnItem,
                {
                  [styles.dragging]:
                    isDragging ||
                    isAnimating ||
                    index === lastUndoIndex ||
                    index === lastUndoPrevIndex ||
                    (index === lastUndoIndex &&
                      lastUndoIndex < Number(lastUndoPrevIndex) &&
                      lastUndoIndex + 1 === Number(lastUndoPrevIndex)) ||
                    (index === lastUndoPrevIndex &&
                      Number(lastUndoIndex) < lastUndoPrevIndex &&
                      Number(lastUndoIndex) + 1 === lastUndoPrevIndex) ||
                    (index === lastUndoIndex &&
                      lastUndoIndex > Number(lastUndoPrevIndex) &&
                      lastUndoIndex - 1 === lastUndoPrevIndex) ||
                    (index === lastUndoPrevIndex &&
                      Number(lastUndoIndex) > lastUndoPrevIndex &&
                      Number(lastUndoIndex) - 1 === lastUndoPrevIndex),
                },
                { [styles.hide]: position === index }
              )}
              style={{ backgroundColor: color }}
              onMouseDown={() => {
                setDraggedIndex(index);
              }}
              onMouseUp={() => {
                setDraggedIndex(-1);
              }}
            >
              <div className={clsx(styles.columnTextWrapper)}>
                <div className={clsx(styles.draggableIcon)}>
                  <DraggableIcon />
                </div>
                <div className={clsx(styles.columnTextBlock)}>
                  <Typography variant="paragraph">{title}</Typography>
                  <span>{`Item ${index + 1}`}</span>
                </div>
              </div>
              <div
                className={clsx(styles.columnEye, { [styles.columnEyeDisabled]: !isVisible })}
                onClick={() => {
                  handleVisibility(id);
                }}
              >
                <EyeIcon isVisible={isVisible} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomColumn;
