import * as yup from 'yup';

import { VALIDATION_MESSAGES } from '../../constants/constants';

const {
  REFERENCE_NUMBER_REQUIRED,
  AUTHORITY_REQUIRED,
  FILE_REQUIRED,
  TRUCK_REQUIRED,
  CUSTOMER_NAME_REQUIRED,
  CUSTOMER_AMOUNT_REQUIRED,
  CARRIER_REQUIRED,
  CARRIER_AMOUNT_REQUIRED,
  CUSTOMER_CONTACT_NAME_REQUIRED,
} = VALIDATION_MESSAGES;

export const tonuValidationForEdit = yup.object().shape({
  load_type: yup.string().required(),
  customerSwitch: yup.boolean().notRequired().nullable(),
  carrierSwitch: yup.boolean().notRequired().nullable(),
  soldUsSwitch: yup.boolean().notRequired().nullable(),
  customerName: yup.string().test('length', CUSTOMER_NAME_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.customerSwitch) {
      return !!value;
    }
    return true;
  }),
  customerContactName: yup.string().test('isRequired', CUSTOMER_CONTACT_NAME_REQUIRED, function (value) {
    if (this.parent.customerSwitch) {
      return !!value;
    }
    return true;
  }),
  referenceNumber: yup.string().test('length', REFERENCE_NUMBER_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.customerSwitch) {
      return !!value;
    }
    return true;
  }),
  customerAmount: yup.string().test('length', CUSTOMER_AMOUNT_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.customerSwitch) {
      if (this.parent.carrierSwitch) {
        if (value) {
          if (Number(value) < Number(this.parent.carrierAmount)) {
            return this?.createError({
              message: `Customer amount must be more or equal than Carrier amount`,
              path: 'customerAmount',
            });
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return !!value;
      }
    }
    return true;
  }),
  customerContact: yup.string().test('length', CUSTOMER_AMOUNT_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.customerSwitch) {
      return !!value;
    }
    return true;
  }),
  takenUsAuthority: yup.string().test('length', AUTHORITY_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.customerSwitch) {
      return !!value;
    }
    return true;
  }),
  carrier: yup.string().test('length', CARRIER_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.carrierSwitch) {
      return !!value;
    }
    return true;
  }),
  carrierAmount: yup.string().test('length', CARRIER_AMOUNT_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.carrierSwitch) {
      if (this.parent.customerSwitch) {
        if (value) {
          if (Number(value) > Number(this.parent.customerAmount)) {
            return this?.createError({
              message: `Carrier amount must be less or equal than Customer amount`,
              path: 'carrierAmount',
            });
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return !!value;
      }
    }
    return true;
  }),
  truck: yup.string().test('length', TRUCK_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.carrierSwitch) {
      return !!value;
    }
    return true;
  }),
  allAuthorities: yup.array().test('length', AUTHORITY_REQUIRED, function checkCarrierValue(value) {
    if (this.parent.soldUsSwitch) {
      return value && !!value.length;
    }
    return true;
  }),
  file: yup.array().test('length', FILE_REQUIRED, function checkCarrierValue() {
    return true;
  }),
  markAsClaimSwitch: yup.boolean().notRequired().nullable(),
});
