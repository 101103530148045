import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import {
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  unicodeFormatter,
} from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import {
  AUTHORITY_KEYS,
  CARRIER_KEYS,
  CUSTOMER_CUSTOM_KEYS,
  MY_AUTHORITY_COLUMNS,
  SEARCH_CARRIER_COLUMNS,
  SEARCH_CUSTOM_CUSTOMER_COLUMNS,
  SEARCH_TRUCK_COLUMNS,
  SUBJECT,
  TRUCK_KEYS,
} from '../constants/constants';
import { Input, SearchableDropdown, Switch } from '../hoc-instance/instanse';

import styles from './TonuCreate.module.scss';

const TonuCreate = ({
  tonuWatch,
  getTonuValues,
  tonuSetValue,
  handleTonuSubmit,
  isTonuValid,
  tonuControl,
  tonuErrors,
  tonuTrigger,
  onSubmit,
  touchedFields,
  activeSection,
  carriersOptions,
  setCarriersFilter,
  truckOptions,
  setTrucksFilter,
  customersOptions,
  setCustomerFilter,
  authorities,
  setAuthoritiesState,
  handleChangeCarrierSwitch,
  handleChangeCustomerSwitch,
  handleChangeSoldAsSwitch,
  handleViewFileCreate,
  handleDeleteFileCreate,
  handleDownloadFileCreate,
  handleDownloadMultiplyFileCreate,
  beforeUploadForCreate,
  allFiles,
  isCreateTonuLoading,
}: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isCreateTonuLoading }));
  }, [isTonuValid, isCreateTonuLoading]);

  useEffect(() => {
    if (tonuWatch('truck').length) {
      tonuSetValue('truck', [], { shouldDirty: false, shouldTouch: false, shouldValidate: true });
    }
  }, [tonuWatch('carrier')]);

  useEffect(() => {
    if (tonuWatch('carrier')[0]?.type === 'Outside') {
      tonuSetValue('soldUsSwitch', true, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    } else if (tonuWatch('carrier')[0]?.type !== 'Outside') {
      tonuSetValue('soldUsSwitch', false, {
        shouldTouch: false,
        shouldDirty: false,
        shouldValidate: false,
      });
      tonuSetValue('allAuthorities', [], {
        shouldTouch: false,
        shouldDirty: false,
        shouldValidate: false,
      });
    }
  }, [tonuWatch('carrierSwitch'), tonuWatch('carrier')]);

  return (
    <form onSubmit={handleTonuSubmit(onSubmit)} className={styles.tonuLoadForm}>
      <ViewItemWrapper
        className={activeSection === SUBJECT.GENERAL_INFORMATION_ID ? 'active' : ''}
        id="general-information"
        title={SUBJECT.TONU_LOAD_CREATE}
      >
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="loadType"
              type="text"
              control={tonuControl}
              rules={{ required: true }}
              placeholder={SUBJECT.LOAD_TYPE}
              getValues={getTonuValues}
              errors={tonuErrors}
              required
              disabled
            />
          </Col>
          <Col span={8} />
          <Col span={8} />
          {tonuWatch('customerSwitch') && (
            <>
              <Col span={8}>
                <SearchableDropdown
                  options={customersOptions?.result}
                  columns={SEARCH_CUSTOM_CUSTOMER_COLUMNS}
                  placeholder="Customer"
                  offset={customersOptions?.count}
                  setOffset={setCustomerFilter}
                  values={tonuWatch('customerName')}
                  keys={CUSTOMER_CUSTOM_KEYS}
                  control={tonuControl}
                  name="customerName"
                  errors={tonuErrors}
                  showKey="title"
                  setValue={tonuSetValue}
                  withoutBorder={true}
                  required
                  rules={{ required: true }}
                  width={620}
                  onBlur={() => {
                    if (Object.keys(touchedFields).includes('takenUsAuthority')) {
                      setTimeout(() => {
                        tonuTrigger(['takenUsAuthority', 'customerName']);
                      }, 300);
                    } else {
                      setTimeout(() => {
                        tonuTrigger(['customerName']);
                      }, 300);
                    }
                  }}
                />
              </Col>
              <Col span={8}>
                <Input
                  suffix={<DollarIcon />}
                  name="customerAmount"
                  type="text"
                  control={tonuControl}
                  rules={{ required: true }}
                  placeholder={SUBJECT.CUSTOMER_AMOUNT}
                  onChange={(e: any) => {
                    if (tonuWatch('carrierAmount')) {
                      tonuTrigger('carrierAmount');
                    }
                    tonuSetValue('customerAmount', floatNumberFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  value={
                    getTonuValues('customerAmount')
                      ? formatNumberWithThousandsSeparatorAndDecimal(getTonuValues('customerAmount'))
                      : ''
                  }
                  errors={tonuErrors}
                  required
                />
              </Col>
              <Col span={8}>
                <Input
                  name="referenceNumber"
                  type="text"
                  control={tonuControl}
                  rules={{ required: true }}
                  placeholder={SUBJECT.REFERENCE_NUMBER}
                  getValues={getTonuValues}
                  errors={tonuErrors}
                  required
                  onChange={(e: any) =>
                    tonuSetValue('referenceNumber', e.target.value.trimStart(), { shouldValidate: true })
                  }
                />
              </Col>
              {tonuWatch('customerSwitch') && (
                <Col span={8}>
                  <SearchableDropdown
                    options={authorities?.result}
                    columns={MY_AUTHORITY_COLUMNS}
                    keys={AUTHORITY_KEYS}
                    placeholder={SUBJECT.TAKEN_AS_AUTHORITY}
                    name="takenUsAuthority"
                    control={tonuControl}
                    setValue={tonuSetValue}
                    values={tonuWatch('takenUsAuthority')}
                    offset={authorities?.count}
                    setOffset={setAuthoritiesState}
                    showKey="name"
                    required
                    errors={tonuErrors}
                    rules={{ required: true }}
                    width={500}
                    onBlur={() => {
                      if (Object.keys(touchedFields).includes('customerName')) {
                        setTimeout(() => {
                          tonuTrigger(['takenUsAuthority', 'customerName']);
                        }, 300);
                      } else {
                        setTimeout(() => {
                          tonuTrigger(['takenUsAuthority']);
                        }, 300);
                      }
                    }}
                  />
                </Col>
              )}
              <Col span={8}>
                <Input
                  name="customerContactName"
                  type="text"
                  control={tonuControl}
                  rules={{ required: true }}
                  placeholder={SUBJECT.CONTACT_NAME}
                  getValues={getTonuValues}
                  errors={tonuErrors}
                  required
                  onChange={(e: any) => {
                    tonuSetValue('customerContactName', unicodeFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                />
              </Col>
              <Col span={8}>
                <div className={clsx({ [styles.customerContactISize]: !tonuWatch('carrierSwitch') })}>
                  <Input
                    name="customerContact"
                    type="text"
                    control={tonuControl}
                    rules={{ required: true }}
                    placeholder={SUBJECT.CUSTOMER_CONTACT}
                    getValues={getTonuValues}
                    onChange={(e: any) => {
                      tonuSetValue('customerContact', formatPhone(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    errors={tonuErrors}
                    required
                  />
                </div>
              </Col>
            </>
          )}
          {tonuWatch('carrierSwitch') && (
            <Col span={24}>
              <Row gutter={[18, 40]}>
                <Col span={8}>
                  <SearchableDropdown
                    options={carriersOptions?.result}
                    columns={SEARCH_CARRIER_COLUMNS}
                    placeholder="Carrier"
                    offset={carriersOptions?.count}
                    setOffset={setCarriersFilter}
                    values={tonuWatch('carrier')}
                    keys={CARRIER_KEYS}
                    control={tonuControl}
                    name="carrier"
                    errors={tonuErrors}
                    showKey="title"
                    setValue={tonuSetValue}
                    withoutBorder={true}
                    required
                    rules={{ required: true }}
                    width={620}
                  />
                </Col>
                {tonuWatch('carrier')[0]?.type === 'Owner Operator' && (
                  <Col span={8}>
                    <SearchableDropdown
                      options={truckOptions?.result?.map((truck: any) => ({
                        title: truck?.truckNumber,
                        value: String(truck?.id),
                      }))}
                      placeholder={SUBJECT.TRUCK}
                      offset={truckOptions?.count}
                      setOffset={setTrucksFilter}
                      values={tonuWatch('truck')}
                      columns={SEARCH_TRUCK_COLUMNS}
                      keys={TRUCK_KEYS}
                      control={tonuControl}
                      name="truck"
                      showKey="title"
                      setValue={tonuSetValue}
                      withoutBorder={true}
                      required={tonuWatch('carrier')[0]?.type === 'Owner Operator'}
                      disabled={!tonuWatch('carrier').length}
                      rules={{ required: true }}
                    />
                  </Col>
                )}
                <Col span={8}>
                  <Input
                    suffix={<DollarIcon />}
                    name="carrierAmount"
                    type="text"
                    control={tonuControl}
                    rules={{ required: true }}
                    placeholder={SUBJECT.CARRIER_AMOUNT}
                    value={
                      getTonuValues('carrierAmount')
                        ? formatNumberWithThousandsSeparatorAndDecimal(getTonuValues('carrierAmount'))
                        : ''
                    }
                    onChange={(e: any) => {
                      tonuSetValue('carrierAmount', floatNumberFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    errors={tonuErrors}
                    required
                  />
                </Col>
              </Row>
            </Col>
          )}

          <Col span={24}>
            <Row>
              <Col span={2}>
                <Typography variant="heading" children={SUBJECT.SOLD_US} className={styles.switchHeading} />
              </Col>
              <Col span={3}>
                <Switch
                  control={tonuControl}
                  name="soldUsSwitch"
                  checked={tonuWatch('soldUsSwitch')}
                  disabled={!tonuWatch('carrierSwitch') || tonuWatch('carrier')[0]?.type === 'Outside'}
                  onChange={handleChangeSoldAsSwitch}
                />
              </Col>
              <Col span={2}>
                <Typography variant="heading" children="Carrier" className={styles.switchHeading} />
              </Col>
              <Col span={2}>
                <Switch
                  control={tonuControl}
                  disabled={tonuWatch('carrierSwitch') && !tonuWatch('customerSwitch')}
                  name="carrierSwitch"
                  checked={tonuWatch('carrierSwitch')}
                  onChange={handleChangeCarrierSwitch}
                />
              </Col>
              <Col span={3}>
                <Typography variant="heading" children="Customer" className={styles.switchHeading} />
              </Col>
              <Col span={12}>
                <Switch
                  control={tonuControl}
                  disabled={tonuWatch('customerSwitch') && !tonuWatch('carrierSwitch')}
                  name="customerSwitch"
                  checked={tonuWatch('customerSwitch')}
                  onChange={handleChangeCustomerSwitch}
                />
              </Col>
              {!tonuWatch('soldUsSwitch') && !tonuWatch('customerSwitch') && (
                <Col span={24}>
                  <ErrorMessage fieldError={tonuErrors?.allAuthorities} />
                </Col>
              )}
            </Row>
          </Col>
          {tonuWatch('soldUsSwitch') && (
            <>
              <Col span={8}>
                <SearchableDropdown
                  options={authorities?.result}
                  columns={MY_AUTHORITY_COLUMNS}
                  keys={AUTHORITY_KEYS}
                  placeholder={SUBJECT.SOLD_US_AUTHORITY}
                  name="allAuthorities"
                  control={tonuControl}
                  setValue={tonuSetValue}
                  values={tonuWatch('allAuthorities')}
                  offset={authorities?.count}
                  setOffset={setAuthoritiesState}
                  showKey="name"
                  required
                  errors={tonuErrors}
                  rules={{ required: true }}
                  width={500}
                />
              </Col>
            </>
          )}
        </Row>
      </ViewItemWrapper>
      <div className={clsx(styles.uploadSection)}>
        <ViewItemWrapper
          id="documentation"
          title={SUBJECT.DOCUMENTATION}
          className={activeSection === SUBJECT.DOCUMENTATION_ID ? 'active' : ''}
        >
          <Typography
            variant="heading"
            children={SUBJECT.UPLOAD_RED_CONFIRMATION}
            className={clsx(styles.uploadHeading)}
          />
          <Row align="middle" gutter={[20, 40]}>
            <Col span={9}>
              <Upload
                accept="application/pdf"
                uploadText={SUBJECT.UPLOAD_RED_CONFIRMATION}
                className={styles.upload}
                beforeUpload={beforeUploadForCreate}
                multiple={false}
                uploadType="2"
              />
            </Col>
            <Col span={8}>
              <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT_PDF} className={styles.allowedFormat} />
            </Col>
            {!!allFiles.length && (
              <>
                <Col span={24}>
                  <Row justify="end" gutter={10} align="middle">
                    <>
                      <Col>
                        <Typography variant="heading" children={SUBJECT.DOWNLOAD_ALL} className={styles.downloadAll} />
                      </Col>
                      <Col>
                        <div
                          className={clsx(styles.uploadControl, styles.downloadBtn)}
                          onClick={() => handleDownloadMultiplyFileCreate(allFiles)}
                        >
                          <DownloadIcon />
                        </div>
                      </Col>
                    </>
                  </Row>
                </Col>
                <Col span={24}>
                  <Controller
                    control={tonuControl}
                    render={({ field: { value: _value } }) => (
                      <FileItems
                        handleDownloadFile={handleDownloadFileCreate}
                        handleViewFileCreate={handleViewFileCreate}
                        onDeleteClick={handleDeleteFileCreate}
                        files={allFiles}
                        withCrossing
                      />
                    )}
                    name="file"
                  />
                </Col>
              </>
            )}
          </Row>
        </ViewItemWrapper>
      </div>
    </form>
  );
};

export default TonuCreate;
