import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { IRoleLevel } from '../../../../../../../services/truck-board/carrier-request/interfaces';
import { SUBJECT } from '../../constants/constants';

import styles from './CollectionView.module.scss';

interface ICollectionViewProps {
  handleChangeMode: () => void;
  edit: boolean;
  data: {
    result: {
      office: { id: number; name: string };
      agent: { id: number; firstName: string; lastName: string } | null;
      amount: number;
      branch: { id: number; name: string };
      comment: string;
      id: number;
      paymentPlan: number;
      createdFor: number;
    };
  };
  isFetching: boolean;
  roleLevel: number;
}

export const CollectionView = ({ data, isFetching, edit, handleChangeMode, roleLevel }: ICollectionViewProps) => {
  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetching} />
      <ViewItemWrapper
        title={SUBJECT.FULL_INFO}
        Component={edit && <Button onClick={handleChangeMode} children={SUBJECT.EDIT} className="viewDrawerEditBtn" />}
      >
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.result?.createdFor)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          {roleLevel <= IRoleLevel.officeLevel && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.OFFICE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.office?.name}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}

          {roleLevel <= IRoleLevel.branchLevel && data?.result?.branch?.name && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.BRANCH} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.branch?.name}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}

          {roleLevel <= IRoleLevel.branchLevel && data?.result?.agent?.firstName && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.AGENT} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={`${data?.result?.agent?.firstName} ${data?.result?.agent?.lastName}`}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}

          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PAYMENT_PLAN} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={
                    data?.result?.paymentPlan < 2 ? SUBJECT.NO_PAYMENT_PLAN : `${data?.result?.paymentPlan} months`
                  }
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AMOUNT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatNumberWithThousandsSeparatorAndDecimal(data?.result?.amount, true)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </div>
  );
};
