import { Dispatch, SetStateAction } from 'react';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';

export interface ISelectOptions {
  title: string | number;
  value?: string | number;
  key?: string;
  children?: IOptions[];
}

export interface IOptions {
  title: string;
  value: string;
  key?: string;
}

export interface IAuthoritiesFormData {
  name: string;
  authority_type: string;
  bankName: string;
  accounting_number: string;
  ach_routing_number: string;
  mc: number | null;
  phone: number | null;
  fax: number | null;
  email: string;
  address: string;
  secondAddress: string;
  city: string;
  stateId: string;
  zip: number | null;
  files: Array<number>;
  packet: number[] | [];
  broker: number[] | [];
  search?: string;
  dot: null;
  ffNumber: null;
  stateNumber: null;

  physical: boolean;
  physicalAddress: string;
  physicalSecondAddress: string;
  physicalCity: string;
  physicalStateId: string;
  physicalZip: number | null;
  templateId: string;
  privacyPolicy: string;
}

export interface IAuthoritiesFilter {
  authorities?: string | null;
  status?: string | null;
  type?: string | null;
  officeId?: string | null;
  used: string;
}

export interface IAuthoritiesTableColumns {
  title: string;
  id: string;
  dataIndex: string;
  displayType: string;
  isSearchable: boolean;
  isVisible: boolean;
  sortable: boolean;
  width: number;
}

export interface IAuthoritiesTableData {
  id: number | string;
  date: string | Date;
  name: string;
  authorities_id: string | number;
  phone_number: string | number;
  mc: number;
  fax_number: number | string;
  authorities_type: string;
  address: string;
  city: string;
  state: string;
  zipcode: number | string;
  noa: boolean;
  terminate: boolean;
}

export enum AuthorityFileTypes {
  AUTHORITY_FILE_TYPES_UNSPECIFIED = 0,
  NOA = 1,
  PACKET = 2,
  BROKER = 3,
}

export interface IAuthoritiesParams {
  setExcelParams?: Dispatch<SetStateAction<IGetAuthoritiesParams | undefined>>;
}

export enum AuthorityTypes {
  'Carrier' = 1,
  'Broker' = 2,
}
