import Button from 'ui/button/Button';
import ExcelButton from 'ui/excel-button/ExcelButton';
import { getContactTabs } from 'utils/constants';

import CustomizeColumnButton from 'components/customize-column-button/CustomizeColumnButton';
import TabPanel from 'components/tab-panel/TabPanel';

import { SUBJECT } from './constants/constants';
import useContact from './useContact';

const Contact = () => {
  const {
    tab,
    openMainDrawer,
    downloadExcel,
    Component,
    isButtonVisible,
    permissionsInfo,
    hasAddPermission,
    hasDownloadPermission,
  } = useContact();

  return (
    <div>
      <TabPanel tabs={getContactTabs(permissionsInfo?.permissions?.contact?.visibleTabs)}>
        <div className="tabPanelButtons">
          <CustomizeColumnButton onClick={() => openMainDrawer('customize')} isButtonVisible={isButtonVisible} />
          {hasAddPermission && (
            <Button
              onClick={() => openMainDrawer('add')}
              children={SUBJECT.CREATE_CONTACT}
              className="panelAddButton"
            />
          )}
          {hasDownloadPermission && <ExcelButton onClick={downloadExcel} isButtonVisible={isButtonVisible} />}
        </div>
      </TabPanel>

      <div>{Component[tab!]}</div>
    </div>
  );
};
export default Contact;
