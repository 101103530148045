import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Divider, Row } from 'antd';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatPhone, unicodeFormatter, unicodeSpaceNumbersFormatter } from 'utils/helpers';

import { Input } from '../hoc-instance/instance';

import { SUBJECT } from './constants/constants';
import { ITruckCarrierRequestCreateEditProps } from './constants/types';

import styles from './CarrierRequestCreateEdit.module.scss';

const CarrierRequestCreateEdit = ({
  errors,
  control,
  getValues,
  onSubmit,
  handleSubmit,
  setValue,
  watch,
  isEditLoading,
  isCreateLoading,
  dirtyFields,
  mode,
}: ITruckCarrierRequestCreateEditProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (mode === 'edit') {
      dispatch(setIsValid({ isValid: !isEditLoading }));
    } else {
      dispatch(setIsValid({ isValid: !isCreateLoading }));
    }
  }, [isEditLoading, isCreateLoading, mode, Object.keys(dirtyFields)?.length]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainLoader />
      <div className={styles.truckCarrierRequest}>
        <Typography variant="paragraph" children={SUBJECT.CARRIER_REQUEST} className={styles.subHeading} />
        <Divider />
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="companyName"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.COMPANY_NAME}
              getValues={getValues}
              onChange={(e: any) =>
                setValue('companyName', unicodeSpaceNumbersFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              required
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <Input
              name="phoneNumber"
              control={control}
              rules={{ required: true }}
              getValues={watch}
              placeholder={SUBJECT.PHONE_NUMBER}
              onChange={(e: any) =>
                setValue('phoneNumber', formatPhone(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              required
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <Input
              name="contactName"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CONTACT_NAME}
              getValues={getValues}
              onChange={(e: any) => {
                setValue('contactName', unicodeFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              required
              errors={errors}
            />
          </Col>

          <Col span={8}>
            <Input
              name="mc"
              type="number"
              control={control}
              placeholder={SUBJECT.MC}
              getValues={getValues}
              errors={errors}
              required
              rules={{ required: true }}
            />
          </Col>
          <Col span={8}>
            <Input
              name="dot"
              type="number"
              rules={{ required: true }}
              control={control}
              placeholder={SUBJECT.DOT}
              getValues={getValues}
              required
              errors={errors}
            />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default CarrierRequestCreateEdit;
