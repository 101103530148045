export enum EquipmentTypes {
  UNSPECIFIED = 0,
  'Van' = 1,
  'Reefer' = 2,
  'Flat bed' = 3,
  'Power only' = 4,
  'Box truck' = 5,
}

export enum TrailerDetailsTypes {
  UNSPECIFIED = 0,
  'E-truck' = 1,
  'High cube' = 2,
  'Plate trailer' = 3,
  'Hazmat' = 4,
}
export interface IFilter {
  [key: string]: string;
}
export interface ICoordinatorsParams {
  search: string;
  skip: number;
  limit: number;
  onlyCoordinator: boolean;
  filter: IFilter;
}
