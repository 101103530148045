export const MY_AUTHORITY_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
];

export const CUSTOMER_TYPE_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mcID', name: 'ID' },
];

export const AUTHORITY_KEYS = ['id', 'name', 'mc'];
export const CUSTOMER_KEYS = ['id', 'name', 'mcID'];
