import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { IGetFactoringCompaniesParams } from './interfaces';
import {
  factoringDataSerializer,
  getFactoringCompaniesSerializer,
  getFactoringCompanyByIdSerializer,
} from './serializers';

export const factoringCompaniesAPI = createApi({
  reducerPath: 'factoringCompaniesAPI',
  baseQuery,
  tagTypes: ['FactoringCompanies', 'Autocomplete'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getFactoringCompanies: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<IGetFactoringCompaniesParams>) => ({
        url: 'factoring-companies',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getFactoringCompaniesSerializer(body),
      providesTags: ['FactoringCompanies'],
    }),
    getFactoringCompany: builder.query({
      query: id => `factoring-companies/${id}`,
      transformResponse: (body: any) => getFactoringCompanyByIdSerializer(body),
      providesTags: ['FactoringCompanies'],
    }),
    createFactoringCompany: builder.mutation({
      query: body => ({
        url: 'factoring-companies',
        method: 'POST',
        body: factoringDataSerializer(body),
      }),
      invalidatesTags: ['FactoringCompanies'],
    }),
    updateFactoringCompany: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `factoring-companies/${id}`,
        method: 'PUT',
        body: factoringDataSerializer(body),
      }),
    }),
    addFileToFactoringCompany: builder.mutation({
      query: ({ id, fileId, type }) => ({
        url: `factoring-companies/${id}/file/${fileId}`,
        method: 'POST',
        body: { type },
      }),
    }),
    deleteFileFromFactoringCompany: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `factoring-companies/${id}/file/${fileId}`,
        method: 'DELETE',
      }),
    }),
    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `factoring-companies/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['FactoringCompanies'],
    }),
    getFactoringCompaniesShort: builder.query({
      query: ({ search, skip, limit, order, orderBy, type, filter }: Partial<any>) => ({
        url: 'factoring-companies/autocomplete',
        params: { search, skip, limit, order, orderBy, type, ...filter },
      }),
      providesTags: ['Autocomplete'],
    }),
  }),
});

export const {
  useGetFactoringCompaniesQuery,
  useGetFactoringCompanyQuery,
  useCreateFactoringCompanyMutation,
  useUpdateFactoringCompanyMutation,
  useAddFileToFactoringCompanyMutation,
  useDeleteFileFromFactoringCompanyMutation,
  useChangeStatusMutation,
  useGetFactoringCompaniesShortQuery,
} = factoringCompaniesAPI;
