import React, { memo, useEffect, useRef, useState } from 'react';
import { Rate as AntRate } from 'antd';
import clsx from 'clsx';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import { useLazyGetCarrierRatingQuery } from 'services/profile/carriers/carriers';
import Typography from 'ui/typography/Typography';

import StarIcon from 'components/svgs/StarIcon';

import DropdownRate from '../DropdownRate/DropdownRate';

import styles from './RateTable.module.scss';

const RateCellComponent = ({ rowData }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useOutsideDetect<HTMLDivElement>(setIsOpen);
  const countRef = useRef<HTMLSpanElement>(null);

  const countPositionTop = (countRef?.current?.getBoundingClientRect().top || 0) + 20;
  const countPositionLeft = (countRef?.current?.getBoundingClientRect().left || 0) - 490;

  const [params, setParams] = useState<any>({
    skip: 0,
    limit: 10,
  });

  const [getCarrierRating, { data: rateData, isFetching }] = useLazyGetCarrierRatingQuery();

  const getCarrierRatings = () => {
    getCarrierRating({
      skip: params.skip,
      limit: params.limit,
      carrierId: Number(rowData?.carrierId ? rowData?.carrierId : rowData?.id),
    });
  };

  const handleDropdownOpen = (e: any) => {
    e.stopPropagation();
    setIsOpen(prev => !prev);
    getCarrierRatings();
  };

  const handleScroll = (e: any) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight && params.skip) {
      if (rateData.length < params.skip) {
        setParams((prev: any) => {
          return { ...prev, limit: prev.limit + 10 };
        });
      }
    }
  };

  const scrollHandler = (e: Event) => {
    const target = e.target as Element;
    if (!target?.classList?.contains('rate-dropdown')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler, true);
    return () => {
      document.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  return (
    <div
      onClick={(e: any) => e.stopPropagation()}
      className={clsx(styles.tableRateItem, {
        [styles.alreadyRated]: false,
        [styles.rateZero]: Number(rowData?.rate?.ratingCount) === 0,
      })}
      ref={ref}
    >
      <AntRate
        className={clsx(styles.rate, { [styles.canRate]: !!rowData?.rate?.accessRating })}
        character={<StarIcon />}
        disabled
        value={rowData?.rate?.rating}
      />
      <Typography variant="paragraph">({rowData?.rate?.ratingCount})</Typography>
      <div
        className={styles.rateDropdownWrapper}
        onClick={
          rowData?.rate?.ratingCount > 0
            ? e => {
                e.stopPropagation();
                handleDropdownOpen(e);
              }
            : undefined
        }
      >
        <span
          className={clsx(styles.rateShower, 'rate-shower', {
            [styles.rateShowerNone]: !rowData?.rate?.ratingCount,
          })}
          ref={countRef}
        >
          {rowData?.rate?.ratingCount}
        </span>
        <DropdownRate
          isFetching={isFetching}
          rateList={rateData}
          isOpen={isOpen}
          countPositionTop={countPositionTop}
          countPositionLeft={countPositionLeft}
          onScroll={handleScroll}
        />
      </div>
    </div>
  );
};

export default memo(RateCellComponent);
