import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';

import { IReceivablesModalContentFormData } from '../../receivables-view/constants/types';

export const Select = withController<ISelectProps, IReceivablesModalContentFormData>(ReusableSelect);
export const Input = withController<IInputProps, IReceivablesModalContentFormData>(CustomInput);
