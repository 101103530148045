import { IRoleLevel } from 'services/truck-board/carrier-request/interfaces';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  AGENCY_REQUIRED,
  BRANCH_REQUIRED,
  AGENT_REQUIRED,
  TYPE_REQUIRED,
  AMOUNT_REQUIRED,
  ONLY_NOT_ZERO,
  ONLY_NEGATIVE,
} = VALIDATION_MESSAGES;

export const chargeValidation = (roleLevel?: number) => {
  return yup.object().shape({
    agency: yup.array().test('isRequired', AGENCY_REQUIRED, function (value) {
      if (roleLevel === IRoleLevel.corpLevel) {
        return !!value?.length;
      }
      return true;
    }),
    branch: yup.array().test('isRequired', BRANCH_REQUIRED, function (value) {
      if (roleLevel === IRoleLevel.officeLevel) {
        return !!value?.length;
      }
      return true;
    }),
    agent: yup.array().test('isRequired', AGENT_REQUIRED, function (value) {
      if (roleLevel === IRoleLevel.branchLevel) {
        return !!value?.length;
      }
      return true;
    }),
    type: yup.string().required(TYPE_REQUIRED),
    amount: yup.string().test('isValid', AMOUNT_REQUIRED, function (value) {
      if (
        String(value).replace('.', '')! == '0' ||
        String(value).replace('.', '')! == '-0' ||
        String(value).replace('.', '')! == '-00' ||
        String(value).replace('.', '')! == '-000' ||
        String(value).replace('.', '')! == '-'
      ) {
        return this.createError({
          message: ONLY_NEGATIVE,
          path: 'amount',
        });
      }
      return !(value === '-' || !value?.length);
    }),
  });
};

export const advanceValidation = (roleLevel?: number) => {
  return yup.object().shape({
    agency: yup.array().test('isRequired', AGENCY_REQUIRED, function (value) {
      if (roleLevel === IRoleLevel.corpLevel) {
        return !!value?.length;
      }
      return true;
    }),
    branch: yup.array().test('isRequired', BRANCH_REQUIRED, function (value) {
      if (roleLevel === IRoleLevel.officeLevel) {
        return !!value?.length;
      }
      return true;
    }),
    agent: yup.array().test('isRequired', AGENT_REQUIRED, function (value) {
      if (roleLevel === IRoleLevel.branchLevel) {
        return !!value?.length;
      }
      return true;
    }),
    type: yup.string().required(TYPE_REQUIRED),
    amount: yup.string().test('isValid', AMOUNT_REQUIRED, function (value) {
      const checkValue = String(value)?.replace('-', '').replace('.', '');
      if (checkValue! == '0' || checkValue! == '00' || checkValue! == '000') {
        return this.createError({
          message: ONLY_NOT_ZERO,
          path: 'amount',
        });
      }
      return !(value === '-' || !value?.length);
    }),
  });
};

export const collectionValidation = yup.object().shape({
  paymentPlan: yup.string().required('Payment Plan is a required field'),
});
