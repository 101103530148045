import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'services/service';
import { logOut } from 'store/user-slice/user';
import { ls } from 'utils/storage';

import { IAuthParams, IAuthResult, IChangePasswordParams, ICreatePasswordParams } from './interface';

export const authAPI = createApi({
  reducerPath: 'authApi',
  baseQuery,
  tagTypes: ['Auth', 'GetMe'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    login: builder.mutation({
      query: (credentials: IAuthParams) => ({
        url: '/auth/sign-in',
        method: 'POST',
        body: { ...credentials },
      }),
      transformResponse: ({ result }: IAuthResult) => {
        const token = result.access;
        const refresh = result.refresh;

        if (token) {
          ls.set('token', token);
          ls.set('refresh', refresh);
        }
        return result;
      },
    }),
    changePassword: builder.mutation({
      query: (body: IChangePasswordParams) => ({
        url: '/auth/reset',
        method: 'POST',
        body: { resetToken: body.resetToken, password: body.newPassword },
      }),
    }),
    createPassword: builder.mutation({
      query: (body: ICreatePasswordParams) => ({
        url: '/auth/verify',
        method: 'PUT',
        body: { hash: body.hash, password: body.newPassword },
      }),
    }),
    resetPassword: builder.mutation({
      query: (email: string) => ({
        url: '/auth/recover',
        method: 'POST',
        body: { email },
      }),
    }),
    getMe: builder.query({
      query: () => ({
        url: '/users/me',
        method: 'get',
      }),
      transformErrorResponse: (data: any, api: any) => {
        if (data?.status === 404) {
          ls.remove('token');
          ls.remove('refresh');
          api.dispatch(logOut());
        }
      },
      providesTags: ['GetMe'],
    }),
    setProjectColorMode: builder.mutation({
      query: (type: number) => ({
        url: '/users/edit-mode',
        method: 'PATCH',
        body: { type },
      }),
      invalidatesTags: ['GetMe'],
    }),
    carrierSignUp: builder.mutation({
      query: (email: string) => ({
        url: 'auth/carrier/sign-up',
        method: 'POST',
        body: { email },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useChangePasswordMutation,
  useCreatePasswordMutation,
  useResetPasswordMutation,
  useGetMeQuery,
  useSetProjectColorModeMutation,
  useCarrierSignUpMutation,
} = authAPI;
