import { useDeferredValue } from 'react';
import { Col, Popover, Row } from 'antd';
import Button from 'ui/button/Button';
import ExcelButton from 'ui/excel-button/ExcelButton';
import Typography from 'ui/typography/Typography';

import { BlueInfoIcon } from 'components/svgs/BlueInfoIcon';
import { ColumnsIcon } from 'components/svgs/ColumnsIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import Table from 'components/table/Table';

import { equipmentTypeOptions, SUBJECT, trailerDetailsOptions } from './constants/constants';
import { FilterSelect } from './hoc-instance/instance';
import useTruckBoardSingle from './useTruckBoardSingle';

import styles from './TruckBoardSingle.module.scss';

const TruckBoardSingle = ({
  setExcelParams,
  searchInfoData,
  searchResultsData,
  searchResultsColumns,
  isSearchResultsLoading,
  searchResultsFilter,
  handleStopResizeSearchResult,
  setSearchResultsFilter,
  searchResultsTableRef,
  hasDownloadPermission,
}: any) => {
  const {
    handleRowClick,
    handleSortClick,
    handleColumnSearch,
    handlePaginationClick,
    filterControl,
    filterWatch,
    handleResetFilter,
    handleBadgeClick,
    handleCloseSearchResult,
    handleCustomizeSearchResultTable,
    downloadExcel,
  } = useTruckBoardSingle({ setExcelParams, searchResultsFilter, setSearchResultsFilter });

  const mainTableProps = useDeferredValue({
    data: searchResultsData,
    columns: searchResultsColumns,
    skip: searchResultsFilter?.skip,
    limit: searchResultsFilter?.limit,
    isLoading: isSearchResultsLoading,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResizeSearchResult,
    onColumnSearch: handleColumnSearch,
    onPaginationClick: handlePaginationClick,
    withMenu: false,
    onBadgeClick: handleBadgeClick,
    tableRef: searchResultsTableRef,
  });
  return (
    <>
      <div className={styles.bottomTable}>
        <div className={styles.customersMainSelect}>
          <Row align="bottom" gutter={[15, 0]} justify="space-between">
            <Col span={15}>
              <Row align="middle" gutter={[15, 0]}>
                <Col lg={6}>
                  <FilterSelect
                    withAll
                    withCheckbox
                    isMultiSelect
                    options={equipmentTypeOptions}
                    control={filterControl}
                    label={SUBJECT.EQUIPMENT_TYPE}
                    placeholder={SUBJECT.NOT_SELECTED}
                    name="equipmentTypeSingle"
                    isFilter
                  />
                </Col>
                <Col lg={6}>
                  <FilterSelect
                    withAll
                    withCheckbox
                    isMultiSelect
                    options={trailerDetailsOptions}
                    control={filterControl}
                    label={SUBJECT.TRAILER_DETAILS}
                    placeholder={SUBJECT.NOT_SELECTED}
                    name="trailerDetailsSingle"
                    isFilter
                  />
                </Col>
                {searchInfoData && (
                  <div className={styles.searchInfo}>
                    <Row align="middle" gutter={8}>
                      <Col>
                        <Popover
                          overlayClassName={styles.popoverOverlay}
                          placement="bottomLeft"
                          overlayStyle={{
                            width: 'fit-content',
                            maxWidth: '700px',
                            minWidth: '200px',
                          }}
                          content={
                            <>
                              <Row gutter={[0, 18]} align="middle">
                                {!!searchInfoData?.createdAt && (
                                  <>
                                    <Col span={6}>
                                      <Typography
                                        variant="heading"
                                        children="Available Date"
                                        className={styles.infoHeading}
                                      />
                                    </Col>
                                    <Col span={18}>
                                      <Typography
                                        variant="paragraph"
                                        children={searchInfoData?.createdAt}
                                        className={styles.infoParagraph}
                                      />
                                    </Col>
                                  </>
                                )}
                                {!!searchInfoData?.fromStates?.length && (
                                  <>
                                    <Col span={6}>
                                      <Typography variant="heading" children="From" className={styles.infoHeading} />
                                    </Col>
                                    <Col span={18}>
                                      <Typography
                                        variant="paragraph"
                                        children={searchInfoData?.fromStates?.map(
                                          ({ name }: { name: string }, index: number) =>
                                            `${name}${index === searchInfoData?.fromStates?.length - 1 ? '' : ', '}`
                                        )}
                                        className={styles.infoParagraph}
                                      />
                                    </Col>
                                  </>
                                )}
                                {!!searchInfoData?.toStates?.length && (
                                  <>
                                    <Col span={6}>
                                      <Typography variant="heading" children="To" className={styles.infoHeading} />
                                    </Col>
                                    <Col span={18}>
                                      <Typography
                                        variant="paragraph"
                                        children={searchInfoData?.toStates?.map(
                                          ({ name }: { name: string }, index: number) =>
                                            `${name}${index === searchInfoData?.toStates?.length - 1 ? '' : ', '}`
                                        )}
                                        className={styles.infoParagraph}
                                      />
                                    </Col>
                                  </>
                                )}
                                {!!searchInfoData?.truckCount && (
                                  <>
                                    <Col span={6}>
                                      <Typography variant="heading" children="Trucks" className={styles.infoHeading} />
                                    </Col>
                                    <Col span={18}>
                                      <Typography
                                        variant="paragraph"
                                        children={searchInfoData?.truckCount}
                                        className={styles.infoParagraph}
                                      />
                                    </Col>
                                  </>
                                )}
                                {!!searchInfoData?.equipmentType?.length && (
                                  <>
                                    <Col span={6}>
                                      <Typography
                                        variant="heading"
                                        children="Equipment Type"
                                        className={styles.infoHeading}
                                      />
                                    </Col>
                                    <Col span={18}>
                                      <Typography
                                        variant="paragraph"
                                        children={searchInfoData?.equipmentType?.map(
                                          ({ name }: { name: string }, index: number) =>
                                            `${name}${index === searchInfoData?.equipmentType?.length - 1 ? '' : ', '}`
                                        )}
                                        className={styles.infoParagraph}
                                      />
                                    </Col>
                                  </>
                                )}
                                {!!searchInfoData?.trailerDetails?.length && (
                                  <>
                                    <Col span={6}>
                                      <Typography
                                        variant="heading"
                                        children="Trailer Details"
                                        className={styles.infoHeading}
                                      />
                                    </Col>
                                    <Col span={18}>
                                      <Typography
                                        variant="paragraph"
                                        children={searchInfoData?.trailerDetails?.map(
                                          ({ name }: { name: string }, index: number) =>
                                            `${name}${index === searchInfoData?.trailerDetails?.length - 1 ? '' : ', '}`
                                        )}
                                        className={styles.infoParagraph}
                                      />
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </>
                          }
                        >
                          <div>
                            <BlueInfoIcon />
                          </div>
                        </Popover>
                      </Col>
                    </Row>
                  </div>
                )}
                {(!!filterWatch('equipmentTypeSingle')?.length || !!filterWatch('trailerDetailsSingle')?.length) && (
                  <Col>
                    <div className={styles.reset}>
                      <Button
                        icon={<ResetIcon />}
                        variant="ghost"
                        onClick={handleResetFilter}
                        children={SUBJECT.RESET_ALL}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <Row align="middle" gutter={8}>
                <Col>
                  <Button variant="ghost" children="Close Search" onClick={handleCloseSearchResult} />
                </Col>
                <Col>
                  <Button
                    icon={<ColumnsIcon />}
                    size="small"
                    variant="customize"
                    children="Customize Columns"
                    onClick={handleCustomizeSearchResultTable}
                  />
                </Col>
                {hasDownloadPermission && (
                  <Col>
                    <ExcelButton onClick={downloadExcel} isButtonVisible={true} />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
        {!!searchResultsData?.count && <h3 className={styles.fountdCount}>{searchResultsData?.count} results found</h3>}
        <div className={styles.tableWrapper}>
          {' '}
          <Table {...mainTableProps} />
        </div>
      </div>
    </>
  );
};
export default TruckBoardSingle;
