import { ISummariesCards } from 'services/payroll/information/types';

import { cardsNames, FACTORED_CARDS, NOT_FACTORED_CARDS } from './constants';
import { CollectionCards, DistributeCards, FactoredCards, NotFactoredCards, SUMMARIES_FACTORED_CARDS } from './types';

export const getInformationOrderBy = (card: string) => {
  if (
    FACTORED_CARDS.includes(card as FactoredCards) ||
    DistributeCards.CHARGES === card ||
    DistributeCards.CREDITS === card
  )
    return 'acceptBillingTimeAt';
  if (NOT_FACTORED_CARDS.includes(card as NotFactoredCards)) return 'addedLoadAt';
  if (CollectionCards.COLLECTION === card) return 'createdFor';
  return 'createdAt';
};

export const payrollCards = (cards: ISummariesCards, selectedCardData: { [key: string]: any } = {}, card?: number) => {
  if (cards) {
    const filteredSelectedCardData = {
      ...cards,
      ...(SUMMARIES_FACTORED_CARDS[card as number]
        ? {
            [SUMMARIES_FACTORED_CARDS[card as number]]: selectedCardData[SUMMARIES_FACTORED_CARDS[card as number]],
          }
        : {}),
    };
    const { finalPay: _, ...rest } = { ...cards, ...filteredSelectedCardData };

    return Object.entries(rest).map(([name, price], index) => {
      return {
        price,
        name: cardsNames[name as keyof typeof cardsNames],
        id: (index + 1).toString(),
        type: name,
      };
    });
  }
};
