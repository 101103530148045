export interface IFilter {
  [key: string]: string;
}

export interface IGetAuthoritiesParams {
  carrierId?: any;
  id?: string | undefined;
  ignoreBranchId?: string | undefined;
  ignoreAgentId?: string | undefined;
  search: string;
  type?: number;
  field: string;
  skip: number;
  limit: number;
  order: number;
  orderBy: string;
  filter?: IFilter;
  branchId?: string | number;
  officeId?: string | number;
  level?: number;
  ignoreAuthorityIds?: number[];
  ignoreCustomerId?: number | null;
  onlyAssigned?: boolean;
  excludeOwners?: boolean;
  withDefaults?: boolean;
}

export enum AuthorityTypes {
  'Carrier' = 1,
  'Broker' = 2,
}
