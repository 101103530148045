import React from 'react';

const ChargesIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9659 33.0925H26.7153V31.174H28.9147C29.3729 31.174 29.7472 31.0034 30.0374 30.6623C30.3276 30.3213 30.4727 29.9376 30.4727 29.5112V24.6192C30.4727 24.1572 30.3276 23.7628 30.0374 23.4359C29.7472 23.1091 29.3729 22.9456 28.9147 22.9456H22.6829V20.0039H30.4727V17.4459H26.7153V15.5273H23.9659V17.4459H21.7207C21.2624 17.4459 20.85 17.6164 20.4835 17.9575C20.1169 18.2985 19.9336 18.6981 19.9336 19.1562V24.0065C19.9336 24.4646 20.1169 24.8286 20.4835 25.0986C20.85 25.3686 21.2624 25.5037 21.7207 25.5037H27.7233V28.6159H19.9336V31.174H23.9659V33.0925Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2031 38.3607C32.9639 38.3607 39.2553 32.0694 39.2553 24.3086C39.2553 16.5479 32.9639 10.2565 25.2031 10.2565C17.4424 10.2565 11.151 16.5479 11.151 24.3086C11.151 32.0694 17.4424 38.3607 25.2031 38.3607ZM25.2031 40.1172C33.934 40.1172 41.0118 33.0395 41.0118 24.3086C41.0118 15.5778 33.934 8.5 25.2031 8.5C16.4723 8.5 9.39453 15.5778 9.39453 24.3086C9.39453 33.0395 16.4723 40.1172 25.2031 40.1172Z"
        fill="var(--black)"
      />
      <mask id="path-3-inside-1_502_18445" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6843 27.7227C32.5186 27.7227 32.3843 27.857 32.3843 28.0227V33.1706L28.4725 33.1706C28.2154 33.1706 28.0774 33.4727 28.2457 33.667L34.6623 41.0763C34.782 41.2145 34.9963 41.2145 35.1159 41.0763L41.5326 33.667C41.7008 33.4727 41.5628 33.1706 41.3058 33.1706L37.8294 33.1706V28.0227C37.8294 27.857 37.695 27.7227 37.5294 27.7227H32.6843Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6843 27.7227C32.5186 27.7227 32.3843 27.857 32.3843 28.0227V33.1706L28.4725 33.1706C28.2154 33.1706 28.0774 33.4727 28.2457 33.667L34.6623 41.0763C34.782 41.2145 34.9963 41.2145 35.1159 41.0763L41.5326 33.667C41.7008 33.4727 41.5628 33.1706 41.3058 33.1706L37.8294 33.1706V28.0227C37.8294 27.857 37.695 27.7227 37.5294 27.7227H32.6843Z"
        fill="var(--black)"
      />
      <path
        d="M32.3843 33.1706V33.6706H32.8843V33.1706H32.3843ZM28.4725 33.1706L28.4725 32.6706H28.4725V33.1706ZM28.2457 33.667L28.6236 33.3397H28.6236L28.2457 33.667ZM34.6623 41.0763L34.2844 41.4036L34.2844 41.4036L34.6623 41.0763ZM35.1159 41.0763L35.4939 41.4036L35.1159 41.0763ZM41.5326 33.667L41.9105 33.9943H41.9105L41.5326 33.667ZM41.3058 33.1706V33.6706V33.1706ZM37.8294 33.1706H37.3294V33.6706H37.8294V33.1706ZM32.8843 28.0227C32.8843 28.1331 32.7948 28.2227 32.6843 28.2227V27.2227C32.2425 27.2227 31.8843 27.5808 31.8843 28.0227H32.8843ZM32.8843 33.1706V28.0227H31.8843V33.1706H32.8843ZM28.4725 33.6706L32.3843 33.6706V32.6706L28.4725 32.6706L28.4725 33.6706ZM28.6236 33.3397C28.7358 33.4692 28.6438 33.6706 28.4725 33.6706V32.6706C27.7871 32.6706 27.419 33.4762 27.8677 33.9943L28.6236 33.3397ZM35.0403 40.749L28.6236 33.3397L27.8677 33.9943L34.2844 41.4036L35.0403 40.749ZM34.7379 40.749C34.8177 40.6569 34.9605 40.6569 35.0403 40.749L34.2844 41.4036C34.6034 41.772 35.1748 41.772 35.4939 41.4036L34.7379 40.749ZM41.1546 33.3397L34.7379 40.749L35.4939 41.4036L41.9105 33.9943L41.1546 33.3397ZM41.3058 33.6706C41.1344 33.6706 41.0424 33.4692 41.1546 33.3397L41.9105 33.9943C42.3592 33.4762 41.9912 32.6706 41.3058 32.6706V33.6706ZM37.8294 33.6706L41.3058 33.6706V32.6706L37.8294 32.6706V33.6706ZM37.3294 28.0227V33.1706H38.3294V28.0227H37.3294ZM37.5294 28.2227C37.4189 28.2227 37.3294 28.1331 37.3294 28.0227H38.3294C38.3294 27.5808 37.9712 27.2227 37.5294 27.2227V28.2227ZM32.6843 28.2227H37.5294V27.2227H32.6843V28.2227Z"
        fill="#F0F2F5"
        mask="url(#path-3-inside-1_502_18445)"
      />
    </svg>
  );
};

export default ChargesIcon;
