import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import Typography from 'ui/typography/Typography';
import { getMonth, getYear } from 'utils/dates';

import ChevronIcon from 'components/svgs/ChevronIcon';

import { MONTHS } from '../../constants/constants';
import { ICustomHeaderRangePickerProps, TChangeYearMonth } from '../../constants/types';

import styles from '../double-range-picker/DoubleRangePicker.module.scss';

const CustomHeaderRangePicker = ({
  date,
  monthDate,
  changeYear,
  decreaseMonth,
  increaseMonth,
  changeMonth,
  customHeaderCount,
  openDropDown,
  setOpenDropDown,
  start,
}: ICustomHeaderRangePickerProps) => {
  const currentMonth = getMonth(date);
  const currentMonthAdvanced = getMonth(monthDate);

  const currentYearFirst = getYear(monthDate);
  const currentYearSecond = getYear(monthDate);

  const currentMonthFirst = MONTHS[currentMonth];
  const currentMonthSecond = MONTHS[currentMonthAdvanced] || MONTHS[0];

  const setterCallback = () => setOpenDropDown('');

  const monthRef = useOutsideDetect<HTMLLIElement>(setterCallback);
  const monthRef2 = useOutsideDetect<HTMLLIElement>(setterCallback);
  const yearRef = useOutsideDetect<HTMLLIElement>(setterCallback);
  const yearRefSecond = useOutsideDetect<HTMLLIElement>(setterCallback);

  const determineYearRange = () => {
    const currentYear = String(new Date().getFullYear());
    const lastNumberAsString = Number(currentYear.charAt(currentYear.length - 1));

    return new Date().getFullYear() + 10 + (10 - lastNumberAsString);
  };

  const handleChangeYear = (year: number, changeYear: TChangeYearMonth) => {
    changeYear(year);
  };

  const handleChangeMonth = (month: number, changeMonth: TChangeYearMonth) => {
    changeMonth(month);
  };

  const range = (start: number, end: number | string) => {
    const length = Number(end) - start + 1;
    const years = new Array(length);
    for (let i = 0; i < length; i++) years[i] = start + i;
    return years.reverse();
  };

  const handleOpenDropDown = (picker: string) => {
    if (openDropDown) {
      setOpenDropDown('');
    } else {
      setOpenDropDown(picker);
    }
  };

  return customHeaderCount === 0 ? (
    <div className={styles.datepickerHeader}>
      <div className={styles.headerContent}>
        <div className={styles.montsWrapper}>
          <button type="button" onClick={decreaseMonth}>
            <ChevronIcon />
          </button>

          <ul className={styles.selectWrapper}>
            <li ref={monthRef}>
              <Typography variant="paragraph" onClick={() => handleOpenDropDown('month')}>
                {start ? currentMonthSecond : currentMonthFirst}
              </Typography>
              <ul
                className={clsx({
                  [styles.openedDropDown]: openDropDown === 'month',
                })}
              >
                {MONTHS?.map((month, index) => {
                  return (
                    <li key={month} onClick={() => handleChangeMonth(index, changeMonth)}>
                      {month}
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
          <button type="button" onClick={increaseMonth}>
            <ChevronIcon />
          </button>
        </div>
        <div className={styles.yearsWrapper}>
          <button
            type="button"
            onClick={() => handleChangeYear(dayjs(date).year() - 1, changeYear)}
            disabled={currentYearFirst <= 1972}
          >
            <ChevronIcon />
          </button>
          <ul className={styles.selectWrapper}>
            <li ref={yearRef}>
              <Typography variant="paragraph" onClick={() => handleOpenDropDown('year')}>
                {currentYearFirst}
              </Typography>
              <ul
                className={clsx({
                  [styles.openedDropDown]: openDropDown === 'year',
                })}
              >
                {range(1972, determineYearRange())?.map(year => {
                  return (
                    <li key={year} onClick={() => handleChangeYear(year, changeYear)}>
                      {year}
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
          <button type="button" onClick={() => handleChangeYear(dayjs(date).year() + 1, changeYear)}>
            <ChevronIcon />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.datepickerHeader}>
      <div className={styles.headerContent}>
        <div className={styles.montsWrapper}>
          <button type="button" onClick={decreaseMonth}>
            <ChevronIcon />
          </button>

          <ul className={styles.selectWrapper}>
            <li ref={monthRef2}>
              <Typography variant="paragraph" onClick={() => handleOpenDropDown('month2')}>
                {currentMonthSecond}
              </Typography>
              <ul
                className={clsx({
                  [styles.openedDropDown]: openDropDown === 'month2',
                })}
              >
                {MONTHS?.map((month, index) => {
                  return (
                    <li key={month} onClick={() => handleChangeMonth(index - 1, changeMonth)}>
                      {month}
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
          <button type="button" onClick={increaseMonth}>
            <ChevronIcon />
          </button>
        </div>
        <div className={styles.yearsWrapper}>
          <button
            type="button"
            onClick={() => handleChangeYear(dayjs(date).year() - 1, changeYear)}
            disabled={currentYearSecond <= 1972}
          >
            <ChevronIcon />
          </button>
          <ul className={styles.selectWrapper}>
            <li ref={yearRefSecond}>
              <Typography variant="paragraph" onClick={() => handleOpenDropDown('year2')}>
                {currentYearSecond}
              </Typography>
              <ul
                className={clsx({
                  [styles.openedDropDown]: openDropDown === 'year2',
                })}
              >
                {range(1972, determineYearRange())?.map(year => {
                  return (
                    <li key={year} onClick={() => handleChangeYear(year, changeYear)}>
                      {year}
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
          <button type="button" onClick={() => handleChangeYear(dayjs(date).year() + 1, changeYear)}>
            <ChevronIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomHeaderRangePicker;
