import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISocketConnectionStatuses, socketUrl, SUBJECT } from 'api/notifications/constants';
import { billingAPI } from 'services/factoring/billing/billing';
import { payablesAPI } from 'services/factoring/payables/payables';
import { receivablesAPI } from 'services/factoring/receivables/receivables';
import { loadsAPI } from 'services/loads/loads';
import { truckBoardApi } from 'services/truck-board/truck-board/truckBoard';
import { verificationAPI } from 'services/truck-board/verification-board/verification';
import { userAPI } from 'services/user/user';
import { setUpdateLoads } from 'store/loads-slice/loadSlice';
import { setOfflineAgent, setOnlineAgent } from 'store/online-agents-slice/onlineAgents';
import { selectAuth } from 'store/user-slice/selector';

import { ISocketMessage } from './notifications/interfaces';
import { handler } from './notifications/notificationHandler';
import {
  creditCheckUpdateCases,
  factoringBillingUpdateCases,
  factoringPayablesUpdateCases,
  factoringReceivablesUpdateCases,
  loadTableUpdateCases,
  newCreditUpdateCases,
  RefreshPages,
  truckUpdateCases,
} from './refetchHandler/constants';

const useWebSocket = () => {
  const [connectionStatus, setConnectionStatus] = useState<ISocketConnectionStatuses>(SUBJECT.STATUS_CONNECTING);
  const ws = useRef<WebSocket | null>(null);
  const dispatch = useDispatch();

  const token = useSelector(selectAuth);

  const [messageType, setMessageType] = useState<any>({});

  useEffect(() => {
    if (token) {
      const newWs = new WebSocket(socketUrl);

      const connectWebSocket = () => {
        newWs.onopen = () => {
          setConnectionStatus(SUBJECT.STATUS_OPEN);
        };
        newWs.onclose = () => {
          setConnectionStatus(SUBJECT.STATUS_CLOSED);
        };
        newWs.onerror = () => {
          setConnectionStatus(SUBJECT.STATUS_CLOSED);
        };
        newWs.onmessage = (event: MessageEvent<string>) => {
          const data: ISocketMessage = JSON.parse(event.data);
          handler.handle(data);

          setMessageType({ id: data?.id, type: data?.type });

          if (loadTableUpdateCases.includes(data?.payload?.eventType)) {
            dispatch(loadsAPI?.util?.invalidateTags(['Loads']));
          }
          if (factoringBillingUpdateCases.includes(data?.payload?.eventType)) {
            dispatch(billingAPI?.util?.invalidateTags(['Billing', 'Loads']));
          }
          if (factoringPayablesUpdateCases.includes(data?.payload?.eventType)) {
            dispatch(payablesAPI?.util?.invalidateTags(['Payables', 'Loads']));
          }
          if (factoringReceivablesUpdateCases.includes(data?.payload?.eventType)) {
            dispatch(receivablesAPI?.util?.invalidateTags(['Loads', 'Receivables']));
          }
          if (data?.type === 'agents.activity') {
            if (data?.payload?.type === 'online') {
              dispatch(setOnlineAgent(data?.payload?.userId));
            }
            if (data?.payload?.type === 'offline') {
              dispatch(setOfflineAgent(data?.payload?.userId));
            }
          }
          if (newCreditUpdateCases.includes(data?.payload?.eventType)) {
            dispatch(setUpdateLoads(Math.random()));
          }
          if (creditCheckUpdateCases.includes(data?.payload?.eventType)) {
            dispatch(loadsAPI?.util?.invalidateTags(['newLoadForAgent']));
          }

          // refresh pages
          if (
            window.location.search.includes('billing') &&
            (data?.payload?.eventType as any) === RefreshPages.BILLING
          ) {
            dispatch(billingAPI?.util?.invalidateTags(['Billing', 'Loads']));
          }
          if (
            window.location.search.includes('payables') &&
            (data?.payload?.eventType as any) === RefreshPages.PAYABLES
          ) {
            dispatch(payablesAPI?.util?.invalidateTags(['Payables', 'Loads']));
          }

          if (
            window.location.search.includes('receivables') &&
            (data?.payload?.eventType as any) === RefreshPages.RECEIVABLES
          ) {
            dispatch(receivablesAPI?.util?.invalidateTags(['Receivables', 'Loads']));
          }
          if (
            window.location.search.includes('new_credit') &&
            (data?.payload?.eventType as any) === RefreshPages.CREDIT_CHECK
          ) {
            dispatch(loadsAPI?.util?.invalidateTags(['newLoadForEmployee']));
          }
          if (window.location.search.includes('loads') && (data?.payload?.eventType as any) === RefreshPages.LOAD) {
            dispatch(loadsAPI?.util?.invalidateTags(['Loads']));
          }
          if (
            (window.location.search.includes('verification_board') &&
              (data?.payload?.eventType as any) === truckUpdateCases.hasALoad) ||
            (data?.payload?.eventType as any) === truckUpdateCases.truckCreate
          ) {
            dispatch(verificationAPI?.util?.invalidateTags(['VerificationList']));
          }
          if (
            window.location.search.includes('truck_board') &&
            (data?.payload?.eventType as any) === truckUpdateCases.addedSearch
          ) {
            dispatch(truckBoardApi?.util?.invalidateTags(['ResultList', 'TruckBoard']));
          }
        };
        ws.current = newWs;
      };

      connectWebSocket();
    }

    return () => {
      if (ws.current) {
        ws.current?.close();
      }
    };
  }, [socketUrl, token]);

  useEffect(() => {
    if (!token && connectionStatus !== SUBJECT.STATUS_CONNECTING) {
      setConnectionStatus(SUBJECT.STATUS_CONNECTING);
    }
  }, [token, connectionStatus]);

  useEffect(() => {
    const pingInterval = setInterval(() => {
      if (ws?.current && connectionStatus === SUBJECT.STATUS_OPEN && token) {
        ws.current?.send('ping');
      }
    }, 20000);
    return () => {
      clearInterval(pingInterval);
    };
  }, [ws?.current, connectionStatus, token]);

  const sendMessage = (message?: string) => {
    if (ws.current && connectionStatus === SUBJECT.STATUS_OPEN && message) {
      ws.current?.send(
        JSON.stringify({
          payload: {
            token: message,
          },
          type: 'auth.authentication',
        })
      );
    }
    if (connectionStatus === SUBJECT.STATUS_CLOSED) {
      setConnectionStatus(SUBJECT.STATUS_CONNECTING);
    }
  };

  const sendAgentsActivityWatchMessage = () => {
    if (ws.current && connectionStatus === SUBJECT.STATUS_OPEN) {
      ws.current?.send(
        JSON.stringify({
          type: 'agents.activity',
        })
      );
    }
  };

  useEffect(() => {
    const firstPingAgents = setTimeout(() => {
      if (window.location.search?.includes('tab=agents')) {
        sendAgentsActivityWatchMessage();
      }
    }, 2000);

    return () => {
      clearTimeout(firstPingAgents);
    };
  }, [window.location.search?.includes('tab=agents')]);

  useEffect(() => {
    if (messageType?.type === 'notifications.create' && !(window as any).global?.inDrawer) {
      dispatch(userAPI.util.invalidateTags(['Notifications']));
    }
  }, [messageType?.type, messageType?.id]);

  return {
    connectionStatus,
    sendMessage,
    isOpen: connectionStatus === SUBJECT.STATUS_OPEN,
    sendAgentsActivityWatchMessage,
  };
};

export default useWebSocket;
