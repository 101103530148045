import { withController } from 'ui/inputs/hoc/Controller';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { IPayablesDataGridFilter, IPayablesFilter } from '../constants/types';

export const FilterSelect = withController<ISelectProps, IPayablesFilter>(ReusableSelect);
export const DataGridFilterSelect = withController<ISelectProps, IPayablesDataGridFilter>(ReusableSelect);
export const SearchableDropdown = withController<ISearchableDropdownProps, any>(CustomSearchableDropdown);

export const SearchableSelectForFilter = withController<ISearchableSelectProps, IPayablesFilter>(
  CustomSearchableSelect
);
