import { Dispatch, SetStateAction } from 'react';
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { IGetOfficesParams } from 'services/profile/offices/interfaces';

export interface IOfficeFormData {
  name: string | number;
  officeLocation: string;
  phoneNumber: number | null;
  fax: number | null;
  payout: number | string;
  address: string;
  secondAddress: string;
  city: string;
  stateId: string[] | string;
  zipcode: number | null;
  authorities: (string | number)[];
  bankName: string;
  accounting_number: string;
  ach_routing_number: string;
}

export interface IOfficeFilter {
  officeFilter?: string | null;
}

export interface IOptions {
  title: string;
  value: string;
  key?: string;
}

export interface ISelectOptions {
  title: string | number;
  value?: string | number;
  key?: string;
  children?: IOptions[];
}

export interface ICreateEditOffice {
  getValues: UseFormGetValues<IOfficeFormData>;
  setValue: UseFormSetValue<IOfficeFormData>;
  authoritiesData: any;
  authorityByOfficeId: any;
}

export interface IOfficeFormProps extends ICreateEditOffice {
  handleSubmit: UseFormHandleSubmit<IOfficeFormData>;
  clearErrors?: any;
  onSubmit: SubmitHandler<IOfficeFormData>;
  control: Control<IOfficeFormData>;
  refetch: any;
  isAutocompleteFetching: boolean;
  watch: UseFormWatch<IOfficeFormData>;
  getAuthoritiesFilter?: any;
  setGetAuthoritiesFilter?: any;
  getAuthoritiesByOfficeFilter?: any;
  setGetAuthoritiesByOfficeFilter?: any;
  authorityOrder?: number;
  handleSortAuthorities?: () => void;
  errors?: FieldErrors;
  data?: any;
  statesData?: any;
  isFetchingById?: boolean;
  onAuthoritiesBlur?: () => void;
  trigger: any;
  isCreateLoading: boolean;
  isEditLoading: boolean;
}

export interface IOfficeTableData {
  id: number;
  date: string;
  officeName: string;
  officeId: string;
  phoneNumber: string;
  faxNumber: string;
  officeLocation: string;
  owner: { number: string; name: string }[];
  numberOfBranches: number;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  payoutPercentage: string;
  terminate: boolean;
  terminate_denied: boolean;
}

export interface IOfficeTableColumns {
  title: string;
  id: string;
  dataIndex: string;
  displayType: string;
  isVisible: boolean;
  sortable: boolean;
  width: number;
  isSearchable?: boolean;
}

export enum OfficeStatusTypes {
  OFFICE_STATUS_TYPES_UNSPECIFIED = 0,
  OFFICE_STATUS_TYPES_ACTIVE = 1,
  OFFICE_STATUS_TYPES_INACTIVE = 2,
}

export interface IOfficeParams {
  setExcelParams?: Dispatch<SetStateAction<IGetOfficesParams | undefined>>;
}
