import React, { ForwardedRef, forwardRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Upload as AntUpload, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';
import { isFileAllowedToBeUpload } from 'utils/helpers';

import { SUBJECT } from './constants/constants';
import { IUploadProps } from './constants/types';

import styles from './Upload.module.scss';

const { Dragger } = AntUpload;

const Upload = forwardRef(
  (
    {
      uploadText,
      className,
      accept,
      onChange,
      beforeUpload,
      isDisabled,
      multiple = true,
      uploadType = '1',
    }: IUploadProps & UploadProps,
    ref: ForwardedRef<UploadProps>
  ) => {
    const [loading, setLoading] = useState(false);
    const handleBeforeUpload = (file: RcFile) => {
      if (beforeUpload) {
        beforeUpload(file, setLoading, uploadType);
      }
    };
    const props: UploadProps = {
      name: 'file',
      multiple,
      accept: accept || 'image/png, image/jpeg, application/pdf, image/tiff',
      showUploadList: true,
      onChange:
        onChange ||
        (info => {
          if (info.file.status === 'done') {
            setLoading(false);
          } else if (info.file.status === 'error') {
            setLoading(false);
          }
        }),
      onDrop() {
        return null;
      },
      beforeUpload: file => {
        handleBeforeUpload(file);
      },
      customRequest: (info: any) => {
        setLoading(isFileAllowedToBeUpload(info?.file?.size || 0));
      },
    };
    if (isDisabled) {
      return (
        <Tooltip color="#fff" placement="top" title={SUBJECT.PROCESSING_RULE} zIndex={isDisabled ? 9999 : 0}>
          <div className={clsx(styles.uploaderItem, className)}>
            <Dragger {...props} ref={ref} disabled={isDisabled}>
              {loading ? (
                <LoadingOutlined />
              ) : (
                <Typography variant="paragraph" className={styles.uploadText}>
                  {uploadText} <span>{SUBJECT.BROWSE}</span>
                </Typography>
              )}
            </Dragger>
          </div>
        </Tooltip>
      );
    }
    return (
      <div className={clsx(styles.uploaderItem, className)}>
        <Dragger {...props} ref={ref} disabled={isDisabled}>
          {loading ? (
            <LoadingOutlined />
          ) : (
            <Typography variant="paragraph" className={styles.uploadText}>
              {uploadText} <span>{SUBJECT.BROWSE}</span>
            </Typography>
          )}
        </Dragger>
      </div>
    );
  }
);

export default Upload;
