export interface IAutocompleteParams {
  search?: string;
  skip: string;
  limit: string;
  ignoreAuthorityIds?: number[];
}

// TODO CARIER FILE TYPES WHEN SIGN UP

export enum CarrierFileTypes {
  'UNSPECIFIED' = 0,
  'CARRIER_PACKAGE' = 1,
  'W9' = 2,
  'VOIDED_CHECK' = 3,
  'COMPANY_ID' = 4,
  'LETTER_OF_RELEASE' = 5,
  'INSURANCE' = 6,
  'NO_FACTORING' = 7,
}

export enum EquipmentTypes {
  UNSPECIFIED = 0,
  FLAT_BED = 1,
  STEP_DECK = 2,
  LOW_BOY = 3,
  BOX_TRUCK = 4,
  SPRINTER_VAN = 5,
  HOT_SHOT = 6,
  CONESTOGA = 7,
  CURTAIN_VAN = 8,
  DRY_VAN = 9,
  REEFER = 10,
}

export enum ActiveTypes {
  UNSPECIFIED = 0,
  ACTIVE = 1,
  INACTIVE = 2,
}
