import { useEffect, useRef, useState } from 'react';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import { TEventHandler } from 'ui/rate/constants/types';

export const useDisplayingRange = () => {
  const [rateDropDown, setRateDropDown] = useState(false);
  const ref = useOutsideDetect<HTMLDivElement>(setRateDropDown);
  const countRef = useRef<HTMLSpanElement>(null);

  const countPositionTop = (countRef?.current?.getBoundingClientRect().top || 0) + 20;
  const countPositionLeft = (countRef?.current?.getBoundingClientRect().left || 0) - 490;

  const showDropDown = (e: TEventHandler) => {
    e.stopPropagation();
    setRateDropDown(prev => !prev);
  };
  const scrollHandler = (e: Event) => {
    const target = e.target as Element;
    if (!target?.classList?.contains('rate-dropdown')) {
      setRateDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('scroll', scrollHandler, true);
    return () => {
      document.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  return {
    showDropDown,
    ref,
    countRef,
    rateDropDown,
    countPositionTop,
    countPositionLeft,
  };
};
