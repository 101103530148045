import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import { IGetPayablesParams } from 'services/factoring/payables/interface';
import {
  useGetCarrierInfoMutation,
  useGetCarrierPayMutation,
  useGetCarrierQuery,
  useGetPayablesQuery,
  useReceiveInvoiceMutation,
} from 'services/factoring/payables/payables';
import { IGetLoadsParams } from 'services/loads/interface';
import { useGetLoadFilesByIdsMutation } from 'services/loads/loads';
import { useGetAgentQuery, useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { useGetAuthoritiesShortQuery, useGetAuthorityQuery } from 'services/profile/authorities/authorities';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import { useGetCarrierForFilterQuery, useGetCarriersAutocompleteQuery } from 'services/profile/carriers/carriers';
import { useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { multiplyDownloadWithDelay, multiplyViewWithDelay } from 'utils/helpers';
import { IChildren } from 'utils/types';

import { IColumn } from 'components/custom-column/constants/types';
import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import PayablesView from './components/payables-view/PayablesView';
import { filterDefaultValues, SUBJECT } from './constants/constants';
import { IPayablesFilter } from './constants/types';

const usePayables = (setExcelParams: any) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const {
    mode,
    tab,
    open,
    id,
    authorityId,
    mcPayablesFilter,
    carrierPayablesFilter,
    officePayablesFilter,
    branchPayablesFilter,
    agentPayablesFilter,
    paymentMethodFilter,
    payablesCarrierTypeFilter,
    paidOption,
    paidStartDate,
    paidEndDate,
    regOption,
    regStartDate,
    regEndDate,
    regAllDate,
    paidAllDate,
  } = searchParams;

  const [payablesList, setPayablesList] = useState<Partial<IGetPayablesParams>>({
    search: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'aging',
    filter: {},
    field: '',
  });

  const { data: columnsData } = useGetColumnsQuery({ type: 'payables' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [getLoadFilesByIds] = useGetLoadFilesByIdsMutation();

  const [getCarrierInfo, { data: carrierInfo, isLoading: isInfoLoading }] = useGetCarrierInfoMutation();
  const [getCarrierPay, { isSuccess: isCarrierPaySuccess }] = useGetCarrierPayMutation();
  const [receiveInvoice, { isSuccess: isReceiveInvoiceSuccess }] = useReceiveInvoiceMutation();

  const { currentData: carrierById, isFetching: isLoadingById } = useGetCarrierQuery(
    { id },
    {
      skip: !id,
    }
  );
  const payablesPagePermissions = usePermittedActions('factoring.payables');

  const {
    reset,
    control,
    watch,
    setValue,
    formState: { isDirty },
    resetField,
  } = useForm<IPayablesFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onBlur',
  });

  const { data, isFetching: isLoading } = useGetPayablesQuery({
    ...payablesList,
    filter: {
      branchIds: branchPayablesFilter?.length ? branchPayablesFilter?.split(',')?.map(el => Number(el)) : [],
      officeIds: officePayablesFilter?.length ? officePayablesFilter?.split(',')?.map(el => Number(el)) : [],
      ...(agentPayablesFilter ? { agentId: Number(agentPayablesFilter) } : {}),

      ...(carrierPayablesFilter ? { carrierId: carrierPayablesFilter } : {}),
      ...(payablesCarrierTypeFilter ? { carrierType: payablesCarrierTypeFilter } : {}),
      ...(mcPayablesFilter ? { authorityId: Number(mcPayablesFilter) } : {}),
      ...(watch('carrierPaymentMethod') && watch('carrierPaymentMethod') !== '0'
        ? {
            carrierPaymentTermType: Number(watch('carrierPaymentMethod')),
          }
        : {}),
      ...(!regAllDate && regOption !== '4' && regStartDate
        ? { createdAtFrom: Number(regStartDate), createdAtTo: Number(regEndDate) }
        : {}),
      ...(!paidAllDate && paidOption !== '4' && paidStartDate
        ? { carrierPaidAtFrom: Number(paidStartDate), carrierPaidAtTo: Number(paidEndDate) }
        : {}),
      isConvertingCreatedAtTimeNeeded: regOption === '5',
      isConvertingPaidAtTimeNeeded: paidOption === '5',
    },
  });

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...payablesList,
          branchIds: branchPayablesFilter?.length ? branchPayablesFilter?.split(',')?.map(el => Number(el)) : [],
          officeIds: officePayablesFilter?.length ? officePayablesFilter?.split(',')?.map(el => Number(el)) : [],
          ...(agentPayablesFilter ? { agentId: Number(agentPayablesFilter) } : {}),
          ...(payablesCarrierTypeFilter ? { carrierType: payablesCarrierTypeFilter } : {}),
          ...(carrierPayablesFilter ? { carrierId: carrierPayablesFilter } : {}),
          ...(mcPayablesFilter ? { authorityId: Number(mcPayablesFilter) } : {}),
          ...(watch('carrierPaymentMethod') && watch('carrierPaymentMethod') !== '0'
            ? {
                carrierPaymentTermType: Number(watch('carrierPaymentMethod')),
              }
            : {}),
          ...(!regAllDate && regOption !== '3' && regStartDate
            ? { createdAtFrom: Number(regStartDate), createdAtTo: Number(regEndDate) }
            : {}),
          ...(!paidAllDate && paidOption !== '3' && paidStartDate
            ? { carrierPaidAtFrom: Number(paidStartDate), carrierPaidAtTo: Number(paidEndDate) }
            : {}),
          isConvertingCreatedAtTimeNeeded: regOption === '5',
          isConvertingPaidAtTimeNeeded: paidOption === '5',
        };
      });
    }
  }, [
    payablesList,
    branchPayablesFilter?.length,
    regAllDate,
    regOption,
    paidAllDate,
    regStartDate,
    regEndDate,
    paidOption,
    paidStartDate,
    paidEndDate,
    officePayablesFilter?.length,
    agentPayablesFilter,
    carrierPayablesFilter,
    mcPayablesFilter,
    watch('carrierPaymentMethod'),
  ]);
  /////////////// AUTHORITY FILTER
  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });
  const { data: authorities } = useGetAuthoritiesShortQuery(authoritiesState, {
    skip: tab !== 'payables',
  });

  const { data: selectedMc } = useGetAuthorityQuery(mcPayablesFilter, { skip: !mcPayablesFilter });

  const [selectedMcOption, setSelectedMcOption] = useState<any>({});
  const [finalMcOptions, setFinalMcOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedMc?.result) {
      setSelectedMcOption([
        {
          name: selectedMc?.result?.name,
          id: selectedMc?.result?.id,
          mc: selectedMc?.result?.mc,
        },
      ]);
    }
  }, [selectedMc]);

  useEffect(() => {
    if (authorities && !selectedMcOption.id) {
      setFinalMcOptions(authorities);
    } else if (authorities) {
      setFinalMcOptions({
        result: [selectedMcOption, ...authorities.result.filter((el: any) => el.id !== selectedMcOption?.id)],
        count: authorities?.count,
      });
    }
  }, [selectedMcOption, authorities]);

  ////////////////// CARRIER FILTER
  const [carriersState, setCarriersState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });
  const { data: carriers } = useGetCarriersAutocompleteQuery(carriersState, {
    skip: tab !== 'payables',
  });

  const { data: selectedCarrier } = useGetCarrierForFilterQuery(
    { id: Number(carrierPayablesFilter) },
    {
      skip: !carrierPayablesFilter,
    }
  );

  useEffect(() => {
    setValue('authorityIds', [{ id: authorityId, value: authorityId }]);
  }, []);

  const [selectedCarrierOption, setSelectedCarrierOption] = useState<any>({});
  const [finalCarrierOptions, setFinalCarrierOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedCarrier) {
      setSelectedCarrierOption([
        {
          name: selectedCarrier?.companyName,
          value: selectedCarrier?.companyName,
          id: selectedCarrier?.id,
        },
      ]);
    }
  }, [selectedCarrier]);

  useEffect(() => {
    if (carriers && !selectedCarrierOption.id) {
      setFinalCarrierOptions(carriers);
    } else if (carriers) {
      setFinalCarrierOptions({
        result: [selectedCarrierOption, ...carriers.result.filter((el: any) => el.id !== selectedCarrierOption?.id)],
        count: carriers?.count,
      });
    }
  }, [selectedCarrierOption, carriers]);
  ///////////////

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    withDefaults: true,
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [selectedOffices, setSelectedOffices] = useState<any>([]);
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [finalBranchesOptions, setFinalBranchesOptions] = useState<any>({ result: [], count: 0 });

  const { data: officesOptions = { result: [], count: 0 } } = useGetOfficesAutocompleteQuery(officesFilter, {
    skip: tab !== 'payables',
  });
  const { data: branchesData = { result: [], count: 0 } } = useGetBranchesAutocompleteQuery(
    { officeId: selectedOffices?.[0]?.id, ...branchFilter },
    {
      skip: !watch('payablesOffice')?.length || watch('payablesOffice')!.length > 1,
    }
  );
  ///////////////////// AGENT FILTER
  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    excludeOwners: true,
  });

  const { data: agentsOptions } = useGetAgentsAutocompleteQuery(
    {
      ...agentsFilter,
      branchId: watch('payablesBranch')?.[0],
    },
    {
      skip: typeof watch('payablesBranch') === 'string' || watch('payablesBranch')?.length !== 1,
    }
  );

  const { data: selectedAgent } = useGetAgentQuery(agentPayablesFilter, { skip: !agentPayablesFilter });

  const [selectedAgentOption, setSelectedAgentOption] = useState<any>({});
  const [finalAgentOptions, setFinalAgentOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedAgent) {
      setSelectedAgentOption([
        {
          firstName: selectedAgent?.result?.firstName,
          lastName: selectedAgent?.result?.lastName,
          username: selectedAgent?.result?.username,
          id: selectedAgent?.result?.id,
          value: String(selectedAgent?.result?.id),
        },
      ]);
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (agentsOptions && !selectedAgentOption.id) {
      setFinalAgentOptions(agentsOptions);
    } else if (agentsOptions) {
      setFinalAgentOptions({
        result: [selectedAgentOption, ...agentsOptions.result.filter((el: any) => el.id !== selectedAgentOption?.id)],
        count: agentsOptions?.count,
      });
    }
  }, [selectedAgentOption, agentsOptions?.result?.length]);
  ////////////

  useEffect(() => {
    if (Array.isArray(watch('payablesBranch'))) {
      setSelectedBranches((prev: any) =>
        [
          ...prev.filter((el: any) => !branchesData?.result?.some((item: any) => item.value == el.value)),
          ...branchesData.result,
        ].filter((el: any) => watch('payablesBranch')?.some((item: any) => item == el.value))
      );
    }
  }, [watch('payablesBranch'), branchesData?.result?.length]);

  useEffect(() => {
    if (Array.isArray(watch('payablesOffice'))) {
      setSelectedOffices((prev: any) =>
        [
          ...prev.filter((el: any) => !officesOptions?.result?.some((item: any) => item.value == el.value)),
          ...officesOptions.result,
        ].filter((el: any) => watch('payablesOffice')?.some((item: any) => item == el.value))
      );
    }
  }, [watch('payablesOffice'), officesOptions?.result?.length]);

  useEffect(() => {
    if (officesOptions?.result) {
      setFinalOfficeOptions({
        result: [
          ...selectedOffices,
          ...officesOptions.result.filter((el: any) => !selectedOffices.some((item: any) => item.value == el.value)),
        ],
        count: officesOptions.count,
      });
    } else {
      setFinalOfficeOptions(selectedOffices);
    }
  }, [selectedOffices, officesOptions?.result]);

  useEffect(() => {
    if (branchesData?.result?.length) {
      setFinalBranchesOptions({
        result: [
          ...selectedBranches,
          ...branchesData.result.filter((el: any) => !selectedBranches.some((item: any) => item.value == el.value)),
        ],
        count: branchesData.count,
      });
    } else {
      setFinalBranchesOptions(selectedBranches);
    }
  }, [selectedBranches?.length, branchesData?.result?.length]);

  const payablesMc = watch('payablesMc')?.[0]?.id ? watch('payablesMc')?.[0]?.id : watch('payablesMc');
  const carrierPayables = watch('payablesCarrier')?.[0]?.id
    ? watch('payablesCarrier')?.[0]?.id
    : watch('payablesCarrier');

  const officePayables = watch('payablesOffice');
  const payablesCarrierType = watch('payablesCarrierType');
  const branchPayables = watch('payablesBranch');
  const agentPayables = watch('payablesAgent')?.[0]?.id ? watch('payablesAgent')?.[0]?.id : watch('payablesAgent');

  const paymentMethod = watch('carrierPaymentMethod');

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(payablesMc ? { mcPayablesFilter: payablesMc } : { mcPayablesFilter: '' }),
      ...(officePayables
        ? { officePayablesFilter: Array.isArray(officePayables) ? officePayables?.join(',') : '' }
        : { officePayablesFilter: '' }),
      ...(branchPayables
        ? { branchPayablesFilter: Array.isArray(branchPayables) ? branchPayables?.join(',') : '' }
        : { branchPayablesFilter: '' }),
      ...(carrierPayables ? { carrierPayablesFilter: carrierPayables } : { carrierPayablesFilter: '' }),
      ...(payablesCarrierType ? { payablesCarrierTypeFilter: payablesCarrierType } : { payablesCarrierTypeFilter: '' }),
      ...(agentPayables ? { agentPayablesFilter: agentPayables || '' } : { agentPayablesFilter: '' }),
      ...(paymentMethod ? { paymentMethodFilter: paymentMethod } : { paymentMethodFilter: '' }),
    });
  }, [payablesMc, carrierPayables, officePayables, branchPayables, agentPayables, paymentMethod, payablesCarrierType]);

  useEffect(() => {
    if (watch('payablesOffice')!.length !== 1) {
      setSearchParams({
        ...searchParams,
        branchPayablesFilter: '',
        agentPayablesFilter: '',
      });
      setValue('payablesBranch', []);
      setValue('payablesAgent', []);
      setSelectedAgentOption({});
    }
  }, [watch('payablesOffice')]);

  useEffect(() => {
    if (watch('payablesBranch')!.length !== 1) {
      setSearchParams({
        ...searchParams,
        agentPayablesFilter: '',
      });
      setValue('payablesAgent', []);
      setSelectedAgentOption({});
    }
  }, [watch('payablesBranch')]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  useEffect(() => {
    const filterOptions = [
      { key: 'payablesMc', value: mcPayablesFilter },
      { key: 'payablesCarrier', value: carrierPayablesFilter },
      { key: 'payablesCarrierType', value: payablesCarrierTypeFilter },

      { key: 'payablesOffice', value: officePayablesFilter ? officePayablesFilter.split(',') : officePayablesFilter },
      { key: 'payablesBranch', value: branchPayablesFilter ? branchPayablesFilter.split(',') : branchPayablesFilter },
      { key: 'payablesAgent', value: agentPayablesFilter },
      { key: 'carrierPaymentMethod', value: paymentMethodFilter },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setValue(key as keyof IPayablesFilter, value, { shouldDirty: true });
      }
    });
    setPayablesList((prev: any) => {
      return {
        ...prev,
        skip: 0,
        limit: prev.limit,
        filter: {
          ...prev.filter,
        },
      };
    });
  }, [
    mcPayablesFilter,
    carrierPayablesFilter,
    officePayablesFilter,
    branchPayablesFilter,
    agentPayablesFilter,
    paymentMethodFilter,
    payablesCarrierTypeFilter,
    agentsOptions?.result?.length,
  ]);

  const handleResetFilters = () => {
    reset();
    setSearchParams({
      tab,
      open,
      mode,
      paidStartDate: '',
      paidEndDate: '',
      paidAllDate: '',
      paidOption: '4',
      regStartDate: '',
      regEndDate: '',
      regAllDate: '',
      regOption: '4',
    });
    setSelectedAgentOption({});
    setSelectedCarrierOption({});
    setSelectedMcOption({});
  };

  const handleRowClick = (rowId: string, { carrierId, authorityId }: { carrierId: string; authorityId: string }) => {
    setSearchParams({
      ...searchParams,
      mode: 'view',
      open: 'true',
      id: carrierId,
      authorityId,
    });
  };

  const handleDownloadClick = (fileIds: number[], id: number) => {
    if (fileIds.length) {
      getLoadFilesByIds({ id, fileIds }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: SUBJECT.DOWNLOADING_IN_PROCCESS,
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          const files = data?.data?.result.map((file: any) => {
            return {
              download: file?.file?.download,
              id: file?.file?.id,
            };
          });
          multiplyDownloadWithDelay(files);
        }
      });
    }
  };

  const handleViewFileClick = (fileIds: number[], id: number) => {
    if (fileIds.length) {
      getLoadFilesByIds({ id, fileIds }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          const files = data?.data?.result.map((file: any) => {
            return {
              preview: file?.file?.preview,
              id: file?.file?.id,
            };
          });
          multiplyViewWithDelay(files);
        }
      });
    }
  };
  const [carrierParam, setCarrierParam] = useState({ skip: 0, limit: 10 });

  useEffect(() => {
    if (id && authorityId) {
      getCarrierInfo({
        carrierId: id,
        authorityIds: watch('authorityIds')?.map((el: any) => Number(el.id)),
        skip: carrierParam.skip,
        limit: carrierParam.limit,
      });
    }
  }, [id, authorityId, isCarrierPaySuccess, watch('authorityIds')?.length]);

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setPayablesList(prev => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'acceptBillingTimeAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const onReceivePayableInvoice = (rowData: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_RECEIVE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        onOk: () => {
          receiveInvoice({ id: rowData?.id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleCancel = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
      authorityId: '',
    });
    resetField('authorityIds');
  };

  const handleStopResize = (columns: IColumn[]): void => {
    editColumns({ type: 'payables', columns });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (columnsData?.columns.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
    });
    dispatch(closeModal());
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({ setDragColumns, deleteColumns, editColumns, handleCloseModal, dragColumns, type: 'payables' });

  const handlePaginationClick = (skip: number, limit: number) => {
    setPayablesList(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handlePayablePaginationClick = (skip: number, limit: number) => {
    getCarrierInfo({
      carrierId: id,
      authorityIds: watch('authorityIds')?.map((el: any) => Number(el.id)),
      skip,
      limit,
    });
    setCarrierParam({ skip, limit });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setPayablesList((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    view: {
      component: (
        <PayablesView
          carrierById={carrierById!}
          isLoading={isLoadingById}
          carrierInfo={carrierInfo!}
          getCarrierPay={getCarrierPay}
          isInfoLoading={isInfoLoading}
          resetField={resetField}
          watch={watch}
          setValue={setValue}
          control={control}
          pagePermissions={payablesPagePermissions}
          handleDownloadClick={handleDownloadClick}
          handleViewFileClick={handleViewFileClick}
          handlePaginationClick={handlePayablePaginationClick}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    id,
    columnsData,
    columnsData?.columns,
    dragColumns,
    columnsData?.isDefault,
    isSuccess,
    authorityId,
    carrierById,
    isLoadingById,
    carrierInfo,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  useEffect(() => {
    if (data?.result) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result]);

  return {
    columns: columnsData?.columns,
    isLoading,
    data,
    carriers,
    setCarriersState,
    setAuthoritiesState,
    handleRowClick,
    handleSortClick,
    currentChildren,
    control,
    handleResetFilters,
    watch,
    setValue,
    handlePaginationClick,
    skip: payablesList.skip,
    limit: payablesList.limit,
    isDirty,
    regOption,
    handleDownloadClick,
    handleStopResize,
    paidOption,
    setBranchFilter,
    agentsOptions,
    setOfficesFilter,
    setAgentsFilter,
    selectedOffices,
    selectedBranches,
    branches: finalBranchesOptions,
    offices: finalOfficeOptions,
    agentPayablesFilter,
    finalMcOptions,
    selectedMcOption,
    finalCarrierOptions,
    selectedCarrierOption,
    selectedAgentOption,
    finalAgentOptions,
    handleColumnSearch,
    onReceivePayableInvoice,
    hasPermissionToReceiveInvoice: payablesPagePermissions?.receiveInvoice,
  };
};

export default usePayables;
