import React, { ChangeEvent, ForwardedRef, forwardRef, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef, RefSelectProps } from 'antd';
import { Col, Divider, Input, Row, Select } from 'antd';
import clsx from 'clsx';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import SelectArrowIcon from 'components/svgs/SelectArrowIcon';

import { CUSTOM_OPTION, OPTIONS, SUBJECT } from './constants/constants';
import { ICustomDropdownProps } from './constants/types';

import styles from './CustomDropdown.module.scss';

const CustomDropdown = forwardRef(
  (
    {
      suffixIcon,
      options = OPTIONS,
      placeholder = SUBJECT.TYPE,
      label,
      required,
      errors,
      name,
      value,
      onChange,
      withCustomOption = true,
      ...props
    }: Partial<ICustomDropdownProps>,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const [inputValue, setInputValue] = useState('');
    const [selectValue, setSelectValue] = useState<string | undefined>('');

    const [inputVisible, setInputVisible] = useState(false);
    const [keepDropdownOpen, setKeepDropdownOpen] = useState<boolean | undefined>();
    const [isCustomDisabled, setIsCustomDisabled] = useState<boolean | undefined>(true);

    const inputRef = useRef<InputRef>(null);
    const selectRef = useRef<RefSelectProps>(null);
    const mainRef = useOutsideDetect<HTMLDivElement>(() => {
      setInputVisible(false);
      setKeepDropdownOpen(undefined);
    });
    const onInputValueChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setIsCustomDisabled(false);
      if (value === '') {
        setIsCustomDisabled(true);
      }
      setInputValue(value);
    };

    const handleCustomOptionClick = () => {
      setInputVisible(true);
      setKeepDropdownOpen(true);
    };

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      e.preventDefault();
      setSelectValue(inputValue);
      setInputValue('');
      setInputVisible(false);
      setKeepDropdownOpen(undefined);
      if (onChange) {
        onChange(inputValue);
      }
    };

    const handleSelectChange = (value: string) => {
      if (value === 'custom') {
        setSelectValue(value);
        handleCustomOptionClick();
      } else {
        setSelectValue(value);
        if (onChange) {
          onChange(value);
        }
        setKeepDropdownOpen(undefined);
        setInputValue('');
        setInputVisible(false);
      }
    };

    const dropdownRef = useRef<HTMLInputElement>(null);

    return (
      <div className={styles.selectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          id={`custom-dropdown_${name}`}
          className={clsx(styles.selectWrapper, {
            [styles.selected]: value?.length || selectValue?.length,
            [styles.required]: required,
            [styles.error]: errors && errors[name || ''],
          })}
          ref={dropdownRef}
        >
          <Select
            onSelect={handleSelectChange}
            open={keepDropdownOpen}
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            getPopupContainer={() => document.getElementById(`custom-dropdown_${name}`)!}
            optionLabelProp="label"
            optionFilterProp="label"
            suffixIcon={suffixIcon || <SelectArrowIcon />}
            className={styles.select}
            ref={selectRef || ref}
            placeholder={label ? placeholder : null}
            options={withCustomOption ? [...options, CUSTOM_OPTION] : options}
            value={value || null}
            dropdownRender={menu => (
              <>
                {menu}
                {inputVisible && (
                  <div ref={mainRef}>
                    <Divider style={{ margin: '8px 0' }} />
                    <div className={styles.enterItem}>
                      <Row gutter={[8, 4]}>
                        <Col span={24}>
                          <Input
                            placeholder={SUBJECT.ENTER_ITEM}
                            ref={inputRef}
                            value={inputValue}
                            onChange={onInputValueChange}
                          />
                        </Col>
                        <Col span={24}>
                          <Button variant="text" icon={<PlusOutlined />} onClick={addItem} disabled={isCustomDisabled}>
                            {SUBJECT.ADD_ITEM}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </>
            )}
            {...props}
          />
          {!label && (
            <Typography variant="paragraph" className={`placeholder-select ${styles.placeholder}`}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);
export default CustomDropdown;
