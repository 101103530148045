import { ICreateLoad, IPickupsDropoffs } from './types';

export const defaultValues: ICreateLoad = {
  load_type: '',
  customerName: '',
  referenceNumber: '',
  customerAmount: '',
  customerContact: '',
  customerContactName: '',
  carrier: [],
  carrierAmount: '',
  truck: [],
  agent: [],
  agentAmount: '',
  authorities: [],
  file: [],
  soldUsSwitch: false,
  markAsSwitch: false,
  transportType: null,
  celsius: '0',
  takenAs: null,
  givenAs: null,
  commodity: '',
  bookedAs: null,
  soldAs: null,
  feetPartial: '',
  weight: '',
  pickups: [],
  comment: '',
  dropOffs: [],
};

export const pickupsDropoffsDefaultValues: IPickupsDropoffs = {
  dropType: '',
  phoneNumber: '',
  appointmentDate: null,
  appointmentTime: null,
  facility: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  comment: '',
};

export const LOAD_TYPES_OPTIONS = [
  { value: '1', title: 'Regular Load' },
  { value: '3', title: 'Connecting Load' },
  { value: '4', title: 'Internal Load' },
];

export const TRANSPORT_TYPES = [
  { value: '1', title: 'Dry' },
  { value: '2', title: 'Refrigerated' },
  { value: '3', title: 'Power Only' },
  { value: '4', title: 'Flat Bed' },
];

export const RESOURCE_TYPES = [
  { value: '1', title: 'Solo' },
  { value: '2', title: 'Team' },
];

export const BOOKED_TYPES = [
  { value: '1', title: 'Full' },
  { value: '2', title: 'Partial' },
];

export const getDropTypes = (hasPickUp: boolean) => {
  return [{ value: '1', title: 'Pickup' }, ...(hasPickUp ? [{ value: '2', title: 'Drop-off' }] : [])];
};

export const FACILITY_TYPES = [
  { value: '1', title: 'Facility 1' },
  { value: '2', title: 'Facility 2' },
];

export const LOAD_DOCUMENT_OPTIONS = [
  { label: 'POD', value: 'POD' },
  { label: 'Receipt', value: 'Receipt' },
];

export const LEGIBLE_SELECT = [
  { title: 'Legible', value: 'Legible' },
  { title: 'Not Legible', value: 'Not legible' },
];

export const SEARCH_CARRIER_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
  { key: 'value', name: 'ID Number' },
];

export const SEARCH_CARRIER_CUSTOM_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
  { key: 'value', name: 'ID Number' },
];

export const AUTHORITIES_CUSTOM_COLUMNS = [
  { key: 'title', name: 'Name' },
  { key: 'value', name: 'MC Number' },
];

export const AUTHORITIES_CUSTOM_KEYS = ['id', 'title', 'value'];

export const SEARCH_TRUCK_COLUMNS = [{ key: 'value', name: '' }];
export const TRUCK_KEYS = ['title'];
export const CARRIER_CUSTOM_KEYS = ['id', 'name', 'mc', 'value'];

export const CARRIER_KEYS = ['id', 'name', 'mc', 'value'];

export const SEARCH_FACILITY_COLUMNS = [{ key: 'value', name: '' }];
export const FACILITY_KEYS = ['title'];

export enum SUBJECT {
  ADD_LOAD = 'Add load',
  ALLOWED_FORMAT = 'Allowed Format (jpeg, pdf, tiff, png)',
  ALLOWED_FORMAT_PDF = 'Allowed Format (pdf)',
  DELETE = 'Delete',
  CLOSE = 'Close',
  ARE_YOU_SURE = 'Are You Sure',
  UPLOAD_FILE = 'Upload File',
  GENERAL_INFO = 'General Information',
  TAKEN_US_MC = 'Taken As MC',
  LOAD_TYPE = 'Load Type',
  CUSTOMER_NAME = 'Customer Name',
  REFERENCE_NUMBER = 'Reference Number',
  CUSTOMER_AMOUNT = 'Customer Amount',
  CUSTOMER_CONTACT = 'Phone Number',
  SOLD_US_SWITCH = 'Sold As',
  ALL_AUTHORITIES = 'All Authorities',
  CARRIER = 'Carrier',
  CARRIER_AMOUNT = 'Carrier Rate',
  TRUCK = 'Truck',
  AGENT = 'Agent',
  AGENT_AMOUNT = 'Agent Amount',
  AGENT_AMOUNT_REQUIRED = 'Agent Amount Is a Required Field',
  DOCUMENTATION = 'Documents',
  UPLOAD_RED_FILE = 'Upload Rate Confirmation',
  UPLOAD_TEXT = 'Drag And Drop Or',
  LOAD_INFORMATION = 'Load Detail',
  REFRIGERATOR_CELCIUS = 'Refrigerator Fahrenheit',
  TAKEN_AS = 'Taken As',
  GIVEN_AS = 'Given As',
  COMMODITY = 'Commodity',
  BOOKED_AS = 'Booked As',
  SOLD_AS = 'Sold As',
  WEIGHT = 'Weight',
  FEET_PARTIAL = 'Feet Of Partial',
  PICKUPS_DROPOFFS = 'Pick Ups & Drop Offs',
  DROP_TYPE = 'Drop Type',
  PHONE_NUMBER = 'Phone Number',
  APPOINTMENT_DATE = 'Appointment Date',
  APPOINTMENT_TIME = 'Appointment Time',
  FACILITY = 'Facility',
  ADDRESS = 'Address',
  CITY = 'City',
  ZIP = 'ZIP Code',
  STATE = 'State',
  WRITE_COMMENT = 'Write Comment',
  ADD_ADDRESS = 'Add Address',
  NOT_ALLOWED_ADD = 'You Are Not Allowed To Edit Pickups Or Drop-Offs',
  REQUIRED = 'Customer Amount Is Required Field',
  CUSTOMER = 'You Can`t Enter a Number Greater Than',
  PICKUPS = 'Pick Ups',
  DROP_OFFS = 'Drop Offs',
  SAVE = 'Save',
  CANCEL = 'Cancel',
  ADD = 'Add',
  MIN_OPTIONS = 'At Least One Option Is Required',
  PICKUPS_REQUIRED = 'Pickups Are Required',
  DROPOFFS_REQUIRED = 'DropOffs Are Required',
  CUSTOMER_AMOUNT_MUST_BE_A_NUMBER = 'Customer Amount Must Be a Number',
  CARRIER_AMOUNT_MUST_BE_LESS_OR_EQUAL_THAN_CUSTOMER_AMOUNT = 'Carrier Amount Must Be Less Or Equal Than Customer Amount',
  CARRIER_AMOUNT_MUST_BE_LESS_OR_EQUAL_THAN_SECOND_AGENT_AMOUNT = 'Carrier Amount Must Be Less Or Equal Than Second Agent Amount',
  AGENT_AMOUNT_MUST_BE_LESS_OR_EQUAL_THAN_CUSTOMER_AMOUNT = 'Agent Amount Must Be Less Or Equal Than Customer Amount',
  MAX_LENGTH = 'Maximum Of 10 Digits Allowed',
  PRIMARY_PHONE_REQUIRED = 'Phone Number Is a Eequired Field',
  PHONE_FORMAT = 'Incorrect Phone Format',
  FACILITY_REQUIRED = 'Facility Is a Required Field',
  ADDRESS_REQUIRED = 'Address Is a Required Field',
  CITY_REQUIRED = 'City Is a Required Field',
  STATE_REQUIRED = 'State Is a Required Field',
  ZIP_REQUIRED = 'Zip Code Is a Required Field',
  CONTACT_NAME = 'Customer Contact Name',
}

export const PICKUP_DROPOFFS_TYPES = {
  pickups: '1',
  dropOffs: '2',
};
