import { useState } from 'react';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';
import { checkElementLengthForTooltip } from 'utils/helpers';

import { ITooltipText } from './constants/types';

import styles from './TooltipText.module.scss';

const TooltipText = ({ data, dataLink, phoneLink, subject, prefixClass }: ITooltipText) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState('');
  return (
    <Tooltip
      color="white"
      placement="top"
      arrow={{ pointAtCenter: true }}
      title={data}
      onOpenChange={open => checkElementLengthForTooltip(`${subject}`, `child_${subject}`, open, setIsTooltipOpen)}
      open={isTooltipOpen === `child_${subject}`}
    >
      {dataLink ? (
        <a
          onClick={() => setIsTooltipOpen('')}
          className={clsx(prefixClass, styles.tooltipCropped)}
          id={`child_${subject}`}
          href={`mailto:${data}`}
        >
          {data}
        </a>
      ) : phoneLink?.length ? (
        <div className={styles.phoneNameItem}>
          <a href={`tel:${phoneLink}`}>{phoneLink}</a>
          <Typography
            id={`${subject}`}
            variant="paragraph"
            className={clsx(prefixClass, styles.tooltipCropped)}
            onClick={() => setIsTooltipOpen('')}
          >
            <span id={`child_${subject}`}>{data}</span>
          </Typography>
        </div>
      ) : (
        <Typography
          id={`${subject}`}
          variant="paragraph"
          className={clsx(prefixClass, styles.tooltipCropped)}
          onClick={() => setIsTooltipOpen('')}
        >
          <span id={`child_${subject}`}>{data}</span>
        </Typography>
      )}
    </Tooltip>
  );
};

export default TooltipText;
