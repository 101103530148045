import React, { ForwardedRef, forwardRef } from 'react';
import { Input } from 'antd';
import { TextAreaRef } from 'antd/es/input/TextArea';
import clsx from 'clsx';

import { ITextAreaProps } from './constants/types';

import styles from './Textarea.module.scss';

const { TextArea: AntTextArea } = Input;
const TextArea = forwardRef(
  ({ label, isFilled, placeholder, disabled, ...rest }: ITextAreaProps, ref: ForwardedRef<TextAreaRef>) => {
    return (
      <div
        className={clsx(styles.textAreaLabel, {
          [styles.filled]: isFilled,
        })}
      >
        <AntTextArea ref={ref} {...rest} disabled={disabled} placeholder={placeholder} />
        {label && <label className={styles.placeholder}>{label}</label>}
      </div>
    );
  }
);

export default TextArea;
