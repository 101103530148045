import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { IEmployeeViewProps } from 'pages/profile/tabs/employee/components/employee-view/constants/types';
import { SUBJECT } from 'pages/profile/tabs/employee/constants/constants';
import Button from 'ui/button/Button';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate, formatPickUpDropOff } from 'utils/dates';
import {
  checkElementLengthForTooltip,
  formatPhone,
  multiplyDownloadWithDelay,
  multiplyViewWithDelay,
} from 'utils/helpers';

import CopyIcon from 'components/svgs/CopyIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { useEmployeeView } from './useEmployeeView';

import styles from './EmployeeView.module.scss';

const EmployeeView = ({
  data,
  isFetchingById,
  pagePermissions: { terminateOn, terminateOff },
  getAllowedEditPermission,
}: IEmployeeViewProps) => {
  const viewData = data?.result;

  const { handleChecked, handleChangeMode, handleInviteAgain, isTooltipOpen, setIsTooltipOpen, activePage } =
    useEmployeeView(viewData);
  const { copiedText, handleCopy } = useHandleCopyActive();
  const checkedValue = !viewData?.terminatedAt;

  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetchingById} />

      <ViewItemWrapper
        title={SUBJECT.EMPLOYEE_INFO}
        Component={
          getAllowedEditPermission() && (
            <Button children={SUBJECT.EDIT} onClick={handleChangeMode} className="viewDrawerEditBtn" />
          )
        }
      >
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.FIRST_NAME}:{' '}
              </Typography>
              <Tooltip
                color="white"
                placement="top"
                title={viewData?.firstName}
                onOpenChange={open =>
                  checkElementLengthForTooltip(
                    `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`,
                    `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`,
                    open,
                    setIsTooltipOpen
                  )
                }
                open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`}
              >
                <Typography
                  id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`}
                  variant="paragraph"
                  className="tooltipCroped top-banner-value"
                  onClick={() => setIsTooltipOpen('')}
                >
                  <span id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.FIRST_NAME}`}>{viewData?.firstName}</span>
                </Typography>
              </Tooltip>
            </div>
          </Col>
          {!!viewData?.ext && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.EXT}:{' '}
                </Typography>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.ext}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`,
                      `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`}
                >
                  <Typography
                    id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`}
                    variant="paragraph"
                    className="tooltipCroped top-banner-value"
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EXT}`}>{viewData?.ext}</span>
                  </Typography>
                </Tooltip>
              </div>
            </Col>
          )}
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.EMAIL}:{' '}
              </Typography>
              <Tooltip
                color="white"
                placement="top"
                title={viewData?.email}
                onOpenChange={open =>
                  checkElementLengthForTooltip(
                    `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`,
                    `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`,
                    open,
                    setIsTooltipOpen
                  )
                }
                open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`}
              >
                <Typography
                  id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`}
                  variant="paragraph"
                  className="tooltipCroped top-banner-value"
                  onClick={() => setIsTooltipOpen('')}
                >
                  <a id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.EMAIL}`} href={`mailto:${viewData?.email}`}>
                    {viewData?.email}
                  </a>
                </Typography>
              </Tooltip>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.LAST_NAME}:{' '}
              </Typography>
              <Tooltip
                color="white"
                placement="top"
                title={viewData?.lastName}
                onOpenChange={open =>
                  checkElementLengthForTooltip(
                    `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`,
                    `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`,
                    open,
                    setIsTooltipOpen
                  )
                }
                open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`}
              >
                <Typography
                  id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`}
                  variant="paragraph"
                  className="tooltipCroped top-banner-value"
                  onClick={() => setIsTooltipOpen('')}
                >
                  <span id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.LAST_NAME}`}>{viewData?.lastName}</span>
                </Typography>
              </Tooltip>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.PHONE_NUMBER}:{' '}
              </Typography>

              <a className="top-banner-value" href={`tel:${viewData?.phone}`}>
                {formatPhone(viewData?.phone)}
              </a>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.PERMISSION_GROUP}:{' '}
              </Typography>
              <Tooltip
                color="white"
                placement="top"
                title={viewData?.permissionGroupName}
                onOpenChange={open =>
                  checkElementLengthForTooltip(
                    `${SUBJECT.EMPLOYEE_INFO}${SUBJECT.PERMISSION_GROUP}`,
                    `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.PERMISSION_GROUP}`,
                    open,
                    setIsTooltipOpen
                  )
                }
                open={isTooltipOpen === `child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.PERMISSION_GROUP}`}
              >
                <Typography
                  id={`${SUBJECT.EMPLOYEE_INFO}${SUBJECT.PERMISSION_GROUP}`}
                  variant="paragraph"
                  className="tooltipCroped top-banner-value"
                  onClick={() => setIsTooltipOpen('')}
                >
                  <span id={`child_${SUBJECT.EMPLOYEE_INFO}${SUBJECT.PERMISSION_GROUP}`}>
                    {viewData?.permissionGroupName}
                  </span>
                </Typography>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.FULL_INFO}>
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(viewData?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.FIRST_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.firstName}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.FULL_INFO}${SUBJECT.FIRST_NAME}`,
                      `child_${SUBJECT.FULL_INFO}${SUBJECT.FIRST_NAME}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.FIRST_NAME}`}
                >
                  <Typography
                    id={`${SUBJECT.FULL_INFO}${SUBJECT.FIRST_NAME}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.FIRST_NAME}`}>{viewData?.firstName}</span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.LAST_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.lastName}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.FULL_INFO}${SUBJECT.LAST_NAME}`,
                      `child_${SUBJECT.FULL_INFO}${SUBJECT.LAST_NAME}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.LAST_NAME}`}
                >
                  <Typography
                    id={`${SUBJECT.FULL_INFO}${SUBJECT.LAST_NAME}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.LAST_NAME}`}>{viewData?.lastName}</span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.USER_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.username}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.FULL_INFO}${SUBJECT.USER_NAME}`,
                      `child_${SUBJECT.FULL_INFO}${SUBJECT.USER_NAME}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.USER_NAME}`}
                >
                  <Typography
                    id={`${SUBJECT.FULL_INFO}${SUBJECT.USER_NAME}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.USER_NAME}`}>{viewData?.username}</span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>

          {activePage === 'branch' && viewData?.office?.name && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT?.OFFICE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" className={styles.infoParagraph} children={viewData?.office?.name} />
                </Col>
              </Row>
            </Col>
          )}

          {viewData?.[activePage]?.name && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography
                    variant="heading"
                    children={SUBJECT?.[activePage?.toUpperCase() as keyof typeof SUBJECT]}
                    className={styles.infoHeading}
                  />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    className={styles.infoParagraph}
                    children={viewData?.[activePage]?.name}
                  />
                </Col>
              </Row>
            </Col>
          )}

          {viewData?.dateOfBirth && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.DOB} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    className={styles.infoParagraph}
                    children={viewData?.dateOfBirth ? formatPickUpDropOff(viewData?.dateOfBirth) : ''}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.email}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.FULL_INFO}${SUBJECT.EMAIL}`,
                      `child_${SUBJECT.FULL_INFO}${SUBJECT.EMAIL}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.EMAIL}`}
                >
                  <Typography
                    id={`${SUBJECT.FULL_INFO}${SUBJECT.EMAIL}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.EMAIL}`}>{viewData?.email}</span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <a className="top-banner-value" href={`tel:${viewData?.phone}`}>
                  {formatPhone(viewData?.phone)}
                </a>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.INVITATION} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                {viewData?.verifiedAt ? (
                  <Typography variant="paragraph" children={SUBJECT.ACCEPTED} className={styles.infoParagraph} />
                ) : (
                  <Typography
                    onClick={() => handleInviteAgain(viewData?.id)}
                    variant="paragraph"
                    children={SUBJECT.INVITE_AGAIN}
                    className={styles.infoParagraphReset}
                  />
                )}
              </Col>
            </Row>
          </Col>
          {!!viewData?.ext && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.EXT} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Tooltip
                    color="white"
                    placement="top"
                    title={viewData?.ext}
                    onOpenChange={open =>
                      checkElementLengthForTooltip(
                        `${SUBJECT.FULL_INFO}${SUBJECT.EXT}`,
                        `child_${SUBJECT.FULL_INFO}${SUBJECT.EXT}`,
                        open,
                        setIsTooltipOpen
                      )
                    }
                    open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.EXT}`}
                  >
                    <Typography
                      id={`${SUBJECT.FULL_INFO}${SUBJECT.EXT}`}
                      variant="paragraph"
                      className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                      onClick={() => setIsTooltipOpen('')}
                    >
                      <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.EXT}`}>{viewData?.ext}</span>
                    </Typography>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          )}
          {viewData?.title && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.TITLE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Tooltip
                    color="white"
                    placement="top"
                    title={viewData?.title}
                    onOpenChange={open =>
                      checkElementLengthForTooltip(
                        `${SUBJECT.FULL_INFO}${SUBJECT.TITLE}`,
                        `child_${SUBJECT.FULL_INFO}${SUBJECT.TITLE}`,
                        open,
                        setIsTooltipOpen
                      )
                    }
                    open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.TITLE}`}
                  >
                    <Typography
                      id={`${SUBJECT.FULL_INFO}${SUBJECT.TITLE}`}
                      variant="paragraph"
                      className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                      onClick={() => setIsTooltipOpen('')}
                    >
                      <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.TITLE}`}>{viewData?.title}</span>
                    </Typography>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PERMISSION_GROUP} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.permissionGroupName}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.FULL_INFO}${SUBJECT.PERMISSION_GROUP}`,
                      `child_${SUBJECT.FULL_INFO}${SUBJECT.PERMISSION_GROUP}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.PERMISSION_GROUP}`}
                >
                  <Typography
                    id={`${SUBJECT.FULL_INFO}${SUBJECT.PERMISSION_GROUP}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.PERMISSION_GROUP}`}>
                      {viewData?.permissionGroupName}
                    </span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.EMPLOYEES_ID} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === 'employeeId',
                  })}
                >
                  <Typography variant="paragraph" children={viewData?.id} className={styles.infoParagraph} />
                  <div className={styles.copyIcon} role="button" onClick={() => handleCopy(viewData?.id, 'employeeId')}>
                    <CopyIcon active={copiedText === 'employeeId'} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.address}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.FULL_INFO}${SUBJECT.ADDRESS}`,
                      `child_${SUBJECT.FULL_INFO}${SUBJECT.ADDRESS}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.ADDRESS}`}
                >
                  <Typography
                    id={`${SUBJECT.FULL_INFO}${SUBJECT.ADDRESS}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.ADDRESS}`}>{viewData?.address}</span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          {viewData?.secondAddress && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.SECOND_ADDRESS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Tooltip
                    color="white"
                    placement="top"
                    title={viewData?.secondAddress}
                    onOpenChange={open =>
                      checkElementLengthForTooltip(
                        `${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`,
                        `child_${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`,
                        open,
                        setIsTooltipOpen
                      )
                    }
                    open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`}
                  >
                    <Typography
                      id={`${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`}
                      variant="paragraph"
                      className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                      onClick={() => setIsTooltipOpen('')}
                    >
                      <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`}>{viewData?.secondAddress}</span>
                    </Typography>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.city}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.FULL_INFO}${SUBJECT.CITY}`,
                      `child_${SUBJECT.FULL_INFO}${SUBJECT.CITY}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.CITY}`}
                >
                  <Typography
                    id={`${SUBJECT.FULL_INFO}${SUBJECT.CITY}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.FULL_INFO}${SUBJECT.CITY}`}>{viewData?.city}</span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" className={styles.infoParagraph} children={viewData?.stateName} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ZIPCODE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" className={styles.infoParagraph} children={viewData?.zip} />
              </Col>
            </Row>
          </Col>
          {!!viewData?.verifiedAt && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Switch
                    checked={checkedValue || false}
                    onChange={handleChecked}
                    disabled={(checkedValue && !terminateOff) || (!checkedValue && !terminateOn)}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.FILE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Row gutter={5}>
                  <Col>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        multiplyViewWithDelay(data?.result?.files);
                      }}
                    >
                      <EyeIconSvg outlined={true} />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        multiplyDownloadWithDelay(data?.result?.files);
                      }}
                    >
                      <NoaDownloadIcon />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {viewData?.creator?.username && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Tooltip
                    color="white"
                    placement="top"
                    title={`${viewData?.creator.username}`}
                    onOpenChange={open =>
                      checkElementLengthForTooltip(
                        `${SUBJECT.FULL_INFO}${SUBJECT.ADDED_BY}`,
                        `child_${SUBJECT.FULL_INFO}${SUBJECT.ADDED_BY}`,
                        open,
                        setIsTooltipOpen
                      )
                    }
                    open={isTooltipOpen === `child_${SUBJECT.FULL_INFO}${SUBJECT.ADDED_BY}`}
                  >
                    <Typography
                      id={`${SUBJECT.FULL_INFO}${SUBJECT.ADDED_BY}`}
                      variant="paragraph"
                      className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                      onClick={() => setIsTooltipOpen('')}
                    >
                      <span
                        id={`child_${SUBJECT.FULL_INFO}${SUBJECT.ADDED_BY}`}
                      >{`${viewData?.creator.username}`}</span>
                    </Typography>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.EMERGENCY_CONTACT}>
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.emergencyName}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.NAME}`,
                      `child_${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.NAME}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.NAME}`}
                >
                  <Typography
                    id={`${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.NAME}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.NAME}`}>{viewData?.emergencyName}</span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.RELATION} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  color="white"
                  placement="top"
                  title={viewData?.emergencyRelation}
                  onOpenChange={open =>
                    checkElementLengthForTooltip(
                      `${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.RELATION}`,
                      `child_${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.RELATION}`,
                      open,
                      setIsTooltipOpen
                    )
                  }
                  open={isTooltipOpen === `child_${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.RELATION}`}
                >
                  <Typography
                    id={`${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.RELATION}`}
                    variant="paragraph"
                    className={clsx(styles.infoParagraph, styles.tooltipCroped)}
                    onClick={() => setIsTooltipOpen('')}
                  >
                    <span id={`child_${SUBJECT.EMERGENCY_CONTACT}${SUBJECT.RELATION}`}>
                      {viewData?.emergencyRelation}
                    </span>
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <a className="top-banner-value" href={`tel:${viewData?.emergencyPhone}`}>
                  {formatPhone(viewData?.emergencyPhone)}
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </div>
  );
};
export default EmployeeView;
