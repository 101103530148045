export enum SUBJECT {
  ADDED_BY = 'Added By',
  FILENAME = 'd',
  PHONE_NUMBER = 'Phone Number',
  FAX = 'Fax',
  MC = 'MC Number',
  ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIP = 'Zip Code',
  EMAIL = 'Email',
  BANK_NAME = 'Bank Name',
  ROUTING_NUMBER = 'Routing Number',
  ACCOUNT_NUMBER = 'Account Number',
  DOT = 'DOT',
  PAYMENT_COMPANY_NAME = 'Company Name',
  FACTORED = 'Factored',
  NOT_FACTORED = 'Not Factored',
  PAPER_CHECK = 'Paper check',
  DIRECT_DEPOSIT = 'Direct Deposit',
  FUEL_CARD = 'Fuel Card',
}

export enum PaymentTermTypes {
  'Unknown',
  'Carrier package',
  'Sign agreement',
  'Voided check',
  'NOA',
}
