import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';

import { TRangePickerProps } from './constants/types';

import styles from './RangePicker.module.scss';

const { RangePicker: AntRangePicker } = AntDatePicker;

const DatePicker = ({ label, ...rest }: TRangePickerProps) => {
  return (
    <div className={styles.datePicker}>
      <label>{label}</label>
      <AntRangePicker {...rest} />
    </div>
  );
};

export default DatePicker;
