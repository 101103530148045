import { configureStore } from '@reduxjs/toolkit';
import { authAPI } from 'services/auth/auth';
import { autocompleteAPI } from 'services/autocomplete/autocomplete';
import { carriersOnBoardAPI } from 'services/carriersOnBoard/carriersOnBoard';
import { carriersOnBoardFileAPI } from 'services/carriersOnBoard/upload';
import { contactAPI } from 'services/contact/contact';
import { exportsAPI } from 'services/exports/exports';
import { billingAPI } from 'services/factoring/billing/billing';
import { payablesAPI } from 'services/factoring/payables/payables';
import { receivablesAPI } from 'services/factoring/receivables/receivables';
import { loadsAPI } from 'services/loads/loads';
import { informationAPI } from 'services/payroll/information/information';
import { paymentAPI } from 'services/payroll/payment/payment';
import { permissionAPI } from 'services/permission/permission';
import { agentsAPI } from 'services/profile/agents/agents';
import { authoritiesAPI } from 'services/profile/authorities/authorities';
import { authoritiesAPIFile } from 'services/profile/authorities/authorities-file';
import { branchesAPI } from 'services/profile/branches/branches';
import { carriersApi } from 'services/profile/carriers/carriers';
import { customersApi } from 'services/profile/customers/customers';
import { employeesAPI } from 'services/profile/employees/employees';
import { factoringCompaniesAPI } from 'services/profile/factoringCompanies/factoringCompanies';
import { officesAPI } from 'services/profile/offices/offices';
import { statesAPI } from 'services/states/states';
import { transactionsAPI } from 'services/transactions/transactions';
import { carrierRequestApi } from 'services/truck-board/carrier-request/carrier-request';
import { favoriteAPI } from 'services/truck-board/favorite-trucks/favorite';
import { truckBoardApi } from 'services/truck-board/truck-board/truckBoard';
import { verificationAPI } from 'services/truck-board/verification-board/verification';
import { userAPI } from 'services/user/user';

import activeSectionSlice from './active-section-slice/activeSection';
import addButtonVisibleSlice from './add-button-slice/addButton';
import isValidSlice from './check-is-dirty-slice/isValid';
import commentSlice from './commentSlice/comment';
import loadSlice from './loads-slice/loadSlice';
import modalSlice from './modal-slice/modals';
import onlineAgentsSlice from './online-agents-slice/onlineAgents';
import sidebarSlice from './sidebarSlice/sidebar';
import userSlice from './user-slice/user';

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    comment: commentSlice,
    addButtonVisible: addButtonVisibleSlice,
    activeSection: activeSectionSlice,
    isValid: isValidSlice,
    onlineAgents: onlineAgentsSlice,
    modals: modalSlice,
    user: userSlice,
    loads: loadSlice,
    [authoritiesAPI.reducerPath]: authoritiesAPI.reducer,
    [authoritiesAPIFile.reducerPath]: authoritiesAPIFile.reducer,
    [statesAPI.reducerPath]: statesAPI.reducer,
    [agentsAPI.reducerPath]: agentsAPI.reducer,
    [branchesAPI.reducerPath]: branchesAPI.reducer,
    [officesAPI.reducerPath]: officesAPI.reducer,
    [factoringCompaniesAPI.reducerPath]: factoringCompaniesAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [employeesAPI.reducerPath]: employeesAPI.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [carriersApi.reducerPath]: carriersApi.reducer,
    [carrierRequestApi.reducerPath]: carrierRequestApi.reducer,
    [truckBoardApi.reducerPath]: truckBoardApi.reducer,
    [favoriteAPI.reducerPath]: favoriteAPI.reducer,
    [verificationAPI.reducerPath]: verificationAPI.reducer,
    [loadsAPI.reducerPath]: loadsAPI.reducer,
    [contactAPI.reducerPath]: contactAPI.reducer,
    [informationAPI.reducerPath]: informationAPI.reducer,
    [paymentAPI.reducerPath]: paymentAPI.reducer,
    [autocompleteAPI.reducerPath]: autocompleteAPI.reducer,
    [transactionsAPI.reducerPath]: transactionsAPI.reducer,
    [billingAPI.reducerPath]: billingAPI.reducer,
    [receivablesAPI.reducerPath]: receivablesAPI.reducer,
    [payablesAPI.reducerPath]: payablesAPI.reducer,
    [exportsAPI.reducerPath]: exportsAPI.reducer,
    [permissionAPI.reducerPath]: permissionAPI.reducer,
    [carriersOnBoardAPI.reducerPath]: carriersOnBoardAPI.reducer,
    [carriersOnBoardFileAPI.reducerPath]: carriersOnBoardFileAPI.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authoritiesAPI.middleware,
      authoritiesAPIFile.middleware,
      statesAPI.middleware,
      agentsAPI.middleware,
      branchesAPI.middleware,
      officesAPI.middleware,
      factoringCompaniesAPI.middleware,
      authAPI.middleware,
      employeesAPI.middleware,
      customersApi.middleware,
      userAPI.middleware,
      carriersApi.middleware,
      carrierRequestApi.middleware,
      truckBoardApi.middleware,
      verificationAPI.middleware,
      loadsAPI.middleware,
      contactAPI.middleware,
      autocompleteAPI.middleware,
      transactionsAPI.middleware,
      billingAPI.middleware,
      receivablesAPI.middleware,
      payablesAPI.middleware,
      exportsAPI.middleware,
      permissionAPI.middleware,
      favoriteAPI.middleware,
      informationAPI.middleware,
      paymentAPI.middleware,
      carriersOnBoardAPI.middleware,
      carriersOnBoardFileAPI.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
