import { createSlice } from '@reduxjs/toolkit';

import { ICommentInitial } from './interfaces';

export const initialState: ICommentInitial = {
  commentData: { comments: [], count: 0 },
  dataForEdit: { name: '', createdAt: '', comment: '', id: 0 },
};

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    saveSelectedData: (state, { payload }) => {
      state.dataForEdit = payload;
    },
    resetSelectedData: state => {
      state.dataForEdit = initialState.dataForEdit;
    },
  },
});

export const { saveSelectedData, resetSelectedData } = commentSlice.actions;

export default commentSlice.reducer;
