import { IOptions } from './types';

export enum SUBJECT {
  CARRIER_INFO = 'Carrier Info',
  NAME = 'Name',
  SECOND_NUMBER = 'Second Number',
  MC = 'MC',
  CONTACT_NAME = 'Contact Name',
  CARRIER_TYPE = 'Carrier Type',
  FACTORED = 'factored',
  FACTORED_NAME = 'Factored',
  PHONE_NUMBER = 'Phone Number',
  FAX = 'Fax',
  RATING = 'Rating',
  PAYMENT = 'Payment',
  DAY = 'Day',
  PERCENT = 'Percent',
  COORDINATOR = 'Coordinator',
  RESET_ALL = 'Reset All',
  TOTAL = 'Total',
  PAY = 'Pay',
  PAID = 'Paid',
  EXPECTED = 'Pending',
  CANCEL = 'Cancel',
  LOAD = 'Load',
  FACTORED_TYPE = 'factored',
  NOT_FACTORED_TYPE = 'not_factored',
  QUICK_PAY = 'Quick pay',
  DAYS = '30 day',
  UNSPECIFIED = 'unspecified',
  REQUEST_CHECK = 'request_check',
  DENIED = 'denied',
  NOT_PROCESSING = 'not_processing',
  PROCESSING = 'processing',
  BOTH_PENDING = 'both_pending',
  CARRIER_PAID = 'carrier_paid',
  CARRIER_PENDING_CUSTOMER_DEBT = 'carrier_pending_customer_debt',
  CARRIER_PAID_CUSTOMER_DEBT = 'carrier_paid_customer_debt',
  CUSTOMER_PAID = 'customer_debt',
  PROCESSING_AND_CARRIER_PAID = 'processing_and_carrier_paid',
  PROCESSING_FROM_PENDING = 'processing_from_pending',
  PROCESSING_AND_CUSTOMER_PAID = 'processing_and_customer_paid',
  PROCESSING_AND_CUSTOMER_DEBT = 'processing_and_customer_debt',
  BOTH_PAID = 'both_paid',
  INSURANCE_LIABILITY = 'Insurance Liability Exp:',
  INSURANCE_CARGO = 'Insurance Cargo Exp:',
  YES = 'Yes',
  NO = 'No',
  ARE_YOU_SURE_DELETE = 'Are You Sure Want To Delete Rate?',
}

export const RATE_LIST = [
  { key: 1, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 2, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 3, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 4, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 5, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 6, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 7, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 8, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 9, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 10, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 11, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
  { key: 12, name: 'Jonatan', date: '12-30-2022 01:05 PM', rate: 3 },
];

export const PAYABLES_DATA_GRID_OPTIONS: IOptions[] = [
  {
    title: 'Mira',
    value: 'Mira',
  },
  {
    title: 'John',
    value: 'John',
  },
  {
    title: 'Sara',
    value: 'Sara',
  },
];

export const filterDefaultValues = {
  payablesDataGridFilter: [],
};
