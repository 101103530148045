import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useGetNotificationsQuery, useSetReadNotificationsIdsMutation } from 'services/user/user';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';

export const useNotifyDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [notificationsParams, setNotificationParams] = useState({ skip: 0, limit: 15 });

  const { currentData: data, isFetching, refetch } = useGetNotificationsQuery(notificationsParams);
  const [setReadNotifications] = useSetReadNotificationsIdsMutation();

  const [notifications, setNotifications] = useState<any>([]);
  const [readCounts, setReadCounts] = useState(0);
  const [unreadCountsLocal, setUnreadCountsLocal] = useState(0);

  const handleScroll = (event: any) => {
    const { target } = event;
    const { scrollTop, scrollHeight, clientHeight } = target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && !isFetching && notifications?.length < data?.count) {
      const newSkip = notificationsParams.skip + notificationsParams.limit;
      setNotificationParams({ ...notificationsParams, skip: newSkip });
    }
  };

  useEffect(() => {
    if (data?.result) {
      if (notificationsParams.skip < 15) {
        setNotifications([...data.result]);
        setUnreadCountsLocal(data?.unreadCount);
      } else if (!isFetching && notificationsParams.skip >= 15) {
        setNotifications((prev: any) => [...prev, ...data.result]);
      }
    }
  }, [notificationsParams.skip, data?.result, isFetching]);

  const handleReadNotes = (id: number) => {
    setNotifications((prev: any) => {
      return prev.map((el: any) => {
        if (Number(el.id) === Number(id)) {
          return {
            ...el,
            read: true,
          };
        } else {
          return el;
        }
      });
    });
    setReadNotifications({ ids: [id] }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        setReadCounts(prev => prev + 1);
      }
    });
  };

  const handleReadAllNotes = () => {
    setReadNotifications({ all: true }).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        setNotificationParams({ limit: 15, skip: 0 });
        setNotifications([]);
        setReadCounts(0);
        refetch();
      }
    });
  };

  const onOpen = () => {
    (window as any).global = {
      ...window.global,
      inDrawer: true,
    };

    if (data?.result && data.result.length) {
      setNotifications(data.result);
    }
    setIsOpen(prev => !prev);
  };

  const notificationCount = Number(data?.unreadCount) >= 100 ? 99 : data?.unreadCount;

  const onClose = () => {
    (window as any).global = {
      ...window.global,
      inDrawer: false,
    };

    setIsOpen(false);
    setReadCounts(0);
    setNotifications([]);
    setNotificationParams({ skip: 0, limit: 15 });
    refetch();
  };

  return {
    isOpen,
    onClose,
    onOpen,
    notificationCount,
    handleScroll,
    handleReadNotes,
    notifications,
    isFetching,
    unreadCount: data?.unreadCount,
    handleReadAllNotes,
    readCounts,
    unreadCountsLocal,
  };
};
