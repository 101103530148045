import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row } from 'antd';
import {
  BrokerPaymentTermsType,
  CarrierFactoringType,
  CarrierPaymentTermType,
  CarrierPaymentType,
} from 'services/profile/carriers/interfaces';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import UploadedFileRow from 'ui/uploaded-file-row/UploadedFileRow';
import { handleDownloadClick, handleViewFileClick, onlyNumbersFormatter } from 'utils/helpers';

import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import PaymentTermDataGrid from '../../../../components/payment-term-datagrid/PaymentTermDataGrid';
import { PercentIcon } from '../../../../components/svgs/PercentIcon';

import {
  AUTHORITY_KEYS,
  BROKER_FILES_OPTIONS,
  DAY_OPTION,
  FACTORING_COMPANY_KEYS,
  FACTORING_TYPE_OPTIONS,
  // PAYMENT_TERMS_TYPE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  QUICK_PAY_OPTION,
  SEARCH_AUTHORITY_COLUMNS,
  SEARCH_FACTORING_COMPANIES_COLUMNS,
  SUBJECT,
} from './constants/constants';
import FactoredPopover from './hoc-instance/InfoPopOver';
import { Checkbox, Input, InputTags, Radio, SearchableDropdown } from './hoc-instance/instance';
import { usePaymentTermsSignUp } from './usePaymentTermsSignUp';

import styles from './PaymentTerms.module.scss';

const PaymentTermsSignUp = ({
  activeSection,
  statesData,
  setPaymentTermDirty = () => {
    return null;
  },
  isSubmitted = false,
  setValue: setCarrierMainValue,
  signUpFormErrors,
  panelShowed,
  setPanelShowed,
  clearCarrierFormErrors,
}: any) => {
  const {
    control,
    setValue,
    getValues,
    errors,
    watch,
    trigger,
    setError,
    clearErrors,
    setIsEmailValid,
    setAuthoritiesAutocompleteFilter,
    authoritiesAutocomplete,
    factoringCompaniesAutocomplete,
    handleAddPaymentTerm,
    setFactoringCompaniesAutocompleteFilter,
    mode,
    paymentTerm,
    paymentTermFM,
    setEditingRow,
    allFiles,
    handleRowEdit,
    handleRowDelete,
    editingId,
    handleDownloadAuthorityFileCreate,
    handleSelectFileType,
    handleViewAuthorityFileCreate,
    handleDeleteAuthorityFileCreate,
    beforeUploadForCreate,
    formOpened,
    handleOpenPaymentForm,
    handleCancelPaymentTerm,
    // isSelectDisable,
    localFiles,
    serializedStates,
    setAllFiles,
    dirtyFields,
    factoringCompanyById,
    isFetching,
    // isLoading,
    paymentFormRef,
    paymentTableRef,
    isReseted,
    factoringCompaniesAutocompleteFilter,
    filesByAuthorityId,
    authoritiesAutocompleteFilter,
    handleShowForm,
    handleHideForm,
    brokerPanelShowed,
  } = usePaymentTermsSignUp({
    setPaymentTermDirty,
    setValue: setCarrierMainValue,
    isSubmitted,
    panelShowed,
    setPanelShowed,
    clearCarrierFormErrors,
  });

  const factoringCompaniesOptions = useMemo(() => {
    if (factoringCompaniesAutocompleteFilter.search.replaceAll(' ', '').length >= 3) {
      if (factoringCompaniesAutocomplete?.result?.result) {
        return [
          ...factoringCompaniesAutocomplete.result.result,
          { id: 1, companyName: "Can't find my factoring company" },
        ];
      } else {
        return [{ id: 1, companyName: "Can't find my factoring company" }];
      }
    }
    return [];
  }, [factoringCompaniesAutocompleteFilter.search.length, factoringCompaniesAutocomplete]);

  const authoritiesOptions = useMemo(
    () =>
      authoritiesAutocompleteFilter.search.replaceAll(' ', '').length >= 3
        ? authoritiesAutocomplete?.result?.result
        : [],
    [authoritiesAutocompleteFilter.search.length, authoritiesAutocomplete]
  );

  return (
    <div ref={paymentTableRef}>
      {brokerPanelShowed ? (
        <ViewItemWrapper
          title={SUBJECT.PAYMENT_TERMS}
          id={SUBJECT.PAYMENT_TERMS_AND_BILLING}
          Component={
            <Button onClick={handleHideForm} type="text" variant="text" size="large">
              Not Interested
            </Button>
          }
          className={activeSection === SUBJECT.PAYMENT_TERMS_AND_BILLING ? 'active' : ''}
        >
          {((!paymentTermFM?.length && !paymentTerm?.length) || formOpened) && (
            <div ref={paymentFormRef}>
              <div style={{ marginTop: '20px' }}>
                <div>
                  <Row gutter={[19, 19]}>
                    <Col span={24}>
                      <SearchableDropdown
                        options={authoritiesOptions}
                        placeholder={SUBJECT.BROKER_AUTHORITY}
                        offset={authoritiesAutocomplete?.count}
                        setOffset={setAuthoritiesAutocompleteFilter}
                        values={watch('authorityId')}
                        columns={SEARCH_AUTHORITY_COLUMNS}
                        keys={AUTHORITY_KEYS}
                        onBlur={() => trigger('authorityId')}
                        rules={{ required: true }}
                        control={control}
                        name="authorityId"
                        showKey="id"
                        setValue={setValue}
                        required
                        withoutBorder
                        errors={errors}
                      />
                      <Row>
                        <Col>
                          {errors?.carrierFiles && (
                            <ErrorMessage
                              fieldError={{
                                message: allFiles?.carrierFiles?.length
                                  ? 'At least one file type is required'
                                  : 'File is required',
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {!!allFiles?.carrierFiles?.length && (
                    <Row gutter={[0, 22]}>
                      {allFiles.carrierFiles?.map((file: any) => (
                        <Col span={24}>
                          <UploadedFileRow
                            options={BROKER_FILES_OPTIONS}
                            id={file.id}
                            type={file.fileType}
                            name={file.fileName || file.name}
                            addedBy={file.fullName || file.createdBy}
                            addedAt={file.createdAt}
                            previewLink={file.uid || file.preview}
                            downloadLink={file.uid || file.download}
                            onChange={value => {
                              handleSelectFileType(value, file?.id, 'carrierFiles');
                              setTimeout(() => {
                                trigger('carrierFiles');
                              }, 0);
                            }}
                            onWatchClick={value => handleViewAuthorityFileCreate(value)}
                            onDownloadClick={value => handleDownloadAuthorityFileCreate(value)}
                            onDeleteClick={value => handleDeleteAuthorityFileCreate(value, 'carrierFiles')}
                            file={file}
                            mode={mode}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                  {filesByAuthorityId && (
                    <Row align="middle" gutter={[0, 22]}>
                      <Col span={8}>
                        <Typography variant="paragraph" className={styles.uploadTitle}>
                          Please review and sign documents
                        </Typography>
                      </Col>

                      {filesByAuthorityId.map((file: any) => (
                        <Col span={24}>
                          <Row align="middle" gutter={10}>
                            <Col span={5}>
                              <Typography
                                variant="paragraph"
                                children={'Sign agreement'}
                                className={styles.fileTypeHeading}
                              />
                            </Col>
                            <Col>
                              <Row align="middle" gutter={8}>
                                <Col role="button">
                                  <Button variant="ghost" onClick={() => handleViewFileClick(file?.preview)}>
                                    <EyeIconSvg outlined={true} />
                                  </Button>
                                </Col>
                                <Col role="button">
                                  <Button variant="ghost" onClick={() => handleDownloadClick(file?.download)}>
                                    <NoaDownloadIcon />
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                      <Col span={24}>
                        <Row gutter={[15, 0]} align={'middle'}>
                          <Col>
                            <Typography variant="paragraph" className={styles.bold} children={SUBJECT.IAGREE} />
                          </Col>
                          <Col>
                            <div className={styles.checkboxWrapper}>
                              <Checkbox
                                name="iAgree"
                                control={control}
                                rules={{ required: true }}
                                required
                                checked={watch('iAgree')}
                                size="small"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {filesByAuthorityId && errors?.iAgree && (
                    <Col span={24}>
                      <ErrorMessage fieldError={errors?.iAgree ? { message: 'Required Field' } : {}} />
                    </Col>
                  )}
                  <div className={styles.radioWrapper}>
                    <Typography variant="paragraph" className={styles.blockTitle}>
                      Payment method
                    </Typography>
                    <Row gutter={[19, 0]}>
                      <Col>
                        <Radio
                          name="factoringType"
                          control={control}
                          rules={{ required: true }}
                          value={watch('factoringType')}
                          onChange={e => {
                            setValue('factoringType', e.target.value, {
                              shouldDirty: true,
                            });
                            setValue('brokerPaymentTermType', 2);
                            setValue('day', '30');
                            setValue('percent', '0');
                          }}
                          options={[FACTORING_TYPE_OPTIONS[0]]}
                          className={styles.radio}
                        />
                      </Col>
                      <Col>
                        <Radio
                          name="factoringType"
                          control={control}
                          rules={{ required: true }}
                          value={watch('factoringType')}
                          onChange={e => {
                            setValue('agreement', []);
                            setAllFiles(() => ({
                              agreement: [],
                              carrierFiles: [],
                              factoringCompanyDocuments: [],
                              carrierFilesFreightmax: [],
                            }));
                            setValue('factoringType', e.target.value, {
                              shouldDirty: true,
                            });
                            setValue('factoredCompanyName', []);
                          }}
                          options={[FACTORING_TYPE_OPTIONS[1]]}
                          className={styles.radio}
                        />
                      </Col>
                    </Row>
                  </div>
                  {watch('factoringType') === CarrierFactoringType.FACTORED ? (
                    <Row gutter={10} align="middle" style={{ position: 'relative' }}>
                      <Col span={23}>
                        <SearchableDropdown
                          options={factoringCompaniesOptions}
                          placeholder={SUBJECT.FACTORING_COMPANY_NAME}
                          disabled={false}
                          offset={factoringCompaniesAutocomplete?.count}
                          setOffset={setFactoringCompaniesAutocompleteFilter}
                          values={watch('factoredCompanyName')}
                          columns={SEARCH_FACTORING_COMPANIES_COLUMNS}
                          keys={FACTORING_COMPANY_KEYS}
                          control={control}
                          name="factoredCompanyName"
                          showKey="companyName"
                          setValue={setValue}
                          required
                          onBlur={() => trigger('factoredCompanyName')}
                          rules={{ required: true }}
                          withoutBorder
                          errors={errors}
                        />
                      </Col>

                      <Col span={1} className={styles.icon}>
                        <FactoredPopover
                          data={factoringCompanyById?.result}
                          isFetching={isFetching}
                          disabled={!watch('factoredCompanyName')?.[0]?.id}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {!!(watch('factoredCompanyName')?.length && watch('factoredCompanyName')?.[0].id === 1) && (
                    <div style={{ marginTop: '22px' }}>
                      <Row align="middle" gutter={[20, 20]}>
                        <Col span={24}>
                          <Typography variant="paragraph" required className={styles.uploadTitle}>
                            Upload Factoring Company Documents
                          </Typography>
                          <Row align={'middle'} justify="space-between" style={{ marginBottom: 20 }}>
                            <Col span={10}>
                              <Upload
                                uploadText={SUBJECT.DRAG_AND_DROP}
                                className={styles.upload}
                                beforeUpload={(e: any, setLoading: any) =>
                                  beforeUploadForCreate(e, 'factoringCompanyDocuments', setLoading)
                                }
                              />
                            </Col>
                            <Col span={13}>
                              <Typography
                                className={styles.downloadFormats}
                                variant="paragraph"
                                children="Allowed format (jpeg, pdf, tiff, png)"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Col span={24}>
                    {!!allFiles.factoringCompanyDocuments.length &&
                      allFiles.factoringCompanyDocuments.map((file: any) => (
                        <Row align="middle" key={file.id} style={{ marginBottom: 20 }}>
                          <Col span={10}>
                            <Typography variant="paragraph" children={file?.name} className={styles.fileTypeHeading} />
                          </Col>
                          <Col>
                            <Row align="middle" gutter={8}>
                              <Col role="button">
                                <Button variant="ghost" onClick={() => handleViewAuthorityFileCreate(file)}>
                                  <EyeIconSvg outlined={true} />
                                </Button>
                              </Col>
                              <Col role="button">
                                <Button variant="ghost" onClick={() => handleDownloadAuthorityFileCreate(file)}>
                                  <NoaDownloadIcon />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </div>
                <div className={styles.paymentTypeWrapper}>
                  <div className={styles.paymentTypeRadios}>
                    {watch('factoringType') === CarrierFactoringType.NOT_FACTORED && (
                      <Row gutter={[19, 0]}>
                        <Col>
                          <Radio
                            name="paymentType"
                            control={control}
                            rules={{ required: true }}
                            value={watch('paymentType')}
                            options={[PAYMENT_TYPE_OPTIONS[0]]}
                            className={styles.radio}
                            onChange={e => {
                              setValue('paymentType', e.target.value, {
                                shouldDirty: true,
                              });
                              setValue('paymentCompanyName', '');
                              setValue('paymentAddress', '');
                              setValue('paymentSecondAddress', '');
                              setValue('paymentCity', '');
                              setValue('paymentStateId', '');
                              setValue('paymentZip', '');
                            }}
                          />
                        </Col>
                        <Col>
                          <Radio
                            name="paymentType"
                            control={control}
                            rules={{ required: true }}
                            value={watch('paymentType')}
                            options={[PAYMENT_TYPE_OPTIONS[1]]}
                            className={styles.radio}
                            onChange={e => {
                              setValue('paymentType', e.target.value, {
                                shouldDirty: true,
                              });
                              setValue('paymentBankName', '');
                              setValue('paymentRoutingNumber', '');
                              setValue('paymentAccountNumber', '');
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>

                  {watch('paymentType') === CarrierPaymentType.DIRECT_DEPOSIT &&
                  watch('factoringType') != CarrierFactoringType.FACTORED ? (
                    <Row gutter={[20, 20]}>
                      <Col span={8}>
                        <Input
                          name="paymentBankName"
                          control={control}
                          placeholder={SUBJECT.BANK_NAME}
                          getValues={getValues}
                          errors={errors}
                          rules={{ required: true }}
                          required
                          onChange={(e: any) =>
                            setValue('paymentBankName', e.target.value.trimStart(), {
                              shouldValidate: true,
                              shouldDirty: true,
                            })
                          }
                          onBlur={() => trigger('paymentBankName')}
                        />
                      </Col>
                      <Col span={8}>
                        <Input
                          name="paymentRoutingNumber"
                          control={control}
                          placeholder={SUBJECT.ROUTING_NUMBER}
                          onChange={(e: any) =>
                            setValue('paymentRoutingNumber', onlyNumbersFormatter(e.target.value), {
                              shouldDirty: true,
                              shouldValidate: true,
                            })
                          }
                          getValues={getValues}
                          onBlur={() => trigger('paymentRoutingNumber')}
                          errors={errors}
                          rules={{ required: true }}
                          required
                        />
                      </Col>
                      <Col span={8}>
                        <Input
                          name="paymentAccountNumber"
                          control={control}
                          onChange={(e: any) =>
                            setValue('paymentAccountNumber', onlyNumbersFormatter(e.target.value), {
                              shouldDirty: true,
                              shouldValidate: true,
                            })
                          }
                          onBlur={() => trigger('paymentAccountNumber')}
                          placeholder={SUBJECT.ACCOUNT_NUMBER}
                          getValues={getValues}
                          errors={errors}
                          rules={{ required: true }}
                          required
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {/* TODO */}
                  {watch('paymentType') === CarrierPaymentType.PAPER_CHECK &&
                  watch('factoringType') != CarrierFactoringType.FACTORED ? (
                    <Row gutter={[20, 20]}>
                      <Col span={8}>
                        <Input
                          name="paymentCompanyName"
                          control={control}
                          placeholder={SUBJECT.PAYMENT_COMPANY_NAME}
                          getValues={watch}
                          errors={errors}
                          rules={{ required: true }}
                          required
                          onBlur={() => trigger('paymentCompanyName')}
                          onChange={(e: any) =>
                            setValue('paymentCompanyName', e.target.value.trimStart(), {
                              shouldValidate: true,
                              shouldDirty: true,
                            })
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Input
                          name="paymentAddress"
                          control={control}
                          placeholder={SUBJECT.PAYMENT_ADDRESS}
                          getValues={getValues}
                          errors={errors}
                          rules={{ required: true }}
                          required
                          onBlur={() => trigger('paymentAddress')}
                          onChange={(e: any) =>
                            setValue('paymentAddress', e.target.value.trimStart(), {
                              shouldValidate: true,
                              shouldDirty: true,
                            })
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Input
                          name="paymentSecondAddress"
                          control={control}
                          placeholder={SUBJECT.SECOND_ADDRESS}
                          getValues={getValues}
                          errors={errors}
                          onChange={(e: any) =>
                            setValue('paymentSecondAddress', e.target.value.trimStart(), {
                              shouldValidate: true,
                              shouldDirty: true,
                            })
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Input
                          name="paymentCity"
                          control={control}
                          placeholder={SUBJECT.CITY}
                          getValues={watch}
                          errors={errors}
                          rules={{ required: true }}
                          onBlur={() => trigger('paymentCity')}
                          required
                          onChange={(e: any) =>
                            setValue('paymentCity', e.target.value.trimStart(), { shouldValidate: true })
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Controller
                          name="paymentStateId"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                            return (
                              <StateSelect
                                allowClear
                                options={statesData || []}
                                placeholder={SUBJECT.STATE}
                                name={field.name}
                                onBlur={() => trigger('paymentStateId')}
                                errors={errors}
                                onChange={(value: string) =>
                                  setValue('paymentStateId', value, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  })
                                }
                                value={field.value}
                                required
                              />
                            );
                          }}
                        />
                        <ErrorMessage fieldError={errors?.paymentStateId} />
                      </Col>
                      <Col span={8}>
                        <Input
                          name="paymentZip"
                          control={control}
                          placeholder={SUBJECT.ZIP_CODE}
                          getValues={getValues}
                          errors={errors}
                          rules={{ required: true }}
                          required
                          onBlur={() => trigger('paymentZip')}
                          onChange={(e: any) =>
                            setValue('paymentZip', onlyNumbersFormatter(e.target.value), {
                              shouldDirty: true,
                              shouldValidate: true,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  ) : null}
                </div>
                <div className={styles.brokerPaymentTerm}>
                  <Typography
                    children={SUBJECT.BROKER_PAYMENT_TERMS}
                    variant="paragraph"
                    className={styles.blockTitle}
                  />

                  <Row gutter={[0, 27]} align="top">
                    <Col>
                      <Radio
                        name="brokerPaymentTermType"
                        control={control}
                        rules={{ required: true }}
                        onChange={e => {
                          setValue('brokerPaymentTermType', e.target.value, {
                            shouldDirty: true,
                          });
                          setValue('day', 30, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          setValue('percent', 0, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          setAllFiles((prev: any) => ({
                            files: prev.files,
                            agreement: [],
                            carrierFiles: [],
                            factoringCompanyDocuments: [],
                            carrierFilesFreightmax: [],
                          }));
                        }}
                        value={watch('brokerPaymentTermType')}
                        options={DAY_OPTION}
                        className={styles.radio}
                      />
                    </Col>
                    <Col>
                      <Radio
                        name="brokerPaymentTermType"
                        control={control}
                        rules={{ required: true }}
                        onChange={e => {
                          setValue('brokerPaymentTermType', e.target.value, {
                            shouldDirty: true,
                          });
                          setValue('day', 3, { shouldDirty: true });
                          setValue('percent', 3.5, { shouldDirty: true });
                        }}
                        value={watch('brokerPaymentTermType')}
                        options={QUICK_PAY_OPTION}
                        className={styles.radio}
                        // disabled={watch('factoringType') === 1}
                      />
                    </Col>
                    <Col span={24}>
                      <Row gutter={12}>
                        <Col span={4}>
                          <Input
                            name="day"
                            control={control}
                            placeholder={SUBJECT.DAY}
                            disabled
                            getValues={watch}
                            onBlur={() => trigger('day')}
                            errors={errors}
                            rules={{
                              required: watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK,
                            }}
                            required={watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK}
                            onChange={(e: any) =>
                              setValue('day', onlyNumbersFormatter(e.target.value.trimStart()), {
                                shouldValidate: true,
                              })
                            }
                          />
                        </Col>
                        <Col span={4}>
                          <Input
                            name="percent"
                            control={control}
                            placeholder={SUBJECT.PERCENT}
                            disabled
                            getValues={watch}
                            errors={errors}
                            suffix={<PercentIcon />}
                            onBlur={() => trigger('percent')}
                            rules={{
                              required: watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK,
                            }}
                            required={watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK}
                            onChange={(e: any) =>
                              setValue('percent', onlyNumbersFormatter(e.target.value.trimStart()), {
                                shouldValidate: true,
                              })
                            }
                          />
                        </Col>
                        <Col span={16}>
                          <InputTags
                            control={control}
                            placeholder={SUBJECT.EMAIL}
                            getValues={watch}
                            setValue={setValue}
                            required
                            setIsEmailValid={setIsEmailValid}
                            editData={watch('brokerEmails')}
                            rules={{ required: true }}
                            name="brokerEmails"
                            errors={errors}
                            trigger={trigger}
                            setError={setError}
                            clearErrors={clearErrors}
                            isReseted={isReseted}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* ) : ( */}

              {/* )} */}
              {!!Object.keys(dirtyFields).length && isSubmitted && (
                <Col span={24}>
                  <ErrorMessage
                    fieldError={{
                      message: 'You need to add or cancel your changes , before submitting the form',
                    }}
                  />
                </Col>
              )}

              <Col span={24} style={{ marginTop: '20px' }}>
                <Row gutter={15} justify="end">
                  <Col>
                    <Button onClick={handleCancelPaymentTerm} variant="outlined">
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={handleAddPaymentTerm} variant="comment">
                      {editingId ? 'Save' : 'Add'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </div>
          )}
          <Row gutter={[20, 20]}>
            <Col span={24}>
              {!!paymentTermFM?.length && (
                <PaymentTermDataGrid
                  title={'Sign up with FreightMax'}
                  handleRowDelete={(values: any) => handleRowDelete(values, CarrierPaymentTermType.FREIGHTMAX)}
                  handleRowEdit={(values: any) => handleRowEdit(values, CarrierPaymentTermType.FREIGHTMAX)}
                  data={paymentTermFM}
                  localFiles={localFiles}
                  count={paymentTermFM?.length}
                  editingRow={editingId}
                  setEditingRow={setEditingRow}
                  serializedStates={serializedStates}
                  // isDeleteDisabled={mode === 'edit' && paymentTerm?.length === 1}
                />
              )}
            </Col>

            <Col span={24}>
              {!!paymentTerm?.length && (
                <PaymentTermDataGrid
                  title={'Sign up with Brokerage'}
                  handleRowDelete={(values: any) => handleRowDelete(values, CarrierPaymentTermType.BROKERAGE)}
                  handleRowEdit={(values: any) => handleRowEdit(values, CarrierPaymentTermType.BROKERAGE)}
                  data={paymentTerm}
                  localFiles={[]}
                  count={paymentTerm?.length}
                  // handlePaginationClick={handlePaginationClick}
                  editingRow={editingId}
                  setEditingRow={setEditingRow}
                  serializedStates={serializedStates}
                  // isDeleteDisabled={mode === 'edit' && paymentTerm?.length === 1}
                />
              )}
            </Col>
            {!((!paymentTermFM?.length && !paymentTerm?.length) || formOpened) && (
              <Col span={24}>
                <Row justify="end">
                  <Col span={2}>
                    <Col>
                      <Button onClick={handleOpenPaymentForm} variant="comment">
                        Add
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      ) : (
        <div className={styles.viewItemMainWrapper}>
          <div className={styles.whiteBlock}>
            <div className={styles.headerBlock}>
              <div className={styles.headerWithButtons}>
                <div>
                  <Typography
                    variant="heading"
                    className={styles.blockTitle}
                    children="Set Up with your broker through the FreightMax App"
                  />
                </div>

                <Button style={{ width: '243px', height: '40px' }} onClick={handleShowForm} type="primary">
                  Find My Broker
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentTermsSignUp;
