import React from 'react';
import { Col, Dropdown, Row } from 'antd';
import LocalSearchSelect from 'ui/inputs/local-search-select/LocalSearchSelect';
import Switch from 'ui/inputs/switch/Switch';

import MainDrawer from 'components/main-drawer/MainDrawer';
import MenuIcon from 'components/svgs/MenuIcon';
import Table from 'components/table/Table';

import { getPermissionListTableData, PERMISSION_LIST_TABLE_COLUMNS, SUBJECT } from './constants/constants';
import usePermissionList from './usePermissionList';

import styles from './PermissionList.module.scss';

const PermissionList = () => {
  const {
    items,
    currentChildren: { component, buttonText, withFooter, onSubmit, onCancel },
    handlePermissionEdit,
    handleRowClick,
    permissionGroupsData,
    isFetchingGroups,
    permissionNameFilter,
    permissionGroupById,
    handleSwitchClick,
    setFilterValue,
    searchParams,
    setSearchParams,
    terminateSwitch,
    isGroupSelectChanged,
    setIsGroupSelectChanged,
    type,
  } = usePermissionList();

  return (
    <>
      <div className={styles.permissionListContainer}>
        <div className={styles.permissionListTopBlock}>
          {!isFetchingGroups && (
            <Row justify="space-between" align="middle">
              <Col span={6}>
                <div className={styles.permissionListMainSelect}>
                  <div className={styles.selectWrapper}>
                    <LocalSearchSelect
                      options={permissionGroupsData?.result.map((el: any) => ({
                        value: `${el.id}`,
                        title: el.name,
                      }))}
                      placeholder={SUBJECT.NOT_SELECTED}
                      label={SUBJECT.PERMISSION_GROUP}
                      name="permissionListType"
                      onChange={(selectedValue: any) => {
                        setIsGroupSelectChanged(true);
                        setSearchParams({ ...searchParams, permissionNameFilter: selectedValue || '' });
                        setFilterValue('permissionListType', selectedValue);
                      }}
                      value={permissionNameFilter}
                      allowClear
                    />
                  </div>
                </div>
              </Col>
              <Col>
                {!!permissionNameFilter && (
                  <div className={styles.permissionTopRight}>
                    <div className={styles.permissionSwitch}>
                      <span className={styles.subHeading}>{permissionGroupById?.name}</span>
                      <Switch checked={terminateSwitch} onClick={handleSwitchClick} />
                    </div>
                    <div>
                      <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
                        <div className={styles.dotsItem}>
                          <MenuIcon />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          )}
        </div>
        {permissionNameFilter && (
          <Table
            isAuto
            data={{ result: getPermissionListTableData(Number(type))! }}
            columns={PERMISSION_LIST_TABLE_COLUMNS}
            isLoading={isFetchingGroups || isGroupSelectChanged}
            handleRowEdit={(rowId: number) => handlePermissionEdit(rowId)}
            onRowClick={handleRowClick}
            withMenu={true}
            hasEditPermission
          />
        )}
        <MainDrawer
          Children={component}
          buttonText={buttonText}
          onCancel={onCancel}
          onSubmit={onSubmit}
          withFooter={withFooter}
        />
      </div>
    </>
  );
};

export default PermissionList;
