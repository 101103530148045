import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useGetCoordinatorsAutocompleteQuery,
  useGetVerificationListQuery,
} from 'services/truck-board/verification-board/verification';
import { useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';

import { UserTypes } from '../../../../utils/constants';

import { filterDefaultValues, SUBJECT } from './constants/constants';
import { IVerificationBoardFilter, IVerificationBoardFilterState } from './constants/types';

const useVerificationBoard = (setExcelParams: any) => {
  const userInfo = useSelector(selectUserInfo);
  const { userType } = useSelector(selectUserPermissionsInfo);

  const {
    control: filterControl,
    reset: filterReset,
    watch: filterWatch,
    setValue: filterSetValue,
  } = useForm<any>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const { searchParams, setSearchParams } = useDetectedParams();
  const { carrierStatus, coordinator, open, tab, mode } = searchParams;

  const [verificationBoardFilter, setVerificationBoardFilter] = useState<IVerificationBoardFilterState>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'verificationAt',
    filter: {},
  });

  useEffect(() => {
    if (setExcelParams) setExcelParams(verificationBoardFilter);
  }, [verificationBoardFilter]);

  const [coordinatorsFilter, setCoordinatorsFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [selectedCoordinators, setSelectedCoordinators] = useState<any>([]);
  const [finalCoordinatorsOptions, setFinalCoordinatorsOptions] = useState<any>({ result: [], count: 0 });

  const { data: coordinatorsOptions = { result: [], count: 0 }, isFetching: _isCoordinatorsFetching } =
    useGetCoordinatorsAutocompleteQuery(coordinatorsFilter);

  useEffect(() => {
    if (Array.isArray(filterWatch('coordinator'))) {
      setSelectedCoordinators((prev: any) =>
        [
          ...prev.filter((el: any) => !coordinatorsOptions?.result?.some((item: any) => item.value == el.value)),
          ...coordinatorsOptions.result,
        ].filter((el: any) => filterWatch('coordinator')?.some((item: any) => item == el.value))
      );
    }
  }, [filterWatch('coordinator'), coordinatorsOptions.result?.length]);

  useEffect(() => {
    if (coordinatorsOptions?.result) {
      setFinalCoordinatorsOptions({
        result: [
          ...selectedCoordinators,
          ...coordinatorsOptions.result.filter(
            (el: any) => !selectedCoordinators.some((item: any) => item.value == el.value)
          ),
        ],
        count: coordinatorsOptions.count,
      });
    } else {
      setFinalCoordinatorsOptions(selectedCoordinators);
    }
  }, [selectedCoordinators, coordinatorsOptions?.result?.length]);

  const { data: columns } = useGetColumnsQuery({ type: 'verificationBoard' });
  const [editColumns] = useEditColumnsMutation();

  const { data: verificationList, isFetching: isVerificationListFetching } = useGetVerificationListQuery(
    verificationBoardFilter,
    {
      skip: tab !== 'verification_board',
    }
  );

  const carrierStatusFilter = filterWatch(SUBJECT.CARRIER_STATUS_FILTER);
  const coordinatorFilter = filterWatch(SUBJECT.COORDINATOR_FILTER);

  useEffect(() => {
    if (!verificationList?.result.length && verificationBoardFilter?.skip > 0) {
      setVerificationBoardFilter((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [verificationList?.result.length]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(carrierStatusFilter ? { carrierStatus: carrierStatusFilter } : {}),
      ...(coordinatorFilter.length
        ? { coordinator: Array.isArray(coordinatorFilter) ? coordinatorFilter?.join(',') : '' }
        : { coordinator: '' }),
    });
  }, [coordinatorFilter?.length, carrierStatusFilter]);

  useEffect(() => {
    const filterOptions = [
      { key: 'carrierStatus', value: carrierStatus },
      { key: 'coordinator', value: coordinator ? coordinator?.split(',') : coordinator },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        filterSetValue(key as keyof IVerificationBoardFilter, value);
      }
      setVerificationBoardFilter((prev: any) => ({
        ...prev,
        skip: 0,
        filter: {
          ...prev.filter,
          status: carrierStatus,
          coordinatorIds: coordinator?.split(','),
        },
      }));
    });
  }, [carrierStatus, coordinator]);

  const handleResetSearchResultFilter = () => {
    filterReset({
      carrierStatus: null,
      coordinator: [],
    });
    setSearchParams({
      mode,
      tab,
      open,
    });
    setVerificationBoardFilter({
      search: '',
      field: '',
      skip: 0,
      limit: 20,
      order: 2,
      orderBy: 'verificationAt',
      filter: {},
    });
  };

  const handleRowClick = (rowId: number | string, rowData: any) => {
    setSearchParams({
      ...searchParams,
      mode: 'carrierTruckView',
      open: 'true',
      truckId: String(rowId),
      carrierId: String(rowData?.carrierId),
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setVerificationBoardFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'verificationAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setVerificationBoardFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };
  const handlePaginationClick = (skip: number, limit: number) => {
    setVerificationBoardFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleRowEdit = (rowId: number | string, rowData: any) => {
    setSearchParams({
      ...searchParams,
      mode: 'carrierTruckEdit',
      open: 'true',
      truckId: String(rowId),
      carrierId: String(rowData?.carrierId),
    });
  };

  const handleStopResize = (columns: any): void => {
    editColumns({ type: 'verificationBoard', columns });
  };

  const coordinators = useMemo(() => {
    if (finalCoordinatorsOptions?.result?.length) {
      return {
        result: [
          ...(userType !== UserTypes.ADMIN
            ? [
                {
                  id: userInfo?.id,
                  title: SUBJECT.MINE,
                  value: String(userInfo.id),
                },
              ]
            : []),
          ...(finalCoordinatorsOptions?.result?.filter((el: any) => el?.id !== userInfo?.id) || []),
        ],
        count: finalCoordinatorsOptions?.count,
      };
    } else {
      return { result: [], count: 0 };
    }
  }, [finalCoordinatorsOptions?.result?.length]);

  return {
    verificationList,
    isVerificationListFetching,
    columns: columns?.columns,
    filterControl,
    filterWatch,
    filterSetValue,
    coordinators,
    handleResetSearchResultFilter,
    setVerificationBoardFilter,
    setCoordinatorsFilter,
    handleSortClick,
    handleRowClick,
    handleStopResize,
    handleColumnSearch,
    handlePaginationClick,
    handleRowEdit,
    skip: verificationBoardFilter?.skip,
    limit: verificationBoardFilter?.limit,
  };
};
export default useVerificationBoard;
