export enum SUBJECT {
  CREDIT_CHECK_IS_EMPTY = 'Credit Check Is Empty',
  SAVE = 'Save',
  CANCEL = 'Cancel',
  ARE_YOU_SURE = 'Are You Sure Want Do Delete?',
  DELETE = 'Delete',
  NO = 'No',
  YES = 'Yes',
  CLOSE = 'Close',
  REQUEST = 'request',
}
export const MODAL_OPTIONS = {
  Approve: {
    type: 'Approve',
    itemContent: 'Are You Sure You Want To Approve?',
    buttonText: 'Approve',
    buttonType: 'primary',
  },
  Deny: {
    type: 'Deny',
    itemContent: 'Are You Sure You Want To Deny Credit?',
    buttonText: 'Deny',
    buttonType: 'danger',
  },
  COD: {
    type: 'COD',
    itemContent: 'The Credit Will Be Approved Under The Provisions Of COD. Cod Stands For Cash On Delivery',
    buttonText: 'COD',
    buttonType: 'primary',
  },
  Consignment: {
    type: 'Consignment',
    itemContent: 'This Will Approve The Credit Under The Provision Of Consignment',
    buttonText: 'Consignment',
    buttonType: 'primary',
  },
};
