import { CustomerMoneyReceiveType, ICustomerBalkPayRequest } from './interface';

export const customerReceiveDeserializer = ({
  moneyType,
  moneyCode,
  numberOrTransactionId,
  amount,
  loadIds,
}: ICustomerBalkPayRequest) => {
  return {
    loadIds: loadIds?.map(el => Number(el)),
    moneyType: CustomerMoneyReceiveType[moneyType as CustomerMoneyReceiveType],
    ...(loadIds?.length > 1 ? {} : { amount: Number(amount) }),
    numberOrTransactionId: Number(numberOrTransactionId),
    ...(moneyCode?.toString().length ? { moneyCode } : {}),
  };
};
