import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';
import { emailPattern } from 'utils/regexp';

import CloseIcon from 'components/svgs/CloseIcon';

import Button from '../../button/Button';

import { SUBJECT } from './constants/constants';
import { IInputTagsProps } from './constants/types';

import styles from './InputTags.module.scss';

const InputTags = forwardRef(
  (
    {
      placeholder,
      setValue,
      editData,
      setIsEmailValid,
      trigger,
      required = false,
      setError,
      clearErrors,
      name,
      isReseted = false,
    }: IInputTagsProps,
    ref: ForwardedRef<HTMLDivElement | HTMLInputElement>
  ) => {
    const [input, setInput] = useState<string>('');
    const [tags, setTags] = useState<string[]>(editData || []);

    const [isValid, setIsValid] = useState<boolean>(false);

    const [inFocus, setInFocused] = useState(false);

    const onAddTag = () => {
      if (isValid && !tags.includes(input)) {
        setTags(prevState => [...prevState, input]);
        setInput('');
        setIsValid(false);
        setInFocused(false);
        clearErrors(name || 'email');
      }
    };

    useEffect(() => {
      if (isReseted) {
        setInput('');
      }
    }, [isReseted]);
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;

      if (key === 'Enter' && isValid && !tags.includes(input)) {
        e.preventDefault();
        setTags(prevState => [...prevState, input]);
        setInput('');
        setIsValid(false);
      }
    };

    const handleBlur = () => {
      setInFocused(false);
      setTimeout(() => trigger(name || 'email'), 0);
    };

    const handleFocus = () => {
      setInFocused(true);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setInput(value.trimStart());

      if (value.match(emailPattern)) {
        setIsEmailValid(true);
        setIsValid(true);
      } else {
        setIsEmailValid(false);
        setIsValid(false);
      }
    };

    const deleteTag = (index: number) => {
      setTags(prevState => prevState.filter((tag, i) => i !== index));
      setTimeout(() => trigger(name || 'email'), 0);
    };

    useEffect(() => {
      if (editData) {
        setTags(editData);
      }
    }, [editData]);

    useEffect(() => {
      if (setValue) {
        setValue(name || 'email', tags);
      }
    }, [tags.length]);

    useEffect(() => {
      if (input.length) {
        clearErrors(name || 'email');
      }
    }, [tags.length, input.length, inFocus]);

    return (
      <>
        <div
          className={clsx(styles.tagsInputContainer, {
            [styles.filled]: tags.length,
          })}
          ref={ref}
        >
          <div className={styles.tagItemsWrapper}>
            {tags?.map((tag, index) => (
              <div className={clsx(styles.tag, styles.wrapperItem)} key={tag}>
                {tag}
                <Button variant="text" children={<CloseIcon />} onClick={() => deleteTag(index)} />
              </div>
            ))}
            <div className={clsx(styles.inputWrapper, styles.wrapperItem)}>
              <input
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={input}
                onKeyDown={onKeyDown}
                onChange={onChange}
              />

              {isValid && (
                <div className={styles.inviteValidItem} onClick={onAddTag}>
                  {SUBJECT.SELECT} {input}
                </div>
              )}
            </div>
          </div>
          <Typography
            variant="paragraph"
            className={clsx(styles.placeholder, {
              [styles.filled]: tags.length || input,
              [styles.required]: required,
            })}
          >
            {placeholder}
          </Typography>
        </div>
        {!isValid && !!input.length && !inFocus && (
          <Typography variant="paragraph" className={styles.errorMessage}>
            {'Invalid email format'}
          </Typography>
        )}
      </>
    );
  }
);

export default InputTags;
