import React, { useMemo } from 'react';
import { Table as AntdTable } from 'antd';
import clsx from 'clsx';

import { ICarrierPaymentBreakDownProps } from './constants/types';
import { useCarrierPaymentBreakdown } from './useCarrierPaymentBreakdown';

import styles from './CarrierPaymentBreakdown.module.scss';

const CarrierPaymentBreakdown = ({
  data,
  skip,
  limit,
  isLoading,
  onSort,
  onPaginationClick,
  withPagination,
}: ICarrierPaymentBreakDownProps) => {
  const { pageSizeOptions, renderColumns, paginationArrows } = useCarrierPaymentBreakdown({
    onSort,
    onPaginationClick,
    data: data?.result,
  });
  const paginationOptions = useMemo(() => {
    return data?.count && data?.count > 10 && withPagination
      ? {
          current: Math.floor(skip! / limit!) + 1,
          pageSize: limit,
          onChange: (page: number, pageSize: number) => {
            onPaginationClick((page - 1) * pageSize, pageSize);
          },
          showTotal(total: number, [from, to]: number[]) {
            return <span>{`${from}-${to} of ${total}`}</span>;
          },
          showSizeChanger: true,
          pageSizeOptions: pageSizeOptions(data?.count),
          locale: { items_per_page: '' },
          itemRender: paginationArrows,
          total: data?.count,
        }
      : false;
  }, [data?.count, withPagination, skip, limit]);

  return (
    <div className={clsx(styles.tableWrapper)}>
      <AntdTable
        rowKey="id"
        tableLayout="auto"
        pagination={paginationOptions}
        loading={isLoading}
        columns={renderColumns}
        dataSource={data?.result}
        scroll={{ x: '100%' }}
      />
    </div>
  );
};

export default CarrierPaymentBreakdown;
