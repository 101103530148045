import { useEffect, useState } from 'react';

export const useTimeZoneDetector = (currentDate: any) => {
  const [timeZone, setTimeZone] = useState('');
  const [timeZoneOffset, setTimeZoneOffset] = useState(0);

  const date = new Date(Number(currentDate));
  const timeZoneOffsetMinutes = date.getTimezoneOffset();

  const offset = timeZoneOffsetMinutes / 60;
  const isPositive = timeZoneOffsetMinutes < 0;

  useEffect(() => {
    const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(detectedTimeZone);
    setTimeZoneOffset(isPositive ? Math.abs(offset) : offset);
  }, [currentDate]);

  return [timeZone, timeZoneOffset];
};
// usage
// const [timeZone,timeZoneOffset] = useTimeZoneDetector('1691744400000');

export const sumHoursToTimestamp = (timestamp: string, hoursToAdd: number): number => {
  const date = new Date(Number(timestamp));
  date.setHours(date.getHours() + hoursToAdd);
  return date.getTime();
};
// usage
// sumHoursToTimestamp('1691744400000', 13);

export const subTractHoursFromTimestamp = (timestamp: string, hoursForSubTract: number): number => {
  const date = new Date(+timestamp);
  date.setHours(date.getHours() - hoursForSubTract);
  return date.getTime();
};
// usage
// subTractHoursToTimestamp('1691744400000', 13);

//use only when you want to show user date disables or dates
export const changeTimezoneToUs = (date: any) => {
  const formattedDate = new Date(
    date.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    })
  );
  const diff = date.getTime() - formattedDate.getTime();

  return new Date(date.getTime() - diff);
};
