import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { capitalizeWords } from '../../../../../../utils/helpers';
import { permissionDeserializer, permissions, SUBJECT } from '../../constants/constants';

import { useView } from './useView';

import styles from './View.module.scss';

export const View = ({ data, isFethingById, rowName }: any) => {
  const { handleChangeMode } = useView();

  const { agentType, userType } = useSelector(selectUserPermissionsInfo);
  const type = userType === 1 ? 0 : agentType;

  const currentData = data?.[rowName];

  const allowedRows = permissions(Number(type))?.[rowName]?.map((el: any) => el?.name);
  const allowedPermissionsRows = currentData?.filter((el: any) => {
    return (
      allowedRows?.includes(permissionDeserializer?.[el?.permissionName as keyof typeof permissionDeserializer]) ||
      allowedRows?.includes(
        capitalizeWords(permissionDeserializer?.[el?.permissionName as keyof typeof permissionDeserializer])
      ) ||
      (userType !== 1 && el.permissionName === 'newLoad') ||
      (userType === 1 && el.permissionName === 'mcCustomer')
    );
  });

  return (
    <form className={styles.permissionContainer}>
      <MainLoader isFetching={isFethingById} />
      <ViewItemWrapper
        title={SUBJECT.PERMISSION}
        Component={
          <Button children={SUBJECT.EDIT} size="large" onClick={handleChangeMode} className="viewDrawerEditBtn" />
        }
      >
        <Row gutter={[10, 26]}>
          {allowedPermissionsRows?.map((el: any) => {
            return (
              <Col span={24} key={el.permissionName}>
                <Row gutter={[10, 24]} align="middle">
                  <Col>
                    <Typography
                      variant="heading"
                      children={`${capitalizeWords(permissionDeserializer[el.permissionName])}:`}
                      className="top-banner-title"
                    />
                  </Col>
                  <Col span={19}>
                    <Typography variant="paragraph" className={clsx('top-banner-value', styles.permissionItems)}>
                      {el.existingPermissions.map((el: any) => {
                        return <span>{permissionDeserializer[el]}</span>;
                      })}
                    </Typography>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </ViewItemWrapper>
    </form>
  );
};
