import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Typography from 'ui/typography/Typography';

import { allRoutes } from 'components/routes/routes';
import HomeArrowIcon from 'components/svgs/home-page-icons/HomeArrowIcon';

import { getHomeCase, SUBJECT } from './constants/constants';

import styles from './Home.module.scss';

const HomePage = () => {
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);

  const homeCase = getHomeCase();
  const allowedRoutes = useMemo(() => {
    if (permissionsInfo?.routes) {
      return allRoutes.filter((route: string) => permissionsInfo.routes.includes(route));
    }
  }, [permissionsInfo?.routes]);
  return (
    <div className={styles.homePageWrapper}>
      <Typography variant="heading" level={1} className={styles.pageTitle}>
        {SUBJECT.PAGE_TITLE}
      </Typography>
      <Row gutter={[13, 18]}>
        {allowedRoutes?.map((route: any) => (
          <Col key={homeCase[route as keyof typeof homeCase]?.id} xl={12} xxl={8}>
            <Link
              to={homeCase[route as keyof typeof homeCase]?.path}
              className={clsx(styles.singleItem, {
                [styles.disabledItem]: !homeCase[route as keyof typeof homeCase]?.path?.includes('/'),
              })}
            >
              <div className={styles.itemHeader}>
                <div className={styles.headerLeft}>
                  <div className={styles.iconWrapper}>{homeCase[route as keyof typeof homeCase]?.icon()}</div>
                  <Typography variant="heading" level={2} className={styles.itemTitle}>
                    {homeCase[route as keyof typeof homeCase]?.name}
                  </Typography>
                </div>
                <div className={styles.headerRight}>
                  <HomeArrowIcon />
                </div>
              </div>
              <div className={styles.itemBody}>
                <Typography variant="paragraph">{homeCase[route as keyof typeof homeCase]?.description}</Typography>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default HomePage;
