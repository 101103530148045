import { emailPattern, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';
import { AuthorityTypes } from './types';

const {
  NAME_REQUIRED,
  AUTHORITY_TYPE_REQUIRED,
  MC_NUMBER_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  STATE_ID_REQUIRED,
  ZIP_REQUIRED,
  FILE_REQUIRED,
  BROKER_FILE_REQUIRED,
  MIN_OPTION,
  MIN_NUMBER,
  VALID_NUMBER,
  PHONE,
  INCORRECT_FAX,
  MAX_LENGTH,
  ADDRESS_MAX_LENGTH,
  DOT_REQUIRED,
  PACKAGE_REQUIRED,
  MAX_MIN_ZIP_LENGTH,
  EMAIL_REQUIRED,
  INVALID_EMAIL,
  TEMPLATE_REQUIRED,
  RATE_CONFIRMATION_PRIVACY_POLICY_REQUIRED,
} = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  name: yup.string().required(NAME_REQUIRED),
  authority_type: yup.string().required(AUTHORITY_TYPE_REQUIRED),
  dot: yup.string().required(DOT_REQUIRED),
  bankName: yup.string(),
  accounting_number: yup.string(),
  ach_routing_number: yup.string(),
  mc: yup.string().typeError(VALID_NUMBER).required(MC_NUMBER_REQUIRED).min(0, MIN_NUMBER),
  phone: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBER_REQUIRED).matches(phoneRegExp, PHONE),
  fax: yup
    .string()
    .nullable()
    .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
      if ((value && value.length === 12) || !value) {
        return true;
      } else if (!value?.match(phoneRegExp)) {
        return this.createError({
          message: INCORRECT_FAX,
          path: 'fax',
        });
      } else {
        return this.createError({
          message: MAX_LENGTH,
          path: 'fax',
        });
      }
    }),
  address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
  city: yup.string().required(CITY_REQUIRED),
  stateId: yup.string().required(STATE_ID_REQUIRED),
  zip: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
  email: yup.string().email(INVALID_EMAIL).required(EMAIL_REQUIRED).matches(emailPattern, INVALID_EMAIL),
  files: yup
    .array()
    .required(FILE_REQUIRED)
    .test('length', FILE_REQUIRED, function checkLength(arr = []) {
      return !!arr?.length;
    }),
  broker: yup.array().test('length', BROKER_FILE_REQUIRED, function checkLength(arr = []) {
    if (Number(this.parent.authority_type) === AuthorityTypes.Broker) {
      return !!arr?.length;
    } else return true;
  }),
  physical: yup.boolean(),
  packet: yup
    .array()
    .required(PACKAGE_REQUIRED)
    .test('length', PACKAGE_REQUIRED, function checkLength(arr = []) {
      return !!arr?.length;
    }),
  physicalAddress: yup.string().when('physical', {
    is: (value: string) => !!value,
    then: schema => schema.required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
    otherwise: schema => schema.notRequired(),
  }),
  physicalCity: yup.string().when('physical', {
    is: (value: string) => !!value,
    then: schema => schema.required(CITY_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  physicalStateId: yup.string().when('physical', {
    is: (value: string) => !!value,
    then: schema => schema.min(1, MIN_OPTION).required(STATE_ID_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  physicalZip: yup.string().when('physical', {
    is: (value: string) => !!value,
    then: schema => schema.required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
    otherwise: schema => schema.notRequired(),
  }),
  templateId: yup.string().test('isTemplateRequired', TEMPLATE_REQUIRED, function checkIsTemplateReq(value) {
    if (Number(this.parent.authority_type) === AuthorityTypes.Carrier) {
      return true;
    } else {
      return !!value?.length;
    }
  }),
  privacyPolicy: yup.string().when('authority_type', {
    is: (value: string) => Number(value) === AuthorityTypes.Broker,
    then: schema => schema.min(1, RATE_CONFIRMATION_PRIVACY_POLICY_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
});
