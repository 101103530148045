export enum SUBJECT {
  TONU_LOAD = 'Tonu Load',
  FACILITY = 'Facility',
  UPLOADED_RED_CONFIRMATION = 'Uploaded Rate Confirmation',
  UPLOADED_POD = 'Uploaded POD',
  TAKEN_AS_MC = 'Taken As MC',
  LOAD_ID = 'Load ID',
  LOAD_STATUS = 'Load Status',
  OFFICE = 'Agency',
  OFFICE_ID = 'Agency ID',
  BRANCH = 'Branch',
  QUICK_PAY = 'Quick Pay',
  BRANCH_ID = 'Branch ID',
  AGENT = 'Agent',
  PICKUPS = 'Pick Ups',
  DROP_OFFS = 'Drop Offs',
  AGENT_ID = 'Agent ID',
  CUSTOMER_NAME = 'Customer Name',
  CUSTOMER_ID = 'Customer ID',
  CARRIER_NAME = 'Carrier Name',
  CARRIER_ID = 'Carrier ID',
  LOAD_TYPE = 'Load Type',
  REFERENCE_NUMBER = 'Reference Number',
  CUSTOMER_AMOUNT = 'Customer Amount',
  CUSTOMER_CONTACT = 'Phone Number',
  CARRIER_AMOUNT = 'Carrier Rate',
  TRUCK = 'Truck',
  ALL_AUTHORITIES = 'All Authorities',
  CARRIER = 'Carrier Detail',
  CREATE_RATE = 'Create New Carrier Rate Confirmation',
  CARRIER_RATE = 'Carrier Rate',
  PROFIT = 'Profit',
  SOLID_AS_MC = 'Solid as MC',
  FACTORED = 'Factored',
  CUSTOMER_DEBT = 'Customer Debt',
  REFRIGERATOR_CELSIUS = 'Refrigerator Fahrenheit',
  CUSTOMER_INVOICE_PAYMENT_AMOUNT = 'Customer Invoice Payment Amount',
  CUSTOMER_INVOICE = 'Customer Invoice',
  CARRIER_PAYMENT_BREAKDOWN = 'Carrier Payment Breakdown',
  COMMENTS = 'Comments',
  EDIT = 'Edit',
  DOCUMENTATION = 'Documents',
  LOAD_FINANCE = 'Load Finance',
  CUSTOMER_BALANCE = 'Balance',
  INITIAL_RATE_CUSTOMER = 'Initial Rate',
  FINAL_RECEIVABLE_CUSTOMER = 'Final Receivable',
  AMOUNT_PAID_CUSTOMER = 'Amount Received',
  CARRIER_BALANCE = 'Balance',
  INITIAL_RATE_CARRIER = 'Initial Rate',
  FINAL_PAYABLE_CARRIER = 'Final Payable',
  AMOUNT_PAID_CARRIER = 'Amount Paid',
  AGENT_BALANCE = 'Initial Profit',
  FINAL_PROFIT = 'Final Profit',
  TRANSACTION = 'Transaction',
  TAKEN_AS = 'Taken As',
  LOAD_INFORMATION = 'Load Detail',
  GIVEN_AS = 'Given As',
  COMMODITY = 'Commodity',
  BOOKED_AS = 'Booked As',
  SOLD_AS = 'Sold As',
  WEIGHT = 'Weight',
  FEET_OF_PARTIAL = 'Feet Of Partial',

  PICKUPS_AND_DROP_OFFS = 'Pick Ups & Drop Offs',
  CONNECTING_LOAD = 'Connecting load',
  ADDITIONAL = 'Additional',
  AGENT_AMOUNT = 'Agent Amount',
  CONNECTING_LOADS = 'Connecting Loads',
  ADDED_BY = 'Added By',
  DATE = 'Date',
  DATE_AND_TIME = 'Date And Time',
  USERNAME = 'Username',
  CUST_AMOUNT = 'Customer Amount',
  BALANCE_AMOUNT = 'Balance Amount',
  COMMENT = 'Comment',
  ADDRESS = 'Address',
  CITY = 'City',
  ZIP_CODE = 'ZIP Code',
  STATE = 'State',
  ARE_YOU_SURE_CLAIM = 'Are You Sure You Want To Mark As Claim?',
  ARE_YOU_SURE_LOAD = 'Are You Sure You Want To Cancel Load?',
  YES = 'Yes',
  NO = 'No',
  CANCEL = 'Cancel',
  CONFIRMATION_TYPE = 'Confirmation Type',
  FIRST_AGENCY = 'First Agency',
  FIRST_AGENCY_ID = 'First Agency ID',
  FIRST_BRANCH = 'First Branch',
  FIRST_BRANCH_ID = 'First Branch ID',
  FIRST_AGENT = 'First Agent',
  FIRST_AGENT_ID = 'First Agent ID',
  SECOND_AGENCY = 'Second Agency',
  SECOND_AGENCY_ID = 'Second Agency ID',
  SECOND_BRANCH = 'Second Branch',
  SECOND_BRANCH_ID = 'Second Branch ID',
  SECOND_AGENT = 'Second Agent',
  SECOND_AGENT_ID = 'Second Agent ID',
  HISTORY = 'History',
  SECOND_AGENT_AMOUNT = 'Second Agent Amount',
  FACTORED_YES_NO = 'Factored Yes/No',
  CUSTOMER_IS_FACTORED = 'Customer Is Factored',
  CUSTOMER_IS_NOT_FACTORED = 'Customer Is Not Factored',
  LOAD_TRANSACTION = 'Transactions',
  CUSTOMER = 'Customer Detail',
  FIRST_AGENT_HEADER = 'Agent 1',
  RED_CONFIRMATION = 'RED_CONFIRMATION',
  SECOND_AGENT_HEADER = 'Agent 2',
  GENERAL_INFO = 'General Information',
  CONNECTING_ID = 'connecting-load',
  VIEW_SECOND_AGENT_ID = 'second-agent-id',
  VIEW_CUSTOMER_ID = 'customer-id',
  VIEW_CARRIER_ID = 'carrier-id',
  BILLING_ID = 'billing-id',
  RATE_CONFIRMATION_ID = 'rate-confirmation-id',
  CONFIRMATION_DOCUMENTATION_ID = 'confirmation-documentation-id',
  LOAD_FINANCE_ID = 'load-finance',
  TRANSACTION_ID = 'transaction-id',
  LOAD_INFORMATION_ID = 'load-information-id',
  VIEW_PICKUPS_DROPOFFS_ID = 'pickups-dropoffs-id',
  VIEW_BREAKDOWN_ID = 'breakdown-id',
  CARRIER_RATE_CONFIRMATION_ID = 'carrier-rate-confirmation-id',
}
