import { ILoadFile } from 'services/loads/interface';

export interface ITransactions {
  id: number;
  transactionType: string;
  label: string;
  dateAndTime: string;
  addedBy: string;
  customerAmount: string;
  carrierAmount: string;
  comment?: string;
  deletedAt?: number;
  deletedBy?: number;
  deletor: any;
}

export interface IFilterValues {
  date: null | string;
  loadsOffice: any;
  loadsBranch: string[];
  loadsAgent: any;
  loadsByType: string[] | string;
  document: string[];
  loadStatus: string[];
}

export interface ILoadTableColumns {
  title: string;
  id: string;
  dataIndex: string;
  displayType: string;
  isVisible: boolean;
  sortable: boolean;
  width: number;
  isSearchable?: boolean;
}

export interface ILoadTableData {
  id: number;
  redConfirmation: string;
  uploadedPOD: string;
  regDateAndTime: string;
  loadId: string;
  loadStatus: string[];
  agency: string;
  agencyId: number;
  branch: string;
  branchId: number;
  agent: string;
  agentId: number;
  customerName: string;
  customerId: number;
  customerRef: number;
  carrierName: string;
  carrierId: number;
  loadType: string;
  firstPickupCity: string;
  firstPickupState: string;
  firstPickupDate: string;
  lastDeliveryCity: string;
  lastDeliveryState: string;
  lastDeliveryDate: string;
  customerRate: string;
  carrierRate: string;
  profit: string;
  soldAsMc: string;
  factored: string;
  customerDebt: string;
  customerInvoice: string;
  carrierPayment: string;
  comments_count: number;
  isClaim: boolean;
}

export interface ILoadProps {
  transactions?: {
    result: {
      type: string;
      label: number;
      createdAt: number;
      addedBy: string;
      username: string;
      customerAmout: number;
      carrierAmout: number;
      balance: number;
      id: number;
      comment: string;
    }[];
    count: number;
  };
  data?: any;
  files?: { result: { [key: string]: ILoadFile[] }; count: number };
  points?: any;
  isFetching?: boolean;
  handleCommentClick?: () => void;
  refetchLoadById?: any;
  comments: any;
  getMoreComments: any;
  isShowMoreVisible: any;
  loadsPagePermissions: {
    addPickUpDropOff: boolean;
    cancel: boolean;
    claim: boolean;
    deleteComment: boolean;
    editPickUpDropOff: boolean;
    editReferenceNumber: boolean;
    editSoldAsMc: boolean;
    filesDelete: boolean;
    resolve: boolean;
    sendToBilling: boolean;
    transaction: boolean;
    transactionDelete: boolean;
    uploadFiles: boolean;
  };
  hasEditPermission: boolean;
  serializedStates?: any;
  loadHistory?: any;
  drawerRef?: any;
  isShowMoreVisibleNotes?: any;
  getMoreNotes?: any;
  isLoadsCommentsLoading?: any;
  handleCreateRateConfirmation?: any;
  handleSendRateConfirmation?: any;
  rateConfirmations?: any;
  createRateConfCheck?: any;
}

export enum ILoadFileTypes {
  'redConfirmationFileIds' = 'redConfirmation',
  'podFileIds' = 'uploadedPOD',
  'customerInvoice' = 'customerInvoice',
  'carrierPayment' = 'carrierPayment',
  'invoiceIds' = 'invoiceIds',
  'invoice' = 'invoiceIds',
}

export enum LoadFilterStatuses {
  'processing' = '2',
  'claimed' = '8',
}

export enum TransactionType {
  Standard = 1,
  Credit = 2,
  Charge = 3,
}
