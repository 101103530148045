import { ICustomPicker } from 'ui/inputs/custom-date-picker/constants/types';
import CustomDatePicker from 'ui/inputs/custom-date-picker/CustomDatePicker';
import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';

import { IEmployeeFilter, IEmployeeFormData } from './../constants/types';

// export const Select = withController<ISelectProps, IEmployeeFormData>(ReusableSelect);
export const FilterSelect = withController<ISelectProps, IEmployeeFilter>(ReusableSelect);
export const FilterSelectOffice = withController<ISelectProps, IEmployeeFilter>(ReusableSelect);
export const FilterSelectBranch = withController<ISelectProps, IEmployeeFilter>(ReusableSelect);
export const Input = withController<IInputProps, IEmployeeFormData>(CustomInput);
export const DatePicker = withController<ICustomPicker, any>(CustomDatePicker);
