import { SUBJECT } from './constants';

const { LIGHT, DARK } = SUBJECT;

export const isBrowserDefaultDark = () =>
  window.matchMedia && window.matchMedia(`(prefers-color-scheme: ${DARK})`).matches;

export const getDefaultTheme = (): string => {
  const browserDefault = isBrowserDefaultDark() ? DARK : LIGHT;
  return browserDefault;
};

export const setDefaultTheme = (value: string): void => localStorage.setItem('default-theme', value);
