export enum LoadTypes {
  'Regular' = 1,
  'Tonu' = 2,
  'Connecting' = 3,
  'Internal' = 4,
}

export enum TransportCapabilityTypes {
  'Dry' = 1,
  'Refrigerated' = 2,
  'Power only' = 3,
  'Flat bed' = 4,
}

export enum TakenType {
  'Solo' = 1,
  'Team' = 2,
}

export enum BookedTypes {
  'Full' = 1,
  'Partial' = 2,
}

export enum LoadCancelStatus {
  'Canceled' = 2,
}
export interface ICreateLoadFormData {
  load_type: string;
  customerName: string;
  referenceNumber: string | number;
  customerAmount: number | null | string;
  customerContact: string | null;
  customerContactName: string;
  carrier: any[];
  carrierAmount: string | null | number;
  truck: [];
  allAuthorities?: any[];
  file: any[];
  transportType: string;
  celsius: number | null | string;
  takenAs: string;
  givenAs: string;
  commodity: string;
  bookedAs: string;
  soldAs: string;
  weight: number | null | string;
  soldUsSwitch: boolean;
  markAsSwitch: boolean;
  agent?: any[];
  agentAmount?: number | null | string;
  customer: number | null;
  pickups: any;
  dropoffs: any;
  feetPartial: string | null;
  firstAgentName: string | null;
}

export interface ITransactionFormData {
  transactionType: string;
  customer: string;
  carrierTransaction?: string;
  balanceAmount: string | number;
  secondAgent?: string;
  transactionComment: string;
}
