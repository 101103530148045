export enum SORTING {
  NAME = 'sort',
  ASC = 1,
  DESC = 2,
  DEFAULT = 0,
}

export const CARRIER_PAYMENT_BREAKDOWN_COLUMNS = [
  {
    title: 'Date',
    id: 'createdAt',
    dataIndex: 'createdAt',
    displayType: 'text',
    sortable: true,
  },
  {
    title: 'File Name',
    id: 'name',
    dataIndex: 'name',
    displayType: 'text',
  },
  {
    title: '',
    id: 'downloadAll',
    dataIndex: 'downloadAll',
    displayType: 'downloadAll',
    width: 140,
  },
];

export const SUBJECT = {
  PREV: 'prev',
  NEXT: 'next',
};
