import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { CustomerType } from 'services/profile/customers/interfaces';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { checkIsNumberDecimal, formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import CopyIcon from 'components/svgs/CopyIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import {
  AUTHORITIES_CUSTOM_COLUMNS,
  AUTHORITIES_CUSTOM_KEYS,
} from '../../../../../loads/components/create-load/constants/constants';
import { SearchableDropdown } from '../../hoc-instance/instance';

import { SUBJECT } from './constants/constants';
import { IReceivablesProps } from './constants/types';
import useReceivablesView from './useReceivablesView';

import styles from './ReceivablesView.module.scss';

const ReceivablesView = ({
  data,
  isLoading,
  tableData,
  isReceiveLoading,
  resetField,
  watch,
  setValue,
  control,
  pagePermissions,
  handleRefetchCustomer,
  isLoadingCustomerInfo,
  handleDownloadClick,
  handlePaginationClick,
}: IReceivablesProps) => {
  const {
    summary,
    authorities,
    selectedRows,
    paymentDetails,
    paymentDetailsLoading,
    handleReceive,
    setSelectedRows,
    handleOpenLoadView,
    setAuthoritiesState,
    handleCheckboxChange,
    handlePayAllSelectedRows,
    handlePaymentDetailsClick,
    checkIsDisabledRowSelection,
    isDisabledReceiveButton,
    count,
  } = useReceivablesView({ pagePermissions, setValue, handleRefetchCustomer, watch });
  const { copiedText, handleCopy } = useHandleCopyActive();

  const { employeeLevel } = useSelector(selectUserPermissionsInfo);

  const canReceive = employeeLevel
    ? !(Object.values(pagePermissions)?.filter(item => item === true)?.length === 1 && pagePermissions?.view)
    : true;

  return (
    <div className={styles.fullWrapper}>
      <MainLoader isFetching={isLoading} />

      <div className={styles.customerInfo}>
        <ViewItemWrapper title={SUBJECT.CUSTOMER_INFO}>
          <Row gutter={[20, 15]} className={styles.rows}>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">{SUBJECT.NAME}: </Typography>
                <TooltipText data={data?.result?.name} subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.NAME}`} />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">{SUBJECT.PHONE_NUMBER}: </Typography>
                <a className="top-banner-value" href={`tel:${data?.result?.phone}`}>
                  {formatPhone(data?.result?.phone)}
                </a>
              </div>
            </Col>
            {data?.result?.fax ? (
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph">{SUBJECT.FAX}: </Typography>
                  <TooltipText data={data?.result?.fax} subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.FAX}`} />
                </div>
              </Col>
            ) : (
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.CUSTOMER_ID}:{' '}
                  </Typography>
                  <Col span={13}>
                    <div
                      className={clsx(styles.copyIconText, {
                        [styles.copyActive]: copiedText === String(data?.result?.id),
                      })}
                    >
                      <Typography
                        variant="paragraph"
                        children={String(data?.result?.id)}
                        className={styles.infoParagraph}
                      />
                      <div
                        className={styles.copyIcon}
                        role="button"
                        onClick={() => handleCopy(String(data?.result?.id), String(data?.result?.id))}
                      >
                        <CopyIcon active={copiedText === String(data?.result?.id)} />
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>
            )}
          </Row>
          <Row gutter={[20, 15]} className={styles.rows}>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">{SUBJECT.TYPE}: </Typography>
                <Typography variant="paragraph">{CustomerType[data?.result?.type as number]}</Typography>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">
                  {CustomerType[data?.result?.type as number] === 'Shipper' ? SUBJECT.FF_NUMBER : SUBJECT.MC_NUMBER}:
                </Typography>
                <TooltipText data={data?.result?.mc} subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.FF_NUMBER}`} />
              </div>
            </Col>
            {data?.result?.fax && (
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph">{SUBJECT.CUSTOMER_ID}: </Typography>
                  <TooltipText data={data?.result?.id} subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.CUSTOMER_ID}`} />
                </div>
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      </div>
      <div className={styles.filterTableContainer}>
        <div className={styles.receivablesTableViewItems}>
          <div className={styles.filtersItems}>
            <Row justify="space-between" align="middle">
              <Col span={10}>
                <Row align="middle">
                  <Col span={13}>
                    <SearchableDropdown
                      options={authorities?.map((item: any) => ({
                        title: item?.name,
                        value: String(item?.id),
                        id: String(item?.id),
                      }))}
                      placeholder={SUBJECT.MC}
                      offset={count}
                      isMulti
                      setOffset={setAuthoritiesState}
                      columns={AUTHORITIES_CUSTOM_COLUMNS}
                      keys={AUTHORITIES_CUSTOM_KEYS}
                      control={control}
                      name="authorityIds"
                      setValue={setValue}
                      values={watch('authorityIds')}
                      withAll={false}
                      width={500}
                    />
                  </Col>

                  {!!watch('authorityIds')?.length && (
                    <Col>
                      <Button
                        icon={<ResetIcon />}
                        variant="ghost"
                        onClick={() => {
                          resetField('authorityIds');
                          setSelectedRows([]);
                        }}
                        children={SUBJECT.RESET_ALL}
                      />
                    </Col>
                  )}
                </Row>
              </Col>

              {!isReceiveLoading && (
                <Col span={14}>
                  <Row align="middle" gutter={15} justify="end">
                    <Col>
                      <Row align="middle">
                        <Typography variant="paragraph" className={styles.totalInfo}>
                          {SUBJECT.TOTAL}:
                        </Typography>
                        <Typography variant="paragraph" className={styles.totalCount}>
                          {formatNumberWithThousandsSeparatorAndDecimal(
                            selectedRows?.length
                              ? checkIsNumberDecimal(summary)
                              : Number((tableData?.received + tableData?.expected).toFixed(2)),
                            true
                          )}
                        </Typography>
                      </Row>
                    </Col>
                    {!selectedRows.length && (
                      <>
                        <Col>
                          <Row align="middle">
                            <Typography variant="paragraph" className={styles.totalInfo}>
                              {SUBJECT.RECEIVED}:
                            </Typography>
                            <Typography variant="paragraph" className={styles.totalCount}>
                              {`${formatNumberWithThousandsSeparatorAndDecimal(
                                checkIsNumberDecimal(tableData?.received),
                                true
                              )}`}
                            </Typography>
                          </Row>
                        </Col>
                        <Col>
                          <Row align="middle">
                            <Typography variant="paragraph" className={styles.totalInfo}>
                              {SUBJECT.EXPECTED}:
                            </Typography>
                            <Typography variant="paragraph" className={styles.totalCount}>
                              {`${formatNumberWithThousandsSeparatorAndDecimal(
                                checkIsNumberDecimal(tableData?.expected),
                                true
                              )}`}
                            </Typography>
                          </Row>
                        </Col>
                      </>
                    )}
                    {!!selectedRows.length && !!summary && canReceive && (
                      <Col>
                        <Button onClick={handlePayAllSelectedRows} children={SUBJECT.RECEIVE} />
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <Divider />
        <div className={styles.receivablesTableViewItems}>
          <div className={styles.tableWrapper}>
            <DataGrid
              type="receivable_transactions"
              withCheckbox
              isExpandable
              data={tableData?.result}
              isDisabledPayButton={isDisabledReceiveButton}
              handleReceiveBtnClick={handleReceive}
              onCheckboxChange={handleCheckboxChange}
              handlePaymentDetailsClick={handlePaymentDetailsClick}
              paymentDetails={paymentDetails}
              paymentDetailsLoading={paymentDetailsLoading}
              handleRowClick={handleOpenLoadView}
              handleDownloadClick={handleDownloadClick}
              handlePaginationClick={handlePaginationClick}
              checkIsDisabledRowSelection={checkIsDisabledRowSelection}
              isLoading={isLoadingCustomerInfo}
              copiedText={copiedText}
              handleCopy={handleCopy}
              mode="view"
              count={tableData?.count}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivablesView;
