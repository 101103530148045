import { ForwardedRef, forwardRef, ReactNode, useRef } from 'react';
import { RefSelectProps, TreeSelect } from 'antd';
import clsx from 'clsx';
import { ChangeEventExtra } from 'rc-tree-select/lib/TreeSelect';
import Typography from 'ui/typography/Typography';

import CloseIcon from 'components/svgs/CloseIcon';

import { ISelectProps } from './constants/types';

import styles from './SimpleSelect.module.scss';

const SimpleSelect = forwardRef(
  (
    {
      options,
      placeholder,
      label,
      allowClear,
      name,
      suffixIcon,
      isDisabled,
      onChange,
      value,
      treeDefaultExpandAll,
      required,
      errors,
      expandedId,
      setValue,
      resetFunc,
      ...props
    }: ISelectProps,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const selectRef = useRef<RefSelectProps>(null);

    const handleReset = () => {
      if (setValue) {
        setValue(name, '');
      }
      if (onChange) {
        onChange('');
      }
      if (resetFunc) {
        resetFunc();
      }
    };

    const handleChange = (value: string[], label: ReactNode[], extra: ChangeEventExtra) => {
      const triggerValue = extra.triggerValue?.toString();
      if (onChange) {
        onChange(value, triggerValue);
      }
      selectRef?.current?.blur();
    };

    const dropdownRef = useRef<HTMLInputElement>(null);

    return (
      <div className={styles.selectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          id={expandedId ? `simpleSelect_${name}${expandedId}` : `simpleSelect_${name}`}
          className={clsx(styles.selectWrapper, {
            [styles.selected]: value && String(value).length,
            [styles.required]: required,
            [styles.withSubChild]: treeDefaultExpandAll,
            [styles.error]: errors && errors[name || ''],
          })}
          ref={dropdownRef}
        >
          <TreeSelect
            {...props}
            showArrow
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            getPopupContainer={
              expandedId
                ? () => document.getElementById(`simpleSelect_${name}${expandedId}`)!
                : () => document.getElementById(`simpleSelect_${name}`)!
            }
            ref={selectRef || ref}
            placeholder={label ? placeholder : null}
            {...(allowClear ? { onClear: handleReset, clearIcon: <CloseIcon />, allowClear } : {})}
            suffixIcon={!(allowClear && value) ? suffixIcon : <CloseIcon />}
            value={value}
            onChange={(value, label, extra) => handleChange(value, label, extra)}
            disabled={isDisabled || false}
            maxTagCount={0}
            className={clsx(styles.select)}
            treeData={options}
            treeDefaultExpandAll={treeDefaultExpandAll}
          />
          {!label && (
            <Typography variant="paragraph" className={`placeholder-select ${styles.placeholder}`}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

export default SimpleSelect;
