import React, { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import Table from 'components/table/Table';

import { SUBJECT } from '../../constants/constants';

import BlackList from './components/blacklist/BlackList';
import useCustomersBlacklistTable from './useCustomersBlacklistTable';

import styles from '../../Customers.module.scss';

const CustomersBlacklistTable = ({
  handlePaginationClick,
  columns,
  data,
  isLoading,
  skip,
  limit,
  handleSortBlackListClick,
  setBlackListFilter,
  hasUnlockPermission,
  hasLockPermission,
}: any) => {
  const {
    control,
    authoritiesFilter,
    setAuthoritiesFilter,
    getCustomersFilter,
    setCustomersFilter,
    showBlacklist,
    setValue,
    handleResetBlacklist,
    handleRowClick,
    handleStopResize,
    handleColumnSearch,
    handleCustomerUnlockClick,
    handleSubmit,
    onSubmit,
    handleBlacklistClick,
    watch,
    finalAuthorityOptions,
    finalCustomerOptions,
  } = useCustomersBlacklistTable({ setBlackListFilter, data, skip });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading,
    onSort: handleSortBlackListClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onColumnSearch: handleColumnSearch,
    onPaginationClick: handlePaginationClick,
    hasUnlockPermission: hasUnlockPermission,
    onActionUnderline: handleCustomerUnlockClick,
    withMenu: false,
  });

  return (
    <>
      <Row align="bottom" gutter={[20, 0]}>
        {showBlacklist ? (
          <Col span={24}>
            <BlackList
              control={control}
              setValue={setValue}
              handleResetBlacklist={handleResetBlacklist}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              authorities={finalAuthorityOptions}
              authoritiesFilter={authoritiesFilter}
              setAuthoritiesFilter={setAuthoritiesFilter}
              customersData={finalCustomerOptions}
              getCustomersFilter={getCustomersFilter}
              setCustomersFilter={setCustomersFilter}
              watch={watch}
            />
          </Col>
        ) : (
          <Col className={styles.addBlacklist}>
            {hasLockPermission && (
              <Button
                children={SUBJECT.ADD_BLACK_LIST}
                variant="danger"
                htmlType="submit"
                onClick={handleBlacklistClick}
              />
            )}
          </Col>
        )}
      </Row>
      {!isLoading && !data && (
        <div className={styles.customersEmpty}>
          <Typography variant="paragraph">{SUBJECT.BLACKLIST_IS_EMPTY}</Typography>
        </div>
      )}
      <Table {...tableProps} />
    </>
  );
};

export default CustomersBlacklistTable;
