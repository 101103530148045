import { RootState } from '../store';

export const selectAuth = (state: RootState) => state?.user?.token;
export const selectUserInfo = (state: RootState) => state?.user?.userInfo;

export const selectUserPermissionsInfo = (state: RootState) => {
  return {
    permissionsInfo: state.user.permissionsInfo,
    employeeLevel: state.user.employeeLevel,
    branchIds: state.user.branchIds,
    officeIds: state.user.officeIds,
    defaultBranchId: state.user.defaultBranchId,
    permissionGroupId: state.user.permissionGroupId,
    userType: state.user.userType,
    agentType: state.user.agentType,
    isFetching: state.user.isFetching,
    userId: state.user.userInfo.id,
  };
};
