import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'store/sidebarSlice/selector';
import Button from 'ui/button/Button';
import ExcelButton from 'ui/excel-button/ExcelButton';
import { getTruckBoardTabs } from 'utils/constants';

import CustomizeColumnButton from 'components/customize-column-button/CustomizeColumnButton';
import MainDrawer from 'components/main-drawer/MainDrawer';
import TabPanel from 'components/tab-panel/TabPanel';

import useTruckBoard from './useTruckBoard';

const TruckBoard = () => {
  const {
    downloadExcel,
    openMainDrawer,
    currentChildren: { component, buttonText, onSubmit, withFooter, onCancel },
    Component,
    currentPage,
    permissionsInfo,
    tab,
    createBtnContent,
    isDefaultCreatePage,
    hasCreateSearchPermission,
    hasDownloadPermission,
  } = useTruckBoard();

  const isLoading = useSelector(selectIsLoading);

  return (
    <>
      <TabPanel tabs={getTruckBoardTabs(permissionsInfo?.permissions?.['truck-board']?.visibleTabs)}>
        <div className="tabPanelButtons">
          {currentPage !== 'create' && !(currentPage === 'truck' && isDefaultCreatePage) && (
            <CustomizeColumnButton onClick={() => openMainDrawer('customize')} isButtonVisible />
          )}
          {((currentPage === 'truck' && tab === 'truck_board' && !isDefaultCreatePage && hasCreateSearchPermission) ||
            tab === 'carrier_request') && (
            <Button onClick={createBtnContent.action} children={createBtnContent?.text} className="panelAddButton" />
          )}
          {currentPage !== 'create' &&
            tab !== 'favorite_trucks' &&
            !(currentPage === 'truck' && isDefaultCreatePage) &&
            hasDownloadPermission && (
              <ExcelButton
                onClick={() => {
                  if (!isLoading) {
                    downloadExcel();
                  }
                }}
                isButtonVisible={currentPage !== 'create'}
              />
            )}
        </div>
      </TabPanel>
      <div className="page-content">
        <div className="truck-board-tables-wrapper">{Component}</div>
      </div>
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onSubmit={onSubmit}
        withFooter={withFooter}
        onCancel={onCancel}
      />
    </>
  );
};
export default TruckBoard;
