export enum SUBJECT {
  DELETED = 'Deleted',
  USERNAME = 'Username',
  RECEIVE = 'Receive',
  PAY = 'Pay',
  MONEY_TYPE = 'Money Type',
  CHECK_NUMBER_TRANS_ID = ' Check Number Or Transaction ID',
  DISABLED_PICKUP_STATUS = 'You Can Delete Only When Load Has No Processing Status',
  DISABLED_PICKUP_STATUS_EDIT = 'You Are Not Allowed To Ddit Pickups Or Drop-Fffs',
  DISABLED_PICKUP_STATUS_DELETE = 'You Are Not Allowed To Delete Pickups or Drop-Offs',
  MONEY_CODE = 'Money Code',
  AMOUNT = 'Amount',
  DEBT = 'Debt',
  DISABLED_AUTHORITIES = 'Sorry, You Are Not Allowed To Delete Last Authority',
  DISABLED_TRUCK = 'Sorry, You Are Not Allowed To Delete Last Truck',
  DISABLED_PICKUP = 'Sorry, You Are Not Allowed To Delete first Pickup Or Last Drop-Off',
  DISABLED_PICKUP_EDIT = 'Sorry, You Can Edit Only When Load Has No Processing Status',
  DISABLED_MC = 'Sorry, You Are Not Allowed To Delete Last MC',
  DISABLED_FUEL = 'Sorry, You Are Not Allowed To Delete Last Card',
  PAID = 'Paid',
  RECEIVABLES = 'receivables',
  PAYABLES = 'payables',
  'CarrierPaid' = 2,
  'CarrierDebt' = 3,
  'CustomerPaid' = 2,
  CARRIER = 'Carrier Authority',
  BROKER = 'Broker Authority',
}
