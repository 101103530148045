import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { MenuProps, notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useChangeStatusMutation,
  useCreatePermissionGroupMutation,
  useEditPermissionGroupMutation,
  useGetPermissionGroupByIdQuery,
  useGetPermissionGroupsQuery,
  useLazyGetPermissionGroupByIdQuery,
} from 'services/permission/permission';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { IChildren } from 'utils/types';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import AddPermission from './components/add/AddPermission';
import { defaultValues } from './components/add/constants/constants';
import { IAddPermissionData } from './components/add/constants/types';
import { CreateEdit } from './components/create-edit/CreateEdit';
import { View } from './components/view/VIew';
import {
  defaultPermissionsValues,
  filterDefaultValues,
  getPermissionListTableData,
  SUBJECT,
} from './constants/constants';
import { IEditInterfaces, IPermissionListTableData, IPermissionListTypeFilter } from './constants/types';
import { validation } from './constants/validation';

const usePermissionList = () => {
  const { agentType, userType } = useSelector(selectUserPermissionsInfo);
  const type = userType === 1 ? 0 : agentType;

  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, rowName: enteredRow, open, permissionNameFilter } = searchParams;

  const [rowName, setRowName] = useState<string>(enteredRow);

  const { currentData: permissionGroupsData, isFetching: isFetchingGroups } = useGetPermissionGroupsQuery({});
  const [editPermissions, { isLoading }] = useEditPermissionGroupMutation();

  const [isGroupSelectChanged, setIsGroupSelectChanged] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid: isFormValid, isDirty, errors, dirtyFields },
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm<IAddPermissionData>({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: yupResolver(validation),
  });

  const {
    control: filterControl,
    watch: watchFilter,
    setValue: setFilterValue,
  } = useForm<IPermissionListTypeFilter>({
    defaultValues: filterDefaultValues,
    mode: 'all',
  });

  const {
    currentData: permissionInfo,
    isFetching: isFetchingById,
    refetch: refetchPermissions,
  } = useGetPermissionGroupByIdQuery(permissionNameFilter, { skip: !permissionNameFilter });
  const [getPermissionGroups] = useLazyGetPermissionGroupByIdQuery();

  const permissionList = watchFilter('permissionListType') ? String(watchFilter('permissionListType')) : null;
  const permissionListQueryCheck = !!(typeof permissionList === 'string' && permissionList);

  const permissionGroupById = permissionInfo?.permissions;
  const actionsOptions = permissionInfo?.actionsOptions;
  const defaultPermissions = permissionInfo?.defaultPermissions;

  const [createPermissionGroup, { data, isLoading: isCreateLoading }] = useCreatePermissionGroupMutation();
  const [changePermissionGroupStatus] = useChangeStatusMutation();

  //edit permissions forms /////////////////////////////////////////////////////////////////////////
  //profile
  useEffect(() => {
    if (data?.hasOwnProperty('result')) {
      setSearchParams({ ...searchParams, open: 'false', permissionNameFilter: data?.result?.id });
      setFilterValue('permissionListType', data?.result?.id);
    }
  }, [data?.result?.id]);

  const {
    handleSubmit: handleEditProfileSubmit,
    control: editProfileControl,
    watch: editProfileWatchPermissions,
    formState: { isDirty: isDirtyProfileFields, dirtyFields: touchedProfileField, defaultValues: profileInitialValues },
    setValue: setEditProfileValue,
    reset: resetEditProfilePermission,
    resetField: resetProfileField,
  } = useForm<IEditInterfaces>({
    defaultValues: defaultPermissionsValues.profiles,
    mode: 'all',
  });

  //loads
  const {
    handleSubmit: handleEditLoadsSubmit,
    control: editLoadsControl,
    watch: editLoadsWatchPermissions,
    formState: { isDirty: isDirtyLoadsFields, dirtyFields: touchedLoadsField, defaultValues: loadsInitialValues },
    setValue: setEditLoadsValue,
    reset: resetEditLoadsPermission,
  } = useForm<IEditInterfaces>({
    defaultValues: defaultPermissionsValues.loads,
    mode: 'all',
  });
  //payroll
  const {
    handleSubmit: handleEditPayrollSubmit,
    control: editPayrollControl,
    watch: editPayrollWatchPermissions,
    formState: { isDirty: isDirtyPayrollFields, dirtyFields: touchedPayrollField, defaultValues: payrollInitialValues },
    setValue: setEditPayrollValue,
    reset: resetEditPayrollPermission,
  } = useForm<IEditInterfaces>({
    defaultValues: defaultPermissionsValues.payroll,
    mode: 'all',
  });
  //creditcheck
  const {
    handleSubmit: handleEditCreditCheckSubmit,
    control: editCreditCheckControl,
    watch: editCreditCheckWatchPermissions,
    formState: {
      isDirty: isDirtyCreditCheckFields,
      dirtyFields: touchedCreditCheckField,
      defaultValues: creditCheckInitialValues,
    },
    setValue: setEditCreditCheckValue,
    reset: resetEditCreditCheckPermission,
  } = useForm<IEditInterfaces>({
    defaultValues: defaultPermissionsValues.creditcheck,
    mode: 'all',
  });
  //contact
  const {
    handleSubmit: handleEditContactSubmit,
    control: editContactControl,
    watch: editContactWatchPermissions,
    formState: { isDirty: isDirtyContactFields, dirtyFields: touchedContactField, defaultValues: contactInitialValues },
    setValue: setEditContactValue,
    reset: resetEditContactPermission,
  } = useForm<IEditInterfaces>({
    defaultValues: defaultPermissionsValues.contact,
    mode: 'all',
  });
  // factoring
  const {
    handleSubmit: handleEditFactoringSubmit,
    control: editFactoringControl,
    watch: editFactoringWatchPermissions,
    formState: {
      isDirty: isDirtyFactoringFields,
      dirtyFields: touchedFactoringField,
      defaultValues: factoringInitialValues,
    },
    setValue: setEditFactoringValue,
    reset: resetEditFactoringPermission,
  } = useForm<IEditInterfaces>({
    defaultValues: defaultPermissionsValues.factoring,
    mode: 'all',
  });
  // truckboard
  const {
    handleSubmit: handleEditTruckBoardSubmit,
    control: editTruckBoardControl,
    watch: editTruckBoardWatchPermissions,
    formState: {
      isDirty: isDirtyTruckBoardFields,
      dirtyFields: touchedTruckBoardField,
      defaultValues: truckBoardInitialValues,
    },
    setValue: setEditTruckBoardValue,
    reset: resetTruckBoardPermission,
  } = useForm<IEditInterfaces>({
    defaultValues: defaultPermissionsValues.truckboard,
    mode: 'all',
  });
  // permission
  const {
    handleSubmit: handleEditPermissionSubmit,
    control: editPermissionControl,
    watch: editPermissionWatchPermissions,
    formState: {
      isDirty: isDirtyPermissionFields,
      dirtyFields: touchedPermissionField,
      defaultValues: permissionInitialValues,
    },
    setValue: setEditPermissionValue,
    reset: resetPermissionPermission,
  } = useForm<IEditInterfaces>({
    defaultValues: defaultPermissionsValues.permission,
    mode: 'all',
  });

  const formFunctions = {
    profiles: {
      setValues: setEditProfileValue,
      watch: editProfileWatchPermissions,
      control: editProfileControl,
      handleSubmit: handleEditProfileSubmit,
      reset: resetEditProfilePermission,
      defaultValues: defaultPermissionsValues.profiles,
      isDirty: isDirtyProfileFields,
      dirtyFields: touchedProfileField,
      resetField: resetProfileField,
      initialValues: profileInitialValues,
    },
    loads: {
      setValues: setEditLoadsValue,
      watch: editLoadsWatchPermissions,
      control: editLoadsControl,
      handleSubmit: handleEditLoadsSubmit,
      reset: resetEditLoadsPermission,
      defaultValues: defaultPermissionsValues.loads,
      isDirty: isDirtyLoadsFields,
      dirtyFields: touchedLoadsField,
      initialValues: loadsInitialValues,
    },
    payroll: {
      setValues: setEditPayrollValue,
      watch: editPayrollWatchPermissions,
      control: editPayrollControl,
      handleSubmit: handleEditPayrollSubmit,
      reset: resetEditPayrollPermission,
      defaultValues: defaultPermissionsValues.payroll,
      isDirty: isDirtyPayrollFields,
      dirtyFields: touchedPayrollField,
      initialValues: payrollInitialValues,
    },
    creditcheck: {
      setValues: setEditCreditCheckValue,
      watch: editCreditCheckWatchPermissions,
      control: editCreditCheckControl,
      handleSubmit: handleEditCreditCheckSubmit,
      reset: resetEditCreditCheckPermission,
      defaultValues: defaultPermissionsValues.creditcheck,
      isDirty: isDirtyCreditCheckFields,
      dirtyFields: touchedCreditCheckField,
      initialValues: creditCheckInitialValues,
    },
    contact: {
      setValues: setEditContactValue,
      watch: editContactWatchPermissions,
      control: editContactControl,
      handleSubmit: handleEditContactSubmit,
      reset: resetEditContactPermission,
      defaultValues: defaultPermissionsValues.contact,
      isDirty: isDirtyContactFields,
      dirtyFields: touchedContactField,
      initialValues: contactInitialValues,
    },
    factoring: {
      setValues: setEditFactoringValue,
      watch: editFactoringWatchPermissions,
      control: editFactoringControl,
      handleSubmit: handleEditFactoringSubmit,
      reset: resetEditFactoringPermission,
      defaultValues: defaultPermissionsValues.permission,
      isDirty: isDirtyFactoringFields,
      dirtyFields: touchedFactoringField,
      initialValues: factoringInitialValues,
    },
    truckboard: {
      setValues: setEditTruckBoardValue,
      watch: editTruckBoardWatchPermissions,
      control: editTruckBoardControl,
      handleSubmit: handleEditTruckBoardSubmit,
      reset: resetTruckBoardPermission,
      defaultValues: defaultPermissionsValues.truckboard,
      isDirty: isDirtyTruckBoardFields,
      dirtyFields: touchedTruckBoardField,
      initialValues: truckBoardInitialValues,
    },
    permission: {
      setValues: setEditPermissionValue,
      watch: editPermissionWatchPermissions,
      control: editPermissionControl,
      handleSubmit: handleEditPermissionSubmit,
      reset: resetPermissionPermission,
      defaultValues: defaultPermissionsValues.permission,
      isDirty: isDirtyPermissionFields,
      dirtyFields: touchedPermissionField,
      initialValues: permissionInitialValues,
    },
  };

  const currentForm = useMemo(() => {
    return formFunctions[rowName as keyof typeof formFunctions];
  }, [
    rowName,
    formFunctions,
    permissionInitialValues,
    truckBoardInitialValues,
    factoringInitialValues,
    contactInitialValues,
    creditCheckInitialValues,
    payrollInitialValues,
    loadsInitialValues,
    profileInitialValues,
  ]);
  //edit permissions forms /////////////////////////////////////////////////////////////////////////

  useLayoutEffect(() => {
    const defaultValues: any = {};
    permissionGroupById?.[rowName]?.map((el: any) => {
      const permissions = el?.existingPermissions?.filter((el: any) => el === 'view');
      const actions = el?.existingPermissions?.filter((el: any) => el !== 'view');

      defaultValues[el?.permissionName] = {
        permissions: actions.length ? [...permissions, 'actions'] : permissions,
        actions,
      };
    });
    currentForm?.reset(defaultValues);
  }, [permissionGroupById, rowName]);

  const isCurrentFormValid = useMemo(() => {
    const invalidField: any = [];
    for (const key in currentForm?.watch()) {
      const form: any = currentForm?.watch() || {};

      const isInvalidField = form?.[key]?.permissions?.includes('actions') && !form[key]?.actions?.length;
      if (isInvalidField) {
        if (!invalidField.includes(key)) {
          invalidField.push(key);
        }
      }
    }
    return {
      isCurrentFormValid: !!invalidField.length,
      inValidFields: invalidField,
    };
  }, [currentForm?.watch(), rowName]);

  useEffect(() => {
    if (mode === 'editName') {
      reset({ permissionGroupName: defaultPermissions?.name });
    } else {
      reset({ permissionGroupName: '' });
    }
  }, [defaultPermissions?.name, mode, open]);

  const openMainDrawer = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: 'editName',
    });
  };

  const resetEditForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      headerFilter: '',
    });
    reset();
  };

  const onSubmit: SubmitHandler<IAddPermissionData> = data => {
    const deserializedData = { name: data.permissionGroupName };
    createPermissionGroup(deserializedData as any).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 1,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_CREATED,
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        setSearchParams({
          ...searchParams,
          open: 'false',
          headerFilter: '',
          permissionNameFilter: data?.data?.result?.id,
        });
        reset();
      }
    });
  };
  const onEditPermissionNameSubmit: SubmitHandler<IAddPermissionData> = data => {
    editPermissions({ id: permissionNameFilter, data, defaultPermissions, mode } as any).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 1,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_EDITED,
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        resetEditForm();
        handleCloseModal();
      }
    });
  };

  const onEditPermissionSubmit: SubmitHandler<IEditInterfaces> = data => {
    editPermissions({ id: permissionNameFilter, data, defaultPermissions, mode } as any).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 1,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(closeModal());
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_EDITED,
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        setSearchParams({ ...searchParams, open: 'false' });
        currentForm?.reset();
        refetchPermissions();
        dispatch(closeModal());
        setSubmittedInvalidFields([]);
      }
    });
  };

  const handlePermissionEdit = (rowId: number) => {
    const entered = getPermissionListTableData(Number(type))!.filter(
      (data: IPermissionListTableData) => data.id === rowId
    );
    setRowName(entered?.[0]?.deserialized?.toLowerCase());
    setSearchParams({
      ...searchParams,
      ...(permissionListQueryCheck ? { permissionNameFilter: permissionList } : {}),
      mode: 'edit',
      open: 'true',
      rowName: entered[0].deserialized.toLowerCase(),
    });
  };

  const [terminateSwitch, setTerminateSwitch] = useState(false);

  useEffect(() => {
    if (defaultPermissions?.hasOwnProperty('terminatedAt')) {
      setTerminateSwitch(false);
    } else {
      setTerminateSwitch(true);
    }
  }, [defaultPermissions, permissionGroupById, permissionInfo]);

  const handleSwitchClick = (checked: boolean) => {
    dispatch(
      openModal({
        title: checked ? SUBJECT.TERMINATE_ON : SUBJECT.TERMINATE_OFF,
        okText: checked ? SUBJECT.ACTIVATE : SUBJECT.DEACTIVATE,
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        cancelText: SUBJECT.CLOSE,
        onOk: () => {
          changePermissionGroupStatus({ id: Number(permissionNameFilter), status: checked ? 1 : 2 }).then(
            (data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 1,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                setTerminateSwitch(!defaultPermissions?.hasOwnProperty('terminatedAt'));
              } else {
                notification.success({
                  message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                  duration: 1,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                setTerminateSwitch(checked);
              }
            }
          );
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleRowClick = (rowId: number) => {
    const entered = getPermissionListTableData(Number(type))!.filter(
      (data: IPermissionListTableData) => data.id === rowId
    );
    setRowName(entered?.[0]?.deserialized?.toLowerCase());
    setSearchParams({
      ...searchParams,
      ...(permissionListQueryCheck ? { permissionNameFilter: permissionList } : {}),
      rowName: entered[0].deserialized.toLowerCase(),
      mode: 'view',
      open: 'true',
    });
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(permissionListQueryCheck ? { permissionNameFilter: permissionList } : {}),
      open: 'false',
      rowName: 'list',
    });
    dispatch(closeModal());
    reset();
    currentForm?.reset();
    setRowName('');
    if (permissionNameFilter) getPermissionGroups(permissionNameFilter);
    setSubmittedInvalidFields([]);
  };

  const handleCancel = () => {
    if (Object.keys(dirtyFields).length && isFormValid) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: SUBJECT.SAVE,
          cancelText: SUBJECT.CLOSE,
          onOk: () => {
            mode === 'editName' ? handleSubmit(onEditPermissionNameSubmit)() : handleSubmit(onSubmit)();
          },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(permissionListQueryCheck ? { permissionNameFilter: permissionList } : {}),
        open: 'false',
        rowName: 'list',
      });
      if (permissionNameFilter) getPermissionGroups(permissionNameFilter);
    }
    clearErrors();
  };

  const handleCancelEdit = () => {
    if (currentForm?.isDirty && Object.keys(currentForm?.dirtyFields).length) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: !isCurrentFormValid.isCurrentFormValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CLOSE,
          onOk: () => {
            !isCurrentFormValid.isCurrentFormValid
              ? currentForm?.handleSubmit(onEditPermissionSubmit)()
              : (() => {
                  dispatch(closeModal());
                  setSubmittedInvalidFields([]);
                })();
          },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(permissionListQueryCheck ? { permissionNameFilter: permissionList } : {}),
        open: 'false',
        rowName: 'list',
      });
      currentForm?.reset();
      refetchPermissions();
    }
  };
  const [submittedInvalidFields, setSubmittedInvalidFields] = useState([]);
  const drawerChildren: IChildren = {
    add: {
      component: (
        <AddPermission
          control={control}
          errors={errors}
          getValues={watch}
          dirtyFields={dirtyFields}
          isDirty={isDirty}
          isLoading={isCreateLoading}
          setValue={setValue}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: handleSubmit(onSubmit),
      onCancel: handleCancel,
    },
    edit: {
      component: (
        <CreateEdit
          control={currentForm?.control}
          watch={currentForm?.watch}
          rowName={rowName}
          actionsOptions={actionsOptions?.[rowName] || []}
          setValue={currentForm?.setValues}
          submittedInvalidFields={submittedInvalidFields}
          currentForm={currentForm}
          isLoading={isLoading}
          setSubmittedInvalidFields={setSubmittedInvalidFields}
          invalidFields={isCurrentFormValid.inValidFields}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onCancel: handleCancelEdit,
      onSubmit: isCurrentFormValid.isCurrentFormValid
        ? () => {
            setSubmittedInvalidFields(isCurrentFormValid.inValidFields);
          }
        : currentForm?.handleSubmit(onEditPermissionSubmit),
    },
    view: {
      component: <View data={permissionGroupById} isFethingById={isFetchingById} rowName={rowName} />,
      onCancel: handleCancel,
    },
    editName: {
      component: (
        <AddPermission
          control={control}
          errors={errors}
          getValues={watch}
          dirtyFields={dirtyFields}
          isDirty={isDirty}
          isLoading={isLoading}
          setValue={setValue}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: handleSubmit(onEditPermissionNameSubmit),
      onCancel: handleCancel,
    },
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div role="button " onClick={openMainDrawer}>
          {SUBJECT.EDIT}
        </div>
      ),
    },
  ];

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    isDirty,
    watch(),
    Object.keys(dirtyFields || {}).length,
    permissionGroupById,
    currentForm?.watch(),
    rowName,
    currentForm?.isDirty,
    Object.keys(currentForm?.dirtyFields || {}).length,
    isCurrentFormValid.inValidFields,
    currentForm?.initialValues,
    permissionInitialValues,
    truckBoardInitialValues,
    factoringInitialValues,
    contactInitialValues,
    creditCheckInitialValues,
    payrollInitialValues,
    loadsInitialValues,
    profileInitialValues,
    isFormValid,
    permissionInfo,
    open,
    searchParams,
    submittedInvalidFields,
    isCurrentFormValid,
    isLoading,
    isCreateLoading,
    setValue,
  ]);
  useEffect(() => {
    if (enteredRow) {
      setRowName(enteredRow);
    }
  }, [enteredRow]);

  useEffect(() => {
    if (open === 'false' && permissionNameFilter) {
      setRowName('list');
      if (permissionNameFilter) getPermissionGroups(permissionNameFilter);
    }
  }, [open]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(permissionListQueryCheck ? { permissionNameFilter: permissionList } : {}),
    });
  }, [permissionList]);

  useEffect(() => {
    const filterOptions = [{ key: 'permissionListType', value: permissionNameFilter }];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setFilterValue(key as keyof IPermissionListTypeFilter, value);
      }
    });
  }, [permissionNameFilter]);

  useEffect(() => {
    if (isGroupSelectChanged) {
      const timer = setTimeout(() => {
        setIsGroupSelectChanged(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isGroupSelectChanged]);

  return {
    filterControl,
    currentChildren,
    items,
    handlePermissionEdit,
    handleRowClick,
    permissionGroupsData,
    isFetchingGroups,
    permissionGroupById,
    permissionNameFilter,
    handleSwitchClick,
    setFilterValue,
    searchParams,
    setSearchParams,
    terminateSwitch,
    isGroupSelectChanged,
    setIsGroupSelectChanged,
    type,
  };
};

export default usePermissionList;
