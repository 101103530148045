import React from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatPhone } from 'utils/helpers';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import useContactView from './useContactView';

import styles from './ContactView.module.scss';

const ContactView = ({ data, handleDeleteModal, isFethingById, pagePermissions }: any) => {
  const { handleChangeMode } = useContactView();

  return (
    <>
      <MainLoader isFetching={isFethingById} />
      <ViewItemWrapper
        title={SUBJECT.CONTACT}
        Component={
          <>
            {pagePermissions.edit && (
              <Button className="viewDrawerEditBtn" children={SUBJECT.EDIT} onClick={handleChangeMode} />
            )}
            {pagePermissions.delete && (
              <Button
                className="viewDrawerEditBtn"
                variant="danger"
                children={SUBJECT.DELETE}
                onClick={() => handleDeleteModal(data?.userId)}
              />
            )}
          </>
        }
      >
        <Row justify="space-between" align="top" gutter={[10, 25]}>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(new Date(data?.createdAt))}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.FIRST_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.firstName}
                  subject={`${SUBJECT.CONTACT}${SUBJECT.FIRST_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.LAST_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.lastName}
                  subject={`${SUBJECT.CONTACT}${SUBJECT.LAST_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.USER_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.username}
                  subject={`${SUBJECT.CONTACT}${SUBJECT.USER_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <a className="top-banner-value" href={`tel:${data?.phone}`}>
                  {formatPhone(data?.phone)}
                </a>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.email}
                  subject={`${SUBJECT.CONTACT}${SUBJECT.EMAIL}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.LEVEL} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.level} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.POSITION} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.position}
                  subject={`${SUBJECT.CONTACT}${SUBJECT.POSITION}`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </>
  );
};

export default ContactView;
