import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { SUBJECT } from 'pages/authorization/constants/constants';
import { IForgotFormData } from 'pages/authorization/forgot-password/constants/types';
import { ls } from 'utils/storage';

const useEmailConfirmation = (onSubmit: SubmitHandler<IForgotFormData>) => {
  const [showEmail, setShowEmail] = useState('');

  const [activeResend, setActiveResend] = useState(true);

  const [emailTime, setEmailTime] = useState(59);
  const [showEmailTime, setShowEmailTime] = useState('');

  const emailValue = ls.get('emailValue');

  const handleResend = () => {
    setActiveResend(prev => !prev);
    onSubmit({ email: emailValue });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!emailTime) {
        setActiveResend(false);
        setEmailTime(59);
      }
      if (activeResend && emailTime) {
        setEmailTime(prev => prev - 1);
        window.addEventListener('beforeunload', async () => {
          ls.set('emailTime', `${emailTime}`);
        });
      }
    }, 1000);

    return () => clearTimeout(interval);
  }, [emailTime, activeResend]);

  const replaceLetters = () => {
    const lastPosition = emailValue?.lastIndexOf('@');
    const slicedStartSymbols = emailValue?.slice(0, 2);
    const slicedEndSymbols = emailValue?.slice(lastPosition);
    setShowEmail(`${SUBJECT.TITLE} ${slicedStartSymbols}${SUBJECT.ASTERISKS}${slicedEndSymbols}`);
  };

  useEffect(() => {
    if (emailValue) {
      replaceLetters();
    }
  }, [emailValue]);

  useEffect(() => {
    const timer = ls.get(`emailTime`);
    if (timer) {
      setEmailTime(Number(timer));
    }
  }, []);

  useEffect(() => {
    if (emailTime < 10) {
      setShowEmailTime(`0:0${emailTime}`);
    } else {
      setShowEmailTime(`0:${emailTime}`);
    }
  }, [emailTime]);

  return {
    showEmail,
    activeResend,
    handleResend,
    showEmailTime,
  };
};

export default useEmailConfirmation;
