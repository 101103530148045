import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';

import CloseIcon from '../../components/svgs/CloseIcon';
import DangerIcon from '../../components/svgs/DangerIcon';
import SuccessIcon from '../../components/svgs/SuccessIcon';
import { usePermittedActions } from '../../hooks/usePermittedActions';
import { useExportContactMutation } from '../../services/exports/exports';
import { selectUserPermissionsInfo } from '../../store/user-slice/selector';
import { notificationKey } from '../../utils/constants';
import { ServerErrorCodes } from '../../utils/errors';
import { SUBJECT } from '../loads/constants/constants';
import { ContactTabs, IComponent } from '../profile/constants/constants';

import ContactList from './tab/contact-list/ContactList';

const useContact = () => {
  const [exportContact] = useExportContactMutation();

  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab } = searchParams;

  const isButtonVisible = true;
  const contactPagePermissions = usePermittedActions('contact.contact');

  const tabs: any = {
    contact: contactPagePermissions,
  };

  const hasAddPermission = tabs?.[tab]?.create;
  const hasDownloadPermission = tabs?.[tab]?.download;

  const openMainDrawer = (mode: string) => {
    setSearchParams({
      ...searchParams,
      mode,
      open: 'true',
    });
  };

  const [excelParams, setExcelParams] = useState<any>();
  const downloadExcel = () => {
    const exportFunctionMap: Record<string, any> = {
      [ContactTabs.contact]: exportContact,
    };

    const exportFunction = exportFunctionMap[tab];

    exportFunction(excelParams).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.CHECK_EMAIL,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  const Component: IComponent = {
    contact: <ContactList setExcelParams={setExcelParams} />,
  };

  return {
    tab,
    isButtonVisible,
    openMainDrawer,
    downloadExcel,
    Component,
    permissionsInfo,
    hasAddPermission,
    hasDownloadPermission,
  };
};

export default useContact;
