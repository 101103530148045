import { Dropdown, TableColumnsType } from 'antd';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import ChevronIcon from 'components/svgs/ChevronIcon';

import { ExpandedDataType } from './types';

export const columns: TableColumnsType<ExpandedDataType> = [
  {
    title: 'Reg. Date',
    dataIndex: 'registrationDate',
    key: 'registrationDate',
    width: 185,
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
    width: 185,
  },
  {
    title: 'Pick Ups',
    dataIndex: 'pickupsCount',
    key: 'pickupsCount',
    width: 120,
    render: (_, record) => {
      const fromOptions = record?.pickupsOptions?.map((item: any) => {
        return {
          key: item?.createdAt,
          label: <span>{`${item?.city}, ${item?.state?.name}`}</span>,
        };
      });

      return (
        <div className="table-dropdown">
          <span>{record.pickupsCount}</span>
          {Number(record.pickupsCount) > 1 && (
            <Dropdown rootClassName="drop-down-loads_items" menu={{ items: fromOptions }} placement="topRight">
              <a>
                <ChevronIcon />
              </a>
            </Dropdown>
          )}
        </div>
      );
    },
  },
  {
    title: 'To',
    dataIndex: 'to',
    key: 'to',
    width: 185,
  },
  {
    title: 'Drop Offs',
    dataIndex: 'dropoffsCount',
    key: 'dropoffsCount',
    width: 120,
    render: (_, record) => {
      const toOptions = record?.dropoffsOptions?.map((item: any) => {
        return {
          key: item?.createdAt,
          label: <span>{`${item?.city}, ${item?.state?.name}`}</span>,
        };
      });

      return (
        <div className="table-dropdown">
          <span>{record.dropoffsCount}</span>
          {Number(record.dropoffsCount) > 1 && (
            <Dropdown rootClassName="drop-down-loads_items" menu={{ items: toOptions }} placement="topRight">
              <a>
                <ChevronIcon />
              </a>
            </Dropdown>
          )}
        </div>
      );
    },
  },
  {
    title: 'Equipment Type',
    dataIndex: 'equipmentType',
    key: 'equipmentType',
    width: 180,
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
    width: 200,
  },
  {
    title: 'Carrier Amount',
    dataIndex: 'carrierAmount',
    key: 'carrierAmount',
    width: 200,
    render: carrierAmount => {
      return <>{formatNumberWithThousandsSeparatorAndDecimal(carrierAmount, true)}</>;
    },
  },
];
