import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { useDetectedParams } from 'hooks/useDetectedParams';
import Typography from 'ui/typography/Typography';

import DownloadIcon from 'components/svgs/DownloadIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import { TrashIcon } from 'components/svgs/TrashIcon';

import { formatDate } from '../../../../../../utils/dates';
import { SUBJECT } from '../../constants/constants';

import styles from './FileItem.module.scss';

const FileItems = ({
  files,
  onDeleteClick,
  itemId,
  handleDownloadFile,
  handleDownloadFileEdit,
  handleViewFileCreate,
  handleViewFileEdit,
  isDisabled = false,
  withCrossing = false,
  hasDeleteFilePermission = true,
  hasAuthor = true,
}: any) => {
  const {
    searchParams: { mode },
  } = useDetectedParams();

  const textFinder = (el: any, index: number) => {
    if (withCrossing) {
      if (isDisabled) {
        return SUBJECT.PROCESSING_RULE;
      } else if (!Number(el.mailable)) {
        return SUBJECT.ALREADY_DELETED;
      }
    }
    if ((mode === 'edit' || mode === 'tonuEdit') && index === 0 && files.length === 1) {
      return SUBJECT.DISABLED_DELETE_FIRST;
    }
  };
  const isDeleteButtonDisable = (el: any, index: number) => {
    if (withCrossing && (mode === 'edit' || mode === 'tonuEdit')) {
      return isDisabled || !Number(el.mailable);
    } else if (withCrossing && files.length > 1 && index !== 0) {
      return true;
    } else if ((mode === 'edit' || mode === 'tonuEdit') && index === 0 && files.length === 1) {
      return true;
    }
  };

  const isRowHasCrossing = (el: any, index: number) => {
    if (withCrossing) {
      if (mode === 'edit' || mode === 'tonuEdit') {
        return !+el.mailable;
      } else {
        return files.length > 1 && index !== 0;
      }
    }
  };

  const getFormattedDateForTypography = (el: any) => {
    if (typeof el.createdAt === 'string') {
      return el.createdAt;
    }
    return formatDate(el.createdAt);
  };

  return files?.map((el: any, index: any) => {
    return (
      <div className={styles.fileItemWrapper} key={el?.id}>
        <Row
          gutter={15}
          className={clsx({
            [styles.crossing]: isRowHasCrossing(el, index),
          })}
        >
          <Col span={!hasAuthor ? 12 : 8}>
            <Tooltip title={el.fileName} placement="top" color="white" key={1}>
              <Typography variant="paragraph" className={styles.fileName}>
                {el.fileName || el?.name}
              </Typography>
            </Tooltip>
          </Col>
          {hasAuthor && (
            <Col span={6}>
              {el?.fullName && !el?.fullName?.includes('undefined') ? (
                <Typography variant="paragraph">
                  {SUBJECT.ADDED_BY} &nbsp;{el?.fullName}
                </Typography>
              ) : null}
              {el?.createdBy && !el?.createdBy?.includes('undefined') ? (
                <Typography variant="paragraph">
                  {SUBJECT.ADDED_BY} &nbsp;{el?.createdBy}
                </Typography>
              ) : null}
            </Col>
          )}
          <Col span={!hasAuthor ? 7 : 5}>
            <Typography variant="paragraph">{getFormattedDateForTypography(el)}</Typography>
          </Col>
          <Col span={5}>
            <div className={styles.actionsWrapper}>
              <button
                type="button"
                onClick={() => {
                  mode === 'add'
                    ? handleViewFileCreate(el)
                    : el.preview
                    ? handleViewFileEdit(el.preview || el)
                    : handleViewFileCreate(el);
                }}
              >
                <EyeIconSvg />
              </button>
              <button
                type="button"
                onClick={() => {
                  mode === 'add'
                    ? handleDownloadFile(el)
                    : el.download
                    ? handleDownloadFileEdit(el.download || el)
                    : handleDownloadFile(el);
                }}
              >
                <DownloadIcon />
              </button>
              {hasDeleteFilePermission && (
                <Tooltip
                  title={textFinder(el, index)}
                  placement="top"
                  color="#fff"
                  key={el.id}
                  zIndex={
                    (withCrossing || mode === 'edit' || (mode === 'create' && index === 0)) &&
                    isDeleteButtonDisable(el, index)
                      ? 9999
                      : 0
                  }
                >
                  <button
                    type="button"
                    disabled={isDeleteButtonDisable(el, index)}
                    onClick={() => {
                      onDeleteClick(el.id, itemId);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </Tooltip>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  });
};

export default FileItems;
