import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { TRangePickerProps } from 'ui/inputs/range-picker/constants/types';
import ReusableRangePicker from 'ui/inputs/range-picker/RangePicker';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';

import { ILocationData } from '../constants/types';

export const Select = withController<ISelectProps, ILocationData>(ReusableSelect);
export const RangeDatePicker = withController<TRangePickerProps, ILocationData>(ReusableRangePicker);

export const Input = withController<IInputProps, ILocationData>(CustomInput);
