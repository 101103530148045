import React from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';

import { ArrowSvg } from 'components/svgs/ArrowSvg';

import { SUBJECT } from '../constants/constants';
import EmailConfirmation from '../email-confirmation/EmailConfirmation';

import { IForgotPasswordProps } from './constants/types';
import { Input } from './hoc-instance/instance';
import useForgotPassword from './useForgotPassword';

import styles from '../sign-in/SignIn.module.scss';

const ForgotPassword = ({ redirectWaitingPage }: IForgotPasswordProps) => {
  const { handleSubmit, onSubmit, control, isValid, handleEmailConfirmation, confirm, getValues, errors } =
    useForgotPassword();

  return confirm ? (
    <EmailConfirmation handleEmailConfirmation={handleEmailConfirmation} onSubmit={onSubmit} />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[0, 22]}>
        <Col span={24}>
          <h2 className={styles.title}>{SUBJECT.RESET_PASSWORD}</h2>
        </Col>
        <Col span={24}>
          <Input
            errors={errors}
            height={50}
            name="email"
            control={control}
            rules={{ required: true }}
            placeholder={SUBJECT.EMAIL}
            type="email"
            getValues={getValues}
          />
        </Col>
        <Col span={24}>
          <Button htmlType="submit" size="large" disabled={!isValid} variant="default">
            {SUBJECT.RESET_PASSWORD}
          </Button>
        </Col>
      </Row>
      <div className={styles.arrowIcon}>
        <Button onClick={redirectWaitingPage} htmlType="button" variant="text">
          <ArrowSvg />
        </Button>
      </div>
    </form>
  );
};

export default ForgotPassword;
