export const COLUMNS_LOADS: string[] = ['Load ID', 'Reference Number'];
export const COLUMNS_CARRIERS: string[] = ['Carrier Name', 'Carrier ID', 'Phone Number', 'DOT', 'MC Number'];
export const COLUMNS_CUSTUMERS: string[] = ['Customer Name', 'Customer ID', 'Phone Number'];

export const COLUMNS: any = {
  loads: COLUMNS_LOADS,
  carriers: COLUMNS_CARRIERS,
  customers: COLUMNS_CUSTUMERS,
};

export enum SUBJECT {
  PLACEHOLDER = 'Search',
  NO_DATA = 'No Results Found',
  LOADING = 'Loading ...',
}
