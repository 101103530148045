import React from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHandleCopyActive } from 'hooks/useCopy';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useResetPasswordMutation } from 'services/auth/auth';
import { useChangeStatusMutation, useInviteAgentAgainMutation } from 'services/profile/agents/agents';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from '../../constants/constants';

const useView = ({ data, setAuthoritiesByAgent }: any) => {
  const dispatch = useDispatch();

  const { copiedText, handleCopy } = useHandleCopyActive();
  const { searchParams, setSearchParams } = useDetectedParams();
  const { id, mode } = searchParams;
  const [changeStatus] = useChangeStatusMutation();
  const [resetPassword] = useResetPasswordMutation();
  const [inviteAgentAgain] = useInviteAgentAgainMutation();

  const handleChecked = (checked: boolean) => {
    if (data?.result?.verifiedAt) {
      dispatch(
        openModal({
          title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
          okText: SUBJECT.YES,
          cancelText: SUBJECT.NO,
          cancelButtonVariant: 'gray',
          okButtonVariant: checked ? 'modalPrimary' : 'danger',
          onOk: () => {
            changeStatus({ id, status: checked ? 1 : 2 }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 1,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                  duration: 1,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              }
            });
            dispatch(closeModal());
          },
          onCancel: () => {
            dispatch(closeModal());
          },
        })
      );
    }
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      mode: 'edit',
      open: 'true',
    });
  };

  const handleResetPassword = (email: any) => {
    resetPassword(email).then((recover: any) => {
      if (recover.error) {
        notification.error({
          message: recover?.error?.data?.details?.[0] || ServerErrorCodes[0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Instructions to reset the password were sent to your email',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setAuthoritiesByAgent((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleInviteAgain = (id: number) => {
    inviteAgentAgain(id).then((res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.data?.details?.[0] || ServerErrorCodes[0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'The agent was successfully invited',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };
  const checkedValue = !data?.result?.terminatedAt;
  return {
    handleChecked,
    handleChangeMode,
    handleResetPassword,
    handleInviteAgain,
    handleCopy,
    copiedText,
    mode,
    checkedValue,
    handlePaginationClick,
  };
};

export default useView;
