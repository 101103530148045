import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { customerReceiveDeserializer } from './deserializers';
import { ICustomerBalkPayRequest, IGetCustomerInfo, IGetCustomerInfoParams, IReceivablesData } from './interface';
import { getCustomerInfoSerializer, getReceivablesSerializer, getRecievablesParamsSerializer } from './serializers';

export const receivablesAPI = createApi({
  reducerPath: 'receivablesAPI',
  baseQuery,
  tagTypes: ['Receivables', 'Loads'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getReceivables: builder.query({
      query: (filters: any) => {
        const queryParams = getRecievablesParamsSerializer(filters);
        return {
          url: 'factoring/receivables',
          params: queryParams,
        };
      },
      providesTags: ['Receivables', 'Loads'],
      transformResponse: (body: IReceivablesData) => getReceivablesSerializer(body),
    }),
    getCustomerInfo: builder.mutation({
      query: ({ customerId, authorityIds, skip, limit }: IGetCustomerInfoParams) => ({
        url: 'loads/customer-info',
        method: 'POST',
        body: { customerId: Number(customerId), authorityIds: authorityIds?.map(el => Number(el)), skip, limit },
      }),
      transformResponse: (body: IGetCustomerInfo) => getCustomerInfoSerializer(body),
    }),
    getDetailedCustomer: builder.query({
      query: id => `customers/${id}/detailed`,
      providesTags: ['Receivables'],
    }),
    getPaymentDetails: builder.query({
      query: (id: string) => ({
        url: `/loads/${id}/customer-pay-info`,
        providesTags: ['Receivables'],
      }),
    }),
    getCustomerReceive: builder.mutation({
      query: (body: ICustomerBalkPayRequest) => ({
        url: '/loads/customer-balk-pay',
        method: 'PATCH',
        body: customerReceiveDeserializer(body),
      }),
    }),
    sendInvoiceReceivables: builder.mutation({
      query: (id: string) => ({
        url: `/loads/${id}/send-invoice`,
        method: 'POST',
      }),
      invalidatesTags: ['Receivables'],
    }),
    markAsInvoiceReceived: builder.mutation({
      query: (id: string) => ({
        url: `/loads/${id}/receive-invoice`,
        method: 'POST',
      }),
      invalidatesTags: ['Receivables', 'Loads'],
    }),
  }),
});

export const {
  useGetReceivablesQuery,
  useGetCustomerInfoMutation,
  useGetDetailedCustomerQuery,
  useGetPaymentDetailsQuery,
  useLazyGetPaymentDetailsQuery,
  useGetCustomerReceiveMutation,
  useSendInvoiceReceivablesMutation,
  useMarkAsInvoiceReceivedMutation,
} = receivablesAPI;
