import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useDeleteFavoriteMutation, useGetFavoriteListQuery } from 'services/truck-board/favorite-trucks/favorite';
import { useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from './constants/constants';

const useFavoriteTrucks = () => {
  const { data: columns } = useGetColumnsQuery({ type: 'truckBoardFavoriteTrucks' });
  const [editColumns] = useEditColumnsMutation();
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab } = searchParams;

  const [favoriteFilter, setFavoriteFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    field: '',
    search: '',
  });

  const { data: favoriteList, isFetching: isFavoriteListFetching } = useGetFavoriteListQuery(favoriteFilter, {
    skip: tab !== 'favorite_trucks',
  });
  const [deleteFavorite] = useDeleteFavoriteMutation();

  useEffect(() => {
    if (!favoriteList?.result.length && favoriteFilter?.skip > 0) {
      setFavoriteFilter(prev => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [favoriteList?.result.length]);

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setFavoriteFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setFavoriteFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const dispatch = useDispatch();
  const handleFavoriteClick = (id: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_REMOVE,
        okText: SUBJECT.REMOVE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteFavorite({ id: Number(id) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
            } else {
              notification.success({
                message: SUBJECT.SUCCESSFULLY_REMOVED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };
  const handlePaginationClick = (skip: number, limit: number) => {
    setFavoriteFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleRowClick = (rowId: number | string, rowData: any) => {
    setSearchParams({
      ...searchParams,
      mode: 'carrierTruckViewFromResult',
      open: 'true',
      truckId: String(rowId),
      carrierId: String(rowData?.carrierId),
    });
  };

  const handleStopResize = (columns: any): void => {
    editColumns({ type: 'truckBoardFavoriteTrucks', columns });
  };

  return {
    favoriteList,
    columns: columns?.columns,
    isFavoriteListFetching,
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handlePaginationClick,
    handleFavoriteClick,
    handleColumnSearch,
    skip: favoriteFilter?.skip,
    limit: favoriteFilter?.limit,
  };
};
export default useFavoriteTrucks;
