import { ActiveTypes, CarrierFileTypes } from './interface';

export const getFactoringCompanyByIdSerializer = (body: any): any => ({
  result: {
    ...body,
    state: body?.state,
  },
});

export const requestCarrierSignUpSerializer = (body: any) => {
  const paymentTerms = [
    ...body.paymentTerms.map((el: any) => {
      return {
        type: el.type,
        ...(el.type === 1 ? { authorityId: el.authorityId[0].id } : {}),
        billingType: el.factoringType,
        factoringCompanyId: el.factoringType === 2 ? undefined : el.factoringCompanyById?.result?.id,
        dealType: el.factoringType === 2 ? el.paymentType : undefined,
        paymentTermType: el.brokerPaymentTermType,
        day: el.day,
        percent: el.percent,
        emails: el.brokerEmails,
        additionalInfo: [],
        files: el.showFiles.map((file: any) => ({ fileId: file.id, type: CarrierFileTypes.NO_FACTORING })),
        paymentAccountNumber: el.factoringType === 2 && el.paymentType === 1 ? el.paymentAccountNumber : undefined,
        paymentRoutingNumber: el.factoringType === 2 && el.paymentType === 1 ? el.paymentRoutingNumber : undefined,
        paymentBankName: el.factoringType === 2 && el.paymentType === 1 ? el.paymentBankName : undefined,
        paymentAddress: el.factoringType === 2 && el.paymentType === 2 ? el.paymentAddress : undefined,
        paymentCity: el.factoringType === 2 && el.paymentType === 2 ? el.paymentCity : undefined,
        paymentCompanyName: el.factoringType === 2 && el.paymentType === 2 ? el.paymentCompanyName : undefined,
        paymentSecondAddress: el.factoringType === 2 && el.paymentType === 2 ? el.paymentSecondAddress : undefined,
        paymentStateId: el.factoringType === 2 && el.paymentType === 2 ? Number(el.paymentStateId) : undefined,
        paymentZip: el.factoringType === 2 && el.paymentType === 2 ? el.paymentZip : undefined,
      };
    }),
    ...(body.preferedFeature
      ? [
          {
            type: 2,
            files: [],
            additionalInfo: [
              { type: 1, status: body?.fuelAdvance ? ActiveTypes.ACTIVE : ActiveTypes.INACTIVE },
              { type: 2, status: body?.quickPay ? ActiveTypes.ACTIVE : ActiveTypes.INACTIVE },
              { type: 3, status: body?.loadBoard ? ActiveTypes.ACTIVE : ActiveTypes.INACTIVE },
              { type: 4, status: body?.liveTruckBoard ? ActiveTypes.ACTIVE : ActiveTypes.INACTIVE },
              { type: 5, status: body?.factoring ? ActiveTypes.ACTIVE : ActiveTypes.INACTIVE },
            ],
          },
        ]
      : []),
  ];
  const files = [
    ...body.carrierPacketFile.map((id: number) => ({ fileId: id, type: CarrierFileTypes.CARRIER_PACKAGE })),
    ...body.companyOwnerIdFile.map((id: number) => ({ fileId: id, type: CarrierFileTypes.COMPANY_ID })),
    ...body.insuranceFile.map((id: number) => ({ fileId: id, type: CarrierFileTypes.INSURANCE })),
    ...body.paidAsW9File.map((id: number) => ({ fileId: id, type: CarrierFileTypes.W9 })),
    ...body.voidedCheckBankLatterFile.map((id: number) => ({ fileId: id, type: CarrierFileTypes.VOIDED_CHECK })),
  ];
  const equipments = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
    type: item,
    status: body?.equipmentType?.includes(item) ? ActiveTypes.ACTIVE : ActiveTypes.INACTIVE,
  }));

  return {
    companyName: body.company,
    phone: body.primaryPhone,
    ...(body.secondaryPhone ? { secondPhone: body.secondaryPhone } : {}),
    ...(body.fax ? { fax: body.fax } : {}),
    contactName: body.contact,
    email: body.email,
    mc: body.MC,
    dot: body.dot,
    ff: body.ff ?? '',
    ...(body.stateNumber ? { stateNumber: body.stateNumber } : {}),
    address: body.address,
    secondAddress: body.secondAddress,
    city: body.city,
    stateId: Number(body.state),
    zip: body.zip,
    physicalAddress: body.physicalAddress,
    physicalSecondAddress: body.physicalSecondAddress,
    physicalCity: body.physicalCity,
    physicalStateId: Number(body.physicalState),
    physicalZip: body.physicalZip,
    physical: body.physical ? 1 : 2,
    numberOfTrucks: body.numberOfTrucks,
    numberOfTrailers: body.numberOfTrailers,
    paymentTerms,
    files,
    equipments,
  };
};
