export enum SUBJECT {
  CANCEL = 'Cancel',
  DEFAULT = 'default',
  RIGHT = 'right',
  SEVENTY_PERCENT = '70%',
  HUNDRED_PERCENT = '100%',
}

export const drawerTabsWithScrollBar = ['carriers', 'loads', 'billing', 'information', 'new_load'];
export const drawerModesWithScrollBar = [
  'edit',
  'tonuEdit',
  'regularLoad',
  'tonuLoad',
  'internalBoardLoad',
  'view',
  'connectingLoad',
];
