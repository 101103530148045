import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import { IGetCommentRequest, IGetCreditRequestForEmployees, RequestStatus } from 'services/loads/interface';
import {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useGetCreditRequestForEmployeesByIdQuery,
  useGetCreditRequestForEmployeesQuery,
  useUpdateCreditStatusMutation,
} from 'services/loads/loads';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { changeLoading as changeLoadingModal, closeModal, openModal } from 'store/modal-slice/modals';
import { selectModals } from 'store/modal-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import CommentsView from './componetns/comments-view/CommentsView';
import NewCreditView from './componetns/new-credit-view/NewCreditView';
import { MODAL_OPTIONS, SUBJECT } from './constants/constants';

const UseNewCredit = (setExcelParams: any) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, id } = searchParams;

  const [creditsState, setCreditsState] = useState<Partial<IGetCreditRequestForEmployees>>({
    search: '',
    skip: 0,
    limit: 20,
    order: 1,
    orderBy: 'status',
    field: '',
  });
  const { isLoading } = useSelector(selectModals);

  const { data = [], isFetching, refetch } = useGetCreditRequestForEmployeesQuery(creditsState);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...creditsState,
        };
      });
    }
  }, [creditsState]);

  const {
    currentData: creditRequestById,
    isFetching: isLoadsCommentsLoading,
    refetch: refetchById,
  } = useGetCreditRequestForEmployeesByIdQuery({ id: Number(id) }, { skip: !id });

  const [getCommentsFilter, setCommentsFilter] = useState<IGetCommentRequest>({
    skip: 0,
    limit: 10,
  });

  const { data: loadsComments } = useGetCommentsQuery(
    { id, ...getCommentsFilter },
    { skip: !id || mode !== 'comments' }
  );

  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const newCreditPagePermissions = usePermittedActions('creditCheck.newCredit');

  const isShowMoreVisible = useMemo(
    () => loadsComments?.count && loadsComments!.count > getCommentsFilter.limit,
    [loadsComments, getCommentsFilter]
  );

  const getMoreComments = () => {
    setCommentsFilter((prev: IGetCommentRequest) => ({ ...prev, limit: prev?.limit + 10 }));
  };

  const handleCreateComment = (text: string) => {
    createComment({ id: Number(id), message: text }).then((messageData: any) => {
      if (messageData.error) {
        notification.error({
          message: ServerErrorCodes[Number(messageData?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        refetch();
      }
    });
  };

  const handleDeleteCommentModal = (commentId: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteComment({ id: Number(id), commentId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetch();
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleColumnSearch = (value: string, column: string) => {
    setCreditsState((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  const [updateCreditStatus, { isLoading: isCreditStatusLoading }] = useUpdateCreditStatusMutation();

  const [isActionModalOpen, setIsActionModalOpen] = useState(false);

  const { data: columnsData } = useGetColumnsQuery({ type: 'newCredit' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    dispatch(closeModal());
    setCommentsFilter({
      skip: 0,
      limit: 10,
    });
    setIsActionModalOpen(false);
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setCreditsState(prev => {
      if (sortOrder === 0) {
        return {
          ...prev,
          order: 1,
          orderBy: 'status',
        };
      }
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const showActionModal = ({ id }: any) => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id,
    });
    setIsActionModalOpen(true);
  };

  const showActionModalinView = () => {
    setIsActionModalOpen(true);
  };

  const handleActionStatus = (status: any, statusId?: string) => {
    setIsActionModalOpen(false);
    dispatch(
      openModal({
        title: `${status} ${SUBJECT.REQUEST}`,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.CANCEL,
        cancelButtonVariant: 'gray',
        okButtonVariant: MODAL_OPTIONS[status as keyof typeof MODAL_OPTIONS].buttonType,
        content: MODAL_OPTIONS[status as keyof typeof MODAL_OPTIONS].itemContent,
        onOk: () => {
          if (!isLoading) {
            dispatch(changeLoadingModal(true));
            updateCreditStatus({ id: id ? Number(id) : Number(statusId), status: RequestStatus[status] }).then(
              (data: any) => {
                if (data.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(closeModal());
                  dispatch(changeLoadingModal(false));
                } else {
                  notification.success({
                    message: 'Successfully sent',
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  if (!isCreditStatusLoading) {
                    handleCloseModal();
                  }
                  dispatch(changeLoadingModal(false));
                  refetchById();
                  refetch();
                }
              }
            );
          }
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setCreditsState(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleCommentClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      mode: 'comments',
      open: 'true',
      id: rowId,
    });
  };

  const handleCancel = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({ setDragColumns, deleteColumns, editColumns, handleCloseModal, dragColumns, type: 'newCredit' });

  const handleStopResize = (columns: any) => {
    editColumns({ type: 'newCredit', columns });
  };

  useEffect(() => {
    if (columnsData?.columns?.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    view: {
      component: (
        <NewCreditView
          showActionModal={showActionModalinView}
          data={creditRequestById}
          isLoadingById={isLoadsCommentsLoading}
        />
      ),
    },
    comments: {
      component: (
        <CommentsView
          creditRequest={creditRequestById!}
          createComment={handleCreateComment}
          loadsComments={loadsComments}
          deleteComment={handleDeleteCommentModal}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          isLoading={isLoadsCommentsLoading}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      withFooter: mode === 'customize',
      onSubmit: drawerChildren[mode]?.onSubmit,
    };
  }, [
    mode,
    setDragColumns,
    dragColumns,
    creditRequestById,
    loadsComments,
    id,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  return {
    isFetching,
    data,
    columns: columnsData?.columns,
    currentChildren,
    isActionModalOpen,
    handleSortClick,
    handleClose: handleCloseModal,
    setIsActionModalOpen,
    handleActionStatus,
    showActionModal,
    handleRowClick,
    handleStopResize,
    handlePaginationClick,
    handleCommentClick,
    skip: creditsState.skip,
    limit: creditsState.limit,
    pagePermissions: newCreditPagePermissions,
    handleColumnSearch,
  };
};

export default UseNewCredit;
