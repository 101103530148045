import { useDetectedParams } from 'hooks/useDetectedParams';

export const useView = () => {
  const { searchParams, setSearchParams } = useDetectedParams();

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      mode: 'edit',
      open: 'true',
    });
  };

  return { handleChangeMode };
};
