import { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import FilterButtons from 'ui/filter-buttons/FilterButtons';
import Typography from 'ui/typography/Typography';

import MainDrawer from 'components/main-drawer/MainDrawer';
import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import Table from 'components/table/Table';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';

import { DEFAULT_AGENT_TYPE_OPTIONS, SUBJECT } from './constants/constants';
import { IAgentsParams } from './constants/types';
import { FilterSelect, SearchableSelectForFilter } from './hoc-instance/instance';
import useAgent from './useAgent';

import styles from './Agent.module.scss';

const Agent = ({ setExcelParams, sendAgentsActivityWatchMessage }: IAgentsParams) => {
  const {
    data,
    columns,
    isFetching,
    filterControl,
    resetFilter,
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handleSwitchClick,
    handleColumnSearch,
    handlePaginationClick,
    handleRowEdit,
    setOfficesFilter,
    handleInviteAgain,
    skip,
    limit,
    filterOfficeData,
    filterBranchesData,
    office_filter,
    currentChildren: { component, buttonText, onCancel, onSubmit, withFooter },
    setBranchesFilter,
    handleResetPassword,
    handleOfficeFilterSelect,
    setFilterValue,
    handleBranchFilterSelect,
    resetOfficeParams,
    resetBranchParams,
    activeFilter,
    handleActiveFilter,
    filterOfficeValue,
    filterBranchValue,
    filterWatch,
    agentPagePermissions: { edit, terminateOff, terminateOn },
    roleLevel,
    onlineAgents,
    updatedResult,
    isSelectedDefaultBranch,
  } = useAgent({ setExcelParams, sendAgentsActivityWatchMessage });

  const tableProps = useDeferredValue({
    data: {
      ...data,
      result: updatedResult || [],
    },
    skip,
    limit,
    columns,
    isLoading: isFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onColumnSearch: handleColumnSearch,
    onSwitchClick: handleSwitchClick,
    handleRowEdit,
    onPaginationClick: handlePaginationClick,
    onResetPassword: handleResetPassword,
    onStatusClick: handleInviteAgain,
    hasTerminateOnPermission: terminateOn,
    hasTerminateOffPermission: terminateOff,
    hasEditPermission: edit,
    withMenu: edit,
  });

  return (
    <>
      <div className="page-content">
        <div className="filtersWrapper">
          <div className={styles.agentTopBlock}>
            <div className={styles.agentMainFilter}>
              <Row justify="space-between" align="bottom">
                <Col span={14}>
                  {!isFetching && (
                    <Row gutter={[18, 0]} align="middle">
                      {roleLevel === IRoleLevel.corpLevel && (
                        <Col span={8}>
                          <SearchableSelectForFilter
                            options={filterOfficeData?.result}
                            control={filterControl}
                            label={SUBJECT.OFFICE}
                            placeholder={SUBJECT.NOT_SELECTED}
                            name="office_filter"
                            offset={filterOfficeData?.count}
                            setOffset={setOfficesFilter}
                            showKey="title"
                            handleClick={handleOfficeFilterSelect}
                            setValue={setFilterValue}
                            value={filterOfficeValue}
                            isFilter
                            allowClear
                            resetFunc={resetOfficeParams}
                          />
                        </Col>
                      )}
                      {roleLevel <= IRoleLevel.officeLevel && (
                        <Col span={8}>
                          <SearchableSelectForFilter
                            options={filterBranchesData?.result || []}
                            control={filterControl}
                            label={SUBJECT.BRANCH}
                            placeholder={SUBJECT.NOT_SELECTED}
                            disabled={roleLevel === IRoleLevel.officeLevel ? false : !office_filter}
                            name="branch_filter"
                            showKey="title"
                            offset={filterBranchesData?.count}
                            setOffset={setBranchesFilter}
                            setValue={setFilterValue}
                            value={filterBranchValue}
                            handleClick={handleBranchFilterSelect}
                            isFilter
                            allowClear
                            resetFunc={resetBranchParams}
                          />
                        </Col>
                      )}
                      <Col span={8}>
                        <FilterSelect
                          withAll
                          suffixIcon={<SelectArrowIcon />}
                          options={DEFAULT_AGENT_TYPE_OPTIONS.slice(0, 5 - roleLevel)}
                          control={filterControl}
                          label={SUBJECT.AGENT_TYPE}
                          placeholder={SUBJECT.NOT_SELECTED}
                          value={filterWatch('agent_type_filter')}
                          name="agent_type_filter"
                          isFilter
                          isDisabled={isSelectedDefaultBranch}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
                {!isFetching && (
                  <Col span={10}>
                    <Row justify="end" align="middle" gutter={13} className={styles.allUsersBlock}>
                      <Col>
                        <FilterButtons
                          text={SUBJECT.ALL}
                          value={`${data?.totalCount || '0'}`}
                          color="#388DFF"
                          isActive={activeFilter == 1}
                          onClick={() => handleActiveFilter(1)}
                        />
                      </Col>
                      <Col>
                        <FilterButtons
                          text={SUBJECT.ACTIVE_USERS}
                          value={`${data?.online + onlineAgents?.length || '0'}`}
                          color="#34A853"
                          isActive={activeFilter == 2}
                          onClick={() => handleActiveFilter(2)}
                        />
                      </Col>
                      <Col>
                        <FilterButtons
                          text={SUBJECT.PASSIVE}
                          value={`${data?.passive && data?.passive - onlineAgents?.length}`}
                          color="#FF5454"
                          isActive={activeFilter == 3}
                          onClick={() => handleActiveFilter(3)}
                        />
                      </Col>
                      {(filterBranchValue ||
                        filterOfficeValue ||
                        filterWatch('agent_type_filter') ||
                        !!activeFilter) && (
                        <Col>
                          <Button
                            icon={<ResetIcon />}
                            variant="ghost"
                            onClick={resetFilter}
                            children={SUBJECT.RESET_ALL}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
        {!isFetching && !data && (
          <div className="page-content">
            <div className={styles.agentsEmpty}>
              <Typography variant="paragraph">{SUBJECT.AGENTS_LIST_IS_EMPTY}</Typography>
            </div>
          </div>
        )}
        <Table {...tableProps} />
      </div>
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
      />
    </>
  );
};
export default Agent;
