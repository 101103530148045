import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { AgentType } from 'services/profile/agents/interfaces';
import { ICarrierStatus, ICarrierStatusForCarrier } from 'services/profile/carriers/interfaces';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatPhone } from 'utils/helpers';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { useDetectedParams } from '../../../../../../hooks/useDetectedParams';

import { SUBJECT } from './constants/constants';
import useCarrierRequestInfo from './useCarrierRequestInfo';

import styles from './CarrierRequestInfo.module.scss';

const ButtonsComponent = ({ approve, handleApprove, deny, handleDeny, approveText, denyText }: any) => {
  return (
    <>
      {approve && <Button onClick={handleApprove} variant="primary" children={approveText} />}
      {deny && <Button onClick={handleDeny} variant="danger" children={denyText} />}
    </>
  );
};

const CarrierRequestInfo = ({ data, isFetching, carrierRequestPagePermissions: { approve, deny } }: any) => {
  const { searchParams } = useDetectedParams();
  const { activePage } = searchParams;
  const isCarrierOnBoard = activePage === 'carrierOnBoard';

  const { handleApprove, handleDeny } = useCarrierRequestInfo();
  const carrierStatusTexts = {
    [ICarrierStatus.Standart]: 'Standart',
    [ICarrierStatus.Pending]: 'Pending',
    [ICarrierStatus.Denied]: 'Denied',
  };

  const WrapperChildren = useMemo(() => {
    if (isCarrierOnBoard) {
      return data?.status === ICarrierStatusForCarrier.Standart ? (
        <ButtonsComponent
          approve={approve}
          handleApprove={handleApprove}
          deny={deny}
          handleDeny={handleDeny}
          approveText={SUBJECT.REVIEW}
          denyText={SUBJECT.DENY}
        />
      ) : (
        <Typography
          variant="paragraph"
          children={data?.status === ICarrierStatusForCarrier.Denied ? 'Denied' : ''}
          className={clsx(styles.infoParagraph, {
            [styles.infoParagraphDenied]: data?.status === ICarrierStatusForCarrier.Denied,
          })}
        />
      );
    } else {
      return data?.status === ICarrierStatus.Denied ? (
        <Typography
          variant="paragraph"
          children={data?.status === ICarrierStatus.Denied ? 'Denied' : ''}
          className={clsx(styles.infoParagraph, {
            [styles.infoParagraphDenied]: data?.status === ICarrierStatus.Denied,
          })}
        />
      ) : (
        <ButtonsComponent
          approve={approve}
          handleApprove={handleApprove}
          deny={deny}
          handleDeny={handleDeny}
          approveText={SUBJECT.APPROVE}
          denyText={SUBJECT.DENY}
        />
      );
    }
  }, [isCarrierOnBoard, isFetching]);

  return (
    <>
      <MainLoader isFetching={isFetching} />

      <ViewItemWrapper title={SUBJECT.CARRIER_REQUEST_INFO} Component={WrapperChildren}>
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE_AND_TIME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.COMPANY_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.companyName}
                  subject={SUBJECT.COMPANY_NAME}
                />
              </Col>
            </Row>
          </Col>
          {data?.phone && (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Link className="top-banner-value" to={`tel:${data?.phone}`}>
                    {formatPhone(data?.phone)}
                  </Link>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CONTACT_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.contactName}
                  subject={SUBJECT.CONTACT_NAME}
                />
              </Col>
            </Row>
          </Col>
          {data?.mc && (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.MC} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText prefixClass={styles.infoParagraph} data={data?.mc} subject={SUBJECT.MC} />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DOT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText prefixClass={styles.infoParagraph} data={data?.dot} subject={SUBJECT.DOT} />
              </Col>
            </Row>
          </Col>
          {!isCarrierOnBoard && (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={`${data?.user?.username}`}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!isCarrierOnBoard && (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.CARRIER_TYPE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" children={data?.type} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
          )}
          {!isCarrierOnBoard && (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.AGENT_TYPE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={AgentType[data?.user?.agentType]}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {data?.status === ICarrierStatusForCarrier.Denied || data?.status === ICarrierStatus.Denied ? (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ACTION} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={carrierStatusTexts[data.status as ICarrierStatus]}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      </ViewItemWrapper>
    </>
  );
};

export default CarrierRequestInfo;
