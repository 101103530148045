import { formatDate } from 'utils/dates';

export const filesFormatter = (files: any) => {
  const formattedFiles = files?.map((file: any) => {
    return {
      download: file?.file?.download,
      preview: file?.file?.preview,
      id: file?.file?.id,
      name: file?.file?.name,
      createdAt: formatDate(file?.createdAt),
    };
  });
  return formattedFiles;
};

export const invoiceFilesFormatter = (invoice: any) => {
  return [
    {
      download: invoice?.file?.download,
      preview: invoice?.file?.preview,
      id: invoice?.file?.id,
      name: invoice?.file?.name,
      createdAt: formatDate(invoice?.createdAt),
    },
  ];
};

export const filesNoaFormatter = (files: any) => {
  const formattedFiles = files?.map((file: any) => {
    return {
      download: file?.download,
      preview: file?.preview,
      id: file?.id,
      name: file?.name,
      createdAt: formatDate(file?.createdAt),
    };
  });
  return formattedFiles;
};
