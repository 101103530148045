import * as yup from 'yup';

import { ITransactionTypes } from '../../../../../../loads/components/edit-load/constants/constants';

import { VALIDATION_MESSAGES } from './constants';

const { TRANSACTION_TYPE_REQUIRED, CUSTOMER_AMOUNT_REQUIRED, ONLY_NOT_ZERO, CUSTOMER_WITH_TYPE, CUSTOMER_POSITIVE } =
  VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  transactionType: yup.string().min(1, TRANSACTION_TYPE_REQUIRED).required(TRANSACTION_TYPE_REQUIRED),
  customer: yup.string().test('customerRequirements', CUSTOMER_AMOUNT_REQUIRED, function checkValidation(value) {
    const isSpecialTypes =
      this.parent.transactionType == ITransactionTypes.Miscellaneous ||
      this.parent.transactionType == ITransactionTypes.Lumper;
    if (
      String(value).replace('.', '')! == '0' ||
      String(value).replace('.', '')! == '-0' ||
      String(value).replace('.', '')! == '-'
    ) {
      return this.createError({
        message: isSpecialTypes ? CUSTOMER_POSITIVE : ONLY_NOT_ZERO,
        path: 'customer',
      });
    }
    if (value?.length && value.includes('-') && isSpecialTypes) {
      return this.createError({
        message: CUSTOMER_WITH_TYPE,
        path: 'customer',
      });
    } else return !!value?.length;
  }),

  transactionComment: yup.string().notRequired(),
});
