import { useForm } from 'react-hook-form';

import { ICommentViewForm } from './constants/types';

export const useCommentsView = () => {
  const { control, watch, setValue } = useForm<ICommentViewForm>({
    defaultValues: { comment: '' },
  });

  return {
    control,
    watch,
    setValue,
  };
};
