import { ITransactionFormData } from './types';

export const editDefaultValues = {
  load_type: '',
  customerName: '',
  referenceNumber: '',
  customerAmount: '',
  customerContact: '',
  customerContactName: '',
  carrier: [],
  carrierAmount: '',
  truck: [],
  agent: [],
  agentAmount: '',
  authorities: [],
  allAuthorities: null,
  file: [],
  soldUsSwitch: false,
  markAsSwitch: false,
  transportType: '',
  celsius: '',
  takenAs: '',
  givenAs: '',
  commodity: '',
  bookedAs: '',
  soldAs: '',
  feetPartial: '',
  weight: '',
  pickups: [],
  dropOffs: [],
  firstAgentName: '',
};

export enum SUBJECT {
  LOAD_TYPE = 'Load Type',
  CUSTOMER_NAME = 'Customer Name',
  REFERENCE_NUMBER = 'Reference Number',
  CUSTOMER_AMOUNT = 'Customer Amount',
  CUSTOMER_CONTACT = 'Phone Number',
  CARRIER = 'Carrier',
  CREATE_RATE = 'Create New Carrier Rate Confirmation',
  CARRIER_AMOUNT = 'Carrier Rate',
  TRUCK = 'Truck',
  ALL_AUTHORITIES = 'All Authorities',
  OFFICE_NAME = 'Agency Name:',
  BRACH_NAME = 'Branch Name:',
  FIRST_AGENT_NAME = 'First Agent Name',
  FIRST_AGENT_AMOUNT = 'First Agent Amount',
  SECOND_AGENT_NAME = 'Second Agent Name',
  SECOND_AGENT_AMOUNT = 'Second Agent Amount',
  CARRIER_PAYMENT_BREAKDOWN = 'Carrier Payment Breakdown',
  CARRIER_PAYMENT_BREAKDOWN_ID = 'carrier-payment-breakdown',
  SOLD_US_SWITCH = 'Sold As',
  GENERAL_INFORMATION_ID = 'general-information',
  BILLING_ID = 'billing-block',
  GENERAL_INFO = 'General Information',
  TAKEN_AS_MC = 'Taken As MC:',
  LOAD_INFO_ID = 'load-info',
  LOAD_INFO = 'Load Detail',
  CELSIUS = 'Refrigerator Fahrenheit',
  TAKEN_AS = 'Taken As',
  GIVEN_AS = 'Given As',
  COMMODITY = 'Commodity',
  BOOKED_AS = 'Booked As',
  SOLD_AS = 'Sold As',
  FEET_OF_PARTIAL = 'Feet Of Partial',
  WEIGHT = 'Weight',
  DOCUMENTATION_ID = 'documentation',
  DOCUMENTATION = 'Documents',
  UPLOAD_RED_CONFIRMATION = 'Upload Rate Confirmation',
  CARRIER_RATE_CONFIRMATION = 'Carrier Rate Confirmation',
  CARRIER_RATE_CONFIRMATION_ID = 'carrier-rate-confirmation',
  SENT_TO_BILLING = 'Sent To Billing',
  SEND_TO_BILLING = 'Send To Billing',
  ARE_YOU_BILLING = 'Are You Sure Want To Send To Billing',
  SEND = 'Send',
  ALLOWED_FORMAT = 'Allowed Format (jpeg, pdf, tiff, png)',
  ALLOWED_FORMAT_PDF = 'Allowed Format (pdf)',
  DOWNLOAD_ALL = 'Download All',
  UPLOAD_POD = 'Upload POD',
  PICKUPS_DROPOFFS_ID = 'pickupps-drop-offs',
  LOAD_FINANCE_ID = 'load-finance',
  TONU_LOAD_FINANCE_ID = 'loads-finance',
  LOAD_FINANCE = 'Load Finance',
  CUSTOMER_BALANCE = 'Balance',
  INIT_RATE = 'Initial Rate',
  FINAL_RECEIVABLE = 'Final Receivable',
  AMOUNT_PAID_CUSTOMER = 'Amount Received',
  AMOUNT_PAID = 'Amount Paid',
  CARRIER_BALANCE = 'Balance',
  QUICK_PAY = 'Quick pay',
  AGENT_BALANCE = 'Initial Profit',
  FINAL_PROFIT = 'Final Profit',
  TRANSACTION_ID = 'transaction',
  TRANSACTION = 'Transaction',
  TRANSACTION_TYPE = 'Transaction Type',
  CUSTOMER = 'Customer',
  BALANCE_AMOUNT = 'Balance Amount',
  WRITE_COMMENT = 'Write Comment',
  ADD_TRANSACTION = 'Add Transaction',
  COMMENT_ID = 'comment',
  EDIT_REGULAR_LOAD = 'Edit Load',
  AGENT = 'Agent',
  AGENT_AMOUNT = 'Agent amount',
  FINAL_PAYABLE = 'Final Payable',
  ARE_YOU_SURE_LOAD = 'Are You Sure You Want To Cancel Load?',
  CANCEL = 'Cancel',
  NO = 'No',
  ARE_YOU_SURE = 'Are You Sure You Want To Delete Transaction?',
  JUST_ARE_YOU_SURE = 'Are You Sure?',
  ARE_YOU_SURE_ACCEPT = 'Do You Want To Send An Invoice To The Customer?',
  DELETE = 'Delete',
  RESET = 'Reset',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  SUCCESSFULLY_UPDATED = 'Successfully Updated',
  ARE_YOU_SURE_CLAIM = 'Are You Sure You Want To Mark As Claim?',
  YES = 'Yes',
  REQUEST_SENT_SUCCESSFULLY = 'Request Sent Successfully',
  INVOICE_SUCCESSFULLY = 'Invoice Successfully Created',
  ACCEPT = 'Accept',
  REJECT = 'Reject',
  ACCEPTED_SUCCESSFULLY = 'Accepted Successfully',
  REJECTED_SUCCESSFULLY = 'Rejected Successfully',
  CLOSE = 'Close',
  UPLOAD_CONFIRMING_FILES = 'Upload Confirming Documents',
  CREATE_INVOICE_IN_PROCCESS = 'Invoice Generation In Process',
  SECOND_AGENT = '2 Agent',
  FIRST_AGENT = 'Agent 1',
  SECOND_AGENT_HEADER = 'Agent 2',
  CONTACT_NAME = 'Customer Contact Name',
  CARRIER_INVOICE = 'CARRIER_INVOICE',
}

export const CARRIER_OPTIONS = [
  { id: '1', name: 'John', mc: '12659891' },
  { id: '2', name: 'Emanuel', mc: '24456789' },
  { id: '3', name: 'Johnathan', mc: '65656548' },
];

export const CARRIER_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
];

export const CARRIER_KEYS = Object.keys(CARRIER_OPTIONS[0]);

export const ALL_AUTHORITIES_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
];

export const CONNECTING_SECTIONS = () => {
  const sections = [
    {
      id: 'connecting-load',
      title: 'Connecting load',
    },
    {
      id: 'general-information',
      title: 'General Information',
    },
    {
      id: 'load-finance',
      title: 'Load Finance',
    },
    {
      id: 'transaction',
      title: 'Transaction',
    },
    {
      id: 'load-info',
      title: 'Load Detail',
    },
    {
      id: 'pickupps-drop-offs',
      title: 'Pick Ups & Drop Offs',
    },
    {
      id: 'carrier-rate-confirmation',
      title: 'Carrier Rate Confirmation',
    },
    {
      id: 'carrier-payment-breakdown',
      title: 'Carrier Payment Breakdown',
    },
    {
      id: 'documentation',
      title: 'Documents',
    },
    {
      id: 'billing-block',
      title: 'Billing',
    },
    {
      id: 'comment',
      title: 'Comment',
    },
  ];

  return sections;
};
export const CONNECTING_SECTIONS_VIEW = [
  {
    id: 'connecting-load',
    title: 'Connecting load',
  },
  {
    id: 'load-finance',
    title: 'Load Finance',
  },
  {
    id: 'transaction-id',
    title: 'Transactions',
  },
  {
    id: 'load-information-id',
    title: 'Load Detail',
  },
  {
    id: 'pickups-dropoffs-id',
    title: 'Pick Ups & Drop Offs',
  },
  {
    id: 'second-agent-id',
    title: 'Second Agent',
  },
  {
    id: 'customer-id',
    title: 'Customer Detail',
  },
  {
    id: 'rate-confirmation-id',
    title: 'Customer Rate Confirmation',
  },
  {
    id: 'carrier-id',
    title: 'Carrier Detail',
  },
  {
    id: 'carrier-rate-confirmation-id',
    title: 'Carrier Rate Confirmation',
  },
  {
    id: 'breakdown-id',
    title: 'Carrier Payment Breakdown',
  },

  {
    id: 'confirmation-documentation-id',
    title: 'Documents',
  },
  {
    id: 'billing-id',
    title: 'Billing',
  },
  {
    id: 'comment',
    title: 'Comment',
  },
];

export enum ITransactionTypes {
  'Detention Layover' = 1,
  'Driver Assist' = 2,
  'Late Fee' = 3,
  'Lumper' = 4,
  'Fuel Advance' = 5,
  'Rate Adjustment' = 6,
  'Warehouse' = 7,
  'Claimed' = 8,
  'Resolved' = 9,
  'Bonus' = 10,
  'Miscellaneous' = 11,
}

export enum ITransactionLabelTypes {
  'Standard' = 1,
  'Credit' = 2,
  'Charge' = 3,
}

export enum VALIDATION_MESSAGES {
  ONLY_NOT_ZERO = 'Customer amount must be either positive or negative number',
  CUSTOMER_WITH_TYPE = 'Customer amount must be positive with this transaction type',
  CUSTOMER_POSITIVE = 'Customer amount must be positive number',

  CARRIER_ONLY_NOT_ZERO = 'Carrier amount must be either positive or negative number',
  CARRIER_WITH_TYPE = 'Carrier amount must be positive with this transaction type',
  CARRIER_POSITIVE = 'Carrier amount must be positive number',

  SECOND_ONLY_NOT_ZERO = 'Second agent amount must be either positive or negative number',
  SECOND_POSITIVE = 'Carrier amount must be positive number',
  MIN_OPTION = 'At least one option is required',
  POSITIVE = 'Please enter a positive number',
  INTEGER = 'Please enter an integer',
  MIN_NUMBER = 'Number must be greater than or equal to 0',
  VALID_NUMBER = 'Please enter a valid number',
  MAX_NUMBER = 'Carrier amount must be less than or equal to Customer Amount',
  PHONE = 'Incorrect phone format',
  ZIP_REQUIRED = 'Zip Code is a required field',
  STATE_REQUIRED = 'State is a required field',
  CITY_REQUIRED = 'City is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  FACILITY_REQUIRED = 'Facility is a required field',
  APPOINTMENT_DATE_REQUIRED = 'Appointment date is a required field',
  APPOINTMENT_TIME_REQUIRED = 'Appointment time is a required field',
  DROP_TYPE_REQUIRED = 'Drop type is a required field',
  WEIGHT_REQUIRED = 'Weight is a required field',
  FEET_OF_PARTIAL_REQUIRED = 'Feet of partitial is a required field',
  SOLD_AS_REQUIRED = 'Sold as is a required field',
  BOOKED_AS_REQUIRED = 'Booked as is a required field',
  GIVEN_AS_REQUIRED = 'Given as is a required field',
  TAKEN_AS_REQUIRED = 'Taken as is a required field',
  CELCIUS_REQUIRED = 'Celcius is a required field',
  LOAD_BY_TYPE_REQUIRED = 'Load by type is a required field',
  AUTHORITIES_REQUIRED = 'Authorities is a required field',
  AGENT_AMOUNT_REQUIRED = 'Agent amount is a required field',
  AGENT_REQUIRED = 'Agent is a required field',
  CUSTOM_CONTACT_REQUIRED = 'Custom contact is a required field',
  CUSTOM_AMOUNT_REQUIRED = 'Custom amount is a required field',
  REFERENCE_NUMBER_REQUIRED = 'Reference number is a required field',
  CUSTOMER_NAME_REQUIRED = 'Customer name is a required field',
  CARRIER_REQUIRED = 'Carrier is a required field',
  CARRIER_AMOUNT_REQUIRED = 'Carrier rate is a required field',
  CUSTOMER_CONTACT_REQUIRED = 'Phone number is a required field',
  CUSTOMER_AMOUNT_REQUIRED = 'Customer amount is a required field',
  CELSIUS_REQUIRED = 'Fahrenheit is a required field',
  LOAD_TYPE_REQUIRED = 'Load type is a required field',
  COMMODITYY_REQUIRED = 'Commodity is a required field',
  TRANSACTION_TYPE_REQUIRED = 'Transaction type is a required field',
  COMMODITY_REQUIRED = 'Commodity is a required field',
  TRUCK_REQUIRED = 'Truck is a required field',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
  MAX_DIGIT = 'Maximum of 10 digits allowed',
  CUSTOMER_CONTACT_NAME_REQUIRED = 'Phone Number is a required field',
  CARRIER_AMOUNT_NOT_ZERO = 'Carrier amount should be positive number',
  NOT_ZERO = 'Should be a positive number',
  NOT_GRADER_THAN = "Weight couldn't be more than 7 digits",
  LESS_THAN_MILLION = 'Amount must be less than a million',
}

export const REGULAR_SECTIONS = [
  {
    id: 'regular-load',
    title: 'Regular Load',
  },
  {
    id: 'general-information',
    title: 'General Information',
  },
  {
    id: 'load-finance',
    title: 'Load Finance',
  },
  {
    id: 'transaction',
    title: 'Transaction',
  },
  {
    id: 'load-info',
    title: 'Load Detail',
  },
  {
    id: 'pickupps-drop-offs',
    title: 'Pick Ups & Drop Offs',
  },
  {
    id: 'carrier-rate-confirmation',
    title: 'Carrier Rate Confirmation',
  },
  {
    id: 'carrier-payment-breakdown',
    title: 'Carrier Payment Breakdown',
  },
  {
    id: 'documentation',
    title: 'Documents',
  },
  {
    id: 'billing-block',
    title: 'Billing',
  },
  {
    id: 'comment',
    title: 'Comment',
  },
];
export const REGULAR_SECTIONS_VIEW = [
  {
    id: 'regular-load',
    title: 'Regular Load',
  },
  {
    id: 'regular-finance',
    title: 'Load Finance',
  },
  {
    id: 'regular-transaction',
    title: 'Transactions',
  },
  {
    id: 'regular-information',
    title: 'Load Detail',
  },
  {
    id: 'regular-pickup-dropoffs',
    title: 'Pick Ups & Drop Offs',
  },
  {
    id: 'regular-customer',
    title: 'Customer Detail',
  },
  {
    id: 'regular-rate-confirmation',
    title: 'Customer Rate Confirmation',
  },
  {
    id: 'regular-carrier',
    title: 'Carrier Detail',
  },
  {
    id: 'regular-bottom-carrier-confirmation',
    title: 'Carrier Rate Confirmation',
  },
  {
    id: 'regular-carrier-breakdown',
    title: 'Carrier Payment Breakdown',
  },
  {
    id: 'regular-confirmation-documentation',
    title: 'Documents',
  },
  {
    id: 'regular-billing',
    title: 'Billing',
  },
  {
    id: 'comment',
    title: 'Comment',
  },
];

export const INTERNAL_SECTIONS = [
  {
    id: 'internal-load',
    title: 'Internal load',
  },
  {
    id: 'general-information',
    title: 'General Information',
  },
  {
    id: 'billing-block',
    title: 'Billing',
  },
  {
    id: 'carrier-payment-breakdown',
    title: 'Payment breakdown',
  },
  {
    id: 'carrier-rate-confirmation',
    title: 'Rate Confirmation',
  },
  {
    id: 'documentation',
    title: 'Documentation',
  },
  {
    id: 'load-info',
    title: 'Load Info',
  },
  {
    id: 'pickupps-drop-offs',
    title: 'Pick Ups & Drop Offs',
  },
  {
    id: 'transaction',
    title: 'Transaction',
  },
  {
    id: 'comment',
    title: 'Comment',
  },
];
export const INTERNAL_SECTIONS_VIEW = [
  {
    id: 'internal-load',
    title: 'Internal load',
  },
  {
    id: 'customer-id',
    title: 'Customer',
  },
  {
    id: 'rate-confirmation-id',
    title: 'Rate Confirmation',
  },
  {
    id: 'load-information-id',
    title: 'Load information',
  },
  {
    id: 'pickup-dropoffs-id',
    title: 'Pick Ups & Drop Offs',
  },
  {
    id: 'load-transaction-id',
    title: 'Load transaction',
  },
  {
    id: 'comment',
    title: 'Comment',
  },
];

export const TRANSACTION_TYPES = [
  { title: 'Detention Layover', value: '1' },
  { title: 'Driver Assist', value: '2' },
  { title: 'Late Fee', value: '3' },
  { title: 'Lumper', value: '4' },
  { title: 'Fuel Advance', value: '5' },
  { title: 'Warehouse', value: '7' },
  { title: 'Rate Adjustment', value: '6' },
  { title: 'Miscellaneous', value: '11' },
];

export const transactionDefalultValue: ITransactionFormData = {
  transactionType: '',
  customer: '',
  carrierTransaction: '',
  balanceAmount: '',
  secondAgent: '',
  transactionComment: '',
};
