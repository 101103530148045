import React from 'react';

const PotentialGrossProfitIcon = () => {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.3227" cy="23.5727" r="15.3227" stroke="var(--black)" strokeWidth="1.5" />
      <circle
        opacity="0.5"
        cx="21.8933"
        cy="11.573"
        r="1.07151"
        transform="rotate(180 21.8933 11.573)"
        fill="var(--black)"
      />
      <circle
        opacity="0.5"
        cx="34.3235"
        cy="23.1465"
        r="1.07151"
        transform="rotate(-90 34.3235 23.1465)"
        fill="var(--black)"
      />
      <circle
        opacity="0.5"
        cx="11.1789"
        cy="23.1465"
        r="1.07151"
        transform="rotate(-90 11.1789 23.1465)"
        fill="var(--black)"
      />
      <line x1="21.7341" y1="24.7974" x2="30.1628" y2="17.5769" stroke="var(--black)" strokeLinecap="round" />
      <line
        x1="22.6113"
        y1="24.218"
        x2="16.5358"
        y2="18.1425"
        stroke="var(--black)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M39.9623 33.9994C39.9623 38.6932 36.1573 42.4982 31.4636 42.4982C26.7698 42.4982 22.9648 38.6932 22.9648 33.9994C22.9648 29.3057 26.7698 25.5007 31.4636 25.5007C36.1573 25.5007 39.9623 29.3057 39.9623 33.9994Z"
        fill="#252733"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4636 41.5539C35.6357 41.5539 39.018 38.1716 39.018 33.9994C39.018 29.8273 35.6357 26.445 31.4636 26.445C27.2914 26.445 23.9091 29.8273 23.9091 33.9994C23.9091 38.1716 27.2914 41.5539 31.4636 41.5539ZM31.4636 42.4982C36.1573 42.4982 39.9623 38.6932 39.9623 33.9994C39.9623 29.3057 36.1573 25.5007 31.4636 25.5007C26.7698 25.5007 22.9648 29.3057 22.9648 33.9994C22.9648 38.6932 26.7698 42.4982 31.4636 42.4982Z"
        fill="#F0F2F5"
      />
      <path
        d="M30.7986 38.7211H32.2767V37.6897H33.4591C33.7055 37.6897 33.9066 37.598 34.0626 37.4147C34.2187 37.2313 34.2967 37.025 34.2967 36.7958V34.1659C34.2967 33.9175 34.2187 33.7054 34.0626 33.5297C33.9066 33.354 33.7055 33.2661 33.4591 33.2661H30.1089V31.6847H34.2967V30.3095H32.2767V29.2781H30.7986V30.3095H29.5916C29.3452 30.3095 29.1235 30.4012 28.9265 30.5845C28.7294 30.7679 28.6309 30.9827 28.6309 31.229V33.8365C28.6309 34.0827 28.7294 34.2784 28.9265 34.4236C29.1235 34.5688 29.3452 34.6413 29.5916 34.6413H32.8186V36.3145H28.6309V37.6897H30.7986V38.7211Z"
        fill="#F0F2F5"
      />
      <path
        d="M45.3308 31.317C45.3308 34.0344 43.128 36.2373 40.4105 36.2373C37.6931 36.2373 35.4902 34.0344 35.4902 31.317C35.4902 28.5996 37.6931 26.3967 40.4105 26.3967C43.128 26.3967 45.3308 28.5996 45.3308 31.317Z"
        fill="#252733"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4105 35.6906C42.826 35.6906 44.7841 33.7325 44.7841 31.317C44.7841 28.9016 42.826 26.9434 40.4105 26.9434C37.9951 26.9434 36.0369 28.9016 36.0369 31.317C36.0369 33.7325 37.9951 35.6906 40.4105 35.6906ZM40.4105 36.2373C43.128 36.2373 45.3308 34.0344 45.3308 31.317C45.3308 28.5996 43.128 26.3967 40.4105 26.3967C37.6931 26.3967 35.4902 28.5996 35.4902 31.317C35.4902 34.0344 37.6931 36.2373 40.4105 36.2373Z"
        fill="#F0F2F5"
      />
      <path
        d="M40.0246 34.0507H40.8803V33.4536H41.5648C41.7075 33.4536 41.8239 33.4005 41.9142 33.2944C42.0046 33.1882 42.0497 33.0688 42.0497 32.9361V31.4135C42.0497 31.2697 42.0046 31.147 41.9142 31.0452C41.8239 30.9435 41.7075 30.8926 41.5648 30.8926H39.6252V29.977H42.0497V29.1809H40.8803V28.5837H40.0246V29.1809H39.3257C39.1831 29.1809 39.0548 29.2339 38.9407 29.3401C38.8266 29.4463 38.7695 29.5706 38.7695 29.7132V31.2228C38.7695 31.3654 38.8266 31.4787 38.9407 31.5627C39.0548 31.6468 39.1831 31.6888 39.3257 31.6888H41.194V32.6575H38.7695V33.4536H40.0246V34.0507Z"
        fill="#F0F2F5"
      />
      <g opacity="0.7">
        <path
          d="M41.7525 38.919C41.7525 40.8953 40.1504 42.4974 38.1741 42.4974C36.1978 42.4974 34.5957 40.8953 34.5957 38.919C34.5957 36.9427 36.1978 35.3406 38.1741 35.3406C40.1504 35.3406 41.7525 36.9427 41.7525 38.919Z"
          fill="#252733"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.1741 42.0998C39.9308 42.0998 41.3549 40.6757 41.3549 38.919C41.3549 37.1623 39.9308 35.7382 38.1741 35.7382C36.4174 35.7382 34.9933 37.1623 34.9933 38.919C34.9933 40.6757 36.4174 42.0998 38.1741 42.0998ZM38.1741 42.4974C40.1504 42.4974 41.7525 40.8953 41.7525 38.919C41.7525 36.9427 40.1504 35.3406 38.1741 35.3406C36.1978 35.3406 34.5957 36.9427 34.5957 38.919C34.5957 40.8953 36.1978 42.4974 38.1741 42.4974Z"
          fill="#F0F2F5"
        />
        <path
          d="M37.8932 40.9069H38.5156V40.4726H39.0134C39.1171 40.4726 39.2018 40.434 39.2675 40.3568C39.3332 40.2796 39.3661 40.1928 39.3661 40.0963V38.9889C39.3661 38.8843 39.3332 38.7951 39.2675 38.7211C39.2018 38.6471 39.1171 38.6101 39.0134 38.6101H37.6028V37.9442H39.3661V37.3652H38.5156V36.9309H37.8932V37.3652H37.385C37.2813 37.3652 37.1879 37.4038 37.1049 37.481C37.022 37.5582 36.9805 37.6486 36.9805 37.7523V38.8502C36.9805 38.9539 37.022 39.0363 37.1049 39.0974C37.1879 39.1586 37.2813 39.1891 37.385 39.1891H38.7437V39.8936H36.9805V40.4726H37.8932V40.9069Z"
          fill="#F0F2F5"
        />
      </g>
    </svg>
  );
};

export default PotentialGrossProfitIcon;
