export const EditPenIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.64479 15.2152H2.63111L12.5616 5.28469L11.5753 4.29836L1.64479 14.2288V15.2152ZM15.4085 4.32078L12.5392 1.45148L13.4807 0.509986C13.7347 0.255933 14.0486 0.128906 14.4222 0.128906C14.7958 0.128906 15.1096 0.255933 15.3637 0.509986L16.35 1.49631C16.604 1.75036 16.7311 2.06419 16.7311 2.4378C16.7311 2.81141 16.604 3.12524 16.35 3.37929L15.4085 4.32078ZM14.467 5.26227L3.16911 16.5602H0.299805V13.6909L11.5977 2.39297L14.467 5.26227ZM12.0684 4.79153L11.5753 4.29836L12.5616 5.28469L12.0684 4.79153Z"
        fill="#379FFF"
      />
    </svg>
  );
};
