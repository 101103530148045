export const defaultValues = {
  name: '',
  office: [],
  location: '',
  phoneNumber: null,
  fax: null,
  address: '',
  secondAddress: '',
  city: '',
  state: '',
  zipcode: '',
  percent: null,
  amount: 0,
  gross: 0,
  authorities: [],
  brackeds: [],
};

export const filterDefaultValues = {
  location_filter: null,
  office_filter: '',
};

export enum SUBJECT {
  ADDED_BY = 'Added By',
  ADD_NEW_BRANCH = 'Add New Branch',
  NAME = 'Name',
  OFFICE = 'Agency',
  PHONE_NUMBER = 'Phone Number',
  FAX = 'Fax',
  ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIPCODE = 'Zip Code',
  BRANCH_CONSIGMENT_PERCENT = 'Branch Consignment Percent',
  GROSS = '3 Months Gross',
  SAVE = 'Save',
  CLOSE = 'Close',
  ADD_BRANCH = 'Add Branch',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  ADD_AUTHORITIES = 'Add Authorities',
  ADDED = 'Added',
  SEARCH_SELECT = 'Search Select',
  EDIT_BTN_TEXT = 'Save Changes',
  BRANCH_INFO = 'Branch Info',
  ADDITIONAL = 'Additional',
  DATE = 'Date',
  BRANCH_NAME = 'Branch Name',
  EDIT = 'Edit',
  BRANCH_ID = 'Branch ID',
  STATUS = 'Status',
  LOCATION = 'Location',
  EDIT_BRANCH = 'Edit Branch',
  BRANCH_LIST_IS_EMPTY = 'Branch List Is Empty',
  NOT_SELECTED = 'Not Selected',
  BRANCH_FILTER = 'Branch Filter',
  RESET_ALL = 'Reset All',
  ADD_AUTHORITIES_BTN = '+ Add Authorities',
  MANAGER = 'Manager',
  AGENT = 'Agent',
  OFFICE_NAME = 'Agency Name',
  ADD_BRACKED = 'Add Bracket',
  ADD = 'Add',
  PERCENT = 'Percent',
  FROM = 'From',
  TO = 'To',
  LOCATION_FILTER = 'location_filter',
  CONSIGMENT_PERCENT = 'Consignment Percent',
  MANAGERS = 'Managers',
  NO = 'No',
  YES = 'Yes',
  CHECKED_ACTIVATE = 'If Activated, Only The Selected Agency Will Become Active, All Attached Units(Agents) Will Stay Terminated',
  CHECKED_DEACTIVATE = ' If Terminated, All Attached Units Will Be Terminated Too(Agents)',
  ARE_YOU_SURE = 'Are You Sure Want Do Delete?',
  DELETE = 'Delete',
  AUTHORITIES = 'Authorities',
  BRACKED = 'Bracket',
  STAY_HERE = 'Stay Here',
  WARNING_MESSAGE = 'Attention: You Will Be Giving Entire Profit To The Branch',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',
  FULL_INFO = 'Full Info',
  BRACKED_DISABLED = 'If You Have Bracket With 0 Percent And 0 To, You Can Not Add Bracket Anymore',
}

export enum VALIDATION_MESSAGES {
  BRACKET_REQUIRED = 'You have to add at least one bracket',
  MIN_OPTION = 'At least one option is required',
  PHONE = 'Incorrect phone format',
  INCORRECT_FAX = 'Incorrect fax format',
  MAX_LENGTH = 'Maximum of 10 digits allowed',
  NAME_REQUIRED = 'Name is a required field',
  OFFICE_REQUIRED = 'Agency is a required field',
  LOCATION_REQUIRED = 'Location is a required field',
  PHONE_NUMBER_REQUIRED = 'Phone number is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  CITY_REQUIRED = 'City is a required field',
  STATE_REQUIRED = 'State is a required field',
  ZIP_REQUIRED = 'Zip Code is a required field',
  AUTHORITIES_REQUIRED = 'Authorities is a required field',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
}

export const SEARCH_OFFICE_COLUMNS = [{ key: 'value', name: '' }];

export const OFFICE_KEYS = ['title'];

export const defaultBrackedValues = {
  percent: null,
  from: 0,
  to: null,
};
