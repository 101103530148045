import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { SUBJECT } from 'pages/profile/tabs/agent/constants/constants';
import { useAddAuthorityToAgentMutation, useRemoveAuthorityFromAgentMutation } from 'services/profile/agents/agents';
import { AgentPayType, AgentType } from 'services/profile/agents/interfaces';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { IAuthorityElement } from 'utils/types';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { selectUserPermissionsInfo } from '../../../../../../store/user-slice/selector';

export const useCreateEdit = ({
  setValues,
  watch,
  refetchAuthoritiesByAgentId,
  authoritiesByAgentId,
  authoritiesByBranchOrOfficeId,
  trigger,
  setAuthoritiesByAgent,
  isAutocompleteFetching,
  setAuthoritiesByOffice,
  setAuthoritiesByBranch,
}: any) => {
  const dispatch = useDispatch();
  const { agentType, userType, userId } = useSelector(selectUserPermissionsInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, open, id } = searchParams;

  const canceledBlockCheck = useMemo(() => {
    return String(userId) !== id && (agentType === AgentType.Owner || agentType === AgentType.Manager);
  }, [userId, id]);

  useEffect(() => {
    setSearchParams({ ...searchParams, office_filter: '', branch_filter: '', agent_type_filter: '' });
    return () => {
      setSearchParams({
        ...searchParams,
        office_filter: '',
        branch_filter: '',
        agent_type_filter: '',
        open: 'false',
      });
    };
  }, []);

  const [removeAuthorityFromAgent] = useRemoveAuthorityFromAgentMutation();
  const [addAuthorityToAgent, { isLoading }] = useAddAuthorityToAgentMutation();

  const [authorities, setAuthorities] = useState<IAuthorityElement[]>([]);
  const [addedAuthorities, setAddedAuthorities] = useState<IAuthorityElement[]>([]);

  const dataGridCheck = useMemo(() => {
    if (mode === 'add' && !!addedAuthorities?.length) {
      return true;
    } else if (mode === 'edit' && (!!addedAuthorities?.length || !!authoritiesByAgentId?.result?.length)) {
      return true;
    }
    return false;
  }, [addedAuthorities?.length, !!authoritiesByAgentId?.result?.length]);

  useEffect(() => {
    if (mode === 'add') {
      setAddedAuthorities([]);
      setAuthorities(authoritiesByBranchOrOfficeId?.result);
    }
  }, [watch('office'), watch('branch'), mode]);

  const handlePaginationClick = (skip: number, limit: number) => {
    if (mode === 'edit') {
      setAuthoritiesByAgent((prev: any) => ({
        ...prev,
        limit,
        skip,
      }));
    }
    return;
  };

  const handleAddAuthority = (authorityElement: IAuthorityElement) => {
    if (mode === 'edit' && !isLoading) {
      addAuthorityToAgent({ id, authorityId: authorityElement.id }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully added',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          refetchAuthoritiesByAgentId();
        }
      });
    } else {
      if (!addedAuthorities.includes(authorityElement?.id as any) && !isAutocompleteFetching) {
        const renderItems = authorities.filter(authority => authority.id === authorityElement.id);
        const renderItemsIds = renderItems.map(el => el.id);

        setAddedAuthorities(prev => [...prev.filter(item => item.id !== renderItems[0]?.id), ...renderItems]);
        AgentType[watch('agentType') as number] === SUBJECT.OWNER
          ? setAuthoritiesByOffice((prev: any) => ({
              ...prev,
              ignoreAuthorityIds: [
                ...prev.ignoreAuthorityIds.filter((item: number) => item !== renderItemsIds[0]),
                ...renderItemsIds,
              ],
            }))
          : setAuthoritiesByBranch((prev: any) => ({
              ...prev,
              ignoreAuthorityIds: [
                ...prev.ignoreAuthorityIds.filter((item: number) => item !== renderItemsIds[0]),
                ...renderItemsIds,
              ],
            }));
        notification.success({
          message: 'Successfully added',
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    }
  };

  const handleDeleteAuthority = (id: number | string) => {
    setAddedAuthorities(prev => prev.filter(item => item.id !== id));
    if (mode === 'edit') {
      const optionsSetter = authoritiesByAgentId?.result?.filter((item: any) => item.id !== id);

      setAuthorities(prev => [...prev, ...optionsSetter]);
    }
    trigger('authorities');
    AgentType[watch('agentType') as number] === SUBJECT.OWNER
      ? setAuthoritiesByOffice((prev: any) => ({
          ...prev,
          ignoreAuthorityIds: [...prev.ignoreAuthorityIds.filter((el: any) => el !== id)],
        }))
      : setAuthoritiesByBranch((prev: any) => ({
          ...prev,
          ignoreAuthorityIds: [...prev.ignoreAuthorityIds.filter((el: any) => el !== id)],
        }));
    dispatch(closeModal());
  };

  const handleRowDelete = (rowId: string | number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          if (mode === 'edit') {
            removeAuthorityFromAgent({ id, authorityId: rowId }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully deleted',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                refetchAuthoritiesByAgentId();
                handleDeleteAuthority(rowId);
              }
            });
          } else {
            handleDeleteAuthority(rowId);
          }
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  useEffect(() => {
    if (addedAuthorities?.length) {
      setValues(
        'authorities',
        addedAuthorities?.map(item => item.id),
        { shouldValidate: true }
      );
    }
  }, [addedAuthorities?.length, authorities?.length]);
  useEffect(() => {
    if (mode === 'add') {
      setAddedAuthorities([]);
    }
  }, []);

  useEffect(() => {
    if (mode === 'add') {
      setAuthorities(authoritiesByBranchOrOfficeId?.result);
    }
    if (mode === 'edit') {
      setAddedAuthorities(authoritiesByAgentId?.result);
      setAuthorities(
        authoritiesByBranchOrOfficeId?.result?.filter(
          (el: any) =>
            !authoritiesByAgentId?.result?.some((item: any) => {
              return item.id === el.id;
            })
        )
      );
    }
  }, [mode, open, authoritiesByBranchOrOfficeId, authoritiesByAgentId]);

  const ssnFormatter = (e: ChangeEvent<HTMLInputElement>) => {
    const formatter = AgentPayType[watch('agent_pay_type')] === SUBJECT.SSN ? formatSSN : formatEin;
    setValues('ssn_ein_number', formatter(e.target.value), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const formatSSN = (value: string) => {
    if (!value) return value;
    const ssn = value.replace(/[^\d]/g, '');
    const ssnLength = ssn.length;
    if (ssnLength < 4) return ssn;
    if (ssnLength < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
  };

  const formatEin = (value: string) => {
    if (!value) return value;
    const ssn = value.replace(/[^\d]/g, '');
    const ssnLength = ssn.length;
    if (ssnLength < 3) return ssn;
    if (ssnLength < 6) {
      return `${ssn.slice(0, 2)}-${ssn.slice(2)}`;
    }
    return `${ssn.slice(0, 2)}-${ssn.slice(2)}`;
  };

  const formValues = watch();

  const isPayrollDirty = useMemo(() => {
    return !!(formValues?.pay_type || formValues?.paid_as || formValues?.agent_pay_type);
  }, [formValues]);

  const handleResetPayroll = () => {
    setValues('pay_type', '', { shouldValidate: true });
    setValues('paid_as', '', { shouldValidate: true });
    setValues('agent_pay_type', '', { shouldValidate: true });
    setValues('ssn_ein_number', '', { shouldValidate: true });
    trigger(['pay_type', 'paid_as', 'agent_pay_type', 'ssn_ein_number']);
  };

  return {
    authorities,
    addedAuthorities,
    handleAddAuthority,
    handleRowDelete,
    ssnFormatter,
    isPayrollDirty,
    handleResetPayroll,
    dataGridCheck,
    handlePaginationClick,
    agentType,
    canceledBlockCheck,
    userType,
  };
};

export default useCreateEdit;
