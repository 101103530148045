import { formatDate } from 'utils/dates';

import { changeTimeZone, formatNumberWithThousandsSeparatorAndDecimal } from '../../../utils/helpers';

import { IBilling, IBillingsData } from './interface';

export const getBillingsSerializer = (body: IBillingsData) => {
  const result = body?.result?.map((billing: IBilling) => {
    const retrieved =
      billing?.billing?.retrieved ||
      billing?.billing?.byPaymentTerm ||
      billing?.billing?.byClaim ||
      billing?.billing?.claimed;

    return {
      id: billing?.billing?.id,
      createdAt: formatDate(billing?.billing?.createdAt as Date),
      code: billing?.billing?.code,
      loadStatus: { processing: true, status: 4, carrierStatus: 0, customerStatus: 0 },
      loadType: billing?.billing?.loadType,
      retrieved,
      refNumber: billing?.billing?.referenceNumber,
      customerName: billing?.billing?.customer?.name,
      customerFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(billing?.billing?.customerFinalAmount),
      customerDeptAmount: formatNumberWithThousandsSeparatorAndDecimal(billing?.billing?.customerDeptAmount),
      sentToBillingAt: billing?.billing?.sentToBillingAt,
      carrierName: billing?.billing?.carrier?.companyName,
      carrierFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(billing?.billing?.carrierFinalAmount),
      carrierAmount: formatNumberWithThousandsSeparatorAndDecimal(billing?.billing?.carrierAmount),
      mcNumber: billing?.billing?.authority?.mc,
      lastComment: billing?.comment?.message,
      claimed: billing?.billing?.claimed,
      byPaymentTerm: billing?.billing?.byPaymentTerm,
      byClaim: billing?.billing?.byClaim,
      agencyName: billing?.billing?.office?.name,
      branchName: billing?.billing?.branch?.name,
      agentName: `${billing?.billing?.creator?.username}`,
    };
  });

  return {
    result,
    count: body?.count,
    expectedCustomAmount: body?.expectedCustomAmount,
    expectedQuickAmount: body?.expectedQuickAmount,
    expectedTotalAmount: body?.expectedTotalAmount,
    expectedTotalCount: body?.expectedTotalCount,
    payQuickAmount: body?.payQuickAmount,
    payTotalAmount: body?.payTotalAmount,
    payTotalCount: body?.payTotalCount,
  };
};

export const getBillingsParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  if (params.filter?.officeIds?.length) {
    params.filter?.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
  }
  if (params.filter?.branchIds?.length) {
    params.filter?.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
  }
  if (params.filter?.agentIds?.length) {
    params.filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
  }
  if (params.filter?.customerPaymentTypes?.length) {
    params.filter?.customerPaymentTypes?.forEach((el: string | number) =>
      queryParams.append('customerPaymentTypes[]', String(el))
    );
  }
  if (params.filter?.carrierPaymentTypes?.length) {
    params.filter?.carrierPaymentTypes?.forEach((el: string | number) =>
      queryParams.append('carrierPaymentTypes[]', String(el))
    );
  }
  if (params.filter?.createdFrom) {
    queryParams.append(
      'createdFrom',
      params.filter?.isConvertingTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.createdFrom)), 'America/Los_Angeles'))
        : String(params.filter?.createdFrom)
    );
  }
  if (params.filter?.createdTo) {
    queryParams.append(
      'createdTo',
      params.filter?.isConvertingTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.createdTo)), 'America/Los_Angeles'))
        : String(params.filter?.createdTo)
    );
  }
  return queryParams;
};
