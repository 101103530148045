import { withController } from 'ui/inputs/hoc/Controller';
import { IMonthPickerProps } from 'ui/inputs/month-picker/constants/types';
import CustomMonthPicker from 'ui/inputs/month-picker/MonthPicker';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import Select from 'ui/inputs/select/Select';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { IInformationFilters } from '../constants/types';

export const FilterSelect = withController<ISelectProps, IInformationFilters>(Select);
export const SearchableDropdown = withController<ISearchableDropdownProps, IInformationFilters>(
  CustomSearchableDropdown
);

export const MonthPicker = withController<IMonthPickerProps, IInformationFilters>(CustomMonthPicker);
