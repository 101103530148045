import { BrokerPaymentTermsType } from 'services/profile/carriers/interfaces';

export enum VALIDATION_MESSAGES {
  PAYMENT_TERM_TYPE_REQUIRED = 'Payment term type is required field',
  PAID_AS_REQUIRED = 'Paid as is required field',
  AUTHORITY_REQUIRED = 'Broker authority is required',
  FACTORING_TYPE_REQUIRED = 'Payment method is required field',
  FACTORED_COMPANY_NAME_REQUIRED = 'Company name is required field',
  PAYMENT_TYPE_REQUIRED = 'Payment method is required field',
  PAYMENT_BANK_NAME_REQUIRED = 'Bank name is required field',
  PAYMENT_ROUTING_NUMBER_REQUIRED = 'Routing number is required field',
  PAYMENT_ACCOUNT_REQUIRED = 'Account number is required field',
  PAYMENT_COMPANY_NAME_REQUIRED = 'Company name is required field',
  PAYMENT_ADDRESS_REQUIRED = 'Address is required field',
  PAYMENT_CITY_REQUIRED = 'City is required field',
  PAYMENT_ZIP_REQUIRED = 'Zip Code is required field',
  PAYMENT_STATE_REQUIRED = 'State is required field',
  DAY_REQUIRED = 'Day is required field',
  PAYMENT_FUEL_CARD_REQUIRED = 'Fuel Card is required field',
  PERCENT_REQUIRED = 'Percent is required field',
  EMAILS_REQUIRED = 'Emails are required',
  FILES_REQUIRED = 'Files is required field',
  I_AGREE_REQUIRED = 'Required field',
  ZIP_MIN_LENGTH = 'Must be 5 digits',
  ZIP_MAX_LENGTH = 'Must be 5 digits',
  PERCENT_MAX_LENGTH = 'Percent must be less than 100',
  FUEL_CARD_MIN = 'Minimum of 16 digits allowed',
  FUEL_CARD_MAX = 'Maximum of 24 digits allowed',
  MAX_DECIMAL_NUMBER = 'Up to 2 decimal places allowed',
}

export enum SUBJECT {
  PROVIDE = 'Please provide additional information. Select the type of consultation you require.',
  QUICK = '3.5 % Quick Pay',
  SUPPORT = '24/7 support',
  SAVE_GALLON = 'Save 20 Cents Per Gallon',
  IAGREE = 'I Agree',
  FR_SIGN_AGREEMENT = 'FreightMax Sign Agreement',
  READ = 'Please Review And Sign',
  FREIGHTMAX = 'Freightmax: Your Reliable Trucking Partner. With a fleet of modern trucks and experienced drivers, we ensure timely and safe deliveries nationwide.Let us handle your freight needs with professionalism and efficiency.',
  PAID_AS = 'Paid as',
  BROKER_AUTHORITY = `Broker's Authority'`,
  ONLY_ONE_FM = 'You can add only one payment term by FreigthMax',
  NOT_EDITABLE = "You can't edit payment term type",
  ONLY_ONE_FM_CHANGE = "You can't change to FreigthMax as you can add only one payment term by FreigthMax",
  PAYMENT_TERMS = 'Find My Broker',
  PAYMENT_TERMS_AND_BILLING = 'payment-terms-and-billing',
  PAYMENT_METHOD = 'Payment Method',
  FACTORED_EMAIL = 'Email',
  FUEL_CARD = 'Fuel Card',
  BANK_NAME = 'Bank Name',
  ROUTING_NUMBER = 'Routing Number',
  ACCOUNT_NUMBER = 'Account Number',
  PAYMENT_COMPANY_NAME = 'Company Name',
  PAYMENT_ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIP_CODE = 'Zip Code',
  BROKER_PAYMENT_TERMS = 'Broker Payment Terms',
  QUICK_PAY_TOOLTIP = 'Quick pay tooltip',
  DAY = 'Day',
  PERCENT = 'Percent',
  QUICK_PAY = 'Quick Pay',
  EMAIL = 'Email',
  UPLOAD = 'UPLOAD',
  MC = 'MC',
  FACTORING_COMPANY_NAME = 'Factoring Company Name',
  UPLOAD_DOCUMENTS = 'Upload Documents',
  CARRIER_FILES = 'Upload Carrier Files',
  UPLOAD_LETTER_OF_RELEASE = 'Upload letter of release',
  ARE_YOU_SURE = 'Are You Sure',
  DELETE = 'Delete',
  CLOSE = 'Close',
  DRAG_AND_DROP = 'Drag And Drop Or',
}

export const defaultValues = {
  paymentPaidAs: '',
  id: '',
  type: 1,
  authorityId: [],
  customerId: undefined,
  factoringType: 1,
  factoredCompanyName: [],
  paymentType: 0,
  paymentBankName: '',
  paymentRoutingNumber: '',
  paymentAccountNumber: '',
  paymentCompanyName: '',
  paymentAddress: '',
  paymentSecondAddress: '',
  paymentCity: '',
  paymentStateId: undefined,
  paymentZip: '',
  brokerPaymentTermType: 2,
  day: '30',
  percent: '0',
  brokerEmails: [],
  agreement: [],
  createdAt: '',
  createdBy: undefined,
  iAgree: false,
};

export const PAYMENT_TYPE_OPTIONS = [
  { value: 1, label: 'Direct Deposit' },
  { value: 2, label: 'Paper Check' },
];

export const FACTORING_TYPE_OPTIONS = [
  { value: 1, label: 'Carrier Factored' },
  { value: 2, label: 'Carrier not Factored' },
];

export const DAY_OPTION = [{ value: BrokerPaymentTermsType['DAY'], label: SUBJECT.DAY }];
export const QUICK_PAY_OPTION = [{ value: BrokerPaymentTermsType['QUICK'], label: SUBJECT.QUICK_PAY }];

export const PAYMENT_TERMS_TYPE_OPTIONS = [
  { value: 1, label: 'Sign up with my broker' },
  { value: 2, label: 'Sign up with FreightMax' },
];

export const SEARCH_AUTHORITY_COLUMNS = [
  { key: 'id', name: 'Name' },
  { key: 'dot', name: 'Dot' },
];
export const AUTHORITY_KEYS = ['id', 'name', 'dot'];

export const SEARCH_FACTORING_COMPANIES_COLUMNS = [{ key: 'id', name: '' }];

export const FACTORING_COMPANY_KEYS = ['companyName'];

export const BROKER_FILES_OPTIONS = [
  { label: 'Carrier package', value: '1' },
  { label: 'Voided check', value: '3' },
  { label: 'NOA', value: '4' },
  { label: 'W9', value: '2' },
  { label: 'Insurance', value: '6' },
  { label: 'Unknown', value: '0' },
];
