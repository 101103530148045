import { ReactNode } from 'react';

export enum IEventTypes {
  AUTHORITY_CREATE = 'authority.create',
  AUTHORITY_EDIT = 'authority.edit',
  AUTHORITY_INACTIVE = 'authority.inactive',
  AUTHORITY_ACTIVE = 'authority.active',
  BRANCH_CREATE = 'branch.create',
  BRANCH_EDIT = 'branch.edit',
  BRANCH_TERMINATE = 'branch.terminate',
  BRANCH_ACTIVATE = 'branch.activate',
  AUTHORITY_DELETE_FILE = 'authority.deleteFile',
  OFFICE_CREATE = 'office.create',
  OFFICE_EDIT = 'office.edit',
  OFFICE_ACTIVATE = 'office.activate',
  OFFICE_TERMINATE = 'office.terminate',
  CARRIER_CREATE = 'carrier.create',
  CARRIER_EDIT = 'carrier.edit',
  CARRIER_TERMINATE = 'carrier.terminate',
  CARRIER_ACTIVATE = 'carrier.activate',
  CARRIER_CREATE_BLACKLIST = 'carrier.createBlackList',
  CARRIER_REMOVE_BLACKLIST = 'carrier.removeBlackList',
  CARRIER_CREATE_REQUEST = 'carrier.createRequest',
  TRUCK_CREATE = 'truck.create',
  TRUCK_EDIT = 'truck.edit',
  TRUCK_DELETE = 'truck.delete',
  CARRIER_COMMENT_CREATE = 'carrier.comment.create',
  CARRIER_COMMENT_DELETE = 'carrier.comment.delete',
  CARRIER_ADD_FILE = 'carrier.file.add',
  CARRIER_DELETE_FILE = 'carrier.file.delete',
  CARRIER_EDIT_PAYMENT_TERM_TYPE = 'carrier.edit.paymentTermType',
  CARRIER_EDIT_PAYMENT_METHOD_TYPE = 'carrier.edit.paymentMethodType',
  CARRIER_EDIT_INSURANCE = 'carrier.edit.insurance',
  USER_CREATE_AGENT = 'user.agent.create',
  USER_CREATE_MANAGER = 'user.agent.manager',
  USER_CREATE_OWNER = 'user.agent.owner',
  USER_EDIT_AGENT = 'user.agent.edit.agent',
  USER_EDIT_MANAGER = 'user.agent.edit.manager',
  USER_EDIT_OWNER = 'user.agent.edit.owner',
  USER_ACTIVATE_AGENT = 'user.agent.activate.agent',
  USER_ACTIVATE_MANAGER = 'user.agent.activate.manager',
  USER_ACTIVATE_OWNER = 'user.agent.activate.owner',
  USER_TERMINATE_AGENT = 'user.agent.terminate.agent',
  USER_TERMINATE_MANAGER = 'user.agent.terminate.manager',
  USER_TERMINATE_OWNER = 'user.agent.terminate.owner',
  USER_DELETE_FILE_AGENT_AGENT = 'user.agent.file.delete.agent',
  USER_DELETE_FILE_AGENT_MANAGER = 'user.agent.file.delete.manager',
  USER_DELETE_FILE_AGENT_OWNER = 'user.agent.file.delete.owner',
  USER_DELETE_FILE_EMPLOYEE_CORPORATE = 'user.employee.file.delete.corporate',
  USER_DELETE_FILE_EMPLOYEE_OFFICE = 'user.employee.file.delete.office',
  USER_DELETE_FILE_EMPLOYEE_BRANCH = 'user.employee.file.delete.branch',
  USER_AGENT_EDIT_USERNAME_AGENT = 'user.agent.edit.username.agent',
  USER_AGENT_EDIT_USERNAME_OWNER = 'user.agent.edit.username.owner',
  USER_AGENT_EDIT_USERNAME_MANAGER = 'user.agent.edit.username.manager',
  USER_CREATE_EMPLOYEE_CORPORATE = 'user.employee.create.corporate',
  USER_CREATE_EMPLOYEE_OFFICE = 'user.employee.create.office',
  USER_CREATE_EMPLOYEE_BRANCH = 'user.employee.create.brach',
  USER_ACTIVATE_EMPLOYEE_CORPORATE = 'user.employee.activate.corporate',
  USER_ACTIVATE_EMPLOYEE_OFFICE = 'user.employee.activate.office',
  USER_ACTIVATE_EMPLOYEE_BRANCH = 'user.employee.activate.branch',
  USER_TERMINATE_EMPLOYEE_CORPORATE = 'user.employee.terminate.corporate',
  USER_TERMINATE_EMPLOYEE_OFFICE = 'user.employee.terminate.office',
  USER_TERMINATE_EMPLOYEE_BRANCH = 'user.employee.terminate.branch',
  USER_EDIT_EMPLOYEE_CORPORATE = 'user.edit.employee.corporate',
  USER_EDIT_EMPLOYEE_OFFICE = 'user.edit.employee.office',
  USER_EDIT_EMPLOYEE_BRANCH = 'user.edit.employee.branch',
  USER_EDIT_EMPLOYEE_CORPORATE_USERNAME = 'user.edit.employee.corporate.username',
  USER_EDIT_EMPLOYEE_OFFICE_USERNAME = 'user.edit.employee.office.username',
  USER_EDIT_EMPLOYEE_BRANCH_USERNAME = 'user.edit.employee.branch.username',
  USER_EDIT_EMPLOYEE_CORPORATE_PERMISSION = 'user.edit.employee.corporate.permission',
  USER_EDIT_EMPLOYEE_OFFICE_PERMISSION = 'user.edit.employee.office.permission',
  USER_EDIT_EMPLOYEE_BRANCH_PERMISSION = 'user.edit.employee.branch.permission',
  USER_VERIFY_AGENT_AGENT = 'user.verify.agent.agent',
  USER_VERIFY_AGENT_OWNER = 'user.verify.agent.owner',
  USER_VERIFY_AGENT_MANAGER = 'user.verify.agent.manager',
}

export interface ISocketMessage {
  id: string;
  type: string;
  payload: {
    creator: {
      id: number;
      title: string;
      type: number;
    };
    entity?: {
      id: number;
      title: string;
      type?: number;
    };
    event: {
      id: number;
      title: string;
    };
    eventType: IEventTypes;
    type?: string;
    userId?: number | string;
    id?: number | string;
    createdAt: number;
    read: boolean;
    additionalData: Array<string>;
  };
}

export interface Handler {
  handlers: Record<string, (event: ISocketMessage) => void>;
  notification: ReactNode | null;
}

export enum EMessageTypes {
  success = '1',
  danger = '2',
  info = '3',
}

export interface IMessage {
  id?: number;
  title: string | ReactNode;
  link?: string;
  component?: boolean;
}
export interface INotification {
  title: string;
  message: Array<IMessage>;
  date: string;
  messageType: string;
  read?: boolean;
  isNotificationInDrawer?: boolean;
}
