import { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { LOCATION_DROPDOWN_DATA } from 'utils/constants';

import MainDrawer from 'components/main-drawer/MainDrawer';
import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import Table from 'components/table/Table';

import { SUBJECT } from './constants/constants';
import { IOfficeParams } from './constants/types';
import { FilterSelect } from './hoc-instance/instance';
import { useOffice } from './useOffice';

import styles from './Office.module.scss';

const Office = ({ setExcelParams }: IOfficeParams) => {
  const {
    currentChildren: { component, buttonText, onCancel, onSubmit, withFooter },
    isFetching,
    data,
    columns,
    filterControl,
    resetFilter,
    handleRowClick,
    handleRowEdit,
    handleSortClick,
    handleColumnSearch,
    handleSwitchClick,
    handleStopResize,
    handlePaginationClick,
    skip,
    limit,
    isFilterDirty,
    agencyPagePermissions: { edit, terminateOff, terminateOn },
  } = useOffice({ setExcelParams });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading: isFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onSwitchClick: handleSwitchClick,
    onColumnSearch: handleColumnSearch,
    handleRowEdit,
    onPaginationClick: handlePaginationClick,
    hasTerminateOnPermission: terminateOn,
    hasTerminateOffPermission: terminateOff,
    hasEditPermission: edit,
    withMenu: edit,
  });

  return (
    <div className="page-content">
      <div className={styles.officeContainer}>
        <div className={styles.officeTopBlock}>
          <div className={styles.officeMainSelect}>
            <Row gutter={[18, 0]}>
              <Col span={14}>
                <Row align="middle" gutter={[15, 0]}>
                  <Col span={8}>
                    <FilterSelect
                      suffixIcon={<SelectArrowIcon />}
                      withAll
                      options={LOCATION_DROPDOWN_DATA}
                      control={filterControl}
                      label={SUBJECT.OFFICE_LOCATION}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name={SUBJECT.OFFICE_FILTER}
                      isFilter
                    />
                  </Col>
                  {isFilterDirty && (
                    <Col>
                      <div className={styles.officeRestBtn}>
                        <Button
                          icon={<ResetIcon />}
                          variant="ghost"
                          onClick={resetFilter}
                          children={SUBJECT.RESET_ALL}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        {!isFetching && !data && (
          <div className={styles.officeEmpty}>
            <Typography variant="paragraph">{SUBJECT.OFFICES_LIST_IS_EMPTY}</Typography>
          </div>
        )}
        <Table {...tableProps} />
      </div>

      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
      />
    </div>
  );
};

export default Office;
