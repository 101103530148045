import * as yup from 'yup';

export const distributeValidation = (validationCheck: boolean, columns: string[]) => {
  return yup.object().shape({
    corporate: yup.object().shape({
      percent: yup.string().test('isRequired', 'Corporate percent is a required field', function (value) {
        if (columns?.includes('corporate')) {
          if (value) {
            return true;
          } else {
            return validationCheck;
          }
        }
        return true;
      }),
      sum: yup.string().test('isRequired', 'Corporate sum is a required field', function (value) {
        if (columns?.includes('corporate')) {
          if (value) {
            return true;
          } else {
            return validationCheck;
          }
        }
        return true;
      }),
    }),
    agency: yup.object().shape({
      percent: yup.string().test('isRequired', 'Agency percent is a required field', function (value) {
        if (columns?.includes('agency')) {
          if (value) {
            return true;
          } else {
            return validationCheck;
          }
        }
        return true;
      }),
      sum: yup.string().test('isRequired', 'Agency sum is a required field', function (value) {
        if (columns?.includes('agency')) {
          if (value) {
            return true;
          } else {
            return validationCheck;
          }
        }
        return true;
      }),
    }),
    branch: yup.object().shape({
      percent: yup.string().test('isRequired', 'Branch percent is a required field', function (value) {
        if (columns?.includes('branch')) {
          if (value) {
            return true;
          } else {
            return validationCheck;
          }
        }
        return true;
      }),
      sum: yup.string().test('isRequired', 'Branch sum is a required field', function (value) {
        if (columns?.includes('branch')) {
          if (value) {
            return true;
          } else {
            return validationCheck;
          }
        }
        return true;
      }),
    }),
    agent: yup.object().shape({
      percent: yup.string().test('isRequired', 'Agent percent is a required field', function (value) {
        if (columns?.includes('agent')) {
          if (value) {
            return true;
          } else {
            return validationCheck;
          }
        }
        return true;
      }),
      sum: yup.string().test('isRequired', 'Agent sum is a required field', function (value) {
        if (columns?.includes('agent')) {
          if (value) {
            return true;
          } else {
            return validationCheck;
          }
        }
        return true;
      }),
    }),
  });
};
