import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import { Col, RefSelectProps, Row, Select } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import SearchIcon from 'components/svgs/SearchIcon';

import CloseIcon from '../../../components/svgs/CloseIcon';
import useDebounce from '../../../hooks/useDebounce';
import { getHighlightedOptions } from '../../../utils/helpers';

import { SUBJECT } from './constants/constants';
import { ISearchableSelectProps } from './constants/types';

import styles from './SearchableSelect.module.scss';

const SearchableSelect = forwardRef(
  (
    {
      options,
      placeholder,
      withMarking = true,
      onChange,
      handleClick,
      withButton,
      buttonText,
      isMulti,
      data,
      name,
      disabled = false,
      value,
      errors,
      offset,
      setOffset,
      required,
      showKey,
      setValue,
      isFilter,
      label,
      resetFunc,
      allowClear,
      onBlur,
      ...props
    }: ISearchableSelectProps,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const { Option } = Select;
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const debounceValue = useDebounce(searchValue, 500);
    const [isFocused, setIsFocused] = useState(false);

    const currentElement = document.getElementById(`current_select_searchable${label}${placeholder}`);

    const handleSearch = (value: string) => {
      currentElement?.focus();
      setIsSearching(true);
      setSearchValue(value);
      if (onChange) {
        onChange(value);
      }
    };
    const handleReset = () => {
      setSearchValue('');
      if (setValue) {
        setValue(name, '');
      }
      if (onChange) {
        onChange('');
      }
      if (resetFunc) {
        resetFunc();
      }
    };

    const handleChange = (value: string) => {
      currentElement?.blur();
      setIsSearching(false);
      setIsFocused(false);
      if (setValue && name) {
        setValue(name, value);
      }
    };

    useEffect(() => {
      !isSearching && currentElement?.blur();
    }, [isSearching]);

    useEffect(() => {
      if (isSearching && (debounceValue?.length >= 2 || !debounceValue?.length) && typeof searchValue === 'string') {
        setOffset((prev: any) => ({ ...prev, search: debounceValue, limit: 10 }));
      }
    }, [debounceValue]);

    const handleScroll = (event: any) => {
      const { target } = event;
      if (target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight) {
        if (options?.length < offset) {
          setOffset((prev: any) => {
            return { ...prev, limit: prev.limit + 10 };
          });
        }
      }
    };

    const handleBlur = () => {
      setIsFocused(false);
      if (onBlur) {
        onBlur();
        setSearchValue('');
      }
    };

    useEffect(() => {
      if (!searchValue?.length && onChange && data) {
        onChange(data?.map((item: any) => item?.id));
      }
    }, [searchValue]);

    useEffect(() => {
      if (typeof value === 'string') {
        setSearchValue(value);
      }
    }, [value]);

    const dropdownRef = useRef<HTMLInputElement>(null);

    return (
      <div className={styles.searchableSelectMainWrapper}>
        {!!label && <label>{label}</label>}

        <div
          className={clsx(styles.selectWrapper, {
            [styles.required]: required,
            [styles.showValue]: withMarking,
            [styles.hasValue]: (typeof value === 'string' && value?.length) || isFocused,
            [styles.errors]: errors && name && errors[name],
            [styles.disabled]: disabled,
            [styles.filterSelectWrapper]: isFilter,
          })}
          id={`select_searchable${label}${placeholder}`}
          ref={dropdownRef}
        >
          <Select
            {...props}
            id={`current_select_searchable${label}${placeholder}`}
            mode={isMulti}
            ref={ref}
            showSearch={true}
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            showArrow
            {...(allowClear ? { onClear: handleReset, clearIcon: <CloseIcon />, allowClear } : {})}
            autoClearSearchValue={false}
            suffixIcon={!(value && searchValue && allowClear) ? <SearchIcon /> : <CloseIcon />}
            className={clsx(styles.selectMarkingWrapper, styles.select)}
            onChange={handleChange}
            onSearch={handleSearch}
            popupClassName={styles.optionsContainer}
            onFocus={() => {
              setIsFocused(true);
            }}
            value={
              !isFilter
                ? value
                  ? value
                  : null
                : isNaN(Number(value))
                ? value
                : options?.find(el => el.id == value)?.[showKey!]
            }
            getPopupContainer={() => document.getElementById(`select_searchable${label}${placeholder}`)!}
            onPopupScroll={handleScroll}
            onBlur={handleBlur}
          >
            <>
              {withMarking ? (
                <>
                  {options?.map(item => {
                    const highlightedValues = getHighlightedOptions(
                      String(searchValue || ''),
                      showKey ? String(item[showKey]) : String(item?.value)
                    );
                    return (
                      <Option key={item.id} value={showKey ? item[showKey] : item.value}>
                        <div
                          className={styles.optionsContainer}
                          onClick={
                            handleClick
                              ? () => {
                                  if (currentElement === document.activeElement) {
                                    setTimeout(() => {
                                      handleClick(item);
                                    }, 0);
                                    setIsSearching(false);
                                    setIsFocused(false);
                                  }
                                }
                              : () => null
                          }
                        >
                          {highlightedValues?.length && isSearching ? (
                            <>
                              {highlightedValues[0]}
                              <span className={styles.searchResultKeyword}>{highlightedValues[1]}</span>
                              {highlightedValues[2]}
                            </>
                          ) : (
                            <span>{showKey ? item[showKey] : item?.value}</span>
                          )}
                        </div>
                      </Option>
                    );
                  })}
                </>
              ) : (
                <>
                  {options?.length ? (
                    <Option className={styles.headerItems}>
                      <Row>
                        <Col span={7}>
                          <span>{SUBJECT.NAME}</span>
                        </Col>
                        <Col span={4}>
                          <span>{SUBJECT.TYPE}</span>
                        </Col>
                        <Col span={4}>
                          <span>{SUBJECT.MC_NUMBER}</span>
                        </Col>
                        <Col span={4}>
                          <span>{SUBJECT.AUTHORITY_ID}</span>
                        </Col>
                        {withButton && <Col span={5} />}
                      </Row>
                    </Option>
                  ) : (
                    <Typography variant="paragraph" className={styles.noDataText}>
                      {SUBJECT.NO_DATA}
                    </Typography>
                  )}
                  {options?.map(item => {
                    const matchedName = getHighlightedOptions(String(searchValue) || '', String(item.name) || '');
                    const matchedTypeName = getHighlightedOptions(
                      searchValue || '',
                      typeof item.typeName === 'string' ? item.typeName : item.type
                    );
                    const matchedMc = getHighlightedOptions(String(searchValue) || '', String(item.mc) || '');
                    const matchedId = getHighlightedOptions(String(searchValue) || '', String(item.id) || '');
                    return (
                      <Option key={item.id} value={item.name}>
                        <div
                          className={styles.authoritiesContainer}
                          onClick={handleClick ? () => handleClick(item) : () => null}
                        >
                          <Row>
                            {matchedName.length ? (
                              <Col span={7}>
                                {matchedName[0]}
                                <span className={styles.searchResultKeyword}>{matchedName[1]}</span>
                                {matchedName[2]}
                              </Col>
                            ) : (
                              <Col span={7}>
                                <span>{item.name}</span>
                              </Col>
                            )}
                            {matchedTypeName.length ? (
                              <Col span={4}>
                                {matchedTypeName[0]}
                                <span className={styles.searchResultKeyword}>{matchedTypeName[1]}</span>
                                {matchedTypeName[2]}
                              </Col>
                            ) : (
                              <Col span={4}>
                                <span>{typeof item.typeName === 'string' ? item.typeName : item.type}</span>
                              </Col>
                            )}
                            {matchedMc.length ? (
                              <Col span={4}>
                                {matchedMc[0]}
                                <span className={styles.searchResultKeyword}>{matchedMc[1]}</span>
                                {matchedMc[2]}
                              </Col>
                            ) : (
                              <Col span={4}>
                                <span>{item.mc}</span>
                              </Col>
                            )}
                            {matchedId.length ? (
                              <Col span={4}>
                                {matchedId[0]}
                                <span className={styles.searchResultKeyword}>{matchedId[1]}</span>
                                {matchedId[2]}
                              </Col>
                            ) : (
                              <Col span={4}>
                                <span>{item.id}</span>
                              </Col>
                            )}
                            {withButton && (
                              <Col span={5}>
                                <Typography variant="paragraph">{buttonText}</Typography>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Option>
                    );
                  })}
                </>
              )}
            </>
          </Select>
          {isFilter ? (
            !value &&
            !isFocused && (
              <Typography
                variant="paragraph"
                className={clsx(styles.placeholder, {
                  [styles['placeholder-select']]: !isFilter,
                  [styles.isFilter]: isFilter,
                })}
              >
                {placeholder}
              </Typography>
            )
          ) : (
            <Typography
              variant="paragraph"
              className={clsx(styles.placeholder, {
                [styles['placeholder-select']]: !isFilter,
                [styles.isFilter]: isFilter,
              })}
            >
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

export default SearchableSelect;
