import Typography from 'ui/typography/Typography';

import Slider from '../../../slider/Slider';

import { SUBJECT } from './constants/constants';

import styles from './LeftPart.module.scss';

const LeftPart = () => {
  return (
    <div className={styles.leftWrapper}>
      <div className={styles.logo}></div>
      <div className={styles.textInfo}>
        <h1>{SUBJECT.TITLE}</h1>
        <Typography variant="paragraph">{SUBJECT.DESCRIPTION_ONE}</Typography>
        <Typography variant="paragraph">{SUBJECT.DESCRIPTION_TWO}</Typography>
      </div>
      <div className={styles.sliderWrapper}>
        <Slider />
      </div>
    </div>
  );
};

export default LeftPart;
