import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { LoadTypes } from 'pages/loads/components/edit-load/constants/types';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import { LoadStatus } from 'ui/load-status/constants/types';
import { formatPickUpDropOffForSubmitting } from 'utils/dates';
import { changeTimeZone, formatPhone, onlyNumbersFormatter } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { getDropTypes, SUBJECT } from '../../constants/constants';
import { DatePicker, Input, TimePicker } from '../../hoc/instance';

import { pickupsDropOffsSerializer } from './helpers';
import { usePickupsDropoffs } from './usePickupsDropoffs';

import styles from '../../CreateLoad.module.scss';

export const PickupsDropoffs = ({
  pickupDropOffsWatch,
  pickupDropOffsControl,
  pickupDropOffsErrors,
  setPickupDropOffsValue,
  isPickupDropOffsValid,
  pickupsDropoffsTrigger,
  watch,
  errors,
  setValue,
  handleClearDate,
  statesData,
  serializedStates,
  editMode = false,
  id,
  refetchLoadPoints,
  minDate,
  maxDate = new Date(48484106443344),
  setTimePeriod,
  timePeriod,
  disabled,
  timeDisabled,
  isEditCreating,
  setIsEditCreating,
  editedId,
  setEditedId,
  data,
  pickupsDropoffsReset,
  hasAddPickupDropOffPermission,
  hasEditPickupDropOffPermission,
  filledPickUpDropOffs,
  submitted,
  carrierInsurance: insuranceDate,
  isCanceledClaimed,
  activeSection,
  iAmSecondAgent = false,
  invalidLoadPointIds,
  regularError,
  connectingError,
  insurance,
  clearPickupDropoffsError,
}: any) => {
  const { addAddress, showForm, setShowForm, resetPickupsForm, userInfo, handleRowDelete } = usePickupsDropoffs({
    setValue,
    watch,
    setPickupDropOffsValue,
    editMode,
    id,
    pickupDropOffsWatch,
    refetchLoadPoints,
    pickupsDropoffsReset,
  });
  const carrierInsurance = insurance || insuranceDate;
  const showAddButton = !editMode || (!showForm && hasAddPickupDropOffPermission);
  const pickupFormRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showForm && pickupFormRef?.current && !!(watch('pickups')?.length || watch('dropOffs')?.length)) {
      if (pickupFormRef?.current) {
        pickupFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [showForm, pickupFormRef?.current]);
  const filledLength = Number(watch('load_type')) == LoadTypes['Internal'] ? 5 : 8;

  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    if (!showForm) {
      setInitialValues({});
      setIsEditCreating(false);
      setTimePeriod('AM');
      setEditedId('');
      clearPickupDropoffsError(['address', 'facility']);
    }
  }, [showForm]);

  const isDisablePickupDropOffChanges = (mode: string) => {
    if (editMode) {
      if (data?.load?.status !== LoadStatus.LOAD_STATUS_NOT_PROCESSING) {
        return true;
      } else if (mode === 'edit' ? !hasEditPickupDropOffPermission : !hasAddPickupDropOffPermission) {
        return true;
      }
    }
    return false;
  };

  const isNotEditable = isDisablePickupDropOffChanges('edit');

  const isNotAddable = isDisablePickupDropOffChanges('add');

  const findMaxDate = () => {
    if (!editMode) {
      if (!watch('carrier').length) {
        return maxDate;
      } else {
        return !carrierInsurance || (typeof maxDate === 'number' ? maxDate : maxDate.getTime()) < carrierInsurance
          ? maxDate
          : new Date(carrierInsurance);
      }
    } else if (!data?.load?.carrier?.id) {
      new Date(changeTimeZone(maxDate, 'America/Los_Angeles', true));
    } else {
      return !carrierInsurance || changeTimeZone(maxDate, 'America/Los_Angeles', true) < carrierInsurance
        ? new Date(changeTimeZone(maxDate, 'America/Los_Angeles', true))
        : new Date(carrierInsurance);
    }
  };

  return (
    <div
      className={clsx({
        [styles.editloadSection]: editMode,
      })}
    >
      <ViewItemWrapper
        id="pickupps-drop-offs"
        className={activeSection === 'pickupps-drop-offs' ? 'active' : ''}
        title={SUBJECT.PICKUPS_DROPOFFS}
        canceledBlock={isCanceledClaimed}
      >
        <div className={clsx(styles.pickUpsAndDropOffsSection)} id="pickups-dropoffs">
          {!!(watch('pickups')?.length || watch('dropOffs')?.length) && (
            <div className={styles.pickupDropOffMainContainer}>
              <Row gutter={[0, 25]}>
                {!!watch('pickups').length && (
                  <Col span={24}>
                    <DataGrid
                      title={SUBJECT.PICKUPS}
                      type="pickup_dropOffs"
                      data={pickupsDropOffsSerializer(watch('pickups'), editMode, serializedStates, userInfo?.username)}
                      handleRowDelete={id => handleRowDelete(id, '1')}
                      isEditCreating={isEditCreating || !editMode}
                      isFirstItemNotDeletable
                      hasEditPickupDropOffPermission={hasEditPickupDropOffPermission}
                      isNotEditable={isNotEditable}
                      iAmSecondAgent={iAmSecondAgent && editMode}
                      isPickupDropoffs
                      handleRowEdit={record => {
                        setShowForm(true);
                        const pickUp = watch('pickups')?.find((el: any) => el.id === record.id);
                        const appointmentDate = editMode
                          ? new Date(changeTimeZone(pickUp.appointmentDate, 'America/Los_Angeles', true))
                          : new Date(
                              new Date(pickUp.appointmentDate).getFullYear(),
                              new Date(pickUp.appointmentDate).getMonth(),
                              new Date(pickUp.appointmentDate).getDate()
                            );
                        const appointmentTime = editMode
                          ? new Date(changeTimeZone(pickUp.appointmentDate, 'America/Los_Angeles', true))
                              .toLocaleString('en-US')
                              .split(',')[1]
                              .split(' ')[1]
                              .slice(0, -3)
                          : record.dateAndTime?.split(',')[1].split(' ')[1];

                        const timePeriod = editMode
                          ? new Date(changeTimeZone(pickUp.appointmentDate, 'America/Los_Angeles', true))
                              .toLocaleString('en-US')
                              .split(',')[1]
                              .split(' ')[2]
                          : record.dateAndTime?.split(',')[1].split(' ')[2];

                        pickupsDropoffsReset({
                          ...pickUp,
                          appointmentDate,
                          appointmentTime,
                        });
                        setInitialValues({
                          appointmentDate,
                          appointmentTime,
                          timePeriod,
                        });
                        setEditedId(record.id);
                        setIsEditCreating(true);
                      }}
                      highlightErrorsRows={invalidLoadPointIds}
                      scrollIntoView={regularError?.data?.code === 1320 || connectingError?.data?.code === 1320}
                    />
                  </Col>
                )}
                {!!watch('dropOffs').length && (
                  <Col span={24}>
                    <DataGrid
                      title={SUBJECT.DROP_OFFS}
                      type="pickup_dropOffs"
                      data={pickupsDropOffsSerializer(
                        watch('dropOffs'),
                        editMode,
                        serializedStates,
                        userInfo?.username
                      )}
                      handleRowDelete={id => {
                        handleRowDelete(id, '2');
                      }}
                      isEditCreating={isEditCreating || !editMode}
                      isNotEditable={isNotEditable}
                      iAmSecondAgent={iAmSecondAgent && editMode}
                      isPickupDropoffs
                      hasEditPickupDropOffPermission={hasEditPickupDropOffPermission}
                      isFirstItemNotDeletable={watch('dropOffs').length === 1}
                      handleRowEdit={record => {
                        setShowForm(true);
                        const dropOfs = watch('dropOffs')?.find((el: any) => el.id === record.id);
                        const appointmentDate = editMode
                          ? new Date(changeTimeZone(dropOfs.appointmentDate, 'America/Los_Angeles', true))
                          : new Date(
                              new Date(dropOfs.appointmentDate).getFullYear(),
                              new Date(dropOfs.appointmentDate).getMonth(),
                              new Date(dropOfs.appointmentDate).getDate()
                            );
                        const appointmentTime = editMode
                          ? new Date(changeTimeZone(dropOfs.appointmentDate, 'America/Los_Angeles', true))
                              .toLocaleString('en-US')
                              .split(',')[1]
                              .split(' ')[1]
                              .slice(0, -3)
                          : record.dateAndTime?.split(',')[1].split(' ')[1];

                        const timePeriod = editMode
                          ? new Date(changeTimeZone(dropOfs.appointmentDate, 'America/Los_Angeles', true))
                              .toLocaleString('en-US')
                              .split(',')[1]
                              .split(' ')[2]
                          : record.dateAndTime?.split(',')[1].split(' ')[2];
                        pickupsDropoffsReset({
                          ...dropOfs,
                          appointmentDate,
                          appointmentTime,
                        });
                        setInitialValues({
                          appointmentDate,
                          appointmentTime,
                          timePeriod,
                        });
                        setEditedId(record.id);
                        setIsEditCreating(true);
                      }}
                      highlightErrorsRows={invalidLoadPointIds}
                      scrollIntoView={regularError?.data?.code === 1320 || connectingError?.data?.code === 1320}
                    />
                  </Col>
                )}
              </Row>
            </div>
          )}
        </div>
        {showAddButton && !showForm && (
          <Col span={24}>
            <Row justify="end">
              <Tooltip
                placement="top"
                title={(!isPickupDropOffsValid && showForm) || isNotAddable ? SUBJECT.NOT_ALLOWED_ADD : ''}
                color={'white'}
              >
                <span>
                  <Button
                    children={SUBJECT.ADD_ADDRESS}
                    disabled={(!isPickupDropOffsValid && showForm) || isNotAddable}
                    variant="comment"
                    onClick={() => setShowForm(true)}
                  />
                </span>
              </Tooltip>
            </Row>
          </Col>
        )}
        {showForm && (
          <div className={styles.editBlock} ref={pickupFormRef}>
            <Row gutter={[18, 40]}>
              <>
                <Col span={+watch('load_type') !== LoadTypes['Internal'] ? 16 : 24}>
                  <Controller
                    name="dropType"
                    control={pickupDropOffsControl}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <SimpleSelect
                          options={getDropTypes(!!watch('pickups')?.length)}
                          placeholder={SUBJECT.DROP_TYPE}
                          name={field.name}
                          onChange={(value: string | string[]) => {
                            setPickupDropOffsValue(field.name, value, { shouldValidate: true });
                          }}
                          onBlur={() => pickupsDropoffsTrigger('dropType')}
                          errors={pickupDropOffsErrors}
                          value={field.value}
                          required
                          isDisabled={isEditCreating}
                        />
                      );
                    }}
                  />
                  <ErrorMessage fieldError={pickupDropOffsErrors.dropType} />
                </Col>
                {+watch('load_type') !== LoadTypes['Internal'] && (
                  <Col span={8}>
                    <Input
                      name="facility"
                      control={pickupDropOffsControl}
                      placeholder={SUBJECT.FACILITY}
                      errors={pickupDropOffsErrors}
                      required={+watch('load_type') !== LoadTypes['Internal']}
                      rules={{
                        required: +watch('load_type') !== LoadTypes['Internal'],
                      }}
                      getValues={pickupDropOffsWatch}
                    />
                  </Col>
                )}
                <Col span={+watch('load_type') !== LoadTypes['Internal'] ? 8 : 12}>
                  <DatePicker
                    name="appointmentDate"
                    control={pickupDropOffsControl}
                    placeholder={SUBJECT.APPOINTMENT_DATE}
                    errors={pickupDropOffsErrors}
                    onClear={handleClearDate}
                    withDisableText
                    isAbleToSelectFutureValue
                    onBlur={() => pickupsDropoffsTrigger('appointmentDate')}
                    rules={{
                      required: true,
                    }}
                    required
                    minDate={
                      editMode && minDate ? new Date(changeTimeZone(minDate, 'America/Los_Angeles', true)) : minDate
                    }
                    maxDate={findMaxDate()}
                    disabled={disabled}
                    shouldRangeValidate
                    mode={'pickUp'}
                    carrierInsurance={carrierInsurance}
                    hasInsurance={
                      editMode
                        ? data?.load?.carrier?.id && !!carrierInsurance
                        : watch('carrier').length && !!carrierInsurance
                    }
                  />
                </Col>
                <Col span={+watch('load_type') !== LoadTypes['Internal'] ? 8 : 12}>
                  <TimePicker
                    name="appointmentTime"
                    control={pickupDropOffsControl}
                    placeholder={SUBJECT.APPOINTMENT_TIME}
                    errors={pickupDropOffsErrors}
                    value={pickupDropOffsWatch('appointmentTime')}
                    onBlur={() => pickupsDropoffsTrigger('appointmentTime')}
                    required={true}
                    setTimePeriod={setTimePeriod}
                    disabled={timeDisabled}
                    initialValues={initialValues}
                    clearPickupDropoffsError={clearPickupDropoffsError}
                    onChange={value => {
                      setPickupDropOffsValue('appointmentTime', value, { shouldDirty: true, shouldValidate: true });
                    }}
                  />
                </Col>
                {+watch('load_type') !== LoadTypes['Internal'] && (
                  <Col span={8}>
                    <Input
                      name="phoneNumber"
                      control={pickupDropOffsControl}
                      placeholder={SUBJECT.PHONE_NUMBER}
                      errors={pickupDropOffsErrors}
                      getValues={pickupDropOffsWatch}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPickupDropOffsValue('phoneNumber', formatPhone(e.target.value), {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                    />
                  </Col>
                )}

                {+watch('load_type') !== LoadTypes['Internal'] && (
                  <Col span={8}>
                    <Input
                      name="address"
                      control={pickupDropOffsControl}
                      placeholder={SUBJECT.ADDRESS}
                      errors={pickupDropOffsErrors}
                      required={+watch('load_type') !== LoadTypes['Internal']}
                      rules={{
                        required: +watch('load_type') !== LoadTypes['Internal'],
                      }}
                      getValues={pickupDropOffsWatch}
                    />
                  </Col>
                )}
                <Col span={+watch('load_type') !== LoadTypes['Internal'] ? 16 : 24}>
                  <Row gutter={[18, 0]}>
                    <Col span={8}>
                      <Input
                        name="city"
                        rules={{ required: true }}
                        control={pickupDropOffsControl}
                        placeholder={SUBJECT.CITY}
                        errors={pickupDropOffsErrors}
                        getValues={pickupDropOffsWatch}
                        required
                        onChange={(e: any) => setPickupDropOffsValue('city', e.target.value.trimStart())}
                      />
                    </Col>
                    <Col span={8}>
                      <Controller
                        name="state"
                        control={pickupDropOffsControl}
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <StateSelect
                              options={statesData}
                              placeholder={SUBJECT.STATE}
                              name={field.name}
                              errors={pickupDropOffsErrors}
                              onBlur={() => pickupsDropoffsTrigger('state')}
                              onChange={(value: string) =>
                                setPickupDropOffsValue('state', value, {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                  shouldTouch: true,
                                })
                              }
                              value={field.value}
                              required
                              allowClear
                            />
                          );
                        }}
                      />
                      <ErrorMessage fieldError={pickupDropOffsErrors?.state} />
                    </Col>
                    <Col span={8}>
                      <Input
                        name="zip"
                        type="string"
                        control={pickupDropOffsControl}
                        placeholder={SUBJECT.ZIP}
                        errors={pickupDropOffsErrors}
                        getValues={pickupDropOffsWatch}
                        rules={{ required: true }}
                        required={true}
                        onChange={(e: any) => {
                          setPickupDropOffsValue('zip', onlyNumbersFormatter(e.target.value), {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          pickupsDropoffsTrigger(['zip']);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Input
                    type="text"
                    control={pickupDropOffsControl}
                    name="comment"
                    getValues={pickupDropOffsWatch}
                    placeholder={SUBJECT.WRITE_COMMENT}
                    onChange={(e: any) => setPickupDropOffsValue('comment', e.target.value.trimStart())}
                  />
                </Col>
                <Col span={24}>
                  <Row justify="end" gutter={[20, 0]}>
                    {!!(watch('pickups')?.length || watch('dropOffs')?.length) && (
                      <Col>
                        <Button
                          children={SUBJECT.CANCEL}
                          variant="outlined"
                          onClick={() => {
                            setTimeout(() => {
                              setIsEditCreating(false);
                              resetPickupsForm();
                              setShowForm(false);
                            });
                          }}
                        />
                      </Col>
                    )}
                    <Col>
                      <Button
                        children={isEditCreating ? SUBJECT.SAVE : SUBJECT.ADD}
                        variant="comment"
                        disabled={!isPickupDropOffsValid}
                        onClick={() => {
                          addAddress(
                            {
                              timePeriod: timePeriod,
                              dropType: pickupDropOffsWatch('dropType'),
                              phoneNumber: pickupDropOffsWatch('phoneNumber'),
                              appointmentDate: new Date(
                                formatPickUpDropOffForSubmitting(pickupDropOffsWatch('appointmentDate')).split(',')[0]
                              ),
                              appointmentTime: pickupDropOffsWatch('appointmentTime'),
                              facility: pickupDropOffsWatch('facility'),
                              address: pickupDropOffsWatch('address'),
                              city: pickupDropOffsWatch('city'),
                              state: pickupDropOffsWatch('state'),
                              zip: pickupDropOffsWatch('zip'),
                              comment: pickupDropOffsWatch('comment'),
                              ...(editMode ? { id: editedId } : !isEditCreating ? { id: uuidv4() } : { id: editedId }),
                              creator: {
                                firstName: userInfo?.firstName,
                                lastName: userInfo?.lastName,
                              },
                            },
                            pickupDropOffsWatch('dropType'),
                            isEditCreating
                          );
                          setShowForm(false);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </>
            </Row>
          </div>
        )}
        <Row>
          <Col>
            {errors?.pickups && errors?.dropOffs && (
              <ErrorMessage fieldError={{ message: 'You have to add at least one pickup and one dropoff' }} />
            )}
            {!errors?.pickups && errors?.dropOffs && (
              <ErrorMessage fieldError={{ message: 'You have to add at least one dropoff' }} />
            )}
            {!errors?.pickups &&
              !errors?.dropOffs &&
              !Object.keys(pickupDropOffsErrors).length &&
              filledPickUpDropOffs?.length === filledLength &&
              submitted && (
                <ErrorMessage
                  fieldError={{ message: 'You have to add or cancel your filed pickup/dropoff data before saving' }}
                />
              )}
          </Col>
        </Row>
      </ViewItemWrapper>
    </div>
  );
};
