import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import LastLoadsTable from 'pages/truck-board/components/view-edit-carrier-truck/components/last-loads-table/LastLoadsTable';
import { ICarrierType, RatingAccessTypes } from 'services/profile/carriers/interfaces';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Rate from 'ui/rate/Rate';
import Typography from 'ui/typography/Typography';
import { formatDate, formatDateBirth } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import CarrierPaymentBreakdown from 'components/carrier-payment-breakdown/CarrierPaymentBreakdown';
import { ICarrierPaymentBreakDownData } from 'components/carrier-payment-breakdown/constants/types';
import CustomComment from 'components/custom-comment/CustomComment';
import CopyIcon from 'components/svgs/CopyIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from '../../constants/constants';
import CreateCarrier from '../create-carrier/CreateCarrier';

import { IEditCarrierProps } from './constants/types';
import { useEditCarrier } from './useEditCarrier';

import styles from './EditCarrier.module.scss';

const EditCarrier = ({
  handleSubmit,
  onSubmit,
  control,
  getValues,
  errors,
  setValue,
  watch,
  mode,
  activeSection,
  statesData,
  allFiles,
  beforeUpload,
  handleDeleteCarrierFile,
  handleDownloadCarrieryFile,
  handleViewCarrieryFileEdit,
  carrierCommentsInfo,
  carrierInfo,
  getFilesByType,
  carrierRequestInfo,
  createComment,
  trucksByCarrierId,
  trigger,
  handleDeleteCommentModal,
  isLoading,
  getMoreComments,
  isShowMoreVisible,
  resetField,
  truckSectionRef,
  handleNewFileDownload,
  handleNewFileView,
  userInfo,
  carrierLastLoads,
  handleCarrierLasLoadsPaginationClick,
  isCarrierLastLoadsLoading,
  isEditLoading,
  breakdowns,
  isCreateLoading,
  breakdownParams,
  handleCarrierBreakdownPaginationClick,
  handleBreakdownSortClick,
  isBreakdownLoading,
  hasDeleteCommentPermission,
  handleTruckPaginationClick,
  isCommentFetching,
  truckState,
  setTruckState,
  refetchTrucksById,
  setPaymentTermDirty,
  isSubmitted,
  dirtyFields,
  clearErrors,
  carrierRequestCarrier,
}: IEditCarrierProps) => {
  const {
    rateList,
    isAproveMode,
    dispatch,
    handleDeleteRate,
    getCarrierRatings,
    handleCarrierRate,
    handleDownloadAll,
  } = useEditCarrier({ carrierInfo });
  const { copiedText, handleCopy } = useHandleCopyActive();

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isEditLoading && !isCreateLoading }));
  }, [isEditLoading, isCreateLoading]);

  return (
    <div className={styles.carriersEdit}>
      <MainLoader isFetching={isLoading} />
      <ViewItemWrapper
        title={SUBJECT.CARRIER_INFO}
        className={activeSection === 'carrier-info' ? 'active' : ''}
        id="carrier-info"
      >
        <Row gutter={[33, 0]} align="middle">
          <Col span={8}>
            <div className={styles.topBannerPairs}>
              <span className="top-banner-title">{SUBJECT.COMPANY_NAME_COLON}</span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={carrierInfo?.companyName || carrierRequestInfo?.companyName}
                subject={SUBJECT.COMPANY_NAME_COLON}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.topBannerPairs}>
              <span className="top-banner-title">{SUBJECT.CARRIER_ID_COLON}</span>
              <Col span={13} style={{ paddingLeft: '0px' }}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === 'topBannerId',
                  })}
                >
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={carrierInfo?.id || carrierRequestInfo?.id}
                    subject={SUBJECT.CARRIER_ID_COLON}
                  />
                  <div
                    className={styles.copyIcon}
                    role="button"
                    onClick={() => handleCopy(carrierInfo?.id || carrierRequestInfo?.id, 'topBannerId')}
                  >
                    <CopyIcon active={copiedText === 'topBannerId'} />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
          {(carrierInfo?.mc || carrierRequestInfo?.mc) && (
            <Col span={8}>
              <div className={styles.topBannerPairs}>
                <span className="top-banner-title">{SUBJECT.MC_COLON}</span>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={carrierInfo?.mc || carrierRequestInfo?.mc}
                  subject={SUBJECT.MC_COLON}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row gutter={[33, 0]} className={styles.columnsGap} align="middle">
          <Col span={8}>
            <div className={styles.topBannerPairs}>
              <span className="top-banner-title">{SUBJECT.CONTACT_NAME_COLON}</span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={carrierInfo?.contactName || carrierRequestInfo?.contactName}
                subject={SUBJECT.CONTACT_NAME_COLON}
              />
            </div>
          </Col>
          {!!carrierInfo?.type && (
            <Col span={8}>
              <span className="top-banner-title">{SUBJECT.CARRIER_TYPE_COLON}</span>{' '}
              <span className="top-banner-value">{ICarrierType[carrierInfo?.type] || carrierRequestInfo?.type}</span>
            </Col>
          )}
          {!carrierInfo?.type && (
            <>
              {carrierInfo?.fax && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.FAX_COLON}</span>{' '}
                  <span className="top-banner-value">{carrierInfo?.fax}</span>
                </Col>
              )}
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.RATING_COLON}</span>
                <Rate
                  canRate={carrierInfo?.accessRating}
                  averageValue={carrierInfo?.rating}
                  handleRate={handleCarrierRate}
                  getRatings={getCarrierRatings}
                  isFirstTime={carrierInfo?.accessRating === RatingAccessTypes.ADD_RATING}
                  rateCount={carrierInfo?.ratingCount}
                  rateList={rateList}
                  handleDeleteRate={handleDeleteRate}
                />
              </Col>
            </>
          )}
        </Row>
        {!!carrierInfo?.type && (
          <Row gutter={[33, 0]} className={styles.columnsGap} align="middle">
            {!!(carrierInfo?.phone || carrierRequestInfo?.phone) && (
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.PHONE_NUMBER_COLON} </span>
                <Link className="top-banner-value" to={`tel:${carrierInfo?.phone || carrierRequestInfo?.phone}`}>
                  {formatPhone(carrierInfo?.phone || carrierRequestInfo?.phone)}
                </Link>
              </Col>
            )}
            {carrierInfo?.fax && (
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.FAX_COLON}</span>{' '}
                <span className="top-banner-value">{carrierInfo?.fax}</span>
              </Col>
            )}
            <Col span={8}>
              <span className="top-banner-title">{SUBJECT.RATING_COLON}</span>
              <Rate
                canRate={carrierInfo?.accessRating}
                averageValue={carrierInfo?.rating}
                handleRate={handleCarrierRate}
                getRatings={getCarrierRatings}
                isFirstTime={carrierInfo?.accessRating === RatingAccessTypes.ADD_RATING}
                rateCount={carrierInfo?.ratingCount}
                rateList={rateList}
                handleDeleteRate={handleDeleteRate}
              />
            </Col>
          </Row>
        )}

        {carrierInfo?.secondPhone && (
          <Row className={styles.columnsGap} align="middle">
            <Col span={24}>
              <span className="top-banner-title">{SUBJECT.SECOND_NUMBER_COLON}</span>
              <Link className="top-banner-value" to={`tel:${carrierInfo?.secondPhone}`}>
                {' '}
                {formatPhone(carrierInfo?.secondPhone)}
              </Link>
            </Col>
          </Row>
        )}

        {!!carrierInfo?.coordinator?.length && (
          <>
            <Divider className={styles.divider} />
            <Row className={styles.columnsGap} align="middle">
              <Col span={24}>
                <span className="top-banner-title">{SUBJECT.COORDINATOR_COLON} </span>
                <span className="top-banner-value">Mikel</span>
              </Col>
            </Row>
          </>
        )}
        {!!carrierInfo?.cargo && (
          <>
            <Divider className={styles.divider} />
            <Row gutter={[33, 0]} align="middle" className={styles.columnsGap}>
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.INSURANCE_LIABILITY}</span>
                <span className="top-banner-value">{formatDateBirth(carrierInfo?.liability)}</span>
              </Col>
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.INSURANCE_CARGO}</span>
                <span className="top-banner-value">{formatDateBirth(carrierInfo?.cargo)}</span>
              </Col>
              {!!carrierInfo?.cargoCoverageValue && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.CARGO_COVERAGE_VALUE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.cargoCoverageValue, true)}
                  </span>
                </Col>
              )}
            </Row>
            {(!!carrierInfo?.nonOwnedCoverage || !!carrierInfo?.trailerInterchange || carrierInfo?.reeferBreakdown) && (
              <Divider className={styles.divider} />
            )}
            <Row gutter={[33, 0]} align="middle" className={styles.columnsGap}>
              {!!carrierInfo?.nonOwnedCoverage && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.NON_OWNED_COVERAGE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.nonOwnedCoverage, true)}
                  </span>
                </Col>
              )}
              {!!carrierInfo?.trailerInterchange && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.TRAILER_INTERCHANGE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.trailerInterchange, true)}
                  </span>
                </Col>
              )}
              {!!carrierInfo?.reeferBreakdown && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.REEFER_BREAKDOWN + ':'}</span>{' '}
                  <span className="top-banner-value">{SUBJECT.CAN_HAUL}</span>
                </Col>
              )}
            </Row>
          </>
        )}
      </ViewItemWrapper>
      <CreateCarrier
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        getValues={getValues}
        setValue={setValue}
        control={control}
        errors={errors}
        mode={mode}
        activeSection={activeSection}
        statesData={statesData}
        allFiles={allFiles}
        beforeUpload={beforeUpload}
        handleDeleteCarrierFile={handleDeleteCarrierFile}
        handleDownloadCarrieryFile={handleDownloadCarrieryFile}
        handleViewCarrieryFileEdit={handleViewCarrieryFileEdit}
        carrierInfo={carrierInfo}
        getFilesByType={getFilesByType}
        trigger={trigger}
        watch={watch}
        truckSectionRef={truckSectionRef}
        handleDownloadFileCreate={handleNewFileDownload}
        handleViewFileCreate={handleNewFileView}
        isEditLoading={isEditLoading}
        isCreateLoading={isCreateLoading}
        resetField={resetField}
        handleTruckPaginationClick={handleTruckPaginationClick}
        trucksByCarrierId={trucksByCarrierId}
        truckState={truckState}
        setTruckState={setTruckState}
        refetchTrucksById={refetchTrucksById}
        setPaymentTermDirty={setPaymentTermDirty}
        isSubmitted={isSubmitted}
        dirtyFields={dirtyFields}
        clearErrors={clearErrors}
        carrierRequestCarrier={carrierRequestCarrier}
      />
      {!!carrierLastLoads?.count && (
        <ViewItemWrapper
          title={SUBJECT.LAST_LOADS_TITLE}
          className={activeSection === SUBJECT.LAST_LOADS_ID ? 'active' : ''}
          id={SUBJECT.LAST_LOADS_ID}
        >
          <LastLoadsTable
            isLoading={isCarrierLastLoadsLoading}
            carrierLastLoads={carrierLastLoads}
            onPaginationClick={handleCarrierLasLoadsPaginationClick}
          />
        </ViewItemWrapper>
      )}
      {!!breakdowns?.count && (
        <>
          <ViewItemWrapper
            title={SUBJECT.CARRIER_PAYMENT_BREAKDOWN}
            className={activeSection === SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID ? 'active' : ''}
            Component={
              <Row align="middle" gutter={11}>
                <Col>
                  <Typography variant="paragraph" className={styles.infoParagraph} children={SUBJECT.DOWNLOAD_ALL} />
                </Col>
                <Col>
                  <div
                    className={styles.downloadAllFiles}
                    onClick={() => handleDownloadAll(breakdowns?.result?.map((file: any) => file))}
                  >
                    <DownloadIcon />
                  </div>
                </Col>
              </Row>
            }
          >
            <CarrierPaymentBreakdown
              data={{
                result: breakdowns?.result?.map((item: ICarrierPaymentBreakDownData) => {
                  return {
                    download: item?.download,
                    id: item?.id,
                    name: item?.name,
                    createdAt: formatDate(item?.createdAt),
                    preview: item?.preview,
                  };
                }),
                count: breakdowns?.count,
              }}
              skip={breakdownParams.skip}
              limit={breakdownParams.limit}
              isLoading={isBreakdownLoading}
              onPaginationClick={handleCarrierBreakdownPaginationClick}
              onSort={handleBreakdownSortClick}
              withPagination={true}
            />
          </ViewItemWrapper>
        </>
      )}
      {isAproveMode === 'true' ? null : (
        <CustomComment
          id={SUBJECT.COMMENT}
          control={control}
          comments={carrierCommentsInfo}
          createComment={createComment}
          deleteComment={handleDeleteCommentModal}
          setValue={setValue}
          watch={watch!}
          name="commentWithNote"
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          canCreate={!userInfo?.agentType}
          canDelete={!userInfo?.agentType}
          hasDeleteCommentPermission={hasDeleteCommentPermission}
          isLoadsCommentsLoading={isCommentFetching}
          activeSection={activeSection}
        />
      )}
    </div>
  );
};

export default EditCarrier;
