export enum SUBJECT {
  ALL = 'All',
  COORDINATOR_LIST_EMPTY = 'Coordinator List Is Empty',
  RESET_ALL = 'Reset All',
  CARRIER_STATUS = 'Carrier Status',
  COORDINATOR = 'Coordinator',
  NOT_SELECTED = 'Not Selected',
  CARRIER_STATUS_FILTER = 'carrierStatus',
  COORDINATOR_FILTER = 'coordinator',
  MINE_FILTER = 'mine',
  MINE = 'Mine',
}
export const filterDefaultValues = {
  carrierStatus: null,
  coordinator: [],
};

export const carrierStatusOptions = [
  { value: '1', title: 'Standard' },
  { value: '2', title: 'Already Has a Load' },
];
