import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'services/service';

import { ICustomizedColumns, IGetColumnsBody } from './interfaces';
import { getColumnsSerializer, getDefaultColumnsSerializer } from './serializers';

export const userAPI = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['Columns', 'Notifications'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getColumns: builder.query({
      query: ({ type }: Partial<any>) => ({
        url: `/users/customized-columns/${type}`,
      }),
      transformResponse: (body: IGetColumnsBody): ICustomizedColumns => getColumnsSerializer(body),
      providesTags: ['Columns'],
    }),
    getDefaultColumns: builder.query({
      query: ({ type }: Partial<any>) => ({
        url: `/users/customized-columns/default/${type}`,
      }),
      keepUnusedDataFor: 0.000001,
      transformResponse: (body: IGetColumnsBody): ICustomizedColumns => getDefaultColumnsSerializer(body),
      providesTags: ['Columns'],
    }),
    editColumns: builder.mutation({
      query: ({ type, columns }) => ({
        url: '/users/customized-columns',
        method: 'PUT',
        body: { type, columns: JSON.stringify(columns) },
      }),
      invalidatesTags: ['Columns'],
    }),
    deleteColumns: builder.mutation({
      query: ({ type }) => ({
        url: `/users/customized-columns/${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Columns'],
    }),
    changeUserPassword: builder.mutation({
      query: ({ currentPassword, newPassword, email }) => ({
        url: `/users/password`,
        method: 'PUT',
        body: { oldPassword: currentPassword, newPassword, email },
      }),
    }),
    getNotifications: builder.query({
      query: ({ skip, limit }) => ({
        url: 'users/notifications',
        params: { skip, limit },
      }),
      providesTags: ['Notifications'],
    }),
    setReadNotificationsIds: builder.mutation({
      query: ({ ids, all }) => ({
        url: 'users/notifications/read',
        method: 'PUT',
        body: { ...(ids ? { ids } : {}), ...(all ? { all } : {}) },
      }),
    }),
  }),
});

export const {
  useGetColumnsQuery,
  useLazyGetColumnsQuery,
  useEditColumnsMutation,
  useDeleteColumnsMutation,
  useLazyGetDefaultColumnsQuery,
  useChangeUserPasswordMutation,
  useGetNotificationsQuery,
  useSetReadNotificationsIdsMutation,
} = userAPI;
