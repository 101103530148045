import { Table } from 'antd';

import { columns } from './constants/mock.columns';
import { useLastLoadsTable } from './useLastLoadTable';

import styles from './LastLoadsTable.module.scss';

const LastLoadsTable = ({ carrierLastLoads, isLoading, onPaginationClick }: any) => {
  const { pageSizeOptions, paginationArrows } = useLastLoadsTable();
  return (
    <div className={styles.lastLoadsTable}>
      <div className={styles.lastLoadsContent}>
        <Table
          rowKey="id"
          tableLayout="fixed"
          pagination={
            carrierLastLoads?.count > 20 && {
              showTotal(total, [from, to]) {
                return <span>{`${from}-${to} of ${total}`}</span>;
              },
              onChange(page, pageSize) {
                onPaginationClick && onPaginationClick((page - 1) * pageSize, pageSize);
              },
              pageSizeOptions: pageSizeOptions(carrierLastLoads.count!),
              itemRender: paginationArrows,
              locale: { items_per_page: '' },
              showSizeChanger: true,
              total: carrierLastLoads.count!,
            }
          }
          loading={isLoading}
          columns={columns}
          scroll={{ x: '100%' }}
          dataSource={carrierLastLoads?.result}
        />
      </div>
    </div>
  );
};
export default LastLoadsTable;
