const CarrierCustomerPendingIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5498 20C2.81647 20 2.19564 19.7458 1.6873 19.2375C1.17897 18.7292 0.924805 18.1083 0.924805 17.375V14.25H4.0998V0L5.5998 1.5L7.0998 0L8.5748 1.5L10.0748 0L11.5748 1.5L13.0748 0L14.5748 1.5L16.0748 0L17.5748 1.5L19.0748 0V17.375C19.0748 18.1083 18.8206 18.7292 18.3123 19.2375C17.804 19.7458 17.1831 20 16.4498 20H3.5498ZM16.4498 18.5C16.7831 18.5 17.054 18.3958 17.2623 18.1875C17.4706 17.9792 17.5748 17.7083 17.5748 17.375V2.5H5.5998V14.25H15.3248V17.375C15.3248 17.7083 15.429 17.9792 15.6373 18.1875C15.8456 18.3958 16.1165 18.5 16.4498 18.5ZM6.9248 6.45V4.95H12.9248V6.45H6.9248ZM6.9248 9.8V8.3H12.9248V9.8H6.9248ZM15.2498 6.45C15.0498 6.45 14.8748 6.375 14.7248 6.225C14.5748 6.075 14.4998 5.9 14.4998 5.7C14.4998 5.5 14.5748 5.325 14.7248 5.175C14.8748 5.025 15.0498 4.95 15.2498 4.95C15.4498 4.95 15.6248 5.025 15.7748 5.175C15.9248 5.325 15.9998 5.5 15.9998 5.7C15.9998 5.9 15.9248 6.075 15.7748 6.225C15.6248 6.375 15.4498 6.45 15.2498 6.45ZM15.2498 9.675C15.0498 9.675 14.8748 9.6 14.7248 9.45C14.5748 9.3 14.4998 9.125 14.4998 8.925C14.4998 8.725 14.5748 8.55 14.7248 8.4C14.8748 8.25 15.0498 8.175 15.2498 8.175C15.4498 8.175 15.6248 8.25 15.7748 8.4C15.9248 8.55 15.9998 8.725 15.9998 8.925C15.9998 9.125 15.9248 9.3 15.7748 9.45C15.6248 9.6 15.4498 9.675 15.2498 9.675ZM3.5248 18.5H13.8248V15.75H2.4248V17.375C2.4248 17.7083 2.52897 17.9792 2.7373 18.1875C2.94564 18.3958 3.20814 18.5 3.5248 18.5ZM2.4248 18.5V15.75V18.5Z"
        fill="#FF8718"
      />
    </svg>
  );
};

export default CarrierCustomerPendingIcon;
