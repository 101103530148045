export const VectorLeft = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3887 14.6318L14.3887 5.81511C14.3887 5.36633 14.2288 4.98208 13.9092 4.66238C13.5895 4.34268 13.2053 4.18283 12.7564 4.18283L3.84078 4.18283L5.99703 2.02658L4.85326 0.882812L0.737117 4.99897L4.85325 9.11514L5.99703 7.97136L3.84078 5.81511L12.7564 5.81511L12.7564 14.6318L14.3887 14.6318Z"
        fill="#252733"
      />
    </svg>
  );
};
