export enum SUBJECT {
  NEW_LOAD = 'New Load',
  ADD_LOAD = 'Add Load',
  CUSTOMER_ID = 'Customer Id',
  AMOUNT = 'Amount',
  REAL_AMOUNT = 'Real Amount',
  DEPT_NOT_PAID = 'Debt / Not Paid',
  DEPT_LIMIT = 'Debt / Limit',
  DEPT_LOAD_QTY = 'Debt / Load Qty',
  STATUS = 'Status',
  PAYMENT_METHOD = 'Payment Method',
  COMMENTS = 'Comments',
  CUSTOMER_NAME = 'Customer Name',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  AGENT = 'Agent',
  DATE = 'Date',
  LOAD_NUMBER = 'Load ID',
  CREDIT_CHECK = 'Credit Check',
  CREDIT_CHECK_ERROR = 'Your Credit Request Was Changed To Load.',
  OPEN_LOAD = 'Open Load',
}
