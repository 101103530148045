const TruckIcon = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7717 22.8868L20.7065 21.8216L22.2789 20.2238H13.4785C13.3095 20.8663 12.9586 21.3862 12.426 21.7835C11.8934 22.1809 11.3059 22.3795 10.6634 22.3795C10.004 22.3795 9.40802 22.1809 8.87543 21.7835C8.34284 21.3862 7.99201 20.8663 7.82293 20.2238H6.73239C5.87011 20.2238 5.15576 19.9448 4.58935 19.3869C4.02295 18.8289 3.73975 18.1188 3.73975 17.2565V11.3727H9.80113V7.97426H3.73975V6.5033H16.5726C17.046 6.5033 17.4603 6.68083 17.8153 7.03589C18.1704 7.39095 18.3479 7.81364 18.3479 8.30396V18.7529H22.2789L20.7065 17.1805L21.7717 16.1153L25.1448 19.4883L21.7717 22.8868ZM11.3228 11.3727H16.877V8.30396C16.877 8.21942 16.8431 8.14333 16.7755 8.0757C16.7079 8.00807 16.6403 7.97426 16.5726 7.97426H11.3228V11.3727ZM10.6381 20.9086C11.0607 20.9086 11.4116 20.7691 11.6906 20.4901C11.9695 20.2111 12.109 19.8603 12.109 19.4376C12.109 19.0318 11.9695 18.6895 11.6906 18.4105C11.4116 18.1315 11.0692 17.992 10.6634 17.992C10.2576 17.992 9.91102 18.1273 9.62359 18.3978C9.33617 18.6683 9.19245 19.0149 9.19245 19.4376C9.19245 19.8434 9.33194 20.19 9.61091 20.4774C9.88989 20.7649 10.2323 20.9086 10.6381 20.9086ZM7.82293 18.7529C7.99201 18.1104 8.34284 17.5778 8.87543 17.1551C9.40802 16.7324 10.004 16.5211 10.6634 16.5211C11.3059 16.5211 11.8934 16.7324 12.426 17.1551C12.9586 17.5778 13.3095 18.1104 13.4785 18.7529H16.877V12.8436H5.21071V17.2565C5.21071 17.6961 5.35442 18.0554 5.64185 18.3344C5.92928 18.6134 6.2928 18.7529 6.73239 18.7529H7.82293ZM7.82293 12.8436H5.21071H16.877H7.82293Z"
        fill="#252733"
      />
    </svg>
  );
};

export default TruckIcon;
