import React from 'react';
import { Col, Divider, Row } from 'antd';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'ui/typography/Typography';

import { SUBJECT } from './constants/constants';
import { IChangePasswordProps } from './constants/types';
import { Input } from './hoc-instance/instantce';

import styles from './ChangePassword.module.scss';

const ChangePassword = ({ control, watch, errors }: IChangePasswordProps) => {
  return (
    <div className={styles.changePasswordWrapper}>
      <div className={styles.topBanner}>
        <div className={styles.topBannerHeader}>
          <Typography className={styles.topBannerTitle} variant="heading" children={SUBJECT.CHANGE_PASSWORD} />
          <Divider />
        </div>
        <form autoComplete="off">
          <Row gutter={[0, 40]}>
            <Col span={16}>
              <Typography className={styles.passwordsTitle} variant="paragraph" children={SUBJECT.CURRENT_PASSWORD} />
              <div className={styles.currentItem}>
                <Input
                  autoComplete="new-password"
                  errors={errors}
                  getValues={watch}
                  control={control}
                  rules={{ required: true }}
                  required
                  name="currentPassword"
                  type="password"
                  placeholder={SUBJECT.CURRENT_PASSWORD}
                />
              </div>
            </Col>
            <Col span={16}>
              <Typography className={styles.passwordsTitle} variant="paragraph" children={SUBJECT.NEW_PASSWORD} />
              <div className={styles.currentItem}>
                <Input
                  errors={errors}
                  getValues={watch}
                  control={control}
                  rules={{ required: true }}
                  required
                  name="newPassword"
                  type="password"
                  placeholder={SUBJECT.NEW_PASSWORD}
                />
              </div>
              <div className={styles.currentItem}>
                <Input
                  errors={errors}
                  getValues={watch}
                  control={control}
                  rules={{ required: true }}
                  required
                  name="repeatPassword"
                  type="password"
                  placeholder={SUBJECT.REPEAT_PASSWORD}
                />
              </div>
            </Col>
          </Row>
        </form>
        <Row>
          <Col span={16}>
            <div className={styles.tooltipWrapper}>
              <Tooltip
                overlayClassName={styles.tooltipItem}
                color="white"
                placement="left"
                title={
                  <div className={styles.tooltipContent}>
                    <h3>{SUBJECT.PASSWORD_REQUIREMENTS}</h3>
                    <Typography variant="paragraph">{SUBJECT.PASSWORD_REQUIREMENTS_CONTENT}</Typography>
                  </div>
                }
              >
                <Typography variant="paragraph">{SUBJECT.PASSWORD_REQUIREMENTS}</Typography>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChangePassword;
