import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetAdvanceByIdQuery } from 'services/payroll/information/information';
import { useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import { useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { IRoleLevel } from 'services/truck-board/carrier-request/interfaces';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';

import { DEFAULT_VALUES_ADVANCE } from '../../constants/constants';
import { AdvancedCards, ICollectionValues } from '../../constants/types';
import { advanceValidation } from '../constants/validation';

export const useAdvance = () => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, open, card, id } = searchParams;

  const userInfo = useSelector(selectUserInfo);
  const { branchIds, officeIds } = userInfo;

  const { currentData: advanceInfo, isFetching: isAdvanceFetching } = useGetAdvanceByIdQuery(
    { id },
    { skip: !id || card !== AdvancedCards.ADVANCE }
  );
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const {
    control,
    formState: { isValid, errors, dirtyFields },
    watch,
    reset,
    trigger,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm<ICollectionValues>({
    defaultValues: DEFAULT_VALUES_ADVANCE,
    mode: 'all',
    resolver: yupResolver(advanceValidation(roleLevel)),
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const { data: officesOptions = { result: [], count: 0 } } = useGetOfficesAutocompleteQuery(officesFilter, {
    skip: roleLevel !== IRoleLevel.corpLevel || mode !== 'advance' || open !== 'true',
  });

  const { data: branchesData = { result: [], count: 0 } } = useGetBranchesAutocompleteQuery(
    { officeId: officeIds?.[0], withDefaults: true, ...branchFilter },
    { skip: roleLevel !== IRoleLevel.officeLevel || !officeIds?.[0] }
  );

  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    { ...agentsFilter, branchId: branchIds?.[0], excludeOwners: roleLevel === IRoleLevel.branchLevel },
    { skip: roleLevel !== IRoleLevel.branchLevel || !branchIds?.[0] }
  );

  const resetAdvanceForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    setOfficesFilter({ skip: 0, limit: 10, search: '' });
    setBranchFilter({ skip: 0, limit: 10, search: '' });
    setAgentsFilter({ skip: 0, limit: 10, search: '' });
    reset();
  };

  useEffect(() => {
    if (watch('agency')!.length !== 1) {
      setValue('branch', []);
      setValue('agent', []);
    }
  }, [watch('agency')]);

  useEffect(() => {
    if (watch('branch')!.length !== 1) {
      setValue('agent', []);
    }
  }, [watch('branch')]);

  useEffect(() => {
    if (advanceInfo?.result && mode === 'editAdvance') {
      const { office, branch, type, amount, comment, agent } = advanceInfo.result;
      reset({
        agency: office ? [{ id: office?.id, title: office?.name, value: String(office?.id) }] : [],
        branch: branch ? [{ id: branch?.id, title: branch?.name, value: String(branch?.id) }] : [],
        agent: agent
          ? [{ id: agent?.id, firstName: agent?.firstName, lastName: agent?.lastName, userName: agent.username }]
          : [],
        type: String(type),
        amount,
        reason: comment,
      });
    } else {
      reset(DEFAULT_VALUES_ADVANCE);
    }
  }, [advanceInfo, mode]);

  return {
    errors,
    control,
    isValid,
    mode,
    advanceInfo,
    branchesData,
    agentsOptions,
    officesOptions,
    isAdvanceFetching,
    watch,
    reset,
    trigger,
    setValue,
    clearErrors,
    handleSubmit,
    setBranchFilter,
    setAgentsFilter,
    setOfficesFilter,
    resetAdvanceForm,
    dirtyFields,
  };
};
