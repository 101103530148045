import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { createRequestCarrier, editRequestCarrierSelfDeserializer } from './deserializers';
import { getRequestCarrierSelfListSerializer, getRequestCarrierSelfSerializer } from './serializers';

export const carrierRequestApi = createApi({
  reducerPath: 'carrierRequestApi',
  baseQuery,
  tagTypes: ['CarrierRequest'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    createRequestCarrier: builder.mutation({
      query: body => ({
        url: 'carriers/request',
        method: 'POST',
        body: createRequestCarrier(body),
      }),
      invalidatesTags: ['CarrierRequest'],
    }),
    getCarrierRequestListSelf: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<any>) => ({
        url: 'carriers/request-self',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getRequestCarrierSelfListSerializer(body),
      providesTags: ['CarrierRequest'],
    }),
    getCarrierRequestSelf: builder.query({
      query: ({ id }) => `carriers/${id}/request`,
      providesTags: ['CarrierRequest'],
      transformResponse: body => getRequestCarrierSelfSerializer(body),
    }),
    editRequestCarrierSelf: builder.mutation({
      query: ({ body, id }) => ({
        url: `carriers/${id}/request`,
        method: 'PATCH',
        body: editRequestCarrierSelfDeserializer(body),
      }),
      invalidatesTags: ['CarrierRequest'],
    }),
  }),
});

export const {
  useCreateRequestCarrierMutation,
  useGetCarrierRequestListSelfQuery,
  useGetCarrierRequestSelfQuery,
  useEditRequestCarrierSelfMutation,
} = carrierRequestApi;
