import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { IEmployessParams } from 'services/profile/employees/interface';
import {
  useAddCoordinatorMutation,
  useDeleteCoordinatorMutation,
  useGetCoordinatorsQuery,
} from 'services/truck-board/truck-board/truckBoard';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { verificationAPI } from '../../../../../../services/truck-board/verification-board/verification';
import { ICoordinatorForm } from '../../../../constants/types';

import { SUBJECT } from './constants/constants';

const useAddCoordinator = ({ truckInfoById }: any) => {
  const dispatch = useDispatch();
  const { searchParams } = useDetectedParams();
  const { carrierId } = searchParams;

  const verificationPermissions = usePermittedActions('truckBoard.verification');

  const [coordinatorFilters, setCoordinatorFilters] = useState<Partial<IEmployessParams>>({
    level: 1,
    search: '',
    skip: 0,
    limit: 10,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const {
    control: coordinatorControl,
    watch: coordinatorWatch,
    setValue: coordinatorSetValue,
    reset: coordinatorReset,
    handleSubmit: coordinatorSubmit,
    formState: { errors: coordinatorErrors, dirtyFields: coordinatorDirtyFields },
    trigger: coordinatorTrigger,
  } = useForm<ICoordinatorForm>({
    defaultValues: { coordinator: [] },
    mode: 'all',
  });

  const coordinatorId = coordinatorWatch('coordinator')?.[0]?.id;

  const [addCoordinator] = useAddCoordinatorMutation();
  const [deleteCoordinator] = useDeleteCoordinatorMutation();

  const { data = { result: [], count: 0 } } = useGetCoordinatorsQuery(coordinatorFilters);
  const handleDeleteCoordinator = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          deleteCoordinator({ id: carrierId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              coordinatorSetValue('coordinator', []);
              dispatch(verificationAPI?.util?.invalidateTags(['VerificationList']));
              dispatch(changeLoading(false));
              dispatch(closeModal());
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleRowClick = ({ id, username }: any) => {
    coordinatorSetValue('coordinator', [{ username, id }], { shouldDirty: true });
  };

  const handleAddCoordinator = () => {
    addCoordinator({ id: carrierId, coordinatorId: Number(coordinatorId) }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully added',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(verificationAPI?.util?.invalidateTags(['VerificationList']));
      }
    });
  };

  useEffect(() => {
    coordinatorReset({
      coordinator: truckInfoById?.coordinatorId
        ? [
            {
              username: truckInfoById?.coordinator?.username,
              id: truckInfoById?.coordinator?.id,
            },
          ]
        : [],
    });
  }, [truckInfoById]);

  useEffect(() => {
    dispatch(setIsValid({ isValid: !!Object.keys(coordinatorDirtyFields)?.length }));
  }, [Object.keys(coordinatorDirtyFields)?.length]);

  return {
    coordinatorWatch,
    coordinatorControl,
    coordinatorSubmit,
    coordinatorErrors,
    handleDeleteCoordinator,
    handleRowClick,
    options: data,
    coordinatorSetValue,
    setCoordinatorFilters,
    handleAddCoordinator,
    coordinatorTrigger,
    verificationPermissions,
  };
};
export default useAddCoordinator;
