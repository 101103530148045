export enum ServerErrorCodes {
  'Something went wrong' = 1007,
  'Entity not found' = 1017,
  'Invalid user password' = 1018,
  'Your link has expired, please contact customer support' = 1019,
  'Validation error' = 1020,
  'Payout percent of agent and branch exceeds office payout percent' = 1351,
  'You have no permission to make transactions' = 1352,
  'User not found' = 1023,
  'You have a problems with validation' = 1026,
  'Invalid id' = 1027,
  'Invalid type' = 1029,
  'You have a problems with payload validation' = 1030,
  'You can not delete the entity' = 1033,
  'Duplicate error: Already exists' = 1034,
  'Entity already exists' = 1035,
  'You have a problem with your amounts' = 1036,
  'Invalid load status' = 1037,
  'Invoice already created' = 1038,
  'Action not allowed' = 1039,
  'Required files are missing' = 1040,
  'Invalid sold authority' = 1041,
  'Invalid carrier' = 1042,
  'Invalid loads' = 1043,
  'Invalid customer amount' = 1044,
  'Load has invalid data' = 1045,
  'Carrier in blacklist with this agent' = 1046,
  'Invalid load state' = 1048,
  "You can't delete this transaction because the carrier has already paid" = 1049,
  'Load already has carrier' = 1050,
  'Invalid carrier amount' = 1051,
  'Invalid pickups or dropoffs date' = 1052,
  'Invalid pickups or dropoffs' = 1053,
  'Invalid template' = 1056,
  'Invalid brackeds' = 1057,
  'Authority not found' = 1058,
  'Invalid state id' = 1059,
  'Branch not found' = 1062,
  'Email already exists' = 1063,
  'Invalid verification link' = 1064,
  'Password requirements are not met' = 1065,
  'Server error' = 1066,
  'Action error with customize columns' = 1067,
  'This contact already exists' = 1069,
  'Wrong email or password' = 1070,
  "Outside carrier can't post truck" = 1071,
  'Permission group already exists' = 1074,
  'MC must be unique' = 1080,
  'DOT must be unique' = 1081,
  'Authority MC must be unique' = 1082,
  'Customer MC must be unique' = 1083,
  'Customer DOT must be unique' = 1084,
  'FF must be unique' = 1085,
  'State number must be unique' = 1086,
  'Authority mark as black list' = 1087,
  'Agent bracket percent can not exceed branch bracket percent' = 1088,
  'Agent bracket percent can not exceed agency payout percent' = 1089,
  'Invalid email domain' = 1090,
  'User already terminated' = 1091,
  'This authority is the last authority for agency, please at first remove this authority from agency then you can terminate.' = 1095,
  'This authority is the last authority for branch, please at first remove this authority from branch then you can terminate.' = 1096,
  'This authority is the last authority for agent, please at first remove this authority from agent then you can terminate.' = 1097,
  'Authority DOT must be unique' = 1098,
  'Authority FF number already exists' = 1099,
  'Authority State number must be unique' = 1100,
  'FF number already exists' = 1101,
  'State number already exists' = 1102,
  'Carrier card already exists' = 1103,
  'Carrier truck number already exists' = 1104,
  'Customer and authority are in black list' = 1113,
  "Your password doesn't match the required password pattern" = 1114,
  'The customer has exceeded their allowed depth limit' = 1300,
  'Authority and customer are not attached' = 1301,
  'Authority already attached' = 1324,
  'Permission denied' = 1073,
  'Customer is terminated' = 1302,
  'Authority is terminated' = 1303,
  'Customer is not found' = 1304,
  'Authority is not found' = 1305,
  'Carrier is not found' = 1306,
  'Carrier is terminated' = 1307,
  'The truck was removed' = 1308,
  'Agent is terminated' = 1309,
  'Reference number must be unique' = 1310,
  'Automatic created transactions can not be deleted' = 1311,
  'Transactions not on customer can be deleted only current month' = 1312,
  'After accepting the billing, you can not delete the transaction' = 1313,
  'Can not delete customer received transactions' = 1314,
  'Can not delete carrier paid transactions' = 1315,
  'Can not delete agent paid transactions' = 1316,
  'Second agent amount can not be greater then customer amount' = 1317,
  'You have a problem with pickup or drop off validation, please look through the marked rows' = 1320,
  'In this status, you cannot disable sold as' = 1339,
  'The associated office must be activated before activating the branch' = 1332,
  'In this status, you cannot change sold as an MC' = 1321,
  'Bracket percent must be less or equal agency payout percent' = 1322,
  'In this status, you cannot add sold as an MC.' = 1323,
  'In this status, you cannot change reference number' = 1338,
  'Minimum branch bracket should be greater or equal to its agents maximum bracket percent' = 1325,
  'Sold as authority is terminated' = 1328,
  'Taken as authority is terminated' = 1329,
  'You must have at least one agent under selected office/branch' = 1330,
  'Transaction is out of limit' = 1334,
  "You can't write an amount greater than the second agent's amount." = 1333,
  'The carrier should be a positive number' = 1667,
  'User with this email already exists' = 1326,
  'User with this username already exists' = 1327,
  "If the load status is 'carrier paid' you cannot add a transaction on the carrier's side." = 1335,
  "If the load status is 'customer received' you cannot add a transaction on the customer's side." = 1336,
  'The load has to be unclaimed so it can be deleted.' = 1341,
  'In pickUp and dropOff you have few data with the same date and time' = 1340,
  'You are not allowed to do this action' = 1342,
  'You can not change sold as authority, after when you sent rate confirmation email' = 1343,
  'Must have a sold as authority' = 1344,
  "You can't open a deleted file." = 1345,
  "You can't change load type" = 1346,
  'Can not be change truck status to has a load' = 1349,
  'Oops! There was a problem. Please reload the page.' = 0,
  // sksumenq 2000-ic error coder@ el chen kara linen aveli poqr
  'Carrier insurance date must be before the first pickup date' = 2000,
  'Carrier final amount can not be negative' = 2001,
  'Customer final amount can not be negative' = 2002,
  'Negative carrier status is not allowed' = 2003,
  'User is not verified' = 2004,
  "The carrier doesn't have insurance for reefer loads" = 2009,
  "The carrier doesn't have NON-owned coverage" = 2006,
  'Owner operator must have at least one truck' = 2007,
  'The authority is not associated with the customer, or may be blacklisted' = 2010,
  'Carrier and Brokerage not assigned' = 2011,
  'Carrier and FreightMax not assigned' = 2012,
  'Current password is wrong' = 2013,
  'This link has been already used' = 2014,
  'Factoring Company is Required' = 2018,
}

export const FILE_MAX_SIZE_ERROR = 'File size exceeds the maximum limit. Please ensure the file is 10MB or less';
