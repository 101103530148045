import { emailPattern, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const { CONTACT_REQUIRED, PHONE, EMAIL, EMAIL_REQUIRED, PHONE_REQUIRED, POSITION_REQUIRED, MAX_DIGIT } =
  VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  searchContact: yup.array().min(1, CONTACT_REQUIRED).required(CONTACT_REQUIRED),
  email: yup.string().required(EMAIL_REQUIRED).matches(emailPattern, EMAIL),
  phone: yup.string().required(PHONE_REQUIRED).matches(phoneRegExp, PHONE).max(12, MAX_DIGIT),
  position: yup.string().required(POSITION_REQUIRED),
});
