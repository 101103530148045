export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export enum SUBJECT {
  DROP_TYPE = 'Please Select Drop type',
  DISABLE_TOOLTIP_PREV = 'You Cant Select Less Then Previous PickUp/DropOff Date',
  DISABLE_TOOLTIP_NEXT = 'You Cant Select More Then Next PickUp/DropOff Date',
  DISABLE_INSURANCE = "The Carrier Don't Have Insurance For This Day",
  PLACEHOLDER = 'Appointment Date',
}
