import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { pickupsDropOffsSerializer } from 'pages/loads/components/create-load/components/pickups-dropoffs/helpers';
import { INTERNAL_SECTIONS_VIEW } from 'pages/loads/components/edit-load/constants/constants';
import { transactionsForDataGrind } from 'pages/loads/components/edit-load/constants/helpers';
import {
  LoadCancelStatus,
  TakenType,
  TransportCapabilityTypes,
} from 'pages/loads/components/edit-load/constants/types';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import DataGrid from 'ui/data-grid/DataGrid';
import { MainLoader } from 'ui/main-loader/MainLoader';
import ScrollBar from 'ui/scroll-bar/ScrollBar';
import Typography from 'ui/typography/Typography';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import CustomComment from 'components/custom-comment/CustomComment';
import CopyIcon from 'components/svgs/CopyIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import TransactionsTable from 'components/transactions-table/TransactionsTable';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { ILoadProps } from '../../constants/types';
import InternalBoardBanner from '../loads-comment/components/internal-board-banner/InternalBanner';
import RedConfimrationViewFiles from '../read-confimariton-view-files/RedConfimrationViewFiles';
import { BookedTypes } from '../regular-load-view/constants/types';

import { SUBJECT } from './constants/constants';
import useInternalBoardLoadView from './useInternalBoardLoadView';

import styles from './InternalBoardLoadView.module.scss';

const InternalBoardLoadView = ({
  data,
  files,
  points,
  isFetching,
  refetchLoadById,
  comments,
  getMoreComments,
  isShowMoreVisible,
  loadsPagePermissions,
  hasEditPermission,
  serializedStates,
  transactions,
  isLoadsCommentsLoading,
}: ILoadProps) => {
  const {
    handleChangeMode,
    control,
    watch,
    setValue,
    handleCancelLoad,
    handleViewFile,
    handleDownloadFileClick,
    loadHistory,
    isShowMoreVisibleNotes,
    getMoreNotes,
    copiedText,
    handleCopy,
    isDefaultHistory,
    setIsDefaultHistory,
    handleScrollToHistory,
    drawerRef,
    isFetchingHistory,
    activeSection,
    width,
    userInfo,
  } = useInternalBoardLoadView({
    refetchLoadById,
    data,
  });

  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const loadPagePermissions = usePermittedActions('loads.loads');

  const hasAccessToViewHistory =
    userType === UserTypes.ADMIN || (employeeLevel === EmployeeLevels.CORPORATE && loadPagePermissions.view);

  return (
    <div
      className={clsx({
        ['scrollBarWrapper']: width === 'full',
      })}
    >
      {width === 'full' && <ScrollBar sections={INTERNAL_SECTIONS_VIEW} />}
      <div
        className={clsx('scrollBarContent', {
          ['openedDrawer']: width === 'full',
        })}
      >
        <div className={styles.internalViewWrapper}>
          <MainLoader isFetching={isFetching} />
          <InternalBoardBanner
            activeSection={activeSection}
            data={data!}
            withActions={data?.load?.status !== LoadCancelStatus.Canceled}
            handleCancelLoad={handleCancelLoad}
            hasEditPermission={hasEditPermission}
            hasCancelPermission={loadsPagePermissions.cancel}
            handleChangeMode={handleChangeMode}
            hasAccessToViewHistory={hasAccessToViewHistory}
            handleCommentClick={() => handleScrollToHistory('comment')}
            handleScrollToHistory={() => handleScrollToHistory('history')}
            loadHistory={loadHistory}
          />
          {data?.load?.customer?.id && (
            <ViewItemWrapper
              title={SUBJECT.CUSTOMER}
              id={SUBJECT.VIEW_CUSTOMER_ID}
              className={activeSection === SUBJECT.VIEW_CUSTOMER_ID ? 'active' : ''}
            >
              <Row justify="space-between" align="top" gutter={[10, 30]}>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_NAME} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.load?.branch?.name}
                        subject={`${SUBJECT.CUSTOMER}${SUBJECT.CUSTOMER_NAME}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_ID} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'customer',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.customer?.id}
                          subject={`${SUBJECT.GENERAL_INFO}${SUBJECT.REFERENCE_NUMBER}`}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.customer?.id), 'customer')}
                        >
                          <CopyIcon active={copiedText === 'customer'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.REFERENCE_NUMBER}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'referenceNumber',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.referenceNumber}
                          subject={`${SUBJECT.GENERAL_INFO}${SUBJECT.REFERENCE_NUMBER}`}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.referenceNumber), 'referenceNumber')}
                        >
                          <CopyIcon active={copiedText === 'referenceNumber'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_AMOUNT} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount, true)} `}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.CUSTOMER_CONTACT}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.load?.customerContact}
                        subject={`${SUBJECT.GENERAL_INFO}${SUBJECT.CUSTOMER_CONTACT}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.TAKEN_AS_MC} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={`${data?.load?.takenAsAuthority?.name} / MC ${data?.load?.takenAsAuthority?.mc} / ID ${data?.load?.takenAsAuthority?.id}`}
                        subject={`${SUBJECT.GENERAL_INFO}${SUBJECT.TAKEN_AS_MC}`}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ViewItemWrapper>
          )}
          {!!files?.count && (
            <ViewItemWrapper
              title={SUBJECT.RED_CONFIRMATION}
              id={SUBJECT.RATE_CONFIRMATION_ID}
              className={activeSection === SUBJECT.RATE_CONFIRMATION_ID ? 'active' : ''}
            >
              {files?.result?.RED_CONFIRMATION?.map(fileItem => (
                <RedConfimrationViewFiles
                  fileItem={fileItem}
                  handleViewFile={handleViewFile}
                  handleDownloadFileClick={handleDownloadFileClick}
                  key={fileItem.fileId}
                />
              ))}
            </ViewItemWrapper>
          )}

          <ViewItemWrapper
            title={SUBJECT.LOAD_INFORMATION}
            id={SUBJECT.LOAD_INFORMATION_ID}
            className={activeSection === SUBJECT.LOAD_INFORMATION_ID ? 'active' : ''}
          >
            <Row justify="space-between" align="top" gutter={[10, 30]}>
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.LOAD_TYPE} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      className={styles.infoParagraph}
                      children={TransportCapabilityTypes[data?.information?.transportCapability as number]}
                    />
                  </Col>
                </Row>
              </Col>
              {!!data?.information?.refrigeratorCelsius && (
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.REFRIGERATOR_CELSIUS}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={data?.information?.refrigeratorCelsius}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.TAKEN_AS_TYPE} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={TakenType[data?.information?.takenType as number]}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              {!!data?.information?.givenType && (
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.GIVEN_AS} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={TakenType[data?.information?.givenType as number]}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.COMMODITY} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={data?.information?.commodity}
                      subject={`${SUBJECT.LOAD_INFORMATION}${SUBJECT.COMMODITY}`}
                    />
                  </Col>
                </Row>
              </Col>
              {!!data?.information?.bookedType && (
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.BOOKED_AS} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        children={BookedTypes[String(data?.information?.bookedType)]}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {!!data?.information?.soldType && (
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.SOLD_AS} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={BookedTypes[String(data?.information?.soldType)]}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.WEIGHT} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={`${data?.information?.weight} LBS`}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              {!!data?.information?.feetOfPartial && (
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.FEET_OF_PARTIAL} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={data?.information?.feetOfPartial}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </ViewItemWrapper>
          {points?.count && (
            <ViewItemWrapper
              title={SUBJECT.PICKUPS_AND_DROP_OFFS}
              id={SUBJECT.VIEW_PICKUPS_DROPOFFS_ID}
              className={activeSection === SUBJECT.VIEW_PICKUPS_DROPOFFS_ID ? 'active' : ''}
            >
              <div className={styles.pickupDropOffMainContainer}>
                <Row gutter={[0, 28]}>
                  <Col span={24}>
                    <DataGrid
                      title={SUBJECT.PICKUPS}
                      type="pickup_dropOffs"
                      data={pickupsDropOffsSerializer(
                        points?.result?.pickup,
                        true,
                        serializedStates,
                        userInfo?.username
                      )}
                      isNotEditable={true}
                      isPickupDropoffs
                      withoutActions
                    />
                  </Col>
                  <Col span={24}>
                    <DataGrid
                      title={SUBJECT.DROP_OFFS}
                      type="pickup_dropOffs"
                      data={pickupsDropOffsSerializer(
                        points?.result?.dropoffs,
                        true,
                        serializedStates,
                        userInfo?.username
                      )}
                      isNotEditable={true}
                      isPickupDropoffs
                      withoutActions
                    />
                  </Col>
                </Row>
              </div>
            </ViewItemWrapper>
          )}
          {transactions?.count ? (
            <ViewItemWrapper
              title={SUBJECT.LOAD_TRANSACTION}
              id={SUBJECT.LOAD_TRANSACTION_ID}
              className={activeSection === SUBJECT.LOAD_TRANSACTION_ID ? 'active' : ''}
            >
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <TransactionsTable data={transactionsForDataGrind(transactions)} isCustomer isCarrier />
                </Col>
              </Row>
            </ViewItemWrapper>
          ) : null}
          {!!(comments?.count || (loadHistory?.result?.length && hasAccessToViewHistory)) && (
            <div ref={drawerRef}>
              <CustomComment
                activeSection={activeSection}
                id="comment"
                watch={watch}
                name="comment"
                setValue={setValue}
                control={control}
                inView
                canCreate={false}
                canDelete={false}
                comments={comments}
                getMoreComments={getMoreComments}
                isShowMoreVisible={isShowMoreVisible}
                loadHistory={loadHistory}
                setIsDefaultHistory={setIsDefaultHistory}
                withNotes={loadHistory?.result?.length}
                drawerRef={drawerRef}
                isShowMoreVisibleNotes={isShowMoreVisibleNotes}
                getMoreNotes={getMoreNotes}
                hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
                isDefaultHistory={isDefaultHistory}
                isFetchingHistory={isFetchingHistory}
                isLoadsCommentsLoading={isLoadsCommentsLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InternalBoardLoadView;
