import { handleFloatedBrackeds } from 'utils/helpers';

export const updateBranchDeserializer = (body: any) => {
  return {
    location: Number(body.location),
    phone: body.phoneNumber,
    ...(!!body.zipcode && { zip: body.zipcode }),
    ...(!!body.state && { stateId: body.state }),
    ...(!!body.address && { address: body.address }),
    ...(!!body.state && { stateId: Number(body.state) }),
    ...(body?.secondAddress ? { secondAddress: body.secondAddress } : {}),
    ...(!!body.fax && { fax: body.fax }),
    ...(!!body.city && { city: body.city }),
    name: body.name,
    officeId: Number(body.office.value),
    gross: Number(body.gross),
    branchConsignmentPercent: body.percent,
    branchConsignmentAmount: body.amount,
    brackets: handleFloatedBrackeds(body.brackeds),
  };
};
