import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useResetColumns from 'hooks/useResetColumns';
import { LoadTypes, LoadViewModes } from 'pages/loads/components/create-load/constants/types';
import useLoads from 'pages/loads/useLoads';
import { useGetBillingsQuery } from 'services/factoring/billing/billing';
import { IGetBillingsParams } from 'services/factoring/billing/interface';
import { useGetAgentQuery, useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import { useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal } from 'store/modal-slice/modals';

import { IColumn } from 'components/custom-column/constants/types';
import CustomColumn from 'components/custom-column/CustomColumn';

import { filterDefaultValues, SUBJECT } from './constants/constants';
import { IBillingFilter } from './constants/types';

const useBilling = (setExcelParams: any) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const {
    mode,
    tab,
    open,
    renderMode,
    customerPaymentMethod,
    carrierPaymentMethod,
    officeBilling,
    branchBilling,
    agentBilling,
    regOption,
    regStartDate,
    regEndDate,
    regAllDate,
  } = searchParams;

  const [billingList, setBillingList] = useState<IGetBillingsParams>({
    search: '',
    skip: 0,
    limit: 20,
    order: 1,
    orderBy: 'sentToBillingAt',
    filter: {},
    field: '',
  });

  const { data: columnsData } = useGetColumnsQuery({ type: 'billing' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const {
    reset,
    control,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<IBillingFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const {
    data,
    isFetching: isLoading,
    refetch,
  } = useGetBillingsQuery({
    ...billingList,
    filter: {
      branchIds: branchBilling?.length ? branchBilling?.split(',')?.map(el => Number(el)) : [],
      officeIds: officeBilling?.length ? officeBilling?.split(',')?.map(el => Number(el)) : [],
      agentIds: agentBilling ? [Number(agentBilling)] : [],
      ...(watch('customerPaymentMethod')?.length
        ? { customerPaymentTypes: watch('customerPaymentMethod') === '0' ? [] : [watch('customerPaymentMethod')] }
        : {}),
      ...(watch('carrierPaymentMethod')?.length
        ? { carrierPaymentTypes: watch('carrierPaymentMethod') === '0' ? [] : [watch('carrierPaymentMethod')] }
        : {}),
      ...(regAllDate ? {} : regStartDate ? { createdFrom: Number(regStartDate), createdTo: Number(regEndDate) } : {}),
      isConvertingTimeNeeded: regOption === '5',
    },
  });

  useEffect(() => {
    if (!data?.result.length && billingList?.skip > 0) {
      setBillingList(prev => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [data?.result.length]);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...billingList,
          branchIds: branchBilling?.length ? branchBilling?.split(',')?.map(el => Number(el)) : [],
          officeIds: officeBilling?.length ? officeBilling?.split(',')?.map(el => Number(el)) : [],
          agentIds: agentBilling ? [Number(agentBilling)] : [],
          ...(watch('customerPaymentMethod')?.length
            ? { customerPaymentTypes: watch('customerPaymentMethod') === '0' ? [] : [watch('customerPaymentMethod')] }
            : {}),
          ...(watch('carrierPaymentMethod')?.length
            ? { carrierPaymentTypes: watch('carrierPaymentMethod') === '0' ? [] : [watch('carrierPaymentMethod')] }
            : {}),
          ...(regAllDate
            ? {}
            : regStartDate
            ? { createdFrom: Number(regStartDate), createdTo: Number(regEndDate) }
            : {}),
          isConvertingTimeNeeded: regOption === '5',
        };
      });
    }
  }, [
    billingList,
    branchBilling?.length,
    watch('customerPaymentMethod')?.length,
    regStartDate,
    regAllDate,
    regEndDate,
    officeBilling?.length,
    agentBilling,
  ]);

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    withDefaults: true,
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [selectedOffices, setSelectedOffices] = useState<any>([]);
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [finalBranchesOptions, setFinalBranchesOptions] = useState<any>({ result: [], count: 0 });

  const { data: officesOptions = { result: [], count: 0 } } = useGetOfficesAutocompleteQuery(officesFilter, {
    skip: tab !== 'billing',
  });

  const { data: branchesData = { result: [], count: 0 } } = useGetBranchesAutocompleteQuery(
    { officeId: selectedOffices?.[0]?.id, ...branchFilter },
    {
      skip: !watch('officeBilling')?.length || watch('officeBilling')!.length > 1,
    }
  );
  ///////////////////// AGENT FILTER
  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    excludeOwners: true,
  });

  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    {
      ...agentsFilter,
      branchId: watch('branchBilling')?.[0],
    },
    {
      skip: typeof watch('branchBilling') === 'string' || watch('branchBilling')?.length !== 1,
    }
  );

  const { data: selectedAgent } = useGetAgentQuery(agentBilling, { skip: !agentBilling });

  const [selectedAgentOption, setSelectedAgentOption] = useState<any>({});
  const [finalAgentOptions, setFinalAgentOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedAgent) {
      setSelectedAgentOption([
        {
          firstName: selectedAgent?.result?.firstName,
          lastName: selectedAgent?.result?.lastName,
          username: selectedAgent?.result?.username,
          id: selectedAgent?.result?.id,
          value: String(selectedAgent?.result?.id),
        },
      ]);
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (agentsOptions && !selectedAgentOption.id) {
      setFinalAgentOptions(agentsOptions);
    } else if (agentsOptions) {
      setFinalAgentOptions({
        result: [selectedAgentOption, ...agentsOptions.result.filter((el: any) => el.id !== selectedAgentOption?.id)],
        count: agentsOptions?.count,
      });
    }
  }, [selectedAgentOption, agentsOptions?.result?.length]);
  ////////////

  useEffect(() => {
    if (Array.isArray(watch('branchBilling'))) {
      setSelectedBranches((prev: any) =>
        [
          ...prev.filter((el: any) => !branchesData?.result?.some((item: any) => item.value == el.value)),
          ...branchesData.result,
        ].filter((el: any) => watch('branchBilling')?.some((item: any) => item == el.value))
      );
    }
  }, [watch('branchBilling'), branchesData?.result?.length]);

  useEffect(() => {
    if (Array.isArray(watch('officeBilling'))) {
      setSelectedOffices((prev: any) =>
        [
          ...prev.filter((el: any) => !officesOptions?.result?.some((item: any) => item.value == el.value)),
          ...officesOptions.result,
        ].filter((el: any) => watch('officeBilling')?.some((item: any) => item == el.value))
      );
    }
  }, [watch('officeBilling'), officesOptions.result?.length]);

  useEffect(() => {
    if (officesOptions?.result) {
      setFinalOfficeOptions({
        result: [
          ...selectedOffices,
          ...officesOptions.result.filter((el: any) => !selectedOffices.some((item: any) => item.value == el.value)),
        ],
        count: officesOptions.count,
      });
    } else {
      setFinalOfficeOptions(selectedOffices);
    }
  }, [selectedOffices, officesOptions?.result]);

  useEffect(() => {
    if (branchesData?.result?.length) {
      setFinalBranchesOptions({
        result: [
          ...selectedBranches,
          ...branchesData.result.filter((el: any) => !selectedBranches.some((item: any) => item.value == el.value)),
        ],
        count: branchesData.count,
      });
    } else {
      setFinalBranchesOptions(selectedBranches);
    }
  }, [selectedBranches?.length, branchesData?.result?.length]);

  const customerPaymentMethodFilter = watch('customerPaymentMethod');
  const carrierPaymentMethodFilter = watch('carrierPaymentMethod');
  const officeBillingFilter = watch('officeBilling');
  const branchBillingFilter = watch('branchBilling');
  const agentBillingFilter = watch('agentBilling')?.[0]?.id ? watch('agentBilling')?.[0]?.id : watch('agentBilling');

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(customerPaymentMethodFilter ? { customerPaymentMethod: customerPaymentMethodFilter } : {}),
      ...(carrierPaymentMethodFilter ? { carrierPaymentMethod: carrierPaymentMethodFilter } : {}),
      ...(officeBillingFilter
        ? { officeBilling: Array.isArray(officeBillingFilter) ? officeBillingFilter?.join(',') : '' }
        : { officeBilling: '' }),
      ...(branchBillingFilter
        ? { branchBilling: Array.isArray(branchBillingFilter) ? branchBillingFilter?.join(',') : '' }
        : { branchBilling: '' }),
      ...(agentBillingFilter ? { agentBilling: agentBillingFilter } : { agentBilling: '' }),
    });
  }, [
    branchBillingFilter,
    agentBillingFilter,
    customerPaymentMethodFilter,
    carrierPaymentMethodFilter,
    officeBillingFilter,
  ]);

  useEffect(() => {
    if (watch('officeBilling')!.length !== 1) {
      setSearchParams({
        ...searchParams,
        branchBilling: '',
        agentBilling: '',
      });
      setValue('branchBilling', []);
      setValue('agentBilling', []);
      setSelectedAgentOption({});
    }
  }, [watch('officeBilling')]);

  useEffect(() => {
    if (watch('branchBilling')!.length !== 1) {
      setSearchParams({
        ...searchParams,
        agentBilling: '',
      });
      setValue('agentBilling', []);
      setSelectedAgentOption({});
    }
  }, [watch('branchBilling')]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  useEffect(() => {
    const filterOptions = [
      { key: 'carrierPaymentMethod', value: carrierPaymentMethod },
      { key: 'customerPaymentMethod', value: customerPaymentMethod },
      { key: 'officeBilling', value: officeBilling ? officeBilling.split(',') : officeBilling },
      { key: 'branchBilling', value: branchBilling ? branchBilling.split(',') : branchBilling },
      { key: 'agentBilling', value: agentBilling },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setValue(key as keyof IBillingFilter, value, { shouldDirty: true });
      }
    });
    setBillingList((prev: any) => {
      return {
        ...prev,
        skip: 0,
        limit: prev.limit,
        filter: {
          ...prev.filter,
        },
      };
    });
  }, [customerPaymentMethod, carrierPaymentMethod, officeBilling?.length]);

  const handleResetFilters = () => {
    reset();
    setSearchParams({
      tab,
      open,
      mode,
      regStartDate: '',
      regEndDate: '',
      regAllDate: '',
      regOption: '4',
    });
    setSelectedAgentOption({});
  };

  const handleRowClick = (rowId: string, { loadType }: any) => {
    const type = LoadTypes[loadType];
    setSearchParams({
      ...searchParams,
      mode: LoadViewModes?.[type as keyof typeof LoadViewModes],
      open: 'true',
      id: rowId,
      renderMode: 'loads',
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setBillingList(prev => {
      if (sortOrder === 0)
        return {
          ...prev,
          orderBy: 'sentToBillingAt',
          order: 1,
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setBillingList(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleColumnSearch = (value: string, column: string) => {
    setBillingList((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleStopResize = (columns: IColumn[]): void => {
    editColumns({ type: 'billing', columns });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (columnsData?.columns.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      isEdit: '',
      id: '',
      renderMode: '',
    });
    dispatch(closeModal());
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({ setDragColumns, deleteColumns, editColumns, handleCloseModal, dragColumns, type: 'billing' });

  const {
    currentChildren: {
      component: componentLoads,
      buttonText: buttonTextLoads,
      onCancel: onCancelLoads,
      onSubmit: onSubmitLoads,
      withFooter: withFooterLoads,
    },
  } = useLoads();

  const drawerMode: any = {
    loads: {
      component: componentLoads,
      buttonText: buttonTextLoads,
      onCancel: onCancelLoads,
      onSubmit: onSubmitLoads,
      withFooter: withFooterLoads,
    },
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
      withFooter: true,
    },
  };

  const { component, buttonText, onCancel, onSubmit, withFooter } = drawerMode[`${renderMode || 'customize'}`];

  useEffect(() => {
    if (data?.result) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result]);

  useEffect(() => {
    if (open === 'false') {
      refetch();
    }
  }, [open]);

  return {
    columns: columnsData?.columns,
    data,
    control,
    isDirty,
    onCancel,
    onSubmit,
    component,
    regOption,
    isLoading,
    withFooter,
    buttonText,
    billingList,
    agentBilling,
    agentsOptions,
    selectedOffices,
    selectedBranches,
    finalAgentOptions,
    selectedAgentOption,
    offices: finalOfficeOptions,
    branches: finalBranchesOptions,
    watch,
    setValue,
    handleRowClick,
    setAgentsFilter,
    handleSortClick,
    handleStopResize,
    handleResetFilters,
    setBranchFilter,
    handlePaginationClick,
    handleColumnSearch,
    setOfficesFilter,
  };
};

export default useBilling;
