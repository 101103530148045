import React from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row, Tooltip } from 'antd';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import LocalSearchMultiSelect from 'ui/inputs/local-search-multiselect/LocalSearchMultiSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Typography from 'ui/typography/Typography';
import { floatNumberFormatter, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { DatePicker, Switch, TextArea } from '../../../../hoc-instance/instance';

import { EQUIPMENTS_OPTIONS, SUBJECT, TRAILER_OPTIONS } from './constants/constants';
import { Input, Select } from './hoc-instance/instance';
import useLocation from './useLocation';

import styles from './Location.module.scss';

const Location = ({ truckInfoById }: any) => {
  const {
    statesData,
    control,
    setValue,
    errors,
    trigger,
    watch,
    onSubmit,
    handleCancel,
    locationRef,
    handleSubmit,
    canChangeLocation,
    setIsConvertingNeeded,
  } = useLocation({ truckInfoById });

  return (
    <>
      <div ref={locationRef}>
        <ViewItemWrapper title={SUBJECT.LOCATION}>
          <Row gutter={[24, 34]}>
            <Col span={8}>
              <Input
                type="text"
                placeholder={SUBJECT.CITY}
                control={control}
                name="city"
                getValues={watch}
                rules={{ required: true }}
                required
                errors={errors}
                disabled={!canChangeLocation}
                onBlur={() => trigger('city')}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="from"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <StateSelect
                      options={statesData}
                      placeholder={SUBJECT.STATE}
                      name={field.name}
                      errors={errors}
                      disabled={!canChangeLocation}
                      onBlur={() => trigger(field.name)}
                      onChange={(value: string) =>
                        setValue('from', value, {
                          shouldDirty: true,
                          shouldValidate: true,
                          shouldTouch: true,
                        })
                      }
                      value={field.value}
                      required
                      allowClear
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.from} />
            </Col>
            <Col span={8}>
              <DatePicker
                name="availableDate"
                required
                control={control}
                placeholder={SUBJECT.DATE}
                errors={errors}
                disabled={!canChangeLocation}
                withDisableText={false}
                handleChangeIndicator={() => {
                  setIsConvertingNeeded(true);
                }}
                isAbleToSelectFutureValue={true}
                rules={{ required: true }}
                onClear={() => {
                  setValue('availableDate', '');
                  trigger('availableDate');
                }}
                onBlur={() => trigger('availableDate')}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="to"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <LocalSearchMultiSelect
                      options={statesData}
                      placeholder={SUBJECT.TO}
                      name={field.name}
                      disabled={!canChangeLocation}
                      errors={errors}
                      onBlur={() => trigger(field.name)}
                      onChange={(value: string[]) =>
                        setValue('to', value, {
                          shouldDirty: true,
                          shouldValidate: true,
                          shouldTouch: true,
                        })
                      }
                      value={field.value}
                      required
                      allowClear
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.to} />
            </Col>
            <Col span={8}>
              <Select
                options={EQUIPMENTS_OPTIONS}
                placeholder={SUBJECT.EQUIPMENT_TYPE}
                control={control}
                name="equipmentTypeLocation"
                withAll={false}
                rules={{ required: true }}
                required
                isDisabled={!canChangeLocation}
                errors={errors}
                onBlur={() => trigger('equipmentTypeLocation')}
              />
            </Col>
            <Col span={8}>
              <Select
                options={TRAILER_OPTIONS}
                placeholder={SUBJECT.TRAILER_DETAILS}
                control={control}
                name="trailerDetailsLocation"
                withAll={false}
                errors={errors}
                isDisabled={!canChangeLocation}
                onBlur={() => trigger('trailerDetailsLocation')}
              />
            </Col>
            <Col span={8}>
              <Input
                name="length"
                control={control}
                placeholder={SUBJECT.LENGTH}
                getValues={watch}
                disabled={!canChangeLocation}
                value={formatNumberWithThousandsSeparatorAndDecimal(watch('length'))}
                onChange={(e: any) =>
                  setValue('length', floatNumberFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                errors={errors}
                rules={{ required: true }}
                required
                onBlur={() => trigger('length')}
              />
            </Col>
            <Col span={16}>
              <div className={styles.textarea}>
                <TextArea
                  autoSize
                  label={'Comment'}
                  isFilled={!!watch('locationComment')}
                  name="locationComment"
                  control={control}
                  disabled={!canChangeLocation}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setValue('locationComment', e.target.value, { shouldDirty: true })
                  }
                />
              </div>
            </Col>
            <Col span={24}>
              <Row gutter={[18, 40]}>
                <Col>
                  <Typography variant="paragraph" children={SUBJECT.DESIRED_HOME} className={styles.switchHeading} />
                </Col>
                <Col>
                  <Switch
                    name="desiredHomeSwitch"
                    checked={watch('desiredHomeSwitch')}
                    disabled={!canChangeLocation}
                    control={control}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Button children="Cancel" variant="text" onClick={handleCancel} disabled={!canChangeLocation} />
                </Col>
                <Tooltip placement="top" color="#fff" title={!canChangeLocation ? SUBJECT.NO_PERMISSION : null}>
                  <Col>
                    <Button
                      children={SUBJECT.POST_TRUCK}
                      onClick={handleSubmit(onSubmit)}
                      disabled={!canChangeLocation}
                    />
                  </Col>
                </Tooltip>
              </Row>
            </Col>
          </Row>
        </ViewItemWrapper>
      </div>
    </>
  );
};
export default Location;
