import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useUploadFileNOAMutation } from 'services/profile/authorities/authorities-file';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo } from 'store/user-slice/selector';
import { EMAIL_TYPE_OPTIONS } from 'ui/upload-row/constants/constants';
import { notificationKey } from 'utils/constants';
import { FILE_MAX_SIZE_ERROR, ServerErrorCodes } from 'utils/errors';
import { isFileAllowedToBeUpload } from 'utils/helpers';

import DangerIcon from 'components/svgs/DangerIcon';

import CloseIcon from '../components/svgs/CloseIcon';

import { FILE_UPLOAD_SUBJECT } from './constants';

const useFileUpload = ({ data, setValue, getValues, id, addNewFile, deleteFile, trigger, autoUpdate = false }: any) => {
  const [uploadFileNOA] = useUploadFileNOAMutation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);

  const [allFiles, setAllFiles] = useState<Array<Record<string, any>>>([]);

  const savedFiles = useMemo(() => {
    return (
      data?.result?.files?.map((el: any) => ({
        blurred: !!el?.blurred,
        fullName: `${el?.creator?.username}`,
        fileName: el?.name || el.file?.name,
        createdAt: el?.createdAt,
        id: el?.id,
        preview: el?.preview || el?.file?.preview,
        download: el?.download || el?.file?.download,
        type: isNaN(el?.type) ? el?.type?.charAt(0)?.toUpperCase() + el.type?.slice(1)?.toLowerCase() : el?.type,
        legible: el?.readable,
        mailable: el?.mailable ? EMAIL_TYPE_OPTIONS[1].value : EMAIL_TYPE_OPTIONS[0].value,
      })) || []
    );
  }, [data?.result?.files]);

  const [localFiles, setLocalFiles] = useState<Array<any>>([]);

  const beforeUploadForCreate = (file: any, setLoading: any, uploadType = '1') => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', uploadType);
      uploadFileNOA(formData)
        .then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            setLocalFiles(prev => [file, ...prev]);
            setValue('file', [...getValues('file'), ...data!.data!.result!.ids], { shouldDirty: true });
            trigger('file');
            setAllFiles(prev => [
              {
                fullName: `${userInfo?.username}`,
                fileName: file.name,
                id: data?.data?.result?.ids?.[0],
                createdAt: new Date(),
                uid: file.uid,
              },
              ...prev,
            ]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      return false;
    } else {
      notification.error({
        message: FILE_MAX_SIZE_ERROR,
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  const beforeUploadForEdit = (file: any, setLoading: any, uploadType = '1') => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', uploadType);
      uploadFileNOA(formData)
        .then((data: any) => {
          if (data?.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            addNewFile({ id, fileId: Number(data?.data?.result?.ids?.[0]) }).then(() => {
              if (!autoUpdate) {
                setLocalFiles(prev => [file, ...prev]);
                setAllFiles(prev => [
                  {
                    fullName: `${userInfo?.firstName} ${userInfo?.lastName}`,
                    fileName: file.name,
                    id: data?.data?.result?.ids?.[0],
                    createdAt: new Date(),
                    uid: file?.uid,
                  },
                  ...prev,
                ]);
              }
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
      return false;
    } else {
      notification.error({
        message: FILE_MAX_SIZE_ERROR,
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  const handleDeleteFileEdit = (fileId: number) => {
    dispatch(
      openModal({
        title: FILE_UPLOAD_SUBJECT.ARE_YOU_SURE,
        okText: FILE_UPLOAD_SUBJECT.DELETE,
        cancelText: FILE_UPLOAD_SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteFile({ id, fileId }).then(() => {
            setAllFiles(prev => prev.filter(el => el.id !== fileId));
          });
          trigger('file');
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDeleteFileCreate = (fileId: number) => {
    dispatch(
      openModal({
        title: FILE_UPLOAD_SUBJECT.ARE_YOU_SURE,
        okText: FILE_UPLOAD_SUBJECT.DELETE,
        cancelText: FILE_UPLOAD_SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          setValue(
            'file',
            getValues('file').filter((el: any) => el !== fileId)
          );
          trigger('file');
          setAllFiles(prev => prev.filter(el => el.id !== fileId));
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDownloadFileEdit = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    link.click();
  };
  const handleDownloadFileCreate = (file: any) => {
    const fileToDownload = localFiles.find(el => el.uid === file.uid);
    const blob = new Blob([fileToDownload]);

    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');

    downloadLink!.href = url;
    downloadLink.download = fileToDownload!.name;

    // Programmatically click the link to trigger the download
    downloadLink.click();

    // Clean up the temporary URL
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadMultiplyFileCreate = () => {
    localFiles.forEach((file: any) => {
      const blob = new Blob([file]);
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');

      downloadLink!.href = url;
      downloadLink.download = file!.name;
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleViewFileEdit = (url: string) => {
    window.open(`${url}&response-content-disposition=inline`, '_blank');
  };
  const handleViewFileCreate = (file: any) => {
    const fileToView = localFiles.find(el => el.uid === file.uid);
    if (fileToView) {
      const blob = new Blob([fileToView], { type: fileToView.type });

      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      window.URL.revokeObjectURL(url);
    }
  };

  return {
    handleViewFileCreate,
    handleViewFileEdit,
    handleDeleteFileCreate,
    handleDeleteFileEdit,
    handleDownloadFileCreate,
    handleDownloadFileEdit,
    beforeUploadForCreate,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
    handleDownloadMultiplyFileCreate,
  };
};

export default useFileUpload;
