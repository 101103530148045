export enum SUBJECT {
  POST_TRUCK = 'Post Truck',
  POST_TRUCK_TEXT = 'After Posting Truck It Will Move Into Truck Board',
  CITY = 'City',
  NO_PERMISSION = 'You Have No Permission For Post Truck',
  STATE = 'State',
  TO = 'Desired Destination',
  DATE = 'Available Date',
  EQUIPMENT_TYPE = 'Equipment Type',
  TRAILER_DETAILS = 'Trailer Details',
  LOCATION = 'Location',
  LENGTH = 'Available Length',
  JUST_ARE_YOU_SURE = 'Are You Sure ?',
  YES = 'Yes',
  NO = 'No',
  DESIRED_HOME = 'Desired Home',
}

export const TRUCK_OPTIONS = [
  { title: 'All Truck', value: 'All truck' },
  { title: 'Favorite Truck', value: 'Favorite truck' },
];
export const CAPACITY_OPTIONS = [
  { title: 'Full', value: 'Full' },
  { title: 'Particle', value: 'Particle' },
];
export const STATUS_OPTIONS = [
  { title: 'On Board', value: 'On board' },
  { title: 'Off Board', value: 'Off board' },
  { title: 'In Transit', value: 'In transit' },
];
export const EQUIPMENTS_OPTIONS = [
  { title: 'Van', value: '1' },
  { title: 'Reefer', value: '2' },
  { title: 'Flat Bed', value: '3' },
  { title: 'Power Only', value: '4' },
  { title: 'Box Truck', value: '5' },
];
export const TRAILER_OPTIONS = [
  { title: 'E-Truck', value: '1' },
  { title: 'High Cube', value: '2' },
  { title: 'Plate Trailer', value: '3' },
  { title: 'Hazmat', value: '4' },
];
export const defaultValuesLocation = {
  city: '',
  from: '',
  to: [],
  availableDate: '',
  equipmentTypeLocation: '',
  trailerDetailsLocation: '',
  length: '',
  locationComment: '',
  desiredHomeSwitch: true,
};
