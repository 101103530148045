import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import {
  authoritiesAPI,
  useGetAuthoritiesByOfficeIdTableQuery,
  useGetAuthoritiesShortQuery,
} from 'services/profile/authorities/authorities';
import { IGetOfficesParams } from 'services/profile/offices/interfaces';
import {
  useChangeStatusMutation,
  useCreateOfficeMutation,
  useGetOfficeQuery,
  useGetOfficesQuery,
  useUpdateOfficeMutation,
} from 'services/profile/offices/offices';
import { useGetStatesQuery } from 'services/states/states';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatPhone } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import CreateOfficeForm from './components/create-form/CreateOfficeForm';
import EditOfficeForm from './components/edit-form/EditOfficeForm';
import OfficeView from './components/office-view/OfficeView';
import { defaultValues, filterDefaultValues, SUBJECT } from './constants/constants';
import {
  IOfficeFilter,
  IOfficeFormData,
  IOfficeParams,
  IOfficeTableColumns,
  OfficeStatusTypes,
} from './constants/types';
import { validation } from './constants/validation';

export const useOffice = ({ setExcelParams }: IOfficeParams) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { officeFilter, mode, id, open } = searchParams;

  const [getAuthoritiesFilter, setGetAuthoritiesFilter] = useState<Partial<IGetOfficesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: [],
  });

  const [getAuthoritiesByOfficeFilter, setGetAuthoritiesByOfficeFilter] = useState<Partial<IGetOfficesParams>>({
    skip: 0,
    limit: 10,
  });

  const [getOfficesFilter, setGetOfficesFilter] = useState<Partial<IGetOfficesParams>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const { data: columnsData } = useGetColumnsQuery({ type: 'offices' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [changeStatus] = useChangeStatusMutation();
  const [editOffice, { isLoading: isEditLoading }] = useUpdateOfficeMutation();
  const [createOffice, { isLoading: isCreateLoading }] = useCreateOfficeMutation();

  const { currentData: officeById, isFetching: isFetchingById } = useGetOfficeQuery(id, { skip: !id });
  const { data: statesData } = useGetStatesQuery({}, { skip: mode === 'view' || mode === 'customize' });
  const { data = [], isFetching, refetch: refetchOfficesList } = useGetOfficesQuery(getOfficesFilter);

  const { data: authoritiesData, isFetching: isAutocompleteFetching } = useGetAuthoritiesShortQuery(
    { ...getAuthoritiesFilter, ...(mode === 'edit' && { ignoreOfficeId: id }) },
    {
      skip: mode === 'view' || mode === 'customize' || (mode === 'edit' && open === 'false') || open === 'false',
    }
  );

  const { data: authorityByOfficeId = [], refetch } = useGetAuthoritiesByOfficeIdTableQuery(
    { id, ...getAuthoritiesByOfficeFilter },
    {
      skip: mode === 'add' || mode === 'customize' || !id || (mode === 'edit' && open === 'false') || open === 'false',
    }
  );

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    getValues,
    reset,
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm<IOfficeFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const {
    control: filterControl,
    reset: filterReset,
    watch: filterWatch,
    setValue: setFilterValue,
    formState: { isDirty: isFilterDirty },
  } = useForm<IOfficeFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const filterValue = filterWatch('officeFilter');
  const filterQueryCheck = typeof filterValue === 'string';

  useEffect(() => {
    if (officeFilter) {
      setFilterValue('officeFilter', officeFilter, { shouldDirty: true });
      setGetOfficesFilter(prev => ({ ...prev, skip: 0, limit: prev.limit, filter: { location: officeFilter } }));
    }
  }, [officeFilter]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { officeFilter: filterValue } : {}),
    });
  }, [filterValue]);
  const agencyPagePermissions = usePermittedActions('profiles.agencies');

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        name: officeById?.result?.name,
        officeLocation: officeById?.result?.location,
        phoneNumber: formatPhone(officeById?.result?.phone),
        fax: officeById?.result?.fax,
        address: officeById?.result?.address,
        secondAddress: officeById?.result?.secondAddress || '',
        city: officeById?.result?.city,
        stateId: officeById?.result?.state?.id,
        zipcode: officeById?.result?.zip,
        payout: officeById?.result?.payoutPercent,
        bankName: officeById?.result?.bankName,
        accounting_number: officeById?.result?.accountingNumber,
        ach_routing_number: officeById?.result?.achRoutingNumber,
      });
    } else {
      reset(defaultValues);
    }
  }, [mode, officeById?.result, id]);

  const resetFilter = () => {
    filterReset();
    setSearchParams({
      ...searchParams,
      officeFilter: '',
    });
    setGetOfficesFilter((prev: any) => ({
      ...prev,
      filter: {},
    }));
  };

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    reset(defaultValues, { keepDirty: false });
    setGetOfficesFilter(prev => ({
      search: prev.search,
      skip: prev.skip,
      limit: prev.limit,
      order: prev.order,
      orderBy: prev.orderBy,
      field: prev.field,
      filter: {},
    }));
    setGetAuthoritiesFilter({
      skip: 0,
      limit: 10,
      search: '',
    });
    setGetAuthoritiesByOfficeFilter({
      skip: 0,
      limit: 10,
    });
    clearErrors();
  };

  const onAuthoritiesBlur = () => {
    setGetAuthoritiesFilter((prev: any) => ({ ...prev, search: '', skip: 0, limit: 10 }));
    setTimeout(() => {
      trigger('authorities');
    }, 700);
  };

  const onSubmit: SubmitHandler<IOfficeFormData> = data => {
    if (mode === 'edit' && !isEditLoading) {
      editOffice({ id, ...data, location: 1 }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully edited',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
          refetchOfficesList();
        }
      });
    } else if (mode === 'add' && !isCreateLoading) {
      createOffice(data).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    }
  };

  const handleStopResize = (columns: IOfficeTableColumns[]): void => {
    editColumns({ type: 'offices', columns });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (columnsData?.columns.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { officeFilter: filterValue } : {}),
      open: 'false',
      id: '',
    });
    dispatch(closeModal());
    resetForm();
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({ setDragColumns, deleteColumns, editColumns, handleCloseModal, dragColumns, type: 'offices' });

  const handleCancel = () => {
    if (isDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: isValid
            ? () => {
                onSubmit(watch());
                dispatch(closeModal());
                clearErrors();
              }
            : () => {
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(filterValue && filterQueryCheck ? { officeFilter: filterValue } : {}),
        open: 'false',
      });
      if (mode === 'edit') {
        resetForm();
      }
      setGetOfficesFilter(prev => ({
        search: prev.search,
        skip: prev.skip,
        limit: prev.limit,
        order: prev.order,
        orderBy: prev.orderBy,
        field: prev.field,
        filter: {},
      }));
      setGetAuthoritiesFilter({
        skip: 0,
        limit: 10,
        search: '',
      });
      setGetAuthoritiesByOfficeFilter({
        skip: 0,
        limit: 10,
      });
    }
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { officeFilter: filterValue } : {}),
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleRowEdit = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { officeFilter: filterValue } : {}),
      mode: 'edit',
      open: 'true',
      id: rowId,
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setGetOfficesFilter(prev => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setGetOfficesFilter(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setGetOfficesFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleSwitchClick = (checked: boolean, rowId: number | string) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({
            id: rowId,
            status: checked
              ? OfficeStatusTypes.OFFICE_STATUS_TYPES_ACTIVE
              : OfficeStatusTypes.OFFICE_STATUS_TYPES_INACTIVE,
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...getOfficesFilter,
        };
      });
    }
  }, [getOfficesFilter]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: agencyPagePermissions.create
      ? {
          component: (
            <CreateOfficeForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              getValues={getValues}
              control={control}
              errors={errors}
              setValue={setValue}
              statesData={statesData}
              authoritiesData={authoritiesData}
              authorityByOfficeId={authorityByOfficeId}
              refetch={refetch}
              watch={watch}
              getAuthoritiesFilter={getAuthoritiesFilter}
              setGetAuthoritiesFilter={setGetAuthoritiesFilter}
              getAuthoritiesByOfficeFilter={getAuthoritiesByOfficeFilter}
              setGetAuthoritiesByOfficeFilter={setGetAuthoritiesByOfficeFilter}
              onAuthoritiesBlur={onAuthoritiesBlur}
              trigger={trigger}
              isCreateLoading={isCreateLoading}
              isEditLoading={isEditLoading}
              isAutocompleteFetching={isAutocompleteFetching}
              clearErrors={clearErrors}
            />
          ),
          buttonText: SUBJECT.ADD_OFFICE,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit: agencyPagePermissions.edit
      ? {
          component: (
            <EditOfficeForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              getValues={getValues}
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              data={officeById}
              statesData={statesData}
              authoritiesData={authoritiesData}
              authorityByOfficeId={authorityByOfficeId}
              refetch={refetch}
              getAuthoritiesFilter={getAuthoritiesFilter}
              setGetAuthoritiesFilter={setGetAuthoritiesFilter}
              getAuthoritiesByOfficeFilter={getAuthoritiesByOfficeFilter}
              setGetAuthoritiesByOfficeFilter={setGetAuthoritiesByOfficeFilter}
              isFetchingById={isFetchingById}
              onAuthoritiesBlur={onAuthoritiesBlur}
              trigger={trigger}
              isEditLoading={isEditLoading}
              isCreateLoading={isCreateLoading}
              isAutocompleteFetching={isAutocompleteFetching}
            />
          ),
          buttonText: SUBJECT.EDIT_BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    view: {
      component: (
        <OfficeView
          data={officeById}
          authorityByOfficeId={authorityByOfficeId}
          setGetAuthoritiesByOfficeFilter={setGetAuthoritiesByOfficeFilter}
          isFetchingById={isFetchingById}
          pagePermissions={agencyPagePermissions}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    isDirty,
    watch(),
    watch('authorities')?.length,
    officeById,
    statesData,
    columnsData?.isDefault,
    isSuccess,
    isEditLoading,
    isCreateLoading,
    authoritiesData?.result?.length,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  useEffect(() => {
    if (data?.result?.length) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result?.length]);

  useEffect(() => {
    if (open === 'false') {
      dispatch(authoritiesAPI.util.resetApiState());
    }
  }, [mode, open, id]);

  return {
    currentChildren,
    isFetching,
    columns: columnsData?.columns,
    data,
    filterControl,
    handleRowClick,
    resetFilter,
    handleRowEdit,
    handleSortClick,
    handleColumnSearch,
    handleSwitchClick,
    handleStopResize,
    handlePaginationClick,
    skip: getOfficesFilter.skip,
    limit: getOfficesFilter.limit,
    isFilterDirty,
    agencyPagePermissions,
  };
};
