import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { FactoringTabs, IComponent } from 'pages/profile/constants/constants';

import CloseIcon from '../../components/svgs/CloseIcon';
import DangerIcon from '../../components/svgs/DangerIcon';
import SuccessIcon from '../../components/svgs/SuccessIcon';
import {
  useExportBillingMutation,
  useExportPayablesMutation,
  useExportReceivablesMutation,
} from '../../services/exports/exports';
import { selectUserPermissionsInfo } from '../../store/user-slice/selector';
import { notificationKey } from '../../utils/constants';
import { ServerErrorCodes } from '../../utils/errors';
import { SUBJECT } from '../loads/constants/constants';

import Billing from './tabs/billing/Billing';
import Payables from './tabs/payables/Payables';
import Receivables from './tabs/receivables/Receivables';

const useFactoring = () => {
  const [exportBilling] = useExportBillingMutation();
  const [exportPayables] = useExportPayablesMutation();
  const [exportReceivables] = useExportReceivablesMutation();

  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab } = searchParams;

  const isButtonVisible = true;

  const openMainDrawer = (mode: string) => {
    setSearchParams({
      ...searchParams,
      mode,
      open: 'true',
      renderMode: 'customize',
    });
  };

  const [excelParams, setExcelParams] = useState<any>();
  const downloadExcel = () => {
    const exportFunctionMap: Record<string, any> = {
      [FactoringTabs.billing]: exportBilling,
      [FactoringTabs.payables]: exportPayables,
      [FactoringTabs.receivables]: exportReceivables,
    };

    const exportFunction = exportFunctionMap[tab];

    exportFunction(excelParams).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.CHECK_EMAIL,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  const Component: IComponent = {
    billing: <Billing setExcelParams={setExcelParams} />,
    receivables: <Receivables setExcelParams={setExcelParams} />,
    payables: <Payables setExcelParams={setExcelParams} />,
  };

  return {
    tab,
    openMainDrawer,
    downloadExcel,
    Component,
    isButtonVisible,
    permissionsInfo,
  };
};
export default useFactoring;
