import React from 'react';

const ContactIcon = () => {
  return (
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.2725 21.9545V18.9559H24.4071V2.63017C24.4071 2.07488 24.2128 1.60287 23.8241 1.21417C23.4354 0.825458 22.9634 0.631104 22.4081 0.631104H1.751V2.63017H22.4081V18.9559H14.6117V21.9545H27.2725ZM11.3132 21.9545C11.6908 21.9545 12.0018 21.8046 12.2461 21.5047C12.4904 21.2049 12.6126 20.8661 12.6126 20.4885V5.92864C12.6126 5.55104 12.4904 5.24007 12.2461 4.99574C12.0018 4.75141 11.6908 4.62924 11.3132 4.62924H2.25076C1.82874 4.62924 1.45114 4.74586 1.11796 4.97908C0.784779 5.21231 0.618189 5.52883 0.618189 5.92864V20.4885C0.618189 20.9106 0.779224 21.2604 1.1013 21.538C1.42337 21.8157 1.80653 21.9545 2.25076 21.9545H11.3132ZM10.6135 18.9559H2.61726V6.62831H10.6135V18.9559Z"
        fill="white"
      />
    </svg>
  );
};

export default ContactIcon;
