import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const { PERMISSION_GROUP_REQUIRED } = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  permissionGroupName: yup
    .string()
    .required(PERMISSION_GROUP_REQUIRED)
    .test('isRequired', PERMISSION_GROUP_REQUIRED, function checkIsRequired(value) {
      return !!value?.length;
    }),
});
