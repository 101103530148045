import { ForwardedRef, forwardRef } from 'react';
import { Slider } from 'antd';

import { MARKS } from './constants/constants';
import { IProgressBarProps } from './constants/types';

import styles from './ProgressBar.module.scss';

const ProgressBar = forwardRef(({ value = 0, onChange }: IProgressBarProps, ref: ForwardedRef<HTMLDivElement>) => {
  const handleChange = (value: number) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={styles.progressBarWrapper} id="progress_bar">
      <Slider
        ref={ref}
        marks={MARKS}
        onChange={handleChange}
        value={value ? Number(value) : 0}
        tooltip={{
          open: Number(value) > 0,
          formatter: value => `${value}%`,
          getPopupContainer: () => document.getElementById('progress_bar')!,
        }}
      />
    </div>
  );
});

export default ProgressBar;
