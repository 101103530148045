import { useState } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { IComponent, ProfileActivePages, ProfileTabs, SUBJECT } from 'pages/profile/constants/constants';
import {
  useExportAgentsMutation,
  useExportAuthoritiesMutation,
  useExportBranchesMutation,
  useExportCarrierOnBoardMutation,
  useExportCarriersBlacklistMutation,
  useExportCarriersMutation,
  useExportCarriersRequestMutation,
  useExportCustomersBlacklistMutation,
  useExportCustomersMutation,
  useExportEmployeesMutation,
  useExportFactoringCompaniesMutation,
  useExportOfficesMutation,
} from 'services/exports/exports';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { usePermittedActions } from '../../hooks/usePermittedActions';

import Agent from './tabs/agent/Agent';
import Authorities from './tabs/authorities/Authorities';
import Branch from './tabs/branch/Branch';
import Carriers from './tabs/carriers/Carriers';
import Customers from './tabs/customers/Customers';
import Employee from './tabs/employee/Employee';
import FactoringCompanies from './tabs/factoringCompanies/FactoringCompanies';
import Office from './tabs/office/Office';

const useProfile = ({ sendAgentsActivityWatchMessage }: any) => {
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab, activePage } = searchParams;
  const [excelParams, setExcelParams] = useState<any>();
  const [exportAgentsTable] = useExportAgentsMutation();
  const [exportOfficesTable] = useExportOfficesMutation();
  const [exportBranchesTable] = useExportBranchesMutation();
  const [exportAuthoritiesTable] = useExportAuthoritiesMutation();
  const [exportCarriersTable] = useExportCarriersMutation();
  const [exportCarrierOnBoard] = useExportCarrierOnBoardMutation();
  const [exportCarrierRequestTable] = useExportCarriersRequestMutation();
  const [exportCarrierBlacklistTable] = useExportCarriersBlacklistMutation();
  const [exporCustomersTable] = useExportCustomersMutation();
  const [exporCustomersBlacklistTable] = useExportCustomersBlacklistMutation();
  const [exportEmployeesTable] = useExportEmployeesMutation();
  const [exportFactoringCompaniesTable] = useExportFactoringCompaniesMutation();

  const isButtonVisible = true;

  const openMainDrawer = (mode: string) => {
    setSearchParams({
      ...searchParams,
      mode,
      open: 'true',
    });
  };

  const downloadExcel = () => {
    const exportFunctionMap: Record<string, any> = {
      [ProfileTabs.agents]: exportAgentsTable,
      [ProfileTabs.offices]: exportOfficesTable,
      [ProfileTabs.branches]: exportBranchesTable,
      [ProfileTabs.authorities]: exportAuthoritiesTable,
      [ProfileTabs.carriers]: {
        [ProfileActivePages.carriers]: exportCarriersTable,
        [ProfileActivePages.carrierOnBoard]: exportCarrierOnBoard,
        [ProfileActivePages.carriersRequest]: exportCarrierRequestTable,
        [ProfileActivePages.carriersBlackList]: exportCarrierBlacklistTable,
      },
      [ProfileTabs.customers]: {
        [ProfileActivePages.customers]: exporCustomersTable,
        [ProfileActivePages.customersBlackList]: exporCustomersBlacklistTable,
      },
      [ProfileTabs.employees]: exportEmployeesTable,
      [ProfileTabs.factoringCompanies]: exportFactoringCompaniesTable,
    };

    let exportFunction = exportFunctionMap[tab];

    if (exportFunction && typeof exportFunction === 'object') {
      exportFunction = exportFunction[activePage];
    }

    exportFunction(excelParams).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.CHECK_EMAIL,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  const Component: IComponent = {
    offices: <Office setExcelParams={setExcelParams} />,
    agents: <Agent setExcelParams={setExcelParams} sendAgentsActivityWatchMessage={sendAgentsActivityWatchMessage} />,
    branches: <Branch setExcelParams={setExcelParams} />,
    authorities: <Authorities setExcelParams={setExcelParams} />,
    carriers: <Carriers setExcelParams={setExcelParams} />,
    customers: <Customers setExcelParams={setExcelParams} />,
    employees: <Employee setExcelParams={setExcelParams} />,
    factoringCompanies: <FactoringCompanies setExcelParams={setExcelParams} />,
  };
  const agentPagePermissions = usePermittedActions('profiles.agents');
  const agencyPagePermissions = usePermittedActions('profiles.agencies');
  const branchPagePermission = usePermittedActions('profiles.branches');
  const authoritiesPagePermissions = usePermittedActions('profiles.authorities');
  const carrierTablePagePermissions = usePermittedActions('profiles.carriers.carrierTable');
  const carrierRequestPagePermissions = usePermittedActions('profiles.carriers.carrierRequest');
  const carrierOnBoardPagePermissions = usePermittedActions('profiles.carriers.carrierOnBoard');
  const carrierBlackListPagePermissions = usePermittedActions('profiles.carriers.blacklist');
  const customerTablePagePermissions = usePermittedActions('profiles.customers.customerTable');
  const customerBlackListPagePermissions = usePermittedActions('profiles.customers.blackList');
  const employeesPagePermissions = usePermittedActions('profiles.employees');
  const factoringCompaniesPagePermissions = usePermittedActions('profiles.factoringCompanies');

  const tabsWithoutNesting: any = {
    agents: agentPagePermissions,
    offices: agencyPagePermissions,
    branches: branchPagePermission,
    authorities: authoritiesPagePermissions,
    employees: employeesPagePermissions,
    carriers: carrierTablePagePermissions,
    customers: customerTablePagePermissions,
    factoringCompanies: factoringCompaniesPagePermissions,
  };

  const nestedTabs: any = {
    table: carrierTablePagePermissions,
    request: carrierRequestPagePermissions,
    carrierOnBoard: carrierOnBoardPagePermissions,
    blacklist: carrierBlackListPagePermissions,
    'customer-table': customerTablePagePermissions,
    'customer-blacklist': customerBlackListPagePermissions,
  };

  const hasAddPermission = tabsWithoutNesting?.[tab]?.create;
  const hasDownloadPermission =
    tab !== 'customers' && tab !== 'carriers'
      ? tabsWithoutNesting?.[tab]?.download
      : nestedTabs?.[activePage]?.download;

  return {
    tab,
    openMainDrawer,
    downloadExcel,
    Component,
    isButtonVisible,
    activePage,
    permissionsInfo,
    hasAddPermission,
    hasDownloadPermission,
  };
};
export default useProfile;
