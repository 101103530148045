import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { ICarriersFormData } from '../../../constants/types';

export interface IEditCarrierProps {
  isSubmitted: boolean;
  setPaymentTermDirty: any;
  handleSubmit: UseFormHandleSubmit<ICarriersFormData>;
  onSubmit: SubmitHandler<ICarriersFormData>;
  dirtyFields: any;
  control: Control<ICarriersFormData>;
  getValues: UseFormGetValues<ICarriersFormData>;
  errors: FieldErrors;
  setValue: UseFormSetValue<ICarriersFormData>;
  watch: UseFormWatch<ICarriersFormData>;
  mode?: string;
  activeSection?: string;
  statesData: any;
  beforeUpload: any;
  allFiles: any;
  handleDeleteCarrierFile: any;
  handleDownloadCarrieryFile: any;
  handleViewCarrieryFileEdit: any;
  carrierCommentsInfo?: any;
  carrierInfo: any;
  getFilesByType: any;
  carrierRequestInfo: any;
  createComment: any;
  trigger: any;
  handleDeleteCommentModal: any;
  isLoading: any;
  trucksByCarrierId: any;
  getMoreComments?: () => void;
  isShowMoreVisible?: boolean;
  truckSectionRef: any;
  handleTruckPaginationClick: any;
  handleNewFileDownload?: (file: File) => void;
  handleNewFileView?: (file: File) => void;
  userInfo: any;
  carrierLastLoads: any;
  handleCarrierLasLoadsPaginationClick: any;
  isCarrierLastLoadsLoading: boolean;
  resetField: any;
  isEditLoading: boolean;
  isCreateLoading: boolean;
  breakdowns: any;
  breakdownParams: any;
  handleCarrierBreakdownPaginationClick: any;
  handleBreakdownSortClick: any;
  isCommentFetching?: any;
  isBreakdownLoading: boolean;
  hasDeleteCommentPermission?: boolean;
  truckState?: any;
  setTruckState: any;
  refetchTrucksById: any;
  clearErrors: any;
  carrierRequestCarrier?: any;
}

export enum FileTypes {
  FILE_TYPES_CARRIER_PACKET = 1,
  FILE_TYPES_SIGN_AGREEMENT = 2,
  FILE_TYPES_PAID = 3,
  FILE_TYPES_INSURANCE = 4,
  FILE_TYPES_VOIDED_CHECK_BANK_LATTER = 5,
}

export enum PaymentTermFileType {
  UNSPECIFIED = 0,
  CARRIER_PACKAGE = 1,
  SIGN_AGREEMENT = 2,
  VOIDED_CHECK = 3,
  NOA = 4,
  LETTER_OF_RELEASE = 5,
}
