import { LoadTypes } from 'pages/loads/components/create-load/constants/types';
import { TakenType } from 'pages/loads/components/edit-load/constants/types';
import { BookedTypes } from 'pages/loads/tabs/loads-tab/components/regular-load-view/constants/types';
import { formatDate, formatPickUpDropOff } from 'utils/dates';

import { changeTimeZone } from '../../../utils/helpers';

import { EquipmentTypes, TrailerDetailsTypes } from './interfaces';

export const getTruckInfoByIdSerializer = (body: any) => {
  return {
    ...body.result,
    toStateIds: body?.result?.toStateIds.map((el: any) => String(el)),
    availableAt: body?.result?.availableAt
      ? changeTimeZone(new Date(body?.result?.availableAt), 'America/Los_Angeles', true)
      : '',
    availableAtForView: formatPickUpDropOff(body?.result?.availableAt),
    firstPickUpDate: !!body?.result?.firstPickUpDate && formatPickUpDropOff(body?.result?.firstPickUpDate),
    lastDropOffDate: !!body?.result?.lastDropOffDate && formatPickUpDropOff(body?.result?.lastDropOffDate),
    fakeCheckIsOnlyTruckNumber: !!body?.result?.city,
  };
};

export const getCoordiniatorsListSerializer = (data: any) => {
  const result = data?.result?.map((employee: any) => {
    return {
      id: employee?.id,
      username: employee?.username,
    };
  });
  return {
    result,
    count: data.count,
  };
};

export const getTruckBoardResultListSerializer = (data: any) => {
  const result = data?.result?.map((search: any) => {
    return {
      id: search?.id,
      new: search?.new,
      availableAtFrom:
        !!(search?.availableAtFrom && search?.availableAtTo) &&
        `${formatPickUpDropOff(search?.availableAtFrom)} - ${formatPickUpDropOff(search?.availableAtTo)}`,
      from: search?.fromStates,
      to: search?.toStates,
      isFromToLarge: true,
      truckCount: search?.truckCount,
      equipmentType: search?.equipmentTypes
        .filter((el: number) => !!el)
        .map((el: number) => {
          if (el) {
            return {
              name: EquipmentTypes[el],
            };
          }
        }),
      trailerDetails: search?.trailerDetails
        .filter((el: number) => !!el)
        .map((el: number) => {
          if (el) {
            return {
              name: TrailerDetailsTypes[el],
            };
          }
        }),
    };
  });
  return {
    result,
    count: data.count,
  };
};

export const getTruckBoardResultListByIdSerializer = (data: any) => {
  return {
    ...data?.result,
    isFromToLarge: true,
    createdAt:
      !!(data?.result?.availableAtFrom && data?.result?.availableAtTo) &&
      `${formatPickUpDropOff(data?.result?.availableAtFrom)} - ${formatPickUpDropOff(data?.result?.availableAtTo)}`,
    equipmentType: data?.result?.equipmentTypes
      .filter((el: number) => !!el)
      .map((el: number) => {
        if (el) {
          return {
            name: EquipmentTypes[el],
          };
        }
      }),
    trailerDetails: data?.result?.trailerDetails
      .filter((el: number) => !!el)
      .map((el: number) => {
        if (el) {
          return {
            name: TrailerDetailsTypes[el],
          };
        }
      }),
  };
};

export const getTruckBoardResultListByIdTrucksSerializer = (data: any) => {
  const result = data?.result?.map((body: any) => {
    return {
      id: `${body?.id}-${body?.loadId}`,
      isFromToLarge: true,
      availableAt: formatPickUpDropOff(body?.availableAt),
      new: body?.new,
      coordinator: body?.coordinator ? `${body?.coordinator?.username}` : '',
      companyName: body?.companyName,
      truckNumber: body?.truckNumber,
      contactName: body?.contactName,
      carrierId: body?.carrierId,
      rate: {
        rating: body?.rating,
        ratingCount: body?.ratingCount,
      },
      equipmentType: !!body?.equipmentType && [{ name: EquipmentTypes[Number(body?.equipmentType)] }],
      trailerDetails: !!body?.trailerDetails && [{ name: TrailerDetailsTypes[body?.trailerDetails] }],
      trailerLength: body?.availableLength,
      locatedCity: body?.city,
      locatedState: body?.fromState?.name,
      desireDestination: body?.toStates,
      lastComment: body?.comment,
      pickupDateAndTime: formatDate(body?.load?.first?.appointmentDate),
      dropOffDateAndTime: formatDate(body?.load?.last?.appointmentDate),
      loadType: LoadTypes[body?.load?.loadType],
      temperature: body?.load?.refrigeratorCelsius,
      partialOrFull: BookedTypes[body?.load?.soldType],
      team: TakenType[body?.load?.givenType],
      agent: `${body?.load?.agent?.firstName} ${body?.load?.agent?.lastName}`,
      agency: body?.load?.office?.name,
      agentPhoneNumber: body?.load?.agent?.phone,
      pickupState: body?.load?.first?.state?.name,
      dropOffState: body?.load?.last?.state?.name,
      rateSum: body?.load?.carrierFinalAmount,
    };
  });
  return {
    result,
    count: data?.count,
  };
};

export const getTruckBoardResultParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  queryParams.append('truckBoardStatus', String(params?.truckBoardStatus || params?.filter?.truckBoardStatus || '1'));
  if (params.filter?.equipmentTypes?.length) {
    if (!params.filter?.equipmentTypes?.includes('0')) {
      params.filter?.equipmentTypes?.forEach((el: string | number) =>
        queryParams.append('equipmentTypes[]', String(el))
      );
    }
  }
  if (params.filter?.trailerDetails?.length) {
    if (!params.filter?.trailerDetails?.includes('0')) {
      params.filter?.trailerDetails?.forEach((el: string | number) =>
        queryParams.append('trailerDetails[]', String(el))
      );
    }
  }
  return queryParams;
};
