import React, { useDeferredValue } from 'react';

import Table from 'components/table/Table';

import useCarrierRequest from './useCarrierRequest';

const CarrierRequest = ({ data, isLoading, skip, limit, setCarrierRequestSeldfFilter }: any) => {
  const { columns, handleRowClick, handleSortClick, handleColumnSearch, handlePaginationClick } = useCarrierRequest({
    setCarrierRequestSeldfFilter,
  });

  const tableProps = useDeferredValue({
    data,
    columns,
    isLoading,
    withMenu: false,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onColumnSearch: handleColumnSearch,
    onPaginationClick: handlePaginationClick,
    skip,
    limit,
  });

  return (
    <>
      <Table {...tableProps} />
    </>
  );
};

export default CarrierRequest;
