import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useCreateEditCarrierRatingMutation,
  useDeleteCarrierRatingMutation,
  useLazyGetCarrierRatingQuery,
} from 'services/profile/carriers/carriers';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { multiplyDownloadWithDelay } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from '../../constants/constants';

export const useEditCarrier = ({ carrierInfo }: any) => {
  const dispatch = useDispatch();

  const { searchParams } = useDetectedParams();

  const { id, isAproveMode } = searchParams;

  const [ratesParams] = useState({
    skip: 0,
    limit: 50,
  });

  const [createEditCarrierRating] = useCreateEditCarrierRatingMutation();
  const [getCarrierRating, { data: rateList }] = useLazyGetCarrierRatingQuery();
  const [deleteCarrierRating] = useDeleteCarrierRatingMutation();

  const handleCarrierRate = (rating: number | string) => {
    if (carrierInfo?.accessRating) {
      createEditCarrierRating({ carrierId: Number(id), rating }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully rated',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        }
      });
    }
  };

  const getCarrierRatings = () => {
    getCarrierRating({ skip: ratesParams.skip, limit: ratesParams.limit, carrierId: Number(id) });
  };

  const handleDeleteRate = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_DELETE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        onOk: () => {
          deleteCarrierRating({ ratingId: Number(id) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDownloadAll = (ids: number[]) => {
    multiplyDownloadWithDelay(ids);
  };

  return {
    isAproveMode,
    rateList,
    dispatch,
    handleDeleteRate,
    getCarrierRatings,
    handleCarrierRate,
    handleDownloadAll,
  };
};
