import { IFormData } from './types';

export const defaultValues: IFormData = {
  email: '',
  password: '',
  rememberMe: false,
};

export enum SUBJECT {
  EMAIL = 'Email',
  PASSWORD = 'Password',
  LOGIN = 'Login',
  FORGOT_PASSWORD = 'Forgot Password?',
  REMEMBER_ME = 'Remember Me',
  WELCOME = 'Welcome to FreightMax',
  NEW_PASSWORD = 'New Password',
  CREATE_PASSWORD = 'Create Password',
  CREATE_PASS_SUCCESS = 'Password Set Successfully, You Will Be Sent To The Login Page',
  CREATE_UPDATED_SUCCESS = 'Password Was Successfully Updated, You Will Be Sent To The Login Page',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
}

export enum VALIDATION_MESSAGES {
  EMAIL_IS_REQUIRED = 'Email is required',
  PASS_IS_REQUIRED = 'Password is required',
  FORMAT = 'Sorry, we don`t  recognise this email address.',
  PASSWORD = 'Must contain at least 6 symbols',
  SSN_NUMBER = 'SSN Number must match this pattern ###-##-####',
  EIN_NUMBER = 'EIN Number must match this pattern ##-#######',
  PHONE = 'Incorrect phone format',
  MAX_LENGTH = 'Maximum of 10 digits allowed',
  INVALID_EMAIL = 'Invalid email format',
  FIRST_NAME_REQUIRED = 'First name is a required field',
  PAY_TYPE_REQUIRED = 'Pay type is a required field',
  PAID_AS_REQUIRED = 'Paid as is a required field',
  AGENT_PAY_TYPE_REQUIRED = 'Agent type is a required field',
  LAST_NAME_REQUIRED = 'Last name is a required field',
  PHONE_NUMBER_REQUIRED = 'Phone number is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  CITY_REQUIRED = 'City is a required field',
  STATE_REQUIRED = 'State is a required field',
  ZIP_REQUIRED = 'Zip Code is a required field',
  AGENT_TYPE_REQUIRED = 'Agent type is a required field',
  USERNAME_REQUIRED = 'Username is a required field',
  EMAIL_REQUIRED = 'Email is a required field',
  OFFICE_REQUIRED = 'Agency is a required field',
  SSN_OR_EIN_NUMBER_IS_REQUIRED = 'SSN or EIN number is a required field',
  LOCATION_REQUIRED = 'Location is a required field',
  FILE_REQUIRED = 'File is a required field',
  BRACKET_REQUIRED = 'You have to add at least one bracket',
  BRANCH_REQUIRED = 'Branch is a required field',
  AUTHORITIES_REQUIRED = 'Authorities is a required field',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
  NAME_REQUIRED = 'Emergency name is a required field',
  RELATION_REQUIRED = 'Relation is a required field',
  PHONE_NUMBER_EMERGENCY_REQUIRED = 'Phone number emergency is a required field',
  NOT_END_WITH_DOT = "The last character can't be dot",
}

export enum ERRORS {
  EMAIL = 'Wrong email or password. Try again.',
  PASSWORD = 'Wrong email or password. Try again.',
}
