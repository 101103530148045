import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { setActiveSection } from '../../store/active-section-slice/activeSection';

import styles from './ScrollBar.module.scss';

const ScrollBar = ({ sections: defaultSections }: any) => {
  const sections = defaultSections?.filter((section: any) => {
    return !!document.getElementById(section?.id);
  });
  const dispatch = useDispatch();

  const [localActiveSection, setLocalActiveSection] = useState('');

  const handleScroll = useMemo(() => {
    return () => {
      const drawer: any = document.querySelector('.ant-drawer-body');
      const isScrollAtBottom: any = Math.ceil(drawer.scrollTop) + drawer.clientHeight >= drawer.scrollHeight;
      const isScrollAtTop: any = drawer.scrollTop <= 0;
      sections.forEach((section: any) => {
        const element = document.getElementById(section?.id);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (isScrollAtTop) {
            setLocalActiveSection(sections[0]?.id);
          } else if (isScrollAtBottom) {
            setLocalActiveSection(sections[sections?.length - 1]?.id);
          } else if (rect.top <= 380 && rect.bottom >= 380) {
            setLocalActiveSection(section?.id);
          }
        }
      });
    };
  }, [sections]);

  useEffect(() => {
    const drawer: any = document.querySelector('.ant-drawer-body');
    drawer.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  const handleScrollToView = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      dispatch(setActiveSection({ activeSection: id }));

      setTimeout(() => {
        dispatch(setActiveSection({ activeSection: '' }));
      }, 1000);
    }
  };

  return (
    <div className={styles.mainPanel}>
      <ul className={styles.scrollbarLinkWrapper}>
        {sections.map((section: any) => (
          <li key={section?.id}>
            <div
              onClick={() => handleScrollToView(section.id)}
              className={clsx(styles.scrollBarLink, {
                [styles.active]: section?.id === localActiveSection,
              })}
            >
              {section?.title}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScrollBar;
