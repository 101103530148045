import React from 'react';

const OtherServiceFeeIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.574 20.1995L21.6448 20.2702L21.7155 20.1995L28.504 13.411L28.5747 13.3403L28.504 13.2696L28.0272 12.7928L27.9565 12.7221L27.8858 12.7928L21.0973 19.5813L21.0265 19.652L21.0973 19.7228L21.574 20.1995ZM21.5285 15.4531C21.8349 15.7603 22.2082 15.9151 22.6426 15.9151C23.0769 15.9151 23.4505 15.7608 23.7576 15.4545C24.0648 15.1481 24.2195 14.7749 24.2195 14.3404C24.2195 13.9061 24.0652 13.5326 23.7589 13.2254C23.4525 12.9182 23.0793 12.7635 22.6449 12.7635C22.2105 12.7635 21.837 12.9178 21.5299 13.2241C21.2227 13.5305 21.068 13.9037 21.068 14.3381C21.068 14.7725 21.2222 15.146 21.5285 15.4531ZM23.1352 14.8316C22.9989 14.9671 22.8366 15.0339 22.6424 15.0339C22.4484 15.0339 22.2867 14.9668 22.1514 14.8307C22.0159 14.6945 21.9491 14.5321 21.9491 14.338C21.9491 14.144 22.0162 13.9823 22.1523 13.8469C22.2886 13.7115 22.4509 13.6446 22.6451 13.6446C22.839 13.6446 23.0007 13.7118 23.1361 13.8479C23.2716 13.9841 23.3384 14.1465 23.3384 14.3406C23.3384 14.5346 23.2713 14.6963 23.1352 14.8316ZM25.8423 19.7669C26.1487 20.0741 26.5219 20.2288 26.9564 20.2288C27.3907 20.2288 27.7642 20.0746 28.0714 19.7683C28.3786 19.4619 28.5333 19.0886 28.5333 18.6542C28.5333 18.2199 28.379 17.8463 28.0727 17.5392C27.7663 17.232 27.3931 17.0773 26.9587 17.0773C26.5243 17.0773 26.1508 17.2316 25.8436 17.5379C25.5365 17.8443 25.3817 18.2175 25.3817 18.6519C25.3817 19.0863 25.536 19.4598 25.8423 19.7669ZM27.4489 19.1454C27.3127 19.2809 27.1503 19.3477 26.9562 19.3477C26.7622 19.3477 26.6005 19.2806 26.4652 19.1445C26.3297 19.0082 26.2629 18.8459 26.2629 18.6517C26.2629 18.4578 26.33 18.296 26.4661 18.1607C26.6023 18.0252 26.7647 17.9584 26.9588 17.9584C27.1528 17.9584 27.3145 18.0255 27.4499 18.1616C27.5853 18.2979 27.6521 18.4602 27.6521 18.6544C27.6521 18.8484 27.585 19.0101 27.4489 19.1454Z"
        fill="var(--black)"
        stroke="#var(--black)"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7891 9.17676H34.7745C35.6029 9.17676 36.2745 9.84833 36.2745 10.6768V38.8148H37.7745V10.6768C37.7745 9.0199 36.4313 7.67676 34.7745 7.67676H13.7891C12.1322 7.67676 10.7891 9.01991 10.7891 10.6768V38.8148H12.2891V10.6768C12.2891 9.84833 12.9606 9.17676 13.7891 9.17676Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9035 41.9201L10.7898 38.8064L11.8906 37.7056L15.0043 40.8193L13.9035 41.9201Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0153 38.8072L13.9016 41.9209L12.8008 40.8201L15.9145 37.7064L17.0153 38.8072Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0558 41.9201L14.9421 38.8064L16.043 37.7056L19.1567 40.8193L18.0558 41.9201Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1677 38.8072L18.054 41.9209L16.9531 40.8201L20.0668 37.7064L21.1677 38.8072Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2082 41.9201L19.0945 38.8064L20.1953 37.7056L23.309 40.8193L22.2082 41.9201Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.32 38.8072L22.2063 41.9209L21.1055 40.8201L24.2192 37.7064L25.32 38.8072Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3566 41.9201L23.2429 38.8064L24.3438 37.7056L27.4575 40.8193L26.3566 41.9201Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4685 38.8072L26.3548 41.9209L25.2539 40.8201L28.3676 37.7064L29.4685 38.8072Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5089 41.9201L27.3952 38.8064L28.4961 37.7056L31.6098 40.8193L30.5089 41.9201Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6208 38.8072L30.5071 41.9209L29.4062 40.8201L32.52 37.7064L33.6208 38.8072Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6613 41.9201L31.5476 38.8064L32.6484 37.7056L35.7621 40.8193L34.6613 41.9201Z"
        fill="var(--black)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7732 38.8072L34.6595 41.9209L33.5586 40.8201L36.6723 37.7064L37.7732 38.8072Z"
        fill="var(--black)"
      />
      <circle cx="36.738" cy="36.7348" r="7.0153" fill="#252733" stroke="white" strokeWidth="0.5" />
      <path
        d="M35.5707 39.8499L32.5859 36.6655L33.6248 35.5807L35.5707 37.6453L39.38 33.6211L40.4189 34.7059L35.5707 39.8499Z"
        fill="white"
      />
      <path
        d="M35.5707 39.8499L32.5859 36.6655L33.6248 35.5807L35.5707 37.6453L39.38 33.6211L40.4189 34.7059L35.5707 39.8499Z"
        fill="white"
      />
      <path
        d="M19.7076 25.8583C19.7076 26.4133 19.6024 26.8855 19.392 27.2748C19.1833 27.6641 18.8983 27.9615 18.5372 28.1669C18.1777 28.3706 17.7735 28.4725 17.3245 28.4725C16.8722 28.4725 16.4664 28.3698 16.1069 28.1644C15.7474 27.959 15.4633 27.6616 15.2545 27.2723C15.0458 26.883 14.9414 26.4117 14.9414 25.8583C14.9414 25.3034 15.0458 24.8312 15.2545 24.4419C15.4633 24.0526 15.7474 23.756 16.1069 23.5523C16.4664 23.3469 16.8722 23.2441 17.3245 23.2441C17.7735 23.2441 18.1777 23.3469 18.5372 23.5523C18.8983 23.756 19.1833 24.0526 19.392 24.4419C19.6024 24.8312 19.7076 25.3034 19.7076 25.8583ZM18.6167 25.8583C18.6167 25.4988 18.5628 25.1957 18.4552 24.9488C18.3491 24.702 18.1992 24.5148 18.0054 24.3872C17.8116 24.2597 17.5846 24.1959 17.3245 24.1959C17.0644 24.1959 16.8374 24.2597 16.6436 24.3872C16.4498 24.5148 16.299 24.702 16.1913 24.9488C16.0853 25.1957 16.0323 25.4988 16.0323 25.8583C16.0323 26.2178 16.0853 26.521 16.1913 26.7678C16.299 27.0147 16.4498 27.2019 16.6436 27.3294C16.8374 27.457 17.0644 27.5208 17.3245 27.5208C17.5846 27.5208 17.8116 27.457 18.0054 27.3294C18.1992 27.2019 18.3491 27.0147 18.4552 26.7678C18.5628 26.521 18.6167 26.2178 18.6167 25.8583Z"
        fill="var(--black)"
      />
      <path
        d="M22.515 24.586V25.3812H20.2164V24.586H22.515ZM20.7382 23.6716H21.7968V27.23C21.7968 27.3278 21.8117 27.404 21.8416 27.4587C21.8714 27.5117 21.9128 27.5489 21.9658 27.5705C22.0205 27.592 22.0834 27.6028 22.1547 27.6028C22.2044 27.6028 22.2541 27.5986 22.3038 27.5904C22.3535 27.5804 22.3916 27.573 22.4181 27.568L22.5846 28.3557C22.5316 28.3723 22.457 28.3914 22.3609 28.4129C22.2648 28.4361 22.148 28.4502 22.0105 28.4551C21.7554 28.4651 21.5318 28.4311 21.3396 28.3533C21.1491 28.2754 21.0008 28.1545 20.8948 27.9904C20.7888 27.8264 20.7366 27.6194 20.7382 27.3692V23.6716Z"
        fill="var(--black)"
      />
      <path
        d="M24.3452 26.1963V28.403H23.2866V23.3137H24.3154V25.2595H24.3601C24.4462 25.0342 24.5854 24.8577 24.7776 24.7302C24.9697 24.6009 25.2108 24.5363 25.5007 24.5363C25.7658 24.5363 25.9969 24.5943 26.194 24.7103C26.3928 24.8246 26.5469 24.9894 26.6562 25.2048C26.7672 25.4185 26.8219 25.6744 26.8202 25.9726V28.403H25.7616V26.1615C25.7633 25.9263 25.7036 25.7432 25.5827 25.6123C25.4634 25.4814 25.2961 25.416 25.0807 25.416C24.9366 25.416 24.809 25.4467 24.698 25.508C24.5887 25.5693 24.5026 25.6587 24.4396 25.7763C24.3783 25.8923 24.3468 26.0323 24.3452 26.1963Z"
        fill="var(--black)"
      />
      <path
        d="M29.3928 28.4775C29.0002 28.4775 28.6622 28.398 28.3789 28.2389C28.0973 28.0782 27.8803 27.8513 27.7279 27.5581C27.5754 27.2632 27.4992 26.9145 27.4992 26.5119C27.4992 26.1193 27.5754 25.7747 27.7279 25.4781C27.8803 25.1816 28.0948 24.9505 28.3715 24.7848C28.6498 24.6192 28.9761 24.5363 29.3505 24.5363C29.6024 24.5363 29.8368 24.5769 30.0538 24.6581C30.2725 24.7376 30.463 24.8577 30.6253 25.0184C30.7893 25.1791 30.9169 25.3812 31.008 25.6247C31.0991 25.8666 31.1447 26.1499 31.1447 26.4746V26.7654H27.9217V26.1093H30.1482C30.1482 25.9569 30.1151 25.8219 30.0488 25.7043C29.9826 25.5866 29.8906 25.4947 29.773 25.4284C29.657 25.3605 29.522 25.3266 29.3679 25.3266C29.2072 25.3266 29.0648 25.3638 28.9405 25.4384C28.8179 25.5113 28.7218 25.6098 28.6523 25.7341C28.5827 25.8567 28.5471 25.9934 28.5454 26.1441V26.7678C28.5454 26.9567 28.5802 27.1199 28.6498 27.2574C28.721 27.3949 28.8212 27.5009 28.9505 27.5755C29.0797 27.65 29.2329 27.6873 29.4102 27.6873C29.5278 27.6873 29.6355 27.6707 29.7332 27.6376C29.831 27.6044 29.9146 27.5547 29.9842 27.4885C30.0538 27.4222 30.1068 27.341 30.1433 27.245L31.1223 27.3096C31.0726 27.5448 30.9707 27.7502 30.8167 27.9258C30.6643 28.0998 30.4671 28.2356 30.2253 28.3334C29.985 28.4295 29.7076 28.4775 29.3928 28.4775Z"
        fill="var(--black)"
      />
      <path
        d="M31.8355 28.403V24.586H32.8618V25.252H32.9016C32.9712 25.0151 33.0879 24.8362 33.252 24.7152C33.416 24.5927 33.6048 24.5314 33.8185 24.5314C33.8715 24.5314 33.9287 24.5347 33.99 24.5413C34.0513 24.5479 34.1051 24.557 34.1515 24.5686V25.508C34.1018 25.493 34.0331 25.4798 33.9453 25.4682C33.8575 25.4566 33.7771 25.4508 33.7042 25.4508C33.5485 25.4508 33.4093 25.4848 33.2867 25.5527C33.1658 25.619 33.0697 25.7117 32.9985 25.831C32.9289 25.9503 32.8941 26.0878 32.8941 26.2435V28.403H31.8355Z"
        fill="var(--black)"
      />
    </svg>
  );
};

export default OtherServiceFeeIcon;
