export interface IUploadRowProps {
  files: any[];
  onWatchClick: (e: any) => void;
  onDownloadClick: (e: any) => void;
  onDeleteClick: (e: any) => void;
  onEditFile: (e: any) => void;
  isDisabled?: boolean;
}

export enum ReadableTypes {
  'Legible' = 1,
  'Not Legible' = 2,
}

export enum EmailStatus {
  'Send E-Mail' = 1,
  'Don’t E-Mail' = 2,
}

export const FileTypes = {
  POD: 'POD',
  Lumper_receipt: 'LUMPER_RECEIPT',
  Late_fee_receipt: 'LATE_FEE_RECEIPT',
  Breakdown_receipt: 'BREAKDOWN_RECEIPT',
  Carrier_invoice: 'CARRIER_INVOICE',
};
export enum FileTypesText {
  POD = 'POD',
  LUMPER_RECEIPT = 'Lumper receipt',
  LATE_FEE_RECEIPT = 'Late fee receipt',
  BREAKDOWN_RECEIPT = 'Breakdown receipt',
  CARRIER_INVOICE = 'Carrier invoice',
  INVOICE = 'Invoice',
  UNKNOWN = 'Unknown',
}
