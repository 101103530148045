import React, { useDeferredValue } from 'react';
import { Col, Divider, Row } from 'antd';
import Button from 'ui/button/Button';
import { DateOptions } from 'ui/inputs/select-date-picker/constants/types';
import SelectDatePicker from 'ui/inputs/select-date-picker/SelectDatePicker';
import Typography from 'ui/typography/Typography';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import MainDrawer from 'components/main-drawer/MainDrawer';
import ResetIcon from 'components/svgs/ResetIcon';
import Table from 'components/table/Table';

import {
  AGENT_COLUMNS,
  AGENT_KEYS,
  CARRIER_PAYMENT_METHOD,
  CUSTOMER_PAYMENT_METHOD,
  SUBJECT,
} from './constants/constants';
import { FilterSelect, SearchableDropdown } from './hoc-instance/instance';
import useBilling from './useBilling';

import styles from './Billing.module.scss';

const Billing = ({ setExcelParams }: any) => {
  const {
    columns,
    data,
    control,
    isDirty,
    offices,
    onCancel,
    branches,
    onSubmit,
    component,
    regOption,
    isLoading,
    withFooter,
    buttonText,
    billingList,
    agentBilling,
    selectedOffices,
    selectedBranches,
    finalAgentOptions,
    selectedAgentOption,
    watch,
    setValue,
    setBranchFilter,
    handleRowClick,
    setAgentsFilter,
    handleSortClick,
    handleStopResize,
    setOfficesFilter,
    handleResetFilters,
    handlePaginationClick,
    handleColumnSearch,
  } = useBilling(setExcelParams);
  const tableProps = useDeferredValue({
    data,
    columns,
    withMenu: false,
    isLoading: isLoading,
    skip: billingList?.skip,
    limit: billingList?.limit,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onPaginationClick: handlePaginationClick,
    onColumnSearch: handleColumnSearch,
  });

  return (
    <>
      <div className="page-content">
        <div className="filtersWrapperBig">
          <div className={styles.filtersWrapper}>
            <Row gutter={[18, 18]} align="middle">
              <Col className={styles.filterBlock}>
                <SelectDatePicker fieldName="reg" label={SUBJECT.LOAD_REG_DATE} />
              </Col>
              <Col className={styles.filterBlock}>
                <FilterSelect
                  name="customerPaymentMethod"
                  options={CUSTOMER_PAYMENT_METHOD}
                  control={control}
                  label={SUBJECT.CUSTOMER_PAYMENT_METHOD}
                  placeholder={SUBJECT.NOT_SELECTED}
                />
              </Col>
              <Col className={styles.filterBlock}>
                <FilterSelect
                  name="carrierPaymentMethod"
                  options={CARRIER_PAYMENT_METHOD}
                  control={control}
                  label={SUBJECT.CARRIER_PAYMENT_METHOD}
                  placeholder={SUBJECT.NOT_SELECTED}
                />
              </Col>
              <Col className={styles.filterBlock}>
                <FilterSelect
                  isSearchable
                  isMultiSelect
                  withCheckbox
                  options={offices?.result}
                  label={SUBJECT.OFFICE}
                  placeholder={SUBJECT.NOT_SELECTED}
                  name="officeBilling"
                  control={control}
                  offset={offices?.count}
                  setOffset={setOfficesFilter}
                  setValue={setValue}
                  withMarking
                  isAutocomplete
                />
              </Col>
              <Col className={styles.filterBlock}>
                <FilterSelect
                  isSearchable
                  isMultiSelect
                  isAutocomplete
                  withCheckbox
                  options={branches?.result}
                  label={SUBJECT.BRANCH}
                  placeholder={SUBJECT.NOT_SELECTED}
                  name="branchBilling"
                  control={control}
                  offset={branches.count}
                  setOffset={setBranchFilter}
                  setValue={setValue}
                  withMarking
                  isDisabled={watch('officeBilling')!.length !== 1}
                />
              </Col>
              <Col className={styles.filterBlock}>
                <SearchableDropdown
                  options={finalAgentOptions?.result}
                  columns={AGENT_COLUMNS}
                  keys={AGENT_KEYS}
                  label={SUBJECT.AGENT}
                  placeholder={SUBJECT.NOT_SELECTED}
                  name="agentBilling"
                  control={control}
                  disabled={
                    (selectedOffices.length !== 1 || selectedBranches.length !== 1 || selectedBranches?.[0]?.default) &&
                    !agentBilling
                  }
                  setValue={setValue}
                  values={selectedAgentOption}
                  offset={finalAgentOptions?.count}
                  setOffset={setAgentsFilter}
                  showKey="username"
                  width={500}
                />
              </Col>
              {isDirty || (regOption && +regOption !== DateOptions.All) ? (
                <Col span={6}>
                  <div className={styles.reset}>
                    <Button
                      icon={<ResetIcon />}
                      variant="ghost"
                      onClick={handleResetFilters}
                      children={SUBJECT.RESET_ALL}
                    />
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
        </div>
        <Divider />
        <div className={styles.tableHeaderTopInfo}>
          {!isLoading && (
            <Row align="middle" justify="space-between">
              <Col span={14}>
                <div className={styles.billingMoneyBlock}>
                  <Col>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.EXPECTED} />
                  </Col>
                  <Col>
                    <Divider type="vertical" />
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.LOAD} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={data?.expectedTotalCount}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.TOTAL} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.expectedTotalAmount, true)}`}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.QUICK_PAY} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.expectedQuickAmount, true)}`}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.CUSTOM} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.expectedCustomAmount, true)}`}
                      />
                    </Row>
                  </Col>
                </div>
              </Col>
              <Col span={10}>
                <div className={styles.billingMoneyRightBlock}>
                  <Col>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.MUST_PAY} />
                  </Col>
                  <Col>
                    <Divider type="vertical" />
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.LOAD} />
                      <Typography className={styles.totalCount} variant="paragraph" children={data?.payTotalCount} />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.TOTAL} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.payTotalAmount, true)}`}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.QUICK_PAY} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.payQuickAmount, true)}`}
                      />
                    </Row>
                  </Col>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <Table {...tableProps} />
        <MainDrawer
          Children={component}
          buttonText={buttonText}
          onCancel={onCancel}
          onSubmit={onSubmit}
          withFooter={withFooter}
        />
      </div>
    </>
  );
};
export default Billing;
