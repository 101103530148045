export enum SUBJECT {
  PLEASE_ENTER_EMAIL = 'Please enter your email.',
  YOU_WILL_RECEIVE_A_LINK = 'You will receive a link to complete the application.',
  MAKE_SURE = 'The link will expire within 2 hours.',
  PLEASE_CHECK_EMAIL = 'Please check your email',
  PROBLEMS_AND_QUESTIONS = 'Questions or Concerns? Call us at',
  OR_MAIL = 'or email',
  SUCCESS = 'Success! Thank you for your submission.',
  THE_FREIGHTMAX_APP = '',
}

export enum VALIDATION_MESSAGES {
  INVALID_EMAIL = 'Invalid email format',
  EMAIL_IS_REQUIRED = 'Email is required field',
}
