import { createSlice } from '@reduxjs/toolkit';

import { IButtonVisible } from './interfaces';

export const initialState: IButtonVisible = {
  isButtonVisible: true,
};

export const addButtonVisibleSlice = createSlice({
  name: 'addButtonVisible',
  initialState,
  reducers: {
    setButtonVisibility: (state, { payload }) => {
      state.isButtonVisible = payload.visible;
    },
  },
});

export const { setButtonVisibility } = addButtonVisibleSlice.actions;

export default addButtonVisibleSlice.reducer;
