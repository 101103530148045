import { CarrierPaymentTerms } from 'services/profile/carriers/interfaces';
import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { IInputTagsProps } from 'ui/inputs/input-tags/constants/types';
import CustomInputTags from 'ui/inputs/input-tags/InputTags';
import { IRadioProps } from 'ui/inputs/radio/constants/types';
import CustomRadio from 'ui/inputs/radio/Radio';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import CustomCheckbox from '../../../../../ui/inputs/checkbox/Checkbox';

export const Input = withController<IInputProps, CarrierPaymentTerms>(CustomInput);

export const Radio = withController<IRadioProps, CarrierPaymentTerms>(CustomRadio);
export const InputTags = withController<IInputTagsProps, CarrierPaymentTerms>(CustomInputTags);
export const SearchableDropdown = withController<ISearchableDropdownProps, any>(CustomSearchableDropdown);
export const Checkbox = withController<any, any>(CustomCheckbox);

// export const FiltersSelect = withController<ISelectProps, ICarriersFilter>(CustomSelect);

// export const BlacklistSelect = withController<ISelectProps, IBlacklistFilter>(CustomSelect);

// export const SearchableDropdown = withController<ISearchableDropdownProps, IBlacklistFilter>(CustomSearchableDropdown);
// export const DatePicker = withController<any, any>(CustomDatePicker);

// export const SearchableSelect = withController<ISearchableSelectProps, IBlacklistFilter>(CustomSearchableSelect);
// export const Switch = withController<ISwitchProps, any>(CustomSwitch);
