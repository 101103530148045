import { useEffect, useMemo } from 'react';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useLoads from 'pages/loads/useLoads';
import useCarriers from 'pages/profile/tabs/carriers/useCarriers';
import useCustomers from 'pages/profile/tabs/customers/useCustomers';

import { loadTypesView } from '../loads/components/create-load/constants/types';

import { IDrawerMode } from './constants/interfaces';

const useSearchResult = () => {
  const { searchParams, setSearchParams } = useDetectedParams();

  const {
    searchParams: { headerFilter },
  } = useDetectedParams();

  const {
    currentChildren: {
      component: componentCustomers,
      buttonText: buttonTextCustomers,
      onCancel: onCancelCustomers,
      onSubmit: onSubmitCustomers,
      withFooter: withFooterCustomers,
    },
  } = useCustomers({});

  const {
    currentChildren: {
      component: componentCarriers,
      buttonText: buttonTextCarriers,
      onCancel: onCancelCarriers,
      onSubmit: onSubmitCarriers,
      withFooter: withFooterCarriers,
    },
  } = useCarriers({});

  const {
    currentChildren: {
      component: componentLoads,
      buttonText: buttonTextLoads,
      onCancel: onCancelLoads,
      onSubmit: onSubmitLoads,
      withFooter: withFooterLoads,
    },
  } = useLoads();

  const drawerMode: IDrawerMode = {
    carriers: {
      component: componentCarriers,
      buttonText: buttonTextCarriers,
      onCancel: onCancelCarriers,
      onSubmit: onSubmitCarriers,
      withFooter: withFooterCarriers,
    },
    customers: {
      component: componentCustomers,
      buttonText: buttonTextCustomers,
      onCancel: onCancelCustomers,
      onSubmit: onSubmitCustomers,
      withFooter: withFooterCustomers,
    },
    loads: {
      component: componentLoads,
      buttonText: buttonTextLoads,
      onCancel: onCancelLoads,
      onSubmit: onSubmitLoads,
      withFooter: withFooterLoads,
    },
  };

  const { component, buttonText, onCancel, onSubmit, withFooter } = drawerMode[headerFilter];

  useEffect(() => {
    if (headerFilter === 'carriers' && component?.props?.carrierInfo) {
      localStorage.setItem(
        'carrierInfo',
        JSON.stringify({
          contactName: component.props.carrierInfo.contactName,
          companyName: component.props.carrierInfo.companyName,
          id: component.props.carrierInfo.id,
          phone: component.props.carrierInfo.phone,
        })
      );
    }
    if (headerFilter === 'customers' && component?.props?.customerById) {
      localStorage.setItem(
        'customerInfo',
        JSON.stringify({
          customerName: component.props.customerById.name,
          id: component.props.customerById.id,
          phone: component.props.customerById.phone,
          mc: component.props.customerById.mc,
        })
      );
    }
    if (headerFilter === 'loads' && component?.props?.data) {
      localStorage.setItem(
        'loadsInfo',
        JSON.stringify({
          code: component.props.data.load.code,
          id: component.props.data.load.id,
          referenceNumber: component.props.data.load.referenceNumber,
          type: component.props.data.load.loadType,
        })
      );
    }
  }, [headerFilter, component]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('carrierInfo');
      localStorage.removeItem('customerInfo');
      localStorage.removeItem('loadsInfo');
    };
  }, []);

  const carrierInfo = useMemo(() => {
    return !!localStorage.getItem('carrierInfo') && JSON.parse(localStorage.getItem('carrierInfo') as string);
  }, [localStorage.getItem('carrierInfo')]);

  const customerInfo = useMemo(() => {
    return !!localStorage.getItem('customerInfo') && JSON.parse(localStorage.getItem('customerInfo') as string);
  }, [localStorage.getItem('customerInfo')]);

  const loadsInfo = useMemo(() => {
    return !!localStorage.getItem('loadsInfo') && JSON.parse(localStorage.getItem('loadsInfo') as string);
  }, [localStorage.getItem('loadsInfo')]);

  const handleOpen = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      id: carrierInfo?.id || customerInfo?.id || loadsInfo?.id,
      mode: headerFilter === 'loads' ? loadTypesView[loadsInfo?.type] : 'view',
    });
  };

  return {
    handleOpen,
    component,
    buttonText,
    onCancel,
    onSubmit,
    withFooter,
    carrierInfo,
    customerInfo,
    loadsInfo,
  };
};

export default useSearchResult;
