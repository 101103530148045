import React from 'react';
import { Col, Row } from 'antd';
import { CustomerType } from 'services/profile/customers/interfaces';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatPhone } from 'utils/helpers';

import CustomComment from 'components/custom-comment/CustomComment';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import { ICommentsView } from './constants/types';
import { useCommentsView } from './useCommentsVIew';

import styles from './CommentsView.module.scss';

const CommentsView = ({
  customerById,
  customerComments,
  createComment,
  deleteComment,
  getMoreComments,
  isShowMoreVisible,
  isFetchingById,
  userInfo,
  isCommentsLoading,
}: ICommentsView) => {
  const { watch, setValue, control } = useCommentsView();

  return (
    <>
      <MainLoader isFetching={isFetchingById} />
      <ViewItemWrapper title={SUBJECT.CUSTOMER_INFO_HEADING}>
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.NAME}:{' '}
              </Typography>
              <TooltipText
                prefixClass="top-banner-value"
                data={customerById?.name}
                subject={`${SUBJECT.CUSTOMER_INFO_HEADING}${SUBJECT.NAME}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.PHONE_NUMBER}:{' '}
              </Typography>
              <div className={styles.textWithLinks}>
                <a className="top-banner-value" href={`tel:${customerById?.phone}`}>
                  {formatPhone(customerById?.phone)}
                </a>
              </div>
            </div>
          </Col>
          {customerById?.fax && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.FAX}:{' '}
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.fax}
                  subject={`${SUBJECT.CUSTOMER_INFO_HEADING}${SUBJECT.FAX}`}
                />
              </div>
            </Col>
          )}
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.TYPE}:{' '}
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {CustomerType[customerById?.type]}
              </Typography>
            </div>
          </Col>
          {customerById?.ff && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.FF_NUMBER}:{' '}
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.ff}
                  subject={`${SUBJECT.CUSTOMER_INFO_HEADING}${SUBJECT.FF_NUMBER}`}
                />
              </div>
            </Col>
          )}
          {customerById?.id && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.CUSTOMER_ID}:{' '}
                </Typography>
                <Typography variant="paragraph" className="top-banner-value">
                  {customerById?.id}
                </Typography>
              </div>
            </Col>
          )}
          {customerById?.dot && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.DOT}:{' '}
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.dot}
                  subject={`${SUBJECT.CUSTOMER_INFO_HEADING}${SUBJECT.DOT}`}
                />
              </div>
            </Col>
          )}
          {customerById?.mc && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.MC_NUMBER}:{' '}
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.mc}
                  subject={`${SUBJECT.CUSTOMER_INFO_HEADING}${SUBJECT.MC_NUMBER}`}
                />
              </div>
            </Col>
          )}

          {customerById?.stateNumber && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.STATE_NUMBER}:{' '}
                </Typography>

                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.stateNumber}
                  subject={`${SUBJECT.CUSTOMER_INFO_HEADING}${SUBJECT.STATE_NUMBER}`}
                />
              </div>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <CustomComment
        id="comment"
        watch={watch}
        name="comment"
        setValue={setValue}
        control={control}
        comments={customerComments}
        createComment={createComment}
        deleteComment={deleteComment}
        getMoreComments={getMoreComments}
        isShowMoreVisible={isShowMoreVisible}
        canCreate={!userInfo?.agentType}
        canDelete={!userInfo?.agentType}
        isLoadsCommentsLoading={isCommentsLoading}
      />
    </>
  );
};

export default CommentsView;
