import { emailPattern } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const { EMAIL_IS_REQUIRED, PASS_IS_REQUIRED, INVALID_EMAIL } = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  email: yup.string().email(INVALID_EMAIL).required(EMAIL_IS_REQUIRED).matches(emailPattern, INVALID_EMAIL),
  password: yup.string().required(PASS_IS_REQUIRED),
});
