export enum SUBJECT {
  BLACK_LIST_INFO = 'Black List Info',
  DATE = 'Date',
  ADDED_BY = 'Added By',
  CARRIER_NAME = 'Carrier Name',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  AGENT = 'Agent',
  EDIT = 'Edit',
  UNLOCK = 'Unlock',
}
