export interface ITruckBoardResultTableColumns {
  title: string;
  id: string;
  dataIndex: string;
  displayType: string;
  isVisible: boolean;
  sortable: boolean;
  width: number;
  isSearchable?: boolean;
}

export interface ITruckBoardResultTableData {
  id: number;
  from: string;
  carrier_prefer: string;
  to: string;
  status: string;
  date: string;
  trucks: number;
  equip_type: string;
  trailer_details: string;
}

export interface IOptions {
  title: string;
  value: string;
  key?: string;
}

export interface ISearchResultFilterState {
  search: string;
  field: string;
  skip: number;
  limit: number;
  order: number;
  orderBy: string;
  filter: any;
}

type setStateFunction = (prev: ISearchResultFilterState) => void;

export interface ITruckBoardResultProps {
  searchResultFilter: ISearchResultFilterState;
  setSearchResultFilter: (args: ISearchResultFilterState | setStateFunction) => void;
  editTruckBoardResultColumns: (args: any) => void;
}

export enum ITruckBoardStatus {
  'onBoard' = '1',
  'ofBoard' = '2',
  'transit' = '3',
}
