import { IContactListTableColumns, IOptions } from './types';

export enum SUBJECT {
  CONTACT_LIST_IS_EMPTY = 'Contact List Is Empty',
  NOT_SELECTED = 'Not Selected',
  TYPE = 'Type',
  RESET_ALL = 'Reset All',
  STAY_HERE = 'Stay Here',
  SAVE = 'Save',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  ARE_YOU_SURE = 'Are You Sure Want To Delete?',
  CANCEL = 'Close',
  EDIT_BTN_TEXT = 'Save Changes',
  DELETE = 'Delete',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  SUCCESSFULLY_UPDATED = 'Successfully Updated',
  SUCCESSFULLY_DELETED = 'Successfully Deleted',
  SUCCESSFULLY_CREATED = 'Successfully Created',
}

export enum VALIDATION_MESSAGES {
  CONTACT_REQUIRED = 'Contact Is a Required Field',
  EMAIL_REQUIRED = 'Email Is a Required Field',
  PHONE_REQUIRED = 'Phone Is Required Field',
  POSITION_REQUIRED = 'Position Is a Required Field',
  OFFICE_REQUIRED = 'Agency Is a Required Field',
  BRANCH_REQUIRED = 'Branch Is a Required Field',
  PHONE = 'Incorrect Phone Format',
  EMAIL = 'Invalid Email Format',
  MAX_DIGIT = 'Maximum Of 10 Digits Allowed',
}

export const CONTACT_LIST_TYPE_OPTIONS: IOptions[] = [
  {
    title: 'Corporate',
    value: '1',
  },
  {
    title: 'Agency',
    value: '2',
  },
  {
    title: 'Branch',
    value: '3',
  },
];

export const filterDefaultValues = {
  contactListType: null,
};

export const CONTACT_LIST_TABLE_COLUMNS: IContactListTableColumns[] = [
  {
    title: 'Name',
    id: 'firstName',
    dataIndex: 'firstName',
    displayType: 'text',
    isVisible: true,
    isSearchable: true,
    sortable: false,
    width: 200,
  },
  {
    title: 'Surname',
    id: 'lastName',
    dataIndex: 'lastName',
    displayType: 'text',
    isVisible: true,
    isSearchable: true,
    sortable: false,
    width: 200,
  },
  {
    title: 'Username',
    id: 'username',
    dataIndex: 'username',
    displayType: 'text',
    isVisible: true,
    isSearchable: true,
    sortable: false,
    width: 200,
  },
  {
    title: 'Email',
    id: 'email',
    dataIndex: 'email',
    displayType: 'text',
    isVisible: true,
    isSearchable: true,
    sortable: false,
    width: 200,
  },
  {
    title: 'Phone number',
    id: 'phone',
    dataIndex: 'phone',
    displayType: 'tel',
    isVisible: true,
    sortable: false,
    width: 200,
    isSearchable: true,
  },
  {
    title: 'Level',
    id: 'level',
    dataIndex: 'level',
    displayType: 'text',
    isVisible: true,
    sortable: false,
    width: 200,
    isSearchable: true,
  },
  {
    title: 'Position',
    id: 'position',
    dataIndex: 'position',
    displayType: 'text',
    isVisible: true,
    sortable: false,
    width: 200,
    isSearchable: true,
  },
];
