import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Divider, Row } from 'antd';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { emailFormatter, formatPhone } from 'utils/helpers';

import { CONTACT_KEYS, SEARCH_CONTACT_COLUMNS, SUBJECT } from './constants/constants';
import { IContactCreateEditProps } from './constants/types';
import { Input, SearchableDropdown } from './hoc-instance/instance';
import useContactCreateEdit from './useContactCreateEdit';

import styles from './ContactCreateEdit.module.scss';

const ContactCreateEdit = ({
  control,
  errors,
  getValues,
  handleSubmit,
  onSubmit,
  watch,
  setValue,
  usersList,
  mode,
  setOffset,
  isFetchingById,
  isEditLoading,
  isCreateLoading,
}: IContactCreateEditProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsValid({ isValid: !isCreateLoading && !isEditLoading }));
  }, [isCreateLoading, isEditLoading]);

  const { searchContactOptions } = useContactCreateEdit(usersList);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainLoader isFetching={isFetchingById} />
        <div className={styles.contact}>
          <Typography
            variant="paragraph"
            children={mode === 'edit' ? SUBJECT.EDIT_CONTACT : SUBJECT.CREATE_CONTACT}
            className={styles.subHeading}
          />
          <Divider />
          <Row>
            <Col span={24}>
              <Row gutter={[18, 26]}>
                <Col span={24}>
                  <Row>
                    <Col span={16}>
                      <SearchableDropdown
                        control={control}
                        rules={{ required: true }}
                        options={searchContactOptions}
                        columns={SEARCH_CONTACT_COLUMNS}
                        keys={CONTACT_KEYS}
                        placeholder={SUBJECT.SEARCH_CONTACT}
                        name="searchContact"
                        setValue={setValue}
                        values={watch('searchContact')}
                        required
                        showKey="username"
                        disabled={mode === 'edit'}
                        errors={errors}
                        setOffset={setOffset}
                        offset={usersList?.count}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Input
                    name="email"
                    type="text"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.EMAIL}
                    getValues={getValues}
                    required
                    errors={errors}
                    onChange={(e: any) =>
                      setValue('email', emailFormatter(e.target.value.trimStart()), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="phone"
                    type="text"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.PHONE_NUMBER}
                    getValues={getValues}
                    required
                    errors={errors}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setValue('phone', formatPhone(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                  />
                </Col>
                <Col span={24}>
                  <Row gutter={[18, 26]}>
                    <Col span={8}>
                      <Input
                        name="position"
                        type="text"
                        control={control}
                        rules={{ required: true }}
                        placeholder={SUBJECT.POSITION}
                        getValues={getValues}
                        required
                        errors={errors}
                        onChange={(e: any) =>
                          setValue('position', e.target.value.trimStart(), {
                            shouldDirty: true,
                            shouldValidate: true,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </form>
    </>
  );
};

export default ContactCreateEdit;
