const NoProcessingIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.93789 20C8.56315 20 7.27122 19.7375 6.06211 19.2125C4.853 18.6875 3.80124 17.975 2.90683 17.075C2.01242 16.175 1.30435 15.1167 0.782609 13.9C0.26087 12.6833 0 11.3833 0 10C0 8.61667 0.26087 7.31667 0.782609 6.1C1.30435 4.88333 2.01242 3.825 2.90683 2.925C3.80124 2.025 4.853 1.3125 6.06211 0.7875C7.27122 0.2625 8.56315 0 9.93789 0C11.3954 0 12.7743 0.3 14.0745 0.9C15.3747 1.5 16.4969 2.35 17.441 3.45L9.93789 11V1.5C7.58592 1.5 5.59006 2.325 3.95031 3.975C2.31056 5.625 1.49068 7.63333 1.49068 10C1.49068 12.3667 2.31056 14.375 3.95031 16.025C5.59006 17.675 7.58592 18.5 9.93789 18.5C11.2795 18.5 12.5507 18.1958 13.7516 17.5875C14.9524 16.9792 15.9917 16.15 16.8696 15.1V17.175C15.9255 18.075 14.8571 18.7708 13.6646 19.2625C12.472 19.7542 11.2298 20 9.93789 20ZM18.3602 16.325V8.15H19.8509V16.325H18.3602ZM19.1801 19.825C18.9482 19.825 18.7536 19.7458 18.5963 19.5875C18.4389 19.4292 18.3602 19.2333 18.3602 19C18.3602 18.7667 18.4389 18.5708 18.5963 18.4125C18.7536 18.2542 18.9482 18.175 19.1801 18.175C19.412 18.175 19.6066 18.2542 19.764 18.4125C19.9213 18.5708 20 18.7667 20 19C20 19.2333 19.9213 19.4292 19.764 19.5875C19.6066 19.7458 19.412 19.825 19.1801 19.825Z"
        fill="var(--black)"
      />
    </svg>
  );
};

export default NoProcessingIcon;
