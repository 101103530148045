import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { createCarrier, createEditPaymentTerm, updateCarrierSerializer } from './deserializers';
import { GetLastLoadsByCarrierIdResult, ICarrierByIdData, ICarrierRatingParams, ICarrierType } from './interfaces';
import {
  equipmentTypeSerializer,
  getCarrierBlackListSerializer,
  getCarrierRatingsSerializer,
  getCarrierSerializer,
  getCarriersListSerializer,
  getCountsParamsSerializer,
  getLastLoadsSerializer,
  getRequestCarrierListForCarrierSerializer,
  getRequestCarrierListSerializer,
  getRequestCarrierSerializer,
  paymentTermSerializer,
} from './serializers';

export const carriersApi = createApi({
  reducerPath: 'carriersAPI',
  baseQuery,
  tagTypes: ['Carriers', 'Truck', 'Comment', 'PaymentTerm', 'GetCarrierList'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    createCarrier: builder.mutation({
      query: body => {
        return {
          url: 'carriers',
          method: 'POST',
          body: createCarrier(body),
        };
      },
      invalidatesTags: ['GetCarrierList'],
    }),
    editCarrier: builder.mutation({
      query: ({ body, id }) => ({
        url: `carriers/${id}`,
        method: 'PUT',
        body: updateCarrierSerializer(body),
      }),
      invalidatesTags: ['Carriers'],
    }),
    fillCarrier: builder.mutation({
      query: ({ body, id }) => ({
        url: `carriers/${id}/fill`,
        method: 'PUT',
        body: createCarrier(body),
      }),
      invalidatesTags: ['Carriers', 'GetCarrierList'],
    }),
    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `carriers/${Number(id)}/status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['Carriers'],
    }),
    getCarriers: builder.query({
      query: ({ search, field, skip, limit = 50, order, orderBy, filter, insuranceDays }: Partial<any>) => {
        const rates = filter?.ratings?.map((item: string) => {
          if (item === '6') {
            return '0';
          }
          return item;
        });

        return {
          url: 'carriers',
          params: {
            search,
            field,
            skip,
            limit,
            order,
            orderBy,
            ...(insuranceDays !== undefined ? { insuranceDays } : {}),
            ...(filter?.type ? { type: filter.type } : {}),
            ...(filter?.ratings ? { ratings: `[${rates?.toString()}]` } : {}),
          },
        };
      },
      transformResponse: (body: any) => getCarriersListSerializer(body),
      providesTags: ['Carriers', 'GetCarrierList'],
    }),
    getCarriersAutocomplete: builder.query({
      query: ({ search, skip, limit, filter }: Partial<any>) => ({
        url: 'carriers/autocomplete',
        params: {
          search,
          skip,
          limit,
          ...filter,
        },
      }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            title: el.companyName,
            value: String(el.id),
            name: el.companyName,
            mc: el.mc,
            dot: el.dot,
            phone: el.phone,
            companyName: el.companyName,
            type: ICarrierType[el.type],
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      providesTags: ['Carriers'],
    }),
    getCarrier: builder.query({
      query: ({ id }) => `carriers/${id}`,
      transformResponse: (body: ICarrierByIdData) => getCarrierSerializer(body),
      providesTags: ['Carriers'],
    }),
    getCarrierForFilter: builder.query({
      query: ({ id }) => `carriers/${id}`,
      transformResponse: (body: ICarrierByIdData) => getCarrierSerializer(body),
      providesTags: ['Carriers'],
    }),
    getCarrierRequest: builder.query({
      query: ({ id }) => `carriers/${id}/request`,
      providesTags: ['Carriers'],
      transformResponse: body => getRequestCarrierSerializer(body),
    }),
    getCarrierRequestCarrier: builder.query({
      query: ({ id }) => `carriers/on-board/${id}`,
      providesTags: ['Carriers'],
      transformResponse: body => ({
        result: {
          ...body?.result,
          paymentTerms: paymentTermSerializer(body?.result?.paymentTerms),
          equipmentType: equipmentTypeSerializer(body?.result?.equipments),
        },
      }),
    }),
    changeRequestStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `carriers/${id}/request-status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['Carriers'],
    }),
    changeRequestStatusForCarrier: builder.mutation({
      query: ({ id, status }) => ({
        url: `carriers/on-board/status`,
        method: 'PUT',
        body: { status, id: Number(id) },
      }),
      invalidatesTags: ['Carriers'],
    }),
    getCommentsByCarrierId: builder.query({
      query: ({ id, skip, limit }) => ({
        url: `carriers/${id}/comment`,
        params: { skip, limit },
      }),
      providesTags: ['Comment'],
      transformResponse: (data: any) => {
        return {
          count: data?.count,
          commentsList: data?.result?.map((item: any) => {
            return {
              id: item?.id,
              name: `${item?.creator.username}`,
              createdAt: item?.createdAt,
              commentText: item.message,
            };
          }),
        };
      },
    }),
    createComment: builder.mutation({
      query: ({ id, message }) => ({
        url: `carriers/comment`,
        method: 'POST',
        body: { message, carrierId: Number(id) },
      }),
      invalidatesTags: ['Comment'],
    }),
    deleteComment: builder.mutation({
      query: ({ commentId }) => ({
        url: `carriers/${commentId}/comment`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Comment'],
    }),
    addFileToCarrier: builder.mutation({
      query: ({ id, fileId, type }) => ({
        url: `carriers/${id}/file/${fileId}`,
        method: 'POST',
        body: { type },
      }),
    }),
    deleteFileFromCarrier: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `carriers/${id}/file/${fileId}`,
        method: 'DELETE',
      }),
    }),
    getCarrierRequestList: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<any>) => ({
        url: 'carriers/request',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getRequestCarrierListSerializer(body),
      providesTags: ['Carriers', 'GetCarrierList'],
    }),
    getCarrierRequestForCarrierList: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<any>) => ({
        url: 'carriers/on-board/list',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getRequestCarrierListForCarrierSerializer(body),
      providesTags: ['Carriers', 'GetCarrierList'],
    }),
    getCarrierBlackList: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<any>) => ({
        url: 'carriers/black-list',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getCarrierBlackListSerializer(body),
      providesTags: ['Carriers'],
    }),
    addCarrierBlackList: builder.mutation({
      query: ({ officeIds, branchIds, agentIds, id }: any) => ({
        url: `carriers/${id}/black-list`,
        method: 'POST',
        body: {
          officeIds: officeIds?.map((el: any) => Number(el)),
          ...((branchIds.length && !branchIds.includes(0)) || (branchIds.includes(0) && branchIds.length === 2)
            ? { branchIds: branchIds.filter((el: any) => el !== 0)?.map((el: any) => Number(el)) }
            : {}),
          ...(agentIds.length ? { agentIds: agentIds?.map((el: any) => Number(el)) } : {}),
        },
      }),
      invalidatesTags: ['Carriers'],
    }),
    deleteCarrierBlackList: builder.mutation({
      query: ({ groupId }: any) => ({
        url: `carriers/${groupId}/black-list`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Carriers'],
    }),
    getTrucksByCarrierId: builder.query({
      query: ({ id, skip, limit }: Partial<any>) => ({
        url: `carriers/${id}/trucks`,
        params: { limit, skip },
      }),
      providesTags: ['Truck'],
    }),
    addTruckToCarrier: builder.mutation({
      query: ({ carrierId, truckNumber, yearAndMake, boxTruck, length }) => ({
        url: `carriers/truck`,
        method: 'POST',
        body: {
          carrierId: Number(carrierId),
          truckNumber,
          yearAndMake,
          boxTruck: boxTruck,
          ...(boxTruck === 1 ? { length } : {}),
        },
      }),
      invalidatesTags: ['Truck'],
    }),
    deleteTruckById: builder.mutation({
      query: ({ id }) => ({
        url: `carriers/truck/${id}`,
        method: 'DELETE',
      }),
    }),
    editTruckById: builder.mutation({
      query: ({ id, carrierId, truckNumber, yearAndMake, boxTruck, length }) => ({
        url: `carriers/truck/${id}`,
        method: 'PUT',
        body: {
          carrierId: Number(carrierId),
          truckNumber,
          yearAndMake,
          boxTruck: boxTruck,
          ...(boxTruck === 1 ? { length: Number(length) } : {}),
        },
      }),
      invalidatesTags: ['Truck'],
    }),
    getFilesByCarrierIdAndType: builder.query({
      query: ({ id, type, skip, limit }: Partial<any>) => ({
        url: `carriers/${id}/file`,
        params: { skip, limit, fileType: type },
      }),
      providesTags: ['Carriers'],
    }),
    getCarrierBlackListByGroupId: builder.query({
      query: ({ id }: Partial<any>) => ({
        url: `carriers/black-list/${id}`,
      }),
      providesTags: ['Carriers'],
    }),
    getCarrierInsuranceCounts: builder.query({
      query: ({ filter }: any) => {
        const params = getCountsParamsSerializer(filter);

        return {
          url: `carriers/insurance/counts`,
          params: params,
        };
      },
      providesTags: ['Carriers'],
    }),
    //// CARRIER RATING
    createEditCarrierRating: builder.mutation({
      query: ({ rating, carrierId }: Partial<ICarrierRatingParams>) => ({
        url: `carriers/ratings`,
        method: 'POST',
        body: { carrierId: Number(carrierId), rating },
      }),
      invalidatesTags: ['Carriers'],
    }),
    deleteCarrierRating: builder.mutation({
      query: ({ ratingId }: Partial<ICarrierRatingParams>) => ({
        url: `carriers/ratings/${ratingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Carriers'],
    }),
    getCarrierRating: builder.query({
      query: ({ carrierId, skip, limit }: Partial<ICarrierRatingParams>) => ({
        url: `carriers/${carrierId}/ratings`,
        params: { skip, limit },
      }),
      transformResponse: (body: any) => getCarrierRatingsSerializer(body),
      providesTags: ['Carriers'],
    }),
    getCarrierLastLoads: builder.query({
      query: ({ id, search, field, skip, limit, order, orderBy }: Partial<any>) => ({
        url: `loads/carrier/${id}/last-loads`,
        params: {
          search,
          field,
          skip,
          limit,
          order,
          orderBy,
        },
      }),
      transformResponse: (body: GetLastLoadsByCarrierIdResult) => getLastLoadsSerializer(body),
      providesTags: ['Carriers'],
    }),
    getCarrierBreakdowns: builder.query({
      query: ({ skip, limit, order, orderBy, id }: any) => ({
        url: `carriers/${id}/file/breakdowns`,
        params: { skip, limit, order, orderBy },
      }),
      providesTags: ['Carriers'],
    }),
    //// FUEL CARDS
    createFuelCard: builder.mutation({
      query: ({ id, data }: any) => ({
        url: `carriers/${id}/fuel-cards`,
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: ['Carriers'],
    }),
    editFuelCard: builder.mutation({
      query: ({ id, data }: any) => ({
        url: `carriers/fuel-cards/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: ['Carriers'],
    }),
    deleteFuelCard: builder.mutation({
      query: ({ id }: any) => ({
        url: `carriers/fuel-cards/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Carriers'],
    }),
    getPaymentTermsByCarrierId: builder.query({
      query: ({ carrierId, limit, skip }: Partial<any>) => ({
        url: `carriers/${carrierId}/payment-terms`,
        params: { skip, limit },
      }),
      transformResponse: (body: any) => paymentTermSerializer(body),
      providesTags: ['PaymentTerm'],
    }),
    editPaymentTerm: builder.mutation({
      query: ({ id, data }: any) => ({
        url: `carriers/payment-terms/${id}`,
        method: 'PUT',
        body: createEditPaymentTerm(data),
      }),
      invalidatesTags: ['PaymentTerm'],
    }),
    addPaymentTerm: builder.mutation({
      query: ({ carrierId, data }: any) => ({
        url: `carriers/${carrierId}/payment-terms`,
        method: 'POST',
        body: createEditPaymentTerm(data),
      }),
      invalidatesTags: ['PaymentTerm'],
    }),
    deletePaymentTerm: builder.mutation({
      query: ({ id }: any) => ({
        url: `carriers/payment-terms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentTerm'],
    }),
  }),
});
export const {
  useGetCarrierQuery,
  useGetCarriersQuery,
  useChangeStatusMutation,
  useDeleteCommentMutation,
  useCreateCarrierMutation,
  useCreateCommentMutation,
  useEditTruckByIdMutation,
  useGetCarrierRequestQuery,
  useGetCarrierRequestCarrierQuery,
  useDeleteTruckByIdMutation,
  useAddFileToCarrierMutation,
  useGetCarrierBlackListQuery,
  useGetTrucksByCarrierIdQuery,
  useAddTruckToCarrierMutation,
  useGetCarrierRequestListQuery,
  useGetCarrierRequestForCarrierListQuery,
  useGetCommentsByCarrierIdQuery,
  useAddCarrierBlackListMutation,
  useChangeRequestStatusMutation,
  useChangeRequestStatusForCarrierMutation,
  useDeleteFileFromCarrierMutation,
  useDeleteCarrierBlackListMutation,
  useGetFilesByCarrierIdAndTypeQuery,
  useLazyGetFilesByCarrierIdAndTypeQuery,
  useGetCarrierBlackListByGroupIdQuery,
  useEditCarrierMutation,
  useGetCarrierInsuranceCountsQuery,
  useFillCarrierMutation,
  useGetCarriersAutocompleteQuery,
  useDeleteCarrierRatingMutation,
  useGetCarrierRatingQuery,
  useCreateEditCarrierRatingMutation,
  useLazyGetCarrierRatingQuery,
  useGetCarrierLastLoadsQuery,
  useCreateFuelCardMutation,
  useDeleteFuelCardMutation,
  useEditFuelCardMutation,
  useGetCarrierBreakdownsQuery,
  useGetCarrierForFilterQuery,
  useGetPaymentTermsByCarrierIdQuery,
  useEditPaymentTermMutation,
  useAddPaymentTermMutation,
  useDeletePaymentTermMutation,
} = carriersApi;
