export enum SUBJECT {
  NUMBER_OF_TRUCK = 'Number Of Truck',
  NUMBER_OF_TRAILERS = 'Number Of Trailers',
  FAVORITE = 'Favorite',
  ADDED_BY = 'Created By',

  CARRIERS_TABLE = 'Carrier table',
  ADDITIONAL = 'Additional',
  DATE = 'Date',
  CARRIER_ID = 'Carrier ID',
  COMPANY_NAME = 'Company Name',
  PHONE_NUMBER = 'Phone Number',
  SECONDARY_PHONE_NUMBER = 'Second Number',
  PAYMENT_PAID_AS = 'Paid As',
  FAX = 'Fax',
  FF_NUMBER = 'FF Number',
  STATE_NUMBER = 'State Number',
  CONTACT_NAME = 'Contact Name',
  TYPE = 'Type',
  MC = 'MC Number',
  ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIP = 'Zip Code',
  MAIL_CITY = 'City',
  MAIL_ZIP = 'ZIP Code',
  MAIL_STATE = 'State',
  MAIL_ADDRESS = 'Address',
  MAIL_SECOND_ADDRESS = 'Second Adress',
  PHYSICAL_CITY = 'Physical City',
  PHYSICAL_ZIP = 'Physical ZIP Code',
  PHYSICAL_STATE = 'Physical State',
  PHYSICAL_ADDRESS_VIEW = 'Physical Address',
  PHYSICAL_SECOND_ADDRESS = 'Physical Second Address',
  RATING = 'Rating',
  COMMENT = 'Comment',
  TERMINATE = 'Terminate',
  FACTORING = 'Factoring',
  PAYMENT_TERMS = 'Payment Terms',
  EMAIL = 'Email',
  SECOND_EMAIL = 'Second Email',
  BANK_INFO = 'Bank Information',
  BANK_NAME = 'Bank Name',
  ROUTING_NUMBER = 'Routing Number',
  CHECKING_NUMBER = 'Checking Number',
  ACCOUNT_NUMBER = 'Account Number',
  EDIT = 'Edit',
  DOT = 'DOT',
  FACTORED_COMPANY_NAME = 'Factored Company Name',
  PAYMENT_METHOD = 'Payment Method',
  PAYMENT_COMPANY_NAME = 'Company Name',
  PAID_AS = 'Paid As',
  DOCUMENTATION = 'Documents',
  CARRIER_PACKET = 'Carrier Packet',
  SIGN_AGGREMENT = 'Signed Agreement',
  PAID_AS_W9 = 'W9',
  INSURANCE = 'Insurance',
  VOIDED_CHECK = 'Voided Check/Bank Latter',
  CARRIER_PAYMENT_BREAKDOWN = 'Carrier Payment Breakdown',
  FILE_INFO = 'File Info',
  ADDED_TRUCK = 'Equipment',
  ADDED_FUEL_CARDS = 'Fuel Card',
  TRUCK_NUMBER = 'Truck Number',
  VIEW_MORE = 'View More',
  TRAILER_NUMBER = 'Trailer Number',
  TRAILER_TYPE = 'Trailer Type',
  LENGTH = 'Length',
  LIABILITY = 'Liability',
  CARGO = 'Cargo',
  FUEL_CARD = 'Fuel Card',
  CARRIER_INFO = 'Carrier Info',
  COMPANY_NAME_COLON = 'Company Name:',
  CARRIER_ID_COLON = 'Carrier ID:',
  MC_COLON = 'MC:',
  CONTACT_NAME_COLON = 'Contact Name:',
  CARRIER_TYPE_COLON = 'Carrier Type:',
  FACTORED_COLON = 'Factored:',
  PHONE_NUMBER_COLON = 'Phone Number:',
  FAX_COLON = 'Fax:',
  RATING_COLON = 'Rating:',
  SECOND_NUMBER_COLON = 'Second Number:',
  PAYMENT_COLON = 'Payment Term:',
  DAY_COLON = 'Day:',
  PERCENT_COLON = 'Percent:',
  COORDINATOR_COLON = 'Coordinator:',
  INSURANCE_LIABILITY = 'Insurance Liability Exp:',
  INSURANCE_CARGO = 'Insurance Cargo Exp:',
  CARGO_COVERAGE_VALUE = 'Cargo Coverage Value',
  TRAILER_INTERCHANGE = 'Trailer Interchange',
  REEFER_BREAKDOWN = 'Reefer Breakdown',
  CAN_HAUL = 'Can Haul',
  LAST_LOADS = 'Last Loads',
  DOWNLOAD_ALL = 'Download All',
  NON_OWNED_COVERAGE = 'NON Owned Coverage',
  FULL_INFO = 'Carrier Detail',
  STATUS = 'Status',
  CARRIER_INFO_ID = 'carrier-info-id',
  FULL_INFO_ID = 'full-info-id',
  ADDED_TRUCK_ID = 'added-truck-info-id',
  ADDED_FUEL_CARDS_ID = 'added-fuel-cards-id',
  FACTORING_ID = 'factoring-id',
  PAYMENT_TERMS_ID = 'payment-terms-id',
  PAYMENT_METHOD_ID = 'payment-method-id',
  DOCUMENTATION_ID = 'documentation-id',
  CARRIER_PAYMENT_BREAKDOWN_ID = 'carrier-payment-breakdown-id',
  LAST_LOADS_ID = 'last-loads-id',
  MAIN_FUEL_CARD = 'Main fuel card',
}

export enum TrailerTypes {
  'Van' = 1,
  'Reefer' = 2,
  'Flat bed' = 3,
  'Power only' = 4,
  'Box truck' = 5,
}
