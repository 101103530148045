import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';
import clsx from 'clsx';

import NotifyDrawer from 'components/notification-drawer/NotifyDrawer';
import ChevronIcon from 'components/svgs/ChevronIcon';
import LogOutIcon from 'components/svgs/LogOutIcon';

import HeaderSearch from './components/header-search/HeaderSearch';
import HeaderSelect from './components/header-select/HeaderSelect';
import { useHeader } from './useHeader';

import styles from './Header.module.scss';

const Sidebar = () => {
  const {
    userMenu,
    handleUserMenu,
    ref,
    sidebarState,
    handleNavigateToUserSettings,
    filterControl,
    isOverlayOpen,
    handleOverlayState,
    handleLogOut,
    userInfo,
    getValues,
    setGetCustomersFilter,
    setGetCarrierFilter,
    setGetLoadsFilter,
    customersData,
    carriersData,
    loadsData,
    handleRowClick,
    setValue,
    isFetching,
    isFetchingUserInfo,
  } = useHeader();

  return (
    <header
      className={clsx(styles.header, {
        [styles.growHeader]: sidebarState,
        [styles.overlayOpen]: isOverlayOpen,
      })}
    >
      <div className={styles.headerRow}>
        <div className={styles.headerleft}>
          <div className={styles.headerLeftContent}>
            <HeaderSelect filterControl={filterControl} />
            <HeaderSearch
              getValues={getValues}
              filterControl={filterControl}
              handleOverlayState={handleOverlayState}
              isOverlayOpen={isOverlayOpen}
              setGetCustomersFilter={setGetCustomersFilter}
              setGetCarrierFilter={setGetCarrierFilter}
              setGetLoadsFilter={setGetLoadsFilter}
              customersData={customersData}
              carriersData={carriersData}
              loadsData={loadsData}
              handleRowClick={handleRowClick}
              setValue={setValue}
              isFetching={isFetching}
            />
          </div>
          {isOverlayOpen && (
            <div
              onClick={() => {
                setTimeout(() => {
                  handleOverlayState();
                }, 100);
              }}
              className={styles.overlay}
            />
          )}
        </div>
        <div className={styles.headerRight}>
          <div className={styles.headerRightContent}>
            <div className={styles.headerNotifiation}>
              <NotifyDrawer />
            </div>
            <div className={styles.userLogin}>
              <ul className={styles.loginList}>
                <li ref={ref} onClick={handleUserMenu}>
                  <button className={styles.dropdownButton}>
                    {isFetchingUserInfo ? (
                      <>
                        <Skeleton.Input active size={'small'} />
                      </>
                    ) : (
                      `${userInfo?.username || ''}`
                    )}
                    <span>
                      <ChevronIcon />
                    </span>
                  </button>
                  <ul
                    className={clsx(styles.userdropDown, {
                      [styles.openedMenu]: userMenu,
                    })}
                  >
                    <li>
                      <Link to={handleNavigateToUserSettings()}>Settings</Link>
                    </li>
                    <li onClick={handleLogOut}>
                      <button>
                        Logout
                        <span>
                          <LogOutIcon />
                        </span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Sidebar;
