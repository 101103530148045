import { phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  NAME_REQUIRED,
  ZIP_REQUIRED,
  PAYOUT_REQUIRED,
  OFFICE_LOCATION_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  STATE_ID_REQUIRED,
  PHONE,
  INCORRECT_FAX,
  MAX_LENGTH,
  ADDRESS_MAX_LENGTH,
  AUTHORITIES_REQUIRED,
  MAX_MIN_ZIP_LENGTH,
} = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  name: yup.string().required(NAME_REQUIRED),
  officeLocation: yup.string().required(OFFICE_LOCATION_REQUIRED),
  phoneNumber: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBER_REQUIRED).matches(phoneRegExp, PHONE),
  fax: yup
    .string()
    .nullable()
    .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
      if ((value && value.length === 12) || !value) {
        return true;
      } else if (!value?.match(phoneRegExp)) {
        return this.createError({
          message: INCORRECT_FAX,
          path: 'fax',
        });
      } else {
        return this.createError({
          message: MAX_LENGTH,
          path: 'fax',
        });
      }
    }),
  address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
  city: yup.string().required(CITY_REQUIRED),
  stateId: yup.string().required(STATE_ID_REQUIRED),
  zipcode: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
  payout: yup.string().required(PAYOUT_REQUIRED),
  authorities: yup.mixed().test('length', AUTHORITIES_REQUIRED, function checkLength(arr = []) {
    return Array.isArray(arr) && arr.length > 0;
  }),
  bankName: yup.string(),
  accounting_number: yup.string(),
  ach_routing_number: yup.string(),
});
