import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Collapse, notification, Row } from 'antd';
import clsx from 'clsx';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import { EquipmentTypes } from 'services/carriersOnBoard/interface';
import { useUploadFileMutation } from 'services/carriersOnBoard/upload';
import { ICarrierType } from 'services/profile/carriers/interfaces';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { closeModal, openModal } from 'store/modal-slice/modals';
// import { selectIsLoading } from 'store/sidebarSlice/selector';
import { selectUserInfo } from 'store/user-slice/selector';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import Checkbox from 'ui/inputs/checkbox/Checkbox';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import { notificationKey } from 'utils/constants';
import { FILE_MAX_SIZE_ERROR } from 'utils/errors';
import {
  emailFormatter,
  formatPhone,
  isFileAllowedToBeUpload,
  onlyNumbersFormatter,
  unicodeFormatter,
} from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import FilledArrowIcon from 'components/svgs/FilledArrowIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from '../../../profile/tabs/carriers/constants/constants';
import { Input, Switch } from '../../../profile/tabs/carriers/hoc-instance/instance';
import FormAgreement from '../form-agreement/FormAgreement';
import PaymentTermsSignUp from '../paymentTerms-sign-up/PaymentTermsSignUp';
import PreferredCarrier from '../preferred-carrier/PreferredCarrier';

import styles from './CreateCarrier.module.scss';

const { Panel } = Collapse;

const CreateCarrierSignUp = ({
  handleSubmit,
  onSubmit,
  control,
  getValues,
  setValue,
  watch,
  errors,
  mode,
  statesData,
  activeSection,
  trigger,
  truckSectionRef,
  isEditLoading,
  isCreateLoading,
  setPaymentTermDirty,
  isSubmitted,
  panelShowed,
  setPanelShowed,
  clearCarrierFormErrors,
}: any) => {
  const handleResetTruck = () => {
    setValue('numberOfTrailers', '', { shouldDirty: false, shouldTouch: false, shouldValidate: false });
    setValue('numberOfTrucks', '', { shouldDirty: false, shouldTouch: false, shouldValidate: false });
    setValue('equipmentType', [], { shouldDirty: false, shouldTouch: false, shouldValidate: false });
  };

  const dispatch = useDispatch();
  // const isLoading = useSelector(selectIsLoading);
  useEffect(() => {
    dispatch(setIsValid({ isValid: !isEditLoading && !isCreateLoading }));
  }, [isEditLoading, isCreateLoading]);
  const carrierTruckFormRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (watch('Cargo')) {
      trigger('Cargo');
    }
  }, [watch('Cargo')]);

  useEffect(() => {
    if (watch('Liability')) {
      trigger('Liability');
    }
  }, [watch('Liability')]);
  const userInfo = useSelector(selectUserInfo);

  const [allFiles, setAllFiles] = useState<any>({
    carrierPacketFile: [],
    companyOwnerIdFile: [],
    paidAsW9File: [],
    insuranceFile: [],
    voidedCheckBankLatterFile: [],
  });
  const [uploadFileNOA] = useUploadFileMutation();
  const [localFiles, setLocalFiles] = useState<any>([]);

  const handleDeleteCarrierFile = (fileId: number, ctx: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          setValue(
            ctx,
            getValues(ctx)?.filter((el: any) => el !== fileId)
          );
          setAllFiles((prev: any) => {
            const updated = prev[ctx]?.filter((el: any) => el.id !== fileId);
            return {
              ...prev,
              [ctx]: updated,
            };
          });
          trigger(ctx);
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleViewCarrieryFileEdit = (file: any) => {
    const fileToView = localFiles.find((el: any) => el.uid === file.uid);
    if (fileToView) {
      const blob = new Blob([fileToView], { type: fileToView.type });

      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      window.URL.revokeObjectURL(url);
    }
  };
  const onCheckBoxCheck = (ctx: number) => () => {
    const newValue = getValues('equipmentType');
    if (newValue.includes(ctx)) {
      setValue(
        'equipmentType',
        newValue.filter((id: number) => id !== ctx)
      );
    } else {
      setValue('equipmentType', [...newValue, ctx]);
    }
  };

  const beforeUpload = (file: any, ctx: any, setLoading: any) => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', '1');
      uploadFileNOA(formData)
        .then(({ data: { result } }: any) => {
          setLocalFiles((prev: any) => [file, ...prev]);
          setValue(ctx, getValues(ctx) ? [...getValues(ctx), ...result!.ids] : result);
          setAllFiles((prev: any) => {
            prev[ctx].unshift({
              fullName: `${userInfo?.username}`,
              fileName: file.name,
              id: result?.ids?.[0],
              createdAt: new Date(),
              uid: file.uid,
              preview: file.preview,
              download: file.download,
            });
            return { ...prev };
          });
          trigger(ctx);
        })
        .catch(() => null)
        .finally(() => {
          setLoading(false);
        });

      return false;
    } else {
      notification.error({
        message: FILE_MAX_SIZE_ERROR,
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  const handleDownloadCarrieryFile = (file: any) => {
    const fileToDownload = localFiles.find((el: any) => el.uid === file.uid);
    const blob = new Blob([fileToDownload]);

    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');

    downloadLink!.href = url;
    downloadLink.download = fileToDownload!.name;

    // Programmatically click the link to trigger the download
    downloadLink.click();

    // Clean up the temporary URL
    window.URL.revokeObjectURL(url);
  };

  const { file }: any = window.global;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(styles.carriers, {
        [styles.editMode]: mode === 'edit',
      })}
    >
      <ViewItemWrapper
        title={SUBJECT.FILL_ALL_CARRIER_INFORMATION}
        className={activeSection === SUBJECT.GENERAL_INFO_ID ? 'active' : ''}
        id={SUBJECT.GENERAL_INFO_ID}
      >
        <div>
          <Typography variant="heading" level={3} children={SUBJECT.GENERAL_INFO} className={styles.generalInfo} />
          <Row gutter={[18, 40]}>
            <Col span={24}>
              <Input
                name="company"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.COMPANY_NAME}
                getValues={getValues}
                errors={errors}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="primaryPhone"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.PRIMARY_PHONE_NUMBER}
                getValues={getValues}
                errors={errors}
                onChange={(e: any) =>
                  setValue('primaryPhone', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="secondaryPhone"
                control={control}
                placeholder={SUBJECT.SECONDARY_PHONE_NUMBER}
                onChange={(e: any) =>
                  setValue('secondaryPhone', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                getValues={getValues}
              />
            </Col>
            <Col span={8}>
              <Input
                name="fax"
                onChange={(e: any) =>
                  setValue('fax', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                control={control}
                placeholder={SUBJECT.FAX}
                getValues={getValues}
              />
            </Col>
            <Col span={8}>
              <Input
                name="contact"
                control={control}
                rules={{ required: true }}
                onChange={({ target: { value } }) => {
                  setValue('contact', unicodeFormatter(value.trimStart()), { shouldDirty: true, shouldValidate: true });
                }}
                placeholder={SUBJECT.CONTACT_NAME}
                getValues={getValues}
                errors={errors}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="email"
                type="string"
                control={control}
                required
                onChange={(e: any) =>
                  setValue('email', emailFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                rules={{ required: true }}
                placeholder={SUBJECT.EMAIL}
                getValues={getValues}
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="MC"
                type="number"
                control={control}
                placeholder={SUBJECT.MC}
                getValues={getValues}
                errors={errors}
                rules={{ required: true }}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="dot"
                control={control}
                rules={{ required: true }}
                onChange={(e: any) =>
                  setValue('dot', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.DOT}
                getValues={getValues}
                errors={errors}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="ff"
                control={control}
                placeholder={SUBJECT.FF_NUMBER}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => {
                  setValue('ff', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
            <Col span={8}>
              <Input
                name="stateNumber"
                type="number"
                control={control}
                placeholder={SUBJECT.STATE_NUMBER}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => {
                  setValue('stateNumber', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[18, 40]}>
                <Col span={12}>
                  <Input
                    name="address"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.ADDRESS}
                    getValues={getValues}
                    errors={errors}
                    required
                    onChange={(e: any) =>
                      setValue('address', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                    }
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name="secondAddress"
                    control={control}
                    placeholder={SUBJECT.SECOND_ADDRESS}
                    getValues={getValues}
                    errors={errors}
                    onChange={(e: any) =>
                      setValue('secondAddress', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                    }
                  />
                </Col>
                <Col span={24}>
                  <Row gutter={18}>
                    <Col span={8}>
                      <Input
                        name="city"
                        control={control}
                        rules={{ required: true }}
                        placeholder={SUBJECT.CITY}
                        getValues={getValues}
                        errors={errors}
                        required
                        onChange={(e: any) =>
                          setValue('city', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                        }
                      />
                    </Col>
                    <Col span={8}>
                      <Controller
                        name="state"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <StateSelect
                              allowClear
                              options={statesData}
                              placeholder={SUBJECT.STATE}
                              name={field.name}
                              errors={errors}
                              onBlur={() => trigger('state')}
                              onChange={(value: string) =>
                                setValue('state', value, {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                })
                              }
                              value={field.value}
                              required
                            />
                          );
                        }}
                      />
                      <ErrorMessage fieldError={errors?.state} />
                    </Col>
                    <Col span={8}>
                      <Input
                        name="zip"
                        control={control}
                        rules={{ required: true }}
                        placeholder={SUBJECT.ZIP_CODE}
                        getValues={getValues}
                        onChange={(e: any) =>
                          setValue('zip', onlyNumbersFormatter(e.target.value), {
                            shouldDirty: true,
                            shouldValidate: true,
                          })
                        }
                        errors={errors}
                        required
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row align="middle" className={styles.switchSection}>
                <Col span={5}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.ADD_PHYSICAL_ADDRESS}
                    className={styles.switchHeading}
                  />
                </Col>
                <Col>
                  <Switch
                    name="physical"
                    control={control}
                    checked={watch('physical')}
                    onChange={() =>
                      setValue('physical', !watch('physical'), { shouldDirty: true, shouldValidate: true })
                    }
                  />
                </Col>
              </Row>
            </Col>

            {!!watch('physical') && (
              <>
                <Col span={12}>
                  <Input
                    name="physicalAddress"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.ADDRESS}
                    getValues={getValues}
                    errors={errors}
                    required
                    onChange={(e: any) =>
                      setValue('physicalAddress', e.target.value.trimStart(), {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name="physicalSecondAddress"
                    control={control}
                    placeholder={SUBJECT.SECOND_ADDRESS}
                    getValues={getValues}
                    errors={errors}
                    onChange={(e: any) => setValue('physicalSecondAddress', e.target.value.trimStart())}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="physicalCity"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.CITY}
                    getValues={getValues}
                    errors={errors}
                    required
                    onChange={(e: any) =>
                      setValue('physicalCity', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                    }
                  />
                </Col>
                <Col span={8}>
                  <Controller
                    name="physicalState"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <StateSelect
                          allowClear
                          options={statesData}
                          placeholder={SUBJECT.STATE}
                          name={field.name}
                          errors={errors}
                          onBlur={() => trigger('physicalState')}
                          onChange={(value: string) =>
                            setValue('physicalState', value, {
                              shouldDirty: true,
                              shouldValidate: true,
                            })
                          }
                          value={field.value}
                          required
                        />
                      );
                    }}
                  />
                  <ErrorMessage fieldError={errors?.physicalState} />
                </Col>
                <Col span={8}>
                  <Input
                    name="physicalZip"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.ZIP_CODE}
                    getValues={getValues}
                    onChange={(e: any) =>
                      setValue('physicalZip', onlyNumbersFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    errors={errors}
                    required
                  />
                </Col>
              </>
            )}
          </Row>
        </div>
      </ViewItemWrapper>
      <ViewItemWrapper
        title={SUBJECT.DOCUMENTATION}
        className={activeSection === SUBJECT.DOCUMENTATION_ID ? 'active' : ''}
        id={SUBJECT.DOCUMENTATION_ID}
      >
        <div className={styles.collapseWrapper}>
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="end"
            ghost
            expandIcon={({ isActive }) => {
              return (
                <span className={clsx(styles.arrow, { [styles.activeArrow]: isActive })}>
                  <FilledArrowIcon />
                </span>
              );
            }}
          >
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography
                      variant="paragraph"
                      children="Carrier Packet"
                      required
                      className={styles.documentationSubTitle}
                    />
                    {errors?.carrierPacketFile && (
                      <ErrorMessage fieldError={{ message: 'Carrier packet file is required' }} />
                    )}
                  </div>
                  <span>({allFiles['carrierPacketFile']?.length || 0})</span>
                </>
              }
              key="1"
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['carrierPacketFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'carrierPacketFile')}
                        handleDownloadFile={handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewCarrieryFileEdit}
                        files={allFiles['carrierPacketFile']}
                        hasAuthor={false}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) => beforeUpload(e, 'carrierPacketFile', setLoading)}
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography
                      variant="paragraph"
                      children="Company owner ID"
                      className={styles.documentationSubTitle}
                    />
                  </div>
                  <span>({allFiles['companyOwnerIdFile']?.length || 0})</span>
                </>
              }
              key="2"
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['companyOwnerIdFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'companyOwnerIdFile')}
                        files={allFiles['companyOwnerIdFile']}
                        handleDownloadFile={handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewCarrieryFileEdit}
                        hasAuthor={false}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) => beforeUpload(e, 'companyOwnerIdFile', setLoading)}
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography variant="paragraph" children="W9" className={styles.documentationSubTitle} />
                  </div>
                  <span>({allFiles?.['paidAsW9File']?.length || 0})</span>
                </>
              }
              key="3"
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['paidAsW9File']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'paidAsW9File')}
                        handleDownloadFile={handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewCarrieryFileEdit}
                        files={allFiles['paidAsW9File']}
                        hasAuthor={false}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) => beforeUpload(e, 'paidAsW9File', setLoading)}
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography variant="paragraph" children="Insurance" className={styles.documentationSubTitle} />
                  </div>
                  <span>({allFiles?.['insuranceFile']?.length || 0})</span>
                </>
              }
              key="4"
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['insuranceFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'insuranceFile')}
                        handleDownloadFile={handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewCarrieryFileEdit}
                        files={allFiles['insuranceFile']}
                        hasAuthor={false}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) => beforeUpload(e, 'insuranceFile', setLoading)}
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography
                      variant="paragraph"
                      children="Voided Check / Bank Letter"
                      className={styles.documentationSubTitle}
                    />
                  </div>
                  <span>({allFiles?.['voidedCheckBankLatterFile']?.length || 0})</span>
                </>
              }
              key="5"
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['voidedCheckBankLatterFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'voidedCheckBankLatterFile')}
                        handleDownloadFile={handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewCarrieryFileEdit}
                        files={allFiles['voidedCheckBankLatterFile']}
                        hasAuthor={false}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) => beforeUpload(e, 'voidedCheckBankLatterFile', setLoading)}
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      </ViewItemWrapper>

      {String(watch('type')) === String(ICarrierType['Outside']) && (
        <div ref={truckSectionRef}>
          <ViewItemWrapper
            title={SUBJECT.ADD_TRUCK}
            Component={
              (!!watch('numberOfTrucks') || !!watch('numberOfTrailers') || !!watch('equipmentType')?.length) && (
                <Button icon={<ResetIcon />} variant="ghost" children={SUBJECT.RESET} onClick={handleResetTruck} />
              )
            }
            className={activeSection === SUBJECT.TRUCK_ID ? 'active' : ''}
            id={SUBJECT.TRUCK_ID}
          >
            <div ref={carrierTruckFormRef}>
              {String(watch('type')) === String(ICarrierType['Outside']) && (
                <>
                  <Row gutter={[17, 38]}>
                    <Col span={8}>
                      <Input
                        name="numberOfTrucks"
                        control={control}
                        placeholder={SUBJECT.NUMBER_OF_TRUCK}
                        onChange={(e: any) => {
                          setValue('numberOfTrucks', onlyNumbersFormatter(e.target.value), {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }}
                        getValues={getValues}
                        errors={errors}
                        required
                        rules={{ required: true }}
                      />
                    </Col>
                    <Col span={8}>
                      <Input
                        name="numberOfTrailers"
                        control={control}
                        placeholder={SUBJECT.NUMBER_OF_TRAILER}
                        onChange={(e: any) => {
                          setValue('numberOfTrailers', onlyNumbersFormatter(e.target.value), {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }}
                        getValues={getValues}
                        errors={errors}
                        required
                        rules={{ required: true }}
                      />
                    </Col>
                  </Row>
                  <div className={styles.truckTypes}>
                    <label className={styles.truckType}>
                      Dry Van
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.DRY_VAN)}
                        onChange={onCheckBoxCheck(EquipmentTypes.DRY_VAN)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Reefer
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.REEFER)}
                        onChange={onCheckBoxCheck(EquipmentTypes.REEFER)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Flat bed
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.FLAT_BED)}
                        onChange={onCheckBoxCheck(EquipmentTypes.FLAT_BED)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Box truck
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.BOX_TRUCK)}
                        onChange={onCheckBoxCheck(EquipmentTypes.BOX_TRUCK)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Conestoga
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.CONESTOGA)}
                        onChange={onCheckBoxCheck(EquipmentTypes.CONESTOGA)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Step deck
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.STEP_DECK)}
                        onChange={onCheckBoxCheck(EquipmentTypes.STEP_DECK)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Sprinter van
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.SPRINTER_VAN)}
                        onChange={onCheckBoxCheck(EquipmentTypes.SPRINTER_VAN)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Curtain Van
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.CURTAIN_VAN)}
                        onChange={onCheckBoxCheck(EquipmentTypes.CURTAIN_VAN)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Low boy
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.LOW_BOY)}
                        onChange={onCheckBoxCheck(EquipmentTypes.LOW_BOY)}
                      />
                    </label>
                    <label className={styles.truckType}>
                      Hot shot
                      <Checkbox
                        size="default"
                        checked={getValues('equipmentType').includes(EquipmentTypes.HOT_SHOT)}
                        onChange={onCheckBoxCheck(EquipmentTypes.HOT_SHOT)}
                      />
                    </label>
                  </div>
                </>
              )}
            </div>
          </ViewItemWrapper>
        </div>
      )}

      <>
        {errors.paymentTerms && (
          <Row>
            <Col>
              <ErrorMessage fieldError={errors?.paymentTerms} />
            </Col>
          </Row>
        )}
        <PaymentTermsSignUp
          statesData={statesData}
          activeSection={activeSection}
          setPaymentTermDirty={setPaymentTermDirty}
          isSubmitted={isSubmitted}
          setValue={setValue}
          signUpFormErrors={errors}
          panelShowed={panelShowed}
          setPanelShowed={setPanelShowed}
          clearCarrierFormErrors={clearCarrierFormErrors}
        />
        {errors.paymentTerms && (
          <Row>
            <Col>
              <ErrorMessage fieldError={errors?.paymentTerms} />
            </Col>
          </Row>
        )}
      </>
      <PreferredCarrier setValue={setValue} watch={watch} control={control} errors={errors} />

      <FormAgreement setValue={setValue} watch={watch} control={control} errors={errors} />
    </form>
  );
};

export default CreateCarrierSignUp;
