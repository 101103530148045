export enum SUBJECT {
  COMMENT = 'Comment',
  COMMENTS = 'Comments',
  NOTIFICATIONS = 'Notifications',
  PLACEHOLDER = 'Write Comment',
  ADD_COMMENT = 'Add Comment',
  ADDED = 'Added',
  NO_COMMENT = 'No Comments',
  NO_NOTIFICATION = 'No Notification',
  VIEW_MORE = 'View More',
  LOAD_HISTORY = 'Load History',
}
