import { useMemo } from 'react';
import { InView } from 'react-intersection-observer';
import { Col, Drawer, Row } from 'antd';
import { handler } from 'api/notifications/notificationHandler';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import DrawerCloseIcon from 'components/svgs/DrawerCloseIcon';

import { MainLoader } from '../../ui/main-loader/MainLoader';
import NotificationIcon from '../svgs/NotificationIcon';

import { SUBJECT } from './constants/constants';
import { useNotifyDrawer } from './useNotifyDrawer';

import styles from './NotificationDrawer.module.scss';

const NotifyDrawer = () => {
  const {
    isOpen,
    onClose,
    onOpen,
    handleScroll,
    handleReadNotes,
    unreadCountsLocal,
    notifications,
    handleReadAllNotes,
    isFetching,
    unreadCount,
    notificationCount,
    readCounts,
  } = useNotifyDrawer();

  const notesCount = useMemo(() => {
    if (isFetching) {
      if (unreadCountsLocal - readCounts <= 0) {
        return 0;
      } else if (unreadCountsLocal >= 100) {
        return '99+';
      } else {
        return unreadCountsLocal - readCounts;
      }
    } else {
      if (notificationCount >= 100) {
        return '99+';
      }
      if (notificationCount < 100) {
        if (notificationCount <= 0) {
          return 0;
        } else {
          return notificationCount - readCounts;
        }
      }
    }
  }, [isFetching, readCounts]);

  const countsLocal = unreadCountsLocal >= 100 ? '99+' : unreadCountsLocal - readCounts;

  const extraContent = useMemo(() => {
    return (
      <div className={styles.headerContent}>
        <Row align="middle" gutter={6}>
          <Col>
            <Typography variant="heading" level={3} className={styles.headerTitle}>
              {SUBJECT.NOTIFICATIONS}
            </Typography>
          </Col>
          <Col>
            <button type="button" className={styles.notificationOuterButton}>
              <NotificationIcon />

              {notesCount ? (
                <span>{notesCount === 99 && unreadCountsLocal > 100 ? `${notesCount}+` : notesCount}</span>
              ) : null}
            </button>
          </Col>
        </Row>

        {countsLocal ? <Button variant="comment" children={SUBJECT.READ_ALL} onClick={handleReadAllNotes} /> : null}
      </div>
    );
  }, [unreadCount, notifications?.length, isFetching, countsLocal, notesCount]);

  return (
    <>
      <button type="button" onClick={onOpen} className={styles.notificationOuterButton}>
        <NotificationIcon />
        {Number(unreadCount) ? (
          <span>
            {notificationCount === 99 && unreadCountsLocal > 100 ? `${notificationCount}+` : notificationCount}
          </span>
        ) : null}
      </button>
      <Drawer
        width={575}
        rootClassName={styles.notificationDrawerWrapper}
        zIndex={99999}
        destroyOnClose
        open={isOpen}
        onClose={onClose}
        closeIcon={null}
        title={extraContent}
      >
        <div className={styles.drawerControlPanel}>
          <div className={styles.closeButton}>
            <Button onClick={onClose} variant="text" shape="default" children={<DrawerCloseIcon />} />
          </div>
        </div>
        {!isOpen ? (
          <MainLoader shouldWorkAnyway />
        ) : (
          <div className={styles.notificationDrawerContent} onScroll={handleScroll}>
            {!notifications?.length ? (
              <Typography className={styles.noData} variant="paragraph">
                {SUBJECT.NO_DATA}
              </Typography>
            ) : (
              <>
                {notifications?.map((notification: any) => {
                  const eventType = notification.eventType;
                  const [handlerKey, subHandlerKey] = eventType?.split('.') || [];
                  const mainHandler = handler.handlers[handlerKey];

                  if (mainHandler && subHandlerKey && mainHandler.handlers[subHandlerKey]) {
                    mainHandler.handlers[subHandlerKey]({
                      id: notification.id,
                      type: notification.type,
                      payload: notification,
                    });
                    return (
                      <InView
                        as="div"
                        skip={notification?.read}
                        key={notification?.id}
                        className={styles.notification}
                        {...(!notification?.read && { onClick: () => handleReadNotes(notification.id) })}
                        id={notification.id}
                      >
                        {mainHandler.notification}
                        {isFetching && (
                          <>
                            <div className={styles.skeletonWrapper}>
                              <div className={styles.ldsRoller}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </>
                        )}
                      </InView>
                    );
                  }
                })}
              </>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default NotifyDrawer;
