import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import {
  useAddAuhtorityToBlackListMutation,
  useDeleteAuhtorityFromBlackListMutation,
  useGetCustomersAutocompleteQuery,
} from 'services/profile/customers/customers';
import { IGetCustomersParams } from 'services/profile/customers/interfaces';
import { useEditColumnsMutation } from 'services/user/user';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { blacklistFilterDefaultValues, SUBJECT } from '../../constants/constants';
import { ICustomerBlacklistFilter } from '../../constants/types';

import { ICustomersTableColumns } from './components/blacklist/constants/types';

const useCustomersBlacklistTable = ({ setBlackListFilter, data, skip = 0 }: any) => {
  const [showBlacklist, setShowBlacklist] = useState(false);
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { activePage } = searchParams;

  const [authoritiesFilter, setAuthoritiesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: [],
  });

  const [getCustomersFilter, setCustomersFilter] = useState<IGetCustomersParams>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const { data: authorities = { result: [], count: 0 } } = useGetAuthoritiesShortQuery(authoritiesFilter, {
    skip: activePage !== 'customer-blacklist',
  });

  const { data: customersData = { result: [], count: 0 } } = useGetCustomersAutocompleteQuery(getCustomersFilter, {
    skip: activePage !== 'customer-blacklist',
  });

  useEffect(() => {
    if (!data?.result?.length && skip > 0) {
      setBlackListFilter((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [data?.result?.length]);

  const [deleteAuthorityFromBlackList] = useDeleteAuhtorityFromBlackListMutation();
  const [addAuthorityToBlackList] = useAddAuhtorityToBlackListMutation();

  const [editColumns] = useEditColumnsMutation();

  const { control, reset, watch, handleSubmit, setValue } = useForm<ICustomerBlacklistFilter>({
    defaultValues: blacklistFilterDefaultValues,
  });

  const [finalAuthorityOptions, setFinalAuthorityOptions] = useState<any>({ result: [], count: 0 });
  const [finalCustomerOptions, setFinalCustomerOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (watch('blacklistMC')?.length) {
      setFinalAuthorityOptions({
        result: [...watch('blacklistMC'), ...authorities.result],
        count: authorities.count,
      });
    } else {
      setFinalAuthorityOptions(authorities);
    }
  }, [authorities?.result?.length]);

  useEffect(() => {
    if (watch('blacklistCustomer')?.length) {
      setFinalCustomerOptions({
        result: [
          ...watch('blacklistCustomer'),
          ...customersData.result.filter((el: any) => el.id !== watch('blacklistCustomer')?.[0]?.id),
        ],
        count: customersData.count,
      });
    } else {
      setFinalCustomerOptions(customersData);
    }
  }, [customersData?.result?.length]);

  const handleBlacklistClick = () => {
    setShowBlacklist(prev => !prev);
  };

  const handleResetBlacklist = () => {
    if (watch('blacklistCustomer') || watch('blacklistMC')) {
      reset();
    } else {
      handleBlacklistClick();
    }
  };

  const onSubmit = (data: ICustomerBlacklistFilter) => {
    if (watch('blacklistCustomer')?.length && watch('blacklistMC')?.length) {
      addAuthorityToBlackList({
        customerId: data.blacklistCustomer?.[0]?.id,
        authorityId: data.blacklistMC?.[0]?.id,
      }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: data?.result?.updated ? SUBJECT.BLACK_LIST_UPDATED : 'Successfully added',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        }
      });
    }
    setShowBlacklist(false);
    reset();
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({ ...searchParams, mode: 'blacklist', open: 'true', id: rowId });
  };

  const handleStopResize = (columns: ICustomersTableColumns[]) => {
    editColumns({ type: 'customersBlackList', columns });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setBlackListFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleCustomerUnlockClick = (rowId: string, rowData: any) => {
    dispatch(
      openModal({
        title: 'Are you sure want to Unlock?',
        okText: 'Unlock',
        cancelText: 'Cancel',
        onOk: () => {
          deleteAuthorityFromBlackList({ id: rowData?.customerId, authorityId: rowData?.authorityId }).then(
            (data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully unlocked',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              }
            }
          );
          setShowBlacklist(false);
          reset();
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  useEffect(() => {
    return () => {
      setShowBlacklist(false);
      reset();
    };
  }, [activePage]);

  return {
    authoritiesFilter,
    setAuthoritiesFilter,
    getCustomersFilter,
    setCustomersFilter,
    control,
    showBlacklist,
    handleRowClick,
    handleStopResize,
    handleColumnSearch,
    handleCustomerUnlockClick,
    handleSubmit,
    onSubmit,
    handleBlacklistClick,
    handleResetBlacklist,
    watch,
    setValue,
    finalAuthorityOptions,
    finalCustomerOptions,
  };
};

export default useCustomersBlacklistTable;
