import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { SUBJECT } from 'pages/profile/tabs/customers/constants/constants';
import { IAddedMc, ICustomerHookProps } from 'pages/profile/tabs/customers/constants/types';
import {
  useAddPermentTermsMutation,
  useDeletePermentTermsMutation,
  useGetCustomersTemplatesQuery,
  useUpdatePermentTermsMutation,
} from 'services/profile/customers/customers';
import { BillingTypes } from 'services/profile/customers/interfaces';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { scrollToTarget } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

export const useCreateEditCustomersForm = ({
  watch,
  setValue,
  reset,
  authorities,
  resetField,
  refetchAuthoritiesShort,
  setAuthoritiesFilter,
}: ICustomerHookProps) => {
  const dispatch = useDispatch();

  const formValues = watch();

  const [addPermentTerms, { isLoading: isAddPaymentLoading }] = useAddPermentTermsMutation();
  const [updatePermentTerms, { isLoading: isUpdatePaymentLoading }] = useUpdatePermentTermsMutation();
  const [deletePermentTerms, { isLoading: isDeletePaymentLoading }] = useDeletePermentTermsMutation();
  const isPaymentTermsLoading = isAddPaymentLoading || isUpdatePaymentLoading || isDeletePaymentLoading;

  const { data: templateData } = useGetCustomersTemplatesQuery(
    {},
    { skip: formValues?.paymentType !== SUBJECT.CUSTOM && formValues?.paymentType !== SUBJECT.QUICK_PAY }
  );

  const [authorityId, setAuthorityId] = useState<number>();
  const paymentMcRef = useRef(null);

  const image = templateData?.result?.find((item: any) => String(item.value) === watch('templateId'));

  const initialPaymentTermState = {
    QuickPay: {
      day: '',
      percent: '',
      paymentLimit: '',
    },
    Custom: {
      day: '',
      percent: '',
      paymentLimit: '',
    },
    Factoring: {
      paymentLimit: '',
    },
  };

  const [paymentTermState, setPaymentTermState] = useState<typeof initialPaymentTermState>(initialPaymentTermState);

  const { searchParams } = useDetectedParams();
  const { mode, id } = searchParams;

  const defaultValuesCheck = mode === 'edit' ? formValues.addedMc : [];

  const [isEditedRow, setIsEditedRow] = useState(false);
  const [addedMc, setAddedMc] = useState<IAddedMc[]>(defaultValuesCheck);
  const [editingRow, setEditingRow] = useState<number | null>();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const [selectedRow, setSelectedRow] = useState<string | number>('');

  const addBtnDisabledCheck = useMemo(() => {
    if (
      isEmailValid &&
      !!formValues.day?.toString().length &&
      !!formValues.percent?.toString().length &&
      !!formValues.email.length &&
      Number(watch('percent'))! <= 100
    ) {
      if (formValues.paymentType !== SUBJECT.FACTORING && formValues.templateId) {
        return true;
      } else if (formValues.paymentType === SUBJECT.FACTORING) {
        return true;
      }
    }
    return false;
  }, [
    watch('percent'),
    watch('day'),
    isEmailValid,
    formValues.day,
    formValues.percent,
    formValues.email,
    formValues.paymentType,
    formValues.templateId,
  ]);

  const handleResetForm = () => {
    setValue('mc', [], { shouldDirty: false, shouldValidate: false });
    setValue('paymentType', '', { shouldDirty: false, shouldValidate: false });
    setValue('email', [], { shouldDirty: false, shouldValidate: false });
    setValue('templateId', '', { shouldDirty: false, shouldValidate: false });

    setSelectedRow('');
    setIsEditedRow(false);
    setEditingRow(null);

    setPaymentTermState({
      QuickPay: {
        day: '',
        percent: '',
        paymentLimit: '',
      },
      Custom: {
        day: '',
        percent: '',
        paymentLimit: '',
      },
      Factoring: {
        paymentLimit: '',
      },
    });
  };

  useEffect(() => {
    if (watch('mc') && typeof watch('mc') !== 'string') {
      const authority = authorities?.result?.find((el: any) => el.mc === watch('mc')[0]?.mc);
      const authorityId = authority?.id;
      if (authorityId) setAuthorityId(authorityId);
    }
  }, [watch('mc'), authorities]);
  const handleAddMcRow = (data: IAddedMc) => {
    if (addedMc.some(({ mc }) => mc === data?.mc) && !isEditedRow) {
      notification.error({
        message: 'For one MC, you can set up only one payment method',
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    } else {
      if (mode === 'edit') {
        if (!isEditedRow) {
          addPermentTerms({
            id,
            days: data?.day,
            emails: data?.email,
            type:
              data.paymentType === 'Quick pay'
                ? BillingTypes['QuickPay']
                : BillingTypes[data?.paymentType as keyof typeof BillingTypes],
            percent: Number(data?.percent.slice(0, -1)),
            authorityId,
            ...(data?.paymentLimit ? { paymentLimit: Number(data?.paymentLimit) } : {}),
            ...(data?.templateId ? { templateId: data?.templateId } : {}),
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully added',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              resetField('mc', {
                defaultValue: [],
                keepDirty: false,
                keepErrors: false,
                keepDirtyValues: false,
                keepTouched: false,
              });
              resetField('paymentType', {
                defaultValue: '',
                keepDirty: false,
                keepErrors: false,
                keepDirtyValues: false,
                keepTouched: false,
              });
              resetField('templateId', {
                defaultValue: '',
                keepDirty: false,
                keepErrors: false,
                keepDirtyValues: false,
                keepTouched: false,
              });
              setIsEditedRow(false);
              setEditingRow(null);
              refetchAuthoritiesShort();
            }
          });
        } else {
          updatePermentTerms({
            id,
            paymentId: data?.paymentId,
            days: data?.day,
            emails: data?.email,
            type:
              data?.paymentType === 'Quick pay'
                ? BillingTypes['QuickPay']
                : BillingTypes[data?.paymentType as keyof typeof BillingTypes],
            percent: Number(data?.percent.slice(0, -1)),
            authorityId,
            ...(data?.templateId ? { templateId: data?.templateId } : {}),
            ...(data?.paymentLimit ? { paymentLimit: Number(data?.paymentLimit) } : {}),
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully updated',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              setIsEditedRow(false);
              setEditingRow(null);
            }
          });
        }
      }

      if (mode !== 'edit') {
        if (addedMc.some(({ mc }) => mc === selectedRow)) {
          setAddedMc((prev: any) => [
            { ...data, id: authorityId },
            ...prev.filter((item: any) => item.mc !== selectedRow),
          ]);
          setIsEditedRow((prev: boolean) => !prev);
        } else {
          setAddedMc(prev => [
            { ...data, id: authorityId, ...(data?.templateId ? { templateId: data?.templateId } : {}) },
            ...prev,
          ]);
        }
      }
    }

    handleResetForm();
  };

  const handleEditMcRow = (data: IAddedMc) => {
    if (mode === 'edit') {
      setAuthorityId(+data.id!);
    }
    const { name: _, ...paymentTermData } = data;
    const values = {
      ...formValues,
      ...paymentTermData,
      id: authorityId,
      percent: data.percent.slice(0, -1),
      paymentType: data.paymentType,
      mc: [formValues.addedMc.find(el => el.mc === data.mc)],
      email: formValues.addedMc.find(el => el.mc === data.mc)!.email,
      paymentLimit: formValues.addedMc.find(el => el.mc === data.mc)!.paymentLimit,
      templateId: formValues.addedMc.find(el => el.mc === data.mc)!.templateId || '',
    };
    setPaymentTermState({
      ...initialPaymentTermState,
      [data.paymentType]: {
        day: data.day,
        percent: data.percent.slice(0, -1),
        paymentLimit: data.paymentLimit,
      },
    });

    reset(values, { keepDirty: true });
    scrollToTarget(paymentMcRef);

    setSelectedRow(data.mc);
    setIsEditedRow(true);
  };

  const handleDeleteMcRow = (id: number | string) => {
    if (mode !== 'edit') {
      setAddedMc(prev => prev.filter(element => element.mc != id));
    }
    dispatch(closeModal());
    handleResetForm();
  };

  const handleRowDelete = (rowId: string | number, row: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          if (mode === 'edit') {
            deletePermentTerms({ id, paymentId: row?.paymentId }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully deleted',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                handleResetForm();
                refetchAuthoritiesShort();
              }
            });
          } else {
            handleDeleteMcRow(rowId);
            handleResetForm();
          }
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const addedMcIds = useMemo(() => addedMc?.map(el => el.id), [addedMc]);
  useEffect(() => {
    if (mode === 'add') {
      setAuthoritiesFilter((prev: any) => {
        return {
          ...prev,
          ignoreAuthorityIds: addedMcIds,
        };
      });
    }
  }, [mode, addedMcIds?.length]);

  useEffect(() => {
    if (watch('paymentType') === 'Factoring') {
      setValue('day', '30');
      setValue('percent', '0');
      setValue('paymentLimit', paymentTermState.Factoring.paymentLimit);
    }
    if (watch('paymentType') === 'QuickPay') {
      setValue('day', paymentTermState.QuickPay.day);
      setValue('percent', paymentTermState.QuickPay.percent);
      setValue('paymentLimit', paymentTermState.QuickPay.paymentLimit);
    }
    if (watch('paymentType') === 'Custom') {
      setValue('day', paymentTermState.Custom.day);
      setValue('percent', paymentTermState.Custom.percent);
      setValue('paymentLimit', paymentTermState.Custom.paymentLimit);
    }
  }, [watch('paymentType'), watch('mc'), paymentTermState]);

  useEffect(() => {
    setAddedMc(watch('addedMc'));
  }, [watch('addedMc')?.length, isEditedRow]);

  useEffect(() => {
    mode !== 'edit' &&
      setValue('addedMc', addedMc, {
        shouldValidate: true,
        shouldTouch: mode !== 'edit',
        shouldDirty: mode !== 'edit',
      });
  }, [addedMc?.length, isEditedRow]);

  return {
    handleAddMcRow,
    setIsEmailValid,
    handleEditMcRow,
    handleRowDelete,
    setPaymentTermState,
    addBtnDisabledCheck,
    selectedRow,
    templateData,
    image,
    editingRow,
    setEditingRow,
    handleResetForm,
    isEditedRow,
    addedMcIds,
    isPaymentTermsLoading,
    paymentMcRef,
  };
};
