import { createApi } from '@reduxjs/toolkit/query/react';
import { IAgentTable } from 'services/profile/agents/interfaces';
import { baseQuery } from 'services/service';

import { userSinglelevel, usersTableLevels } from './deserializers';
import { IContactByID, IContactParams, IContactTable, IGetContactsParams } from './interfaces';
import { createConact } from './serializers';

export const contactAPI = createApi({
  reducerPath: 'contactAPI',
  baseQuery,
  tagTypes: ['Contact'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    createContact: builder.mutation({
      query: (body: IContactParams) => ({
        url: `users/contact`,
        method: 'POST',
        body: createConact(body),
      }),
      invalidatesTags: ['Contact'],
    }),
    updateContact: builder.mutation({
      query: (body: IContactParams) => ({
        url: `users/contact/${body.id}`,
        method: 'PUT',
        body: createConact(body),
      }),
      invalidatesTags: ['Contact'],
    }),
    getContacts: builder.query({
      query: ({ skip, limit, level, search, field }: Partial<IGetContactsParams>) => ({
        url: 'users/contact',
        params: {
          skip,
          limit,
          ...(level ? { level } : {}),
          ...(search ? { search } : {}),
          ...(field ? { field } : {}),
        },
      }),
      transformResponse: (body: IContactTable) => {
        return usersTableLevels(body);
      },
      providesTags: ['Contact'],
    }),
    getUsers: builder.query({
      query: ({ skip, limit, search }) => ({
        url: 'users/autocomplete',
        params: {
          skip,
          limit,
          search,
        },
      }),
      transformResponse: (body: IAgentTable) => {
        return body;
      },
      providesTags: ['Contact'],
    }),
    getContact: builder.query({
      query: (id: number | string) => {
        return {
          url: `users/contact/${id}`,
        };
      },
      transformResponse: (body: IContactByID) => {
        return userSinglelevel(body);
      },
    }),
    deleteContact: builder.mutation({
      query: (id: number | string) => {
        return {
          method: 'DELETE',
          url: `users/contact/${id}`,
        };
      },
      invalidatesTags: ['Contact'],
    }),
  }),
});

export const {
  useCreateContactMutation,
  useGetContactsQuery,
  useGetUsersQuery,
  useGetContactQuery,
  useDeleteContactMutation,
  useUpdateContactMutation,
} = contactAPI;
