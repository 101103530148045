import { createApi } from '@reduxjs/toolkit/query/react';
import { IAuthorityId } from 'services/profile/carriers/interfaces';

import { baseQuery } from './../service';
import { transformStatesData } from './deserializers';
import { IAutocompleteParams } from './interface';
import { getFactoringCompanyByIdSerializer, requestCarrierSignUpSerializer } from './serializers';

export const carriersOnBoardAPI = createApi({
  reducerPath: 'carriersOnBoardAPI',
  baseQuery,
  tagTypes: ['carriersOnBoard'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getAuthoritiesAutocomplete: builder.query({
      query: ({ search, skip, limit, ignoreAuthorityIds }: Partial<IAutocompleteParams & { token: string }>) => {
        const searchParams = new URLSearchParams();
        search && searchParams.append('search', search);
        searchParams.append('skip', skip!);
        searchParams.append('limit', limit!);
        ignoreAuthorityIds?.length &&
          ignoreAuthorityIds?.forEach((el: any) => searchParams.append('ignoreAuthorityIds[]', el));

        return {
          url: '/carriers-on-board/authority/autocomplete',
          params: searchParams,
        };
      },
      providesTags: ['carriersOnBoard'],
    }),
    getFactoringCompaniesAutocomplete: builder.query({
      query: ({ search, skip, limit }: Partial<IAutocompleteParams & { token: string }>) => ({
        url: '/carriers-on-board/factoring-companies/autocomplete',
        params: { search, skip, limit },
      }),
      providesTags: ['carriersOnBoard'],
    }),
    getStatesForCarrierOnBoard: builder.query({
      query: () => '/carriers-on-board/states',
      providesTags: ['carriersOnBoard'],
      transformResponse: ({ result }) => result && transformStatesData(result),
    }),
    getFactoringCompanyForCarrierOnBoard: builder.query({
      query: id => `/carriers-on-board/factoring-companies/${id}`,
      transformResponse: (body: any) => getFactoringCompanyByIdSerializer(body),
      providesTags: ['carriersOnBoard'],
    }),
    getFilesByAuthorityId: builder.query({
      query: ({ id }: { id: number | IAuthorityId[] | null }) =>
        typeof id === 'number'
          ? `/carriers-on-board/authority/${id}/files`
          : `/carriers-on-board/authority/${id?.[0].id}/files`,
      providesTags: ['carriersOnBoard'],
    }),
    requestCarrierSignUp: builder.mutation({
      query: body => {
        return {
          url: '/carriers-on-board/request',
          method: 'POST',
          body: requestCarrierSignUpSerializer(body),
        };
      },
      invalidatesTags: ['carriersOnBoard'],
    }),
    getAgreements: builder.query({
      query: () => '/carriers-on-board/agreements',
      providesTags: ['carriersOnBoard'],
      transformResponse: (data: any) => {
        const result = Object.values(data);

        return {
          data: result,
        };
      },
    }),
  }),
});

export const {
  useGetAuthoritiesAutocompleteQuery,
  useGetFactoringCompaniesAutocompleteQuery,
  useGetStatesForCarrierOnBoardQuery,
  useGetFactoringCompanyForCarrierOnBoardQuery,
  useGetFilesByAuthorityIdQuery,
  useRequestCarrierSignUpMutation,
  useGetAgreementsQuery,
} = carriersOnBoardAPI;
