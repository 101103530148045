import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Tooltip } from 'antd/lib';
import clsx from 'clsx';
import TruckBoardCard from 'pages/truck-board/components/truck-board-card/TruckBoardCard';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';

import ResetIcon from '../../../../components/svgs/ResetIcon';
import { selectUserPermissionsInfo } from '../../../../store/user-slice/selector';
import { EmployeeLevels, UserTypes } from '../../../../utils/constants';
import { SUBJECT } from '../../constants/constants';

import CustomDoubleRangePicker from './components/custom-double-range-picker/CustomDoubleRangePicker';
import { getFormData, getFormDataNames } from './constants/constants';
import useTruckBoardSearch from './useTruckBoardSearch';

import styles from './TruckBoardSearch.module.scss';

const TruckBoardSearch = ({
  isDefaultCreatePage,
  searchItems,
  isSearchItemsFetching,
  singleSearchItem,
  isSingleSearchItemFetching,
}: any) => {
  const {
    setValue,
    watch,
    handleCreateSearch,
    handleEditSearch,
    handleCreateCancel,
    isEdit,
    isDisabledButton,
    isDisabledButtonDependOnPerm,
    isEditLoading,
    isCreateLoading,
    isLoadingVisible,
    resetAllSearches,
    isFilledAnyBlock,
  } = useTruckBoardSearch({ searchItems, singleSearchItem, isSearchItemsFetching, isSingleSearchItemFetching });

  const { userType, employeeLevel } = useSelector(selectUserPermissionsInfo);

  return (
    <>
      <MainLoader intoElement isFetching={isSearchItemsFetching || isSingleSearchItemFetching} />
      {!isDisabledButtonDependOnPerm && (
        <div className={styles.filterWrapper}>
          <Row align="middle" gutter={25}>
            <Col lg={6} xxl={5}>
              <div className={styles.selectedWrapper}>
                <CustomDoubleRangePicker />
              </div>
            </Col>
          </Row>
        </div>
      )}

      <div
        className={clsx(styles.truckBoardSearchWrapper, {
          [styles.withoutBorder]: isDisabledButtonDependOnPerm,
          [styles.openedLoader]: isLoadingVisible,
        })}
      >
        <div className={clsx(styles.truckBoardCardsContainer)}>
          <div className={styles.truckBoardHeader}>
            <div className={styles.headerLeft}>
              <h2 className={styles.title}>Create Search</h2>
              <div className={styles.fromToInfo}>
                <div className={styles.item}>
                  <Typography variant="paragraph">
                    <span className={clsx(styles.cube, styles.blueCube)} />
                    {SUBJECT.FROM}
                    <span>(left click)</span>
                  </Typography>
                </div>
                <div className={styles.item}>
                  <Typography variant="paragraph">
                    <span className={clsx(styles.cube, styles.greenCube)} />
                    {SUBJECT.TO}
                    <span>(right click)</span>
                  </Typography>
                </div>
                <div className={styles.item}>
                  <Typography variant="paragraph">
                    <span className={clsx(styles.cube, styles.grayCube)} />
                    {SUBJECT.BOTH}
                    <span>(double click)</span>
                  </Typography>
                </div>
                {isFilledAnyBlock && !isDisabledButtonDependOnPerm && (
                  <div className={styles.item}>
                    <Button
                      variant="ghost"
                      onClick={resetAllSearches}
                      icon={<ResetIcon />}
                      children={SUBJECT.RESET_ALL}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.headerRight}>
              {!!searchItems?.summaryTransitCount &&
                (employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN) && (
                  <Typography variant="paragraph">
                    {SUBJECT.TRANSIT}: <span>{searchItems?.summaryTransitCount}</span>
                  </Typography>
                )}
              {!!searchItems?.summaryOnBoardCount && (
                <Typography variant="paragraph">
                  {SUBJECT.ON_BOARD}: <span>{searchItems?.summaryOnBoardCount}</span>
                </Typography>
              )}
              {!!searchItems?.summaryOffBoardCount && (
                <Typography variant="paragraph">
                  {SUBJECT.OF_BOARD}: <span>{searchItems?.summaryOffBoardCount}</span>
                </Typography>
              )}
            </div>
          </div>
          <Row gutter={[13, 15]}>
            {searchItems?.result?.map((el: any) => {
              return (
                <Col xl={12} xxl={8}>
                  <TruckBoardCard
                    watch={watch}
                    setValue={setValue}
                    state={el?.region?.name}
                    onBoardCount={el?.onBoardCount}
                    offBoardCount={el?.offBoardCount}
                    transitCount={el?.transitCount}
                    formData={getFormData(el)}
                    formDataNames={getFormDataNames(el)}
                    isDisabledButtonDependOnPerm={isDisabledButtonDependOnPerm}
                  />
                </Col>
              );
            })}
          </Row>
        </div>

        {!isSearchItemsFetching && !isDisabledButtonDependOnPerm && (
          <div className={styles.searchButton}>
            {!isDefaultCreatePage && <Button onClick={handleCreateCancel} variant="text" children={SUBJECT.CANCEL} />}
            <Tooltip placement="top" color="#fff" title={isDisabledButton ? SUBJECT.AT_LEAST : ''}>
              <span>
                <Button
                  onClick={isEdit ? handleEditSearch : handleCreateSearch}
                  disabled={isDisabledButton}
                  children={isEdit ? SUBJECT.SAVE : SUBJECT.CREATE}
                  loading={isEditLoading || isCreateLoading}
                />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};
export default TruckBoardSearch;
