const AdvanceIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_502_9727)">
        <path
          d="M41.0692 15.177H40.9305V14.9372C40.9305 14.1174 40.6178 13.331 40.0608 12.7499C39.5037 12.1688 38.7476 11.8402 37.9578 11.8359H10.4178C9.62531 11.8359 8.86527 12.1627 8.30489 12.7443C7.74451 13.3259 7.42969 14.1147 7.42969 14.9372V32.3937C7.42969 32.8009 7.50698 33.2042 7.65714 33.5805C7.80731 33.9567 8.02742 34.2986 8.30489 34.5866C8.58236 34.8745 8.91177 35.103 9.2743 35.2588C9.63684 35.4147 10.0254 35.4949 10.4178 35.4949H10.6488V35.6388C10.6488 36.4867 10.9734 37.2999 11.5511 37.8995C12.1288 38.4991 12.9124 38.8359 13.7294 38.8359H41.0692C41.8862 38.8359 42.6697 38.4991 43.2474 37.8995C43.8251 37.2999 44.1497 36.4867 44.1497 35.6388V18.3741C44.1497 17.5262 43.8251 16.713 43.2474 16.1134C42.6697 15.5138 41.8862 15.177 41.0692 15.177ZM8.96996 17.399H39.4057V20.6921H8.96996V17.399ZM10.4178 13.4345H37.9578C38.3418 13.4345 38.7101 13.5928 38.9816 13.8746C39.2531 14.1564 39.4057 14.5386 39.4057 14.9372V15.8004H8.96996V14.9372C8.96996 14.5386 9.1225 14.1564 9.39402 13.8746C9.66555 13.5928 10.0338 13.4345 10.4178 13.4345ZM8.96996 32.3937V22.2906H39.4057V32.3937C39.4057 32.7922 39.2531 33.1744 38.9816 33.4562C38.7101 33.738 38.3418 33.8963 37.9578 33.8963H10.4178C10.0338 33.8963 9.66555 33.738 9.39402 33.4562C9.1225 33.1744 8.96996 32.7922 8.96996 32.3937ZM42.6094 35.5908C42.6094 36.0148 42.4471 36.4214 42.1583 36.7212C41.8694 37.021 41.4777 37.1894 41.0692 37.1894H13.7294C13.3209 37.1894 12.9291 37.021 12.6403 36.7212C12.3514 36.4214 12.1891 36.0148 12.1891 35.5908V35.4949H37.9578C38.3502 35.4949 38.7388 35.4147 39.1013 35.2588C39.4639 35.103 39.7933 34.8745 40.0707 34.5866C40.3482 34.2986 40.5683 33.9567 40.7185 33.5805C40.8686 33.2042 40.9459 32.8009 40.9459 32.3937V16.7755H41.0846C41.4931 16.7755 41.8848 16.944 42.1737 17.2438C42.4625 17.5436 42.6248 17.9502 42.6248 18.3741L42.6094 35.5908Z"
          fill="var(--black)"
        />
        <path
          opacity="0.3"
          d="M30.7037 31.8497C31.0766 31.8591 31.4475 31.7904 31.7942 31.6475C32.1409 31.5047 32.4563 31.2907 32.7214 31.0184C33.1041 31.4408 33.6003 31.7341 34.1456 31.8603C34.6909 31.9864 35.2602 31.9397 35.7795 31.726C36.2989 31.5124 36.7444 31.1417 37.0584 30.6621C37.3723 30.1824 37.5402 29.616 37.5402 29.0362C37.5402 28.4563 37.3723 27.8899 37.0584 27.4103C36.7444 26.9306 36.2989 26.56 35.7795 26.3463C35.2602 26.1327 34.6909 26.0859 34.1456 26.2121C33.6003 26.3382 33.1041 26.6315 32.7214 27.0539C32.4117 26.6947 32.0199 26.4219 31.5822 26.2608C31.1444 26.0996 30.6746 26.0552 30.216 26.1316C29.7574 26.2079 29.3246 26.4027 28.9574 26.6979C28.5902 26.9931 28.3004 27.3794 28.1145 27.8212C27.9286 28.263 27.8527 28.7461 27.8936 29.2263C27.9346 29.7065 28.0911 30.1684 28.3489 30.5694C28.6066 30.9704 28.9573 31.2978 29.3688 31.5215C29.7802 31.7452 30.2392 31.8581 30.7037 31.8497ZM34.7238 27.6774C34.9705 27.6774 35.2117 27.7533 35.4169 27.8956C35.6221 28.0379 35.782 28.2401 35.8764 28.4767C35.9708 28.7133 35.9955 28.9737 35.9474 29.2248C35.8993 29.476 35.7804 29.7067 35.606 29.8878C35.4315 30.0689 35.2092 30.1922 34.9672 30.2422C34.7251 30.2922 34.4743 30.2665 34.2463 30.1685C34.0183 30.0705 33.8235 29.9045 33.6864 29.6916C33.5493 29.4787 33.4761 29.2283 33.4761 28.9722C33.4761 28.6288 33.6076 28.2995 33.8416 28.0566C34.0755 27.8138 34.3929 27.6774 34.7238 27.6774ZM30.7037 27.6774C30.9504 27.6774 31.1916 27.7533 31.3968 27.8956C31.602 28.0379 31.7619 28.2401 31.8563 28.4767C31.9507 28.7133 31.9754 28.9737 31.9273 29.2248C31.8792 29.476 31.7603 29.7067 31.5859 29.8878C31.4114 30.0689 31.1891 30.1922 30.9471 30.2422C30.705 30.2922 30.4542 30.2665 30.2262 30.1685C29.9982 30.0705 29.8034 29.9045 29.6663 29.6916C29.5292 29.4787 29.456 29.2283 29.456 28.9722C29.456 28.6288 29.5875 28.2995 29.8215 28.0566C30.0554 27.8138 30.3728 27.6774 30.7037 27.6774Z"
          fill="var(--black)"
        />
      </g>
      <defs>
        <clipPath id="clip0_502_9727">
          <rect width="36.72" height="27" fill="white" transform="translate(7.42969 11.8359)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdvanceIcon;
