import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useCreateEditCarrierRatingMutation, useDeleteCarrierRatingMutation } from 'services/loads/loads';
import { useLazyGetCarrierRatingQuery } from 'services/profile/carriers/carriers';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from './constants/constants';

export const useBanner = ({ data }: any) => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParams } = useDetectedParams();

  const [ratesParams] = useState({
    skip: 0,
    limit: 50,
  });

  const [createEditCarrierRating] = useCreateEditCarrierRatingMutation();
  const [getCarrierRating, { data: rateList }] = useLazyGetCarrierRatingQuery();
  const [deleteCarrierRating] = useDeleteCarrierRatingMutation();

  const handleCarrierRate = (rating: number | string) => {
    if (data?.load?.carrier?.accessRating) {
      createEditCarrierRating({ carrierId: String(data?.load?.carrier?.id), rating }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully rated',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        }
      });
    }
  };

  const handleDeleteRate = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_DELETE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        onOk: () => {
          deleteCarrierRating({ ratingId: Number(id) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const getCarrierRatings = () => {
    getCarrierRating({ skip: ratesParams.skip, limit: ratesParams.limit, carrierId: Number(data?.load?.carrier?.id) });
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: 'edit',
      isEdit: 'true',
    });
  };

  return {
    rateList,
    handleCarrierRate,
    getCarrierRatings,
    handleDeleteRate,
    handleChangeMode,
  };
};
