import React from 'react';
import { Rate as AntRate } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import StarIcon from 'components/svgs/StarIcon';

import RateDropdown from '../rate-dropdown/RateDropdown';

import { IDisplayingRateProps } from './constants/types';
import { useDisplayingRange } from './useDisplayingRange';

import styles from './DisplayingRate.module.scss';

const DisplayingRate = ({
  rateCount,
  averageValue,
  rateList,
  direction = 'left',
  getRatings,
  canRate,
  setOffset,
  offset,
  handleDeleteRate,
}: IDisplayingRateProps) => {
  const { ref, showDropDown, rateDropDown, countRef, countPositionTop, countPositionLeft } = useDisplayingRange();

  const handleScroll = (e: any) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight && offset) {
      if (rateList.length < offset) {
        setOffset((prev: any) => {
          return { ...prev, limit: prev.limit + 10 };
        });
      }
    }
  };

  return (
    <>
      <AntRate
        className={clsx(styles.rate, { [styles.canRate]: !!canRate })}
        character={<StarIcon />}
        disabled
        value={averageValue}
      />
      <Typography variant="paragraph">({rateCount})</Typography>
      <div
        className={styles.rateDropdownWrapper}
        ref={ref}
        onClick={
          rateCount > 0 && getRatings
            ? e => {
                showDropDown(e);
                getRatings();
              }
            : undefined
        }
      >
        <span
          ref={countRef}
          className={clsx(styles.rateShower, 'rate-shower', {
            [styles.rateShowerNone]: !rateCount,
          })}
        >
          {rateCount}
        </span>
        <RateDropdown
          onScroll={handleScroll}
          rateDropDown={rateDropDown}
          rateList={rateList}
          direction={direction}
          countPositionTop={countPositionTop}
          countPositionLeft={countPositionLeft}
          handleDeleteRate={handleDeleteRate}
        />
      </div>
    </>
  );
};

export default DisplayingRate;
