import React from 'react';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import { IFilterButtons } from './constants/types';

import styles from './FilterButtons.module.scss';

const FilterButtons = ({ text = 'All', value = '', color = '#388DFF', isActive = false, onClick }: IFilterButtons) => {
  return (
    <div
      className={clsx(styles.filterButton, {
        [styles.active]: isActive,
      })}
      onClick={onClick}
    >
      <Typography variant="paragraph">
        <strong>{`${value ? `${text}:` : text}`}</strong>
        {value}
      </Typography>
      <div className={styles.colorPrefix} style={{ backgroundColor: color }} />
    </div>
  );
};

export default FilterButtons;
