const LogoBlack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
      <rect width="96" height="96" rx="4.88136" fill="#252733" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.5217 8.61523H34.4619C34.4619 13.8122 38.6749 18.0252 43.8719 18.0252H65.5217V8.61523ZM65.5214 21.79H53.6846C43.2883 21.79 34.8605 30.2179 34.8605 40.6141V49.5065H44.2824V40.624C44.2824 35.4258 48.4963 31.2119 53.6944 31.2119H65.5214V21.79Z"
        fill="url(#paint0_linear_952_4814)"
      />
      <path
        d="M13.5381 56.8375H20.0515V59.0597H16.0002V63.393H19.1786V65.6152H16.0002V72.393H13.5381V56.8375Z"
        fill="#F0F2F5"
      />
      <path
        d="M23.4705 56.8375H27.1189C28.3873 56.8375 29.3125 57.1338 29.8944 57.7263C30.4764 58.3041 30.7674 59.2004 30.7674 60.4152V61.3708C30.7674 62.9856 30.2302 64.0078 29.1558 64.4375V64.4819C29.7527 64.6597 30.1705 65.0226 30.4092 65.5708C30.6629 66.1189 30.7897 66.8523 30.7897 67.7708V70.5041C30.7897 70.9486 30.8047 71.3115 30.8345 71.593C30.8643 71.8597 30.939 72.1263 31.0583 72.393H28.5514C28.4619 72.1412 28.4022 71.9041 28.3724 71.6819C28.3425 71.4597 28.3276 71.0597 28.3276 70.4819V67.6375C28.3276 66.9263 28.2082 66.43 27.9695 66.1486C27.7457 65.8671 27.3502 65.7263 26.7832 65.7263H25.9326V72.393H23.4705V56.8375ZM26.828 63.5041C27.3204 63.5041 27.686 63.3782 27.9247 63.1263C28.1784 62.8745 28.3052 62.4523 28.3052 61.8597V60.6597C28.3052 60.0967 28.2008 59.6893 27.9919 59.4375C27.7979 59.1856 27.4845 59.0597 27.0518 59.0597H25.9326V63.5041H26.828Z"
        fill="#F0F2F5"
      />
      <path
        d="M34.7363 56.8375H41.4512V59.0597H37.1984V63.1708H40.5783V65.393H37.1984V70.1708H41.4512V72.393H34.7363V56.8375Z"
        fill="#F0F2F5"
      />
      <path d="M45.1059 56.8375H47.568V72.393H45.1059V56.8375Z" fill="#F0F2F5" />
      <path
        d="M55.142 72.6152C53.9483 72.6152 53.038 72.2819 52.4113 71.6152C51.7846 70.9338 51.4712 69.9634 51.4712 68.7041V60.5263C51.4712 59.2671 51.7846 58.3041 52.4113 57.6375C53.038 56.956 53.9483 56.6152 55.142 56.6152C56.3358 56.6152 57.246 56.956 57.8727 57.6375C58.4995 58.3041 58.8128 59.2671 58.8128 60.5263V61.8597H56.485V60.3708C56.485 59.3486 56.0597 58.8375 55.2092 58.8375C54.3586 58.8375 53.9333 59.3486 53.9333 60.3708V68.8819C53.9333 69.8893 54.3586 70.393 55.2092 70.393C56.0597 70.393 56.485 69.8893 56.485 68.8819V65.8375H55.2539V63.6152H58.8128V68.7041C58.8128 69.9634 58.4995 70.9338 57.8727 71.6152C57.246 72.2819 56.3358 72.6152 55.142 72.6152Z"
        fill="#F0F2F5"
      />
      <path
        d="M62.6319 56.8375H65.094V63.1708H67.7352V56.8375H70.1973V72.393H67.7352V65.393H65.094V72.393H62.6319V56.8375Z"
        fill="#F0F2F5"
      />
      <path d="M76.1942 59.0597H73.6202V56.8375H81.2304V59.0597H78.6563V72.393H76.1942V59.0597Z" fill="#F0F2F5" />
      <path
        d="M38.1533 76.3066H40.54L41.6041 84.2346H41.6345L42.6986 76.3066H45.0853V87.3836H43.5043V78.9968H43.4739L42.2578 87.3836H40.8592L39.6431 78.9968H39.6127V87.3836H38.1533V76.3066Z"
        fill="#F0F2F5"
      />
      <path
        d="M47.6253 76.3066H49.8903L51.6233 87.3836H49.9511L49.6471 85.184V85.2157H47.7469L47.4428 87.3836H45.8923L47.6253 76.3066ZM49.4494 83.7124L48.7046 78.2372H48.6742L47.9445 83.7124H49.4494Z"
        fill="#F0F2F5"
      />
      <path
        d="M53.9388 81.7185L52.0994 76.3066H53.8628L54.9877 79.8829H55.0181L56.1734 76.3066H57.7544L55.915 81.7185L57.8456 87.3836H56.0822L54.8661 83.5225H54.8357L53.5892 87.3836H52.0082L53.9388 81.7185Z"
        fill="#F0F2F5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_952_4814"
          x1="49.9918"
          y1="8.61523"
          x2="49.9918"
          y2="49.5066"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4DA9FF" />
          <stop offset="1" stopColor="#37F3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoBlack;
