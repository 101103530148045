import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useResetColumns from 'hooks/useResetColumns';
import { IComponent } from 'pages/profile/constants/constants';
import {
  useExportCarriersRequestSelfMutation,
  useExportTruckBoardMutation,
  useExportVerificationMutation,
} from 'services/exports/exports';
import {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useGetCarrierLastLoadsQuery,
  useGetCarrierQuery,
  useGetCommentsByCarrierIdQuery,
} from 'services/profile/carriers/carriers';
import {
  useCreateRequestCarrierMutation,
  useEditRequestCarrierSelfMutation,
  useGetCarrierRequestListSelfQuery,
  useGetCarrierRequestSelfQuery,
} from 'services/truck-board/carrier-request/carrier-request';
import {
  useGetTruckBoardResultListByIdQuery,
  useGetTruckBoardResultListQuery,
  useGetTruckBoardSearchItemsQuery,
} from 'services/truck-board/truck-board/truckBoard';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { selectIsValid } from 'store/check-is-dirty-slice/selector';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { changeLoading } from 'store/sidebarSlice/sidebar';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { usePermittedActions } from '../../hooks/usePermittedActions';
import { TSkipLimit } from '../loads/tabs/new-load/constants/types';

import CarrierRequest from './components/carrier-request/CarrierRequest';
import CarrierRequestCreateEdit from './components/carrier-request/request-create-edit/CarrierRequestCreateEdit';
import { defaultValues } from './components/carrier-request/request-create-edit/constants/constants';
import { ITruckCarrierRequestFormData } from './components/carrier-request/request-create-edit/constants/types';
import { validation } from './components/carrier-request/request-create-edit/constants/validation';
import TruckCarrierRequestView from './components/carrier-request/truck-carrier-request-view/TruckCarrierRequestView';
import FavoriteTrucks from './components/favorite-trucks/FavoriteTrucks';
import TruckBoardResult from './components/truck-board-result/TruckBoardResult';
import TruckBoardSearch from './components/truck-board-search/TruckBoardSearch';
import VerificationBoard from './components/verification-board/VerificationBoard';
import EditCarrierTruck from './components/view-edit-carrier-truck/EditCarrierTruck';
import ViewCarrierTruck from './components/view-edit-carrier-truck/ViewCarrierTruck';
import { SUBJECT, truckBoardColumns, truckBoardTabColumnsMap } from './constants/constants';
import { ICreateActions, IItems } from './constants/types';

const useTruckBoard = () => {
  const dispatch = useDispatch();
  const isDirtySlice = useSelector(selectIsValid);
  const { permissionsInfo, employeeLevel, userType } = useSelector(selectUserPermissionsInfo);
  const userInfo = useSelector(selectUserInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab, mode, currentPage, isEdit, id, editId, carrierId, headerFilter, status, singleStatus } = searchParams;

  const [carrierRequestSeldfFilter, setCarrierRequestSeldfFilter] = useState<Partial<any>>({
    search: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const [searchResultFilter, setSearchResultFilter] = useState<any>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'availableAtFrom',
    filter: { truckBoardStatus: '1' },
  });

  const [exportTruckBoard] = useExportTruckBoardMutation();
  const [exportVerification] = useExportVerificationMutation();
  const [exportCarrierRequestSelfTable] = useExportCarriersRequestSelfMutation();

  const [excelParams, setExcelParams] = useState<any>();

  const queryParams: any = {
    truck: searchResultFilter,
    single: { ...excelParams, id, status },
    carrier_request: carrierRequestSeldfFilter,
    verification_board: excelParams,
  };

  const downloadExcel = () => {
    const exportFunctionMap: Record<string, any> = {
      truck: exportTruckBoard,
      verification_board: exportVerification,
      carrier_request: exportCarrierRequestSelfTable,
    };

    const exportFunction = tab === 'truck_board' ? exportFunctionMap[currentPage] : exportFunctionMap[tab];

    exportFunction(tab === 'truck_board' ? queryParams?.[currentPage] : queryParams?.[tab]).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.CHECK_EMAIL,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  const [getCommentsFilter, setCommentsFilter] = useState<any>({
    skip: 0,
    limit: 10,
  });

  const inSearchPage = window.location.pathname === '/search-result';

  const columnsType = useMemo(() => {
    if (tab === 'verification_board') {
      return truckBoardColumns.VERIFICATION_BOARD;
    } else if (tab === 'truck_board') {
      if (currentPage === 'truck') {
        return truckBoardColumns.TRUCK_BOARD;
      }
    } else if (tab === 'favorite_trucks') {
      return truckBoardColumns.FAVORITE;
    } else if (tab === 'carrier_request') {
      return truckBoardColumns.CARRIER_REQUEST;
    }
  }, [tab, currentPage, status]);

  const { data: columnsData } = useGetColumnsQuery({ type: columnsType }, { skip: currentPage === 'create' });
  const { data: searchResultColumns } = useGetColumnsQuery(
    { type: truckBoardTabColumnsMap[singleStatus] },
    { skip: !singleStatus }
  );
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const { data: carrierCommentsInfo, isFetching: isCommentFetching } = useGetCommentsByCarrierIdQuery(
    {
      id: carrierId,
      ...getCommentsFilter,
    },
    { skip: !carrierId }
  );

  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const [createRequestCarrier, { isLoading: isCreateLoading }] = useCreateRequestCarrierMutation();
  const [editRequestCarrier, { isLoading: isEditLoading }] = useEditRequestCarrierSelfMutation();

  const [carrierLastLoadsFilter, setCarrierLastLoadsFilter] = useState({
    search: '',
    field: '',
    skip: 0,
    limit: 10,
    order: 2,
    orderBy: 'createdAt',
  });

  const handleCarrierLasLoadsPaginationClick = (skip: number, limit: number) => {
    setCarrierLastLoadsFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const { data: carrierLastLoads, isLoading: isCarrierLastLoadsLoading } = useGetCarrierLastLoadsQuery(
    {
      id: carrierId,
      ...carrierLastLoadsFilter,
    },
    { skip: !carrierId || (inSearchPage && headerFilter !== 'carriers') }
  );
  const { data: searchItems, isFetching: isSearchItemsFetching } = useGetTruckBoardSearchItemsQuery({});

  const { currentData: singleSearchItem, isFetching: isSingleSearchItemFetching } = useGetTruckBoardResultListByIdQuery(
    { id: editId, status },
    {
      skip: !(editId && status) || isEdit !== 'true',
    }
  );

  const { data: carrierRequestTable, isFetching: isCarrierFetching } = useGetCarrierRequestListSelfQuery(
    carrierRequestSeldfFilter,
    { skip: tab !== 'carrier_request' }
  );

  const { currentData: carrierRequestSelfRowInfo, isFetching } = useGetCarrierRequestSelfQuery(
    { id: id },
    { skip: !id || tab !== 'carrier_request' }
  );

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, dirtyFields },
    clearErrors,
    getValues,
    setValue,
    watch,
    reset,
  } = useForm<ITruckCarrierRequestFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      carrierId: '',
    });
    reset(defaultValues, { keepDirty: false });
  };
  const [dragColumns, setDragColumns] = useState<any>([]);

  const openMainDrawer = (mode: string) => {
    setSearchParams({
      ...searchParams,
      mode,
      open: 'true',
    });
  };

  useEffect(() => {
    if (mode === 'customize') {
      if (columnsData?.columns.length) {
        setDragColumns(columnsData?.columns);
      }
    } else {
      if (searchResultColumns?.columns.length) {
        setDragColumns(searchResultColumns?.columns);
      }
    }
  }, [columnsData?.columns?.length, mode, searchResultColumns?.columns?.length]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
    });
    dispatch(closeModal());
    reset(defaultValues);
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: columnsType,
    });

  const {
    isSuccess: isSuccessResultColumns,
    setIsSuccess: setIssSuccessResultColumns,
    defaultColumnsData: defaultResultColumns,
    handleResetToDefault: handleResetToDefaultResultColumns,
    onCustomizeSubmit: onCustomizeResultColumnsSubmit,
    setIsDragged: setIsResultColumnsDragged,
  } = useResetColumns({
    setDragColumns,
    deleteColumns,
    editColumns,
    handleCloseModal,
    dragColumns,
    type: truckBoardTabColumnsMap[singleStatus],
  });

  const handleCancel = () => {
    if (isDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: isValid
            ? () => {
                onSubmit(watch());
                dispatch(closeModal());
              }
            : () => dispatch(closeModal()),
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        open: 'false',
      });
    }
    clearErrors();
  };

  const handleCloseCarrierTruckView = () => {
    setSearchParams({ ...searchParams, open: 'false', carrierId: '', truckId: '' });
  };

  const handleCloseCarrierTruckEdit = () => {
    if (isDirtySlice) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: () => {
            dispatch(closeModal());
          },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({ ...searchParams, open: 'false', carrierId: '' });
    }
  };

  const onSubmit: SubmitHandler<ITruckCarrierRequestFormData> = data => {
    if (mode === 'edit') {
      dispatch(changeLoading(true));
      editRequestCarrier({ id, body: data }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          dispatch(changeLoading(false));
        } else {
          notification.success({
            message: 'Successfully edited',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
          dispatch(changeLoading(false));
        }
      });
    } else {
      dispatch(changeLoading(true));
      createRequestCarrier(data).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          dispatch(changeLoading(false));
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
          dispatch(changeLoading(false));
        }
      });
    }
  };

  const getMoreComments = () => {
    setCommentsFilter((prev: TSkipLimit) => ({ ...prev, limit: prev?.limit + 10 }));
  };
  const { currentData: carrierInfoById, isFetching: isFetchingCarrierInfo } = useGetCarrierQuery(
    { id: carrierId },
    {
      skip:
        !carrierId ||
        mode === 'add' ||
        (mode === 'carrierTruckView' && !carrierId) ||
        (inSearchPage && headerFilter !== 'carriers'),
    }
  );
  const [carrierInfo, setCarrierInfo] = useState<any>(null);

  useEffect(() => {
    setCarrierInfo(carrierInfoById);
  }, [carrierInfoById, mode, carrierId]);

  useEffect(() => {
    if (mode === 'add') {
      setCarrierInfo(null);
    }
  }, [mode]);

  const isShowMoreVisible = useMemo(
    () => carrierCommentsInfo?.count > getCommentsFilter.limit,
    [carrierCommentsInfo, getCommentsFilter]
  );
  const handleCreateComment = (text: string) => {
    createComment({ id: carrierId, message: text }).then((messageData: any) => {
      if (messageData.error) {
        notification.error({
          message: ServerErrorCodes[Number(messageData?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };
  const handleDeleteCommentModal = (commentId: string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        cancelButtonVariant: 'outlined',
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          deleteComment({ id: carrierId, commentId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };
  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    customizeResult: {
      component: (
        <CustomColumn
          columns={searchResultColumns?.columns}
          isDefault={searchResultColumns?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefaultResultColumns}
          isSuccess={isSuccessResultColumns}
          setIsSuccess={setIssSuccessResultColumns}
          defaultColumnsData={defaultResultColumns}
          setIsDragged={setIsResultColumnsDragged}
          onCustomizeSubmit={onCustomizeResultColumnsSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeResultColumnsSubmit,
    },
    carrierTruckView: {
      component: (
        <ViewCarrierTruck
          createComment={handleCreateComment}
          deleteComment={handleDeleteCommentModal}
          carrierCommentsInfo={carrierCommentsInfo}
          carrierInfo={carrierInfo}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          isCommentFetching={isCommentFetching}
          userInfo={userInfo}
          handleCarrierLasLoadsPaginationClick={handleCarrierLasLoadsPaginationClick}
          carrierLastLoads={carrierLastLoads}
          hasDeleteCommentPermission={true}
          isCarrierLastLoadsLoading={isCarrierLastLoadsLoading}
          isFetchingCarrierInfo={isFetchingCarrierInfo}
        />
      ),
      onCancel: handleCloseCarrierTruckView,
    },
    carrierTruckViewFromResult: {
      component: (
        <ViewCarrierTruck
          createComment={handleCreateComment}
          deleteComment={handleDeleteCommentModal}
          carrierCommentsInfo={carrierCommentsInfo}
          carrierInfo={carrierInfo}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          isCommentFetching={isCommentFetching}
          userInfo={userInfo}
          handleCarrierLasLoadsPaginationClick={handleCarrierLasLoadsPaginationClick}
          isCarrierLastLoadsLoading={isCarrierLastLoadsLoading}
          isFetchingCarrierInfo={isFetchingCarrierInfo}
          carrierLastLoads={carrierLastLoads}
          hasDeleteCommentPermission={true}
        />
      ),
      onCancel: handleCloseCarrierTruckView,
    },
    carrierTruckEdit: {
      component: (
        <EditCarrierTruck
          createComment={handleCreateComment}
          deleteComment={handleDeleteCommentModal}
          carrierCommentsInfo={carrierCommentsInfo}
          carrierInfo={carrierInfo}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          isCommentFetching={isCommentFetching}
          userInfo={userInfo}
          handleCarrierLasLoadsPaginationClick={handleCarrierLasLoadsPaginationClick}
          carrierLastLoads={carrierLastLoads}
          hasDeleteCommentPermission={true}
          isCarrierLastLoadsLoading={isCarrierLastLoadsLoading}
          isFetchingCarrierInfo={isFetchingCarrierInfo}
        />
      ),
      onCancel: handleCloseCarrierTruckEdit,
    },
    carrierView: {
      component: <TruckCarrierRequestView data={carrierRequestSelfRowInfo} isLoadingById={isFetching} />,
      onCancel: handleCancel,
    },
    carrierCreate: {
      component: (
        <CarrierRequestCreateEdit
          errors={errors}
          control={control}
          getValues={getValues}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          setValue={setValue}
          watch={watch}
          isEditLoading={isEditLoading}
          isCreateLoading={isCreateLoading}
          dirtyFields={dirtyFields}
          mode={mode}
        />
      ),
      onCancel: handleCancel,
      onSubmit: handleSubmit(onSubmit),
      buttonText: SUBJECT.SEND_REQUEST,
    },
    edit: {
      component: (
        <CarrierRequestCreateEdit
          errors={errors}
          control={control}
          getValues={getValues}
          handleSubmit={handleSubmit}
          setValue={setValue}
          watch={watch}
          onSubmit={onSubmit}
          isEditLoading={isEditLoading}
          isCreateLoading={isCreateLoading}
          dirtyFields={dirtyFields}
          mode={mode}
        />
      ),
      onCancel: handleCancel,
      onSubmit: handleSubmit(onSubmit),
      buttonText: SUBJECT.SEND_REQUEST,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode === 'carrierCreate' || mode === 'edit' || mode === 'customize' || mode === 'customizeResult',
    };
  }, [
    mode,
    isDirty,
    carrierRequestSelfRowInfo,
    watch(),
    isCarrierFetching,
    carrierRequestTable,
    isEditLoading,
    isCreateLoading,
    handleCreateComment,
    isCarrierLastLoadsLoading,
    isFetchingCarrierInfo,
    isDirtySlice,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
    status,
    singleStatus,
    tab,
  ]);

  const openCreatePage = () => {
    setSearchParams({ ...searchParams, open: 'false', currentPage: 'create', isEdit: 'false' });
  };

  const openCreateRequestDrawer = () => {
    setSearchParams({ ...searchParams, open: 'true', mode: 'carrierCreate' });
  };

  const createBtnActions: ICreateActions = {
    truck_board: {
      action: openCreatePage,
      text: SUBJECT.CREATE_SEARCH,
    },
    carrier_request: {
      action: openCreateRequestDrawer,
      text: SUBJECT.CREATE_REQUEST,
    },
  };

  const createBtnContent = useMemo(() => createBtnActions[tab], [tab]);

  useEffect(() => {
    if (tab !== 'truck_board' || currentPage !== 'truck') {
      setSearchResultFilter({
        search: '',
        field: '',
        skip: 0,
        limit: 20,
        order: 2,
        orderBy: 'availableAtFrom',
        filter: { truckBoardStatus: '1' },
      });
    }
    if (tab !== 'carrier_request') {
      setCarrierRequestSeldfFilter({
        search: '',
        skip: 0,
        limit: 20,
        order: 2,
        orderBy: 'createdAt',
        filter: {},
      });
    }
  }, [tab, currentPage]);

  const { data: truckBoardResultList, isFetching: isTruckBoardResultListFetching } = useGetTruckBoardResultListQuery(
    searchResultFilter,
    {
      skip: currentPage !== 'truck',
    }
  );
  const truckBoardPagePermissions = usePermittedActions('truckBoard.truckBoard');
  const verificationBoardPagePermissions = usePermittedActions('truckBoard.verification');
  const tabsWithoutNesting: any = {
    truck_board: truckBoardPagePermissions,
    verification_board: verificationBoardPagePermissions,
  };

  const hasCreateSearchPermission = tabsWithoutNesting?.[tab]?.createSearch;
  const hasDownloadPermission = tabsWithoutNesting?.[tab]?.download;

  const isDefaultCreatePage = useMemo(() => {
    return (
      hasCreateSearchPermission &&
      currentPage === 'truck' &&
      isEdit !== 'true' &&
      !truckBoardResultList?.result?.length &&
      !searchResultFilter?.search?.length &&
      !isTruckBoardResultListFetching
    );
  }, [
    isEdit,
    truckBoardResultList?.result?.length,
    isFetching,
    searchResultFilter?.search?.length,
    isTruckBoardResultListFetching,
    hasCreateSearchPermission,
  ]);

  useEffect(() => {
    if (currentPage !== 'single' && !isTruckBoardResultListFetching && isEdit !== 'true') {
      if (
        isDefaultCreatePage &&
        !truckBoardResultList?.result?.length &&
        !searchResultFilter?.search?.length &&
        !(searchResultFilter.skip > 0)
      ) {
        setSearchParams({ ...searchParams, currentPage: 'create' });
      }
    }
  }, [
    isEdit,
    truckBoardResultList?.result?.length,
    tab,
    isDefaultCreatePage,
    currentPage,
    searchResultFilter?.search?.length,
    isTruckBoardResultListFetching,
  ]);

  useEffect(() => {
    if (!truckBoardResultList?.result.length && searchResultFilter?.skip > 0) {
      setSearchResultFilter((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [truckBoardResultList?.result.length]);

  const truckTableChildren: IComponent = {
    create: (
      <TruckBoardSearch
        isDefaultCreatePage={isDefaultCreatePage}
        searchItems={searchItems}
        isSearchItemsFetching={isSearchItemsFetching}
        isSingleSearchItemFetching={isSingleSearchItemFetching}
        singleSearchItem={singleSearchItem}
      />
    ),
    truck: (
      <TruckBoardResult
        hasDownloadPermission={hasDownloadPermission}
        hasCreateSearchPermission={hasCreateSearchPermission}
        columns={columnsData?.columns}
        truckBoardResultList={truckBoardResultList}
        isTruckBoardResultListFetching={isTruckBoardResultListFetching}
        searchResultFilter={searchResultFilter}
        setSearchResultFilter={setSearchResultFilter}
      />
    ),
  };

  const TableComponent = useMemo(() => {
    return isDefaultCreatePage ? truckTableChildren.create : truckTableChildren[currentPage];
  }, [
    currentPage,
    isDefaultCreatePage,
    isFetching,
    isTruckBoardResultListFetching,
    isSearchItemsFetching,
    isSingleSearchItemFetching,
    singleSearchItem,
    columnsData?.columns,
    truckBoardResultList?.result?.length,
  ]);

  const items: IItems = useMemo(() => {
    return {
      ...(employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN
        ? { verification_board: <VerificationBoard setExcelParams={setExcelParams} /> }
        : {}),
      ...(userType === UserTypes.AGENT ? { favorite_trucks: <FavoriteTrucks /> } : {}),
      truck_board: TableComponent,
      ...(employeeLevel !== EmployeeLevels.CORPORATE && userType !== UserTypes.ADMIN
        ? {
            carrier_request: (
              <CarrierRequest
                data={carrierRequestTable}
                isLoading={isCarrierFetching}
                skip={carrierRequestSeldfFilter.skip}
                limit={carrierRequestSeldfFilter.limit}
                setCarrierRequestSeldfFilter={setCarrierRequestSeldfFilter}
              />
            ),
          }
        : {}),
    };
  }, [
    currentPage,
    employeeLevel,
    userType,
    tab,
    carrierRequestTable,
    isCarrierFetching,
    isDefaultCreatePage,
    columnsData?.columns,
    truckBoardResultList?.result?.length,
    isTruckBoardResultListFetching,
    singleSearchItem,
    isSearchItemsFetching,
    isSingleSearchItemFetching,
  ]);

  const Component = useMemo(() => {
    return items[tab];
  }, [
    tab,
    currentPage,
    carrierRequestTable,
    isCarrierFetching,
    columnsData?.columns,
    isTruckBoardResultListFetching,
    truckBoardResultList?.result?.length,
    isSearchItemsFetching,
    singleSearchItem,
    isSingleSearchItemFetching,
  ]);

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        companyName: carrierRequestSelfRowInfo?.companyName,
        phoneNumber: carrierRequestSelfRowInfo?.phone,
        mc: carrierRequestSelfRowInfo?.mc,
        dot: carrierRequestSelfRowInfo?.dot,
        contactName: carrierRequestSelfRowInfo?.contactName,
      });
    } else {
      reset(defaultValues);
    }
  }, [mode, carrierRequestSelfRowInfo]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  return {
    openMainDrawer,
    downloadExcel,
    setSearchParams,
    currentChildren,
    currentPage,
    Component,
    tab,
    createBtnContent,
    permissionsInfo,
    isDefaultCreatePage,
    hasCreateSearchPermission,
    hasDownloadPermission,
  };
};
export default useTruckBoard;
