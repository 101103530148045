import React from 'react';
import { Col, Row, Spin, Tooltip } from 'antd';
import Button from 'ui/button/Button';

import { TrashIcon } from 'components/svgs/TrashIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { CoordinatorSelect } from '../../../../hoc-instance/instance';

import { SUBJECT } from './constants/constants';
import useAddCoordinator from './useAddCoordinator';

import styles from './AddCoordinator.module.scss';

const AddCoordinator = ({ truckInfoById, isFetchingTruckInfoById }: any) => {
  const {
    handleDeleteCoordinator,
    handleRowClick,
    options,
    coordinatorWatch,
    coordinatorControl,
    coordinatorSetValue,
    setCoordinatorFilters,
    handleAddCoordinator,
    coordinatorSubmit,
    coordinatorErrors,
    coordinatorTrigger,
    verificationPermissions,
  } = useAddCoordinator({ truckInfoById });

  if (!truckInfoById?.coordinatorId && !verificationPermissions?.addCoordinator) {
    return null;
  }

  return (
    <ViewItemWrapper title={SUBJECT.COORDINATOR}>
      {isFetchingTruckInfoById ? (
        <div className={styles.spinWrapper}>
          <Spin />
        </div>
      ) : (
        <div className={styles.addCoordinatorWrapper}>
          {!truckInfoById?.coordinatorId && (
            <div>
              <Row gutter={16}>
                <Col span={19}>
                  <CoordinatorSelect
                    options={options.result}
                    control={coordinatorControl}
                    placeholder="Search Coordinator"
                    name="coordinator"
                    offset={options?.count}
                    setOffset={setCoordinatorFilters}
                    setValue={coordinatorSetValue}
                    showKey="username"
                    value={coordinatorWatch('coordinator')?.[0]?.id}
                    handleClick={handleRowClick}
                    allowClear
                    isFilter
                    errors={coordinatorErrors}
                    onBlur={() => coordinatorTrigger('coordinator')}
                  />
                </Col>
                <Col span={5}>
                  <Tooltip
                    placement="top"
                    color="#fff"
                    title={!coordinatorWatch('coordinator')?.length ? SUBJECT.SELECT_COORDINATOR : ''}
                  >
                    <span>
                      <Button
                        variant="comment"
                        children={SUBJECT.ADD_COORDINATOR}
                        onClick={coordinatorSubmit(handleAddCoordinator)}
                        disabled={!coordinatorWatch('coordinator')?.length}
                      />
                    </span>
                  </Tooltip>
                </Col>
              </Row>
            </div>
          )}

          {!!truckInfoById?.coordinatorId && (
            <div className={styles.coordinatorAdded}>
              <div>
                {coordinatorWatch('coordinator')?.[0]?.id && (
                  <Row align="middle">
                    <Col span={7}>
                      <span>{`${coordinatorWatch('coordinator')?.[0]?.username}`}</span>
                    </Col>
                    {verificationPermissions?.deleteCoordinator && (
                      <Col>
                        <Button
                          icon={<TrashIcon />}
                          variant="text"
                          htmlType="button"
                          onClick={() => handleDeleteCoordinator()}
                        />
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </ViewItemWrapper>
  );
};

export default AddCoordinator;
