export enum ROUTES {
  HOME = '/',
  SIGN_IN = '/sign-in',
  CARRIER_SIGN_UP = '/carrier-sign-in',
  CARRIER_OTP = 'carrierotp',
  PROFILE = '/profile',
  LOADS = '/loads',
  PAYROLL = '/payroll',
  CREDIT_CHECK = '/credit-check',
  CONTACT = '/contact',
  FACTORING = '/factoring',
  TRUCK_BOARD = '/truck-board',
  PERMISSION = '/permission',
  NOT_FOUND = '/404',
  USER_SETTINGS = '/user-settings',
  SEARCH_RESULT = '/search-result',

  BROKER_THANK_YOU = 'broker/thank-you/',
  DISPATCHER_THANK_YOU = 'dispatcher/thank-you/',
  SHIPPER_THANK_YOU = 'shipper/thank-you/',
  FACTORING_COMPANY_THANK_YOU = 'factoring-company/thank-you/',
}

export const allRoutes = [
  'profile',
  'loads',
  'payroll',
  'credit-check',
  'contact',
  'factoring',
  'truck-board',
  'permission',
];

export const ProfileTabs = [
  'authority',
  'office',
  'branch',
  'agent',
  'employee',
  'carrier',
  'customer',
  'factoringCompanies',
];
export const LoadTabs = ['load', 'newLoad'];
export const PayrollTabs = ['payrollInformation', 'payrollPayout'];
export const PaymentSubTabs = ['Agency', 'Branch', 'Agent'];
export const CreditCheckTabs = ['newCredit', 'mcCustomer'];
export const ContactsTabs = ['contact'];
export const FactoringTabs = ['billing', 'payable', 'receivable'];
export const TruckBoardTabs = ['favoriteTrucks', 'truckBoardCoordinator', 'truckBoard', 'truckBoardCarrierRequest'];
export const CarrierSubTabs = ['carrierTable', 'carrierRequest', 'carriersBlackList', 'carrierOnBoard'];
export const CustomerSubTabs = ['customersTable', 'customersBlackList'];
export const EmployeeSubTabs = ['corporate', 'office', 'branch'];
