import { createSlice } from '@reduxjs/toolkit';

import { IActiveSection } from './interfaces';

export const initialState: IActiveSection = {
  activeSection: '',
};

export const activeSectionSlice = createSlice({
  name: 'activeSection',
  initialState,
  reducers: {
    setActiveSection: (state, { payload }) => {
      state.activeSection = payload.activeSection;
    },
  },
});

export const { setActiveSection } = activeSectionSlice.actions;

export default activeSectionSlice.reducer;
