import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { SUBJECT } from 'ui/inputs/select-date-picker/constants/constants';
import { DateOptions } from 'ui/inputs/select-date-picker/constants/types';
import { TDate } from 'utils/types';

export const useCustomDoubleRangePicker = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [inputValue, setInputValue] = useState('All');
  const { searchParams, setSearchParams } = useDetectedParams();
  const {
    availableStartDate: startDate,
    availableEndDate: endDate,
    availableOption: selectedOption,
    isEdit,
    tab,
    currentPage,
  } = searchParams;

  const onRangeChange = (dates: TDate) => {
    const startOfSelectedFromDate = dayjs(dates?.[0])?.startOf('day');
    const endOfSelectedToDate = dayjs(dates?.[1])?.endOf('day');
    if (dates?.length) {
      setInputValue(
        `${dayjs(dates?.[0]?.valueOf()).format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(dates?.[1]?.valueOf()).format(
          SUBJECT.FULL_DATE_FORMAT
        )}`
      );
      setSearchParams({
        ...searchParams,
        availableStartDate: String(startOfSelectedFromDate?.valueOf()),
        availableEndDate: String(endOfSelectedToDate?.valueOf()),
        availableAllDate: '',
        availableOption: String(DateOptions.CUSTOM),
      });
    }
    setIsOpened(false);
  };
  const handleClear = (e: any) => {
    e.stopPropagation();
    setInputValue('All');
    setSearchParams({
      ...searchParams,
      availableStartDate: '',
      availableEndDate: '',
      availableAllDate: 'true',
    });
  };
  const handleOpenCalendar = () => {
    setIsOpened(prev => !prev);
  };

  useEffect(() => {
    if (startDate && endDate && !selectedOption && isEdit === 'true') {
      setInputValue(
        `${dayjs(Number(startDate)).format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(Number(endDate) - 86400000).format(
          SUBJECT.FULL_DATE_FORMAT
        )}`
      );
    } else if (startDate && endDate && selectedOption) {
      setInputValue(
        `${dayjs(Number(startDate)).format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(Number(endDate)).format(
          SUBJECT.FULL_DATE_FORMAT
        )}`
      );
    }
  }, [selectedOption, startDate, endDate]);

  return {
    handleOpenCalendar,
    handleClear,
    onRangeChange,
    inputValue,
    isOpened,
    startDate,
    setIsOpened,
    endDate:
      (startDate &&
        endDate &&
        !selectedOption &&
        isEdit !== 'true' &&
        tab === 'truck_board' &&
        currentPage === 'create') ||
      (startDate && endDate && !selectedOption && isEdit === 'true')
        ? String(Number(endDate) - 86400000)
        : endDate,
  };
};
