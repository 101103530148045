import { createSlice } from '@reduxjs/toolkit';

import { IValidInitial } from './interfaces';

export const initialState: IValidInitial = {
  isValid: false,
};

export const isValidSlice = createSlice({
  name: 'isValid',
  initialState,
  reducers: {
    setIsValid: (state, { payload }) => {
      state.isValid = payload.isValid;
    },
  },
});

export const { setIsValid } = isValidSlice.actions;

export default isValidSlice.reducer;
