import { IModalItems } from './types';

export enum SUBJECT {
  ACTIONS = 'Actions',
}

export const MODAL_ITEMS: IModalItems[] = [
  {
    id: 1,
    itemContent: 'This Will Set The Load Credit And Allow The Agent To Continue With The Load',
    buttonText: 'Approve',
    buttonType: 'primary',
  },
  {
    id: 2,
    itemContent: 'This Will Deny The Credit And The Agent Cant Put In The Load In The System',
    buttonText: 'Deny',
    buttonType: 'danger',
  },
  {
    id: 3,
    itemContent: 'The Credit Will Be Approved Under The Provisions Of COD. Cod Stands For Cash On Delivery',
    buttonText: 'COD',
    buttonType: 'warning',
  },
  {
    id: 4,
    itemContent: 'This Will Approve The Credit Under The Provision Of Consignment',
    buttonText: 'Consignment',
    buttonType: 'default',
  },
];
