import React, { ForwardedRef, forwardRef, useRef, useState } from 'react';
import { RefSelectProps, TreeSelect } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';
import { getHighlightedOptions } from 'utils/helpers';

import CloseIcon from '../../../components/svgs/CloseIcon';

import { IOptions } from './constants/types';

import styles from './LocalSearchSelect.module.scss';

const LocalSearchSelect = forwardRef(
  (
    { options, placeholder, label, allowClear, name, suffixIcon, isDisabled, onChange, value, required, ...props }: any,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const selectRef = useRef<RefSelectProps>(null);
    const currentElement = document.getElementById(`current_local_searchable${label}${placeholder}`);

    const handleSelect = () => {
      currentElement?.blur();
      setIsSearching(false);
      setSearchValue('');
    };

    const { TreeNode } = TreeSelect;

    const dropdownRef = useRef<HTMLInputElement>(null);

    return (
      <div className={styles.selectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          id={`current_local_select_${name}`}
          className={clsx(styles.selectWrapper, {
            [styles.selected]: value,
            [styles.required]: required,
            [styles.withSubChild]: true,
          })}
          ref={dropdownRef}
        >
          <TreeSelect
            id={`current_local_searchable${label}${placeholder}`}
            showSearch
            showArrow
            ref={selectRef || ref}
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            style={{ width: '100%' }}
            getPopupContainer={() => document.getElementById(`current_local_select_${name}`)!}
            placeholder={label ? placeholder : null}
            allowClear={allowClear}
            onClear={() =>
              setTimeout(() => {
                currentElement?.blur();
              }, 0)
            }
            clearIcon={<CloseIcon />}
            value={value}
            suffixIcon={allowClear && (value || searchValue) ? <CloseIcon /> : suffixIcon}
            disabled={isDisabled || false}
            className={clsx(styles.select)}
            treeDefaultExpandAll
            onSearch={value => {
              setSearchValue(value);
              setIsSearching(true);
            }}
            onChange={onChange}
            treeNodeFilterProp="title"
            onSelect={handleSelect}
            filterTreeNode={(search, item: any) => {
              return item.innerValue.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
            {...props}
          >
            {options?.map((el: IOptions) => {
              const highlightedValues = getHighlightedOptions(String(searchValue || ''), String(el.title));
              return (
                <TreeNode
                  value={el.value}
                  innerValue={el?.title}
                  title={
                    <div className={styles.optionsContainer}>
                      {highlightedValues?.length && isSearching ? (
                        <>
                          {highlightedValues[0]}
                          <span className={styles.searchResultKeyword}>{highlightedValues[1]}</span>
                          {highlightedValues[2]}
                        </>
                      ) : (
                        <span>{el.title}</span>
                      )}
                    </div>
                  }
                  key={el?.key}
                  selectable={true}
                />
              );
            })}
          </TreeSelect>
          {!label && (
            <Typography variant="paragraph" className={`placeholder-select ${styles.placeholder}`}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

export default LocalSearchSelect;
