import { IOptions } from './types';

export enum SUBJECT {
  MONEY_TYPE = 'Money Type',
  CHECK_TRANSACTION_ID = 'Check Number Or Transaction ID',
  AMOUNT = 'Amount',
  MONEY_CODE = 'Money Code',
  EFS = 'EFS',
  PAYMENT = 'Payment',
  LOAD = 'Load',
  TOTAL = 'Total',
  COMDATA = 'Comdata',
  TCHECK = 'TCheck',
  CANCEL = 'Cancel',
  RECEIVE = 'Receive',
  TOTAL_BALANCE = 'Total Balance',
  FINAL_RECEVIABLES = 'Final receivables',
}

export enum VALIDATION_MESSAGES {
  MONEY_TYPE_REQUIRED = 'Money Type is a required field',
  TRANSACTION_ID_REQUIRED = 'Transaction ID is a required field',
  AMOUNT_REQUIRED = 'Amount is a required field',
  MONEY_CODE_REQUIRED = 'Money code is a required field',
  POSITIVE = 'Please enter a positive number',
  INTEGER = 'Please enter an integer',
  MIN_NUMBER = 'Number must be greater than or equal to 0',
  VALID_NUMBER = 'Please enter a valid number',
  NOT_ZERO = 'Should be a positive number',
}

export const RECEIVABLES_MODAL_OPTIONS: IOptions[] = [
  {
    title: 'Check Number',
    value: 'Check number',
  },
  {
    title: 'ACH / Wire',
    value: 'ACH / Wire',
  },
  {
    title: 'TCheck',
    value: 'TCheck',
  },
  {
    title: 'EFS',
    value: 'EFS',
  },
  {
    title: 'Comdata',
    value: 'Comdata',
  },
];
