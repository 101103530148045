import { ICollectionValues, IDistributeValues } from 'pages/payroll/tabs/information/constants/types';
import { checkIsNumberDecimal } from 'utils/helpers';

export const createAdvanceDeserializer = (body: ICollectionValues, id?: string) => {
  return {
    ...(id ? { id: Number(id) } : {}),
    ...(body?.agency?.length ? { officeId: body?.agency?.[0]?.id } : {}),
    ...(body?.agent?.length ? { agentId: body?.agent?.[0]?.id } : {}),
    ...(body?.branch?.length ? { branchId: body?.branch?.[0]?.id } : {}),
    type: body?.type,
    amount: Number(body?.amount),
    ...(body?.reason ? { comment: body?.reason } : {}),
  };
};

export const editAdvanceDeserializer = (body: ICollectionValues, id: string) => {
  return {
    id: Number(id),
    type: body?.type,
    amount: Number(body?.amount),
    ...(body?.reason ? { comment: body?.reason } : {}),
  };
};

export const createChargeDeserializer = (body: ICollectionValues) => {
  return {
    ...(body?.agency?.length ? { officeId: body?.agency?.[0]?.id } : {}),
    ...(body?.agent?.length ? { agentId: body?.agent?.[0]?.id } : {}),
    ...(body?.branch?.length ? { branchId: body?.branch?.[0]?.id } : {}),
    type: body?.type,
    amount: Number(body?.amount),
  };
};

export const editChargeDeserializer = (body: ICollectionValues, id: string) => {
  return {
    id: Number(id),
    type: body?.type,
    amount: Number(body?.amount),
  };
};

export const createDistributeDeserializer = (body: IDistributeValues) => {
  return {
    ...(body?.corporate?.percent ? { corporatePercent: checkIsNumberDecimal(Number(body?.corporate?.percent)) } : {}),
    ...(body?.agency?.percent ? { officePercent: checkIsNumberDecimal(Number(body?.agency?.percent)) } : {}),
    ...(body?.branch?.percent ? { branchPercent: checkIsNumberDecimal(Number(body?.branch?.percent)) } : {}),
    agentPercent: checkIsNumberDecimal(Number(body?.agent?.percent)),
  };
};
