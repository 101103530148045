import React from 'react';
import { Col, Divider, Popover, Row, Spin, Tooltip } from 'antd';
import clsx from 'clsx';
import { IAddedMc } from 'pages/profile/tabs/customers/constants/types';
import { CustomerMoneyReceiveType } from 'services/factoring/receivables/interface';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';
import { IAuthorityElement } from 'utils/types';

import CopyIcon from 'components/svgs/CopyIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import { EditPenIcon } from 'components/svgs/EditPenIcon';
import InfoWarningIcon from 'components/svgs/InfoWarningIcon';
import { TrashIcon } from 'components/svgs/TrashIcon';
import TooltipText from 'components/tooltip-text/TooltipText';

import EyeIconSvg from '../../../components/svgs/EyeIconSvg';

import { SUBJECT } from './constants';
import { IDataGridProps, IDataSource } from './types';

import styles from '../DataGrid.module.scss';

export const getAuthoritiesColumns = ({ handleRowDelete, isDeleteDisabled }: Partial<IDataGridProps>) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: (
      <div className={styles.HeaderTitleWithSort}>
        <Typography className={styles.headingCellText} variant="paragraph">
          Type
        </Typography>
      </div>
    ),
    key: 'type',
    dataIndex: 'type',
    render: (type: number | string) => {
      if (typeof type === 'string') {
        return <span>{type}</span>;
      } else {
        return <Typography children={type === 1 ? SUBJECT.CARRIER : SUBJECT.BROKER} variant="paragraph" />;
      }
    },
  },
  {
    title: 'MC Number',
    key: 'mc',
    dataIndex: 'mc',
  },
  {
    title: 'Authority ID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
    width: 60,
    render: (_: any, { id }: IAuthorityElement) => (
      <div className={styles.actionBlock} key={id}>
        {isDeleteDisabled ? (
          <Tooltip title={SUBJECT.DISABLED_AUTHORITIES} placement="top" color="#ffffff">
            <div
              onClick={() => !isDeleteDisabled && handleRowDelete!(Number(id))}
              className={clsx(styles.trashIcon, { [styles.disabledTrashIcon]: isDeleteDisabled })}
            >
              <TrashIcon />
            </div>
          </Tooltip>
        ) : (
          <div
            onClick={() => !isDeleteDisabled && handleRowDelete!(Number(id))}
            className={clsx(styles.trashIcon, { [styles.disabledTrashIcon]: isDeleteDisabled })}
          >
            <TrashIcon />
          </div>
        )}
      </div>
    ),
  },
];

export const getAuthoritiesViewColumns = ({ copiedText, handleCopy }: any) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => {
      return <TooltipText prefixClass="" data={name} subject={`getAuthoritiesViewColumns_${name}`} />;
    },
  },
  {
    title: (
      <div className={styles.HeaderTitleWithSort}>
        <Typography className={styles.headingCellText} variant="paragraph">
          Type
        </Typography>
      </div>
    ),
    key: 'type',
    dataIndex: 'type',
    render: (type: number | string) => {
      if (typeof type === 'string') {
        return <span>{type}</span>;
      } else {
        return <Typography children={type === 1 ? SUBJECT.CARRIER : SUBJECT.BROKER} variant="paragraph" />;
      }
    },
  },
  {
    title: 'MC Number',
    key: 'mc',
    dataIndex: 'mc',
    render: (mc: string) => {
      return <TooltipText prefixClass="" data={mc} subject={`getAuthoritiesViewColumns_${mc}`} />;
    },
  },
  {
    title: 'Authority ID',
    key: 'id',
    dataIndex: 'id',
    render: (record: any) => {
      return (
        <Col span={13}>
          <div
            className={clsx(styles.copyIconText, {
              [styles.copyActive]: copiedText === record,
            })}
          >
            <Typography variant="paragraph" children={record} className={styles.infoParagraph} />
            <div className={styles.copyIcon} role="button" onClick={() => handleCopy(String(record), record)}>
              <CopyIcon active={copiedText === record} />
            </div>
          </div>
        </Col>
      );
    },
  },
];

export const getMcViewColumns = () => [
  {
    title: 'MC',
    dataIndex: 'name',
    key: 'mc',
    width: 150,
  },
  {
    title: 'Payment Method',
    key: 'paymentType',
    dataIndex: 'paymentType',
    width: 200,
  },
  {
    title: 'Day',
    key: 'day',
    dataIndex: 'day',
    width: 100,
  },
  {
    title: 'Percent',
    key: 'percent',
    dataIndex: 'percent',
    width: 110,
  },
  {
    title: 'Credit Limit',
    key: 'paymentLimit',
    dataIndex: 'paymentLimit',
    width: 150,
    render: (_: string, record: any) => (
      <Typography
        variant="paragraph"
        className={styles.emailBlock}
        children={formatNumberWithThousandsSeparatorAndDecimal(record.paymentLimit)}
      />
    ),
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    width: 230,
    render: (_: string, record: IAddedMc) => (
      <Row onClick={e => e.stopPropagation()} key={record.mc}>
        {Array.isArray(record.email) &&
          record.email?.map(
            (el, index) =>
              index < 2 && (
                <Col span={24} key={index}>
                  <Typography variant="paragraph" className={styles.emailBlock}>
                    {el}
                  </Typography>
                  {index !== record.email.length - 1 && <span style={{ marginRight: '2px' }}>,</span>}
                </Col>
              )
          )}
        {Array.isArray(record.email) && record.email.length > 2 && (
          <Popover
            placement="top"
            content={record.email.slice(2)?.map((el, index) => (
              <Col key={index}>
                <Row>
                  <Typography variant="paragraph" className={styles.emailBlock}>
                    {el}
                  </Typography>
                </Row>
              </Col>
            ))}
          >
            <a>{`+${record.email.length - 2}`}</a>
          </Popover>
        )}
      </Row>
    ),
  },
];

export const getMcColumns = ({
  handleRowDelete,
  handleRowEdit,
  isDeleteDisabled,
  setEditingRow,
}: Partial<IDataGridProps>) => {
  if (handleRowEdit) {
    return [
      {
        title: 'MC',
        dataIndex: 'name',
        key: 'mc',
      },
      {
        title: 'Payment Method',
        key: 'paymentType',
        dataIndex: 'paymentType',
      },
      {
        title: 'Day',
        key: 'day',
        dataIndex: 'day',
        width: 80,
      },
      {
        title: 'Percent',
        key: 'percent',
        dataIndex: 'percent',
        width: 100,
      },
      {
        title: 'Credit Limit',
        key: 'paymentLimit',
        dataIndex: 'paymentLimit',
        width: 100,
        render: (_: string, record: any) => (
          <Typography
            variant="paragraph"
            className={styles.emailBlock}
            children={formatNumberWithThousandsSeparatorAndDecimal(record.paymentLimit)}
          />
        ),
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
        width: 300,
        render: (_: string, record: IAddedMc) => (
          <Row onClick={e => e.stopPropagation()} key={record.mc}>
            {Array.isArray(record.email) &&
              record.email?.map(
                (el, index) =>
                  index < 2 && (
                    <Col span={24} key={index}>
                      <Typography variant="paragraph" className={styles.emailBlock}>
                        {el}
                      </Typography>
                      {index !== record.email.length - 1 && <span style={{ marginRight: '2px' }}>,</span>}
                    </Col>
                  )
              )}
            {Array.isArray(record.email) && record.email.length > 2 && (
              <Popover
                placement="top"
                content={record.email.slice(2)?.map((el, index) => (
                  <Col key={index}>
                    <Row>
                      <Typography variant="paragraph" className={styles.emailBlock}>
                        {el}
                      </Typography>
                    </Row>
                  </Col>
                ))}
              >
                <a>{`+${record.email.length - 2}`}</a>
              </Popover>
            )}
          </Row>
        ),
      },
      {
        title: 'Action',
        id: 'action',
        dataIndex: 'action',
        align: 'right',
        width: 100,
        render: (_: any, record: IAddedMc) => (
          <div className={styles.actionBlock} key={record.mc}>
            <div
              onClick={() => {
                setEditingRow(record.id);
                handleRowEdit(record);
              }}
              className={styles.trashIcon}
            >
              <EditPenIcon />
            </div>
            {isDeleteDisabled ? (
              <Tooltip title={SUBJECT.DISABLED_MC} placement="top" color="#ffffff">
                <div
                  onClick={() => !isDeleteDisabled && handleRowDelete!(record.mc, record)}
                  className={clsx(styles.trashIcon, { [styles.disabledTrashIcon]: isDeleteDisabled })}
                >
                  <TrashIcon />
                </div>
              </Tooltip>
            ) : (
              <div
                onClick={() => !isDeleteDisabled && handleRowDelete!(record.mc, record)}
                className={clsx(styles.trashIcon, { [styles.disabledTrashIcon]: isDeleteDisabled })}
              >
                <TrashIcon />
              </div>
            )}
          </div>
        ),
      },
    ];
  }
};

export const getPayablesColumns = ({
  handleDownloadClick,
  handleViewFileClick,
  handlePayBtnClick,
  handleRowClick,
  isDisabledPayButton,
  copiedText,
  handleCopy,
  mode,
}: Partial<IDataGridProps>) => [
  {
    title: 'Reg. Date',
    dataIndex: 'regDate',
    key: 'regDate',
    width: 200,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        return <div className={styles.customTableCell}>{value}</div>;
      }
    },
  },
  {
    title: 'Load ID',
    key: 'code',
    dataIndex: 'code',
    width: 140,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        if (mode === 'view') {
          return (
            <Col span={13} className={styles.customTableCell}>
              <div
                className={clsx(styles.copyIconText, {
                  [styles.copyActive]: copiedText === value,
                })}
              >
                <Typography variant="paragraph" children={value} className={styles.infoParagraph} />
                <div
                  className={styles.copyIcon}
                  role="button"
                  onClick={e => {
                    e.stopPropagation();
                    handleCopy(String(value), value);
                  }}
                >
                  <CopyIcon active={copiedText === value} />
                </div>
              </div>
            </Col>
          );
        } else {
          return <div className={styles.customTableCell}>{value}</div>;
        }
      }
    },
  },
  {
    title: 'Ref. Number',
    key: 'refNumber',
    dataIndex: 'refNumber',
    width: 150,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        if (mode === 'view') {
          return (
            <Col span={13} className={styles.customTableCell}>
              <div
                className={clsx(styles.copyIconText, {
                  [styles.copyActive]: copiedText === value,
                })}
              >
                <Typography variant="paragraph" children={value} className={styles.infoParagraph} />
                <div
                  className={styles.copyIcon}
                  role="button"
                  onClick={e => {
                    e.stopPropagation();
                    handleCopy(String(value), value);
                  }}
                >
                  <CopyIcon active={copiedText === value} />
                </div>
              </div>
            </Col>
          );
        } else {
          return <div className={styles.customTableCell}>{value}</div>;
        }
      }
    },
  },
  {
    title: 'Carrier Balance',
    key: 'carrierBalance',
    dataIndex: 'carrierBalance',
    width: 180,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{formatNumberWithThousandsSeparatorAndDecimal(value, true)}</div>
          </Tooltip>
        );
      } else {
        return (
          <div className={styles.customTableCell}>{formatNumberWithThousandsSeparatorAndDecimal(value, true)}</div>
        );
      }
    },
  },
  {
    title: 'Carrier Payable',
    key: 'carrierPayable',
    dataIndex: 'carrierPayable',
    width: 180,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{formatNumberWithThousandsSeparatorAndDecimal(value, true)}</div>
          </Tooltip>
        );
      } else {
        return (
          <div className={styles.customTableCell}>{formatNumberWithThousandsSeparatorAndDecimal(value, true)}</div>
        );
      }
    },
  },
  {
    title: 'Taken As MC',
    key: 'takenAs',
    dataIndex: 'takenAs',
    width: 150,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        return <div className={styles.customTableCell}>{value}</div>;
      }
    },
  },
  {
    title: 'Sold As MC',
    key: 'soldAs',
    dataIndex: 'soldAs',
    width: 150,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        return <div className={styles.customTableCell}>{value}</div>;
      }
    },
  },
  {
    title: 'Rate Confirmation',
    key: 'invoice',
    dataIndex: 'invoice',
    width: 240,

    render: (value: any, rowData: IDataSource) => {
      if (handleDownloadClick) {
        if (rowData?.claimed && !!rowData?.invoice?.ids?.length) {
          return (
            <Tooltip title="Claimed" color="white" placement="top">
              <div className={styles.customTableCell}>
                <div className={styles.tdContent}>
                  <u>{rowData?.invoice?.date}</u>
                  <Typography
                    variant="paragraph"
                    onClick={() => handleDownloadClick(rowData?.invoice?.ids, rowData?.id)}
                  >
                    <span className={styles.datagridDownolad}>
                      <DownloadIcon />
                    </span>
                  </Typography>
                  <Typography
                    variant="paragraph"
                    onClick={() => handleViewFileClick(rowData?.invoice?.ids, rowData?.id)}
                  >
                    <span className={styles.datagridDownolad}>
                      <EyeIconSvg />
                    </span>
                  </Typography>
                </div>
              </div>
            </Tooltip>
          );
        } else if (rowData?.invoice?.ids?.length) {
          return (
            <div className={styles.customTableCell}>
              <div className={styles.tdContent}>
                <u>{rowData?.invoice?.date}</u>
                <Typography variant="paragraph" onClick={() => handleDownloadClick(rowData?.invoice?.ids, rowData?.id)}>
                  <span className={styles.datagridDownolad}>
                    <DownloadIcon />
                  </span>
                </Typography>
                <Typography variant="paragraph" onClick={() => handleViewFileClick(rowData?.invoice?.ids, rowData?.id)}>
                  <span className={styles.datagridDownolad}>
                    <EyeIconSvg />
                  </span>
                </Typography>
              </div>
            </div>
          );
        } else if (rowData?.claimed && !rowData?.invoice?.ids?.length) {
          return (
            <Tooltip title="Claimed" color="white" placement="top">
              <div className={styles.customTableCell}></div>
            </Tooltip>
          );
        }
      }
    },
  },
  {
    title: 'Breakdown',
    key: 'breakdown',
    dataIndex: 'breakdown',
    width: 240,

    render: (value: any, rowData: IDataSource) => {
      if (handleDownloadClick) {
        if (rowData?.claimed && !!rowData?.breakdown?.ids?.length) {
          return (
            <Tooltip title="Claimed" color="white" placement="top">
              <div className={styles.customTableCell}>
                <div className={styles.tdContent}>
                  <u>{rowData?.invoice?.date}</u>
                  <Typography
                    variant="paragraph"
                    onClick={() => handleDownloadClick(rowData?.breakdown?.ids, rowData?.id)}
                  >
                    <span className={styles.datagridDownolad}>
                      <DownloadIcon />
                    </span>
                  </Typography>

                  <Typography
                    variant="paragraph"
                    onClick={() => handleViewFileClick(rowData?.breakdown?.ids, rowData?.id)}
                  >
                    <span className={styles.datagridDownolad}>
                      <EyeIconSvg />
                    </span>
                  </Typography>
                </div>
              </div>
            </Tooltip>
          );
        } else if (rowData?.breakdown?.ids?.length) {
          return (
            <div className={styles.customTableCell}>
              <div className={styles.tdContent}>
                <u>{rowData?.invoice?.date}</u>
                <Typography
                  variant="paragraph"
                  onClick={() => handleDownloadClick(rowData?.breakdown?.ids, rowData?.id)}
                >
                  <span className={styles.datagridDownolad}>
                    <DownloadIcon />
                  </span>
                </Typography>
                <Typography
                  variant="paragraph"
                  onClick={() => handleViewFileClick(rowData?.breakdown?.ids, rowData?.id)}
                >
                  <span className={styles.datagridDownolad}>
                    <EyeIconSvg />
                  </span>
                </Typography>
              </div>
            </div>
          );
        } else if (rowData?.claimed && !rowData?.breakdown?.ids?.length) {
          return (
            <Tooltip title="Claimed" color="white" placement="top">
              <div className={styles.customTableCell}></div>
            </Tooltip>
          );
        }
      }
    },
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    width: 95,
    render: (status: number, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>
              {rowData?.status !== SUBJECT.CarrierPaid && handlePayBtnClick ? (
                <Button
                  variant="default"
                  disabled={isDisabledPayButton!(rowData?.claimed)}
                  className={styles.payBtnBlock}
                  size="small"
                  onClick={() => handlePayBtnClick(rowData)}
                >
                  {SUBJECT.PAY}
                </Button>
              ) : (
                <Typography variant="paragraph" className={styles.status}>
                  {SUBJECT.PAID}
                </Typography>
              )}
            </div>
          </Tooltip>
        );
      } else {
        return (
          <div className={styles.customTableCell}>
            {rowData?.status !== SUBJECT.CarrierPaid && handlePayBtnClick ? (
              <Tooltip
                placement="top"
                color="#fff"
                title={
                  rowData?.haveUnacceptedTransactions
                    ? "You can't pay the carrier, because the carrier's transactions are in the billing"
                    : ''
                }
              >
                <span>
                  <Button
                    variant="default"
                    disabled={rowData?.haveUnacceptedTransactions || isDisabledPayButton!(rowData?.claimed)}
                    className={styles.payBtnBlock}
                    size="small"
                    onClick={() => handlePayBtnClick(rowData)}
                  >
                    {SUBJECT.PAY}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Typography variant="paragraph" className={styles.status}>
                {SUBJECT.PAID}
              </Typography>
            )}
          </div>
        );
      }
    },
    fixed: 'right',
  },
];

export const getPickupsAndDropOffsColumns = ({
  handleRowDelete,
  handleRowEdit,
  isFirstItemNotDeletable,
  isNotEditable,
  hasEditPickupDropOffPermission,
  withoutActions,
}: Partial<IDataGridProps>) => {
  const tooltipTextSelector = (index: number) => {
    if (isFirstItemNotDeletable && index === 0 && !isNotEditable) {
      return SUBJECT.DISABLED_PICKUP;
    } else if (isNotEditable) {
      if (!hasEditPickupDropOffPermission) {
        return SUBJECT.DISABLED_PICKUP_STATUS_EDIT;
      } else {
        return SUBJECT.DISABLED_PICKUP_STATUS;
      }
    }
  };

  if (withoutActions) {
    return [
      {
        title: 'Date And Time',
        dataIndex: 'dateAndTime',
        key: 'dateAndTime',
        width: 200,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: 100,
      },
      {
        title: 'Zip Code',
        dataIndex: 'zip',
        key: 'zip',
        width: 100,
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        width: 100,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: 100,
      },
      {
        title: 'Facility',
        dataIndex: 'facility',
        key: 'facility',
        width: 100,
      },
      {
        title: 'Added By',
        dataIndex: 'addedBy',
        key: 'addedBy',
        width: 200,
      },
      {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        width: 150,
        render: (_: string, { comment, id }: IDataSource) =>
          !!comment?.length && (
            <Tooltip title={comment} placement="top" color="white" key={id}>
              <div className={styles.commentText}>{comment.length > 16 ? comment?.slice(0, 16) + '...' : comment}</div>
            </Tooltip>
          ),
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        width: 200,
        render: (_: string | number, record: IDataSource) => (
          <a className={styles.phoneLink} href={`tel:${record.phoneNumber}`}>
            {record.phoneNumber}
          </a>
        ),
      },
    ];
  } else {
    return [
      {
        title: 'Date And Time',
        dataIndex: 'dateAndTime',
        key: 'dateAndTime',
        width: 200,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: 100,
      },
      {
        title: 'Zip Code',
        dataIndex: 'zip',
        key: 'zip',
        width: 100,
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        width: 100,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: 100,
      },
      {
        title: 'Facility',
        dataIndex: 'facility',
        key: 'facility',
        width: 100,
      },
      {
        title: 'Added By',
        dataIndex: 'addedBy',
        key: 'addedBy',
        width: 200,
      },
      {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        width: 150,
        render: (_: string, { comment, id }: IDataSource) =>
          !!comment?.length && (
            <Tooltip title={comment} placement="top" color="white" key={id}>
              <div className={styles.commentText}>{comment.length > 16 ? comment?.slice(0, 16) + '...' : comment}</div>
            </Tooltip>
          ),
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        width: 200,
        render: (_: string | number, record: IDataSource) => (
          <a className={styles.phoneLink} href={`tel:${record.phoneNumber}`}>
            {record.phoneNumber}
          </a>
        ),
      },
      {
        title: 'Action',
        id: 'action',
        dataIndex: 'action',
        align: 'right',
        width: 100,
        render: (_: any, record: any, index: number) => {
          return (
            <div className={styles.actionBlock} key={record.id}>
              {isNotEditable ? (
                <Tooltip
                  title={
                    isNotEditable && !hasEditPickupDropOffPermission
                      ? SUBJECT.DISABLED_PICKUP_STATUS_EDIT
                      : SUBJECT.DISABLED_PICKUP_EDIT
                  }
                  placement="top"
                  color="#ffffff"
                >
                  <div
                    onClick={() => {
                      if (isNotEditable) return;

                      handleRowDelete!(record.id, record);
                    }}
                    className={clsx(styles.trashIcon, {
                      [styles.disabledTrashIcon]: isNotEditable,
                    })}
                  >
                    <EditPenIcon />
                  </div>
                </Tooltip>
              ) : (
                <div
                  onClick={() => {
                    if (isNotEditable) return;
                    handleRowEdit && handleRowEdit(record);
                  }}
                  className={clsx(styles.trashIcon, {
                    [styles.disabledTrashIcon]: isNotEditable,
                  })}
                >
                  <EditPenIcon />
                </div>
              )}
              {(isFirstItemNotDeletable && index === 0) || isNotEditable ? (
                <Tooltip title={tooltipTextSelector(index)} placement="top" color="#ffffff">
                  <div
                    onClick={() => {
                      if (isNotEditable) return;

                      isFirstItemNotDeletable && index !== 0 && handleRowDelete!(record.id, record);
                    }}
                    className={clsx(styles.trashIcon, styles.disabledTrashIcon)}
                  >
                    <TrashIcon />
                  </div>
                </Tooltip>
              ) : (
                <div
                  onClick={() => {
                    if (isNotEditable) return;
                    handleRowDelete!(record.id, record);
                  }}
                  className={clsx(styles.trashIcon, {
                    [styles.disabledTrashIcon]: isNotEditable,
                  })}
                >
                  <TrashIcon />
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }
};

export const getReceivableTransactionsColumns = ({
  handleDownloadClick,
  handleTransactionBtnClick,
  handlePaymentDetailsClick,
  handleReceiveBtnClick,
  isDisabledPayButton,
  paymentDetails,
  paymentDetailsLoading,
  handleRowClick,
  copiedText,
  handleCopy,
  mode,
}: Partial<IDataGridProps>) => [
  {
    title: 'Reg. Date',
    dataIndex: 'regDate',
    key: 'regDate',
    width: 200,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        return <div className={styles.customTableCell}>{value}</div>;
      }
    },
  },
  {
    title: 'Load ID',
    key: 'code',
    dataIndex: 'code',
    width: 200,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        if (mode === 'view') {
          return (
            <Col span={13} className={styles.customTableCell}>
              <div
                className={clsx(styles.copyIconText, {
                  [styles.copyActive]: copiedText === value,
                })}
              >
                <Typography variant="paragraph" children={value} className={styles.infoParagraph} />
                <div
                  className={styles.copyIcon}
                  role="button"
                  onClick={e => {
                    e.stopPropagation();
                    handleCopy(String(value), value);
                  }}
                >
                  <CopyIcon active={copiedText === value} />
                </div>
              </div>
            </Col>
          );
        } else {
          return <div className={styles.customTableCell}>{value}</div>;
        }
      }
    },
  },
  {
    title: 'Ref. Number',
    key: 'refNumber',
    dataIndex: 'refNumber',
    width: 200,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        if (mode === 'view') {
          return (
            <Col span={13} className={styles.customTableCell}>
              <div
                className={clsx(styles.copyIconText, {
                  [styles.copyActive]: copiedText === value,
                })}
              >
                <Typography variant="paragraph" children={value} className={styles.infoParagraph} />
                <div
                  className={styles.copyIcon}
                  role="button"
                  onClick={e => {
                    e.stopPropagation();
                    handleCopy(String(value), value);
                  }}
                >
                  <CopyIcon active={copiedText === value} />
                </div>
              </div>
            </Col>
          );
        } else {
          return <div className={styles.customTableCell}>{value}</div>;
        }
      }
    },
  },
  {
    title: 'Customer Balance',
    dataIndex: 'customerBalance',
    key: 'customerBalance',
    width: 200,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>
              <Typography
                variant="paragraph"
                children={`${formatNumberWithThousandsSeparatorAndDecimal(value, true)}`}
              />
            </div>
          </Tooltip>
        );
      } else {
        return (
          <div className={styles.customTableCell}>
            <Typography variant="paragraph" children={`${formatNumberWithThousandsSeparatorAndDecimal(value, true)}`} />
          </div>
        );
      }
    },
  },
  {
    title: 'Customer Receivable',
    dataIndex: 'customerReceivable',
    key: 'customerReceivable',
    width: 200,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>
              <Typography
                variant="paragraph"
                children={`${formatNumberWithThousandsSeparatorAndDecimal(value, true)}`}
              />
            </div>
          </Tooltip>
        );
      } else {
        return (
          <div className={styles.customTableCell}>
            <Typography variant="paragraph" children={`${formatNumberWithThousandsSeparatorAndDecimal(value, true)}`} />
          </div>
        );
      }
    },
  },
  {
    title: 'Customer Dept',
    dataIndex: 'customerDept',
    key: 'customerDept',
    width: 200,
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>
              {value ? (
                <Typography
                  className={styles.deptAmount}
                  variant="paragraph"
                  children={`${formatNumberWithThousandsSeparatorAndDecimal(value, true)}`}
                />
              ) : null}
            </div>
          </Tooltip>
        );
      } else {
        return (
          <div className={styles.customTableCell}>
            {value ? (
              <Typography
                className={styles.deptAmount}
                variant="paragraph"
                children={`${formatNumberWithThousandsSeparatorAndDecimal(value, true)}`}
              />
            ) : null}
          </div>
        );
      }
    },
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
  },
  {
    title: 'Invoice',
    key: 'invoice',
    dataIndex: 'invoice',
    width: 220,
    render: (value: any, rowData: IDataSource) => {
      if (handleDownloadClick) {
        if (rowData?.claimed && !!rowData?.invoice?.ids?.length) {
          return (
            <Tooltip title="Claimed" color="white" placement="top">
              <div className={styles.customTableCell}>
                <div className={styles.tdContent}>
                  <u>{rowData?.invoice?.date}</u>
                  <Typography
                    variant="paragraph"
                    onClick={() => handleDownloadClick(rowData?.invoice?.ids, rowData?.id)}
                  >
                    <span className={styles.datagridDownolad}>
                      <DownloadIcon />
                    </span>
                  </Typography>
                </div>
              </div>
            </Tooltip>
          );
        } else if (rowData?.invoice?.ids?.length) {
          return (
            <div className={styles.customTableCell}>
              <div className={styles.tdContent}>
                <u>{rowData?.invoice?.date}</u>
                <Typography
                  variant="paragraph"
                  onClick={() => {
                    handleDownloadClick(rowData?.invoice?.ids, rowData?.id);
                  }}
                >
                  <span className={styles.datagridDownolad}>
                    <DownloadIcon />
                  </span>
                </Typography>
              </div>
            </div>
          );
        } else if (rowData?.claimed && !rowData?.invoice?.ids?.length) {
          return (
            <Tooltip title="Claimed" color="white" placement="top">
              <div className={styles.customTableCell}></div>
            </Tooltip>
          );
        }
      }
    },
  },
  {
    title: 'Taken As MC',
    key: 'takenAs',
    dataIndex: 'takenAs',
    width: 200,
    onCell: (record: IDataSource) => {
      if (handleRowClick) {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    },
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>{value}</div>
          </Tooltip>
        );
      } else {
        return <div className={styles.customTableCell}>{value}</div>;
      }
    },
  },
  {
    title: 'Transaction',
    key: 'transaction',
    dataIndex: 'transaction',
    width: 200,
    render: (value: any, rowData: IDataSource) => {
      if (rowData?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>
              <Button
                size="middle"
                variant="primary"
                onClick={() => handleTransactionBtnClick && handleTransactionBtnClick(rowData.key)}
              >
                Transaction
              </Button>
            </div>
          </Tooltip>
        );
      } else {
        return (
          <div className={styles.customTableCell}>
            <Button
              size="middle"
              variant="primary"
              onClick={() => handleTransactionBtnClick && handleTransactionBtnClick(rowData.key)}
            >
              Transaction
            </Button>
          </div>
        );
      }
    },
  },
  {
    title: 'Payment',
    key: 'payment',
    dataIndex: 'payment',
    width: 200,
    render: (col: boolean, record: IDataSource) => {
      return col && handlePaymentDetailsClick ? (
        <div className={styles.customTableCell}>
          <div className={styles.tdContent}>
            <Popover
              overlayClassName={styles.popoverOverlay}
              placement="top"
              overlayStyle={{
                width: 360,
                paddingRight: 10,
              }}
              destroyTooltipOnHide
              content={
                paymentDetailsLoading ? (
                  <div className={styles.spinWrapper}>
                    <Spin />
                  </div>
                ) : (
                  paymentDetails?.result?.map((item: any, index: number) => {
                    return (
                      <React.Fragment key={item?.id}>
                        <Row gutter={[20, 20]}>
                          <Col span={11}>
                            <Typography variant="heading" className={styles.popoverInnerTitle}>
                              Money Type
                            </Typography>
                          </Col>
                          <Col span={13}>
                            <Typography variant="paragraph" className={styles.popoverValue}>
                              {CustomerMoneyReceiveType[item?.moneyType]}
                            </Typography>
                          </Col>
                          {item?.moneyCode && (
                            <>
                              <Col span={11}>
                                <Typography variant="heading" className={styles.popoverInnerTitle}>
                                  Money Code
                                </Typography>
                              </Col>
                              <Col span={13}>
                                <Typography variant="paragraph" className={styles.popoverValue}>
                                  {item?.moneyCode}
                                </Typography>
                              </Col>
                            </>
                          )}
                          <Col span={11}>
                            <Typography variant="heading" className={styles.popoverInnerTitle}>
                              Check Number Or Transaction ID
                            </Typography>
                          </Col>
                          <Col span={13}>
                            <Typography variant="paragraph" className={styles.popoverValue}>
                              {item?.numberOrTransactionId}
                            </Typography>
                          </Col>
                          <Col span={11}>
                            <Typography variant="heading" className={styles.popoverInnerTitle}>
                              Amount
                            </Typography>
                          </Col>
                          <Col span={13}>
                            <Typography variant="paragraph" className={styles.popoverValue}>
                              {formatNumberWithThousandsSeparatorAndDecimal(item?.customerFinalAmount, true)}
                            </Typography>
                          </Col>
                          {!!item?.debt && (
                            <>
                              <Col span={11}>
                                <Typography variant="heading" className={styles.popoverInnerTitle}>
                                  Debt
                                </Typography>
                              </Col>
                              <Col span={13}>
                                <Typography
                                  variant="paragraph"
                                  className={clsx(styles.popoverValue, {
                                    [styles.debt]: true,
                                  })}
                                >
                                  {formatNumberWithThousandsSeparatorAndDecimal(item?.debt, true)}
                                </Typography>
                              </Col>
                            </>
                          )}
                          <Col span={11}>
                            <Typography variant="heading" className={styles.popoverInnerTitle}>
                              Received
                            </Typography>
                          </Col>
                          <Col span={13}>
                            <Typography variant="paragraph" className={styles.popoverValue}>
                              {formatNumberWithThousandsSeparatorAndDecimal(item?.received, true)}
                            </Typography>
                          </Col>
                          <Col span={11}>
                            <Typography variant="heading" className={styles.popoverInnerTitle}>
                              Added by
                            </Typography>
                          </Col>
                          <Col span={13}>
                            <Typography variant="paragraph" className={styles.popoverValue}>
                              {`${item?.creator?.username}`}
                            </Typography>
                          </Col>
                          <Col span={11}>
                            <Typography variant="heading" className={styles.popoverInnerTitle}>
                              Date And Time
                            </Typography>
                          </Col>
                          <Col span={13}>
                            <Typography variant="paragraph" className={styles.popoverValue}>
                              {formatDate(item?.createdAt)}
                            </Typography>
                          </Col>
                        </Row>
                        {paymentDetails?.result?.length - 1 !== index && (
                          <Divider className={styles.dividerinPopover} />
                        )}
                      </React.Fragment>
                    );
                  })
                )
              }
            >
              <u className={styles.infoIconText} onMouseEnter={() => handlePaymentDetailsClick(record.id)}>
                Payment Details <InfoWarningIcon />
              </u>
            </Popover>
          </div>
        </div>
      ) : null;
    },
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    width: 140,
    fixed: 'right',
    render: (status: number, record: IDataSource) => {
      if (record?.claimed) {
        return (
          <Tooltip title="Claimed" color="white" placement="top">
            <div className={styles.customTableCell}>
              {record.status !== SUBJECT.CustomerPaid && handleReceiveBtnClick ? (
                <Button
                  variant="default"
                  onClick={() => handleReceiveBtnClick(record)}
                  disabled={isDisabledPayButton!(record.claimed)}
                >
                  Receive
                </Button>
              ) : (
                <Typography variant="paragraph" className={styles.status}>
                  Received
                </Typography>
              )}
            </div>
          </Tooltip>
        );
      } else {
        return record.status !== SUBJECT.CustomerPaid && handleReceiveBtnClick ? (
          <div className={styles.customTableCell}>
            <Button
              variant="default"
              onClick={() => handleReceiveBtnClick(record)}
              disabled={isDisabledPayButton!(record.claimed)}
            >
              Receive
            </Button>
          </div>
        ) : (
          <div className={styles.customTableCell}>
            <Typography variant="paragraph" className={styles.status}>
              Received
            </Typography>
          </div>
        );
      }
    },
  },
];

export const getAddedTrucksColumns = ({
  handleRowEdit,
  handleRowDelete,
  isDeleteDisabled,
  setEditingRow,
}: Partial<IDataGridProps>) => {
  if (handleRowEdit) {
    return [
      {
        title: 'Truck Number',
        dataIndex: 'truckNumber',
        key: 'truckNumber',
      },
      {
        title: 'Year & Make',
        key: 'yearAndMake',
        dataIndex: 'yearAndMake',
      },
      {
        title: 'BoxTruck',
        key: 'boxTruck',
        dataIndex: 'boxTruck',
        render: (_: any, { boxTruck }: any) => {
          return <Typography variant="paragraph">{boxTruck === 1 ? 'Yes' : 'No'}</Typography>;
        },
      },
      {
        title: 'Length',
        key: 'length',
        dataIndex: 'length',
        render: (_: any, { length }: any) => {
          return <Typography variant="paragraph">{length || ''}</Typography>;
        },
      },
      {
        title: 'Action',
        id: 'action',
        dataIndex: 'action',
        align: 'right',
        render: (_: any, record: any) => (
          <div className={styles.actionBlock} key={record.truckId}>
            <div
              onClick={() => {
                setEditingRow(record.truckId);
                handleRowEdit(record);
              }}
              className={styles.trashIcon}
            >
              <EditPenIcon />
            </div>
            {isDeleteDisabled ? (
              <Tooltip title={SUBJECT.DISABLED_TRUCK} placement="top" color="#ffffff">
                <div
                  onClick={() => !isDeleteDisabled && handleRowDelete!(record.truckId, record)}
                  className={clsx(styles.trashIcon, { [styles.disabledTrashIcon]: isDeleteDisabled })}
                >
                  <TrashIcon />
                </div>
              </Tooltip>
            ) : (
              <div
                onClick={() => !isDeleteDisabled && handleRowDelete!(record.truckId, record)}
                className={clsx(styles.trashIcon, { [styles.disabledTrashIcon]: isDeleteDisabled })}
              >
                <TrashIcon />
              </div>
            )}
          </div>
        ),
      },
    ];
  } else {
    return [
      {
        title: 'Truck Number',
        dataIndex: 'truckNumber',
        key: 'truckNumber',
        width: 300,
      },
      {
        title: 'Year & Make',
        key: 'yearAndMake',
        dataIndex: 'yearAndMake',
      },
      {
        title: 'BoxTruck',
        key: 'boxTruck',
        dataIndex: 'boxTruck',
        render: (_: any, { boxTruck }: any) => {
          return <Typography variant="paragraph">{boxTruck === 1 ? 'Yes' : 'No'}</Typography>;
        },
      },
      {
        title: 'Length',
        key: 'length',
        dataIndex: 'length',
        render: (_: any, { length }: any) => {
          return <Typography variant="paragraph">{length || ''}</Typography>;
        },
      },
    ];
  }
};

export const getFuelCardsColumns = ({ isDeleteDisabled, handleRowDelete, handleRowEdit }: any) => {
  return [
    {
      title: 'Fuel Card Number',
      key: 'card',
      dataIndex: 'card',
    },
    {
      title: '',
      key: 'main',
      dataIndex: 'main',
      render: (_: any, record: any) => (
        <Typography variant="paragraph">{record?.main ? 'Main fuel card' : ''}</Typography>
      ),
    },
    {
      title: 'Action',
      id: 'action',
      dataIndex: 'action',
      align: 'right',
      render: (_: any, record: IAddedMc) => (
        <div className={styles.actionBlock} key={record.mc}>
          <div
            onClick={() => {
              handleRowEdit(record);
            }}
            className={styles.trashIcon}
          >
            <EditPenIcon />
          </div>
          {isDeleteDisabled ? (
            <Tooltip title={SUBJECT.DISABLED_FUEL} placement="top" color="#ffffff">
              <div
                onClick={() => !isDeleteDisabled && handleRowDelete!(record)}
                className={clsx(styles.trashIcon, { [styles.disabledTrashIcon]: isDeleteDisabled })}
              >
                <TrashIcon />
              </div>
            </Tooltip>
          ) : (
            <div
              onClick={() => !isDeleteDisabled && handleRowDelete!(record)}
              className={clsx(styles.trashIcon, { [styles.disabledTrashIcon]: isDeleteDisabled })}
            >
              <TrashIcon />
            </div>
          )}
        </div>
      ),
    },
  ];
};
