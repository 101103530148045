import React from 'react';
import clsx from 'clsx';

import styles from './Badge.module.scss';

interface IBadge {
  text: string;
  type: string;
  helperText?: string;
  direction?: string;
  color?: string;
  bgColor?: string;
  onBadgeClick?: any;
}

const Badge = ({ text, type, color, bgColor, direction, helperText, onBadgeClick }: IBadge) => {
  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={clsx(styles.badge, {
        [styles.rightLabel]: direction === 'right',
        [styles.standart]: type === 'standard',
        [styles.widthLoad]: type === 'widthLoad',
        [styles.new]: type === 'new',
        [styles.hoverState]: onBadgeClick,
      })}
    >
      <span
        role="button"
        onClick={onBadgeClick ? onBadgeClick : undefined}
        className={styles.badgeMain}
        style={{ color: color }}
      >
        {text}
      </span>
      {helperText && type !== 'standard' ? <span className={styles.badgeText}>{helperText}</span> : null}
    </div>
  );
};

export default Badge;
