import { phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { LoadTypes } from '../../create-load/constants/types';

import { ITransactionTypes, VALIDATION_MESSAGES } from './constants';

const {
  MIN_OPTION,
  POSITIVE,
  INTEGER,
  MIN_NUMBER,
  MAX_NUMBER,
  VALID_NUMBER,
  PHONE,
  CARRIER_REQUIRED,
  CARRIER_AMOUNT_REQUIRED,
  CUSTOMER_CONTACT_REQUIRED,
  CUSTOMER_AMOUNT_REQUIRED,
  CELSIUS_REQUIRED,
  LOAD_TYPE_REQUIRED,
  CUSTOMER_NAME_REQUIRED,
  REFERENCE_NUMBER_REQUIRED,
  TAKEN_AS_REQUIRED,
  GIVEN_AS_REQUIRED,
  BOOKED_AS_REQUIRED,
  SOLD_AS_REQUIRED,
  FEET_OF_PARTIAL_REQUIRED,
  WEIGHT_REQUIRED,
  DROP_TYPE_REQUIRED,
  ZIP_REQUIRED,
  STATE_REQUIRED,
  CITY_REQUIRED,
  ADDRESS_REQUIRED,
  FACILITY_REQUIRED,
  APPOINTMENT_DATE_REQUIRED,
  APPOINTMENT_TIME_REQUIRED,
  COMMODITY_REQUIRED,
  ADDRESS_MAX_LENGTH,
  MAX_MIN_ZIP_LENGTH,
  CUSTOMER_CONTACT_NAME_REQUIRED,
  ONLY_NOT_ZERO,
  CUSTOMER_WITH_TYPE,
  CUSTOMER_POSITIVE,
  CARRIER_ONLY_NOT_ZERO,
  CARRIER_WITH_TYPE,
  CARRIER_POSITIVE,
  SECOND_ONLY_NOT_ZERO,
  SECOND_POSITIVE,
} = VALIDATION_MESSAGES;

const customValidator = (carrier: string, customer: string, secondAgent: string) =>
  Boolean(carrier?.length || customer?.length || secondAgent?.length);

export const validation = yup.object().shape({
  customerName: yup.string().required(CUSTOMER_NAME_REQUIRED),
  referenceNumber: yup.string().required(REFERENCE_NUMBER_REQUIRED),
  soldUsSwitch: yup.boolean(),
  markAsSwitch: yup.boolean(),
  customerAmount: yup
    .number()
    .typeError(VALID_NUMBER)
    .required(CUSTOMER_AMOUNT_REQUIRED)
    .positive(POSITIVE)
    .integer(INTEGER)
    .min(0, MIN_NUMBER),
  customerContact: yup
    .number()
    .typeError(VALID_NUMBER)
    .required(CUSTOMER_CONTACT_REQUIRED)
    .positive(POSITIVE)
    .integer(INTEGER)
    .min(0, MIN_NUMBER),
  customerContactName: yup.string().required(CUSTOMER_CONTACT_NAME_REQUIRED),
  carrier: yup.array().min(1, MIN_OPTION).required(CARRIER_REQUIRED),
  carrierAmount: yup
    .number()
    .typeError(VALID_NUMBER)
    .required(CARRIER_AMOUNT_REQUIRED)
    .positive(POSITIVE)
    .integer(INTEGER)
    .min(0, MIN_NUMBER)
    .max(yup.ref('customerAmount'), MAX_NUMBER),
  allAuthorities: yup.array().when('soldUsSwitch', (soldUsSwitch, schema) => {
    if (soldUsSwitch) {
      return schema.min(1, 'At least one authority is required');
    } else {
      return schema.nullable();
    }
  }),
  redConfirmation: yup.mixed(),
  transportType: yup.string().min(1, MIN_OPTION).required(LOAD_TYPE_REQUIRED),
  celsius: yup
    .number()
    .typeError(VALID_NUMBER)
    .when('transportType', {
      is: (val: string) => val === 'Refrigerated',
      then: schema => schema.required(CELSIUS_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
  takenAs: yup.string().min(1, MIN_OPTION).required(TAKEN_AS_REQUIRED),
  givenAs: yup.string().min(1, MIN_OPTION).required(GIVEN_AS_REQUIRED),
  commodity: yup.string().required(COMMODITY_REQUIRED),
  bookedAs: yup.string().min(1, MIN_OPTION).required(BOOKED_AS_REQUIRED),
  soldAs: yup.string().min(1, MIN_OPTION).required(SOLD_AS_REQUIRED),
  feetOfPartial: yup
    .number()
    .positive(POSITIVE)
    .typeError(VALID_NUMBER)
    .min(0, MIN_NUMBER)
    .when('soldAs', {
      is: (val: string) => val === 'Partial',
      then: schema => schema.required(FEET_OF_PARTIAL_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
  weight: yup.number().typeError(VALID_NUMBER).required(WEIGHT_REQUIRED).positive(POSITIVE).min(0, MIN_NUMBER),
  dropType: yup.string().min(1, MIN_OPTION).required(DROP_TYPE_REQUIRED),
  phoneNumber: yup.string().notRequired().matches(phoneRegExp, PHONE),
  appointmentDate: yup.string().required(APPOINTMENT_DATE_REQUIRED),
  appointmentTime: yup.string().required(APPOINTMENT_TIME_REQUIRED),
  facility: yup.string().required(FACILITY_REQUIRED),
  address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
  city: yup.string().required(CITY_REQUIRED),
  state: yup.string().min(1, MIN_OPTION).required(STATE_REQUIRED),
  zipcode: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
});

export const transactionValidation = (loadType = 0) => {
  return yup.object().shape({
    transactionType: yup.string().required(VALIDATION_MESSAGES.TRANSACTION_TYPE_REQUIRED),
    customer: yup.string().test('carrierTransaction', '', function (arr1) {
      if (loadType === LoadTypes.Internal && !arr1?.length) {
        return this.createError({
          message: ' Customer amount is a required',
          path: 'customer',
        });
      }
      const isSpecialTypes =
        this.parent.transactionType == ITransactionTypes.Miscellaneous ||
        this.parent.transactionType == ITransactionTypes.Lumper;
      const arr2 = this.parent.carrierTransaction;
      const secondAgent = this.parent.secondAgent;
      if (
        String(arr1).replace('.', '')! == '0' ||
        String(arr1).replace('.', '')! == '-0' ||
        String(arr1).replace('.', '')! == '-'
      ) {
        return this.createError({
          message: isSpecialTypes ? CUSTOMER_POSITIVE : ONLY_NOT_ZERO,
          path: 'customer',
        });
      }
      if (arr1?.length && arr1.includes('-') && isSpecialTypes) {
        return this.createError({
          message: CUSTOMER_WITH_TYPE,
          path: 'customer',
        });
      } else return customValidator(arr1 as string, arr2, secondAgent);
    }),
    carrierTransaction: yup.string().test('customer', '', function (arr2) {
      const isSpecialTypes =
        this.parent.transactionType == ITransactionTypes.Miscellaneous ||
        this.parent.transactionType == ITransactionTypes.Lumper;
      const arr1 = this.parent.customer;
      const secondAgent = this.parent.secondAgent;
      if (
        String(arr2).replace('.', '')! == '0' ||
        String(arr2).replace('.', '')! == '-0' ||
        String(arr2).replace('.', '')! == '-'
      ) {
        return this.createError({
          message: isSpecialTypes ? CARRIER_POSITIVE : CARRIER_ONLY_NOT_ZERO,
          path: 'carrierTransaction',
        });
      }
      if (arr2?.length && arr2.includes('-') && isSpecialTypes) {
        return this.createError({
          message: CARRIER_WITH_TYPE,
          path: 'carrierTransaction',
        });
      } else return customValidator(arr1, arr2 as string, secondAgent);
    }),
    secondAgent: yup.string().test('customer', '', function (value) {
      const isSpecialTypes =
        this.parent.transactionType == ITransactionTypes.Miscellaneous ||
        this.parent.transactionType == ITransactionTypes.Lumper;
      const arr1 = this.parent.customer;
      const arr2 = this.parent.carrierTransaction;
      if (isSpecialTypes) {
        return true;
      }
      if (
        String(value).replace('.', '')! == '0' ||
        String(value).replace('.', '')! == '-0' ||
        String(value).replace('.', '')! == '-'
      ) {
        return this.createError({
          message: isSpecialTypes ? SECOND_POSITIVE : SECOND_ONLY_NOT_ZERO,
          path: 'secondAgent',
        });
      } else return customValidator(arr1, arr2, value as string);
    }),
    transactionComment: yup.string(),
  });
};
