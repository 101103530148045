import React, { useDeferredValue } from 'react';

import Table from 'components/table/Table';

import { ICarriersParams } from '../../constants/types';

import useRequestTableCarrier from './useRequestTableCarrier';

const RequestTableCarrier = ({ setExcelParams, pagePermissions: { approve, deny } }: ICarriersParams) => {
  const {
    data,
    columns,
    isFetching,
    skip,
    limit,
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handleColumnSearch,
    handleCarrierActionClick,
    handlePaginationClick,
  } = useRequestTableCarrier({
    setExcelParams,
    pagePermissions: { approve, deny },
  });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading: isFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onColumnSearch: handleColumnSearch,
    onPaginationClick: handlePaginationClick,
    onActionButton: handleCarrierActionClick,
    hasApprovePermission: approve,
    hasDenyPermission: deny,
    withMenu: false,
  });

  return (
    <>
      <Table {...tableProps} />
    </>
  );
};

export default RequestTableCarrier;
