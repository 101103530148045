import React from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import { ArrowSvg } from 'components/svgs/ArrowSvg';
import EnvelopeIcon from 'components/svgs/EnvelopeIcon';

import { SUBJECT } from '../constants/constants';

import { IEmailProps } from './constants/types';
import useEmailConfirmation from './useEmailConfirmation';

import styles from '../sign-in/SignIn.module.scss';

const EmailConfirmation = ({ handleEmailConfirmation, onSubmit }: IEmailProps) => {
  const { showEmail, activeResend, handleResend, showEmailTime } = useEmailConfirmation(onSubmit);

  return (
    <div className={styles.EmailConfirmation}>
      <div className={styles.arrowIcon}>
        <Button onClick={handleEmailConfirmation} variant="text" shape="default" size="middle" htmlType="button">
          <ArrowSvg />
        </Button>
      </div>
      <Row justify={'center'}>
        <Col span={24}>
          <div>
            <EnvelopeIcon />
          </div>
        </Col>
        <Col span={24}>
          <h2 className={styles.title}>{SUBJECT.EMAIL_TITLE}</h2>
        </Col>
        <Col span={24}>
          <Typography variant="paragraph">
            {showEmail}
            <Button variant="link" htmlType="button" onClick={handleEmailConfirmation}>
              {SUBJECT.CHANGE}
            </Button>
          </Typography>
        </Col>
        <Col span={24}>
          <div className={styles.resendWrapper}>
            <Typography variant="paragraph">
              {SUBJECT.RECEIVE_EMAIL}
              <Button disabled={activeResend} onClick={handleResend} variant="link" size="middle" htmlType="submit">
                {SUBJECT.RESEND}
              </Button>
            </Typography>
          </div>

          {activeResend && <div>{showEmailTime}</div>}
        </Col>
      </Row>
    </div>
  );
};

export default EmailConfirmation;
