export const MC_TYPE_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
];

export const AUTHORITY_KEYS = ['id', 'name', 'mc'];

export const SEARCH_CARRIER_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'value', name: 'ID Number' },
];

export const CARRIER_KEYS = ['name', 'value'];

export const AGENT_COLUMNS = [
  { key: 'firstName', name: 'Name' },
  { key: 'lastName', name: 'Last Name' },
  { key: 'username', name: 'Username' },
];

export const AGENT_KEYS = ['id', 'firstName', 'lastName', 'username'];

export const PAYABLES_PAYMENT_METHOD = [
  {
    title: 'Quick Pay',
    value: '1',
  },
  {
    title: 'Factored',
    value: '2',
  },
];

export const filterDefaultValues = {
  loadRegDateFilter: null,
  paidDate: null,
  payablesMc: [],
  payablesCarrier: [],
  payablesCarrierType: null,
  payablesOffice: [],
  payablesBranch: [],
  payablesAgent: [],
  carrierPaymentMethod: null,
  authorityIds: [],
};

export enum SUBJECT {
  YES = 'Yes',
  NO = 'No',
  ARE_YOU_SURE_RECEIVE = 'Are You Sure Want To Receive Invoice ?',
  CARRIER_TYPE = 'Carrier Type',
  PAYABLES_IS_EMPTY = 'Payables Is Empty',
  TOTAL = 'Total:',
  SAVE = 'Save',
  RESET_ALL = 'Reset All',
  SEARCH = 'Search',
  PAYMENT_METHOD = 'Carrier Payment Method',
  MC = 'MC',
  CARRIER = 'Carrier',
  AGENT = 'Agent',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  NOT_SELECTED = 'Not Selected',
  PAID_DATE = 'Paid Date',
  LOAD_REG_DATE = 'Approved Date',
  EXPECTED = 'Expected',
  LOAD = 'Load:',
  QUICK_PAY = 'QuickPay %:',
  CUSTOM = 'Custom %:',
  MUST_PAY = 'Pending',
  PAID = 'Paid',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  DOWNLOADING_IN_PROCCESS = 'Downloading In Proccess',
}
