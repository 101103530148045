import React, { useDeferredValue } from 'react';
import ActionsModal from 'ui/actions-modal/ActionsModal';
import Typography from 'ui/typography/Typography';

import MainDrawer from 'components/main-drawer/MainDrawer';
import Table from 'components/table/Table';

import { SUBJECT } from './constants/constants';
import UseNewCredit from './useNewCredit';

import styles from './NewCredit.module.scss';

const NewCredit = ({ setExcelParams }: any) => {
  const {
    isFetching,
    data,
    columns,
    isActionModalOpen,
    handleClose,
    handleSortClick,
    handleActionStatus,
    showActionModal,
    handleRowClick,
    handlePaginationClick,
    handleCommentClick,
    handleColumnSearch,
    currentChildren: { component, withFooter, buttonText, onCancel, onSubmit },
    handleStopResize,
    skip,
    limit,
    pagePermissions: { approve, deny, cod, consignment },
  } = UseNewCredit(setExcelParams);

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading: isFetching,
    withMenu: false,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onCommentClick: handleCommentClick,
    onPaginationClick: handlePaginationClick,
    onActionButton: showActionModal,
    onColumnSearch: handleColumnSearch,
    hasCreditActionsPermissions: approve || deny || cod || consignment,
  });

  return (
    <>
      {data ? (
        <div className="page-content">
          <Table {...tableProps} />
          <MainDrawer
            Children={component}
            buttonText={buttonText}
            withFooter={withFooter}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
          <ActionsModal
            handleActionStatus={handleActionStatus}
            isModalOpen={isActionModalOpen}
            handleClose={handleClose}
            actionPermissions={{ approve, deny, cod, consignment }}
          />
        </div>
      ) : (
        <div>
          <Typography className={styles.newCreditEmpty} variant="paragraph">
            {SUBJECT.CREDIT_CHECK_IS_EMPTY}
          </Typography>
        </div>
      )}
    </>
  );
};
export default NewCredit;
