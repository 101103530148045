import React from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from '../../constants/constants';

import { useCustomerBlackListView } from './useCustomerBlackListView';

import styles from './CustomersBlacklistView.module.scss';

const CustomersBlacklistView = ({ data, isFetchingBlackListById, hasUnlockPermission }: any) => {
  const { handleCustomerUnlockClick } = useCustomerBlackListView();

  return (
    <>
      <MainLoader isFetching={isFetchingBlackListById} />

      <ViewItemWrapper
        title={SUBJECT.BLACKLIST}
        Component={
          hasUnlockPermission && (
            <Button
              children={SUBJECT.UNLOCK}
              onClick={() => handleCustomerUnlockClick(data?.result?.id, data?.result)}
              className="viewDrawerEditBtn"
            />
          )
        }
      >
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE_AND_TIME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.result?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CUSTOMER_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.result?.customer?.name}
                  subject={`${SUBJECT.BLACKLIST}${SUBJECT.CUSTOMER_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AUTHORITY_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.result?.authority?.name}
                  subject={`${SUBJECT.BLACKLIST}${SUBJECT.AUTHORITY_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={`${data?.result?.creator?.username} `}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </>
  );
};

export default CustomersBlacklistView;
