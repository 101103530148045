import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { selectAuth, selectUserPermissionsInfo } from 'store/user-slice/selector';
import WarningModal from 'ui/warning-modal/WarningModal';
import { getFilteredPagesByPermissions } from 'utils/permissions';
import { ls } from 'utils/storage';

import { MainLoader } from '../../ui/main-loader/MainLoader';
import AuthLayout from '../auth-layout/AuthLayout';
import MainLayout from '../main-layout/MainLayout';
import NotFound from '../not-found/NotFound';

import { getPublicRoutes } from './constants/constants';
import { ROUTES } from './routes';

const Router = ({ sendAgentsActivityWatchMessage }: any) => {
  const publicRoutes = getPublicRoutes();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const hashValue = urlParams.get('hash');

  const token = useSelector(selectAuth);
  const pathname = window.location.search;

  const { permissionsInfo, agentType, userType, isFetching } = useSelector(selectUserPermissionsInfo);
  const privateRoutes: any = getFilteredPagesByPermissions(userType, agentType, permissionsInfo?.routes);

  const isAuth = useMemo(() => {
    if (window.location.pathname === ROUTES.CARRIER_SIGN_UP && hashValue) {
      ls.set('carriersOnBoardHash', hashValue);
      return false;
    } else {
      ls.remove('carriersOnBoardHash');
    }
    if (pathname.includes('hash=')) {
      ls.remove('token');
      ls.remove('refreshToken');
    }
    return ls.get('token');
  }, [ls.get('token'), token, pathname]);

  return (
    <BrowserRouter>
      <WarningModal />
      <Routes>
        <Route path="">
          <Route path="*" element={<NotFound />} />
          {publicRoutes?.map(({ path, Component }) => {
            console.log(path, 'path');
            return (
              <Route
                key={path}
                path={path}
                element={
                  isAuth ? (
                    <Navigate to={ROUTES.HOME} replace />
                  ) : path === ROUTES.CARRIER_SIGN_UP ||
                    path === ROUTES.CARRIER_OTP ||
                    path === ROUTES.BROKER_THANK_YOU ||
                    path === ROUTES.SHIPPER_THANK_YOU ||
                    path === ROUTES.DISPATCHER_THANK_YOU ||
                    path === ROUTES.FACTORING_COMPANY_THANK_YOU ? (
                    <Component />
                  ) : (
                    <AuthLayout>
                      <Component />
                    </AuthLayout>
                  )
                }
              />
            );
          })}
          {privateRoutes?.map(({ path, Component, children = [] }: any) => {
            if (!children.length) {
              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    isAuth ? (
                      <MainLayout>
                        {isFetching ? (
                          <MainLoader isFetching={isFetching} isLarge />
                        ) : (
                          <Component sendAgentsActivityWatchMessage={sendAgentsActivityWatchMessage} />
                        )}
                      </MainLayout>
                    ) : (
                      <Navigate to={ROUTES.SIGN_IN} replace />
                    )
                  }
                />
              );
            } else {
              return (
                <Route path={path} key={path}>
                  <Route
                    path=""
                    element={
                      <MainLayout>
                        <MainLayout>
                          {isFetching ? <MainLoader isFetching={isFetching} isLarge /> : <Component />}
                        </MainLayout>
                      </MainLayout>
                    }
                  />
                  {children?.map(({ path, Component }: any) => {
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          isAuth ? (
                            <MainLayout>
                              <MainLayout>
                                {isFetching ? <MainLoader isFetching={isFetching} isLarge /> : <Component />}
                              </MainLayout>
                            </MainLayout>
                          ) : (
                            <Navigate to={ROUTES.SIGN_IN} replace />
                          )
                        }
                      />
                    );
                  })}
                </Route>
              );
            }
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
