export enum SUBJECT {
  ADD_COORDINATOR = 'Add Coordinator',
  ADDED = 'Added',
  WILL_BE_REPLACED = 'Selected Coordinator Will Be rReplaced',
  REPLACE = 'Replace',
  CANCEL = 'Cancel',
  COORDINATOR = 'Coordinator',
  ARE_YOU_SURE = 'Are You Sure Want Do Delete?',
  DELETE = 'Delete',
  SELECT_COORDINATOR = 'Please Select Coordinator',
}

export const MOCK_ADDED_COORDINATOR = {
  name: 'Houston',
  id: '2',
};
