import { ICarriersFilter, ICarriersFormData, ISelectOptions } from './types';

export const defaultValues: ICarriersFormData = {
  company: '',
  paymentTerms: [],
  primaryPhone: null,
  secondaryPhone: null,
  numberOfTrucks: '',
  numberOfTrailers: '',
  fax: null,
  contact: '',
  type: '',
  MC: null,
  email: '',
  dot: null,
  stateNumber: null,
  ff: null,
  address: '',
  secondAddress: '',
  city: '',
  state: '',
  zip: null,
  fuelCard: '',
  isMainFuelCard: false,
  fuelCards: [],
  carrierPacketFile: [],
  companyOwnerIdFile: [],
  signAgreementFile: [],
  paymentPaidAs: '',
  paidAsW9File: [],
  insuranceFile: [],
  voidedCheckBankLatterFile: [],
  Liability: '',
  Cargo: '',
  truckNumber: '',
  yearAndMake: '',
  boxTruck: 2,
  length: '',
  truckId: '',
  trucks: [],
  comments: [],
  cargoCoverageValue: '',
  nonOwnedCoverage: '',
  trailerInterchange: '',
  reeferBreakdown: false,
  physical: false,
  physicalAddress: '',
  physicalSecondAddress: '',
  physicalCity: '',
  physicalState: '',
  physicalZip: '',
  equipmentType: [],
};

export const filterDefaultValues: ICarriersFilter = {
  carrierType: null,
  ratings: [],
  insuranceDays: null,
};

export const columnsTypes: { [key: string]: string } = {
  table: 'carriers',
  request: 'carrierRequest',
  blacklist: 'carrierBlackList',
  carrierOnBoard: 'carrierOnBoard',
};

export const ALL_FILTERS_SELECTED = ['5', '4', '3', '2', '1', '0'];

export enum SUBJECT {
  FILL_ALL_CARRIER_INFORMATION = 'New Carrier Application',
  SUCCESSFULLY_DELETED = 'Successfully Deleted',
  NUMBER_OF_TRUCK = 'Number Of Trucks',
  NUMBER_OF_TRAILER = 'Number Of Trailers',
  SUCCESSFULLY_REMOVED = 'Successfully Removed',
  SUCCESSFULLY_ADDED = 'Successfully Added To Favorite',
  PERCENTAGE = '3.5',
  ACTIONS = 'Actions',
  ACTIONS_TEXT = 'After Approving You Need To Fill All Required Fields',
  REVIEW = 'Review',
  APPROVE = 'Approve',
  DENY = 'Deny',
  SEARCH = 'Search',
  RESET_ALL = 'Reset All',
  CARRIERS_LIST_IS_EMPTY = 'Carriers List Is Empty',
  CARRIERS_REQUEST_LIST_IS_EMPTY = 'Request From Agent List Is Empty',
  CARRIERS_REQUEST_LIST_IS_EMPTY_CARRIER = 'Request From Carrier List Is Empty',
  BLACK_LIST_IS_EMPTY = 'Black List Is Empty',
  ADD_BLACK_LIST = 'Add Blacklist',
  CARRIERS_TABLE = 'Carrier Table',
  CARRIERS_REQUEST_AGENT = 'Request From Agent',
  CARRIERS_REQUEST_CARRIER = 'Request From Carrier',
  CARRIERS_BLACK_LIST = 'Blacklist',
  TYPE = 'Type',
  CARRIER_TYPE = 'Carrier Type',
  RATING = 'Rating',
  NOT_SELECTED = 'Not Selected',
  ADD = 'Add Carrier',
  CLOSE = 'Close',
  SAVE = 'Save',
  SAVE_CHANGES = 'Save Changes',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  CREATE_CARRIER = 'Add New Carrier',
  GENERAL_INFO = 'General Information',
  CARRIER_INFO = 'Carrier info',
  PAID_AS = 'Paid As',
  COMPANY_NAME = 'Company Name',
  PRIMARY_PHONE_NUMBER = 'Phone Number',
  SECONDARY_PHONE_NUMBER = 'Second Number',
  FAX = 'Fax',
  CONTACT_NAME = 'Contact Name',
  CARRIERS_TYPE = 'Carrier Type',
  MC = 'MC',
  DOT = 'DOT',
  FF_NUMBER = 'FF Number',
  STATE_NUMBER = 'State Number',
  ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIP_CODE = 'Zip Code',
  FUEL_CARD = 'Fuel Card',
  COMPANY_NAME_COLON = 'Company Name:',
  CARRIER_ID_COLON = 'Carrier ID:',
  ARE_YOU_SURE_DELETE = 'Are You Sure Want To Delete Rate?',
  ARE_YOU_SURE_REMOVE = 'You Are About To Deactivate A Favorite Item. Please Be Aware That Deactivating This Item Will Also Deactivate All Items Connected To It. This Action Cannot Be Undone.',
  REMOVE = 'Remove',
  MC_COLON = 'MC:',
  CONTACT_NAME_COLON = 'Contact Name:',
  CARRIER_TYPE_COLON = 'Carrier Type:',
  FACTORED_COLON = 'Factored:',
  PHONE_NUMBER_COLON = 'Phone Number:',
  FAX_COLON = 'Fax:',
  RATING_COLON = 'Rating:',
  SECOND_NUMBER_COLON = 'Second Number:',
  PAYMENT_COLON = 'Payment Term:',
  DAY_COLON = 'Day:',
  PERCENT_COLON = 'Percent:',
  COORDINATOR_COLON = 'Coordinator:',
  NO = 'No',
  STAY_HERE = 'Stay here',
  YES = 'Yes',
  CHECKED_ACTIVATE = 'If  Activated Carrier Can Be Used Again',
  CHECKED_DEACTIVATE = 'If Terminated Carrier  Can’t Be  Used Anymore',
  GENERAL_INFO_ID = 'general-information',
  FACTORING_ID = 'factoring',
  DOCUMENTATION_ID = 'documentation',
  TRUCK_ID = 'truck',
  PAYMENT_METHOD = 'Payment Method',
  PAYMENT_METHOD_ID = 'payment_method',
  LAST_LOADS = 'last-Loads',
  LAST_LOADS_ID = 'last-loads',
  CARRIER_PAYMENT_BREAKDOWN_ID = 'carrier-payment-breakdown',
  LAST_LOADS_TITLE = 'Last loads',
  COMMENT = 'comment',
  PAPER_CHECK = 'Pay per check',
  DIRECT_DEPOSIT = 'Direct deposit',
  DELETE = 'Delete',
  ARE_YOU_SURE = 'Are You Sure Want To Delete?',
  DOCUMENTATION = 'Documents',
  UPLOAD_TEXT = 'Drag And Drop Or',
  LIABILITY = 'Liability Expiration Date',
  CARGO = 'Cargo Expiration Date',
  ADD_TRUCK = 'Equipment',
  TRUCK = 'Truck',
  TRUCK_NUMBER = 'Truck Number',
  TRAILER_NUMBER = 'Trailer Number',
  TRAILER_TYPE = 'Trailer Type',
  LENGTH = 'Length',
  TRUCK_ADD_BTN = 'Add Truck',
  FACTORED_RADIO = 'factored',
  NOT_FACTORED_RADIO = 'notFactored',
  INSURANCE_LIABILITY = 'Insurance Liability Exp:',
  INSURANCE_CARGO = 'Insurance Cargo Exp:',
  RESET = 'Reset',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',
  CARRIER_ACTIVE_ID = 'carrier-top-banner',
  CARGO_COVERAGE_VALUE = 'Cargo Coverage Value',
  NON_OWNED_COVERAGE = 'NON Owned Coverage',
  FUEL_CARD_ID = 'fuel-card',
  EDIT_FUEL_CARD = 'Edit Fuel Card',
  ADD_FUEL_CARD = 'Fuel Card',
  ADD_PHYSICAL_ADDRESS = 'Add Physical Address',
  EMAIL_ADDRESS = 'Email address',
  EMAIL = 'Email',
  QUICK_PAY_TOOLTIP = 'If Your Carrier Is Factored, You Can`t Select Quick Pay Payment Term',
  CANCEL = 'Cancel',
  CARRIER_PAYMENT_BREAKDOWN = 'Carrier Payment Breakdown',
  DOWNLOAD_ALL = 'Download All',
  EDIT_CARRIER = 'Edit Carrier',
  YEAR_AND_MAKE = 'Year & make',
  BOX_TRUCK = 'Box Truck',
  TRAILER_INTERCHANGE = 'Trailer Interchange',
  REEFER_BREAKDOWN = 'Reefer Breakdown',
  CAN_HAUL = 'Can Haul',
  EQUIPMENTS = 'Equipments',
}

export enum VALIDATION_MESSAGES {
  PAYMENT_TYPE_REQUIRED_FOR_ONE = 'Payment method is required field for one of the payment terms',
  TRUCK_REQUIRED = 'Truck is required field',
  MIN_OPTION = 'At least one option is required',
  POSITIVE = 'Please enter a positive number',
  INTEGER = 'Please enter an integer',
  MIN_NUMBER = 'Number must be greater than or equal to 0',
  VALID_NUMBER = 'Please enter a valid number',
  PHONE = 'Incorrect phone format',
  FORMAT = 'Sorry, we don`t  recognise this email address.',
  CONTACT = 'Contact can only contain alphabetical characters',
  COMPANY_REQUIRED = 'Company is a required field',
  PRIMARY_PHONE_REQUIRED = 'Phone number is a required field',
  CONTACT_REQUIRED = 'Name is a required field',
  TYPE_REQUIRED = 'Type is a required field',
  MC_NUMBER_REQUIRED = 'MC number is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  CITY_REQUIRED = 'City is a required field',
  PAID_AS_REQUIRED = 'Paid as is required field',

  STATE_REQUIRED = 'State is a required field',
  ZIP_REQUIRED = 'Zip Code is a required field',
  INVALID_EMAIL = 'Invalid email format',
  EMAIL_IS_REQUIRED = 'Email is required field',
  DOT_REQUIRED = 'DOT is required field',
  TRUCK_NUMBER_REQUIRED = 'Truck number is required field',
  TRUCKS_REQUIRED = 'You have to add at least one truck',
  TRAILER_NUMBER_REQUIRED = 'Trailer number is required',
  LENGTH_REQUIRED = 'Length is required field',
  TRAILER_TYPE_REQUIRED = 'Trailer type is required field',
  FUEL_CARD_REQUIRED = 'Fuel card is required field',
  FUEL_CARD_MIN = 'Minimum of 16 digits allowed',
  FUEL_CARD_MAX = 'Maximum of 24 digits allowed',
  MAX_LENGTH = 'Maximum of 10 digits allowed',
  CARRIER_PACKET_REQUIRED = 'Carrier packet is required field',
  SIGN_AGREEMENT_REQUIRED = 'Sign agreement is required field',
  PAID_W9_REQUIRED = 'Paid as W9 is required field',
  INSURANCE_REQUIRED = 'Insurance is required field',
  VOIDED_CHECK_REQUIRED = 'Voided check/bank letter is required field',
  LIABILITY_REQUIRED = 'Liability is required field',
  CARGO_REQUIRED = 'Cargo is required field',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
  NOT_ZERO = 'Should be a positive number',
  INCORRECT_FAX = 'Incorrect fax format',
  LESS_THAN_BILLION = 'Amount must be less than a billion',
}

export const TYPE_FILTER_OPTIONS: ISelectOptions[] = [
  { title: 'Owner Operator', value: '1' },
  { title: 'Outside', value: '2' },
];

export const CARRIER_OPTIONS: ISelectOptions[] = [
  { title: 'Owner Operator', value: '1' },
  { title: 'Outside', value: '2' },
];
