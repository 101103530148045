import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { ProfileAllSubTabsReversed } from 'pages/profile/constants/constants';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';

import { CarrierSubTabs, CustomerSubTabs, EmployeeSubTabs, PaymentSubTabs } from '../routes/routes';

export const useTabPanel = () => {
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab, headerFilter } = searchParams;

  //carriers
  const uniqueVisibleCarrierSubTabs: string[] = Array.from(new Set(permissionsInfo?.allPossibleSubTabs));
  const firstAvailableCarrierSubTab = CarrierSubTabs.find(el => uniqueVisibleCarrierSubTabs.includes(el));

  //customers
  const uniqueVisibleCustomerSubTabs: string[] = Array.from(new Set(permissionsInfo?.allPossibleSubTabs));
  const firstAvailableCustomerSubTab = CustomerSubTabs.find(el => uniqueVisibleCustomerSubTabs.includes(el));

  //payment
  const uniqueVisiblePaymentSubTabs: string[] = Array.from(
    new Set(
      permissionsInfo?.permissions?.payroll?.payrollPayout?.visibleSubTabs ||
        permissionsInfo?.permissions?.payroll?.payment?.visibleSubTabs
    )
  );
  const firstAvailablePaymentSubTab = PaymentSubTabs.find(el => uniqueVisiblePaymentSubTabs.includes(el));

  const handleChangeTab = (tabName: string) => {
    if (tab === tabName) return;
    setSearchParams({
      headerFilter: headerFilter,
      tab: tabName,
      ...(tabName === 'employees' ? { activePage: EmployeeSubTabs[permissionsInfo.roleLevel - 1] } : {}),
      ...(tabName === 'carriers'
        ? {
            activePage:
              ProfileAllSubTabsReversed[firstAvailableCarrierSubTab as keyof typeof ProfileAllSubTabsReversed],
          }
        : {}),
      ...(tabName === 'customers'
        ? {
            activePage:
              ProfileAllSubTabsReversed[firstAvailableCustomerSubTab as keyof typeof ProfileAllSubTabsReversed],
          }
        : {}),
      ...(tabName === 'truck_board' ? { currentPage: 'truck' } : {}),
      ...(tabName === 'payout'
        ? {
            activeTab: firstAvailablePaymentSubTab,
            startDate: String(dayjs().startOf('month').subtract(1, 'month').valueOf()),
          }
        : {}),
    });
  };

  return {
    handleChangeTab,
    tab,
  };
};
