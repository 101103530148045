import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Col, RefSelectProps, Row, Select } from 'antd';
import clsx from 'clsx';
import useDebounce from 'hooks/useDebounce';
import Typography from 'ui/typography/Typography';
import { getHighlightedOptions } from 'utils/helpers';
import { v4 as uuid } from 'uuid';

import CloseIcon from 'components/svgs/CloseIcon';
import SearchIcon from 'components/svgs/SearchIcon';

import { COLUMNS, SUBJECT } from '../header-search/constants/constants';
import { IMainSearchProps } from '../header-search/constants/types';

import styles from './MainSearchSelect.module.scss';

const MainSearchSelect = forwardRef<HTMLDivElement, IMainSearchProps>(
  (
    {
      options,
      placeholder,
      onChange,
      handleRowClick,
      data,
      name,
      disabled = false,
      value,
      errors,
      offset,
      setOffset,
      required,
      setValue,
      headerFilter,
      label,
      isFetching,
      ...props
    },
    ref
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const selectRef = useRef<RefSelectProps>(null);
    const debounceValue = useDebounce(searchValue, 500);
    const [isFocused, setIsFocused] = useState(false);
    const handleSearch = (value: string) => {
      setIsSearching(true);
      setSearchValue(value);
      if (onChange) {
        onChange(value);
      }
    };
    const handleChange = (value: string) => {
      setIsSearching(false);
      if (setValue && name) {
        setValue(name, value);
      }
    };
    useEffect(() => {
      if (isSearching && typeof searchValue === 'string') {
        setOffset((prev: any) => ({ ...prev, search: debounceValue, limit: 10 }));
      }
    }, [debounceValue]);
    const handleScroll = (event: any) => {
      const { target } = event;
      if (target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight) {
        if (options?.length < offset) {
          setOffset((prev: any) => {
            return { ...prev, limit: prev.limit + 10 };
          });
        }
      }
    };
    const handleBlur = () => {
      setValue!('header_input', '');
      selectRef?.current?.blur();
      setIsFocused(false);
    };
    useEffect(() => {
      if (!searchValue?.length && onChange && data) {
        onChange(data?.map((item: any) => item?.id));
      }
    }, [searchValue]);
    useEffect(() => {
      setSearchValue(value);
    }, [value]);

    const columnsWidth = useMemo<Record<string, string>>(() => {
      return {
        loads: '33%',
        carriers: '20%',
        customers: '33%',
      };
    }, [headerFilter]);

    return (
      <div className={styles.searchableSelectMainWrapper} ref={ref}>
        {!!label && <label>{label}</label>}
        <div
          className={clsx(styles.selectWrapper, {
            [styles.required]: required,
            [styles.errors]: errors && name && errors[name],
            [styles.disabled]: disabled,
          })}
          id={`select_searchable${label}${placeholder}`}
        >
          <Select
            {...props}
            disabled={disabled}
            ref={selectRef}
            showArrow
            allowClear={!!searchValue.length}
            clearIcon={
              searchValue.length ? (
                <div className={styles.clearIcon}>
                  <CloseIcon />
                </div>
              ) : null
            }
            onClear={() => setSearchValue('')}
            showSearch
            suffixIcon={
              searchValue?.length ? (
                <div className={styles.clearIcon}>
                  <CloseIcon />
                </div>
              ) : (
                <SearchIcon />
              )
            }
            className={clsx(styles.selectMarkingWrapper)}
            onChange={handleChange}
            onSearch={handleSearch}
            placeholder={placeholder}
            popupClassName={styles.optionsContainer}
            onFocus={() => {
              setIsFocused(true);
            }}
            value={value}
            getPopupContainer={() => document.getElementById(`select_searchable${label}${placeholder}`)!}
            onPopupScroll={handleScroll}
            onBlur={handleBlur}
            open={Boolean(debounceValue.length)}
            dropdownRender={() =>
              value &&
              !isFetching && (
                <>
                  {options?.length ? (
                    <div key={COLUMNS[headerFilter]} className={styles.headerItems}>
                      <Row>
                        {COLUMNS[headerFilter].map((column: string) => {
                          return (
                            <Col key={uuid()} style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                              <span>{column}</span>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  ) : (
                    <Typography variant="paragraph" className={styles.noDataText}>
                      {SUBJECT.NO_DATA}
                    </Typography>
                  )}
                  <div className={styles.mainSearchItesWrapper}>
                    {options?.map(item => {
                      const matchedName = getHighlightedOptions(searchValue!, item.name);
                      const matchedId = getHighlightedOptions(searchValue!, item.id);
                      const matchedLoadId = getHighlightedOptions(searchValue!, item.code);
                      const matchedPhone = getHighlightedOptions(searchValue!, item.phone);
                      const matchedDot = item.dot ? getHighlightedOptions(searchValue!, item.dot) : [];
                      const matchedMC = item.mc ? getHighlightedOptions(searchValue!, item.mc) : [];
                      const matchedReference = item.referenceNumber
                        ? getHighlightedOptions(searchValue!, item.referenceNumber)
                        : [];
                      return (
                        <div key={item.id}>
                          <div
                            role="button"
                            className={styles.authoritiesContainer}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              handleRowClick({ value: String(item.name), id: String(item.id), type: item?.type });
                              handleBlur();
                            }}
                          >
                            <Row>
                              {headerFilter === 'customers' ? (
                                <>
                                  {matchedName.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedName[0]}
                                      <span className={styles.searchResultKeyword}>{matchedName[1]}</span>
                                      {matchedName[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item.name}</span>
                                    </Col>
                                  )}
                                  {matchedId.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedId[0]}
                                      <span className={styles.searchResultKeyword}>{matchedId[1]}</span>
                                      {matchedId[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item.id}</span>
                                    </Col>
                                  )}
                                  {matchedPhone.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedPhone[0]}
                                      <span className={styles.searchResultKeyword}>{matchedPhone[1]}</span>
                                      {matchedPhone[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item.phone}</span>
                                    </Col>
                                  )}
                                </>
                              ) : null}
                              {headerFilter === 'carriers' ? (
                                <>
                                  {matchedName.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedName[0]}
                                      <span className={styles.searchResultKeyword}>{matchedName[1]}</span>
                                      {matchedName[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item.name}</span>
                                    </Col>
                                  )}
                                  {matchedId.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedId[0]}
                                      <span className={styles.searchResultKeyword}>{matchedId[1]}</span>
                                      {matchedId[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item.id}</span>
                                    </Col>
                                  )}
                                  {matchedPhone.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedPhone[0]}
                                      <span className={styles.searchResultKeyword}>{matchedPhone[1]}</span>
                                      {matchedPhone[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item.phone}</span>
                                    </Col>
                                  )}
                                  {matchedDot.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedDot[0]}
                                      <span className={styles.searchResultKeyword}>{matchedDot[1]}</span>
                                      {matchedDot[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item?.dot}</span>
                                    </Col>
                                  )}
                                  {matchedMC.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedMC[0]}
                                      <span className={styles.searchResultKeyword}>{matchedMC[1]}</span>
                                      {matchedMC[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item?.mc}</span>
                                    </Col>
                                  )}
                                </>
                              ) : null}
                              {headerFilter === 'loads' ? (
                                <>
                                  {matchedLoadId.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedLoadId[0]}
                                      <span className={styles.searchResultKeyword}>{matchedLoadId[1]}</span>
                                      {matchedLoadId[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item?.code}</span>
                                    </Col>
                                  )}
                                  {matchedReference.length ? (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      {matchedReference[0]}
                                      <span className={styles.searchResultKeyword}>{matchedReference[1]}</span>
                                      {matchedReference[2]}
                                    </Col>
                                  ) : (
                                    <Col style={{ padding: '0 5px', width: columnsWidth[headerFilter] }}>
                                      <span>{item?.referenceNumber}</span>
                                    </Col>
                                  )}
                                </>
                              ) : null}
                            </Row>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )
            }
          />
          {!value && !isFocused && (
            <Typography variant="paragraph" className={clsx(styles.placeholder)}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);
export default MainSearchSelect;
