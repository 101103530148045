import React from 'react';
import { Col, Popover, Row } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';

import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';

import { SUBJECT } from './constants/constants';

import styles from './RedConfirmationViewFiles.module.scss';

const RedConfimrationViewFiles = ({ fileItem, handleViewFile, handleDownloadFileClick }: any) => {
  return (
    <div className={styles.fileItemWrapper} key={fileItem.id}>
      <Row
        gutter={10}
        className={clsx({
          [styles.crossing]: !fileItem?.mailable,
        })}
      >
        <Col span={6}>
          <Typography variant="heading" children="Rate Confirmation" className={styles.infoHeading} />
        </Col>
        <Col span={8}>
          <Typography variant="paragraph" children={fileItem?.file?.name} className={styles.infoParagraph} />
        </Col>
        <Col span={5}>
          <Popover
            overlayClassName={styles.popoverOverlay}
            placement="top"
            overlayStyle={{
              width: 480,
              paddingRight: 10,
            }}
            content={
              <>
                <Row gutter={[20, 24]}>
                  <Col span={7}>
                    <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                  </Col>
                  <Col span={17}>
                    <Typography
                      variant="paragraph"
                      className={styles.infoParagraph}
                      children={`${fileItem?.creator?.username}`}
                    />
                  </Col>
                  <Col span={7}>
                    <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
                  </Col>
                  <Col span={17}>
                    <Typography
                      variant="paragraph"
                      className={styles.infoParagraph}
                      children={formatDate(fileItem?.updatedAt)}
                    />
                  </Col>
                </Row>
              </>
            }
          >
            <Typography
              variant="paragraph"
              children="View more"
              className={clsx(styles.infoParagraph, styles.popoverTitle)}
            />
          </Popover>
        </Col>
        <Col span={5}>
          <div className={styles.actionsWrapper}>
            <button type="button" onClick={() => handleViewFile(fileItem.file.preview)}>
              <EyeIconSvg outlined={true} />
            </button>
            <button type="button" onClick={() => handleDownloadFileClick(fileItem.file.download)}>
              <NoaDownloadIcon />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RedConfimrationViewFiles;
