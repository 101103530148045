import { ReactNode } from 'react';
import { formatDate } from 'utils/dates';

import { CreatorTypes, links } from '../constants';
import { EMessageTypes, Handler, ISocketMessage } from '../interfaces';
import Notification from '../notification/Notification';

export class CustomerHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      create: this.handleCreate,
      edit: this.handleEdit,
      activate: this.handleActivate,
      terminate: this.handleTerminate,
      createBlackList: this.handleCreateBlackList,
      removeBlackList: this.handleRemoveBlackList,
      paymentTermCreate: this.handlePaymentTermCreate,
      paymentTermEdit: this.handlePaymentTermEdit,
      paymentTermDelete: this.handlePaymentTermDelete,
      commentCreate: this.handleCommentCreate,
      commentDelete: this.handleCommentDelete,
    };
  }
  handleCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.customers}${id}` },
          { title: 'customer was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Customer created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };

  handleEdit = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.customers}${id}` },
          { title: 'customer was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Customer edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };

  handleActivate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.customers}${id}` },
          { title: 'customer was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Customer activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };

  handleTerminate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.customers}${id}` },
          { title: 'customer was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Customer terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleCreateBlackList = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Customer' },
          { id, title, link: `${links.customers}${id}` },
          { title: 'was added to blacklist by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Customer added to blacklist"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleRemoveBlackList = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Customer' },
          { id, title, link: `${links.customers}${id}` },
          { title: 'was unlocked by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Customer removed from blacklist"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handlePaymentTermCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'New payment terms were added in customer' },
          { id, title, link: `${links.customers}${id}` },
          { title: 'for MC' },
          { id: entity?.id, title: entity!.title, link: `${links.authorities}${entity?.id}` },
          { title: 'by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Payment terms added"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handlePaymentTermEdit = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'New payment terms were edited in customer' },
          { id, title, link: `${links.customers}${id}` },
          { title: 'for MC' },
          { id: entity?.id, title: entity!.title, link: `${links.authorities}${id}` },
          { title: 'by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Payment terms edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handlePaymentTermDelete = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'New payment terms were deleted in customer' },
          { id, title, link: `${links.customers}${id}` },
          { title: 'for MC' },
          { id: entity?.id, title: entity!.title, link: `${links.authorities}${id}` },
          { title: 'by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Payment terms deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleCommentCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'A new comment was added in customer' },
          { id, title, link: `${links.customers}${id}` },
          { title: 'by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Comment added"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleCommentDelete = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'A new comment was deleted in customer' },
          { id, title, link: `${links.customers}${id}` },
          { title: 'by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Comment deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
}
