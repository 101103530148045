import { useState } from 'react';
import { PaginationProps } from 'antd';
import clsx from 'clsx';

import { ArrowSvg } from 'components/svgs/ArrowSvg';

import {
  getAddedTrucksColumns,
  getAuthoritiesColumns,
  getAuthoritiesViewColumns,
  getFuelCardsColumns,
  getMcColumns,
  getMcViewColumns,
  getPayablesColumns,
  getPickupsAndDropOffsColumns,
  getReceivableTransactionsColumns,
} from './constants/helpers';
import { IDataGridProps, IDataSource } from './constants/types';

import styles from './DataGrid.module.scss';

const useDataGrid = ({
  handleRowDelete,
  handleRowEdit,
  handlePickupDropOffRow,
  handleDownloadClick,
  handleViewFileClick,
  handlePaymentDetailsClick,
  handleReceiveBtnClick,
  handlePayBtnClick,
  handleRowClick,
  addedAuthorities,
  isDisabledPayButton,
  isDeleteDisabled,
  paymentDetails,
  isEditCreating,
  isFirstItemNotDeletable,
  isNotEditable,
  setEditingRow,
  paymentDetailsLoading,
  iAmSecondAgent,
  hasEditPickupDropOffPermission,
  copiedText,
  handleCopy,
  mode,
  withoutActions,
}: Partial<IDataGridProps>) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const handleTransactionBtnClick = (key: number) => {
    setExpandedRowKeys([key]);
  };

  const CURRENT_COLUMNS: IDataSource = {
    authorities: getAuthoritiesColumns({ handleRowDelete, addedAuthorities, isDeleteDisabled }),
    authoritiesView: getAuthoritiesViewColumns({ copiedText, handleCopy }),
    mc: getMcColumns({ handleRowDelete, handleRowEdit, isDeleteDisabled, setEditingRow }),
    mcView: getMcViewColumns(),
    pickup_dropOffs: getPickupsAndDropOffsColumns({
      handleRowDelete,
      handlePickupDropOffRow,
      isDeleteDisabled,
      handleRowEdit,
      isEditCreating,
      isFirstItemNotDeletable,
      isNotEditable,
      hasEditPickupDropOffPermission,
      iAmSecondAgent,
      withoutActions,
    }),
    receivable_transactions: getReceivableTransactionsColumns({
      handleDownloadClick,
      handleTransactionBtnClick,
      handlePaymentDetailsClick,
      handleReceiveBtnClick,
      isDisabledPayButton,
      paymentDetails,
      paymentDetailsLoading,
      handleRowClick,
      copiedText,
      handleCopy,
      mode,
    }),
    payables: getPayablesColumns({
      handleDownloadClick,
      handleViewFileClick,
      handlePayBtnClick,
      handleRowClick,
      isDisabledPayButton,
      copiedText,
      handleCopy,
      mode,
    }),
    addedTrucks: getAddedTrucksColumns({ handleRowDelete, handleRowEdit, isDeleteDisabled, setEditingRow }),
    fuelCards: getFuelCardsColumns({ handleRowDelete, handleRowEdit, isDeleteDisabled }),
  };

  const paginationArrows: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowLeft)}>
          <ArrowSvg />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowRight)}>
          <ArrowSvg />
        </a>
      );
    }
    return originalElement;
  };

  const pageSizeOptions = (total: number) => {
    const maxPageSize = 40;
    const pageSizeOptions = Array.from({ length: Math.ceil(total / 10) }, (_, i) => (i + 1) * 10).filter(
      option => option <= maxPageSize
    );
    return pageSizeOptions;
  };

  return {
    CURRENT_COLUMNS,
    expandedRowKeys,
    setExpandedRowKeys,
    paginationArrows,
    pageSizeOptions,
  };
};

export default useDataGrid;
