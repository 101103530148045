import { IOption } from './types';

export const OPTIONS: IOption[] = [
  { label: 'DOT', value: 'DOT' },
  { label: 'Phone', value: 'Phone' },
  { label: 'Internet', value: 'Internet' },
];
export const CUSTOM_OPTION: IOption = { label: 'Custom', value: 'custom' };

export enum SUBJECT {
  ADD_ITEM = 'Add item',
  TYPE = 'Type',
  ENTER_ITEM = 'Please enter item',
}
