export enum equipmentTypeText {
  'Van' = 1,
  'Reefer' = 2,
  'Flat Bed' = 3,
  'Power Only' = 4,
  'Box Truck' = 5,
}
export enum trailerTypesText {
  'E-Truck' = 1,
  'High Cube' = 2,
  'Plate Trailer' = 3,
  'Hazmat' = 4,
}

export enum statusTypesText {
  'On Board' = 1,
  'Off Board' = 2,
  'Transit' = 3,
}

export enum TruckStatuses {
  UNSPECIFIED = 0,
  STANDARD = 1,
  HAS_A_LOAD = 2,
  TRUCK_BOARD = 3,
  WRONG_LOCATION = 4,
}

export enum TruckBoardStatuses {
  UNSPECIFIED = 0,
  ON_BOARD = 1,
  OFF_BOARD = 2,
  TRANSIT = 3,
}
