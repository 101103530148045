import { formatPickUpDropOffWithTime, formatPickUpDropOffWithTimeWithoutTimeZoneChange } from 'utils/dates';

export const pickupsDropOffsSerializer = (
  pickupsAndDropoffs: any,
  editMode = false,
  serializedStates: any,
  username: string
) => {
  const formattedData = pickupsAndDropoffs?.map((el: any) => {
    return {
      dateAndTime: editMode
        ? formatPickUpDropOffWithTime(el?.appointmentDate)
        : formatPickUpDropOffWithTimeWithoutTimeZoneChange(el?.appointmentDate),
      address: el?.address,
      city: el?.city,
      state: serializedStates?.[el?.state as keyof typeof serializedStates],
      comment: el?.comment,
      phoneNumber: el?.phoneNumber || el?.phone,
      more: '',
      id: el?.id,
      zip: el?.zip,
      addedBy: editMode ? el?.creator?.username : username,
      facility: el?.facility,
    };
  });
  return formattedData;
};
