import { IReceivablesModalContentFormData } from './types';

export enum SUBJECT {
  CUSTOMER_INFO = 'Customer Info',
  NAME = 'Name',
  PHONE_NUMBER = 'Phone Number',
  CUSTOMER_MC = 'Customer MC',
  TYPE = 'Type',
  FF_NUMBER = 'FF Number',
  PAYMENT = 'Payment',
  CANCEL = 'Cancel',
  RECEIVE = 'Receive',
  MC = 'MC',
  RESET_ALL = 'Reset All',
  TOTAL = 'Total',
  EXPECTED = 'Expected',
  PAID = 'Paid',
  PAY = 'Pay',
  MC_NUMBER = 'MC Number',
  FAX = 'Fax',
  CUSTOMER_ID = 'Customer ID',
  RECEIVED = 'Received',
}

export const defaultValues: IReceivablesModalContentFormData = {
  moneyType: '',
  numberOrTransactionId: '',
  moneyCode: '',
  amount: '',
};

export const RECEIVABLE_TRANSACTION_DATA = [
  {
    key: 1,
    regDate: '12-30-2021, 01:05 AM',
    loadId: 1256471466,
    customerRate: '1,000.00.00 $',
    customerAmount: 1400,
    invoice: '12-30-2021, 01:05 AM',
    mc: 'Mira',
    transaction: 'Transaction',
    payment: 'Payment details',
    status: 'Receive',
    paymentMethod: {
      moneyType: 'Check',
      checkNumber: '1256471466',
      moneyCode: '32659897',
      amount: '15,000.00 $',
      debt: '1,000.00 $',
    },
  },
  {
    key: 2,
    regDate: '12-30-2021, 01:05 AM',
    loadId: 1256471466,
    customerRate: '1,000.00.00 $',
    customerAmount: 1400,
    invoice: '12-30-2021, 01:05 AM',
    mc: 'Mira',
    transaction: 'Transaction',
    payment: 'Payment details',
    status: 'Receive',
    paymentMethod: {
      moneyType: 'Check',
      checkNumber: '1256471466',
      moneyCode: '32659897',
      amount: '15,000.00 $',
      debt: '1,000.00 $',
    },
  },
  {
    key: 3,
    regDate: '12-30-2021, 01:05 AM',
    loadId: 1256471466,
    customerRate: '1,000.00.00 $',
    customerAmount: 1400,
    invoice: '12-30-2021, 01:05 AM',
    mc: 'Mira',
    transaction: 'Transaction',
    payment: 'Payment details',
    status: 'Receive',
    paymentMethod: {
      moneyType: 'Check',
      checkNumber: '1256471466',
      moneyCode: '32659897',
      amount: '15,000.00 $',
      debt: '1,000.00 $',
    },
  },
  {
    key: 4,
    regDate: '12-30-2021, 01:05 AM',
    loadId: 1256471466,
    customerRate: '1,000.00.00 $',
    customerAmount: 1400,
    invoice: '12-30-2021, 01:05 AM',
    mc: 'Mira',
    transaction: 'Transaction',
    payment: 'Payment details',
    status: 'Received',
    paymentMethod: {
      moneyType: 'Check',
      checkNumber: '1256471466',
      moneyCode: '32659897',
      amount: '15,000.00 $',
      debt: '1,000.00 $',
    },
  },
  {
    key: 5,
    regDate: '12-30-2021, 01:05 AM',
    loadId: 1256471466,
    customerRate: '1,000.00.00 $',
    customerAmount: 1400,
    invoice: '12-30-2021, 01:05 AM',
    mc: 'Mira',
    transaction: 'Transaction',
    payment: 'Payment details',
    status: 'Received',
    paymentMethod: {
      moneyType: 'Check',
      checkNumber: '1256471466',
      moneyCode: '32659897',
      amount: '15,000.00 $',
      debt: '1,000.00 $',
    },
  },
];
