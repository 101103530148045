import { PaginationProps } from 'antd';
import clsx from 'clsx';
import { useDetectedParams } from 'hooks/useDetectedParams';

import { ArrowSvg } from 'components/svgs/ArrowSvg';

import styles from './LastLoadsTable.module.scss';

export const useLastLoadsTable = () => {
  const { searchParams, setSearchParams } = useDetectedParams();

  const pageSizeOptions = (total: number) => {
    const maxPageSize = 40;
    const pageSizeOptions = Array.from({ length: Math.ceil(total / 10) }, (_, i) => (i + 1) * 10).filter(
      option => option <= maxPageSize
    );
    return pageSizeOptions;
  };

  const paginationArrows: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowLeft)}>
          <ArrowSvg />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowRight)}>
          <ArrowSvg />
        </a>
      );
    }
    return originalElement;
  };

  return {
    searchParams,
    setSearchParams,
    pageSizeOptions,
    paginationArrows,
  };
};
