import { ReactNode, useEffect, useRef } from 'react';
import { Table, Tooltip } from 'antd';
import clsx from 'clsx';
import { RowContent } from 'pages/factoring/tabs/receivables/components/row-content/RowContent';
import Checkbox from 'ui/inputs/checkbox/Checkbox';
import { CarrierStatus, CustomerStatus } from 'ui/load-status/constants/types';
import Typography from 'ui/typography/Typography';

import { SUBJECT } from './constants/constants';
import { IDataGridProps, IDataSource } from './constants/types';
import useDataGrid from './useDataGrid';

import styles from './DataGrid.module.scss';

const DataGrid = ({
  title,
  data = [],
  selectedRow,
  selectedRows,
  type,
  selectedCard,
  isDisableAllRows,
  withCheckbox = false,
  isExpandable,
  InfoContainer,
  handleRowDelete,
  handleRowEdit,
  handlePickupDropOffRow,
  handleDownloadClick,
  handleViewFileClick,
  handlePaymentDetailsClick,
  handleReceiveBtnClick,
  handlePaginationClick,
  onCheckboxChange,
  isDeleteDisabled,
  count,
  isDisabledPayButton,
  onRowClick,
  handlePayBtnClick,
  handleRowClick,
  paymentDetails,
  isEditCreating = false,
  isLoading = false,
  isFirstItemNotDeletable = false,
  isNotEditable = false,
  editingRow,
  setEditingRow,
  isPickupDropoffs = false,
  checkIsDisabledRowSelection,
  isInFactoring = false,
  iAmSecondAgent = false,
  paymentDetailsLoading,
  highlightErrorsRows = [],
  scrollIntoView = false,
  hasEditPickupDropOffPermission,
  copiedText,
  handleCopy,
  withoutActions,
  mode,
}: IDataGridProps & IDataSource) => {
  const { CURRENT_COLUMNS, expandedRowKeys, setExpandedRowKeys, paginationArrows, pageSizeOptions } = useDataGrid({
    handleRowDelete,
    handleRowEdit,
    handlePickupDropOffRow,
    handleDownloadClick,
    handleViewFileClick,
    handlePaymentDetailsClick,
    handleReceiveBtnClick,
    handlePayBtnClick,
    addedAuthorities: data,
    isDeleteDisabled,
    handlePaginationClick,
    isDisabledPayButton,
    paymentDetails,
    handleRowClick,
    isEditCreating,
    isFirstItemNotDeletable,
    isNotEditable,
    hasEditPickupDropOffPermission,
    setEditingRow,
    iAmSecondAgent,
    paymentDetailsLoading,
    copiedText,
    handleCopy,
    mode,
    withoutActions,
  });
  const isRowSelection = data.filter(el => el?.status !== 2)?.length;
  const dataGridRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollIntoView) {
      dataGridRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [scrollIntoView]);

  const rowSelection = {
    renderCell(checked: boolean, record: IDataSource, index: number, node: ReactNode) {
      if (!checkIsDisabledRowSelection) {
        if (record?.keyType === SUBJECT.PAYABLES && record?.status === CarrierStatus.CARRIER_STATUS_PAID) {
          return (
            <Tooltip color="#fff" placement="top" title="You can’t select paid status">
              <Checkbox children="" size="large" disabled />
            </Tooltip>
          );
        }
        if (record?.keyType === SUBJECT.RECEIVABLES && record?.status === CustomerStatus.CUSTOMER_STATUS_PAID) {
          return (
            <Tooltip placement="top" color="#fff" title="You can’t select received status">
              <Checkbox children="" size="large" disabled />
            </Tooltip>
          );
        }
        return node;
      } else {
        if (record?.keyType === SUBJECT.PAYABLES) {
          if (record?.haveUnacceptedTransactions) {
            return (
              <Tooltip
                placement="top"
                color="#fff"
                title="You can't pay the carrier, because the carrier's transactions are in the billing"
              >
                <Checkbox children="" size="large" disabled />
              </Tooltip>
            );
          }
          if (!record?.haveUnacceptedTransactions && record?.status === SUBJECT.CarrierDebt) {
            return (
              <Tooltip placement="top" color="#fff" title="You can only do single pay">
                <Checkbox children="" size="large" disabled />
              </Tooltip>
            );
          }
          if (record?.status === CarrierStatus.CARRIER_STATUS_PAID) {
            return (
              <Tooltip placement="top" color="#fff" title="You can’t select paid status">
                <Checkbox children="" size="large" disabled />
              </Tooltip>
            );
          } else {
            if (record.claimed) {
              return (
                <Tooltip placement="top" title={'Claimed'} color="white">
                  {checkIsDisabledRowSelection(record, node)}
                </Tooltip>
              );
            }
            return checkIsDisabledRowSelection(record, node);
          }
        }
        if (record?.keyType === SUBJECT.RECEIVABLES) {
          if (record?.status === CustomerStatus.CUSTOMER_STATUS_PAID) {
            return (
              <Tooltip placement="top" color="#fff" title="You can’t select received status">
                <Checkbox children="" size="large" disabled />
              </Tooltip>
            );
          } else {
            if (record.claimed) {
              return (
                <Tooltip placement="top" title={'Claimed'} color="white">
                  {checkIsDisabledRowSelection(record, node)}
                </Tooltip>
              );
            }
            return checkIsDisabledRowSelection(record, node);
          }
        }
        return node;
      }
    },
  };

  return (
    <div
      ref={dataGridRef}
      className={clsx(styles.tableWrapper, {
        [styles.authoritesViewDataGrid]: type === 'authoritiesView',
        [styles.payableReceivableDataGrid]: type === 'payables' || type === 'receivable_transactions',
        [styles.pickupDropoffsDataGrid]: type === 'pickup_dropOffs',
        [styles.truckFuelcardDataGrid]: type === 'addedTrucks' || type === 'fuelCards',
      })}
    >
      {title && <Typography children={title} variant="heading" className={styles.heading} />}

      {InfoContainer}
      <Table
        className={clsx({
          [styles.authoritesDataGrid]: type === 'authorities',
        })}
        onRow={(record: IDataSource) => {
          return {
            onClick: () => onRowClick && onRowClick(record?.id)!,
          };
        }}
        tableLayout="fixed"
        rowClassName={({ mc, truckNumber, truckId, id, card, claimed }: IDataSource) =>
          clsx({
            ['enteredRow']: mc && mc === selectedRow && !handleRowEdit && type !== 'pickup_dropOffs',
            ['activeRow']: !truckNumber && !card && ((handleRowEdit && mc && mc === selectedRow) || id === editingRow),
            ['activeRowTruck']: !card
              ? selectedRow
                ? handleRowEdit && selectedRow && (selectedRow === truckNumber || selectedRow === truckId)
                : editingRow === id
              : false,
            ['activeRowFuel']: !!card && selectedCard?.card === card,
            ['invalidRows']: highlightErrorsRows?.includes(id),
            [styles.claimed]: claimed,
            [styles.claimedHasAccess]: isDisabledPayButton && !isDisabledPayButton!(claimed),
          })
        }
        rowSelection={
          withCheckbox && isRowSelection
            ? {
                type: 'checkbox',
                onChange: onCheckboxChange,
                selectedRowKeys: selectedRows,
                fixed: 'left',
                columnWidth: 50,
                getCheckboxProps: (record: IDataSource) => ({
                  disabled: record.claimed || isDisableAllRows,
                }),
                ...rowSelection,
              }
            : undefined
        }
        expandable={
          isExpandable
            ? {
                expandedRowRender: (data: IDataSource) => (
                  <RowContent data={data} setExpandedRowKeys={setExpandedRowKeys} />
                ),
                expandIcon: () => null,
                expandedRowKeys,
                showExpandColumn: false,
              }
            : {}
        }
        columns={CURRENT_COLUMNS[type] || []}
        dataSource={data}
        rowKey={record => record.key}
        pagination={
          count! > 10 && {
            showTotal(total, [from, to]) {
              return <span>{`${from}-${to} of ${total}`}</span>;
            },
            onChange(page, pageSize) {
              handlePaginationClick && handlePaginationClick((page - 1) * pageSize, pageSize);
            },
            pageSizeOptions: pageSizeOptions(count!),
            itemRender: paginationArrows,
            locale: { items_per_page: '' },
            showSizeChanger: true,
            total: count!,
          }
        }
        loading={isLoading}
        scroll={{
          ...(!isInFactoring
            ? {
                x: '100%',
                ...(isPickupDropoffs
                  ? {
                      y: 1000000,
                    }
                  : {}),
              }
            : {}),
        }}
      />
    </div>
  );
};

export default DataGrid;
