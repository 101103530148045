import { useDeferredValue } from 'react';

import Table from 'components/table/Table';

import useFavoriteTrucks from './useFavoriteTrucks';

import styles from './FavoriteTrucks.module.scss';

const FavoriteTrucks = () => {
  const {
    columns,
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handlePaginationClick,
    favoriteList,
    isFavoriteListFetching,
    handleFavoriteClick,
    handleColumnSearch,
    skip,
    limit,
  } = useFavoriteTrucks();

  const tableProps = useDeferredValue({
    data: favoriteList,
    skip,
    limit,
    columns: columns,
    isLoading: isFavoriteListFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onFavoriteClick: handleFavoriteClick,
    onResizeStop: handleStopResize,
    onPaginationClick: handlePaginationClick,
    onColumnSearch: handleColumnSearch,
    withMenu: false,
  });

  return (
    <div className={styles.coordinatorWrapper}>
      <Table {...tableProps} />
    </div>
  );
};
export default FavoriteTrucks;
