import { createApi } from '@reduxjs/toolkit/query/react';
import { getCarriersListSerializer } from 'services/profile/carriers/serializers';
import { baseQuery } from 'services/service';
import { formatDate } from 'utils/dates';

import { IGetCommentRequest, IGetCommentsResponse } from '../loads/interface';
import { getLoadComments } from '../loads/serializers';
import { IAgentTable, IGetAgentsParams } from '../profile/agents/interfaces';
import { getAgentListSerializer } from '../profile/agents/serializers';
import { IGetAuthoritiesParams } from '../profile/authorities/interfaces';
import { IGetBranchesParams } from '../profile/branches/interfaces';
import { getAuthoritiesByBranchId, getBranchesSerializer } from '../profile/branches/serializers';
import { IGetCustomersParams } from '../profile/customers/interfaces';
import { IEmployessParams } from '../profile/employees/interface';
import { getEmployeesSerializer } from '../profile/employees/serializers';
import { IAuthorityByOfficeId, IGetOfficesParams } from '../profile/offices/interfaces';
import { getAuthoritiesByOfficeId } from '../profile/offices/serializers';

export const autocompleteAPI = createApi({
  reducerPath: 'autocompleteAPI',
  baseQuery,
  tagTypes: ['Autocomplete', 'Branches'],
  refetchOnMountOrArgChange: false,
  refetchOnReconnect: false,
  refetchOnFocus: false,
  endpoints: builder => ({
    getAuthoritiesShort: builder.query({
      query: ({ search, skip, limit, order, orderBy, type, carrierId, ignoreAuthorityIds, filter }: Partial<any>) => {
        const searchParams = new URLSearchParams();
        search && searchParams.append('search', search);
        searchParams.append('skip', skip);
        searchParams.append('limit', limit);
        order && searchParams.append('order', order);
        orderBy && searchParams.append('orderBy', orderBy);
        type && searchParams.append('type', type);

        carrierId && searchParams.append('ignoreCarrierId', carrierId);

        ignoreAuthorityIds?.length &&
          ignoreAuthorityIds?.forEach((el: any) => !!el && searchParams.append('ignoreAuthorityIds[]', el));

        return {
          url: 'authorities/autocomplete',
          params: searchParams,
        };
      },
      providesTags: ['Autocomplete'],
    }),
    getOffices: builder.query({
      query: ({ search, skip, limit, order, orderBy, filter }: Partial<IGetOfficesParams>) => ({
        url: 'offices/autocomplete',
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      providesTags: ['Autocomplete'],
    }),
    getOfficesShortData: builder.query({
      query: ({ search, skip, limit, order, orderBy, filter }: Partial<IGetOfficesParams>) => ({
        url: 'offices/short-data',
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            title: el.name,
            value: String(el.id),
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },
      merge: (currentCache, newItems) => {
        currentCache.result.push(...newItems.result);
      },
      providesTags: ['Autocomplete'],
    }),
    getLoadsComments: builder.query({
      query: ({ id, skip, limit }: IGetCommentRequest) => ({
        url: `loads/${id}/comments`,
        params: { skip, limit },
      }),
      transformResponse: (body: IGetCommentsResponse) => getLoadComments(body),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },
      merge: (currentCache, newItems) => {
        currentCache.commentsList.push(...newItems.commentsList);
      },
      providesTags: ['Autocomplete'],
    }),
    getCustomersComments: builder.query({
      query: ({ skip, limit, id }) => {
        return {
          url: `customers/${id}/comments`,
          params: { skip, limit },
        };
      },
      providesTags: ['Autocomplete'],
      transformResponse: (data: any) => {
        return {
          count: data?.count,
          commentsList: data?.result?.map((item: any) => {
            return {
              id: item?.id,
              name: `${item?.creator.firstName} ${item?.creator.lastName}`,
              createdAt: formatDate(item?.createdAt),
              commentText: item.message,
            };
          }),
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },
      merge: (currentCache, newItems) => {
        currentCache.commentsList.push(...newItems.commentsList);
      },
    }),
    getCarriersComments: builder.query({
      query: ({ id, skip, limit }) => ({
        url: `carriers/${id}/comment`,
        params: { skip, limit },
      }),
      providesTags: ['Autocomplete'],
      transformResponse: (data: any) => {
        return {
          count: data?.count,
          commentsList: data?.result?.map((item: any) => {
            return {
              id: item?.id,
              name: `${item?.creator.firstName} ${item?.creator.lastName}`,
              createdAt: formatDate(item?.createdAt),
              commentText: item.message,
            };
          }),
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },
      merge: (currentCache, newItems) => {
        currentCache.commentsList.push(...newItems.commentsList);
      },
    }),
    getCarriers: builder.query({
      query: ({ search, skip, limit, order, orderBy, filter, insuranceDays }: Partial<any>) => ({
        url: 'carriers',
        params: {
          search,
          skip,
          limit,
          order,
          orderBy,
          ...(insuranceDays !== undefined ? { insuranceDays } : {}),
          ...filter,
        },
      }),
      transformResponse: (body: any) => getCarriersListSerializer(body),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },

      merge: (currentCache, newItems) => {
        currentCache.result.push(...newItems.result);
      },
      providesTags: ['Autocomplete'],
    }),
    getAgents: builder.query({
      query: ({ search, skip, limit, order, orderBy, filter }: Partial<IGetAgentsParams>) => ({
        url: 'agents/autocomplete',
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: IAgentTable) => getAgentListSerializer(body),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },

      merge: (currentCache, newItems) => {
        currentCache.result.push(...newItems.result);
      },
      providesTags: ['Autocomplete'],
    }),
    getAuthoritiesByOfficeId: builder.query({
      query: ({ id, search, skip, limit, order, orderBy, filter }: Partial<IGetAuthoritiesParams>) => ({
        url: `offices/${id}/authorities`,
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: { result: IAuthorityByOfficeId[]; count: number }) => getAuthoritiesByOfficeId(body),

      providesTags: ['Autocomplete'],
    }),
    getBranchAuthorities: builder.query({
      query: ({ id, search, skip, limit, order, orderBy, filter }: Partial<IGetBranchesParams>) => ({
        url: `branches/${id}/authorities`,
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getAuthoritiesByBranchId(body),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },
      merge: (prev, current) => ({ ...prev, result: [...prev.result, ...current.result] }),
      providesTags: ['Autocomplete'],
    }),
    getBranches: builder.query({
      query: ({ search, skip, limit, order, orderBy, filter }: Partial<IGetBranchesParams>) => ({
        url: 'branches',
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getBranchesSerializer(body),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },
      merge: (prev, current) => ({ ...prev, result: [...prev.result, ...current.result] }),
      providesTags: ['Autocomplete'],
    }),
    getBranchesByOfficeShort: builder.query({
      query: ({ search, skip, limit, id }: Partial<IGetBranchesParams>) => ({
        url: `branches/${id}/branches/short-data`,
        params: { search, skip, limit },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },
      merge: (prev, current) => ({ ...prev, result: [...prev.result, ...current.result] }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            title: el.name,
            value: String(el.id),
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      providesTags: ['Autocomplete'],
    }),
    getTrucksByCarrierId: builder.query({
      query: ({ id, skip, limit }: Partial<any>) => ({
        url: `carriers/${id}/trucks`,
        params: { limit, skip },
      }),
      providesTags: ['Autocomplete'],
    }),
    getCustomers: builder.query({
      query: ({ search, skip, limit, order, orderBy, filter }: Partial<IGetCustomersParams>) => ({
        url: 'customers/autocomplete',
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      providesTags: ['Autocomplete'],
    }),
    getEmployees: builder.query({
      query: ({ level, search, skip, limit, order, orderBy, filter }: Partial<IEmployessParams>) => ({
        url: 'employees',
        params: { level, search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getEmployeesSerializer(body),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch() {
        return true;
      },
      merge: (prev, current) => ({ ...prev, result: [...prev.result, ...current.result] }),
      providesTags: ['Autocomplete'],
    }),
  }),
});

export const {
  useGetAuthoritiesShortQuery,
  useGetOfficesQuery,
  useGetOfficesShortDataQuery,
  useGetLoadsCommentsQuery,
  useGetCustomersCommentsQuery,
  useGetCarriersCommentsQuery,
  useGetAgentsQuery,
  useGetAuthoritiesByOfficeIdQuery,
  useGetBranchAuthoritiesQuery,
  useGetBranchesQuery,
  useGetTrucksByCarrierIdQuery,
  useGetCustomersQuery,
  useGetEmployeesQuery,
  useGetCarriersQuery,
  useGetBranchesByOfficeShortQuery,
} = autocompleteAPI;
