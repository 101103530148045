import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { ICarrierType, RatingAccessTypes } from 'services/profile/carriers/interfaces';
import Rate from 'ui/rate/Rate';
import Typography from 'ui/typography/Typography';
import { UserTypes } from 'utils/constants';
import { formatDateBirth } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import Favorite from 'components/favorite/Favorite';
import CopyIcon from 'components/svgs/CopyIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import useCarrierInfo from './useCarrierInfo';

import styles from './CarrierInfo.module.scss';

const CarrierInfo = ({ carrierInfo, handleScrollToHistory }: any) => {
  const {
    handleDeleteRate,
    getCarrierRatings,
    handleCarrierRate,
    handleCreateFavorite,
    handleDeleteFavorite,
    rateList,
    hasPermissionToView,
    userType,
    isFavorite,
  } = useCarrierInfo({
    carrierInfo,
  });
  const { copiedText, handleCopy } = useHandleCopyActive();

  return (
    <div className={styles.careers}>
      <ViewItemWrapper title={SUBJECT.CARRIER_INFO}>
        <Row gutter={[33, 0]} align="middle">
          <Col span={8}>
            <div className={styles.topbannerPairs}>
              <span className="top-banner-title">{SUBJECT.COMPANY_NAME_COLON}</span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={carrierInfo?.companyName}
                subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.COMPANY_NAME_COLON}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.topbannerPairs}>
              <span className="top-banner-title">{SUBJECT.CARRIER_ID_COLON}</span>{' '}
              <div
                className={clsx(styles.copyIconText, {
                  [styles.copyActive]: copiedText === 'topBannerCarrierId',
                })}
              >
                <Typography
                  variant="paragraph"
                  children={carrierInfo?.id}
                  className={clsx(styles.infoParagraph, 'top-banner-value')}
                />
                <div
                  className={styles.copyIcon}
                  role="button"
                  onClick={() => handleCopy(carrierInfo?.id, 'topBannerCarrierId')}
                >
                  <CopyIcon active={copiedText === 'topBannerCarrierId'} />
                </div>
              </div>
            </div>
          </Col>
          {carrierInfo?.mc && (
            <Col span={8}>
              <div className={styles.topbannerPairs}>
                <span className="top-banner-title">{SUBJECT.MC_COLON}</span>{' '}
                <TooltipText
                  prefixClass="top-banner-value"
                  data={carrierInfo?.mc}
                  subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.MC_COLON}`}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row gutter={[33, 0]} className={styles.columnsGap} align="middle">
          <Col span={8}>
            <div className={styles.topbannerPairs}>
              <span className="top-banner-title">{SUBJECT.CONTACT_NAME_COLON}</span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={carrierInfo?.contactName}
                subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.CONTACT_NAME_COLON}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.CARRIER_TYPE_COLON}</span>
            <span className="top-banner-value">{ICarrierType[carrierInfo?.type]}</span>
          </Col>
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.PHONE_NUMBER_COLON}</span>
            <Link className="top-banner-value" to={`tel:${carrierInfo?.phone}`}>
              {formatPhone(carrierInfo?.phone)}
            </Link>
          </Col>
        </Row>
        <Row gutter={[33, 0]} align="middle" className={styles.columnsGap}>
          {carrierInfo?.fax && (
            <Col span={8}>
              <div className={styles.topbannerPairs}>
                <span className="top-banner-title">{SUBJECT.FAX_COLON}</span>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={carrierInfo?.fax}
                  subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.FAX_COLON}`}
                />
              </div>
            </Col>
          )}
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.RATING_COLON}</span>
            <Rate
              canRate={carrierInfo?.accessRating}
              averageValue={carrierInfo?.rating}
              handleRate={handleCarrierRate}
              getRatings={getCarrierRatings}
              isFirstTime={carrierInfo?.accessRating === RatingAccessTypes.ADD_RATING}
              rateCount={carrierInfo?.ratingCount}
              rateList={rateList}
              handleDeleteRate={handleDeleteRate}
            />
          </Col>
          <Col span={8}>
            <Typography variant="paragraph" className={styles.textItem}>
              <span className="top-banner-title">{SUBJECT.COMMENTS}:</span>
              <span className={clsx('top-banner-value', styles.count)} onClick={handleScrollToHistory}>
                {`${SUBJECT.COMMENT} ${carrierInfo?.commentCount || 0}`}
              </span>
            </Typography>
          </Col>
          {hasPermissionToView && carrierInfo?.secondPhone && (
            <Col span={8}>
              <span className="top-banner-title">{SUBJECT.SECOND_NUMBER_COLON}</span>
              <Link className="top-banner-value" to={`tel:${carrierInfo?.secondPhone}`}>
                {formatPhone(carrierInfo?.secondPhone)}
              </Link>
            </Col>
          )}
        </Row>

        <Row className={styles.columnsGap} gutter={33} align="middle">
          {userType === UserTypes.AGENT && (
            <Col span={8}>
              <div className={styles.topbannerPairs}>
                <span className="top-banner-title">{SUBJECT.FAVORITE}:</span>
                <span className="top-banner-value">
                  <div className={styles.favoriteItem}>
                    <Favorite
                      selected={isFavorite}
                      onFavoriteClick={isFavorite ? handleDeleteFavorite : handleCreateFavorite}
                    />
                  </div>
                </span>
              </div>
            </Col>
          )}
        </Row>
        {!!carrierInfo?.coordinator?.length && (
          <>
            <Divider className={styles.divider} />
            <Col span={24}>
              <span className="top-banner-title">{SUBJECT.COORDINATOR_COLON} </span>
              <span className="top-banner-value">Mikel</span>
            </Col>
          </>
        )}
        {!!carrierInfo?.cargo && (
          <>
            <Divider className={styles.divider} />
            <Row gutter={[33, 0]} align="middle">
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.INSURANCE_LIABILITY}</span>
                <span className="top-banner-value">{formatDateBirth(carrierInfo?.liability)}</span>
              </Col>
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.INSURANCE_CARGO}</span>
                <span className="top-banner-value">{formatDateBirth(carrierInfo?.cargo)}</span>
              </Col>
              {!!carrierInfo?.cargoCoverageValue && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.CARGO_COVERAGE_VALUE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.cargoCoverageValue, true)}
                  </span>
                </Col>
              )}
            </Row>
            {(!!carrierInfo?.nonOwnedCoverage || !!carrierInfo?.trailerInterchange || carrierInfo?.reeferBreakdown) && (
              <Divider className={styles.divider} />
            )}
            <Row gutter={[33, 0]} align="middle">
              {!!carrierInfo?.nonOwnedCoverage && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.NON_OWNED_COVERAGE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.nonOwnedCoverage, true)}
                  </span>
                </Col>
              )}
              {!!carrierInfo?.trailerInterchange && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.TRAILER_INTERCHANGE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.trailerInterchange, true)}
                  </span>
                </Col>
              )}
              {!!carrierInfo?.reeferBreakdown && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.REEFER_BREAKDOWN + ':'}</span>{' '}
                  <span className="top-banner-value">{SUBJECT.CAN_HAUL}</span>
                </Col>
              )}
            </Row>
          </>
        )}
      </ViewItemWrapper>
    </div>
  );
};
export default CarrierInfo;
