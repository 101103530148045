import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  VALID_NUMBER,
  MIN_NUMBER,
  MONEY_TYPE_REQUIRED,
  TRANSACTION_ID_REQUIRED,
  AMOUNT_REQUIRED,
  MONEY_CODE_REQUIRED,
  NOT_ZERO,
} = VALIDATION_MESSAGES;

export const validation = () => {
  return yup.object().shape({
    moneyType: yup.string().required(MONEY_TYPE_REQUIRED),
    numberOrTransactionId: yup.string().required(TRANSACTION_ID_REQUIRED),
    moneyCode: yup.string().when('moneyType', {
      is: (value: string) => value === 'TCheck' || value === 'EFS' || value === 'Comdata',
      then: schema => schema.typeError(VALID_NUMBER).min(0, MIN_NUMBER).required(MONEY_CODE_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
    amount: yup
      .string()
      .typeError(VALID_NUMBER)
      .test('isRequired', AMOUNT_REQUIRED, function checkLength(value) {
        if (value === '0') {
          return this.createError({
            message: NOT_ZERO,
            path: 'amount',
          });
        }
        return !!value;
      }),
  });
};
