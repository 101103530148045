/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useHandleCopyActive } from 'hooks/useCopy';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useCancelLoadMutation,
  useClaimLoadMutation,
  useGetCarrierBreakdownsInLoadQuery,
  useGetLoadHistoryQuery,
  useUnclaimLoadMutation,
} from 'services/loads/loads';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { scrollToTarget } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { ICommentViewForm } from '../loads-comment/constants/types';

import { SUBJECT } from './constants/constants';

const useRegularLoadView = ({ refetchLoadById, data }: any) => {
  const dispatch = useDispatch();

  const { control, watch, setValue } = useForm<ICommentViewForm>({
    defaultValues: { comment: '' },
  });

  const userInfo = useSelector(selectUserInfo);
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;
  const { copiedText, handleCopy } = useHandleCopyActive();
  const drawerRef: { current: any } = useRef(null);
  const [isDefaultHistory, setIsDefaultHistory] = useState<boolean>(false);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { id, open, width } = searchParams;

  const [cancelLoad] = useCancelLoadMutation();
  const [claimLoad] = useClaimLoadMutation();
  const [unclaimLoad] = useUnclaimLoadMutation();
  const activeSection = useSelector(selectActiveSection);
  const handleScrollToHistory = (mode: string) => {
    scrollToTarget(drawerRef);
    setIsDefaultHistory(mode === 'history');
  };

  const handleSwitchMarkAsClaim = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_CLAIM,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: data?.load?.claimed ? 'modalPrimary' : 'danger',
        onOk: () => {
          dispatch(changeLoading(true));

          if (data?.load?.claimed) {
            unclaimLoad(String(data?.load?.id)).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(changeLoading(false));
              } else {
                notification.success({
                  message: 'Successfully marked as claim',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                refetchLoadById({ id: data?.load?.id });
                dispatch(changeLoading(false));
              }
            });
          } else {
            claimLoad(String(data?.load?.id)).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully marked as claim',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                refetchLoadById({ id: data?.load?.id });
                dispatch(changeLoading(false));
              }
            });
          }
        },

        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleCancelLoad = (id: string | number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_LOAD,
        okText: SUBJECT.CANCEL,
        cancelText: SUBJECT.NO,
        okButtonVariant: 'danger',
        cancelButtonVariant: 'gray',
        onOk: () => {
          cancelLoad(Number(id)).then((arg: any) => {
            if (arg.error) {
              dispatch(closeModal());
              notification.error({
                message: ServerErrorCodes[Number(arg?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully canceled',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              refetchLoadById({ id: data?.load?.id });
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: 'edit',
      isEdit: 'true',
    });
  };

  const handleViewFile = (url: string) => {
    window.open(`${url}`, '_blank');
  };

  const handleDownloadFileClick = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    link.click();
  };

  const [breakdownParams, setBreakdownParams] = useState({
    skip: 0,
    limit: 10,
    order: 2,
    orderBy: 'createdAt',
  });

  const { data: breakdowns, isFetching: isBreakdownLoading } = useGetCarrierBreakdownsInLoadQuery(
    { id: id, ...breakdownParams },
    { skip: !id }
  );

  const handleCarrierBreakdownPaginationClick = (skip: number, limit: number) => {
    setBreakdownParams((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleBreakdownSortClick = (sortOrder: number, dataIndex: string) => {
    setBreakdownParams((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          skip: 0,
          limit: prev.limit,
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const [notes, setNotes] = useState<any>({
    result: [],
    count: 0,
  });

  const [notesParams, setNotesParams] = useState<any>({
    skip: 0,
    limit: 10,
  });

  const { currentData: loadHistory, isFetching: isFetchingHistory } = useGetLoadHistoryQuery(
    { id, ...notesParams },
    { skip: !id }
  );

  const getMoreNotes = () => {
    setNotesParams((prev: any) => ({ ...prev, skip: prev.skip + 10 }));
  };

  const isShowMoreVisibleNotes = useMemo(
    () => loadHistory?.count && loadHistory?.count > notes?.result?.length,
    [loadHistory, notesParams, notes?.result?.length]
  );

  useEffect(() => {
    if (loadHistory?.result && isShowMoreVisibleNotes) {
      setNotes((prev: any) => ({
        result: [...prev.result, ...loadHistory.result],
        count: loadHistory?.count,
      }));
    }
  }, [loadHistory?.result?.length, notesParams.skip]);

  useEffect(() => {
    return () => {
      setIsDefaultHistory(false);
      setNotesParams({
        skip: 0,
        limit: 10,
      });
    };
  }, [open]);

  return {
    handleChangeMode,
    handleSwitchMarkAsClaim,
    handleCancelLoad,
    handleViewFile,
    handleDownloadFileClick,
    control,
    watch,
    setValue,
    breakdowns,
    breakdownParams,
    isBreakdownLoading,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    loadHistory: notes,
    isShowMoreVisibleNotes,
    getMoreNotes,
    roleLevel,
    copiedText,
    handleCopy,
    drawerRef,
    isDefaultHistory,
    setIsDefaultHistory,
    handleScrollToHistory,
    isFetchingHistory,
    activeSection,
    width,
    userInfo,
  };
};

export default useRegularLoadView;
