import { useEffect, useMemo, useState } from 'react';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetCarrierInsuranceByIdQuery, useGetLoadHistoryQuery } from 'services/loads/loads';

export const useEditLoad = ({ id, carrierId }: any) => {
  const {
    searchParams: { open },
  } = useDetectedParams();

  const [notes, setNotes] = useState<any>({
    result: [],
    count: 0,
  });

  const [notesParams, setNotesParams] = useState<any>({
    skip: 0,
    limit: 10,
  });

  const { currentData: loadHistory, isFetching: isFetchingHistory } = useGetLoadHistoryQuery(
    { id, ...notesParams },
    { skip: !id }
  );

  const { data: insuranceDate } = useGetCarrierInsuranceByIdQuery({ id: carrierId }, { skip: !carrierId });

  const getMoreNotes = () => {
    setNotesParams((prev: any) => ({ ...prev, skip: prev.skip + 10 }));
  };

  const isShowMoreVisibleNotes = useMemo(
    () => loadHistory?.count && loadHistory?.count > notes?.result?.length,
    [loadHistory, notesParams, notes?.result?.length]
  );

  useEffect(() => {
    if (loadHistory?.result && isShowMoreVisibleNotes) {
      setNotes((prev: any) => ({
        result: [...prev.result, ...loadHistory.result],
        count: loadHistory?.count,
      }));
    }
  }, [loadHistory?.result?.length, notesParams.skip]);

  useEffect(() => {
    return () => {
      setNotesParams({
        skip: 0,
        limit: 10,
      });
    };
  }, [open]);

  return {
    loadHistory: notes,
    getMoreNotes,
    isShowMoreVisibleNotes,
    isFetchingHistory,
    carrierInsurance: insuranceDate?.result || 0,
  };
};
