const PasswordEyeIcon = () => {
  return (
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6362 12.6731C7.23151 12.6731 4.24992 10.6571 2.81173 7.67309C4.24992 4.68907 7.23151 2.6731 10.6362 2.6731C14.041 2.6731 17.0225 4.68907 18.4607 7.6731C17.0225 10.6571 14.041 12.6731 10.6362 12.6731ZM10.6362 0.673096C15.1864 0.673096 19.0751 3.57574 20.6362 7.6731C19.0751 11.7705 15.1864 14.6731 10.6362 14.6731C6.08607 14.6731 2.19732 11.7705 0.63623 7.6731C2.19732 3.57574 6.08607 0.673096 10.6362 0.673096ZM10.6362 10.6731C12.2931 10.6731 13.6362 9.32995 13.6362 7.6731C13.6362 6.01624 12.2931 4.6731 10.6362 4.6731C8.97938 4.6731 7.63623 6.01624 7.63623 7.6731C7.63623 9.32995 8.97938 10.6731 10.6362 10.6731Z"
        fill="#6E7491"
      />
    </svg>
  );
};

export default PasswordEyeIcon;
