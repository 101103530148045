import React from 'react';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { USER_SETTINGS_TABS } from 'utils/constants';

import MainDrawer from 'components/main-drawer/MainDrawer';
import TabPanel from 'components/tab-panel/TabPanel';

import { SUBJECT } from './constants/constants';
import useUserSettings from './useUserSettings';

import styles from './UserSettings.module.scss';

const UserSettings = () => {
  const {
    openMainDrawer,
    onSubmit,
    handleSubmit,
    currentChildren: { component, withFooter, buttonText, onCancel },
  } = useUserSettings();
  return (
    <div>
      <TabPanel tabs={USER_SETTINGS_TABS}></TabPanel>
      <div className="page-content">
        <div className={styles.settingsItems}>
          <Typography variant="heading" children={SUBJECT.SETTINGS} className={styles.settingsTitle} />
          <Button variant="default" children={SUBJECT.CHANGE_PASSWORD} onClick={openMainDrawer} />
        </div>
        <MainDrawer
          onSubmit={handleSubmit(onSubmit)}
          Children={component}
          buttonText={buttonText || ''}
          withFooter={withFooter}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

export default UserSettings;
