import React from 'react';
import { Col, Divider, Row } from 'antd';
import { BillingTypesText } from 'services/profile/customers/interfaces';
import RequestError from 'ui/request-error/RequestError';
import Typography from 'ui/typography/Typography';
import {
  checkIsNumberDecimal,
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
} from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import RequestErroIcon from 'components/svgs/RequestErroIcon';

import {
  AUTHORITY_KEYS,
  CUSTOMER_KEYS,
  CUSTOMER_TYPE_COLUMNS,
  MY_AUTHORITY_COLUMNS,
  SUBJECT,
} from './constants/constants';
import { Input, SearchableDropdown } from './hoc-instance/instance';

import styles from './RequestCredit.module.scss';

const RequestCredit = ({
  handleSubmit,
  onSubmit,
  control,
  errors,
  getValues,
  authorities,
  setAuthoritiesState,
  customers,
  setCustomersState,
  watch,
  setValue,
  paymentTerm,
  isPaymentTermLoading,
  depthData,
}: any) => {
  const realAmount = +watch('amount') - (+watch('amount') / 100) * Number(paymentTerm?.result?.percent);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.topBanner}>
        <div className={styles.topBannerHeader}>
          <Typography className={styles.topBannerTitle} variant="heading" level={2} children={SUBJECT.REQUEST_CREDIT} />
        </div>
        <Divider />
        <div className={styles.requestCreditContent}>
          <Row gutter={[20, 36]}>
            <Col span={12}>
              <SearchableDropdown
                options={authorities?.result}
                columns={MY_AUTHORITY_COLUMNS}
                keys={AUTHORITY_KEYS}
                placeholder={SUBJECT.MY_AUTHORITY}
                name="authority"
                control={control}
                setValue={setValue}
                values={watch('authority')}
                offset={authorities?.count}
                errors={errors}
                setOffset={setAuthoritiesState}
                showKey="name"
                required
                rules={{ required: true }}
              />
            </Col>
            <Col span={12}>
              <SearchableDropdown
                options={customers?.result?.map((item: any) => {
                  return {
                    id: item.id,
                    mc: item?.mc,
                    name: item.name,
                  };
                })}
                columns={CUSTOMER_TYPE_COLUMNS}
                keys={CUSTOMER_KEYS}
                placeholder={SUBJECT.CUSTOMER}
                name="customer"
                control={control}
                setValue={setValue}
                values={watch('customer')}
                offset={customers?.count}
                errors={errors}
                setOffset={setCustomersState}
                showKey="name"
                required
                rules={{ required: true }}
              />
            </Col>
            {!!Object.keys(paymentTerm)?.length && !!watch('authority')?.length && !!watch('customer')?.length && (
              <>
                <Col span={12}>
                  <Input
                    name="amount"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.AMOUNT}
                    value={getValues('amount') ? formatNumberWithThousandsSeparatorAndDecimal(watch('amount')) : ''}
                    required
                    errors={errors}
                    suffix={<DollarIcon />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue('amount', floatNumberFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  />
                </Col>
              </>
            )}
            {!Object.keys(paymentTerm)?.length &&
              !!watch('authority')?.length &&
              !!watch('customer')?.length &&
              !isPaymentTermLoading && (
                <Col span={24}>
                  <RequestError icon={<RequestErroIcon />} title={SUBJECT.REQUEST_CREDIT_ERROR_MESSAGE_TITLE} />
                </Col>
              )}
          </Row>
        </div>

        {!!Object.keys(paymentTerm)?.length && !!watch('customer')?.length && !!watch('authority')?.length ? (
          <div>
            <Divider />

            <Row align="middle">
              <Col span={8}>
                <Typography
                  className={styles.topBannerSubTitle}
                  variant="heading"
                  level={2}
                  children={SUBJECT.PAYMENT_METHOD}
                />
                <Row className={styles.amountBlock} align="middle">
                  <Col span={8}>
                    <Typography
                      variant="paragraph"
                      className={styles.amountText}
                      children={BillingTypesText[paymentTerm?.result?.type as BillingTypesText]}
                    />
                  </Col>
                  <Col span={8}>
                    <Typography
                      variant="paragraph"
                      className={styles.amountText}
                      children={`${paymentTerm?.result?.percent}%`}
                    />
                  </Col>
                  <Col span={8}>
                    <Typography
                      variant="paragraph"
                      className={styles.amountText}
                      children={`${paymentTerm?.result?.days} day`}
                    />
                  </Col>
                </Row>
              </Col>
              {watch('amount') && (
                <Col span={8}>
                  <Typography
                    className={styles.topBannerSubTitle}
                    variant="heading"
                    level={2}
                    children={SUBJECT.REAL_AMOUNT}
                  />
                  <Row className={styles.realAmount} align="middle">
                    <Col span={12}>
                      <Typography
                        className={styles.amountFull}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(
                          checkIsNumberDecimal(realAmount),
                          true
                        )}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Row align="middle" className={styles.debtBlock}>
              <Col span={8}>
                <Typography className={styles.topBannerSubTitle} variant="heading" level={2} children={SUBJECT.DEBT} />
                <Row className={styles.debtRow} align="middle">
                  <Col span={8}>
                    <Typography variant="paragraph" children={SUBJECT.NOT_PAID} />
                  </Col>
                  <Col span={8}>
                    <Typography
                      variant="paragraph"
                      children={`${formatNumberWithThousandsSeparatorAndDecimal(depthData?.result?.depth, true)}`}
                    />
                  </Col>
                </Row>
                {!!paymentTerm?.result?.paymentLimit && (
                  <Row className={styles.debtRow} align="middle">
                    <Col span={8}>
                      <Typography variant="paragraph" children={SUBJECT.LIMIT} />
                    </Col>
                    <Col span={8}>
                      <Typography
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(
                          paymentTerm?.result?.paymentLimit,
                          true
                        )}`}
                      />
                    </Col>
                  </Row>
                )}
                <Row className={styles.debtRow} align="middle">
                  <Col span={8}>
                    <Typography variant="paragraph" children={SUBJECT.LOAD_QTY} />
                  </Col>
                  <Col span={8}>
                    <Typography variant="paragraph" children={depthData?.result?.count} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default RequestCredit;
