import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import CustomDropdown from 'ui/inputs/select/components/dropdown-with-custom-input/CustomDropdown';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';

import { BlueInfoIcon } from 'components/svgs/BlueInfoIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import { TrashIcon } from 'components/svgs/TrashIcon';

import { FILES_OPTIONS, SUBJECT } from './constants/constants';
import { IUploadedFileRow } from './constants/types';

import styles from './UploadedFileRow.module.scss';

const UploadedFileRow = ({
  id,
  type,
  name,
  addedBy,
  addedAt,
  previewLink,
  downloadLink,
  options,
  onChange,
  onWatchClick,
  onDownloadClick,
  onDeleteClick,
  file,
  mode,
}: IUploadedFileRow) => {
  return (
    <Row className={styles.uploadedList} gutter={20}>
      <Col span={8}>
        <CustomDropdown
          options={options?.length ? options : FILES_OPTIONS}
          name={String(id)}
          value={type}
          onChange={onChange}
          withCustomOption={false}
        />
      </Col>
      <Col span={12}>
        <Row gutter={20}>
          {!!addedBy && (
            <Col>
              <Typography children={`Added by ${addedBy}`} variant="paragraph" className={styles.addedByName} />
            </Col>
          )}
          {!!addedAt && (
            <Col>
              <Tooltip
                placement="top"
                color="white"
                overlayInnerStyle={{ width: 400 }}
                key={id}
                title={
                  <Row gutter={[10, 10]} className={styles.infoBlock}>
                    <Col span={24}>
                      <Row>
                        <Col span={12} className={styles.leftColumn}>
                          {SUBJECT.FILE_NAME}
                        </Col>
                        <Col span={12} className={styles.rightColumn}>
                          {name}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              >
                <div className={styles.dateBlock}>
                  <Typography children={formatDate(addedAt)} variant="paragraph" />
                  <BlueInfoIcon />
                </div>
              </Tooltip>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={4}>
        <div className={styles.uploadedButtons}>
          {!!previewLink && (
            <button
              type="button"
              className={clsx(styles.uploadControl, styles.eyeIcon)}
              onClick={() => {
                mode === 'add' ? onWatchClick(file) : file.preview ? onWatchClick(file.preview) : onWatchClick(file);
              }}
            >
              <EyeIconSvg />
            </button>
          )}
          {!!downloadLink && (
            <button
              type="button"
              className={clsx(styles.uploadControl, styles.downloadBtn)}
              onClick={() => {
                mode === 'add'
                  ? onDownloadClick(file)
                  : file.download
                  ? onDownloadClick(file.download)
                  : onDownloadClick(file);
              }}
            >
              <DownloadIcon />
            </button>
          )}
          <button type="button" className={styles.uploadControl} onClick={() => onDeleteClick(id)}>
            <TrashIcon />
          </button>
        </div>
      </Col>
    </Row>
  );
};

export default UploadedFileRow;
