import { Col, Row } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { changeTimezoneToUs } from 'hooks/useTimeZoneDetector';
import Button from 'ui/button/Button';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { OtherServiceFeeCards } from '../../constants/types';
import { SUBJECT } from '../constants/constants';

import styles from './AdvanceChargeView.module.scss';

interface IAdvanceChargeViewProps {
  edit: boolean;
  handleChangeMode: () => void;
  agentType: number;
  data: {
    office: { id: number; name: string };
    agent: { id: number; firstName: string; lastName: string } | null;
    amount: number;
    branch: { id: number; name: string };
    comment: string;
    id: number;
    type: number;
    createdAt: number;
    status: number;
  };
  isFetching: boolean;
  turnOnCharge: boolean;
  turnOffCharge: boolean;
  handleSwitchClick: (checked: boolean, rowId?: string | number) => void;
  canEditAdvanceOrCharge: boolean;
}

export const AdvanceChargeView = ({
  data,
  isFetching,
  edit,
  handleChangeMode,
  handleSwitchClick,
  turnOffCharge,
  turnOnCharge,
  canEditAdvanceOrCharge,
}: IAdvanceChargeViewProps) => {
  const {
    searchParams: { id, card },
  } = useDetectedParams();

  const defineAdvanceChargeTitle = () => {
    if (data?.agent?.id) {
      return `${SUBJECT.AGENT}: ${data?.agent?.firstName} ${data?.agent?.lastName}`;
    } else if (data?.branch?.id) {
      return `${SUBJECT.BRANCH}: ${data?.branch?.name}`;
    } else if (data?.office?.id) {
      return `${SUBJECT.OFFICE}: ${data?.office?.name}`;
    } else {
      return SUBJECT.FULL_INFO;
    }
  };

  const isInCurrentMonth =
    changeTimezoneToUs(new Date()).getMonth() === changeTimezoneToUs(new Date(data?.createdAt)).getMonth();

  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetching} />
      <ViewItemWrapper
        title={defineAdvanceChargeTitle()}
        Component={
          edit &&
          canEditAdvanceOrCharge &&
          isInCurrentMonth && (
            <Button onClick={handleChangeMode} children={SUBJECT.EDIT} className="viewDrawerEditBtn" />
          )
        }
      >
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.TYPE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.type} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AMOUNT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatNumberWithThousandsSeparatorAndDecimal(data?.amount, true)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          {card === OtherServiceFeeCards.OTHER_SERVICE_FEE &&
            canEditAdvanceOrCharge &&
            (turnOffCharge || turnOnCharge) &&
            isInCurrentMonth && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Switch
                      checked={!data?.status || false}
                      onChange={(checked: boolean) => handleSwitchClick(checked, id)}
                      disabled={(!data?.status && !turnOffCharge) || (!!data?.status && !turnOnCharge)}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          {data?.comment && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.REASON} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" children={data?.comment} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
    </div>
  );
};
