const CopyIcon = ({ active }: { active?: boolean | '' | null }) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill={active ? '#379fff' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49951 16.8623C1.16618 16.8623 0.874512 16.7373 0.624512 16.4873C0.374512 16.2373 0.249512 15.9456 0.249512 15.6123V3.0498H1.49951V15.6123H11.3745V16.8623H1.49951ZM3.99951 14.3623C3.66618 14.3623 3.37451 14.2373 3.12451 13.9873C2.87451 13.7373 2.74951 13.4456 2.74951 13.1123V1.44563C2.74951 1.1123 2.87451 0.820633 3.12451 0.570633C3.37451 0.320633 3.66618 0.195633 3.99951 0.195633H13.1662C13.4995 0.195633 13.7912 0.320633 14.0412 0.570633C14.2912 0.820633 14.4162 1.1123 14.4162 1.44563V13.1123C14.4162 13.4456 14.2912 13.7373 14.0412 13.9873C13.7912 14.2373 13.4995 14.3623 13.1662 14.3623H3.99951ZM3.99951 13.1123H13.1662V1.44563H3.99951V13.1123Z"
        fill={active ? '#379fff' : '#667695'}
      />
    </svg>
  );
};
export default CopyIcon;
