import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import Select from 'ui/inputs/select/Select';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { floatNumberFormatterWithMinus, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { useDetectedParams } from '../../../../../../hooks/useDetectedParams';
import { PAYMENT_PLAN_OPTIONS, SUBJECT } from '../constants/constants';

import styles from './Collection.module.scss';

export const Collection = ({
  amount,
  isCreateLoading,
  isCollectionFetching,
  control,
  errors,
  setValue,
  trigger,
  hasPermissionToChangePlan,
  isCurrentMonth,
  watch,
}: any) => {
  const dispatch = useDispatch();

  const { searchParams } = useDetectedParams();
  const { mine } = searchParams;

  useEffect(() => {
    dispatch(
      setIsValid({
        isValid: !isCreateLoading,
      })
    );
  }, [isCreateLoading]);

  return (
    <ViewItemWrapper title={SUBJECT.PAYMENT_PLAN}>
      <MainLoader isFetching={isCollectionFetching} />
      <form>
        <Row className={styles.chargebackContainer} justify="space-between" align="middle">
          <Col className={styles.subHeaderSummary}>
            <Typography variant="heading" children={SUBJECT.AMOUNT} />
          </Col>
          <Col className={styles.subHeaderSummary}>
            <Typography
              variant="heading"
              className={clsx(styles.percentage, {
                [styles.negative]: amount < 0,
              })}
              children={formatNumberWithThousandsSeparatorAndDecimal(amount, true)}
            />
          </Col>
        </Row>
        <Row className={styles.paymentPlanSection} align="middle" gutter={[10, 0]}>
          <Col span={5}>
            <Controller
              name="paymentPlan"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    isDisabled={!(hasPermissionToChangePlan && isCurrentMonth && mine !== 'true')}
                    options={PAYMENT_PLAN_OPTIONS}
                    placeholder={SUBJECT.PAYMENT_PLAN}
                    name={field.name}
                    errors={errors}
                    onBlur={() =>
                      setTimeout(() => {
                        trigger(field.name);
                      }, 300)
                    }
                    onChange={(value: string | string[]) =>
                      setValue('paymentPlan', String(value), {
                        shouldDirty: true,
                        shouldValidate: true,
                        shouldTouch: true,
                      })
                    }
                    value={field.value}
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.paymentPlan} />
          </Col>
          {watch('paymentPlan') > 1 && (
            <Col className={styles.differenceBlock}>
              <Typography
                variant="heading"
                className={clsx(styles.difference, {
                  [styles.negative]: amount < 0,
                })}
                children={formatNumberWithThousandsSeparatorAndDecimal(
                  floatNumberFormatterWithMinus(String(amount / Number(watch('paymentPlan'))))
                )}
              />
            </Col>
          )}
        </Row>
      </form>
    </ViewItemWrapper>
  );
};
