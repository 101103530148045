export enum SUBJECT {
  CONTACT = 'Contact',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  USER_NAME = 'User Name',
  PHONE_NUMBER = 'Phone Number',
  EMAIL = 'E-mail',
  LEVEL = 'Level',
  POSITION = 'Position',
  EDIT = 'Edit',
  DELETE = 'Delete',
  DATE = 'Date',
}

export const mockData = {
  first_name: 'Jonathan',
  last_name: 'Wainwright',
  user_name: 'Remington',
  phone_number: '776-54-46977',
  email: 'remington@gmail.com',
  workplace: 'Corporate',
  position: 'Manager',
};
