import React, { ForwardedRef, forwardRef, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { RefSelectProps, Tooltip, TreeSelect } from 'antd';
import clsx from 'clsx';
import useDebounce from 'hooks/useDebounce';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { ChangeEventExtra } from 'rc-tree-select/lib/TreeSelect';
import Typography from 'ui/typography/Typography';
import { getHighlightedOptions } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';

import Checkbox from '../checkbox/Checkbox';

import { SUBJECT } from './constants/constants';
import { ISelectProps } from './constants/types';

import styles from './Select.module.scss';

const { ALL, All_VALUE } = SUBJECT;
const Select = forwardRef(
  (
    {
      isSearchable,
      withCheckbox,
      isMultiSelect,
      options,
      placeholder,
      label,
      withAll,
      allowClear,
      name,
      suffixIcon,
      isDisabled,
      onChange,
      value,
      treeDefaultExpandAll,
      required,
      errors,
      offset,
      setOffset,
      expandedId,
      setValue,
      resetFunc,
      withMarking,
      isAutocomplete,
      isFilter,
      disableFirstOption,
      count,
      ...props
    }: ISelectProps,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const debounceValue = useDebounce(searchValue, 500);
    const { searchParams, setSearchParams } = useDetectedParams();

    const handleSearch = (value: string) => {
      setIsSearching(true);
      setSearchValue(value);
      if (onChange) {
        onChange(value);
      }
    };

    useEffect(() => {
      if (isSearching && (debounceValue?.length >= 2 || !debounceValue?.length) && typeof searchValue === 'string') {
        setOffset((prev: any) => ({ ...prev, search: debounceValue, limit: 10 }));
      }
    }, [debounceValue]);

    const [selectedValues, setSelectedValues] = useState<string[]>(value || []);

    const allIds = options?.map(({ value }) => (value ? value!.toString() : ''));

    const selectRef = useRef<RefSelectProps>(null);

    const tagPlaceholder = useMemo(() => {
      return (withAll && isMultiSelect) || isMultiSelect ? (
        !!selectedValues?.length && (
          <div className={styles.selectedItems}>
            +
            {isAutocomplete && selectedValues.includes(All_VALUE)
              ? count
              : selectedValues?.filter(el => el !== All_VALUE)?.length}
            <div role="button" onClick={() => onChange && onChange([])}>
              <CloseIcon />
            </div>
          </div>
        )
      ) : (
        <div className={styles.selectedItems}>
          <Typography variant="paragraph">+{selectedValues?.length}</Typography>
          <div role="button" onClick={() => onChange && onChange([])}>
            <CloseIcon />
          </div>
        </div>
      );
    }, [selectedValues?.length, value?.length, count]);

    const handleChange = (value: string[], label: ReactNode[], extra: ChangeEventExtra) => {
      const triggerValue = extra.triggerValue?.toString();
      setTimeout(() => {
        selectRef?.current?.blur();
      }, 0);
      if (withAll && isMultiSelect) {
        if (triggerValue === All_VALUE) {
          setSelectedValues((prev: any) => (prev?.includes(triggerValue) ? [] : [...allIds, All_VALUE]));
        } else {
          const filtered = value?.filter(el => el !== All_VALUE);
          setSelectedValues(filtered);
        }
      } else {
        setSelectedValues(value);
      }
      if (onChange && isMultiSelect) {
        if (triggerValue === All_VALUE && selectedValues?.includes(All_VALUE)) {
          onChange([]);
        } else if (triggerValue === All_VALUE) {
          onChange(allIds, triggerValue);
        } else {
          const filtered = value?.filter(el => el !== All_VALUE);
          onChange(filtered.length ? filtered : value, triggerValue);
        }
      } else if (onChange) {
        onChange(value, triggerValue);
      }
      if (!isMultiSelect) {
        selectRef.current?.blur();
      }
      isFilter &&
        value !== selectedValues &&
        setSearchParams({
          ...searchParams,
        });
    };

    const handleCustomDropdownChange = (value: string) => {
      if (value === All_VALUE) {
        const selectedValuesWithoutAll = selectedValues.filter(el => el !== All_VALUE);
        if (selectedValuesWithoutAll?.length === options?.length) {
          setSelectedValues([]);
          if (onChange) {
            onChange([]);
          }
        } else {
          if (onChange) {
            onChange([...allIds, All_VALUE]);
          }
          setSelectedValues(() => [...allIds, All_VALUE]);
        }
      } else {
        if (selectedValues?.includes(value)) {
          const filtered = selectedValues?.filter(el => el !== All_VALUE && el !== value);
          setSelectedValues(filtered);
          onChange && onChange(filtered);
        } else {
          setSelectedValues(prev => [...prev, value]);
          onChange && onChange([...selectedValues, value]);
        }
      }
      isFilter &&
        setSearchParams({
          ...searchParams,
        });
    };

    const handleScroll = (event: any) => {
      const { target } = event;
      if (target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight) {
        if (options?.length < offset) {
          setOffset((prev: any) => {
            return { ...prev, limit: prev.limit + 10 };
          });
        }
      }
    };
    const dropdownRef = useRef<HTMLInputElement>(null);

    const dropdown = () => {
      return (
        <div className={styles.customDropDown} onScroll={handleScroll} ref={dropdownRef}>
          {!options?.length ? (
            <div className={styles.noResultFound}>
              <Typography variant="paragraph">{SUBJECT.NO_DATA}</Typography>
            </div>
          ) : (
            <>
              {withAll && isMultiSelect
                ? [{ title: ALL, value: All_VALUE }, ...options]?.map(({ value, title, children }) => {
                    const highlightedValues = getHighlightedOptions(String(searchValue || ''), String(title));
                    return (
                      <div
                        key={value}
                        className={clsx(styles.dropdownItem, styles.checkboxMainItem, {
                          [styles.checkboxItem]: isMultiSelect,
                        })}
                      >
                        {isMultiSelect ? (
                          <Checkbox
                            checked={
                              selectedValues?.includes(value as string) ||
                              (withAll && options?.length === selectedValues?.length)
                            }
                            onChange={() => handleCustomDropdownChange(value as string)}
                            children={
                              highlightedValues?.length && withMarking ? (
                                <>
                                  {highlightedValues[0]}
                                  <span className={styles.searchResultKeyword}>{highlightedValues[1]}</span>
                                  {highlightedValues[2]}
                                </>
                              ) : (
                                <span>{title}</span>
                              )
                            }
                            size="small"
                          />
                        ) : (
                          <Typography
                            variant="paragraph"
                            key={value}
                            className={clsx({
                              [styles.unhovered]: children?.length,
                            })}
                            onClick={() => handleChange(value as any, title as any, value as any)}
                          >
                            {title}
                          </Typography>
                        )}
                      </div>
                    );
                  })
                : isMultiSelect
                ? options?.map(({ value, title, children }, index) => {
                    const highlightedValues = getHighlightedOptions(String(searchValue || ''), String(title));

                    return (
                      <div
                        key={value}
                        className={clsx(styles.dropdownItem, {
                          [styles.checkboxItem]: isMultiSelect,
                          [styles.disableFirstElem]: disableFirstOption,
                        })}
                      >
                        {isMultiSelect ? (
                          <Tooltip
                            color="white"
                            title={disableFirstOption && index === 0 ? 'First uncheck the Actions field' : ''}
                            placement="top"
                          >
                            <Checkbox
                              disabled={disableFirstOption && index === 0}
                              checked={selectedValues?.includes(value as string)}
                              onChange={() => handleCustomDropdownChange(value as string)}
                              children={
                                <>
                                  {highlightedValues?.length && withMarking ? (
                                    <>
                                      {highlightedValues[0]}
                                      <span className={styles.searchResultKeyword}>{highlightedValues[1]}</span>
                                      {highlightedValues[2]}
                                    </>
                                  ) : (
                                    <span>{title}</span>
                                  )}
                                </>
                              }
                              size="small"
                            />
                          </Tooltip>
                        ) : (
                          <Typography
                            variant="paragraph"
                            key={value}
                            className={clsx({
                              [styles.unhovered]: children?.length,
                            })}
                            onClick={() => handleChange(value as any, title as any, value as any)}
                          >
                            {highlightedValues?.length && withMarking ? (
                              <>
                                {highlightedValues[0]}
                                <span className={styles.searchResultKeyword}>{highlightedValues[1]}</span>
                                {highlightedValues[2]}
                              </>
                            ) : (
                              <span>{title}</span>
                            )}
                          </Typography>
                        )}
                      </div>
                    );
                  })
                : withAll
                ? [{ title: ALL, value: All_VALUE }, ...options].map(({ value, title, children }) => {
                    return (
                      <div className={styles.dropdownItem} key={value}>
                        <Typography
                          variant="paragraph"
                          key={value}
                          className={clsx({
                            [styles.unhovered]: children?.length,
                          })}
                          onClick={
                            children?.length ? undefined : () => handleChange(value as any, title as any, value as any)
                          }
                        >
                          {title}
                        </Typography>
                        {children && (
                          <ul>
                            {children?.map(item => {
                              return (
                                <li
                                  key={item.value}
                                  onClick={() => handleChange(item.value as any, item.title as any, item.value as any)}
                                >
                                  {item.title}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })
                : options?.map(({ value, title, children }) => {
                    return (
                      <div className={styles.dropdownItem} key={value}>
                        <Typography
                          variant="paragraph"
                          key={value}
                          className={clsx({
                            [styles.unhovered]: children?.length,
                          })}
                          onClick={
                            children?.length ? undefined : () => handleChange(value as any, title as any, value as any)
                          }
                        >
                          {title}
                        </Typography>
                        {children && (
                          <ul>
                            {children?.map(item => {
                              return (
                                <li
                                  key={item.value}
                                  onClick={() => handleChange(item.value as any, item.title as any, item.value as any)}
                                >
                                  {item.title}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      );
    };

    useEffect(() => {
      if (selectedValues?.length === options?.length && withAll && isMultiSelect && options?.length) {
        setSelectedValues(prev => [...prev, All_VALUE]);
      }
    }, [selectedValues?.length, options?.length]);

    useEffect(() => {
      if (!value?.length && !Array.isArray(value) && !isAutocomplete) {
        setSelectedValues(value?.toString());
      }
    }, [value]);

    useEffect(() => {
      if (Array.isArray(value) && value?.length && withAll) {
        setSelectedValues(value);
      }
      if (Array.isArray(value) && value?.length && !withAll) {
        setSelectedValues(value);
      }
      if (Array.isArray(value) && !value?.length) {
        setSelectedValues([]);
      }
    }, [value]);

    const handleReset = () => {
      setSearchValue('');
      if (setValue) {
        setValue(name, '');
      }
      if (onChange) {
        onChange('');
      }
      if (resetFunc) {
        resetFunc();
      }
    };
    return (
      <div className={styles.selectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          id={expandedId ? `select_${name}${expandedId}` : `select_${name}`}
          className={clsx(styles.selectWrapper, {
            [styles.selected]: selectedValues?.length,
            [styles.required]: required,
            [styles.withSubChild]: treeDefaultExpandAll,
            [styles.error]: errors && errors[name || ''],
          })}
        >
          <TreeSelect
            {...props}
            showArrow
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            getPopupContainer={
              expandedId
                ? () => document.getElementById(`select_${name}${expandedId}`)!
                : () => document.getElementById(`select_${name}`)!
            }
            ref={selectRef || ref}
            showSearch={isSearchable || false}
            treeCheckable={withCheckbox || false}
            multiple={isMultiSelect || false}
            placeholder={label ? placeholder : null}
            {...(allowClear ? { onClear: handleReset, clearIcon: <CloseIcon />, allowClear } : {})}
            suffixIcon={!(allowClear && value && selectedValues?.length) ? suffixIcon : <CloseIcon />}
            value={isMultiSelect ? selectedValues : value}
            disabled={isDisabled || false}
            maxTagCount={0}
            maxTagPlaceholder={() => tagPlaceholder}
            className={clsx(styles.select)}
            onSearch={handleSearch}
            onChange={(value, label, extra) => handleChange(value, label, extra)}
            treeData={
              options
                ? withAll || (isMultiSelect && withAll)
                  ? [{ title: ALL, value: All_VALUE }, ...options]
                  : options
                : []
            }
            treeDefaultExpandAll={treeDefaultExpandAll}
            dropdownRender={dropdown}
          />
          {!label && (
            <Typography variant="paragraph" className={`placeholder-select ${styles.placeholder}`}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

export default Select;
