import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';

import ChevronIcon from 'components/svgs/ChevronIcon';

import { useDetectedParams } from '../../../../../../hooks/useDetectedParams';
import { headerSearchOptions } from '../../constants/constants';
import { IHeaderSearcSelectProps } from '../../constants/types';
import { Select } from '../../hoc-instantce/instance';
import { SUBJECT } from '../header-search/constants/constants';

import styles from './HeaderSelect.module.scss';

const HeaderSelect = ({ filterControl }: Partial<IHeaderSearcSelectProps>) => {
  const loadPermissions = usePermittedActions('loads.loads');
  const { isFetching: isPermissionFetching } = useSelector(selectUserPermissionsInfo);

  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const { searchParams } = useDetectedParams();
  const { mode, tab } = searchParams;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isPermissionFetching) {
        setIsLocalLoading(false);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [mode, isPermissionFetching, tab]);

  return (
    <div className={styles.headerSelect}>
      <Select
        name="header_select"
        {...(isLocalLoading ? { value: SUBJECT.LOADING } : {})}
        suffixIcon={<ChevronIcon />}
        control={filterControl}
        options={headerSearchOptions(loadPermissions?.view)}
      />
    </div>
  );
};

export default HeaderSelect;
