import { ReactNode } from 'react';
import { formatDate } from 'utils/dates';

import { CreatorTypes, links } from '../constants';
import { EMessageTypes, Handler, ISocketMessage } from '../interfaces';
import Notification from '../notification/Notification';

export class LoadHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      createCredit: this.handleCreateCredit,
      create: this.handleCreate,
      createTonu: this.handleCreateTonu,
      addSecondAgent: this.handleAddSecondAgent,
      deleteFile: this.handleDeleteFile,
      editReferenceNumber: this.handleEditReferenceNumber,
      claim: this.handleClaim,
      unclaim: this.handleUnclaim,
      createTransaction: this.handleCreateTransaction,
      deleteTransaction: this.handleDeleteTransaction,
      createComment: this.handleCreateComment,
      deleteComment: this.handleDeleteComment,
      cancel: this.handleCancel,
      sendToBilling: this.handleSendToBilling,
      reject: this.handleReject,
      acceptToBilling: this.handleAcceptToBilling,
      carrierPaySingle: this.handleCarrierPaySingle,
      carrierPayMultiply: this.handleCarrierPayMultiply,
      customerReceiveSinglePartial: this.handleCustomerReceiveSinglePartial,
      customerReceiveSingleRemaining: this.handleCustomerReceiveSingleRemaining,
      customerReceiveSingleFull: this.handleCustomerReceiveSingleFull,
      customerReceiveMultiple: this.handleCustomerReceiveMultiple,
      toRetrieved: this.handleToRetrieved,
      addPickup: this.handleAddPickup,
      editPickup: this.handleEditPickup,
      deletePickup: this.handleDeletePickup,
      addDropOff: this.handleAddDropOff,
      editDropOff: this.handleEditDropOff,
      deleteDropOff: this.handleDeleteDropOff,
      creditApproved: this.handleCreateApproved,
      creditCOD: this.handleCreateCOD,
      creditConsignment: this.handleCreateConsignment,
      creditDeny: this.handleCreateDeny,
      addCarrier: this.handleAddCarrier,
      editPodFile: this.handleAddPOD,
      deleteFileFirstAgent: this.handleDeleteFileFirstAgent,
    };
  }
  handleCreateCredit = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'made a request credit' },
        ]}
        title="Request credit created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'added load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Load created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleCreateTonu = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'created Tonu load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Tonu load created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleAddSecondAgent = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'selected the second agent' },
          { id: entity?.id, title: entity!.title, link: `${links.agents}${id}` },
          { title: 'for load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Load second agent selected"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleDeleteFile = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted file of load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Load file deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditReferenceNumber = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed reference number from ${additionalData[0]} into ${additionalData[1]} for load` },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Load reference number changed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleClaim = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'marked load' },
          { id, title, link: `${links.loads}${id}` },
          { title: 'as claim' },
        ]}
        title="Claim load"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleUnclaim = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'resolved load' },
          { id, title, link: `${links.loads}${id}` },
          { title: 'that was marked as claim' },
        ]}
        title="Resolved load"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleCreateTransaction = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'created a transaction in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Transaction created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleDeleteTransaction = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted a transaction in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Transaction deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleCreateComment = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'added new a comment in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Load comment added"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleDeleteComment = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted new a comment in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Load comment deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleCancel = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'canceled load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Load canceled"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleSendToBilling = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'sent load' },
          { id, title, link: `${links.loads}${id}` },
          { title: 'to billing' },
        ]}
        title="Load sent to billing"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleReject = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'rejected load' },
          { id, title, link: `${links.loads}${id}` },
          { title: 'that was sent to billing' },
        ]}
        title="Load rejected"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleAcceptToBilling = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'sent load' },
          { id, title, link: `${links.loads}${id}` },
          { title: 'to payables and receivables' },
        ]}
        title="Load accepted to billing"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCarrierPaySingle = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'paid carrier' },
          { id: entity?.id, title: entity!.title, link: `${links.carriers}${entity?.id}` },
          { title: 'for load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Carrier paid"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCarrierPayMultiply = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id: _id, title: _title },
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'paid carrier' },
          { id: entity?.id, title: entity!.title, link: `${links.carriers}${entity?.id}` },
          { title: 'for loads' },
        ]}
        title="Carrier paid"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCustomerReceiveSinglePartial = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'received money partially from customer' },
          { id: entity?.id, title: entity!.title, link: `${links.customers}${entity?.id}` },
          { title: 'for loads' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Customer received"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCustomerReceiveSingleRemaining = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'received remaining from customer' },
          { id: entity?.id, title: entity!.title, link: `${links.customers}${entity?.id}` },
          { title: 'for loads' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Customer received"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCustomerReceiveSingleFull = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'received money completely from customer' },
          { id: entity?.id, title: entity!.title, link: `${links.customers}${entity?.id}` },
          { title: 'for load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Customer received"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCustomerReceiveMultiple = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id: _idReceive, title: _titleReceive },
      entity,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'received money completely from customer' },
          { id: entity?.id, title: entity!.title, link: `${links.customers}${entity?.id}` },
          { title: `for ${additionalData[1]} loads` },
        ]}
        title="Customer received"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleToRetrieved = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      entity,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'created a new plus(or minus) transaction in customer' },
          { id: entity?.id, title: entity!.title, link: `${links.customers}${entity?.id}` },
          { title: 'for load' },
          { id, title, link: `${links.loads}${id}` },
          { title: 'and attached load to billing' },
        ]}
        title="Retrieved"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleAddPickup = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'created a new pick up in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Pick up created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditPickup = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'edited a pick up in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Pick up edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleDeletePickup = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted a pick up from load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Pick up deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleAddDropOff = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'created a new drop off in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Drop off created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditDropOff = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'edited a drop off in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Drop off edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleDeleteDropOff = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted a drop off in load' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Drop off deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleCreateApproved = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'approved request credit and gave status approve' },
          { id, title, link: `${links.new_load}${id}` },
        ]}
        title="Credit approved"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };

  handleCreateDeny = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'denied request credit' },
          { id, title, link: `${links.new_load}${id}` },
        ]}
        title="Credit denied"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleCreateCOD = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'approved request credit and gave status COD' },
          { id, title, link: `${links.new_load}${id}` },
        ]}
        title="Credit COD"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleCreateConsignment = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'approved request credit and gave status consignment' },
          { id, title, link: `${links.new_load}${id}` },
        ]}
        title="Credit consignment"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleAddCarrier = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'added carrier in load ' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="Carrier added"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleAddPOD = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { id, title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'added POD file in load ' },
          { id, title, link: `${links.loads}${id}` },
        ]}
        title="POD file uploaded"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleDeleteFileFirstAgent = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      createdAt,
      event: { title },
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'removed the' },
          { title },
          { title: 'file you added' },
        ]}
        title="POD file uploaded"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
}
