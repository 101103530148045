import React from 'react';

const NotificationIcon = () => {
  return (
    <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.650391 12.0218C0.650391 10.1018 1.04601 8.28251 1.83724 6.56399C2.62847 4.84548 3.75714 3.38177 5.22324 2.17288L6.65444 3.77288C5.42105 4.79214 4.4611 6.01881 3.77459 7.45288C3.08808 8.88696 2.74483 10.4099 2.74483 12.0218H0.650391ZM23.4448 12.0218C23.4448 10.4099 23.119 8.88696 22.4674 7.45288C21.8158 6.01881 20.8733 4.79214 19.64 3.77288L21.0711 2.17288C22.514 3.40548 23.6194 4.87511 24.3873 6.58177C25.1553 8.28844 25.5393 10.1018 25.5393 12.0218H23.4448ZM1.90705 25.0707V22.9373H4.83926V12.0573C4.83926 10.0662 5.41523 8.29436 6.56717 6.74177C7.71911 5.18918 9.23758 4.2114 11.1226 3.80844V2.77733C11.1226 2.23214 11.3146 1.78177 11.6985 1.42622C12.0825 1.07066 12.5421 0.892883 13.0774 0.892883C13.6126 0.892883 14.0722 1.07066 14.4562 1.42622C14.8402 1.78177 15.0322 2.23214 15.0322 2.77733V3.80844C16.9172 4.2114 18.4415 5.18918 19.605 6.74177C20.7686 8.29436 21.3504 10.0662 21.3504 12.0573V22.9373H24.2477V25.0707H1.90705ZM13.0774 29.3373C12.3327 29.3373 11.6811 29.0588 11.1226 28.5018C10.5641 27.9447 10.2848 27.2751 10.2848 26.4929H15.87C15.87 27.2751 15.5965 27.9447 15.0496 28.5018C14.5028 29.0588 13.8453 29.3373 13.0774 29.3373Z"
        fill="var(--black)"
      />
    </svg>
  );
};

export default NotificationIcon;
