import { ReactNode } from 'react';
import { formatDate } from 'utils/dates';

import { CreatorTypes, links } from '../constants';
import { EMessageTypes, Handler, ISocketMessage } from '../interfaces';
import Notification from '../notification/Notification';

export class BranchHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      create: this.handleCreate,
      edit: this.handleEdit,
      activate: this.handleActivate,
      terminate: this.handleTerminate,
    };
  }
  handleCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.branches}${id}` },
          { title: 'branch was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Branch created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };

  handleEdit = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.branches}${id}` },
          { title: 'branch was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Branch edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };

  handleActivate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.branches}${id}` },
          { title: 'branch was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Branch activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };

  handleTerminate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.branches}${id}` },
          { title: 'branch was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Branch terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
}
