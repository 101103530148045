import dayjs from 'dayjs';
import { formatDate } from 'utils/dates';
import { changeTimeZone } from 'utils/helpers';

import { IPaymentListItemResponse, IPaymentListResponse } from './types';

export const getPaymentsParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();

  const currentMonthEnd = dayjs(Number(params.dateStart)).endOf('month').valueOf();
  const endDateMonthEnd = dayjs(Number(params.dateEnd)).endOf('month').valueOf();

  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  if (params.level) {
    queryParams.append('level', String(params.level));
  }
  if (params.officeIds?.length) {
    params.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
  }
  if (params.branchIds?.length) {
    params.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
  }
  if (params.agentIds?.length) {
    params.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
  }
  if (params.dateStart) {
    queryParams.append('dateStart', String(changeTimeZone(new Date(Number(params.dateStart)), 'America/Los_Angeles')));
  }
  if (params.dateEnd) {
    queryParams.append('dateEnd', changeTimeZone(new Date(endDateMonthEnd), 'America/Los_Angeles'));
  } else if (params.dateStart) {
    queryParams.append('dateEnd', changeTimeZone(new Date(Number(currentMonthEnd)), 'America/Los_Angeles'));
  }
  return queryParams;
};

export const getPaymentListSerializer = (data: IPaymentListResponse) => {
  const result = data?.result?.map((item: IPaymentListItemResponse) => {
    return {
      createdFor: formatDate(item?.createdFor, 'MM-YYYY'),
      id: item?.id,
      agency: item?.office?.name,
      branch: item?.branch?.name,
      agent: item?.agent?.username ? `${item?.agent?.username}` : '',
      paidAs: item?.paidBy?.username,
      paidAt: item?.paidAt,
      grossProfit: item?.grossProfit,
      charges: item?.charges,
      credit: item?.credits,
      final: item?.final,
      status: item?.status,
      others: item?.others,
      overallPay: item?.overallPay,
    };
  });

  return {
    total: data?.total,
    count: data?.count,
    result,
  };
};
