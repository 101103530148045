import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { transformStatesData } from './helpers';

export const statesAPI = createApi({
  reducerPath: 'statesAPI',
  baseQuery,
  tagTypes: ['States'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getStates: builder.query({
      query: () => 'states',
      providesTags: ['States'],
      transformResponse: ({ result }) => result && transformStatesData(result),
    }),

    getState: builder.query({
      query: id => `states/${id}`,
      providesTags: ['States'],
    }),
  }),
});

export const { useGetStatesQuery, useGetStateQuery } = statesAPI;
