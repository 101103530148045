import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'api/useWebSocket';
import { selectAuth, selectUserInfo } from 'store/user-slice/selector';
import { updateAuth } from 'store/user-slice/user';

import Router from './components/routes/Router';
import { getDefaultTheme } from './ui/inputs/theme-switcher/constants/helpers';
import { messageForInspect } from './FreightMax';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectAuth);
  const { isOpen, sendMessage, sendAgentsActivityWatchMessage } = useWebSocket();

  const { modeType } = useSelector(selectUserInfo);

  useEffect(() => {
    messageForInspect();
    const defaultTheme = localStorage.getItem('default-theme') || getDefaultTheme();

    if (!modeType && !token) {
      document.documentElement.classList.remove('dark', 'light');
      document.documentElement.classList.add(defaultTheme);
      return;
    }
  }, []);

  useEffect(() => {
    dispatch(updateAuth({ token: localStorage.getItem('token') }));
  }, [localStorage.getItem('token')]);

  useEffect(() => {
    if (token && isOpen) {
      sendMessage(token);
    } else if (!isOpen) {
      sendMessage();
    }
  }, [token?.length, isOpen]);

  return (
    <div className="App">
      <Router sendAgentsActivityWatchMessage={sendAgentsActivityWatchMessage} />
    </div>
  );
};

export default App;
