// this functionality we can test after backend integration
export const getHighlightedOptions = (phaseWords: string, fullWords = '') => {
  const res: string | number[] = [];
  const result = [];
  const words = fullWords;
  const phase = phaseWords?.at(-1) === ' ' ? phaseWords?.trim() : phaseWords;

  for (let i = 0, j = phase?.length; i < words?.length; i++) {
    const firstIndex = i;
    while (j > 0) {
      if (words[i]?.toLocaleLowerCase() !== phase[phase.length - j]?.toLocaleLowerCase()) break;
      j--;
      i++;
    }
    if (j === 0) {
      res.push(i - phase.length, i - 1);
      result.push(words.slice(0, res[0]));
      if (res[1] === words.length - 1) {
        result.push(words.slice(res[0]));
      } else {
        result.push(words.slice(res[0], res[1] + 1));
        result.push(words.slice(res[1] + 1, words.length));
      }
      return result;
    }
    i = firstIndex;
    j = phase?.length;
  }

  return res;
};
