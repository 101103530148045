import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import clsx from 'clsx';

import { SUBJECT } from '../../../../../components/payment-term-datagrid/constants/constants';
import { BlueInfoIcon } from '../../../../../components/svgs/BlueInfoIcon';
import Typography from '../../../../../ui/typography/Typography';

import styles from './InfoPopOver.module.scss';

const FactoredPopover = ({ data, size = '28', isFetching = false, disabled = false }: any) => {
  return (
    <Col>
      <Popover
        overlayClassName={clsx(styles.popoverOverlay, {
          [styles.popoverOverlayError]: disabled,
        })}
        placement="top"
        overlayStyle={{
          width: disabled ? 305 : 725,
        }}
        content={
          disabled ? (
            <>
              <Row align="middle" gutter={[8, 24]}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Typography
                        variant="paragraph"
                        children={'At First Please Select Factoring Company'}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row align="middle" gutter={[8, 24]}>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.PAYMENT_COMPANY_NAME}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography variant="paragraph" children={data?.companyName} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography variant="paragraph" children={data?.email} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography variant="paragraph" children={data?.city} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography variant="paragraph" children={data?.address} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography variant="paragraph" children={data?.state?.name} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
                {!!data?.secondAddress && (
                  <Col span={12}>
                    <Row align="middle" gutter={8}>
                      <Col span={12}>
                        <Typography
                          variant="heading"
                          children={SUBJECT.SECOND_ADDRESS}
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col span={12}>
                        <Typography
                          variant="paragraph"
                          children={data?.secondAddress}
                          className={styles.infoParagraph}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.ZIP} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography variant="paragraph" children={data?.zip} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )
        }
      >
        <div className={styles.popoverIcon}>
          {isFetching ? <LoadingOutlined /> : <BlueInfoIcon size={size} disabled={disabled} />}
        </div>
      </Popover>
    </Col>
  );
};
export default FactoredPopover;
