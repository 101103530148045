import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import { TSkipLimit } from 'pages/loads/tabs/new-load/constants/types';
import { ProfileActivePages } from 'pages/profile/constants/constants';
import { authoritiesAPI, useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import {
  useCreateCommentMutation,
  useCreateCustomerMutation,
  useDeleteCommentMutation,
  useGetBlackListByIdQuery,
  useGetBlackListQuery,
  useGetCommentsQuery,
  useGetCustomersQuery,
  useGetDetailedCustomerQuery,
  useGetPermentTermsQuery,
  useUpdateCustomerMutation,
} from 'services/profile/customers/customers';
import { IGetCustomersParams, IPaymentTermsParams } from 'services/profile/customers/interfaces';
import { useGetStatesQuery } from 'services/states/states';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatPhone } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import CustomersBlacklistView from './components/blacklist-view/CustomersBlacklistView';
import CommentsView from './components/comments-view/CommentsView';
import CreateCustomerForm from './components/create-form/CreateCustomersForm';
import CustomersViews from './components/customers-view/CustomersView';
import EditCustomersForm from './components/edit-form/EditCustomersForm';
import { defaultValues, filterDefaultValues, SUBJECT } from './constants/constants';
import { ICustomersFormData, ICustomersParams, ICustomerTypeFilter } from './constants/types';
import { validation } from './constants/validation';
import CustomersBlacklistTable from './tabs/blacklist-table/CustomersBlacklistTable';
import CustomersTable from './tabs/customer-table/CustomerTable';

const useCustomers = ({ setExcelParams }: ICustomersParams) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);

  const { employeeLevel, userType, permissionsInfo } = useSelector(selectUserPermissionsInfo);

  const hasPermissionToPaymentTerms = usePermittedActions('profiles.customers.customerTable');

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, activePage, id, open, headerFilter } = searchParams;

  const inSearchPage = window.location.pathname === '/search-result';

  const [getPaymentTerms, setGetPaymentTerms] = useState<Partial<IPaymentTermsParams>>({
    search: '',
    skip: 0,
    limit: 20,
    order: 1,
    orderBy: '',
  });

  useEffect(() => {
    if (open === 'false') {
      setGetPaymentTerms((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [open]);

  const [authoritiesFilter, setAuthoritiesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: [],
    ignoreCustomerId: null,
  });

  const [getCustomersFilter, setCustomersFilter] = useState<Partial<IGetCustomersParams>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const [getBlackListFilter, setBlackListFilter] = useState<Partial<IGetCustomersParams>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const {
    data: customersData = [],
    isFetching,
    refetch,
  } = useGetCustomersQuery(getCustomersFilter, { skip: inSearchPage || activePage === 'customer-blacklist' });

  const { data: customersBlackList = [], isFetching: isBlackListFetching } = useGetBlackListQuery(getBlackListFilter, {
    skip: inSearchPage || activePage !== 'customer-blacklist',
  });

  const { data: statesData = [] } = useGetStatesQuery({}, { skip: mode === 'view' });

  const { currentData: customerById, isFetching: isFetchingById } = useGetDetailedCustomerQuery(id, {
    skip: !id || activePage === 'customer-blacklist' || (inSearchPage && headerFilter !== 'customers'),
  });

  const [getCommentsFilter, setCommentsFilter] = useState<TSkipLimit>({
    skip: 0,
    limit: 10,
  });

  const getMoreComments = () => {
    setCommentsFilter((prev: TSkipLimit) => ({ ...prev, limit: prev?.limit + 10 }));
  };

  const { data: customerComments, isFetching: isCustomerCommentsLoading } = useGetCommentsQuery(
    { id, ...getCommentsFilter },
    { skip: !id || activePage === 'customer-blacklist' || (inSearchPage && headerFilter !== 'customers') }
  );

  const isShowMoreVisible = useMemo(
    () => customerComments?.count > getCommentsFilter.limit,
    [customerComments, getCommentsFilter]
  );

  const { data: paymentTerms, isFetching: _isPaymentTermsLoading } = useGetPermentTermsQuery(
    { id, ...getPaymentTerms },
    {
      skip:
        !(
          (UserTypes.ADMIN === userType || EmployeeLevels.CORPORATE === employeeLevel) &&
          hasPermissionToPaymentTerms.view
        ) ||
        (inSearchPage && headerFilter !== 'customers') ||
        !id ||
        mode === 'add',
    }
  );

  const [createCustomer, { isLoading: isCreateLoading }] = useCreateCustomerMutation();
  const [updateCustomer, { isLoading: isEditLoading }] = useUpdateCustomerMutation();

  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const { data: customersColumnsData } = useGetColumnsQuery(
    { type: 'customers' },
    { skip: inSearchPage || activePage === 'customer-blacklist' }
  );
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const { data: columnsBlackList, isFetching: _isBlackListLoading } = useGetColumnsQuery(
    { type: 'customersBlackList' },
    { skip: activePage !== 'customer-blacklist' }
  );

  const { currentData: getBlackListById, isFetching: isFetchingBlackListById } = useGetBlackListByIdQuery(
    { id },
    { skip: !id || activePage !== 'customer-blacklist' || (inSearchPage && headerFilter !== 'customers') }
  );

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, dirtyFields, isSubmitted },
    reset,
    watch,
    setValue,
    resetField,
    clearErrors,
    setError,
    trigger,
  } = useForm<ICustomersFormData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(validation),
  });
  const paymentCheck = watch('paymentType');
  const type = watch('type');
  const mc = watch('mc');
  const addedMc = watch('addedMc');
  const addedMcIds = useMemo(() => addedMc?.map(el => el.id), [addedMc?.length]);

  const { data: authorities, refetch: refetchAuthoritiesShort } = useGetAuthoritiesShortQuery(authoritiesFilter, {
    skip: mode === 'view' || open === 'false',
  });

  useEffect(() => {
    if (mode === 'add') {
      setAuthoritiesFilter((prev: any) => {
        return {
          ...prev,
          ignoreAuthorityIds: addedMcIds,
        };
      });
    } else if (mode === 'edit') {
      setAuthoritiesFilter((prev: any) => {
        return {
          ...prev,
          ignoreCustomerId: id,
        };
      });
    }
  }, [addedMcIds?.length, mode, id]);

  const {
    control: filterControl,
    reset: filterReset,
    watch: filterWatch,
    setValue: setFilterValue,
    formState: { isDirty: isFilterDirty },
  } = useForm<ICustomerTypeFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const filterValue = filterWatch('customerType')?.toString();
  const filterQueryCheck = !!(typeof filterValue === 'string' && filterValue);

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      mode: 'view',
      ...(!inSearchPage && { id: '' }),
    });
    reset(defaultValues, { keepDirty: false, keepTouched: false, keepErrors: false });
    setAuthoritiesFilter({
      skip: 0,
      limit: 10,
    });
    setCommentsFilter({
      skip: 0,
      limit: 10,
    });
    clearErrors();
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(filterValue ? { customerType: filterValue } : {}),
      open: 'false',
      id: '',
    });
    dispatch(closeModal());
    resetForm();
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: activePage === 'customer-blacklist' ? 'customersBlackList' : 'customers',
    });

  const customerBlackListPagePermissions = usePermittedActions('profiles.customers.blackList');
  const customerTablePagePermissions = usePermittedActions('profiles.customers.customerTable');

  const { lock, unlock } = customerBlackListPagePermissions;
  const handleCancel = () => {
    if (Object.keys(dirtyFields).length) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CLOSE,
          onOk: isValid
            ? () => {
                onSubmit(watch());
                dispatch(closeModal());
                clearErrors();
              }
            : () => {
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(filterQueryCheck ? { customerType: filterValue } : {}),
        open: 'false',
        id: '',
      });
      if (mode === 'edit' || mode === 'customize') {
        resetForm();
      }
      setAuthoritiesFilter({
        skip: 0,
        limit: 10,
      });
      setCommentsFilter({
        skip: 0,
        limit: 10,
      });
    }
  };

  const handleTabChange = (activePage: string) => {
    setSearchParams({ ...searchParams, activePage, open: 'false', customerType: '' });
    setFilterValue('customerType', []);
  };
  const isSubmitPaymentTerms = useMemo(() => {
    if (!!watch('day')?.toString().length && !!watch('percent')?.toString().length && watch('email')?.length) {
      if (watch('paymentType') !== SUBJECT.FACTORING && watch('templateId')) {
        return true;
      } else if (watch('paymentType') === SUBJECT.FACTORING) {
        return true;
      }
    }
    return false;
  }, [watch('day'), watch('percent'), watch('email'), watch('paymentType'), watch('templateId')]);

  const onSubmit = (values: ICustomersFormData) => {
    if (!paymentCheck?.length && !isSubmitPaymentTerms) {
      if (mode === 'edit' && !isEditLoading) {
        updateCustomer({ id, values }).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            notification.success({
              message: 'Successfully edited',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            resetForm();
            if (!inSearchPage) {
              refetch();
            }
          }
          dispatch(closeModal());
        });
      } else if (mode === 'add' && !isCreateLoading) {
        createCustomer(values).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            notification.success({
              message: 'Successfully created',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            resetForm();
          }
          dispatch(closeModal());
        });
      }
    }
  };

  const handleCommentClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterValue?.length ? { customerType: filterValue } : {}),
      mode: 'comments',
      open: 'true',
      id: rowId,
    });
  };

  const handleCreateComment = (text: string) => {
    createComment({ id, message: text }).then((messageData: any) => {
      if (messageData.error) {
        notification.error({
          message: ServerErrorCodes[Number(messageData?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        if (!inSearchPage) {
          refetch();
        }
      }
    });
  };

  const handleDeleteCommentModal = (commentId: string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteComment({ id, commentId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              if (!inSearchPage) {
                refetch();
              }
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setCustomersFilter(prev => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleSortBlackListClick = (sortOrder: number, dataIndex: string) => {
    setBlackListFilter(prev => {
      if (sortOrder === 0)
        return {
          skip: 0,
          limit: prev.limit,
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setCustomersFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handlePaymentPaginationClick = (skip: number, limit: number) => {
    setGetPaymentTerms(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleBlackListPagination = (skip: number, limit: number) => {
    setBlackListFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  useEffect(() => {
    if (open === 'false' && activePage !== 'customer-blacklist') {
      dispatch(authoritiesAPI.util.resetApiState());
    }
  }, [mode, open, id, activePage]);

  useEffect(() => {
    if (customersColumnsData?.columns.length && activePage === 'customer-table') {
      setDragColumns(customersColumnsData?.columns);
    }
  }, [customersColumnsData?.columns, activePage]);

  useEffect(() => {
    if (columnsBlackList?.columns?.length && activePage === 'customer-blacklist') {
      setDragColumns(columnsBlackList?.columns);
    }
  }, [columnsBlackList?.columns, activePage]);

  useEffect(() => {
    clearErrors(['percent', 'day']);
  }, [watch('paymentType')]);

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        name: customerById?.result?.name,
        type: customerById?.result?.type,
        phoneNumber: formatPhone(customerById?.result?.phone),
        ffNumber: customerById?.result?.ff,
        mcNumber: customerById?.result?.mc,
        fax: customerById?.result?.fax,
        address: customerById?.result?.address,
        secondAddress: customerById?.result?.secondAddress,
        city: customerById?.result?.city,
        state: customerById?.result?.stateId,
        zipcode: customerById?.result?.zip,
        mc: [],
        paymentType: '',
        day: '',
        percent: null,
        email: [],
        comments: [],
        addedMc: paymentTerms?.result || [],
        stateNumber: customerById?.result?.stateNumber,
        dot: customerById?.result?.dot,
        templateId: '',
        billingPhone: formatPhone(customerById?.result?.billingPhone) || '',
        billingEmail: customerById?.result?.billingEmail || '',
        billingContactName: customerById?.result?.billingContactName || '',
        receivablesPhone: formatPhone(customerById?.result?.receivablesPhone) || '',
        receivablesEmail: customerById?.result?.receivablesEmail || '',
        receivablesContactName: customerById?.result?.receivablesContactName || '',
      });
    } else {
      reset(defaultValues);
    }
  }, [mode, open, id, customerById]);

  useEffect(() => {
    if (mode === 'edit') {
      setValue('addedMc', paymentTerms?.result, { shouldDirty: false });
    } else {
      reset(defaultValues);
    }
  }, [paymentTerms]);

  useEffect(() => {
    if (activePage === ProfileActivePages.customers) {
      if (setExcelParams) {
        setExcelParams((prev: any) => {
          return {
            ...prev,
            ...getCustomersFilter,
          };
        });
      }
    } else {
      if (setExcelParams) {
        setExcelParams((prev: any) => {
          return {
            ...prev,
            ...getBlackListFilter,
          };
        });
      }
    }
  }, [getCustomersFilter, getBlackListFilter]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={activePage === 'customer-blacklist' ? columnsBlackList?.columns : customersColumnsData?.columns}
          isDefault={
            activePage === 'customer-blacklist' ? columnsBlackList?.isDefault : customersColumnsData?.isDefault
          }
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: customerTablePagePermissions.create
      ? {
          component: (
            <CreateCustomerForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              resetField={resetField}
              defaultValues={defaultValues}
              reset={reset}
              paymentCheck={paymentCheck}
              type={type}
              mc={mc}
              addedMc={addedMc}
              statesData={statesData}
              authorities={authorities}
              authoritiesFilter={authoritiesFilter}
              setAuthoritiesFilter={setAuthoritiesFilter}
              customerComments={customerComments}
              handlePaymentPaginationClick={handlePaymentPaginationClick}
              mode={mode}
              trigger={trigger}
              setError={setError}
              clearErrors={clearErrors}
              paymentTermsCount={paymentTerms?.count}
              userInfo={userInfo}
              isEditLoading={isEditLoading}
              isCreateLoading={isCreateLoading}
              isSubmitted={isSubmitted}
              refetchAuthoritiesShort={refetchAuthoritiesShort}
            />
          ),
          buttonText: SUBJECT.ADD_CUSTOMER,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit: customerTablePagePermissions.edit
      ? {
          component: (
            <EditCustomersForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              control={control}
              errors={errors}
              setValue={setValue}
              resetField={resetField}
              isValid={isValid}
              reset={reset}
              dirtyFields={dirtyFields}
              paymentCheck={paymentCheck}
              type={type}
              mc={mc}
              addedMc={addedMc}
              watch={watch}
              statesData={statesData}
              authorities={authorities}
              authoritiesFilter={authoritiesFilter}
              setAuthoritiesFilter={setAuthoritiesFilter}
              customerById={customerById}
              createComment={handleCreateComment}
              customerComments={customerComments}
              deleteComment={handleDeleteCommentModal}
              handlePaymentPaginationClick={handlePaymentPaginationClick}
              isFetchingById={isFetchingById}
              getMoreComments={getMoreComments}
              isShowMoreVisible={isShowMoreVisible}
              mode={mode}
              trigger={trigger}
              setError={setError}
              clearErrors={clearErrors}
              paymentTermsCount={paymentTerms?.count}
              userInfo={userInfo}
              isEditLoading={isEditLoading}
              isCreateLoading={isCreateLoading}
              isSubmitted={isSubmitted}
              hasDeleteCommentPermission={customerTablePagePermissions.deleteComment}
              defaultValues={defaultValues}
              isCustomerCommentsLoading={isCustomerCommentsLoading}
              refetchAuthoritiesShort={refetchAuthoritiesShort}
            />
          ),
          buttonText: SUBJECT.EDIT_BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    view: {
      component: (
        <CustomersViews
          customerById={customerById?.result}
          handleCommentClick={handleCommentClick}
          isFetchingById={isFetchingById}
          type={type}
          pagePermissions={customerTablePagePermissions}
          customerComments={customerComments}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          paymentTerms={paymentTerms}
          getPaymentTerms={getPaymentTerms}
          setGetPaymentTerms={setGetPaymentTerms}
          isCustomerCommentsLoading={isCustomerCommentsLoading}
        />
      ),
      onCancel: handleCancel,
    },
    comments: {
      component: (
        <CommentsView
          customerById={customerById?.result}
          createComment={handleCreateComment}
          customerComments={customerComments}
          deleteComment={handleDeleteCommentModal}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          isFetchingById={isFetchingById}
          userInfo={userInfo}
          isCommentsLoading={isCustomerCommentsLoading}
        />
      ),
      onCancel: handleCancel,
    },
    blacklist: customerBlackListPagePermissions.view
      ? {
          component: (
            <CustomersBlacklistView
              data={getBlackListById}
              isFetchingBlackListById={isFetchingBlackListById}
              hasUnlockPermission={unlock}
              hasLockPermission={lock}
            />
          ),
          onCancel: handleCancel,
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view' && mode !== 'blacklist' && mode !== 'comments',
    };
  }, [
    mode,
    isDirty,
    watch(),
    watch('email').length,
    customerById,
    customerComments,
    paymentTerms?.count,
    authorities,
    customersData,
    activePage,
    customersBlackList,
    customersColumnsData?.columns,
    columnsBlackList?.columns,
    getBlackListById,
    open,
    isEditLoading,
    isCreateLoading,
    isSubmitted,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);
  const customerTabs: any[] = useMemo(
    () => [
      {
        ...(permissionsInfo?.permissions?.profile.customer.visibleSubTabs.includes('customersTable')
          ? {
              label: SUBJECT.CUSTOMER_TABLE,
              key: 'customer-table',
              children: (
                <CustomersTable
                  filterControl={filterControl}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  filterReset={filterReset}
                  filterWatch={filterWatch}
                  handleCommentClick={handleCommentClick}
                  data={customersData}
                  isLoading={isFetching}
                  handleSortClick={handleSortClick}
                  handlePaginationClick={handlePaginationClick}
                  editColumns={editColumns}
                  columns={customersColumnsData?.columns}
                  isFilterDirty={isFilterDirty}
                  setCustomersFilter={setCustomersFilter}
                  skip={getCustomersFilter?.skip}
                  limit={getCustomersFilter?.limit}
                />
              ),
            }
          : {}),
      },
      {
        ...(permissionsInfo?.permissions?.profile.customer.visibleSubTabs.includes('customersBlackList')
          ? {
              label: SUBJECT.CUSTOMERS_BLACK_LIST,
              key: 'customer-blacklist',
              children: (
                <CustomersBlacklistTable
                  handlePaginationClick={handleBlackListPagination}
                  columns={columnsBlackList?.columns}
                  data={customersBlackList}
                  isLoading={isBlackListFetching}
                  skip={getBlackListFilter?.skip}
                  limit={getBlackListFilter?.limit}
                  handleSortBlackListClick={handleSortBlackListClick}
                  setBlackListFilter={setBlackListFilter}
                  hasUnlockPermission={unlock}
                  hasLockPermission={lock}
                />
              ),
            }
          : {}),
      },
    ],
    [
      activePage,
      filterValue,
      customersData,
      customersColumnsData?.columns,
      customersBlackList,
      columnsBlackList?.columns,
      getBlackListById,
      isBlackListFetching,
    ]
  );

  return {
    activePage,
    handleTabChange,
    customerTabs,
    currentChildren,
  };
};

export default useCustomers;
