export const defaultValues = {
  name: '',
  email: '',
  phoneNumber: null,
  address: '',
  secondAddress: '',
  fax: null,
  city: '',
  stateId: '',
  zipcode: null,
  billBy: false,
  file: [],
  paymentBankName: '',
  paymentRoutingNumber: '',
  paymentAccountNumber: '',
  paymentCompanyName: '',
  paymentAddress: '',
  paymentSecondAddress: '',
  paymentCity: '',
  paymentStateId: undefined,
  paymentZip: '',
};

export const filterDefaultValues = {
  factoringCompaniesFilter: null,
};

export enum SUBJECT {
  BANK_NAME = 'Bank Name',
  ROUTING_NUMBER = 'Routing Number',
  ACCOUNT_NUMBER = 'Account Number',
  PAYMENT_COMPANY_NAME = 'Company Name',
  PAYMENT_ADDRESS = 'Address',
  ZIP_CODE = 'Zip Code',
  DOCUMENTS = 'Documents',
  FAX_NUMBER = 'Fax Number',
  FACTORING_TYPE = 'Type',
  UPLOAD = 'Drag And Drop Or',
  ALLOWED_FORMAT = 'Allowed Format (jpeg, pdf, tiff, png)',
  UPLOAD_FILE = 'Upload File',
  DIRECT_DEPOSIT = 'Direct deposit',
  PAYPER_CHECK = 'Payper check',
  BILL_BY = 'Bill By FreightMax',
  EMAIL = 'Email',
  ADDED_BY = 'Added By',
  ADD_NEW_FACTORING = 'Add New Factoring',
  ADD_FACTORING = 'Add Factoring',
  NAME = 'Name',
  FACTORING_INFO = 'Factoring info',
  PHONE_NUMBER = 'Phone Number',
  FAX = 'Fax',
  ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIPCODE = 'Zip Code',
  SAVE = 'Save',
  CANCEL = 'Close',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  ADDED = 'Added',
  EDIT_BTN_TEXT = 'Save Changes',
  ADDITIONAL = 'Additional',
  DATE = 'Date',
  FACTORING_NAME = 'Factoring Name',
  EDIT = 'Edit',
  OWNER = 'Owner',
  STATUS = 'Status',
  LOCATION = 'Location',
  EDIT_FACTORING = 'Edit Factoring',
  FACTORING_LIST_IS_EMPTY = 'Factoring List Is Empty',
  NOT_SELECTED = 'Not Selected',
  PROFILE_FACTORING_FILTER = 'factoringCompaniesFilter',
  RESET_ALL = 'Reset All',
  ARE_YOU_SURE = 'Are You Sure Want Do Delete?',
  DELETE = 'Delete',
  NO = 'No',
  YES = 'Yes',
  CHECKED_ACTIVATE = 'Activate?',
  CHECKED_DEACTIVATE = 'Terminate?',
  STAY_HERE = 'Stay Here',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',
  FULL_INFO = 'Full Info',
  FACTORING_ID = 'Factoring ID:',
}

export enum VALIDATION_MESSAGES {
  ZIP_MIN_LENGTH = 'Must be 5 digits',
  PAYMENT_BANK_NAME_REQUIRED = 'Bank name is required field',
  PAYMENT_ROUTING_NUMBER_REQUIRED = 'Routing number is required field',
  PAYMENT_ACCOUNT_REQUIRED = 'Account number is required field',
  PAYMENT_COMPANY_NAME_REQUIRED = 'Company name is required field',
  PAYMENT_ADDRESS_REQUIRED = 'Address is required field',
  PAYMENT_CITY_REQUIRED = 'City is required field',
  PAYMENT_ZIP_REQUIRED = 'Zip Code is required field',
  PAYMENT_STATE_REQUIRED = 'State is required field',
  FILE_REQUIRED = 'File is a required field',
  EMAIL_REQUIRED = 'Email is a required field',
  EMAIL = 'Invalid Email Format',
  NAME_REQUIRED = 'Name is a required field',
  ZIP_REQUIRED = 'Zip Code is a required field',
  PHONE_NUMBER_REQUIRED = 'Phone number is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  CITY_REQUIRED = 'City name is a required field',
  STATE_ID_REQUIRED = 'State is a required field',
  MIN_OPTION = 'At least one option is required',
  PHONE = 'Incorrect phone format',
  INCORRECT_FAX = 'Incorrect fax format',
  MAX_LENGTH = 'Maximum of 10 digits allowed',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
}
