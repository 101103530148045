export enum SUBJECT {
  LOAD_STATUS = 'Load Status',
  LOAD_STATUS_NOT_PROCESSING = 'Not Processed',
  LOAD_STATUS_PROCESSING = 'Processing',
  CARRIER_PAYMENT_PENDING = 'Carrier Payment Pending',
  CUSTOMER_PAYMENT_PENDING = 'Customer Payment Pending',
  LOAD_STATUS_CARRIER_PAID = 'Carrier Paid',
  LOAD_STATUS_CUSTOMER_PAID = 'Customer Received',
  LOAD_STATUS_DENIED = 'Canceled',
  LOAD_STATUS_REQUEST_CHECK = 'Request Check',
}
