export enum SUBJECT {
  CARRIER_REQUEST = 'Carrier Request',
  TIMER = 'Timer',
  DOT = 'DOT',
  DATE = 'Date',
  CARRIER_NAME = 'Carrier Name',
  PHONE_NUMBER = 'Phone Number',
  CONTACT_NAME = 'Contact Name',
  MC_NUMBER = 'MC Number',
  ADDED_BY = 'Added By',
  TYPE = 'Type',
  AGENT_TYPE = 'Agent Type',
  ACTION = 'Action',
  EDIT = 'Edit',
  STATUS = 'Status',
}
