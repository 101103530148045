import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useOutsideDetect } from 'hooks/useOutsideDetect';

import { addOneYear, subtractOneYear } from '../../../../../utils/dates';
import { SUBJECT } from '../../constants/constants';

import { getFirstDayOfMonth } from './helper/helper';
import { IUseCustomMonthRangePickerProps } from './types/types';

export const useCustomMonthRangePicker = ({ setIsCalendarOpen }: IUseCustomMonthRangePickerProps) => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { startDate: startDateParams, endDate: endDateParams } = searchParams;

  const [possibleRange, setPossibleRange] = useState<any>([]);

  const firstDayOfCurrentMonth = dayjs().startOf('month').valueOf();
  const [startDate, setStartDate] = useState<number>(
    startDateParams ? Number(startDateParams) : firstDayOfCurrentMonth
  );
  const [endDate, setEndDate] = useState<number>(endDateParams ? Number(endDateParams) : 0);
  const startDateYear = Number(dayjs(startDate).format(SUBJECT.YEARS));
  const [year, setYear] = useState<number>(startDateYear);

  const startMonthAndYear = useMemo(() => {
    return String(dayjs(startDate).format(SUBJECT.MONTHS)) + String(dayjs(startDate).format(SUBJECT.YEARS));
  }, [startDate, setStartDate]);

  const ref = useOutsideDetect<HTMLUListElement>(setIsCalendarOpen);

  const handleChangeMonthRange = (month: number) => {
    const selectedDayOfTheMonth = getFirstDayOfMonth(year, month);
    const { allMonths: _allMonths, ...rest } = searchParams;
    if (endDate) {
      setStartDate(selectedDayOfTheMonth);
      setEndDate(0);
      setSearchParams({
        ...rest,
        startDate: String(selectedDayOfTheMonth),
        endDate: '',
      });
    } else {
      if (selectedDayOfTheMonth < startDate) {
        setStartDate(selectedDayOfTheMonth);
        setEndDate(0);
      } else {
        setEndDate(selectedDayOfTheMonth);
        setIsCalendarOpen(false);
      }
      setSearchParams({
        ...rest,
        startDate: selectedDayOfTheMonth < startDate ? String(selectedDayOfTheMonth) : String(startDate),
        endDate: selectedDayOfTheMonth < startDate ? '' : String(selectedDayOfTheMonth),
      });
    }
  };

  const handleChangeYear = (changer: string) => {
    const isIncrease = changer === SUBJECT.INCREASE;
    if (isIncrease && isDisabled) return;
    isIncrease ? setYear(prev => prev + 1) : setYear(prev => prev - 1);
    setSearchParams({
      ...searchParams,
      startDate: String(!isIncrease ? subtractOneYear(Number(startDateParams)) : addOneYear(Number(startDateParams))),
      ...(endDate && {
        endDate: String(!isIncrease ? subtractOneYear(Number(endDateParams)) : addOneYear(Number(endDateParams))),
      }),
    });
  };

  const isDisabled = Number(dayjs().format(SUBJECT.YEARS)) === year;

  const handleSelectingProcess = (e: any) => {
    if ((startDate && endDate) || new Date(startDate)?.getFullYear() > year) return;

    const id = Number(e?.target?.id);
    const selectedMonth = new Date(startDate)?.getMonth() + 1;

    if (id < possibleRange?.[possibleRange?.length - 1]) {
      setPossibleRange((prev: any) => prev.slice(0, -1));
    }
    if (possibleRange.includes(id)) {
      setPossibleRange((prev: any) => prev.filter((el: any) => el !== id));
    } else {
      setPossibleRange((prev: any) => [...prev, ...generateRange(selectedMonth, id)]);
    }
  };

  const generateRange = (start: number, end: number): number[] => {
    const result: number[] = [];

    if (startDate && new Date(startDate)?.getFullYear() < year) {
      for (let i = 1; i <= start; i++) {
        result.push(i);
      }
    }
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  return {
    ref,
    handleChangeYear,
    year,
    isDisabled,
    startMonthAndYear,
    handleChangeMonthRange,
    start: startDate,
    end: endDate,
    setPossibleRange,
    possibleRange,
    handleSelectingProcess,
  };
};
