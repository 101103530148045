import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { selectModals } from 'store/modal-slice/selector';
import Button from 'ui/button/Button';
import RequestError from 'ui/request-error/RequestError';
import Typography from 'ui/typography/Typography';
import {
  checkIsNumberDecimal,
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
  onlyNumbersFormatter,
} from 'utils/helpers';

import RequestErroIcon from 'components/svgs/RequestErroIcon';

import { IReceivablesModalContentFormData } from '../receivables-view/constants/types';

import { RECEIVABLES_MODAL_OPTIONS, SUBJECT } from './constants/constants';
import { IReceivablesModalContentProps } from './constants/types';
import { Input, Select } from './hoc-instance/instance';
import useReceivablesModalContent from './useReceivablesModalContent';

import styles from './ReceivablesModalContent.module.scss';

const ReceivablesModalContent = ({
  onSubmit,
  enteredCount,
  total,
  customerDeptAmount,
}: IReceivablesModalContentProps) => {
  const {
    handleSubmit,
    control,
    trigger,
    loadIds,
    getValues,
    watch,
    errors,
    showMoneyTypeInput,
    isMoneyWarning,
    handleCancel,
    isValid,
    setValue,
    isMoneyWarningGrader,
  } = useReceivablesModalContent({ total, enteredCount, customerDeptAmount });

  const { isLoading } = useSelector(selectModals);

  return (
    <>
      <form
        onSubmit={handleSubmit((data: IReceivablesModalContentFormData) => {
          if (!isLoading) {
            onSubmit(data, loadIds);
          }
        })}
      >
        <Typography variant="heading" children={SUBJECT.PAYMENT} className={styles.heading} />
        <Row gutter={[0, 31]}>
          <Col span={24}>
            <Row align="middle" gutter={12}>
              <Col>
                <span className={styles.totalInfo}>{SUBJECT.LOAD}: </span>
                <span className={styles.totalInfo}>{enteredCount}</span>
              </Col>
              <Col>
                <span className={styles.totalInfo}>{SUBJECT.TOTAL_BALANCE}: </span>
                <span className={styles.totalInfo}>
                  {formatNumberWithThousandsSeparatorAndDecimal(checkIsNumberDecimal(total), true)}
                </span>
              </Col>
              <Col>
                <span className={styles.totalInfo}>{SUBJECT.FINAL_RECEVIABLES}: </span>
                <span className={styles.totalInfo}>
                  {customerDeptAmount
                    ? formatNumberWithThousandsSeparatorAndDecimal(checkIsNumberDecimal(customerDeptAmount), true)
                    : null}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[24, 31]}>
              <Col span={12}>
                <Select
                  options={RECEIVABLES_MODAL_OPTIONS}
                  placeholder={SUBJECT.MONEY_TYPE}
                  name="moneyType"
                  onBlur={() =>
                    setTimeout(() => {
                      trigger('moneyType');
                    }, 300)
                  }
                  control={control}
                  rules={{ required: true }}
                  required
                  errors={errors}
                />
              </Col>
              <Col span={12}>
                <Input
                  name="numberOrTransactionId"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.CHECK_TRANSACTION_ID}
                  getValues={getValues}
                  errors={errors}
                  onChange={(e: any) =>
                    setValue('numberOrTransactionId', onlyNumbersFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  required
                />
              </Col>
              {showMoneyTypeInput && (
                <Col span={12}>
                  <Input
                    name="moneyCode"
                    type="text"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.MONEY_CODE}
                    onChange={e =>
                      setValue('moneyCode', onlyNumbersFormatter(e.target.value), { shouldValidate: true })
                    }
                    getValues={getValues}
                    errors={errors}
                    required
                  />
                </Col>
              )}
              <Col span={12}>
                <Input
                  name="amount"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.AMOUNT}
                  value={getValues('amount') ? formatNumberWithThousandsSeparatorAndDecimal(getValues('amount')) : ''}
                  errors={errors}
                  onChange={(e: any) => {
                    setValue('amount', floatNumberFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                      shouldTouch: true,
                    });
                  }}
                  disabled={enteredCount > 1}
                  required
                />
              </Col>
              {isMoneyWarningGrader && (
                <Col span={24}>
                  <RequestError
                    icon={<RequestErroIcon />}
                    title={`You are going to receive at least ${formatNumberWithThousandsSeparatorAndDecimal(
                      checkIsNumberDecimal(Number(watch('amount')) - Number(customerDeptAmount || 0)),
                      true
                    )} more than Final receivables.`}
                    mode="warning"
                  />
                </Col>
              )}
              {isMoneyWarning && (
                <Col span={24}>
                  <RequestError
                    icon={<RequestErroIcon />}
                    title={`You are not receiving the full amount, you’ll have ${formatNumberWithThousandsSeparatorAndDecimal(
                      checkIsNumberDecimal(Number(customerDeptAmount || 0) - Number(watch('amount'))),
                      true
                    )} debt`}
                    mode="warning"
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={10} justify="end">
              <Col>
                <Button variant="outlined" htmlType="button" size="large" onClick={handleCancel}>
                  {SUBJECT.CANCEL}
                </Button>
              </Col>
              <Col>
                <Button disabled={!isValid} variant="default" htmlType="submit" size="large">
                  {SUBJECT.RECEIVE}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ReceivablesModalContent;
