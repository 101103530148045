export enum SUBJECT {
  BILLING = 'Billing',
  FACTORED = 'Factored',
  RADIO_FACTORED = 'factored',
  RADIO_NOT_FACTORED = 'not_factored',
  NOT_FACTORED = 'Not Factored',
  REJECT = 'Reject',
  ACCEPT = 'Approve',
  NOA = 'NOA',
  RED_CONFIRMATION = 'Rate Confirmation',
  POD = 'POD',
  INVOICE = 'Invoice',
  CREATE_INVOICE = 'Create Invoice',
  TONU = 'Tonu',
  CUSTOMER_IS_FACTORED = 'Customer Is Factored',
  CUSTOMER_IS_NOT_FACTORED = 'Customer Is Not Factored',
  ACCEPTED_TO_BILLING = 'Approved',
}
