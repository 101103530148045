import { IAddPermissionData } from './types';

export enum SUBJECT {
  PERMISSION_GROUP = 'Permission Group',
  PERMISSION_GROUP_NAME = 'Permission Group Name',
}

export const defaultValues: IAddPermissionData = {
  permissionGroupName: '',
};
