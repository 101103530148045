import React from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useChangeStatusMutation } from 'services/profile/factoringCompanies/factoringCompanies';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from '../../constants/constants';
import { FactoringStatusTypes } from '../../constants/types';

export const useFactoringCompaniesView = ({ id }: any) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();

  // todo for factoring
  const [changeStatus] = useChangeStatusMutation();

  const handleChecked = (checked: boolean) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({
            id,
            status: checked
              ? FactoringStatusTypes.FACTORING_STATUS_TYPES_ACTIVE
              : FactoringStatusTypes.FACTORING_STATUS_TYPES_INACTIVE,
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      mode: 'edit',
      open: 'true',
    });
  };

  return {
    handleChecked,
    handleChangeMode,
    mode: searchParams?.mode,
  };
};
