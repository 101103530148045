import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useChangeRequestStatusForCarrierMutation,
  useChangeRequestStatusMutation,
  useGetCarrierRequestForCarrierListQuery,
} from 'services/profile/carriers/carriers';
import { useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from '../../constants/constants';
import { ICarriersParams } from '../../constants/types';

const useRequestTableCarrier = ({ setExcelParams, pagePermissions: { approve, deny } }: ICarriersParams) => {
  const dispatch = useDispatch();

  const [getCarrierRequestFilter, setGetCarrierRequestFilter] = useState<Partial<any>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const { data, isFetching, isLoading } = useGetCarrierRequestForCarrierListQuery(getCarrierRequestFilter);

  const { data: columnsData } = useGetColumnsQuery({ type: 'carrierOnBoard' });
  const [editColumns] = useEditColumnsMutation();
  const [changeRequestStatuesForAgent] = useChangeRequestStatusMutation();
  const [changeRequestStatuesForCarrier] = useChangeRequestStatusForCarrierMutation();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { activePage } = searchParams;

  const isCarrierOnBoard = activePage === 'carrierOnBoard';

  const changeRequestStatues = isCarrierOnBoard ? changeRequestStatuesForCarrier : changeRequestStatuesForAgent;

  useEffect(() => {
    if (activePage !== 'carrierOnBoard') {
      setGetCarrierRequestFilter({
        search: '',
        field: '',
        skip: 0,
        limit: 20,
        order: 2,
        orderBy: 'createdAt',
        filter: {},
      });
    }
  }, [activePage]);

  const handleRowClick = (rowId: string) => {
    setSearchParams({ ...searchParams, mode: 'carrierOnBoard', open: 'true', id: rowId });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setGetCarrierRequestFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleStopResize = (columns: any): void => {
    editColumns({ type: 'carrierRequest', columns });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setGetCarrierRequestFilter(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setGetCarrierRequestFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleCloseModal = (id: any) => {
    dispatch(changeLoading(true));

    changeRequestStatues({ id: id, status: isCarrierOnBoard ? 2 : 3 }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully denied',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
      dispatch(changeLoading(false));
      setSearchParams({
        ...searchParams,
        ...(activePage ? { activePage } : {}),
        open: 'false',
      });
      dispatch(closeModal());
    });
  };
  const handleCarrierActionClick = ({ id }: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ACTIONS,
        content: SUBJECT.ACTIONS_TEXT,
        okText: SUBJECT.REVIEW,
        cancelText: SUBJECT.DENY,
        cancelButtonVariant: 'danger',
        showOk: approve,
        showCancel: deny,
        onOk: () => {
          setSearchParams({
            ...searchParams,
            activePage: 'carrierOnBoard',
            open: 'true',
            mode: 'edit',
            id,
            isAproveMode: 'true',
          });
          dispatch(closeModal());
        },
        onCancel: () => handleCloseModal(id),
      })
    );
  };

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...getCarrierRequestFilter,
        };
      });
    }
  }, [getCarrierRequestFilter]);

  useEffect(() => {
    if (!data?.result.length && getCarrierRequestFilter?.skip > 0) {
      setGetCarrierRequestFilter((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [data?.result?.length]);

  return {
    data,
    columns: columnsData?.columns,
    isFetching,
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handleColumnSearch,
    handleCarrierActionClick,
    handlePaginationClick,
    isLoading,
    skip: getCarrierRequestFilter.skip,
    limit: getCarrierRequestFilter.limit,
  };
};

export default useRequestTableCarrier;
