const DownloadIcon = () => {
  return (
    <svg cursor="pointer" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.79004 16.3071C1.39004 16.3071 1.04004 16.1571 0.740039 15.8571C0.440039 15.5571 0.290039 15.2071 0.290039 14.8071V11.2321H1.79004V14.8071H14.79V11.2321H16.29V14.8071C16.29 15.2071 16.14 15.5571 15.84 15.8571C15.54 16.1571 15.19 16.3071 14.79 16.3071H1.79004ZM8.29004 12.4821L3.46504 7.65713L4.54004 6.58213L7.54004 9.58213V0.307129H9.04004V9.58213L12.04 6.58213L13.115 7.65713L8.29004 12.4821Z"
        fill="var(--blueGrayWhite)"
      />
    </svg>
  );
};

export default DownloadIcon;
