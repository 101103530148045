import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { Mutex } from 'async-mutex';
import { logOut } from 'store/user-slice/user';
import { ls } from 'utils/storage';

const { api, file }: any = window.global;

const mutex = new Mutex();
export const fetchQuery = fetchBaseQuery({
  baseUrl: api,
  prepareHeaders: headers => {
    const token = ls.get('token');
    const hash = ls.get('carriersOnBoardHash');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (hash) {
      headers.set('Authorization', `Bearer ${hash}`);
    }
    return headers;
  },
});

export const fetchQueryFile = fetchBaseQuery({
  baseUrl: file,
  prepareHeaders: headers => {
    const token = ls.get('token');
    const hash = ls.get('carriersOnBoardHash');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (hash) {
      headers.set('Authorization', `Bearer ${hash}`);
    }
    return headers;
  },
});

export const baseQuery = async (args: any, api: any, extraOptions: any): Promise<any> => {
  await mutex.waitForUnlock();

  let result: any = await fetchQuery(args, api, extraOptions);

  if (result?.error?.status === 401 || result?.error?.status === 403) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult: any = await fetchQuery(
          {
            url: '/auth/exchange',
            method: 'POST',
            body: {
              token: ls.get('refresh'),
            },
          },
          api,
          extraOptions
        );
        if (refreshResult?.data) {
          ls.set('token', refreshResult.data.result.access);
          ls.set('refresh', refreshResult.data.result.refresh);
          result = await fetchQuery(args, api, extraOptions);
        } else {
          ls.remove('token');
          ls.remove('refresh');
          api.dispatch(logOut());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseQueryFile = async (args: any, api: any, extraOptions: any) => {
  let result = await fetchQueryFile(args, api, extraOptions);

  if (result?.error?.status === 401 || result?.error?.status === 403) {
    const refreshResult: any = await fetchQuery(
      {
        url: '/auth/exchange',
        method: 'POST',
        body: {
          token: ls.get('refresh'),
        },
      },
      api,
      extraOptions
    );
    const { access, refresh } = refreshResult.data.result;

    if (refreshResult?.data) {
      ls.set('token', access);
      ls.set('refresh', refresh);

      result = await fetchQueryFile(args, api, extraOptions);
    } else {
      ls.remove('token');
      ls.remove('refresh');
      api.dispatch(logOut());
    }
  }
  return result;
};
