import { IState, IStatesWithRegion } from 'services/states/interfaces';

export const transformStatesData = (data: Array<IStatesWithRegion>) =>
  data
    ?.map((item: IStatesWithRegion) => {
      return {
        title: item.region.name,
        value: `${item.region.id}${item.region.name}`,
        key: `${item.region.id}${item.region.name}`,
        children: item.state?.map((state: IState) => {
          return {
            title: state.name,
            value: String(state.id),
            key: String(state.id),
          };
        }),
      };
    })
    .slice(0, 9);
