import { Table } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import useDataGrid from './useTransactionsTable';

import styles from './DataGrid.module.scss';

const TransactionsTable = ({
  title,
  data = [],
  handleRowDelete,
  isLoading = false,
  hasDeletePermission,
  isCustomer,
  isCarrier,
  isSecondAgent,
}: any) => {
  const { transactionColumns } = useDataGrid({
    handleRowDelete,
    hasDeletePermission,
    isCustomer,
    isCarrier,
    isSecondAgent,
  });

  return (
    <div className={clsx(styles.tableWrapper, styles.transactionTableWrapper)}>
      <Typography children={title} variant="heading" className={styles.heading} />
      <Table
        tableLayout="fixed"
        pagination={false}
        rowClassName={({ deletedAt }: any) =>
          clsx({
            ['enteredRow']: deletedAt,
          })
        }
        columns={transactionColumns?.transactions || []}
        dataSource={data}
        rowKey={record => record.id}
        loading={isLoading}
        scroll={{ x: '100%' }}
      />
    </div>
  );
};

export default TransactionsTable;
