import { memo, useCallback, useMemo, useState } from 'react';
import { PaginationProps } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';
import { multiplyDownloadWithDelay } from 'utils/helpers';

import { ArrowSvg } from 'components/svgs/ArrowSvg';
import DownloadIcon from 'components/svgs/DownloadIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import PoligonDownIcon from 'components/svgs/PoligonDownIcon';
import PoligonUpIcon from 'components/svgs/PoligonUpIcon';

import { CARRIER_PAYMENT_BREAKDOWN_COLUMNS, SORTING, SUBJECT } from './constants/constants';
import { ICarrierPaymentBreakDownData, ICarrierPaymentBreakDownHookProps, IColumn } from './constants/types';

import styles from './CarrierPaymentBreakdown.module.scss';

export const useCarrierPaymentBreakdown = ({ onSort, data }: ICarrierPaymentBreakDownHookProps) => {
  const [sortingColumns, setSortingColumn] = useState<{ dataIndex: string; sorting: number }>();

  const handleViewFileClick = (url: string) => {
    window.open(url, '_blank');
  };

  const pageSizeOptions = (total: number) => {
    const maxPageSize = 40;
    const pageSizeOptions = Array.from({ length: Math.ceil(total / 10) }, (_, i) => (i + 1) * 10).filter(
      option => option <= maxPageSize
    );
    return pageSizeOptions;
  };

  const handleSortColumn = useCallback(
    (e: React.SyntheticEvent<Element>, dataIndex: string) => {
      e.stopPropagation();
      if (sortingColumns) {
        if (sortingColumns.sorting === SORTING.ASC) {
          onSort(SORTING.DESC, dataIndex);
          setSortingColumn({ dataIndex, sorting: SORTING.DESC as number });
        } else if (sortingColumns.sorting === SORTING.DESC) {
          onSort(SORTING.DEFAULT, dataIndex);
          setSortingColumn({ dataIndex, sorting: SORTING.DEFAULT as number });
        } else if (sortingColumns.sorting === SORTING.DEFAULT) {
          onSort(SORTING.ASC, dataIndex);
          setSortingColumn({ dataIndex, sorting: SORTING.ASC as number });
        }
      } else {
        onSort(SORTING.ASC, dataIndex);
        setSortingColumn({ dataIndex, sorting: SORTING.ASC as number });
      }
    },
    [onSort, sortingColumns]
  );

  const renderHeaderCellTitle = useMemo(() => {
    return (column: IColumn) => (
      <div className={styles.HeaderTitleWithSort}>
        <Typography className={styles.headingCellText} variant="paragraph">
          {column.title}
        </Typography>
        {column.sortable && (
          <div className={styles.poligonWrapper} onClick={e => handleSortColumn(e, column.dataIndex)}>
            <PoligonUpIcon
              filled={
                sortingColumns &&
                sortingColumns.dataIndex === column.dataIndex &&
                sortingColumns.sorting === SORTING.ASC
              }
            />
            <PoligonDownIcon
              filled={
                sortingColumns &&
                sortingColumns.dataIndex === column.dataIndex &&
                sortingColumns.sorting === SORTING.DESC
              }
            />
          </div>
        )}
        {column.withDownloadIcon && (
          <div className={styles.downloadIconWrapper} onClick={() => multiplyDownloadWithDelay(data)}>
            <DownloadIcon />
          </div>
        )}
      </div>
    );
  }, [handleSortColumn]);

  const TextComponent = memo(({ col }: { col: string }) => {
    return (
      <Typography variant="paragraph" className={clsx(styles.RowTextType)}>
        {col}
      </Typography>
    );
  });

  const ViewAndDownloadComponent = memo(({ rowData }: any) => {
    return (
      <div className={styles.viewDownloadItems}>
        <div
          className={clsx(styles.iconItem, styles.viewItem)}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            handleViewFileClick(rowData?.preview);
          }}
        >
          <EyeIconSvg />
        </div>

        <div
          className={clsx(styles.iconItem, styles.downladItem)}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            multiplyDownloadWithDelay([rowData]);
          }}
        >
          <DownloadIcon />
        </div>
      </div>
    );
  });

  const renderRows = useMemo(() => {
    return (type: string, col: string | object | number, rowData: ICarrierPaymentBreakDownData) => {
      const rows: {
        [key: string]: JSX.Element;
      } = {
        text: <TextComponent col={col as string} />,
        downloadAll: <ViewAndDownloadComponent rowData={rowData} />,
      };
      return rows[type];
    };
  }, []);

  const renderColumns = useMemo(() => {
    const customizedColumns = CARRIER_PAYMENT_BREAKDOWN_COLUMNS?.map((column: IColumn) => ({
      ...column,
      title: renderHeaderCellTitle(column),
      render: (col: IColumn, rowData: ICarrierPaymentBreakDownData) => {
        return renderRows(column?.displayType, col, rowData);
      },
    }));
    return customizedColumns;
  }, [renderHeaderCellTitle, renderRows]);

  const paginationArrows: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === SUBJECT.PREV) {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowLeft)}>
          <ArrowSvg />
        </a>
      );
    }
    if (type === SUBJECT.NEXT) {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowRight)}>
          <ArrowSvg />
        </a>
      );
    }
    return originalElement;
  };

  return {
    pageSizeOptions,
    renderColumns,
    paginationArrows,
  };
};
