import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';

import { IAddPermissionData } from '../tab/permission-list/components/add/constants/types';
import { IPermissionListTypeFilter } from '../tab/permission-list/constants/types';

export const FilterSelect = withController<ISelectProps, IPermissionListTypeFilter>(ReusableSelect);
export const Input = withController<IInputProps, IAddPermissionData>(CustomInput);
