const LoadIcon = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2223 22.3747C9.54975 21.8125 8.17236 20.8076 7.09012 19.3599C6.00788 17.9122 5.46676 16.2467 5.46676 14.3633C5.46676 13.9136 5.5019 13.4638 5.57217 13.014C5.64245 12.5643 5.75489 12.1215 5.9095 11.6858L4.56021 12.4659L3.92773 11.3907L7.57502 9.28243L9.68327 12.9086L8.58698 13.5411L7.36419 11.4539C7.16742 11.9177 7.01633 12.3956 6.91092 12.8875C6.8055 13.3795 6.7528 13.8784 6.7528 14.3844C6.7528 16.0148 7.23418 17.4554 8.19695 18.7063C9.15972 19.9572 10.386 20.8076 11.8759 21.2573L11.2223 22.3747ZM17.2941 11.4118V10.1468H19.7186C19.0439 9.21918 18.1971 8.49534 17.1781 7.97531C16.1591 7.45527 15.0734 7.19525 13.9209 7.19525C12.9511 7.19525 12.048 7.37094 11.2118 7.72232C10.3755 8.07369 9.63408 8.55859 8.98755 9.17701L8.33399 8.03855C9.09296 7.37797 9.94329 6.86144 10.885 6.48899C11.8267 6.11653 12.8316 5.9303 13.8998 5.9303C15.1366 5.9303 16.3032 6.17977 17.3995 6.67873C18.4958 7.17768 19.4445 7.88395 20.2456 8.79753V7.19525H21.5106V11.4118H17.2941ZM16.3454 24.483L12.6981 22.3747L14.8063 18.7485L15.8815 19.381L14.6588 21.5103C16.4859 21.3276 18.0144 20.5581 19.2442 19.2018C20.474 17.8455 21.0889 16.2397 21.0889 14.3844C21.0889 14.0892 21.0714 13.8011 21.0362 13.52C21.0011 13.2389 20.9484 12.9578 20.8781 12.6767H22.1852C22.2415 12.9578 22.2836 13.2389 22.3117 13.52C22.3398 13.8011 22.3539 14.0822 22.3539 14.3633C22.3539 16.3732 21.7249 18.1512 20.467 19.6972C19.2091 21.2433 17.6033 22.2201 15.6496 22.6277L16.9778 23.4077L16.3454 24.483Z"
        fill="#252733"
      />
    </svg>
  );
};

export default LoadIcon;
