export const defaulValuesForEdit: any = {
  allAuthorities: [],
  carrier: '',
  carrierAmount: '',
  carrierSwitch: false,
  customerAmount: '',
  customerContactName: '',
  referenceNumber: '',
  soldUsSwitch: false,
  file: [],
  markAsClaimSwitch: false,
  customerContact: '',
  customerName: '',
  customerSwitch: false,
  load_type: 'Tonu',
  takenUsAuthority: '',
  truck: '',
};
