import { ReactNode } from 'react';
import { formatDate } from 'utils/dates';

import { CreatorTypes, links } from '../constants';
import { EMessageTypes, Handler, ISocketMessage } from '../interfaces';
import Notification from '../notification/Notification';

export class CarrierHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      create: this.handleCreate,
      edit: this.handleEdit,
      terminate: this.handleTerminate,
      activate: this.handleActivate,
      createBlackList: this.handleCreateBlackList,
      removeBlackList: this.handleRemoveBlackList,
      createRequest: this.handleCreateRequest,
      editPaymentTermType: this.handleEditPaymentTermType,
      editPaymentMethodType: this.handleEditPaymentMethodType,
      commentCreate: this.handleCommentCreate,
      commentDelete: this.handleCommentDelete,
      fileDelete: this.handleFileDelete,
      fileAdd: this.handleFileAdd,
      editInsurance: this.handleEditInsurance,
      intervalsInsurance: this.handleIntervalsInsurance,
    };
  }
  handleCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.carriers}${id}` },
          { title: 'carrier was created by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Carrier created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleEdit = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.carriers}${id}` },
          { title: 'carrier was edited by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Carrier edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleActivate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.carriers}${id}` },
          { title: 'carrier was activated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Carrier activated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleTerminate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.carriers}${id}` },
          { title: 'carrier was terminated by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Carrier terminated"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleCreateBlackList = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Carrier' },
          { id, title, link: `${links.carriers}${id}` },
          { title: 'was added to blacklist by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Carrier added to blacklist"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleRemoveBlackList = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'Carrier' },
          { id, title, link: `${links.carriers}${id}` },
          { title: 'was unlocked by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Carrier removed from blacklist"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCreateRequest = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'was made carrier request for' },
          { id, title, link: `${links.carriers}${id}` },
          { title: 'carrier' },
        ]}
        title="Carrier request created"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleEditPaymentTermType = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed payment terms from ${additionalData[0]} into ${additionalData[1]} in carrier` },
          { id, title, link: `${links.carriers}${id}` },
        ]}
        title="Payment term type edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditPaymentMethodType = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      additionalData,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `changed payment method from ${additionalData[0]} into ${additionalData[1]} in carrier` },
          { id, title, link: `${links.carriers}${id}` },
        ]}
        title="Payment method edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleCommentCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'A new comment was added in carrier' },
          { id, title, link: `${links.carriers}${id}` },
          { title: 'by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Comment added"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
  handleCommentDelete = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { title: 'A new comment was deleted from carrier' },
          { id, title, link: `${links.carriers}${id}` },
          { title: 'by' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
        ]}
        title="Comment deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleFileDelete = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted a file from carrier' },
          { id, title, link: `${links.carriers}${id}` },
        ]}
        title="File deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleFileAdd = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'added a file in carrier' },
          { id, title, link: `${links.carriers}${id}` },
        ]}
        title="File added"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleEditInsurance = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'changed insurance date in carrier' },
          { id, title, link: `${links.carriers}${id}` },
        ]}
        title="Insurance date changed"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleIntervalsInsurance = (event: ISocketMessage) => {
    const {
      event: { title },
      createdAt,
      read,
      additionalData,
    } = event.payload;

    this.notification = (
      <Notification
        read={read}
        message={[
          { title },
          {
            title: `${
              additionalData[0] === '0'
                ? `Carrier ${additionalData[1]} Insurance is Expired`
                : `Carrier ${additionalData[1]} Insurance will expire within ${additionalData[0]} days`
            }`,
          },
        ]}
        title="Carrier Insurance Expired"
        date={formatDate(createdAt)}
        messageType={additionalData[0] === '0' ? EMessageTypes.danger : EMessageTypes.info}
      />
    );
  };
}
