import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { ITransactionTypes } from 'pages/loads/components/edit-load/constants/constants';
import { ITransactions } from 'pages/loads/tabs/loads-tab/constants/types';
import { formatDate } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import { GreenInfoIcon } from 'components/svgs/GreenInfoIcon';
import { TrashIcon } from 'components/svgs/TrashIcon';

import { SUBJECT } from './constants';
import { ITransactionsTableProps } from './types';

import styles from '../DataGrid.module.scss';

export const getTransactionsColumns = ({
  handleRowDelete,
  hasDeletePermission,
  isCustomer,
  isCarrier,
  isSecondAgent,
}: ITransactionsTableProps) => {
  const columns = [
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      width: 180,
      render: (value: any) => {
        return <span>{ITransactionTypes[value]}</span>;
      },
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: 150,
      render: (_: string, { label }: ITransactions) => {
        return (
          <div
            className={clsx(styles.labelBlock, {
              [styles.red]: label === 'Charge',
              [styles.blue]: label === 'Credit',
            })}
          >
            <span>{label}</span>
          </div>
        );
      },
    },
    {
      title: 'Date And Time',
      key: 'dateAndTime',
      dataIndex: 'dateAndTime',
      width: 180,
    },
    {
      title: 'Added By',
      key: 'addedBy',
      dataIndex: 'addedBy',
      width: 180,
    },
    {
      title: 'Balance Amount',
      key: 'balanceAmount',
      dataIndex: 'balanceAmount',
      width: 120,
      render: (value: string) => {
        return (
          <span
            className={clsx({
              ['negative']: String(value?.[0]) === '-',
            })}
          >
            {formatNumberWithThousandsSeparatorAndDecimal(value, true)}
          </span>
        );
      },
    },
    {
      title: 'Comment',
      key: 'comment',
      dataIndex: 'comment',
      width: 100,
      render: (_: string, { dateAndTime, comment, id, addedBy }: ITransactions) =>
        !!comment?.length && (
          <Tooltip
            title={
              <div className={styles.commentBlock}>
                <span>{addedBy}</span>
                <span>{dateAndTime}</span>
                <p>{comment}</p>
              </div>
            }
            placement="top"
            color="white"
            key={id}
          >
            <div className={styles.commentText}>{comment.length > 16 ? comment?.slice(0, 16) + '...' : comment}</div>
          </Tooltip>
        ),
    },
    {
      title: '',
      id: 'action',
      dataIndex: 'action',
      width: 100,
      render: (_: any, row: ITransactions) => {
        if (row.deletedAt) {
          return (
            <Tooltip
              overlayInnerStyle={{
                width: 300,
              }}
              title={
                <div className={styles.deletedBlock}>
                  <Row gutter={[0, 15]}>
                    <Col span={24}>
                      <Row gutter={10}>
                        <Col span={8}>
                          <span>{SUBJECT.USERNAME}</span>
                        </Col>
                        <Col span={16}>
                          <span
                            className={styles.blockValue}
                          >{`${row.deletor?.firstName} ${row.deletor?.lastName}`}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={10}>
                        <Col span={8}>
                          <span>{SUBJECT.DELETED}</span>
                        </Col>
                        <Col span={16}>
                          <span className={styles.blockValue}>{formatDate(row.deletedAt)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              }
              placement="top"
              color="white"
              key={row?.id}
            >
              <div className={styles.deletedRow}>
                <GreenInfoIcon />
                <span>{'Deleted'}</span>
              </div>
            </Tooltip>
          );
        } else {
          return (
            hasDeletePermission && (
              <div className={styles.actionBlock} key={row?.id}>
                <div onClick={() => handleRowDelete(row?.id)} className={styles.trashIcon}>
                  <TrashIcon />
                </div>
              </div>
            )
          );
        }
      },
    },
  ];

  if (isCustomer) {
    columns.splice(5, 0, {
      title: 'Customer Amount',
      key: 'customerAmount',
      dataIndex: 'customerAmount',
      width: 130,
      render: (value: string) => {
        return (
          <span
            className={clsx({
              ['negative']: String(value?.[0]) === '-',
            })}
          >
            {formatNumberWithThousandsSeparatorAndDecimal(value, true)}
          </span>
        );
      },
    });
  }

  if (isCarrier) {
    columns.splice(isCustomer ? 6 : 5, 0, {
      title: 'Carrier Amount',
      key: 'carrierAmount',
      dataIndex: 'carrierAmount',
      width: 120,
      render: (value: string) => {
        return (
          <span
            className={clsx({
              ['negative']: String(value?.[0]) === '-',
            })}
          >
            {formatNumberWithThousandsSeparatorAndDecimal(value, true)}
          </span>
        );
      },
    });
  }

  if (isSecondAgent) {
    columns.splice(6, 0, {
      title: 'Second Agent Amount',
      key: 'secondAgent',
      dataIndex: 'secondAgent',
      width: 180,
      render: (value: string) => {
        return (
          <span
            className={clsx({
              ['negative']: String(value?.[0]) === '-',
            })}
          >
            {formatNumberWithThousandsSeparatorAndDecimal(value, true)}
          </span>
        );
      },
    });
  }

  return columns;
};
