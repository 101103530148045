import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';

import CustomComment from 'components/custom-comment/CustomComment';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import useNewLoadsComment from './useNewLoadsComment';

import styles from './LoadsComment.module.scss';

const NewLoadsComment = ({
  creditRequestById,
  createComment,
  deleteComment,
  loadsComments,
  getMoreComments,
  isShowMoreVisible,
  isLoading,
  hasDeleteCommentPermission,
  isLoadsCommentsLoading,
}: any) => {
  const { watch, setValue, control } = useNewLoadsComment();

  return (
    <>
      <div className={clsx(styles.topBanner)}>
        <MainLoader isFetching={isLoading} />
        <ViewItemWrapper title={SUBJECT.CREDIT_REQUEST}>
          <Row gutter={[10, 20]}>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.LOAD_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.LOAD_NUMBER}</span>
                    <TooltipText
                      data={creditRequestById?.id}
                      prefixClass="top-banner-value"
                      subject={`${SUBJECT.LOAD_INFO} ${SUBJECT.LOAD_NUMBER}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.OFFICE_NAME}</span>
                    <TooltipText
                      data={creditRequestById?.officeName}
                      prefixClass="top-banner-value"
                      subject={`${SUBJECT.LOAD_INFO}${SUBJECT.OFFICE_NAME}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.BRANCH_NAME} </span>
                    <TooltipText
                      data={creditRequestById?.branchName}
                      prefixClass="top-banner-value"
                      subject={`${SUBJECT.LOAD_INFO}${SUBJECT.BRANCH_NAME}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.REG_DATE_TIME}</span>{' '}
                    <span className="top-banner-value">{formatDate(creditRequestById?.addedLoadAt)}</span>
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.CUSTOMER_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.CUSTOMER_NAME}</span>
                    <TooltipText
                      data={creditRequestById?.customerName}
                      prefixClass="top-banner-value"
                      subject={`${SUBJECT.LOAD_INFO}${SUBJECT.CUSTOMER_NAME}`}
                    />
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.AGENT_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.AGENT_NAME}</span>
                    <TooltipText
                      data={creditRequestById?.agentName}
                      prefixClass="top-banner-value"
                      subject={`${SUBJECT.LOAD_INFO}${SUBJECT.AGENT_NAME}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.AGENT_PHONE} </span>
                    <Link to={`tel:${creditRequestById?.agentPhone}`}>{creditRequestById?.agentPhone}</Link>
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.TAKEN_AS_MC}:</span>
                    <TooltipText
                      data={creditRequestById?.mc}
                      prefixClass="top-banner-value"
                      subject={`${SUBJECT.LOAD_INFO}${SUBJECT.TAKEN_AS_MC}`}
                    />
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
        </ViewItemWrapper>
      </div>
      <CustomComment
        id="comment"
        watch={watch}
        name="comment"
        setValue={setValue}
        control={control}
        comments={loadsComments}
        createComment={createComment}
        deleteComment={deleteComment}
        getMoreComments={getMoreComments}
        isShowMoreVisible={isShowMoreVisible}
        hasDeleteCommentPermission={hasDeleteCommentPermission}
        isLoadsCommentsLoading={isLoadsCommentsLoading}
      />
    </>
  );
};

export default NewLoadsComment;
