import { ICreator } from 'services/auth/interface';
import { IAuthorityByOfficeId } from 'services/profile/offices/interfaces';

export interface IReceivableComment {
  createdAt: number;
  createdBy: number;
  id: number;
  loadId: number;
  message: string;
  creator: ICreator;
}

export interface IReceivable {
  receivable: {
    aging: number;
    acceptToBillingAt: number;
    authority: IAuthorityByOfficeId;
    branch: Partial<IAuthorityByOfficeId>;
    branchId: number;
    carrierAmount: number;
    carrierFinalAmount: number;
    carrier: Partial<IAuthorityByOfficeId>;
    carrierId: number;
    acceptBillingTimeAt: number | Date;
    createdBy: number;
    creator: ICreator;
    customer: Partial<IAuthorityByOfficeId>;
    customerContact: string;
    customerBalance: number;
    customerFinalAmount: number;
    customerId: number;
    id: number;
    loadType: number;
    office: Partial<IAuthorityByOfficeId>;
    officeId: number;
    paymentTermDay: number;
    paymentTermPercent: number;
    paymentTermType: number;
    referenceNumber: string;
    sentToBillingAt: number;
    soldAuthorityId: number;
    status: number;
    customerStatus: number;
    carrierStatus: number;
    processing: boolean;
    takenAuthorityId: number;
    truckId: number;
    claimed: boolean;
    invoiceReceivedAt: number | Date;
    code: number;
  };
  invoiceDate: number | Date;
  invoiceIds: number[] | [];
  invoiceReceivedByUser?: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    phone: string;
  };
  comment?: IReceivableComment;
}

export interface IReceivablesData {
  result: IReceivable[];
  count: number;
  expectedCustomAmount: number;
  expectedQuickAmount: number;
  expectedTotalAmount: number;
  expectedTotalCount: number;
  receivedCustomAmount: number;
  receivedQuickAmount: number;
  receivedTotalAmount: number;
  receivedTotalCount: number;
}

export interface IGetCustomerInfoParams {
  customerId: string;
  authorityIds: string[];
  skip?: any;
  limit?: any;
}

export interface IFilter {
  [key: string]: string | string[] | null | number | number[] | [[] | string[]] | [string | null];
}

export interface IGetReceivablesParams {
  search: string;
  skip: number;
  limit: number;
  order: number;
  orderBy: string;
  filter?: IFilter;
  field: string;
}

export enum CustomerMoneyReceiveType {
  'Check number' = 1,
  'ACH / Wire' = 2,
  'TCheck' = 3,
  'EFS' = 4,
  'Comdata' = 5,
}

export interface ICustomerBalkPayRequest {
  loadIds: number[];
  moneyType: CustomerMoneyReceiveType | string;
  moneyCode?: number | string;
  amount?: number | string;
  numberOrTransactionId?: number | string;
}

export interface ICustomerInfo {
  authority: IAuthorityByOfficeId;
  authorityId: number;
  createdAt: number | Date;
  customerAmount: number;
  customerDeptAmount: number;
  customerFinalAmount: number;
  customerBalance: number;
  customerStatus: number;
  havePayment: boolean;
  id: number;
  invoiceCreatedAt: number | Date;
  invoiceFileIds: number[] | [];
  status: number;
  code: number;
  type: number;
  claimed: boolean;
  referenceNumber: string;
}

export interface IGetCustomerInfo {
  count: number;
  expected: number;
  received: number;
  result: ICustomerInfo[];
}

export interface ICustomerById {
  result: {
    address: string;
    city: string;
    commentsCount: number;
    createdAt: number;
    createdBy: number;
    creator: ICreator;
    fax: string;
    id: number;
    mc: string;
    name: string;
    paymentTermsInfo: { count: number; type: number }[];
    phone: string;
    state: { id: number; name: string; regionId: number };
    stateId: number;
    status: number;
    terminatedAt: number;
    type: number;
    updatedAt: number;
    zip: string;
  };
}

export interface ICustomerInfoSerialized {
  count: number;
  expected: number;
  received: number;
  result: {
    customerBalance: number;
    customerDept: number;
    customerReceivable: number;
    id: number;
    invoice: {
      date: string;
      ids: [] | number[];
    };
    key: number;
    keyType: string;
    loadId: number;
    mc: string;
    payment: boolean;
    regDate: string;
    status: number;
  }[];
}
