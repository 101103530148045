import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useFileUpload from 'hooks/useFileUpload';
import { useGetPaymentTermsByCustomerIdAndAuthorityIdQuery, useGetTrucksAutocompleteQuery } from 'services/loads/loads';
import { useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { useGetCarriersAutocompleteQuery } from 'services/profile/carriers/carriers';
import { useGetCustomersAutocompleteQuery } from 'services/profile/customers/customers';

import { selectUserPermissionsInfo } from '../../../../store/user-slice/selector';
import { EmployeeLevels, UserTypes } from '../../../../utils/constants';
import { defaultValues } from '../constants/constants';
import { tonuValidation } from '../constants/validation';

const useTonuCreate = () => {
  const {
    searchParams: { open },
  } = useDetectedParams();

  const {
    handleSubmit: handleTonuSubmit,
    control: tonuControl,
    formState: { isValid: isTonuValid, isDirty: isTonuDirty, errors: tonuErrors, dirtyFields, touchedFields },
    clearErrors: clearTonuErrors,
    getValues: getTonuValues,
    watch: tonuWatch,
    reset: resetTonu,
    resetField: resetTonuField,
    setValue: tonuSetValue,
    trigger: tonuTrigger,
    setError: tonuSetError,
  } = useForm<any>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(tonuValidation),
  });

  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const [carriersFilter, setCarriersFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
  });
  const [customerFilter, setCustomerFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
  });
  const [trucksFilter, setTrucksFilter] = useState<Partial<any>>({
    skip: 0,
    search: '',
    limit: 10,
  });
  const [authoritiesState, setAuthoritiesState] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: [],
  });
  const { data: carriersOptions = { result: [], count: 0 } } = useGetCarriersAutocompleteQuery(carriersFilter);

  const { data: truckOptions } = useGetTrucksAutocompleteQuery(
    { carrierId: tonuWatch('carrier')?.[0]?.value, ...trucksFilter },
    { skip: !tonuWatch('carrier')?.[0]?.value }
  );

  const { data: customersOptions = { result: [], count: 0 } } = useGetCustomersAutocompleteQuery(customerFilter, {
    skip: !tonuWatch('customerSwitch'),
  });

  const authoritiesRequestType = () => {
    return !(employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN);
  };

  const { data: authorities = [] } = useGetAuthoritiesShortQuery(
    { ...authoritiesState, onlyAssigned: authoritiesRequestType() },
    {
      skip: !tonuWatch('carrierSwitch'),
    }
  );

  const { currentData: paymentTerm = {}, isFetching: isPaymentTermFetching } =
    useGetPaymentTermsByCustomerIdAndAuthorityIdQuery(
      { customerId: tonuWatch('customerName')?.[0]?.value, authorityId: tonuWatch('takenUsAuthority')?.[0]?.id },
      { skip: !tonuWatch('customerName')?.[0]?.value || !tonuWatch('takenUsAuthority')?.[0]?.id }
    );

  const paymentTermCheck = useMemo(() => {
    return tonuWatch('customerSwitch') ? !!Object.keys(paymentTerm!)?.length : true;
  }, [tonuWatch('customerSwitch'), Object.keys(paymentTerm!)?.length, paymentTerm, isPaymentTermFetching]);

  const handleChangeSoldAsSwitch = () => {
    if (tonuWatch('soldUsSwitch')) {
      resetTonuField('allAuthorities');
    }
    tonuSetValue('soldUsSwitch', !tonuWatch('soldUsSwitch'), { shouldValidate: true });
  };

  const handleChangeCarrierSwitch = () => {
    if (tonuWatch('carrierSwitch')) {
      resetTonuField('carrier');
      resetTonuField('truck');
      resetTonuField('carrierAmount');

      tonuSetValue('soldUsSwitch', false, { shouldValidate: true });
      tonuSetValue('allAuthorities', [], { shouldValidate: true });
    }
    tonuSetValue('carrierSwitch', !tonuWatch('carrierSwitch'));
  };

  const handleChangeCustomerSwitch = () => {
    if (tonuWatch('customerSwitch')) {
      resetTonuField('customerName');
      resetTonuField('referenceNumber');
      resetTonuField('customerAmount');
      resetTonuField('customerContact');
      resetTonuField('takenUsAuthority');
    }
    tonuSetValue('customerSwitch', !tonuWatch('customerSwitch'));
  };

  useEffect(() => {
    if (!isPaymentTermFetching) {
      if (!!tonuWatch('takenUsAuthority').length && !!tonuWatch('customerName').length && !paymentTermCheck) {
        if (tonuWatch('customerSwitch') && !paymentTermCheck) {
          setTimeout(() => {
            tonuSetError('customerName', {
              message: 'Authority and customer are not attached',
            });
          }, 10);
        }
        if (paymentTermCheck) {
          clearTonuErrors('customerName');
        }
      }
    }
  }, [
    tonuWatch('takenUsAuthority')?.[0]?.id,
    tonuWatch('customerSwitch'),
    tonuWatch('customerName')?.[0]?.id,
    paymentTermCheck,
    isPaymentTermFetching,
  ]);

  useEffect(() => {
    if (tonuWatch('customerName')) {
      tonuTrigger('customerName');
    }
  }, [tonuWatch('customerName')]);

  useEffect(() => {
    clearTonuErrors();
  }, [open]);

  useEffect(() => {
    if (open === 'false') {
      resetTonu(defaultValues);
    }
  }, [open]);

  const findIgnoreAuthoritiesIds = () => {
    if (tonuWatch('allAuthorities')?.length && tonuWatch('takenUsAuthority')?.length) {
      return { ignoreAuthorityIds: [tonuWatch('allAuthorities')?.[0]?.id, tonuWatch('takenUsAuthority')?.[0]?.id] };
    } else if (!tonuWatch('allAuthorities')?.length && tonuWatch('takenUsAuthority')?.length) {
      return {
        ignoreAuthorityIds: [tonuWatch('takenUsAuthority')?.[0]?.id],
      };
    } else if (tonuWatch('allAuthorities')?.length && !tonuWatch('takenUsAuthority')?.length) {
      return { ignoreAuthorityIds: [tonuWatch('allAuthorities')?.[0]?.id] };
    } else {
      return {
        ignoreAuthorityIds: [],
      };
    }
  };

  useEffect(() => {
    setAuthoritiesState((prev: any) => {
      return {
        ...prev,
        ...findIgnoreAuthoritiesIds(),
      };
    });
  }, [tonuWatch('takenUsAuthority'), tonuWatch('allAuthorities')]);

  const {
    handleViewFileCreate,
    handleDeleteFileCreate,
    handleDownloadFileCreate,
    beforeUploadForCreate,
    allFiles,
    setAllFiles,
    savedFiles,
    handleDownloadMultiplyFileCreate,
  } = useFileUpload({ setValue: tonuSetValue, getValues: tonuWatch, trigger: tonuTrigger });

  return {
    carriersOptions,
    setCarriersFilter,
    truckOptions,
    setTrucksFilter,
    customersOptions,
    setCustomerFilter,
    authorities,
    setAuthoritiesState,
    tonuWatch,
    getTonuValues,
    tonuSetValue,
    resetTonu,
    clearErrors: clearTonuErrors,
    handleTonuSubmit,
    isDirty: isTonuDirty,
    isValid: isTonuValid,
    touchedFields,
    tonuControl,
    tonuErrors,
    handleChangeCarrierSwitch,
    handleChangeCustomerSwitch,
    handleChangeSoldAsSwitch,
    handleViewFileCreate,
    handleDeleteFileCreate,
    handleDownloadFileCreate,
    handleDownloadMultiplyFileCreate,
    beforeUploadForCreate,
    allFiles,
    setAllFiles,
    savedFiles,
    resetTonuField,
    dirtyFields,
    tonuTrigger,
  };
};

export default useTonuCreate;
