import Button from 'ui/button/Button';
import ExcelButton from 'ui/excel-button/ExcelButton';
import { getProfileTabs } from 'utils/constants';

import CustomizeColumnButton from 'components/customize-column-button/CustomizeColumnButton';
import TabPanel from 'components/tab-panel/TabPanel';

import { SUBJECT } from './constants/constants';
import useProfile from './useProfile';

const Profile = ({ sendAgentsActivityWatchMessage }: any) => {
  const {
    tab,
    openMainDrawer,
    downloadExcel,
    Component,
    isButtonVisible,
    permissionsInfo,
    hasAddPermission,
    hasDownloadPermission,
  } = useProfile({ sendAgentsActivityWatchMessage });

  return (
    <div>
      <TabPanel tabs={getProfileTabs(permissionsInfo?.permissions?.profile?.visibleTabs)}>
        <div className="tabPanelButtons">
          <CustomizeColumnButton onClick={() => openMainDrawer('customize')} isButtonVisible={isButtonVisible} />
          {hasAddPermission && (
            <Button
              onClick={() => openMainDrawer('add')}
              children={SUBJECT[tab.toUpperCase() as keyof typeof SUBJECT]}
              className="panelAddButton"
            />
          )}
          {hasDownloadPermission && <ExcelButton onClick={downloadExcel} isButtonVisible={isButtonVisible} />}
        </div>
      </TabPanel>
      <div>{Component[tab]}</div>
    </div>
  );
};
export default Profile;
