import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useChangeStatusMutation } from 'services/profile/customers/customers';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { usePermittedActions } from '../../../../../../hooks/usePermittedActions';
import { customerTypeOptions, SUBJECT } from '../../constants/constants';

import { ICustomersTableProps, ICustomerTableColumns } from './constants/types';

const useCustomersTable = ({
  setFilterValue,
  filterReset,
  filterValue,
  filterWatch,
  editColumns,
  setCustomersFilter,
}: ICustomersTableProps) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, tab, open, customerType, activePage } = searchParams;

  const [changeStatus] = useChangeStatusMutation();

  const filterData = filterWatch('customerType');

  const filterTypes = () => {
    const obj: any = {};

    Array.isArray(filterData) &&
      filterData?.forEach((elm, index) => {
        if (elm !== '0') {
          obj[`type[${index}]`] = elm;
        }
      });
    return obj;
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterValue.length ? { customerType: filterValue } : {}),
    });
    if (!filterData.length) {
      setSearchParams({
        mode,
        tab,
        open,
        activePage,
      });
    }
  }, [filterValue?.length]);

  useEffect(() => {
    const filterOptions = [{ key: 'customerType', value: customerType ? customerType.split(',') : customerType }];
    const allFiltersSelected: string[] = customerTypeOptions?.map(({ value }) => value);

    filterOptions.forEach(({ value }) => {
      if (value) {
        setFilterValue('customerType', value.includes('all') ? allFiltersSelected : value);

        setCustomersFilter((prev: any) => ({
          ...prev,
          skip: 0,
          filter: filterTypes(),
        }));
      }
    });
  }, [customerType]);

  useEffect(() => {
    if (filterData.length === customerTypeOptions.length) {
      setSearchParams({
        ...searchParams,
        customerType: 'all',
      });
    }
    setCustomersFilter((prev: any) => ({ ...prev, filter: filterTypes() }));
  }, [filterData.length]);

  const customerTablePagePermissions = usePermittedActions('profiles.customers.customerTable');

  const handleResetFilter = () => {
    filterReset();
    setSearchParams({
      mode,
      tab,
      open,
      activePage,
    });
    setCustomersFilter((prev: any) => ({
      search: prev.search,
      skip: prev.skip,
      limit: prev.limit,
      order: prev.order,
      orderBy: prev.orderBy,
      filter: {},
    }));
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterValue.length ? { customerType: filterValue } : {}),
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleSwitchClick = (checked: boolean, rowId: number | string) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({ id: rowId, status: checked ? 1 : 2 }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleRowEdit = (rowId: number) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterValue.length ? { customerType: filterValue } : {}),
      mode: 'edit',
      open: 'true',
      id: String(rowId),
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setCustomersFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleStopResize = (columns: ICustomerTableColumns[]) => {
    editColumns({ type: 'customers', columns });
  };

  return {
    filterData,
    handleRowClick,
    handleResetFilter,
    handleRowEdit,
    handleSwitchClick,
    handleStopResize,
    handleColumnSearch,
    customerTablePagePermissions,
  };
};

export default useCustomersTable;
