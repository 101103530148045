export const handleBalanceAmount = (customer: number, carrier: number) => {
  let amount = customer || carrier;

  if (customer < 0 && carrier > 0) {
    amount = customer - carrier;
  } else if ((customer < 0 && carrier < 0) || (customer > 0 && carrier > 0)) {
    amount = customer - carrier;
  } else if (customer > 0 && carrier < 0) {
    amount = customer - carrier;
  } else if (customer === 0 && carrier < 0) {
    amount = Math.abs(carrier);
  } else if (customer === 0 && carrier > 0) {
    amount = -Math.abs(carrier);
  }

  return amount;
};
