import { IMcAndCustomersTableColumns, IMcAndCustomersTableData } from './types';

export const MC_AND_CUSTOMERS_TABLE_COLUMNS: IMcAndCustomersTableColumns[] = [
  {
    title: 'MC name',
    id: 'mc_name',
    dataIndex: 'mc_name',
    displayType: 'text',
    isVisible: true,
    sortable: false,
    width: 200,
  },
  {
    title: 'MC number',
    id: 'mc_number',
    dataIndex: 'mc_number',
    displayType: 'text',
    isVisible: true,
    sortable: false,
    width: 200,
  },
  {
    title: 'Customer name',
    id: 'customer_name',
    dataIndex: 'customer_name',
    displayType: 'text',
    isVisible: true,
    sortable: false,
    width: 200,
  },
  {
    title: 'Customer MC',
    id: 'customer_mc',
    dataIndex: 'customer_mc',
    displayType: 'text',
    isVisible: true,
    sortable: false,
    width: 200,
  },
  {
    title: 'Payment terms type',
    id: 'payment_terms_type',
    dataIndex: 'payment_terms_type',
    displayType: 'text',
    isVisible: true,
    sortable: false,
    width: 200,
  },
];

export const MC_AND_CUSTOMERS_TABLE_DATA: IMcAndCustomersTableData[] = [
  {
    id: 1,
    mc_name: 'Vilkon',
    mc_number: '123456789',
    customer_name: 'Real broker',
    customer_mc: '234234234',
    payment_terms_type: 'Custom / 22 day / 12%',
  },
  {
    id: 2,
    mc_name: 'Vilkon',
    mc_number: '123456789',
    customer_name: 'Real broker',
    customer_mc: '234234234',
    payment_terms_type: 'Custom / 22 day / 12%',
  },
  {
    id: 3,
    mc_name: 'Vilkon',
    mc_number: '123456789',
    customer_name: 'Real broker',
    customer_mc: '234234234',
    payment_terms_type: 'Custom / 22 day / 12%',
  },
  {
    id: 4,
    mc_name: 'Vilkon',
    mc_number: '123456789',
    customer_name: 'Real broker',
    customer_mc: '234234234',
    payment_terms_type: 'Custom / 22 day / 12%',
  },
];

export enum SUBJECT {
  MC_AND_CUSTOMERS_IS_EMPTY = 'MC and customers is empty',
  SAVE = 'Save',
}
