import React, { ReactNode } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { formatDate } from 'utils/dates';

import Typography from '../../../ui/typography/Typography';
import { CreatorTypes, links } from '../constants';
import { EMessageTypes, Handler, ISocketMessage } from '../interfaces';
import Notification from '../notification/Notification';

export class TruckHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      create: this.handleCreate,
      edit: this.handleEdit,
      delete: this.handleDelete,
      hasALoad: this.handleHasALoad,
      addedSearch: this.handleAddedSearch,
      addedToVerificationBoard: this.handleAddedToVerificationBoard,
    };
  }
  handleCreate = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'added truck in' },
          { id, title, link: `${links.carriers}${id}` },
          { title: 'carrier' },
        ]}
        title="Truck added"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };

  handleEdit = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'edited truck in' },
          { id, title, link: `${links.carriers}${id}` },
          { title: 'carrier' },
        ]}
        title="Truck edited"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };

  handleDelete = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'deleted truck in' },
          { id, title, link: `${links.carriers}${id}` },
          { title: 'carrier' },
        ]}
        title="Truck deleted"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.danger}
      />
    );
  };
  handleHasALoad = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title, id },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title, link: `${links.verificationBoard}${id}&carrierId=${creatorID}&truckId=${id}` },
          { title: 'truck was sent to verification board by ' },
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: 'after clicking "already has a load"' },
        ]}
        title="Truck has a load"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleAddedSearch = (event: ISocketMessage) => {
    const {
      creator: { title: _creatorName, id: creatorID, type },
      event: { title, id },
      additionalData,
      createdAt,
      read,
    } = event.payload;

    const arrayOfToStates = additionalData[1].split(',');
    const arrayOfFromStates = additionalData[0].split(',');

    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id, title },
          { title: 'truck was added in your search from' },
          {
            title: !!arrayOfFromStates?.length && (
              <>
                <span className="top-banner-value">{`${arrayOfFromStates?.[0]}${
                  arrayOfFromStates[1] ? ', ' + arrayOfFromStates[1] : ''
                }`}</span>
                &nbsp;
                <Tooltip
                  color="white"
                  placement="top"
                  zIndex={999999}
                  title={
                    arrayOfFromStates?.length > 2 && (
                      <div>
                        <Row gutter={8}>
                          {arrayOfFromStates?.slice(2)?.map((el: any, index: number) => (
                            <Col key={index}>
                              <Typography variant="paragraph">
                                <span>{el}, </span>
                              </Typography>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )
                  }
                >
                  {arrayOfFromStates?.length > 2 && (
                    <a className="top-banner-value">{`+${arrayOfFromStates?.length - 2}`}</a>
                  )}
                </Tooltip>
              </>
            ),
            component: arrayOfFromStates?.length > 2,
          },
          { title: 'to' },
          {
            title: !!arrayOfToStates?.length && (
              <>
                <span className="top-banner-value">{`${arrayOfToStates?.[0]}${
                  arrayOfToStates[1] ? ', ' + arrayOfToStates[1] : ''
                }`}</span>
                &nbsp;
                <Tooltip
                  color="white"
                  placement="top"
                  zIndex={999999}
                  title={
                    arrayOfToStates?.length > 2 && (
                      <div>
                        <Row gutter={8}>
                          {arrayOfToStates?.slice(2)?.map((el: any, index: number) => (
                            <Col key={index}>
                              <Typography variant="paragraph">
                                <span>{el}, </span>
                              </Typography>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )
                  }
                >
                  {arrayOfToStates?.length > 2 && (
                    <a className="top-banner-value">{`+${arrayOfToStates?.length - 2}`}</a>
                  )}
                </Tooltip>
              </>
            ),
            component: arrayOfToStates?.length > 2,
          },
        ]}
        title="Truck added to search"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
  handleAddedToVerificationBoard = (event: ISocketMessage) => {
    const {
      event: { title },
      createdAt,
      read,
    } = event.payload;
    this.notification = (
      <Notification
        read={read}
        message={[{ title }, { title: 'truck was added to verification board' }]}
        title="Truck added to Verification board"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.info}
      />
    );
  };
}
