import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetColumnsQuery } from 'services/user/user';

const useCarrierRequest = ({ setCarrierRequestSeldfFilter }: any) => {
  const { searchParams, setSearchParams } = useDetectedParams();

  const { data: columnsData } = useGetColumnsQuery({ type: 'truckBoardCarriersRequest' });

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      mode: 'carrierView',
      open: 'true',
      id: rowId,
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setCarrierRequestSeldfFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setCarrierRequestSeldfFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };
  const handlePaginationClick = (skip: number, limit: number) => {
    setCarrierRequestSeldfFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  return {
    columns: columnsData?.columns,
    handleRowClick,
    handleSortClick,
    handlePaginationClick,
    handleColumnSearch,
  };
};

export default useCarrierRequest;
