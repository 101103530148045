import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { useGetTruckInfoByIdQuery } from 'services/truck-board/truck-board/truckBoard';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { scrollToTarget } from 'utils/helpers';

import { ICommentViewForm } from '../../constants/types';

const useViewEditCarrierTruck = () => {
  const { userType } = useSelector(selectUserPermissionsInfo);
  const { searchParams } = useDetectedParams();
  const { truckId, singleStatus, tab } = searchParams;

  const drawerRef: { current: any } = useRef(null);

  const isInFavoriteTab = tab === 'favorite_trucks';

  const {
    control: commentsControl,
    watch: commentsWatch,
    setValue: commentsSetValue,
  } = useForm<ICommentViewForm>({
    defaultValues: { comment: '' },
  });
  const {
    currentData: truckInfoById,
    isFetching: isFetchingTruckInfoById,
    refetch: refetchTruckInfoById,
  } = useGetTruckInfoByIdQuery(
    {
      truckId: truckId.split('-')[0],
      truckBoardStatus: isInFavoriteTab ? null : singleStatus,
      loadId: truckId.split('-')[1],
    },
    {
      skip: !truckId,
    }
  );

  const verificationPermissions = usePermittedActions('truckBoard.verification');
  const handleScrollToHistory = () => {
    scrollToTarget(drawerRef);
  };

  return {
    commentsControl,
    commentsSetValue,
    commentsWatch,
    userType,
    handleScrollToHistory,
    drawerRef,
    truckInfoById,
    refetchTruckInfoById,
    isFetchingTruckInfoById,
    verificationPermissions,
  };
};

export default useViewEditCarrierTruck;
