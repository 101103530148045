import React, { useDeferredValue } from 'react';

import Table from 'components/table/Table';

import { useNewLoad } from './useNewLoad';

import styles from './NewLoad.module.scss';

const NewLoad = ({ setExcelParams }: any) => {
  const {
    data,
    columns,
    isLoading,
    skip,
    limit,
    handleRowClick,
    handleSortClick,
    handleColumnSearch,
    handlePaginationClick,
    handleStopResize,
    handleRowType,
    handleCommentClick,
  } = useNewLoad(setExcelParams);

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onColumnSearch: handleColumnSearch,
    onCommentClick: handleCommentClick,
    onPaginationClick: handlePaginationClick,
    onActionButton: handleRowType,
    withMenu: false,
  });

  return (
    <>
      <div className={styles.loadsTabContainer}>
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default NewLoad;
