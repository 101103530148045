import React, { forwardRef, LegacyRef } from 'react';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import { CalendarIcon } from 'components/svgs/CalendarIcon';
import CloseIcon from 'components/svgs/CloseIcon';

import { SUBJECT } from '../../constants/constants';
import { ICustomInput } from '../../constants/types';

import styles from './DatePickerInput.module.scss';

const DatepickerInput = forwardRef(
  (
    { mandatory, value, isOpened, setIsOpen, handleClear, inputText, disabled }: ICustomInput,
    ref: LegacyRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(styles.datePickerInputWrapper, {
          [styles.opened]: isOpened,
          [styles.filled]: value,
          [styles.required]: mandatory,
          [styles.disabled]: disabled,
        })}
        role="button"
        onClick={() => {
          if (disabled) return;
          setIsOpen((prev: boolean) => !prev);
        }}
      >
        <input value={value} type="text" readOnly />
        {value ? (
          <div role="button" className={styles.datePickerControl} onClick={handleClear}>
            <CloseIcon />
          </div>
        ) : (
          <div className={styles.datePickerControl}>
            <CalendarIcon />
          </div>
        )}
        <Typography className={styles.placeholder} variant="paragraph" children={inputText ?? SUBJECT.PLACEHOLDER} />
      </div>
    );
  }
);

export default DatepickerInput;
