import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { SUBJECT } from 'pages/profile/tabs/customers/constants/constants';
import { ICustomersFormProps } from 'pages/profile/tabs/customers/constants/types';
import { CustomerType } from 'services/profile/customers/interfaces';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatPhone } from 'utils/helpers';

import CustomComment from 'components/custom-comment/CustomComment';
import CopyIcon from 'components/svgs/CopyIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import CreateCustomerForm from '../create-form/CreateCustomersForm';

import styles from './EditCustomersForm.module.scss';

const EditCustomersForm = ({
  handleSubmit,
  onSubmit,
  control,
  errors,
  setValue,
  resetField,
  reset,
  paymentCheck,
  type,
  mc,
  watch,
  addedMc,
  authorities,
  statesData,
  trigger,
  authoritiesFilter,
  setAuthoritiesFilter,
  customerById,
  createComment,
  deleteComment,
  customerComments,
  handlePaymentPaginationClick,
  isFetchingById,
  getMoreComments,
  mode,
  clearErrors,
  setError,
  isShowMoreVisible,
  paymentTermsCount,
  userInfo,
  isEditLoading,
  isCreateLoading,
  isSubmitted,
  hasDeleteCommentPermission,
  defaultValues,
  isCustomerCommentsLoading,
  refetchAuthoritiesShort,
}: ICustomersFormProps) => {
  const dispatch = useDispatch();
  const { copiedText, handleCopy } = useHandleCopyActive();

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isEditLoading && !isCreateLoading }));
  }, [isEditLoading, isCreateLoading]);

  return (
    <>
      <MainLoader isFetching={isFetchingById} />
      <ViewItemWrapper title={SUBJECT.CUSTOMER_INFO}>
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.NAME}:{' '}
              </Typography>

              <TooltipText
                prefixClass="top-banner-value"
                data={customerById?.result.name}
                subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.NAME}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.PHONE_NUMBER}:{' '}
              </Typography>
              <div className={styles.textWithLinks}>
                <a className="top-banner-value" href={`tel:${customerById?.phone}`}>
                  {formatPhone(customerById?.result.phone)}
                </a>
              </div>
            </div>
          </Col>
          {customerById?.result.fax && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.FAX}:{' '}
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.result.fax}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.FAX}`}
                />
              </div>
            </Col>
          )}
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.TYPE}:{' '}
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {CustomerType[customerById?.result.type]}
              </Typography>
            </div>
          </Col>
          {customerById?.result.ff && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.FF_NUMBER}:{' '}
                </Typography>

                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.result.ff}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.FF_NUMBER}`}
                />
              </div>
            </Col>
          )}
          {customerById?.result.id && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.CUSTOMER_ID}:{' '}
                </Typography>
                <Col span={13}>
                  <div
                    className={clsx(styles.copyIconText, {
                      [styles.copyActive]: copiedText === 'topBannerId',
                    })}
                  >
                    <Typography
                      variant="paragraph"
                      children={customerById?.result.id}
                      className={styles.infoParagraph}
                    />
                    <div
                      className={styles.copyIcon}
                      role="button"
                      onClick={() => handleCopy(customerById?.result.id, 'topBannerId')}
                    >
                      <CopyIcon active={copiedText === 'topBannerId'} />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
          )}
          {customerById?.result.dot && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.DOT}:{' '}
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.result.dot}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.DOT}`}
                />
              </div>
            </Col>
          )}
          {customerById?.result.mc && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.MC_NUMBER}:{' '}
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.result.mc}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.MC_NUMBER}`}
                />
              </div>
            </Col>
          )}

          {customerById?.result.stateNumber && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.STATE_NUMBER}:{' '}
                </Typography>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={customerById?.result.stateNumber}
                  subject={`${SUBJECT.CUSTOMER_INFO}${SUBJECT.STATE_NUMBER}`}
                />
              </div>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <CreateCustomerForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        resetField={resetField}
        reset={reset}
        paymentCheck={paymentCheck}
        type={type}
        mc={mc}
        addedMc={addedMc}
        statesData={statesData}
        authorities={authorities}
        authoritiesFilter={authoritiesFilter}
        setAuthoritiesFilter={setAuthoritiesFilter}
        customerComments={customerComments}
        handlePaymentPaginationClick={handlePaymentPaginationClick}
        isFetchingById={isFetchingById}
        mode={mode}
        trigger={trigger}
        setError={setError}
        clearErrors={clearErrors}
        paymentTermsCount={paymentTermsCount}
        userInfo={userInfo}
        isEditLoading={isEditLoading}
        isCreateLoading={isCreateLoading}
        isSubmitted={isSubmitted}
        defaultValues={defaultValues}
        refetchAuthoritiesShort={refetchAuthoritiesShort}
      />
      <CustomComment
        id={SUBJECT.COMMENT_ID}
        comments={customerComments}
        name="commentWithNote"
        control={control}
        setValue={setValue}
        watch={watch!}
        deleteComment={deleteComment}
        createComment={createComment}
        getMoreComments={getMoreComments}
        isShowMoreVisible={isShowMoreVisible}
        canCreate={!userInfo?.agentType}
        canDelete={!userInfo?.agentType}
        hasDeleteCommentPermission={hasDeleteCommentPermission}
        isLoadsCommentsLoading={isCustomerCommentsLoading}
      />
    </>
  );
};

export default EditCustomersForm;
