import { ReactNode } from 'react';

import DangerIcon from 'components/svgs/DangerIcon';
import InfoIcon from 'components/svgs/InfoIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

export const ICONS: Record<string, ReactNode> = {
  '1': <SuccessIcon />,
  '2': <DangerIcon />,
  '3': <InfoIcon />,
};
