import React from 'react';
import { Table } from 'antd';
import clsx from 'clsx';
import { IDataSource } from 'ui/data-grid/constants/types';
import Typography from 'ui/typography/Typography';

import { IPaymentTermDataGridProps } from './constants/types';
import usePaymentTermDataGrid from './usePaymentTermDataGrid';

import styles from './PaymentTermDataGrid.module.scss';

const PaymentTermDataGrid = ({
  title,
  data,
  count,
  handlePaginationClick,
  handleRowEdit,
  handleRowDelete,
  editingRow,
  setEditingRow,
  localFiles,
  isLoading = false,
  serializedStates,
  withoutAction = false,
}: IPaymentTermDataGridProps) => {
  const { CURRENT_COLUMNS, paginationArrows, pageSizeOptions } = usePaymentTermDataGrid({
    handleRowDelete,
    handleRowEdit,
    setEditingRow,
    data,
    localFiles,
    serializedStates,
    withoutAction,
  });

  return (
    <div className={styles.tableWrapper}>
      {title && <Typography children={title} variant="paragraph" className={styles.heading} />}

      <Table
        tableLayout="fixed"
        rowClassName={({ id }: IDataSource) =>
          clsx({
            ['activeRow']: id === editingRow,
          })
        }
        columns={CURRENT_COLUMNS}
        dataSource={data}
        rowKey={record => record.key}
        pagination={
          count! > 10 && {
            showTotal(total, [from, to]) {
              return <span>{`${from}-${to} of ${total}`}</span>;
            },
            onChange(page, pageSize) {
              handlePaginationClick && handlePaginationClick((page - 1) * pageSize, pageSize);
            },
            pageSizeOptions: pageSizeOptions(count!),
            itemRender: paginationArrows,
            locale: { items_per_page: '' },
            showSizeChanger: true,
            total: count!,
          }
        }
        loading={isLoading}
      />
    </div>
  );
};

export default PaymentTermDataGrid;
