import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import { CalendarIcon } from 'components/svgs/CalendarIcon';
import ChevronIcon from 'components/svgs/ChevronIcon';

import CustomDatePicker from '../custom-date-picker/CustomDatePicker';

import CustomMonthRangePicker from './components/custom-month-rangepicker/CustomMonthRangePicker';
import { months, SUBJECT } from './constants/constants';
import { IMonthPickerProps } from './constants/types';
import { useMonthPicker } from './useMonthPicker';

import styles from './MonthPicker.module.scss';

const MonthPicker = ({ name, ...props }: IMonthPickerProps) => {
  const {
    handleChangeYear,
    year,
    month,
    handleOpenList,
    isOpenedMonth,
    ref,
    handleChangeMonth,
    handleOpenCalendar,
    card,
    isDisabled,
    handleAllRequest,
    showInputValue,
    isCalendarOpen,
    setIsCalendarOpen,
    handleGoBack,
    startDate,
    endDate,
    onRangeChange,
  } = useMonthPicker(name || '');

  return (
    <div className={styles.monthPickerWrapper} id={`month_${name}`} {...props}>
      <label>{SUBJECT.MONTH}</label>
      <ul className={styles.monthPickerList} ref={ref}>
        <li className={styles.monthPickerItem}>
          <div
            className={clsx(styles.selectMonth, {
              [styles.openedPicker]: isOpenedMonth || isCalendarOpen,
            })}
            role="button"
            onClick={handleOpenList}
          >
            {showInputValue}
            <span>
              <CalendarIcon />
            </span>
          </div>
          <ul
            className={clsx(styles.subList, {
              [styles.openedList]: isOpenedMonth,
            })}
          >
            <li>
              <div className={styles.yearPicker}>
                <span role="button" onClick={() => handleChangeYear(SUBJECT.DECREASE)}>
                  <ChevronIcon />
                </span>
                <Typography variant="paragraph">{year}</Typography>
                <span
                  role="button"
                  className={clsx({
                    [styles.disabledBtn]: isDisabled,
                  })}
                  onClick={() => handleChangeYear(SUBJECT.INCREASE)}
                >
                  <ChevronIcon />
                </span>
              </div>
            </li>
            {months?.map(({ name, value }, index) => (
              <li
                role="button"
                className={clsx(styles.monthItem, {
                  [styles.selectedMonth]: name === month,
                })}
                key={value}
                value={value}
                onClick={() => handleChangeMonth(index)}
              >
                {name}
              </li>
            ))}
            <li role="button" className={styles.allButton} onClick={handleAllRequest}>
              {SUBJECT.ALL}
            </li>
            {card !== '7' && (
              <li role="button" className={styles.customButton} onClick={() => handleOpenCalendar(name || '')}>
                {name === 'monthPickerPayout' ? SUBJECT.RANGE : SUBJECT.CUSTOM}
              </li>
            )}
          </ul>
        </li>
      </ul>
      <div className={styles.monthPickerWrapper}>
        {isCalendarOpen && name === 'monthPickerPayout' ? (
          <CustomMonthRangePicker
            isCalendarOpen={isCalendarOpen}
            setIsCalendarOpen={setIsCalendarOpen}
            handleGoBack={handleGoBack}
          />
        ) : (
          <CustomDatePicker
            selectsRange
            isCalendarOpen={isCalendarOpen}
            setIsCalendarOpen={setIsCalendarOpen}
            handleDateChange={onRangeChange}
            start={startDate}
            end={endDate}
            isMonthPicker
          />
        )}
      </div>
    </div>
  );
};

export default MonthPicker;
