import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useAddTruckToCarrierMutation,
  useCreateFuelCardMutation,
  useDeleteFuelCardMutation,
  useDeleteTruckByIdMutation,
  useEditFuelCardMutation,
  useEditTruckByIdMutation,
} from 'services/profile/carriers/carriers';
import { ICarrierType } from 'services/profile/carriers/interfaces';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { changeLoading } from 'store/sidebarSlice/sidebar';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { scrollToTarget } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from './constants/constants';

export const useCreateCarrier = ({
  watch,
  setValue,
  truckSectionRef,
  resetField,
  errors,
  carrierInfo,
  setTruckState,
  truckState,
  trucksByCarrierId,
  refetchTrucksById,
}: any) => {
  const dispatch = useDispatch();

  const {
    searchParams: { mode, id, activePage, isAproveMode, open },
  } = useDetectedParams();

  const [addTruckToCarrier, { isLoading }] = useAddTruckToCarrierMutation();
  const [deleteTruckById] = useDeleteTruckByIdMutation();
  const [editTruckById, { isLoading: isLoadingEdit }] = useEditTruckByIdMutation();

  const [addedTrucks, setAddedTrucks] = useState<any>([]);
  const [isVisibleTrucksForm, setIsVisibleTrucksForm] = useState(mode !== 'edit');
  const [isEditedRow, setIsEditedRow] = useState(false);
  const [editingRow, setEditingRow] = useState<number | null>();

  const [fuelCards, setFuelCards] = useState<any>([]);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [isVisibleCardsForm, setIsVisibleCardsForm] = useState(true);

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const isAddTruckBtnDisabled =
    (!watch('truckNumber') ||
      (watch('boxTruck') === 1 && !watch('length')) ||
      errors?.length ||
      errors?.truckNumber ||
      errors?.yearAndMake ||
      errors?.boxTruck) &&
    !isLoading &&
    !isLoadingEdit;

  const handleResetTruck = (ctx: any) => {
    setValue('truckNumber', '', { shouldDirty: false, shouldTouch: false, shouldValidate: !!watch('trucks').length });
    setValue('yearAndMake', '', { shouldDirty: false, shouldTouch: false, shouldValidate: true });
    setValue('boxTruck', 2, { shouldDirty: false, shouldTouch: false, shouldValidate: true });
    setValue('length', '', { shouldDirty: false, shouldTouch: false, shouldValidate: true });
    setValue('truckId', '', { shouldDirty: false, shouldTouch: false, shouldValidate: true });
    if (ctx !== 'no reset equipment') {
      setValue('equipmentType', [], { shouldDirty: false, shouldTouch: false, shouldValidate: true });
      setValue('numberOfTrucks', '');
      setValue('numberOfTrailers', '');
    }

    setEditingRow(null);
  };

  const handleAddTruck = (data: any) => {
    if (addedTrucks?.some(({ truckNumber }: any) => truckNumber === data.truckNumber) && !isEditedRow) {
      notification.error({
        message: ServerErrorCodes[1104],
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    } else {
      if (mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard' && !isLoading) {
        if (carrierInfo?.type === ICarrierType['Outside'] && watch('type') === String(ICarrierType['Owner Operator'])) {
          if (addedTrucks?.some(({ truckId }: any) => truckId === selectedRow)) {
            const editedIndex = addedTrucks.findIndex((el: any) => el.truckId === selectedRow);

            const updatedTrucks = [...addedTrucks];
            updatedTrucks[editedIndex] = data;
            setValue('trucks', updatedTrucks);
            setAddedTrucks(updatedTrucks);
            setIsEditedRow((prev: boolean) => !prev);
          } else {
            setAddedTrucks((prev: any) => [data, ...prev]);
          }
          setIsVisibleTrucksForm(false);
          handleResetTruck('no reset equipment');
        } else if (!data.id) {
          dispatch(changeLoading(true));
          addTruckToCarrier({
            carrierId: id,
            truckNumber: data?.truckNumber,
            yearAndMake: data?.yearAndMake,
            boxTruck: data?.boxTruck,
            length: Number(data?.length),
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: 'Successfully added',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              handleResetTruck('no reset equipment');
              setIsVisibleTrucksForm(false);
              dispatch(changeLoading(false));
            }
          });
        } else if (!isLoadingEdit) {
          dispatch(changeLoading(true));
          editTruckById({
            id: Number(data?.id),
            carrierId: Number(id),
            truckNumber: data?.truckNumber,
            yearAndMake: data?.yearAndMake,
            boxTruck: data?.boxTruck,
            length: data?.length,
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: 'Successfully edited',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              handleResetTruck('no reset equipment');
              setIsVisibleTrucksForm(false);
              setIsEditedRow((prev: boolean) => !prev);
              dispatch(changeLoading(false));
            }
          });
        }
      } else {
        if (
          (mode === 'add' || activePage === 'request' || activePage === 'carrierOnBoard') &&
          addedTrucks?.some(({ truckId }: any) => truckId === selectedRow)
        ) {
          const editedIndex = addedTrucks.findIndex((el: any) => el.truckId === selectedRow);

          const updatedTrucks = [...addedTrucks];
          updatedTrucks[editedIndex] = data;
          setValue('trucks', updatedTrucks);
          setAddedTrucks(updatedTrucks);
          setIsEditedRow((prev: boolean) => !prev);
        } else {
          setAddedTrucks((prev: any) => [data, ...prev]);
        }
        setIsVisibleTrucksForm(false);
        handleResetTruck('no reset equipment');
      }
    }
    setSelectedRow(null);
  };

  const handleCloseTruckForm = () => {
    setIsVisibleTrucksForm(false);
    handleResetTruck('no reset equipment');
    setSelectedRow(null);
  };

  const handleOpenTruckForm = () => {
    setIsVisibleTrucksForm(true);
  };

  const [createFuelCard, { isLoading: isCreateCardLoading }] = useCreateFuelCardMutation();
  const [deleteFuelCard] = useDeleteFuelCardMutation();
  const [editFuelCard] = useEditFuelCardMutation();

  const isFuelCardValid = watch('fuelCard')?.length >= 16 && watch('fuelCard')?.length <= 24;

  const handleAddFuelCard = () => {
    if (isFuelCardValid) {
      if (fuelCards.some(({ card }: any) => card === watch('fuelCard')) && !selectedCard?.card) {
        notification.error({
          message: 'Fuel card is already exists',
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        if (selectedCard?.card) {
          if (mode === 'edit' && isAproveMode !== 'true') {
            editFuelCard({
              id: selectedCard?.id,
              data: { card: watch('fuelCard'), main: watch('isMainFuelCard') },
            }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully edited',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              }
            });
          } else {
            const filtered = fuelCards.filter(({ card }: any) => card !== selectedCard?.card);
            const resetedFuelCards = filtered.map((el: any) => {
              return {
                card: el?.card,
                main: watch('isMainFuelCard') ? false : el?.main,
              };
            });
            setFuelCards(() => [...resetedFuelCards, { card: watch('fuelCard'), main: watch('isMainFuelCard') }]);
            if (mode === 'edit') {
              setValue('fuelCards', [...resetedFuelCards, { card: watch('fuelCard'), main: watch('isMainFuelCard') }]);
            }
          }
        } else {
          if (mode === 'edit' && isAproveMode !== 'true') {
            createFuelCard({ id, data: { card: watch('fuelCard'), main: watch('isMainFuelCard') } }).then(
              (data: any) => {
                if (data.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                } else {
                  notification.success({
                    message: 'Successfully added',
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                }
              }
            );
          } else {
            if (mode === 'edit') {
              if (fuelCards.length) {
                const resetedFuelCards = fuelCards?.map((el: any) => {
                  return {
                    card: el?.card,
                    main: watch('isMainFuelCard') ? false : el?.main,
                  };
                });
                setValue('fuelCards', [
                  ...resetedFuelCards,
                  { card: watch('fuelCard'), main: watch('isMainFuelCard') },
                ]);
              } else {
                setValue('fuelCards', [{ card: watch('fuelCard'), main: watch('isMainFuelCard') }]);
              }
            }
            setFuelCards((prev: any) => {
              if (prev?.length) {
                const resetedFuelCards = prev?.map((el: any) => {
                  return {
                    card: el?.card,
                    main: watch('isMainFuelCard') ? false : el?.main,
                  };
                });
                return [...resetedFuelCards, { card: watch('fuelCard'), main: watch('isMainFuelCard') }];
              } else {
                return [{ card: watch('fuelCard'), main: watch('isMainFuelCard') }];
              }
            });
          }
        }
        resetField('fuelCard');
        resetField('isMainFuelCard');
      }
    }
    setSelectedCard(null);
    setIsVisibleCardsForm(false);
  };

  const handleFuelCardEdit = (data: any) => {
    setValue('fuelCard', data?.card);
    setValue('isMainFuelCard', data?.main);
    setSelectedCard(data);
    setIsVisibleCardsForm(true);
  };

  const handleDeleteFuelCard = (data: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          if (mode === 'edit' && isAproveMode !== 'true') {
            deleteFuelCard({ id: data?.id }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully deleted',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                setIsVisibleCardsForm(fuelCards?.length === 1);
                setValue('fuelCard', '', { shouldDirty: false, shouldValidate: true });
                setSelectedCard(null);
              }
            });
          } else {
            setFuelCards((prev: any) => prev.filter(({ card }: any) => card !== data?.card));
            dispatch(closeModal());
            setIsVisibleCardsForm(fuelCards?.length === 1);
            setValue('fuelCard', '', { shouldDirty: false, shouldValidate: true });
            setSelectedCard(null);
          }
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
    setSelectedCard(null);
  };

  const handleCloseCardForm = () => {
    setIsVisibleCardsForm(false);
    setValue('fuelCard', '', { shouldDirty: false, shouldValidate: true });
    resetField('isMainFuelCard');
    setSelectedCard(null);
  };

  const handleOpenCardForm = () => {
    setIsVisibleCardsForm(true);
  };

  useEffect(() => {
    if (mode !== 'edit') {
      setValue('fuelCards', fuelCards);
    }
  }, [fuelCards]);

  useEffect(() => {
    if (mode === 'edit') {
      setFuelCards(watch('fuelCards'));
      if (watch('fuelCards').length) {
        setIsVisibleCardsForm(false);
      }
    }
  }, [watch('fuelCards')]);

  const handleRowEdit = (data: any) => {
    if (data.id) {
      setValue('truckId', data.id);
      setEditingRow(data?.id);
    }

    setValue('truckNumber', data.truckNumber);
    setValue('yearAndMake', data.yearAndMake);
    setValue('boxTruck', data.boxTruck);
    setValue('length', data.length || '');

    setIsEditedRow(true);

    setSelectedRow(data.truckId);

    scrollToTarget(truckSectionRef);
    setIsVisibleTrucksForm(true);
  };

  const handleDeleteMcRow = (id: number | string) => {
    if (
      (mode !== 'edit' && activePage !== 'request') ||
      (mode === 'edit' &&
        carrierInfo?.type === ICarrierType['Outside'] &&
        watch('type') === String(ICarrierType['Owner Operator']))
    ) {
      setAddedTrucks((prev: any) => prev.filter((element: any) => element.truckId != id));
    }
    dispatch(closeModal());
    setSelectedRow(null);
    setIsVisibleTrucksForm(addedTrucks?.length === 1);
  };

  const handleRowDelete = (rowId: string | number, row: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          if (
            mode === 'edit' &&
            activePage !== 'request' &&
            !(carrierInfo?.type === ICarrierType['Outside'] && watch('type') === String(ICarrierType['Owner Operator']))
          ) {
            deleteTruckById({ id: row?.id }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully deleted',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                if (truckState?.skip > 0 && trucksByCarrierId?.result?.length === 1) {
                  setTruckState(() => ({
                    skip: 0,
                    limit: 10,
                  }));
                } else {
                  refetchTrucksById();
                }
                dispatch(closeModal());
                setIsVisibleTrucksForm(addedTrucks?.length === 1);
                handleResetTruck('no reset equipment');
              }
            });
          } else {
            handleDeleteMcRow(rowId);
            handleResetTruck('no reset equipment');
          }
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
    setSelectedRow(null);
  };

  useEffect(() => {
    if (mode === 'add') {
      setAddedTrucks([]);
    }
  }, [mode]);

  useEffect(() => {
    if (open === 'false') {
      setAddedTrucks([]);
      setValue('trucks', []);
    }
  }, [open]);

  useEffect(() => {
    if (
      mode === 'add' ||
      activePage === 'request' ||
      activePage === 'carrierOnBoard' ||
      (mode === 'edit' &&
        carrierInfo?.type === ICarrierType['Outside'] &&
        watch('type') === String(ICarrierType['Owner Operator']))
    ) {
      setValue('trucks', addedTrucks);
    }
  }, [mode, addedTrucks?.length]);

  useEffect(() => {
    if (mode === 'edit') {
      setAddedTrucks(watch('trucks'));
    }
  }, [mode, watch('trucks')]);

  useEffect(() => {
    if (!addedTrucks?.length && isAproveMode === 'true') {
      setIsVisibleTrucksForm(true);
    }
  }, [addedTrucks?.length]);

  const handleSwitchMainFullCard = () => {
    const mainFuelCard = fuelCards?.find((el: { card: string; main: boolean }) => !!el?.main);
    if (mainFuelCard && !watch('isMainFuelCard')) {
      dispatch(
        openModal({
          title: `Are you sure you want to make this Fuel Card the main one and remove this setting from this ${mainFuelCard?.card} fuel card?`,
          okText: SUBJECT.YES,
          cancelText: SUBJECT.NO,
          okButtonVariant: 'default',
          onOk: () => {
            setValue('isMainFuelCard', true);
            dispatch(closeModal());
          },
          onCancel: () => {
            setValue('isMainFuelCard', false);
            dispatch(closeModal());
          },
        })
      );
    } else {
      setValue('isMainFuelCard', !watch('isMainFuelCard'));
    }
  };

  useEffect(() => {
    if (!fuelCards?.length && !watch('fuelCards')?.length && !isCreateCardLoading) {
      setIsVisibleCardsForm(true);
    }
  }, [isCreateCardLoading]);

  return {
    addedTrucks,
    isAddTruckBtnDisabled,
    selectedRow,
    handleAddTruck,
    handleRowEdit,
    handleRowDelete,
    isEditedRow,
    isAproveMode,
    editingRow,
    setEditingRow,
    handleResetTruck,
    isFuelCardValid,
    handleAddFuelCard,
    fuelCards,
    handleFuelCardEdit,
    handleDeleteFuelCard,
    selectedCard,
    isVisibleTrucksForm,
    handleCloseTruckForm,
    handleOpenTruckForm,
    isVisibleCardsForm,
    handleCloseCardForm,
    handleOpenCardForm,
    handleSwitchMainFullCard,
    isCreateCardLoading,
    setIsVisibleTrucksForm,
  };
};
