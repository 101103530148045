export enum SUBJECT {
  CUSTOMER_INFO = 'Customer Info:',
  CUSTOMER_INFO_HEADING = 'Customer Info',
  NAME = 'Name',
  PHONE_NUMBER = 'Phone Number',
  FAX = 'Fax',
  TYPE = 'Type',
  FF_NUMBER = 'FF Number',
  CUSTOMER_ID = 'Customer ID',
  PAYMENT = 'Payment',
  PERCENT = 'Percent',
  EMAIL = 'Email',
  DAY = 'Day:',
  COMMENT_ID = 'comment',
  DOT = 'Dot',
  STATE_NUMBER = 'State Number',
  MC_NUMBER = 'MC Number',
}
