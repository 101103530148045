import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import clsx from 'clsx';
import { useSetProjectColorModeMutation } from 'services/auth/auth';
import { ModeTypes } from 'services/auth/interface';
import { changeLoading } from 'store/modal-slice/modals';
import { selectModals } from 'store/modal-slice/selector';
import { selectWidth } from 'store/sidebarSlice/selector';
import { selectUserInfo } from 'store/user-slice/selector';
import Typography from 'ui/typography/Typography';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import MoonIcon from 'components/svgs/MoonIcon';
import SunIcon from 'components/svgs/SunIcon';

import { SUBJECT } from './constants/constants';
import { getDefaultTheme } from './constants/helpers';

import styles from './ThemeSwitcher.module.scss';

const ThemeSwitcher = () => {
  const { LIGHT, DARK, NAME, TEXT_FOR_LIGHT, TEXT_FOR_DARK } = SUBJECT;
  const [theme, setTheme] = useState<string>(LIGHT);

  const [setProjectColorMode] = useSetProjectColorModeMutation();

  const { isLoading = false } = useSelector(selectModals);

  const dispatch = useDispatch();
  const handleThemeChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeLoading(true));
    setProjectColorMode(checked ? ModeTypes.dark : ModeTypes.light).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        dispatch(changeLoading(false));
        setTheme(checked ? DARK : LIGHT);
      }
    });
  };

  const { modeType } = useSelector(selectUserInfo);

  useEffect(() => {
    const defaultTheme = localStorage.getItem('default-theme') || getDefaultTheme();

    if (!modeType) {
      document.documentElement.classList.remove(DARK, LIGHT);
      document.documentElement.classList.add(defaultTheme);
      return;
    }
    if (document.documentElement.classList.contains(DARK) && ModeTypes[modeType] === LIGHT) {
      localStorage.setItem('default-theme', LIGHT);
      document.documentElement.classList.replace(DARK, LIGHT);
      setTheme(LIGHT);
    } else if (document.documentElement.classList.contains(LIGHT) && ModeTypes[modeType] === DARK) {
      localStorage.setItem('default-theme', DARK);
      document.documentElement.classList.replace(LIGHT, DARK);
      setTheme(DARK);
    } else {
      localStorage.setItem('default-theme', ModeTypes[modeType]);
      document.documentElement.classList.remove(DARK, LIGHT);
      document.documentElement.classList.add(ModeTypes[modeType]);
      setTheme(ModeTypes[modeType]);
    }
  }, [modeType]);

  const sidebarState = useSelector(selectWidth);

  return (
    <div className={clsx(styles.switchMode, { [styles.switcherSize]: sidebarState })}>
      <label htmlFor={theme}>
        <input
          type="checkbox"
          id={theme}
          value={theme}
          name={NAME}
          checked={theme === DARK}
          onChange={handleThemeChange}
        />
        <div className={styles.themeItems}>
          <div className={styles.themeItem}>
            <span className={clsx(styles.icon, { [styles.rotate]: isLoading && theme === DARK })}>
              <SunIcon />
            </span>
            {!sidebarState && <Typography variant="paragraph">{TEXT_FOR_LIGHT}</Typography>}
          </div>
          <div className={styles.themeItem}>
            <span className={clsx(styles.icon, { [styles.rotate]: isLoading && theme === LIGHT })}>
              <MoonIcon />
            </span>
            {!sidebarState && <Typography variant="paragraph">{TEXT_FOR_DARK}</Typography>}
          </div>
        </div>
      </label>
    </div>
  );
};

export default ThemeSwitcher;
