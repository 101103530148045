import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useCreateTruckBoardSearchMutation,
  useEditTruckBoardResultListByIdMutation,
} from 'services/truck-board/truck-board/truckBoard';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { usePermittedActions } from '../../../../hooks/usePermittedActions';
import { changeTimeZone } from '../../../../utils/helpers';
import { ICardData } from '../../constants/types';
import { SUBJECT } from '../carrier-info/constants/constants';

import { getDefaultValues, getDefaultValuesForEdit } from './constants/constants';

const useTruckBoardSearch = ({
  searchItems,
  singleSearchItem,
  isSearchItemsFetching,
  isSingleSearchItemFetching,
}: any) => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { isEdit, availableStartDate, availableEndDate, availableOption, editId } = searchParams;
  const [isLoadingVisible, setIsLoadingVisible] = useState(true);
  const { handleSubmit, setValue, watch, reset } = useForm<ICardData>({
    defaultValues: getDefaultValues(searchItems) || {},
  });

  useEffect(() => {
    if (isEdit === 'true') {
      reset(getDefaultValuesForEdit(searchItems, singleSearchItem), { keepDirty: false, keepTouched: false });
      setSearchParams({
        ...searchParams,
        availableOption: '5',
        ...(singleSearchItem?.availableAtFrom && {
          availableStartDate: changeTimeZone(
            new Date(Number(singleSearchItem?.availableAtFrom)),
            'America/Los_Angeles',
            true
          ),
        }),
        ...(singleSearchItem?.availableAtTo && {
          availableEndDate: changeTimeZone(
            new Date(Number(singleSearchItem?.availableAtTo)),
            'America/Los_Angeles',
            true
          ),
        }),
      });
    }
  }, [
    isEdit,
    singleSearchItem?.fromStateIds?.length,
    singleSearchItem?.availableAtFrom,
    singleSearchItem?.availableAtTo,
    searchItems,
  ]);

  const [createTruckBoardSearchItem, { isLoading: isCreateLoading }] = useCreateTruckBoardSearchMutation();
  const [editTruckBoardSearchItem, { isLoading: isEditLoading }] = useEditTruckBoardResultListByIdMutation();

  const hasCreateSearch = usePermittedActions('truckBoard.truckBoard.createSearch');
  const hasTruckBoardView = usePermittedActions('truckBoard.truckBoard.view');

  const isDisabledButtonDependOnPerm = useMemo(() => {
    return !(hasCreateSearch && hasTruckBoardView);
  }, [hasCreateSearch, hasTruckBoardView]);

  const isDisabledButton = useMemo(() => {
    const values = Object.values(watch());
    return !(
      values?.includes(Number(SUBJECT.BOTH)) ||
      (values?.includes(Number(SUBJECT.FROM)) && values?.includes(Number(SUBJECT.TO)))
    );
  }, [watch()]);

  const handleCreateSearch = () => {
    createTruckBoardSearchItem({
      body: { form: watch(), availableAtTo: availableEndDate, availableAtFrom: availableStartDate, availableOption },
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 1,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_CREATED,
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        setSearchParams({
          ...searchParams,
          currentPage: 'truck',
          availableStartDate: '',
          availableEndDate: '',
          availableAllDate: '',
          availableOption: '',
        });
      }
    });
  };
  const handleEditSearch = () => {
    editTruckBoardSearchItem({
      body: {
        form: watch(),
        id: editId,
        availableAtTo: availableEndDate,
        availableAtFrom: availableStartDate,
        availableOption,
      },
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 1,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_SAVED,
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });

        setSearchParams({
          ...searchParams,
          currentPage: 'truck',
          availableStartDate: '',
          availableEndDate: '',
          availableAllDate: '',
          availableOption: '',
        });
      }
    });
  };

  const handleCreateCancel = () => {
    setSearchParams({
      ...searchParams,
      currentPage: 'truck',
      availableStartDate: '',
      availableEndDate: '',
      availableAllDate: '',
      availableOption: '',
      isEdit: 'false',
      editId: '',
    });
  };

  const resetAllSearches = () => {
    const formKeys = Object.keys(watch());
    formKeys?.forEach((el: string) => {
      setValue(el, Number(SUBJECT.EMPTY));
    });
  };

  useEffect(() => {
    if (!isSearchItemsFetching && !isSingleSearchItemFetching) {
      setTimeout(() => {
        setIsLoadingVisible(false);
      }, 2100);
    }
  }, [isSearchItemsFetching, isSingleSearchItemFetching]);

  const isFilledAnyBlock = useMemo(() => {
    return !!Object.values(watch())?.filter((el: any) => el == SUBJECT.FROM || el == SUBJECT.TO || el == SUBJECT.BOTH)
      ?.length;
  }, [watch()]);

  useEffect(() => {
    if (isEdit === 'true') {
      setSearchParams({
        ...searchParams,
        ...(singleSearchItem?.availableAtFrom && { availableStartDate: singleSearchItem?.availableAtFrom }),
        ...(singleSearchItem?.availableAtTo && { availableEndDate: singleSearchItem?.availableAtTo }),
      });
    }
  }, [singleSearchItem, isEdit]);

  return {
    handleSubmit,
    setValue,
    watch,
    handleCreateSearch,
    handleEditSearch,
    handleCreateCancel,
    isEdit: isEdit === 'true',
    isDisabledButton,
    isDisabledButtonDependOnPerm,
    isEditLoading,
    isCreateLoading,
    resetAllSearches,
    isLoadingVisible,
    isFilledAnyBlock,
  };
};
export default useTruckBoardSearch;
