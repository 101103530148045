import { createApi } from '@reduxjs/toolkit/query/react';
import { TruckBoardStatuses } from 'pages/truck-board/components/view-edit-carrier-truck/components/truck-info/constants/constants';
import { baseQuery } from 'services/service';

import { createSearchSerializer, updateLocationDeserializer } from './deserializers';
import { ICoordinatorsParams } from './interfaces';
import {
  getCoordiniatorsListSerializer,
  getTruckBoardResultListByIdSerializer,
  getTruckBoardResultListByIdTrucksSerializer,
  getTruckBoardResultListSerializer,
  getTruckBoardResultParamsSerializer,
  getTruckInfoByIdSerializer,
} from './serializers';

export const truckBoardApi = createApi({
  reducerPath: 'truckBoardApi',
  baseQuery,
  tagTypes: ['TruckBoard', 'ResultList'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getTruckBoardResultList: builder.query({
      query: (filter: Partial<any>) => {
        const queryParams = getTruckBoardResultParamsSerializer(filter);
        return {
          url: 'truck-board/filter',
          params: queryParams,
        };
      },
      transformResponse: (body: any) => getTruckBoardResultListSerializer(body),
      providesTags: ['ResultList'],
    }),
    deleteTruckBoardResultListItem: builder.mutation({
      query: ({ id }) => ({
        url: `truck-board/filter/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ResultList'],
    }),
    getTruckBoardResultListById: builder.query({
      query: ({ id, status }) => ({
        url: `truck-board/filter/${id}`,
        params: { truckBoardStatus: status },
      }),
      transformResponse: (body: any) => getTruckBoardResultListByIdSerializer(body),
      providesTags: ['TruckBoard'],
    }),
    editTruckBoardResultListById: builder.mutation({
      query: ({ body }) => ({
        url: `truck-board/filter/${body?.id}`,
        method: 'PUT',
        body: createSearchSerializer(body),
      }),
      invalidatesTags: ['TruckBoard', 'ResultList'],
    }),
    getTruckBoardSearchItems: builder.query({
      query: () => ({
        url: 'truck-board/board-counts-with-states',
      }),
    }),
    createTruckBoardSearch: builder.mutation({
      query: ({ body }) => ({
        url: `truck-board/filter`,
        method: 'POST',
        body: createSearchSerializer(body),
      }),
      invalidatesTags: ['TruckBoard', 'ResultList'],
    }),

    addCoordinator: builder.mutation({
      query: ({ id, coordinatorId }) => ({
        url: `truck-board/truck/${id.split('-')[0]}/coordinator`,
        method: 'POST',
        body: { userId: coordinatorId },
      }),
      invalidatesTags: ['TruckBoard'],
    }),
    deleteCoordinator: builder.mutation({
      query: ({ id }) => ({
        url: `truck-board/truck/${id}/coordinator`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TruckBoard'],
    }),
    getCoordinators: builder.query({
      query: ({ search, skip, limit, filter }: Partial<ICoordinatorsParams>) => ({
        url: 'employees/autocomplete',
        params: { search, skip, limit, onlyCoordinator: true, ...filter },
      }),
      transformResponse: (data: any) => getCoordiniatorsListSerializer(data),
      providesTags: ['TruckBoard'],
    }),
    getTruckInfoById: builder.query({
      query: ({ truckId, loadId, truckBoardStatus }) => ({
        url: `truck-board/truck/${truckId}/location`,
        params: {
          ...(truckBoardStatus === TruckBoardStatuses.TRANSIT ? { loadId } : {}),
          ...(truckBoardStatus !== null && { truckBoardStatus: truckBoardStatus || TruckBoardStatuses.OFF_BOARD }),
        },
      }),
      transformResponse: (data: any) => getTruckInfoByIdSerializer(data),
      providesTags: ['TruckBoard'],
    }),
    updateLocation: builder.mutation({
      query: ({ id, data }) => ({
        url: `truck-board/truck/${id.split('-')[0]}/location`,
        method: 'PUT',
        body: updateLocationDeserializer(data),
      }),
      invalidatesTags: ['TruckBoard'],
    }),
    changeTruckStatus: builder.mutation({
      query: ({ status, id }) => ({
        url: `truck-board/truck/${id.split('-')[0]}/change-status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['TruckBoard', 'ResultList'],
    }),
    getTruckBoardResultListByIdTrucks: builder.query({
      query: (params: any) => {
        const queryParams = getTruckBoardResultParamsSerializer(params);
        return {
          url: `truck-board/filter/${params?.id}/trucks`,
          params: queryParams,
        };
      },
      transformResponse: (data: any) => getTruckBoardResultListByIdTrucksSerializer(data),
      providesTags: ['TruckBoard'],
    }),
    markTruckAsRead: builder.mutation({
      query: ({ id, filterId, status }) => ({
        url: `truck-board/filter/${filterId}/truck/${id.split('-')[0]}/read`,
        body: { truckBoardStatus: Number(status) },
        method: 'PUT',
      }),
      invalidatesTags: ['TruckBoard', 'ResultList'],
    }),
  }),
});

export const {
  useGetTruckBoardResultListQuery,
  useDeleteTruckBoardResultListItemMutation,
  useGetTruckBoardResultListByIdQuery,
  useLazyGetTruckBoardResultListByIdQuery,
  useLazyGetTruckBoardResultListByIdTrucksQuery,
  useGetTruckBoardResultListByIdTrucksQuery,
  useEditTruckBoardResultListByIdMutation,
  useGetTruckBoardSearchItemsQuery,
  useCreateTruckBoardSearchMutation,
  useAddCoordinatorMutation,
  useDeleteCoordinatorMutation,
  useGetCoordinatorsQuery,
  useGetTruckInfoByIdQuery,
  useUpdateLocationMutation,
  useChangeTruckStatusMutation,
  useMarkTruckAsReadMutation,
} = truckBoardApi;
