import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { selectWidth } from 'store/sidebarSlice/selector';
import { sidebarStatus } from 'store/sidebarSlice/sidebar';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import {
  carrierSubTabs,
  contactsTabs,
  creditCheckTabs,
  factoringTabs,
  loadsTabs,
  payrollTabs,
  profileTabs,
  truckBoardTabs,
} from 'utils/constants';

import {
  CarrierSubTabs,
  ContactsTabs,
  CreditCheckTabs,
  FactoringTabs,
  LoadTabs,
  PaymentSubTabs,
  PayrollTabs,
  ProfileTabs,
  ROUTES,
  TruckBoardTabs,
} from 'components/routes/routes';
import ContactIcon from 'components/svgs/ContactIcon';
import CreditIcon from 'components/svgs/CreditIcon';
import FactoringIcon from 'components/svgs/FactoringIcon';
import LoadIcon from 'components/svgs/LoadIcon';
import PermissionIcon from 'components/svgs/PermissionIcon';
import ProfileIcon from 'components/svgs/ProfileIcon';

import PayrollIcon from '../../../svgs/PayrollIcon';
import TruckIcon from '../../../svgs/TruckIcon';

export const useSidebar = () => {
  const dispatch = useDispatch();

  const sidebarState = useSelector(selectWidth);
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);

  //profile
  const uniqueVisibleProfileTabs: string[] = Array.from(new Set(permissionsInfo?.permissions?.profile?.visibleTabs));
  const firstAvailableProfileTab = ProfileTabs.find(el => uniqueVisibleProfileTabs.includes(el));

  //loads
  const uniqueVisibleLoadTabs: string[] = Array.from(new Set(permissionsInfo?.permissions?.loads?.visibleTabs));
  const firstAvailableLoadTab = LoadTabs.find(el => uniqueVisibleLoadTabs.includes(el));

  //payroll
  const uniqueVisiblePayrollTabs: string[] = Array.from(new Set(permissionsInfo?.permissions?.payroll?.visibleTabs));
  const firstAvailablePayrollTab = PayrollTabs.find(el => uniqueVisiblePayrollTabs.includes(el));

  //payroll/payment
  const uniqueVisiblePaymentSubTabs: string[] = Array.from(
    new Set(
      permissionsInfo?.permissions?.payroll?.payrollPayout?.visibleSubTabs ||
        permissionsInfo?.permissions?.payroll?.payment?.visibleSubTabs
    )
  );
  const firstAvailablePaymentSubTab = PaymentSubTabs.find(el => uniqueVisiblePaymentSubTabs.includes(el));
  //creditCheck
  const uniqueVisibleCreditCheckTabs: string[] = Array.from(
    new Set(permissionsInfo?.permissions?.['credit-check']?.visibleTabs)
  );
  const firstAvailableCreditCheckTab = CreditCheckTabs.find(el => uniqueVisibleCreditCheckTabs.includes(el));

  //contact
  const uniqueVisibleContactTabs: string[] = Array.from(new Set(permissionsInfo?.permissions?.contact?.visibleTabs));
  const firstAvailableContactTab = ContactsTabs.find(el => uniqueVisibleContactTabs.includes(el));

  //factoring
  const uniqueVisibleFactoringTabs: string[] = Array.from(
    new Set(permissionsInfo?.permissions?.factoring?.visibleTabs)
  );
  const firstAvailableFactoringTab = FactoringTabs.find(el => uniqueVisibleFactoringTabs.includes(el));

  //truckBoard
  const uniqueVisibleTruckBoardTabs: string[] = Array.from(
    new Set(permissionsInfo?.permissions?.['truck-board']?.visibleTabs)
  );
  const firstAvailableTruckBoardTab = TruckBoardTabs.find(el => uniqueVisibleTruckBoardTabs.includes(el));

  //carrierSubTabs
  const uniqueVisibleCarrierSubTabs = Array.from(new Set(permissionsInfo?.allPossibleSubTabs));
  const firstAvailableCarrierSubTab = CarrierSubTabs.find(el => uniqueVisibleCarrierSubTabs.includes(el));
  const { searchParams } = useDetectedParams();
  const { tab, currentPage, headerFilter } = searchParams;

  const location = useLocation();
  const pathName = location.pathname;

  const handleSidebarOpen = () => {
    dispatch(sidebarStatus(!sidebarState));
  };

  useEffect(() => {
    return () => {
      dispatch(sidebarStatus(false));
    };
  }, []);

  const navigate = useNavigate();
  const disableSameRouteClick = (route: string) => {
    return route === pathName;
  };
  const handleNavigateToProfile = () => {
    if (disableSameRouteClick(ROUTES.PROFILE)) return;

    navigate(
      `${ROUTES.PROFILE}?headerFilter=${headerFilter}&tab=${
        pathName === ROUTES.PROFILE ? tab : profileTabs[firstAvailableProfileTab as keyof typeof profileTabs]
      }${
        firstAvailableCarrierSubTab
          ? `&activePage=${carrierSubTabs[firstAvailableCarrierSubTab as keyof typeof carrierSubTabs]}`
          : ''
      }`
    );
  };

  const handleNavigateToContact = () => {
    if (disableSameRouteClick(ROUTES.CONTACT)) return;

    navigate(
      `${ROUTES.CONTACT}?headerFilter=${headerFilter}&tab=${
        pathName === ROUTES.CONTACT ? tab : contactsTabs[firstAvailableContactTab as keyof typeof contactsTabs]
      }`
    );
  };

  const handleNavigateToPermission = () => {
    if (disableSameRouteClick(ROUTES.PERMISSION)) return;

    navigate(
      `${ROUTES.PERMISSION}?headerFilter=${headerFilter}&tab=${pathName === ROUTES.PERMISSION ? tab : 'permission'}`
    );
  };
  const handleNavigateToLoads = () => {
    if (disableSameRouteClick(ROUTES.LOADS)) return;

    navigate(
      `${ROUTES.LOADS}?headerFilter=${headerFilter}&tab=${
        pathName === ROUTES.LOADS ? tab : loadsTabs[firstAvailableLoadTab as keyof typeof loadsTabs]
      }`
    );
  };

  const handleNavigateToCreditCheck = () => {
    if (disableSameRouteClick(ROUTES.CREDIT_CHECK)) return;

    navigate(
      `${ROUTES.CREDIT_CHECK}?headerFilter=${headerFilter}&tab=${
        pathName === ROUTES.CREDIT_CHECK
          ? tab
          : creditCheckTabs[firstAvailableCreditCheckTab as keyof typeof creditCheckTabs]
      }`
    );
  };

  const handleNavigateToFactoring = () => {
    if (disableSameRouteClick(ROUTES.FACTORING)) return;

    navigate(
      `${ROUTES.FACTORING}?headerFilter=${headerFilter}&tab=${
        pathName === ROUTES.FACTORING ? tab : factoringTabs[firstAvailableFactoringTab as keyof typeof factoringTabs]
      }`
    );
  };
  const handleNavigateToPayroll = () => {
    if (disableSameRouteClick(ROUTES.PAYROLL)) return;

    navigate(
      `${ROUTES.PAYROLL}?headerFilter=${headerFilter}&tab=${
        pathName === ROUTES.PAYROLL ? tab : payrollTabs[firstAvailablePayrollTab as keyof typeof payrollTabs]
      }`
    );
  };

  const handleNavigateToTruckBoard = () => {
    if (disableSameRouteClick(ROUTES.TRUCK_BOARD)) return;

    navigate(
      `${ROUTES.TRUCK_BOARD}?headerFilter=${headerFilter}&tab=${
        pathName === ROUTES.TRUCK_BOARD
          ? tab
          : truckBoardTabs[firstAvailableTruckBoardTab as keyof typeof truckBoardTabs]
      }&currentPage=${
        currentPage
          ? currentPage
          : truckBoardTabs[firstAvailableTruckBoardTab as keyof typeof truckBoardTabs] === 'truck_board'
          ? 'truck'
          : ''
      }`
    );
  };
  const routesCase = {
    profile: { navigate: handleNavigateToProfile, icon: ProfileIcon, title: 'Profiles' },
    loads: { navigate: handleNavigateToLoads, icon: LoadIcon, title: 'Loads' },
    payroll: { navigate: handleNavigateToPayroll, icon: PayrollIcon, title: 'Agency Finance' },
    'credit-check': { navigate: handleNavigateToCreditCheck, icon: CreditIcon, title: 'Credit Check' },
    contact: { navigate: handleNavigateToContact, icon: ContactIcon, title: 'Contact' },
    factoring: { navigate: handleNavigateToFactoring, icon: FactoringIcon, title: 'Accounting' },
    'truck-board': { navigate: handleNavigateToTruckBoard, icon: TruckIcon, title: 'Truck Board' },
    permission: { navigate: handleNavigateToPermission, icon: PermissionIcon, title: 'Permission' },
  };

  return {
    handleSidebarOpen,
    handleNavigateToTruckBoard,
    handleNavigateToLoads,
    handleNavigateToCreditCheck,
    handleNavigateToFactoring,
    handleNavigateToPayroll,
    handleNavigateToContact,
    handleNavigateToPermission,
    pathName,
    sidebarState,
    routesCase,
    searchParams,
    activeTab: {
      firstAvailableProfileTab,
      firstAvailableLoadTab,
      firstAvailablePayrollTab,
      firstAvailableCreditCheckTab,
      firstAvailableContactTab,
      firstAvailableFactoringTab,
      firstAvailableTruckBoardTab,
      firstAvailablePaymentSubTab,
    },
  };
};
