import React from 'react';
import { PaginationProps } from 'antd';
import clsx from 'clsx';

import { ArrowSvg } from '../svgs/ArrowSvg';

import { getPaymentTermColumns } from './constants/helpers';
import { IUsePaymentTermDataGridProps } from './constants/types';

import styles from './PaymentTermDataGrid.module.scss';

const usePaymentTermDataGrid = ({
  handleRowDelete,
  handleRowEdit,
  setEditingRow,
  data,
  localFiles,
  serializedStates,
  withoutAction,
}: IUsePaymentTermDataGridProps) => {
  const CURRENT_COLUMNS = getPaymentTermColumns({
    data,
    serializedStates,
    handleRowDelete,
    handleRowEdit,
    setEditingRow,
    localFiles,
    withoutAction,
  });

  const paginationArrows: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowLeft)}>
          <ArrowSvg />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className={clsx(styles.paginationArrow, styles.arrowRight)}>
          <ArrowSvg />
        </a>
      );
    }
    return originalElement;
  };

  const pageSizeOptions = (total: number) => {
    const maxPageSize = 40;
    const pageSizeOptions = Array.from({ length: Math.ceil(total / 10) }, (_, i) => (i + 1) * 10).filter(
      option => option <= maxPageSize
    );
    return pageSizeOptions;
  };

  return { CURRENT_COLUMNS, paginationArrows, pageSizeOptions };
};

export default usePaymentTermDataGrid;
