const SearchIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.7902 24L14.0235 15.2333C13.3568 15.8111 12.5791 16.2611 11.6902 16.5833C10.8013 16.9056 9.85684 17.0667 8.85684 17.0667C6.45684 17.0667 4.4235 16.2333 2.75684 14.5667C1.09017 12.9 0.256836 10.8889 0.256836 8.53333C0.256836 6.17778 1.09017 4.16667 2.75684 2.5C4.4235 0.833333 6.44573 0 8.8235 0C11.1791 0 13.1846 0.833333 14.8402 2.5C16.4957 4.16667 17.3235 6.17778 17.3235 8.53333C17.3235 9.48889 17.1679 10.4111 16.8568 11.3C16.5457 12.1889 16.0791 13.0222 15.4568 13.8L24.2568 22.5333L22.7902 24ZM8.8235 15.0667C10.6235 15.0667 12.1568 14.4278 13.4235 13.15C14.6902 11.8722 15.3235 10.3333 15.3235 8.53333C15.3235 6.73333 14.6902 5.19444 13.4235 3.91667C12.1568 2.63889 10.6235 2 8.8235 2C7.00128 2 5.45128 2.63889 4.1735 3.91667C2.89573 5.19444 2.25684 6.73333 2.25684 8.53333C2.25684 10.3333 2.89573 11.8722 4.1735 13.15C5.45128 14.4278 7.00128 15.0667 8.8235 15.0667Z"
        fill="#98A2B2"
      />
    </svg>
  );
};

export default SearchIcon;
