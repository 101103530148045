export enum SUBJECT {
  DISTRIBUTE = 'Distribute',
  CHARGEBACK = 'Chargeback',
  CORPORATE = 'Corporate',
  BRANCH = 'Branch',
  OFFICE = 'Agency',
  AGENT = 'Agent',
  RESET_ALL = 'Reset All',
  CANCEL = 'Cancel',
  NEGATIVE = 'Negative',
  CHARGES = 'Charges',
  CREDIT = 'Credit',
  COLLECTION = 'Collection',
  AMOUNT = 'Amount',
  PAYMENT_PLAN = 'Payment Plan',
  FOR_BRANCH = 'For Branch',
  FOR_AGENT = 'For Agent',
  ADD_NEW_CHARGE = 'Add New Charge',
  ADD_NEW_ADVANCE = 'Add New Advance',
  NAME = 'Name',
  REASON = 'Reason',
  FULL_INFO = 'Full Info',
  EDIT = 'Edit',
  SOME = 'Some',
  TYPE = 'Type',
  ERROR_MESSAGE = 'It Can’t Be More Than ',
  NEGATIVE_ERROR = 'It Can’t Be Less Than ',
  DISTRIBUTE_ERROR = 'You Must Distribute All Your Available Percents',
  EXPIRATION_ERROR = 'You Cannot Distribute Because Your Load Was In Past Month',
  DATE = 'Date',
  NO_PAYMENT_PLAN = 'No Payment Plan',
  STATUS = 'Status',
  DONT_HAVE_PERMISSION = "You Don't Have A Permission",
}

export const distributeColumnsNames = {
  corporate: 'Corporate',
  agency: 'Agency:',
  branch: 'Branch:',
  agent: 'Agent:',
};

export enum VALIDATION_MESSAGES {
  ONLY_NOT_ZERO = 'Amount must be either positive or negative number',
  ONLY_NEGATIVE = 'Amount must be negative number',
  AGENCY_REQUIRED = 'Agency is a required field',
  BRANCH_REQUIRED = 'Branch is a required field',
  AGENT_REQUIRED = 'Agent is a required field',
  TYPE_REQUIRED = 'Type is a required field',
  AMOUNT_REQUIRED = 'Amount is a required field',
}

export const PAYMENT_PLAN_OPTIONS = [
  { value: '1', title: '1 month' },
  { value: '2', title: '2 month' },
  { value: '3', title: '3 month' },
  { value: '4', title: '4 month' },
  { value: '5', title: '5 month' },
  { value: '6', title: '6 month' },
];

export const SEARCH_OFFICE_COLUMNS = [{ key: 'value', name: '' }];
export const OFFICE_KEYS = ['title'];
