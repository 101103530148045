import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import { LoadTypes, LoadViewModes } from 'pages/loads/components/create-load/constants/types';
import useLoads from 'pages/loads/useLoads';
import {
  useChangeStatusMutation,
  useCreateAdvanceMutation,
  useCreateChargeMutation,
  useCreateDistributeMutation,
  useDeleteAdvanceMutation,
  useDeleteChargeMutation,
  useEditAdvanceMutation,
  useEditChargeMutation,
  useEditPaymentPlanMutation,
  useGetSummariesQuery,
  useLazyGetAdvancesQuery,
  useLazyGetChargesQuery,
  useLazyGetCollectionQuery,
  useLazyGetCreditsQuery,
  useLazyGetInformationFactoredQuery,
  useLazyGetInformationNotFactoredQuery,
  useLazyGetOtherServiceFeeQuery,
} from 'services/payroll/information/information';
import { IPayrollCreditChargesItem } from 'services/payroll/information/types';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { IRoleLevel } from 'services/truck-board/carrier-request/interfaces';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { notificationHandler } from 'utils/handlers';

import CustomColumn from 'components/custom-column/CustomColumn';

import { AgentTypes } from '../../../../utils/constants';

import { Advance } from './components/advance/Advance';
import { useAdvance } from './components/advance/useAdvance';
import { AdvanceChargeView } from './components/advance-charge-view/AdvanceChargeView';
import { Charge } from './components/charge/Charge';
import { useCharge } from './components/charge/useCharge';
import { Collection } from './components/collection/Collection';
import { CollectionView } from './components/collection/collection-view/CollectionView';
import { useCollection } from './components/collection/useCollection';
import { Distribute } from './components/distribute/Distribute';
import { useDistribute } from './components/distribute/useDistribute';
import {
  CardNames,
  CARDS_HAS_MINE,
  FACTORED_CARDS,
  NOT_FACTORED_CARDS,
  QueryRenders,
  SUBJECT,
  tabCards,
} from './constants/constants';
import { getInformationOrderBy, payrollCards } from './constants/helper';
import {
  AdvancedCards,
  CollectionCards,
  DistributeCards,
  FactoredCards,
  IChildren,
  ICollectionValues,
  IDistributeValues,
  IInformationTableColumns,
  NotFactoredCards,
  OtherServiceFeeCards,
} from './constants/types';
import { useInformationFilter } from './useInformationFilter';

const useInformation = (setExcelParams: any) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectUserInfo);
  const { agentType, id: userId } = userInfo;
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const allAllowedCards = permissionsInfo?.permissions?.payroll?.informationCards || [];

  const payrollPermissions = usePermittedActions('payroll');
  const { turnOffCharge, turnOnCharge } = payrollPermissions.payrollOtherServiceFee;

  const { roleLevel } = permissionsInfo;

  const { searchParams, setSearchParams } = useDetectedParams();
  const {
    card,
    mode,
    officePayroll,
    branchPayroll,
    agentPayroll,
    startDate,
    endDate,
    id,
    renderMode,
    mine,
    allMonths,
  } = searchParams;

  const canEditChargeOrAdvance = useMemo(() => {
    if (card === AdvancedCards.ADVANCE) return payrollPermissions.payrollAdvance?.edit;
    if (card === OtherServiceFeeCards.OTHER_SERVICE_FEE) return payrollPermissions.payrollOtherServiceFee?.edit;
  }, [card, payrollPermissions.payrollOtherServiceFee?.delete, payrollPermissions.payrollAdvance?.delete]);

  const canDeleteChargeOrAdvance = useMemo(() => {
    if (card === AdvancedCards.ADVANCE) return payrollPermissions.payrollAdvance?.delete;
    if (card === OtherServiceFeeCards.OTHER_SERVICE_FEE) return payrollPermissions.payrollOtherServiceFee?.delete;
  }, [card, payrollPermissions.payrollOtherServiceFee?.delete, payrollPermissions.payrollAdvance?.delete]);

  const {
    isChangedDate,
    filterControl,
    isFilterDirty,
    selectedOffices,
    selectedBranches,
    finalAgentOptions,
    finalOfficeOptions,
    selectedAgentOption,
    finalBranchesOptions,
    filterWatch,
    resetFilter,
    setFilterValue,
    setAgentsFilter,
    setBranchFilter,
    setOfficesFilter,
    dirtyFields,
    setSelectedAgentOption,
  } = useInformationFilter();

  const [informationFilters, setInformationFilters] = useState<IGetAuthoritiesParams>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: getInformationOrderBy(card),
  });

  useEffect(() => {
    if (setExcelParams) setExcelParams(informationFilters);
  }, [informationFilters]);

  const { data: summariesData, isFetching: isSummariesFetching } = useGetSummariesQuery(
    {
      ...(endDate ? { dateEnd: endDate } : {}),
      dateStart: startDate,
      branchIds: branchPayroll?.length ? branchPayroll?.split(',')?.map(el => Number(el)) : [],
      officeIds: officePayroll?.length ? officePayroll?.split(',')?.map(el => Number(el)) : [],
      agentIds: agentPayroll?.length ? [Number(agentPayroll)] : [],
      mine,
    },
    { skip: !startDate && !allMonths }
  );

  const handleMineFilter = () => {
    if (Object.keys(dirtyFields).length) {
      if (mine === 'false') {
        dispatch(
          openModal({
            title: SUBJECT.ARE_YOU_SURE_FILTER,
            okText: SUBJECT.YES,
            cancelText: SUBJECT.NO,
            cancelButtonVariant: 'gray',
            onOk: () => {
              resetFilter(true);
              setSearchParams({ ...searchParams, mine: 'true' });
              dispatch(closeModal());
            },
            onCancel: () => {
              dispatch(closeModal());
            },
          })
        );
      } else {
        setSearchParams({ ...searchParams, mine: 'false' });
      }
    } else {
      setSearchParams({ ...searchParams, mine: mine === 'true' ? 'false' : 'true' });
    }
  };

  useEffect(() => {
    setInformationFilters(prev => ({ ...prev, skip: 0, limit: 20 }));
  }, [startDate, endDate, branchPayroll?.length, officePayroll?.length, agentPayroll?.length]);

  useEffect(() => {
    if (officePayroll?.length || branchPayroll?.length || agentPayroll?.length) {
      setTimeout(() => {
        setSearchParams({ ...searchParams, mine: 'false' });
      }, 100);
    }
  }, [officePayroll?.length, branchPayroll?.length, agentPayroll?.length]);

  const [getInformationFactored, { currentData: dataFactored, isFetching: isFetchingFactored }] =
    useLazyGetInformationFactoredQuery();
  const [getInformationNotFactored, { currentData: notFactored, isFetching: isFetchingNotFactored }] =
    useLazyGetInformationNotFactoredQuery();
  const [getCredits, { currentData: creditsData, isFetching: isFetchingCredits }] = useLazyGetCreditsQuery();
  const [getCharges, { currentData: chargesData, isFetching: isFetchingCharges }] = useLazyGetChargesQuery();
  const [getAdvances, { currentData: advancesData, isFetching: isFetchingAdvance }] = useLazyGetAdvancesQuery();
  const [getCollection, { currentData: collectionData, isFetching: isFetchingCollection }] =
    useLazyGetCollectionQuery();
  const [getOtherServiceFee, { currentData: otherServiceFeeData, isFetching: isFetchingOtherServiceFee }] =
    useLazyGetOtherServiceFeeQuery();

  const data = useMemo(() => {
    if (card) {
      if (FACTORED_CARDS.includes(card as FactoredCards)) return dataFactored;
      if (NOT_FACTORED_CARDS.includes(card as NotFactoredCards)) return notFactored;
      if (DistributeCards.CHARGES === card) return chargesData;
      if (DistributeCards.CREDITS === card) return creditsData;
      if (AdvancedCards.ADVANCE === card) return advancesData;
      if (CollectionCards.COLLECTION === card) return collectionData;
      if (OtherServiceFeeCards.OTHER_SERVICE_FEE === card) return otherServiceFeeData;
    }
  }, [
    isFetchingFactored,
    isFetchingNotFactored,
    isFetchingCredits,
    isFetchingCharges,
    isFetchingAdvance,
    isFetchingCollection,
    isFetchingOtherServiceFee,
    card,
  ]);

  const cards = payrollCards(summariesData!, data?.cardsData, Number(card));

  const isFetching = useMemo(() => {
    return (
      isFetchingFactored ||
      isFetchingNotFactored ||
      isFetchingCredits ||
      isFetchingCharges ||
      isFetchingAdvance ||
      isFetchingCollection ||
      isFetchingOtherServiceFee
    );
  }, [
    isFetchingFactored,
    isFetchingNotFactored,
    isFetchingCredits,
    isFetchingCharges,
    isFetchingAdvance,
    isFetchingCollection,
    isFetchingOtherServiceFee,
  ]);

  const canEditAdvanceOrCharge = useMemo(() => {
    if (mine === 'true') {
      return false;
    } else if (roleLevel === IRoleLevel.corpLevel && selectedBranches.length) {
      return false;
    } else if (roleLevel === IRoleLevel.officeLevel && selectedAgentOption.length) {
      return false;
    } else return !(roleLevel === IRoleLevel.branchLevel && selectedAgentOption.length);
  }, [roleLevel, mine, agentType, userId, selectedBranches, selectedOffices, selectedAgentOption]);

  const { data: columnsData } = useGetColumnsQuery({ type: tabCards[card] }, { skip: !card });
  const [dragColumns, setDragColumns] = useState<any>([]);

  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const handleCloseModal = () => {
    setSearchParams({ ...searchParams, open: 'false', renderMode: '' });
    dispatch(closeModal());
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: tabCards[card],
    });

  const allowedCards = cards?.filter(card => allAllowedCards.includes(CardNames[card?.type as keyof typeof CardNames]));

  const handleSelectCard = (id: string) => {
    if (id !== card) {
      setSearchParams({ ...searchParams, card: id, mine: 'false' });
      setInformationFilters(prev => ({
        ...prev,
        orderBy: getInformationOrderBy(id),
        search: '',
        field: '',
        skip: 0,
        limit: 20,
        order: 2,
      }));
    }
  };

  const handleCancel = () => {
    setSearchParams({
      ...searchParams,
      id: '',
      open: 'false',
      renderMode: '',
    });
  };

  const hasViewMode: string[] = [
    AdvancedCards.ADVANCE,
    CollectionCards.COLLECTION,
    OtherServiceFeeCards.OTHER_SERVICE_FEE,
  ];

  const loadsPermissions = usePermittedActions('loads.loads');

  const handleRowClick = (rowId: string, { loadType, loadId }: any) => {
    if (hasViewMode.includes(card)) {
      setSearchParams({
        ...searchParams,
        id: loadId || rowId,
        open: 'true',
        mode: 'view',
        renderMode: '',
      });
    } else if (loadsPermissions?.view) {
      const type = LoadTypes[loadType];
      setSearchParams({
        ...searchParams,
        mode: LoadViewModes?.[type as keyof typeof LoadViewModes],
        open: 'true',
        id: loadId || rowId,
        renderMode: 'loads',
      });
    } else return;
  };

  const handleRowEdit = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: card === AdvancedCards.ADVANCE ? 'editAdvance' : 'editCharge',
      id: rowId,
    });
  };

  const handleRowDelete = (rowId: string) => {
    if (card === AdvancedCards.ADVANCE) {
      dispatch(
        openModal({
          title: SUBJECT.ARE_YOU_SURE,
          okText: SUBJECT.DELETE,
          cancelText: SUBJECT.CANCEL,
          okButtonVariant: 'danger',
          onOk: () => {
            dispatch(changeLoading(true));
            deleteAdvance({ id: rowId }).then((data: any) => {
              if (data.error) {
                notificationHandler('error', data);
                dispatch(changeLoading(false));
              } else {
                notificationHandler('success', data, 'Successfully deleted');
                dispatch(changeLoading(false));
              }
              dispatch(closeModal());
            });
          },
          onCancel: () => {
            dispatch(closeModal());
          },
        })
      );
    } else {
      dispatch(
        openModal({
          title: SUBJECT.ARE_YOU_SURE,
          okText: SUBJECT.DELETE,
          cancelText: SUBJECT.CANCEL,
          okButtonVariant: 'danger',
          onOk: () => {
            dispatch(changeLoading(true));
            deleteCharge({ id: rowId }).then((data: any) => {
              if (data.error) {
                notificationHandler('error', data);
                dispatch(changeLoading(false));
              } else {
                notificationHandler('success', data, 'Successfully deleted');
                dispatch(changeLoading(false));
              }
              dispatch(closeModal());
            });
          },
          onCancel: () => {
            dispatch(closeModal());
          },
        })
      );
    }
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: QueryRenders[card as keyof typeof QueryRenders],
    });
  };

  const handleStopResize = (columns: IInformationTableColumns[]): void => {
    editColumns({ type: tabCards[card], columns });
  };

  const handlePaginationClick = useCallback((skip: number, limit: number) => {
    setInformationFilters(prev => ({ ...prev, limit, skip }));
  }, []);

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    // if (
    //   informationFilters.search &&
    //   excludeSortWithSearch.includes(tabCards[card]) &&
    //   dataIndex !== 'addedLoadAt' &&
    //   dataIndex !== 'acceptBillingTimeAt'
    // ) {
    //   return 'unSort';
    // }
    setInformationFilters(prev => {
      if (sortOrder === 0) {
        return {
          ...prev,
          order: 2,
          orderBy: getInformationOrderBy(card),
        };
      }
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setInformationFilters(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  // -------------------------
  const [createCharge, { isLoading: isChargeLoading }] = useCreateChargeMutation();
  const [editCharge, { isLoading: isChargeEditLoading }] = useEditChargeMutation();
  const [deleteCharge] = useDeleteChargeMutation();
  const [changeStatus] = useChangeStatusMutation();
  const createChargeForm = useCharge();

  const onChargeSubmit = (body: ICollectionValues) => {
    if (!isChargeLoading && !createChargeForm?.otherServiceFeeInfo) {
      createCharge(body).then((data: any) => {
        if (data.error) {
          notificationHandler('error', data);
        } else {
          notificationHandler('success', data, 'Successfully charged');
          createChargeForm.reset();
          createChargeForm.clearErrors();
          setSearchParams({
            ...searchParams,
            open: 'false',
            id: '',
          });
        }
      });
    }
    if (createChargeForm?.otherServiceFeeInfo && !isChargeEditLoading) {
      editCharge({ body, id }).then((data: any) => {
        if (data.error) {
          notificationHandler('error', data);
        } else {
          notificationHandler('success', data, 'Successfully updated');
          createChargeForm.reset();
          createChargeForm.clearErrors();
          setSearchParams({
            ...searchParams,
            open: 'false',
            id: '',
          });
        }
      });
    }
  };
  const handleChargeCancel = () => {
    if (Object.keys(createChargeForm?.dirtyFields)?.length) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: createChargeForm.isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: createChargeForm.isValid
            ? () => onChargeSubmit(createChargeForm.watch())
            : () => {
                dispatch(closeModal());
              },
          onCancel: () => {
            setSearchParams({ ...searchParams, open: 'false', id: '' });
            dispatch(closeModal());
            createChargeForm.reset();
            createChargeForm.clearErrors();
          },
        })
      );
    } else {
      setSearchParams({ ...searchParams, open: 'false', id: '' });
      createChargeForm.clearErrors();
    }
  };
  const handleSwitchClick = (checked: boolean, rowId?: number | string) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({
            id: rowId ? String(rowId) : id,
            status: checked ? 1 : 2,
          }).then((data: any) => {
            if (data.error) {
              notificationHandler('error', data);
            } else {
              notificationHandler(
                'success',
                data,
                checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED
              );
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  // -------------------------
  const [createAdvance, { isLoading: isAdvanceLoading }] = useCreateAdvanceMutation();
  const [editAdvance, { isLoading: isAdvanceEditLoading }] = useEditAdvanceMutation();
  const [deleteAdvance] = useDeleteAdvanceMutation();
  const createAdvanceForm = useAdvance();

  const onAdvanceSubmit = (body: ICollectionValues) => {
    if (!isAdvanceLoading && !createAdvanceForm?.advanceInfo) {
      createAdvance({ body }).then((data: any) => {
        if (data.error) {
          notificationHandler('error', data);
        } else {
          notificationHandler('success', data, 'Successfully created');
          createAdvanceForm.resetAdvanceForm();
          createAdvanceForm.clearErrors();
          setSearchParams({
            ...searchParams,
            open: 'false',
            id: '',
          });
        }
      });
    }
    if (createAdvanceForm?.advanceInfo && !isAdvanceEditLoading) {
      editAdvance({ body, id }).then((data: any) => {
        if (data.error) {
          notificationHandler('error', data);
        } else {
          notificationHandler('success', data, 'Successfully updated');
          createAdvanceForm.resetAdvanceForm();
          createAdvanceForm.clearErrors();
          setSearchParams({
            ...searchParams,
            open: 'false',
            id: '',
          });
        }
      });
    }
  };
  const handleAdvanceCancel = () => {
    if (Object.keys(createAdvanceForm?.dirtyFields)?.length) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: createAdvanceForm.isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: createAdvanceForm.isValid
            ? () => onAdvanceSubmit(createAdvanceForm.watch())
            : () => {
                dispatch(closeModal());
              },
          onCancel: () => {
            setSearchParams({ ...searchParams, open: 'false', id: '' });
            dispatch(closeModal());
            createAdvanceForm.reset();
            createAdvanceForm.clearErrors();
          },
        })
      );
    } else {
      setSearchParams({ ...searchParams, open: 'false', id: '' });
      createAdvanceForm.clearErrors();
    }
  };

  // -------------------------
  const [createDistribute, { isLoading: isDistributeLoading }] = useCreateDistributeMutation();
  const distributeForm = useDistribute();

  const handleDistributeClick = ({ id }: IPayrollCreditChargesItem) => {
    setSearchParams({ ...searchParams, mode: 'distribute', open: 'true', id: String(id), renderMode: '' });
  };
  const onDistributeSubmit = (data: IDistributeValues) => {
    if (!isDistributeLoading) {
      createDistribute({ id, body: data }).then((data: any) => {
        if (data.error) {
          notificationHandler('error', data);
        } else {
          notificationHandler('success', data, 'Successfully distributed');
          distributeForm.handleReset();
          distributeForm.setIsDistributeOpen(false);
          setSearchParams({
            ...searchParams,
            open: 'false',
            id: '',
          });
        }
      });
    }
    distributeForm.clearErrors();
  };
  const handleDistributeCancel = () => {
    if (distributeForm.isDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: distributeForm.isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: () => (distributeForm.isValid ? onDistributeSubmit(distributeForm.watch()) : dispatch(closeModal())),
          onCancel: () => {
            setSearchParams({ ...searchParams, open: 'false', id: '' });
            dispatch(closeModal());
            distributeForm.reset();
            distributeForm.setIsDistributeOpen(false);
            distributeForm.clearErrors();
          },
        })
      );
    } else {
      setSearchParams({ ...searchParams, open: 'false', id: '' });
      distributeForm.setIsDistributeOpen(false);
      distributeForm.clearErrors();
    }
  };

  // -------------------------
  const [editPaymentPlan, { isLoading: isCollectionLoading }] = useEditPaymentPlanMutation();
  const collectionForm = useCollection();

  const hasPermissionToChangePlan = useMemo(() => {
    if (roleLevel === IRoleLevel.corpLevel) {
      return !collectionForm?.collectionInfo?.result?.branch && !collectionForm?.collectionInfo?.result?.agent;
    }
    if (roleLevel === IRoleLevel.officeLevel) {
      return !collectionForm?.collectionInfo?.result?.agent;
    }
    if (roleLevel === IRoleLevel.branchLevel) {
      return !!collectionForm?.collectionInfo?.result?.agent || !!collectionForm?.collectionInfo?.result?.branch;
    }
    return false;
  }, [roleLevel, collectionForm?.collectionInfo]);

  const handlePaymentPlanClick = (rowId: number | string) => {
    setSearchParams({
      ...searchParams,
      id: String(rowId),
      open: 'true',
      mode: 'collection',
    });
  };
  const onCollectionSubmit = (data: Partial<ICollectionValues>) => {
    if (hasPermissionToChangePlan) {
      if (!isCollectionLoading && data?.paymentPlanId) {
        editPaymentPlan({ id: String(data?.paymentPlanId), body: data }).then((data: any) => {
          if (data.error) {
            notificationHandler('error', data);
          } else {
            notificationHandler('success', data, 'Successfully prolong');
            collectionForm.reset();
            setSearchParams({
              ...searchParams,
              open: 'false',
              id: '',
            });
          }
        });
      }
    } else {
      setSearchParams({
        ...searchParams,
        open: 'false',
        id: '',
      });
    }
  };
  const handleCollectionCancel = () => {
    if (collectionForm.isDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: collectionForm.isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: collectionForm.isValid
            ? () => onCollectionSubmit(collectionForm.watch())
            : () => {
                dispatch(closeModal());
              },
          onCancel: () => {
            setSearchParams({ ...searchParams, open: 'false', id: '' });
            dispatch(closeModal());
            collectionForm.reset();
            collectionForm.clearErrors();
          },
        })
      );
    } else {
      setSearchParams({ ...searchParams, open: 'false', id: '' });
      collectionForm.clearErrors();
    }
  };

  const selectedCardTitle = useMemo(() => allowedCards?.find(({ id }) => String(id) === card), [card]);

  useEffect(() => {
    if (columnsData?.columns?.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const hasMineFilter =
    (agentType === AgentTypes.MANAGER || agentType === AgentTypes.OWNER) && CARDS_HAS_MINE.includes(tabCards[card]);

  useEffect(() => {
    const additionalParams = {
      ...(endDate ? { dateEnd: endDate } : {}),
      dateStart: startDate,
      branchIds: branchPayroll?.length ? branchPayroll?.split(',')?.map(el => Number(el)) : [],
      officeIds: officePayroll?.length ? officePayroll?.split(',')?.map(el => Number(el)) : [],
      agentIds: agentPayroll ? [Number(agentPayroll)] : [],
    };

    if (card) {
      if (FACTORED_CARDS.includes(card as FactoredCards)) {
        getInformationFactored({
          ...informationFilters,
          ...additionalParams,
        });
      }
      if (NOT_FACTORED_CARDS.includes(card as NotFactoredCards)) {
        getInformationNotFactored({
          ...informationFilters,
          ...additionalParams,
        });
      }
      if (DistributeCards.CHARGES === card) {
        getCharges({
          ...informationFilters,
          ...additionalParams,
        });
      }
      if (DistributeCards.CREDITS === card) {
        getCredits({
          ...informationFilters,
          ...additionalParams,
        });
      }
      if (AdvancedCards.ADVANCE === card) {
        getAdvances({
          ...(hasMineFilter && { mine }),
          ...informationFilters,
          ...additionalParams,
        });
      }
      if (CollectionCards.COLLECTION === card) {
        getCollection({
          ...(hasMineFilter && { mine }),
          ...informationFilters,
          ...additionalParams,
        });
      }
      if (OtherServiceFeeCards.OTHER_SERVICE_FEE === card) {
        getOtherServiceFee({
          ...(hasMineFilter && { mine }),
          ...informationFilters,
          ...additionalParams,
        });
      }
      if (setExcelParams) setExcelParams({ ...informationFilters, ...additionalParams });
    }
  }, [card, informationFilters, startDate, endDate, officePayroll, branchPayroll, agentPayroll, mine]);

  useEffect(() => {
    if (OtherServiceFeeCards.OTHER_SERVICE_FEE === card) {
      if (otherServiceFeeData && !otherServiceFeeData?.result.length && informationFilters?.skip > 0) {
        setInformationFilters((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
      }
    } else if (AdvancedCards.ADVANCE === card) {
      if (advancesData && !advancesData?.result.length && informationFilters?.skip > 0) {
        setInformationFilters((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
      }
    }
  }, [otherServiceFeeData?.result.length, advancesData?.result?.length]);

  const {
    currentChildren: {
      component: componentLoads,
      buttonText: buttonTextLoads,
      onCancel: onCancelLoads,
      onSubmit: onSubmitLoads,
      withFooter: withFooterLoads,
    },
  } = useLoads();

  const drawerChildren: IChildren | any = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    distribute: {
      component: <Distribute {...distributeForm} card={card} />,
      buttonText: SUBJECT.SAVE,
      onCancel: handleDistributeCancel,
      onSubmit: distributeForm.validationCheck
        ? distributeForm.handleSubmit(onDistributeSubmit)
        : () => distributeForm.setIsSubmitted(true),
    },
    charge: {
      component: <Charge {...createChargeForm} isFetching={isChargeLoading} />,
      buttonText: SUBJECT.SAVE_CHANGES,
      onCancel: handleChargeCancel,
      onSubmit: createChargeForm.handleSubmit(onChargeSubmit),
    },
    editCharge: {
      component: <Charge {...createChargeForm} isFetching={isChargeEditLoading} />,
      buttonText: SUBJECT.SAVE_CHANGES,
      onCancel: handleChargeCancel,
      onSubmit: createChargeForm.handleSubmit(onChargeSubmit),
    },
    advance: {
      component: <Advance {...createAdvanceForm} />,
      buttonText: SUBJECT.SAVE_CHANGES,
      onCancel: handleAdvanceCancel,
      onSubmit: createAdvanceForm.handleSubmit(onAdvanceSubmit),
    },
    editAdvance: {
      component: <Advance {...createAdvanceForm} />,
      buttonText: SUBJECT.SAVE_CHANGES,
      onCancel: handleAdvanceCancel,
      onSubmit: createAdvanceForm.handleSubmit(onAdvanceSubmit),
    },
    collection: {
      component: (
        <Collection
          {...collectionForm}
          isCreateLoading={isCollectionLoading}
          hasPermissionToChangePlan={hasPermissionToChangePlan}
        />
      ),
      buttonText: SUBJECT.SAVE_CHANGES,
      onCancel: handleCollectionCancel,
      onSubmit: collectionForm.handleSubmit(onCollectionSubmit),
    },
    view: {
      component:
        card === CollectionCards.COLLECTION ? (
          <CollectionView
            data={collectionForm.collectionInfo}
            isFetching={collectionForm.isCollectionFetching}
            edit={
              payrollPermissions.payrollCollection.editPaymentPlan &&
              mode === 'collection' &&
              mine !== 'true' &&
              hasPermissionToChangePlan &&
              collectionForm?.isCurrentMonth
            }
            handleChangeMode={handleChangeMode}
            roleLevel={roleLevel}
          />
        ) : (
          <AdvanceChargeView
            data={createAdvanceForm.advanceInfo?.result || createChargeForm.otherServiceFeeInfo}
            isFetching={createAdvanceForm.isAdvanceFetching || createChargeForm.isOtherServiceFeeFetching}
            edit={canEditChargeOrAdvance}
            handleChangeMode={handleChangeMode}
            agentType={agentType!}
            handleSwitchClick={handleSwitchClick}
            turnOnCharge={turnOnCharge}
            turnOffCharge={turnOffCharge}
            canEditAdvanceOrCharge={canEditAdvanceOrCharge}
          />
        ),
      onCancel: handleCancel,
    },
    loads: {
      component: componentLoads,
      buttonText: buttonTextLoads,
      onCancel: onCancelLoads,
      onSubmit: onSubmitLoads,
      withFooter: withFooterLoads,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[renderMode || mode]?.component,
      buttonText: drawerChildren[renderMode || mode]?.buttonText,
      onCancel: drawerChildren[renderMode || mode]?.onCancel,
      onSubmit: drawerChildren[renderMode || mode]?.onSubmit,
      withFooter:
        (distributeForm.isOpen && mode === 'distribute') ||
        mode === 'charge' ||
        mode === 'advance' ||
        mode === 'editAdvance' ||
        mode === 'editCharge' ||
        (mode === 'collection' && mine !== 'true' && hasPermissionToChangePlan && collectionForm?.isCurrentMonth) ||
        mode === 'customize',
    };
  }, [
    mode,
    card,
    distributeForm.isOpen,
    distributeForm.activeRow,
    distributeForm.activeInput,
    distributeForm.remainingSum,
    distributeForm.validationCheck,
    distributeForm.remainingPercent,
    distributeForm.watch(),
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
    renderMode,
    canEditAdvanceOrCharge,
    mine,
    hasPermissionToChangePlan,
    collectionForm?.isCurrentMonth,
  ]);

  return {
    data,
    card,
    columns: columnsData?.columns,
    offices: finalOfficeOptions,
    branches: finalBranchesOptions,
    finalPay: summariesData?.finalPay,
    overallPay: summariesData?.overallPay,
    roleLevel,
    isFetching,
    agentPayroll,
    cardsData: allowedCards!,
    filterControl,
    isFilterDirty,
    turnOnCharge,
    turnOffCharge,
    currentChildren,
    selectedOffices,
    selectedBranches,
    selectedCardTitle,
    finalAgentOptions,
    informationFilters,
    selectedAgentOption,
    canEditChargeOrAdvance,
    canDeleteChargeOrAdvance,
    resetFilter,
    filterWatch,
    handleRowEdit,
    handleRowClick,
    setFilterValue,
    setAgentsFilter,
    handleRowDelete,
    handleSortClick,
    setBranchFilter,
    handleStopResize,
    handleSelectCard,
    setOfficesFilter,
    handleSwitchClick,
    handleColumnSearch,
    handlePaginationClick,
    handleDistributeClick,
    handlePaymentPlanClick,
    handleMineFilter,
    isChangedDate,
    dirtyFields,
    agentType,
    mine,
    canEditAdvanceOrCharge,
    setSelectedAgentOption,
    isSummariesFetching,
  };
};

export default useInformation;
