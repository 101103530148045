import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useChangeUserPasswordMutation } from 'services/user/user';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectModals } from 'store/modal-slice/selector';
import { changeLoading } from 'store/sidebarSlice/sidebar';
import { selectUserInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { IChildren } from 'utils/types';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import ChangePassword from './components/change-password/ChangePassword';
import { defaultValues, SUBJECT } from './constants/constants';
import { IResetPassword } from './constants/types';
import { validation } from './constants/validation';

const useUserSettings = () => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParams } = useDetectedParams();
  const { isLoading: isLoadingSubmit } = useSelector(selectModals);

  const userInfo = useSelector(selectUserInfo);

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isDirty, errors, touchedFields },
    clearErrors,
    getValues,
    reset,
    trigger,
  } = useForm<IResetPassword>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(validation),
  });

  const newPassword = getValues('newPassword');

  useEffect(() => {
    if (newPassword.length && touchedFields.repeatPassword) {
      trigger('repeatPassword');
    }
  }, [newPassword]);
  const [changeUserPassword, { isLoading }] = useChangeUserPasswordMutation();

  const onSubmit = () => {
    if (!isLoadingSubmit) {
      dispatch(changeLoading(true));
      changeUserPassword({
        currentPassword: watch('currentPassword'),
        newPassword: watch('newPassword'),
        email: userInfo?.email,
      }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          dispatch(changeLoading(false));
        } else {
          notification.success({
            message: 'The password was successfully changed',
            duration: 3,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          reset();
          dispatch(changeLoading(false));
          setSearchParams({
            ...searchParams,
            open: 'false',
          });
        }
      });
    }
  };

  const handleCloseModal = () => {
    setSearchParams({
      tab: 'user-settings',
      open: 'false',
    });
    dispatch(closeModal());
    reset(defaultValues);
  };

  const handleCancel = () => {
    if (isDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: isValid
            ? () => {
                onSubmit();
                dispatch(closeModal());
                clearErrors();
              }
            : () => {
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        tab: 'user-settings',
        open: 'false',
      });
      clearErrors();
    }
  };

  const isButtonVisible = true;

  const openMainDrawer = () => {
    setSearchParams({
      tab: 'user-settings',
      open: 'true',
    });
  };

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isLoading }));
  }, [isLoading]);

  const drawerChildren: IChildren = {
    settings: {
      component: <ChangePassword control={control} watch={watch} errors={errors} />,
      buttonText: SUBJECT.SAVE,
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren.settings.component,
      buttonText: drawerChildren.settings.buttonText,
      onCancel: drawerChildren.settings.onCancel,
      withFooter: true,
    };
  }, [isDirty, errors, watch, isValid]);

  return {
    isButtonVisible,
    openMainDrawer,
    currentChildren,
    onSubmit,
    handleSubmit,
  };
};
export default useUserSettings;
