import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetCustomersQuery } from 'services/autocomplete/autocomplete';
import { IGetLoadsParams } from 'services/loads/interface';
import {
  useCreateCheckRequestMutation,
  useGetDepthByCustomerAndAuthorityQuery,
  useGetPaymentTermsByCustomerIdAndAuthorityIdQuery,
} from 'services/loads/loads';
import { useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { changeLoading } from 'store/sidebarSlice/sidebar';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { selectUserPermissionsInfo } from '../../../../store/user-slice/selector';

import { creditDefaultValues } from './constants/constants';
import { IRequestCreditFormData } from './constants/types';
import { validation } from './constants/validation';

const useRequestCredit = () => {
  const dispatch = useDispatch();
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { open, mode } = searchParams;

  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: [],
  });

  const [customersState, setCustomersState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const authoritiesRequestType = () => {
    return !(employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN);
  };

  const { data: authorities = [] } = useGetAuthoritiesShortQuery(
    { ...authoritiesState, onlyAssigned: authoritiesRequestType() },
    {
      skip: open === 'false' || mode !== 'add',
    }
  );

  const { data: customers = [] } = useGetCustomersQuery(customersState, {
    skip: open === 'false' || mode !== 'add',
  });

  const [createCheckRequest, { isLoading: isCreateLoading }] = useCreateCheckRequestMutation();

  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { isValid: isValid, isDirty: isDirty, errors: errors },
    getValues,
    setValue,
    watch,
    reset,
    resetField,
  } = useForm<IRequestCreditFormData>({
    defaultValues: creditDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    reset(creditDefaultValues, { keepDirty: false });
  };

  const onSubmit = (data: IRequestCreditFormData) => {
    dispatch(changeLoading(true));
    createCheckRequest({ customerId: +customerId, authorityId: +authorityId, customerAmount: +data.amount }).then(
      (data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          dispatch(changeLoading(false));
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          dispatch(changeLoading(false));
          resetForm();
        }
      }
    );
  };

  const customerId = watch('customer')?.[0]?.id;
  const authorityId = watch('authority')?.[0]?.id;

  const { data: depthData = [] } = useGetDepthByCustomerAndAuthorityQuery(
    { customerId, authorityId },
    { skip: !authorityId || !customerId }
  );

  const { data: paymentTerm = [], isFetching: isPaymentTermLoading } =
    useGetPaymentTermsByCustomerIdAndAuthorityIdQuery(
      { customerId, authorityId },
      { skip: !authorityId || !customerId }
    );

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isCreateLoading }));
  }, [isCreateLoading]);

  useEffect(() => {
    setAuthoritiesState(prev => ({
      ...prev,
      ...(authorityId ? { ignoreAuthorityIds: [authorityId] } : { ignoreAuthorityIds: [] }),
    }));
  }, [authorityId]);

  useEffect(() => {
    if (!watch('authority')?.length || !watch('customer')?.length) {
      resetField('amount');
    }
  }, [watch('authority')?.length, watch('customer')?.length]);

  return {
    isDirty,
    errors,
    control,
    authorities,
    setAuthoritiesState,
    customers,
    setCustomersState,
    reset,
    watch,
    setValue,
    getValues,
    onSubmit,
    clearErrors,
    handleSubmit,
    paymentTerm,
    depthData,
    isPaymentTermLoading,
    isValid,
  };
};

export default useRequestCredit;
