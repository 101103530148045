import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { autocompleteAPI } from 'services/autocomplete/autocomplete';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { branchesAPI, useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import {
  useAddCarrierBlackListMutation,
  useDeleteCarrierBlackListMutation,
  useGetCarrierBlackListQuery,
  useGetCarriersAutocompleteQuery,
} from 'services/profile/carriers/carriers';
import { useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { useGetAgentsAutocompleteQuery } from '../../../../../../services/profile/agents/agents';
import { useGetOfficesAutocompleteQuery } from '../../../../../../services/profile/offices/offices';
import { blacklistDefaultValues } from '../../components/blacklist/constants/constants';
import { IBlacklistFilter } from '../../components/blacklist/constants/types';
import { ICarriersParams, ICarriersTableColumns } from '../../constants/types';

const useBlacklistTable = ({ setExcelParams }: ICarriersParams) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { activePage } = searchParams;

  const [showBlacklist, setShowBlacklist] = useState(false);

  const [carriersFilter, setCarriersFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    search: '',
    skip: 0,
    limit: 10,
  });

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [getBlackListFilter, setBlackListFilter] = useState<Partial<any>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const [selectedOffices, setSelectedOffices] = useState<any>([]);
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [finalBranchesOptions, setFinalBranchesOptions] = useState<any>({ result: [], count: 0 });

  const { data: carriersOptions = { result: [], count: 0 } } = useGetCarriersAutocompleteQuery(carriersFilter);

  const { data: officesOptions = { result: [], count: 0 } } = useGetOfficesAutocompleteQuery(officesFilter);

  const { data, isFetching } = useGetCarrierBlackListQuery(getBlackListFilter);
  const { data: columnsData } = useGetColumnsQuery({ type: 'carrierBlackList' });

  const [addCarrierBlackList] = useAddCarrierBlackListMutation();
  const [deleteCarrierBlackList] = useDeleteCarrierBlackListMutation();

  const [editColumns] = useEditColumnsMutation();

  const { reset, control, watch, handleSubmit, setValue } = useForm<IBlacklistFilter>({
    defaultValues: blacklistDefaultValues,
    mode: 'onBlur',
  });

  const { data: branchesData = { result: [], count: 0 }, isFetching: isBranchesFetching } =
    useGetBranchesAutocompleteQuery(
      { officeId: selectedOffices?.[0]?.id, ...branchFilter },
      {
        skip: !watch('office')?.length || watch('office')!.length > 1,
      }
    );
  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    {
      ...agentsFilter,
      branchId: watch('branch')?.[0],
    },
    {
      skip: typeof watch('branch') === 'string' || (watch('branch')?.length !== 1 && !watch('branch')?.includes('0')),
    }
  );

  useEffect(() => {
    if (activePage !== 'blacklist') {
      setBlackListFilter({
        search: '',
        field: '',
        skip: 0,
        limit: 20,
        order: 2,
        orderBy: 'createdAt',
        filter: {},
      });
    }
  }, [activePage]);

  useEffect(() => {
    if (!watch('office')?.length) {
      dispatch(branchesAPI.util.resetApiState());
    }
  }, [watch('office')]);

  const watchData = watch();

  useEffect(() => {
    if (Array.isArray(watch('office'))) {
      setSelectedOffices((prev: any) =>
        [
          ...prev.filter((el: any) => !officesOptions.result.some((item: any) => item.value == el.value)),
          ...officesOptions.result,
        ].filter((el: any) => watch('office')!.some((item: any) => item == el.value))
      );
    }
  }, [watch('office')]);

  useEffect(() => {
    if (Array.isArray(watch('branch'))) {
      setSelectedBranches((prev: any) =>
        [
          ...prev.filter((el: any) => !branchesData.result.some((item: any) => item.value == el.value)),
          ...branchesData.result,
        ].filter((el: any) => watch('branch')!.some((item: any) => item == el.value))
      );
    }
  }, [watch('branch')]);

  useEffect(() => {
    if (officesOptions?.result) {
      setFinalOfficeOptions({
        result: [
          ...selectedOffices,
          ...officesOptions.result.filter((el: any) => !selectedOffices.some((item: any) => item.value == el.value)),
        ],
        count: officesOptions.count,
      });
    } else {
      setFinalOfficeOptions(selectedOffices);
    }
  }, [selectedOffices, officesOptions]);

  useEffect(() => {
    if (branchesData?.result?.length) {
      setFinalBranchesOptions({
        result: [
          ...selectedBranches,
          ...branchesData.result.filter((el: any) => !selectedBranches.some((item: any) => item.value == el.value)),
        ],
        count: branchesData.count,
      });
    } else {
      setFinalBranchesOptions(selectedBranches);
    }
  }, [selectedBranches, branchesData?.result?.length, isBranchesFetching]);

  const handleResetBlacklist = () => {
    if (watchData.branch?.length || watchData.carrier?.length || watchData.office?.length) {
      reset();
    } else {
      handleBlacklistClick();
    }
  };

  const onSubmit = (data: IBlacklistFilter) => {
    if (watchData.carrier && (watchData.office || watchData.branch || watchData.agent)) {
      addCarrierBlackList({
        officeIds: data?.office?.map((item: any) => Number(item)),
        branchIds: data?.branch?.map((item: any) => Number(item)),
        agentIds: data?.agent?.map((item: any) => Number(item?.id)),
        id: Number(data?.carrier?.[0]?.id),
      }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully added',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          reset();
          setShowBlacklist(false);
        }
      });
    }
  };

  const handleBlacklistClick = () => {
    setShowBlacklist(prev => !prev);
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({ ...searchParams, id: rowId, mode: 'blacklist', open: 'true' });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string | number) => {
    setBlackListFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex === 'date' ? 'createdAt' : dataIndex,
      };
    });
  };

  const handleStopResize = (columns: ICarriersTableColumns[]) => {
    editColumns({ type: 'carrierBlackList', columns });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setBlackListFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleCarrierUnlockClick = (rowId: string) => {
    dispatch(
      openModal({
        title: 'Are you sure want to Unlock?',
        okText: 'Unlock',
        cancelText: 'Cancel',
        onOk: () => {
          deleteCarrierBlackList({ groupId: rowId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully unlocked',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          setShowBlacklist(false);
          reset();
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setBlackListFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  useEffect(() => {
    if (watch('office')!.length !== 1) {
      setValue('branch', []);
      setValue('agent', []);
    }
  }, [watch('office')]);

  useEffect(() => {
    if (watch('branch')!.length !== 1) {
      setValue('agent', []);
    }
  }, [watch('branch')]);

  useEffect(() => {
    return () => {
      reset();
      setShowBlacklist(false);
      dispatch(autocompleteAPI.util.resetApiState());
    };
  }, [activePage]);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...getBlackListFilter,
        };
      });
    }
  }, [getBlackListFilter]);

  useEffect(() => {
    if (!data?.result.length && getBlackListFilter?.skip > 0) {
      setBlackListFilter((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [data?.result?.length]);

  return {
    data,
    columns: columnsData?.columns,
    isFetching,
    showBlacklist,
    carriersOptions,
    carriersFilter,
    officesFilter,
    agentsFilter,
    setCarriersFilter,
    setOfficesFilter,
    setAgentsFilter,
    agentsOptions,
    control,
    handleBlacklistClick,
    handleRowClick,
    handleSortClick,
    handleStopResize,
    handleColumnSearch,
    handleCarrierUnlockClick,
    handlePaginationClick,
    handleResetBlacklist,
    setValue,
    handleSubmit,
    onSubmit,
    watch,
    setBranchFilter,
    skip: getBlackListFilter.skip,
    limit: getBlackListFilter.limit,
    finalOfficeOptions,
    finalBranchesOptions,
    selectedOffices,
    selectedBranches,
  };
};
export default useBlacklistTable;
