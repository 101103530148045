import React from 'react';

const TimeIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0625 14.0221L14 13.0846L10.6875 9.7513V5.5638H9.43746V10.2513L13.0625 14.0221ZM9.99996 18.3346C8.86107 18.3346 7.78468 18.1159 6.77079 17.6784C5.7569 17.2409 4.87149 16.6437 4.11454 15.8867C3.3576 15.1298 2.76038 14.2444 2.32288 13.2305C1.88538 12.2166 1.66663 11.1402 1.66663 10.0013C1.66663 8.86241 1.88538 7.78603 2.32288 6.77214C2.76038 5.75825 3.3576 4.87283 4.11454 4.11589C4.87149 3.35894 5.7569 2.76172 6.77079 2.32422C7.78468 1.88672 8.86107 1.66797 9.99996 1.66797C11.1388 1.66797 12.2152 1.88672 13.2291 2.32422C14.243 2.76172 15.1284 3.35894 15.8854 4.11589C16.6423 4.87283 17.2395 5.75825 17.677 6.77214C18.1145 7.78603 18.3333 8.86241 18.3333 10.0013C18.3333 11.1402 18.1145 12.2166 17.677 13.2305C17.2395 14.2444 16.6423 15.1298 15.8854 15.8867C15.1284 16.6437 14.243 17.2409 13.2291 17.6784C12.2152 18.1159 11.1388 18.3346 9.99996 18.3346ZM9.99996 17.0846C11.9444 17.0846 13.6111 16.3902 15 15.0013C16.3889 13.6124 17.0833 11.9457 17.0833 10.0013C17.0833 8.05686 16.3889 6.39019 15 5.0013C13.6111 3.61241 11.9444 2.91797 9.99996 2.91797C8.05552 2.91797 6.38885 3.61241 4.99996 5.0013C3.61107 6.39019 2.91663 8.05686 2.91663 10.0013C2.91663 11.9457 3.61107 13.6124 4.99996 15.0013C6.38885 16.3902 8.05552 17.0846 9.99996 17.0846Z"
        fill="#667695"
      />
    </svg>
  );
};

export default TimeIcon;
