import React from 'react';
import clsx from 'clsx';

import { CalendarIcon } from 'components/svgs/CalendarIcon';
import CloseIcon from 'components/svgs/CloseIcon';

import DoubleRangePicker from './components/double-range-picker/DoubleRangePicker';
import { useCustomDoubleRangePicker } from './useCustomDoubleRangePicker';

import styles from './CustomDoubleRangePicker.module.scss';

const CustomDoubleRangePicker = () => {
  const { handleOpenCalendar, handleClear, onRangeChange, inputValue, isOpened, startDate, endDate, setIsOpened } =
    useCustomDoubleRangePicker();

  return (
    <div
      className={clsx(styles.selectDatePickerWrapper, {
        [styles.openedWrapper]: isOpened,
      })}
    >
      <label>{'Available date'}</label>
      <ul className={styles.datepickerMainList}>
        <li className={styles.datepickerAction}>
          <div className={clsx(styles.actionItem)} role="button" onClick={handleOpenCalendar}>
            {inputValue}
            {startDate && endDate ? (
              <div role="button" className={styles.clearBtn} onClick={handleClear}>
                <CloseIcon />
              </div>
            ) : (
              <span>
                <CalendarIcon />
              </span>
            )}
          </div>
        </li>
      </ul>
      <div className={styles.rangepicker}>
        {isOpened && (
          <DoubleRangePicker
            selectsRange
            isCalendarOpen={isOpened}
            setIsCalendarOpen={setIsOpened}
            handleDateChange={onRangeChange}
            start={startDate}
            end={endDate}
          />
        )}
      </div>
    </div>
  );
};

export default CustomDoubleRangePicker;
