export const MOCK_DATA = {
  count: 3,
  comments: [
    {
      name: 'Zella',
      createdAt: '13/02/2019 10:39 AM',
      comment: 'comment 33333333333333333333333',
      id: 3,
    },
    {
      name: 'Stefan',
      createdAt: '06/09/2016 9:34 AM',
      comment: 'comment 222222222222222222222222',
      id: 4,
    },
    {
      name: 'Rodrigo',
      createdAt: '13/02/2017 12:34 AM',
      comment: 'comment 11111111111111111111111111',
      id: 5,
    },
  ],
};

export const COMMENTS = {
  count: 2,
  commentsList: [
    {
      id: '1',
      name: 'Zella',
      createdAt: '12-7-2022 11:34 AM',
      commentText:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
    },
    {
      id: '2',
      name: 'John',
      createdAt: '12-7-2022 11:34 AM',
      commentText:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
    },
  ],
};

export const NOTIFICATIONS = {
  count: 1,
  notificationsList: [
    {
      id: '1',
      notificatonText: 'Reference number of tonu load has changed from Name Surname ',
      createdAt: '12-30-2021, 01:45 PM',
    },
  ],
};

export enum VALIDATION_MESSAGES {
  CITY_REQUIRED = 'City is a required field',
  COORDINATOR_REQUIRED = 'Coordinator is a required field',
  FROM_REQUIRED = 'State is a required field',
  TO_REQUIRED = 'Desired destination is a required field',
  AVAILABLE_DATE_REQUIRED = 'Available date is a required field',
  EQUIPMENT_TYPE_REQUIRED = 'Equipment type is a required field',
  TRAILER_DETALS_REQUIRED = 'Trailer details is a required field',
  NOT_ZERO = 'Should be a positive number',
  LENGTH_REQUIRED = 'Length is required field',
  LENGTH_DIGITS = "Length couldn't be more than 7 digits",
}

export enum SUBJECT {
  FIRST_PICKUP = 'First pick-up date',
  ARE_YOU_SURE_WRONG_LOCATION = 'Are you sure truck has a wrong location?',
  LAST_DROP_OFF = 'Last drop-off date',
  JUST_ARE_YOU_SURE = 'Are You Sure ?',
  ARE_YOU_SURE_HAS_LOAD = 'Are You Sure? Load Will Be Moved To Verification Board',
  YES = 'Yes',
  NO = 'No',
  CANCEL = 'Close',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  SAVE = 'Save',
  COMMENT_ID = 'comment',
  TRUCK_INFO = 'Truck Info',
  EDIT = 'Edit',
  TRUCK_NUMBER = 'Truck Number',
  AVAILABLE_DATE = 'Available Date',
  COORDINATOR = 'Coordinator',
  EQUIPMENT_TYPE = 'Equipment Type',
  LOCATED_CITY = 'Located City',
  DESIRE_DESTINATION = 'Desire Destination',
  STATUS = 'Status',
  TRAILER_DETAILS = 'Trailer Details',
  LOCATED_STATE = 'Located State',
  LAST_COMMENT = 'Last Comment',
  REFUSED_THE_LOAD = 'Refused The Load',
  ALREADY_HAS_LOAD = 'Already Has a Load',
  WRONG_LOCATION = 'Wrong Location',
  LAST_LOADS = 'Last Loads',
  COMMENT = 'Comment',
}
