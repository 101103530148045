import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import Button from 'ui/button/Button';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { multiplyDownloadWithDelay, multiplyViewWithDelay } from 'utils/helpers';

import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import CopyIcon from '../../../../../../components/svgs/CopyIcon';
import { SUBJECT } from '../../constants/constants';
import { BillByFreightMax } from '../../constants/types';

import { useFactoringCompaniesView } from './useFactoringCompaniesView';

import styles from './FactoringCompanies.module.scss';

const FactoringCompaniesView = ({
  data,
  isFetchingById,
  pagePermissions: { edit, terminateOn, terminateOff },
  factoringCompaniesPermissions,
}: any) => {
  const { handleChecked, handleChangeMode } = useFactoringCompaniesView({
    id: data?.result?.id,
    factoringCompaniesPermissions,
  });
  const { copiedText, handleCopy } = useHandleCopyActive();
  const checkedValue = !data?.result?.terminatedAt;

  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetchingById} />
      <ViewItemWrapper title={SUBJECT.FACTORING_INFO}>
        <Row gutter={[20, 15]} align="middle">
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.FACTORING_NAME}:{' '}
              </Typography>
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.companyName}
                subject={`${SUBJECT.FACTORING_INFO}${SUBJECT.FACTORING_NAME}`}
              />
            </div>
          </Col>
          <Col span={7}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.PHONE_NUMBER}:
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {data?.result?.phone}
              </Typography>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" children={SUBJECT.FACTORING_ID} className="top-banner-title" />
              <div
                className={clsx(styles.copyIconText, {
                  [styles.copyActive]: copiedText === 'factoringId',
                })}
              >
                <Typography variant="paragraph" children={data?.result?.id} className={styles.infoParagraph} />
                <div
                  className={styles.copyIcon}
                  role="button"
                  onClick={() => handleCopy(data?.result?.id, 'factoringId')}
                >
                  <CopyIcon active={copiedText === 'factoringId'} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper
        title={SUBJECT.FULL_INFO}
        Component={
          edit && (
            <Button children={SUBJECT.EDIT} size="large" onClick={handleChangeMode} className="viewDrawerEditBtn" />
          )
        }
      >
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.result?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.email}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.EMAIL}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.result?.address} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          {data?.result?.secondAddress && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.SECOND_ADDRESS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.secondAddress}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {data?.result?.fax && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FAX_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" children={data?.result?.fax} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.city}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.CITY}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.state?.name}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.STATE}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ZIPCODE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.result?.zip} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.BILL_BY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={BillByFreightMax[data?.result?.type]}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.BILL_BY}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DOCUMENTS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Row gutter={5}>
                  <Col>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        multiplyViewWithDelay(data?.result?.files);
                      }}
                    >
                      <EyeIconSvg outlined={true} />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        multiplyDownloadWithDelay(data?.result?.files);
                      }}
                    >
                      <NoaDownloadIcon />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Tooltip
                  placement="top"
                  title={
                    (checkedValue && !terminateOff) || (!checkedValue && !terminateOn)
                      ? "You Don't have permission for this action"
                      : ''
                  }
                  color={'white'}
                >
                  <span>
                    <Switch
                      checked={checkedValue || false}
                      onChange={handleChecked}
                      disabled={(checkedValue && !terminateOff) || (!checkedValue && !terminateOn)}
                    />
                  </span>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.DIRECT_DEPOSIT}>
        <Col>
          <Row align="middle">
            <Col span={8}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.BANK_NAME} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.paymentBankName}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className={styles.secondRow}>
            <Row>
              <Col span={8}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.ROUTING_NUMBER} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={data?.result?.paymentRoutingNumber}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={styles.secondRow}>
            <Row>
              <Col span={8}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.ACCOUNT_NUMBER} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={data?.result?.paymentAccountNumber}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.PAYPER_CHECK}>
        <div>
          <Row align="middle">
            <Col span={12}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.PAYMENT_COMPANY_NAME}
                    className={styles.infoHeading}
                  />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.paymentCompanyName}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.paymentAddress}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className={styles.secondRow}>
          <Row align="middle">
            <Col span={12}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ZIPCODE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.paymentZip}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.paymentCity}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className={styles.secondRow}>
          <Row align="middle">
            <Col span={12}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.paymentState?.name}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
            {data?.result?.paymentSecondAddress && (
              <Col span={12}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.SECOND_ADDRESS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={data?.result?.paymentSecondAddress}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </ViewItemWrapper>
    </div>
  );
};

export default FactoringCompaniesView;
