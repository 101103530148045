import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { ICommentViewForm } from 'pages/credit-check/tabs/new-credit/componetns/comments-view/constants/types';
import { ActiveTypes } from 'services/carriersOnBoard/interface';
import {
  useChangeStatusMutation,
  useCreateEditCarrierRatingMutation,
  useDeleteCarrierRatingMutation,
  useLazyGetCarrierRatingQuery,
  useLazyGetFilesByCarrierIdAndTypeQuery,
} from 'services/profile/carriers/carriers';
import { useCreateFavoriteMutation, useDeleteFavoriteMutation } from 'services/truck-board/favorite-trucks/favorite';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { multiplyDownloadWithDelay } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from '../../constants/constants';

const useCarrierRowInfo = ({ carrierInfo, setBreakdownParams, refetchTrucksById }: any) => {
  const dispatch = useDispatch();
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const [isFavorite, setIsFavorite] = useState(carrierInfo?.favorite);

  const hasAccessToView = usePermittedActions('profiles.carriers.carrierTable');

  const { control, watch, setValue } = useForm<ICommentViewForm>({
    defaultValues: { comment: '' },
  });

  const { searchParams, setSearchParams } = useDetectedParams();

  const { type, rating, id, width } = searchParams;

  const [changeStatus] = useChangeStatusMutation();
  const [getFilesByType, { data: _filesDataForEdit }] = useLazyGetFilesByCarrierIdAndTypeQuery();

  const [ratesParams] = useState({
    skip: 0,
    limit: 50,
  });

  const [deleteFavorite] = useDeleteFavoriteMutation();
  const [createFavorite] = useCreateFavoriteMutation();

  const [createEditCarrierRating] = useCreateEditCarrierRatingMutation();
  const [getCarrierRating, { data: rateList }] = useLazyGetCarrierRatingQuery();
  const [deleteCarrierRating] = useDeleteCarrierRatingMutation();

  const activeSection = useSelector(selectActiveSection);
  const handleCarrierRate = (rating: number | string) => {
    if (carrierInfo?.accessRating) {
      createEditCarrierRating({ carrierId: Number(id), rating }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully rated',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        }
      });
    }
  };

  const getCarrierRatings = () => {
    getCarrierRating({ skip: ratesParams.skip, limit: ratesParams.limit, carrierId: Number(id) });
  };

  const handleDeleteRate = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_DELETE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        onOk: () => {
          deleteCarrierRating({ ratingId: Number(id) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleSwitchClick = (checked: boolean) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({ id, status: checked ? 1 : 2 }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      ...(type ? { type } : {}),
      ...(rating ? { rating } : {}),
      tab: 'carriers',
      mode: 'edit',
      open: 'true',
    });
    setBreakdownParams({
      skip: 0,
      limit: 10,
      order: 2,
      orderBy: 'createdAt',
    });
    refetchTrucksById();
  };

  const handleCommentClick = () => {
    setSearchParams({
      ...searchParams,
      mode: 'comments',
      open: 'true',
      id,
    });
  };

  const handleDownloadFile = (type: any) => {
    getFilesByType({ id, type }).then(({ data }: any) => multiplyDownloadWithDelay(data?.result));
  };

  const handleViewFile = (type: any) => {
    getFilesByType({ id, type }).then(({ data }: any) =>
      data?.result?.forEach((file: any) => {
        const { preview } = file;
        window.open(preview, '_blank');
      })
    );
  };

  const handleDownloadAll = (ids: number[]) => {
    multiplyDownloadWithDelay(ids);
  };

  const handleDeleteFavorite = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_REMOVE,
        okText: SUBJECT.REMOVE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          deleteFavorite({ id: Number(carrierInfo?.id) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
              dispatch(closeModal());
            } else {
              notification.success({
                message: SUBJECT.SUCCESSFULLY_REMOVED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              setIsFavorite(false);
              dispatch(changeLoading(false));
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleCreateFavorite = () => {
    dispatch(changeLoading(true));
    createFavorite({ id: Number(carrierInfo?.id) }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 1,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
        dispatch(closeModal());
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_ADDED,
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
        setIsFavorite(true);
        dispatch(closeModal());
      }
    });
  };

  const equipmentType = useMemo(() => {
    if (!carrierInfo?.equipments) {
      return [];
    }

    return carrierInfo?.equipments
      .map((item: any) => (item.status === ActiveTypes.ACTIVE ? item.type : null))
      .filter((item: any) => item !== null);
  }, [carrierInfo]);

  useEffect(() => {
    setIsFavorite(carrierInfo?.favorite);
  }, [carrierInfo?.favorite]);

  return {
    handleSwitchClick,
    handleChangeMode,
    handleCommentClick,
    handleDownloadFile,
    handleViewFile,
    userType,
    employeeLevel,
    control,
    watch,
    setValue,
    handleCarrierRate,
    getCarrierRatings,
    rateList,
    handleDeleteRate,
    ratesParams,
    handleDownloadAll,
    hasAccessToView,
    activeSection,
    width,
    handleDeleteFavorite,
    handleCreateFavorite,
    isFavorite,
    equipmentType,
  };
};

export default useCarrierRowInfo;
