import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import Typography from 'ui/typography/Typography';

import Button from '../../../ui/button/Button';

import { SUBJECT } from './constants/constants';
import { IResetPasswordProps } from './constants/types';
import { Input } from './hoc-instance/instance';
import useCreateUpdatePassword from './useCreateUpdatePassword';

import styles from './CreateUpdatePassword.module.scss';

const CreateUpdatePassword = ({ name }: IResetPasswordProps) => {
  const { handleSubmit, onSubmit, watch, control, isValid, errors, isCreateOrUpdateLoading } =
    useCreateUpdatePassword(name);

  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[0, 30]} justify="center">
          <Col span={24}>
            <h2 className={styles.title}>{name}</h2>
          </Col>
          <Col span={24}>
            <Input
              errors={errors}
              height={50}
              name="newPassword"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.NEW_PASSWORD}
              type="password"
              getValues={watch}
            />
          </Col>
          <Col span={24}>
            <Input
              errors={errors}
              height={50}
              name="repeatPassword"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.REPEAT_PASSWORD}
              type="password"
              getValues={watch}
            />
          </Col>
        </Row>

        <div className={styles.tooltipWrapper}>
          <Tooltip
            overlayClassName={styles.tooltipItem}
            color="white"
            placement="left"
            title={
              <div className={styles.tooltipContent}>
                <h3>{SUBJECT.PASSWORD_REQUIREMENTS}</h3>
                <Typography variant="paragraph">{SUBJECT.PASSWORD_REQUIREMENTS_CONTENT}</Typography>
              </div>
            }
          >
            <Typography variant="paragraph">{SUBJECT.PASSWORD_REQUIREMENTS}</Typography>
          </Tooltip>
        </div>
        <Button variant="default" size="large" htmlType="submit" disabled={!isValid} loading={isCreateOrUpdateLoading}>
          {SUBJECT.SAVE}
        </Button>
      </form>
    </div>
  );
};

export default CreateUpdatePassword;
