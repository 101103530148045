import { IExpandedRowFormData } from './types';

export enum SUBJECT {
  TRANSACTION_TYPE = 'Transaction Type',
  CUSTOMER_AMOUNT = 'Customer Amount',
  COMMENT = 'Comment',
  WRITE_COMMENT = 'Write Comment',
  CANCEL = 'Cancel',
  ADD_TRANSACTION = 'Add Transaction',
}

export enum VALIDATION_MESSAGES {
  ONLY_NOT_ZERO = 'Customer amount must be either positive or negative number',
  CUSTOMER_WITH_TYPE = 'Customer amount must be positive with this transaction type',
  CUSTOMER_POSITIVE = 'Customer amount must be positive number',
  TRANSACTION_TYPE_REQUIRED = 'Transaction type is a required field',
  CUSTOMER_AMOUNT_REQUIRED = 'Customer amount is a required field',
  MIN_OPTION = 'At least one option is required',
  POSITIVE = 'Please enter a positive number',
  INTEGER = 'Please enter an integer',
  MIN_NUMBER = 'Number must be greater than or equal to 0',
  VALID_NUMBER = 'Please enter a valid number',
}

export const defaultValues: IExpandedRowFormData = {
  transactionType: '',
  customer: '',
  transactionComment: '',
};

export const TRANSACTION_TYPE_OPTIONS = [
  { title: 'Detention Layover', value: '1' },
  { title: 'Driver Assist', value: '2' },
  { title: 'Late Fee', value: '3' },
  { title: 'Lumper', value: '4' },
  { title: 'Fuel Advance', value: '5' },
  { title: 'Warehouse', value: '7' },
  { title: 'Rate Adjustment', value: '6' },
  { title: 'Miscellaneous', value: '11' },
];
