import { IBlacklistFilter } from './types';

export const blacklistDefaultValues: IBlacklistFilter = {
  carrier: [],
  office: [],
  branch: [],
  agent: [],
};

export enum SUBJECT {
  CARRIER = 'Carrier',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  AGENT = 'Agent',
  ADD_BLACKLIST = 'Add Blacklist',
  CANCEL = 'Cancel',
  NOT_SELECTED = 'Not Selected',
  TOOLTIP_TEXT = 'Select All Active Fields',
}

export const AGENT_COLUMNS = [
  { key: 'username', name: 'Username' },
  { key: 'officeName', name: 'Agency name' },
  { key: 'value', name: 'ID number' },
];

export const AGENT_KEYS = ['username', 'officeName', 'value', 'id'];
export const AGENT_COLUMNS_LOAD = [
  { key: 'username', name: 'Username' },
  { key: 'officeName', name: 'Agency name' },
  { key: 'value', name: 'ID number' },
];

export const AGENT_KEYS_LOAD = ['username', 'officeName', 'id'];

export const CARRIER_KEYS = ['name', 'mc'];

export const SEARCH_CARRIER_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'value', name: 'Mc number' },
];
