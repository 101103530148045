import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useFileUpload from 'hooks/useFileUpload';
import { SUBJECT, transactionDefalultValue } from 'pages/loads/components/edit-load/constants/constants';
import { handleBalanceAmount } from 'pages/loads/constants/helpers';
import { billingAPI } from 'services/factoring/billing/billing';
import { IGetLoadsParams } from 'services/loads/interface';
import {
  useAddRedConfirmationFileToLoadMutation,
  useCancelLoadMutation,
  useDeleteFileFromLoadMutation,
  useEditInternalInfoLoadMutation,
  useEditLoadMutation,
  useGetTrucksAutocompleteQuery,
} from 'services/loads/loads';
import { useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { useGetCarriersAutocompleteQuery } from 'services/profile/carriers/carriers';
import { useCreateTransactionMutation, useDeleteTransactionMutation } from 'services/transactions/transactions';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { formatNumberWithThousandsSeparatorAndDecimal, scrollToTarget } from '../../../../../../utils/helpers';
import { ITransactionFormData, LoadTypes } from '../../constants/types';
import { transactionValidation } from '../../constants/validation';

export const useInternalEdit = ({
  data,
  createLoadForm,
  loadFiles,
  loadPoints,
  refetchTransaction,
  refetchLoadById,
  refetchFilesByLoadId,
  loadsPagePermissions,
}: any) => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { open, mode } = searchParams;

  const { employeeLevel, userType, permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const { id: userId } = useSelector(selectUserInfo);

  const dispatch = useDispatch();
  const activeSection = useSelector(selectActiveSection);
  const [editLoad] = useEditLoadMutation();
  const [editInternalInfoLoad] = useEditInternalInfoLoadMutation();
  const [addRedConfirmationFileToLoad] = useAddRedConfirmationFileToLoadMutation();
  const [deleteFileFromLoad] = useDeleteFileFromLoadMutation();
  const [createTransaction, { isLoading: isCreateTransactionLoading }] = useCreateTransactionMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [cancelLoad] = useCancelLoadMutation();

  const {
    formState: { errors: transactioErrors, dirtyFields: transactionDirtyFields },
    control: transactioControl,
    reset: resetTransaction,
    watch: transactionWatch,
    setValue: transactionSetValue,
    trigger: transactionTrigger,
  } = useForm<ITransactionFormData>({
    defaultValues: transactionDefalultValue,
    mode: 'all',
    resolver: yupResolver(transactionValidation(data?.load?.loadType)),
  });
  const drawerRef: { current: any } = useRef(null);

  const [isDefaultHistory, setIsDefaultHistory] = useState(false);
  const handleScrollToHistory = (mode: string) => {
    scrollToTarget(drawerRef);
    setIsDefaultHistory(mode === 'history');
  };

  const customer = transactionWatch('customer');
  const carrier = transactionWatch('carrierTransaction');
  const secondAgent = transactionWatch('secondAgent');

  const amount = handleBalanceAmount(Number(customer), Number(carrier));

  const isTransactionValidBtn =
    !!(customer || carrier || secondAgent) &&
    !!transactionWatch('transactionType') &&
    !Object.keys(transactioErrors).length;

  useEffect(() => {
    if (amount) {
      transactionSetValue('balanceAmount', formatNumberWithThousandsSeparatorAndDecimal(amount));
    } else {
      transactionSetValue('balanceAmount', 0);
    }
  }, [customer, carrier]);

  const handleResetTransactionForm = () => {
    resetTransaction();
  };

  const {
    handleViewFileEdit: handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    beforeUploadForCreate,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
  } = useFileUpload({
    data: {
      result: { files: loadFiles?.result?.RED_CONFIRMATION },
    },
    setValue: createLoadForm.setValue,
    getValues: createLoadForm.getValues,
    id: data?.load?.id,
    addNewFile: addRedConfirmationFileToLoad,
    deleteFile: deleteFileFromLoad,
    trigger: createLoadForm.trigger,
    autoUpdate: true,
  });

  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: data?.load?.takenAsAuthority?.id ? [data?.load?.takenAsAuthority?.id] : [],
  });
  const { data: authorities = [] } = useGetAuthoritiesShortQuery(authoritiesState, {
    skip: mode !== 'edit' || open === 'false' || Number(createLoadForm.watch('load_type')) === LoadTypes['Internal'],
  });
  ////

  const [carriersState, setCarriersState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
  });

  const { data: carriers = [] as any } = useGetCarriersAutocompleteQuery(carriersState, {
    skip: mode !== 'edit' || open === 'false' || Number(createLoadForm.watch('load_type')) !== LoadTypes.Regular,
  });
  ////

  const [agentsState, setAgentsState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });
  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    { ...agentsState },
    {
      skip: mode !== 'edit' || open === 'false' || Number(createLoadForm.watch('load_type')) !== LoadTypes.Connecting,
    }
  );
  ////

  const [trucksState, setTrucksState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    search: '',
    limit: 10,
  });

  const { data: trucks } = useGetTrucksAutocompleteQuery(
    { carrierId: createLoadForm.watch('carrier')?.[0]?.value, ...trucksState },
    { skip: !createLoadForm.watch('carrier').length }
  );

  useEffect(() => {
    setAllFiles(savedFiles);
  }, [savedFiles]);

  useEffect(() => {
    createLoadForm.reset(
      {
        load_type: data?.load?.loadType ? data?.load?.loadType : '',
        customerName: data?.load?.customer?.name || '',
        referenceNumber: data?.load?.referenceNumber,
        customerAmount: data?.load?.customerAmount || '',
        customerContact: data?.load?.customerContact || '',
        customerContactName: data?.load?.customerContactName || '',
        carrier: data?.load?.carrier?.id ? [data?.load?.carrier] : [],
        carrierAmount: Number(data?.load?.carrierAmount) ? data?.load?.carrierAmount : '',
        truck: data?.load?.truck ? [data?.load?.truck] : [],
        agent: [],
        agentAmount: '',
        authorities: data?.load?.soldAsAuthority?.id ? [data?.load?.soldAsAuthority] : [],
        file: [],
        soldUsSwitch: Boolean(data?.load?.soldAsAuthority?.id),
        markAsSwitch: Boolean(data?.load?.claimed),
        transportType: data?.information?.transportCapability || null,
        celsius: data?.information?.refrigeratorCelsius || '',
        takenAs: data?.information?.takenType || null,
        givenAs: data?.information?.givenType || null,
        commodity: data?.information?.commodity || '',
        bookedAs: data?.information?.bookedType || null,
        soldAs: data?.information?.soldType || null,
        feetPartial: data?.information?.feetOfPartial ? data?.information?.feetOfPartial : '',
        weight: data?.information?.weight || '',
        pickups: loadPoints?.result?.pickup || [],
        dropOffs: loadPoints?.result?.dropoffs || [],
        comment: '',
      },
      { keepDirty: false }
    );
  }, [data]);

  useEffect(() => {
    createLoadForm.setValue('pickups', loadPoints?.result?.pickup || [], { keepDirty: false, keepTouched: false });
    createLoadForm.setValue('dropOffs', loadPoints?.result?.dropoffs || [], { keepDirty: false, keepTouched: false });
  }, [loadPoints?.result?.pickup?.length, loadPoints?.result?.dropoffs?.length]);

  const handleSwitchSoldUs = () => {
    createLoadForm.setValue('soldUsSwitch', !createLoadForm.watch('soldUsSwitch'), {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    if (!createLoadForm.watch('soldUsSwitch')) {
      createLoadForm.setValue('authorities', []);
    }
  };

  const handleCancelLoad = (id: string | number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_LOAD,
        okText: SUBJECT.CANCEL,
        cancelText: SUBJECT.NO,
        okButtonVariant: 'danger',
        cancelButtonVariant: 'gray',
        onOk: () => {
          cancelLoad(Number(id)).then((data: any) => {
            if (data.error) {
              dispatch(closeModal());
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully canceled',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              refetchLoadById({ id: data?.load?.id });
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleSave = () => {
    handleSaveModal();
  };
  const handleSaveModal = () => {
    dispatch(
      openModal({
        title: SUBJECT.JUST_ARE_YOU_SURE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        onOk: () => {
          editLoad({
            id: Number(data.load.id!),
            referenceNumber: String(createLoadForm.watch('referenceNumber')),
            loadType: createLoadForm.watch('load_type'),
            ...(!(Number(createLoadForm.watch('load_type')) === LoadTypes['Internal'])
              ? { soldAuthority: createLoadForm.watch('authorities') }
              : {}),
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully edited',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              createLoadForm.resetField('authorities', { defaultValue: createLoadForm.watch('authorities') });
              createLoadForm.resetField('referenceNumber', { defaultValue: createLoadForm.watch('referenceNumber') });
              createLoadForm.resetField('soldUsSwitch', { defaultValue: createLoadForm.watch('soldUsSwitch') });
              refetchLoadById();
            }
            dispatch(changeLoading(false));
            dispatch(closeModal());
            setSearchParams({
              ...searchParams,
              open: 'false',
            });
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleCancel = () => {
    createLoadForm.reset({
      ...createLoadForm.watch(),
      load_type: data?.load?.loadType,
      referenceNumber: data?.load?.referenceNumber,
    });
  };

  const handleCreateTransaction = () => {
    dispatch(changeLoading(true));
    createTransaction({
      loadId: data?.load?.id,
      body: transactionWatch(),
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        resetTransaction();
        refetchTransaction();
        refetchFilesByLoadId({ id: data?.load?.id });
        dispatch(changeLoading(false));
        dispatch(billingAPI.util.invalidateTags(['Billing']));
      }
    });
  };

  const handleDeleteTransaction = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          deleteTransaction({ id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchLoadById();
              refetchTransaction();
              dispatch(changeLoading(false));
              refetchFilesByLoadId({ id: data?.load?.id });
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const isLoadInfoSaveButtonActive = useMemo(() => {
    if (Number(createLoadForm.watch('load_type')) == LoadTypes['Internal']) {
      return (
        Object.keys(createLoadForm.dirtyFields).includes('bookedAs') ||
        Object.keys(createLoadForm.dirtyFields).includes('givenAs') ||
        Object.keys(createLoadForm.dirtyFields).includes('soldAs') ||
        Object.keys(createLoadForm.dirtyFields).includes('takenAs')
      );
    } else {
      return false;
    }
  }, [Object.keys(createLoadForm.dirtyFields)?.length]);

  const handleCancelLoadinfo = () => {
    createLoadForm.resetField('takenAs', { defaultValue: data?.information?.takenType || null });
    createLoadForm.resetField('givenAs', { defaultValue: data?.information?.givenType || null });
    createLoadForm.resetField('soldAs', { defaultValue: data?.information?.soldType || null });
    createLoadForm.resetField('bookedAs', { defaultValue: data?.information?.bookedType || null });
  };

  const handleSaveLoadinfo = () => {
    editInternalInfoLoad({
      id: Number(data.load.id!),
      transportType: createLoadForm.watch('transportType'),
      takenAs: createLoadForm.watch('takenAs'),
      givenAs: createLoadForm.watch('givenAs'),
      soldAs: createLoadForm.watch('soldAs'),
      bookedAs: createLoadForm.watch('bookedAs'),
      weight: createLoadForm.watch('weight'),
      commodity: createLoadForm.watch('commodity'),
      celsius: createLoadForm.watch('celsius'),
      feetOfPartial: createLoadForm.watch('feetPartial'),
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully edited',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        handleCancelLoadinfo();
        refetchLoadById();
      }
    });
  };

  const isSaveButtonVisible = useMemo(() => {
    if (Number(createLoadForm.watch('load_type')) === LoadTypes['Internal']) {
      return createLoadForm.watch('referenceNumber') !== data?.load?.referenceNumber;
    } else {
      Object.keys(createLoadForm.dirtyFields).includes('load_type');
    }
  }, [
    createLoadForm.watch('referenceNumber'),
    data?.load?.referenceNumber,
    createLoadForm.watch('load_type'),
    Object.keys(createLoadForm.dirtyFields)?.length,
  ]);

  const addTransactionCheck = () => {
    if (
      userType === UserTypes.ADMIN ||
      (employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.transaction)
    ) {
      return false;
    } else {
      if (
        (data?.load?.customerStatus === 2 && !data?.load?.processing) ||
        (data?.load?.carrierStatus === 2 && !data?.load?.processing)
      ) {
        return true;
      }
    }
  };

  return {
    handleSwitchSoldUs,
    control: createLoadForm.control,
    watch: createLoadForm.watch,
    setValue: createLoadForm.setValue,
    errors: createLoadForm.errors,
    activeSection,
    handleCancel,
    handleSave,
    handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    beforeUploadForCreate,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
    transactionWatch,
    transactioControl,
    transactioErrors,
    transactionTrigger,
    transactionSetValue,
    handleCreateTransaction,
    handleDeleteTransaction,
    addTransactionCheck,
    handleCancelLoad,
    isLoadInfoSaveButtonActive,
    handleSaveLoadinfo,
    handleCancelLoadinfo,
    dirtyFields: createLoadForm.dirtyFields,
    isSaveButtonVisible,
    authorities,
    setAuthoritiesState,
    carriers,
    setCarriersState,
    agents: agentsOptions,
    setAgentsState,
    trucks,
    setTrucksState,
    trigger: createLoadForm.trigger,
    employeeLevel,
    userType,
    transactionDirtyFields,
    handleResetTransactionForm,
    isTransactionValidBtn,
    isCreateTransactionLoading,
    drawerRef,
    userId,
    setIsDefaultHistory,
    handleScrollToHistory,
    isDefaultHistory,
    roleLevel,
  };
};
