import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { SLIDER_IMAGES } from './constants/constants';

import styles from './Slide.module.scss';

const Slider = () => {
  const [imageHeight, setImageHeight] = useState(0);

  const handleImageLoad = () => {
    const imageItem = document.getElementById('image_item');

    if (imageItem) {
      const height = imageItem.clientHeight;
      if (!imageHeight) {
        setImageHeight(height);
      }
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--scrollArea', `-${imageHeight * 6 + 180}px`);
  }, [imageHeight]);

  return (
    <div className={styles.slider}>
      <div
        className={clsx(styles.slideTrack, {
          [styles.ready]: !!imageHeight,
        })}
        id="sliderTrack"
      >
        {SLIDER_IMAGES?.map(({ IMG_FIRST, IMG_SECOND, IMG_DESCR_FIRST, IMG_DESCR_SECOND, ID }) => (
          <div className={styles.slide} key={ID}>
            <img id="image_item" src={IMG_FIRST} alt={IMG_DESCR_FIRST} onLoad={handleImageLoad} />
            <img src={IMG_SECOND} alt={IMG_DESCR_SECOND} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
