import { useSelector } from 'react-redux';
import { useHandleCopyActive } from 'hooks/useCopy';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { selectUserInfo } from 'store/user-slice/selector';

const useNewLoadsView = () => {
  const userInfo = useSelector(selectUserInfo);
  const { type: userType, id: userId } = userInfo;

  const { searchParams, setSearchParams } = useDetectedParams();
  const { id } = searchParams;

  const { copiedText, handleCopy } = useHandleCopyActive();

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: 'create',
    });
  };

  const handleCommentClick = () => {
    setSearchParams({
      ...searchParams,
      mode: 'newLoadComment',
      open: 'true',
      id,
    });
  };

  const handleRedirectToLoad = () => {
    setSearchParams({
      tab: 'loads',
      open: 'true',
      mode: 'view',
      id,
    });
  };

  return {
    handleChangeMode,
    status,
    handleCommentClick,
    copiedText,
    handleCopy,
    userType,
    userId,
    handleRedirectToLoad,
  };
};

export default useNewLoadsView;
