import React from 'react';
import { Button as AntButton } from 'antd';
import clsx from 'clsx';

import { IButtonProps } from './constants/types';

import styles from './Button.module.scss';

const Button = ({
  type = 'primary',
  variant = 'primary',
  onClick,
  size = 'middle',
  shape = 'default',
  htmlType = 'button',
  children,
  className,
  ...props
}: IButtonProps) => {
  return (
    <div
      className={clsx(styles.button, styles[variant], styles[size], {
        [styles[`${className}`]]: className,
      })}
    >
      <AntButton type={type} onClick={onClick} size={size} shape={shape} htmlType={htmlType} {...props}>
        {children}
      </AntButton>
    </div>
  );
};

export default Button;
