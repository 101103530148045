export enum SUBJECT {
  UP = 'up',
  DOWN = 'down',
  HOUR = 'hour',
  MINUTE = 'minute',
  OK = 'Ok',
  CANCEL = 'Cancel',
  RESET_ALL = 'Reset All',
  RESET = 'Reset',
  ZEROES = '00',
  AM = 'AM',
  PM = 'PM',
  AM_PM = 'amPm',
  DASHES = '--',
  TIME = 'time',
}

export const TWELVE = 12;
export const FIFTY_NINE = 59;
export const isHoursValid = /^(1[012]|[0-9])$/;
