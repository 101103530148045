import { Col, Divider, Row } from 'antd';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import {
  floatNumberFormatter,
  floatNumberFormatterWithMinus,
  formatNumberWithThousandsSeparatorAndDecimal,
} from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';

import { ITransactionTypes } from '../../../../../loads/components/edit-load/constants/constants';

import { SUBJECT, TRANSACTION_TYPE_OPTIONS } from './constants/constants';
import { Input, Select } from './constants/instance';
import { useRowContent } from './useRowContent';

import styles from './RowContent.module.scss';

export const RowContent = ({ setExpandedRowKeys, data }: any) => {
  const {
    trigger,
    control,
    isTransactionValidBtn,
    errors,
    loadTransactions,
    watch,
    handleCancel,
    handleSubmit,
    onSubmit,
    setValue,
    isCreateTransactionLoading,
  } = useRowContent(setExpandedRowKeys, data?.id);

  return (
    <form className={styles.expandedRowContent} onSubmit={handleSubmit(onSubmit)}>
      {!data?.claimed && (
        <>
          <Row align="top" gutter={26}>
            <Col span={6}>
              <Select
                name="transactionType"
                expandedId={data?.id}
                control={control}
                rules={{ required: true }}
                errors={errors}
                onBlur={() =>
                  setTimeout(() => {
                    trigger('transactionType');
                    if (watch('customer')?.length) {
                      trigger('customer');
                    }
                  }, 500)
                }
                options={TRANSACTION_TYPE_OPTIONS}
                required
                suffixIcon={<SelectArrowIcon />}
                placeholder={SUBJECT.TRANSACTION_TYPE}
              />
            </Col>
            <Col span={7}>
              <Input
                name="customer"
                control={control}
                rules={{ required: true }}
                getValues={watch}
                errors={errors}
                onChange={e => {
                  setValue(
                    'customer',
                    Number(watch('transactionType')) === ITransactionTypes.Lumper ||
                      Number(watch('transactionType')) === ITransactionTypes.Miscellaneous
                      ? floatNumberFormatter(e.target.value)
                      : floatNumberFormatterWithMinus(e.target.value),
                    {
                      shouldDirty: true,
                      shouldValidate: true,
                    }
                  );
                }}
                placeholder={SUBJECT.CUSTOMER_AMOUNT}
                required
                value={watch('customer') ? formatNumberWithThousandsSeparatorAndDecimal(watch('customer')) : ''}
                suffix={<DollarIcon />}
                withRedMinus
              />
            </Col>
            <Col span={11}>
              <Input
                placeholder={SUBJECT.WRITE_COMMENT}
                name="transactionComment"
                control={control}
                errors={errors}
                getValues={watch}
              />
            </Col>
          </Row>
          <Row justify="end" align="top" gutter={7} className={styles.actions}>
            <Col>
              <Button variant="outlined" children={SUBJECT.CANCEL} onClick={handleCancel} />
            </Col>
            <Col>
              <Button
                variant="comment"
                children={SUBJECT.ADD_TRANSACTION}
                disabled={!isTransactionValidBtn || isCreateTransactionLoading}
                htmlType="submit"
              />
            </Col>
          </Row>
          <Divider />
        </>
      )}

      {data && !!loadTransactions?.result?.length && (
        <>
          <Row align="middle" gutter={25}>
            <Col span={5}>
              <Typography variant="heading" children={SUBJECT.TRANSACTION_TYPE} className={styles.heading} />
            </Col>
            <Col span={5}>
              <Typography variant="heading" children={SUBJECT.CUSTOMER_AMOUNT} className={styles.heading} />
            </Col>
            <Col span={14}>
              <Typography variant="heading" children={SUBJECT.COMMENT} className={styles.heading} />
            </Col>
          </Row>
          {loadTransactions?.result?.map((item: any) => {
            return (
              <Row align="middle" gutter={25} className={styles.rows} key={item?.id}>
                <Col span={5}>
                  <Typography variant="paragraph" children={item?.type} className={styles.paragraph} />
                </Col>
                <Col span={5}>
                  <Typography
                    variant="paragraph"
                    children={`${formatNumberWithThousandsSeparatorAndDecimal(item?.customerAmout, true)}`}
                    className={styles.paragraph}
                  />
                </Col>
                <Col span={14}>
                  <u className={styles.paragraph}>{item?.comment}</u>
                </Col>
              </Row>
            );
          })}
        </>
      )}
      {data?.claimed && (
        <Row justify="end" align="top" gutter={7} className={styles.actions}>
          <Col>
            <Button variant="outlined" children={SUBJECT.CANCEL} onClick={handleCancel} />
          </Col>
        </Row>
      )}
    </form>
  );
};
