import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';

import CustomComment from 'components/custom-comment/CustomComment';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import { ICommentsView } from './constants/types';
import { useCommentsView } from './useCommentsVIew';

import styles from './CommentsView.module.scss';

const CommentsView = ({
  creditRequest,
  loadsComments,
  createComment,
  deleteComment,
  getMoreComments,
  isShowMoreVisible,
  isLoading,
}: ICommentsView) => {
  const { watch, setValue, control } = useCommentsView();

  return (
    <>
      <>
        <MainLoader isFetching={isLoading} />

        <ViewItemWrapper title={SUBJECT.CREDIT_REQUEST}>
          <Row gutter={[10, 20]}>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.LOAD_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <div className={styles.textItem}>
                    <Typography variant="paragraph" className="top-banner-title">
                      {SUBJECT.LOAD_NUMBER}:
                    </Typography>
                    <Typography variant="paragraph" className="top-banner-value">
                      {creditRequest?.code}
                    </Typography>
                  </div>
                </Col>
                <Col span={24}>
                  <div className={styles.textItem}>
                    <Typography variant="paragraph" className="top-banner-title">
                      {SUBJECT.OFFICE_NAME}:
                    </Typography>
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={creditRequest?.officeName}
                      subject={SUBJECT.OFFICE_NAME}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className={styles.textItem}>
                    <Typography variant="paragraph" className="top-banner-title">
                      {SUBJECT.BRANCH_NAME}:
                    </Typography>
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={creditRequest?.branchName}
                      subject={SUBJECT.BRANCH_NAME}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className={styles.textItem}>
                    <Typography variant="paragraph" className="top-banner-title">
                      {SUBJECT.REG_DATE_TIME}:
                    </Typography>
                    <Typography variant="paragraph" className="top-banner-value">
                      {formatDate(creditRequest?.addedLoadAt)}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.CUSTOMER_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <div className={styles.textItem}>
                    <Typography variant="paragraph" className="top-banner-title">
                      {SUBJECT.CUSTOMER_NAME}:
                    </Typography>
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={creditRequest?.customerName}
                      subject={SUBJECT.CUSTOMER_NAME}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.AGENT_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <div className={styles.textItem}>
                    <Typography variant="paragraph" className="top-banner-title">
                      {SUBJECT.AGENT_NAME}:
                    </Typography>
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={creditRequest?.agentName}
                      subject={SUBJECT.AGENT_NAME}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className={styles.textItem}>
                    <Typography variant="paragraph" className="top-banner-title">
                      {SUBJECT.AGENT_PHONE}:
                    </Typography>
                    <Link className="top-banner-value" to={`tel:${creditRequest?.agentPhone}`}>
                      {creditRequest?.agentPhone}
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </ViewItemWrapper>
      </>
      <CustomComment
        id="comment"
        watch={watch}
        name="comment"
        setValue={setValue}
        control={control}
        comments={loadsComments}
        createComment={createComment}
        deleteComment={deleteComment}
        getMoreComments={getMoreComments}
        isShowMoreVisible={isShowMoreVisible}
      />
    </>
  );
};

export default CommentsView;
