import clsx from 'clsx';

import Button from '../../ui/button/Button';
import { ColumnsIcon } from '../svgs/ColumnsIcon';

import { SUBJECT } from './constants/constants';
import { ICustomizeColumnButton } from './constants/types';

import styles from './CustomizeColumn.module.scss';

const CustomizeColumnButton = ({ onClick, isButtonVisible, ...props }: ICustomizeColumnButton) => {
  return (
    <div className={clsx({ [styles.disabled]: !isButtonVisible })}>
      <Button
        icon={<ColumnsIcon />}
        children={SUBJECT.CUSTOMIZE}
        onClick={(
          e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => isButtonVisible && onClick(e)}
        size="small"
        variant="customize"
        {...props}
      />
    </div>
  );
};

export default CustomizeColumnButton;
