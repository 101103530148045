export enum SUBJECT {
  LEGIBLE = 'Legible / not leg.',
  FILE_NAME = 'File name',
  ADDED_BY = 'Added by',
  DATE_AND_TIME = 'Date and time',
  EMAIL = 'E-mail',
  PROCESSING_RULE = 'Action cannot be completed due to current load status or your permissions.',
}

export const LOAD_DOCUMENT_OPTIONS = [
  { label: 'POD', value: 'POD' },
  { label: 'Lumper receipt', value: 'Lumper_receipt' },
  { label: 'Late fee receipt', value: 'Late_fee_receipt' },
  { label: 'Breakdown receipt', value: 'Breakdown_receipt' },
  { label: 'Carrier invoice', value: 'Carrier_invoice' },
];

export const LEGIBLE_SELECT_OPTIONS = [
  { title: 'Legible', value: '1' },
  { title: 'Not legible', value: '2' },
];

export const EMAIL_TYPE_OPTIONS = [
  { title: 'Don’t E-Mail', value: '0' },
  { title: 'Send E-Mail', value: '1' },
];

export const defaultFileType = 'UNKNOWN';
