import { MenuProps } from 'antd';

export const handleMenuItems = ({ hasEditPermission, hasDeletePermission, withDelete }: any) => {
  const menuItems: MenuProps['items'] = [];
  if (hasEditPermission) {
    menuItems.push({
      label: 'Edit',
      key: '1',
    });
  }
  if (withDelete && hasDeletePermission) {
    menuItems.push({
      label: 'Delete',
      key: '2',
    });
  }
  return menuItems;
};
