import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import Input from 'ui/inputs/input/Input';
import { IMonthPickerProps } from 'ui/inputs/month-picker/constants/types';
import CustomMonthPicker from 'ui/inputs/month-picker/MonthPicker';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import Select from 'ui/inputs/select/Select';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { IPayoutFilter } from './../constants/types';

export const FilterSelect = withController<ISelectProps, IPayoutFilter>(Select);
export const FilterInput = withController<IInputProps, any>(Input);
export const SearchableDropdown = withController<ISearchableDropdownProps, any>(CustomSearchableDropdown);
export const MonthPicker = withController<IMonthPickerProps, IPayoutFilter>(CustomMonthPicker);
