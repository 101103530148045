import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, notification, Row } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useDeleteCarrierBlackListMutation } from 'services/profile/carriers/carriers';
import { closeModal, openModal } from 'store/modal-slice/modals';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { notificationKey } from 'utils/constants';
import { formatDate } from 'utils/dates';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';

import styles from './BlackListInfo.module.scss';

const BlackListInfo = ({ data, isFetchingBlackListById, blackListPagePermissions: { unlock } }: any) => {
  const { searchParams, setSearchParams } = useDetectedParams();

  const [deleteCarrierBlackList] = useDeleteCarrierBlackListMutation();
  const dispatch = useDispatch();
  const handleCarrierUnlockClick = (rowId: string) => {
    dispatch(
      openModal({
        title: 'Are you sure want to Unlock?',
        okText: 'Unlock',
        cancelText: 'Cancel',
        onOk: () => {
          deleteCarrierBlackList({ groupId: rowId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully unlocked',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              setSearchParams({
                ...searchParams,
                open: 'false',
                mode: 'view',
                id: '',
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  return (
    <>
      <MainLoader isFetching={isFetchingBlackListById} />

      <ViewItemWrapper
        title={SUBJECT.BLACK_LIST_INFO}
        Component={
          unlock && (
            <Button
              children={SUBJECT.UNLOCK}
              size="large"
              onClick={() => handleCarrierUnlockClick(data?.result?.groupId)}
              className="viewDrawerEditBtn"
            />
          )
        }
      >
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.result?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={`${data?.result?.creator.username}`}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CARRIER_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.result?.carrier?.contactName}
                  subject={`${SUBJECT.BLACK_LIST_INFO}${SUBJECT.CARRIER_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.OFFICE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={data?.result?.offices?.map(
                    (el: any, index: number) =>
                      `${el.name}${
                        data?.result?.offices.length > 1 && index !== data?.result?.offices.length - 1 ? ', ' : ''
                      }`
                  )}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.BRANCH} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={
                    data?.result?.allBranch
                      ? 'All'
                      : data?.result?.branches?.map(
                          (el: any, index: number) =>
                            `${el.name}${
                              data?.result?.branches.length > 1 && index !== data?.result?.branches.length - 1
                                ? ', '
                                : ''
                            }`
                        )
                  }
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AGENT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={
                    data?.result?.allAgent
                      ? 'All'
                      : data?.result?.agents?.map(
                          (el: any, index: number) =>
                            `${el.username}${
                              data?.result?.agents.length > 1 && index !== data?.result?.agents.length - 1 ? ', ' : ''
                            }`
                        )
                  }
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </>
  );
};

export default BlackListInfo;
