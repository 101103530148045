import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import { emailFormatter, formatPhone, nonAlphanumericSpaceFormatter, onlyNumbersFormatter } from 'utils/helpers';

import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { setIsValid } from '../../../../../../store/check-is-dirty-slice/isValid';
import { AUTHORITIES_TYPE_OPTIONS, SUBJECT } from '../../constants/constants';
import { AuthorityTypes } from '../../constants/types';
import { Input, Switch, TextArea } from '../../hoc-instance/instance';
import FileItems from '../file-item/FileItems';

import { ICreateAuthorityProps } from './constants/types';

import styles from './CreateAuthority.module.scss';

const CreateAuthority = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  getValues,
  errors,
  mode,
  statesData,
  beforeUpload,
  allFiles,
  authorityId,
  watch,
  handleDeleteAuthorityFile,
  handleDownloadAuthorityFileEdit,
  handleDownloadAuthorityFile,
  handleViewAuthorityFileEdit,
  handleViewAuthorityFileCreate,
  setValue,
  isCreateLoading,
  templates,
}: ICreateAuthorityProps) => {
  const dispatch = useDispatch();

  const image = templates?.result?.find((item: any) => item.value === watch('templateId'));

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isCreateLoading }));
  }, [isCreateLoading]);

  return (
    <div className={styles.formWrapper}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={clsx(styles.authorities, {
          [styles.editMode]: mode === 'edit',
        })}
      >
        <ViewItemWrapper title={mode === 'add' ? SUBJECT.ADD_AUTHORITIES : SUBJECT.EDIT_AUTHORITIES}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Input
                name="name"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.AUTHORITY_NAME}
                getValues={getValues}
                errors={errors}
                required
                onChange={(e: any) =>
                  setValue('name', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                }
              />
            </Col>
            <Col span={8}>
              <Controller
                name="authority_type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      suffixIcon={<SelectArrowIcon />}
                      options={AUTHORITIES_TYPE_OPTIONS}
                      placeholder={SUBJECT.AUTHORITIES_TYPE}
                      key="value"
                      required
                      errors={errors}
                      onBlur={() => trigger(field.name)}
                      onChange={(value: string | string[]) => field.onChange(value)}
                      value={field.value}
                      name={field.name}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.authority_type} />
            </Col>
            <Col span={8}>
              <Input
                name="mc"
                type="number"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.MC_NUMBER}
                getValues={getValues}
                errors={errors}
                required
              />
            </Col>
          </Row>
          <Row gutter={[18, 40]} className={styles.rowsGap}>
            <Col span={8}>
              <Input
                name="dot"
                type="text"
                control={control}
                rules={{ required: true }}
                onChange={(e: any) =>
                  setValue('dot', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.DOT}
                getValues={getValues}
                errors={errors}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="ffNumber"
                type="text"
                control={control}
                placeholder={SUBJECT.FF_NUMBER}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => {
                  setValue('ffNumber', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
            <Col span={8}>
              <Input
                name="stateNumber"
                type="number"
                control={control}
                placeholder={SUBJECT.STATE_NUMBER}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => {
                  setValue('stateNumber', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
            <Col span={24}>
              <Row gutter={18}>
                <Col span={8}>
                  <Input
                    name="phone"
                    type="text"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.PHONE_NUMBER}
                    getValues={watch}
                    onChange={(e: any) =>
                      setValue('phone', formatPhone(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    errors={errors}
                    required
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="fax"
                    type="text"
                    control={control}
                    placeholder={SUBJECT.FAX}
                    getValues={getValues}
                    onChange={(e: any) =>
                      setValue('fax', formatPhone(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    errors={errors}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="email"
                    type="text"
                    control={control}
                    placeholder={SUBJECT.EMAIL}
                    getValues={getValues}
                    onChange={(e: any) =>
                      setValue('email', emailFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    errors={errors}
                    rules={{ required: true }}
                    required
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Input
                name="address"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.ADDRESS}
                getValues={getValues}
                errors={errors}
                required
                onChange={(e: any) =>
                  setValue('address', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                }
              />
            </Col>
            <Col span={12}>
              <Input
                name="secondAddress"
                type="text"
                control={control}
                placeholder={SUBJECT.SECOND_ADDRESS}
                getValues={getValues}
                errors={errors}
                onChange={(e: any) =>
                  setValue('secondAddress', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                }
              />
            </Col>
            <Col span={8}>
              <Input
                name="city"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.CITY}
                getValues={getValues}
                errors={errors}
                required
                onChange={(e: any) =>
                  setValue('city', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                }
              />
            </Col>
            <Col span={8}>
              <Controller
                name="stateId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <StateSelect
                      options={statesData}
                      placeholder={SUBJECT.STATE}
                      name={field.name}
                      errors={errors}
                      onBlur={() => trigger('stateId')}
                      onChange={(value: string) =>
                        setValue('stateId', value, {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      value={field.value}
                      required
                      allowClear
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.stateId} />
            </Col>
            <Col span={8}>
              <Input
                name="zip"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.ZIP_CODE}
                getValues={getValues}
                onChange={(e: any) =>
                  setValue('zip', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                errors={errors}
                required
              />
            </Col>
          </Row>
          <Row align="middle" className={styles.switchSection}>
            <Col span={5}>
              <Typography variant="heading" children={SUBJECT.PHYSICAL_ADDRESS} className={styles.switchHeading} />
            </Col>
            <Col>
              <Switch
                name="physical"
                control={control}
                checked={Boolean(watch('physical'))}
                onChange={() =>
                  setValue('physical', !Number(watch('physical')), { shouldDirty: true, shouldValidate: true })
                }
              />
            </Col>
          </Row>
          {!!watch('physical') && (
            <Row gutter={[18, 40]} className={styles.rowsGap}>
              <Col span={12}>
                <Input
                  name="physicalAddress"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.ADDRESS}
                  getValues={getValues}
                  errors={errors}
                  required
                  onChange={(e: any) =>
                    setValue('physicalAddress', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                  }
                />
              </Col>
              <Col span={12}>
                <Input
                  name="physicalSecondAddress"
                  type="text"
                  control={control}
                  placeholder={SUBJECT.SECOND_ADDRESS}
                  getValues={getValues}
                  errors={errors}
                  onChange={(e: any) =>
                    setValue('physicalSecondAddress', e.target.value.trimStart(), {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                />
              </Col>
              <Col span={8}>
                <Input
                  name="physicalCity"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.CITY}
                  getValues={getValues}
                  errors={errors}
                  required
                  onChange={(e: any) =>
                    setValue('physicalCity', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                  }
                />
              </Col>
              <Col span={8}>
                <Controller
                  name="physicalStateId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <StateSelect
                        options={statesData}
                        placeholder={SUBJECT.STATE}
                        name={field.name}
                        errors={errors}
                        onBlur={() => trigger('physicalStateId')}
                        onChange={(value: string) =>
                          setValue('physicalStateId', value, {
                            shouldDirty: true,
                            shouldValidate: true,
                          })
                        }
                        value={field.value}
                        required
                        allowClear
                      />
                    );
                  }}
                />
                <ErrorMessage fieldError={errors?.physicalStateId} />
              </Col>
              <Col span={8}>
                <Input
                  name="physicalZip"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.ZIP_CODE}
                  getValues={getValues}
                  onChange={(e: any) =>
                    setValue('physicalZip', onlyNumbersFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  errors={errors}
                  required
                />
              </Col>
            </Row>
          )}

          <Typography variant="heading" children={SUBJECT.UPLOAD_NOA} className={styles.uploadHeading} />
          <Row gutter={[23, 45]}>
            <Col span={12}>
              <Upload
                uploadText={SUBJECT.UPLOAD_TEXT}
                className={styles.upload}
                beforeUpload={(e: any, setLoading: any) => beforeUpload(e, 'files', setLoading)}
              />
            </Col>
            <Col span={12}>
              <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT} className={styles.allowedFormat} />
            </Col>
            <Col span={24}>
              <Controller
                control={control}
                render={({ field: { value: _value } }) => (
                  <FileItems
                    handleDownloadFileEdit={handleDownloadAuthorityFileEdit}
                    handleDownloadFile={handleDownloadAuthorityFile}
                    handleViewFileEdit={handleViewAuthorityFileEdit}
                    handleViewFileCreate={handleViewAuthorityFileCreate}
                    itemId={authorityId}
                    onDeleteClick={(e: any) => handleDeleteAuthorityFile(e, 'files')}
                    files={allFiles['files']}
                  />
                )}
                name="files"
              />
              <ErrorMessage fieldError={errors?.files} />
            </Col>
          </Row>
          <Typography variant="heading" children={SUBJECT.UPLOAD_PACKAGE} className={styles.uploadHeading} />
          <Row gutter={[23, 45]}>
            <Col span={12}>
              <Upload
                uploadText={SUBJECT.UPLOAD_TEXT}
                className={styles.upload}
                beforeUpload={(e: any, setLoading: any) => beforeUpload(e, 'packet', setLoading)}
              />
            </Col>
            <Col span={12}>
              <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT} className={styles.allowedFormat} />
            </Col>
            <Col span={24}>
              <Controller
                control={control}
                render={({ field: { value: _value } }) => (
                  <FileItems
                    handleDownloadFileEdit={handleDownloadAuthorityFileEdit}
                    handleDownloadFile={handleDownloadAuthorityFile}
                    handleViewFileEdit={handleViewAuthorityFileEdit}
                    handleViewFileCreate={handleViewAuthorityFileCreate}
                    itemId={authorityId}
                    onDeleteClick={(e: any) => handleDeleteAuthorityFile(e, 'packet')}
                    files={allFiles['packet']}
                  />
                )}
                name="packet"
              />
              <ErrorMessage fieldError={errors.packet} />
            </Col>
          </Row>
        </ViewItemWrapper>
        <ViewItemWrapper title={SUBJECT.BANK_INFORMATION}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Input
                name="bankName"
                type="text"
                control={control}
                placeholder={SUBJECT.BANK_NAME}
                getValues={getValues}
                onChange={(e: any) => {
                  setValue('bankName', nonAlphanumericSpaceFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="accounting_number"
                type="text"
                control={control}
                placeholder={SUBJECT.ACCOUNTING_NUMBER}
                getValues={getValues}
                onChange={(e: any) => {
                  setValue('accounting_number', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="ach_routing_number"
                type="number"
                control={control}
                placeholder={SUBJECT.ACH_ROUTING_NUMBER}
                getValues={getValues}
                errors={errors}
                onChange={(e: any) => {
                  setValue('ach_routing_number', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
          </Row>
        </ViewItemWrapper>
        {Number(watch('authority_type')) === AuthorityTypes.Broker && (
          <ViewItemWrapper title={SUBJECT.RATE_CONFIRMATION_TEMPLATE}>
            <Row gutter={[18, 40]}>
              <Col span={8}>
                <Controller
                  name="templateId"
                  control={control}
                  rules={{ required: Number(watch('authority_type')) === AuthorityTypes.Broker }}
                  render={({ field }) => {
                    return (
                      <SimpleSelect
                        suffixIcon={<SelectArrowIcon />}
                        options={templates?.result}
                        placeholder={SUBJECT.TEMPLATE}
                        key="value"
                        required={Number(watch('authority_type')) === AuthorityTypes.Broker}
                        errors={errors}
                        onBlur={() => {
                          trigger(field.name);
                        }}
                        onChange={(value: string | string[]) => field.onChange(value)}
                        value={field.value}
                        name={field.name}
                      />
                    );
                  }}
                />
                <ErrorMessage fieldError={errors?.templateId} />
              </Col>
              {image ? (
                <Col span={16}>
                  <img src={image?.url} alt="" />
                </Col>
              ) : null}
            </Row>
          </ViewItemWrapper>
        )}
        {Number(watch('authority_type')) === AuthorityTypes.Broker && (
          <ViewItemWrapper
            title={SUBJECT.RATE_CONFIRMATION_PRIVACY_POLICY}
            required={Number(watch('authority_type')) === AuthorityTypes.Broker}
          >
            <Row gutter={[18, 40]}>
              <Col span={24}>
                <TextArea
                  isFilled={!!watch('privacyPolicy')}
                  name="privacyPolicy"
                  control={control}
                  autoSize
                  label={SUBJECT.WRITE_TEXT}
                />
              </Col>
            </Row>
          </ViewItemWrapper>
        )}

        {Number(watch('authority_type')) === AuthorityTypes.Broker && (
          <ViewItemWrapper
            title={'Broker Documents'}
            required={Number(watch('authority_type')) === AuthorityTypes.Broker}
          >
            {/*<Typography variant="heading" children={SUBJECT.UPLOAD_NOA} className={styles.uploadHeading} />*/}
            <Typography variant="paragraph" className={styles.uploadTitle}>
              Broker sign agreement *
            </Typography>
            <Row gutter={[23, 45]}>
              <Col span={12}>
                <Upload
                  accept="application/pdf"
                  uploadText={SUBJECT.UPLOAD_TEXT}
                  className={styles.upload}
                  beforeUpload={(e: any, setLoading: any) => beforeUpload(e, 'broker', setLoading)}
                />
              </Col>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT_PDF} className={styles.allowedFormat} />
              </Col>
              <Col span={24}>
                <Controller
                  control={control}
                  render={({ field: { value: _value } }) => (
                    <FileItems
                      handleDownloadFileEdit={handleDownloadAuthorityFileEdit}
                      handleDownloadFile={handleDownloadAuthorityFile}
                      handleViewFileEdit={handleViewAuthorityFileEdit}
                      handleViewFileCreate={handleViewAuthorityFileCreate}
                      itemId={authorityId}
                      onDeleteClick={(e: any) => handleDeleteAuthorityFile(e, 'broker')}
                      files={allFiles['broker']}
                    />
                  )}
                  name="broker"
                />
                <ErrorMessage fieldError={errors?.broker} />
              </Col>
            </Row>
          </ViewItemWrapper>
        )}
      </form>
    </div>
  );
};

export default CreateAuthority;
