import { Col } from 'antd';
import Typography from 'ui/typography/Typography';

import { SUBJECT } from 'components/table/constants/constants';

import styles from './StatusTable.module.scss';

interface IStatusTableProps {
  text?: string;
  backgroundColor?: string;
}

export const StatusTable = ({ text = SUBJECT.MARK_AS_CLAIM, backgroundColor = '#A1B0CC' }: IStatusTableProps) => {
  return (
    <Col className={styles.statusTable} style={{ background: backgroundColor }}>
      <Typography variant="paragraph">{text}</Typography>
    </Col>
  );
};
