import { withController } from 'ui/inputs/hoc/Controller';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import CustomSelect from 'ui/inputs/select/Select';

import MainSearchSelect from 'components/main-layout/components/header/components/MainSearchSelect/MainSearchSelect';

import { IHeaderSelectTypeFilter } from '../constants/types';

export const Select = withController<ISelectProps, IHeaderSelectTypeFilter>(CustomSelect);

export const MainSearchDropdown = withController<any, any>(MainSearchSelect);
