import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useFileUpload from 'hooks/useFileUpload';
import {
  editDefaultValues,
  SUBJECT,
  transactionDefalultValue,
} from 'pages/loads/components/edit-load/constants/constants';
import { ITransactionFormData } from 'pages/loads/components/edit-load/constants/types';
import { transactionValidation } from 'pages/loads/components/edit-load/constants/validation';
import { handleBalanceAmount } from 'pages/loads/constants/helpers';
import { billingAPI } from 'services/factoring/billing/billing';
import { IGetLoadsParams } from 'services/loads/interface';
import {
  useAcceptBillingMutation,
  useAddRedConfirmationFileToLoadMutation,
  useAddUnspecifiedFileToLoadMutation,
  useCancelLoadMutation,
  useClaimLoadMutation,
  useCreateLoadInvoiceMutation,
  useDeleteFileFromLoadMutation,
  useEditLoadFileMutation,
  useEditLoadMutation,
  useGetCarrierBreakdownsInLoadQuery,
  useRejectLoadBillingMutation,
  useRejectLoadRetrievedMutation,
  useSendToBillingMutation,
  useUnclaimLoadMutation,
} from 'services/loads/loads';
import { useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { useCreateTransactionMutation, useDeleteTransactionMutation } from 'services/transactions/transactions';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { CarrierStatus, CustomerStatus, LoadStatus } from 'ui/load-status/constants/types';
import { FileTypes } from 'ui/upload-row/constants/types';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatNumberWithThousandsSeparatorAndDecimal, scrollToTarget } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

export const useRegularLoad = ({
  data,
  createLoadForm,
  loadFiles,
  loadPoints,
  refetchTransaction,
  refetchLoadById,
  isFetching,
  refetchFilesByLoadId,
  isFilesFetching,
  refetchRateConfirmations,
  loadsPagePermissions,
}: any) => {
  const dispatch = useDispatch();

  const activeSection = useSelector(selectActiveSection);
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);
  const drawerRef: { current: any } = useRef(null);
  const [isDefaultHistory, setIsDefaultHistory] = useState(false);
  const handleScrollToHistory = (mode: string) => {
    scrollToTarget(drawerRef);
    setIsDefaultHistory(mode === 'history');
  };
  const isDisableSoldUs = () => {
    if (
      (userType == 3 ||
        (userType == 2 && (employeeLevel == 2 || employeeLevel == 3) && loadsPagePermissions.editSoldAsMc)) &&
      data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING &&
      !data?.load?.rateConfirmationSentAt
    ) {
      return false;
    } else
      return !(
        ((employeeLevel === 1 && loadsPagePermissions.editSoldAsMc) || userType === 1) &&
        data?.load?.carrierStatus !== CarrierStatus.CARRIER_STATUS_PAID &&
        !data?.load?.rateConfirmationSentAt
      );
  };
  const isDisableReference = () => {
    if (
      (userType == 3 ||
        (userType == 2 && (employeeLevel == 2 || employeeLevel == 3) && loadsPagePermissions.editSoldAsMc)) &&
      data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING
    ) {
      return false;
    } else
      return !(
        ((employeeLevel === 1 && loadsPagePermissions.editReferenceNumber) || userType === 1) &&
        data?.load?.customerStatus !== CustomerStatus.CUSTOMER_STATUS_PAID &&
        data?.load?.customerStatus !== CustomerStatus.CUSTOMER_STATUS_DEBT
      );
  };

  const { id: userId } = useSelector(selectUserInfo);
  const { searchParams, setSearchParams } = useDetectedParams();
  const { open, mode, id } = searchParams;

  const [breakdownParams, setBreakdownParams] = useState({
    skip: 0,
    limit: 10,
    order: 2,
    orderBy: 'createdAt',
  });

  const { data: breakdowns, isFetching: isBreakdownLoading } = useGetCarrierBreakdownsInLoadQuery(
    { id: id, ...breakdownParams },
    { skip: !id }
  );

  const handleCarrierBreakdownPaginationClick = (skip: number, limit: number) => {
    setBreakdownParams((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleBreakdownSortClick = (sortOrder: number, dataIndex: string) => {
    setBreakdownParams((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          skip: 0,
          limit: prev.limit,
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const [editLoad] = useEditLoadMutation();
  const [addRedConfirmationFileToLoad] = useAddRedConfirmationFileToLoadMutation();
  const [addUnspecifiedFileToLoad] = useAddUnspecifiedFileToLoadMutation();
  const [deleteFileFromLoad] = useDeleteFileFromLoadMutation();
  const [createTransaction, { isLoading: isCreateTransactionLoading }] = useCreateTransactionMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [editLoadFile] = useEditLoadFileMutation();
  const [sendToBilling] = useSendToBillingMutation();
  const [createInvoice, { isLoading: isInvoiceLoading }] = useCreateLoadInvoiceMutation();
  const [acceptBilling] = useAcceptBillingMutation();
  const [rejectBilling] = useRejectLoadBillingMutation();
  const [rejectLoadRetrieved] = useRejectLoadRetrievedMutation();
  const [cancelLoad] = useCancelLoadMutation();
  const [claimLoad] = useClaimLoadMutation();
  const [unclaimLoad] = useUnclaimLoadMutation();

  const [isSendedToBilling, setIsSendedToBilling] = useState(false);
  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: [],
  });
  const authoritiesRequestType = () => {
    return !(employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN);
  };

  const { data: authorities = [] } = useGetAuthoritiesShortQuery(
    { ...authoritiesState, onlyAssigned: authoritiesRequestType() },
    {
      skip: mode !== 'edit' || open === 'false',
    }
  );

  useEffect(() => {
    if (data?.load?.status === LoadStatus.LOAD_STATUS_FACTORED) {
      setIsSendedToBilling(true);
    }
    if (data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING) {
      setIsSendedToBilling(false);
    }
  }, [data?.load?.status]);

  const {
    formState: { isValid: isTransactionValid, errors: transactioErrors, dirtyFields: transactionDirtyFields },
    control: transactioControl,
    reset: resetTransaction,
    watch: transactionWatch,
    setValue: transactionSetValue,
    trigger: transactionTrigger,
  } = useForm<ITransactionFormData>({
    defaultValues: transactionDefalultValue,
    mode: 'all',
    resolver: yupResolver(transactionValidation()),
  });

  const customer = transactionWatch('customer');
  const carrier = transactionWatch('carrierTransaction');

  const amount = handleBalanceAmount(Number(customer), Number(carrier));

  useEffect(() => {
    if (amount) {
      transactionSetValue('balanceAmount', formatNumberWithThousandsSeparatorAndDecimal(amount));
    } else {
      transactionSetValue('balanceAmount', 0);
    }
  }, [customer, carrier]);

  const {
    handleViewFileEdit: handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    beforeUploadForCreate,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
  } = useFileUpload({
    data: {
      result: { files: loadFiles?.result?.RED_CONFIRMATION },
    },
    setValue: createLoadForm.setValue,
    getValues: createLoadForm.watch,
    id: data?.load?.id,
    addNewFile: addRedConfirmationFileToLoad,
    deleteFile: deleteFileFromLoad,
    trigger: createLoadForm.trigger,
    autoUpdate: true,
  });

  const handleResetTransactionForm = () => {
    resetTransaction();
  };

  useEffect(() => {
    setAllFiles(savedFiles);
  }, [savedFiles]);

  const podFiles = useMemo(() => {
    const resultArray = [];
    for (const key in loadFiles?.result) {
      if (key !== 'RED_CONFIRMATION' && key !== 'INVOICE') {
        resultArray.push(...loadFiles.result[key]);
      }
    }
    return resultArray.sort((a, b) => a.createdAt - b.createdAt);
  }, [loadFiles]);

  const mailableFiles = podFiles.filter(item => item.mailable);

  const {
    handleViewFileEdit: handleViewFileEditPOD,
    handleDeleteFileEdit: handleDeleteFileEditPOD,
    handleDownloadFileEdit: handleDownloadFileEditPOD,
    beforeUploadForEdit: beforeUploadForEditPOD,
    allFiles: allFilesPOD,
    setAllFiles: setAllFilesPOD,
    savedFiles: savedFilesPOD,
  } = useFileUpload({
    data: {
      result: { files: podFiles },
    },
    setValue: createLoadForm.setValue,
    getValues: createLoadForm.watch,
    id: data?.load?.id,
    addNewFile: addUnspecifiedFileToLoad,
    deleteFile: deleteFileFromLoad,
    trigger: createLoadForm.trigger,
    autoUpdate: true,
  });

  useEffect(() => {
    setAllFilesPOD(savedFilesPOD);
  }, [savedFilesPOD]);

  useEffect(() => {
    createLoadForm.reset(
      {
        load_type: '',
        customerName: '',
        referenceNumber: data?.load?.referenceNumber,
        customerAmount: '',
        customerContact: '',
        customerContactName: data?.load?.customerContactName,
        carrier: [],
        carrierAmount: '',
        truck: [],
        agent: [],
        agentAmount: '',
        authorities: data?.load?.soldAsAuthority?.id ? [data?.load?.soldAsAuthority] : [],
        file: [],
        soldUsSwitch: Boolean(data?.load?.soldAsAuthority?.id),
        markAsSwitch: Boolean(data?.load?.claimed),
        transportType: null,
        celsius: '',
        takenAs: null,
        givenAs: null,
        commodity: '',
        bookedAs: null,
        soldAs: data?.information?.soldType || null,
        feetPartial: '',
        weight: '',
        pickups: loadPoints?.result?.pickup || [],
        dropOffs: loadPoints?.result?.dropoffs || [],
        comment: '',
      },
      { keepDirty: false }
    );
  }, [data]);

  useEffect(() => {
    createLoadForm.setValue('pickups', loadPoints?.result?.pickup || [], { keepDirty: false, keepTouched: false });
    createLoadForm.setValue('dropOffs', loadPoints?.result?.dropoffs || [], { keepDirty: false, keepTouched: false });
  }, [loadPoints?.result?.pickup?.length, loadPoints?.result?.dropoffs?.length]);

  const handleSwitchSoldUs = () => {
    createLoadForm.setValue('soldUsSwitch', !createLoadForm.watch('soldUsSwitch'), {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (!createLoadForm.watch('soldUsSwitch')) {
      createLoadForm.setValue('authorities', []);
    }
  };

  const handleSwitchMarkAsClaim = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_CLAIM,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: data?.load?.claimed ? 'modalPrimary' : 'danger',
        onOk: () => {
          dispatch(changeLoading(true));

          if (data?.load?.claimed) {
            unclaimLoad(String(data?.load?.id)).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(changeLoading(false));
              } else {
                notification.success({
                  message: 'Successfully marked as claim',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                refetchLoadById({ id: data?.load?.id });
                dispatch(changeLoading(false));
              }
            });
          } else {
            claimLoad(String(data?.load?.id)).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully marked as claim',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                refetchLoadById({ id: data?.load?.id });
                dispatch(changeLoading(false));
              }
            });
          }
        },

        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleSave = () => {
    if (!createLoadForm.watch('referenceNumber')) {
      createLoadForm.trigger('referenceNumber');
    }
    if (createLoadForm.watch('soldUsSwitch') && !createLoadForm.watch('authorities').length) {
      createLoadForm.trigger('authorities');
    }
    if (
      createLoadForm.watch('referenceNumber') &&
      ((createLoadForm.watch('soldUsSwitch') && createLoadForm.watch('authorities').length) ||
        (!createLoadForm.watch('soldUsSwitch') && !createLoadForm.watch('authorities').length))
    ) {
      handleSaveModal();
    }
  };

  const handleCancel = () => {
    createLoadForm.reset({
      ...createLoadForm.watch(),
      authorities: data?.load?.soldAsAuthority.id ? [data?.load?.soldAsAuthority] : [],
      soldUsSwitch: Boolean(data?.load?.soldAsAuthority?.id),
      referenceNumber: data?.load?.referenceNumber,
    });
  };

  const handleSaveModal = () => {
    dispatch(
      openModal({
        title: SUBJECT.JUST_ARE_YOU_SURE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        onOk: () => {
          dispatch(changeLoading(true));
          editLoad({
            id: Number(data.load.id!),
            referenceNumber: String(createLoadForm.watch('referenceNumber')),
            soldAuthority: createLoadForm.watch('authorities'),
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              handleCancel();
            } else {
              notification.success({
                message: 'Successfully edited',
                duration: 3,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchLoadById();
            }
            dispatch(changeLoading(false));
            dispatch(closeModal());
            setSearchParams({
              ...searchParams,
              open: 'false',
            });
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleCancelLoad = (id: string | number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_LOAD,
        okText: SUBJECT.CANCEL,
        cancelText: SUBJECT.NO,
        okButtonVariant: 'danger',
        cancelButtonVariant: 'gray',
        onOk: () => {
          cancelLoad(Number(id)).then((data: any) => {
            if (data.error) {
              dispatch(closeModal());
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully canceled',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              refetchLoadById({ id: data?.load?.id });
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleCreateTransaction = () => {
    dispatch(changeLoading(true));
    createTransaction({
      loadId: data?.load?.id,
      body: transactionWatch(),
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        resetTransaction();
        refetchLoadById();
        refetchTransaction();
        refetchFilesByLoadId({ id: data?.load?.id });
        dispatch(changeLoading(false));
        refetchRateConfirmations();
        dispatch(billingAPI.util.invalidateTags(['Billing']));
      }
    });
  };
  const handleDeleteTransaction = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          deleteTransaction({ id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchTransaction();
              refetchLoadById();
              dispatch(changeLoading(false));
              refetchRateConfirmations();
              refetchFilesByLoadId({ id: data?.load?.id });
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleEditFile = ({ id, type, readable, mailable }: any) => {
    const typeData = FileTypes[type as keyof typeof FileTypes] ? FileTypes[type as keyof typeof FileTypes] : type;

    editLoadFile({
      id,
      ...(readable && {
        readable: Number(readable),
      }),
      mailable:
        (FileTypes[type as keyof typeof FileTypes] as string) === SUBJECT.CARRIER_INVOICE ? false : Boolean(+mailable),
      type: typeData,
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_UPDATED,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
      refetchFilesByLoadId();
    });
  };
  const handleSendToBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_BILLING,
        okText: SUBJECT.SEND,
        cancelText: SUBJECT.CLOSE,
        onOk: () => {
          dispatch(changeLoading(true));
          sendToBilling({ id: data?.load?.id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: SUBJECT.REQUEST_SENT_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              setIsSendedToBilling(true);
              dispatch(closeModal());
            }
            dispatch(changeLoading(false));
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const checkIsSendToBillingButtonDisabled = (files: any) => {
    const hasPodFile = files?.POD && files.POD.length > 0;
    const hasRedConfirmationFile = files?.RED_CONFIRMATION && files.RED_CONFIRMATION.length > 0;
    const hasMailablePod = hasPodFile && files?.POD.some((file: any) => file.mailable === true);
    const hasMailableRedConfirmation =
      hasRedConfirmationFile && files?.RED_CONFIRMATION.some((file: any) => file.mailable === true);
    return !(hasMailablePod && hasMailableRedConfirmation);
  };
  const isSendToBillingDisabled = checkIsSendToBillingButtonDisabled(loadFiles?.result);

  const handleAcceptToBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_ACCEPT,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          acceptBilling({ id: data?.load?.id, sendEmail: true }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: SUBJECT.ACCEPTED_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchLoadById();
              refetchFilesByLoadId();
              if (!isFetching && !isFilesFetching) {
                dispatch(changeLoading(false));
                dispatch(closeModal());
              }
            }
          });
        },
        onCancel: () => {
          dispatch(changeLoading(true));
          acceptBilling({ id: data?.load?.id, sendEmail: false }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: SUBJECT.ACCEPTED_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchLoadById();
              refetchFilesByLoadId();
              if (!isFetching && !isFilesFetching) {
                dispatch(changeLoading(false));
                dispatch(closeModal());
              }
            }
          });
        },
      })
    );
  };

  const handleRejectBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.REJECT,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          data?.load?.retrieved
            ? rejectLoadRetrieved({ id: data?.load?.id }).then((data: any) => {
                if (data.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                } else {
                  notification.success({
                    message: SUBJECT.REJECTED_SUCCESSFULLY,
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  refetchLoadById();
                  refetchTransaction();
                  refetchFilesByLoadId();
                }
              })
            : rejectBilling({ id: data?.load?.id }).then((data: any) => {
                if (data.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                } else {
                  notification.success({
                    message: SUBJECT.REJECTED_SUCCESSFULLY,
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  refetchFilesByLoadId();
                  refetchLoadById();
                  refetchTransaction();
                }
              });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleCreateInvoice = () => {
    notification.info({
      message: SUBJECT.CREATE_INVOICE_IN_PROCCESS,
      duration: 3,
      placement: 'topRight',
      closeIcon: <CloseIcon />,
    });
    createInvoice({ id: data?.load?.id }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.INVOICE_SUCCESSFULLY,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        refetchFilesByLoadId();
      }
    });
  };

  const handleDeleteInvoice = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteFileFromLoad({ fileId: loadFiles?.result?.INVOICE[0].id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const isSaveButtonVisible = useMemo(() => {
    return !!(
      createLoadForm.dirtyFields.soldUsSwitch ||
      createLoadForm.dirtyFields.authorities ||
      createLoadForm.dirtyFields.referenceNumber
    );
  }, [Object.keys(createLoadForm.dirtyFields)?.length]);

  const addTransactionCheck = () => {
    if (
      userType === UserTypes.ADMIN ||
      (employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.transaction)
    ) {
      return false;
    } else {
      if (
        (data?.load?.customerStatus === 2 && !data?.load?.processing) ||
        (data?.load?.carrierStatus === 2 && !data?.load?.processing)
      ) {
        return true;
      }
    }
  };

  useEffect(() => {
    setAuthoritiesState((prev: any) => {
      return {
        ...prev,
        ...(data?.load?.takenAsAuthority?.id
          ? { ignoreAuthorityIds: [data?.load?.takenAsAuthority?.id] }
          : { ignoreAuthorityIds: [] }),
      };
    });
  }, []);

  return {
    handleSwitchSoldUs,
    handleSwitchMarkAsClaim,
    editDefaultValues,
    control: createLoadForm.control,
    watch: createLoadForm.watch,
    setValue: createLoadForm.setValue,
    errors: createLoadForm.errors,
    dirtyFields: createLoadForm.dirtyFields,
    trigger: createLoadForm.trigger,
    activeSection,
    handleCancel,
    handleSave,
    handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    beforeUploadForCreate,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
    handleViewFileEditPOD,
    handleDeleteFileEditPOD,
    handleDownloadFileEditPOD,
    beforeUploadForEditPOD,
    allFilesPOD,
    setAllFilesPOD,
    savedFilesPOD,
    transactionWatch,
    transactioControl,
    transactioErrors,
    isTransactionValid,
    transactionTrigger,
    transactionSetValue,
    handleCreateTransaction,
    handleDeleteTransaction,
    handleEditFile,
    handleSendToBilling,
    handleAcceptToBilling,
    handleRejectBilling,
    handleCreateInvoice,
    handleDeleteInvoice,
    handleCancelLoad,
    setAuthoritiesState,
    authorities,
    isSaveButtonVisible,
    isSendToBillingDisabled,
    isSendedToBilling,
    handleResetTransactionForm,
    transactionDirtyFields,
    employeeLevel,
    addTransactionCheck,
    userType,
    isCreateTransactionLoading,
    userId,
    isInvoiceLoading,
    isDisableSoldUs,
    isDisableReference,
    breakdowns,
    isBreakdownLoading,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    breakdownParams,
    mailableFiles,
    drawerRef,
    handleScrollToHistory,
    setIsDefaultHistory,
    isDefaultHistory,
  };
};
