import React from 'react';
import { Col, Row } from 'antd';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from '../../constants/constants';
import { IProfileFactoringFormProps } from '../../constants/types';
import CreateFactoringCompaniesForm from '../create-form/CreateFactoringCompaniesForm';

import styles from './EditProfileFactoringForm.module.scss';

const EditProfileFactoringForm = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  getValues,
  errors,
  setValue,
  data,
  statesData,
  watch,
  id,
  isFetchingById,
  isEditLoading,
  isCreateLoading,
  handleViewFileEdit,
  handleDeleteFileEdit,
  handleDownloadFileEdit,
  beforeUploadForEdit,
  allFiles,
}: IProfileFactoringFormProps) => {
  return (
    <div className={styles.officeWrapper}>
      <MainLoader isFetching={isFetchingById} />

      <ViewItemWrapper title={SUBJECT.FACTORING_INFO}>
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.FACTORING_NAME}:{' '}
              </Typography>
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.companyName}
                subject={`${SUBJECT.FACTORING_INFO}${SUBJECT.FACTORING_NAME}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.PHONE_NUMBER}:{' '}
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {data?.result?.phone}
              </Typography>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.FACTORING_ID}:{' '}
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {data?.result?.id}
              </Typography>
            </div>
          </Col>
        </Row>
      </ViewItemWrapper>
      <CreateFactoringCompaniesForm
        handleViewFileEdit={handleViewFileEdit}
        handleDeleteFileEdit={handleDeleteFileEdit}
        handleDownloadFileEdit={handleDownloadFileEdit}
        beforeUploadForEdit={beforeUploadForEdit}
        allFiles={allFiles}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        id={id}
        control={control}
        trigger={trigger}
        getValues={getValues}
        errors={errors}
        setValue={setValue}
        statesData={statesData}
        watch={watch}
        isFetchingById={isFetchingById}
        isEditLoading={isEditLoading}
        isCreateLoading={isCreateLoading}
      />
    </div>
  );
};

export default EditProfileFactoringForm;
