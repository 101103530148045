import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { PickupsDropoffs } from 'pages/loads/components/create-load/components/pickups-dropoffs/PickupsDropoffs';
import {
  AUTHORITIES_CUSTOM_COLUMNS,
  AUTHORITIES_CUSTOM_KEYS,
  BOOKED_TYPES,
  CARRIER_KEYS,
  LOAD_TYPES_OPTIONS,
  RESOURCE_TYPES,
  SEARCH_CARRIER_CUSTOM_COLUMNS,
  SEARCH_TRUCK_COLUMNS,
  TRUCK_KEYS,
} from 'pages/loads/components/create-load/constants/constants';
import { ITransactionTypes, SUBJECT, TRANSACTION_TYPES } from 'pages/loads/components/edit-load/constants/constants';
import InternalBoardBanner from 'pages/loads/tabs/loads-tab/components/loads-comment/components/internal-board-banner/InternalBanner';
import { BookedTypesEnum } from 'pages/loads/tabs/loads-tab/components/regular-load-view/constants/types';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import { AGENT_COLUMNS, AGENT_KEYS } from 'pages/profile/tabs/carriers/components/blacklist/constants/constants';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import { LoadStatus } from 'ui/load-status/constants/types';
import LoadStatusComponent from 'ui/load-status/LoadStatus';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import {
  floatNumberFormatter,
  floatNumberFormatterWithMinus,
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  onlyNumbersFormatter,
  unicodeFormatter,
} from 'utils/helpers';

import CustomComment from 'components/custom-comment/CustomComment';
import DollarIcon from 'components/svgs/DollarIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import TransactionsTable from 'components/transactions-table/TransactionsTable';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { IRoleLevel } from '../../../../../../services/truck-board/carrier-request/interfaces';
import { selectUserPermissionsInfo } from '../../../../../../store/user-slice/selector';
import { EmployeeLevels, UserTypes } from '../../../../../../utils/constants';
import { transactionsForDataGrind } from '../../constants/helpers';
import { LoadCancelStatus, LoadTypes, TransportCapabilityTypes } from '../../constants/types';
import {
  Input,
  SearchableDropdown,
  Select,
  Switch,
  TransactionInput,
  TransactionSelect,
} from '../../hoc-instance/instance';

import { useInternalEdit } from './useInternalEdit';

import styles from './InternalEdit.module.scss';

const InternalEdit = ({
  data,
  createLoadForm,
  loadTransactions,
  loadFiles,
  mode,
  loadPoints,
  comments,
  createComment,
  deleteComment,
  getMoreComments,
  isShowMoreVisible,
  refetchTransaction,
  refetchLoadById,
  refetchFilesByLoadId,
  refetchLoadPoints,
  loadHistory,
  isShowMoreVisibleNotes,
  loadsPagePermissions,
  onSubmit,
  isFetching,
  getMoreNotes,
  invalidLoadPointIds,
  connectingError,
  regularError,
  loadPointsAutocomplete,
  isLoadsCommentsLoading,
  isFetchingHistory,
  pointsState,
  setPointsState,
  filledPickUpDropOffs,
  submitted,
}: any) => {
  const {
    control,
    watch,
    setValue,
    errors,
    activeSection,
    handleSwitchSoldUs,
    handleCancel,
    handleSave,
    handleDownloadFileEdit,
    handleViewFileEdit,
    handleDeleteFileEdit,
    beforeUploadForEdit,
    allFiles,
    transactionWatch,
    transactioControl,
    transactioErrors,
    transactionSetValue,
    transactionTrigger,
    handleCreateTransaction,
    handleDeleteTransaction,
    addTransactionCheck,
    handleCancelLoad,
    isLoadInfoSaveButtonActive,
    handleSaveLoadinfo,
    carriers,
    setCarriersState,
    agents,
    setAgentsState,
    trucks,
    setTrucksState,
    handleCancelLoadinfo,
    authorities,
    setAuthoritiesState,
    isSaveButtonVisible,
    trigger,
    transactionDirtyFields,
    handleResetTransactionForm,
    userId,
    isTransactionValidBtn,
    isCreateTransactionLoading,
    drawerRef,
    handleScrollToHistory,
    setIsDefaultHistory,
    isDefaultHistory,
    roleLevel,
  } = useInternalEdit({
    data,
    createLoadForm,
    loadFiles,
    mode,
    loadPoints,
    refetchTransaction,
    refetchLoadById,
    refetchFilesByLoadId,
    loadsPagePermissions,
  });

  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const hasAccessToViewHistory =
    userType === UserTypes.ADMIN || (employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.view);

  useEffect(() => {
    if (!data?.load?.carrier?.id && watch('carrier')[0]?.type === 'Outside') {
      setValue('soldUsSwitch', true, {
        shouldTouch: false,
        shouldDirty: false,
        shouldValidate: false,
      });
    } else if (watch('carrier')[0]?.type !== 'Outside' && !data?.load?.authorities?.length) {
      setValue('soldUsSwitch', false, {
        shouldTouch: false,
        shouldDirty: false,
        shouldValidate: false,
      });
      setValue('authorities', [], {
        shouldTouch: false,
        shouldDirty: false,
        shouldValidate: false,
      });
    }
  }, [watch('carrier'), data?.load?.carrier?.id]);

  return (
    <form className={styles.regularLoadForm} onSubmit={createLoadForm.handleSubmit(onSubmit)}>
      <MainLoader isFetching={isFetching} />

      <InternalBoardBanner
        data={data}
        activeSection={activeSection}
        internalWatch={createLoadForm.watch}
        internalControl={createLoadForm.control}
        withActions={data?.load?.status !== LoadCancelStatus.Canceled}
        handleCancelLoad={handleCancelLoad}
        hasClaimPermission={loadsPagePermissions.claim}
        hasDeClaimPermission={loadsPagePermissions.resolve}
        hasCancelPermission={loadsPagePermissions.cancel}
        hasAccessToViewHistory={hasAccessToViewHistory}
        handleScrollToHistory={() => handleScrollToHistory('history')}
        handleCommentClick={() => handleScrollToHistory('comment')}
        loadHistory={loadHistory}
      />

      <ViewItemWrapper
        title={SUBJECT.EDIT_REGULAR_LOAD}
        id="general-information"
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        className={activeSection === SUBJECT.GENERAL_INFORMATION_ID ? 'active' : ''}
        Component={
          <LoadStatusComponent
            loadStatus={Number(data?.load?.status)}
            carrierStatus={Number(data?.load?.carrierStatus)}
            customerStatus={Number(data?.load?.customerStatus)}
            isProcessing={data?.load?.processing || false}
            withoutLoadsText
          />
        }
      >
        <Row align="middle" className={styles.topBannerBlock}>
          <Col span={12}>
            <Typography variant="heading" children={SUBJECT.GENERAL_INFO} className={styles.generalInfoHeading} />
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Col>
                <div className={styles.takenAsBlock}>
                  <Typography variant="paragraph">{`${SUBJECT.TAKEN_AS_MC} `}</Typography>
                  <span>{data?.load?.takenAsAuthority?.name}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Controller
              name="load_type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Select
                    options={LOAD_TYPES_OPTIONS}
                    placeholder={SUBJECT.LOAD_TYPE}
                    onBlur={() => trigger('load_type')}
                    name={field.name}
                    onChange={(value: string | string[]) => field.onChange(value)}
                    errors={errors}
                    value={field.value}
                    required
                    control={control}
                    isDisabled={roleLevel === IRoleLevel.corpLevel}
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.load_type} />
          </Col>
          <Col span={8} />
          <Col span={8} />

          <Col span={8}>
            <Input
              name="customerName"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_NAME}
              getValues={watch}
              errors={errors}
              defaultValue={data?.load?.customer?.name}
              value={data?.load?.customer?.name}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              suffix={<DollarIcon />}
              name="customerAmount"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_AMOUNT}
              defaultValue={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount)}
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount)}
              getValues={watch}
              errors={errors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="referenceNumber"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.REFERENCE_NUMBER}
              getValues={watch}
              errors={errors}
              onChange={(e: any) =>
                setValue('referenceNumber', e.target.value.trimStart(), { shouldTouch: true, shouldDirty: true })
              }
              required
              disabled={!loadsPagePermissions.editReferenceNumber}
            />
          </Col>
          {LoadTypes[watch('load_type')] !== 'Internal' && (
            <>
              <Col span={8}>
                <Input
                  name="customerContactName"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.CONTACT_NAME}
                  getValues={watch}
                  errors={errors}
                  required
                  onChange={(e: any) => {
                    setValue('customerContactName', unicodeFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="customerContact"
                  control={control}
                  placeholder={SUBJECT.CUSTOMER_CONTACT}
                  rules={{ required: true }}
                  errors={errors}
                  getValues={watch}
                  required
                  onChange={(e: any) => {
                    setValue('customerContact', formatPhone(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                />
              </Col>
            </>
          )}
          {Number(watch('load_type')) === LoadTypes.Regular && (
            <Col span={24}>
              <Row gutter={[18, 40]}>
                <Col span={8}>
                  <SearchableDropdown
                    options={carriers?.result}
                    placeholder={SUBJECT.CARRIER}
                    offset={carriers?.count}
                    setOffset={setCarriersState}
                    values={watch('carrier')}
                    columns={SEARCH_CARRIER_CUSTOM_COLUMNS}
                    keys={CARRIER_KEYS}
                    control={control}
                    name="carrier"
                    showKey="title"
                    setValue={setValue}
                    withoutBorder={true}
                    required
                    rules={{ required: true }}
                    width={500}
                  />
                </Col>
                {watch('carrier')[0]?.type === 'Owner Operator' && (
                  <Col span={8}>
                    <SearchableDropdown
                      options={trucks?.result?.map((truck: any) => ({
                        title: truck?.truckNumber,
                        value: String(truck?.id),
                      }))}
                      placeholder={SUBJECT.TRUCK}
                      offset={trucks?.count}
                      setOffset={setTrucksState}
                      values={watch('truck')}
                      columns={SEARCH_TRUCK_COLUMNS}
                      keys={TRUCK_KEYS}
                      control={control}
                      onBlur={() => trigger('truck')}
                      name="truck"
                      showKey="title"
                      setValue={setValue}
                      disabled={!watch('carrier').length}
                      withoutBorder={true}
                      required
                      rules={{ required: true }}
                    />
                  </Col>
                )}
                <Col span={8}>
                  <Input
                    name="carrierAmount"
                    control={control}
                    placeholder={SUBJECT.CARRIER_AMOUNT}
                    rules={{ required: true }}
                    errors={errors}
                    onBlur={() => trigger('carrierAmount')}
                    value={
                      watch('carrierAmount') ? formatNumberWithThousandsSeparatorAndDecimal(watch('carrierAmount')) : ''
                    }
                    suffix={<DollarIcon />}
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue('carrierAmount', onlyNumbersFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
          )}
          {Number(watch('load_type')) !== LoadTypes.Internal && Number(watch('load_type')) !== LoadTypes.Connecting && (
            <Col span={24}>
              <Row gutter={[18, 40]}>
                <Col>
                  <Typography variant="heading" children={SUBJECT.SOLD_US_SWITCH} className={styles.switchHeading} />
                </Col>
                <Col>
                  <Switch
                    name="soldUsSwitch"
                    checked={watch('soldUsSwitch')}
                    control={control}
                    onChange={handleSwitchSoldUs}
                    disabled={
                      !watch('carrier').length ||
                      !loadsPagePermissions.editSoldAsMc ||
                      watch('carrier')[0]?.type === 'Outside'
                    }
                  />
                </Col>
              </Row>
            </Col>
          )}
          {Number(watch('load_type')) === LoadTypes.Connecting && (
            <>
              <Col span={8} />
              <Col span={8}>
                <SearchableDropdown
                  options={agents?.result?.filter((el: any) => el.id != userId)}
                  columns={AGENT_COLUMNS}
                  keys={AGENT_KEYS}
                  placeholder={SUBJECT.AGENT}
                  name="agent"
                  control={control}
                  setValue={setValue}
                  values={watch('agent')}
                  offset={agents?.count}
                  setOffset={setAgentsState}
                  showKey="firstName"
                  rules={{ required: true }}
                  required
                  width={500}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="agentAmount"
                  placeholder={SUBJECT.AGENT_AMOUNT}
                  rules={{ required: true }}
                  errors={errors}
                  control={control}
                  value={watch('agentAmount') ? formatNumberWithThousandsSeparatorAndDecimal(watch('agentAmount')) : ''}
                  onChange={(e: any) => {
                    setValue('agentAmount', onlyNumbersFormatter(e.target.value), {
                      shouldValidate: true,
                      shouldTouch: true,
                      shouldDirty: true,
                    });
                  }}
                  suffix={<DollarIcon />}
                  required
                />
              </Col>
            </>
          )}
          {watch('soldUsSwitch') && (
            <Col span={8}>
              <SearchableDropdown
                options={authorities?.result?.map((item: any) => ({
                  title: item?.name,
                  value: String(item?.id),
                }))}
                placeholder={SUBJECT.ALL_AUTHORITIES}
                offset={authorities?.count}
                setOffset={setAuthoritiesState}
                values={watch('authorities')}
                columns={AUTHORITIES_CUSTOM_COLUMNS}
                keys={AUTHORITIES_CUSTOM_KEYS}
                control={control}
                name="authorities"
                showKey="title"
                setValue={setValue}
                withoutBorder={true}
                required
                rules={{ required: true }}
                disabled={!loadsPagePermissions.editSoldAsMc}
                width={500}
              />
            </Col>
          )}
          {isSaveButtonVisible && (
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Button children="Cancel" variant="text" onClick={handleCancel} />
                </Col>
                <Col>
                  <Button
                    children="Save"
                    disabled={!watch('referenceNumber') || !watch('load_type')}
                    onClick={handleSave}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>

      <div className={clsx(styles.uploadSection)}>
        <ViewItemWrapper
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          title={SUBJECT.DOCUMENTATION}
          className={activeSection === SUBJECT.DOCUMENTATION_ID ? 'active' : ''}
          id="documentation"
        >
          <Row align="middle" justify="space-between">
            <Col span={8}>
              <Typography
                variant="heading"
                children={SUBJECT.UPLOAD_RED_CONFIRMATION}
                className={styles.uploadHeading}
              />
            </Col>
          </Row>
          <Row gutter={[23, 30]}>
            <Col span={9}>
              <Upload
                accept="application/pdf"
                uploadText={SUBJECT.UPLOAD_RED_CONFIRMATION}
                className={styles.upload}
                beforeUpload={beforeUploadForEdit}
                multiple={false}
                isDisabled={!loadsPagePermissions.uploadsFiles}
                uploadType="2"
              />
            </Col>
            {loadsPagePermissions.uploadsFiles && (
              <Col span={8}>
                <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT_PDF} className={styles.allowedFormat} />
              </Col>
            )}
            {!!allFiles.length && (
              <Col span={24}>
                <Row justify="end" gutter={[20, 0]} align="bottom" className={styles.downloadAllBlock}>
                  <Col>
                    <Typography variant="heading" children={SUBJECT.DOWNLOAD_ALL} className={styles.downloadAll} />
                  </Col>
                  <Col>
                    <div className={clsx(styles.uploadControl, styles.downloadBtn)}>
                      <DownloadIcon />
                    </div>
                  </Col>
                </Row>

                <Col span={24}>
                  <Controller
                    control={control}
                    render={({ field: { value: _value } }) => (
                      <FileItems
                        handleDownloadFileEdit={handleDownloadFileEdit}
                        handleViewFileEdit={handleViewFileEdit}
                        itemId={data?.load?.id}
                        onDeleteClick={handleDeleteFileEdit}
                        files={allFiles}
                        isDisabled={data?.load?.status !== LoadStatus.LOAD_STATUS_NOT_PROCESSING}
                        withCrossing
                        hasDeleteFilePermission={loadsPagePermissions.filesDelete}
                      />
                    )}
                    name="file"
                  />
                </Col>
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      </div>

      <ViewItemWrapper
        title={SUBJECT.LOAD_INFO}
        className={activeSection === SUBJECT.LOAD_INFO_ID ? 'active' : ''}
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        id="load-info"
      >
        <Row align="top" className={styles.topBannerBlock} gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="transportType"
              control={control}
              placeholder={SUBJECT.LOAD_TYPE}
              rules={{ required: true }}
              errors={errors}
              value={TransportCapabilityTypes[data?.information?.transportCapability]}
              defaultValue={TransportCapabilityTypes[data?.information?.transportCapability]}
              disabled
              required
            />
          </Col>
          {watch('transportType') === TransportCapabilityTypes.Refrigerated && (
            <Col span={8}>
              <Input
                name="celsius"
                control={control}
                placeholder={SUBJECT.CELSIUS}
                rules={{ required: true }}
                getValues={watch}
                value={data?.information?.refrigeratorCelsius || ''}
                errors={errors}
                required
                disabled
              />
            </Col>
          )}
          <Col span={8}>
            <Controller
              name="takenAs"
              control={control}
              rules={{ required: Number(watch('load_type')) !== LoadTypes.Internal }}
              render={({ field }) => {
                return (
                  <Select
                    options={RESOURCE_TYPES}
                    placeholder={SUBJECT.TAKEN_AS}
                    name={field.name}
                    onBlur={() => trigger('takenAs')}
                    onChange={(value: string | string[]) => field.onChange(value)}
                    errors={errors}
                    control={control}
                    value={field.value}
                    required={Number(watch('load_type')) !== LoadTypes.Internal}
                    isDisabled={data?.information?.takenType}
                  />
                );
              }}
            />
          </Col>
          <Col span={8}>
            <Controller
              name="givenAs"
              control={control}
              rules={{ required: Number(watch('load_type')) !== LoadTypes.Internal }}
              render={({ field }) => {
                return (
                  <Select
                    options={RESOURCE_TYPES}
                    placeholder={SUBJECT.GIVEN_AS}
                    name={field.name}
                    control={control}
                    onBlur={() => setTimeout(() => trigger('givenAs'), 300)}
                    onChange={(value: string | string[]) => field.onChange(value)}
                    errors={errors}
                    value={field.value}
                    required={Number(watch('load_type')) !== LoadTypes.Internal}
                    isDisabled={data?.information?.givenType}
                  />
                );
              }}
            />
          </Col>
          <Col span={16}>
            <Input
              type="text"
              name="commodity"
              control={control}
              getValues={watch}
              placeholder={SUBJECT.COMMODITY}
              rules={{ required: true }}
              errors={errors}
              value={data?.information?.commodity}
              defaultValue={data?.information?.commodity}
              disabled
              required
            />
          </Col>
        </Row>
        <Row align="top" gutter={[18, 40]}>
          <Col span={8}>
            <Controller
              name="bookedAs"
              control={control}
              rules={{ required: Number(watch('load_type')) !== LoadTypes.Internal }}
              render={({ field }) => {
                return (
                  <Select
                    options={BOOKED_TYPES}
                    placeholder={SUBJECT.BOOKED_AS}
                    name={field.name}
                    control={control}
                    onBlur={() => setTimeout(() => trigger('bookedAs'), 300)}
                    onChange={(value: string | string[]) => field.onChange(value)}
                    errors={errors}
                    value={field.value}
                    required={Number(watch('load_type')) !== LoadTypes.Internal}
                    isDisabled={data?.information?.bookedType}
                  />
                );
              }}
            />
          </Col>
          <Col span={8}>
            <Controller
              name="soldAs"
              control={control}
              rules={{ required: Number(watch('load_type')) !== LoadTypes.Internal }}
              render={({ field }) => {
                return (
                  <Select
                    options={BOOKED_TYPES}
                    control={control}
                    placeholder={SUBJECT.SOLD_AS}
                    errors={errors}
                    name={field.name}
                    onBlur={() => setTimeout(() => trigger('soldAs'), 300)}
                    onChange={(value: string | string[]) => field.onChange(value)}
                    value={field.value}
                    required={Number(watch('load_type')) !== LoadTypes.Internal}
                    isDisabled={data?.information?.soldType}
                  />
                );
              }}
            />
          </Col>
          {watch('soldAs') == BookedTypesEnum.Partial && (
            <Col span={8}>
              <Input
                name="feetPartial"
                type="number"
                control={control}
                placeholder={SUBJECT.FEET_OF_PARTIAL}
                rules={{ required: true }}
                errors={errors}
                value={watch('feetPartial')}
                getValues={watch}
                required
                onChange={(e: any) => {
                  setValue('feetPartial', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                disabled={!!data?.information?.feetOfPartial}
                defaultValue={data?.information?.feetOfPartial}
              />
            </Col>
          )}
          <Col span={8}>
            <Input
              name="weight"
              type="number"
              control={control}
              placeholder={SUBJECT.WEIGHT}
              rules={{ required: true }}
              value={data?.information?.weight}
              defaultValue={data?.information?.weight}
              errors={errors}
              required
              disabled
              getValues={watch}
            />
          </Col>
          <Col span={24}>
            {isLoadInfoSaveButtonActive && (
              <Row justify="end">
                <Col>
                  <Button children="Cancel" variant="text" onClick={handleCancelLoadinfo} />
                </Col>
                <Col>
                  <Button
                    children="Save"
                    disabled={watch('soldAs') == BookedTypesEnum.Partial && !watch('feetPartial')}
                    onClick={handleSaveLoadinfo}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </ViewItemWrapper>

      <PickupsDropoffs
        activeSection={activeSection}
        loadPointsAutocomplete={loadPointsAutocomplete}
        pickupDropOffsWatch={createLoadForm.pickupDropOffsWatch}
        pickupDropOffsControl={createLoadForm.pickupDropOffsControl}
        pickupDropOffsErrors={createLoadForm.pickupDropOffsErrors}
        setPickupDropOffsValue={createLoadForm.setPickupDropOffsValue}
        isPickupDropOffsValid={createLoadForm.isPickupDropOffsValid}
        watch={createLoadForm.watch}
        errors={createLoadForm.errors}
        setValue={createLoadForm.setValue}
        handleClearDate={createLoadForm.handleClearDate}
        statesData={createLoadForm.statesData}
        trigger={createLoadForm.trigger}
        refetchLoadPoints={refetchLoadPoints}
        {...createLoadForm}
        data={data}
        editMode
        id={data?.load?.id}
        hasAddPickupDropOffPermission={loadsPagePermissions.addPickUpDropOff}
        hasEditPickupDropOffPermission={loadsPagePermissions.editPickUpDropOff}
        isCanceledClaimed={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        invalidLoadPointIds={invalidLoadPointIds}
        connectingError={connectingError}
        regularError={regularError}
        pointsState={pointsState}
        setPointsState={setPointsState}
        filledPickUpDropOffs={filledPickUpDropOffs}
        submitted={submitted}
      />

      {loadsPagePermissions.transaction ? (
        <ViewItemWrapper
          title={SUBJECT.TRANSACTION}
          Component={
            <>
              {!!Object.keys(transactionDirtyFields)?.length && (
                <Col>
                  <Button
                    icon={<ResetIcon />}
                    variant="ghost"
                    children={SUBJECT.RESET}
                    onClick={handleResetTransactionForm}
                  />
                </Col>
              )}
            </>
          }
          className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          id="transaction"
        >
          <Row gutter={[10, 30]}>
            <Col span={8}>
              <TransactionSelect
                name="transactionType"
                control={transactioControl}
                options={TRANSACTION_TYPES}
                onBlur={() =>
                  setTimeout(() => {
                    transactionTrigger('transactionType');
                    if (transactionWatch('customer')?.length) {
                      transactionTrigger('customer');
                    }
                  }, 500)
                }
                placeholder={SUBJECT.TRANSACTION_TYPE}
                required
                errors={transactioErrors}
              />
            </Col>
            <Col span={8}>
              <TransactionInput
                suffix={<DollarIcon />}
                name="customer"
                control={transactioControl}
                getValues={transactionWatch}
                handleBlur={() =>
                  setTimeout(() => {
                    transactionTrigger('customer');
                  }, 0)
                }
                onChange={e => {
                  transactionSetValue(
                    'customer',
                    Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                      Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                      ? floatNumberFormatter(e.target.value)
                      : floatNumberFormatterWithMinus(e.target.value),
                    { shouldDirty: true }
                  );

                  setTimeout(() => {
                    transactionTrigger('customer');
                  }, 0);
                }}
                placeholder={SUBJECT.CUSTOMER}
                value={
                  transactionWatch('customer')
                    ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('customer'))
                    : ''
                }
                required
                withRedMinus
              />
            </Col>
            <Col span={8}>
              <TransactionInput
                suffix={<DollarIcon />}
                name="balanceAmount"
                getValues={transactionWatch}
                control={transactioControl}
                rules={{ required: true }}
                placeholder={SUBJECT.BALANCE_AMOUNT}
                value={String(transactionWatch('balanceAmount'))}
                disabled
                withRedMinus
              />
            </Col>
            <Col span={24}>
              <TransactionInput
                type="text"
                control={transactioControl}
                name="transactionComment"
                getValues={transactionWatch}
                placeholder={SUBJECT.WRITE_COMMENT}
              />
            </Col>

            {loadsPagePermissions.transaction && (
              <Col span={24}>
                <Row justify="end">
                  <Button
                    variant="default"
                    children={SUBJECT.ADD_TRANSACTION}
                    disabled={addTransactionCheck() || !isTransactionValidBtn || isCreateTransactionLoading}
                    onClick={handleCreateTransaction}
                  />
                </Row>
              </Col>
            )}
            {!!transactionsForDataGrind(loadTransactions)?.length && (
              <Col span={24}>
                <TransactionsTable
                  data={transactionsForDataGrind(loadTransactions)}
                  handleRowDelete={handleDeleteTransaction}
                  hasDeletePermission={loadsPagePermissions.transactionDelete}
                  isCustomer
                />
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      ) : (
        <>
          {!!transactionsForDataGrind(loadTransactions)?.length && (
            <ViewItemWrapper
              title={SUBJECT.TRANSACTION}
              className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
              canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
              id="transaction"
            >
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <TransactionsTable
                    data={transactionsForDataGrind(loadTransactions)}
                    handleRowDelete={handleDeleteTransaction}
                    hasDeletePermission={loadsPagePermissions.transactionDelete}
                    isCustomer
                  />
                </Col>
              </Row>
            </ViewItemWrapper>
          )}
        </>
      )}
      <div ref={drawerRef}>
        <CustomComment
          id="comment"
          watch={watch}
          name="comment"
          setValue={setValue}
          control={control}
          comments={comments}
          createComment={createComment}
          deleteComment={deleteComment}
          activeSection={activeSection}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          setIsDefaultHistory={setIsDefaultHistory}
          isDefaultHistory={isDefaultHistory}
          isBlockCanceled={data?.load?.status === LoadCancelStatus.Canceled}
          loadHistory={loadHistory}
          withNotes={loadHistory?.result?.length}
          drawerRef={drawerRef}
          getMoreNotes={getMoreNotes}
          isShowMoreVisibleNotes={isShowMoreVisibleNotes}
          hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
          isFetchingHistory={isFetchingHistory}
        />
      </div>
    </form>
  );
};

export default InternalEdit;
