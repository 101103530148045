import clsx from 'clsx';

import HeartIcon from '../svgs/HeartIcon';

import { IFavoriteProps } from './constants/types';

import styles from './Favorite.module.scss';

const Favorite = ({ selected, onFavoriteClick }: IFavoriteProps) => {
  return (
    <div className={styles.favoriteWrapper}>
      <div
        role="button"
        className={clsx(styles.favorite, { [styles.selectedFavorite]: selected })}
        onClick={onFavoriteClick}
      >
        <HeartIcon />
      </div>
    </div>
  );
};
export default Favorite;
