export enum SUBJECT {
  EDIT = 'Edit',
  ARE_YOU_SURE = 'Are You Sure Want Do Delete?',
  DELETE = 'Delete',
  NO = 'No',
  YES = 'Yes',
  CANCEL = 'Cancel',
  OPEN = 'Open',
  RESULT = 'Search Result',
  SEARCH_TYPE = 'Search type',
  CARRIER_NAME = 'Carrier Name',
  CONTACT_NAME = 'Contact Name',
  CARRIER_ID = 'Carrier ID',
  PHONE_NUMBER = 'Phone Number',
  LOAD_ID = 'Load ID',
  REF_NUMBER = 'Ref. Number',
  MC_NUMBER = 'MC Number',
  NO_RESULT = 'No Search Result',
  COMPANY_NAME = 'Company Name',
  CUSTOMER_NAME = 'Customer Name',
  CUSTOMER_ID = 'Customer ID',
  CUSTOMER_PHONE = 'Phone Number',
  CUSTOMER_MC = 'MC Number',
  LOAD_TYPE = 'Load type',
}

export const mockData = {
  search_type: 'Carriers',
  carrier_name: 'Logan',
  contact_name: 'John',
  carrier_id: '123456789',
  phone_number: '858-28-9357',
  load_id: '123456789',
  ref_number: '659875065',
  mc_number: '123456789',
};
