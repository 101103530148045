import { createApi } from '@reduxjs/toolkit/query/react';
import { IAuthoritiesFormData } from 'pages/profile/tabs/authorities/constants/types';
import { baseQuery } from 'services/service';

import { IGetBranchesParams } from '../branches/interfaces';
import { getAuthoritiesByBranchId } from '../branches/serializers';
import { IAuthorityByOfficeId } from '../offices/interfaces';
import { getAuthoritiesByOfficeId } from '../offices/serializers';

import { createAutorityDeserializer, updateAutorityDeserializer } from './deserializers';
import { IGetAuthoritiesParams } from './interfaces';
import { getAuthoritiesListSerializer, getAuthoritiesShortSerializer, getTemplatesSerializer } from './serializers';

export const authoritiesAPI = createApi({
  reducerPath: 'authoritiesAPI',
  baseQuery,
  tagTypes: ['Authorities'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getAuthorities: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<IGetAuthoritiesParams>) => ({
        url: 'authorities',
        params: {
          search,
          field,
          skip,
          limit,
          order,
          orderBy,
          ...filter,
        },
      }),
      transformResponse: (body: { result: IAuthorityByOfficeId[]; count: number }) =>
        getAuthoritiesListSerializer(body),
      providesTags: ['Authorities'],
    }),
    getAuthoritiesShort: builder.query({
      query: (queries: Partial<IGetAuthoritiesParams>) => {
        const searchParams = getAuthoritiesShortSerializer(queries);
        return {
          url: 'authorities/autocomplete',
          params: searchParams,
        };
      },
      providesTags: ['Authorities'],
    }),

    getAuthority: builder.query({
      query: id => `authorities/${id}`,
      providesTags: ['Authorities'],
    }),

    createAutority: builder.mutation({
      query: (body: IAuthoritiesFormData) => ({
        url: 'authorities',
        method: 'POST',
        body: createAutorityDeserializer(body as IAuthoritiesFormData),
      }),
      invalidatesTags: ['Authorities'],
    }),

    updateAuthority: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `authorities/${id}`,
        method: 'PUT',
        body: updateAutorityDeserializer(rest as IAuthoritiesFormData),
      }),
    }),
    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `authorities/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['Authorities'],
    }),
    getAuthoritiesByOfficeId: builder.query({
      query: ({ ...queries }: Partial<IGetAuthoritiesParams>) => {
        const searchParams = getAuthoritiesShortSerializer(queries);
        return {
          url: `authorities/autocomplete`,
          params: searchParams,
        };
      },
      transformResponse: (body: { result: IAuthorityByOfficeId[]; count: number }) => getAuthoritiesByOfficeId(body),
      providesTags: ['Authorities'],
    }),
    getAuthoritiesByBranchId: builder.query({
      query: ({ ...queries }: Partial<IGetAuthoritiesParams>) => {
        const searchParams = getAuthoritiesShortSerializer(queries);
        return {
          url: `authorities/autocomplete`,
          params: searchParams,
        };
      },
      transformResponse: (body: { result: IAuthorityByOfficeId[]; count: number }) => getAuthoritiesByOfficeId(body),
      providesTags: ['Authorities'],
    }),
    getAuthoritiesByOfficeIdTable: builder.query({
      query: ({ id, search, skip, limit, order, orderBy, filter }: Partial<IGetAuthoritiesParams>) => ({
        url: `offices/${id}/authorities`,
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: { result: IAuthorityByOfficeId[]; count: number }) => getAuthoritiesByOfficeId(body),
      providesTags: ['Authorities'],
    }),
    getBranchAuthorities: builder.query({
      query: ({ id, search, skip, limit, order, orderBy, filter }: Partial<IGetBranchesParams>) => ({
        url: `branches/${id}/authorities`,
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getAuthoritiesByBranchId(body),
      providesTags: ['Authorities'],
    }),
    getBranchAuthoritiesTable: builder.query({
      query: ({ id, search, skip, limit, order, orderBy, filter }: any) => ({
        url: `branches/${id}/authorities`,
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getAuthoritiesByBranchId(body),
      providesTags: ['Authorities'],
    }),
    getAuthoritiesTemplates: builder.query({
      query: () => ({
        url: `authorities/templates`,
      }),
      providesTags: ['Authorities'],
      transformResponse: (body: any) => getTemplatesSerializer(body),
    }),
    addNewFileToAuthority: builder.mutation({
      query: ({ id, fileId, type }) => ({
        url: `authorities/${id}/file/${fileId}`,
        method: 'POST',
        body: { type },
      }),
    }),
    deleteFileFromAuthority: builder.mutation({
      query: ({ id, fileId, type }) => ({
        url: `authorities/${id}/file/${fileId}`,
        method: 'DELETE',
        body: { type },
      }),
    }),
  }),
});

export const {
  useGetAuthoritiesQuery,
  useGetAuthorityQuery,
  useCreateAutorityMutation,
  useUpdateAuthorityMutation,
  useChangeStatusMutation,
  useGetAuthoritiesByOfficeIdQuery,
  useGetAuthoritiesShortQuery,
  useAddNewFileToAuthorityMutation,
  useDeleteFileFromAuthorityMutation,
  useGetBranchAuthoritiesQuery,
  useGetAuthoritiesByOfficeIdTableQuery,
  useGetBranchAuthoritiesTableQuery,
  useGetAuthoritiesByBranchIdQuery,
  useGetAuthoritiesTemplatesQuery,
} = authoritiesAPI;
