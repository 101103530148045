import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { LoadsViewTypes } from 'pages/factoring/tabs/payables/components/payables-view/constants/types';
import {
  useGetCustomerReceiveMutation,
  useLazyGetPaymentDetailsQuery,
} from 'services/factoring/receivables/receivables';
import { IGetLoadsParams } from 'services/loads/interface';
import { useGetAuthoritiesShortQuery, useGetAuthorityQuery } from 'services/profile/authorities/authorities';
import { changeLoading, closeModal, openModal, setLoadIds } from 'store/modal-slice/modals';
import { IDataSource } from 'ui/data-grid/constants/types';
import { CustomerStatus } from 'ui/load-status/constants/types';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import ReceivablesModalContent from '../receivables-modal-content/ReceivablesModalContent';

import { SUBJECT } from './constants/constants';
import { IReceivablesModalContentFormData } from './constants/types';

const useReceivablesView = ({ pagePermissions, setValue, handleRefetchCustomer }: any) => {
  const dispatch = useDispatch();

  const { searchParams } = useDetectedParams();
  const { authorityId, tab, width } = searchParams;

  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
    ...(!isNaN(Number(authorityId)) ? { ignoreAuthorityIds: [Number(authorityId)] } : {}),
  });
  const { currentData: authorityIdData } = useGetAuthorityQuery(authorityId, { skip: !authorityId });

  const { data: authorities = [] } = useGetAuthoritiesShortQuery(authoritiesState, {
    skip: tab !== 'receivables',
  });

  const [getPaymentDetails, { data: paymentDetails, isFetching: paymentDetailsLoading }] =
    useLazyGetPaymentDetailsQuery();
  const [getCustomerReceive] = useGetCustomerReceiveMutation();

  const [selectedRows, setSelectedRows] = useState<IDataSource[]>([]);

  const summary = useMemo(() => {
    let sum = 0;
    selectedRows.forEach(row => {
      sum += +row.customerBalance;
    });
    return sum;
  }, [selectedRows.length]);

  const handleCheckboxChange = (data: number | string[], items: any[]) => {
    setSelectedRows(items.filter((row: any) => row.status !== CustomerStatus.CUSTOMER_STATUS_PAID));
  };

  const handlePaymentDetailsClick = (id: string | number) => {
    getPaymentDetails(id as string);
  };

  const onSubmit = (
    { moneyType, moneyCode, amount, numberOrTransactionId }: IReceivablesModalContentFormData,
    ids: number[]
  ) => {
    const loadIds = selectedRows.length ? selectedRows.map(({ id }) => id) : ids;
    dispatch(changeLoading(true));
    getCustomerReceive({ moneyType, moneyCode, amount, numberOrTransactionId, loadIds }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: 'Successfully received',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
        handleRefetchCustomer();
        dispatch(closeModal());
        setSelectedRows([]);
      }
    });
  };

  const handlePayAllSelectedRows = () => {
    const customersDeptAmount = selectedRows.reduce((acc, obj) => {
      acc.customerDept = (acc?.customerDept || 0) + obj?.customerDept;
      return acc;
    }, {});

    dispatch(
      openModal({
        title: (
          <ReceivablesModalContent
            onSubmit={onSubmit}
            enteredCount={selectedRows?.length}
            total={summary}
            customerDeptAmount={Number(customersDeptAmount?.customerDept)}
          />
        ),
        okText: SUBJECT.RECEIVE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'default',
        isReceive: true,
        maskClosable: false,
        currentClass: 'receiveModal',
      })
    );
  };

  const handleReceive = (rowData?: IDataSource) => {
    dispatch(setLoadIds([rowData?.id as number]));
    dispatch(
      openModal({
        title: (
          <ReceivablesModalContent
            onSubmit={onSubmit}
            enteredCount={1}
            total={rowData?.customerBalance}
            customerDeptAmount={rowData?.customerDept}
          />
        ),
        okText: SUBJECT.RECEIVE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'default',
        isReceive: true,
        maskClosable: false,
        currentClass: 'receiveModal',
      })
    );
  };

  const handleOpenLoadView = (rowData: IDataSource) => {
    window.open(
      `http://${window.location.host}/loads?tab=loads&open=true&mode=${LoadsViewTypes[rowData.loadType]}&id=${
        rowData.id
      }`
    );
  };

  const checkIsDisabledRowSelection = (item: any, node: ReactNode) => {
    if (!pagePermissions.receive && !pagePermissions.claimLoadReceive) {
      return;
    } else if (item.claimed && pagePermissions.claimLoadReceive) {
      return node;
    } else if (!item.claimed && pagePermissions.receive) {
      return node;
    }
    return;
  };

  const isDisabledReceiveButton = (claimed: boolean) =>
    !!selectedRows.length || (claimed && !pagePermissions.claimLoadReceive) || (!claimed && !pagePermissions.receive);

  const selectedAuthorityOption = useMemo(
    () => ({
      id: authorityIdData?.result?.id,
      name: authorityIdData?.result?.name,
      value: String(authorityIdData?.result?.id),
    }),
    [authorityIdData?.result]
  );

  useEffect(() => {
    setValue('authorityIds', [{ id: authorityId, value: authorityId }]);
  }, []);

  return {
    width,
    summary,
    count: authorityId ? authorities?.count + 1 : authorities?.count,
    authorities: [selectedAuthorityOption, ...(authorities?.result || [])],
    selectedRows,
    paymentDetails,
    paymentDetailsLoading,
    handleReceive,
    setSelectedRows,
    handleOpenLoadView,
    setAuthoritiesState,
    handleCheckboxChange,
    handlePayAllSelectedRows,
    handlePaymentDetailsClick,
    checkIsDisabledRowSelection,
    isDisabledReceiveButton,
  };
};

export default useReceivablesView;
