import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import { Col, RefSelectProps, Row, Select } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';
import { getHighlightedOptions } from 'utils/helpers';

import SearchIcon from 'components/svgs/SearchIcon';

import CloseIcon from '../../../../components/svgs/CloseIcon';
import useDebounce from '../../../../hooks/useDebounce';
import { ICoordinatorItem } from '../../../../pages/truck-board/constants/types';
import { SUBJECT } from '../constants/constants';

import { ISimpleSearchableSelect } from './constants/types';

import styles from './SimpleSearchableSelect.module.scss';

const SimpleSearchableSelect = forwardRef(
  (
    {
      options,
      placeholder,
      withMarking = true,
      onChange,
      handleClick,
      setValue,
      data,
      name,
      value,
      errors,
      offset,
      setOffset,
      required,
      showKey,
      label,
      onBlur,
      disabled,
      allowClear,
      ...props
    }: ISimpleSearchableSelect,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const debounceValue = useDebounce(searchValue, 500);

    const [isFocused, setIsFocused] = useState(false);
    const currentElement = document.getElementById(`current_select_searchable${label}${placeholder}`);

    const handleSearch = (value: string) => {
      currentElement?.focus();
      setIsSearching(true);
      setSearchValue(value);
    };

    const handleReset = () => {
      setSearchValue('');
      if (setValue) {
        setValue(name, []);
      }
      if (onChange) {
        onChange([]);
      }
    };

    useEffect(() => {
      !isSearching && currentElement?.blur();
    }, [isSearching]);

    useEffect(() => {
      if (isSearching && (debounceValue?.length >= 2 || !debounceValue?.length) && typeof searchValue === 'string') {
        setOffset((prev: any) => ({ ...prev, search: debounceValue, limit: 10 }));
      }
    }, [debounceValue]);

    const handleScroll = (event: any) => {
      const { target } = event;
      if (target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight) {
        if (options?.length < offset) {
          setOffset((prev: any) => {
            return { ...prev, limit: prev.limit + 10 };
          });
        }
      }
    };

    const handleBlur = () => {
      setTimeout(() => {
        if (onBlur) {
          onBlur();
          setSearchValue('');
        }
        setIsFocused(false);
      }, 700);
    };
    const handleChange = (value: string) => {
      currentElement?.blur();
      setIsSearching(false);
      setIsFocused(false);
      if (setValue && name) {
        setValue(name, value || []);
      }
    };

    useEffect(() => {
      if (!searchValue?.length && onChange && data) {
        onChange(data?.map((item: any) => item?.id));
      }
    }, [searchValue]);

    useEffect(() => {
      if (typeof value === 'string') {
        setSearchValue(value);
      }
    }, [value]);

    const dropdownRef = useRef<HTMLInputElement>(null);

    return (
      <div className={styles.searchableSelectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          className={clsx(styles.selectWrapper, {
            [styles.required]: required,
            [styles.showValue]: withMarking,
            [styles.hasValue]:
              (typeof value === 'string' && value?.length) || (typeof value === 'number' && value) || isFocused,
            [styles.errors]: errors && name && errors[name],
            [styles.disabled]: disabled,
            [styles.onFocus]: isFocused,
          })}
          id={`select_searchable${label}${placeholder}`}
          ref={dropdownRef}
        >
          <Select
            {...props}
            id={`current_select_searchable${label}${placeholder}`}
            getPopupContainer={() => document.getElementById(`select_searchable${label}${placeholder}`)!}
            ref={ref}
            showSearch={true}
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            showArrow
            onChange={handleChange}
            {...(allowClear ? { onClear: handleReset, clearIcon: <CloseIcon />, allowClear } : {})}
            autoClearSearchValue={false}
            suffixIcon={
              searchValue?.length ? (
                <div className={styles.clearIcon}>
                  <CloseIcon />
                </div>
              ) : (
                <SearchIcon />
              )
            }
            className={clsx(styles.selectMarkingWrapper, styles.select)}
            onSearch={handleSearch}
            popupClassName={styles.optionsContainer}
            value={
              isNaN(Number(value)) ? value : options?.find(el => el.id == value)?.[showKey as keyof ICoordinatorItem]
            }
            onBlur={handleBlur}
            dropdownRender={() => (
              <>
                {options?.length ? (
                  <div className={styles.headerItems}>
                    <Row>
                      <Col span={15}>
                        <span>{SUBJECT.USERNAME}</span>
                      </Col>
                      <Col span={9}>
                        <span>{SUBJECT.Id}</span>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <Typography variant="paragraph" className={styles.noDataText}>
                    {SUBJECT.NO_DATA}
                  </Typography>
                )}
                <div className={styles.optionsCustomWrapper} onScroll={handleScroll}>
                  {options?.map(item => {
                    const matchedUserName = getHighlightedOptions(
                      String(searchValue) || '',
                      String(item.username) || ''
                    );
                    const matchedId = getHighlightedOptions(String(searchValue) || '', String(item.id) || '');
                    return (
                      <div
                        key={item.id}
                        onClick={
                          handleClick
                            ? () => {
                                currentElement?.blur();
                                handleClick(item);
                                setIsSearching(false);
                                setIsFocused(false);
                              }
                            : () => null
                        }
                      >
                        <div className={styles.authoritiesContainer}>
                          <Row>
                            {matchedUserName.length ? (
                              <Col span={15}>
                                {matchedUserName[0]}
                                <span className={styles.searchResultKeyword}>{matchedUserName[1]}</span>
                                {matchedUserName[2]}
                              </Col>
                            ) : (
                              <Col span={15}>
                                <span>{item.username}</span>
                              </Col>
                            )}
                            {matchedId.length ? (
                              <Col span={9}>
                                {matchedId[0]}
                                <span className={styles.searchResultKeyword}>{matchedId[1]}</span>
                                {matchedId[2]}
                              </Col>
                            ) : (
                              <Col span={9}>
                                <span>{item.id}</span>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          />

          <Typography variant="paragraph" className={clsx(styles.placeholder)}>
            {placeholder}
          </Typography>
        </div>
      </div>
    );
  }
);

export default SimpleSearchableSelect;
