import { getTransactionsColumns } from './constants/helpers';
import { ITransactionsTableProps } from './constants/types';

const useTransactionsTable = ({
  handleRowDelete,
  hasDeletePermission,
  isCustomer,
  isCarrier,
  isSecondAgent,
}: ITransactionsTableProps) => {
  const transactionColumns = {
    transactions: getTransactionsColumns({
      handleRowDelete,
      hasDeletePermission,
      isCustomer,
      isCarrier,
      isSecondAgent,
    }),
  };

  return {
    transactionColumns,
  };
};

export default useTransactionsTable;
