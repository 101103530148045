import React from 'react';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { IRequestStatusText, RequestStatus, RequestStatusText } from 'services/loads/interface';
import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';

import CopyIcon from 'components/svgs/CopyIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import { INewCreditViewProps } from './constants/types';
import { useNewCreditView } from './useNewCreditView';

import styles from './NewCreditView.module.scss';

const NewCreditView = ({ data, isLoadingById, showActionModal }: INewCreditViewProps) => {
  const { copiedText, handleCopy } = useHandleCopyActive();
  const { handleCommentClick } = useNewCreditView();

  return (
    <>
      <MainLoader isFetching={isLoadingById} />

      <ViewItemWrapper
        title={SUBJECT.CREDIT_CHECK}
        Component={
          RequestStatus[data?.requestStatus as number] === 'Pending' && (
            <Button onClick={showActionModal} variant="comment" children={SUBJECT.ALL_ACTIONS} />
          )
        }
      >
        <Row justify="space-between" align="top" gutter={[10, 25]}>
          {data?.createdAt && (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={formatDate(new Date(data?.createdAt || 0))}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.LOAD_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === String(data?.code),
                  })}
                >
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.code}
                    subject={`${SUBJECT.LOAD_NUMBER}`}
                  />

                  <div
                    className={styles.copyIcon}
                    role="button"
                    onClick={() => handleCopy(String(data?.code), String(data?.code))}
                  >
                    <CopyIcon active={copiedText === String(data?.code)} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CUSTOMER_ID} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === 'customerId',
                  })}
                >
                  <Typography variant="paragraph" children={data?.customerId} className={styles.infoParagraph} />
                  <div
                    className={styles.copyIcon}
                    role="button"
                    onClick={() => handleCopy(String(data?.customerId), 'customerId')}
                  >
                    <CopyIcon active={copiedText === 'customerId'} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CUSTOMER_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.customerName}
                  subject={SUBJECT.CUSTOMER_NAME}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.OFFICE_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText prefixClass={styles.infoParagraph} data={data?.officeName} subject={SUBJECT.OFFICE_NAME} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.BRANCH_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText prefixClass={styles.infoParagraph} data={data?.branchName} subject={SUBJECT.BRANCH_NAME} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AGENT_USERNAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.agentName}
                  subject={SUBJECT.AGENT_USERNAME}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AMOUNT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.amount} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.REAL_AMOUNT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.realAmount} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DEBT_NOT_PAID} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.debtNotPaid} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DEBT_LOAD_QTY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.debtQty} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          {!!data?.debtLimit && (
            <Col span={10}>
              <Row gutter={10} align="middle">
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.DEBT_LIMIT} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" children={data?.debtLimit} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={RequestStatusText[RequestStatus[data?.requestStatus as number] as keyof IRequestStatusText]}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PAYMENT_METHOD} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.paymentMethod} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10} align="middle">
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.COMMENT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={`Comments ${data?.comments}`}
                  className={styles.infoCommentParagraph}
                  onClick={handleCommentClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </>
  );
};

export default NewCreditView;
