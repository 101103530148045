import dayjs from 'dayjs';

import { ActiveTabRoutes, IPaymentRoutes, IPayoutFilter } from './types';

export enum SUBJECT {
  DATE = 'Date',
  OFFICE = 'Agency',
  OFFICES = 'Agencies',
  BRANCH = 'Branch',
  BRANCHES = 'Branches',
  AGENT = 'Agent',
  AGENTS = 'Agents',
  NOT_SELECTED = 'Not selected',
  RESET_ALL = 'Reset All',
  BALANCE = 'Balance',
  CANCEL = 'Cancel',
  PAY = 'Pay',
  YOU_WILL_PAY = 'You Will Be Paying To',
  YOU_WILL_APPROVE = 'You Will Be Approving To',
  DIFFERENCE = '-100.00',
  DEBT = 'Debt',
  SAVE = 'Save',
  EDIT = 'Edit',
  FULL_INFO = 'Payment',
  AGENCY_NAME = 'Agency Name',
  PAID_AS = 'Paid As',
  PAID_DATE = 'Paid Date',
  GROSS = 'Gross',
  CHARGES = 'Charges',
  CREDIT = 'Credit',
  COLLECTION = 'Collection',
  FINAL_PAY = 'Final Pay',
  APPROVE = 'Approve',
  SUCCESFULLY_APPROVED = 'Successfully approved',
  SUCCESFULLY_PAID = 'Successfully paid',
}

export const AGENT_COLUMNS = [
  { key: 'firstName', name: 'Name' },
  { key: 'lastName', name: 'Last Name' },
  { key: 'username', name: 'Username' },
];

export const AGENT_KEYS = ['id', 'firstName', 'lastName', 'username'];

export const MOCK_CARDS = [
  {
    price: '111199999',
    name: 'Loads',
    id: '1',
  },
  {
    price: '111111',
    name: 'Potential Gross Profit',
    id: '2',
  },
  {
    price: -22222,
    name: 'Gross Profit',
    id: '3',
  },
  {
    price: '-33333',
    name: 'Credit',
    id: '4',
  },
  {
    price: -44444,
    name: 'Charges',
    id: '5',
  },
  {
    price: '0',
    name: 'Service Fee',
    id: '6',
  },
  {
    price: '0',
    name: 'Other Service Fee',
    id: '7',
  },
  {
    price: '0',
    name: 'Load Fee',
    id: '8',
  },
  {
    price: 343434344,
    name: 'Advance',
    id: '9',
  },
  {
    price: 4343434,
    name: 'Collection',
    id: '10',
  },
];

export const FILTER_DEFAULT_VALUES: IPayoutFilter = {
  officePayout: [],
  branchPayout: [],
  agentPayout: [],
  monthPickerPayout: {
    startDate: dayjs().startOf('month').subtract(1, 'month').valueOf(),
    endDate: dayjs().endOf('month').valueOf().toString(),
  },
};

export const MOCK_DATA_FILTER = [
  { title: 'Office1', value: 'Office1' },
  { title: 'Office2', value: 'Office2' },
];

export const paymentRoutes: IPaymentRoutes = {
  Agency: ActiveTabRoutes.Agency,
  Branch: ActiveTabRoutes.Branch,
  Agent: ActiveTabRoutes.Agent,
};

export const ACTIVE_TAB_LEVEL = {
  Agency: 3,
  Branch: 2,
  Agent: 1,
};
