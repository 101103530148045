import { useForm } from 'react-hook-form';

import { defaultValues } from './constants/constants';
import { ILoadsCommentFormData } from './constants/types';

const useNewLoadsComment = () => {
  const { control, watch, setValue } = useForm<ILoadsCommentFormData>({
    defaultValues,
  });

  return {
    control,
    watch,
    setValue,
  };
};

export default useNewLoadsComment;
