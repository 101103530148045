import { Col, Row } from 'antd';
import clsx from 'clsx';
import { IPaymentListItemResponse } from 'services/payroll/payment/types';
// import Button from 'ui/button/Button';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from '../../constants/constants';

import styles from './PaymentView.module.scss';

interface IPaymentViewProps {
  data: {
    result: IPaymentListItemResponse;
  };
  isFetching: boolean;
  handlePay: () => void;
}

export const PaymentView = ({ data, isFetching, handlePay: _handlePay }: IPaymentViewProps) => {
  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetching} />
      <ViewItemWrapper
        title={SUBJECT.FULL_INFO}
        // Component={<Button onClick={handlePay} children={SUBJECT.EDIT} className="viewDrawerEditBtn" />}
      >
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.result?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AGENCY_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={data?.result?.office?.name}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          {!!data?.result?.branch?.name && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.BRANCH} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.branch?.name}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!data?.result?.agent?.firstName && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.AGENT} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={`${data?.result?.agent?.firstName} ${data?.result?.agent?.lastName}`}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!data?.result?.paidAs && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.PAID_AS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" children={data?.result?.paidAs} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
          )}
          {!!data?.result?.paidAt && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.PAID_DATE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={formatDate(data?.result?.paidAt)}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.GROSS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatNumberWithThousandsSeparatorAndDecimal(data?.result?.grossProfit, true)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CHARGES} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatNumberWithThousandsSeparatorAndDecimal(data?.result?.charges, true)}
                  className={clsx(styles.infoParagraph, {
                    [styles.negative]: data?.result?.charges < 0,
                  })}
                />
              </Col>
            </Row>
          </Col>
          {!!data?.result?.credits && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.CREDIT} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={formatNumberWithThousandsSeparatorAndDecimal(data?.result?.credits, true)}
                    className={clsx(styles.infoParagraph)}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!data?.result?.collection && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.COLLECTION} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={formatNumberWithThousandsSeparatorAndDecimal(data?.result?.collection, true)}
                    className={clsx(styles.infoParagraph, {
                      [styles.negative]: data?.result?.collection < 0,
                    })}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.FINAL_PAY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatNumberWithThousandsSeparatorAndDecimal(data?.result?.final, true)}
                  className={clsx(styles.infoParagraph, {
                    [styles.negative]: data?.result?.final < 0,
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </div>
  );
};
