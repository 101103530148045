export const BlueInfoIcon = ({ size = '24', disabled = false }) => {
  return (
    <svg
      width={+size + 1}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={disabled ? 'not-allowed' : 'pointer'}
    >
      <path
        d="M10.2575 16.5H11.9075V9.9H10.2575V16.5ZM11 7.865C11.2567 7.865 11.4721 7.7825 11.6462 7.6175C11.8204 7.4525 11.9075 7.24167 11.9075 6.985C11.9075 6.72833 11.8204 6.50833 11.6462 6.325C11.4721 6.14167 11.2567 6.05 11 6.05C10.7433 6.05 10.5279 6.14167 10.3538 6.325C10.1796 6.50833 10.0925 6.72833 10.0925 6.985C10.0925 7.24167 10.1796 7.4525 10.3538 7.6175C10.5279 7.7825 10.7433 7.865 11 7.865ZM11 22C9.49667 22 8.07583 21.7113 6.7375 21.1338C5.39917 20.5562 4.23042 19.7679 3.23125 18.7688C2.23208 17.7696 1.44375 16.6008 0.86625 15.2625C0.28875 13.9242 0 12.4942 0 10.9725C0 9.46917 0.28875 8.04833 0.86625 6.71C1.44375 5.37167 2.23208 4.2075 3.23125 3.2175C4.23042 2.2275 5.39917 1.44375 6.7375 0.86625C8.07583 0.28875 9.50583 0 11.0275 0C12.5308 0 13.9517 0.28875 15.29 0.86625C16.6283 1.44375 17.7925 2.2275 18.7825 3.2175C19.7725 4.2075 20.5562 5.37167 21.1338 6.71C21.7113 8.04833 22 9.47833 22 11C22 12.5033 21.7113 13.9242 21.1338 15.2625C20.5562 16.6008 19.7725 17.7696 18.7825 18.7688C17.7925 19.7679 16.6283 20.5562 15.29 21.1338C13.9517 21.7113 12.5217 22 11 22Z"
        fill="#379FFF"
      />
    </svg>
  );
};
