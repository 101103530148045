import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { getFavoriteListSerializer } from './serializers';

export const favoriteAPI = createApi({
  reducerPath: 'favoriteAPI',
  baseQuery,
  tagTypes: ['Favorite'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getFavoriteList: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<any>) => ({
        url: 'truck-board/favorites',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getFavoriteListSerializer(body),
      providesTags: ['Favorite'],
    }),
    createFavorite: builder.mutation({
      query: ({ id }) => ({
        url: `carriers/${id}/favorite`,
        method: 'POST',
      }),
      invalidatesTags: ['Favorite'],
    }),
    deleteFavorite: builder.mutation({
      query: ({ id }) => ({
        url: `carriers/${id}/favorite`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorite'],
    }),
  }),
});

export const { useGetFavoriteListQuery, useCreateFavoriteMutation, useDeleteFavoriteMutation } = favoriteAPI;
