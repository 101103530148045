import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import CanceledIcon from 'components/svgs/CanceledIcon';
import CarrierCustomerPendingIcon from 'components/svgs/CarrierCustomerPendingIcon';
import CarrierCutomerPaidIcon from 'components/svgs/CarrierCutomerPaidIcon';
import NoProcessingIcon from 'components/svgs/NoProcessingIcon';
import ProcessingIcon from 'components/svgs/ProcessingIcon';
import { RequestCheck } from 'components/svgs/RequestCheck';

import { SUBJECT } from './constants';

import styles from '../LoadStatus.module.scss';

export const renderLoadStatus = ({ isProcessing, status }: { isProcessing: boolean; status: number }) => {
  const statuses = {
    0: null,
    1: (
      <div className={clsx(styles.statusItem, styles.requestCheck)}>
        <RequestCheck />
        <Typography variant="paragraph" className={styles.statusText} children={SUBJECT.LOAD_STATUS_REQUEST_CHECK} />
      </div>
    ),
    2: (
      <div className={clsx(styles.statusItem, styles.denied)}>
        <CanceledIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.LOAD_STATUS_DENIED}
        </Typography>
      </div>
    ),
    3: (
      <div className={clsx(styles.statusItem, styles.notProcessed)}>
        <NoProcessingIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.LOAD_STATUS_NOT_PROCESSING}
        </Typography>
      </div>
    ),
    4: isProcessing ? (
      <div className={clsx(styles.statusItem, styles.processing)}>
        <ProcessingIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.LOAD_STATUS_PROCESSING}
        </Typography>
      </div>
    ) : null,
  };
  return statuses[status as keyof typeof statuses];
};

export const renderCustomerStatus = ({
  isProcessing,
  customerStatus,
}: {
  isProcessing: boolean;
  customerStatus: number;
}) => {
  const statuses = {
    0: null,
    1: !isProcessing && (
      <div className={clsx(styles.statusItem, styles.customerPending)}>
        <CarrierCustomerPendingIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.CUSTOMER_PAYMENT_PENDING}
        </Typography>
      </div>
    ),
    2: !isProcessing && (
      <div className={clsx(styles.statusItem, styles.carrierPaid)}>
        <CarrierCutomerPaidIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.LOAD_STATUS_CUSTOMER_PAID}
        </Typography>
      </div>
    ),
    3: !isProcessing && (
      <div className={clsx(styles.statusItem, styles.customerPending)}>
        <CarrierCustomerPendingIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.CUSTOMER_PAYMENT_PENDING}
        </Typography>
      </div>
    ),
  };
  return statuses[customerStatus as keyof typeof statuses];
};

export const renderCarrierStatus = ({ carrierStatus }: { carrierStatus: number }) => {
  const statuses = {
    0: null,
    1: carrierStatus === 1 && (
      <div className={clsx(styles.statusItem, styles.carrierPending)}>
        <CarrierCustomerPendingIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.CARRIER_PAYMENT_PENDING}
        </Typography>
      </div>
    ),
    2: carrierStatus === 2 && (
      <div className={clsx(styles.statusItem, styles.carrierPaid)}>
        <CarrierCutomerPaidIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.LOAD_STATUS_CARRIER_PAID}
        </Typography>
      </div>
    ),
    3: carrierStatus === 3 && (
      <div className={clsx(styles.statusItem, styles.carrierPending)}>
        <CarrierCustomerPendingIcon />
        <Typography variant="paragraph" className={styles.statusText}>
          {SUBJECT.CARRIER_PAYMENT_PENDING}
        </Typography>
      </div>
    ),
  };
  return statuses[carrierStatus as keyof typeof statuses];
};
