export const filterDefaultValues = {
  header_select: '',
  header_input: '',
};

export const headerSearchOptions = (loadView: boolean) => {
  const excludeLoad = !loadView;

  return [
    { value: 'loads', title: 'Loads' },
    { value: 'carriers', title: 'Carriers' },
    { value: 'customers', title: 'Customers' },
  ]?.filter(el => {
    if (excludeLoad) return el.value !== 'loads';
    return el;
  });
};
export const allowedHeaderOptionsVariants = ['carriers', 'customers', 'loads'];
