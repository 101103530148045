import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  updated: null,
};

export const loadSlice = createSlice({
  name: 'loads',
  initialState,
  reducers: {
    setUpdateLoads: (state, { payload: updated }) => {
      state.updated = updated;
    },
  },
});

export const { setUpdateLoads } = loadSlice.actions;

export default loadSlice.reducer;
