import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Typography from 'ui/typography/Typography';
import { FileTypesText } from 'ui/upload-row/constants/types';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { multiplyDownloadWithDelay, multiplyViewWithDelay } from 'utils/helpers';

import { TrashIcon } from 'components/svgs/TrashIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import Button from '../../ui/button/Button';
import DownloadIcon from '../svgs/DownloadIcon';
import EyeIconSvg from '../svgs/EyeIconSvg';

import { SUBJECT } from './constants/constants';
import { filesFormatter, filesNoaFormatter, invoiceFilesFormatter } from './constants/helpers';
import { IBillingBlockProps, PaymentTermType } from './constants/types';

import styles from './BillingBlock.module.scss';

const BillingBlock = ({
  paymentTermType,
  noa,
  redConfirmation,
  pod,
  invoice,
  onRejectClick,
  onAcceptClick,
  onCreateInvoice,
  onDeleteInvoice,
  processing,
  mailableFiles,
  hasAcceptToBillingPermission,
  hasClaimLoadAcceptToBillingPermission,
  hasRejectBillingPermission,
  hasClaimLoadRejectPermission,
  isLoadClaimed,
  isSendedToBilling,
  loadData,
  isFetching,
  isCanceledClaimed,
  isInvoiceLoading,
}: IBillingBlockProps) => {
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const retrieved = !!loadData?.load?.retrieved;
  const retrievedAt = loadData?.load?.retrievedAt;

  const invoiceData = [...invoice].reverse();

  const hasAcceptPermission =
    (!isLoadClaimed && hasAcceptToBillingPermission) || (isLoadClaimed && hasClaimLoadAcceptToBillingPermission);

  const hasRejectPermission =
    (!isLoadClaimed && hasRejectBillingPermission) || (isLoadClaimed && hasClaimLoadRejectPermission);

  const acceptToBillingButtonCheck = !(processing && !loadData?.canCreateInvoice);

  const hasAccessToCreateInvoice =
    (employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN) &&
    (hasAcceptToBillingPermission || hasClaimLoadAcceptToBillingPermission);

  // const createInvoiceHandler = () => {
  //   if (loadData?.load?.isPaymentTerm && loadData?.load?.customerFinalAmount > 0) {
  //     return true;
  //   } else {
  //     if (!retrieved) {
  //       if (loadData?.load?.loadType === LoadTypes.Tonu && isLoadHasCustomer && !invoiceData?.length) {
  //         return true;
  //       } else if (loadData?.load?.loadType !== LoadTypes.Tonu && processing && !invoiceData?.length && ) {
  //         return processing;
  //       } else {
  //         return false;
  //       }
  //     } else {
  //       return loadData?.canCreateInvoice;
  //     }
  //   }
  // };

  const deleteInvoiceHandler = (createdAt: number) => {
    if (!retrieved) {
      return processing;
    } else {
      return createdAt > retrievedAt;
    }
  };

  const isButtonsInside =
    !loadData?.load?.customer?.id && !noa?.length && !redConfirmation?.length && !pod?.length && !invoiceData?.length;

  return (
    <div className={styles.billingWrapper}>
      <ViewItemWrapper
        title={SUBJECT.BILLING}
        canceledBlock={isCanceledClaimed}
        Component={
          <>
            {isButtonsInside && !isFetching && (
              <>
                {(!processing || !isSendedToBilling) && (
                  <Typography
                    className={styles.acceptedToBilling}
                    children={SUBJECT.ACCEPTED_TO_BILLING}
                    variant="paragraph"
                  />
                )}
                {processing && isSendedToBilling && (
                  <>
                    {hasRejectPermission && (
                      <>
                        <Button
                          className="smallBtn"
                          variant="danger"
                          children={SUBJECT.REJECT}
                          onClick={onRejectClick}
                        />
                      </>
                    )}
                    {hasAcceptPermission && (
                      <>
                        <Button
                          className="smallBtn"
                          variant="default"
                          children={SUBJECT.ACCEPT}
                          disabled={acceptToBillingButtonCheck}
                          onClick={onAcceptClick}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        }
      >
        <Row align="middle" className={styles.billingOverlay}>
          <Col span={8}>
            <Typography
              level={5}
              variant="heading"
              className={styles.factored}
              children={
                loadData?.load?.customer?.id
                  ? paymentTermType === PaymentTermType.BILLING_TYPES_FACTORING
                    ? SUBJECT.CUSTOMER_IS_FACTORED
                    : SUBJECT.CUSTOMER_IS_NOT_FACTORED
                  : null
              }
            />
          </Col>
          {!isButtonsInside && !isFetching && (
            <Col span={16}>
              {(!processing || !isSendedToBilling) && (
                <Typography
                  className={styles.acceptedToBilling}
                  children={SUBJECT.ACCEPTED_TO_BILLING}
                  variant="paragraph"
                />
              )}
              {processing && isSendedToBilling && (
                <Row justify="end" gutter={10}>
                  {hasRejectPermission && (
                    <Col>
                      <Button className="smallBtn" variant="danger" children={SUBJECT.REJECT} onClick={onRejectClick} />
                    </Col>
                  )}
                  {hasAcceptPermission && (
                    <Col>
                      <Button
                        className="smallBtn"
                        variant="default"
                        children={SUBJECT.ACCEPT}
                        disabled={acceptToBillingButtonCheck}
                        onClick={onAcceptClick}
                      />
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          )}
        </Row>
        {!isButtonsInside ? <Divider /> : null}
        <div className={styles.uploadedList}>
          {!!noa?.length &&
            noa?.map((item: any) => {
              return (
                <Row align="middle" key={item?.id}>
                  <Col span={6}>
                    <Typography variant="paragraph">{SUBJECT.NOA}</Typography>
                  </Col>
                  <Col span={3}>
                    <div className={styles.uploadedButtons}>
                      <div
                        className={clsx(styles.uploadControl, styles.eyeIcon)}
                        onClick={() => multiplyViewWithDelay(filesNoaFormatter([item]))}
                      >
                        <EyeIconSvg />
                      </div>
                      <div
                        className={clsx(styles.uploadControl, styles.downloadBtn)}
                        onClick={() => multiplyDownloadWithDelay(filesNoaFormatter([item]))}
                      >
                        <DownloadIcon />
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          {!!redConfirmation?.length && (
            <Row align="middle">
              <Col span={6}>
                <Typography className={styles.fileTyleHeading} variant="paragraph">
                  {SUBJECT.RED_CONFIRMATION}
                </Typography>
              </Col>
              <Col span={3}>
                <div className={styles.uploadedButtons}>
                  <div
                    className={clsx(styles.uploadControl, styles.eyeIcon)}
                    onClick={() => multiplyViewWithDelay(filesFormatter(redConfirmation))}
                  >
                    <EyeIconSvg />
                  </div>
                  <div
                    className={clsx(styles.uploadControl, styles.downloadBtn)}
                    onClick={() => multiplyDownloadWithDelay(filesFormatter(redConfirmation))}
                  >
                    <DownloadIcon />
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {mailableFiles?.map((item: any) => {
            return (
              <Row align="middle">
                <Col span={6}>
                  <Typography className={styles.fileTyleHeading} variant="paragraph">
                    {FileTypesText[item?.type as keyof typeof FileTypesText] || item.type}
                  </Typography>
                </Col>
                <Col span={3}>
                  <div className={styles.uploadedButtons}>
                    <div
                      className={clsx(styles.uploadControl, styles.eyeIcon)}
                      onClick={() => multiplyViewWithDelay(filesFormatter([item]))}
                    >
                      <EyeIconSvg />
                    </div>
                    <div
                      className={clsx(styles.uploadControl, styles.downloadBtn)}
                      onClick={() => multiplyDownloadWithDelay(filesFormatter([item]))}
                    >
                      <DownloadIcon />
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
          {invoiceData?.length
            ? invoiceData?.map((item: any) => {
                return (
                  <Row align="middle" key={item?.id}>
                    <Col span={6}>
                      <Typography className={styles.fileTyleHeading} variant="paragraph">
                        {SUBJECT.INVOICE}
                      </Typography>
                    </Col>
                    <Col span={3}>
                      <div className={styles.uploadedButtons}>
                        <div
                          className={clsx(styles.uploadControl, styles.eyeIcon)}
                          onClick={() => multiplyViewWithDelay(invoiceFilesFormatter(item))}
                        >
                          <EyeIconSvg />
                        </div>
                        <div
                          className={clsx(styles.uploadControl, styles.downloadBtn)}
                          onClick={() => multiplyDownloadWithDelay(invoiceFilesFormatter(item))}
                        >
                          <DownloadIcon />
                        </div>
                        {deleteInvoiceHandler(item?.createdAt) && (
                          <div className={clsx(styles.uploadControl, styles.trashIcon)} onClick={onDeleteInvoice}>
                            <TrashIcon />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                );
              })
            : null}
          {!isInvoiceLoading && hasAccessToCreateInvoice && loadData?.canCreateInvoice && !isFetching && (
            <Button
              variant="link"
              children={SUBJECT.CREATE_INVOICE}
              onClick={onCreateInvoice}
              className={styles.createInvoice}
            />
          )}
        </div>
      </ViewItemWrapper>
    </div>
  );
};

export default BillingBlock;
