export enum SUBJECT {
  ARE_YOU_SURE_REMOVE = 'You Are About To Deactivate A Favorite Item. Please Be Aware That Deactivating This Item Will Also Deactivate All Items Connected To It. This Action Cannot Be Undone.',
  REMOVE = 'Remove',
  SUCCESSFULLY_REMOVED = 'Successfully Removed',
  ARE_YOU_SURE_DELETE = 'Are You Sure Want To Delete Truck From Favorite List ?',
  SUCCESSFULLY_ADDED = 'Successfully Added To Favorite',
  SUCCESSFULLY_DELETED = 'Successfully Deleted From Favorite',
  CANCEL = 'Cancel',
  DELETE = 'Delete',
  FAVORITE_TRUCKS_LIST_EMPTY = 'Favorite Trucks List Is Empty',
  RESET_ALL = 'Reset All',
  CARRIER_STATUS = 'Carrier Status',
  COORDINATOR = 'Coordinator',
  NOT_SELECTED = 'Not Selected',
  CARRIER_STATUS_FILTER = 'carrierStatus',
  COORDINATOR_FILTER = 'coordinatorFilter',
}
export const filterDefaultValues = {
  carrierStatus: null,
  coordinatorFilter: null,
};

export const carrierStatusOptions = [
  { value: '1', title: 'Standard' },
  { value: '2', title: 'Already Has a Load' },
  { value: '3', title: 'Refused To Reload' },
];

export enum equipmentTypeText {
  'Van' = 1,
  'Reefer' = 2,
  'Flat bed' = 3,
  'Power only' = 4,
  'Box truck' = 5,
}

export enum carrierStatusText {
  'Standard' = 1,
  'Already Has a Load' = 2,
  'Refused To Reload' = 3,
}

export enum trailerTypeText {
  'E-Truck' = 1,
  'High Cube' = 2,
  'Plate Trailer' = 3,
  'Hazmat' = 4,
}
