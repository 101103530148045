export enum SUBJECT {
  BECOME_A_PREFERRED_CARRIER = 'Become a preferred carrier on the Freightmax app?',
  VIEW_PREFERRED_CARRIER_BENEFITS = 'View Preferred Carrier Benefits',
  CANCEL = 'Cancel',
  PROVIDE = 'Please provide additional information. Select the type of consultation you require.',
  FREIGHTMAX = 'Freightmax: Your Reliable Trucking Partner. With a fleet of modern trucks and experienced drivers, we ensure timely and safe deliveries nationwide. Let us handle your freight needs with professionalism and efficiency.',
  FUEL_ADVANCE = 'Fuel Advance',
  LOAD_BOARD = 'Load Board',
  LIVE_TRUCK_BOARD = 'Live Truck Board',
  SUPPORT = '24/7 support',
  IAGREE = 'I Agree',
  QUICK = 'Quick Pay',
  FACTORING = 'Factoring',
  NOT_INTERESTED = 'Not Interested',
}
