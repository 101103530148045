import { IOptions } from './types';

export enum SUBJECT {
  EQUIPMENT_TYPE = 'Equipment Type',
  NOT_SELECTED = 'Not Selected',
  TRAILER_DETAILS = 'Trailer Details',
  STATUS = 'Status',
  RESET_ALL = 'Reset All',
  CHECK_EMAIL = 'Please Check Your Email',
}

export const equipmentTypeOptions: IOptions[] = [
  {
    title: 'Van',
    value: '1',
  },
  {
    title: 'Reefer',
    value: '2',
  },
  {
    title: 'Flat Bed',
    value: '3',
  },
  {
    title: 'Power Only',
    value: '4',
  },
  {
    title: 'Box Truck',
    value: '5',
  },
];

export const trailerDetailsOptions: IOptions[] = [
  {
    title: 'E-Truck',
    value: '1',
  },
  {
    title: 'High Cube',
    value: '2',
  },
  {
    title: 'Plate Trailer',
    value: '3',
  },
  {
    title: 'Hazmat',
    value: '4',
  },
];

export const singleFilterDefaultValues = {
  equipmentTypeSingle: [],
  trailerDetailsSingle: [],
};
