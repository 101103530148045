import { createSlice } from '@reduxjs/toolkit';

import { permissionsSerializer } from '../sidebarSlice/serializer';

import { IUser } from './interface';

export const initialState: IUser = {
  token: null,
  userInfo: { email: '', firstName: '', id: '', lastName: '', phone: '', username: '', modeType: 0 },
  permissionsInfo: {},
  agentType: 0,
  employeeLevel: 0,
  branchIds: [],
  officeIds: [],
  defaultBranchId: 0,
  permissionGroupId: 0,
  userType: 0,
  isFetching: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateAuth: (state, { payload }) => {
      state.token = payload.token;
    },
    logOut: state => {
      state.token = null;
    },
    setMe: (state, { payload: { data, isFetching } }) => {
      state.userInfo = data;
      state.permissionsInfo = permissionsSerializer(data);
      state.employeeLevel = data?.employeeLevel;
      state.branchIds = data?.branchIds;
      state.officeIds = data?.officeIds;
      state.defaultBranchId = data?.defaultBranchId;
      state.permissionGroupId = data?.permissionGroupId;
      state.userType = data?.type;
      state.agentType = data?.agentType;
      state.isFetching = isFetching;
    },
    setIsFetching: (state, { payload: { isFetching } }) => {
      state.isFetching = isFetching;
    },
  },
});

export const { updateAuth, logOut, setMe, setIsFetching } = userSlice.actions;

export default userSlice.reducer;
