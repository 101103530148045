import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { SUBJECT } from 'pages/profile/tabs/carriers/constants/constants';
import {
  useChangeStatusMutation,
  useGetCarrierInsuranceCountsQuery,
  useLazyGetCarrierRatingQuery,
} from 'services/profile/carriers/carriers';
import { useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { usePermittedActions } from '../../../../../../hooks/usePermittedActions';
import { ICarriersFilter } from '../../constants/types';

import { ICarriersTableProps } from './constants/types';

const useCarriersTable = ({
  watchFilter,
  setFilterValue,
  resetFilterForm,
  setExcelParams,
  carriersFilter,
  setCarriersFilter,
}: ICarriersTableProps) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { carrier_type, rating, insuranceDay, activePage, open, mode, tab } = searchParams;

  const carrierTypeFilter = watchFilter('carrierType');
  const ratingFilter = watchFilter('ratings')?.toString();

  const inSearchPage = window.location.pathname === '/search-result';

  const { data: insuranceDays = [], refetch } = useGetCarrierInsuranceCountsQuery(carriersFilter, {
    skip: inSearchPage,
  });

  const { data: columnsData } = useGetColumnsQuery({ type: 'carriers' });
  const [editColumns] = useEditColumnsMutation();

  const [ratesParams] = useState({
    skip: 0,
    limit: 50,
  });

  const [getCarrierRating, { data: rateList }] = useLazyGetCarrierRatingQuery();

  const [changeStatus] = useChangeStatusMutation();

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(carrierTypeFilter ? { carrier_type: carrierTypeFilter } : {}),
      ...(ratingFilter && ratingFilter?.length ? { rating: ratingFilter } : { rating: '' }),
      ...(carriersFilter.insuranceDays !== 0
        ? { insuranceDay: String(carriersFilter.insuranceDays) }
        : { insuranceDay: '' }),
    });
  }, [carrierTypeFilter, ratingFilter?.length, carriersFilter.insuranceDays]);

  useEffect(() => {
    if (carrierTypeFilter) {
      refetch();
    }
  }, [carrierTypeFilter]);

  useEffect(() => {
    const filterOptions = [
      { key: 'carrierType', value: carrier_type },
      { key: 'ratings', value: rating ? rating.split(',') : rating },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (key === 'ratings') {
        setFilterValue(
          key as keyof ICarriersFilter,
          value?.includes('0') ? Array.isArray(value) && value?.filter((item: any) => item !== '0') : value
        );
      } else {
        setFilterValue(key as keyof ICarriersFilter, value);
      }
      setCarriersFilter((prev: any) => ({
        ...prev,
        skip: 0,
        filter: {
          ...(carrier_type && carrier_type !== '0' ? { type: carrier_type } : {}),
          ...(key === 'ratings' && value && value?.length !== 6 ? { ratings: value } : {}),
        },
        insuranceDays: insuranceDay || 0,
      }));
    });
  }, [carrier_type, rating]);

  const carrierTablePagePermissions = usePermittedActions('profiles.carriers.carrierTable');

  const handleResetFilters = () => {
    resetFilterForm();
    setSearchParams({
      mode,
      tab,
      open,
      activePage,
    });
    setCarriersFilter({
      search: '',
      field: '',
      skip: 0,
      limit: 20,
      order: 2,
      orderBy: 'createdAt',
      filter: {},
      insuranceDays: 0,
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setCarriersFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(carrierTypeFilter ? { type: carrierTypeFilter } : {}),
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleRowEdit = (rowId: number | string) => {
    setSearchParams({
      ...searchParams,
      ...(carrierTypeFilter ? { type: carrierTypeFilter } : {}),
      mode: 'edit',
      open: 'true',
      id: String(rowId),
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setCarriersFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          orderBy: 'createdAt',
          order: 2,
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleSortByInsurance = (insuranceDay: number) => {
    setCarriersFilter((prev: any) => {
      if (prev.insuranceDays === insuranceDay)
        return {
          ...prev,
          insuranceDays: 0,
        };
      return {
        ...prev,
        insuranceDays: insuranceDay,
      };
    });

    setSearchParams({
      ...searchParams,
    });
  };

  const handleStopResize = (columns: any): void => {
    editColumns({ type: 'carriers', columns });
  };

  const handleRateClick = () => {
    // Function that is called when you change Rate value, takes new value and the row id (id of entity)
    return null;
  };

  const handleGetRatings = (id: number) => {
    getCarrierRating({ skip: ratesParams.skip, limit: ratesParams.limit, carrierId: Number(id) });
  };

  const handleCommentClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(carrierTypeFilter ? { type: carrierTypeFilter } : {}),
      mode: 'comments',
      open: 'true',
      id: rowId,
    });
  };

  const handleSwitchClick = (checked: boolean, rowId: number | string) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({ id: rowId, status: checked ? 1 : 2 }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setCarriersFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  useEffect(() => {
    return () => {
      setCarriersFilter((prev: any) => ({
        ...prev,
        insuranceDays: 0,
      }));
    };
  }, [activePage]);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...carriersFilter,
        };
      });
    }
  }, [carriersFilter]);

  return {
    columns: columnsData?.columns,
    handleResetFilters,
    handleColumnSearch,
    handleRowClick,
    handleRowEdit,
    handleStopResize,
    handleSortClick,
    handleRateClick,
    handleCommentClick,
    handleSwitchClick,
    handlePaginationClick,
    handleSortByInsurance,
    insuranceDays,
    rateList,
    handleGetRatings,
    rating,
    insuranceDay,
    carrierTypeFilter,
    carrierTablePagePermissions,
  };
};

export default useCarriersTable;
