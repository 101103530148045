import React from 'react';

const InfoIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C4.48606 0 0 4.4238 0 9.86122C0 15.2986 4.48606 19.7224 10 19.7224C15.5139 19.7224 20 15.2986 20 9.86122C20 4.4238 15.5139 0 10 0ZM10 15.9297C8.91923 15.9297 8.07692 15.4437 8.07692 14.4126H11.9231C11.9087 15.4257 11.0716 15.9297 10 15.9297ZM15.3846 13.654H4.61538V12.3265L5.96154 10.6198V9.08844C5.96154 7.17261 6.72212 5.51612 8.65385 5.09322L8.84615 3.79278H11.1538L11.3462 5.09322C13.2692 5.51612 14.0385 7.17925 14.0385 9.08844V10.6198L15.3846 12.3265V13.654Z"
        fill="var(--black)"
      />
    </svg>
  );
};

export default InfoIcon;
