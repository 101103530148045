import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, Row, Spin, Tooltip } from 'antd';
import clsx from 'clsx';
import { PickupsDropoffs } from 'pages/loads/components/create-load/components/pickups-dropoffs/PickupsDropoffs';
import { ITransactionTypes, SUBJECT, TRANSACTION_TYPES } from 'pages/loads/components/edit-load/constants/constants';
import AutoRateConfirmation from 'pages/loads/tabs/loads-tab/components/auto-rate-confirmation/AutoRateConfirmation';
import RegularBanner from 'pages/loads/tabs/loads-tab/components/loads-comment/components/regular-banner/RegularBanner';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import Button from 'ui/button/Button';
import { LoadStatus } from 'ui/load-status/constants/types';
import LoadStatusComponent from 'ui/load-status/LoadStatus';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import UploadRow from 'ui/upload-row/UploadRow';
import { formatDate } from 'utils/dates';
import {
  floatNumberFormatter,
  floatNumberFormatterWithMinus,
  formatNumberWithThousandsSeparatorAndDecimal,
  multiplyDownloadWithDelay,
} from 'utils/helpers';

import BillingBlock from 'components/billing-block/BillingBlock';
import { PaymentTermType, PaymentTermTypeText } from 'components/billing-block/constants/types';
import CarrierPaymentBreakdown from 'components/carrier-payment-breakdown/CarrierPaymentBreakdown';
import CustomComment from 'components/custom-comment/CustomComment';
import DollarIcon from 'components/svgs/DollarIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import TransactionsTable from 'components/transactions-table/TransactionsTable';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { IRoleLevel } from '../../../../../../services/truck-board/carrier-request/interfaces';
import { selectUserPermissionsInfo } from '../../../../../../store/user-slice/selector';
import { EmployeeLevels, UserTypes } from '../../../../../../utils/constants';
import { AUTHORITIES_CUSTOM_COLUMNS, AUTHORITIES_CUSTOM_KEYS } from '../../../create-load/constants/constants';
import { transactionsForDataGrind } from '../../constants/helpers';
import { BookedTypes, LoadCancelStatus, LoadTypes, TakenType, TransportCapabilityTypes } from '../../constants/types';
import { Input, SearchableDropdown, Switch, TransactionInput, TransactionSelect } from '../../hoc-instance/instance';

import { useRegularLoad } from './useRegularEdit';

import styles from './RegularEdit.module.scss';

const RegularEdit = ({
  data,
  createLoadForm,
  loadTransactions,
  loadPointsAutocomplete,
  loadFiles,
  mode,
  loadPoints,
  comments,
  createComment,
  deleteComment,
  getMoreComments,
  isFilesFetching,
  isShowMoreVisible,
  refetchTransaction,
  refetchLoadById,
  refetchFilesByLoadId,
  refetchLoadPoints,
  loadHistory,
  isFetching,
  getMoreNotes,
  isShowMoreVisibleNotes,
  loadsPagePermissions,
  roleLevel,
  invalidLoadPointIds,
  connectingError,
  regularError,
  isFetchingHistory,
  refetchRateConfirmations,
  isLoadsCommentsLoading,
  handleCreateRateConfirmation,
  rateConfirmations,
  createRateConfCheck,
  pointsState,
  setPointsState,
  handleSendRateConfirmation,
  insurance,
  filledPickUpDropOffs,
  submitted,
}: any) => {
  const {
    control,
    watch,
    setValue,
    errors,
    activeSection,
    handleSwitchSoldUs,
    handleSwitchMarkAsClaim,
    handleCancel,
    handleSave,
    handleDownloadFileEdit,
    handleViewFileEdit,
    handleDeleteFileEdit,
    beforeUploadForEdit,
    allFiles,
    transactionWatch,
    transactionTrigger,
    transactioControl,
    isTransactionValid,
    transactionSetValue,
    isInvoiceLoading,
    handleCreateTransaction,
    handleDeleteTransaction,
    handleViewFileEditPOD,
    handleDeleteFileEditPOD,
    handleDownloadFileEditPOD,
    beforeUploadForEditPOD,
    allFilesPOD,
    handleEditFile,
    handleSendToBilling,
    handleAcceptToBilling,
    handleRejectBilling,
    handleCreateInvoice,
    handleCancelLoad,
    handleDeleteInvoice,
    setAuthoritiesState,
    authorities,
    isSaveButtonVisible,
    isSendToBillingDisabled,
    isSendedToBilling,
    handleResetTransactionForm,
    transactionDirtyFields,
    isDisableSoldUs,
    isDisableReference,
    isCreateTransactionLoading,
    userId,
    breakdowns,
    isBreakdownLoading,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    breakdownParams,
    mailableFiles,
    addTransactionCheck,
    drawerRef,
    handleScrollToHistory,
    isDefaultHistory,
    setIsDefaultHistory,
  } = useRegularLoad({
    data,
    createLoadForm,
    isFetching,
    isFilesFetching,
    loadFiles,
    mode,
    loadPoints,
    refetchTransaction,
    refetchLoadById,
    refetchFilesByLoadId,
    loadsPagePermissions,
    refetchRateConfirmations,
  });
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const hasAccessToViewHistory =
    userType === UserTypes.ADMIN || (employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.view);

  return (
    <form className={styles.regularLoadForm}>
      <MainLoader isFetching={isFetching} />

      <RegularBanner
        data={data}
        regularControl={control}
        hasAccessToViewHistory={hasAccessToViewHistory}
        handleSwitchMarkAsClaim={handleSwitchMarkAsClaim}
        regularWatch={watch}
        activeSection={activeSection}
        withActions={data?.load?.status !== LoadCancelStatus.Canceled}
        handleCancelLoad={handleCancelLoad}
        hasClaimPermission={loadsPagePermissions.claim}
        hasDeClaimPermission={loadsPagePermissions.resolve}
        hasCancelPermission={loadsPagePermissions.cancel}
        loadHistory={loadHistory}
        handleScrollToHistory={() => handleScrollToHistory('history')}
        handleCommentClick={() => handleScrollToHistory('comment')}
      />

      <ViewItemWrapper
        className={activeSection === SUBJECT.GENERAL_INFORMATION_ID ? 'active' : ''}
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        id="general-information"
        title={SUBJECT.EDIT_REGULAR_LOAD}
        Component={
          <LoadStatusComponent
            loadStatus={Number(data?.load?.status)}
            carrierStatus={Number(data?.load?.carrierStatus)}
            customerStatus={Number(data?.load?.customerStatus)}
            isProcessing={data?.load?.processing || false}
            withoutLoadsText
          />
        }
      >
        <Row align="middle" className={styles.topBannerBlock}>
          <Col span={12}>
            <Typography variant="heading" children={SUBJECT.GENERAL_INFO} className={styles.generalInfoHeading} />
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Col>
                <div className={styles.takenAsBlock}>
                  <Typography variant="paragraph">{`${SUBJECT.TAKEN_AS_MC} `}</Typography>
                  <span>{data?.load?.takenAsAuthority?.name}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="load_type"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.LOAD_TYPE}
              getValues={watch}
              errors={errors}
              defaultValue={LoadTypes[data?.load?.loadType]}
              value={LoadTypes[data?.load?.loadType]}
              required
              disabled
            />
          </Col>
          <Col span={8} />
          <Col span={8} />
          <Col span={8}>
            <Input
              name="customerName"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_NAME}
              getValues={watch}
              errors={errors}
              defaultValue={data?.load?.customer?.name}
              value={data?.load?.customer?.name}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              suffix={<DollarIcon />}
              name="customerAmount"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_AMOUNT}
              defaultValue={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount)}
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount)}
              getValues={watch}
              errors={errors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="referenceNumber"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.REFERENCE_NUMBER}
              getValues={watch}
              errors={errors}
              onChange={(e: any) => setValue('referenceNumber', e.target.value.trimStart(), { shouldDirty: true })}
              required
              disabled={isDisableReference()}
            />
          </Col>
          <Col span={8}>
            <Input
              name="customerContactName"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CONTACT_NAME}
              defaultValue={data?.load?.customerContactName}
              value={data?.load?.customerContactName}
              getValues={watch}
              errors={errors}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="customerContact"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CUSTOMER_CONTACT}
              defaultValue={String(data?.load?.customerContact)}
              value={String(data?.load?.customerContact)}
              getValues={watch}
              errors={errors}
              required
              disabled
            />
          </Col>
          <Col span={8} />
          <Col span={8}>
            <Input
              rules={{ required: true }}
              placeholder={SUBJECT.CARRIER}
              defaultValue={data?.load?.carrier?.name}
              value={data?.load?.carrier?.name}
              name="carrier"
              getValues={watch}
              control={control}
              required
              disabled
            />
          </Col>
          {data?.load?.truck?.truckNumber && (
            <Col span={8}>
              <Input
                name="truck"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.TRUCK}
                defaultValue={data?.load?.truck?.truckNumber}
                value={data?.load?.truck?.truckNumber}
                getValues={watch}
                errors={errors}
                required
                disabled
              />
            </Col>
          )}
          <Col span={8}>
            <Input
              suffix={<DollarIcon />}
              name="carrierAmount"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CARRIER_AMOUNT}
              defaultValue={data?.load?.carrier?.name}
              value={formatNumberWithThousandsSeparatorAndDecimal(data?.load?.carrierAmount)}
              getValues={watch}
              errors={errors}
              required
              disabled
            />
          </Col>
          <Col span={24}>
            <Row gutter={[18, 40]}>
              <Col>
                <Typography variant="heading" children={SUBJECT.SOLD_US_SWITCH} className={styles.switchHeading} />
              </Col>
              <Col>
                <Tooltip placement="top" title={isDisableSoldUs() ? "You can't change sold us" : ''} color={'white'}>
                  <span>
                    <Switch
                      name="soldUsSwitch"
                      checked={watch('soldUsSwitch')}
                      control={control}
                      onChange={handleSwitchSoldUs}
                      disabled={isDisableSoldUs()}
                    />
                  </span>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          {watch('soldUsSwitch') && (
            <Col span={8}>
              <SearchableDropdown
                options={authorities?.result?.map((item: any) => ({
                  title: item?.name,
                  value: String(item?.id),
                  id: String(item?.id),
                }))}
                placeholder={SUBJECT.ALL_AUTHORITIES}
                offset={authorities?.count}
                setOffset={setAuthoritiesState}
                values={watch('authorities')}
                columns={AUTHORITIES_CUSTOM_COLUMNS}
                keys={AUTHORITIES_CUSTOM_KEYS}
                control={control}
                name="authorities"
                showKey="title"
                setValue={setValue}
                withoutBorder={true}
                required
                rules={{ required: true }}
                disabled={isDisableSoldUs()}
                width={500}
              />
            </Col>
          )}
          {isSaveButtonVisible && (
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Button children="Cancel" variant="text" onClick={handleCancel} />
                </Col>
                <Col>
                  <Button children="Save" onClick={handleSave} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper
        id="load-finance"
        title={SUBJECT.LOAD_FINANCE}
        className={activeSection === SUBJECT.LOAD_FINANCE_ID ? 'active' : ''}
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
      >
        {isFetching ? (
          <div className={styles.spinWrapper}>
            <Spin />
          </div>
        ) : (
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Row gutter={[50, 0]}>
                <Col span={8}>
                  <Typography variant="heading" children={SUBJECT.CUSTOMER} className={styles.financeTitle} />
                </Col>
                <Col span={8}>
                  <Typography variant="heading" children={SUBJECT.AGENT} className={styles.financeTitle} />
                </Col>
                <Col span={8}>
                  <Typography variant="heading" children={SUBJECT.CARRIER} className={styles.financeTitle} />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={50}>
                <Col className={styles.borderedColumn} span={8}>
                  <Row gutter={[0, 24]}>
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.CUSTOMER_BALANCE}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.customerBalance,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography variant="paragraph" className={styles.infoHeading} children={SUBJECT.INIT_RATE} />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.customerInitialRate,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {data?.load?.paymentTermType !== PaymentTermType.BILLING_TYPES_FACTORING && (
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={
                                PaymentTermTypeText[data?.load?.paymentTermType as keyof typeof PaymentTermTypeText]
                              }
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerPaymentTermGeneratedAmount,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.FINAL_RECEIVABLE}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.customerFinalReceivable,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.AMOUNT_PAID_CUSTOMER}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.customerAmountPaid,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col className={styles.borderedColumn} span={8}>
                  <Row gutter={[0, 24]}>
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.AGENT_BALANCE}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.agentBalance,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.FINAL_PROFIT}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.agentFinalProfit,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col className={styles.borderedColumn} span={8}>
                  <Row gutter={[0, 24]}>
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.CARRIER_BALANCE}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.carrierBalance,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography variant="paragraph" className={styles.infoHeading} children={SUBJECT.INIT_RATE} />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.carrierInitialRate,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>

                    {!!Number(data?.finance?.carrierPaymentTermGeneratedAmount) && (
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.QUICK_PAY}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierPaymentTermGeneratedAmount,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.FINAL_PAYABLE}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.carrierFinalPayable,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.AMOUNT_PAID}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.carrierAmountPaid,
                              true
                            )}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ViewItemWrapper>
      {loadsPagePermissions.transaction ? (
        <ViewItemWrapper
          id="transaction"
          className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          title={SUBJECT.TRANSACTION}
          Component={
            <>
              {!!Object.keys(transactionDirtyFields)?.length && (
                <Col>
                  <Button
                    icon={<ResetIcon />}
                    variant="ghost"
                    children={SUBJECT.RESET}
                    onClick={handleResetTransactionForm}
                  />
                </Col>
              )}
            </>
          }
        >
          <Row gutter={[10, 30]}>
            <Col span={8}>
              <TransactionSelect
                name="transactionType"
                control={transactioControl}
                options={TRANSACTION_TYPES}
                onBlur={() =>
                  setTimeout(() => {
                    transactionTrigger('transactionType');
                    if (transactionWatch('customer')?.length) {
                      transactionTrigger('customer');
                    }
                    if (transactionWatch('carrierTransaction')?.length) {
                      transactionTrigger('carrierTransaction');
                    }
                  }, 500)
                }
                placeholder={SUBJECT.TRANSACTION_TYPE}
                required
              />
            </Col>
            <Col span={5}>
              <TransactionInput
                suffix={<DollarIcon />}
                name="customer"
                control={transactioControl}
                getValues={transactionWatch}
                onChange={e => {
                  transactionSetValue(
                    'customer',
                    Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                      Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                      ? floatNumberFormatter(e.target.value)
                      : floatNumberFormatterWithMinus(e.target.value),
                    {
                      shouldDirty: true,
                      shouldValidate: true,
                    }
                  );
                  setTimeout(() => {
                    transactionTrigger('customer');
                  }, 0);
                }}
                placeholder={SUBJECT.CUSTOMER}
                value={
                  transactionWatch('customer')
                    ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('customer'))
                    : ''
                }
                withRedMinus
              />
            </Col>
            <Col span={5}>
              <TransactionInput
                suffix={<DollarIcon />}
                name="carrierTransaction"
                getValues={transactionWatch}
                control={transactioControl}
                onChange={e => {
                  transactionSetValue(
                    'carrierTransaction',
                    Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                      Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                      ? floatNumberFormatter(e.target.value)
                      : floatNumberFormatterWithMinus(e.target.value),
                    {
                      shouldDirty: true,
                      shouldValidate: true,
                    }
                  );
                }}
                placeholder={SUBJECT.CARRIER}
                value={
                  transactionWatch('carrierTransaction')
                    ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('carrierTransaction'))
                    : ''
                }
                withRedMinus
              />
            </Col>
            <Col span={6}>
              <TransactionInput
                suffix={<DollarIcon />}
                name="balanceAmount"
                getValues={transactionWatch}
                control={transactioControl}
                placeholder={SUBJECT.BALANCE_AMOUNT}
                value={String(transactionWatch('balanceAmount'))}
                disabled
                withRedMinus
              />
            </Col>
            <Col span={24}>
              <TransactionInput
                type="text"
                control={transactioControl}
                name="transactionComment"
                getValues={transactionWatch}
                placeholder={SUBJECT.WRITE_COMMENT}
              />
            </Col>

            {loadsPagePermissions.transaction && (
              <Col span={24}>
                <Row justify="end">
                  <Button
                    variant="default"
                    children={SUBJECT.ADD_TRANSACTION}
                    disabled={addTransactionCheck() || !isTransactionValid || isCreateTransactionLoading}
                    onClick={handleCreateTransaction}
                  />
                </Row>
              </Col>
            )}
            {!!transactionsForDataGrind(loadTransactions)?.length && (
              <Col span={24}>
                <TransactionsTable
                  data={transactionsForDataGrind(loadTransactions)}
                  handleRowDelete={handleDeleteTransaction}
                  hasDeletePermission={loadsPagePermissions.transactionDelete}
                  isCustomer
                  isCarrier
                />
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      ) : (
        <>
          {!!transactionsForDataGrind(loadTransactions)?.length && (
            <ViewItemWrapper
              id="transaction"
              className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
              canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
              title={SUBJECT.TRANSACTION}
            >
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <TransactionsTable
                    data={transactionsForDataGrind(loadTransactions)}
                    handleRowDelete={handleDeleteTransaction}
                    hasDeletePermission={loadsPagePermissions.transactionDelete}
                    isCustomer
                    isCarrier
                  />
                </Col>
              </Row>
            </ViewItemWrapper>
          )}
        </>
      )}
      <ViewItemWrapper
        className={activeSection === SUBJECT.LOAD_INFO_ID ? 'active' : ''}
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        title={SUBJECT.LOAD_INFO}
        id="load-info"
      >
        <Row align="middle" className={styles.topBannerBlock} gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="transportType"
              control={control}
              placeholder={SUBJECT.LOAD_TYPE}
              rules={{ required: true }}
              errors={errors}
              value={TransportCapabilityTypes[data?.information?.transportCapability]}
              defaultValue={TransportCapabilityTypes[data?.information?.transportCapability]}
              disabled
              required
            />
          </Col>
          {data?.information?.transportCapability === TransportCapabilityTypes.Refrigerated && (
            <Col span={8}>
              <Input
                name="celsius"
                control={control}
                placeholder={SUBJECT.CELSIUS}
                rules={{ required: true }}
                value={data?.information?.refrigeratorCelsius || ''}
                getValues={watch}
                errors={errors}
                required
                disabled
              />
            </Col>
          )}
          <Col span={8}>
            <Input
              name="takenAs"
              control={control}
              placeholder={SUBJECT.TAKEN_AS}
              value={TakenType[data?.information?.takenType]}
              defaultValue={TakenType[data?.information?.takenType]}
              rules={{ required: true }}
              errors={errors}
              disabled
              required
            />
          </Col>
          <Col span={8}>
            <Input
              name="givenAs"
              control={control}
              value={TakenType[data?.information?.takenType]}
              defaultValue={TakenType[data?.information?.takenType]}
              placeholder={SUBJECT.GIVEN_AS}
              rules={{ required: true }}
              errors={errors}
              disabled
              required
            />
          </Col>
          <Col span={16}>
            <Input
              type="text"
              name="commodity"
              control={control}
              getValues={watch}
              placeholder={SUBJECT.COMMODITY}
              rules={{ required: true }}
              errors={errors}
              value={data?.information?.commodity}
              defaultValue={data?.information?.commodity}
              disabled
              required
            />
          </Col>
        </Row>
        <Row align="middle" gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="bookedAs"
              control={control}
              placeholder={SUBJECT.BOOKED_AS}
              value={BookedTypes[data?.information?.bookedType]}
              defaultValue={BookedTypes[data?.information?.bookedType]}
              disabled
              rules={{ required: true }}
              errors={errors}
              required
            />
          </Col>
          <Col span={8}>
            <Input
              name="soldAs"
              control={control}
              placeholder={SUBJECT.SOLD_AS}
              value={BookedTypes[data?.information?.soldType]}
              defaultValue={BookedTypes[data?.information?.soldType]}
              rules={{ required: true }}
              errors={errors}
              disabled
              required
            />
          </Col>
          {watch('soldAs') === BookedTypes.Partial && (
            <Col span={8}>
              <Input
                name="feetPartial"
                type="number"
                control={control}
                placeholder={SUBJECT.FEET_OF_PARTIAL}
                value={data?.information?.feetOfPartial}
                defaultValue={data?.information?.feetOfPartial}
                rules={{ required: true }}
                errors={errors}
                required
                disabled
                getValues={watch}
              />
            </Col>
          )}
          <Col span={8}>
            <Input
              name="weight"
              type="number"
              control={control}
              placeholder={SUBJECT.WEIGHT}
              rules={{ required: true }}
              value={data?.information?.weight}
              defaultValue={data?.information?.weight}
              errors={errors}
              required
              disabled
              getValues={watch}
            />
          </Col>
        </Row>
      </ViewItemWrapper>
      <div className={clsx(styles.pickUpsAndDropOffsSection)}>
        <PickupsDropoffs
          invalidLoadPointIds={invalidLoadPointIds}
          connectingError={connectingError}
          regularError={regularError}
          activeSection={activeSection}
          pickupDropOffsWatch={createLoadForm.pickupDropOffsWatch}
          pickupDropOffsControl={createLoadForm.pickupDropOffsControl}
          pickupDropOffsErrors={createLoadForm.pickupDropOffsErrors}
          setPickupDropOffsValue={createLoadForm.setPickupDropOffsValue}
          isPickupDropOffsValid={createLoadForm.isPickupDropOffsValid}
          watch={createLoadForm.watch}
          errors={createLoadForm.errors}
          setValue={createLoadForm.setValue}
          handleClearDate={createLoadForm.handleClearDate}
          statesData={createLoadForm.statesData}
          trigger={createLoadForm.trigger}
          refetchLoadPoints={refetchLoadPoints}
          {...createLoadForm}
          data={data}
          editMode
          id={data?.load?.id}
          isPickupDropoffs
          hasAddPickupDropOffPermission={loadsPagePermissions.addPickUpDropOff}
          hasEditPickupDropOffPermission={loadsPagePermissions.editPickUpDropOff}
          isCanceledClaimed={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          pointsState={pointsState}
          setPointsState={setPointsState}
          loadPointsAutocomplete={loadPointsAutocomplete}
          insurance={insurance}
          filledPickUpDropOffs={filledPickUpDropOffs}
          submitted={submitted}
        />
      </div>
      {rateConfirmations?.result?.length ? (
        <div className={styles.documentationWrapper}>
          <ViewItemWrapper
            title="Carrier Rate Confirmation"
            id={SUBJECT.CARRIER_RATE_CONFIRMATION_ID}
            className={activeSection === SUBJECT.CARRIER_RATE_CONFIRMATION_ID ? 'active' : ''}
            canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          >
            {rateConfirmations?.result?.map((fileItem: any, index: number) => (
              <AutoRateConfirmation
                fileItem={fileItem}
                handleViewFile={handleViewFileEdit}
                handleDownloadFileClick={handleDownloadFileEdit}
                handleSendRateConfirmation={handleSendRateConfirmation}
                rateConfirmations={rateConfirmations}
                index={index}
              />
            ))}
            {!!createRateConfCheck && (
              <Typography
                onClick={handleCreateRateConfirmation}
                variant="paragraph"
                children={SUBJECT.CREATE_RATE}
                className={styles.newCarrierRateBtn}
              />
            )}
          </ViewItemWrapper>
        </div>
      ) : null}
      {!!breakdowns?.result?.length && (
        <ViewItemWrapper
          title={SUBJECT.CARRIER_PAYMENT_BREAKDOWN}
          id={SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID}
          className={activeSection === SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID ? 'active' : ''}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        >
          <CarrierPaymentBreakdown
            data={{
              result: breakdowns?.result?.map((item: any) => {
                return {
                  download: item?.file?.download,
                  id: item?.id,
                  name: item?.file?.name,
                  createdAt: formatDate(item?.createdAt),
                  preview: item?.file?.preview,
                };
              }),
              count: breakdowns?.count,
            }}
            skip={breakdownParams.skip}
            limit={breakdownParams.limit}
            isLoading={isBreakdownLoading}
            onPaginationClick={handleCarrierBreakdownPaginationClick}
            onSort={handleBreakdownSortClick}
            withPagination={true}
          />
        </ViewItemWrapper>
      )}
      <div className={styles.uploadSection}>
        <ViewItemWrapper
          title={SUBJECT.DOCUMENTATION}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          className={activeSection === SUBJECT.DOCUMENTATION_ID ? 'active' : ''}
          id="documentation"
        >
          <Row gutter={[0, 35]}>
            <Col span={24}>
              <Row align="top" justify="space-between">
                <Col span={8}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.UPLOAD_RED_CONFIRMATION}
                    className={styles.uploadHeading}
                  />
                </Col>
                <Col span={5}>
                  {isSendedToBilling ? (
                    <Typography
                      className={styles.toBillingText}
                      variant="paragraph"
                      children={SUBJECT.SENT_TO_BILLING}
                    />
                  ) : (
                    loadsPagePermissions.sendToBilling &&
                    data?.agents?.[1]?.user?.id !== userId && (
                      <Button
                        className="smallBtn"
                        variant="default"
                        children={SUBJECT.SEND_TO_BILLING}
                        disabled={isSendToBillingDisabled}
                        onClick={handleSendToBilling}
                      />
                    )
                  )}
                </Col>
              </Row>
              <Row gutter={[0, 30]}>
                <Col span={24}>
                  <Row gutter={[23, 30]}>
                    <Col span={9}>
                      <Upload
                        accept="application/pdf"
                        uploadText={SUBJECT.UPLOAD_RED_CONFIRMATION}
                        className={styles.upload}
                        beforeUpload={beforeUploadForEdit}
                        multiple={false}
                        isDisabled={!loadsPagePermissions.uploadsFiles}
                        uploadType="2"
                      />
                    </Col>

                    {loadsPagePermissions.uploadsFiles && (
                      <Col span={8}>
                        <Typography
                          variant="heading"
                          children={SUBJECT.ALLOWED_FORMAT_PDF}
                          className={styles.allowedFormat}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
                {!!allFiles.length && (
                  <Col span={24}>
                    <Row justify="end" gutter={[20, 0]} align="bottom" className={styles.downloadAllBlock}>
                      <Col>
                        <Typography variant="heading" children={SUBJECT.DOWNLOAD_ALL} className={styles.downloadAll} />
                      </Col>
                      <Col>
                        <div
                          className={clsx(styles.uploadControl, styles.downloadBtn)}
                          onClick={() => multiplyDownloadWithDelay(allFiles)}
                        >
                          <DownloadIcon />
                        </div>
                      </Col>
                    </Row>

                    <Col span={24}>
                      <Controller
                        control={control}
                        render={({ field: { value: _value } }) => (
                          <FileItems
                            handleDownloadFileEdit={handleDownloadFileEdit}
                            handleViewFileEdit={handleViewFileEdit}
                            itemId={data?.load?.id}
                            onDeleteClick={handleDeleteFileEdit}
                            files={allFiles}
                            isDisabled={
                              isSendedToBilling || data?.load?.status !== LoadStatus.LOAD_STATUS_NOT_PROCESSING
                            }
                            withCrossing
                            hasDeleteFilePermission={loadsPagePermissions.filesDelete}
                          />
                        )}
                        name="file"
                      />
                    </Col>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.UPLOAD_CONFIRMING_FILES}
                    className={styles.uploadHeading}
                  />
                </Col>
              </Row>
              <Row gutter={[23, 30]}>
                <Col span={9}>
                  <Upload
                    accept="application/pdf"
                    uploadText={SUBJECT.UPLOAD_CONFIRMING_FILES}
                    className={styles.upload}
                    beforeUpload={beforeUploadForEditPOD}
                    isDisabled={!loadsPagePermissions.uploadsFiles}
                    uploadType="2"
                  />
                </Col>
                <Col span={8} flex="end">
                  <Typography
                    variant="heading"
                    children={SUBJECT.ALLOWED_FORMAT_PDF}
                    className={styles.allowedFormat}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <>
            <div className={styles.uploadRowWrapper}>
              <UploadRow
                files={allFilesPOD}
                onWatchClick={handleViewFileEditPOD}
                onDownloadClick={handleDownloadFileEditPOD}
                onDeleteClick={handleDeleteFileEditPOD}
                onEditFile={handleEditFile}
                isDisabled={isSendedToBilling || data?.load?.status !== LoadStatus.LOAD_STATUS_NOT_PROCESSING}
              />
            </div>
          </>
        </ViewItemWrapper>
      </div>
      {isSendedToBilling && roleLevel === IRoleLevel.corpLevel && (
        <div
          id="billing-block"
          className={clsx({
            [styles.active]: activeSection === SUBJECT.BILLING_ID,
          })}
        >
          <BillingBlock
            paymentTermType={data?.load?.paymentTermType}
            noa={data?.load?.noa}
            pod={loadFiles?.result?.POD}
            mailableFiles={mailableFiles}
            redConfirmation={loadFiles?.result?.RED_CONFIRMATION}
            invoice={loadFiles?.result?.INVOICE || []}
            onAcceptClick={handleAcceptToBilling}
            onRejectClick={handleRejectBilling}
            onCreateInvoice={handleCreateInvoice}
            onDeleteInvoice={handleDeleteInvoice}
            loadStatus={data?.load?.status}
            processing={data?.load?.processing}
            hasValidFiles={!isSendToBillingDisabled}
            hasAcceptToBillingPermission={loadsPagePermissions.acceptToBilling}
            hasClaimLoadAcceptToBillingPermission={loadsPagePermissions.claimLoadAcceptToBilling}
            hasRejectBillingPermission={loadsPagePermissions.reject}
            hasClaimLoadRejectPermission={loadsPagePermissions.claimLoadReject}
            isLoadClaimed={data?.load?.claimed}
            isSendedToBilling={isSendedToBilling}
            loadData={data}
            isCanceledClaimed={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
            isFetching={isFetching}
            isInvoiceLoading={isInvoiceLoading}
            isLoadHasCustomer={!!data?.load?.customer?.id}
          />
        </div>
      )}

      <div ref={drawerRef}>
        <CustomComment
          id={'comment'}
          watch={watch}
          name="comment"
          setValue={setValue}
          control={control}
          comments={comments}
          isDefaultHistory={isDefaultHistory}
          setIsDefaultHistory={setIsDefaultHistory}
          createComment={createComment}
          deleteComment={deleteComment}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          loadHistory={loadHistory}
          withNotes={loadHistory?.result?.length}
          drawerRef={drawerRef}
          isShowMoreVisibleNotes={isShowMoreVisibleNotes}
          getMoreNotes={getMoreNotes}
          hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
          activeSection={activeSection}
          isBlockCanceled={data?.load?.status === LoadCancelStatus.Canceled}
          isFetchingHistory={isFetchingHistory}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
        />
      </div>
    </form>
  );
};

export default RegularEdit;
