import { useEffect, useMemo, useState } from 'react';
import dayjs, { UnitType } from 'dayjs';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import { TDate } from 'utils/types';

import { SUBJECT } from './constants/constants';

export const useMonthPicker = (name: string) => {
  const [isOpenedMonth, setIsOpenedMonth] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { startDate, endDate, allMonths, card } = searchParams;

  const currentDate = useMemo(() => {
    return !startDate ? dayjs() : dayjs(Number(startDate));
  }, [startDate]);

  const month = currentDate.format(SUBJECT.MONTHS);
  const year = currentDate.format(SUBJECT.YEARS);

  const showInputValue = useMemo(() => {
    if (allMonths) {
      return SUBJECT.ALL;
    } else if (endDate && name != 'monthPickerPayout') {
      return `${currentDate.format(SUBJECT.FULL_DATE_FORMAT)} - ${dayjs(Number(endDate)).format(
        SUBJECT.FULL_DATE_FORMAT
      )}`;
    } else if (endDate && name === 'monthPickerPayout') {
      return `${currentDate.format(SUBJECT.MONTH_YEAR)} - ${dayjs(Number(endDate)).format(SUBJECT.MONTH_YEAR)}`;
    } else {
      return `${month} ${year}`;
    }
  }, [allMonths, endDate, startDate]);

  const isDisabled = dayjs().format(SUBJECT.YEARS) === year;

  const settingDate = (key: UnitType, value: number) => {
    const startDayOfMonth = currentDate.set(key, value).startOf(SUBJECT.MONTH_SINGLE).valueOf();
    const endDayOfMonth = currentDate.set(key, value).endOf(SUBJECT.MONTH_SINGLE).valueOf();

    return {
      startDayOfMonth,
      endDayOfMonth,
    };
  };

  const handleChangeYear = (changer: string) => {
    const isIncrease = changer === SUBJECT.INCREASE;

    if (isIncrease && isDisabled) return;

    const changingYear = isIncrease ? Number(year) + 1 : Number(year) - 1;

    const { startDayOfMonth } = settingDate(SUBJECT.YEAR_SINGLE, changingYear);
    setSearchParams({
      ...searchParams,
      startDate: startDayOfMonth.toString(),
    });
  };

  const handleChangeMonth = (month: number) => {
    setIsOpenedMonth(prev => !prev);

    const { startDayOfMonth } = settingDate(SUBJECT.MONTH_SINGLE, month);

    const { endDate: _endDate, allMonths: _allMonths, ...rest } = searchParams;
    setSearchParams({
      ...rest,
      startDate: startDayOfMonth.toString(),
    });
  };

  const handleOpenList = () => {
    if (endDate) {
      setIsOpenedMonth(false);
      setIsCalendarOpen(true);
    } else {
      setIsOpenedMonth(prev => !prev);
    }
  };

  const handleOpenCalendar = (name: string) => {
    setIsCalendarOpen(prev => !prev);
    setIsOpenedMonth(false);

    if (name === 'monthPickerPayout') {
      setSearchParams({ ...searchParams, dateMode: 'range' });
    } else {
      setSearchParams({ ...searchParams, dateMode: 'custom' });
    }
  };

  const onRangeChange = (dates: TDate) => {
    if (dates?.length) {
      const { allMonths: _allMonths, ...rest } = searchParams;

      setSearchParams({
        ...rest,
        startDate: String(dayjs(dates[0]).startOf('day')?.valueOf()),
        endDate: String(dayjs(dates[1]).endOf('day')?.valueOf()),
      });
    }
    setIsCalendarOpen(false);
  };

  const handleAllRequest = () => {
    const { endDate: _endDate, startDate: _startDate, ...rest } = searchParams;
    setSearchParams({
      ...rest,
      allMonths: 'true',
    });
    setIsOpenedMonth(prev => !prev);
  };

  const ref = useOutsideDetect<HTMLUListElement>(setIsOpenedMonth);

  const handleGoBack = () => {
    const currentDate = dayjs().tz('America/Los_Angeles');

    const startOfMonth = currentDate.startOf('month');

    setIsCalendarOpen(false);
    setIsOpenedMonth(true);
    setSearchParams({ ...searchParams, startDate: String(startOfMonth.valueOf()), endDate: '', dateMode: 'common' });
  };

  useEffect(() => {
    if (!startDate && !allMonths) {
      setSearchParams({
        ...searchParams,
        startDate: String(dayjs().startOf(SUBJECT.MONTH_SINGLE).valueOf()),
      });
    }
  }, [searchParams]);

  return {
    date: new Date(),
    handleChangeYear,
    year,
    month,
    handleChangeMonth,
    isOpenedMonth,
    setIsOpenedMonth,
    handleOpenList,
    ref,
    isCalendarOpen,
    handleOpenCalendar,
    setIsCalendarOpen,
    isDisabled,
    onRangeChange,
    showInputValue,
    handleAllRequest,
    startDate,
    endDate,
    card,
    handleGoBack,
  };
};
