import Typography from 'ui/typography/Typography';

import { ITableAging } from './constants/types';
import useTableAging from './useTableAging';

import styles from './TableAging.module.scss';

const TableAging = ({ data, day }: ITableAging) => {
  const { colorChecker } = useTableAging();
  return (
    <div className={styles.tableAgingItem}>
      <div className={styles.colorItem} style={{ backgroundColor: colorChecker(data, Number(day)) }} />
      <Typography variant="paragraph">{day}</Typography>
    </div>
  );
};

export default TableAging;
