import React, { useEffect, useState } from 'react';
import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { type TabsProps, notification } from 'antd';
import clsx from 'clsx';
import { LoadTypes } from 'pages/loads/components/create-load/constants/types';
import { ITransactionTypes } from 'pages/loads/components/edit-load/constants/constants';
import { RequestStatus } from 'services/loads/interface';
import { useGetLoadFilesByIdsMutation } from 'services/loads/loads';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';
import { ServerErrorCodes } from 'utils/errors';
import { multiplyViewWithDelay } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import { TrashIcon } from 'components/svgs/TrashIcon';

import { usePermittedActions } from '../../hooks/usePermittedActions';
import { IRoleLevel } from '../../services/truck-board/carrier-request/interfaces';
import { selectUserPermissionsInfo } from '../../store/user-slice/selector';
import { PaymentTermTypeForHistoryMessage } from '../billing-block/constants/types';

import { SUBJECT } from './constants/constants';
import { linkGeneratorForLoadHistory } from './constants/helpers';
import { IComments, ICommentsNotifications, LoadHistoryMessageTypes } from './constants/types';

import styles from './CustomComment.module.scss';

const useCustomComment = <T extends FieldValues>(
  comments: IComments,
  loadHistory: any,
  name: Path<T> | string,
  setValue: UseFormSetValue<T>,
  createComment: any,
  deleteComment: any,
  withNotes: boolean,
  getMoreComments: any,
  isShowMoreVisible: any,
  drawerRef: any,
  canDelete: boolean,
  hasDeleteCommentPermission?: boolean,
  getMoreNotes?: any,
  isShowMoreVisibleNotes?: any,
  inView?: boolean,
  isDefaultHistory = false,
  setIsDefaultHistory = (_mode: boolean) => {
    return;
  },
  isFetchingHistory?: boolean,
  isLoadsCommentsLoading?: boolean
) => {
  const { employeeLevel, userType, permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const loadPagePermissions = usePermittedActions('loads.loads');
  const { roleLevel } = permissionsInfo;

  const hasAccessToViewHistory =
    userType === UserTypes.ADMIN || (employeeLevel === EmployeeLevels.CORPORATE && loadPagePermissions.view);

  const defaultActiveKey = (!comments?.count || isDefaultHistory) && hasAccessToViewHistory ? '2' : '1';
  const [activeTab, setActiveTab] = useState(defaultActiveKey);
  const [getLoadFilesByIds] = useGetLoadFilesByIdsMutation();

  const handleAddComment = (commentText: string) => {
    createComment(commentText);
    setValue(name as Path<T>, '' as PathValue<string, string>, { shouldDirty: false });
    setActiveTab('1');
    setIsDefaultHistory(false);
  };
  const handleDelete = (commentId: string) => {
    deleteComment(commentId);
  };

  const handleViewFile = (fileIds: number[], id: number) => {
    if (fileIds.length) {
      getLoadFilesByIds({ id, fileIds }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          const files = data?.data?.result.map((file: any) => {
            return {
              preview: file?.file?.preview,
              download: file?.file?.download,
              id: file?.file?.id,
            };
          });
          multiplyViewWithDelay(files);
        }
      });
    }
  };

  const handleChangeTab = (key: any) => {
    setTimeout(() => {
      drawerRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 100);
    setActiveTab(key);
    setIsDefaultHistory(key == '2');
  };

  useEffect(() => {
    if ((!comments?.count || isDefaultHistory) && hasAccessToViewHistory) {
      setActiveTab('2');
    } else {
      setActiveTab('1');
    }
  }, [comments?.count, isDefaultHistory, hasAccessToViewHistory]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `${SUBJECT.COMMENTS} ${comments?.count}`,
      children: comments?.count ? (
        <>
          {comments?.commentsList?.map(({ id, commentText, name, createdAt }: ICommentsNotifications) => {
            return (
              <div key={id} className={styles.singleItem}>
                <Typography variant="heading" level={3} className={styles.commentName}>
                  {name}
                  {canDelete && hasDeleteCommentPermission && roleLevel === IRoleLevel.corpLevel && (
                    <Button onClick={() => handleDelete(id)} variant="text" children={<TrashIcon />} />
                  )}
                </Typography>
                <Typography variant="paragraph" className={styles.commentDate}>
                  {formatDate(createdAt)}
                </Typography>
                <pre className={styles.commentText}>{commentText}</pre>
              </div>
            );
          })}
          {isShowMoreVisible || isLoadsCommentsLoading ? (
            <div className={styles.viewMoreBtn}>
              <Button variant="link" size="small" onClick={getMoreComments} children={SUBJECT.VIEW_MORE} />
            </div>
          ) : null}
        </>
      ) : (
        <Typography variant="paragraph" className={styles.noData}>
          {SUBJECT.NO_COMMENT}
        </Typography>
      ),
      disabled: !comments?.count,
    },
    hasAccessToViewHistory && {
      key: '2',
      label: `${SUBJECT.LOAD_HISTORY} ${loadHistory?.count}`,
      children: loadHistory?.count ? (
        <>
          <div>
            {loadHistory?.result?.map((messageData: any, index: any) => (
              <div className={clsx(styles.singleItem, styles.notificaitonItem)} key={index}>
                {messageData.message.map((part: any, index: any) => {
                  switch (part.type) {
                    case LoadHistoryMessageTypes.DATE:
                      return (
                        <Typography key={index} variant="paragraph" className={styles.commentDate}>
                          {formatDate(messageData?.createdAt)}&nbsp;
                        </Typography>
                      );
                    case LoadHistoryMessageTypes.DEFAULT:
                    case LoadHistoryMessageTypes.REFERENCE:
                    case LoadHistoryMessageTypes.TRANSACTION_STATUS:
                    case LoadHistoryMessageTypes.ADDRESS:
                    case LoadHistoryMessageTypes.CITY:
                    case LoadHistoryMessageTypes.STATE:
                    case LoadHistoryMessageTypes.ZIP:
                    case LoadHistoryMessageTypes.PERCENT:
                    case LoadHistoryMessageTypes.FACILITY:
                    case LoadHistoryMessageTypes.AMOUNT:
                    case LoadHistoryMessageTypes.COMMENT:
                      return <span key={index}>{part.message}&nbsp;</span>;
                    case [
                      LoadHistoryMessageTypes.EMPLOYEE,
                      LoadHistoryMessageTypes.AGENT,
                      LoadHistoryMessageTypes.BRANCH,
                      LoadHistoryMessageTypes.OFFICE,
                      LoadHistoryMessageTypes.CUSTOMER,
                      LoadHistoryMessageTypes.CARRIER,
                    ].find((el: any) => el === part.type):
                      return part?.id === 849503496 ? (
                        <span key={index}>{part.message}</span>
                      ) : (
                        <React.Fragment key={part?.id}>
                          <Link
                            target="_blank"
                            to={
                              linkGeneratorForLoadHistory({
                                type: part.type,
                                id: part.id,
                                loadId: messageData?.loadId,
                              }) || ''
                            }
                          >
                            {part.message}
                          </Link>
                          &nbsp;
                        </React.Fragment>
                      );
                    case LoadHistoryMessageTypes.REQUEST_STATUS:
                      return <span key={index}>{RequestStatus[part.message]}&nbsp;</span>;
                    case LoadHistoryMessageTypes.FILE:
                    case LoadHistoryMessageTypes.INVOICE:
                      return (
                        <a key={index} onClick={() => handleViewFile([part.id], messageData?.loadId)}>
                          {part.message}&nbsp;
                        </a>
                      );
                    case LoadHistoryMessageTypes.LOAD_STATUS:
                      return <span key={index}>{part.message}&nbsp;</span>;
                    case LoadHistoryMessageTypes.LOAD_TYPE:
                      return <span key={index}>{LoadTypes[part.message]}&nbsp;</span>;
                    case LoadHistoryMessageTypes.PAYMENT_TERM_TYPE:
                      return (
                        <span key={index}>
                          {
                            PaymentTermTypeForHistoryMessage[
                              part.message as keyof typeof PaymentTermTypeForHistoryMessage
                            ]
                          }
                          &nbsp;
                        </span>
                      );
                    case LoadHistoryMessageTypes.TRANSACTION_TYPE:
                      return <span key={index}>{ITransactionTypes[part.message]}&nbsp;</span>;
                  }
                })}
              </div>
            ))}
          </div>
          {isShowMoreVisibleNotes || isFetchingHistory ? (
            <div className={styles.viewMoreBtn}>
              <Button variant="link" size="small" onClick={getMoreNotes} children={SUBJECT.VIEW_MORE} />
            </div>
          ) : null}
        </>
      ) : (
        <Typography variant="paragraph" className={styles.noData}>
          {SUBJECT.NO_NOTIFICATION}
        </Typography>
      ),
      disabled: !withNotes,
    },
  ];

  return {
    handleDelete,
    handleAddComment,
    items,
    handleChangeTab,
    activeTab,
  };
};

export default useCustomComment;
