import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetOtherServiceFeeByIdQuery } from 'services/payroll/information/information';
import { useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import { useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { IRoleLevel } from 'services/truck-board/carrier-request/interfaces';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';

import { DEFAULT_VALUES_CHARGE } from '../../constants/constants';
import { ICollectionValues, OtherServiceFeeCards } from '../../constants/types';
import { chargeValidation } from '../constants/validation';

export const useCharge = () => {
  const { searchParams } = useDetectedParams();
  const { mode, open, card, id } = searchParams;

  const { currentData: otherServiceFeeInfo, isFetching: isOtherServiceFeeFetching } = useGetOtherServiceFeeByIdQuery(
    { id },
    { skip: !id || card !== OtherServiceFeeCards.OTHER_SERVICE_FEE }
  );

  const userInfo = useSelector(selectUserInfo);
  const { branchIds, officeIds } = userInfo;

  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const {
    control,
    formState: { isValid, errors, dirtyFields },
    watch,
    reset,
    trigger,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm<ICollectionValues>({
    defaultValues: DEFAULT_VALUES_CHARGE,
    mode: 'all',
    resolver: yupResolver(chargeValidation(roleLevel)),
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const { data: officesOptions = { result: [], count: 0 } } = useGetOfficesAutocompleteQuery(officesFilter, {
    skip: roleLevel !== IRoleLevel.corpLevel || mode !== 'charge' || open !== 'true',
  });

  const { data: branchesData = { result: [], count: 0 } } = useGetBranchesAutocompleteQuery(
    { officeId: officeIds?.[0], withDefaults: true, ...branchFilter },
    { skip: roleLevel !== IRoleLevel.officeLevel || !officeIds?.[0] }
  );

  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    { ...agentsFilter, branchId: branchIds?.[0], excludeOwners: roleLevel === IRoleLevel.branchLevel },
    { skip: roleLevel !== IRoleLevel.branchLevel || !branchIds?.[0] }
  );

  useEffect(() => {
    if (otherServiceFeeInfo && mode === 'editCharge') {
      const { office, branch, type, amount, agent } = otherServiceFeeInfo;

      reset({
        agency: office ? [{ id: office?.id, title: office?.name, value: String(office?.id) }] : [],
        branch: branch ? [{ id: branch?.id, title: branch?.name, value: String(branch?.id) }] : [],
        agent: agent
          ? [{ id: agent?.id, firstName: agent?.firstName, lastName: agent?.lastName, userName: agent.username }]
          : [],
        type: String(type),
        amount,
      });
    } else {
      reset(DEFAULT_VALUES_CHARGE);
    }
  }, [otherServiceFeeInfo, mode]);

  return {
    errors,
    control,
    isValid,
    mode,
    branchesData,
    agentsOptions,
    officesOptions,
    otherServiceFeeInfo,
    isOtherServiceFeeFetching,
    watch,
    reset,
    trigger,
    setValue,
    clearErrors,
    handleSubmit,
    setBranchFilter,
    setAgentsFilter,
    setOfficesFilter,
    dirtyFields,
  };
};
