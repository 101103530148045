import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { MainLoader } from 'ui/main-loader/MainLoader';

import CustomComment from 'components/custom-comment/CustomComment';

import { ICommentsView } from './constants/types';
import useLoadsComment from './useLoadsComment';

const LoadsComment = ({
  loadData,
  createComment,
  deleteComment,
  loadsComments,
  getMoreComments,
  isShowMoreVisible,
  isLoading,
  hasDeleteCommentPermission,
  isLoadsCommentsLoading,
  loadsPagePermissions,
}: ICommentsView) => {
  const { employeeLevel } = useSelector(selectUserPermissionsInfo);

  const { watch, setValue, control, banners, loadType } = useLoadsComment(loadData);

  const canCreate = !(
    Object.values(loadsPagePermissions)?.filter(item => item === true)?.length === 1 && loadsPagePermissions?.view
  );

  return (
    <>
      <MainLoader isFetching={isLoading} />
      {banners[loadType as keyof typeof banners]}
      <CustomComment
        id="comment"
        watch={watch}
        name="comment"
        setValue={setValue}
        control={control}
        comments={loadsComments}
        createComment={createComment}
        deleteComment={deleteComment}
        getMoreComments={getMoreComments}
        isShowMoreVisible={isShowMoreVisible}
        hasDeleteCommentPermission={hasDeleteCommentPermission}
        isLoadsCommentsLoading={isLoadsCommentsLoading}
        {...(employeeLevel ? { canCreate } : {})}
      />
    </>
  );
};

export default LoadsComment;
