import React, { useMemo } from 'react';
import { Col, Row, Tooltip } from 'antd';
import Button from 'ui/button/Button';

import { BlacklistSelect, SearchableDropdown } from '../../hoc-instance/instance';

import { AGENT_COLUMNS, AGENT_KEYS, CARRIER_KEYS, SEARCH_CARRIER_COLUMNS, SUBJECT } from './constants/constants';
import { IBlacklistProps } from './constants/types';

import styles from './Blacklist.module.scss';

const Blacklist = ({
  carriersOptions,
  officesOptions,
  control,
  handleResetBlacklist,
  setValue,
  handleSubmit,
  onSubmit,
  agentsOptions,
  watch,
  branchesData,
  setCarriersFilter,
  setOfficesFilter,
  setAgentsFilter,
  setBranchFilter,
  selectedOffices,
  selectedBranches,
}: IBlacklistProps) => {
  const isBlackListBtnValid = useMemo(
    () =>
      !(
        (selectedOffices.length === 1 &&
          selectedBranches.length === 1 &&
          watch('agent')?.length &&
          watch('carrier').length) ||
        (selectedOffices.length > 1 &&
          !selectedBranches.length &&
          !watch('agent')?.length &&
          watch('carrier').length) ||
        (selectedOffices.length === 1 &&
          selectedBranches.length > 1 &&
          !watch('agent')?.length &&
          watch('carrier').length) ||
        (selectedOffices.length === 1 && watch('carrier').length)
      ),
    [watch('carrier'), watch('agent'), watch('branch'), watch('office')]
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.blacklistOverlay}>
        <div className={styles.blacklist}>
          <Row gutter={[20, 20]} align="bottom">
            <Col xl={12} xxl={12}>
              <BlacklistSelect
                isSearchable
                isMultiSelect
                withCheckbox
                options={officesOptions?.result}
                label={SUBJECT.OFFICE}
                placeholder={SUBJECT.NOT_SELECTED}
                name="office"
                control={control}
                offset={officesOptions?.count}
                setOffset={setOfficesFilter}
                setValue={setValue}
                withMarking
                isAutocomplete
              />
            </Col>
            <Col xl={12} xxl={12}>
              <BlacklistSelect
                isSearchable
                isMultiSelect
                withCheckbox
                withAll
                options={branchesData?.result}
                count={branchesData?.count}
                label={SUBJECT.BRANCH}
                placeholder={SUBJECT.NOT_SELECTED}
                name="branch"
                control={control}
                offset={branchesData?.count}
                setOffset={setBranchFilter}
                isDisabled={selectedOffices.length !== 1}
                setValue={setValue}
                withMarking
                isAutocomplete
              />
            </Col>
            <Col xl={12} xxl={12}>
              <SearchableDropdown
                options={agentsOptions?.result}
                columns={AGENT_COLUMNS}
                keys={AGENT_KEYS}
                label={SUBJECT.AGENT}
                placeholder={SUBJECT.NOT_SELECTED}
                name="agent"
                control={control}
                disabled={selectedOffices.length !== 1 || selectedBranches.length !== 1}
                setValue={setValue}
                values={watch('agent')}
                offset={agentsOptions.count}
                setOffset={setAgentsFilter}
                showKey="username"
              />
            </Col>
            <Col xl={12} xxl={12}>
              <SearchableDropdown
                options={carriersOptions?.result}
                label={SUBJECT.CARRIER}
                placeholder={SUBJECT.NOT_SELECTED}
                keys={CARRIER_KEYS}
                offset={carriersOptions?.count}
                setOffset={setCarriersFilter}
                values={watch('carrier')}
                columns={SEARCH_CARRIER_COLUMNS}
                control={control}
                name="carrier"
                showKey="name"
                setValue={setValue}
                withoutBorder={true}
              />
            </Col>
            <Col span={24}>
              <div className={styles.blackListButtons}>
                {isBlackListBtnValid ? (
                  <Tooltip title={SUBJECT.TOOLTIP_TEXT} color="white">
                    <Button
                      style={{ opacity: 0.5, cursor: 'not-allowed' }}
                      children={SUBJECT.ADD_BLACKLIST}
                      htmlType="button"
                      variant="danger"
                    />
                  </Tooltip>
                ) : (
                  <Button children={SUBJECT.ADD_BLACKLIST} htmlType="submit" variant="danger" />
                )}

                <Button children={SUBJECT.CANCEL} variant="text" htmlType="reset" onClick={handleResetBlacklist} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default Blacklist;
