import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useUploadFileNOAMutation } from 'services/profile/authorities/authorities-file';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { FILE_MAX_SIZE_ERROR } from 'utils/errors';
import { isFileAllowedToBeUpload } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';

import { SUBJECT } from '../../constants/constants';

export const useDocumentation = ({ setValue, watch }: any) => {
  const { file }: any = window.global;
  const [uploadFileNOA] = useUploadFileNOAMutation();
  const userInfo = useSelector(selectUserInfo);
  const [allFiles, setAllFiles] = useState<any>([]);
  const [localFiles, setLocalFiles] = useState<any>([]);
  const dispatch = useDispatch();

  const handleDeleteFile = (fileId: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          setValue(
            'documentation',
            watch('documentation').filter((el: any) => el !== fileId)
          );
          setAllFiles((prev: any) => prev.filter((el: any) => el.id !== fileId));
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };
  const handleDownloadFile = (file: any) => {
    const fileToDownload = localFiles.find((el: any) => el.uid === file.uid);
    const blob = new Blob([fileToDownload]);
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink!.href = url;
    downloadLink.download = fileToDownload!.name;
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  };

  const handleViewFile = (file: any) => {
    const fileToView = localFiles.find((el: any) => el.uid === file.uid);
    if (fileToView) {
      const blob = new Blob([fileToView], { type: fileToView.type });

      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      window.URL.revokeObjectURL(url);
    }
  };

  const beforeUploadForCreate = (file: any, ctx: any, setLoading: any) => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', '1');
      uploadFileNOA(formData)
        .then(
          ({
            data: {
              result: { ids },
            },
          }: any) => {
            setLocalFiles((prev: any) => [file, ...prev]);
            setValue('documentation', watch('documentation') ? [...watch('documentation'), ...ids] : ids);
            setAllFiles((prev: any) => [
              {
                fullName: `${userInfo?.firstName} ${userInfo?.lastName}`,
                fileName: file.name,
                id: ids[0],
                createdAt: new Date(),
                uid: file.uid,
              },
              ...prev,
            ]);
          }
        )
        .catch(() => null)
        .finally(() => {
          setLoading(false);
        });
      return false;
    } else {
      notification.error({
        message: FILE_MAX_SIZE_ERROR,
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  return {
    beforeUploadForCreate,
    file,
    handleDeleteFile,
    handleDownloadFile,
    handleViewFile,
    allFiles,
  };
};
