import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useExportTruckBoardSearchResultMutation } from 'services/exports/exports';
import { truckBoardApi, useMarkTruckAsReadMutation } from 'services/truck-board/truck-board/truckBoard';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { singleFilterDefaultValues, SUBJECT } from './constants/constants';
import { ISingleFilter } from './constants/types';

const useTruckBoardSingle = ({ setExcelParams, searchResultsFilter, setSearchResultsFilter }: any) => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParams } = useDetectedParams();
  const { equipmentTypeSingle, trailerDetailsSingle, status, singleStatus, id } = searchParams;

  const [markTruckAsRead] = useMarkTruckAsReadMutation();

  useEffect(() => {
    if (setExcelParams) setExcelParams(setSearchResultsFilter);
  }, [setSearchResultsFilter]);

  const {
    control: filterControl,
    reset: filterReset,
    watch: filterWatch,
    setValue: filterSetValue,
  } = useForm<ISingleFilter>({
    defaultValues: singleFilterDefaultValues,
    mode: 'onChange',
  });

  const equipmentTypeSingleFilter = filterWatch('equipmentTypeSingle')?.toString();
  const trailerDetailsSingleFilter = filterWatch('trailerDetailsSingle')?.toString();
  const equipmentTypeSingleQueryCheck = !!(typeof equipmentTypeSingleFilter === 'string' && equipmentTypeSingleFilter);
  const trailerDetailsSingleQueryCheck = !!(
    typeof trailerDetailsSingleFilter === 'string' && trailerDetailsSingleFilter
  );

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(equipmentTypeSingleQueryCheck
        ? { equipmentTypeSingle: equipmentTypeSingleFilter }
        : { equipmentTypeSingle: '' }),
      ...(trailerDetailsSingleQueryCheck
        ? { trailerDetailsSingle: trailerDetailsSingleFilter }
        : { trailerDetailsSingle: '' }),
    });
  }, [equipmentTypeSingleFilter, trailerDetailsSingleFilter]);

  useEffect(() => {
    const filterOptions = [
      {
        key: 'equipmentTypeSingle',
        value: equipmentTypeSingle ? equipmentTypeSingle?.split(',') : equipmentTypeSingle,
      },
      {
        key: 'trailerDetailsSingle',
        value: trailerDetailsSingle ? trailerDetailsSingle?.split(',') : trailerDetailsSingle,
      },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        filterSetValue(key as keyof ISingleFilter, value);
      }
      setSearchResultsFilter((prev: any) => ({
        ...prev,
        skip: 0,
        filter: {
          ...prev?.filter,
          equipmentTypes: equipmentTypeSingle?.split(','),
          trailerDetails: trailerDetailsSingle?.split(','),
        },
      }));
    });
  }, [equipmentTypeSingle, trailerDetailsSingle]);

  const handleResetFilter = () => {
    filterReset();
    setSearchParams({ ...searchParams });
    setSearchResultsFilter({
      search: '',
      field: '',
      skip: 0,
      limit: 20,
      order: 2,
      orderBy: 'availableAt',
      truckBoardStatus: status,
      filter: {},
    });
  };

  const handleRowClick = (rowId: number | string, rowData: any) => {
    setSearchParams({
      ...searchParams,
      mode: 'carrierTruckViewFromResult',
      open: 'true',
      truckId: String(rowId),
      carrierId: String(rowData?.carrierId),
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setSearchResultsFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'availableAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setSearchResultsFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setSearchResultsFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleBadgeClick = (truckId: number | string) => {
    markTruckAsRead({ filterId: id, id: truckId, status });
  };

  const handleCloseSearchResult = () => {
    setSearchParams({
      ...searchParams,
      trailerDetailsSingle: '',
      equipmentTypeSingle: '',
      isSingle: '',
      id: '',
    });
    filterReset({
      equipmentTypeSingle: '',
      trailerDetailsSingle: '',
    });
    dispatch(truckBoardApi?.util?.resetApiState());
  };

  const handleCustomizeSearchResultTable = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: 'customizeResult',
    });
  };

  const [excelParamsSingle, setExcelParamsSingle] = useState();

  useEffect(() => {
    setExcelParamsSingle({
      ...searchResultsFilter,
      status: singleStatus,
      id,
    });
  }, [searchResultsFilter, singleStatus, id]);

  const [exportTruckBoardSearchResult] = useExportTruckBoardSearchResultMutation();

  const downloadExcel = () => {
    exportTruckBoardSearchResult(excelParamsSingle).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.CHECK_EMAIL,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  return {
    handleRowClick,
    handleSortClick,
    handleColumnSearch,
    handlePaginationClick,
    filterControl,
    filterWatch,
    handleResetFilter,
    handleBadgeClick,
    handleCloseSearchResult,
    handleCustomizeSearchResultTable,
    downloadExcel,
  };
};
export default useTruckBoardSingle;
