import {
  BrokerPaymentTermsType,
  CarrierFactoringType,
  CarrierPaymentTermType,
  CarrierPaymentType,
} from 'services/profile/carriers/interfaces';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  PAYMENT_TERM_TYPE_REQUIRED,
  AUTHORITY_REQUIRED,
  FACTORING_TYPE_REQUIRED,
  FACTORED_COMPANY_NAME_REQUIRED,
  PAYMENT_TYPE_REQUIRED,
  PAYMENT_BANK_NAME_REQUIRED,
  PAYMENT_ROUTING_NUMBER_REQUIRED,
  PAYMENT_ACCOUNT_REQUIRED,
  PAYMENT_COMPANY_NAME_REQUIRED,
  PAYMENT_ADDRESS_REQUIRED,
  PAYMENT_CITY_REQUIRED,
  PAYMENT_ZIP_REQUIRED,
  PAYMENT_STATE_REQUIRED,
  DAY_REQUIRED,
  PAYMENT_FUEL_CARD_REQUIRED,
  PERCENT_REQUIRED,
  EMAILS_REQUIRED,
  FILES_REQUIRED,
  I_AGREE_REQUIRED,
  ZIP_MIN_LENGTH,
  FUEL_CARD_MIN,
  FUEL_CARD_MAX,
  PERCENT_MAX_LENGTH,
  MAX_DECIMAL_NUMBER,
} = VALIDATION_MESSAGES;

export const validation = (allFiles: any) => {
  return yup.object().shape({
    id: yup.mixed(),
    type: yup.number().required(PAYMENT_TERM_TYPE_REQUIRED),
    authorityId: yup.array().test('isRequired', AUTHORITY_REQUIRED, function checkisRequired(value) {
      if (this.parent.type === CarrierPaymentTermType.BROKERAGE) {
        return !!value?.length;
      } else {
        return true;
      }
    }),
    factoringType: yup.number().required(FACTORING_TYPE_REQUIRED),
    factoredCompanyName: yup
      .array()
      .test('isRequired', FACTORED_COMPANY_NAME_REQUIRED, function checkisRequired(value) {
        if (
          this.parent.factoringType === CarrierFactoringType.FACTORED &&
          this.parent.type === CarrierPaymentTermType.BROKERAGE
        ) {
          return !!value?.length;
        } else {
          return true;
        }
      }),
    paymentType: yup.number().test('isRequired', PAYMENT_TYPE_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.type === CarrierPaymentTermType.BROKERAGE &&
        this.parent.factoringType === CarrierFactoringType.NOT_FACTORED
      ) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentBankName: yup.string().test('isRequired', PAYMENT_BANK_NAME_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.type !== CarrierPaymentTermType.BROKERAGE ||
        this.parent.factoringType === CarrierFactoringType.FACTORED
      )
        return true;
      if (this.parent.paymentType === CarrierPaymentType.DIRECT_DEPOSIT) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentRoutingNumber: yup
      .string()
      .test('isRequired', PAYMENT_ROUTING_NUMBER_REQUIRED, function checkisRequired(value) {
        if (
          this.parent.type !== CarrierPaymentTermType.BROKERAGE ||
          this.parent.factoringType === CarrierFactoringType.FACTORED
        )
          return true;
        if (this.parent.paymentType === CarrierPaymentType.DIRECT_DEPOSIT) {
          return !!value;
        } else {
          return true;
        }
      }),
    paymentAccountNumber: yup.string().test('isRequired', PAYMENT_ACCOUNT_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.type !== CarrierPaymentTermType.BROKERAGE ||
        this.parent.factoringType === CarrierFactoringType.FACTORED
      )
        return true;
      if (this.parent.paymentType === CarrierPaymentType.DIRECT_DEPOSIT) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentCompanyName: yup.string().test('isRequired', PAYMENT_COMPANY_NAME_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.type !== CarrierPaymentTermType.BROKERAGE ||
        this.parent.factoringType === CarrierFactoringType.FACTORED
      )
        return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentAddress: yup.string().test('isRequired', PAYMENT_ADDRESS_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.type !== CarrierPaymentTermType.BROKERAGE ||
        this.parent.factoringType === CarrierFactoringType.FACTORED
      )
        return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentSecondAddress: yup.string(),
    paymentCity: yup.string().test('isRequired', PAYMENT_CITY_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.type !== CarrierPaymentTermType.BROKERAGE ||
        this.parent.factoringType === CarrierFactoringType.FACTORED
      )
        return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentStateId: yup.string().test('isRequired', PAYMENT_STATE_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.type !== CarrierPaymentTermType.BROKERAGE ||
        this.parent.factoringType === CarrierFactoringType.FACTORED
      )
        return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        return !!value;
      } else {
        return true;
      }
    }),
    paymentZip: yup.string().test('isRequired', PAYMENT_ZIP_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.type !== CarrierPaymentTermType.BROKERAGE ||
        this.parent.factoringType === CarrierFactoringType.FACTORED
      )
        return true;
      if (this.parent.paymentType === CarrierPaymentType.PAPER_CHECK) {
        if (value && value?.length !== 5) {
          return this?.createError({
            message: ZIP_MIN_LENGTH,
            path: 'paymentZip',
          });
        } else return !!value?.length;
      } else {
        return true;
      }
    }),
    brokerPaymentTermType: yup.number().test('isRequired', '', function checkisRequired(value) {
      if (this.parent.type === CarrierPaymentTermType.BROKERAGE) {
        return !!value;
      } else {
        return true;
      }
    }),
    day: yup.string().test('isRequired', DAY_REQUIRED, function checkisRequired(value) {
      if (
        this.parent.brokerPaymentTermType === BrokerPaymentTermsType.QUICK &&
        this.parent.type === CarrierPaymentTermType.BROKERAGE
      ) {
        return !!value;
      } else {
        return true;
      }
    }),
    percent: yup
      .string()
      .test('isRequired', PERCENT_REQUIRED, function checkisRequired(value) {
        if (
          this.parent.brokerPaymentTermType === BrokerPaymentTermsType.QUICK &&
          this.parent.type === CarrierPaymentTermType.BROKERAGE
        ) {
          return !!value;
        } else {
          return true;
        }
      })
      .test('percentValidation', PERCENT_MAX_LENGTH, function emailPatternValidation(value) {
        if (
          this.parent.brokerPaymentTermType === BrokerPaymentTermsType.QUICK &&
          value &&
          this.parent.type === CarrierPaymentTermType.BROKERAGE
        ) {
          return yup.number().max(100).isValidSync(value);
        }
        return true;
      })
      .test('is-decimal', MAX_DECIMAL_NUMBER, function test(value) {
        if (
          this.parent.brokerPaymentTermType !== BrokerPaymentTermsType.QUICK &&
          this.parent.type === CarrierPaymentTermType.BROKERAGE
        )
          return true;
        const decimalPart = value?.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      }),
    brokerEmails: yup.array().test('isRequired', EMAILS_REQUIRED, function checkisRequired(value) {
      if (this.parent.type !== CarrierPaymentTermType.BROKERAGE) return true;
      return !!value?.length;
    }),
    carrierFilesFreightmax: yup.array().test('isRequired', FILES_REQUIRED, function checkisRequired(value) {
      if (this.parent.type === CarrierPaymentTermType.BROKERAGE) {
        return true;
      }
      const hasOneOfRequiredFile = allFiles?.carrierFilesFreightmax?.some((file: any) =>
        ['1', '2', '3', '4', '6'].includes(file.fileType)
      );
      return hasOneOfRequiredFile;
    }),
    iAgree: yup.boolean().test('isRequired', '', function checkisRequired(value) {
      return value;
    }),
  });
};
