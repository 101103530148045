const MenuIcon = () => {
  return (
    <svg width="5" cursor="pointer" height="17" viewBox="0 0 5 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.35363 4.44141C3.45907 4.44141 4.35521 3.54598 4.35521 2.44141C4.35521 1.33684 3.45907 0.441406 2.35363 0.441406C1.24819 0.441406 0.352051 1.33684 0.352051 2.44141C0.352051 3.54598 1.24819 4.44141 2.35363 4.44141Z"
        fill="#667695"
      />
      <path
        d="M2.35363 10.4414C3.45907 10.4414 4.35521 9.54598 4.35521 8.44141C4.35521 7.33684 3.45907 6.44141 2.35363 6.44141C1.24819 6.44141 0.352051 7.33684 0.352051 8.44141C0.352051 9.54598 1.24819 10.4414 2.35363 10.4414Z"
        fill="#667695"
      />
      <path
        d="M2.35363 16.4414C3.45907 16.4414 4.35521 15.546 4.35521 14.4414C4.35521 13.3368 3.45907 12.4414 2.35363 12.4414C1.24819 12.4414 0.352051 13.3368 0.352051 14.4414C0.352051 15.546 1.24819 16.4414 2.35363 16.4414Z"
        fill="#667695"
      />
    </svg>
  );
};

export default MenuIcon;
