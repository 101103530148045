import { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useChangePasswordMutation, useCreatePasswordMutation } from 'services/auth/auth';
import { selectIsLoading } from 'store/sidebarSlice/selector';
import { changeLoading } from 'store/sidebarSlice/sidebar';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { ls } from 'utils/storage';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from '../sign-in/constants/constants';

import { defaultValues } from './constants/constants';
import { INewPasswordFormData } from './constants/types';
import { validation } from './constants/validation';

const useCreateUpdatePassword = (name: string) => {
  const { searchParams } = useDetectedParams();
  const { hash } = searchParams;
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, errors, isDirty, touchedFields },
    getValues,
    trigger,
  } = useForm<INewPasswordFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validation),
  });

  const newPassword = getValues('newPassword');

  useEffect(() => {
    if (newPassword.length && touchedFields.repeatPassword) {
      trigger('repeatPassword');
    }
  }, [newPassword]);

  const [changePassword, { isLoading: isChangeLoading }] = useChangePasswordMutation();
  const [createPassword, { isLoading: isCreateLoading }] = useCreatePasswordMutation();
  const navigate = useNavigate();

  const isCreateOrUpdateLoading = useMemo(() => isChangeLoading || isCreateLoading, [isChangeLoading, isCreateLoading]);
  const onSubmit: SubmitHandler<INewPasswordFormData> = ({ newPassword }) => {
    if (!isLoading && !isCreateOrUpdateLoading) {
      dispatch(changeLoading(true));
      if (name === SUBJECT.CREATE_PASSWORD) {
        createPassword({ hash: hash || '', newPassword }).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            dispatch(changeLoading(false));
          } else {
            notification.success({
              message: SUBJECT.CREATE_UPDATED_SUCCESS,
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            dispatch(changeLoading(false));
            navigate('/sign-in');
          }
        });
      }
      if (name === SUBJECT.NEW_PASSWORD) {
        changePassword({ resetToken: hash || '', newPassword }).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            dispatch(changeLoading(false));
          } else {
            notification.success({
              message: SUBJECT.CREATE_PASS_SUCCESS,
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            dispatch(changeLoading(false));
            navigate('/sign-in');
          }
        });
      }
    }
  };

  useEffect(() => {
    ls.remove('emailValue');
    ls.remove('emailTime');
  }, []);

  return {
    handleSubmit,
    control,
    isValid,
    onSubmit,
    watch,
    errors,
    isDirty,
    isCreateOrUpdateLoading,
  };
};

export default useCreateUpdatePassword;
