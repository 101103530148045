import { useDetectedParams } from 'hooks/useDetectedParams';

import { IComponent } from '../profile/constants/constants';

import PermissionList from './tab/permission-list/PermissionList';

const usePermission = () => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab } = searchParams;

  const openMainDrawer = (mode: string) => {
    setSearchParams({
      ...searchParams,
      mode,
      open: 'true',
    });
  };

  const Component: IComponent = {
    permission: <PermissionList />,
  };

  return { tab, openMainDrawer, Component };
};

export default usePermission;
