import { Dispatch, SetStateAction } from 'react';
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { IFilter } from 'services/profile/offices/interfaces';

export interface IProfileFactoringFormData {
  name: string | number;
  email: string;
  phoneNumber: number | null;
  fax: number | null;
  address: string;
  secondAddress: string;
  city: string;
  stateId: string[] | string;
  zipcode: number | null;
  billBy: boolean;
  file: Array<number>;
  paymentBankName: string;
  paymentRoutingNumber: string;
  paymentAccountNumber: string;
  paymentCompanyName: string;
  paymentAddress: string;
  paymentSecondAddress: string;
  paymentCity: string;
  paymentStateId: undefined | string;
  paymentZip: string;
}

export interface IProfileFactoringFilter {
  factoringCompaniesFilter?: string | null;
}

export interface IOptions {
  title: string;
  value: string;
  key?: string;
}

export interface ICreateEditProfileFactoring {
  getValues: UseFormGetValues<IProfileFactoringFormData>;
  setValue: UseFormSetValue<IProfileFactoringFormData>;
}

export interface IProfileFactoringFormProps extends ICreateEditProfileFactoring {
  id?: string;
  allFiles: Array<any>;
  beforeUploadForCreate?: any;
  handleDeleteFileCreate?: any;
  handleDownloadFileCreate?: any;
  handleViewFileCreate?: any;
  beforeUploadForEdit?: any;
  handleDeleteFileEdit?: any;
  handleDownloadFileEdit?: any;
  handleViewFileEdit?: any;
  handleSubmit: UseFormHandleSubmit<IProfileFactoringFormData>;
  clearErrors?: any;
  onSubmit: SubmitHandler<IProfileFactoringFormData>;
  control: Control<IProfileFactoringFormData>;
  watch: UseFormWatch<IProfileFactoringFormData>;
  errors?: FieldErrors;
  data?: any;
  statesData?: any;
  isFetchingById?: boolean;
  trigger: any;
  isCreateLoading: boolean;
  isEditLoading: boolean;
}

export interface IOfficeTableData {
  id: number;
  date: string;
  officeName: string;
  officeId: string;
  phoneNumber: string;
  faxNumber: string;
  officeLocation: string;
  owner: { number: string; name: string }[];
  numberOfBranches: number;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  payoutPercentage: string;
  terminate: boolean;
  terminate_denied: boolean;
}

export interface IFactoringCompaniesTableColumns {
  title: string;
  id: string;
  dataIndex: string;
  displayType: string;
  isVisible: boolean;
  sortable: boolean;
  width: number;
  isSearchable?: boolean;
}

export enum FactoringStatusTypes {
  FACTORING_STATUS_TYPES_UNSPECIFIED = 0,
  FACTORING_STATUS_TYPES_ACTIVE = 1,
  FACTORING_STATUS_TYPES_INACTIVE = 2,
}

export interface IGetFactoringParams {
  search: string;
  field: string;
  skip: number;
  limit: number;
  order: number;
  orderBy: string;
  filter: IFilter;
}

export interface IFactoringParams {
  setExcelParams?: Dispatch<SetStateAction<IGetFactoringParams | undefined>>;
}

export enum BillBy {
  billByFreightMax = 1,
  billByOther = 2,
}

export enum BillByFreightMax {
  Yes = 1,
  No = 2,
}
