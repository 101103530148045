import React, { FC, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { useGetAgreementsQuery } from 'services/carriersOnBoard/carriersOnBoard';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import Typography from 'ui/typography/Typography';
import { handleDownloadClick, handleViewFileClick } from 'utils/helpers';

import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { Checkbox } from '../paymentTerms-sign-up/hoc-instance/instance';

import { SUBJECT } from './constants';

import styles from './PreferredCarrier.module.scss';

const PreferredCarrier: FC<any> = ({ setValue, watch, errors, control }) => {
  const [panelShowed, setPanelShowed] = useState(false);
  const { data: agreements } = useGetAgreementsQuery({});

  const onCancel = () => {
    setPanelShowed(false);
    setValue('factoring', false);
    setValue('quickPay', false);
    setValue('loadBoard', false);
    setValue('liveTruckBoard', false);
    setValue('fuelAdvance', false);
    setValue('preferedFeature', false);
  };
  const onOpen = () => {
    setPanelShowed(true);
    setValue('preferedFeature', true);
  };

  return (
    <ViewItemWrapper
      title={SUBJECT.BECOME_A_PREFERRED_CARRIER}
      Component={
        !panelShowed ? (
          <Button onClick={onOpen}>{SUBJECT.VIEW_PREFERRED_CARRIER_BENEFITS}</Button>
        ) : (
          <Button onClick={onCancel} type="text" variant="text" size="large">
            {SUBJECT.NOT_INTERESTED}
          </Button>
        )
      }
    >
      {panelShowed ? (
        <div>
          <div style={{ marginTop: '20px' }}>
            <Typography variant="paragraph" className={styles.bold} children={SUBJECT.FREIGHTMAX} />

            {agreements?.data?.length && (
              <Row align="middle" gutter={[0, 22]}>
                <Col span={8}>
                  <Typography variant="paragraph" className={styles.uploadTitle}>
                    Please Review And Sign
                  </Typography>
                </Col>

                {agreements?.data?.map((file: any) => (
                  <Col span={24} key={file.id}>
                    <Row align="middle" gutter={10}>
                      <Col span={5}>
                        <Typography variant="paragraph" children={file?.name} className={styles.fileTypeHeading} />
                      </Col>
                      <Col>
                        <Row align="middle" gutter={8}>
                          <Col role="button">
                            <Button variant="ghost" onClick={() => handleViewFileClick(file?.url)}>
                              <EyeIconSvg outlined={true} />
                            </Button>
                          </Col>
                          <Col role="button">
                            <Button variant="ghost" onClick={() => handleDownloadClick(file?.url)}>
                              <NoaDownloadIcon />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))}
                <Col span={24}>
                  <Row gutter={[15, 0]} align={'middle'}>
                    <Col>
                      <Typography variant="paragraph" className={styles.bold} children={SUBJECT.IAGREE} />
                    </Col>
                    <Col>
                      <div className={styles.checkboxWrapper}>
                        <Checkbox
                          name="iAgreePref"
                          control={control}
                          rules={{
                            validator: (_: any, value: boolean) =>
                              value ? Promise.resolve() : Promise.reject(new Error()),
                          }}
                          required
                          checked={watch('iAgreePref')}
                          size="small"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {errors?.iAgreePref && (
              <Col span={24}>
                <ErrorMessage fieldError={errors?.iAgreePref ? { message: 'Required Field' } : {}} />
              </Col>
            )}
            <Divider />
            <Typography variant="paragraph" className={styles.uploadTitle} children={SUBJECT.PROVIDE} />

            <Row className={styles.typeSection}>
              <Col span={8}>
                <Typography variant="paragraph" className={styles.bold} children={SUBJECT.FUEL_ADVANCE} />
              </Col>
              <Col>
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    name="fuelAdvance"
                    control={control}
                    rules={{ required: true }}
                    size="small"
                    checked={watch('fuelAdvance')}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.typeSection}>
              <Col span={8}>
                <Typography variant="paragraph" className={styles.bold} children={SUBJECT.QUICK} />
              </Col>
              <Col>
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    name="quickPay"
                    control={control}
                    rules={{ required: true }}
                    checked={watch('quickPay')}
                    size="small"
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.typeSection}>
              <Col span={8}>
                <Typography variant="paragraph" className={styles.bold} children={SUBJECT.LOAD_BOARD} />
              </Col>
              <Col>
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    name="loadBoard"
                    control={control}
                    rules={{ required: true }}
                    checked={watch('loadBoard')}
                    size="small"
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.typeSection}>
              <Col span={8}>
                <Typography variant="paragraph" className={styles.bold} children={SUBJECT.LIVE_TRUCK_BOARD} />
              </Col>
              <Col>
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    name="liveTruckBoard"
                    control={control}
                    rules={{ required: true }}
                    checked={watch('liveTruckBoard')}
                    size="small"
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.typeSection}>
              <Col span={8}>
                <Typography variant="paragraph" className={styles.bold} children={SUBJECT.FACTORING} />
              </Col>
              <Col>
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    name="factoring"
                    control={control}
                    rules={{ required: true }}
                    checked={watch('factoring')}
                    size="small"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className={styles.checkList}>
          <div className={styles.checkListItem}>
            <SuccessIcon />
            Fuel Advance
          </div>
          <div className={styles.checkListItem}>
            <SuccessIcon />
            Quick Pay
          </div>
          <div className={styles.checkListItem}>
            <SuccessIcon />
            Load Board
          </div>
          <div className={styles.checkListItem}>
            <SuccessIcon />
            Live Truck Board
          </div>
          <div className={styles.checkListItem}>
            <SuccessIcon />
            Factoring
          </div>
        </div>
      )}
    </ViewItemWrapper>
  );
};

export default PreferredCarrier;
