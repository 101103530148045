import { IFormData } from 'pages/authorization/sign-in/constants/types';

import CustomCheckbox from './checkbox/Checkbox';
import { ICheckboxProps } from './checkbox/constants/types';
import { withController } from './hoc/Controller';
import { IInputProps } from './input/constants/types';
import CustomInput from './input/Input';

//here while wrapping your component into withController you have to send that components props interface in the first parameter,
// in the second parameter - your form's data interface, please follow the example,
// there is such a case, if we need f.e Select with different form's data interface, you can call withController in your component's separate file,
// in that case there won't be name collisions.
export const Input = withController<IInputProps, IFormData>(CustomInput);
export const Checkbox = withController<ICheckboxProps, IFormData>(CustomCheckbox);
