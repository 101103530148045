import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetCollectionByIdQuery } from 'services/payroll/information/information';

import { DEFAULT_VALUES_COLLECTION } from '../../constants/constants';
import { CollectionCards, ICollectionValues } from '../../constants/types';
import { collectionValidation } from '../constants/validation';

export const useCollection = () => {
  const { searchParams } = useDetectedParams();
  const { card, id } = searchParams;

  const { currentData: collectionInfo, isFetching: isCollectionFetching } = useGetCollectionByIdQuery(
    { id },
    { skip: !id || card !== CollectionCards.COLLECTION }
  );

  const {
    control,
    formState: { isDirty, isValid, errors },
    watch,
    reset,
    trigger,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm<Partial<ICollectionValues>>({
    defaultValues: DEFAULT_VALUES_COLLECTION,
    mode: 'all',
    resolver: yupResolver(collectionValidation),
  });

  useEffect(() => {
    reset({
      paymentPlan: String(collectionInfo?.result?.paymentPlan),
      paymentPlanId: collectionInfo?.result?.id,
    });
  }, [collectionInfo]);

  const isCurrentMonth = useMemo(() => {
    return dayjs(collectionInfo?.result?.createdFor).month() === dayjs().month();
  }, [collectionInfo?.result?.createdFor]);

  return {
    errors,
    control,
    isDirty,
    isValid,
    collectionInfo,
    isCollectionFetching,
    amount: collectionInfo?.result?.amount,
    paymentPlan: collectionInfo?.result?.paymentPlan,
    watch,
    reset,
    trigger,
    setValue,
    clearErrors,
    handleSubmit,
    isCurrentMonth,
  };
};
