import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const { AUTHORITY_REQUIRED, CUSTOMER_REQUIRED, AMOUNT_REQUIRED, NOT_ZERO, LESS_THAN_MILLION } = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  authority: yup.array().min(1, AUTHORITY_REQUIRED).required(AUTHORITY_REQUIRED),
  customer: yup.array().min(1, CUSTOMER_REQUIRED).required(CUSTOMER_REQUIRED),
  amount: yup
    .string()
    .typeError(AMOUNT_REQUIRED)
    .nullable()
    .test('is-decimal', AMOUNT_REQUIRED, function checkLength(value) {
      const decimalPart = value?.toString().split('.')[1];

      if (this.parent?.authority?.length && this.parent?.customer?.length && !value?.length) {
        return this.createError({
          message: AMOUNT_REQUIRED,
          path: 'amount',
        });
      } else if (String(value).replace('.', '')! == '0') {
        return this.createError({
          message: NOT_ZERO,
          path: 'amount',
        });
      } else if ((!decimalPart || decimalPart.length <= 2) && Number(value) <= 1000000) {
        return true;
      } else if (Number(value) >= 1000000) {
        return this.createError({
          message: LESS_THAN_MILLION,
          path: 'amount',
        });
      } else {
        return true;
      }
    }),
});
