import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import { emailFormatter, formatPhone, onlyNumbersFormatter } from 'utils/helpers';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import FileItems from '../../../authorities/components/file-item/FileItems';
import { SUBJECT } from '../../constants/constants';
import { IProfileFactoringFormProps } from '../../constants/types';
import { Input } from '../../hoc-instance/instance';

import styles from './CreateFactoringCompaniesForm.module.scss';

const CreateFactoringCompaniesForm = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  getValues,
  errors,
  setValue,
  statesData,
  watch,
  isFetchingById,
  isCreateLoading,
  isEditLoading,
  clearErrors,
  handleViewFileEdit,
  handleDeleteFileEdit,
  handleDownloadFileEdit,
  beforeUploadForEdit,
  handleViewFileCreate,
  handleDeleteFileCreate,
  handleDownloadFileCreate,
  beforeUploadForCreate,
  allFiles,
  id,
}: IProfileFactoringFormProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isCreateLoading && !isEditLoading }));
  }, [isCreateLoading, isEditLoading]);

  const { searchParams } = useDetectedParams();
  const { mode } = searchParams;

  useEffect(() => {
    if (mode === 'add') {
      clearErrors();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainLoader isFetching={isFetchingById} />
      <ViewItemWrapper title={mode === 'add' ? SUBJECT.ADD_NEW_FACTORING : SUBJECT.EDIT_FACTORING}>
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="name"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.NAME}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) =>
                setValue('name', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
              }
            />
          </Col>
          <Col span={8}>
            <Input
              name="email"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.EMAIL}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) =>
                setValue('email', emailFormatter(e.target.value.trimStart()), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
            />
          </Col>
          <Col span={8}>
            <Input
              name="phoneNumber"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.PHONE_NUMBER}
              getValues={watch}
              onChange={(e: any) =>
                setValue('phoneNumber', formatPhone(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              required
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <Input
              name="address"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.ADDRESS}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) => setValue('address', e.target.value.trimStart(), { shouldValidate: true })}
            />
          </Col>
          <Col span={8}>
            <Input
              name="secondAddress"
              type="text"
              control={control}
              placeholder={SUBJECT.SECOND_ADDRESS}
              getValues={getValues}
              errors={errors}
              onChange={(e: any) =>
                setValue('secondAddress', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
              }
            />
          </Col>
          <Col span={8}>
            <Input
              name="fax"
              type="text"
              control={control}
              rules={{ required: false }}
              onChange={(e: any) =>
                setValue('fax', formatPhone(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              placeholder={SUBJECT.FAX}
              getValues={watch}
              errors={errors}
            />
          </Col>

          <Col span={8}>
            <Input
              name="city"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CITY}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) => setValue('city', e.target.value.trimStart(), { shouldValidate: true })}
            />
          </Col>
          <Col span={8}>
            <Controller
              name="stateId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <StateSelect
                    options={statesData}
                    placeholder={SUBJECT.STATE}
                    name={field.name}
                    errors={errors}
                    onBlur={() => trigger('stateId')}
                    onChange={(value: string) =>
                      setValue('stateId', value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={field.value}
                    required
                    allowClear
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.stateId} />
          </Col>
          <Col span={8}>
            <Input
              name="zipcode"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.ZIPCODE}
              onChange={(e: any) =>
                setValue('zipcode', onlyNumbersFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              getValues={getValues}
              required
              errors={errors}
            />
          </Col>
          <Col span={4}>
            <h4>{SUBJECT.BILL_BY}</h4>
          </Col>
          <Col span={1}>
            <span>
              <Switch
                checked={watch('billBy')}
                onClick={checked => setValue('billBy', checked, { shouldDirty: true })}
              />
            </span>
          </Col>
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.UPLOAD_FILE}>
        <Row className={styles.uploadSection} align="middle" gutter={[20, 40]}>
          <Col span={12}>
            <Upload
              uploadText={SUBJECT.UPLOAD}
              className={styles.upload}
              beforeUpload={beforeUploadForCreate || beforeUploadForEdit}
            />
          </Col>
          <Col span={12}>
            <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT} className={styles.allowedFormat} />
          </Col>
          <Col span={24}>
            <Controller
              control={control}
              render={({ field: { value: _value } }) => (
                <FileItems
                  handleDownloadFileEdit={handleDownloadFileEdit}
                  handleDownloadFile={handleDownloadFileCreate}
                  handleViewFileEdit={handleViewFileEdit}
                  handleViewFileCreate={handleViewFileCreate}
                  itemId={id}
                  onDeleteClick={handleDeleteFileCreate || handleDeleteFileEdit}
                  files={allFiles}
                />
              )}
              name="file"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorMessage fieldError={errors?.file} />
          </Col>
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.DIRECT_DEPOSIT}>
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <Input
              name="paymentBankName"
              control={control}
              placeholder={SUBJECT.BANK_NAME}
              getValues={getValues}
              errors={errors}
              rules={{ required: true }}
              required
              onChange={(e: any) =>
                setValue('paymentBankName', e.target.value.trimStart(), {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              onBlur={() => trigger('paymentBankName')}
            />
          </Col>
          <Col span={8}>
            <Input
              name="paymentRoutingNumber"
              control={control}
              placeholder={SUBJECT.ROUTING_NUMBER}
              onChange={(e: any) =>
                setValue('paymentRoutingNumber', onlyNumbersFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              getValues={getValues}
              onBlur={() => trigger('paymentRoutingNumber')}
              errors={errors}
              rules={{ required: true }}
              required
            />
          </Col>
          <Col span={8}>
            <Input
              name="paymentAccountNumber"
              control={control}
              onChange={(e: any) =>
                setValue('paymentAccountNumber', onlyNumbersFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              onBlur={() => trigger('paymentAccountNumber')}
              placeholder={SUBJECT.ACCOUNT_NUMBER}
              getValues={getValues}
              errors={errors}
              rules={{ required: true }}
              required
            />
          </Col>
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.PAYPER_CHECK}>
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <Input
              name="paymentCompanyName"
              control={control}
              placeholder={SUBJECT.PAYMENT_COMPANY_NAME}
              getValues={watch}
              errors={errors}
              rules={{ required: true }}
              required
              onBlur={() => trigger('paymentCompanyName')}
              onChange={(e: any) =>
                setValue('paymentCompanyName', e.target.value.trimStart(), {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
            />
          </Col>
          <Col span={8}>
            <Input
              name="paymentAddress"
              control={control}
              placeholder={SUBJECT.PAYMENT_ADDRESS}
              getValues={getValues}
              errors={errors}
              rules={{ required: true }}
              required
              onBlur={() => trigger('paymentAddress')}
              onChange={(e: any) =>
                setValue('paymentAddress', e.target.value.trimStart(), {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
            />
          </Col>
          <Col span={8}>
            <Input
              name="paymentSecondAddress"
              control={control}
              placeholder={SUBJECT.SECOND_ADDRESS}
              getValues={getValues}
              errors={errors}
              onChange={(e: any) =>
                setValue('paymentSecondAddress', e.target.value.trimStart(), {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
            />
          </Col>
          <Col span={8}>
            <Input
              name="paymentCity"
              control={control}
              placeholder={SUBJECT.CITY}
              getValues={watch}
              errors={errors}
              rules={{ required: true }}
              onBlur={() => trigger('paymentCity')}
              required
              onChange={(e: any) => setValue('paymentCity', e.target.value.trimStart(), { shouldValidate: true })}
            />
          </Col>
          <Col span={8}>
            <Controller
              name="paymentStateId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <StateSelect
                    allowClear
                    options={statesData || []}
                    placeholder={SUBJECT.STATE}
                    name={field.name}
                    onBlur={() => trigger('paymentStateId')}
                    errors={errors}
                    onChange={(value: string) =>
                      setValue('paymentStateId', value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={field.value}
                    required
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.paymentStateId} />
          </Col>
          <Col span={8}>
            <Input
              name="paymentZip"
              control={control}
              placeholder={SUBJECT.ZIP_CODE}
              getValues={getValues}
              errors={errors}
              rules={{ required: true }}
              required
              onBlur={() => trigger('paymentZip')}
              onChange={(e: any) =>
                setValue('paymentZip', onlyNumbersFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
            />
          </Col>
        </Row>
      </ViewItemWrapper>
    </form>
  );
};
export default CreateFactoringCompaniesForm;
