const SunIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_52_117)">
        <path
          d="M10.0097 4.33334C7.00968 4.33334 4.59302 6.75001 4.59302 9.75001C4.59302 12.75 7.00968 15.1667 10.0097 15.1667C13.0097 15.1667 15.4264 12.75 15.4264 9.75001C15.4264 6.75001 13.0097 4.33334 10.0097 4.33334Z"
          fill="#B8BFCC"
        />
        <path
          d="M10.0096 3.83334C10.5096 3.83334 10.8429 3.50001 10.8429 3.00001V1.66668C10.8429 1.16668 10.5096 0.833344 10.0096 0.833344C9.5096 0.833344 9.17627 1.16668 9.17627 1.66668V3.00001C9.17627 3.41668 9.5096 3.83334 10.0096 3.83334Z"
          fill="#B8BFCC"
        />
        <path
          d="M4.42643 5.58333C4.5931 5.75 4.8431 5.83333 5.00977 5.83333C5.17643 5.83333 5.42643 5.75 5.5931 5.58333C5.92643 5.25 5.92643 4.75 5.5931 4.41667L4.67643 3.5C4.3431 3.16667 3.8431 3.16667 3.50977 3.5C3.17643 3.83333 3.17643 4.33333 3.50977 4.66667L4.42643 5.58333Z"
          fill="#B8BFCC"
        />
        <path
          d="M3.84302 10C3.84302 9.50002 3.50968 9.16669 3.00968 9.16669H1.67635C1.17635 9.16669 0.843018 9.50002 0.843018 10C0.843018 10.5 1.17635 10.8334 1.67635 10.8334H3.00968C3.42635 10.8334 3.84302 10.5 3.84302 10Z"
          fill="#B8BFCC"
        />
        <path
          d="M4.42643 14.4167L3.50977 15.3334C3.17643 15.6667 3.17643 16.1667 3.50977 16.5C3.67643 16.6667 3.92643 16.75 4.0931 16.75C4.25977 16.75 4.50977 16.6667 4.67643 16.5L5.5931 15.5834C5.92643 15.25 5.92643 14.75 5.5931 14.4167C5.25977 14.0834 4.75977 14.0834 4.42643 14.4167Z"
          fill="#B8BFCC"
        />
        <path
          d="M10.0096 16.1667C9.5096 16.1667 9.17627 16.5 9.17627 17V18.3334C9.17627 18.8334 9.5096 19.1667 10.0096 19.1667C10.5096 19.1667 10.8429 18.8334 10.8429 18.3334V17C10.8429 16.5834 10.5096 16.1667 10.0096 16.1667Z"
          fill="#B8BFCC"
        />
        <path
          d="M15.5929 14.4167C15.2596 14.0834 14.7596 14.0834 14.4263 14.4167C14.0929 14.75 14.0929 15.25 14.4263 15.5834L15.3429 16.5C15.5096 16.6667 15.7596 16.75 15.9263 16.75C16.0929 16.75 16.3429 16.6667 16.5096 16.5C16.8429 16.1667 16.8429 15.6667 16.5096 15.3334L15.5929 14.4167Z"
          fill="#B8BFCC"
        />
        <path
          d="M18.3429 9.16669H17.0096C16.5096 9.16669 16.1763 9.50002 16.1763 10C16.1763 10.5 16.5096 10.8334 17.0096 10.8334H18.3429C18.8429 10.8334 19.1763 10.5 19.1763 10C19.1763 9.50002 18.8429 9.16669 18.3429 9.16669Z"
          fill="#B8BFCC"
        />
        <path
          d="M15.0096 5.83333C15.2596 5.83333 15.4263 5.75 15.5929 5.58333L16.5096 4.66667C16.8429 4.33333 16.8429 3.83333 16.5096 3.5C16.1763 3.16667 15.6763 3.16667 15.3429 3.5L14.4263 4.41667C14.0929 4.75 14.0929 5.25 14.4263 5.58333C14.5929 5.75 14.7596 5.83333 15.0096 5.83333Z"
          fill="#B8BFCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_52_117">
          <rect width="20" height="20" fill="white" transform="translate(0.00976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SunIcon;
