import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

export interface ICommentsNotifications {
  [key: string]: string;
}

export interface IComments {
  commentsList: ICommentsNotifications[];
  count: number;
}

export interface ICustomCommentProps<T extends FieldValues> {
  comments: IComments;
  loadHistory?: any;
  setIsDefaultHistory?: any;
  name: string;
  control: Control<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  withNotes?: boolean;
  deleteComment?: any;
  createComment?: any;
  required?: boolean;
  isBlockCanceled?: boolean;
  id?: string;
  activeSection?: string;
  getMoreComments?: () => void;
  isShowMoreVisible?: boolean;
  rawerRef?: any;
  canCreate?: boolean;
  canDelete?: boolean;
  hasDeleteCommentPermission?: boolean;
  inView?: boolean;
  getMoreNotes?: any;
  isShowMoreVisibleNotes?: boolean;
  isDefaultHistory?: boolean;
  drawerRef?: any;
  isFetchingHistory?: boolean;
  isLoadsCommentsLoading?: boolean;
}

export enum LoadHistoryMessageTypes {
  UNSPECIFIED = 0,
  AGENT = 1,
  EMPLOYEE = 2,
  BRANCH = 3,
  OFFICE = 4,
  DATE = 5,
  REQUEST_STATUS = 6,
  DEFAULT = 7,
  CUSTOMER = 8,
  CARRIER = 9,
  FILE = 10,
  LOAD_STATUS = 11,
  LOAD_TYPE = 12,
  COMMENT = 13,
  AMOUNT = 14,
  TRANSACTION = 15,
  TRANSACTION_TYPE = 16,
  TRANSACTION_STATUS = 17,
  REFERENCE = 18,
  INVOICE = 19,
  FACILITY = 20,
  ADDRESS = 21,
  CITY = 22,
  STATE = 23,
  ZIP = 24,
  PERCENT = 25,
  PAYMENT_TERM_TYPE = 26,
}
