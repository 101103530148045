const RequestErroIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1247 20.666H15.1247V12.666H13.1247V20.666ZM14.0247 10.1993C14.3358 10.1993 14.597 10.0993 14.8081 9.89935C15.0192 9.69935 15.1247 9.44379 15.1247 9.13268C15.1247 8.82157 15.0192 8.5549 14.8081 8.33268C14.597 8.11046 14.3358 7.99935 14.0247 7.99935C13.7136 7.99935 13.4525 8.11046 13.2414 8.33268C13.0303 8.5549 12.9247 8.82157 12.9247 9.13268C12.9247 9.44379 13.0303 9.69935 13.2414 9.89935C13.4525 10.0993 13.7136 10.1993 14.0247 10.1993ZM14.0247 27.3327C12.2025 27.3327 10.4803 26.9827 8.85807 26.2827C7.23585 25.5827 5.81918 24.6271 4.60807 23.416C3.39696 22.2049 2.44141 20.7882 1.74141 19.166C1.04141 17.5438 0.691406 15.8105 0.691406 13.966C0.691406 12.1438 1.04141 10.4216 1.74141 8.79935C2.44141 7.17713 3.39696 5.76602 4.60807 4.56602C5.81918 3.36602 7.23585 2.41602 8.85807 1.71602C10.4803 1.01602 12.2136 0.666016 14.0581 0.666016C15.8803 0.666016 17.6025 1.01602 19.2247 1.71602C20.847 2.41602 22.2581 3.36602 23.4581 4.56602C24.6581 5.76602 25.6081 7.17713 26.3081 8.79935C27.0081 10.4216 27.3581 12.1549 27.3581 13.9993C27.3581 15.8216 27.0081 17.5438 26.3081 19.166C25.6081 20.7882 24.6581 22.2049 23.4581 23.416C22.2581 24.6271 20.847 25.5827 19.2247 26.2827C17.6025 26.9827 15.8692 27.3327 14.0247 27.3327Z"
        fill="#FF5454"
      />
    </svg>
  );
};

export default RequestErroIcon;
