import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetAgentQuery, useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import { useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';

import { FILTER_DEFAULT_VALUES } from './constants/constants';
import { IInformationFilters } from './constants/types';

export const useInformationFilter = () => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab, card, officePayroll, branchPayroll, agentPayroll, startDate, endDate } = searchParams;

  const userInfo = useSelector(selectUserInfo);
  const { branchIds, officeIds } = userInfo;
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const {
    reset: resetFilterForm,
    control: filterControl,
    setValue: setFilterValue,
    watch: filterWatch,
    formState: { isDirty: isFilterDirty, dirtyFields },
  } = useForm<IInformationFilters>({ defaultValues: FILTER_DEFAULT_VALUES });

  const isChangedDate = useMemo(() => {
    if (startDate && endDate) {
      return true;
    } else return !!(startDate && !endDate && startDate !== String(FILTER_DEFAULT_VALUES.monthPickerPayroll.startDate));
  }, [
    filterWatch('monthPickerPayroll').startDate,
    startDate,
    endDate,
    FILTER_DEFAULT_VALUES.monthPickerPayroll.startDate,
  ]);

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const currentMonth = dayjs().startOf('month').valueOf();

  const [selectedOffices, setSelectedOffices] = useState<any>([]);
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [finalBranchesOptions, setFinalBranchesOptions] = useState<any>({ result: [], count: 0 });

  const { data: officesOptions = { result: [], count: 0 }, isFetching: isOfficeFetching } =
    useGetOfficesAutocompleteQuery(officesFilter, {
      skip: roleLevel ? roleLevel > IRoleLevel.corpLevel : tab !== 'information',
    });

  const { data: branchesData = { result: [], count: 0 }, isFetching: isBranchFetching } =
    useGetBranchesAutocompleteQuery(
      { officeId: selectedOffices?.[0]?.id || officeIds?.[0], withDefaults: true, ...branchFilter },
      {
        skip:
          roleLevel > IRoleLevel.officeLevel ||
          (!filterWatch('officePayroll')?.length && roleLevel < IRoleLevel.officeLevel) ||
          filterWatch('officePayroll')!.length > 1,
      }
    );

  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    {
      ...agentsFilter,
      branchId: filterWatch('branchPayroll')?.[0] || branchIds?.[0],
      excludeOwners: true,
    },
    {
      skip:
        roleLevel <= IRoleLevel.officeLevel &&
        (typeof filterWatch('branchPayroll') === 'string' || filterWatch('branchPayroll')?.length !== 1),
    }
  );

  const { data: selectedAgent, isFetching: isSelectedAgentFetching } = useGetAgentQuery(agentPayroll, {
    skip: !agentPayroll,
  });

  const [selectedAgentOption, setSelectedAgentOption] = useState<any>({});
  const [finalAgentOptions, setFinalAgentOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedAgent) {
      setSelectedAgentOption([
        {
          firstName: selectedAgent?.result?.firstName,
          lastName: selectedAgent?.result?.lastName,
          username: selectedAgent?.result?.username,
          id: selectedAgent?.result?.id,
          value: String(selectedAgent?.result?.id),
        },
      ]);
    }
  }, [selectedAgent, isSelectedAgentFetching]);

  useEffect(() => {
    if (agentsOptions && !selectedAgentOption.id) {
      setFinalAgentOptions(agentsOptions);
    } else if (agentsOptions) {
      setFinalAgentOptions({
        result: [selectedAgentOption, ...agentsOptions.result.filter((el: any) => el.id !== selectedAgentOption?.id)],
        count: agentsOptions?.count,
      });
    }
  }, [selectedAgentOption, agentsOptions?.result?.length]);

  useEffect(() => {
    if (Array.isArray(filterWatch('branchPayroll'))) {
      setSelectedBranches((prev: any) =>
        [
          ...prev.filter((el: any) => !branchesData?.result?.some((item: any) => item.value == el.value)),
          ...branchesData.result,
        ].filter((el: any) => filterWatch('branchPayroll')?.some((item: any) => item == el.value))
      );
    }
  }, [filterWatch('branchPayroll')?.length, branchesData?.result?.length]);

  useEffect(() => {
    if (Array.isArray(filterWatch('officePayroll'))) {
      setSelectedOffices((prev: any) =>
        [
          ...prev.filter((el: any) => !officesOptions?.result?.some((item: any) => item.value == el.value)),
          ...officesOptions.result,
        ].filter((el: any) => filterWatch('officePayroll')?.some((item: any) => item == el.value))
      );
    }
  }, [filterWatch('officePayroll')?.length, isOfficeFetching]);

  useEffect(() => {
    if (officesOptions?.result) {
      setFinalOfficeOptions({
        result: [
          ...selectedOffices,
          ...officesOptions.result.filter((el: any) => !selectedOffices.some((item: any) => item.value == el.value)),
        ],
        count: officesOptions.count,
      });
    } else {
      setFinalOfficeOptions(selectedOffices);
    }
  }, [selectedOffices?.length, isOfficeFetching]);

  useEffect(() => {
    if (branchesData?.result?.length) {
      setFinalBranchesOptions({
        result: [
          ...selectedBranches,
          ...branchesData.result.filter((el: any) => !selectedBranches.some((item: any) => item.value == el.value)),
        ],
        count: branchesData.count,
      });
    } else {
      setFinalBranchesOptions({ result: [], count: 0 });
    }
  }, [selectedBranches?.length, isBranchFetching]);

  const filterOfficeValue = filterWatch('officePayroll');
  const filterBranchValue = filterWatch('branchPayroll');
  const filterAgentValue = filterWatch('agentPayroll')?.[0]?.id
    ? filterWatch('agentPayroll')?.[0]?.id
    : filterWatch('agentPayroll');

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(filterOfficeValue
        ? { officePayroll: Array.isArray(filterOfficeValue) ? filterOfficeValue?.join(',') : '' }
        : { officePayroll: '' }),
      ...(filterBranchValue
        ? { branchPayroll: Array.isArray(filterBranchValue) ? filterBranchValue?.join(',') : '' }
        : { branchPayroll: '' }),
      ...(filterAgentValue ? { agentPayroll: filterAgentValue } : { agentPayroll: '' }),
    });
  }, [filterOfficeValue, filterBranchValue, filterAgentValue, card]);

  useEffect(() => {
    if (filterWatch('officePayroll')!.length !== 1 && roleLevel < IRoleLevel.officeLevel) {
      setSearchParams({
        ...searchParams,
        branchPayroll: '',
        agentPayroll: '',
      });
      setFilterValue('branchPayroll', []);
      setFilterValue('agentPayroll', []);
      setSelectedAgentOption({});
    }
  }, [filterWatch('officePayroll'), roleLevel]);

  useEffect(() => {
    if (filterWatch('branchPayroll')!.length !== 1 && roleLevel < IRoleLevel.branchLevel) {
      setSearchParams({
        ...searchParams,
        agentPayroll: '',
      });
      setFilterValue('agentPayroll', []);
      setSelectedAgentOption({});
    }
  }, [filterWatch('branchPayroll'), roleLevel]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  useEffect(() => {
    const filterOptions = [
      { key: 'officePayroll', value: officePayroll ? officePayroll.split(',') : officePayroll },
      { key: 'branchPayroll', value: branchPayroll ? branchPayroll.split(',') : branchPayroll },
      { key: 'agentPayroll', value: agentPayroll || [] },
      { key: 'monthPickerPayroll.startDate', value: startDate },
      { key: 'monthPickerPayroll.endDate', value: endDate },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setFilterValue(key as keyof IInformationFilters, value, {
          shouldDirty: key === 'monthPickerPayroll.startDate' ? false : !!value?.length,
        });
      }
    });
  }, [officePayroll, branchPayroll, agentPayroll, startDate, endDate, agentsOptions?.result?.length]);

  const resetFilter = (withoutDate = false) => {
    resetFilterForm();
    setSelectedAgentOption({});
    setSearchParams({
      ...searchParams,
      officePayroll: '',
      branchPayroll: '',
      agentPayroll: '',
      ...(!withoutDate && { startDate: String(currentMonth) }),
      ...(!withoutDate && { endDate: '' }),
      dateMode: '',
    });
  };

  return {
    finalOfficeOptions,
    finalBranchesOptions,
    filterControl,
    isFilterDirty,
    selectedOffices,
    selectedBranches,
    finalAgentOptions,
    selectedAgentOption,
    filterWatch,
    resetFilter,
    setFilterValue,
    setAgentsFilter,
    setBranchFilter,
    setOfficesFilter,
    isChangedDate,
    dirtyFields,
    setSelectedAgentOption,
  };
};
