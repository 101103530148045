import { IContactCreateEditData } from './types';

export enum SUBJECT {
  CREATE_CONTACT = 'Create Contact',
  SEARCH_CONTACT = 'Search Contact',
  EDIT_CONTACT = 'Edit Contact',
  EMAIL = 'Email',
  PHONE_NUMBER = 'Phone Number',
  RADIO_CORPORATE = 'corporate',
  RADIO_OFFICE = 'agency',
  RADIO_BRANCH = 'branch',
  CORPORATE = 'Corporate',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  POSITION = 'Position',
}

export const SEARCH_CONTACT_COLUMNS = [
  { key: 'username', name: 'Username' },
  { key: 'userId', name: 'User Id' },
];

export const CONTACT_KEYS = ['id', 'username', 'userId'];

export const CONTACT_RADIO_OPTIONS = [
  { value: SUBJECT.RADIO_CORPORATE, label: SUBJECT.CORPORATE },
  { value: SUBJECT.RADIO_OFFICE, label: SUBJECT.OFFICE },
  { value: SUBJECT.RADIO_BRANCH, label: SUBJECT.BRANCH },
];

export const MOCK_DATA_SELECT = [
  { value: '123456', id: '123456' },
  { value: '471247', id: '471247' },
  { value: '333333', id: '333333' },
  { value: '444444', id: '444444' },
];

export const defaultValues: IContactCreateEditData = {
  searchContact: [],
  email: '',
  phone: '',
  position: '',
};
