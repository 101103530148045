import { ReactNode } from 'react';

export enum ILoadStatuses {
  LOAD_STATUS_UNSPECIFIED = 0,
  LOAD_STATUS_REQUEST_CHECK = 1,
  LOAD_STATUS_DENIED = 2,
  LOAD_STATUS_NOT_PROCESSING = 3,
  LOAD_STATUS_PROCESSING = 4,
  LOAD_STATUS_BOTH_PENDING = 5,
  LOAD_STATUS_CARRIER_PAID = 6,
  LOAD_STATUS_CARRIER_PENDING_CUSTOMER_DEBT = 7,
  LOAD_STATUS_CARRIER_PAID_CUSTOMER_DEBT = 8,
  LOAD_STATUS_CUSTOMER_PAID = 9,
  LOAD_STATUS_PROCESSING_AND_CARRIER_PAID = 10,
  LOAD_STATUS_PROCESSING_FROM_PENDING = 11,
  LOAD_STATUS_PROCESSING_AND_CUSTOMER_PAID = 12,
  LOAD_STATUS_PROCESSING_AND_CUSTOMER_DEBT = 13,
  LOAD_STATUS_BOTH_PAID = 14,
  LOAD_STATUS_FACTORED = 4,
}

export interface ILoadStatusProps {
  loadStatus: number;
  customerStatus: number;
  carrierStatus: number;
  isProcessing: boolean;
  withoutBackground?: boolean;
  withoutLoadsText?: boolean;
}

export interface IStatusLIst {
  [key: string]: ReactNode;
}

export enum LoadStatus {
  LOAD_STATUS_UNSPECIFIED = 0,
  LOAD_STATUS_REQUEST_CHECK = 1,
  LOAD_STATUS_DENIED = 2,
  LOAD_STATUS_NOT_PROCESSING = 3,
  LOAD_STATUS_FACTORED = 4,
}

export enum LoadGlobalStatus {
  LOAD_STATUS_UNSPECIFIED = 0,
  LOAD_STATUS_REQUEST_CHECK = 1,
  LOAD_STATUS_DENIED = 2,
  LOAD_STATUS_NOT_PROCESSING = 3,
  LOAD_STATUS_FACTORED = 4,
}

export enum CustomerStatus {
  CUSTOMER_STATUS_UNSPECIFIED = 0,
  CUSTOMER_STATUS_PENDING = 1,
  CUSTOMER_STATUS_PAID = 2,
  CUSTOMER_STATUS_DEBT = 3,
}

export enum CarrierStatus {
  CARRIER_STATUS_UNSPECIFIED = 0,
  CARRIER_STATUS_PENDING = 1,
  CARRIER_STATUS_PAID = 2,
  CARRIER_STATUS_DEBT = 3,
}
