import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';
import { handleFloatedBrackeds } from 'utils/helpers';

import { updateBranchDeserializer } from './deserializers';
import { IGetBranchesParams } from './interfaces';
import { getBranchByIdSerializer, getBranchesSerializer } from './serializers';

export const branchesAPI = createApi({
  reducerPath: 'branchesAPI',
  baseQuery,
  tagTypes: ['Branches'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    // BRANCHES
    getBranches: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<IGetBranchesParams>) => ({
        url: 'branches',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getBranchesSerializer(body),
      providesTags: ['Branches'],
    }),

    getBranchesAutocomplete: builder.query({
      query: ({ search, skip, limit, filter, officeId, withDefaults }: Partial<IGetBranchesParams>) => ({
        url: 'branches/autocomplete',
        params: { search, skip, limit, officeId, withDefaults, ...filter },
      }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            title: el.name,
            value: String(el.id),
            default: Boolean(el.default),
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      providesTags: ['Branches'],
    }),

    getBranch: builder.query({
      query: id => `branches/${id}`,
      transformResponse: (body: any) => getBranchByIdSerializer(body),
      providesTags: ['Branches'],
    }),

    createBranch: builder.mutation({
      query: body => ({
        url: 'branches',
        method: 'POST',
        body: {
          location: Number(body.location),
          phone: body.phoneNumber,
          ...(!!body.zipcode && { zip: body.zipcode }),
          ...(!!body.state && { stateId: body.state }),
          ...(!!body.address && { address: body.address }),
          ...(!!body.state && { stateId: Number(body.state) }),
          ...(body?.secondAddress ? { secondAddress: body.secondAddress } : {}),
          ...(!!body.fax && { fax: body.fax }),
          ...(!!body.city && { city: body.city }),
          authorityIds: body.authorities?.map((el: any) => Number(el)),
          ...(body?.secondAddress ? { secondAddress: body.secondAddress } : {}),
          name: body.name,
          officeId: Number(body.office[0].value),
          gross: 0,
          branchConsignmentPercent: 0,
          branchConsignmentAmount: 0,
          brackets: handleFloatedBrackeds(body.brackeds),
        },
      }),
      invalidatesTags: ['Branches'],
    }),

    updateBranch: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `branches/${id}`,
        method: 'PUT',
        body: updateBranchDeserializer(body),
      }),
    }),

    updateDefaultBranch: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `branches/${id}/default`,
        method: 'PUT',
        body: updateBranchDeserializer(body),
      }),
    }),

    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `branches/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['Branches'],
    }),

    // AUTHORITY BY OFFICE ID
    addAuthorityToBranch: builder.mutation({
      query: ({ id, authorityId }) => ({
        url: `branches/${id}/authority/${authorityId}`,
        method: 'POST',
      }),
    }),

    removeAuthorityFromBranch: builder.mutation({
      query: ({ id, authorityId }) => ({
        url: `branches/${id}/authority/${authorityId}`,
        method: 'DELETE',
      }),
    }),

    // BRACKETS
    createBracketForBranch: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `branches/${id}/brackets`,
        method: 'POST',
        body,
      }),
    }),

    removeBracketFromBranch: builder.mutation({
      query: ({ id }) => ({
        url: `branches/brackets/${id}`,
        method: 'DELETE',
      }),
    }),

    // BRANCHES BY OFFICE ID
    getBranchesByOffice: builder.query({
      query: officeId => `branches/${officeId}/branches`,
      providesTags: ['Branches'],
    }),
    getBranchesByOfficeShort: builder.query({
      query: ({ search, skip, limit, id }: any) => ({
        url: `branches/${id}/branches/short-data`,
        params: { search, skip, limit },
      }),
      providesTags: ['Branches'],
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            title: el.name,
            value: String(el.id),
          };
        });
        return {
          result,
          count: body.count,
        };
      },
    }),
  }),
});

export const {
  useGetBranchesQuery,
  useGetBranchQuery,
  useCreateBranchMutation,
  useUpdateBranchMutation,
  useUpdateDefaultBranchMutation,
  useChangeStatusMutation,
  useAddAuthorityToBranchMutation,
  useRemoveAuthorityFromBranchMutation,
  useCreateBracketForBranchMutation,
  useRemoveBracketFromBranchMutation,
  useGetBranchesByOfficeQuery,
  useGetBranchesByOfficeShortQuery,
  useGetBranchesAutocompleteQuery,
} = branchesAPI;
