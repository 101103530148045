import React, { ForwardedRef, forwardRef } from 'react';
import { Radio as AntRadio } from 'antd';
import clsx from 'clsx';

import { IRadioProps } from './constants/types';

import styles from './Radio.module.scss';

const { Group } = AntRadio;

const Radio = forwardRef(({ options, className, ...rest }: IRadioProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <Group {...rest} ref={ref} className={clsx(styles.radio, className)}>
      {options?.map(({ label, value }) => (
        <AntRadio key={value} value={value}>
          {label}
        </AntRadio>
      ))}
    </Group>
  );
});

export default Radio;
