import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { loadTypesView } from 'pages/loads/components/create-load/constants/types';
import { useGetMeQuery } from 'services/auth/auth';
import { useGetLoadsAutocompleteQuery } from 'services/loads/loads';
import { useGetCarriersAutocompleteQuery } from 'services/profile/carriers/carriers';
import { useGetCustomersAutocompleteQuery } from 'services/profile/customers/customers';
import { selectWidth } from 'store/sidebarSlice/selector';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { logOut, setIsFetching, setMe } from 'store/user-slice/user';
import { ls } from 'utils/storage';

import { ROUTES } from 'components/routes/routes';

import { allowedHeaderOptionsVariants, filterDefaultValues } from './constants/constants';
import { IHeaderSelectTypeFilter } from './constants/types';

export const useHeader = () => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab, headerFilter } = searchParams;

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const loadPermissions = usePermittedActions('loads.loads');

  const { isFetching: isPermissionFetching } = useSelector(selectUserPermissionsInfo);
  const sidebarState = useSelector(selectWidth);
  const userInfo = useSelector(selectUserInfo);

  const [userMenu, setUserMenu] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const ref = useOutsideDetect<HTMLLIElement>(setUserMenu);

  const {
    control: filterControl,
    setValue: setFilterValue,
    watch: filterWatch,
    getValues,
  } = useForm<IHeaderSelectTypeFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const [getCustomerFilter, setGetCustomersFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [getCarrierFilter, setGetCarrierFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [getLoadsFilter, setGetLoadsFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const { data: userData, isFetching } = useGetMeQuery({});
  const { data: carriersData, isFetching: isFetchingCarrier } = useGetCarriersAutocompleteQuery(getCarrierFilter, {
    skip: getValues('header_select') !== 'carriers',
  });
  const { data: customersData, isFetching: isFetchingCustomer } = useGetCustomersAutocompleteQuery(getCustomerFilter, {
    skip: getValues('header_select') !== 'customers',
  });
  const { data: loadsData, isFetching: isFetchingLoads } = useGetLoadsAutocompleteQuery(getLoadsFilter, {
    skip: getValues('header_select') !== 'loads',
  });
  const fetching = {
    carriers: isFetchingCarrier,
    customers: isFetchingCustomer,
    loads: isFetchingLoads,
  };

  const handleLogOut = () => {
    ls.remove('token');
    ls.remove('refresh');
    dispatch(logOut());
  };

  const handleOverlayState = (inputArea?: boolean) => {
    setIsOverlayOpen(!!inputArea);
  };

  const handleNavigateToUserSettings = () =>
    `${ROUTES.USER_SETTINGS}?tab=${pathname === ROUTES.USER_SETTINGS ? tab : 'user-settings'}`;

  const handleUserMenu = () => {
    setUserMenu(prev => !prev);
  };

  const handleRowClick = ({ id, type }: any) => {
    const queryParams = {
      ...searchParams,
      id,
      mode: headerFilter === 'loads' ? loadTypesView[type] : 'view',
      open: 'true',
    };
    setSearchParams(queryParams);
    navigate({ pathname: ROUTES.SEARCH_RESULT, search: createSearchParams(queryParams).toString() });
    setIsOverlayOpen(false);
  };

  const selectHeader = filterWatch('header_select');
  const selectHeaderQueryCheck = !!(typeof selectHeader === 'string' && selectHeader);
  const permissionsForSelect = {
    carriers: true,
    loads: !!loadPermissions?.view,
    customers: true,
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(selectHeaderQueryCheck ? { headerFilter: selectHeader } : {}),
    });
  }, [selectHeader, tab, pathname]);

  useEffect(() => {
    const { id: _id, ...rest } = searchParams;
    setSearchParams({ ...rest });
  }, [tab, pathname, headerFilter!]);

  useEffect(() => {
    (async () => {
      if (userData) {
        dispatch(setMe({ data: userData?.result, isFetching }));
      }
      dispatch(setIsFetching({ isFetching }));
    })();
  }, [userData, isFetching]);

  useEffect(() => {
    if (searchParams?.open === 'false') {
      handleOverlayState(false);
    }
  }, [searchParams?.open]);

  useEffect(() => {
    if (isPermissionFetching || loadPermissions.view === undefined) return;
    if (
      headerFilter &&
      allowedHeaderOptionsVariants.includes(headerFilter) &&
      permissionsForSelect?.[headerFilter as keyof typeof permissionsForSelect]
    ) {
      setFilterValue('header_select' as keyof IHeaderSelectTypeFilter, headerFilter);
      return;
    }

    if (loadPermissions?.view && allowedHeaderOptionsVariants.includes('loads')) {
      setSearchParams({
        ...searchParams,
        headerFilter: 'loads',
      });
      setFilterValue('header_select' as keyof IHeaderSelectTypeFilter, 'loads');
      return;
    }

    setFilterValue(
      'header_select' as keyof IHeaderSelectTypeFilter,
      allowedHeaderOptionsVariants.includes(headerFilter) &&
        permissionsForSelect?.[headerFilter as keyof typeof permissionsForSelect]
        ? headerFilter
        : allowedHeaderOptionsVariants[1]
    );
  }, [loadPermissions.view, isPermissionFetching, tab, pathname]);

  return {
    userMenu,
    handleUserMenu,
    ref,
    sidebarState,
    handleNavigateToUserSettings,
    filterControl,
    isOverlayOpen,
    handleOverlayState,
    handleLogOut,
    getValues,
    userInfo,
    setGetCustomersFilter,
    setGetCarrierFilter,
    setGetLoadsFilter,
    customersData,
    carriersData,
    loadsData,
    handleRowClick,
    setValue: setFilterValue,
    isFetching: fetching[getValues('header_select') as keyof typeof fetching],
    isFetchingUserInfo: isFetching,
  };
};
