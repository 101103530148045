import React, { useDeferredValue } from 'react';
import { Tabs } from 'antd';

import MainDrawer from 'components/main-drawer/MainDrawer';
import Table from 'components/table/Table';

import { IEmployeeParams } from './constants/types';
import useEmployee from './useEmployee';

import styles from './Employee.module.scss';

const Employee = ({ setExcelParams }: IEmployeeParams) => {
  const {
    currentChildren: { component, buttonText, onCancel, onSubmit, withFooter },
    employeeTabs,
    activePage,
    columns,
    data,
    isFetching,
    handleRowEdit,
    handleSortClick,
    handleSwitchClick,
    handleRowClick,
    handleTabChange,
    handlePaginationClick,
    handleStopResize,
    skip,
    limit,
    handleInviteAgain,
    handleColumnSearch,
    employeesPagePermissions: { terminateOn, terminateOff },
    getAllowedEditPermission,
  } = useEmployee({ setExcelParams });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading: isFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onSwitchClick: handleSwitchClick,
    onColumnSearch: handleColumnSearch,
    handleRowEdit,
    onPaginationClick: handlePaginationClick,
    onStatusClick: handleInviteAgain,
    hasTerminateOnPermission: terminateOn,
    hasTerminateOffPermission: terminateOff,
    hasEditPermission: getAllowedEditPermission(),
    withMenu: getAllowedEditPermission(),
  });
  return (
    <div className="page-content">
      <Tabs
        defaultActiveKey={activePage}
        items={employeeTabs}
        className={styles.employeeHeaderTabs}
        onChange={activeTab => handleTabChange(activeTab)}
      />
      <>
        <Table {...tableProps} />
      </>
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
      />
    </div>
  );
};

export default Employee;
