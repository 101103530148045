export const getDefaultValues = (searchItems: any) => {
  const defaultValues: any = {};
  searchItems?.result?.forEach((el: any) => {
    defaultValues[`${el?.region?.id}_ALL`] = 0;
    el?.state?.forEach((state: any) => {
      defaultValues[state?.id] = 0;
    });
  });
  return defaultValues;
};

export const getDefaultValuesForEdit = (searchItems: any, singleSearchItem: any) => {
  const defaultValues: any = {};
  searchItems?.result?.forEach((el: any) => {
    defaultValues[`${el?.region?.id}_ALL`] = 0;
    el?.state?.forEach((state: any) => {
      if (singleSearchItem?.fromStateIds?.includes(state?.id) && !singleSearchItem?.toStateIds?.includes(state?.id)) {
        defaultValues[state?.id] = 1;
      } else if (
        !singleSearchItem?.fromStateIds?.includes(state?.id) &&
        singleSearchItem?.toStateIds?.includes(state?.id)
      ) {
        defaultValues[state?.id] = 2;
      } else if (
        singleSearchItem?.fromStateIds?.includes(state?.id) &&
        singleSearchItem?.toStateIds?.includes(state?.id)
      ) {
        defaultValues[state?.id] = 3;
      } else {
        defaultValues[state?.id] = 0;
      }
    });
  });
  return defaultValues;
};

export const getFormData = (form: any) => {
  const formData = [];
  formData.push(`${form?.region?.id}_ALL`);
  form?.state?.forEach((state: any) => formData.push(String(state?.id)));
  return formData;
};

export const getFormDataNames = (form: any) => {
  const formDataNames: any = {};
  formDataNames[`${form?.region?.id}_ALL`] = 'All';
  form.state.forEach((state: any) => (formDataNames[state.id] = state?.name));
  return formDataNames;
};

export enum SUBJECT {
  ON_BOARD_COUNT = 'On Board: ',
  OFF_BOARD_COUNT = 'Off Board: ',
  EMPTY = 0,

  FROM = 1,
  TO = 2,
  BOTH = 3,
  ALL = 'ALL',
}
