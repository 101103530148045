import React from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';
import { formatPhone } from 'utils/helpers';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { MainLoader } from '../../../../../ui/main-loader/MainLoader';

import { SUBJECT } from './constants/constants';
import useTruckCarrierRequestView from './useTruckCarrierRequestView';

import styles from './TruckCarrierRequestView.module.scss';

const TruckCarrierRequestView = ({ data, isLoadingById }: any) => {
  const { handleChangeMode } = useTruckCarrierRequestView();

  return (
    <>
      <MainLoader isFetching={isLoadingById} />

      <ViewItemWrapper
        title={SUBJECT.CARRIER_REQUEST}
        Component={
          <Button className="viewDrawerEditBtn" size="large" children={SUBJECT.EDIT} onClick={handleChangeMode} />
        }
      >
        <Row gutter={[10, 25]} align="top" justify="space-between">
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.DATE} className="elementTitle" />
              </Col>
              <Col span={12}>
                <Typography variant="paragraph" children={data?.createdAt} className="elementParagraph" />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.CARRIER_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={12}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.companyName}
                  subject={SUBJECT.CARRIER_NAME}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={12}>
                <Typography
                  variant="paragraph"
                  className={styles.infoParagraph}
                  children={<a href={`tel:${data?.phone}`}>{formatPhone(data?.phone)}</a>}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.CONTACT_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={12}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={data?.contactName}
                  subject={SUBJECT.CONTACT_NAME}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.DOT} className={styles.infoHeading} />
              </Col>
              <Col span={12}>
                <TooltipText prefixClass={styles.infoParagraph} data={data?.dot} subject={SUBJECT.DOT} />
              </Col>
            </Row>
          </Col>
          {data?.mc && (
            <Col span={10}>
              <Row align="middle" gutter={10}>
                <Col span={12}>
                  <Typography variant="heading" children={SUBJECT.MC_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={12}>
                  <TooltipText prefixClass={styles.infoParagraph} data={data?.mc} subject={SUBJECT.MC_NUMBER} />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
              </Col>
              <Col span={12}>
                <Typography variant="paragraph" children={data?.addedBy} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.TYPE} className={styles.infoHeading} />
              </Col>
              <Col span={12}>
                <Typography variant="paragraph" children={data?.type} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.AGENT_TYPE} className={styles.infoHeading} />
              </Col>
              <Col span={12}>
                <Typography variant="paragraph" children={data?.agentType} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row align="middle" gutter={10}>
              <Col span={12}>
                <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
              </Col>
              <Col span={12}>
                <Typography variant="paragraph" children={data?.status} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
        </Row>
      </ViewItemWrapper>
    </>
  );
};

export default TruckCarrierRequestView;
