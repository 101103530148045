import React from 'react';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';

import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import TooltipText from 'components/tooltip-text/TooltipText';

import { SUBJECT } from './constants/constants';

import styles from './AutoRateConfirmation.module.scss';

const AutoRateConfirmation = ({
  fileItem,
  handleViewFile,
  handleDownloadFileClick,
  handleSendRateConfirmation,
  rateConfirmations,
  index,
}: any) => {
  return (
    <div className={styles.fileItemWrapper} key={fileItem.id}>
      <Row
        gutter={10}
        className={clsx({
          [styles.crossing]: !fileItem?.mailable,
        })}
        align="middle"
      >
        <Col span={6}>
          <Typography variant="heading" children={SUBJECT.FILE_NAME} className={styles.infoHeading} />
        </Col>
        <Col span={8}>
          <Typography variant="paragraph" children={formatDate(fileItem?.createdAt)} className={styles.infoParagraph} />
        </Col>
        <Col span={5}>
          <TooltipText data={fileItem?.file?.name} prefixClass={styles.infoParagraph} subject={fileItem?.file?.name} />
        </Col>
        <Col span={5}>
          <div className={styles.actionsWrapper}>
            {rateConfirmations?.mailable && !index && (
              <button type="button" onClick={handleSendRateConfirmation} className={styles.sendEmailBtn}>
                {SUBJECT.SEND_EMAIL}
              </button>
            )}
            <button type="button" onClick={() => handleViewFile(fileItem?.file?.preview)}>
              <EyeIconSvg outlined={true} />
            </button>
            <button type="button" onClick={() => handleDownloadFileClick(fileItem?.file?.download)}>
              <NoaDownloadIcon />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AutoRateConfirmation;
