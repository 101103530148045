import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useHandleCopyActive } from 'hooks/useCopy';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useCancelLoadMutation, useGetLoadHistoryQuery } from 'services/loads/loads';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { scrollToTarget } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { selectUserInfo } from '../../../../../../store/user-slice/selector';
import { ICommentViewForm } from '../loads-comment/constants/types';

import { SUBJECT } from './constants/constants';

const useInternalBoardLoadView = ({ refetchLoadById, data }: any) => {
  const dispatch = useDispatch();
  const [isDefaultHistory, setIsDefaultHistory] = useState<boolean>(false);

  const { control, watch, setValue } = useForm<ICommentViewForm>({
    defaultValues: { comment: '' },
  });
  const userInfo = useSelector(selectUserInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { id, open, width } = searchParams;
  const [cancelLoad] = useCancelLoadMutation();
  const activeSection = useSelector(selectActiveSection);
  const drawerRef: { current: any } = useRef(null);
  const { copiedText, handleCopy } = useHandleCopyActive();
  const handleScrollToHistory = (mode: string) => {
    scrollToTarget(drawerRef);
    setIsDefaultHistory(mode === 'history');
  };

  const handleChangeMode = () => {
    setSearchParams({
      ...searchParams,
      open: 'true',
      mode: 'edit',
      isEdit: 'true',
    });
  };

  const handleCancelLoad = (id: string | number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_LOAD,
        okText: SUBJECT.CANCEL,
        cancelText: SUBJECT.NO,
        okButtonVariant: 'danger',
        cancelButtonVariant: 'gray',
        onOk: () => {
          cancelLoad(Number(id)).then((arg: any) => {
            if (arg.error) {
              dispatch(closeModal());
              notification.error({
                message: ServerErrorCodes[Number(arg?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully canceled',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              refetchLoadById({ id: data?.load?.id });
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleViewFile = (url: string) => {
    window.open(`${url}`, '_blank');
  };

  const handleDownloadFileClick = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    link.click();
  };

  const [notes, setNotes] = useState<any>({
    result: [],
    count: 0,
  });

  const [notesParams, setNotesParams] = useState<any>({
    skip: 0,
    limit: 10,
  });

  const { currentData: loadHistory, isFetching: isFetchingHistory } = useGetLoadHistoryQuery(
    { id, ...notesParams },
    { skip: !id }
  );

  const getMoreNotes = () => {
    setNotesParams((prev: any) => ({ ...prev, skip: prev.skip + 10 }));
  };

  const isShowMoreVisibleNotes = useMemo(
    () => loadHistory?.count && loadHistory?.count > notes?.result?.length,
    [loadHistory, notesParams, notes?.result?.length]
  );

  useEffect(() => {
    if (loadHistory?.result && isShowMoreVisibleNotes) {
      setNotes((prev: any) => ({
        result: [...prev.result, ...loadHistory.result],
        count: loadHistory?.count,
      }));
    }
  }, [loadHistory?.result?.length, notesParams.skip]);

  useEffect(() => {
    return () => {
      setIsDefaultHistory(false);
      setNotesParams({
        skip: 0,
        limit: 10,
      });
    };
  }, [open]);

  return {
    handleChangeMode,
    handleCancelLoad,
    handleViewFile,
    handleDownloadFileClick,
    control,
    watch,
    setValue,
    loadHistory: notes,
    isShowMoreVisibleNotes,
    getMoreNotes,
    copiedText,
    handleCopy,
    isDefaultHistory,
    setIsDefaultHistory,
    handleScrollToHistory,
    drawerRef,
    isFetchingHistory,
    activeSection,
    width,
    userInfo,
  };
};

export default useInternalBoardLoadView;
