import { ICarrierStatus, ICarrierType } from 'services/profile/carriers/interfaces';
import { formatDate } from 'utils/dates';

import { IAgentType } from './interfaces';

export const getRequestCarrierSelfListSerializer = (body: any) => {
  const result = body?.result?.map((carrier: any) => {
    return {
      createdAt: formatDate(carrier?.createdAt),
      company: carrier?.companyName,
      phone: carrier?.phone,
      contact: carrier?.contactName,
      mc: carrier?.mc,
      dot: carrier?.dot,
      addedBy: `${carrier?.user?.username}`,
      type: ICarrierType[carrier?.type],
      agentType: IAgentType[carrier?.user?.agentType],
      status: ICarrierStatus[carrier?.requestStatus],
      id: carrier.id,
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getRequestCarrierSelfSerializer = (body: any) => {
  return {
    ...body,
    createdAt: formatDate(body.createdAt),
    type: ICarrierType[body.type],
    status: ICarrierStatus[body.requestStatus],
    agentType: IAgentType[body?.user?.type],
    addedBy: `${body?.user?.firstName} ${body?.user?.lastName}`,
  };
};
