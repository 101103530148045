export interface IFilter {
  [key: string]: string;
}

export interface IGetCustomersParams {
  search: string;
  field?: string;
  skip: number;
  limit: number;
  order?: number | string;
  orderBy?: string;
  filter?: IFilter;
}

export interface IPaymentTermsParams {
  id: number | string;
  search: string;
  skip: number;
  limit: number;
  order: number | string;
  orderBy: string;
}

export enum CustomerType {
  Shipper = 1,
  Broker = 2,
  Carrier = 3,
}

export enum CustomerStatusTypes {
  CUSTOMER_STATUS_TYPES_ACTIVE = 1,
  CUSTOMER_STATUS_TYPES_INACTIVE = 2,
}

export enum BillingTypes {
  Factoring = 1,
  QuickPay = 2,
  Custom = 3,
}

export enum BillingTypesText {
  'Factoring' = 1,
  'Quick pay' = 2,
  'Custom' = 3,
}
