import React from 'react';
import { Divider, Modal } from 'antd';
import Button from 'ui/button/Button';
import { TButtonVariants } from 'ui/button/constants/types';
import Typography from 'ui/typography/Typography';

import CloseIcon from 'components/svgs/CloseIcon';

import { MODAL_ITEMS, SUBJECT } from './constants/constants';
import { IActionsModalProps, IModalItems } from './constants/types';

import styles from './ActionsModal.module.scss';

const ActionsModal = ({
  handleActionStatus,
  isModalOpen,
  handleClose,
  isConsignment = true,
  actionPermissions,
}: IActionsModalProps) => {
  return (
    <>
      <Modal
        footer={false}
        zIndex={999999}
        title={SUBJECT.ACTIONS}
        open={isModalOpen}
        onCancel={handleClose}
        closeIcon={<CloseIcon />}
        className={styles.modalDialog}
      >
        <Divider />
        {MODAL_ITEMS.filter(
          ({ buttonText }) => actionPermissions[buttonText.toLowerCase() as keyof typeof actionPermissions]
        )?.map(({ id, itemContent, buttonText, buttonType }: IModalItems) => {
          return (
            <div key={id} className={styles.actionModalItem}>
              <div className={styles.actionItemTextWrapper}>
                <Typography variant="paragraph">{itemContent}</Typography>
              </div>
              <Button
                htmlType="submit"
                variant={buttonType as TButtonVariants}
                onClick={() => handleActionStatus(buttonText)}
                disabled={buttonText === 'Consignment' && isConsignment}
              >
                {buttonText}
              </Button>
              {buttonText !== 'Consignment' && <Divider />}
            </div>
          );
        })}
      </Modal>
    </>
  );
};

export default ActionsModal;
