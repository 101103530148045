export enum SUBJECT {
  FILE_NAME = 'File Name:',
}

export const FILES_OPTIONS = [
  { label: 'Carrier package', value: '1' },
  { label: 'Sign agreement', value: '2' },
  { label: 'Voided check', value: '3' },
  { label: 'NOA', value: '4' },
  { label: 'Insurance', value: '6' },
  { label: 'Unknown', value: '0' },
];
