export enum SUBJECT {
  CUSTOMIZE_COLUMNS = 'Customize Columns',
  SELECT_COLUMNS = 'Select the columns you use often and arrange them as desired',
  UNDO = 'Undo',
  RESET = 'Reset',
}

export const columns = [
  { id: '1', title: 'Component 1', item: 'Item 1', isVisible: true },
  { id: '2', title: 'Component 2', item: 'Item 2', isVisible: true },
  { id: '3', title: 'Component 3', item: 'Item 3', isVisible: true },
  { id: '4', title: 'Component 4', item: 'Item 4', isVisible: true },
  { id: '5', title: 'Component 5', item: 'Item 5', isVisible: true },
];
