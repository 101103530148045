import { notification } from 'antd';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { notificationKey } from './constants';
import { ServerErrorCodes } from './errors';

enum NoteTypes {
  error = 'error',
  success = 'success',
}

export const notificationHandler = (noteType: string, errorData: any, message?: string) => {
  return notification?.[NoteTypes[noteType as keyof typeof NoteTypes]]({
    message: NoteTypes.error === noteType ? ServerErrorCodes[Number(errorData?.error?.data?.code) || 0] : message,
    duration: NoteTypes.error === noteType ? 3 : 1.5,
    icon: NoteTypes.error === noteType ? <DangerIcon /> : <SuccessIcon />,
    placement: 'topRight',
    closeIcon: <CloseIcon />,
    key: notificationKey,
    btn: (
      <button type="button" onClick={() => notification.destroy(notificationKey)}>
        <CloseIcon />
      </button>
    ),
  });
};
