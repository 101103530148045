import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useCreateEditCarrierRatingMutation,
  useDeleteCarrierRatingMutation,
  useLazyGetCarrierRatingQuery,
} from 'services/profile/carriers/carriers';
import { useCreateFavoriteMutation, useDeleteFavoriteMutation } from 'services/truck-board/favorite-trucks/favorite';
import { truckBoardApi } from 'services/truck-board/truck-board/truckBoard';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { SUBJECT } from './constants/constants';

const useCarrierInfo = ({ carrierInfo }: any) => {
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const [isFavorite, setIsFavorite] = useState(carrierInfo?.favorite);
  const [ratesParams] = useState({
    skip: 0,
    limit: 50,
  });

  const { searchParams } = useDetectedParams();
  const { carrierId } = searchParams;
  const dispatch = useDispatch();
  const [createEditCarrierRating] = useCreateEditCarrierRatingMutation();
  const [getCarrierRating, { data: rateList }] = useLazyGetCarrierRatingQuery();
  const [deleteCarrierRating] = useDeleteCarrierRatingMutation();

  const [deleteFavorite] = useDeleteFavoriteMutation();
  const [createFavorite] = useCreateFavoriteMutation();

  const hasAccessToView = true;
  const hasPermissionToView =
    UserTypes.ADMIN === userType || (EmployeeLevels.CORPORATE === employeeLevel && hasAccessToView);

  const handleDeleteFavorite = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_REMOVE,
        okText: SUBJECT.REMOVE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          deleteFavorite({ id: Number(carrierId) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
              dispatch(closeModal());
            } else {
              notification.success({
                message: SUBJECT.SUCCESSFULLY_REMOVED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
              setIsFavorite(false);
              dispatch(closeModal());
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleCreateFavorite = () => {
    dispatch(changeLoading(true));
    createFavorite({ id: Number(carrierId) }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 1,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
        dispatch(closeModal());
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_ADDED,
          duration: 1,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
        setIsFavorite(true);
        dispatch(closeModal());
      }
    });
  };
  const handleCarrierRate = (rating: number | string) => {
    if (carrierInfo?.accessRating) {
      createEditCarrierRating({ carrierId: Number(carrierId), rating }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully rated',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          dispatch(truckBoardApi?.util?.invalidateTags(['TruckBoard']));
        }
      });
    }
  };

  const getCarrierRatings = () => {
    getCarrierRating({ skip: ratesParams.skip, limit: ratesParams.limit, carrierId: Number(carrierId) });
  };

  const handleDeleteRate = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_DELETE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CANCEL,
        onOk: () => {
          deleteCarrierRating({ ratingId: Number(id) }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(truckBoardApi?.util?.invalidateTags(['TruckBoard']));
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  useEffect(() => {
    setIsFavorite(carrierInfo?.favorite);
  }, [carrierInfo?.favorite]);

  return {
    handleDeleteRate,
    getCarrierRatings,
    handleCarrierRate,
    handleCreateFavorite,
    handleDeleteFavorite,
    rateList,
    hasPermissionToView,
    userType,
    isFavorite,
  };
};

export default useCarrierInfo;
