import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row, Spin } from 'antd';
import clsx from 'clsx';
import { PickupsDropoffs } from 'pages/loads/components/create-load/components/pickups-dropoffs/PickupsDropoffs';
import { ITransactionTypes, SUBJECT, TRANSACTION_TYPES } from 'pages/loads/components/edit-load/constants/constants';
import { Input, TransactionInput, TransactionSelect } from 'pages/loads/components/edit-load/hoc-instance/instance';
import AutoRateConfirmation from 'pages/loads/tabs/loads-tab/components/auto-rate-confirmation/AutoRateConfirmation';
import ConnectingBanner from 'pages/loads/tabs/loads-tab/components/loads-comment/components/connecting-banner/ConnectingBanner';
import { BookedTypes, BookedTypesEnum } from 'pages/loads/tabs/loads-tab/components/regular-load-view/constants/types';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import Button from 'ui/button/Button';
import { LoadStatus } from 'ui/load-status/constants/types';
import LoadStatusComponent from 'ui/load-status/LoadStatus';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import UploadRow from 'ui/upload-row/UploadRow';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';
import {
  floatNumberFormatter,
  floatNumberFormatterWithMinus,
  formatNumberWithThousandsSeparatorAndDecimal,
  multiplyDownloadWithDelay,
} from 'utils/helpers';

import BillingBlock from 'components/billing-block/BillingBlock';
import { PaymentTermType, PaymentTermTypeText } from 'components/billing-block/constants/types';
import CarrierPaymentBreakdown from 'components/carrier-payment-breakdown/CarrierPaymentBreakdown';
import CustomComment from 'components/custom-comment/CustomComment';
import DollarIcon from 'components/svgs/DollarIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import TransactionsTable from 'components/transactions-table/TransactionsTable';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { IRoleLevel } from '../../../../../../services/truck-board/carrier-request/interfaces';
import { transactionsForDataGrind } from '../../constants/helpers';
import { LoadCancelStatus, TakenType, TransportCapabilityTypes } from '../../constants/types';

import GeneralInformation from './general-info/GeneralInformation';
import { useConnectingEdit } from './useConnectingEdit';

import styles from './ConnectingEdit.module.scss';

const ConnectingEdit = ({
  data,
  createLoadForm,
  loadTransactions,
  loadFiles,
  mode,
  loadPoints,
  comments,
  createComment,
  deleteComment,
  getMoreComments,
  isFilesFetching,
  isShowMoreVisible,
  refetchTransaction,
  refetchLoadById,
  refetchLoadPoints,
  loadPointsAutocomplete,
  isFetching,
  refetchFilesByLoadId,
  isShowMoreVisibleNotes,
  loadHistory,
  loadsPagePermissions,
  roleLevel,
  getMoreNotes,
  isFetchingHistory,
  isLoadsCommentsLoading,
  invalidLoadPointIds,
  connectingError,
  regularError,
  handleCreateRateConfirmation,
  rateConfirmations,
  createRateConfCheck,
  refetchRateConfirmations,
  pointsState,
  setPointsState,
  handleSendRateConfirmation,
  insurance,
  filledPickUpDropOffs,
  submitted,
}: any) => {
  const {
    control,
    watch,
    setValue,
    errors,
    activeSection,
    handleCancelLoad,
    handleSwitchSoldUs,
    handleSwitchMarkAsClaim,
    handleCancel,
    handleSave,
    handleDownloadFileEdit,
    handleViewFileEdit,
    handleDeleteFileEdit,
    beforeUploadForEdit,
    allFiles,
    transactionWatch,
    transactioControl,
    isTransactionValidBtn,
    transactionSetValue,
    handleCreateTransaction,
    authorities,
    setAuthoritiesState,
    handleDeleteTransaction,
    handleViewFileEditPOD,
    handleDeleteFileEditPOD,
    handleDownloadFileEditPOD,
    beforeUploadForEditPOD,
    allFilesPOD,
    handleEditFile,
    isSaveButtonVisible,
    userId,
    handleSendToBilling,
    handleAcceptToBilling,
    handleRejectBilling,
    handleCreateInvoice,
    handleDeleteInvoice,
    touchedFields,
    dirtyFields,
    carriers,
    setCarriersState,
    trucks,
    setTrucksState,
    isSendToBillingDisabled,
    connectingTrigger,
    isSendedToBilling,
    transactionDirtyFields,
    employeeLevel,
    userType,
    handleResetTransactionForm,
    isCreateTransactionLoading,
    isSecondAgent,
    isInvoiceLoading,
    isDisableSoldUs,
    isDisableReference,
    breakdowns,
    isBreakdownLoading,
    addTransactionCheck,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    breakdownParams,
    isFirstAgent,
    hasAccessToRateConf,
    mailableFiles,
    handleScrollToHistory,
    isDefaultHistory,
    setIsDefaultHistory,
    drawerRef,
    generalInformationRef,
    transactionTrigger,
  } = useConnectingEdit({
    data,
    createLoadForm,
    loadFiles,
    mode,
    loadPoints,
    isFilesFetching,
    isFetching,
    refetchTransaction,
    refetchLoadById,
    refetchRateConfirmations,
    refetchFilesByLoadId,
    loadsPagePermissions,
  });

  const hasAccessToViewHistory =
    userType === UserTypes.ADMIN || (employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.view);

  const iAmSecondAgent = data?.agents?.length > 1 && userId === data?.agents?.[1]?.user?.id;

  const [financeSpan, setFinanceSpan] = useState(0);

  const carrierLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.carrierAmountPaid && data?.finance?.carrierAmountPaid) ||
      (data?.finance?.carrierBalance && data?.finance?.carrierBalance) ||
      (data?.finance?.carrierFinalPayable && data?.finance?.carrierFinalPayable) ||
      (data?.finance?.carrierInitialRate && data?.finance?.carrierInitialRate) ||
      (data?.finance?.carrierPaymentTermGeneratedAmount && data?.finance?.carrierPaymentTermGeneratedAmount !== '0')
    );
  }, [
    data?.finance?.carrierAmountPaid,
    data?.finance?.carrierBalance,
    data?.finance?.carrierFinalPayable,
    data?.finance?.carrierInitialRate,
    data?.finance?.carrierPaymentTermGeneratedAmount,
  ]);

  const customerLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.customerAmountPaid && data?.finance?.customerAmountPaid) ||
      (data?.finance?.customerBalance && data?.finance?.customerBalance) ||
      (data?.finance?.customerFinalReceivable && data?.finance?.customerFinalReceivable) ||
      (data?.finance?.customerInitialRate && data?.finance?.customerInitialRate)
    );
  }, [
    data?.finance?.customerAmountPaid,
    data?.finance?.customerBalance,
    data?.finance?.customerFinalReceivable,
    data?.finance?.customerInitialRate,
  ]);

  const firstAgentLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.agentFinalProfit && data?.finance?.agentFinalProfit) ||
      (data?.finance?.agentBalance && data?.finance?.agentBalance)
    );
  }, [data?.finance?.agentFinalProfit, data?.finance?.agentBalance]);

  const secondAgentLoadFinanceCheck = useMemo(() => {
    return (
      !!(
        (data?.finance?.secondAgentBalance && data?.finance?.secondAgentBalance) ||
        (data?.finance?.secondAgentFinalProfit && data?.finance?.secondAgentFinalProfit)
      ) && !isFirstAgent
    );
  }, [data?.finance?.secondAgentBalance, data?.finance?.secondAgentFinalProfit, isFirstAgent]);

  useEffect(() => {
    if (carrierLoadFinanceCheck) {
      setFinanceSpan(prev => prev + 1);
    }
    if (customerLoadFinanceCheck && !isSecondAgent) {
      setFinanceSpan(prev => prev + 1);
    }
    if (firstAgentLoadFinanceCheck && !isSecondAgent) {
      setFinanceSpan(prev => prev + 1);
    }
    if (secondAgentLoadFinanceCheck) {
      setFinanceSpan(prev => prev + 1);
    }
    return () => {
      setFinanceSpan(0);
    };
  }, []);

  return (
    <form className={styles.connectingLoadForm}>
      <MainLoader isFetching={isFetching} />

      <ConnectingBanner
        data={data}
        activeSection={activeSection}
        userId={Number(userId)}
        connectingControl={control}
        handleSwitchMarkAsClaim={handleSwitchMarkAsClaim}
        connectingWatch={watch}
        withActions={data?.load?.status !== LoadCancelStatus.Canceled}
        handleCancelLoad={handleCancelLoad}
        hasClaimPermission={loadsPagePermissions.claim}
        hasDeClaimPermission={loadsPagePermissions.resolve}
        hasCancelPermission={loadsPagePermissions.cancel}
        handleCommentClick={() => handleScrollToHistory('comment')}
        handleScrollToHistory={() => handleScrollToHistory('history')}
        loadHistory={loadHistory}
        hasAccessToViewHistory={hasAccessToViewHistory}
      />

      <ViewItemWrapper
        className={activeSection === SUBJECT.GENERAL_INFORMATION_ID ? 'active' : ''}
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        id="general-information"
        title={SUBJECT.EDIT_REGULAR_LOAD}
        Component={
          <LoadStatusComponent
            loadStatus={Number(data?.load?.status)}
            carrierStatus={Number(data?.load?.carrierStatus)}
            customerStatus={Number(data?.load?.customerStatus)}
            isProcessing={data?.load?.processing || false}
            withoutLoadsText
          />
        }
      >
        <Row align="middle" className={styles.topBannerBlock}>
          <Col span={12}>
            <Typography variant="heading" children={SUBJECT.GENERAL_INFO} className={styles.generalInfoHeading} />
          </Col>
          {!isSecondAgent && (
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <div className={styles.takenAsBlock}>
                    <Typography variant="paragraph">{`${SUBJECT.TAKEN_AS_MC} `}</Typography>
                    <span>{data?.load?.takenAsAuthority?.name}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <GeneralInformation
          errors={errors}
          data={data}
          connectingControl={control}
          connectingWatch={watch}
          connectingSetValue={setValue}
          connectingTouchedFields={touchedFields}
          handleSwitchSoldUs={handleSwitchSoldUs}
          isDisableSoldUs={isDisableSoldUs}
          isDisableReference={isDisableReference}
          handleCancel={handleCancel}
          handleSave={handleSave}
          connectingDirtyFields={dirtyFields}
          connectingErrors={errors}
          connectingTrigger={connectingTrigger}
          carriers={carriers}
          setCarriersState={setCarriersState}
          trucks={trucks}
          isSaveButtonVisible={isSaveButtonVisible}
          setTrucksState={setTrucksState}
          authorities={authorities}
          setAuthoritiesState={setAuthoritiesState}
          canEditReferenceNumber={loadsPagePermissions.editReferenceNumber}
          canEditSoldAs={loadsPagePermissions.editSoldAsMc}
          generalInformationRef={generalInformationRef}
        />
      </ViewItemWrapper>
      <ViewItemWrapper
        id="load-finance"
        title={SUBJECT.LOAD_FINANCE}
        canceledBlock={LoadCancelStatus.Canceled === data?.load?.status || data?.load?.claimed}
        className={activeSection === SUBJECT.LOAD_FINANCE_ID ? 'active' : ''}
      >
        {isFetching ? (
          <div className={styles.spinWrapper}>
            <Spin />
          </div>
        ) : (
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Row gutter={[50, 0]}>
                {customerLoadFinanceCheck && (
                  <Col span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 12}>
                    <Typography variant="heading" children={SUBJECT.CUSTOMER} className={styles.financeTitle} />
                  </Col>
                )}
                {firstAgentLoadFinanceCheck && !isSecondAgent && (
                  <Col span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 12}>
                    <Typography variant="heading" children={SUBJECT.FIRST_AGENT} className={styles.financeTitle} />
                  </Col>
                )}
                {secondAgentLoadFinanceCheck && (
                  <Col span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 12}>
                    <Typography
                      variant="heading"
                      children={SUBJECT.SECOND_AGENT_HEADER}
                      className={styles.financeTitle}
                    />
                  </Col>
                )}
                {carrierLoadFinanceCheck && (
                  <Col span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 12}>
                    <Typography variant="heading" children={SUBJECT.CARRIER} className={styles.financeTitle} />
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={50}>
                {customerLoadFinanceCheck && (
                  <Col
                    className={styles.borderedColumn}
                    span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 12}
                  >
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.CUSTOMER_BALANCE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerBalance,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.INIT_RATE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerInitialRate,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {data?.load?.paymentTermType !== PaymentTermType.BILLING_TYPES_FACTORING && (
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={
                                  PaymentTermTypeText[data?.load?.paymentTermType as keyof typeof PaymentTermTypeText]
                                }
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerPaymentTermGeneratedAmount,
                                  true
                                )}`}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.FINAL_RECEIVABLE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerFinalReceivable,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.AMOUNT_PAID_CUSTOMER}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerAmountPaid,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {firstAgentLoadFinanceCheck && !isSecondAgent && (
                  <Col
                    className={styles.borderedColumn}
                    span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 12}
                  >
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.AGENT_BALANCE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.agentBalance,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.FINAL_PROFIT}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.agentFinalProfit,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {secondAgentLoadFinanceCheck && (
                  <Col
                    className={styles.borderedColumn}
                    span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 12}
                  >
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.AGENT_BALANCE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.secondAgentBalance,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.FINAL_PROFIT}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.secondAgentFinalProfit,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {carrierLoadFinanceCheck && (
                  <Col
                    className={styles.borderedColumn}
                    span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 12}
                  >
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.CARRIER_BALANCE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierBalance,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.INIT_RATE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierInitialRate,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {!!Number(data?.finance?.carrierPaymentTermGeneratedAmount) && (
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.QUICK_PAY}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierPaymentTermGeneratedAmount,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.QUICK_PAY}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierPaymentTermGeneratedAmount,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.FINAL_PAYABLE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierFinalPayable,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.AMOUNT_PAID}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierAmountPaid,
                                true
                              )}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </ViewItemWrapper>
      {((isSecondAgent && !!data?.load?.carrier?.id) || !isSecondAgent) && loadsPagePermissions.transaction ? (
        <ViewItemWrapper
          title={SUBJECT.TRANSACTION}
          Component={
            <>
              {!!Object.keys(transactionDirtyFields)?.length && (
                <Col>
                  <Button
                    icon={<ResetIcon />}
                    variant="ghost"
                    children={SUBJECT.RESET}
                    onClick={handleResetTransactionForm}
                  />
                </Col>
              )}
            </>
          }
          className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          id="transaction"
        >
          <Row gutter={[10, 30]}>
            <Col span={8}>
              <TransactionSelect
                name="transactionType"
                control={transactioControl}
                options={TRANSACTION_TYPES}
                onBlur={() =>
                  setTimeout(() => {
                    transactionTrigger('transactionType');
                    if (transactionWatch('customer')?.length) {
                      transactionTrigger('customer');
                    }
                    if (
                      (Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                        Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous) &&
                      transactionWatch('secondAgent')?.length
                    ) {
                      transactionSetValue('secondAgent', '', { shouldValidate: true });
                      transactionTrigger('secondAgent');
                    }
                    if (transactionWatch('carrierTransaction')?.length) {
                      transactionTrigger('carrierTransaction');
                    }
                  }, 500)
                }
                placeholder={SUBJECT.TRANSACTION_TYPE}
                required
              />
            </Col>
            {data?.load?.customerAccess && (
              <Col
                span={
                  !(
                    (data?.load?.customerAccess && !data?.load?.carrierAccess) ||
                    (data?.load?.carrierAccess && !!data?.load?.carrier?.id)
                  )
                    ? 8
                    : 4
                }
              >
                <TransactionInput
                  suffix={<DollarIcon />}
                  name="customer"
                  control={transactioControl}
                  getValues={transactionWatch}
                  onChange={e => {
                    transactionSetValue(
                      'customer',
                      Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                        Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                        ? floatNumberFormatter(e.target.value)
                        : floatNumberFormatterWithMinus(e.target.value),
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                        shouldTouch: false,
                      }
                    );
                    transactionTrigger(['secondAgent', 'carrierTransaction']);
                  }}
                  placeholder={SUBJECT.CUSTOMER}
                  value={
                    transactionWatch('customer')
                      ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('customer'))
                      : ''
                  }
                  withRedMinus
                />
              </Col>
            )}
            {data?.load?.customerAccess && !data?.load?.carrierAccess && (
              <Col span={4}>
                <TransactionInput
                  suffix={<DollarIcon />}
                  name="secondAgent"
                  control={transactioControl}
                  getValues={transactionWatch}
                  disabled={
                    Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                    Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                  }
                  onChange={e => {
                    transactionSetValue('secondAgent', floatNumberFormatterWithMinus(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                      shouldTouch: false,
                    });
                    transactionTrigger(['customer', 'carrierTransaction']);
                  }}
                  placeholder={SUBJECT.SECOND_AGENT}
                  value={
                    transactionWatch('secondAgent')
                      ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('secondAgent'))
                      : ''
                  }
                  withRedMinus
                />
              </Col>
            )}
            {data?.load?.carrierAccess && !!data?.load?.carrier?.id && (
              <Col span={data?.load?.carrierAccess && !data?.load?.customerAccess ? 8 : 4}>
                <TransactionInput
                  suffix={<DollarIcon />}
                  name="carrierTransaction"
                  control={transactioControl}
                  getValues={transactionWatch}
                  onChange={e => {
                    transactionSetValue(
                      'carrierTransaction',
                      Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                        Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                        ? floatNumberFormatter(e.target.value)
                        : floatNumberFormatterWithMinus(e.target.value),
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                        shouldTouch: false,
                      }
                    );
                    transactionTrigger(['customer', 'secondAgent']);
                  }}
                  placeholder={SUBJECT.CARRIER}
                  value={
                    transactionWatch('carrierTransaction')
                      ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('carrierTransaction'))
                      : ''
                  }
                  withRedMinus
                />
              </Col>
            )}
            <Col span={8}>
              <TransactionInput
                suffix={<DollarIcon />}
                name="balanceAmount"
                getValues={transactionWatch}
                control={transactioControl}
                rules={{ required: true }}
                placeholder={SUBJECT.BALANCE_AMOUNT}
                value={String(transactionWatch('balanceAmount'))}
                disabled
                withRedMinus
              />
            </Col>
            {data?.load?.customerAccess && data?.load?.carrierAccess && (
              <Col span={5}>
                <TransactionInput
                  suffix={<DollarIcon />}
                  name="secondAgent"
                  control={transactioControl}
                  getValues={transactionWatch}
                  disabled={
                    Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                    Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                  }
                  onChange={e => {
                    transactionSetValue('secondAgent', floatNumberFormatterWithMinus(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                      shouldTouch: false,
                    });
                    transactionTrigger(['customer', 'carrierTransaction']);
                  }}
                  placeholder={SUBJECT.SECOND_AGENT}
                  value={
                    transactionWatch('secondAgent')
                      ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('secondAgent'))
                      : ''
                  }
                  withRedMinus
                />
              </Col>
            )}
            <Col
              span={
                data?.load?.customerAccess && !data?.load?.carrierAccess
                  ? 24
                  : data?.load?.customerAccess && data?.load?.carrierAccess
                  ? 19
                  : 24
              }
            >
              <TransactionInput
                type="text"
                control={transactioControl}
                name="transactionComment"
                getValues={transactionWatch}
                placeholder={SUBJECT.WRITE_COMMENT}
              />
            </Col>

            {loadsPagePermissions.transaction && (
              <Col span={24}>
                <Row justify="end">
                  <Button
                    variant="default"
                    children={SUBJECT.ADD_TRANSACTION}
                    disabled={addTransactionCheck() || !isTransactionValidBtn || isCreateTransactionLoading}
                    onClick={handleCreateTransaction}
                  />
                </Row>
              </Col>
            )}
            {!!transactionsForDataGrind(loadTransactions)?.length && (
              <Col span={24}>
                <TransactionsTable
                  data={transactionsForDataGrind(loadTransactions)}
                  handleRowDelete={handleDeleteTransaction}
                  hasDeletePermission={loadsPagePermissions.transactionDelete}
                  isCarrier={data?.load?.carrierAccess && !!data?.load?.carrier?.id}
                  isCustomer={data?.load?.customerAccess}
                  isSecondAgent={
                    (data?.load?.customerAccess && data?.load?.carrierAccess) ||
                    (data?.load?.customerAccess && !data?.load?.carrierAccess)
                  }
                />
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      ) : (
        <>
          {!!transactionsForDataGrind(loadTransactions)?.length && (
            <ViewItemWrapper
              title={SUBJECT.TRANSACTION}
              className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
              canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
              id="transaction"
            >
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <TransactionsTable
                    data={transactionsForDataGrind(loadTransactions)}
                    handleRowDelete={handleDeleteTransaction}
                    hasDeletePermission={loadsPagePermissions.transactionDelete}
                    isCarrier={data?.load?.carrierAccess && !!data?.load?.carrier?.id}
                    isCustomer={data?.load?.customerAccess}
                    isSecondAgent={isSecondAgent}
                  />
                </Col>
              </Row>
            </ViewItemWrapper>
          )}
        </>
      )}
      <ViewItemWrapper
        title={SUBJECT.LOAD_INFO}
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        id="load-info"
        className={activeSection === SUBJECT.LOAD_INFO_ID ? 'active' : ''}
      >
        <Row align="middle" className={styles.topBannerBlock} gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="transportType"
              control={control}
              placeholder={SUBJECT.LOAD_TYPE}
              rules={{ required: true }}
              value={TransportCapabilityTypes[data?.information?.transportCapability]}
              defaultValue={TransportCapabilityTypes[data?.information?.transportCapability]}
              errors={errors}
              required
              disabled
            />
          </Col>
          {watch('transportType') === TransportCapabilityTypes.Refrigerated && (
            <Col span={8}>
              <Input
                name="celsius"
                control={control}
                placeholder={SUBJECT.CELSIUS}
                rules={{ required: true }}
                getValues={watch}
                value={data?.information?.refrigeratorCelsius || ''}
                errors={errors}
                required
                disabled
              />
            </Col>
          )}
          <Col span={8}>
            <Input
              name="takenAs"
              control={control}
              placeholder={SUBJECT.TAKEN_AS}
              rules={{ required: true }}
              errors={errors}
              value={TakenType[data?.information?.takenType]}
              defaultValue={TakenType[data?.information?.takenType]}
              required
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="givenAs"
              control={control}
              placeholder={SUBJECT.GIVEN_AS}
              rules={{ required: true }}
              value={TakenType[data?.information?.givenType]}
              defaultValue={TakenType[data?.information?.givenType]}
              errors={errors}
              required
              disabled
            />
          </Col>
          <Col span={16}>
            <Input
              type="text"
              name="commodity"
              control={control}
              getValues={watch}
              placeholder={SUBJECT.COMMODITY}
              rules={{ required: true }}
              errors={errors}
              required
              value={data?.information?.commodity}
              defaultValue={data?.information?.commodity}
              disabled
            />
          </Col>
        </Row>
        <Row align="middle" gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="bookedAs"
              control={control}
              placeholder={SUBJECT.BOOKED_AS}
              rules={{ required: true }}
              errors={errors}
              required
              value={BookedTypes[data?.information?.bookedType]}
              defaultValue={BookedTypes[data?.information?.bookedType]}
              disabled
            />
          </Col>
          <Col span={8}>
            <Input
              name="soldAs"
              control={control}
              placeholder={SUBJECT.SOLD_AS}
              rules={{ required: true }}
              errors={errors}
              required
              value={BookedTypes[data?.information?.soldType]}
              defaultValue={BookedTypes[data?.information?.soldType]}
              disabled
            />
          </Col>
          {watch('soldAs') === BookedTypesEnum.Partial && (
            <Col span={8}>
              <Input
                name="feetPartial"
                type="number"
                control={control}
                placeholder={SUBJECT.FEET_OF_PARTIAL}
                rules={{ required: true }}
                value={data?.information?.feetOfPartial}
                defaultValue={data?.information?.feetOfPartial}
                errors={errors}
                required
                getValues={watch}
                disabled
              />
            </Col>
          )}
          <Col span={8}>
            <Input
              name="weight"
              type="number"
              control={control}
              placeholder={SUBJECT.WEIGHT}
              rules={{ required: true }}
              errors={errors}
              required
              getValues={watch}
              value={data?.information?.weight}
              defaultValue={data?.information?.weight}
              disabled
            />
          </Col>
        </Row>
      </ViewItemWrapper>

      <PickupsDropoffs
        activeSection={activeSection}
        pickupDropOffsWatch={createLoadForm.pickupDropOffsWatch}
        pickupDropOffsControl={createLoadForm.pickupDropOffsControl}
        pickupDropOffsErrors={createLoadForm.pickupDropOffsErrors}
        setPickupDropOffsValue={createLoadForm.setPickupDropOffsValue}
        isPickupDropOffsValid={createLoadForm.isPickupDropOffsValid}
        watch={createLoadForm.watch}
        errors={createLoadForm.errors}
        loadPointsAutocomplete={loadPointsAutocomplete}
        setValue={createLoadForm.setValue}
        handleClearDate={createLoadForm.handleClearDate}
        statesData={createLoadForm.statesData}
        trigger={createLoadForm.trigger}
        refetchLoadPoints={refetchLoadPoints}
        {...createLoadForm}
        data={data}
        editMode
        id={data?.load?.id}
        iAmSecondAgent={iAmSecondAgent}
        hasAddPickupDropOffPermission={loadsPagePermissions.addPickUpDropOff}
        hasEditPickupDropOffPermission={loadsPagePermissions.editPickUpDropOff}
        isCanceledClaimed={LoadCancelStatus.Canceled === data?.load?.status || data?.load?.claimed}
        invalidLoadPointIds={invalidLoadPointIds}
        connectingError={connectingError}
        regularError={regularError}
        pointsState={pointsState}
        setPointsState={setPointsState}
        insurance={insurance}
        filledPickUpDropOffs={filledPickUpDropOffs}
        submitted={submitted}
      />
      {rateConfirmations?.result?.length && !isFirstAgent ? (
        <div className={styles.documentationWrapper}>
          <ViewItemWrapper
            title="Carrier Rate Confirmation"
            id={SUBJECT.CARRIER_RATE_CONFIRMATION_ID}
            className={activeSection === SUBJECT.CARRIER_RATE_CONFIRMATION_ID ? 'active' : ''}
            canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          >
            {rateConfirmations?.result?.map((fileItem: any, index: number) => {
              return (
                <AutoRateConfirmation
                  fileItem={fileItem}
                  handleViewFile={handleViewFileEdit}
                  handleDownloadFileClick={handleDownloadFileEdit}
                  key={fileItem.id}
                  handleSendRateConfirmation={handleSendRateConfirmation}
                  rateConfirmations={rateConfirmations}
                  index={index}
                />
              );
            })}
            {!!createRateConfCheck && (
              <Typography
                onClick={handleCreateRateConfirmation}
                variant="paragraph"
                children={SUBJECT.CREATE_RATE}
                className={styles.newCarrierRateBtn}
              />
            )}
          </ViewItemWrapper>
        </div>
      ) : null}

      {data?.load?.carrierAccess && !!breakdowns?.result?.length && (
        <ViewItemWrapper
          title={SUBJECT.CARRIER_PAYMENT_BREAKDOWN}
          id={SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID}
          className={activeSection === SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID ? 'active' : ''}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        >
          <CarrierPaymentBreakdown
            data={{
              result: breakdowns?.result?.map((item: any) => {
                return {
                  download: item?.file?.download,
                  id: item?.id,
                  name: item?.file?.name,
                  createdAt: formatDate(item?.createdAt),
                  preview: item?.file?.preview,
                };
              }),
              count: breakdowns?.count,
            }}
            skip={breakdownParams.skip}
            limit={breakdownParams.limit}
            isLoading={isBreakdownLoading}
            onPaginationClick={handleCarrierBreakdownPaginationClick}
            onSort={handleBreakdownSortClick}
            withPagination={true}
          />
        </ViewItemWrapper>
      )}

      <div className={styles.uploadSection}>
        <ViewItemWrapper
          title={SUBJECT.DOCUMENTATION}
          className={activeSection === SUBJECT.DOCUMENTATION_ID ? 'active' : ''}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          id="documentation"
        >
          {(!isSecondAgent || hasAccessToRateConf || UserTypes.ADMIN === userType) && (
            <Row align="middle" justify={isSecondAgent ? 'end' : 'space-between'}>
              {!iAmSecondAgent && (
                <Col span={8}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.UPLOAD_RED_CONFIRMATION}
                    className={styles.uploadHeading}
                  />
                </Col>
              )}

              <Col span={5}>
                {!isSecondAgent && isSendedToBilling ? (
                  <Typography className={styles.toBillingText} variant="paragraph" children={SUBJECT.SENT_TO_BILLING} />
                ) : (
                  loadsPagePermissions.sendToBilling &&
                  !iAmSecondAgent && (
                    <Button
                      className="smallBtn"
                      variant="default"
                      children={SUBJECT.SEND_TO_BILLING}
                      disabled={isSendToBillingDisabled}
                      onClick={handleSendToBilling}
                    />
                  )
                )}
              </Col>
            </Row>
          )}
          <Row gutter={[0, 30]}>
            {!iAmSecondAgent && (
              <Col span={24}>
                <Row gutter={[23, 30]}>
                  <Col span={9}>
                    <Upload
                      uploadText={SUBJECT.UPLOAD_RED_CONFIRMATION}
                      className={styles.upload}
                      beforeUpload={beforeUploadForEdit}
                      name="file"
                      accept="application/pdf"
                      multiple={false}
                      isDisabled={
                        !(
                          (!isSecondAgent || hasAccessToRateConf || UserTypes.ADMIN === userType) &&
                          loadsPagePermissions.uploadsFiles
                        )
                      }
                      uploadType="2"
                    />
                  </Col>
                  <Col span={12}>
                    <Typography
                      variant="heading"
                      children={SUBJECT.ALLOWED_FORMAT_PDF}
                      className={styles.allowedFormat}
                    />
                  </Col>
                </Row>
              </Col>
            )}

            {!!allFiles?.length && (
              <Col span={24}>
                <Row justify="end" gutter={[20, 0]} align="bottom" className={styles.downloadAllBlock}>
                  <Col>
                    <Typography variant="heading" children={SUBJECT.DOWNLOAD_ALL} className={styles.downloadAll} />
                  </Col>
                  <Col>
                    <div
                      className={clsx(styles.uploadControl, styles.downloadBtn)}
                      onClick={() => multiplyDownloadWithDelay(allFiles)}
                    >
                      <DownloadIcon />
                    </div>
                  </Col>
                </Row>

                <Col span={24}>
                  <Controller
                    control={control}
                    render={() => (
                      <FileItems
                        handleDownloadFileEdit={handleDownloadFileEdit}
                        handleViewFileEdit={handleViewFileEdit}
                        itemId={data?.load?.id}
                        onDeleteClick={handleDeleteFileEdit}
                        files={allFiles}
                        isDisabled={isSendedToBilling || data?.load?.status !== LoadStatus.LOAD_STATUS_NOT_PROCESSING}
                        withCrossing
                        hasDeleteFilePermission={loadsPagePermissions.filesDelete}
                      />
                    )}
                    name="file"
                  />
                </Col>
              </Col>
            )}
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.UPLOAD_CONFIRMING_FILES}
                    className={styles.uploadHeading}
                  />
                </Col>
                {isSecondAgent && (
                  <Col span={5}>
                    {isSendedToBilling ? (
                      <Typography
                        className={styles.toBillingText}
                        variant="paragraph"
                        children={SUBJECT.SENT_TO_BILLING}
                      />
                    ) : (
                      loadsPagePermissions.sendToBilling &&
                      !iAmSecondAgent && (
                        <Button
                          variant="default"
                          children={SUBJECT.SEND_TO_BILLING}
                          disabled={isSendToBillingDisabled}
                          onClick={handleSendToBilling}
                        />
                      )
                    )}
                  </Col>
                )}
              </Row>
              <Row gutter={[23, 30]}>
                <Col span={9}>
                  <Upload
                    accept="application/pdf"
                    uploadText={SUBJECT.UPLOAD_CONFIRMING_FILES}
                    className={styles.upload}
                    beforeUpload={beforeUploadForEditPOD}
                    isDisabled={!loadsPagePermissions.uploadsFiles}
                    uploadType="2"
                  />
                </Col>
                <Col span={8} flex="end">
                  <Typography
                    variant="heading"
                    children={SUBJECT.ALLOWED_FORMAT_PDF}
                    className={styles.allowedFormat}
                  />
                </Col>
              </Row>
              {allFilesPOD?.length ? (
                <div className={styles.uploadRowWrapper}>
                  <UploadRow
                    files={allFilesPOD}
                    onWatchClick={handleViewFileEditPOD}
                    onDownloadClick={handleDownloadFileEditPOD}
                    onDeleteClick={handleDeleteFileEditPOD}
                    onEditFile={handleEditFile}
                    isDisabled={
                      isSendedToBilling ||
                      data?.load?.status !== LoadStatus.LOAD_STATUS_NOT_PROCESSING ||
                      !loadsPagePermissions.uploadsFiles
                    }
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </ViewItemWrapper>
      </div>
      {isSendedToBilling && roleLevel === IRoleLevel.corpLevel && (
        <div
          className={clsx({
            [styles.active]: activeSection === SUBJECT.GENERAL_INFORMATION_ID,
          })}
          id="billing-block"
        >
          <BillingBlock
            paymentTermType={data?.load?.paymentTermType}
            noa={data?.load?.noa}
            mailableFiles={mailableFiles}
            pod={loadFiles?.result?.POD}
            redConfirmation={loadFiles?.result?.RED_CONFIRMATION}
            invoice={loadFiles?.result?.INVOICE || []}
            onAcceptClick={handleAcceptToBilling}
            onRejectClick={handleRejectBilling}
            onCreateInvoice={handleCreateInvoice}
            onDeleteInvoice={handleDeleteInvoice}
            loadStatus={data?.load?.status}
            processing={data?.load?.processing}
            hasValidFiles={!isSendToBillingDisabled}
            hasAcceptToBillingPermission={loadsPagePermissions.acceptToBilling}
            hasClaimLoadAcceptToBillingPermission={loadsPagePermissions.claimLoadAcceptToBilling}
            hasRejectBillingPermission={loadsPagePermissions.reject}
            hasClaimLoadRejectPermission={loadsPagePermissions.claimLoadReject}
            isLoadClaimed={data?.load?.claimed}
            isSendedToBilling={isSendedToBilling}
            loadData={data}
            isCanceledClaimed={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
            isFetching={isFetching}
            isInvoiceLoading={isInvoiceLoading}
            isLoadHasCustomer={!!data?.load?.customer?.id}
          />
        </div>
      )}
      <div ref={drawerRef}>
        <CustomComment
          id="comment"
          watch={watch}
          name="comment"
          setValue={setValue}
          control={control}
          comments={comments}
          createComment={createComment}
          deleteComment={deleteComment}
          getMoreComments={getMoreComments}
          isBlockCanceled={data?.load?.status === LoadCancelStatus.Canceled}
          isShowMoreVisible={isShowMoreVisible}
          activeSection={activeSection}
          isDefaultHistory={isDefaultHistory}
          setIsDefaultHistory={setIsDefaultHistory}
          loadHistory={loadHistory}
          withNotes={loadHistory?.result?.length}
          isShowMoreVisibleNotes={isShowMoreVisibleNotes}
          getMoreNotes={getMoreNotes}
          drawerRef={drawerRef}
          hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
          isFetchingHistory={isFetchingHistory}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
        />
      </div>
    </form>
  );
};

export default ConnectingEdit;
