import React from 'react';
import { Table as AntdTable } from 'antd';
import clsx from 'clsx';
import { PaymentStatusTypes } from 'services/payroll/payment/types';

import useTable from './useTable';

import styles from './Table.module.scss';

const Table = ({
  data,
  onSort,
  columns,
  isLoading,
  onRowClick,
  onRateChange,
  onResizeStop,
  onSwitchClick,
  onColumnSearch,
  onDownloadClick,
  onPaginationClick,
  handleRowEdit,
  handleRowDelete,
  withColumnSearch,
  withMenu,
  RateComponent,
  onFavoriteClick,
  onCommentClick,
  onActionButton,
  onActionUnderline,
  onStatusClick,
  onRowPayClick,
  onCheckboxChange,
  withCheckbox,
  selectedRows,
  isAuto = false,
  onGetRatings,
  rateList,
  onDistributeClick,
  onResetPassword,
  onSendInvoice,
  onReceivedInvoice,
  onReceivePayableInvoice,
  skip,
  limit,
  hasTerminateOnPermission,
  hasTerminateOffPermission,
  hasEditPermission,
  hasDeletePermission,
  hasUnlockPermission,
  hasApprovePermission,
  hasDenyPermission,
  hasCreditActionsPermissions,
  onBadgeClick,
  onPaymentPlanClick,
  highlightedRowId,
  tableRef,
  hasPermissionToPay,
  hasPermissionToApprove,
  hasPermissionToAction,
  hasPermissionToReceiveInvoice,
}: any) => {
  const { renderColumns, ResizableTitle, onMouseUp, paginationArrows, pageSizeOptions } = useTable({
    onSort,
    columns,
    onRateChange,
    onResizeStop,
    onSwitchClick,
    onColumnSearch,
    onDownloadClick,
    withColumnSearch,
    withMenu,
    handleRowEdit,
    onGetRatings,
    rateList,
    handleRowDelete,
    RateComponent,
    onFavoriteClick,
    onCommentClick,
    onActionButton,
    onActionUnderline,
    onStatusClick,
    onRowPayClick,
    onDistributeClick,
    onRowClick,
    onResetPassword,
    onPaginationClick,
    onSendInvoice,
    onReceivedInvoice,
    onReceivePayableInvoice,
    hasTerminateOnPermission,
    hasTerminateOffPermission,
    hasEditPermission,
    hasDeletePermission,
    hasUnlockPermission,
    hasApprovePermission,
    hasDenyPermission,
    hasCreditActionsPermissions,
    onBadgeClick,
    onPaymentPlanClick,
    hasPermissionToPay,
    hasPermissionToApprove,
    hasPermissionToAction,
    hasPermissionToReceiveInvoice,
  });
  const rowClassName = ({ id }: { id: number }) => {
    return highlightedRowId && Number(id) === Number(highlightedRowId) ? 'highlight-row' : '';
  };

  return (
    <div
      className={clsx(styles.tableWrapper, {
        [styles.autoWrapper]: isAuto,
      })}
    >
      <AntdTable
        rowKey="id"
        tableLayout={`${isAuto ? 'auto' : 'fixed'}`}
        ref={tableRef}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        rowClassName={rowClassName}
        pagination={
          data?.count > 10 && {
            current: Math.floor(skip / limit) + 1,
            pageSize: limit,
            onChange: (page, pageSize) => {
              onPaginationClick((page - 1) * pageSize, pageSize);
            },
            showTotal(total, [from, to]) {
              return <span>{`${from}-${to} of ${total}`}</span>;
            },
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions(data.count),
            locale: { items_per_page: '' },
            itemRender: paginationArrows,
            total: data.count,
          }
        }
        rowSelection={
          withCheckbox &&
          (hasPermissionToPay || hasPermissionToApprove) &&
          hasPermissionToAction && {
            getCheckboxProps: record => {
              const firstSelectedRow = selectedRows[0];
              const firstSelectedRowType = firstSelectedRow?.final > 0;
              const currentRecordType = record?.final > 0;
              const disableCheckbox = firstSelectedRowType !== currentRecordType;

              const checkDisablePayOrApprove =
                (record?.final > 0 && !hasPermissionToPay) || (!(record?.final > 0) && !hasPermissionToApprove);

              return {
                disabled:
                  record?.status !== PaymentStatusTypes.PAYMENT_STATUS_PENDING ||
                  (firstSelectedRow && disableCheckbox) ||
                  checkDisablePayOrApprove,
              };
            },
            type: 'checkbox',
            onChange: onCheckboxChange,
            selectedRowKeys: selectedRows.map((el: any) => el?.id),
            columnWidth: 40,
            hideSelectAll: true,
            renderCell: (value: boolean, record: unknown, index: number, originNode: React.ReactNode) => {
              return <div onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>{originNode}</div>;
            },
          }
        }
        loading={isLoading}
        columns={renderColumns?.filter((el: any) => el?.isVisible)}
        dataSource={data?.result}
        onRow={(record: any) => {
          return {
            onMouseUp: () => onMouseUp(),
            onClick: () => onRowClick && onRowClick(record.id, record),
          };
        }}
        sticky
        scroll={{ x: '100%' }}
      />
    </div>
  );
};

export default Table;
