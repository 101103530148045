import { ICustomersFormData, IOptions } from './types';

export enum SUBJECT {
  RESET_ALL_BIG = '   Reset All',
  CUSTOMER_TABLE = 'Customer Table',
  CUSTOMERS_BLACK_LIST = 'Blacklist',
  CUSTOMER_TYPE = 'Customer Type',
  NOT_SELECTED = 'Not Selected',
  CUSTOMER_FILTER_TYPE = 'customerType',
  RESET_ALL = 'Reset All',
  SAVE = 'Save',
  ADD_CUSTOMER = 'Add Customer',
  BLACKLIST = 'Blacklist',
  CONTACT_NAME = 'Contact Name',
  BILLING = 'Billing',
  RECEIVABLES = 'Receivables',
  ADD_BLACK_LIST = 'Add Blacklist',
  EDIT_BTN_TEXT = 'Save Changes',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  CANCEL = 'Cancel',
  UNLOCK = 'Unlock',
  IS_EMPTY = 'Customer List Is Empty',
  BLACKLIST_IS_EMPTY = 'Blacklist Is Empty',
  ADD_NEW_CUSTOMER = 'Add New Customer',
  EDIT_CUSTOMER = 'Edit Customer',
  NAME = 'Name',
  PHONE_NUMBER = 'Phone Number',
  FAX = 'Fax',
  ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIPCODE = 'Zip Code',
  TYPE = 'Type',
  PAYMENT_TERMS = 'Payment Terms',
  PAYMENT_TERMS_FOR_AGENCY_AUTHORITIES = 'Payment terms and billing for agency authorities ',
  PAYMENT_TERMS_FOR_FACTORED_AUTHORITIES = 'Payment terms and billing for factored authorities ',
  GENERAL_INFO = 'General Information',
  MC = 'MC',
  FACTORING = 'Factoring',
  QUICK_PAY = 'QuickPay',
  CUSTOM = 'Custom',
  FF_NUMBER = 'FF Number',
  MC_NUMBER = 'MC Number',
  DAY = 'Day',
  PERCENT = 'Percent',
  EMAIL = 'Email',
  ADD = 'Add',
  ADDED = 'Added',
  ADDED_BY = 'Added By',
  TOOLTIP_TEXT = 'Select All Active Fields',
  TEAMPLATE = 'Template',
  CREDIT_LIMIT = 'Credit Limit',
  AUTHORITY_NAME = 'Authority Name',

  CUSTOMER = 'Customer',
  CUSTOMER_NAME = 'Customer Name',
  CUSTOMER_INFO = 'Customer Info',
  CUSTOMER_ID = 'Customer ID',
  ADDITIONAL = 'Additional',
  FULL_INFO = 'Customer Detail',
  EDIT = 'Edit',
  DATE = 'Date',
  DATE_AND_TIME = 'Date And Time',

  MC_FF = 'MC / FF number',
  COMMENT = 'Comment',
  TERMINATE = 'Terminate',
  STATUS = 'Status',
  PAYMENT = 'Payment',
  ARE_YOU_SURE = 'Are You Sure Want Do Delete?',
  DELETE = 'Delete',
  NO = 'No',
  YES = 'Yes',
  CHECKED_ACTIVATE = 'If Activated, Customer Can Be Used Again',
  CHECKED_DEACTIVATE = "If Terminated, Customer Can't Be Used Anymore",
  ADD_BLACKLIST = 'Add Blacklist',

  COMMENT_ID = 'Comment',
  STAY_HERE = 'Stay Here',
  RESET = 'Reset',

  CLOSE = 'Close',
  ADD_AUTHORITIES_BTN = '+ Add Authorities',
  DOT = 'DOT',
  STATE_NUMBER = 'State Number',
  BLACK_LIST_UPDATED = 'Authority Has Been Already Been Marked Blacklisted When Load Had Been Claimed, But Now It Has Been Blacklisted Again Manually.',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',

  RECEIVABLES_CONTACT = 'Receivables Contact',
  BILLING_CONTACT = 'Billing Contact',
}

export const filterDefaultValues = {
  customerType: [],
};

export const blacklistFilterDefaultValues = {
  blacklistMC: '',
  blacklistCustomer: '',
};

export const customerTypeOptions: IOptions[] = [
  {
    title: 'Shipper',
    value: '1',
  },
  {
    title: 'Broker',
    value: '2',
  },
  {
    title: 'Carrier',
    value: '3',
  },
];

export const defaultValues: ICustomersFormData = {
  name: '',
  type: '',
  phoneNumber: null,
  mcNumber: '',
  ffNumber: '',
  dot: '',
  stateNumber: '',
  fax: null,
  address: '',
  secondAddress: '',
  city: '',
  state: '',
  zipcode: null,
  mc: [],
  paymentType: '',
  day: '',
  percent: null,
  email: [],
  comments: [],
  addedMc: [],
  paymentId: '',
  templateId: '',
  paymentLimit: '',
  billingPhone: '',
  billingEmail: '',
  billingContactName: '',
  receivablesPhone: '',
  receivablesEmail: '',
  receivablesContactName: '',
};

export const PAYMENT_TERMS_OPTIONS = [
  { value: SUBJECT.FACTORING, label: SUBJECT.FACTORING },
  { value: SUBJECT.QUICK_PAY, label: SUBJECT.QUICK_PAY },
  { value: SUBJECT.CUSTOM, label: SUBJECT.CUSTOM },
];

export const columnsTypes: { [key: string]: string } = {
  'customer-table': 'customers',
  'customer-blacklist': 'customersBlackList',
};

export enum VALIDATION_MESSAGES {
  EMAIL_REQUIRED = 'Email is a required field',
  MIN_OPTION = 'At least one option is required',
  INVALID_PHONE = 'Invalid phone number',
  PHONE = 'Incorrect phone format',
  INCORRECT_FAX = 'Incorrect fax format',
  NAME_REQUIRED = 'Name is a required field',
  PAYMENT_TYPE_REQUIRED = 'Payment type is a required field',
  DAY_REQUIRED = 'Day is a required field',
  PERCENT_REQUIRED = 'Percent is a required field',
  ADDED_MC_REQUIRED = 'Added mc is a required field',
  TYPE_REQUIRED = 'Type is a required field',
  MC_NUMBER_REQUIRED = 'MC number is a required field',
  FF_NUMBER_REQUIRED = 'FF number is a required field',
  PHONE_NUMBER_REQUIRED = 'Phone number is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  CITY_REQUIRED = 'City is a required field',
  STATE_REQUIRED = 'State is a required field',
  MAX_LENGTH = 'Maximum of 10 digits allowed',
  ZIP_REQUIRED = 'Zip Code is a required field',
  TEMPLATE_REQUIRED = 'Template is a required field',
  DOT_REQUIRED = 'DOT is a required field',
  STATE_NUMBER_REQUIRED = 'State number is a required field',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
  INVALID_EMAIL = 'Invalid email format',
  CONTACT_NAME_REQUIRED = 'Contact name is a required field',
  NOT_ZERO = 'Should be a positive number',
}

export const AUTHORITIES_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'type', name: 'Type' },
  { key: 'mc', name: 'MC Number' },
  { key: 'authorityId', name: 'Authority ID' },
];

export const AUTHORITIES_KEYS = ['name', 'type', 'mc', 'authorityId'];

export const CUSTOMERS_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC' },
  { key: 'id', name: 'ID' },
];

export const CUSTOMERS_KEYS = ['name', 'mc', 'id'];
