import { useState } from 'react';
import { Dropdown, MenuProps } from 'antd';

import MenuIcon from 'components/svgs/MenuIcon';

import { handleMenuItems } from './constants';
import { IMenuProps } from './types';

const Menu = ({
  handleEditClick,
  handleDeleteClick,
  rowId,
  hasEditPermission,
  hasDeletePermission,
  rowData,
}: IMenuProps) => {
  const [open, setOpen] = useState(false);

  const handleMenuClick: MenuProps['onClick'] = e => {
    e.domEvent.stopPropagation();
    if (e.key === '1') {
      handleEditClick(rowId, rowData);
    } else if (e.key === '2') {
      handleDeleteClick(rowId);
    }
    setOpen(false);
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: handleMenuItems({
          hasEditPermission,
          hasDeletePermission,
          withDelete: handleDeleteClick,
        }),
        onClick: handleMenuClick,
      }}
      onOpenChange={handleOpenChange}
      open={open}
    >
      <a
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {!rowData?.withoutEditDelete && <MenuIcon />}
      </a>
    </Dropdown>
  );
};

export default Menu;
