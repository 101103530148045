import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { IInputTagsProps } from 'ui/inputs/input-tags/constants/types';
import CustomInputTags from 'ui/inputs/input-tags/InputTags';
import { IRadioProps } from 'ui/inputs/radio/constants/types';
import CustomRadio from 'ui/inputs/radio/Radio';
import { ISearchProps } from 'ui/inputs/search/constants/types';
import CustomSearch from 'ui/inputs/search/Search';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { ICustomerBlacklistFilter, ICustomersFormData, ICustomerTypeFilter } from '../constants/types';

export const Input = withController<IInputProps, ICustomersFormData>(CustomInput);
export const SearchableSelect = withController<ISearchableSelectProps, ICustomersFormData>(CustomSearchableSelect);
export const SearchableDropdown = withController<ISearchableDropdownProps, any>(CustomSearchableDropdown);
export const Radio = withController<IRadioProps, ICustomersFormData>(CustomRadio);
export const InputTags = withController<IInputTagsProps, ICustomersFormData>(CustomInputTags);
export const FilterSelect = withController<ISelectProps, ICustomerTypeFilter>(ReusableSelect);

export const FilterMCSearch = withController<Partial<ISearchProps>, ICustomerBlacklistFilter>(CustomSearch);
export const FilterCustomerSearch = withController<Partial<ISearchProps>, ICustomerBlacklistFilter>(CustomSearch);
export const FilterSelectBlackList = withController<ISelectProps, ICustomerBlacklistFilter>(ReusableSelect);
export const SearchableSelectForBlackList = withController<ISearchableSelectProps, ICustomerBlacklistFilter>(
  CustomSearchableSelect
);
