import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { createTransaction } from './deserializers';

export const transactionsAPI = createApi({
  reducerPath: 'transactionsAPI',
  baseQuery,
  tagTypes: ['Transactions'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    createTransaction: builder.mutation({
      query: (body: any) => ({
        url: `transactions`,
        method: 'POST',
        body: createTransaction(body),
      }),
      invalidatesTags: ['Transactions'],
    }),
    deleteTransaction: builder.mutation({
      query: ({ id }) => ({
        url: `transactions/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateTransactionMutation, useDeleteTransactionMutation } = transactionsAPI;
