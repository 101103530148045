import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { Col, Popover, Row, Tooltip } from 'antd';
import {
  BrokerPaymentTermsType,
  CarrierFactoringType,
  CarrierPaymentTermType,
  CarrierPaymentType,
} from 'services/profile/carriers/interfaces';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import UploadedFileRow from 'ui/uploaded-file-row/UploadedFileRow';
import { formatDate } from 'utils/dates';
import { handleDownloadClick, handleViewFileClick, onlyNumbersFormatter } from 'utils/helpers';
import { v4 as uuid } from 'uuid';

import { BlueInfoIcon } from 'components/svgs/BlueInfoIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import PaymentTermDataGrid from '../../../../../components/payment-term-datagrid/PaymentTermDataGrid';
import { PercentIcon } from '../../../../../components/svgs/PercentIcon';
import FileItems from '../../authorities/components/file-item/FileItems';

import {
  AdditionalInfoTypes,
  AUTHORITY_KEYS,
  DAY_OPTION,
  FACTORING_COMPANY_KEYS,
  FACTORING_TYPE_OPTIONS,
  PAYMENT_TERMS_TYPE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  QUICK_PAY_OPTION,
  SEARCH_AUTHORITY_COLUMNS,
  SEARCH_FACTORING_COMPANIES_COLUMNS,
  SUBJECT,
  VALIDATION_MESSAGES,
} from './constants/constants';
import FactoredPopover from './hoc-instance/InfoPopOver';
import { Input, InputTags, Radio, SearchableDropdown } from './hoc-instance/instance';
import { usePaymentTerms } from './usePaymentTerms';

import styles from './PaymentTerms.module.scss';

const PaymentTerms = ({
  activeSection,
  statesData,
  setPaymentTermDirty = () => {
    return null;
  },
  isSubmitted = false,
  setValue: setCarrierMainValue,
  errorsFromCreateForm,
  clearCarrierError,
  carrierRequestCarrier,
}: any) => {
  const {
    control,
    setValue,
    getValues,
    errors,
    watch,
    trigger,
    setError,
    clearErrors,
    setIsEmailValid,
    setAuthoritiesAutocompleteFilter,
    authoritiesAutocomplete,
    factoringCompaniesAutocomplete,
    handleAddPaymentTerm,
    setFactoringCompaniesAutocompleteFilter,
    mode,
    paymentTerm,
    paymentTermFM,
    setEditingRow,
    allFiles,
    handleRowEdit,
    handleRowDelete,
    editingId,
    handleDownloadAuthorityFileCreate,
    handleSelectFileType,
    handleViewAuthorityFileCreate,
    handleDeleteAuthorityFileCreate,
    beforeUploadForCreate,
    formOpened,
    handleOpenPaymentForm,
    handleCancelPaymentTerm,
    isSelectDisable,
    localFiles,
    serializedStates,
    setAllFiles,
    dirtyFields,
    factoringCompanyById,
    isFetching,
    isLoading,
    paymentTerms,
    handlePaginationClick,
    isPaymentTermsFetching,
    paymentFormRef,
    paymentTableRef,
    isAproveMode,
    isReseted,
    isCarrierOnBoard,
    allFilesWithoutFactored,
    nonDeletableFiles,
    factoderFile,
    agreementsFiles,
  } = usePaymentTerms({
    setPaymentTermDirty,
    setCarrierMainValue,
    isSubmitted,
    clearCarrierError,
    carrierRequestCarrier,
  });
  if (!paymentTermFM?.length && !paymentTerm?.length && mode === 'view') {
    return null;
  }

  return (
    <div ref={paymentTableRef}>
      <ViewItemWrapper
        title={SUBJECT.PAYMENT_TERMS}
        id={SUBJECT.PAYMENT_TERMS_AND_BILLING}
        className={activeSection === SUBJECT.PAYMENT_TERMS_AND_BILLING ? 'active' : ''}
      >
        {((!paymentTermFM?.length && !paymentTerm?.length) || formOpened) && mode !== 'view' && (
          <div ref={paymentFormRef}>
            <div>
              <Row gutter={[19, 19]}>
                <Col>
                  <Tooltip color="white" title={isSelectDisable(1)} placement="top">
                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                      <Radio
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        value={watch('type')}
                        options={[PAYMENT_TERMS_TYPE_OPTIONS[0]]}
                        className={styles.radio}
                        disabled={!!isSelectDisable(1)}
                      />
                      {/* {!!factoderFile?.length && watch('type') === CarrierPaymentTermType.BROKERAGE && (
                        <Popover
                          overlayClassName={styles.popoverOverlay}
                          placement="top"
                          overlayStyle={{
                            maxWidth: '600px',
                          }}
                          content={
                            <>
                              <Row gutter={[20, 24]}>
                                <Col span={24}>
                                  <Typography
                                    children="Factoring Company Files"
                                    variant="heading"
                                    className={styles.factoringTooltipHeader}
                                  />
                                </Col>
                                {factoderFile?.map((file: any) => (
                                  <Col span={24} key={uuid()}>
                                    <div className={styles.fileItemWrapper} style={{ justifyContent: 'flex-start' }}>
                                      <Row gutter={[25, 0]} align="middle">
                                        <Col span={5}>
                                          <div className={styles.actionsWrapper}>
                                            <button type="button" onClick={() => handleViewFileClick(file.preview)}>
                                              <EyeIconSvg />
                                            </button>
                                            <button type="button" onClick={() => handleDownloadClick(file.download)}>
                                              <DownloadIcon />
                                            </button>
                                          </div>
                                        </Col>
                                        <Col span={6}>
                                          <Typography variant="paragraph" className={styles.fileInfo}>
                                            {formatDate(file.createdAt)}
                                          </Typography>
                                        </Col>
                                        <Col span={8}>
                                          <Tooltip title={file.name} placement="top" color="white" key={1}>
                                            <Typography variant="paragraph" className={styles.fileInfo}>
                                              {file.name}
                                            </Typography>
                                          </Tooltip>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </>
                          }
                        >
                          <span className={styles.infoIcon}>
                            <BlueInfoIcon />
                          </span>
                        </Popover>
                      )} */}
                    </span>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip color="white" title={isSelectDisable(2)} placement="top">
                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                      <Radio
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        value={watch('type')}
                        options={[PAYMENT_TERMS_TYPE_OPTIONS[1]]}
                        disabled={!!isSelectDisable(2)}
                        onChange={e => {
                          setValue('type', e.target.value, { shouldDirty: true });
                          setValue('authorityId', []);
                        }}
                        className={styles.radio}
                      />
                      {watch('type') === CarrierPaymentTermType.FREIGHTMAX &&
                        (!!paymentTermFM[0]?.additionalInfo?.length || !!agreementsFiles?.length) && (
                          <Popover
                            overlayClassName={styles.popoverOverlay}
                            placement="top"
                            overlayStyle={{
                              maxWidth: '600px',
                            }}
                            content={
                              <>
                                {!!paymentTermFM[0]?.additionalInfo?.filter((item: any) => item.status === 1)
                                  .length && (
                                  <Col span={24}>
                                    <Typography
                                      children="Carrier is interested"
                                      variant="heading"
                                      className={styles.factoringTooltipHeader}
                                    />
                                  </Col>
                                )}
                                <div
                                  style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '20px' }}
                                >
                                  {paymentTermFM[0]?.additionalInfo?.map((el: any) => {
                                    if (el.status === 1)
                                      return (
                                        <Col span={24} key={uuid()}>
                                          <Typography variant="paragraph" className={styles.interested}>
                                            {AdditionalInfoTypes[el.type]}
                                          </Typography>
                                        </Col>
                                      );
                                  })}
                                </div>
                                <Row gutter={[20, 24]}>
                                  {!!agreementsFiles?.length && (
                                    <Col span={24}>
                                      <Typography
                                        children="Signed agreements"
                                        variant="heading"
                                        className={styles.factoringTooltipHeader}
                                      />
                                    </Col>
                                  )}
                                  {!!agreementsFiles?.length &&
                                    agreementsFiles.map((file: any) => (
                                      <Col span={24} key={uuid()}>
                                        <div className={styles.fileItemWrapper}>
                                          <Row gutter={25} align="middle">
                                            <Col span={10}>
                                              <Tooltip title={file.name} placement="top" color="white" key={1}>
                                                <Typography variant="paragraph" className={styles.fileInfo}>
                                                  {file.name}
                                                </Typography>
                                              </Tooltip>
                                            </Col>
                                            <Col span={5}>
                                              <div className={styles.actionsWrapper}>
                                                <button type="button" onClick={() => handleViewFileClick(file.url)}>
                                                  <EyeIconSvg />
                                                </button>
                                                <button type="button" onClick={() => handleDownloadClick(file.url)}>
                                                  <DownloadIcon />
                                                </button>
                                              </div>
                                            </Col>
                                            <Col span={6}>
                                              <Typography variant="paragraph" className={styles.fileInfo}>
                                                {formatDate(file.createdAt)}
                                              </Typography>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    ))}
                                </Row>
                              </>
                            }
                          >
                            <span className={styles.infoIcon}>
                              <BlueInfoIcon />
                            </span>
                          </Popover>
                        )}
                    </span>
                  </Tooltip>
                </Col>
                {watch('type') === CarrierPaymentTermType.BROKERAGE && (
                  <Col span={24}>
                    <SearchableDropdown
                      options={authoritiesAutocomplete?.result}
                      placeholder={SUBJECT.BROKER_AUTHORITY}
                      disabled={mode === 'edit' && isAproveMode !== 'true' && editingId}
                      offset={authoritiesAutocomplete?.count}
                      setOffset={setAuthoritiesAutocompleteFilter}
                      values={watch('authorityId')}
                      columns={SEARCH_AUTHORITY_COLUMNS}
                      keys={AUTHORITY_KEYS}
                      onBlur={() => trigger('authorityId')}
                      rules={{ required: true }}
                      control={control}
                      name="authorityId"
                      showKey="id"
                      setValue={setValue}
                      required
                      withoutBorder
                      errors={errors}
                    />
                  </Col>
                )}
                <Col span={24}>
                  <Row align="middle" gutter={[20, 0]}>
                    <Col span={8}>
                      <Typography variant="paragraph" className={styles.uploadTitle} required>
                        Sign agreement
                      </Typography>
                      <Upload
                        uploadText={SUBJECT.UPLOAD_DOCUMENTS}
                        className={styles.upload}
                        beforeUpload={(e: any, setLoading: any) => beforeUploadForCreate(e, 'files', setLoading)}
                      />
                    </Col>
                    <Col>
                      <Typography
                        className={styles.downloadFormats}
                        variant="paragraph"
                        children="Allowed format (jpeg, pdf, tiff, png)"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {errors?.files && (
                        <ErrorMessage
                          fieldError={{
                            message: allFiles?.files.filter((item: any) => Number(item.fileType) !== 6)?.length
                              ? 'At least one file type is required'
                              : 'File is required',
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {!!allFilesWithoutFactored?.length && (
                <Row gutter={[0, 22]}>
                  {allFilesWithoutFactored?.map((file: any) => (
                    <Col span={24} key={uuid()}>
                      <UploadedFileRow
                        id={file.id}
                        type={file.fileType}
                        name={file.fileName || file.name}
                        addedBy={isCarrierOnBoard ? '' : file.fullName || file.createdBy}
                        addedAt={file.createdAt}
                        previewLink={file.uid || file.preview}
                        downloadLink={file.uid || file.download}
                        onChange={value => handleSelectFileType(value, file?.id)}
                        onWatchClick={value => handleViewAuthorityFileCreate(value)}
                        onDownloadClick={value => handleDownloadAuthorityFileCreate(value)}
                        onDeleteClick={value => handleDeleteAuthorityFileCreate(value, 'files')}
                        file={file}
                        mode={mode}
                      />
                    </Col>
                  ))}
                </Row>
              )}
              {/* {isCarrierOnBoard &&
                watch('type') === CarrierPaymentTermType.FREIGHTMAX &&
                !!agreementsFiles?.length &&
                agreementsFiles.map((file: any) => (
                  <Col span={24} key={file?.id} style={{ marginTop: '30px' }}>
                    <Row align="middle" gutter={10}>
                      <Col span={5}>
                        <Typography variant="paragraph" children={file?.name} className={styles.fileTypeHeading} />
                      </Col>
                      <Col>
                        <Row align="middle" gutter={8}>
                          <Col role="button">
                            <Button variant="ghost" onClick={() => handleViewFileClick(file?.url)}>
                              <EyeIconSvg outlined={true} />
                            </Button>
                          </Col>
                          <Col role="button">
                            <Button variant="ghost" onClick={() => handleDownloadClick(file?.url)}>
                              <NoaDownloadIcon />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))} */}
              {isCarrierOnBoard &&
                watch('type') === CarrierPaymentTermType.BROKERAGE &&
                !!nonDeletableFiles?.length &&
                nonDeletableFiles.map((file: any) => (
                  <Col span={24} key={file.id} style={{ marginTop: '30px' }}>
                    <Row align="middle" gutter={10}>
                      <Col span={5}>
                        <Typography variant="paragraph" children={file?.name} className={styles.fileTypeHeading} />
                      </Col>
                      <Col>
                        <Row align="middle" gutter={8}>
                          <Col role="button">
                            <Button variant="ghost" onClick={() => handleViewFileClick(file?.url)}>
                              <EyeIconSvg outlined={true} />
                            </Button>
                          </Col>
                          <Col role="button">
                            <Button variant="ghost" onClick={() => handleDownloadClick(file?.url)}>
                              <NoaDownloadIcon />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))}
              <div className={styles.radioWrapper}>
                <Typography variant="paragraph" className={styles.blockTitle}>
                  Payment method
                </Typography>
                <Row gutter={[19, 0]}>
                  <Col span={5}>
                    <Radio
                      name="factoringType"
                      control={control}
                      rules={{ required: true }}
                      value={watch('factoringType')}
                      onChange={e => {
                        setValue('factoringType', e.target.value, { shouldDirty: true });
                        if (watch('paymentType') === 3) {
                          setValue('paymentType', 1);
                          setValue('paymentFuelCard', '');
                        }
                      }}
                      options={[FACTORING_TYPE_OPTIONS[0]]}
                      className={styles.radio}
                    />
                  </Col>
                  <Col span={7}>
                    <Radio
                      name="factoringType"
                      control={control}
                      rules={{ required: true }}
                      value={watch('factoringType')}
                      onChange={e => {
                        setValue('agreement', []);
                        setAllFiles(prev => ({ files: prev?.files, agreement: [] }));
                        setValue('factoringType', e.target.value, { shouldDirty: true });
                        setValue('factoredCompanyName', []);
                      }}
                      options={[FACTORING_TYPE_OPTIONS[1]]}
                      className={styles.radio}
                    />
                  </Col>
                </Row>
              </div>
              {watch('factoringType') === CarrierFactoringType.FACTORED ? (
                <Row gutter={10} align="middle" style={{ position: 'relative' }}>
                  <Col span={23}>
                    <SearchableDropdown
                      options={factoringCompaniesAutocomplete?.result || []}
                      placeholder={SUBJECT.FACTORING_COMPANY_NAME}
                      disabled={false}
                      offset={factoringCompaniesAutocomplete?.count}
                      setOffset={setFactoringCompaniesAutocompleteFilter}
                      values={watch('factoredCompanyName')}
                      columns={SEARCH_FACTORING_COMPANIES_COLUMNS}
                      keys={FACTORING_COMPANY_KEYS}
                      control={control}
                      name="factoredCompanyName"
                      showKey="companyName"
                      setValue={setValue}
                      required
                      onBlur={() => trigger('factoredCompanyName')}
                      rules={{ required: true }}
                      withoutBorder
                      errors={errors}
                    />
                  </Col>

                  <Col span={1} className={styles.icon}>
                    <FactoredPopover
                      data={factoringCompanyById?.result}
                      isFetching={isFetching}
                      disabled={!watch('factoredCompanyName')?.[0]?.id}
                    />
                  </Col>
                  {factoderFile.map((file: any) => (
                    <Col span={24} key={file.id} style={{ marginTop: '30px' }}>
                      <Row align="middle" gutter={10}>
                        <Col span={5}>
                          <Typography variant="paragraph" children={file?.name} className={styles.fileTypeHeading} />
                        </Col>
                        <Col>
                          <Row align="middle" gutter={8}>
                            <Col role="button">
                              <Button variant="ghost" onClick={() => handleViewFileClick(file?.preview)}>
                                <EyeIconSvg outlined={true} />
                              </Button>
                            </Col>
                            <Col role="button">
                              <Button variant="ghost" onClick={() => handleDownloadClick(file?.download)}>
                                <NoaDownloadIcon />
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              ) : null}
            </div>
            <div className={styles.paymentTypeWrapper}>
              <div className={styles.paymentTypeRadios}>
                <Row gutter={[19, 0]}>
                  <Col span={5}>
                    <Radio
                      name="paymentType"
                      control={control}
                      rules={{ required: true }}
                      value={watch('paymentType')}
                      options={[PAYMENT_TYPE_OPTIONS[0]]}
                      className={styles.radio}
                      onChange={e => {
                        setValue('paymentType', e.target.value, { shouldDirty: true });
                        setValue('paymentCompanyName', '');
                        setValue('paymentAddress', '');
                        setValue('paymentSecondAddress', '');
                        setValue('paymentCity', '');
                        setValue('paymentStateId', '');
                        setValue('paymentZip', '');
                        setValue('paymentFuelCard', '');
                        clearErrors('paymentType');
                      }}
                    />
                  </Col>
                  <Col span={4}>
                    <Radio
                      name="paymentType"
                      control={control}
                      rules={{ required: true }}
                      value={watch('paymentType')}
                      options={[PAYMENT_TYPE_OPTIONS[1]]}
                      className={styles.radio}
                      onChange={e => {
                        setValue('paymentType', e.target.value, { shouldDirty: true });
                        setValue('paymentBankName', '');
                        setValue('paymentRoutingNumber', '');
                        setValue('paymentAccountNumber', '');
                        setValue('paymentFuelCard', '');
                        clearErrors('paymentType');
                      }}
                    />
                  </Col>
                  {watch('factoringType') === CarrierFactoringType.NOT_FACTORED && (
                    <Col>
                      <Radio
                        name="paymentType"
                        control={control}
                        rules={{ required: true }}
                        value={watch('paymentType')}
                        options={[PAYMENT_TYPE_OPTIONS[2]]}
                        className={styles.radio}
                        onChange={e => {
                          setValue('paymentType', e.target.value, { shouldDirty: true });
                          setValue('paymentBankName', '');
                          setValue('paymentRoutingNumber', '');
                          setValue('paymentAccountNumber', '');
                          setValue('paymentCompanyName', '');
                          setValue('paymentAddress', '');
                          setValue('paymentSecondAddress', '');
                          setValue('paymentCity', '');
                          setValue('paymentStateId', '');
                          setValue('paymentZip', '');
                          clearErrors('paymentType');
                        }}
                      />
                    </Col>
                  )}
                </Row>
                {errors.paymentType && (
                  <ErrorMessage fieldError={{ message: VALIDATION_MESSAGES.PAYMENT_TYPE_REQUIRED }} />
                )}
              </div>

              {watch('paymentType') === CarrierPaymentType.DIRECT_DEPOSIT &&
              watch('factoringType') != CarrierFactoringType.FACTORED ? (
                <Row gutter={[20, 20]}>
                  <Col span={8}>
                    <Input
                      name="paymentBankName"
                      control={control}
                      placeholder={SUBJECT.BANK_NAME}
                      getValues={getValues}
                      errors={errors}
                      rules={{ required: true }}
                      required
                      onChange={(e: any) =>
                        setValue('paymentBankName', e.target.value.trimStart(), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                      onBlur={() => trigger('paymentBankName')}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      name="paymentRoutingNumber"
                      control={control}
                      placeholder={SUBJECT.ROUTING_NUMBER}
                      onChange={(e: any) =>
                        setValue('paymentRoutingNumber', onlyNumbersFormatter(e.target.value), {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      getValues={getValues}
                      onBlur={() => trigger('paymentRoutingNumber')}
                      errors={errors}
                      rules={{ required: true }}
                      required
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      name="paymentAccountNumber"
                      control={control}
                      onChange={(e: any) =>
                        setValue('paymentAccountNumber', onlyNumbersFormatter(e.target.value), {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      onBlur={() => trigger('paymentAccountNumber')}
                      placeholder={SUBJECT.ACCOUNT_NUMBER}
                      getValues={getValues}
                      errors={errors}
                      rules={{ required: true }}
                      required
                    />
                  </Col>
                </Row>
              ) : null}

              {watch('paymentType') === CarrierPaymentType.PAPER_CHECK &&
              watch('factoringType') != CarrierFactoringType.FACTORED ? (
                <Row gutter={[20, 20]}>
                  <Col span={8}>
                    <Input
                      name="paymentCompanyName"
                      control={control}
                      placeholder={SUBJECT.PAYMENT_COMPANY_NAME}
                      getValues={watch}
                      errors={errors}
                      rules={{ required: true }}
                      required
                      onBlur={() => trigger('paymentCompanyName')}
                      onChange={(e: any) =>
                        setValue('paymentCompanyName', e.target.value.trimStart(), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      name="paymentAddress"
                      control={control}
                      placeholder={SUBJECT.PAYMENT_ADDRESS}
                      getValues={getValues}
                      errors={errors}
                      rules={{ required: true }}
                      required
                      onBlur={() => trigger('paymentAddress')}
                      onChange={(e: any) =>
                        setValue('paymentAddress', e.target.value.trimStart(), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      name="paymentSecondAddress"
                      control={control}
                      placeholder={SUBJECT.SECOND_ADDRESS}
                      getValues={getValues}
                      errors={errors}
                      onChange={(e: any) =>
                        setValue('paymentSecondAddress', e.target.value.trimStart(), {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      name="paymentCity"
                      control={control}
                      placeholder={SUBJECT.CITY}
                      getValues={watch}
                      errors={errors}
                      rules={{ required: true }}
                      onBlur={() => trigger('paymentCity')}
                      required
                      onChange={(e: any) =>
                        setValue('paymentCity', e.target.value.trimStart(), { shouldValidate: true })
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Controller
                      name="paymentStateId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <StateSelect
                            allowClear
                            options={statesData || []}
                            placeholder={SUBJECT.STATE}
                            name={field.name}
                            onBlur={() => trigger('paymentStateId')}
                            errors={errors}
                            onChange={(value: string) =>
                              setValue('paymentStateId', value, {
                                shouldDirty: true,
                                shouldValidate: true,
                              })
                            }
                            value={field.value}
                            required
                          />
                        );
                      }}
                    />
                    <ErrorMessage fieldError={errors?.paymentStateId} />
                  </Col>
                  <Col span={8}>
                    <Input
                      name="paymentZip"
                      control={control}
                      placeholder={SUBJECT.ZIP_CODE}
                      getValues={getValues}
                      errors={errors}
                      rules={{ required: true }}
                      required
                      onBlur={() => trigger('paymentZip')}
                      onChange={(e: any) =>
                        setValue('paymentZip', onlyNumbersFormatter(e.target.value), {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                    />
                  </Col>
                </Row>
              ) : null}
              {watch('paymentType') === CarrierPaymentType.FUEL_CARD ? (
                <Row gutter={[20, 0]}>
                  <Col span={8}>
                    <Input
                      name="paymentFuelCard"
                      control={control}
                      placeholder={SUBJECT.FUEL_CARD}
                      getValues={getValues}
                      onBlur={() => trigger('paymentFuelCard')}
                      onChange={(e: any) =>
                        setValue('paymentFuelCard', onlyNumbersFormatter(e.target.value), {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      errors={errors}
                      rules={{ required: true }}
                      required
                    />
                  </Col>
                </Row>
              ) : null}
            </div>
            <div className={styles.brokerPaymentTerm}>
              <Typography children={SUBJECT.BROKER_PAYMENT_TERMS} variant="paragraph" className={styles.blockTitle} />

              <Row gutter={[0, 27]} align="top">
                <Col span={4}>
                  <Radio
                    name="brokerPaymentTermType"
                    control={control}
                    rules={{ required: true }}
                    onChange={e => {
                      setValue('brokerPaymentTermType', e.target.value, { shouldDirty: true });
                      setValue('day', 30, { shouldDirty: true, shouldValidate: true });
                      setValue('percent', 0, { shouldDirty: true, shouldValidate: true });
                      setAllFiles((prev: any) => ({ files: prev.files, agreement: [] }));
                    }}
                    value={watch('brokerPaymentTermType')}
                    options={DAY_OPTION}
                    className={styles.radio}
                  />
                </Col>
                <Col span={4}>
                  <Radio
                    name="brokerPaymentTermType"
                    control={control}
                    rules={{ required: true }}
                    onChange={e => {
                      setValue('brokerPaymentTermType', e.target.value, { shouldDirty: true });
                      setValue('day', 5, { shouldDirty: true });
                      setValue('percent', 3.5, { shouldDirty: true });
                    }}
                    value={watch('brokerPaymentTermType')}
                    options={QUICK_PAY_OPTION}
                    className={styles.radio}
                  />
                </Col>
                <Col span={24}>
                  <Row gutter={12}>
                    <Col span={4}>
                      <Input
                        name="day"
                        control={control}
                        placeholder={SUBJECT.DAY}
                        disabled={watch('brokerPaymentTermType') === BrokerPaymentTermsType.DAY}
                        getValues={watch}
                        onBlur={() => trigger('day')}
                        errors={errors}
                        rules={{ required: watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK }}
                        required={watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK}
                        onChange={(e: any) =>
                          setValue('day', onlyNumbersFormatter(e.target.value.trimStart()), { shouldValidate: true })
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <Input
                        name="percent"
                        control={control}
                        placeholder={SUBJECT.PERCENT}
                        disabled={watch('brokerPaymentTermType') === BrokerPaymentTermsType.DAY}
                        getValues={watch}
                        errors={errors}
                        suffix={<PercentIcon />}
                        onBlur={() => trigger('percent')}
                        rules={{ required: watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK }}
                        required={watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK}
                        onChange={(e: any) =>
                          setValue('percent', onlyNumbersFormatter(e.target.value.trimStart()), {
                            shouldValidate: true,
                          })
                        }
                      />
                    </Col>
                    <Col span={16}>
                      <InputTags
                        control={control}
                        placeholder={SUBJECT.EMAIL}
                        getValues={watch}
                        setValue={setValue}
                        required
                        setIsEmailValid={setIsEmailValid}
                        editData={watch('brokerEmails')}
                        rules={{ required: true }}
                        name="brokerEmails"
                        errors={errors}
                        trigger={trigger}
                        setError={setError}
                        clearErrors={clearErrors}
                        isReseted={isReseted}
                      />
                    </Col>
                  </Row>
                </Col>
                {watch('brokerPaymentTermType') === BrokerPaymentTermsType.QUICK &&
                  watch('factoringType') === CarrierFactoringType.FACTORED && (
                    <Col span={24}>
                      <Row align="middle" gutter={[20, 0]}>
                        <Col span={8}>
                          <Typography variant="paragraph" className={styles.uploadTitle}>
                            Letter of release *
                          </Typography>
                          <Upload
                            uploadText={SUBJECT.UPLOAD_DOCUMENTS}
                            className={styles.upload}
                            beforeUpload={(e: any, setLoading: any) =>
                              beforeUploadForCreate(e, 'agreement', setLoading)
                            }
                          />
                        </Col>
                        <Col>
                          <Typography
                            className={styles.downloadFormats}
                            variant="paragraph"
                            children="Allowed format (jpeg, pdf, tiff, png)"
                          />
                        </Col>
                        <Col span={24}>
                          <div className={styles.fileItemMainWrapper}>
                            {!!allFiles['agreement']?.length && (
                              <FileItems
                                onDeleteClick={(e: any) => handleDeleteAuthorityFileCreate(e, 'agreement')}
                                handleDownloadFile={handleDownloadAuthorityFileCreate}
                                handleDownloadFileEdit={handleDownloadAuthorityFileCreate}
                                handleViewFileEdit={handleViewAuthorityFileCreate}
                                handleViewFileCreate={handleViewAuthorityFileCreate}
                                files={allFiles['agreement']}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>{errors?.agreement && <ErrorMessage fieldError={{ message: 'File is required' }} />}</Col>
                      </Row>
                    </Col>
                  )}

                {!!Object.keys(dirtyFields).length && isSubmitted && (
                  <Col span={12}>
                    <ErrorMessage
                      fieldError={{
                        message: 'You need to add or cancel your changes , before submitting the form',
                      }}
                    />
                  </Col>
                )}

                <Col span={!!Object.keys(dirtyFields).length && isSubmitted ? 12 : 24}>
                  <Row gutter={15} justify="end">
                    <Col>
                      <Button onClick={handleCancelPaymentTerm} variant="outlined">
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={handleAddPaymentTerm} variant="comment" loading={isLoading}>
                        {editingId ? 'Save' : 'Add'}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        )}
        <Row gutter={[20, 20]}>
          <Col span={24}>
            {!!paymentTermFM?.length && (
              <PaymentTermDataGrid
                title={'Sign up with FreightMax'}
                handleRowDelete={values => handleRowDelete(values, CarrierPaymentTermType.FREIGHTMAX)}
                handleRowEdit={values => handleRowEdit(values, CarrierPaymentTermType.FREIGHTMAX)}
                data={paymentTermFM}
                localFiles={localFiles}
                count={paymentTermFM?.length}
                editingRow={editingId}
                setEditingRow={setEditingRow}
                serializedStates={serializedStates}
                withoutAction={mode === 'view'}
                // isDeleteDisabled={mode === 'edit' && paymentTerm?.length === 1}
              />
            )}
          </Col>

          <Col span={24}>
            {!!paymentTerm?.length && (
              <PaymentTermDataGrid
                title={'Sign up with Brokerage'}
                handleRowDelete={values => handleRowDelete(values, CarrierPaymentTermType.BROKERAGE)}
                handleRowEdit={values => handleRowEdit(values, CarrierPaymentTermType.BROKERAGE)}
                data={paymentTerm}
                localFiles={localFiles}
                count={
                  mode === 'edit' && isAproveMode !== 'true' ? paymentTerms?.paymentTerm?.count : paymentTerm?.length
                }
                handlePaginationClick={handlePaginationClick}
                editingRow={editingId}
                setEditingRow={setEditingRow}
                serializedStates={serializedStates}
                isLoading={isPaymentTermsFetching}
                withoutAction={mode === 'view'}
                // isDeleteDisabled={mode === 'edit' && paymentTerm?.length === 1}
              />
            )}
          </Col>
          {!((!paymentTermFM?.length && !paymentTerm?.length) || formOpened) && mode !== 'view' && (
            <Col span={24}>
              <Row justify="end">
                <Col span={2}>
                  <Col>
                    <Button onClick={handleOpenPaymentForm} variant="comment">
                      Add
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        {errorsFromCreateForm?.paymentTerms?.map((error: any) => (
          <Fragment key={uuid()}>
            <ErrorMessage fieldError={error?.files} />
            <ErrorMessage fieldError={error?.paymentType} />
          </Fragment>
        ))}
      </ViewItemWrapper>
    </div>
  );
};

export default PaymentTerms;
