import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFile } from 'services/service';

export const authoritiesAPIFile = createApi({
  reducerPath: 'authoritiesAPIFile',
  baseQuery: baseQueryFile,
  tagTypes: [],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints(build) {
    return {
      uploadFileNOA: build.mutation({
        query: body => ({
          url: '/',
          method: 'POST',
          body,
        }),
        invalidatesTags: [],
      }),
    };
  },
});

export const { useUploadFileNOAMutation } = authoritiesAPIFile;
