import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useGetAuthoritiesShortQuery } from 'services/autocomplete/autocomplete';
import { CarrierPaymentTerms, CarrierPaymentTermType } from 'services/profile/carriers/interfaces';
import { IRoleLevel } from 'services/truck-board/carrier-request/interfaces';
import { v4 as uuid } from 'uuid';

import CloseIcon from '../../../../../components/svgs/CloseIcon';
import DangerIcon from '../../../../../components/svgs/DangerIcon';
import SuccessIcon from '../../../../../components/svgs/SuccessIcon';
import { useDetectedParams } from '../../../../../hooks/useDetectedParams';
import { useUploadFileNOAMutation } from '../../../../../services/profile/authorities/authorities-file';
import {
  useAddPaymentTermMutation,
  useDeletePaymentTermMutation,
  useEditPaymentTermMutation,
  useGetCarrierRequestCarrierQuery,
  useGetPaymentTermsByCarrierIdQuery,
} from '../../../../../services/profile/carriers/carriers';
import {
  useGetFactoringCompaniesShortQuery,
  useGetFactoringCompanyQuery,
} from '../../../../../services/profile/factoringCompanies/factoringCompanies';
import { useGetStatesQuery } from '../../../../../services/states/states';
import { changeLoading, closeModal, openModal } from '../../../../../store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from '../../../../../store/user-slice/selector';
import { notificationKey } from '../../../../../utils/constants';
import { ServerErrorCodes } from '../../../../../utils/errors';
import { PaymentTermFileType } from '../components/edit-carrier/constants/types';

import { defaultValues, SUBJECT } from './constants/constants';
import { validation } from './constants/validation';

export const usePaymentTerms = ({
  setPaymentTermDirty,
  setCarrierMainValue,
  isSubmitted,
  clearCarrierError,
  carrierRequestCarrier,
}: any) => {
  const paymentFormRef = useRef<HTMLDivElement>(null);
  const paymentTableRef = useRef<HTMLDivElement>(null);

  const [allFiles, setAllFiles] = useState<{ files: any[]; agreement: any[] }>({ files: [], agreement: [] });
  const [formOpened, setFormOpened] = useState<any>(false);
  const [localFiles, setLocalFiles] = useState<Array<any>>([]);
  const [paymentTermFM, setPaymentTermFM] = useState<any>([]);
  const [paymentTerm, setPaymentTerm] = useState<any>([]);

  const [editingRow, setEditingRow] = useState<number>();
  const [serializedStates, setSerializedState] = useState({});

  const [, setIsEmailValid] = useState(false);
  const [isReseted, setIsReseted] = useState(false);

  const [authoritiesAutocompleteFilter, setAuthoritiesAutocompleteFilter] = useState<Partial<any>>({
    type: 2,
    skip: 0,
    limit: 10,
    search: '',
    ignoreAuthorityIds: [],
  });

  const [factoringCompaniesAutocompleteFilter, setFactoringCompaniesAutocompleteFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [paymentTermFilter, setPaymentTermFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
  });

  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const {
    permissionsInfo: { roleLevel },
  } = useSelector(selectUserPermissionsInfo);
  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, id: carrierId, editingId, paymentType, open, isAproveMode, activePage } = searchParams;

  const { data: statesData } = useGetStatesQuery(
    {},
    { skip: mode === 'view' || mode === 'customize' || open === 'false' }
  );

  const [uploadFile] = useUploadFileNOAMutation();

  const [editPaymentTerm, { isLoading }] = useEditPaymentTermMutation();
  const [addPaymentTerm, { isLoading: isCreateLoading }] = useAddPaymentTermMutation();
  const [deletePaymentTerm] = useDeletePaymentTermMutation();

  const { data: authoritiesAutocomplete, refetch } = useGetAuthoritiesShortQuery({
    ...authoritiesAutocompleteFilter,
    ...(mode === 'edit' && isAproveMode !== 'true' && { carrierId }),
  });

  const { data: factoringCompaniesAutocomplete } = useGetFactoringCompaniesShortQuery(
    factoringCompaniesAutocompleteFilter
  );

  const { data: paymentTermsAgent, isFetching: isPaymentTermsFetching } = useGetPaymentTermsByCarrierIdQuery(
    { carrierId, ...paymentTermFilter },
    {
      skip:
        !carrierId ||
        !((mode === 'edit' && isAproveMode !== 'true') || mode === 'view') ||
        roleLevel === IRoleLevel.agentLevel,
    }
  );
  const { data: carrierInfo } = useGetCarrierRequestCarrierQuery(
    { id: carrierId },
    { skip: !carrierId || activePage !== 'carrierOnBoard' }
  );

  const paymentTermOnBoard = carrierInfo?.result?.paymentTerms;
  const isCarrierOnBoard = activePage === 'carrierOnBoard';
  const paymentTerms = isCarrierOnBoard ? paymentTermOnBoard : paymentTermsAgent;
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, dirtyFields },
    clearErrors,
    getValues,
    setValue,
    watch,
    reset,
    trigger,
    setError,
  } = useForm<CarrierPaymentTerms>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validation(allFiles)),
  });

  const { data: factoringCompanyById, isFetching } = useGetFactoringCompanyQuery(
    watch('factoredCompanyName')?.[0]?.id,
    {
      skip: !watch('factoredCompanyName')?.[0]?.id,
    }
  );
  useEffect(() => {
    setPaymentTermDirty(!!Object.keys(dirtyFields).length);
    setIsReseted(!!Object.keys(dirtyFields).length);
  }, [Object.keys(dirtyFields)]);

  useEffect(() => {
    if (isSubmitted && Object.keys(dirtyFields).length) {
      setTimeout(() => paymentFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
    }
  }, [isSubmitted]);

  useEffect(() => {
    setCarrierMainValue('paymentTerms', [...paymentTermFM, ...paymentTerm]);
  }, [paymentTermFM, paymentTerm]);

  const statesSerializer = (statesData: any) => {
    const serializedStates: any = {};
    statesData.forEach((el: any) => el.children.forEach((el: any) => (serializedStates[el.value] = el.title)));
    setSerializedState(serializedStates);
  };

  useEffect(() => {
    if (statesData?.length) {
      statesSerializer(statesData);
    }
  }, [statesData]);

  const handleDetermineWhichIsChanging = (data: any) => {
    if (Number(paymentType) !== CarrierPaymentTermType.BROKERAGE && data.type === CarrierPaymentTermType.BROKERAGE) {
      setPaymentTerm((prev: any) => [
        ...prev,
        {
          ...data,
          id: editingId,
          showFiles: allFiles?.files,
          showAgreement: allFiles?.agreement,
          factoringCompanyById: factoringCompanyById,
        },
      ]);
      setPaymentTermFM((prev: any) => prev.filter((el: any) => el.id != editingId) || []);
    } else if (
      Number(paymentType) !== CarrierPaymentTermType.FREIGHTMAX &&
      data.type === CarrierPaymentTermType.FREIGHTMAX
    ) {
      setPaymentTermFM((prev: any) => [
        ...prev,
        {
          ...data,
          id: editingId,
          showFiles: allFiles?.files,
          showAgreement: allFiles?.agreement,
          factoringCompanyById: factoringCompanyById,
        },
      ]);
      setPaymentTerm((prev: any) => prev.filter((el: any) => el.id != editingId));
    } else if (Number(paymentType) === data.type) {
      const currentSetter = data.type === CarrierPaymentTermType.BROKERAGE ? setPaymentTerm : setPaymentTermFM;
      const currentVariable = data.type === CarrierPaymentTermType.BROKERAGE ? paymentTerm : paymentTermFM;

      const editedIndex = currentVariable?.findIndex((el: any) => el.id == editingId);
      const updatedPaymentTerms = [...currentVariable];
      updatedPaymentTerms[editedIndex] = {
        ...data,
        id: editingId,
        showFiles: allFiles?.files,
        showAgreement: allFiles?.agreement,
        factoringCompanyById: factoringCompanyById,
      };
      currentSetter(updatedPaymentTerms);
      //   todo nee continue overWrite with replace index
    }
  };

  const handleAddPaymentTerm = (data: any) => {
    if (mode === 'add' || isAproveMode === 'true') {
      if (data.type === CarrierPaymentTermType.BROKERAGE) {
        if (!editingId || editingId === 'undefined') {
          setPaymentTerm((prev: any) => [
            ...prev,
            {
              ...data,
              id: uuid(),
              showFiles: allFiles?.files,
              showAgreement: allFiles?.agreement,
              factoringCompanyById: factoringCompanyById,
            },
          ]);
        } else {
          handleDetermineWhichIsChanging(data);
          setSearchParams({ ...searchParams, editingId: '', paymentType: '' });
        }
      } else {
        if (!editingId || editingId === 'undefined') {
          setPaymentTermFM((prev: any) => [
            ...prev,
            {
              ...data,
              id: uuid(),
              showFiles: allFiles?.files,
              showAgreement: allFiles?.agreement,
              factoringCompanyById: factoringCompanyById,
            },
          ]);
        } else {
          handleDetermineWhichIsChanging(data);
          setSearchParams({ ...searchParams, editingId: '', paymentType: '' });
        }
      }
      setAllFiles({ files: [], agreement: [] });
      setSearchParams({ ...searchParams, editingId: '', paymentType: '' });
      setTimeout(() => reset(defaultValues, { keepDirty: false, keepErrors: false, keepTouched: false }), 0);
      setFormOpened(false);
      setTimeout(() => paymentFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
      clearErrors();
      refetch();
      clearCarrierError();
    } else if (editingId) {
      handleUpdatePaymentTerm(data);
    } else {
      handleCreatePaymentTerm(data);
    }
  };

  const handleCreatePaymentTerm = (data: any) => {
    addPaymentTerm({
      carrierId,
      data: { ...data, showFiles: allFiles?.files, showAgreement: allFiles?.agreement },
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully added',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        setSearchParams({ ...searchParams, editingId: '', paymentType: '' });
        setAllFiles({ files: [], agreement: [] });
        setTimeout(() => paymentFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
        setTimeout(() => reset(defaultValues, { keepDirty: false, keepErrors: false, keepTouched: false }), 0);
        setFormOpened(false);
        clearErrors();
        refetch();
      }
    });
  };
  const handleUpdatePaymentTerm = (data: any) => {
    editPaymentTerm({
      id: editingId,
      data: { ...data, showFiles: allFiles?.files, showAgreement: allFiles?.agreement },
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully edited',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        setSearchParams({ ...searchParams, editingId: '', paymentType: '' });
        setAllFiles({ files: [], agreement: [] });
        setTimeout(() => paymentFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
        setTimeout(() => reset(defaultValues, { keepDirty: false, keepErrors: false, keepTouched: false }), 0);
        setFormOpened(false);
        clearErrors();
        refetch();
      }
    });
  };
  const handleCancelPaymentTerm = () => {
    reset(defaultValues, { keepDirty: false, keepErrors: false, keepTouched: false });
    clearErrors();
    setIsReseted(true);
    setAllFiles({ files: [], agreement: [] });
    setSearchParams({ ...searchParams, editingId: '', paymentType: '' });
    setFormOpened(!paymentTerm.length && !paymentTermFM.length);
    setTimeout(() => paymentTableRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
  };

  const handleRowEdit = (values: any, type: number) => {
    setTimeout(() => paymentFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
    setSearchParams({ ...searchParams, editingId: values.id, paymentType: values?.type });

    setFormOpened(true);
    if (type === CarrierPaymentTermType.BROKERAGE) {
      const editingElement = paymentTerm?.find((el: any) => el.id == values.id);
      const { showAgreement, showFiles, ...rest } = editingElement;
      setAllFiles({ files: showFiles, agreement: showAgreement });
      reset(
        {
          ...rest,
          files: showFiles?.map((el: any) => el.id),
          agreement: showAgreement?.map((el: any) => el.id),
        },
        { keepDefaultValues: false }
      );
    } else {
      const editingElement = paymentTermFM?.find((el: any) => el.id == values.id);
      const { showAgreement, showFiles, ...rest } = editingElement;
      setAllFiles({ files: showFiles, agreement: showAgreement });

      reset(
        {
          ...rest,
          files: showFiles?.map((el: any) => el.id),
          agreement: showAgreement?.map((el: any) => el.id),
        },
        { keepDefaultValues: false }
      );
    }
  };
  const handleRowDelete = (values: any, type: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          if (mode === 'add' || isAproveMode === 'true') {
            if (editingId == watch('id')) {
              reset(defaultValues, { keepDirty: false, keepErrors: false, keepTouched: false });
              setSearchParams({ ...searchParams, editingId: '', paymentType: '' });
              setAllFiles({ files: [], agreement: [] });
            }
            if (type === CarrierPaymentTermType.FREIGHTMAX) {
              setPaymentTermFM((prev: any) => prev.filter((el: any) => el.id !== values.id));
              dispatch(changeLoading(false));
            } else {
              setPaymentTerm((prev: any) => prev.filter((el: any) => el.id !== values.id));
              dispatch(changeLoading(false));
            }
            refetch();
            dispatch(closeModal());
          } else {
            deletePaymentTerm({
              id: values?.id,
            }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(changeLoading(true));
              } else {
                if (editingId == watch('id')) {
                  reset(defaultValues, { keepDirty: false, keepErrors: false, keepTouched: false });
                  setSearchParams({ ...searchParams, editingId: '', paymentType: '' });
                  setAllFiles({ files: [], agreement: [] });
                }
                refetch();
                notification.success({
                  message: 'Successfully deleted',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(changeLoading(false));
                dispatch(closeModal());
              }
            });
          }
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const beforeUploadForCreate = (file: any, ctx: any, setLoading: any) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('type', '1');
    uploadFile(formData)
      .then((data: any) => {
        if (data?.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          setLocalFiles(prev => [file, ...prev]);
          setValue(ctx, getValues(ctx) ? [...getValues(ctx), ...data!.data!.result!.ids] : data!.data!.result!.ids, {
            shouldDirty: true,
          });
          setAllFiles((prev: any) => {
            return {
              ...prev,
              [ctx]: [
                {
                  fullName: `${userInfo?.username}`,
                  fileName: file?.name,
                  fileType: ctx === 'agreement' ? PaymentTermFileType['LETTER_OF_RELEASE'] : '0',
                  id: data?.data?.result?.ids?.[0],
                  createdAt: new Date(),
                  uid: file?.uid,
                  type: file?.type,
                  size: file?.size,
                  lastModifiedDate: file?.lastModifiedDate,
                  lastModified: file?.lastModified,
                  name: file?.name,
                },
                ...prev[ctx],
              ],
            };
          });
          setTimeout(() => {
            trigger(ctx);
          }, 0);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return false;
  };

  const handleDeleteAuthorityFileCreate = (fileId: any, ctx: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          setValue(
            ctx,
            getValues(ctx)?.filter((el: any) => el !== fileId)
          );
          setAllFiles((prev: any) => {
            const updated = prev[ctx]?.filter((el: any) => el.id !== fileId);
            return {
              ...prev,
              [ctx]: updated,
            };
          });
          dispatch(closeModal());
          setTimeout(() => {
            trigger(ctx);
          }, 0);
          dispatch(changeLoading(false));
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleViewAuthorityFileCreate = (file: any) => {
    if (typeof file === 'object' && file !== null) {
      const fileToView = localFiles?.find(el => el.uid === file.uid);
      if (fileToView) {
        const blob = new Blob([fileToView], { type: fileToView.type });

        const url = window.URL.createObjectURL(blob);

        window.open(url, '_blank');

        window.URL.revokeObjectURL(url);
      }
    } else {
      handleViewAuthorityFileEdit(file);
    }
  };

  const handleSelectFileType = (value: string, fileId: number) => {
    setAllFiles((prev: any) => {
      return {
        files: prev?.files?.map((el: any) => {
          if (el.id === fileId) {
            return { ...el, fileType: value };
          } else {
            return el;
          }
        }),
        agreement: prev?.agreement,
      };
    });
    setTimeout(() => trigger('files'), 0);
  };

  const handleDownloadAuthorityFileCreate = (file: any) => {
    if (typeof file === 'object' && file !== null) {
      const fileToDownload = localFiles?.find(el => el.uid === file.uid);
      const blob = new Blob([fileToDownload]);
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink!.href = url;
      downloadLink.download = fileToDownload!.name;
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    } else {
      // todo edit url
      handleDownloadAuthorityFileEdit(file);
    }
  };

  const handleDownloadAuthorityFileEdit = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    link.click();
  };

  const handleViewAuthorityFileEdit = (url: string) => {
    window.open(`${url}&response-content-disposition=inline`, '_blank');
  };

  const handleOpenPaymentForm = () => {
    setFormOpened(true);
    setTimeout(() => paymentFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setPaymentTermFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  useEffect(() => {
    if (!paymentTerms?.paymentTerm?.result?.length && paymentTermFilter?.skip > 0) {
      setPaymentTermFilter((prev: any) => ({ ...prev, skip: 0, limit: 20 }));
    }
  }, [paymentTerms?.paymentTerm?.result?.length]);

  const isSelectDisable = (selectedType = 0) => {
    if (mode === 'edit' && isAproveMode !== 'true' && watch('type') == selectedType && selectedType) {
      return '';
    } else if ((mode === 'add' || isAproveMode === 'true') && watch('type') == selectedType && selectedType) {
      return '';
    } else if (mode === 'edit' && isAproveMode !== 'true' && editingId) {
      return SUBJECT.NOT_EDITABLE;
    } else if (editingId && paymentTermFM?.length === 1 && paymentTermFM[0].id == editingId) {
      return '';
    } else if (paymentTermFM?.length && watch('type') != 2) {
      if (editingId) {
        return SUBJECT.ONLY_ONE_FM_CHANGE;
      } else {
        return SUBJECT.ONLY_ONE_FM;
      }
    } else return '';
  };

  useEffect(() => {
    if ((mode === 'edit' && isAproveMode !== 'true') || mode === 'view' || activePage === 'carrierOnBoard') {
      setPaymentTermFM(paymentTerms?.paymentTermFM || []);
      setPaymentTerm(paymentTerms?.paymentTerm?.result || []);
    }
  }, [mode, paymentTerms?.paymentTerm?.result, paymentTerms?.paymentTermFM]);

  useEffect(() => {
    if (mode === 'add' || isAproveMode === 'true') {
      const data = [...paymentTerm, ...paymentTermFM];
      const detectedAuthoritiesIds = data?.map((el: any) => el?.authorityId?.[0]?.id);
      setAuthoritiesAutocompleteFilter(prev => ({ ...prev, ignoreAuthorityIds: detectedAuthoritiesIds }));
    }
  }, [paymentTerm.length, paymentTermFM.length, mode]);

  const allFilesWithoutFactored = useMemo(() => {
    const result = allFiles?.files?.filter((el: any) => Number(el.fileType) !== 6 && Number(el.fileType) !== 7) || [];
    return result;
  }, [allFiles?.files]);

  const nonDeletableFiles = useMemo(() => {
    if (!editingId) {
      return [];
    }
    return paymentTerm?.[0]?.authorityId?.[0]?.brokerSignFiles;
  }, [paymentTerm, editingId]);

  const factoderFile = useMemo(() => {
    const result = allFiles.files.filter((el: any) => Number(el.fileType) === 6 || Number(el.fileType) === 7);
    return result;
  }, [allFiles.files]);

  const agreementsFiles = useMemo(() => {
    if (!paymentTermFM?.[0]?.agreementsFiles) {
      return [];
    }
    return paymentTermFM.length
      ? [paymentTermFM?.[0]?.agreementsFiles?.agreement1, paymentTermFM?.[0]?.agreementsFiles?.agreement2]
      : [];
  }, [paymentTermFM]);

  return {
    reset,
    watch,
    trigger,
    setValue,
    getValues,
    clearErrors,
    setError,
    control,
    isValid,
    isDirty,
    errors,
    dirtyFields,
    setIsEmailValid,
    setAuthoritiesAutocompleteFilter,
    authoritiesAutocomplete,
    factoringCompaniesAutocomplete,
    setFactoringCompaniesAutocompleteFilter,
    handleAddPaymentTerm: handleSubmit(handleAddPaymentTerm),
    mode,
    paymentTerm,
    paymentTermFM,
    editingRow,
    setEditingRow,
    allFiles,
    setAllFiles,
    localFiles,
    setLocalFiles,
    handleRowEdit,
    handleRowDelete,
    editingId,
    handleDownloadAuthorityFileCreate,
    handleSelectFileType,
    handleViewAuthorityFileCreate,
    handleDeleteAuthorityFileCreate,
    beforeUploadForCreate,
    handleOpenPaymentForm,
    formOpened,
    handleCancelPaymentTerm,
    isSelectDisable,
    serializedStates,
    factoringCompanyById,
    isFetching,
    isLoading: isLoading || isCreateLoading,
    paymentTerms,
    handlePaginationClick,
    isPaymentTermsFetching,
    paymentFormRef,
    paymentTableRef,
    isAproveMode,
    isReseted,
    isCarrierOnBoard,
    allFilesWithoutFactored,
    nonDeletableFiles,
    factoderFile,
    agreementsFiles,
  };
};
