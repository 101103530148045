import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { IDistributeResponse } from 'services/payroll/information/types';
import Typography from 'ui/typography/Typography';
import { floatNumberFormatter, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import TooltipText from 'components/tooltip-text/TooltipText';

import { distributeColumnsNames, SUBJECT } from '../../constants/constants';
import { IChargebackProps } from '../../constants/types';

import styles from './Chargeback.module.scss';

export const Chargeback = ({ card, data, columns }: IChargebackProps) => {
  return (
    <div className={styles.chargebackContainer}>
      {/* {Number(card) !== 10 ? ( */}
      <>
        <Row align="middle" justify="space-between">
          <Col span={6} className={styles.subHeader}>
            <Typography variant="heading" children={SUBJECT.CHARGEBACK} />
          </Col>
          <Col span={6} className={styles.subHeaderSummary}>
            <Typography
              variant="heading"
              children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.amount, true)}`}
              className={clsx(styles.percentage, {
                [styles.positive]: Number(card) === 4,
                [styles.negative]: Number(card) === 5,
              })}
            />
            <Divider type="vertical" />
            <Typography variant="heading" className={styles.percentage} children={'100 %'} />
          </Col>
        </Row>
        <Divider className={styles.normalDivider} />
        <Row align="top" className={styles.chargesWrapper}>
          {columns?.map((column: any) => {
            const percent = `${column}Percent` as keyof IDistributeResponse;
            return (
              <Col span={24 / columns?.length} key={column}>
                <Row gutter={4}>
                  <Col>
                    <Typography
                      variant="paragraph"
                      className={styles.title}
                      children={distributeColumnsNames[column as keyof typeof distributeColumnsNames]}
                    />
                  </Col>
                  {column !== 'corporate' && (
                    <Col span={16}>
                      <TooltipText
                        data={data?.[column as keyof IDistributeResponse]}
                        subject={`${column}${SUBJECT.DISTRIBUTE}`}
                      />
                    </Col>
                  )}
                </Row>
                <div
                  className={clsx(styles.percentBlock, {
                    [styles.positive]: Number(card) === 4,
                    [styles.negative]: Number(card) === 5,
                  })}
                >
                  <Typography
                    variant="heading"
                    children={`${formatNumberWithThousandsSeparatorAndDecimal(
                      floatNumberFormatter(String((data?.amount / 100) * (data?.[percent] as number))),
                      true
                    )}`}
                  />
                  <Divider type="vertical" />
                  <Typography variant="heading" children={`${data?.[percent]}%`} />
                </div>
              </Col>
            );
          })}
        </Row>
      </>
      {/* ) : (
        <Row align="middle" justify="space-between" className={styles.collectionBlock}>
          <Col span={6} className={styles.subHeader}>
            <Typography variant="heading" children={SUBJECT.AMOUNT} />
          </Col>
          <Col span={6} className={styles.subHeaderSummary}>
            <Typography
              variant="heading"
              children={DISTRIBUTE_POSITIVE.collection.amount + '$'}
              className={clsx(styles.percentage, {
                [styles.positive]: Math.sign(DISTRIBUTE_POSITIVE.collection.amount!) === 1,
                [styles.negative]: Math.sign(DISTRIBUTE_POSITIVE.collection.amount!) === -1,
              })}
            />
          </Col>
        </Row>
      )} */}
    </div>
  );
};
