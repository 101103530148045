import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { receivablesAPI } from 'services/factoring/receivables/receivables';
import { useGetTransactionstByIdQuery } from 'services/loads/loads';
import { useCreateTransactionMutation } from 'services/transactions/transactions';
import { changeLoading } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { defaultValues } from './constants/constants';
import { IExpandedRowFormData } from './constants/types';
import { validation } from './constants/validation';

export const useRowContent = (setExpandedRowKeys: Dispatch<SetStateAction<number[]>>, loadId: number) => {
  const dispatch = useDispatch();

  const { data: loadTransactions, refetch: refetchTransaction } = useGetTransactionstByIdQuery(
    { id: loadId.toString() },
    { skip: !loadId }
  );
  const [createTransaction, { isLoading: isCreateTransactionLoading }] = useCreateTransactionMutation();

  const { searchParams, setSearchParams } = useDetectedParams();

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
    reset,
    trigger,
    setValue,
  } = useForm<IExpandedRowFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const isTransactionValidBtn = !!watch('transactionType') && !Object.keys(errors).length;

  const handleCancel = () => {
    reset(defaultValues);
    setExpandedRowKeys([]);
  };

  const billingPagePermissions = usePermittedActions('factoring.billing');

  const onSubmit = (data: IExpandedRowFormData) => {
    dispatch(changeLoading(true));
    createTransaction({
      loadId,
      body: data,
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        reset(defaultValues);
        refetchTransaction();
        dispatch(changeLoading(false));
        if (billingPagePermissions.view) {
          setSearchParams({
            ...searchParams,
            open: 'false',
            tab: 'billing',
            width: 'half',
          });
        } else {
          setSearchParams({
            ...searchParams,
            open: 'false',
            width: 'half',
          });
          dispatch(receivablesAPI.util.invalidateTags(['Receivables']));
        }
      }
    });
  };

  return {
    control,
    isValid,
    errors,
    loadTransactions,
    watch,
    handleCancel,
    handleSubmit,
    onSubmit,
    trigger,
    setValue,
    isCreateTransactionLoading,
    isTransactionValidBtn,
  };
};
