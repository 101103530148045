import { SUBJECT } from 'pages/truck-board/components/truck-board-search/constants/constants';
import { changeTimeZone } from 'utils/helpers';

export const createSearchSerializer = (body: any) => {
  const ids = Object.keys(body.form);

  const toStateIds: number[] = [];
  const fromStateIds: number[] = [];

  ids?.forEach(id => {
    if (!id.includes('ALL')) {
      if (body?.form?.[id] === SUBJECT.TO) {
        toStateIds.push(Number(id));
      } else if (body?.form?.[id] === SUBJECT.FROM) {
        fromStateIds.push(Number(id));
      } else if (body?.form?.[id] === SUBJECT.BOTH) {
        fromStateIds.push(Number(id));
        toStateIds.push(Number(id));
      }
    }
  });

  return {
    toStateIds,
    fromStateIds,
    ...(body?.availableAtTo && {
      availableAtTo: body.availableOption
        ? changeTimeZone(new Date(Number(body?.availableAtTo)), 'America/Los_Angeles')
        : String(body?.availableAtTo),
    }),
    ...(body?.availableAtFrom && {
      availableAtFrom: body.availableOption
        ? changeTimeZone(new Date(Number(body?.availableAtFrom)), 'America/Los_Angeles')
        : String(body?.availableAtFrom),
    }),
  };
};
export const updateLocationDeserializer = (data: any) => {
  return {
    availableAt: data.isConvertingNeeded
      ? changeTimeZone(new Date(data.availableDate), 'America/Los_Angeles')
      : new Date(Number(data.availableDate))?.getTime(),
    city: data.city,
    fromStateId: Number(data.from),
    toStateIds: data.to?.map((el: any) => Number(el)),
    equipmentType: Number(data.equipmentTypeLocation),
    ...(Number(data.trailerDetailsLocation) && { trailerDetails: Number(data.trailerDetailsLocation) }),
    availableLength: Number(data.length),
    desired: data.desiredHomeSwitch,
    comment: data.locationComment,
  };
};
