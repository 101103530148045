import React from 'react';
import clsx from 'clsx';
import Button from 'ui/button/Button';

import { ArrowSvg } from 'components/svgs/ArrowSvg';
import ChevronIcon from 'components/svgs/ChevronIcon';

import Typography from '../../../../typography/Typography';
import { months, SUBJECT } from '../../constants/constants';

import { getFirstDayOfMonth } from './helper/helper';
import { ICustomMonthRangePickerProps } from './types/types';
import { useCustomMonthRangePicker } from './useCustomMonthRangePicker';

import styles from '../../MonthPicker.module.scss';

const CustomMonthRangePicker = ({ isCalendarOpen, setIsCalendarOpen, handleGoBack }: ICustomMonthRangePickerProps) => {
  const {
    ref,
    handleChangeYear,
    year,
    isDisabled,
    startMonthAndYear,
    handleChangeMonthRange,
    start,
    end,
    setPossibleRange,
    handleSelectingProcess,
    possibleRange,
  } = useCustomMonthRangePicker({ setIsCalendarOpen });

  return (
    <ul className={styles.monthPickerList} ref={ref}>
      <li onMouseLeave={() => setPossibleRange([])} className={styles.monthPickerItem}>
        <ul
          className={clsx(styles.subList, {
            [styles.openedList]: isCalendarOpen,
          })}
        >
          <li>
            <div className={styles.yearPicker}>
              <span role="button" onClick={() => handleChangeYear(SUBJECT.DECREASE)}>
                <ChevronIcon />
              </span>
              <Typography variant="paragraph">{year}</Typography>
              <span
                role="button"
                className={clsx({
                  [styles.disabledBtn]: isDisabled,
                })}
                onClick={() => handleChangeYear(SUBJECT.INCREASE)}
              >
                <ChevronIcon />
              </span>
            </div>
          </li>
          <li className={styles.goBackLabelWrapper}>
            <Button variant="ghost" onClick={handleGoBack}>
              <ArrowSvg />
            </Button>
            <Typography variant="paragraph">{SUBJECT.CUSTOM_FILTER}</Typography>
          </li>
          {months?.map(({ name, value }, index) => {
            const timestamp = getFirstDayOfMonth(year, index);
            const isSelected = end
              ? timestamp >= Number(start) && timestamp <= Number(end)
              : name + year === startMonthAndYear;
            return (
              <li
                role="button"
                className={clsx(styles.monthItem, {
                  [styles.selectedMonth]: possibleRange.includes(Number(value)) || isSelected,
                })}
                id={value}
                key={value}
                onMouseEnter={(e: any) => handleSelectingProcess(e)}
                value={value}
                onClick={() => handleChangeMonthRange(index)}
              >
                {name}
              </li>
            );
          })}
        </ul>
      </li>
    </ul>
  );
};

export default CustomMonthRangePicker;
