import React from 'react';
import { Col, Divider, Row } from 'antd';
import { useHandleCopyActive } from 'hooks/useCopy';
import { ICarrierType, RatingAccessTypes } from 'services/profile/carriers/interfaces';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import { CarrierStatus } from 'ui/load-status/constants/types';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Rate from 'ui/rate/Rate';
import Typography from 'ui/typography/Typography';
import { formatDateBirth } from 'utils/dates';
import { floatNumberFormatter, formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import ResetIcon from 'components/svgs/ResetIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import {
  AUTHORITIES_CUSTOM_COLUMNS,
  AUTHORITIES_CUSTOM_KEYS,
} from '../../../../../loads/components/create-load/constants/constants';
import { SearchableDropdown } from '../../../receivables/hoc-instance/instance';

import { SUBJECT } from './constants/constants';
import { IPayablesProps } from './constants/types';
import usePayablesView from './usePayablesView';

import styles from './PayablesView.module.scss';

const PayablesView = ({
  carrierById,
  isLoading,
  carrierInfo,
  isInfoLoading,
  getCarrierPay,
  resetField,
  watch,
  setValue,
  control,
  pagePermissions,
  handleDownloadClick,
  handleViewFileClick,
  handlePaginationClick,
}: IPayablesProps) => {
  const {
    summary,
    count,
    authorities,
    selectedRows,
    rateList,
    setSelectedRows,
    handleDeleteRate,
    getCarrierRatings,
    handlePayBtnClick,
    handleOpenLoadView,
    setAuthoritiesState,
    handleCheckboxChange,
    handlePayAllSelectedRows,
    checkIsDisabledRowSelection,
    isDisabledPayButton,
    mode,
  } = usePayablesView({ getCarrierPay, pagePermissions, setValue, watch });
  const { copiedText, handleCopy } = useHandleCopyActive();

  return (
    <div className={styles.fullWrapper}>
      <div className={styles.payablesInfo}>
        <MainLoader isFetching={isLoading} />

        <ViewItemWrapper title={SUBJECT.CARRIER_INFO}>
          <Row gutter={[20, 10]}>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">{SUBJECT.NAME}: </Typography>
                <TooltipText data={carrierById?.companyName} subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.NAME}`} />
              </div>
            </Col>
            {carrierById?.secondPhone && (
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph">{SUBJECT.SECOND_NUMBER}: </Typography>
                  <a className="top-banner-value" href={`tel:${carrierById?.secondPhone}`}>
                    {formatPhone(carrierById?.secondPhone)}
                  </a>
                </div>
              </Col>
            )}
            {carrierById?.mc && (
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph">{SUBJECT.MC}: </Typography>
                  <TooltipText data={carrierById?.mc} subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.MC}`} />
                </div>
              </Col>
            )}

            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">{SUBJECT.CONTACT_NAME}: </Typography>
                <TooltipText
                  data={carrierById?.contactName}
                  subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.CONTACT_NAME}`}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">{SUBJECT.CARRIER_TYPE}: </Typography>
                <Typography variant="paragraph">{ICarrierType[carrierById?.type]}</Typography>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">{SUBJECT.PHONE_NUMBER}: </Typography>
                <a className="top-banner-value" href={`tel:${carrierById?.phone}`}>
                  {formatPhone(carrierById?.phone)}
                </a>
              </div>
            </Col>
            {carrierById?.fax && (
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph">{SUBJECT.FAX}: </Typography>
                  <Typography variant="paragraph">{carrierById?.fax}</Typography>
                </div>
              </Col>
            )}
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph">{SUBJECT.RATING}: </Typography>
                <Rate
                  canRate={carrierById?.accessRating}
                  averageValue={carrierById?.rating}
                  getRatings={getCarrierRatings}
                  isFirstTime={carrierById?.accessRating === RatingAccessTypes.ADD_RATING}
                  rateCount={carrierById?.ratingCount || 0}
                  rateList={rateList}
                  handleDeleteRate={handleDeleteRate}
                />
              </div>
            </Col>
          </Row>

          {!!carrierById?.cargo && (
            <>
              <Divider className={styles.dividerLast} />
              <Row gutter={[20, 15]} className={styles.rows}>
                <Col span={8}>
                  <div className={styles.headingBlock}>
                    <Typography variant="paragraph">{SUBJECT.INSURANCE_LIABILITY} </Typography>
                    <Typography variant="paragraph">{formatDateBirth(carrierById?.liability as any)}</Typography>
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.headingBlock}>
                    <Typography variant="paragraph">{SUBJECT.INSURANCE_CARGO} </Typography>
                    <Typography variant="paragraph">{formatDateBirth(carrierById?.cargo as any)}</Typography>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ViewItemWrapper>
      </div>

      <div className={styles.filterTableContainer}>
        <div className={styles.payableTableViewItems}>
          <div className={styles.filtersItems}>
            <Row justify="space-between" align="middle">
              <Col span={10}>
                <Row align="middle">
                  <Col span={13}>
                    <SearchableDropdown
                      options={authorities?.map((item: any) => ({
                        title: item?.name,
                        value: String(item?.id),
                        id: String(item?.id),
                      }))}
                      placeholder={SUBJECT.MC}
                      offset={count}
                      isMulti
                      setOffset={setAuthoritiesState}
                      columns={AUTHORITIES_CUSTOM_COLUMNS}
                      keys={AUTHORITIES_CUSTOM_KEYS}
                      control={control}
                      name="authorityIds"
                      setValue={setValue}
                      values={watch('authorityIds')}
                      withAll={false}
                      width={490}
                    />
                  </Col>
                  {!!watch('authorityIds')?.length && (
                    <Col>
                      <Button
                        icon={<ResetIcon />}
                        variant="ghost"
                        onClick={() => {
                          resetField('authorityIds');
                          setSelectedRows([]);
                        }}
                        children={SUBJECT.RESET_ALL}
                      />
                    </Col>
                  )}
                </Row>
              </Col>

              <Col span={14}>
                <Row align="middle" gutter={15} justify="end">
                  <Col>
                    <Row align="middle">
                      <Typography variant="paragraph" className={styles.totalInfo}>
                        {SUBJECT.TOTAL}:
                      </Typography>
                      <Typography variant="paragraph" className={styles.totalCount}>
                        {`${formatNumberWithThousandsSeparatorAndDecimal(
                          floatNumberFormatter(
                            String(selectedRows?.length ? summary : carrierInfo?.paid + carrierInfo?.expected)
                          ),
                          true
                        )}`}
                      </Typography>
                    </Row>
                  </Col>
                  {!selectedRows.length && (
                    <>
                      <Col>
                        <Row align="middle">
                          <Typography variant="paragraph" className={styles.totalInfo}>
                            {SUBJECT.PAID}:
                          </Typography>
                          <Typography variant="paragraph" className={styles.totalCount}>
                            {`${formatNumberWithThousandsSeparatorAndDecimal(
                              floatNumberFormatter(String(carrierInfo?.paid)),
                              true
                            )}`}
                          </Typography>
                        </Row>
                      </Col>
                      <Col>
                        <Row align="middle">
                          <Typography variant="paragraph" className={styles.totalInfo}>
                            {SUBJECT.EXPECTED}:
                          </Typography>
                          <Typography variant="paragraph" className={styles.totalCount}>
                            {`${formatNumberWithThousandsSeparatorAndDecimal(
                              floatNumberFormatter(String(carrierInfo?.expected)),
                              true
                            )}`}
                          </Typography>
                        </Row>
                      </Col>
                    </>
                  )}
                  {!!selectedRows.length && !!summary && (
                    <Col>
                      <Button onClick={handlePayAllSelectedRows} children={SUBJECT.PAY} />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <Divider />
        <div className={styles.payableTableViewItems}>
          <DataGrid
            type="payables"
            data={carrierInfo?.result}
            isDisableAllRows={
              !carrierInfo?.result?.some(
                (item: any) => item.status === CarrierStatus.CARRIER_STATUS_PENDING && !item?.haveUnacceptedTransactions
              )
            }
            withCheckbox
            handlePayBtnClick={handlePayBtnClick}
            handleDownloadClick={handleDownloadClick}
            handleViewFileClick={handleViewFileClick}
            isDisabledPayButton={isDisabledPayButton}
            handlePaginationClick={handlePaginationClick}
            onCheckboxChange={handleCheckboxChange}
            handleRowClick={handleOpenLoadView}
            isLoading={isInfoLoading}
            checkIsDisabledRowSelection={checkIsDisabledRowSelection}
            mode={mode}
            copiedText={copiedText}
            handleCopy={handleCopy}
            count={carrierInfo?.count}
          />
        </div>
      </div>
    </div>
  );
};

export default PayablesView;
