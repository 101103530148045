import { createApi } from '@reduxjs/toolkit/query/react';
import { IAgentFormData } from 'pages/profile/tabs/agent/constants/types';
import { baseQuery } from 'services/service';

import { editAgentDeserializer, inviteAgentDeserializer } from './deserializers';
import { IAgentById, IAgentTable, IAuthorityByAgentId, IGetAgentsParams } from './interfaces';
import { getAgentById, getAgentListSerializer, getAuthoritiesByAgentId } from './serializers';

export const agentsAPI = createApi({
  reducerPath: 'agentsAPI',
  baseQuery,
  tagTypes: ['Agents'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getAgents: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<IGetAgentsParams>) => {
        return {
          url: 'agents',
          params: {
            search,
            field,
            skip,
            limit,
            order,
            orderBy,
            ...(filter?.officeId ? { officeId: filter?.officeId } : {}),
            ...(filter?.branchId ? { branchId: filter?.branchId } : {}),
            ...(filter?.type ? { type: filter?.type } : {}),
          },
        };
      },
      transformResponse: (body: IAgentTable) => getAgentListSerializer(body),
      providesTags: ['Agents'],
    }),
    getAgentsAutocomplete: builder.query({
      query: ({ search, skip, limit, branchId, officeId, excludeOwners, filter }: Partial<IGetAgentsParams>) => ({
        url: 'agents/autocomplete',
        params: { search, skip, limit, branchId, officeId, excludeOwners, ...filter },
      }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            firstName: el.firstName,
            value: String(el.id),
            lastName: el.lastName,
            username: el.username,
            officeName: el.officeName,
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      providesTags: ['Agents'],
    }),

    getAgent: builder.query({
      query: id => `agents/${id}`,
      providesTags: ['Agents'],
      transformResponse: (body: { result: IAgentById }) => getAgentById(body),
    }),
    updateAgent: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `agents/${id}`,
        method: 'PUT',
        body: editAgentDeserializer(rest),
      }),
    }),
    inviteAgent: builder.mutation({
      query: (body: IAgentFormData) => ({
        url: `agents/invite`,
        method: 'POST',
        body: inviteAgentDeserializer(body),
      }),
      invalidatesTags: ['Agents'],
    }),
    inviteAgentAgain: builder.mutation({
      query: (id: string | number) => ({
        url: `agents/invite/again/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Agents'],
    }),
    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `agents/${id}/status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: ['Agents'],
    }),
    getAuthoritiesByAgentId: builder.query({
      query: ({ id, search, skip, limit, order, orderBy, filter }) => ({
        url: `agents/${id}/authorities`,
        params: { search, skip, limit, order, orderBy, ...filter, id },
      }),
      providesTags: ['Agents'],
      transformResponse: (body: { result: IAuthorityByAgentId[]; count: number }) => getAuthoritiesByAgentId(body),
    }),
    addAuthorityToAgent: builder.mutation({
      query: ({ id, authorityId }) => ({
        url: `agents/${id}/authority/${authorityId}`,
        method: 'POST',
      }),
    }),
    addNewFileToAgent: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `users/file`,
        method: 'POST',
        body: { userId: Number(id), fileId: Number(fileId) },
      }),
    }),
    deleteFileFromAgent: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `users/file`,
        method: 'DELETE',
        body: { userId: Number(id), fileId: Number(fileId) },
      }),
    }),
    removeAuthorityFromAgent: builder.mutation({
      query: ({ id, authorityId }) => ({
        url: `agents/${id}/authority/${authorityId}`,
        method: 'DELETE',
      }),
    }),
    checkUserName: builder.query({
      query: ({ username, id }) => ({
        url: `users/username/${username}`,
        ...(id ? { params: { id } } : {}),
      }),
      transformResponse: (body: { result: boolean }) => body.result,
    }),
  }),
});

export const {
  useGetAgentsQuery,
  useGetAgentQuery,
  useUpdateAgentMutation,
  useAddAuthorityToAgentMutation,
  useChangeStatusMutation,
  useInviteAgentMutation,
  useRemoveAuthorityFromAgentMutation,
  useGetAuthoritiesByAgentIdQuery,
  useCheckUserNameQuery,
  useAddNewFileToAgentMutation,
  useDeleteFileFromAgentMutation,
  useInviteAgentAgainMutation,
  useGetAgentsAutocompleteQuery,
} = agentsAPI;
