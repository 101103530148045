import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Popover, Row } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';
import { AlignType } from 'rc-table/lib/interface';
import {
  BrokerPaymentTermsType,
  BrokerPaymentTermsTypeTable,
  CarrierFactoringType,
  CarrierPaymentTermFileTypesTexts,
  CarrierPaymentTermTypeText,
  CarrierPaymentType,
} from 'services/profile/carriers/interfaces';
import Typography from 'ui/typography/Typography';
import {
  getFormattedDateForTypography,
  handleDownloadClick,
  handleViewFileClick,
  multiplyDownloadWithDelay,
  multiplyDownloadWithDelayLocal,
  multiplyViewWithDelay,
  multiplyViewWithDelayLocal,
} from 'utils/helpers';

import { useDetectedParams } from '../../../hooks/useDetectedParams';
import FactoredPopover from '../../../pages/profile/tabs/carriers/paymentTerms/hoc-instance/InfoPopOver';
import { selectUserInfo } from '../../../store/user-slice/selector';
import { BlueInfoIcon } from '../../svgs/BlueInfoIcon';
import DownloadIcon from '../../svgs/DownloadIcon';
import { EditPenIcon } from '../../svgs/EditPenIcon';
import EyeIconSvg from '../../svgs/EyeIconSvg';
import { TrashIcon } from '../../svgs/TrashIcon';
import TooltipText from '../../tooltip-text/TooltipText';

import { SUBJECT } from './constants';
import { ICarrierData, IUsePaymentTermDataGridProps } from './types';

import styles from '../PaymentTermDataGrid.module.scss';

export const getPaymentTermColumns = ({
  data,
  handleRowDelete,
  handleRowEdit,
  setEditingRow,
  localFiles,
  serializedStates,
  withoutAction,
}: IUsePaymentTermDataGridProps): ColumnsType<ICarrierData> => {
  const { searchParams } = useDetectedParams();
  const userInfo = useSelector(selectUserInfo);

  const { mode, isAproveMode, activePage, hash } = searchParams;

  const DirectDepositPopover = ({ data }: any) => {
    if (data?.factoringType === CarrierFactoringType.FACTORED) return null;
    return (
      <Col>
        <Popover
          overlayClassName={styles.popoverOverlay}
          placement="top"
          overlayStyle={{
            width: 300,
          }}
          content={
            <Row align="middle" gutter={[8, 24]}>
              <Col span={24}>
                <Row align="middle" gutter={8}>
                  <Col span={12}>
                    <Typography variant="heading" children={SUBJECT.BANK_NAME} className={styles.infoHeading} />
                  </Col>
                  <Col>
                    <Typography variant="paragraph" children={data?.paymentBankName} className={styles.infoParagraph} />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align="middle" gutter={8}>
                  <Col span={12}>
                    <Typography variant="heading" children={SUBJECT.ROUTING_NUMBER} className={styles.infoHeading} />
                  </Col>
                  <Col>
                    <Typography
                      variant="paragraph"
                      children={data?.paymentRoutingNumber}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align="middle" gutter={8}>
                  <Col span={12}>
                    <Typography variant="heading" children={SUBJECT.ACCOUNT_NUMBER} className={styles.infoHeading} />
                  </Col>
                  <Col>
                    <Typography
                      variant="paragraph"
                      children={data?.paymentAccountNumber}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          <div className={styles.popoverIcon}>
            <BlueInfoIcon />
          </div>
        </Popover>
      </Col>
    );
  };
  const FuelCardPopover = ({ data }: any) => (
    <Col>
      <Popover
        overlayClassName={styles.popoverOverlay}
        placement="top"
        overlayStyle={{
          width: 300,
        }}
        content={
          <Col span={24}>
            <Row align="middle" gutter={8}>
              <Col span={7}>
                <Typography variant="heading" children={SUBJECT.FUEL_CARD} className={styles.infoHeading} />
              </Col>
              <Col>
                <Typography variant="paragraph" children={data?.paymentFuelCard} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
        }
      >
        <div className={styles.popoverIcon}>
          <BlueInfoIcon />
        </div>
      </Popover>
    </Col>
  );

  const PaperCheckPopover = ({ data }: any) => {
    if (data?.factoringType === CarrierFactoringType.FACTORED) return null;
    return (
      <Col>
        <Popover
          overlayClassName={styles.popoverOverlay}
          placement="top"
          overlayStyle={{
            width: 725,
          }}
          content={
            <>
              <Row align="middle" gutter={[8, 24]}>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.PAYMENT_COMPANY_NAME}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography
                        variant="paragraph"
                        children={data?.paymentCompanyName}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography variant="paragraph" children={data?.paymentCity} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography
                        variant="paragraph"
                        children={data?.paymentAddress}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography
                        variant="paragraph"
                        children={serializedStates?.[data?.paymentStateId] || ''}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
                {!!data?.paymentSecondAddress && (
                  <Col span={12}>
                    <Row align="middle" gutter={8}>
                      <Col span={12}>
                        <Typography
                          variant="heading"
                          children={SUBJECT.SECOND_ADDRESS}
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col span={12}>
                        <Typography
                          variant="paragraph"
                          children={data?.paymentSecondAddress}
                          className={styles.infoParagraph}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col span={12}>
                  <Row align="middle" gutter={8}>
                    <Col span={12}>
                      <Typography variant="heading" children={SUBJECT.ZIP} className={styles.infoHeading} />
                    </Col>
                    <Col span={12}>
                      <Typography variant="paragraph" children={data?.paymentZip} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          }
        >
          <div className={styles.popoverIcon}>
            <BlueInfoIcon />
          </div>
        </Popover>
      </Col>
    );
  };

  const FactoredPopoverContainer = React.memo(({ data }: any) => {
    return <FactoredPopover data={data?.factoringCompanyById?.result} size="22" />;
  });

  const filesRender = (_: string, currentData: any) => {
    const fileInfoModified = [...(currentData.showFiles || []), ...(currentData.showAgreement || [])];
    const fileModified = fileInfoModified?.map(el => el.uid);

    const types: any = { add: {}, edit: {} };
    fileInfoModified?.forEach((el: any) => (types.add[el?.uid] = Number(el?.fileType)));

    const onlyCurrentFiles = localFiles.filter((el: any) => fileModified?.includes(el.uid));

    const fileInfoModifiedIds = [...(currentData.showFiles || []), ...(currentData.showAgreement || [])];
    const fileModifiedIds = fileInfoModifiedIds?.map(el => el.id);

    fileInfoModifiedIds?.forEach((el: any) => (types.edit[el?.id] = Number(el?.fileType)));
    const files: any = {
      combinedFiles: [],
    };
    data?.forEach((el: any) => {
      files.combinedFiles = [
        ...(files.combinedFiles || []),
        ...(el.showFiles || []),
        ...(el.showAgreement || []),
      ]?.filter(el => fileModifiedIds?.includes(el.id));
    });

    const infoData = mode === 'add' || isAproveMode === 'true' ? onlyCurrentFiles : files?.combinedFiles;

    return (
      <div className={styles.actionBlock}>
        <div className={styles.actionsWrapper}>
          <button
            type="button"
            onClick={() =>
              mode === 'add' || isAproveMode === 'true'
                ? multiplyViewWithDelayLocal(onlyCurrentFiles)
                : multiplyViewWithDelay(files?.combinedFiles)
            }
          >
            <EyeIconSvg />
          </button>
          <button
            type="button"
            onClick={() =>
              mode === 'add' || (isAproveMode === 'true' && activePage !== 'carrierOnBoard')
                ? multiplyDownloadWithDelayLocal(onlyCurrentFiles)
                : multiplyDownloadWithDelay(files?.combinedFiles)
            }
          >
            <DownloadIcon />
          </button>
          <Col>
            <Popover
              overlayClassName={styles.popoverOverlay}
              placement="topLeft"
              content={
                <>
                  <Row align="middle">
                    {infoData?.map((el: any) => {
                      return (
                        <Col span={24}>
                          <Row align={'middle'} gutter={[0, 10]} justify={'space-between'}>
                            <Col span={4}>
                              <Typography className={styles.infoHeading} variant="paragraph">
                                {CarrierPaymentTermFileTypesTexts?.[
                                  types?.[mode === 'view' ? 'edit' : mode]?.[el?.uid || el?.id]
                                ] || CarrierPaymentTermFileTypesTexts[el.fileType]}
                              </Typography>
                            </Col>

                            <Col span={3}>
                              <div className={clsx(styles.actionsWrapper, styles.tooltipActionsWrapper)}>
                                <button
                                  type="button"
                                  onClick={() => {
                                    mode === 'add' || isAproveMode === 'true'
                                      ? multiplyViewWithDelayLocal([el])
                                      : handleViewFileClick(el.preview);
                                  }}
                                >
                                  <EyeIconSvg />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    mode === 'add' || isAproveMode === 'true'
                                      ? multiplyDownloadWithDelayLocal([el])
                                      : handleDownloadClick(el.download);
                                  }}
                                >
                                  <DownloadIcon />
                                </button>
                              </div>
                            </Col>
                            {!!(userInfo.firstName && el.addedBy) && (
                              <Col span={8}>
                                <Row align="middle">
                                  <Col span={24}>
                                    <Typography variant="paragraph" className={styles.infoParagraph}>
                                      {SUBJECT.ADDED_BY} &nbsp;{' '}
                                      {mode === 'add' || isAproveMode === 'true'
                                        ? `${userInfo.firstName} ${userInfo.lastName}`
                                        : `${el?.createdBy}`}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            <Col span={6}>
                              <Row align="middle">
                                <Col span={24}>
                                  <Typography className={styles.infoParagraph} variant="paragraph">
                                    {getFormattedDateForTypography(el)}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={6}>
                              <TooltipText
                                data={el.fileName || el.name}
                                prefixClass={styles.infoParagraph}
                                subject={SUBJECT.ADDED_BY}
                              />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              }
            >
              <div className={styles.popoverIcon}>
                <BlueInfoIcon />
              </div>
            </Popover>
          </Col>
        </div>
      </div>
    );
  };
  const actionRender = (_: string, record: ICarrierData) => (
    <div className={styles.actionBlock}>
      <div
        onClick={() => {
          setEditingRow(record.id);
          handleRowEdit(record);
        }}
        className={styles.trashIcon}
      >
        <EditPenIcon />
      </div>
      {
        <div onClick={() => handleRowDelete!(record)} className={clsx(styles.trashIcon)}>
          <TrashIcon />
        </div>
      }
    </div>
  );
  const emailRender = (_: string, record: ICarrierData) => {
    return (
      <Row onClick={e => e.stopPropagation()} key={record.id}>
        {Array.isArray(record.brokerEmails) &&
          record.brokerEmails?.map(
            (el: any, index: number) =>
              index < 1 && (
                <Col key={index}>
                  <Typography variant="paragraph" className={styles.emailBlock}>
                    {el}
                    {index !== record.brokerEmails.length - 1 && <span style={{ marginRight: '2px' }}>,</span>}
                  </Typography>
                </Col>
              )
          )}
        {Array.isArray(record.brokerEmails) && record.brokerEmails.length > 1 && (
          <Popover
            placement="top"
            content={record.brokerEmails.slice(1)?.map((el: any, index: number) => (
              <Col key={index}>
                <Row align="middle" gutter={8}>
                  <Typography variant="paragraph" className={styles.emailBlock}>
                    {el}
                  </Typography>
                </Row>
              </Col>
            ))}
          >
            <a>{`+${record.brokerEmails.length - 1}`}</a>
          </Popover>
        )}
      </Row>
    );
  };
  const CURRENT_COLUMNS = [
    {
      title: 'MC',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      render: (record: any, values: any) => {
        return (
          <Row align="middle" gutter={10}>
            <Col>
              {record === CarrierPaymentTermTypeText.FreightMax
                ? CarrierPaymentTermTypeText[record]
                : values?.authorityId?.[0]?.name}
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Factoring',
      dataIndex: 'factoringType',
      key: 'factoringType',
      width: 120,
      render: (record: any, data: any) => {
        if ((data.type === 2 && hash) || record === CarrierFactoringType.NOT_SPECIFIED) {
          return '';
        }
        return record === CarrierFactoringType.FACTORED ? (
          <Row align="middle" gutter={10}>
            <Col>{SUBJECT.FACTORED}</Col>{' '}
            {data?.factoringCompanyById?.result && <FactoredPopoverContainer data={data} />}
          </Row>
        ) : (
          <Row align="middle" gutter={10}>
            <Col>{SUBJECT.NOT_FACTORED}</Col>
          </Row>
        );
      },
    },
    {
      title: 'Payment Method',
      key: 'paymentType',
      dataIndex: 'paymentType',
      width: 150,
      render: (record: any, data: any) => {
        if ((data.type === 2 && hash) || record === CarrierPaymentType.NOT_SPECIFIED) {
          return '';
        }
        return record === CarrierPaymentType.PAPER_CHECK ? (
          <Row align="middle" gutter={10}>
            <Col>{SUBJECT.PAPER_CHECK}</Col> <PaperCheckPopover data={data} />
          </Row>
        ) : record === CarrierPaymentType.DIRECT_DEPOSIT ? (
          <Row align="middle" gutter={10}>
            <Col>{SUBJECT.DIRECT_DEPOSIT}</Col> <DirectDepositPopover data={data} />
          </Row>
        ) : (
          <Row align="middle" gutter={10}>
            <Col>{SUBJECT.FUEL_CARD}</Col> <FuelCardPopover data={data} />
          </Row>
        );
      },
    },
    {
      title: 'Payment Terms',
      key: 'brokerPaymentTermType',
      dataIndex: 'brokerPaymentTermType',
      width: 120,
      render: (record: any, data: any) => {
        if (data.type === 2 && hash) {
          return '';
        }
        return (
          <Row align="middle" gutter={10}>
            <Col>{BrokerPaymentTermsTypeTable[record]}</Col>
          </Row>
        );
      },
    },
    {
      title: 'Day',
      key: 'day',
      dataIndex: 'day',
      width: 80,
      render: (record: any, data: any) => {
        if ((data.type === 2 && hash) || data.brokerPaymentTermType === BrokerPaymentTermsType.NOT_SPECIFIED) {
          return '';
        }
        return <span>{data.day}</span>;
      },
    },
    {
      title: 'Percent',
      key: 'percent',
      dataIndex: 'percent',
      width: 100,
      render: (record: any, data: any) => {
        if ((data.type === 2 && hash) || data.brokerPaymentTermType === BrokerPaymentTermsType.NOT_SPECIFIED) {
          return '';
        }
        return <span>{data.percent}</span>;
      },
    },
    {
      title: 'Email',
      key: 'brokerEmails',
      dataIndex: 'brokerEmails',
      width: 200,
      render: emailRender,
    },
    ...(hash
      ? []
      : [
          {
            title: 'Documents',
            id: 'documents',
            dataIndex: 'documents',
            width: 150,
            render: (record: any, data: any) => {
              if ((data.type === 2 && hash) || data.brokerPaymentTermType === BrokerPaymentTermsType.NOT_SPECIFIED) {
                return '';
              }
              return filesRender(record, data);
            },
          },
        ]),
    ...(!withoutAction
      ? [
          {
            title: 'Action',
            id: 'action',
            dataIndex: 'action',
            align: 'right' as AlignType,
            width: 100,
            render: actionRender,
          },
        ]
      : []),
  ];

  return CURRENT_COLUMNS;
};
