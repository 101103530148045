import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useDeleteAuhtorityFromBlackListMutation } from 'services/profile/customers/customers';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

export const useCustomerBlackListView = () => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const [deleteAuthorityFromBlackList] = useDeleteAuhtorityFromBlackListMutation();
  const dispatch = useDispatch();

  const handleCustomerUnlockClick = (rowId: string, rowData: any) => {
    dispatch(
      openModal({
        title: 'Are you sure want to Unlock?',
        okText: 'Unlock',
        cancelText: 'Cancel',
        onOk: () => {
          deleteAuthorityFromBlackList({ id: rowData?.customerId, authorityId: rowData?.authorityId }).then(
            (data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: 'Successfully unlocked',
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                setSearchParams({
                  ...searchParams,
                  id: '',
                  open: 'false',
                  mode: 'view',
                });
              }
            }
          );
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  return { handleCustomerUnlockClick };
};
