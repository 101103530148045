import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import clsx from 'clsx';
import { ITransactionTypes, SUBJECT, TRANSACTION_TYPES } from 'pages/loads/components/edit-load/constants/constants';
import { transactionsForDataGrind } from 'pages/loads/components/edit-load/constants/helpers';
import { LoadCancelStatus } from 'pages/loads/components/edit-load/constants/types';
import AutoRateConfirmation from 'pages/loads/tabs/loads-tab/components/auto-rate-confirmation/AutoRateConfirmation';
import TonuBanner from 'pages/loads/tabs/loads-tab/components/loads-comment/components/tonu-banner/TonuBanner';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Button from 'ui/button/Button';
import { LoadStatus } from 'ui/load-status/constants/types';
import LoadStatusComponent from 'ui/load-status/LoadStatus';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';
import {
  floatNumberFormatter,
  floatNumberFormatterWithMinus,
  formatNumberWithThousandsSeparatorAndDecimal,
  multiplyDownloadWithDelay,
} from 'utils/helpers';

import BillingBlock from 'components/billing-block/BillingBlock';
import { PaymentTermType, PaymentTermTypeText } from 'components/billing-block/constants/types';
import CarrierPaymentBreakdown from 'components/carrier-payment-breakdown/CarrierPaymentBreakdown';
import CustomComment from 'components/custom-comment/CustomComment';
import DollarIcon from 'components/svgs/DollarIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import TransactionsTable from 'components/transactions-table/TransactionsTable';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { AUTHORITY_KEYS, MY_AUTHORITY_COLUMNS } from '../constants/constants';
import { Input, SearchableDropdown, Switch, TransactionInput, TransactionSelect } from '../hoc-instance/instanse';

import useTonuEdit from './useTonuEdit';

import styles from './TonuEdit.module.scss';

const TonuLoadEdit = ({
  data,
  loadTransactions,
  loadFiles,
  comments,
  createComment,
  deleteComment,
  getMoreComments,
  isFilesFetching,
  isShowMoreVisible,
  refetchTransaction,
  refetchLoadById,
  refetchFilesByLoadId,
  activeSection,
  loadsPagePermissions,
  roleLevel,
  isFetching,
  rateList,
  handleCarrierRate,
  getCarrierRatings,
  handleDeleteRate,
  isLoadsCommentsLoading,
  handleCreateRateConfirmation,
  rateConfirmations,
  createRateConfCheck,
  handleSendRateConfirmation,
  refetchRateConfirmations,
}: any) => {
  const {
    loadHistory,
    getMoreNotes,
    isShowMoreVisibleNotes,
    authorities,
    setAuthoritiesState,
    allFiles,
    beforeUploadForEdit,
    handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    transactioControl,
    transactionSetValue,
    transactionWatch,
    transactionTrigger,
    handleSwitchMarkAsClaim,
    handleCancelLoad,
    addTransactionCheck,
    control,
    watch,
    errors,
    setValue,
    isSaveButtonVisible,
    handleCancel,
    handleSave,
    handleCreateTransaction,
    handleDeleteTransaction,
    isSendedToBilling,
    handleAcceptToBilling,
    handleRejectBilling,
    dirtyFields,
    handleCreateInvoice,
    handleDeleteInvoice,
    isSendToBillingDisabled,
    handleSendToBilling,
    resetField,
    isLoadHasCustomer,
    isTransactionValidBtn,
    handleResetTransactionForm,
    transactionDirtyFields,
    mailableFiles,
    isInvoiceLoading,
    isCreateTransactionLoading,
    userId,
    isFetchingHistory,
    isDisableSoldUs,
    isDisableReference,
    isDisableAuthority,
    breakdowns,
    isBreakdownLoading,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    breakdownParams,
    drawerRef,
    setIsDefaultHistory,
    isDefaultHistory,
    handleScrollToHistory,
  } = useTonuEdit({
    data,
    loadFiles,
    isFilesFetching,
    isFetching,
    refetchLoadById,
    refetchTransaction,
    refetchRateConfirmations,
    refetchFilesByLoadId,
    loadsPagePermissions,
  });
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const hasAccessToViewHistory =
    userType === UserTypes.ADMIN || (employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.view);

  const saveButtonCheck = () => {
    if (watch('soldUsSwitch')) {
      return !!watch('allAuthorities').length;
    }
    if (Object?.keys(dirtyFields)?.includes('referenceNumber') && !watch('soldUsSwitch')) {
      return true;
    } else {
      return !!watch('allAuthorities').length;
    }
  };

  const carrierLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.carrierAmountPaid && data?.finance?.carrierAmountPaid) ||
      (data?.finance?.carrierBalance && data?.finance?.carrierBalance) ||
      (data?.finance?.carrierFinalPayable && data?.finance?.carrierFinalPayable) ||
      (data?.finance?.carrierInitialRate && data?.finance?.carrierInitialRate) ||
      (data?.finance?.carrierPaymentTermGeneratedAmount && data?.finance?.carrierPaymentTermGeneratedAmount)
    );
  }, [
    data?.finance?.carrierAmountPaid,
    data?.finance?.carrierBalance,
    data?.finance?.carrierFinalPayable,
    data?.finance?.carrierInitialRate,
    data?.finance?.carrierPaymentTermGeneratedAmount,
  ]);

  const customerLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.customerAmountPaid && data?.finance?.customerAmountPaid !== '0') ||
      (data?.finance?.customerBalance && data?.finance?.customerBalance !== '0') ||
      (data?.finance?.customerFinalReceivable && data?.finance?.customerFinalReceivable !== '0') ||
      (data?.finance?.customerInitialRate && data?.finance?.customerInitialRate !== '0')
    );
  }, [
    data?.finance?.customerAmountPaid,
    data?.finance?.customerBalance,
    data?.finance?.customerFinalReceivable,
    data?.finance?.customerInitialRate,
  ]);

  return (
    <form className={styles.tonuLoadForm}>
      <MainLoader isFetching={isFetching} />
      <TonuBanner
        data={data}
        control={control}
        tonuWatch={watch}
        handleSwitchChange={handleSwitchMarkAsClaim}
        activeSection={activeSection}
        withActions={data?.load?.status !== LoadCancelStatus.Canceled}
        handleCancelLoad={handleCancelLoad}
        hasClaimPermission={loadsPagePermissions.claim}
        hasDeClaimPermission={loadsPagePermissions.resolve}
        hasCancelPermission={loadsPagePermissions.cancel}
        rateList={rateList}
        handleCarrierRate={handleCarrierRate}
        getCarrierRatings={getCarrierRatings}
        handleDeleteRate={handleDeleteRate}
        loadHistory={loadHistory}
        hasAccessToViewHistory={hasAccessToViewHistory}
        handleScrollToHistory={() => handleScrollToHistory('history')}
        handleCommentClick={() => handleScrollToHistory('comment')}
      />
      <ViewItemWrapper
        className={activeSection === SUBJECT.GENERAL_INFORMATION_ID ? 'active' : ''}
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        id="general-information"
        title={SUBJECT.EDIT_REGULAR_LOAD}
        Component={
          <LoadStatusComponent
            loadStatus={Number(data?.load?.status)}
            carrierStatus={Number(data?.load?.carrierStatus)}
            customerStatus={Number(data?.load?.customerStatus)}
            isProcessing={data?.load?.processing || false}
            withoutLoadsText
          />
        }
      >
        <Row align="middle" className={styles.topBannerBlock}>
          <Col span={12}>
            <Typography variant="heading" children={SUBJECT.GENERAL_INFO} className={styles.generalInfoHeading} />
          </Col>
          {data?.load?.customer?.id && (
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <div className={styles.takenAsBlock}>
                    <Typography variant="paragraph">{`${SUBJECT.TAKEN_AS_MC} `}</Typography>
                    <span>{data?.load?.takenAsAuthority?.name}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="load_type"
              control={control}
              type="text"
              placeholder={SUBJECT.LOAD_TYPE}
              value={watch('load_type')}
              defaultValue={watch('load_type')}
              required
              disabled
            />
          </Col>
          <Col span={8} />
          <Col span={8} />
          {data?.load?.customer?.id && (
            <>
              <Col span={8}>
                <Input
                  type="text"
                  name="customerName"
                  value={watch('customerName')}
                  defaultValue={watch('customerName')}
                  control={control}
                  placeholder={SUBJECT.CUSTOMER_NAME}
                  required
                  disabled
                />
              </Col>
              <Col span={8}>
                <Input
                  suffix={<DollarIcon />}
                  control={control}
                  name="customerAmount"
                  value={watch('customerAmount')}
                  defaultValue={watch('customerAmount')}
                  placeholder={SUBJECT.CUSTOMER_AMOUNT}
                  required
                  disabled
                />
              </Col>
              <Col span={8}>
                <Input
                  name="referenceNumber"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.REFERENCE_NUMBER}
                  getValues={watch}
                  errors={errors}
                  onChange={(e: any) =>
                    setValue('referenceNumber', e.target.value.trimStart(), {
                      shouldDirty: true,
                      shouldValidate: true,
                      shouldTouch: true,
                    })
                  }
                  required
                  disabled={isDisableReference()}
                />
              </Col>

              <Col span={8}>
                <Input
                  name="takenUsAuthority"
                  control={control}
                  type="text"
                  value={watch('takenUsAuthority')}
                  defaultValue={watch('takenUsAuthority')}
                  placeholder={SUBJECT.TAKEN_AS}
                  required
                  disabled
                />
              </Col>
              <Col span={8}>
                <Input
                  name="customerContactName"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.CONTACT_NAME}
                  value={watch('customerContactName')}
                  required
                  disabled
                />
              </Col>
              <Col span={8}>
                <Input
                  name="customerContact"
                  control={control}
                  value={watch('customerContact')}
                  defaultValue={watch('customerContact')}
                  placeholder={SUBJECT.CUSTOMER_CONTACT}
                  required
                  disabled
                />
              </Col>
            </>
          )}
          {data?.load?.carrier?.id && (
            <>
              <Col span={8}>
                <Input
                  placeholder={SUBJECT.CARRIER}
                  name="carrier"
                  control={control}
                  value={watch('carrier')}
                  defaultValue={watch('carrier')}
                  required
                  disabled
                />
              </Col>
              {data?.load?.truck && (
                <Col span={8}>
                  <Input
                    name="truck"
                    value={watch('truck')}
                    defaultValue={watch('truck')}
                    control={control}
                    placeholder={SUBJECT.TRUCK}
                    required
                    disabled
                  />
                </Col>
              )}
              <Col span={8}>
                <Input
                  control={control}
                  suffix={<DollarIcon />}
                  name="carrierAmount"
                  placeholder={SUBJECT.CARRIER_AMOUNT}
                  value={formatNumberWithThousandsSeparatorAndDecimal(watch('carrierAmount'))}
                  defaultValue={watch('carrierAmount')}
                  required
                  disabled
                />
              </Col>
            </>
          )}

          <Col span={24}>
            <Row gutter={[18, 40]}>
              <Col>
                <Typography variant="heading" children={SUBJECT.SOLD_US_SWITCH} className={styles.switchHeading} />
              </Col>
              <Col>
                <Switch
                  name="soldUsSwitch"
                  checked={watch('soldUsSwitch')}
                  control={control}
                  onChange={() => {
                    setValue('soldUsSwitch', !watch('soldUsSwitch'), {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    resetField('allAuthorities');
                  }}
                  disabled={isDisableSoldUs()}
                />
              </Col>
            </Row>
          </Col>

          {watch('soldUsSwitch') && (
            <Col span={8}>
              <SearchableDropdown
                options={authorities?.result}
                columns={MY_AUTHORITY_COLUMNS}
                keys={AUTHORITY_KEYS}
                placeholder={SUBJECT.ALL_AUTHORITIES}
                name="allAuthorities"
                control={control}
                setValue={setValue}
                values={watch('allAuthorities')}
                offset={authorities?.count}
                setOffset={setAuthoritiesState}
                showKey="name"
                required
                rules={{ required: true }}
                disabled={isDisableAuthority() || !!data?.load?.rateConfirmationSentAt}
                width={500}
              />
            </Col>
          )}
          {isSaveButtonVisible && (
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Button children="Cancel" variant="text" onClick={handleCancel} />
                </Col>
                <Col>
                  <Button children="Save" disabled={!saveButtonCheck()} onClick={handleSave} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper
        id="loads-finance"
        title={SUBJECT.LOAD_FINANCE}
        className={activeSection === SUBJECT.TONU_LOAD_FINANCE_ID ? 'active' : ''}
        canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
      >
        {isFetching ? (
          <div className={styles.spinWrapper}>
            <Spin />
          </div>
        ) : (
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Row gutter={[50, 0]}>
                {customerLoadFinanceCheck && (
                  <Col span={8}>
                    <Typography variant="heading" children={SUBJECT.CUSTOMER} className={styles.financeTitle} />
                  </Col>
                )}
                <Col span={8}>
                  <Typography variant="heading" children={SUBJECT.AGENT} className={styles.financeTitle} />
                </Col>
                {carrierLoadFinanceCheck && (
                  <Col span={8}>
                    <Typography variant="heading" children={SUBJECT.CARRIER} className={styles.financeTitle} />
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={50}>
                {customerLoadFinanceCheck && (
                  <Col className={styles.borderedColumn} span={8}>
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.CUSTOMER_BALANCE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerBalance,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.INIT_RATE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerInitialRate,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {data?.load?.paymentTermType !== PaymentTermType.BILLING_TYPES_FACTORING && (
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={
                                  PaymentTermTypeText[data?.load?.paymentTermType as keyof typeof PaymentTermTypeText]
                                }
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerPaymentTermGeneratedAmount,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.FINAL_RECEIVABLE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerFinalReceivable,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.AMOUNT_PAID_CUSTOMER}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.customerAmountPaid,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col className={styles.borderedColumn} span={8}>
                  <Row gutter={[0, 24]}>
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.AGENT_BALANCE}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.agentBalance,
                              true
                            )} `}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col span={12}>
                          <Typography
                            variant="paragraph"
                            className={styles.infoHeading}
                            children={SUBJECT.FINAL_PROFIT}
                          />
                        </Col>
                        <Col>
                          <Typography
                            variant="paragraph"
                            className={styles.infoParagraph}
                            children={`${formatNumberWithThousandsSeparatorAndDecimal(
                              data?.finance?.agentFinalProfit,
                              true
                            )} `}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                {carrierLoadFinanceCheck && (
                  <Col className={styles.borderedColumn} span={8}>
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.CARRIER_BALANCE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierBalance,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.INIT_RATE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierInitialRate,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {!!Number(data?.finance?.carrierPaymentTermGeneratedAmount) && (
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.QUICK_PAY}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierPaymentTermGeneratedAmount,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.FINAL_PAYABLE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierFinalPayable,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.AMOUNT_PAID}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.carrierAmountPaid,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </ViewItemWrapper>
      {loadsPagePermissions.transaction ? (
        <ViewItemWrapper
          title={SUBJECT.TRANSACTION}
          Component={
            <>
              {!!Object.keys(transactionDirtyFields)?.length && (
                <Col>
                  <Button
                    icon={<ResetIcon />}
                    variant="ghost"
                    children={SUBJECT.RESET}
                    onClick={handleResetTransactionForm}
                  />
                </Col>
              )}
            </>
          }
          className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          id="transaction"
        >
          <Row gutter={[10, 30]}>
            <Col span={8}>
              <TransactionSelect
                name="transactionType"
                control={transactioControl}
                options={TRANSACTION_TYPES}
                onBlur={() =>
                  setTimeout(() => {
                    transactionTrigger('transactionType');
                    if (transactionWatch('customer')?.length) {
                      transactionTrigger('customer');
                    }
                    if (transactionWatch('carrierTransaction')?.length) {
                      transactionTrigger('carrierTransaction');
                    }
                  }, 500)
                }
                placeholder={SUBJECT.TRANSACTION_TYPE}
                required
              />
            </Col>
            {data?.load?.customer?.id && (
              <Col span={data?.load?.carrier?.id ? 5 : 8}>
                <TransactionInput
                  suffix={<DollarIcon />}
                  name="customer"
                  control={transactioControl}
                  getValues={transactionWatch}
                  onChange={e => {
                    transactionSetValue(
                      'customer',
                      Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                        Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                        ? floatNumberFormatter(e.target.value)
                        : floatNumberFormatterWithMinus(e.target.value),
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                      }
                    );
                    setTimeout(() => {
                      transactionTrigger('customer');
                    }, 0);
                  }}
                  placeholder={SUBJECT.CUSTOMER}
                  value={
                    transactionWatch('customer')
                      ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('customer'))
                      : ''
                  }
                  withRedMinus
                />
              </Col>
            )}
            {data?.load?.carrier?.id && (
              <Col span={data?.load?.customer?.id ? 5 : 8}>
                <TransactionInput
                  suffix={<DollarIcon />}
                  name="carrierTransaction"
                  control={transactioControl}
                  getValues={transactionWatch}
                  onChange={e => {
                    transactionSetValue(
                      'carrierTransaction',
                      Number(transactionWatch('transactionType')) === ITransactionTypes.Lumper ||
                        Number(transactionWatch('transactionType')) === ITransactionTypes.Miscellaneous
                        ? floatNumberFormatter(e.target.value)
                        : floatNumberFormatterWithMinus(e.target.value),
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                      }
                    );
                    setTimeout(() => {
                      transactionTrigger('carrierTransaction');
                    }, 0);
                  }}
                  placeholder={SUBJECT.CARRIER}
                  value={
                    transactionWatch('carrierTransaction')
                      ? formatNumberWithThousandsSeparatorAndDecimal(transactionWatch('carrierTransaction'))
                      : ''
                  }
                  withRedMinus
                />
              </Col>
            )}
            <Col span={data?.load?.customer?.id && data?.load?.carrier?.id ? 6 : 8}>
              <TransactionInput
                suffix={<DollarIcon />}
                name="balanceAmount"
                getValues={transactionWatch}
                control={transactioControl}
                rules={{ required: true }}
                placeholder={SUBJECT.BALANCE_AMOUNT}
                value={String(transactionWatch('balanceAmount'))}
                disabled
                withRedMinus
              />
            </Col>
            <Col span={24}>
              <TransactionInput
                type="text"
                control={transactioControl}
                name="transactionComment"
                getValues={transactionWatch}
                placeholder={SUBJECT.WRITE_COMMENT}
                onChange={(e: any) => transactionSetValue('transactionComment', e.target.value.trimStart())}
              />
            </Col>

            {loadsPagePermissions.transaction && (
              <Col span={24}>
                <Row justify="end">
                  <Button
                    variant="default"
                    children={SUBJECT.ADD_TRANSACTION}
                    disabled={addTransactionCheck() || !isTransactionValidBtn || isCreateTransactionLoading}
                    onClick={handleCreateTransaction}
                  />
                </Row>
              </Col>
            )}
            {!!transactionsForDataGrind(loadTransactions)?.length && (
              <Col span={24}>
                <TransactionsTable
                  data={transactionsForDataGrind(loadTransactions)}
                  handleRowDelete={handleDeleteTransaction}
                  hasDeletePermission={loadsPagePermissions.transactionDelete}
                  isCustomer={Boolean(data?.load?.customer?.id)}
                  isCarrier={Boolean(data?.load?.carrier?.id)}
                />
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      ) : (
        <>
          {!!transactionsForDataGrind(loadTransactions)?.length && (
            <ViewItemWrapper
              title={SUBJECT.TRANSACTION}
              className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
              canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
              id="transaction"
            >
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <TransactionsTable
                    data={transactionsForDataGrind(loadTransactions)}
                    handleRowDelete={handleDeleteTransaction}
                    hasDeletePermission={loadsPagePermissions.transactionDelete}
                    isCustomer={Boolean(data?.load?.customer?.id)}
                    isCarrier={Boolean(data?.load?.carrier?.id)}
                  />
                </Col>
              </Row>
            </ViewItemWrapper>
          )}
        </>
      )}
      {rateConfirmations?.result?.length ? (
        <div className={styles.documentationWrapper}>
          <ViewItemWrapper
            title="Carrier Rate Confirmation"
            id={SUBJECT.CARRIER_RATE_CONFIRMATION_ID}
            className={activeSection === SUBJECT.CARRIER_RATE_CONFIRMATION_ID ? 'active' : ''}
            canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          >
            {rateConfirmations?.result?.map((fileItem: any, index: number) => (
              <AutoRateConfirmation
                fileItem={fileItem}
                handleViewFile={handleViewFileEdit}
                handleDownloadFileClick={handleDownloadFileEdit}
                handleSendRateConfirmation={handleSendRateConfirmation}
                rateConfirmations={rateConfirmations}
                index={index}
              />
            ))}
            {!!createRateConfCheck && (
              <Typography
                onClick={handleCreateRateConfirmation}
                variant="paragraph"
                children={SUBJECT.CREATE_RATE}
                className={styles.newCarrierRateBtn}
              />
            )}
          </ViewItemWrapper>
        </div>
      ) : null}

      {data?.load?.carrier?.id && !!breakdowns?.result?.length && (
        <ViewItemWrapper
          id={SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID}
          className={activeSection === SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID ? 'active' : ''}
          title={SUBJECT.CARRIER_PAYMENT_BREAKDOWN}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
        >
          <CarrierPaymentBreakdown
            data={{
              result: breakdowns?.result?.map((item: any) => {
                return {
                  download: item?.file?.download,
                  id: item?.id,
                  name: item?.file?.name,
                  createdAt: formatDate(item?.createdAt),
                  preview: item?.file?.preview,
                };
              }),
              count: breakdowns?.count,
            }}
            skip={breakdownParams.skip}
            limit={breakdownParams.limit}
            isLoading={isBreakdownLoading}
            onPaginationClick={handleCarrierBreakdownPaginationClick}
            onSort={handleBreakdownSortClick}
            withPagination={true}
          />
        </ViewItemWrapper>
      )}

      <div className={clsx(styles.uploadSection)}>
        <ViewItemWrapper
          className={activeSection === SUBJECT.DOCUMENTATION_ID ? 'active' : ''}
          title={SUBJECT.DOCUMENTATION}
          canceledBlock={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
          id="documentation"
        >
          <Row gutter={[0, 35]}>
            <Col span={24}>
              <Row align="top" justify="space-between">
                <Col span={8}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.UPLOAD_RED_CONFIRMATION}
                    className={styles.uploadHeading}
                  />
                </Col>
                <Col span={5}>
                  {isSendedToBilling ? (
                    <Typography
                      className={styles.toBillingText}
                      variant="paragraph"
                      children={SUBJECT.SENT_TO_BILLING}
                    />
                  ) : (
                    loadsPagePermissions.sendToBilling &&
                    data?.agents?.[1]?.user?.id !== userId && (
                      <Button
                        className="smallBtn"
                        variant="default"
                        children={SUBJECT.SEND_TO_BILLING}
                        disabled={isSendToBillingDisabled}
                        onClick={handleSendToBilling}
                      />
                    )
                  )}
                </Col>
              </Row>
              <Row gutter={[23, 30]}>
                <>
                  <Col span={9}>
                    <Upload
                      accept="application/pdf"
                      uploadText={SUBJECT.UPLOAD_RED_CONFIRMATION}
                      className={styles.upload}
                      beforeUpload={beforeUploadForEdit}
                      multiple={false}
                      isDisabled={!loadsPagePermissions.uploadsFiles}
                      uploadType="2"
                    />
                  </Col>
                  <Col span={8}>
                    <Typography
                      variant="heading"
                      children={SUBJECT.ALLOWED_FORMAT_PDF}
                      className={styles.allowedFormat}
                    />
                  </Col>
                </>
                {!!allFiles.length && (
                  <Col span={24}>
                    <Row justify="end" gutter={[20, 0]} align="bottom" className={styles.downloadAllBlock}>
                      <Col>
                        <Typography variant="heading" children={SUBJECT.DOWNLOAD_ALL} className={styles.downloadAll} />
                      </Col>
                      <Col>
                        <div
                          className={clsx(styles.uploadControl, styles.downloadBtn)}
                          onClick={() => multiplyDownloadWithDelay(allFiles)}
                        >
                          <DownloadIcon />
                        </div>
                      </Col>
                    </Row>

                    <Col span={24}>
                      <Controller
                        control={control}
                        render={({ field: { value } }) => (
                          <FileItems
                            handleDownloadFileEdit={handleDownloadFileEdit}
                            handleViewFileEdit={handleViewFileEdit}
                            itemId={value.id}
                            onDeleteClick={handleDeleteFileEdit}
                            files={allFiles}
                            isDisabled={
                              isSendedToBilling || data?.load?.status !== LoadStatus.LOAD_STATUS_NOT_PROCESSING
                            }
                            withCrossing
                            hasDeleteFilePermission={loadsPagePermissions.filesDelete}
                          />
                        )}
                        name="file"
                      />
                    </Col>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </ViewItemWrapper>
      </div>
      {isSendedToBilling && roleLevel === 1 && (
        <div
          className={clsx({
            [styles.active]: activeSection === SUBJECT.BILLING_ID,
          })}
          id="billing-block"
        >
          <BillingBlock
            paymentTermType={data?.load?.paymentTermType}
            noa={data?.load?.noa}
            mailableFiles={mailableFiles}
            pod={loadFiles?.result?.POD}
            redConfirmation={loadFiles?.result?.RED_CONFIRMATION}
            invoice={loadFiles?.result?.INVOICE || []}
            onAcceptClick={handleAcceptToBilling}
            onRejectClick={handleRejectBilling}
            onCreateInvoice={handleCreateInvoice}
            onDeleteInvoice={handleDeleteInvoice}
            hasValidFiles={!isSendToBillingDisabled}
            loadStatus={data?.load?.status}
            processing={data?.load?.processing}
            hasAcceptToBillingPermission={loadsPagePermissions.acceptToBilling}
            hasClaimLoadAcceptToBillingPermission={loadsPagePermissions.claimLoadAcceptToBilling}
            hasRejectBillingPermission={loadsPagePermissions.reject}
            hasClaimLoadRejectPermission={loadsPagePermissions.claimLoadReject}
            isLoadClaimed={data?.load?.claimed}
            isLoadHasCustomer={isLoadHasCustomer}
            loadData={data}
            isSendedToBilling={isSendedToBilling}
            isCanceledClaimed={data?.load?.status === LoadCancelStatus.Canceled || data?.load?.claimed}
            isFetching={isFetching}
            isInvoiceLoading={isInvoiceLoading}
          />
        </div>
      )}
      <div ref={drawerRef}>
        <CustomComment
          id="comment"
          watch={watch}
          name="comment"
          setValue={setValue}
          control={control}
          comments={comments}
          createComment={createComment}
          deleteComment={deleteComment}
          getMoreComments={getMoreComments}
          isBlockCanceled={data?.load?.status === LoadCancelStatus.Canceled}
          isShowMoreVisible={isShowMoreVisible}
          loadHistory={loadHistory}
          isDefaultHistory={isDefaultHistory}
          setIsDefaultHistory={setIsDefaultHistory}
          activeSection={activeSection}
          withNotes={loadHistory?.result?.length}
          drawerRef={drawerRef}
          isShowMoreVisibleNotes={isShowMoreVisibleNotes}
          getMoreNotes={getMoreNotes}
          hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
          isLoadsCommentsLoading={isLoadsCommentsLoading}
          isFetchingHistory={isFetchingHistory}
        />
      </div>
    </form>
  );
};

export default TonuLoadEdit;
