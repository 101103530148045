import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import CopyIcon from '../../../../../../components/svgs/CopyIcon';
import { SUBJECT } from '../../constants/constants';

import { equipmentTypeText, statusTypesText, trailerTypesText } from './constants/constants';
import useTruckInfo from './useTruckInfo';

import styles from './TruckInfo.module.scss';

const TruckInfo = ({ truckInfo, carrierInfo: _carrierInfo }: any) => {
  const { TruckInfoButtonsRenderer, copiedText, handleCopy, statusQuery: status, tab } = useTruckInfo({ truckInfo });

  const currentStatus =
    tab === 'truck_board'
      ? statusTypesText[Number(status)]
      : truckInfo?.truckBoardStatus === 3
      ? statusTypesText[1]
      : statusTypesText[truckInfo?.truckBoardStatus];

  return (
    <div>
      <ViewItemWrapper title={SUBJECT.TRUCK_INFO} Component={<TruckInfoButtonsRenderer />}>
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.TRUCK_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <div
                    className={clsx(styles.copyIconText, {
                      [styles.copyActive]: copiedText === 'truckNumber',
                    })}
                  >
                    <Typography
                      variant="paragraph"
                      children={truckInfo?.truckNumber}
                      className={styles.infoParagraph}
                    />
                    <div
                      className={styles.copyIcon}
                      role="button"
                      onClick={() => handleCopy(String(truckInfo?.truckNumber), 'truckNumber')}
                    >
                      <CopyIcon active={copiedText === 'truckNumber'} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {!!truckInfo?.fakeCheckIsOnlyTruckNumber && (
              <>
                {currentStatus === statusTypesText[3] ? (
                  <>
                    <Col span={10}>
                      <Row gutter={10}>
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children={SUBJECT.FIRST_PICKUP}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={13}>
                          <Typography
                            variant="heading"
                            children={truckInfo?.firstPickUpDate}
                            className={styles.infoParagraph}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {truckInfo?.coordinator?.id && (
                      <Col span={10}>
                        <Row gutter={10}>
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={SUBJECT.COORDINATOR}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col span={13}>
                            <TooltipText
                              prefixClass="top-banner-value"
                              data={truckInfo?.coordinator?.firstName}
                              subject={`${SUBJECT.TRUCK_INFO}${SUBJECT.COORDINATOR}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <Col span={10}>
                      <Row gutter={10}>
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children={SUBJECT.LAST_DROP_OFF}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={13}>
                          <Typography
                            variant="heading"
                            children={truckInfo?.lastDropOffDate}
                            className={styles.infoParagraph}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={10}>
                      <Row gutter={10}>
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children={SUBJECT.AVAILABLE_DATE}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={13}>
                          <Typography
                            variant="heading"
                            children={truckInfo?.availableAtForView}
                            className={styles.infoParagraph}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {truckInfo?.coordinator?.id && (
                      <Col span={10}>
                        <Row gutter={10}>
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={SUBJECT.COORDINATOR}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col span={13}>
                            <TooltipText
                              prefixClass="top-banner-value"
                              data={truckInfo?.coordinator?.username}
                              subject={`${SUBJECT.TRUCK_INFO}${SUBJECT.COORDINATOR}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </>
                )}

                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography variant="heading" children={currentStatus} className={styles.infoParagraph} />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.EQUIPMENT_TYPE} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="heading"
                        children={equipmentTypeText[truckInfo?.equipmentType]}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
                {!!truckInfo?.trailerDetails && (
                  <Col span={10}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography
                          variant="heading"
                          children={SUBJECT.TRAILER_DETAILS}
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col span={13}>
                        <Typography
                          variant="heading"
                          children={trailerTypesText[truckInfo?.trailerDetails]}
                          className={styles.infoParagraph}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.LOCATED_CITY} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass="top-banner-value"
                        data={truckInfo?.city}
                        subject={`${SUBJECT.TRUCK_INFO}${SUBJECT.LOCATED_CITY}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.LOCATED_STATE} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="heading"
                        children={truckInfo?.fromState?.name}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.DESIRE_DESTINATION}
                        className={styles.infoHeading}
                      />
                    </Col>
                    {!!truckInfo?.toStates?.length && (
                      <Col span={13}>
                        <span className="top-banner-value">{`${truckInfo?.toStates?.[0]?.name}${
                          truckInfo?.toStates?.[1]?.name ? ', ' + truckInfo?.toStates?.[1]?.name : ''
                        }`}</span>
                        &nbsp;
                        <Tooltip
                          color="white"
                          placement="top"
                          zIndex={999999}
                          title={
                            truckInfo?.toStates?.length > 2 && (
                              <div className={styles.stateWrapperList}>
                                <Row gutter={8}>
                                  {truckInfo?.toStates?.slice(2)?.map((el: any, index: number) => (
                                    <Col key={index}>
                                      <Typography variant="paragraph">
                                        <span>{el?.name}</span>
                                      </Typography>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            )
                          }
                        >
                          {truckInfo?.toStates?.length > 2 && (
                            <a className="top-banner-value">{`+${truckInfo?.toStates?.length - 2}`}</a>
                          )}
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                </Col>
                {truckInfo?.comment && (
                  <Col span={10}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography variant="heading" children={SUBJECT.COMMENT} className={styles.infoHeading} />
                      </Col>
                      <Col span={13}>
                        <TooltipText
                          prefixClass="top-banner-value"
                          data={truckInfo?.comment}
                          subject={`${SUBJECT.TRUCK_INFO}${SUBJECT.LAST_COMMENT}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </>
            )}
          </>
        </Row>
      </ViewItemWrapper>
    </div>
  );
};

export default TruckInfo;
