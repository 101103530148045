export const MY_AUTHORITY_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC number' },
];

export const CUSTOMER_TYPE_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC number' },
];

export const AUTHORITY_KEYS = ['id', 'name', 'mc'];
export const CUSTOMER_KEYS = ['id', 'name', 'mc'];

export const creditDefaultValues = {
  authority: [],
  customer: [],
  amount: '',
};

export enum VALIDATION_MESSAGES {
  AUTHORITY_REQUIRED = 'Authority Is a Required Field',
  CUSTOMER_REQUIRED = 'Customer Is a Required Field',
  AMOUNT_REQUIRED = 'Amount Is a Required Field',
  NOT_ZERO = 'Should Be a Positive Number',
  LESS_THAN_MILLION = 'Amount Must Be Less Than a Million',
}

export enum SUBJECT {
  REQUEST_CREDIT = 'Request Credit',
  MY_AUTHORITY = 'My Authority',
  CUSTOMER_TYPE = 'Customer Type',
  AMOUNT = 'Amount',
  LOAD_TYPE = 'Load Type',
  PAYMENT_METHOD = 'Payment Method',
  FACTORING = 'Factoring',
  REAL_AMOUNT = 'Real Amount',
  REQUEST_CREDIT_ERROR_MESSAGE_TITLE = 'The Authority And Customer Are Not Attached. Please Contact Your Manager',
  DEBT = 'Debt',
  LIMIT = 'Limit',
  NOT_PAID = 'Not Paid',
  LOAD_QTY = 'Load Qty',
  CUSTOMER = 'Customer',
}
