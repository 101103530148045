import { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';

import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import Table from 'components/table/Table';

import { carrierStatusOptions, SUBJECT } from './constants/constants';
import { FilterSelect } from './hoc/instance';
import useVerificationBoard from './useVerificationBoard';

import styles from './VerificationBoard.module.scss';

const VerificationBoard = ({ setExcelParams }: any) => {
  const {
    columns,
    filterWatch,
    filterControl,
    filterSetValue,
    handleRowClick,
    handleResetSearchResultFilter,
    handleSortClick,
    handleStopResize,
    handleColumnSearch,
    handlePaginationClick,
    handleRowEdit,
    coordinators,
    verificationList,
    isVerificationListFetching,
    setCoordinatorsFilter,
    skip,
    limit,
  } = useVerificationBoard(setExcelParams);

  const tableProps = useDeferredValue({
    data: verificationList,
    skip,
    limit,
    columns: columns,
    isLoading: isVerificationListFetching,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    handleRowEdit: handleRowEdit,
    onResizeStop: handleStopResize,
    onPaginationClick: handlePaginationClick,
    onColumnSearch: handleColumnSearch,
    onCommentClick: () => null,
    hasEditPermission: true,
    withMenu: true,
  });

  return (
    <div className={styles.coordinatorWrapper}>
      <div className="filtersWrapper">
        <div className={styles.verificationMainSelect}>
          <Row align="bottom" gutter={[15, 0]}>
            <Col lg={6} xxl={4}>
              <FilterSelect
                suffixIcon={<SelectArrowIcon />}
                withAll
                options={carrierStatusOptions}
                control={filterControl}
                label={SUBJECT.CARRIER_STATUS}
                placeholder={SUBJECT.NOT_SELECTED}
                name={SUBJECT.CARRIER_STATUS_FILTER}
              />
            </Col>
            <Col lg={6} xxl={4}>
              <FilterSelect
                suffixIcon={<SelectArrowIcon />}
                withAll
                isSearchable
                withCheckbox
                isMultiSelect
                isFilter
                options={coordinators?.result}
                count={coordinators?.count}
                label={SUBJECT.COORDINATOR}
                placeholder={SUBJECT.NOT_SELECTED}
                name={SUBJECT.COORDINATOR_FILTER}
                control={filterControl}
                offset={coordinators?.count}
                setOffset={setCoordinatorsFilter}
                setValue={filterSetValue}
                withMarking
                isAutocomplete
              />
            </Col>
            <Col lg={6} xxl={4}>
              {!!(filterWatch('carrierStatus') || filterWatch('coordinator')?.length) && (
                <Button
                  icon={<ResetIcon />}
                  variant="ghost"
                  children={SUBJECT.RESET_ALL}
                  onClick={handleResetSearchResultFilter}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
      <Table {...tableProps} />
    </div>
  );
};
export default VerificationBoard;
