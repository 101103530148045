export const getEmployeesSerializer = (data: any) => {
  const result = data?.result?.map((employee: any) => {
    return {
      createdAt: employee.createdAt,
      firstName: employee.firstName,
      lastName: employee.lastName,
      username: employee.username,
      id: employee.id,
      phone: employee.phone,
      dateOfBirth: employee.dateOfBirth,
      ext: employee.ext,
      title: employee.title,
      permission: employee?.permissionGroupName,
      email: employee.email,
      verifiedAt: !!employee.verifiedAt,
      address: employee.address,
      city: employee.city,
      state: employee.stateName,
      zip: employee.zip,
      terminatedAt: employee.terminatedAt,
      terminate_denied: !employee.verifiedAt,
      office: employee?.office?.name,
      branch: employee?.branch?.name,
    };
  });

  return {
    result,
    count: data.count,
  };
};
