import React from 'react';
import { Tabs } from 'antd';

import MainDrawer from 'components/main-drawer/MainDrawer';

import { ICarriersParams } from './constants/types';
import useCarriers from './useCarriers';

import styles from './Carriers.module.scss';

const Carriers = ({ setExcelParams }: ICarriersParams) => {
  const {
    activePage,
    handleTabChange,
    currentChildren: { component, buttonText, onCancel, onSubmit, withFooter },
    carrierTabs,
  } = useCarriers({ setExcelParams });

  return (
    <div className="page-content">
      <Tabs
        defaultActiveKey={activePage}
        onChange={activeTab => handleTabChange(activeTab)}
        items={carrierTabs}
        className={styles.carriersHeaderTabs}
      />
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
        withScrollBar
      />
    </div>
  );
};

export default Carriers;
