const LoadsIcon = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4295 26.3154C9.76303 25.4191 7.56716 23.817 5.84183 21.5091C4.11651 19.2012 3.25384 16.546 3.25384 13.5435C3.25384 12.8265 3.30986 12.1094 3.42189 11.3924C3.53393 10.6754 3.71318 9.96958 3.95966 9.27497L1.8086 10.5185L0.800293 8.80443L6.61487 5.4434L9.9759 11.2244L8.22816 12.2327L6.27877 8.90526C5.96507 9.64468 5.7242 10.4065 5.55615 11.1908C5.3881 11.975 5.30407 12.7704 5.30407 13.5771C5.30407 16.1763 6.07151 18.473 7.60637 20.4672C9.14124 22.4614 11.0962 23.817 13.4714 24.534L12.4295 26.3154ZM22.1092 8.83804V6.82142H25.9744C24.8989 5.34257 23.5489 4.18862 21.9244 3.35956C20.2999 2.53051 18.5689 2.11598 16.7316 2.11598C15.1855 2.11598 13.7459 2.39607 12.4126 2.95624C11.0794 3.51641 9.89747 4.28945 8.86676 5.27535L7.82484 3.46039C9.03481 2.40727 10.3904 1.58382 11.8917 0.990038C13.3929 0.396256 14.995 0.0993652 16.698 0.0993652C18.6698 0.0993652 20.5295 0.497087 22.2773 1.29253C24.025 2.08797 25.5375 3.21392 26.8146 4.67036V2.11598H28.8313V8.83804H22.1092ZM20.5967 29.6764L14.7822 26.3154L18.1432 20.5344L19.8573 21.5427L17.9079 24.9374C20.8208 24.6461 23.2576 23.4193 25.2182 21.257C27.1788 19.0948 28.1591 16.5348 28.1591 13.5771C28.1591 13.1065 28.1311 12.6472 28.075 12.1991C28.019 11.7509 27.935 11.3028 27.823 10.8547H29.9068C29.9964 11.3028 30.0636 11.7509 30.1085 12.1991C30.1533 12.6472 30.1757 13.0953 30.1757 13.5435C30.1757 16.7477 29.173 19.5821 27.1676 22.0469C25.1621 24.5116 22.6022 26.0689 19.4876 26.7187L21.6051 27.9623L20.5967 29.6764Z"
        fill="white"
      />
    </svg>
  );
};

export default LoadsIcon;
