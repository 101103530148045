export const GreenInfoIcon = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.77083 13.166H9.02083V8.16602H7.77083V13.166ZM8.33333 6.62435C8.52778 6.62435 8.69097 6.56185 8.82292 6.43685C8.95486 6.31185 9.02083 6.15213 9.02083 5.95768C9.02083 5.76324 8.95486 5.59657 8.82292 5.45768C8.69097 5.31879 8.52778 5.24935 8.33333 5.24935C8.13889 5.24935 7.9757 5.31879 7.84375 5.45768C7.71181 5.59657 7.64583 5.76324 7.64583 5.95768C7.64583 6.15213 7.71181 6.31185 7.84375 6.43685C7.9757 6.56185 8.13889 6.62435 8.33333 6.62435ZM8.33333 17.3327C7.19444 17.3327 6.11806 17.1139 5.10417 16.6764C4.09028 16.2389 3.20486 15.6417 2.44792 14.8848C1.69097 14.1278 1.09375 13.2424 0.65625 12.2285C0.21875 11.2146 0 10.1313 0 8.97852C0 7.83963 0.21875 6.76324 0.65625 5.74935C1.09375 4.73546 1.69097 3.85352 2.44792 3.10352C3.20486 2.35352 4.09028 1.75977 5.10417 1.32227C6.11806 0.884766 7.20139 0.666016 8.35417 0.666016C9.49306 0.666016 10.5694 0.884766 11.5833 1.32227C12.5972 1.75977 13.4792 2.35352 14.2292 3.10352C14.9792 3.85352 15.5729 4.73546 16.0104 5.74935C16.4479 6.76324 16.6667 7.84657 16.6667 8.99935C16.6667 10.1382 16.4479 11.2146 16.0104 12.2285C15.5729 13.2424 14.9792 14.1278 14.2292 14.8848C13.4792 15.6417 12.5972 16.2389 11.5833 16.6764C10.5694 17.1139 9.48611 17.3327 8.33333 17.3327Z"
        fill="#34A853"
      />
    </svg>
  );
};
