import React from 'react';

const CreditIcon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0879 38.3607C31.8487 38.3607 38.14 32.0694 38.14 24.3086C38.14 16.5479 31.8487 10.2565 24.0879 10.2565C16.3272 10.2565 10.0358 16.5479 10.0358 24.3086C10.0358 32.0694 16.3272 38.3607 24.0879 38.3607ZM24.0879 40.1172C32.8188 40.1172 39.8965 33.0395 39.8965 24.3086C39.8965 15.5778 32.8188 8.5 24.0879 8.5C15.3571 8.5 8.2793 15.5778 8.2793 24.3086C8.2793 33.0395 15.3571 40.1172 24.0879 40.1172Z"
        fill="var(--black)"
      />
      <path
        d="M22.8507 33.0913H25.6V31.1727H27.7995C28.2577 31.1727 28.6319 31.0022 28.9221 30.6611C29.2123 30.3201 29.3574 29.9364 29.3574 29.51V24.618C29.3574 24.156 29.2123 23.7616 28.9221 23.4347C28.6319 23.1078 28.2577 22.9444 27.7995 22.9444H21.5677V20.0027H29.3574V17.4446H25.6V15.5261H22.8507V17.4446H20.6054C20.1472 17.4446 19.7348 17.6152 19.3682 17.9563C19.0016 18.2973 18.8184 18.6969 18.8184 19.155V24.0053C18.8184 24.4633 19.0016 24.8274 19.3682 25.0974C19.7348 25.3674 20.1472 25.5024 20.6054 25.5024H26.6081V28.6147H18.8184V31.1727H22.8507V33.0913Z"
        fill="var(--black)"
      />
      <mask id="path-3-inside-1_502_18384" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.816 41.2227C37.9817 41.2227 38.116 41.0883 38.116 40.9227L38.116 36.0383L41.8068 36.0383C42.0639 36.0383 42.2019 35.7362 42.0336 35.5419L35.9591 28.5277C35.8395 28.3895 35.6252 28.3895 35.5056 28.5277L29.4311 35.5419C29.2629 35.7362 29.4009 36.0383 29.6579 36.0383L32.9344 36.0383L32.9344 40.9227C32.9344 41.0883 33.0687 41.2227 33.2344 41.2227L37.816 41.2227Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.816 41.2227C37.9817 41.2227 38.116 41.0883 38.116 40.9227L38.116 36.0383L41.8068 36.0383C42.0639 36.0383 42.2019 35.7362 42.0336 35.5419L35.9591 28.5277C35.8395 28.3895 35.6252 28.3895 35.5056 28.5277L29.4311 35.5419C29.2629 35.7362 29.4009 36.0383 29.6579 36.0383L32.9344 36.0383L32.9344 40.9227C32.9344 41.0883 33.0687 41.2227 33.2344 41.2227L37.816 41.2227Z"
        fill="var(--black)"
      />
      <path
        d="M37.816 41.2227L37.816 40.7227L37.816 41.2227ZM38.116 36.0383L38.116 35.5383L37.616 35.5383L37.616 36.0383L38.116 36.0383ZM41.8068 36.0383L41.8068 36.5383H41.8068L41.8068 36.0383ZM42.0336 35.5419L41.6557 35.8692L41.6557 35.8692L42.0336 35.5419ZM35.9591 28.5277L36.3371 28.2003L36.3371 28.2003L35.9591 28.5277ZM35.5056 28.5277L35.8836 28.855L35.8836 28.855L35.5056 28.5277ZM29.4311 35.5419L29.8091 35.8692L29.8091 35.8692L29.4311 35.5419ZM29.6579 36.0383L29.6579 35.5383L29.6579 36.0383ZM32.9344 36.0383L33.4344 36.0383L33.4344 35.5383L32.9344 35.5383L32.9344 36.0383ZM33.2344 41.2227L33.2344 41.7227L33.2344 41.2227ZM37.616 40.9227C37.616 40.8122 37.7055 40.7227 37.816 40.7227L37.816 41.7227C38.2578 41.7227 38.616 41.3645 38.616 40.9227L37.616 40.9227ZM37.616 36.0383L37.616 40.9227L38.616 40.9227L38.616 36.0383L37.616 36.0383ZM41.8068 35.5383L38.116 35.5383L38.116 36.5383L41.8068 36.5383L41.8068 35.5383ZM41.6557 35.8692C41.5435 35.7397 41.6355 35.5383 41.8068 35.5383L41.8068 36.5383C42.4922 36.5383 42.8603 35.7327 42.4116 35.2145L41.6557 35.8692ZM35.5812 28.855L41.6557 35.8692L42.4116 35.2145L36.3371 28.2003L35.5812 28.855ZM35.8836 28.855C35.8038 28.9471 35.6609 28.9471 35.5812 28.855L36.3371 28.2003C36.0181 27.832 35.4466 27.832 35.1276 28.2003L35.8836 28.855ZM29.8091 35.8692L35.8836 28.855L35.1276 28.2003L29.0532 35.2145L29.8091 35.8692ZM29.6579 35.5383C29.8292 35.5383 29.9213 35.7397 29.8091 35.8692L29.0532 35.2145C28.6044 35.7327 28.9725 36.5383 29.6579 36.5383L29.6579 35.5383ZM32.9344 35.5383L29.6579 35.5383L29.6579 36.5383L32.9344 36.5383L32.9344 35.5383ZM33.4344 40.9227L33.4344 36.0383L32.4344 36.0383L32.4344 40.9227L33.4344 40.9227ZM33.2344 40.7227C33.3448 40.7227 33.4344 40.8122 33.4344 40.9227L32.4344 40.9227C32.4344 41.3645 32.7925 41.7227 33.2344 41.7227L33.2344 40.7227ZM37.816 40.7227L33.2344 40.7227L33.2344 41.7227L37.816 41.7227L37.816 40.7227Z"
        fill="white"
        mask="url(#path-3-inside-1_502_18384)"
      />
    </svg>
  );
};

export default CreditIcon;
