export enum SUBJECT {
  RECEIVABLES_IS_EMPTY = 'Receivables Is Empty',
  TOTAL = 'Total',
  SEARCH = 'Search',
  RESET_ALL = 'Reset All',
  SAVE = 'Save',
  MC = 'MC',
  CUSTOMER = 'Customer',
  PAYMENT_METHOD = 'Payment Method',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  NOT_SELECTED = 'Not Selected',
  AGENT = 'Agent',
  EXPECTED = 'Pending',
  LOAD = 'Load:',
  QUICK_PAY = 'QuickPay %:',
  CUSTOM = 'Custom %:',
  PAID_DATE = 'Received Date',
  LOAD_REG_DATE = 'Approved Date',
  RECEIVED = 'Received',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  DOWNLOADING_IN_PROCCESS = 'Downloading In Process',
  ARE_YOU_SURE = 'Are You Sure Want To Send Invoice?',
  ARE_YOU_SURE_INVOICE_CHECK = 'Are You Sure Want To Check Invoice Pending?',
  SEND = 'Send',
  YES = 'Yes',
  CANCEL = 'Cancel',
  SUCCESSFULLY_SENT = 'Successfully Sent',
}

export const filterDefaultValues = {
  loadRegDateFilter: null,
  paidDate: null,
  receivablesMc: [],
  receivablesCustomer: [],
  receivablesOffice: [],
  receivablesBranch: [],
  receivablesAgent: [],
  customerPaymentMethod: null,
  authorityIds: [],
};

export const RECEIVABLES_PAYMENT_METHOD = [
  {
    title: 'Quick pay',
    value: 'Quick pay',
  },
  {
    title: 'Factoring',
    value: 'Factoring',
  },
];

export const RECEIVABLES_DATA_GRID_OPTIONS = [
  {
    title: 'Mira',
    value: 'Mira',
  },
  {
    title: 'John',
    value: 'John',
  },
  {
    title: 'Sara',
    value: 'Sara',
  },
];

export const MC_TYPE_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
];

export const CUSTOMER_TYPE_COLUMNS = [
  { key: 'name', name: 'Customer Name' },
  { key: 'id', name: 'ID' },
];

export const CUSTOMER_TYPE_OPTIONS = [
  { id: '1', name: 'John', mc: '24456789' },
  { id: '2', name: 'Alex', mc: '98876897' },
  { id: '3', name: 'Johnathan', mc: '65656548' },
];

export const CUSTOMER_PAYMENT_METHOD = [
  {
    title: 'Quick pay',
    value: '2',
  },
  {
    title: 'Custom',
    value: '3',
  },
  {
    title: 'Factoring',
    value: '1',
  },
];

export const AGENT_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'surname', name: 'Last Name' },
  { key: 'username', name: 'Username' },
];

export const AGENT_KEYS = ['id', 'name', 'surname', 'username'];
