import { emailPattern, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  NAME_REQUIRED,
  ZIP_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  STATE_ID_REQUIRED,
  PHONE,
  INCORRECT_FAX,
  MAX_LENGTH,
  ADDRESS_MAX_LENGTH,
  EMAIL_REQUIRED,
  EMAIL,
  MAX_MIN_ZIP_LENGTH,
  PAYMENT_BANK_NAME_REQUIRED,
  PAYMENT_ROUTING_NUMBER_REQUIRED,
  PAYMENT_ACCOUNT_REQUIRED,
  PAYMENT_COMPANY_NAME_REQUIRED,
  PAYMENT_ADDRESS_REQUIRED,
  PAYMENT_CITY_REQUIRED,
  PAYMENT_ZIP_REQUIRED,
  PAYMENT_STATE_REQUIRED,
  ZIP_MIN_LENGTH,
} = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  name: yup.string().required(NAME_REQUIRED),
  email: yup.string().required(EMAIL_REQUIRED).matches(emailPattern, EMAIL),
  phoneNumber: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBER_REQUIRED).matches(phoneRegExp, PHONE),
  address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
  fax: yup
    .string()
    .nullable()
    .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
      if ((value && value.length === 12) || !value) {
        return true;
      } else if (!value?.match(phoneRegExp)) {
        return this.createError({
          message: INCORRECT_FAX,
          path: 'fax',
        });
      } else {
        return this.createError({
          message: MAX_LENGTH,
          path: 'fax',
        });
      }
    }),
  city: yup.string().required(CITY_REQUIRED),
  stateId: yup.string().required(STATE_ID_REQUIRED),
  zipcode: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
  file: yup.array(),

  paymentBankName: yup.string().test('isRequired', PAYMENT_BANK_NAME_REQUIRED, function checkisRequired(value) {
    return !!value;
  }),
  paymentRoutingNumber: yup
    .string()
    .test('isRequired', PAYMENT_ROUTING_NUMBER_REQUIRED, function checkisRequired(value) {
      return !!value;
    }),
  paymentAccountNumber: yup.string().test('isRequired', PAYMENT_ACCOUNT_REQUIRED, function checkisRequired(value) {
    return !!value;
  }),
  paymentCompanyName: yup.string().test('isRequired', PAYMENT_COMPANY_NAME_REQUIRED, function checkisRequired(value) {
    return !!value;
  }),
  paymentAddress: yup.string().test('isRequired', PAYMENT_ADDRESS_REQUIRED, function checkisRequired(value) {
    return !!value;
  }),
  paymentSecondAddress: yup.string(),
  paymentCity: yup.string().test('isRequired', PAYMENT_CITY_REQUIRED, function checkisRequired(value) {
    return !!value;
  }),
  paymentStateId: yup.string().test('isRequired', PAYMENT_STATE_REQUIRED, function checkisRequired(value) {
    return !!value;
  }),
  paymentZip: yup.string().test('isRequired', PAYMENT_ZIP_REQUIRED, function checkisRequired(value) {
    if (value && value?.length !== 5) {
      return this?.createError({
        message: ZIP_MIN_LENGTH,
        path: 'paymentZip',
      });
    } else return !!value?.length;
  }),
});
