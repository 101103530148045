import { Col, Row } from 'antd';
import ForgotPassword from 'pages/authorization/forgot-password/ForgotPassword';
import { SUBJECT } from 'pages/authorization/sign-in/constants/constants';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import { Checkbox, Input } from 'ui/inputs/hoc';

import CreateUpdatePassword from '../create-update-password/CreateUpdatePassword';

import useSignIn from './useSignIn';

import styles from './SignIn.module.scss';

const SignIn = () => {
  const {
    handleSubmit,
    onSubmit,
    control,
    isValid,
    isForgot,
    redirectWaitingPage,
    errors,
    getValues,
    newPass,
    createPass,
    isLoading,
  } = useSignIn();

  if (createPass) {
    return <CreateUpdatePassword name={SUBJECT.CREATE_PASSWORD} />;
  }

  if (newPass) {
    return <CreateUpdatePassword name={SUBJECT.NEW_PASSWORD} />;
  }

  return isForgot ? (
    <ForgotPassword redirectWaitingPage={redirectWaitingPage} />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <h2 className={styles.title}>{SUBJECT.WELCOME}</h2>
        </Col>
        <Col span={24}>
          <Input
            autoComplete="username"
            errors={errors}
            getValues={getValues}
            height={50}
            name="email"
            control={control}
            rules={{ required: true }}
            placeholder={SUBJECT.EMAIL}
            type="email"
          />
        </Col>
        <Col span={24}>
          <Input
            autoComplete="current-password"
            errors={errors}
            getValues={getValues}
            height={50}
            name="password"
            control={control}
            rules={{ required: true }}
            placeholder={SUBJECT.PASSWORD}
            type="password"
          />
          <ErrorMessage errors={errors} name="email" />
        </Col>
      </Row>
      <div className={styles.signinRemember}>
        <Row justify="space-between" align="top">
          <Col>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                name="rememberMe"
                control={control}
                rules={{ required: false }}
                checked={false}
                children={SUBJECT.REMEMBER_ME}
                size="small"
              />
            </div>
          </Col>
          <Col>
            <Button onClick={redirectWaitingPage} variant="link" shape="default" size="middle" htmlType="button">
              {SUBJECT.FORGOT_PASSWORD}
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          <Button
            variant="default"
            shape="default"
            size="large"
            htmlType="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {SUBJECT.LOGIN}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default SignIn;
