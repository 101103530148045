import { IAgentFormData } from 'pages/profile/tabs/agent/constants/types';
import { handleFloatedBrackeds } from 'utils/helpers';

import { AgentType } from './interfaces';

export const inviteAgentDeserializer = (body: IAgentFormData) => {
  return {
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body?.secondAddress } : {}),
    authorityIds: body.authorities?.map(el => Number(el)),
    brackets: handleFloatedBrackeds(body.brackeds)?.map(item => {
      return {
        to: Number(item.to),
        from: Number(item.from),
        percent: Number(item.percent),
      };
    }),
    city: body.city,
    email: body.email,
    firstName: body.first_name,
    lastName: body.last_name,
    location: Number(body.location),
    officeId: Number(body.office?.[0]?.id),
    phone: body.phone_number,
    stateId: Number(body.state),
    type: Number(body.agentType),
    username: body.user_name,
    zip: body.zip_code,
    ...(body.file.length ? { fileIds: body.file?.map(el => Number(el)) } : {}),
    ...(body.second_number ? { phoneSecond: body.second_number } : {}),
    ...(body.pay_type ? { payType: Number(body.pay_type) } : {}),
    ...(body.paid_as ? { paidAs: Number(body.paid_as) } : {}),
    ...(body.ssn_ein_number ? { agentPayInput: body.ssn_ein_number } : {}),
    ...(body.agent_pay_type ? { agentPayType: Number(body.agent_pay_type) } : {}),
    ...(AgentType[body.agentType as AgentType] === 'Owner' ? {} : { branchId: Number(body.branch?.[0]?.id) }),
    ...(body?.emergencyName ? { emergencyName: body?.emergencyName } : {}),
    ...(body?.emergencyPhone ? { emergencyPhone: body?.emergencyPhone } : {}),
    ...(body?.emergencyRelation ? { emergencyRelation: body?.emergencyRelation } : {}),
  };
};

export const editAgentDeserializer = (body: Partial<IAgentFormData>) => {
  return {
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body?.secondAddress } : {}),
    brackets: handleFloatedBrackeds(body.brackeds)?.map(item => {
      return {
        to: Number(item.to),
        from: Number(item.from),
        percent: Number(item.percent),
        ...(item.id ? { id: item.id } : {}),
      };
    }),
    city: body.city,
    firstName: body.first_name,
    lastName: body.last_name,
    location: Number(body.location),
    phone: body.phone_number,
    stateId: Number(body.state),
    username: body.user_name,
    zip: body.zip_code,
    agentPayInput: body.ssn_ein_number ? body.ssn_ein_number : null,
    phoneSecond: body.second_number || '',
    payType: Number(body.pay_type!) ? Number(body.pay_type!) : null,
    paidAs: Number(body.paid_as!) ? Number(body.paid_as!) : null,
    agentPayType: Number(body.agent_pay_type!) ? Number(body.agent_pay_type!) : null,
    ...(body?.emergencyName ? { emergencyName: body?.emergencyName } : {}),
    ...(body?.emergencyPhone ? { emergencyPhone: body?.emergencyPhone } : {}),
    ...(body?.emergencyRelation ? { emergencyRelation: body?.emergencyRelation } : {}),
  };
};
