import React, { useDeferredValue } from 'react';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import MainDrawer from 'components/main-drawer/MainDrawer';
import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import Table from 'components/table/Table';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';

import { CONTACT_LIST_TYPE_OPTIONS, SUBJECT } from './constants/constants';
import { FilterSelect } from './hoc-instance/instance';
import useContactList from './useContactList';

import styles from './ContactList.module.scss';

const ContactList = ({ setExcelParams }: any) => {
  const {
    currentChildren: { component, buttonText, withFooter, onSubmit, onCancel },
    isLoading,
    data,
    columns,
    filterControl,
    resetFilter,
    handleRowClick,
    handlePaginationClick,
    handleRowEdit,
    contactListFilter,
    handleColumnSearch,
    skip,
    limit,
    handleDeleteModal,
    handleStopResize,
    contactPagePermissions,
    roleLevel,
  } = useContactList(setExcelParams);

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading,
    handleRowDelete: handleDeleteModal,
    handleRowEdit: handleRowEdit,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onPaginationClick: handlePaginationClick,
    onColumnSearch: handleColumnSearch,
    hasEditPermission: contactPagePermissions.edit,
    hasDeletePermission: contactPagePermissions.delete,
    withMenu: contactPagePermissions.edit || contactPagePermissions.delete,
  });

  return (
    <>
      <div className={styles.contactListContainer}>
        <div className={styles.contactListTopBlock}>
          {roleLevel <= IRoleLevel.officeLevel && (
            <div className={styles.contactListMainSelect}>
              <FilterSelect
                withAll
                options={CONTACT_LIST_TYPE_OPTIONS.slice(roleLevel - 1)}
                control={filterControl}
                label={SUBJECT.TYPE}
                suffixIcon={<SelectArrowIcon />}
                placeholder={SUBJECT.NOT_SELECTED}
                name="contactListType"
              />
            </div>
          )}
          {+contactListFilter && roleLevel <= IRoleLevel.officeLevel ? (
            <Button icon={<ResetIcon />} variant="ghost" onClick={resetFilter} children={SUBJECT.RESET_ALL} />
          ) : null}
        </div>
        {!isLoading && !data && (
          <div className="page-content">
            <div className={styles.agentsEmpty}>
              <Typography variant="paragraph">{SUBJECT.CONTACT_LIST_IS_EMPTY}</Typography>
            </div>
          </div>
        )}
        <Table {...tableProps} />
      </div>

      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
      />
    </>
  );
};

export default ContactList;
