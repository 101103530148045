import dayjs from 'dayjs';

import { ICardData, ITruckBoardColumns } from './types';

export const defaultValues: ICardData = {
  S_WEST_All: 0,
  CENTRAL_All: 0,
  S_EAST_All: 0,
  N_WEST_All: 0,
  WESTERN_All: 0,
  N_CENTRAL_All: 0,
  MID_WEST_All: 0,
  N_EAST_All: 0,
  FAR_N_EAST_All: 0,
  AZ: 0,
  SCA: 0,
  NV: 0,
  NCA: 0,
  NM: 0,
  OK: 0,
  LA: 0,
  KS: 0,
  TX: 0,
  AR: 0,
  MO: 0,
  MS: 0,
  AL: 0,
  FL: 0,
  NC: 0,
  TN: 0,
  GA: 0,
  SC: 0,
  WA: 0,
  OR: 0,
  ID: 0,
  WY: 0,
  VT: 0,
  CO: 0,
  MN: 0,
  MT: 0,
  ND: 0,
  SD: 0,
  NE: 0,
  WI: 0,
  IL: 0,
  IN: 0,
  OH: 0,
  IA: 0,
  MI: 0,
  KY: 0,
  WV: 0,
  NY: 0,
  MD: 0,
  DE: 0,
  PA: 0,
  VA: 0,
  NJ: 0,
  VT2: 0,
  ME: 0,
  CT: 0,
  MA: 0,
  RI: 0,
  NH: 0,
};

export const defaultValuesFromBackend: ICardData = {
  S_WEST_All: 0,
  CENTRAL_All: 0,
  S_EAST_All: 0,
  N_WEST_All: 0,
  WESTERN_All: 0,
  N_CENTRAL_All: 0,
  MID_WEST_All: 0,
  N_EAST_All: 0,
  FAR_N_EAST_All: 0,
  AZ: 1,
  SCA: 2,
  NV: 1,
  NCA: 0,
  NM: 1,
  OK: 2,
  LA: 0,
  KS: 0,
  TX: 0,
  AR: 0,
  MO: 0,
  MS: 0,
  AL: 0,
  FL: 2,
  NC: 2,
  TN: 1,
  GA: 1,
  SC: 1,
  WA: 2,
  OR: 2,
  ID: 1,
  WY: 1,
  VT: 2,
  CO: 0,
  MN: 0,
  MT: 0,
  ND: 0,
  SD: 0,
  NE: 0,
  WI: 0,
  IL: 0,
  IN: 0,
  OH: 0,
  IA: 0,
  MI: 1,
  KY: 2,
  WV: 0,
  NY: 0,
  MD: 0,
  DE: 0,
  PA: 0,
  VA: 0,
  NJ: 0,
  VT2: 0,
  ME: 0,
  CT: 0,
  MA: 0,
  RI: 0,
  NH: 0,
};

export const TRUCK_CHILDREN = ['create', 'single', 'truck'];

export const filterDefaultValuesFromBackend = {
  truck: 'Truck Board',
  capacity: 'Particle',
  status: 'Off Board',
  equipment: ['Van', 'Reefer'],
  trailer: ['Hazmat', 'E-truck', 'Plate trailer', 'High Cube'],
  date: dayjs().tz('America/Los_Angeles'),
};

const ALL_FIELDS = [
  'S_WEST_All',
  'CENTRAL_All',
  'S_EAST_All',
  'N_WEST_All',
  'WESTERN_All',
  'N_CENTRAL_All',
  'MID_WEST_All',
  'N_EAST_All',
  'FAR_N_EAST_All',
];

export const S_WEST = ['S_WEST_All', 'AZ', 'SCA', 'NV', 'NCA'];
export const CENTRAL = ['CENTRAL_All', 'NM', 'OK', 'LA', 'KS', 'TX', 'AR', 'MO'];
export const S_EAST = ['S_EAST_All', 'MS', 'AL', 'FL', 'NC', 'TN', 'GA', 'SC'];
export const N_WEST = ['N_WEST_All', 'WA', 'OR'];
export const WESTERN = ['WESTERN_All', 'ID', 'WY', 'VT', 'CO'];
export const N_CENTRAL = ['N_CENTRAL_All', 'MN', 'MT', 'ND', 'SD', 'NE'];
export const MID_WEST = ['MID_WEST_All', 'WI', 'IL', 'IN', 'OH', 'IA', 'MI', 'KY', 'WV'];
export const N_EAST = ['N_EAST_All', 'NY', 'MD', 'DE', 'PA', 'VA', 'NJ'];
export const FAR_N_EAST = ['FAR_N_EAST_All', 'VT2', 'ME', 'CT', 'MA', 'RI', 'NH'];

export enum SUBJECT {
  RESET_ALL = 'Reset All',
  AT_LEAST = 'At Least One From/To Destinations Are Required For Creating',
  NO_PERMISSION = 'You Have No Permission For Creating',
  BOTH = 'Both',
  TO = 'To',
  FROM = 'From',
  ARE_YOU_SURE = 'Are You Sure',
  DELETE = 'Delete',
  CLOSE = 'Close',
  CREATE = 'Create',
  COORDINATOR = 'Coordinator',
  CREATE_SEARCH = 'Create Search',
  CANCEL = 'Cancel',
  TRANSIT = 'Transit',
  OF_BOARD = 'Off Board',
  ON_BOARD = 'On Board',
  BACK_TO_BOARD = 'Back To Board',
  SAVE = 'Save',
  EDIT = 'Edit',
  CREATE_REQUEST = 'Create Request',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  SEND_REQUEST = 'Send Request',
  STAY_HERE = 'Stay Here',
  CHECK_EMAIL = 'Please Check Your Email',
}

export const MOCK_CARRIER_INFO_DATA = {
  name: 'Stefan',
  contactName: 'Zella',
  secondNumber: '404-404-404-2421',
  phoneNumber: '6464-4545-4545-345',
  mc: '23456789',
  factored: 'No',
  rating: '4',
  percent: '3.4%',
  carrierType: 'Inside',
  fax: '44 5555 7878 99945',
  day: '3',
  payment: 'Quick pay',
  coordinator: 'Erik',
};
export const getSelectedValues = (truckValues: ICardData) => {
  const selectedValues: Partial<ICardData> = {};
  const filteredValues = Object.keys(truckValues).filter(key => !ALL_FIELDS.includes(key));
  filteredValues.forEach(key => {
    if (truckValues[key]) {
      selectedValues[key] = truckValues[key];
    }
  });
  return selectedValues;
};

export const searchResultFilterDefaultValues = {
  equipmentType: [],
  trailerDetails: [],
  status: '1',
};

export const truckBoardTabColumnsMap: ITruckBoardColumns = {
  1: 'truckBoardSearchResultOnOfBoard',
  2: 'truckBoardSearchResultOnOfBoard',
  3: 'truckBoardSearchResultTransit',
};

export enum truckBoardColumns {
  VERIFICATION_BOARD = 'verificationBoard',
  TRUCK_BOARD = 'truckBoard',
  FAVORITE = 'truckBoardFavoriteTrucks',
  CARRIER_REQUEST = 'truckBoardCarriersRequest',
  ON_OF_BOARD = 'truckBoardSearchResultOnOfBoard',
  TRANSIT = 'truckBoardSearchResultTransit',
}
