import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { CreditCheckTabs, IComponent } from 'pages/profile/constants/constants';

import CloseIcon from '../../components/svgs/CloseIcon';
import DangerIcon from '../../components/svgs/DangerIcon';
import SuccessIcon from '../../components/svgs/SuccessIcon';
import { usePermittedActions } from '../../hooks/usePermittedActions';
import { useExportMcAndCustomerMutation, useExportNewCreditMutation } from '../../services/exports/exports';
import { selectUserPermissionsInfo } from '../../store/user-slice/selector';
import { notificationKey } from '../../utils/constants';
import { ServerErrorCodes } from '../../utils/errors';
import { SUBJECT } from '../loads/constants/constants';

import McAndCustomers from './tabs/mc-and-customers/McAndCustomers';
import NewCredit from './tabs/new-credit/NewCredit';

const useCreditCheck = () => {
  const [exportNewCredit] = useExportNewCreditMutation();
  const [exportMcAndCustomer] = useExportMcAndCustomerMutation();

  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab } = searchParams;

  const isButtonVisible = true;
  const newCreditPagePermissions = usePermittedActions('creditCheck.newCredit');
  const mcAndCustomersPagePermissions = usePermittedActions('creditCheck.mcAndCustomers');

  const tabs: any = {
    new_credit: newCreditPagePermissions,
    mc_and_customers: mcAndCustomersPagePermissions,
  };

  const hasDownloadPermission = tabs?.[tab]?.download;

  const [excelParams, setExcelParams] = useState<any>();
  const downloadExcel = () => {
    const exportFunctionMap: Record<string, any> = {
      [CreditCheckTabs.newCredit]: exportNewCredit,
      [CreditCheckTabs.mcCustomers]: exportMcAndCustomer,
    };

    const exportFunction = exportFunctionMap[tab];

    exportFunction(excelParams).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.CHECK_EMAIL,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };
  const openMainDrawer = (mode: string) => {
    setSearchParams({
      ...searchParams,
      mode,
      open: 'true',
    });
  };

  const Component: IComponent = {
    mc_and_customers: <McAndCustomers setExcelParams={setExcelParams} />,
    new_credit: <NewCredit setExcelParams={setExcelParams} />,
  };

  return {
    tab,
    openMainDrawer,
    downloadExcel,
    Component,
    isButtonVisible,
    permissionsInfo,
    hasDownloadPermission,
  };
};
export default useCreditCheck;
