export enum SUBJECT {
  ADD_AUTHORITIES = 'Add authorities',
  NAME = 'Name',
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  USERNAME = 'Username',
  TYPE = 'Type',
  MC_NUMBER = 'MC Number',
  AUTHORITY_ID = 'Authority ID',
  Id = 'ID',
  NO_DATA = 'No Results Found',
}
