import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Divider, Row } from 'antd';
import { ICarrierType, RatingAccessTypes } from 'services/profile/carriers/interfaces';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Rate from 'ui/rate/Rate';
import { formatDateBirth } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import CustomComment from 'components/custom-comment/CustomComment';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from '../../constants/constants';

import { ICarrierInfoProps } from './constants/types';
import { useCarrierInfo } from './useCarrierInfo';

import styles from './CarrierInfo.module.scss';

const CarrierInfo = ({
  carrierCommentsInfo,
  carrierInfo,
  createComment,
  deleteComment,
  getMoreComments,
  isShowMoreVisible,
  isCommentFetching,
  userInfo,
  hasDeleteCommentPermission,
  carrierRequestInfo,
}: ICarrierInfoProps) => {
  const { watch, setValue, rateList, getCarrierRatings, handleDeleteRate, handleCarrierRate, control } = useCarrierInfo(
    { carrierInfo }
  );

  return (
    <>
      <MainLoader isFetching={isCommentFetching} />
      <ViewItemWrapper title={SUBJECT.CARRIER_INFO}>
        <Row gutter={[33, 0]} align="middle">
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.COMPANY_NAME_COLON}</span>{' '}
            <span className="top-banner-value">{carrierInfo?.companyName || carrierRequestInfo?.companyName}</span>
          </Col>
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.CARRIER_ID_COLON}</span>{' '}
            <span className="top-banner-value"> {carrierInfo?.id || carrierRequestInfo?.id}</span>
          </Col>
          {(carrierInfo?.mc || carrierRequestInfo?.mc) && (
            <Col span={8}>
              <span className="top-banner-title">{SUBJECT.MC_COLON}</span>{' '}
              <span className="top-banner-value">{carrierInfo?.mc || carrierRequestInfo?.mc}</span>
            </Col>
          )}
        </Row>
        <Row gutter={[33, 0]} className={styles.columnsGap} align="middle">
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.CONTACT_NAME_COLON}</span>{' '}
            <span className="top-banner-value">{carrierInfo?.contactName || carrierRequestInfo?.contactName}</span>
          </Col>
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.CARRIER_TYPE_COLON}</span>{' '}
            <span className="top-banner-value">{ICarrierType[carrierInfo?.type] || carrierRequestInfo?.type}</span>
          </Col>
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.FACTORED_COLON}</span>{' '}
            <span className="top-banner-value">
              {carrierInfo?.factoringType === 1 ? 'Yes' : carrierInfo?.factoringType === 2 ? 'No' : ''}
            </span>
          </Col>
        </Row>
        <Row gutter={[33, 0]} className={styles.columnsGap} align="middle">
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.PHONE_NUMBER_COLON} </span>
            <Link className="top-banner-value" to={`tel:${carrierInfo?.phone || carrierRequestInfo?.phone}`}>
              {formatPhone(carrierInfo?.phone || carrierRequestInfo?.phone)}
            </Link>
          </Col>
          {carrierInfo?.fax && (
            <Col span={8}>
              <span className="top-banner-title">{SUBJECT.FAX_COLON}</span>
              <span className="top-banner-value">{carrierInfo?.fax}</span>
            </Col>
          )}
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.RATING_COLON}</span>
            <Rate
              canRate={carrierInfo?.accessRating}
              averageValue={carrierInfo?.rating}
              handleRate={handleCarrierRate}
              getRatings={getCarrierRatings}
              isFirstTime={carrierInfo?.accessRating === RatingAccessTypes.ADD_RATING}
              rateCount={carrierInfo?.ratingCount}
              rateList={rateList}
              handleDeleteRate={handleDeleteRate}
            />
          </Col>
        </Row>
        {carrierInfo?.secondPhone && (
          <Row className={styles.columnsGap} align="middle">
            <Col span={24}>
              <span className="top-banner-title">{SUBJECT.SECOND_NUMBER_COLON}</span>
              <Link className="top-banner-value" to={`tel:${carrierInfo?.secondPhone}`}>
                {' '}
                {formatPhone(carrierInfo?.secondPhone)}
              </Link>
            </Col>
          </Row>
        )}
        <Divider className={styles.divider} />
        {/* TODO Add after creating new payment term type */}

        {/* <Row gutter={[33, 0]} align="middle">
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.PAYMENT_COLON} </span>
            <span className="top-banner-value">{IPaymentType[carrierInfo?.paymentTermType]}</span>
          </Col>
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.DAY_COLON}</span>{' '}
            <span className="top-banner-value">
              {carrierInfo?.paymentTermType === 1 ? '3-5 days' : carrierInfo?.paymentTermType === 2 ? '30 days' : ''}
            </span>
          </Col>
          <Col span={8}>
            <span className="top-banner-title">{SUBJECT.PERCENT_COLON} </span>
            <span className="top-banner-value">
              {carrierInfo?.paymentTermType === 1 ? '3.5' : carrierInfo?.paymentTermType === 2 ? '0' : ''}%
            </span>
          </Col>
        </Row> */}
        {!!carrierInfo?.coordinator?.length && (
          <>
            <Divider className={styles.divider} />
            <Row className={styles.columnsGap} align="middle">
              <Col span={24}>
                <span className="top-banner-title">{SUBJECT.COORDINATOR_COLON} </span>
                <span className="top-banner-value">Mikel</span>
              </Col>
            </Row>
          </>
        )}
        {!!carrierInfo?.cargo && (
          <>
            <Divider className={styles.divider} />
            <Row gutter={[33, 0]} align="middle">
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.INSURANCE_LIABILITY}</span>
                <span className="top-banner-value">{formatDateBirth(carrierInfo?.liability)}</span>
              </Col>
              <Col span={8}>
                <span className="top-banner-title">{SUBJECT.INSURANCE_CARGO}</span>
                <span className="top-banner-value">{formatDateBirth(carrierInfo?.cargo)}</span>
              </Col>
              {!!carrierInfo?.cargoCoverageValue && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.CARGO_COVERAGE_VALUE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.cargoCoverageValue, true)}
                  </span>
                </Col>
              )}
            </Row>
            {(!!carrierInfo?.nonOwnedCoverage || !!carrierInfo?.trailerInterchange || carrierInfo?.reeferBreakdown) && (
              <Divider className={styles.divider} />
            )}
            <Row gutter={[33, 0]} align="middle">
              {!!carrierInfo?.nonOwnedCoverage && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.NON_OWNED_COVERAGE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.nonOwnedCoverage, true)}
                  </span>
                </Col>
              )}
              {!!carrierInfo?.trailerInterchange && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.TRAILER_INTERCHANGE + ':'}</span>{' '}
                  <span className="top-banner-value">
                    {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.trailerInterchange, true)}
                  </span>
                </Col>
              )}
              {!!carrierInfo?.reeferBreakdown && (
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.REEFER_BREAKDOWN + ':'}</span>{' '}
                  <span className="top-banner-value">{SUBJECT.CAN_HAUL}</span>
                </Col>
              )}
            </Row>
          </>
        )}
      </ViewItemWrapper>
      <div className={styles.commentSection}>
        <CustomComment
          id="comment"
          watch={watch}
          name="comment"
          setValue={setValue}
          control={control}
          comments={carrierCommentsInfo}
          createComment={createComment}
          deleteComment={deleteComment}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          canCreate={!userInfo?.agentType}
          canDelete={!userInfo?.agentType}
          hasDeleteCommentPermission={hasDeleteCommentPermission}
          isLoadsCommentsLoading={isCommentFetching}
        />
      </div>
    </>
  );
};

export default CarrierInfo;
