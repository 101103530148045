import { ReactNode } from 'react';
import { Col, Row } from 'antd';

import LeftPart from './components/left-part/LeftPart';
import { SUBJECT } from './constants/constants';

import styles from './AuthLayout.module.scss';

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.layoutWrapper}>
      <Row justify="center">
        <Col className={styles.layoutLeft} span={8}>
          <LeftPart />
        </Col>
        <Col lg={16} md={24}>
          <Row align="middle" justify="center">
            <Col span={24}>
              <div className={styles.componentsWrapper}>{children}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.designedInfo}>
        <a href="https://aimit.company/" target="_blank">
          {SUBJECT.CREATOR_INFO}
        </a>
      </div>
    </div>
  );
};

export default AuthLayout;
