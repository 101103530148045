import { FieldValues, Path, PathValue } from 'react-hook-form';
import { Col, Divider, Row, Tabs } from 'antd';
import clsx from 'clsx';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import { SUBJECT } from './constants/constants';
import { ICustomCommentProps } from './constants/types';
import { TextArea } from './hoc-instance/instance';
import useCustomComment from './useCustomComment';

import styles from './CustomComment.module.scss';

const CustomComment = <T extends FieldValues>({
  isBlockCanceled,
  comments,
  loadHistory,
  activeSection,
  id,
  name,
  control,
  watch,
  required = false,
  setValue,
  createComment,
  deleteComment,
  withNotes = false,
  drawerRef,
  canCreate = true,
  canDelete = true,
  setIsDefaultHistory = (_mode: boolean) => {
    return;
  },
  getMoreComments = () => {
    return;
  },
  isShowMoreVisible,
  hasDeleteCommentPermission,
  inView = false,
  getMoreNotes,
  isShowMoreVisibleNotes,
  isDefaultHistory = false,
  isFetchingHistory,
  isLoadsCommentsLoading,
}: ICustomCommentProps<T>) => {
  const { handleAddComment, items, handleChangeTab, activeTab } = useCustomComment<T>(
    comments,
    loadHistory,
    name,
    setValue,
    createComment,
    deleteComment,
    withNotes,
    getMoreComments,
    isShowMoreVisible,
    drawerRef,
    canDelete,
    hasDeleteCommentPermission,
    getMoreNotes,
    isShowMoreVisibleNotes,
    inView,
    isDefaultHistory,
    setIsDefaultHistory,
    isFetchingHistory,
    isLoadsCommentsLoading
  );

  return (
    <div className={styles.customCommentMainWrapper}>
      <div
        id={id}
        className={clsx(styles.commentWrapper, {
          [styles.active]: activeSection === `${id}`,
        })}
      >
        {canCreate && (
          <div
            className={clsx(styles.commentHeader, {
              ['canceled-block']: isBlockCanceled,
            })}
          >
            <div className={styles.addCommentWrapper}>
              <Row gutter={16} align="bottom">
                <Col span={19}>
                  <div className={styles.textArea}>
                    <label className={styles.label}>{SUBJECT.COMMENT}</label>
                    <TextArea
                      autoSize
                      label={SUBJECT.PLACEHOLDER}
                      isFilled={!!watch(name as Path<T>)}
                      name={name}
                      control={control}
                      rules={{ required }}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setValue(name as Path<T>, e.target.value as PathValue<string, string>, { shouldDirty: false })
                      }
                    />
                  </div>
                </Col>
                <Col span={5}>
                  <div>
                    <Button
                      variant="comment"
                      disabled={!watch(name as Path<T>)?.length}
                      children={SUBJECT.ADD_COMMENT}
                      onClick={() => handleAddComment(watch(name as Path<T>))}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
        <div
          className={clsx(styles.commentNotificationBody, {
            ['canceled-block']: isBlockCanceled,
            [styles.noNotification]: !withNotes,
          })}
        >
          {inView ? (
            <>
              <Typography variant="heading" children={SUBJECT.COMMENTS} className={styles.heading} />
              <Divider />
            </>
          ) : (
            <Typography variant="paragraph" className={styles.addedTitle}>
              {SUBJECT.ADDED}
            </Typography>
          )}
          <Tabs
            onChange={key => handleChangeTab(key)}
            items={items}
            destroyInactiveTabPane={true}
            activeKey={activeTab}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomComment;
