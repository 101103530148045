import { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import ResetIcon from 'components/svgs/ResetIcon';
import Table from 'components/table/Table';

import { customerTypeOptions, SUBJECT } from '../../constants/constants';
import { FilterSelect } from '../../hoc-instance/instance';

import { ICustomersTableProps } from './constants/types';
import useCustomersTable from './useCustomerTable';

import styles from '../../Customers.module.scss';

const CustomersTable = ({
  filterControl,
  setFilterValue,
  filterReset,
  filterValue,
  filterWatch,
  handleCommentClick,
  data,
  isLoading,
  handlePaginationClick,
  handleSortClick,
  editColumns,
  columns,
  setCustomersFilter,
  skip,
  limit,
}: ICustomersTableProps) => {
  const {
    filterData,
    handleRowClick,
    handleResetFilter,
    handleRowEdit,
    handleSwitchClick,
    handleStopResize,
    handleColumnSearch,
    customerTablePagePermissions: { edit, terminateOn, terminateOff },
  } = useCustomersTable({
    setFilterValue,
    filterReset,
    filterValue,
    filterWatch,
    editColumns,
    setCustomersFilter,
  });

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    isLoading,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onSwitchClick: handleSwitchClick,
    onColumnSearch: handleColumnSearch,
    handleRowEdit,
    onCommentClick: handleCommentClick,
    onPaginationClick: handlePaginationClick,
    hasTerminateOnPermission: terminateOn,
    hasTerminateOffPermission: terminateOff,
    hasEditPermission: edit,
    withMenu: edit,
  });

  return (
    <>
      <div className={styles.customersContainer}>
        <div className={styles.customersTopBlock}>
          <div className={styles.customersMainSelect}>
            <Row align="middle" gutter={[18, 0]}>
              <Col span={14}>
                <Row align="middle" gutter={[15, 0]}>
                  <Col span={8}>
                    <FilterSelect
                      withAll
                      withCheckbox
                      isMultiSelect
                      options={customerTypeOptions}
                      control={filterControl}
                      label={SUBJECT.CUSTOMER_TYPE}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name={SUBJECT.CUSTOMER_FILTER_TYPE}
                      isFilter
                    />
                  </Col>
                  {!!filterData?.length && (
                    <Col>
                      <div className={styles.customerSearchClear}>
                        <Button
                          icon={<ResetIcon />}
                          variant="ghost"
                          onClick={handleResetFilter}
                          children={SUBJECT.RESET_ALL}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        {!isLoading && !data && (
          <div className={styles.customersEmpty}>
            <Typography variant="paragraph">{SUBJECT.IS_EMPTY}</Typography>
          </div>
        )}
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default CustomersTable;
