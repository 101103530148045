import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { useOutsideDetect } from 'hooks/useOutsideDetect';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import ArrowDown from 'components/svgs/ArrowDown';
import ArrowUp from 'components/svgs/ArrowUp';
import ResetIcon from 'components/svgs/ResetIcon';
import TimeIcon from 'components/svgs/TimeIcon';

import { onlyNumbersFormatter } from '../../../utils/helpers';

import { FIFTY_NINE, SUBJECT, TWELVE } from './constants/constants';
import { ITimePickerProps } from './constants/types';

import styles from './TimePicker.module.scss';

const TimePicker = ({
  placeholder = '',
  onChange,
  required,
  setTimePeriod,
  disabled,
  initialValues,
  errors,
  name,
  onBlur,
}: ITimePickerProps) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const [hours, setHours] = useState<number | null | string>(null);
  const [openTimePicker, setOpenTimePicker] = useState<boolean>(false);
  const [minutes, setMinutes] = useState<number | null | string>(null);
  const [amPm, setAmPm] = useState<SUBJECT.AM | SUBJECT.PM>(SUBJECT.AM);

  const ref = useOutsideDetect<HTMLDivElement>(() => {
    setOpenDropdown(false);
    setOpenTimePicker(false);
    setHoursInputValue(defineHours());
    setMinutesInputValue(defineMinutes());
  });

  const [hoursInputValue, setHoursInputValue] = useState<any>('');
  const [minutesInputValue, setMinutesInputValue] = useState<any>('');

  const hoursRef = useRef<HTMLInputElement | null>(null);
  const minutesRef = useRef<HTMLInputElement | null>(null);

  const hoursINRef = useRef<HTMLInputElement | null>(null);
  const minutesINRef = useRef<HTMLInputElement | null>(null);

  const timeRef = useRef<HTMLInputElement | null>(null);
  const timeInRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setHoursInputValue(defineHours());
    setMinutesInputValue(defineMinutes());
  }, []);

  useEffect(() => {
    setMinutesInputValue(minutes);
  }, [minutes]);

  useEffect(() => {
    setHoursInputValue(hours);
  }, [hours]);

  useEffect(() => {
    if (Object.keys(initialValues)?.length) {
      const hour =
        initialValues.appointmentTime?.split(':')[0].length === 1
          ? `0${initialValues.appointmentTime?.split(':')[0]}`
          : initialValues.appointmentTime?.split(':')[0];

      const minute =
        initialValues.appointmentTime?.split(':')[1].length === 1
          ? `0${initialValues.appointmentTime?.split(':')[1]}`
          : initialValues.appointmentTime?.split(':')[1];

      setHours(hour);
      setMinutes(minute);
      setAmPm(initialValues.timePeriod);
      setTimePeriod && setTimePeriod(initialValues.timePeriod);
    }
  }, [initialValues]);

  useEffect(() => {
    if (onChange)
      onChange(`${(hoursInputValue && hoursInputValue) || null}:${minutesInputValue ? minutesInputValue : null}`);
  }, [hoursInputValue, minutesInputValue]);

  const defineHours = () => {
    if (hours?.toString().length === 1 && hours?.toString() === '1') {
      return `0${hours?.toString()}`;
    }
    if (hours?.toString().length === 1 && hours?.toString() !== '0') {
      return `0${hours?.toString()}`;
    }
    return hours?.toString();
  };

  const defineMinutes = () => {
    if (minutes?.toString().length === 1) {
      return `0${minutes?.toString()}`;
    }
    if (minutes?.toString().length === 1 && Number(minutes) > 5) {
      return `0${minutes?.toString()}`;
    }
    return minutes?.toString();
  };

  const handleOpen = () => {
    if (!disabled) setOpenDropdown(prev => !prev);
  };

  const handleHourChange = (event: any) => {
    const isInserted = event?.nativeEvent?.inputType === 'insertText';
    const value = onlyNumbersFormatter(event?.target?.value);
    if (Number(value) > 1) {
      setHours(`0${Number(String(value)?.[0])}`);
      setHoursInputValue(`0${Number(String(value)?.[0])}`);
      if (openDropdown) {
        setTimeout(() => {
          minutesINRef?.current?.focus();
        }, 10);
      } else {
        setTimeout(() => {
          minutesRef?.current?.focus();
        }, 10);
      }
    } else if (Number(value) === 1 && String(value) === '01') {
      setHours(value);
      setHoursInputValue(value);
      if (openDropdown) {
        setTimeout(() => {
          minutesINRef?.current?.focus();
        }, 10);
      } else {
        setTimeout(() => {
          minutesRef?.current?.focus();
        }, 10);
      }
    } else {
      setHours(value);
      setHoursInputValue(value);
    }
    if (String(value).length > 2) {
      return;
    }
    if (Number(value) < 0) {
      setHours(0);
      return;
    }

    if (value === '') {
      setHours(null);
    } else if (Number(value) <= TWELVE) {
      if (hours == 0 && value == '00') {
        setHours(0);
      } else {
        setHours(value);
        if (!(Number(value) < 2)) {
          if (!isInserted) {
            return;
          }
          if (openDropdown) {
            setTimeout(() => {
              minutesINRef?.current?.focus();
            }, 10);
          } else {
            setTimeout(() => {
              minutesRef?.current?.focus();
            }, 10);
          }
          return;
        }
      }
    }
  };
  const handleMinuteChange = (event: any) => {
    const value = onlyNumbersFormatter(event?.target?.value);
    if (Number(String(value)) > 5) {
      setMinutes(`0${Number(String(value)?.[0])}`);
      setMinutesInputValue(`0${Number(String(value)?.[0])}`);
      if (openDropdown) {
        setTimeout(() => {
          timeInRef?.current?.focus();
          timeRef?.current?.select();
        }, 100);
      } else {
        setTimeout(() => {
          timeRef?.current?.focus();
          timeRef?.current?.select();
        }, 100);
      }
    } else {
      setMinutes(value);
      setMinutesInputValue(value);
    }
    if (String(value).length > 2) {
      return;
    }
    if (Number(value) < 0) {
      setMinutes(0);
      return;
    }
    if (value === '') {
      setMinutes(null);
      return;
    }
    setMinutes(value);
    if (!(String(value).length === 1 && Number(value) < 6)) {
      if (openDropdown) {
        setTimeout(() => {
          timeInRef?.current?.focus();
          timeRef?.current?.select();
        }, 100);
      } else {
        setTimeout(() => {
          timeRef?.current?.focus();
          timeRef?.current?.select();
        }, 100);
      }
    }
  };

  const handleArrowClick = (direction: string, input: string) => {
    if (direction === SUBJECT.UP) {
      if (input === SUBJECT.HOUR) {
        setHours(prev => (Number(prev) == TWELVE ? 1 : Number(prev)! + 1));
      } else if (input === SUBJECT.MINUTE) {
        setMinutes(prev => (Number(prev) == FIFTY_NINE ? '0' : Number(prev)! + 1));
      } else {
        setAmPm(prev => (prev === SUBJECT.AM ? SUBJECT.PM : SUBJECT.AM));
        if (setTimePeriod) {
          setTimePeriod((prev: string) => (prev === SUBJECT.AM ? SUBJECT.PM : SUBJECT.AM));
        }
      }
    } else {
      if (input === SUBJECT.HOUR) {
        setHours(prev => ((prev && Number(prev) == 1) || !prev ? TWELVE : Number(prev)! - 1));
      } else if (input === SUBJECT.MINUTE) {
        setMinutes(prev => {
          return Number(prev) == 0 ? FIFTY_NINE : Number(prev)! - 1;
        });
      } else {
        setAmPm(prev => (prev === SUBJECT.AM ? SUBJECT.PM : SUBJECT.AM));
        if (setTimePeriod) {
          setTimePeriod((prev: string) => (prev === SUBJECT.AM ? SUBJECT.PM : SUBJECT.AM));
        }
      }
    }
  };
  const handleCancel = () => {
    resetInputValues();
    setOpenDropdown(false);
    setOpenTimePicker(true);
  };

  const resetInputValues = () => {
    setHours(null);
    setMinutes(null);
    setAmPm(SUBJECT.AM);
    if (setTimePeriod) {
      setTimePeriod(SUBJECT.AM);
    }
    if (onChange) {
      onChange('');
    }
    setTimeout(() => {
      hoursRef.current?.focus();
    }, 500);
  };

  const handleOkClick = () => {
    setOpenDropdown(false);
    setHoursInputValue(defineHours());
    setMinutesInputValue(defineMinutes());
  };

  const onInputClick = () => {
    if (hoursInputValue || minutesInputValue) {
      return;
    }
    if (disabled) {
      return;
    }
    setOpenTimePicker(true);
    setTimeout(() => {
      hoursRef.current?.focus();
    }, 500);
  };

  const handleTimeChange = (withMark: boolean) => {
    setAmPm(prev => (prev === SUBJECT.AM ? SUBJECT.PM : SUBJECT.AM));
    if (setTimePeriod) {
      setTimePeriod((prev: string) => (prev === SUBJECT.AM ? SUBJECT.PM : SUBJECT.AM));
    }
    if (withMark) {
      if (openDropdown) {
        setTimeout(() => {
          timeInRef.current?.select();
        }, 100);
      } else {
        setTimeout(() => {
          timeRef.current?.select();
        }, 100);
      }
    }
  };

  const getRef = (input: any): any => {
    if (input === SUBJECT.HOUR) {
      if (openDropdown) {
        return hoursINRef;
      } else {
        return hoursRef;
      }
    } else if (openDropdown) {
      return minutesINRef;
    } else {
      return minutesRef;
    }
  };

  const getFocusedRef = (input: any): any => {
    if (input === SUBJECT.HOUR || input === SUBJECT.TIME) {
      if (openDropdown) {
        return minutesINRef;
      } else {
        return minutesRef;
      }
    } else if (openDropdown) {
      return hoursINRef;
    } else {
      return hoursRef;
    }
  };

  const handleKeyDown = (event: any, input: any) => {
    if (input === SUBJECT.TIME) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        handleTimeChange(true);
      } else if (event.key === 'ArrowLeft') {
        getFocusedRef(input)?.current?.focus();
        getFocusedRef(input)?.current.setSelectionRange(
          String(minutesInputValue).length,
          String(minutesInputValue).length
        );
      }
    }

    if (input === SUBJECT.MINUTE || input === SUBJECT.HOUR) {
      if (event.key === 'ArrowUp') {
        handleArrowClick(SUBJECT.UP, input);
      } else if (event.key === 'ArrowDown') {
        handleArrowClick(SUBJECT.DOWN, input);
      } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Backspace') {
        const cursorPosition = getRef(input)?.current.selectionStart;
        if (cursorPosition == String(hoursInputValue).length && input === SUBJECT.HOUR && event.key === 'ArrowRight') {
          setTimeout(() => {
            getFocusedRef(input)?.current?.focus();
            getFocusedRef(input)?.current.setSelectionRange(0, 0);
          }, 500);
        } else if (
          cursorPosition == 0 &&
          input === SUBJECT.MINUTE &&
          (event.key === 'ArrowLeft' || event.key === 'Backspace')
        ) {
          setTimeout(() => {
            getFocusedRef(input)?.current?.focus();
            getFocusedRef(input)?.current.setSelectionRange(
              String(hoursInputValue).length,
              String(hoursInputValue).length
            );
          }, 500);
        } else if (
          cursorPosition == String(minutesInputValue).length &&
          input === SUBJECT.MINUTE &&
          event.key === 'ArrowRight'
        ) {
          if (openDropdown) {
            timeInRef.current?.focus();
            setTimeout(() => {
              timeInRef.current?.select();
            }, 100);
          } else {
            timeRef.current?.focus();
            setTimeout(() => {
              timeRef.current?.select();
            }, 100);
          }
        }
      }
    }
  };

  return (
    <Tooltip placement="top" color="#fff" title={disabled ? 'Please select Appointment date' : null}>
      <div
        className={clsx(styles.timePickerWrapper, {
          [styles.disabled]: disabled,
          [styles.errors]: errors?.[name || ''],
        })}
      >
        <div ref={ref}>
          <ul>
            <li>
              <div className={styles.showTime} role="button">
                <div
                  className={clsx(styles.timeWrapper, {
                    [styles.openedInput]: openDropdown,
                    [styles.required]: required,
                    [styles.filled]: hoursInputValue || minutesInputValue || openTimePicker,
                    [styles.hasValue]: hoursInputValue || minutesInputValue,
                  })}
                  onClick={onInputClick}
                >
                  <input
                    className={styles.input}
                    value={hoursInputValue || ''}
                    onInput={handleHourChange}
                    onKeyDown={e => handleKeyDown(e, SUBJECT.HOUR)}
                    maxLength={2}
                    ref={hoursRef}
                    onBlur={() => {
                      onBlur && onBlur();
                      setHoursInputValue(defineHours());
                    }}
                    placeholder={SUBJECT.DASHES}
                    disabled={openDropdown}
                  />
                  <span>:</span>
                  <input
                    className={styles.input}
                    value={minutesInputValue || ''}
                    onInput={handleMinuteChange}
                    disabled={openDropdown}
                    onKeyDown={e => handleKeyDown(e, SUBJECT.MINUTE)}
                    ref={minutesRef}
                    maxLength={2}
                    onBlur={() => {
                      onBlur && onBlur();
                      setMinutesInputValue(defineMinutes());
                    }}
                    placeholder={SUBJECT.DASHES}
                  />
                  <div className={styles.amPmWrapper}>
                    <input
                      className={styles.inputTime}
                      value={amPm || ''}
                      onKeyDown={e => handleKeyDown(e, SUBJECT.TIME)}
                      onClick={() => handleTimeChange(true)}
                      ref={timeRef}
                    />
                  </div>
                  <div role="button" className={styles.timeIcon} onClick={handleOpen}>
                    <TimeIcon />
                  </div>
                  {(!isNaN(Number(hoursInputValue)) || !isNaN(Number(minutesInputValue))) && (
                    <div role="button" onClick={resetInputValues} className={styles.resetButton}>
                      <ResetIcon />
                    </div>
                  )}
                  <Typography variant="paragraph" className={styles.placeholder}>
                    {placeholder}
                  </Typography>
                </div>
              </div>
              {openDropdown && (
                <div
                  className={clsx(styles.dropdonwContainer, {
                    [styles.openedDropdown]: openDropdown,
                  })}
                >
                  <ul className={styles.dropdownWrapper}>
                    <li>
                      <div className={styles.inputWrapper}>
                        <Button
                          variant="text"
                          children={<ArrowUp />}
                          onClick={() => {
                            handleArrowClick(SUBJECT.UP, SUBJECT.HOUR);
                          }}
                        />
                        <input
                          name="showHours"
                          className={styles.input}
                          value={hoursInputValue || ''}
                          onInput={handleHourChange}
                          onKeyDown={e => handleKeyDown(e, SUBJECT.HOUR)}
                          maxLength={2}
                          ref={hoursINRef}
                          onBlur={() => {
                            onBlur && onBlur();
                            setHoursInputValue(defineHours());
                          }}
                          placeholder={SUBJECT.DASHES}
                        />
                        <Button
                          variant="text"
                          children={<ArrowDown />}
                          onClick={() => {
                            handleArrowClick(SUBJECT.DOWN, SUBJECT.HOUR);
                          }}
                        />
                      </div>
                    </li>
                    <li>
                      <div className={styles.inputWrapper}>
                        <Button
                          variant="text"
                          children={<ArrowUp />}
                          onClick={() => {
                            handleArrowClick(SUBJECT.UP, SUBJECT.MINUTE);
                          }}
                        />
                        <input
                          name="showMinutes"
                          className={styles.input}
                          value={minutesInputValue || ''}
                          onInput={handleMinuteChange}
                          onKeyDown={e => handleKeyDown(e, SUBJECT.MINUTE)}
                          ref={minutesINRef}
                          maxLength={2}
                          onBlur={() => {
                            onBlur && onBlur();
                            setMinutesInputValue(defineMinutes());
                          }}
                          placeholder={SUBJECT.DASHES}
                        />
                        <Button
                          variant="text"
                          children={<ArrowDown />}
                          onClick={() => {
                            handleArrowClick(SUBJECT.DOWN, SUBJECT.MINUTE);
                          }}
                        />
                      </div>
                    </li>
                    <li>
                      <div className={styles.inputWrapper}>
                        <Button
                          variant="text"
                          children={<ArrowUp />}
                          onClick={() => {
                            handleArrowClick(SUBJECT.UP, SUBJECT.AM_PM);
                          }}
                        />
                        <div className={styles.amPmWrapper}>
                          <input
                            className={styles.inputTime}
                            value={amPm || ''}
                            onKeyDown={e => handleKeyDown(e, SUBJECT.TIME)}
                            ref={timeInRef}
                          />
                        </div>
                        <Button
                          variant="text"
                          children={<ArrowDown />}
                          onClick={() => {
                            handleArrowClick(SUBJECT.DOWN, SUBJECT.AM_PM);
                          }}
                        />
                      </div>
                    </li>
                  </ul>
                  <div className={styles.timePikerActions}>
                    <Row justify="space-between" align="middle">
                      <Col></Col>
                      <Col>
                        <div className={styles.butonsItems}>
                          <Button variant="ghost" children={SUBJECT.CANCEL} onClick={handleCancel} />
                          <Button children={SUBJECT.OK} onClick={handleOkClick} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </Tooltip>
  );
};

export default TimePicker;
