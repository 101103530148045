export interface IAgent {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phone: string;
}

export interface IOfficeBranch {
  id: number;
  name: string;
}

export interface IPaymentListItemResponse {
  charges: number;
  collection: number;
  createdAt: number;
  createdFor: number;
  credits: number;
  final: number;
  overallPay: number;
  grossProfit: number;
  id: number;
  agent: IAgent;
  office: IOfficeBranch;
  branch: IOfficeBranch;
  others: number;
  paidAs: string;
  paidAt: number;
  status: number;
  paidBy?: {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    phone: string;
    username: string;
  };
}
export interface IPaymentListResponse {
  count: number;
  total: number;
  result: IPaymentListItemResponse[];
}

export enum PaymentStatusTypes {
  PAYMENT_STATUS_UNSPECIFIED = 0,
  PAYMENT_STATUS_APPROVE = 1,
  PAYMENT_STATUS_PAY = 2,
  PAYMENT_STATUS_PENDING = 3,
}
