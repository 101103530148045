import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import dayjs from 'dayjs';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useFileUpload from 'hooks/useFileUpload';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import CreateEditEmployeeForm from 'pages/profile/tabs/employee/components/create-edit-form/CreateEditEmployeeForm';
import EditEmployeeForm from 'pages/profile/tabs/employee/components/edit-form/EditEmployeeForm';
import EmployeeView from 'pages/profile/tabs/employee/components/employee-view/EmployeeView';
import { defaultValues, filterDefaultValues, LEVEL, SUBJECT } from 'pages/profile/tabs/employee/constants/constants';
import { validation } from 'pages/profile/tabs/employee/constants/validation';
import { useGetPermissionActiveGroupsQuery } from 'services/permission/permission';
import { useCheckUserNameQuery, useInviteAgentAgainMutation } from 'services/profile/agents/agents';
import {
  useAddNewFileEmployeeMutation,
  useChangeStatusMutation,
  useDeleteFileFromEmployeeMutation,
  useGetEmployeesByIdQuery,
  useGetEmployeesQuery,
  useInviteEmployeeMutation,
  useUpdateEmployeeMutation,
} from 'services/profile/employees/employees';
import { IEmployessParams } from 'services/profile/employees/interface';
import { useGetStatesQuery } from 'services/states/states';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { AgentTypes, EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatPhone } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { IEmployeeFilter, IEmployeeFormData, IEmployeeParams, IEmployeeTableColumns } from './constants/types';

const useEmployee = ({ setExcelParams }: IEmployeeParams) => {
  const dispatch = useDispatch();
  const { employeeLevel, agentType, userType } = useSelector(selectUserPermissionsInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, permission, activePage, id, open } = searchParams;

  const [employeesFilters, setEmployeesFilters] = useState<Partial<IEmployessParams>>({
    level: 2,
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const {
    data = { result: [] },
    isFetching,
    refetch: refetchEmployeeList,
  } = useGetEmployeesQuery(employeesFilters, {
    skip: employeesFilters.level === 0,
  });

  const { data: statesData } = useGetStatesQuery({}, { skip: mode === 'view' });
  const { currentData: employeeById, isFetching: isFetchingById } = useGetEmployeesByIdQuery(id, { skip: !id });

  const [addNewFile] = useAddNewFileEmployeeMutation();
  const [deleteFile] = useDeleteFileFromEmployeeMutation();

  const [inviteEmployee, { isLoading: isCreateLoading }] = useInviteEmployeeMutation();
  const [updateEmployee, { isLoading: isEditLoading }] = useUpdateEmployeeMutation();
  const [changeStatus] = useChangeStatusMutation();

  const columnTypes = {
    office: 'employeesOffice',
    branch: 'employeesBranch',
    corporate: 'employees',
  };
  const { data: columnsData } = useGetColumnsQuery({ type: columnTypes[activePage as keyof typeof columnTypes] });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();
  const [inviteEmployeeAgain] = useInviteAgentAgainMutation();

  const { currentData: permissionOptions, isFetching: isFetchingGroups } = useGetPermissionActiveGroupsQuery({});

  const [username, setUsername] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const { currentData: isUserNameExists } = useCheckUserNameQuery(
    { username: searchTerm, id },
    { skip: !searchTerm || searchTerm === employeeById?.result?.username }
  );

  useEffect(() => {
    if (employeeById?.result?.username) {
      setUsername(employeeById?.result?.username);
    }
  }, [employeeById?.result?.username, isFetchingById]);

  useEffect(() => {
    if (username.length) {
      setSearchTerm(username);
    }
  }, [username, isFetchingById]);

  useEffect(() => {
    if (searchTerm === employeeById?.result?.username) {
      clearErrors();
    }
  }, [searchTerm]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    clearErrors,
    setValue,
    getValues,
    reset,
    setError,
    watch,
    trigger,
  } = useForm<IEmployeeFormData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(validation(searchTerm !== employeeById?.result?.username ? isUserNameExists : false)),
  });

  useEffect(() => {
    if (watch('username')?.length) trigger('username');
  }, [isUserNameExists]);

  const {
    control: filterControl,
    reset: filterReset,
    formState: { isDirty: isFilterDirty },
    watch: filterWatch,
    setValue: setFilterValue,
  } = useForm<IEmployeeFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  ///////////////////////////// work with files
  const {
    handleViewFileCreate,
    handleViewFileEdit,
    handleDeleteFileCreate,
    handleDeleteFileEdit,
    handleDownloadFileCreate,
    handleDownloadFileEdit,
    beforeUploadForCreate,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
  } = useFileUpload({ data: employeeById, setValue, getValues, id, trigger, deleteFile, addNewFile });

  /////////////////

  const handleClearDate = () => {
    setValue('dob', '');
  };
  useEffect(() => {
    if (mode === 'edit') {
      reset({
        firstName: employeeById?.result?.firstName,
        lastName: employeeById?.result?.lastName,
        username: employeeById?.result?.username,
        dob: employeeById?.result?.dateOfBirth
          ? dayjs(employeeById?.result?.dateOfBirth).tz('America/Los_Angeles').valueOf()
          : '',
        email: employeeById?.result?.email,
        phoneNumber: formatPhone(employeeById?.result?.phone),
        ext: employeeById?.result?.ext || '',
        title: employeeById?.result?.title,
        permissionGroup: permissionOptions?.result?.some(
          (item: { id: number; name: string }) => item.id === employeeById?.result?.permissionGroupId
        )
          ? employeeById?.result?.permissionGroupId
          : employeeById?.result?.permissionGroupName,
        address: employeeById?.result?.address,
        secondAddress: employeeById?.result?.secondAddress,
        city: employeeById?.result?.city,
        relation: employeeById?.result?.emergencyRelation,
        state: employeeById?.result?.stateId,
        zipcode: employeeById?.result?.zip,
        name: employeeById?.result?.emergencyName,
        phoneNumberEmergency: formatPhone(employeeById?.result?.emergencyPhone),
        file: savedFiles,
      });
      setAllFiles(savedFiles);
    } else {
      reset(defaultValues);
      setAllFiles([]);
    }
  }, [mode, open, id, savedFiles, employeeById, isFetchingGroups]);

  const employeesPagePermissions = usePermittedActions('profiles.employees');

  const permissionGroup = filterWatch('permissionGroupFilter');
  const permissionGroupQueryCheck = !!(typeof permissionGroup === 'string' && permissionGroup);

  const handleResetFilters = () => {
    filterReset();
    setSearchParams({
      ...searchParams,
      permission: '',
    });
    setEmployeesFilters(prev => ({ ...prev, filter: {} }));
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleRowEdit = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(permissionGroupQueryCheck ? { permission: permissionGroup } : {}),
      mode: 'edit',
      open: 'true',
      id: rowId,
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setEmployeesFilters(prev => {
      if (sortOrder === 0) {
        return {
          ...prev,
          level: LEVEL[activePageQuery],
          order: 2,
          orderBy: 'createdAt',
        };
      }
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setEmployeesFilters(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleSwitchClick = (checked: boolean, rowId: number | string) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({ id: rowId, status: checked ? 1 : 2 }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setEmployeesFilters(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const employeeTabs = useMemo(() => {
    const allTabs = [
      {
        label: SUBJECT.CORPORATE,
        key: 'corporate',
      },
      {
        label: SUBJECT.OFFICE,
        key: 'office',
      },
      {
        label: SUBJECT.BRANCH,
        key: 'branch',
      },
    ];

    return employeeLevel ? allTabs.slice(employeeLevel - 1) : agentType ? allTabs.slice(agentType) : allTabs;
  }, [data, isFetching, employeeLevel]);
  const activePageQuery = useMemo(() => {
    return activePage === undefined ? employeeTabs[0].key : activePage;
  }, [activePage]);

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    reset(defaultValues, { keepDirty: false });
    clearErrors();
  };

  const onSubmit: SubmitHandler<IEmployeeFormData> = values => {
    if (mode === 'edit' && !isEditLoading) {
      updateEmployee({ id, groupId: employeeById?.result?.permissionGroupId, ...values }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          setSearchTerm('');
        } else {
          notification.success({
            message: 'Successfully edited',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
          setUsername('');
          setSearchTerm('');
          refetchEmployeeList();
        }
      });
    } else if (mode === 'add' && !isCreateLoading) {
      inviteEmployee(values).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    }
  };

  const handleStopResize = (columns: IEmployeeTableColumns[]): void => {
    editColumns({ type: 'employees', columns });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (columnsData?.columns.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(permissionGroupQueryCheck ? { permission: permissionGroup } : {}),
      open: 'false',
      id: '',
    });
    dispatch(closeModal());
    reset(defaultValues);
  };
  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: columnTypes[activePage as keyof typeof columnTypes],
    });
  const handleCancel = () => {
    if (isDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CANCEL,
          onOk: isValid
            ? () => {
                onSubmit(watch());
                dispatch(closeModal());
                clearErrors();
              }
            : () => {
                dispatch(closeModal());
              },

          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(permissionGroupQueryCheck ? { permission: permissionGroup } : {}),
        open: 'false',
      });
      if (mode === 'edit') {
        resetForm();
      }
    }
  };

  const handleTabChange = (activePage: string) => {
    filterReset();
    setSearchParams({
      ...searchParams,
      activePage,
      open: 'false',
      mode: 'customize',
      permission: '',
    });
    setEmployeesFilters({
      level: 2,
      search: '',
      field: '',
      skip: 0,
      limit: 20,
      order: 2,
      orderBy: 'createdAt',
      filter: {},
    });
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(permissionGroupQueryCheck ? { permission: permissionGroup } : {}),

      activePage: activePageQuery,
    });
    setEmployeesFilters(prev => ({ ...prev, level: LEVEL[activePageQuery] }));
  }, [activePageQuery, permissionGroup]);

  useEffect(() => {
    if (permission) {
      setFilterValue('permissionGroupFilter', permission);
      setEmployeesFilters(prev => ({ ...prev, filter: { permission } }));
    }
  }, [permission]);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...employeesFilters,
        };
      });
    }
  }, [employeesFilters]);

  const getAllowedEditPermission = () => {
    if ((userType == UserTypes.ADMIN || employeeLevel == EmployeeLevels.CORPORATE) && employeesPagePermissions?.edit) {
      return !((activePage === 'office' || activePage === 'branch') && employeesPagePermissions?.edit);
    } else if (
      (agentType === AgentTypes.OWNER || employeeLevel == EmployeeLevels.OFFICE) &&
      employeesPagePermissions?.edit
    ) {
      return activePage !== 'branch';
    } else {
      return employeesPagePermissions?.edit;
    }
  };

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: employeesPagePermissions.create
      ? {
          component: (
            <CreateEditEmployeeForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              statesData={statesData}
              employeeById={employeeById}
              setError={setError}
              isDirty={isDirty}
              handleViewFileCreate={handleViewFileCreate}
              handleDeleteFileCreate={handleDeleteFileCreate}
              handleDownloadFileCreate={handleDownloadFileCreate}
              beforeUploadForCreate={beforeUploadForCreate}
              allFiles={allFiles}
              employeeId={id}
              isFetchingById={isFetchingById}
              setUsername={setUsername}
              handleClearDate={handleClearDate}
              mode={mode}
              isUserNameExists={isUserNameExists}
              employeeLevel={employeeLevel}
              permissionOptions={permissionOptions}
              trigger={trigger}
              isEditLoading={isEditLoading}
              isCreateLoading={isCreateLoading}
            />
          ),
          buttonText: SUBJECT.ADD_EMPLOYEE,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit: getAllowedEditPermission()
      ? {
          component: (
            <EditEmployeeForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              statesData={statesData}
              employeeById={employeeById}
              setError={setError}
              isDirty={isDirty}
              handleViewFileEdit={handleViewFileEdit}
              handleDeleteFileEdit={handleDeleteFileEdit}
              handleDownloadFileEdit={handleDownloadFileEdit}
              beforeUploadForEdit={beforeUploadForEdit}
              allFiles={allFiles}
              employeeId={id}
              isFetchingById={isFetchingById}
              handleNewFileView={handleViewFileCreate}
              handleNewFileDownload={handleDownloadFileCreate}
              setUsername={setUsername}
              handleClearDate={handleClearDate}
              mode={mode}
              isUserNameExists={isUserNameExists}
              employeeLevel={employeeLevel}
              permissionOptions={permissionOptions}
              trigger={trigger}
              isEditLoading={isEditLoading}
              isCreateLoading={isCreateLoading}
            />
          ),
          buttonText: SUBJECT.EDIT_BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    view: {
      component: (
        <EmployeeView
          data={employeeById}
          isFetchingById={isFetchingById}
          pagePermissions={employeesPagePermissions}
          getAllowedEditPermission={getAllowedEditPermission}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    isDirty,
    statesData,
    data,
    watch(),
    employeeById?.result,
    activePage,
    employeesFilters.level,
    isEditLoading,
    isCreateLoading,
    agentType,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  useEffect(() => {
    if (data?.result?.length) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result?.length]);

  const handleInviteAgain = (id: number) => {
    inviteEmployeeAgain(id).then((res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.data?.details?.[0] || ServerErrorCodes[0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Employee was successfully invited',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  return {
    currentChildren,
    employeeTabs,
    activePage,
    columns: columnsData?.columns,
    filterControl,
    data,
    isFetching,
    isFilterDirty,
    handleRowEdit,
    handleSortClick,
    handleSwitchClick,
    handleTabChange,
    handleRowClick,
    handleResetFilters,
    handlePaginationClick,
    handleStopResize,
    skip: employeesFilters.skip,
    limit: employeesFilters.limit,
    handleInviteAgain,
    handleColumnSearch,
    employeesPagePermissions,
    agentType,
    getAllowedEditPermission,
  };
};

export default useEmployee;
