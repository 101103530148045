import React from 'react';

const EnvelopeIcon = () => {
  return (
    <svg width="77" height="80" viewBox="0 0 77 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.7102 0.690788L2.59216 24.437C2.0743 24.7963 1.65145 25.2742 1.35949 25.8301C1.06753 26.386 0.915071 27.0035 0.915039 27.6303L0.915039 74.6013C0.915039 75.1954 1.0331 75.7837 1.26246 76.3325C1.49183 76.8814 1.82801 77.38 2.25179 77.7999C2.67556 78.2198 3.17863 78.5528 3.73224 78.7798C4.28584 79.0069 4.87913 79.1234 5.47819 79.1229H72.3519C73.5598 79.1209 74.7175 78.6436 75.5708 77.7959C76.4242 76.9481 76.9035 75.7992 76.9035 74.6013V27.6303C76.9033 27.004 76.7511 26.3869 76.4599 25.8311C76.1687 25.2753 75.7469 24.7972 75.2302 24.437L41.1199 0.690788C40.474 0.241213 39.7041 0 38.915 0C38.126 0 37.3561 0.241213 36.7102 0.690788Z"
        fill="#184BC4"
      />
      <path
        d="M69.8649 13.3799H7.96113C6.68939 13.3799 5.65845 14.4023 5.65845 15.6635V59.6544C5.65845 60.9156 6.68939 61.938 7.96113 61.938H69.8649C71.1366 61.938 72.1676 60.9156 72.1676 59.6544V15.6635C72.1676 14.4023 71.1366 13.3799 69.8649 13.3799Z"
        fill="#F0F2F5"
      />
      <path
        d="M51.4475 28.3955H27.2195C27.0159 28.3955 26.8207 28.3153 26.6767 28.1725C26.5328 28.0298 26.4519 27.8362 26.4519 27.6343C26.4519 27.4324 26.5328 27.2388 26.6767 27.096C26.8207 26.9532 27.0159 26.873 27.2195 26.873H51.4475C51.6511 26.873 51.8463 26.9532 51.9903 27.096C52.1342 27.2388 52.2151 27.4324 52.2151 27.6343C52.2151 27.8362 52.1342 28.0298 51.9903 28.1725C51.8463 28.3153 51.6511 28.3955 51.4475 28.3955Z"
        fill="#184BC4"
      />
      <path
        d="M64.3616 33.553H14.3052C14.1016 33.553 13.9064 33.4728 13.7624 33.33C13.6185 33.1872 13.5376 32.9936 13.5376 32.7917C13.5376 32.5898 13.6185 32.3962 13.7624 32.2535C13.9064 32.1107 14.1016 32.0305 14.3052 32.0305H64.3616C64.5652 32.0305 64.7604 32.1107 64.9044 32.2535C65.0483 32.3962 65.1292 32.5898 65.1292 32.7917C65.1292 32.9936 65.0483 33.1872 64.9044 33.33C64.7604 33.4728 64.5652 33.553 64.3616 33.553Z"
        fill="#D0D8E6"
      />
      <path
        d="M64.3616 38.7134H14.3052C14.1016 38.7134 13.9064 38.6332 13.7624 38.4904C13.6185 38.3476 13.5376 38.154 13.5376 37.9521C13.5376 37.7502 13.6185 37.5566 13.7624 37.4139C13.9064 37.2711 14.1016 37.1909 14.3052 37.1909H64.3616C64.5652 37.1909 64.7604 37.2711 64.9044 37.4139C65.0483 37.5566 65.1292 37.7502 65.1292 37.9521C65.1292 38.154 65.0483 38.3476 64.9044 38.4904C64.7604 38.6332 64.5652 38.7134 64.3616 38.7134Z"
        fill="#D0D8E6"
      />
      <path
        d="M64.3616 43.8747H14.3052C14.1016 43.8747 13.9064 43.7945 13.7624 43.6518C13.6185 43.509 13.5376 43.3154 13.5376 43.1135C13.5376 42.9116 13.6185 42.718 13.7624 42.5753C13.9064 42.4325 14.1016 42.3523 14.3052 42.3523H64.3616C64.5652 42.3523 64.7604 42.4325 64.9044 42.5753C65.0483 42.718 65.1292 42.9116 65.1292 43.1135C65.1292 43.3154 65.0483 43.509 64.9044 43.6518C64.7604 43.7945 64.5652 43.8747 64.3616 43.8747Z"
        fill="#D0D8E6"
      />
      <path
        d="M64.3616 49.0351H14.3052C14.1016 49.0351 13.9064 48.9549 13.7624 48.8122C13.6185 48.6694 13.5376 48.4758 13.5376 48.2739C13.5376 48.072 13.6185 47.8784 13.7624 47.7357C13.9064 47.5929 14.1016 47.5127 14.3052 47.5127H64.3616C64.5652 47.5127 64.7604 47.5929 64.9044 47.7357C65.0483 47.8784 65.1292 48.072 65.1292 48.2739C65.1292 48.4758 65.0483 48.6694 64.9044 48.8122C64.7604 48.9549 64.5652 49.0351 64.3616 49.0351Z"
        fill="#D0D8E6"
      />
      <path
        d="M64.3616 54.1926H14.3052C14.1016 54.1926 13.9064 54.1124 13.7624 53.9696C13.6185 53.8269 13.5376 53.6333 13.5376 53.4314C13.5376 53.2295 13.6185 53.0359 13.7624 52.8931C13.9064 52.7504 14.1016 52.6702 14.3052 52.6702H64.3616C64.5652 52.6702 64.7604 52.7504 64.9044 52.8931C65.0483 53.0359 65.1292 53.2295 65.1292 53.4314C65.1292 53.6333 65.0483 53.8269 64.9044 53.9696C64.7604 54.1124 64.5652 54.1926 64.3616 54.1926Z"
        fill="#D0D8E6"
      />
      <path
        d="M5.47819 79.1263C4.87881 79.1268 4.28521 79.0101 3.73135 78.7829C3.1775 78.5556 2.67426 78.2223 2.25043 77.802C1.8266 77.3816 1.4905 76.8826 1.26136 76.3333C1.03222 75.784 0.914538 75.1953 0.915043 74.6009V27.6299C0.914161 28.2579 1.06617 28.8769 1.35816 29.4341C1.65014 29.9914 2.07347 30.4705 2.59216 30.8308L38.9093 56.1071L75.2418 30.8308C75.7588 30.47 76.1807 29.9912 76.4719 29.4348C76.7631 28.8784 76.9151 28.2606 76.915 27.6337V74.6009C76.915 75.195 76.797 75.7833 76.5676 76.3321C76.3382 76.8809 76.0021 77.3796 75.5783 77.7995C75.1545 78.2194 74.6514 78.5524 74.0978 78.7794C73.5442 79.0064 72.951 79.123 72.3519 79.1225L5.47819 79.1263Z"
        fill="#388DFF"
      />
      <path
        d="M36.7102 47.3415L2.85697 70.9012C2.25759 71.318 1.76822 71.8717 1.43021 72.5157C1.0922 73.1596 0.915494 73.8748 0.915039 74.6008H0.915039C0.915039 75.1949 1.0331 75.7832 1.26246 76.332C1.49183 76.8808 1.82801 77.3795 2.25179 77.7994C2.67556 78.2193 3.17863 78.5523 3.73224 78.7793C4.28584 79.0063 4.87913 79.1229 5.47819 79.1224H72.3519C73.5598 79.1204 74.7175 78.6431 75.5708 77.7954C76.4242 76.9476 76.9035 75.7987 76.9035 74.6008C76.9037 73.8742 76.7273 73.1582 76.3892 72.5136C76.0512 71.8689 75.5615 71.3146 74.9616 70.8974L41.1083 47.3377C40.4632 46.8912 39.6953 46.6522 38.9087 46.6528C38.122 46.6535 37.3545 46.8939 36.7102 47.3415Z"
        fill="#388DFF"
      />
    </svg>
  );
};

export default EnvelopeIcon;
