import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { SUBJECT } from 'pages/loads/constants/constants';
import {
  useCreateLoadPointMutation,
  useDeleteLoadPointMutation,
  useUpdateLoadPointMutation,
} from 'services/loads/loads';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { changeTimeZone } from '../../../../../../utils/helpers';
import { PICKUP_DROPOFFS_TYPES } from '../../constants/constants';

export const usePickupsDropoffs = ({ setValue, watch, editMode, id, pickupsDropoffsReset, refetchLoadPoints }: any) => {
  const [showForm, setShowForm] = useState<boolean>();
  const [addLoadPoint] = useCreateLoadPointMutation();
  const [updateLoadPoint] = useUpdateLoadPointMutation();
  const [deleteLoadPoint] = useDeleteLoadPointMutation();

  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const resetPickupsForm = () => {
    pickupsDropoffsReset({}, { keepDirty: false, keepTouched: false });
  };

  useEffect(() => {
    if (!editMode) setShowForm(true);
  }, [editMode]);

  const addAddress = (data: any, type: string, isEditCreating: boolean) => {
    const [hour, minute] = data.appointmentTime.split(':');

    const appointmentDateWithoutTime = new Date(data.appointmentDate.toLocaleString('en-US').split(',')[0]);

    const appointmentDate = editMode
      ? changeTimeZone(
          new Date(
            appointmentDateWithoutTime.getTime() +
              ((Number(hour) === 12 ? 0 : Number(hour)) * 3600 + Number(minute) * 60) * 1000 +
              (data.timePeriod === 'PM' ? 12 * 3600 * 1000 : 0)
          ),
          'America/Los_Angeles'
        )
      : appointmentDateWithoutTime.getTime() +
        ((Number(hour) === 12 ? 0 : Number(hour)) * 3600 + Number(minute) * 60) * 1000 +
        (data.timePeriod === 'PM' ? 12 * 3600 * 1000 : 0);

    if (editMode && !isEditCreating) {
      addLoadPoint({ id, body: data }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetPickupsForm();
        } else {
          notification.success({
            message: SUBJECT.SUCCESSFULLY_CREATED,
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          refetchLoadPoints().then((data: any) => {
            setValue('pickups', data?.data?.result?.pickup, { shouldDirty: false, shouldValidate: true });
            setValue('dropOffs', data?.data?.result?.dropoffs, { shouldDirty: false, shouldValidate: true });
          });
          resetPickupsForm();
        }
      });
    } else if (editMode && isEditCreating) {
      updateLoadPoint({ body: data }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetPickupsForm();
        } else {
          notification.success({
            message: SUBJECT.SUCCESSFULLY_UPDATED,
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          refetchLoadPoints().then((data: any) => {
            setValue('pickups', data?.data?.result?.pickup, {
              shouldDirty: false,
              shouldValidate: true,
              shouldTouch: false,
            });
            setValue('dropOffs', data?.data?.result?.dropoffs, {
              shouldDirty: false,
              shouldValidate: true,
              shouldTouch: false,
            });
          });
          resetPickupsForm();
        }
      });
    } else if (isEditCreating && !editMode) {
      const pickupEditedIndex = watch('pickups').findIndex((el: any) => el.id === data.id);
      const dropOffEditedIndex = watch('dropOffs').findIndex((el: any) => el.id === data.id);

      const updatedPickUps = [...watch('pickups')];
      updatedPickUps[pickupEditedIndex] = { ...data, appointmentDate };

      const updatedDropOffs = [...watch('dropOffs')];
      updatedDropOffs[dropOffEditedIndex] = { ...data, appointmentDate };

      if (type === PICKUP_DROPOFFS_TYPES.pickups) {
        setValue('pickups', [...updatedPickUps], { shouldDirty: true, shouldValidate: true });
      } else {
        setValue('dropOffs', [...updatedDropOffs]);
      }
      resetPickupsForm();
    } else {
      if (type === PICKUP_DROPOFFS_TYPES.pickups) {
        setValue('pickups', [...watch('pickups'), { ...data, appointmentDate }], {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setValue('dropOffs', [...watch('dropOffs'), { ...data, appointmentDate }], {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      resetPickupsForm();
    }
  };

  const handleRowDelete = (id: any, dropType: string) => {
    if (!editMode) {
      dispatch(
        openModal({
          title: SUBJECT.ARE_YOU_SURE,
          okText: SUBJECT.DELETE,
          cancelText: SUBJECT.CLOSE,
          okButtonVariant: 'danger',
          onOk: () => {
            const filteredPickUps = watch('pickups').filter((el: any) => el.id !== id);
            const filteredDropOffs = watch('dropOffs').filter((el: any) => el.id !== id);
            if (dropType === PICKUP_DROPOFFS_TYPES.pickups) {
              setValue('pickups', [...filteredPickUps], { shouldDirty: true, shouldValidate: true });
            } else {
              setValue('dropOffs', [...filteredDropOffs], { shouldDirty: true, shouldValidate: true });
            }
            dispatch(closeModal());
          },
          onCancel: () => {
            resetPickupsForm();
            dispatch(closeModal());
            setShowForm(false);
          },
        })
      );
    } else {
      dispatch(
        openModal({
          title: SUBJECT.ARE_YOU_SURE,
          okText: SUBJECT.DELETE,
          cancelText: SUBJECT.CLOSE,
          okButtonVariant: 'danger',
          onOk: () => {
            deleteLoadPoint({ id }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 3,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
              } else {
                notification.success({
                  message: SUBJECT.SUCCESSFULLY_DELETED,
                  duration: 1.5,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
                dispatch(closeModal());
                refetchLoadPoints().then((data: any) => {
                  setValue('pickups', data?.data?.result?.pickup);
                  setValue('dropOffs', data?.data?.result?.dropoffs);
                });
              }
            });
          },
          onCancel: () => {
            resetPickupsForm();
            dispatch(closeModal());
            setShowForm(false);
          },
        })
      );
    }
  };
  return {
    addAddress,
    showForm,
    setShowForm,
    resetPickupsForm,
    userInfo,
    handleRowDelete,
  };
};
