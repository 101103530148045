export enum SUBJECT {
  RATING = 'Rating',
  CLOSE = 'Close',
  SAVE = 'Save',
  SAVE_CHANGES = 'Save Changes',
  ARE_YOU_SURE_DELETE = 'Are You Sure Want To Delete Rate?',
  NO = 'No',
  STAY_HERE = 'Stay Here',
  YES = 'Yes',
  DELETE = 'Delete',
  ARE_YOU_SURE = 'Are You Sure Want To Delete?',
}
