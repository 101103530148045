import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Divider } from 'antd';
import { Input } from 'pages/permission/hoc-instance/instance';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import Typography from 'ui/typography/Typography';

import { SUBJECT } from './constants/constants';
import { IAddPermissionProps } from './constants/types';

import styles from './AddPermission.module.scss';

const AddPermission = ({ control, errors, getValues, isLoading, setValue }: IAddPermissionProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setIsValid({
        isValid: !isLoading,
      })
    );
  }, [isLoading]);

  return (
    <>
      <div className={styles.permission}>
        <Typography variant="paragraph" children={SUBJECT.PERMISSION_GROUP} className={styles.subHeading} />
        <Divider />
        <Input
          name="permissionGroupName"
          type="text"
          control={control}
          rules={{ required: true }}
          onChange={(e: any) => {
            setValue('permissionGroupName', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true });
          }}
          placeholder={SUBJECT.PERMISSION_GROUP_NAME}
          getValues={getValues}
          required
          errors={errors}
        />
      </div>
    </>
  );
};

export default AddPermission;
