import React, { useDeferredValue } from 'react';
import { Col, Divider, Row } from 'antd';
import { CUSTOMER_KEYS, CUSTOMER_TYPE_COLUMNS } from 'pages/loads/components/request-credit/constants/constants';
import Button from 'ui/button/Button';
import { DateOptions } from 'ui/inputs/select-date-picker/constants/types';
import SelectDatePicker from 'ui/inputs/select-date-picker/SelectDatePicker';
import Typography from 'ui/typography/Typography';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import MainDrawer from 'components/main-drawer/MainDrawer';
import ResetIcon from 'components/svgs/ResetIcon';
import Table from 'components/table/Table';

import { AGENT_COLUMNS, AGENT_KEYS, AUTHORITY_KEYS } from '../payables/constants/constants';

import { CUSTOMER_PAYMENT_METHOD, MC_TYPE_COLUMNS, SUBJECT } from './constants/constants';
import { FilterSelect, SearchableDropdown } from './hoc-instance/instance';
import useReceivables from './useReceivables';

import styles from './Receivables.module.scss';

const Receivables = ({ setExcelParams }: any) => {
  const {
    data,
    limit,
    skip,
    columns,
    control,
    isDirty,
    offices,
    branches,
    regOption,
    isLoading,
    paidOption,
    finalMcOptions,
    selectedMcOption,
    finalCustomerOptions,
    selectedCustomerOption,
    selectedAgentOption,
    finalAgentOptions,
    selectedOffices,
    selectedBranches,
    agentReceivablesFilter,
    watch,
    setValue,
    handleRowClick,
    setAgentsFilter,
    setBranchFilter,
    handleSortClick,
    setOfficesFilter,
    handleStopResize,
    handleSendInvoice,
    setCustomersState,
    handleResetFilters,
    setAuthoritiesState,
    handleDownloadClick,
    handleReceivedInvoice,
    handlePaginationClick,
    handleColumnSearch,
    currentChildren: { component, withFooter, buttonText, onCancel, onSubmit },
  } = useReceivables(setExcelParams);

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    withMenu: false,
    isLoading: isLoading,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onSendInvoice: handleSendInvoice,
    onColumnSearch: handleColumnSearch,
    onDownloadClick: handleDownloadClick,
    onPaginationClick: handlePaginationClick,
    onReceivedInvoice: handleReceivedInvoice,
  });

  return (
    <>
      <div className="page-content">
        <div className="filtersWrapperBig">
          <Row justify="space-between">
            <Col span={24}>
              <div className={styles.filtersWrapper}>
                <Row gutter={[18, 18]} align="middle">
                  <Col span={5}>
                    <SelectDatePicker fieldName="reg" label={SUBJECT.LOAD_REG_DATE} />
                  </Col>
                  <Col span={5}>
                    <SelectDatePicker fieldName="paid" label={SUBJECT.PAID_DATE} />
                  </Col>
                  <Col span={5}>
                    <SearchableDropdown
                      options={finalMcOptions?.result}
                      columns={MC_TYPE_COLUMNS}
                      keys={AUTHORITY_KEYS}
                      label={SUBJECT.MC}
                      placeholder={SUBJECT.SEARCH}
                      name="receivablesMc"
                      control={control}
                      setValue={setValue}
                      values={selectedMcOption}
                      offset={finalMcOptions?.count}
                      setOffset={setAuthoritiesState}
                      showKey="name"
                      width={500}
                    />
                  </Col>
                  <Col span={5}>
                    <SearchableDropdown
                      options={finalCustomerOptions?.result?.map((item: any) => {
                        return {
                          id: item?.id,
                          mc: item?.mc,
                          name: item?.name,
                        };
                      })}
                      columns={CUSTOMER_TYPE_COLUMNS}
                      keys={CUSTOMER_KEYS}
                      label={SUBJECT.CUSTOMER}
                      placeholder={SUBJECT.SEARCH}
                      name="receivablesCustomer"
                      control={control}
                      setValue={setValue}
                      values={selectedCustomerOption}
                      offset={finalCustomerOptions?.count}
                      setOffset={setCustomersState}
                      showKey="name"
                      width={400}
                    />
                  </Col>
                  {isDirty ||
                  (regOption && +regOption !== DateOptions.All) ||
                  (paidOption && +paidOption !== DateOptions.All) ? (
                    <Col span={4}>
                      <div className={styles.reset}>
                        <Button
                          icon={<ResetIcon />}
                          variant="ghost"
                          onClick={handleResetFilters}
                          children={SUBJECT.RESET_ALL}
                        />
                      </div>
                    </Col>
                  ) : null}
                  <Col span={5}>
                    <FilterSelect
                      isSearchable
                      isMultiSelect
                      withCheckbox
                      options={offices?.result}
                      label={SUBJECT.OFFICE}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name="receivablesOffice"
                      control={control}
                      offset={offices?.count}
                      setOffset={setOfficesFilter}
                      setValue={setValue}
                      withMarking
                      isAutocomplete
                    />
                  </Col>
                  <Col span={5}>
                    <FilterSelect
                      isSearchable
                      isMultiSelect
                      isAutocomplete
                      withCheckbox
                      options={branches?.result}
                      label={SUBJECT.BRANCH}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name="receivablesBranch"
                      control={control}
                      offset={branches.count}
                      setOffset={setBranchFilter}
                      setValue={setValue}
                      withMarking
                      isDisabled={watch('receivablesOffice')!.length !== 1}
                    />
                  </Col>
                  <Col span={5}>
                    <SearchableDropdown
                      options={finalAgentOptions?.result}
                      columns={AGENT_COLUMNS}
                      keys={AGENT_KEYS}
                      label={SUBJECT.AGENT}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name="receivablesAgent"
                      control={control}
                      disabled={
                        (selectedOffices.length !== 1 ||
                          selectedBranches.length !== 1 ||
                          selectedBranches?.[0]?.default) &&
                        !agentReceivablesFilter
                      }
                      setValue={setValue}
                      values={selectedAgentOption}
                      offset={finalAgentOptions.count}
                      setOffset={setAgentsFilter}
                      showKey="username"
                      width={420}
                    />
                  </Col>
                  <Col span={5}>
                    <FilterSelect
                      withAll
                      name="customerPaymentMethod"
                      options={CUSTOMER_PAYMENT_METHOD}
                      control={control}
                      label={SUBJECT.PAYMENT_METHOD}
                      placeholder={SUBJECT.NOT_SELECTED}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Divider />

        <div className={styles.tableHeaderTopInfo}>
          <Row align="middle" justify="space-between">
            <Col span={14}>
              <div className={styles.billingMoneyBlock}>
                <Col>
                  <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.EXPECTED} />
                </Col>
                <Col>
                  <Divider type="vertical" />
                </Col>
                <Col>
                  <Row align="middle" className={styles.moneyInfo}>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.LOAD} />
                    <Typography className={styles.totalCount} variant="paragraph" children={data?.expectedTotalCount} />
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" className={styles.moneyInfo}>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.TOTAL} />
                    <Typography
                      className={styles.totalCount}
                      variant="paragraph"
                      children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.expectedTotalAmount, true)}`}
                    />
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" className={styles.moneyInfo}>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.QUICK_PAY} />
                    <Typography
                      className={styles.totalCount}
                      variant="paragraph"
                      children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.expectedQuickAmount, true)}`}
                    />
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" className={styles.moneyInfo}>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.CUSTOM} />
                    <Typography
                      className={styles.totalCount}
                      variant="paragraph"
                      children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.expectedCustomAmount, true)}`}
                    />
                  </Row>
                </Col>
              </div>
            </Col>
            <Col span={10}>
              <div className={styles.billingMoneyRightBlock}>
                <Col>
                  <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.RECEIVED} />
                </Col>
                <Col>
                  <Divider type="vertical" />
                </Col>
                <Col>
                  <Row align="middle" className={styles.moneyInfo}>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.LOAD} />
                    <Typography className={styles.totalCount} variant="paragraph" children={data?.receivedTotalCount} />
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" className={styles.moneyInfo}>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.TOTAL} />
                    <Typography
                      className={styles.totalCount}
                      variant="paragraph"
                      children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.receivedTotalAmount, true)}`}
                    />
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" className={styles.moneyInfo}>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.QUICK_PAY} />
                    <Typography
                      className={styles.totalCount}
                      variant="paragraph"
                      children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.receivedQuickAmount, true)}`}
                    />
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" className={styles.moneyInfo}>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.CUSTOM} />
                    <Typography
                      className={styles.totalCount}
                      variant="paragraph"
                      children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.receivedCustomAmount, true)}`}
                    />
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
        <Table {...tableProps} />
        <MainDrawer
          Children={component}
          buttonText={buttonText}
          withFooter={withFooter}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </>
  );
};

export default Receivables;
