import { equipmentTypeText, trailerTypeText } from 'pages/truck-board/components/favorite-trucks/constants/constants';
import { formatDate } from 'utils/dates';

export const getFavoriteListSerializer = (body: any) => {
  const result = body?.result?.map(({ carrier, truck }: any) => {
    return {
      carrierId: carrier?.id,
      id: truck?.id,
      availableAt: truck?.availableAt ? formatDate(truck?.availableAt) : '',
      coordinator: truck.coordinatorId ? `${truck?.coordinator?.username}` : '',
      companyName: carrier?.companyName,
      truckNumber: truck?.truckNumber,
      contactName: carrier?.contactName,
      isFavorite: carrier?.favorite,
      rate: {
        rating: carrier?.rating,
        ratingCount: carrier?.ratingCount,
      },
      carrierRating: carrier?.rating,
      equipmentType: equipmentTypeText[truck?.equipmentType],
      trailerDetails: trailerTypeText[truck?.trailerDetails],
      availableLength: truck?.availableLength || '',
      locatedCity: truck?.city,
      locatedState: truck?.fromState?.name,
      desireDestination: truck?.toState,
      lastComment: carrier?.lastComment,
    };
  });
  return {
    result,
    count: body.count,
  };
};
