import React from 'react';
import { Col, Row, Tabs } from 'antd';
import clsx from 'clsx';
import Button from 'ui/button/Button';
import PayOutFooter from 'ui/pay-out-footer/PayOutFooter';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import MainDrawer from 'components/main-drawer/MainDrawer';
import PayrollCards from 'components/payroll-cards/PayrollCards';
import ResetIcon from 'components/svgs/ResetIcon';
import Table from 'components/table/Table';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';

import { AGENT_COLUMNS, AGENT_KEYS, SUBJECT } from './constants/constants';
import { FilterSelect, MonthPicker, SearchableDropdown } from './hoc-instance/instance';
import { usePayout } from './usePayout';

import styles from './Payout.module.scss';

const Payout = ({ setExcelParams }: any) => {
  const {
    data,
    agents,
    offices,
    summary,
    columns,
    branches,
    activeTab,
    isLoading,
    cardsData,
    payoutTabs,
    selectedRows,
    filterControl,
    paymentFilters,
    activeTabCheck,
    selectedAgentOption,
    currentChildren: { component, withFooter, buttonText, onCancel, onSubmit },
    filterWatch,
    handleAllPay,
    handleRowClick,
    setFilterValue,
    setAgentsFilter,
    setBranchFilter,
    handleSortClick,
    handleTabChange,
    setOfficesFilter,
    handleStopResize,
    handleRowPayClick,
    handleResetFilters,
    handleColumnSearch,
    handleCancelPayments,
    handleCheckboxChange,
    handlePaginationClick,
    roleLevel,
    dirtyFields,
    isChangedDate,
    isPayButton,
    hasPermissionToPay,
    hasPermissionToApprove,
    hasPermissionToAction,
    selectedBranches,
  } = usePayout(setExcelParams);
  return (
    <div className="page-content">
      <div className={styles.payoutContainer}>
        <Tabs
          items={payoutTabs}
          className={styles.payoutHeaderTabs}
          onChange={tab => handleTabChange(tab)}
          defaultActiveKey={activeTab}
        />
        <PayrollCards cards={cardsData} withColoredWrapper />

        <div className="filtersWrapper">
          <div className={styles.payoutMainSelect}>
            <Row align="middle" justify="space-between">
              <Col span={18}>
                <Row gutter={[15, 0]} align="middle">
                  <Col span={5}>
                    <MonthPicker control={filterControl} name="monthPickerPayout" />
                  </Col>
                  {roleLevel === IRoleLevel.corpLevel && (
                    <Col span={5}>
                      <FilterSelect
                        isSearchable
                        isMultiSelect
                        withCheckbox
                        options={offices?.result}
                        label={SUBJECT.OFFICE}
                        placeholder={SUBJECT.NOT_SELECTED}
                        name="officePayout"
                        control={filterControl}
                        offset={offices?.count}
                        setOffset={setOfficesFilter}
                        setValue={setFilterValue}
                        withMarking
                        isAutocomplete
                      />
                    </Col>
                  )}

                  {(activeTab === 'Branch' || activeTab === 'Agent') && roleLevel <= IRoleLevel.officeLevel && (
                    <Col span={5}>
                      <FilterSelect
                        isSearchable
                        isMultiSelect
                        isAutocomplete
                        withCheckbox
                        options={branches?.result}
                        label={SUBJECT.BRANCH}
                        placeholder={SUBJECT.NOT_SELECTED}
                        name="branchPayout"
                        control={filterControl}
                        offset={branches?.count}
                        setOffset={setBranchFilter}
                        setValue={setFilterValue}
                        withMarking
                        isDisabled={filterWatch('officePayout')!.length !== 1 && roleLevel === IRoleLevel.corpLevel}
                      />
                    </Col>
                  )}
                  {activeTab === 'Agent' && roleLevel <= IRoleLevel.branchLevel && (
                    <Col span={5}>
                      <SearchableDropdown
                        options={agents?.result}
                        columns={AGENT_COLUMNS}
                        keys={AGENT_KEYS}
                        label={SUBJECT.AGENT}
                        placeholder={SUBJECT.NOT_SELECTED}
                        name="agentPayout"
                        control={filterControl}
                        disabled={
                          (filterWatch('branchPayout')!.length !== 1 && roleLevel < IRoleLevel.branchLevel) ||
                          (filterWatch('branchPayout')!.length === 1 && selectedBranches?.[0]?.default)
                        }
                        setValue={setFilterValue}
                        values={selectedAgentOption}
                        offset={agents.count}
                        setOffset={setAgentsFilter}
                        showKey="firstName"
                        width={420}
                      />
                    </Col>
                  )}
                  {(!!Object.keys(dirtyFields).length || isChangedDate) && (
                    <Col>
                      <div className={styles.payoutResetBtn}>
                        <Button
                          icon={<ResetIcon />}
                          onClick={handleResetFilters}
                          variant="ghost"
                          children={SUBJECT.RESET_ALL}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>

              <Col>
                <div className={styles.balanceInfo}>
                  <span className={styles.filtersInfo}>{SUBJECT.BALANCE}:</span>
                  <span
                    className={clsx(styles.filtersCount, {
                      [styles.filtersCountRed]: String(data?.total)?.[0] === '-',
                    })}
                  >
                    {formatNumberWithThousandsSeparatorAndDecimal(data?.total, true)}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {activeTabCheck && (
          <>
            <Table
              withCheckbox
              data={data}
              columns={columns}
              isLoading={isLoading}
              withMenu={false}
              onRowPayClick={handleRowPayClick}
              onCheckboxChange={handleCheckboxChange}
              selectedRows={selectedRows}
              onPaginationClick={handlePaginationClick}
              onSort={handleSortClick}
              onColumnSearch={handleColumnSearch}
              onResizeStop={handleStopResize}
              skip={paymentFilters.skip}
              limit={paymentFilters.limit}
              onRowClick={handleRowClick}
              hasPermissionToPay={hasPermissionToPay}
              hasPermissionToApprove={hasPermissionToApprove}
              hasPermissionToAction={hasPermissionToAction}
            />
            <MainDrawer
              Children={component}
              buttonText={buttonText}
              withFooter={withFooter}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          </>
        )}

        {!!selectedRows.length && (
          <PayOutFooter
            isPayButton={isPayButton}
            handleCancel={handleCancelPayments}
            selectedCount={selectedRows.length}
            payTotal={summary}
            handlePay={handleAllPay}
          />
        )}
      </div>
    </div>
  );
};

export default Payout;
