import React from 'react';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import { IRequestErrorProps } from './constants/types';

import styles from './RequestErrors.module.scss';

const RequestError = ({ title, icon, mode = 'error' }: IRequestErrorProps) => {
  return (
    <div
      className={clsx(styles.requestError, {
        [styles.warningMode]: mode === 'warning',
      })}
    >
      <Typography variant="paragraph">
        {icon} {title}
      </Typography>
    </div>
  );
};

export default RequestError;
