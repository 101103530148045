import { allPossibleRoutes, getRoutesByPermission, staticUserRoutes } from '../components/routes/constants/constants';

export const getFilteredPagesByPermissions = (userType: any, agentType: any, routes: any = []): any => {
  if (!userType) return allPossibleRoutes;
  if (userType == 3) {
    return staticUserRoutes[agentType];
  }
  if (userType == 1) {
    return staticUserRoutes[0];
  }
  if (userType == 2) {
    return getRoutesByPermission(routes);
  }
};
