import { colors } from './constants/constants';

const useTableAging = () => {
  const colorChecker = (data: any, day: number) => {
    if (data?.dataType === 'payables') {
      if (data?.paymentMethod === 'Quick pay') {
        if (day <= 2) {
          return colors.blue;
        } else if (day <= 4) {
          return colors.yellow;
        } else {
          return colors.red;
        }
      } else {
        if (day <= 26) {
          return colors.blue;
        } else if (day <= 29) {
          return colors.yellow;
        } else {
          return colors.red;
        }
      }
    }
    if (data?.dataType === 'receivables') {
      if (data?.paymentMethod === 'Factoring') {
        if (day <= 24) {
          return colors.blue;
        } else if (day <= 29) {
          return colors.yellow;
        } else {
          return colors.red;
        }
      } else {
        if (Math.round((data?.aging / data?.paymentTermDay) * 100) <= 80) {
          return colors.blue;
        } else if (Math.round((data?.aging / data?.paymentTermDay) * 100) <= 99) {
          return colors.yellow;
        } else {
          return colors.red;
        }
      }
    }
  };
  return {
    colorChecker,
  };
};

export default useTableAging;
