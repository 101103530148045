import { IOptions } from './types';

export enum SUBJECT {
  CARRIER_REQUEST = 'Carrier Request',
  COMPANY_NAME = 'Company Name',
  PHONE_NUMBER = 'Phone Number',
  CONTACT_NAME = 'Contact Name',
  CARRIER_TYPE = 'Carrier Type',
  MC = 'MC',
  DOT = 'DOT',
}

export const TRUCK_CARRIER_REQUEST_LOCATION_OPTIONS: IOptions[] = [
  {
    title: 'Inside',
    value: '1',
  },
  {
    title: 'Outside',
    value: '2',
  },
];

export const defaultValues = {
  companyName: '',
  phoneNumber: '',
  contactName: '',
  mc: '',
  dot: '',
};

export const defaultValuesFromBack = {
  companyName: 'Blue Box 14',
  phoneNumber: '776-54-46977',
  contactName: 'Erik',
  carrierType: 'Truck board',
  mc: '12365478',
  dot: '34343443',
};

export enum VALIDATION_MESSAGES {
  COMPANY_NAME_REQUIRED = 'Company Name Is a Required Field',
  PHONE_NUMBRE_REQUIRED = 'Phone Number Is a Required Field',
  CONTANCT_NAME_REQUIRE = 'Contact Name Is a Required Field',
  CARRIER_TYPE_REQUIRED = 'Carrier Type Is a Required Field',
  DOT_REQUIRED = 'DOT Is a Required Field',
  PHONE = 'Incorrect Phone Format',
  MAX_LENGTH = 'Maximum Of 10 Digits Allowed',
  MC_REQUIRED = 'MC Is a Required Field',
}
