import { INewCreditActionItems } from './types';

export enum SUBJECT {
  CREDIT_CHECK = 'Credit Check',
  CUSTOMER_ID = 'Customer ID',
  CUSTOMER_NAME = 'Customer Name',
  AGENT_USERNAME = 'Agent',
  OFFICE_NAME = 'Agency',
  BRANCH_NAME = 'Branch',
  AMOUNT = 'Amount',
  REAL_AMOUNT = 'Real Amount',
  DEBT_NOT_PAID = 'Debt / Not Paid',
  DEBT_LIMIT = 'Debt / Limit',
  DEBT_LOAD_QTY = 'Debt / Load Qty',
  STATUS = 'Status',
  PAYMENT_METHOD = 'Payment Method',
  COMMENT = 'Comment',
  ALL_ACTIONS = 'All Actions',
  DATE = 'Date',
  LOAD_NUMBER = 'Load ID',
}

export const mockData = {
  customer_id: '123456789',
  customer_name: 'Gabriel',
  agent_username: 'Eric',
  office_name: 'Green',
  branch_name: 'MAX',
  amount: '4000 $',
  real_amount: '1200 $',
  debt_not_paid: '1200 $',
  status: 'Approved',
  payment_method: 'Factoring | 0% | 30 day',
  comments: 'Comment 14',
};

export const NEW_CREDIT_ACTIONS_BUTTONS: INewCreditActionItems[] = [
  {
    id: 1,
    buttonText: 'Approve',
    buttonType: 'primary',
  },
  {
    id: 2,
    buttonText: 'Deny',
    buttonType: 'danger',
  },
  {
    id: 3,
    buttonText: 'COD',
    buttonType: 'warning',
  },
  {
    id: 4,
    buttonText: 'Consignment',
    buttonType: 'default',
  },
];
