import {
  contactTabs,
  creditcheckTabs,
  factoringTabs,
  loadsTabs,
  payrollTabs,
  permissionDeserializer,
  profilesTabs,
  truckboardTabs,
} from '../../pages/permission/tab/permission-list/constants/constants';

export const getPermissionGroupByIdSerializer = (body: any) => {
  const { permissions } = body.result;

  const result: any = {
    profiles: [],
    loads: [],
    payroll: [],
    creditcheck: [],
    contact: [],
    factoring: [],
    truckboard: [],
  };

  const actionsOptions: any = {
    profiles: {},
    loads: {},
    payroll: {},
    creditcheck: {},
    contact: {},
    factoring: {},
    truckboard: {},
  };

  const handleSerializer = (object: any) => {
    const modifiedActions = {
      download: object?.download,
      view: object?.view,
      ...object?.actions,
    };
    const permissionsTitles = [];

    for (const key in modifiedActions) {
      if (modifiedActions[key] && key !== 'noAction') {
        permissionsTitles.push(key);
      }
    }
    return permissionsTitles;
  };

  const handleSerializerOptions = (object: any) => {
    const modifiedActions = {
      ...object?.actions,
      download: object?.download,
    };

    const actionOption = [];

    for (const key in modifiedActions) {
      if (key !== 'noAction') {
        actionOption.push({ title: permissionDeserializer[key], value: key });
      }
    }
    return actionOption;
  };

  for (const key in permissions) {
    if (profilesTabs('all')?.includes(key)) {
      result.profiles.push({ existingPermissions: [...handleSerializer(permissions[key])], permissionName: key });
      actionsOptions.profiles[key] = handleSerializerOptions(permissions[key]);
    }
    if (loadsTabs('all')?.includes(key)) {
      result.loads.push({ existingPermissions: [...handleSerializer(permissions[key])], permissionName: key });
      actionsOptions.loads[key] = handleSerializerOptions(permissions[key]);
    }
    if (payrollTabs('all')?.includes(key)) {
      result.payroll.push({ existingPermissions: [...handleSerializer(permissions[key])], permissionName: key });
      actionsOptions.payroll[key] = handleSerializerOptions(permissions[key]);
    }
    if (creditcheckTabs('all')?.includes(key)) {
      result.creditcheck.push({ existingPermissions: [...handleSerializer(permissions[key])], permissionName: key });
      actionsOptions.creditcheck[key] = handleSerializerOptions(permissions[key]);
    }
    if (contactTabs('all')?.includes(key)) {
      result.contact.push({ existingPermissions: [...handleSerializer(permissions[key])], permissionName: key });
      actionsOptions.contact[key] = handleSerializerOptions(permissions[key]);
    }
    if (factoringTabs('all')?.includes(key)) {
      result.factoring.push({ existingPermissions: [...handleSerializer(permissions[key])], permissionName: key });
      actionsOptions.factoring[key] = handleSerializerOptions(permissions[key]);
    }
    if (truckboardTabs('all')?.includes(key)) {
      result.truckboard.push({ existingPermissions: [...handleSerializer(permissions[key])], permissionName: key });
      actionsOptions.truckboard[key] = handleSerializerOptions(permissions[key]);
    }
  }
  return {
    permissions: result,
    defaultPermissions: body.result,
    actionsOptions,
  };
};
