import { createApi } from '@reduxjs/toolkit/query/react';
import { ICarrierByIdData } from 'services/profile/carriers/interfaces';
import { baseQuery } from 'services/service';

import { ICarrierInfoData, IGetCarrierInfoParams, IPayablesData } from './interface';
import {
  getCarrierInfoSerializer,
  getCarrierSerializer,
  getPayablesParamsSerializer,
  getPayablesSerializer,
} from './serializers';

export const payablesAPI = createApi({
  reducerPath: 'payablesAPI',
  baseQuery,
  tagTypes: ['Payables', 'Loads'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getPayables: builder.query({
      query: (filters: any) => {
        const queryParams = getPayablesParamsSerializer(filters);
        return {
          url: 'loads/payable',
          params: queryParams,
        };
      },
      transformResponse: (body: IPayablesData) => getPayablesSerializer(body),
      providesTags: ['Payables', 'Loads'],
    }),
    getCarrierInfo: builder.mutation({
      query: ({ carrierId, authorityIds, skip, limit }: IGetCarrierInfoParams) => ({
        url: 'loads/carrier-info',
        method: 'POST',
        body: { carrierId: Number(carrierId), authorityIds: authorityIds.map(item => Number(item)), skip, limit },
      }),
      transformResponse: (body: ICarrierInfoData) => getCarrierInfoSerializer(body),
    }),
    getCarrier: builder.query({
      query: ({ id }) => `carriers/${id}`,
      transformResponse: (body: ICarrierByIdData) => getCarrierSerializer(body),
      providesTags: ['Payables'],
    }),
    getCarrierPay: builder.mutation({
      query: (body: { ids: number[] }) => ({
        url: '/loads/carrier-balk-pay',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Payables', 'Loads'],
    }),
    receiveInvoice: builder.mutation({
      query: ({ id }: any) => ({
        url: `loads/payable/${id}/receive-invoice`,
        method: 'POST',
      }),
      invalidatesTags: ['Payables', 'Loads'],
    }),
  }),
});

export const {
  useGetPayablesQuery,
  useGetCarrierInfoMutation,
  useGetCarrierQuery,
  useGetCarrierPayMutation,
  useReceiveInvoiceMutation,
} = payablesAPI;
