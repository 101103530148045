import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import { Col, RefSelectProps, Row, Select } from 'antd';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import SearchIcon from 'components/svgs/SearchIcon';

import CloseIcon from '../../../../components/svgs/CloseIcon';
import useDebounce from '../../../../hooks/useDebounce';
import { getHighlightedOptions } from '../../../../utils/helpers';

import { SUBJECT } from './../constants/constants';
import { ISearchableSelectProps } from './../constants/types';

import styles from './SearchableSelectForMultipleAuthorities.module.scss';

const SearchableSelectForMultipleAuthorities = forwardRef(
  (
    {
      options,
      placeholder,
      withMarking = true,
      onChange,
      handleClick,
      withButton,
      buttonText,
      isMulti,
      data,
      name,
      disabled = false,
      value,
      errors,
      offset,
      setOffset,
      required,
      showKey,
      setValue,
      isFilter,
      label,
      resetFunc,
      allowClear,
      isLoading,
      onBlur,
      ...props
    }: ISearchableSelectProps,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const debounceValue = useDebounce(searchValue, 500);

    const [isFocused, setIsFocused] = useState(false);
    const currentElement = document.getElementById(`current_select_searchable${label}${placeholder}`);

    const handleSearch = (value: string) => {
      currentElement?.focus();
      setIsSearching(true);
      setSearchValue(value);
    };

    const handleReset = () => {
      setSearchValue('');
      if (setValue) {
        setValue(name, '');
      }
      if (onChange) {
        onChange('');
      }
      if (resetFunc) {
        resetFunc();
      }
    };

    useEffect(() => {
      if (isSearching && (debounceValue?.length >= 2 || !debounceValue?.length) && typeof searchValue === 'string') {
        setOffset((prev: any) => ({ ...prev, search: debounceValue, limit: 10 }));
      }
    }, [debounceValue]);

    const handleScroll = (event: any) => {
      const { target } = event;
      if (target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight) {
        if (options?.length < offset) {
          setOffset((prev: any) => {
            return { ...prev, limit: prev.limit + 10 };
          });
        }
      }
    };

    const handleBlur = () => {
      setTimeout(() => {
        if (onBlur) {
          onBlur();
          setSearchValue('');
        }
        setIsFocused(false);
      }, 700);
    };

    useEffect(() => {
      if (!searchValue?.length && onChange && data) {
        onChange(data?.map((item: any) => item?.id));
      }
    }, [searchValue]);

    useEffect(() => {
      if (typeof value === 'string') {
        setSearchValue(value);
      }
    }, [value]);

    const dropdownRef = useRef<HTMLInputElement>(null);

    return (
      <div className={styles.searchableSelectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          className={clsx(styles.selectWrapper, {
            [styles.required]: required,
            [styles.showValue]: withMarking,
            [styles.hasValue]: (typeof value === 'string' && value?.length) || isFocused,
            [styles.errors]: errors && name && errors[name],
            [styles.disabled]: disabled,
            [styles.filterSelectWrapper]: isFilter,
          })}
          id={`select_searchable${label}${placeholder}`}
          ref={dropdownRef}
        >
          <Select
            {...props}
            id={`current_select_searchable${label}${placeholder}`}
            getPopupContainer={() => document.getElementById(`select_searchable${label}${placeholder}`)!}
            mode={isMulti}
            ref={ref}
            showSearch={true}
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            showArrow
            {...(allowClear ? { onClear: handleReset, clearIcon: <CloseIcon />, allowClear } : {})}
            autoClearSearchValue={false}
            suffixIcon={!(value && searchValue && allowClear) ? <SearchIcon /> : <CloseIcon />}
            className={clsx(styles.selectMarkingWrapper, styles.select)}
            onSearch={handleSearch}
            popupClassName={styles.optionsContainer}
            value={
              !isFilter
                ? value
                  ? value
                  : null
                : isNaN(Number(value))
                ? value
                : options?.find(el => el.id == value)?.[showKey!]
            }
            onBlur={handleBlur}
            dropdownRender={() => (
              <>
                {options?.length ? (
                  <div className={styles.headerItems}>
                    <Row>
                      <Col span={7}>
                        <span>{SUBJECT.NAME}</span>
                      </Col>
                      <Col span={4}>
                        <span>{SUBJECT.TYPE}</span>
                      </Col>
                      <Col span={4}>
                        <span>{SUBJECT.MC_NUMBER}</span>
                      </Col>
                      <Col span={4}>
                        <span>{SUBJECT.AUTHORITY_ID}</span>
                      </Col>
                      {withButton && <Col span={5} />}
                    </Row>
                  </div>
                ) : (
                  <Typography variant="paragraph" className={styles.noDataText}>
                    {SUBJECT.NO_DATA}
                  </Typography>
                )}
                <div className={styles.optionsCustomWrapper} onScroll={handleScroll}>
                  {options?.map(item => {
                    const matchedName = getHighlightedOptions(String(searchValue) || '', String(item.name) || '');
                    const matchedTypeName = getHighlightedOptions(
                      searchValue || '',
                      typeof item.typeName === 'string' ? item.typeName : item.type
                    );
                    const matchedMc = getHighlightedOptions(String(searchValue) || '', String(item.mc) || '');
                    const matchedId = getHighlightedOptions(String(searchValue) || '', String(item.id) || '');
                    return (
                      <div
                        key={item.id}
                        onClick={
                          handleClick && !isLoading
                            ? () => {
                                if (options.length === 1) {
                                  currentElement?.blur();
                                }
                                handleClick(item);
                              }
                            : undefined
                        }
                      >
                        <div className={styles.authoritiesContainer}>
                          <Row>
                            {matchedName.length ? (
                              <Col span={7}>
                                {matchedName[0]}
                                <span className={styles.searchResultKeyword}>{matchedName[1]}</span>
                                {matchedName[2]}
                              </Col>
                            ) : (
                              <Col span={7}>
                                <span>{item.name}</span>
                              </Col>
                            )}
                            {matchedTypeName.length ? (
                              <Col span={4}>
                                {matchedTypeName[0]}
                                <span className={styles.searchResultKeyword}>{matchedTypeName[1]}</span>
                                {matchedTypeName[2]}
                              </Col>
                            ) : (
                              <Col span={4}>
                                <span>{typeof item.typeName === 'string' ? item.typeName : item.type}</span>
                              </Col>
                            )}
                            {matchedMc.length ? (
                              <Col span={4}>
                                {matchedMc[0]}
                                <span className={styles.searchResultKeyword}>{matchedMc[1]}</span>
                                {matchedMc[2]}
                              </Col>
                            ) : (
                              <Col span={4}>
                                <span>{item.mc}</span>
                              </Col>
                            )}
                            {matchedId.length ? (
                              <Col span={4}>
                                {matchedId[0]}
                                <span className={styles.searchResultKeyword}>{matchedId[1]}</span>
                                {matchedId[2]}
                              </Col>
                            ) : (
                              <Col span={4}>
                                <span>{item.id}</span>
                              </Col>
                            )}
                            {withButton && (
                              <Col span={5}>
                                <Typography variant="paragraph">{buttonText}</Typography>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          />
          {isFilter ? (
            !value &&
            !isFocused && (
              <Typography
                variant="paragraph"
                className={clsx(styles.placeholder, {
                  [styles['placeholder-select']]: !isFilter,
                  [styles.isFilter]: isFilter,
                })}
              >
                {placeholder}
              </Typography>
            )
          ) : (
            <Typography
              variant="paragraph"
              className={clsx(styles.placeholder, {
                [styles['placeholder-select']]: !isFilter,
                [styles.isFilter]: isFilter,
              })}
            >
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);
export default SearchableSelectForMultipleAuthorities;
