import { useRef } from 'react';
import clsx from 'clsx';
import Typography from 'ui/typography/Typography';

import { IMessage, INotification } from '../interfaces';

import { ICONS } from './constants/constants';

import styles from './Notification.module.scss';

const Notification = ({ title, message, date, messageType, read }: INotification) => {
  const handleLinkClick = (link: string) => {
    window.open(link, '_blank');
  };
  const ref = useRef<HTMLDivElement>(null);
  const isNotificationInDrawer = (window as any).global.inDrawer;

  return (
    <div
      className={clsx(styles.notificationBox, 'notificationBox', {
        [styles.read]: isNotificationInDrawer ? read : true,
      })}
      ref={ref}
    >
      <div className={styles.notificationHeader}>
        {ICONS[messageType]}
        <Typography variant="paragraph">{title}</Typography>
      </div>
      <div className={styles.notificationBody}>
        <Typography variant="paragraph">
          {message.map((el: IMessage) => {
            const Component = el?.title;
            return (
              <span
                key={el?.id}
                onClick={() => el?.link && handleLinkClick(el.link)}
                className={clsx({ [styles.link]: el.link })}
              >
                {el?.component ? Component : el?.title}
              </span>
            );
          })}
        </Typography>
      </div>
      <div className={styles.notificationFooter}>
        <Typography variant="paragraph">{date}</Typography>
      </div>
    </div>
  );
};

export default Notification;
