import ExcelButton from 'ui/excel-button/ExcelButton';
import { getCreditCheckTabs } from 'utils/constants';

import CustomizeColumnButton from 'components/customize-column-button/CustomizeColumnButton';
import TabPanel from 'components/tab-panel/TabPanel';

import useCreditCheck from './useCreditCheck';

const CreditCheck = () => {
  const { tab, openMainDrawer, downloadExcel, Component, isButtonVisible, permissionsInfo, hasDownloadPermission } =
    useCreditCheck();

  return (
    <div>
      <TabPanel tabs={getCreditCheckTabs(permissionsInfo?.permissions?.['credit-check']?.visibleTabs)}>
        <div className="tabPanelButtons">
          <CustomizeColumnButton onClick={() => openMainDrawer('customize')} isButtonVisible={isButtonVisible} />
          {hasDownloadPermission && <ExcelButton onClick={downloadExcel} isButtonVisible={isButtonVisible} />}
        </div>
      </TabPanel>

      <div>{Component[tab!]}</div>
    </div>
  );
};
export default CreditCheck;
