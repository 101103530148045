import { loginPasswordPattern } from 'utils/regexp';
import * as yup from 'yup';

import { SUBJECT } from './constants';

export const validation = yup.object().shape({
  currentPassword: yup
    .string()
    .required(SUBJECT.CURRENT_REQUIRED)
    .matches(loginPasswordPattern, SUBJECT.PASSWORD_NOT_VALID),
  newPassword: yup
    .string()
    .required(SUBJECT.NEW_REQUIRED)
    .matches(loginPasswordPattern, SUBJECT.PASSWORD_NOT_VALID)
    .test('isRequired', SUBJECT.THE_SAME_PASSWORD, function checkLength(value) {
      if (value === this.parent.currentPassword) {
        return this.createError({
          message: SUBJECT.THE_SAME_PASSWORD,
          path: 'newPassword',
        });
      }
      return !!value;
    }),
  repeatPassword: yup
    .string()
    .required(SUBJECT.REPEAT_REQUIRED)
    .matches(loginPasswordPattern, SUBJECT.PASSWORD_NOT_VALID)
    .oneOf([yup.ref('newPassword')], SUBJECT.PASSWORD_NOT_MATCH),
});
