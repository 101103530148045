import { ActiveTypes } from 'services/carriersOnBoard/interface';

import { changeTimeZone } from '../../../utils/helpers';

import { CarrierFactoringType, ICarrierType, IFileTypes } from './interfaces';

export const createCarrier = (carrier: any) => {
  const carrierPaymentTerms = carrier?.paymentTerms?.map((term: any) => {
    return {
      type: term?.type,
      ...(term?.authorityId?.length && { authorityId: term?.authorityId?.[0]?.id }),
      ...(term?.carrierId && { carrierId: term?.carrierId }),
      billingType: term?.factoringType,
      ...(term?.factoredCompanyName?.length && { factoringCompanyId: term?.factoredCompanyName?.[0]?.id }),
      dealType: term?.paymentType,
      ...(term?.paymentType === 1 && term?.factoringType !== CarrierFactoringType.FACTORED && !!term?.paymentBankName
        ? {
            paymentBankName: term?.paymentBankName,
          }
        : {}),
      ...(term?.paymentType === 1 &&
      term?.factoringType !== CarrierFactoringType.FACTORED &&
      !!term?.paymentRoutingNumber
        ? {
            paymentRoutingNumber: term?.paymentRoutingNumber,
          }
        : {}),
      ...(term?.paymentType === 1 &&
      term?.factoringType !== CarrierFactoringType.FACTORED &&
      !!term?.paymentAccountNumber
        ? {
            paymentAccountNumber: term?.paymentAccountNumber,
          }
        : {}),
      ...(term?.paymentType === 2 &&
        term?.factoringType !== CarrierFactoringType.FACTORED && {
          paymentCompanyName: term?.paymentCompanyName,
          paymentAddress: term?.paymentAddress,
          ...(term?.paymentSecondAddress && { paymentSecondAddress: term?.paymentSecondAddress }),
          paymentCity: term?.paymentCity,
          paymentStateId: Number(term?.paymentStateId),
          paymentZip: String(term?.paymentZip),
        }),
      ...(term?.paymentType === 3 && {
        paymentFuelCard: term?.paymentFuelCard,
      }),
      paymentTermType: term?.brokerPaymentTermType,
      day: term?.day,
      percent: Number(term?.percent) || 0,
      emails: term?.brokerEmails,
      ...(term?.showFiles?.length || term?.showAgreement?.length
        ? {
            files: [...(term.showFiles || []), ...(term.showAgreement || [])]?.map(file => ({
              fileId: file?.id,
              type: Number(file?.fileType),
            })),
          }
        : {}),
    };
  });
  const equipments = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
    type: item,
    status: carrier?.equipmentType?.includes(item) ? ActiveTypes.ACTIVE : ActiveTypes.INACTIVE,
  }));

  return {
    ...(carrierPaymentTerms && { paymentTerms: carrierPaymentTerms }),
    ...(carrier?.id ? { carrierOnBoardId: Number(carrier?.id) } : {}),
    address: carrier.address,
    city: carrier.city,
    equipments,
    ...(carrier?.fuelCards?.length ? { fuelCards: carrier?.fuelCards } : {}),
    cargo: changeTimeZone(new Date(carrier.Cargo), 'America/Los_Angeles'),
    liability: changeTimeZone(new Date(carrier.Liability), 'America/Los_Angeles'),
    companyName: carrier.company,
    contactName: carrier.contact,
    email: carrier.email,
    mc: String(carrier.MC),
    fax: carrier.fax ?? '',
    ff: carrier.ff ?? '',
    phone: String(carrier.primaryPhone),
    secondPhone: carrier.secondaryPhone ? String(carrier.secondaryPhone) : '',
    secondAddress: carrier.secondAddress ? String(carrier.secondAddress) : '',
    stateId: Number(carrier.state),
    zip: carrier.zip,
    type: Number(carrier.type),
    dot: carrier.dot,
    ...(carrier?.cargoCoverageValue ? { cargoCoverageValue: Number(carrier.cargoCoverageValue) } : {}),
    ...(carrier?.nonOwnedCoverage ? { nonOwnedCoverage: Number(carrier.nonOwnedCoverage) } : {}),
    ...(carrier?.trailerInterchange ? { trailerInterchange: carrier.trailerInterchange } : {}),
    ...(carrier?.reeferBreakdown ? { reeferBreakdown: carrier.reeferBreakdown } : {}),
    ...(carrier?.stateNumber ? { stateNumber: carrier?.stateNumber } : {}),
    ...(carrier?.secondAddress ? { secondAddress: carrier?.secondAddress } : {}),
    paymentPaidAs: carrier?.paymentPaidAs,
    document: [
      {
        ...(carrier.carrierPacketFile?.length
          ? {
              type: IFileTypes.carrierPacketFile,
              ids: carrier.carrierPacketFile?.map((el: any) => Number(el)),
            }
          : {}),
      },
      ...(carrier.companyOwnerIdFile?.length
        ? [
            {
              type: IFileTypes.companyOwnerIdFile,
              ids: carrier.companyOwnerIdFile?.map((el: any) => Number(el)),
            },
          ]
        : []),
      {
        ...(carrier.signAgreementFile?.length
          ? {
              type: IFileTypes.signAgreementFile,
              ids: carrier.signAgreementFile?.map((el: any) => Number(el)),
            }
          : {}),
      },
      {
        ...(carrier.paidAsW9File?.length
          ? {
              type: IFileTypes.paidAsW9File,
              ids: carrier.paidAsW9File?.map((el: any) => Number(el)),
            }
          : {}),
      },
      {
        ...(carrier.insuranceFile?.length
          ? {
              type: IFileTypes.insuranceFile,
              ids: carrier.insuranceFile?.map((el: any) => Number(el)),
            }
          : {}),
      },
      {
        ...(carrier.voidedCheckBankLatterFile?.length
          ? {
              type: IFileTypes.voidedCheckBankLatterFile,
              ids: carrier.voidedCheckBankLatterFile?.map((el: any) => Number(el)),
            }
          : {}),
      },
    ],
    ...(ICarrierType[Number(carrier.type)] === 'Owner Operator' && {
      trucks: carrier?.trucks?.reverse()?.map((item: any) => {
        return {
          truckNumber: item.truckNumber,
          yearAndMake: item.yearAndMake,
          boxTruck: item.boxTruck,
          ...(item.boxTruck === 1 ? { length: Number(item.length) } : {}),
        };
      }),
    }),
    physical: carrier?.physical ? 1 : 2,
    ...(carrier?.physical ? { physicalAddress: carrier?.physicalAddress } : {}),
    ...(carrier?.physical ? { physicalZip: carrier?.physicalZip } : {}),
    ...(carrier?.physical ? { physicalStateId: Number(carrier?.physicalState) } : {}),
    ...(carrier?.physical ? { physicalCity: carrier?.physicalCity } : {}),
    ...(carrier?.physical && carrier?.physicalSecondAddress
      ? { physicalSecondAddress: carrier?.physicalSecondAddress }
      : {}),
    ...(carrier?.numberOfTrailers?.length && { numberOfTrailers: carrier?.numberOfTrailers }),
    ...(carrier?.numberOfTrucks?.length && { numberOfTrucks: carrier?.numberOfTrucks }),
  };
};

export const createEditPaymentTerm = (term: any): any => {
  return {
    type: term?.type,
    ...(term?.authorityId?.length && { authorityId: term?.authorityId?.[0]?.id }),
    ...(term?.carrierId && { carrierId: term?.carrierId }),
    billingType: term?.factoringType,
    ...(term?.factoredCompanyName?.length && { factoringCompanyId: term?.factoredCompanyName?.[0]?.id }),
    dealType: term?.paymentType,
    ...(term?.paymentType === 1 &&
      term?.factoringType !== CarrierFactoringType.FACTORED && {
        paymentBankName: term?.paymentBankName,
        paymentRoutingNumber: term?.paymentRoutingNumber,
        paymentAccountNumber: term?.paymentAccountNumber,
      }),
    ...(term?.paymentType === 2 &&
      term?.factoringType !== CarrierFactoringType.FACTORED && {
        paymentCompanyName: term?.paymentCompanyName,
        paymentAddress: term?.paymentAddress,
        ...(term?.paymentSecondAddress && { paymentSecondAddress: term?.paymentSecondAddress }),
        paymentCity: term?.paymentCity,
        paymentStateId: Number(term?.paymentStateId),
        paymentZip: String(term?.paymentZip),
      }),
    ...(term?.paymentType === 3 && {
      paymentFuelCard: term?.paymentFuelCard,
    }),
    paymentTermType: term?.brokerPaymentTermType,
    day: term?.day,
    percent: Number(term?.percent),
    emails: term?.brokerEmails,
    files: [...(term.showFiles || []), ...(term.showAgreement || [])]?.map(file => ({
      fileId: file?.id,
      type: Number(file?.fileType),
    })),
  };
};

export const updateCarrierSerializer = (carrier: any) => {
  const equipments = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
    type: item,
    status: carrier?.equipmentType?.includes(item) ? ActiveTypes.ACTIVE : ActiveTypes.INACTIVE,
  }));
  const cargo =
    typeof carrier.Cargo !== 'number'
      ? new Date(carrier.Cargo.getFullYear(), carrier.Cargo.getMonth(), carrier.Cargo.getDate())
      : carrier.Cargo;
  const liability =
    typeof carrier.Liability !== 'number'
      ? new Date(carrier.Liability.getFullYear(), carrier.Liability.getMonth(), carrier.Liability.getDate())
      : carrier.Liability;
  return {
    address: carrier.address,
    city: carrier.city,
    companyName: carrier.company,
    equipments,
    contactName: carrier.contact,
    ...(carrier?.cargoCoverageValue ? { cargoCoverageValue: Number(carrier.cargoCoverageValue) } : {}),
    ...(carrier?.nonOwnedCoverage ? { nonOwnedCoverage: Number(carrier.nonOwnedCoverage) } : {}),
    ...(carrier?.trailerInterchange ? { trailerInterchange: carrier.trailerInterchange } : {}),
    ...(carrier?.reeferBreakdown ? { reeferBreakdown: carrier.reeferBreakdown } : {}),
    email: carrier.email,
    mc: carrier.MC ? String(carrier.MC) : '',
    ff: carrier.ff ?? '',
    fax: carrier.fax ?? '',
    phone: String(carrier.primaryPhone),
    secondPhone: carrier.secondaryPhone ? String(carrier.secondaryPhone) : '',
    secondAddress: carrier.secondAddress ? String(carrier.secondAddress) : '',
    stateId: Number(carrier.state),
    zip: carrier.zip,
    type: Number(carrier.type),
    dot: carrier.dot,
    ...(carrier?.stateNumber ? { stateNumber: carrier?.stateNumber } : {}),
    ...(carrier?.ff ? { ff: carrier?.ff } : {}),
    paymentPaidAs: carrier?.paymentPaidAs,
    cargo: typeof cargo !== 'number' ? changeTimeZone(cargo, 'America/Los_Angeles') : cargo,
    liability: typeof liability !== 'number' ? changeTimeZone(liability, 'America/Los_Angeles') : liability,
    physical: carrier?.physical ? 1 : 2,
    ...(carrier?.physical ? { physicalAddress: carrier?.physicalAddress } : {}),
    ...(carrier?.physical ? { physicalZip: carrier?.physicalZip } : {}),
    ...(carrier?.physical ? { physicalStateId: Number(carrier?.physicalState) } : {}),
    ...(carrier?.physical ? { physicalCity: carrier?.physicalCity } : {}),
    ...(carrier?.physical && carrier?.physicalSecondAddress
      ? { physicalSecondAddress: carrier?.physicalSecondAddress }
      : {}),
    ...(carrier.trucks.length && ICarrierType[Number(carrier.type)] === 'Owner Operator'
      ? {
          trucks: carrier?.trucks?.reverse()?.map((item: any) => {
            return {
              truckNumber: item.truckNumber,
              yearAndMake: item.yearAndMake,
              boxTruck: item.boxTruck,
              ...(item.boxTruck === 1 ? { length: Number(item.length) } : {}),
            };
          }),
        }
      : {}),
    ...(carrier?.numberOfTrailers?.length && { numberOfTrailers: carrier?.numberOfTrailers }),
    ...(carrier?.numberOfTrucks?.length && { numberOfTrucks: carrier?.numberOfTrucks }),
  };
};
